import React from 'react';
import {
    RegularWork, Object, Comment, IAct, regularWorkRemoveOtRequest, getRegularWorkHistory,
    getRegularStatus, declineRegularWorks, getFullName, getRegularWork, History
} from "@justpro/terminal";
import moment from "moment";
import {Angle} from "../../UI/itemsList/card/card.view";
import WithModuleDetail from "../../UI/dialog/withModuleDetail/withModuleDetail.controller";
import ObjectController from "../../references/objects/object/object.controller";
import RenderIf from "../../../utils/renderIf";
import RegularWorksComments from "./tabs/comments";
import RegularWorksPhotos from "./tabs/photos";
import Button from "../../UI/button/button";
import Tabs from "../../UI/tabs/tabs.controller";
import RegularWorkWorkorders from "./tabs/regularWork.workorders";
import ActListItem from "../../acts/act/act.listItem";
import ProtocolController from "../../UI/protocol/protocol.controller";
import {getObjectName} from "../../../utils/names";
import {getEquipmentName} from "../../../utils/names";
import Dropdown from "../../UI/dropdownMenu/dropdown.controller";
import checkError from "../../../utils/checkError";
import {DropDownItem} from "../../UI/dropdownMenu/dropdown.types";
import Spinner from "../../UI/spinner/spinner.controller";
import getText from "../../../localization/getText";
import DeclineReasonModal from "../../orders/detail/declineReason.modal";
import {connect} from "react-redux";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal} from "../../../store/modal/modal.types";
import InfoBlock from "../../UI/infoBlock/infoBlock.controller";
import BlockController from "../../UI/infoBlock/block.controller";
import {confirm} from "../../UI/confirmAction/confirmAction.controller";
import ModuleHistory from "../../UI/moduleHistory/history.controller";

interface Props {
    regularWork: RegularWork
    settings: any
    rights: any
    openModal(props:Modal) : void
    getEditMode(mode: 'edit' | 'read'): void
    getRegularWork(): any
}

interface State {
    comments: Comment[],
    history: History[],
    loading: boolean
}


class RegularWorkDetail extends React.Component<Props, State> {

    state: State = {
        comments: [],
        history: [],
        loading: false,
    };

    get tabs() {

        const {regularWork, rights} = this.props;

        const result: any[] = [
            {
                title: 'UI.tabs.photoAndVideo',
                component: () => {
                    return <RegularWorksPhotos regularWorkId={this.props.regularWork.id}/>
                },
                onSelectTab: (tab: any) => {
                    return Promise.resolve()
                }
            },
            {
                title: 'UI.tabs.comments',
                component: () => {
                    return <RegularWorksComments regularWorkId={this.props.regularWork.id}/>
                },
                onSelectTab: (tab: any) => {
                    return Promise.resolve()
                }
            },
        ];

        if (regularWork?.workorders && regularWork.workorders.length > 0) {
            result.push({
                title: 'workorders.workorders',
                component: () => {
                    return <RegularWorkWorkorders workorders={regularWork?.workorders}/>
                },
                onSelectTab: (tab: any) => {
                    return Promise.resolve()
                }
            })
        }

        if (regularWork.act) {
            result.push({
                title: 'acts.acts',
                component: () => {
                    return <ActListItem act={(regularWork.act as IAct)} withModuleDetail={true}/>
                },
                onSelectTab: (tab: any) => {
                    return Promise.resolve()
                }
            })
        }

        if (regularWork?.otRequest?.protocol) {
            result.push({
                title: 'UI.tabs.protocol',
                component: () => {
                    return <ProtocolController protocol={regularWork?.otRequest?.protocol}/>
                }
            })
        }

        if (rights?.["regularWorks.history"]?.read) {
            result.push(
                {
                    title: 'UI.tabs.history',
                    component: () => <ModuleHistory list={this.state.history}/>,
                    onSelectTab: this.getHistory,
                }
            )
        }

        return result
    }

    changeTab: ((index: number) => any) | void = void 0;

    onTabsInit = ({changeTab}: any) => {
        this.changeTab = changeTab
    };

    get dropdownOptions() {
        const {otRequest} = this.props.regularWork;
        const actions: DropDownItem[] = [{
            id: 2,
            icon: 'fa fa-ban',
            name: "Снять регламентную работу",
            handler: this.declineOpenModal
        }];
        if (otRequest) {
            actions.push({
                id: 1,
                icon: 'fa fa-window-close',
                name: 'Отвязать WO',
                handler: this.removeOtRequest
            });
        }
        return actions;
    }

    getHistory = async () => {
        try {
            const history = await getRegularWorkHistory(this.props?.regularWork?.id);
            this.setState(() => ({
                history: history ? history : []
            }))
        } catch (e) {
            checkError(e)
        }
    };

    declineOpenModal = () => {
        this.props.openModal({
            id : 'ordersDeclineOrder',
            component: (props) => <DeclineReasonModal {...props} save={this.declineRegularWork}/>,
            title : getText('orders.cancelReason')
        });
    };

    removeOtRequest = async () => {
        try {
            const accept = await confirm({
                question: getText("woDeclineMessage")
            });
            if(!accept) return;
            this.setState({
                loading: true
            });
            await regularWorkRemoveOtRequest(this.props.regularWork.id);
            await this.props.getRegularWork();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    declineRegularWork = async (reason: string) => {
        const {settings} = this.props;
        try {
            this.setState({
                loading: true
            });
            const declineRegularStatusId = settings?.['declineRegularStatusId'];
            const declined = await declineRegularWorks([this.props.regularWork.id], reason);
            if (declined) {
                await this.props.getRegularWork();
            }
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    selectAction = (item: DropDownItem) => {
        item?.handler && item.handler();
    }

    get blockItems () {
        const {
            number, controlDate, date, equipment, equipmentWork,
            regularStatus, createdAt, workGroup, otRequest, declineReasons
        } = this.props.regularWork;
        const result:any[] = [
            {
                key : getText('regularWorks.number'),
                value : number ? number : getText('common.notSpecified')
            }
        ];

        if(otRequest?.number) {
            result.push(
                {key : getText('orders.numberWO'), value : `${otRequest?.number} ${otRequest?.baseDocument ? otRequest.baseDocument : ""}`},
            )
        }

        result.push(
            {key : getText('regularWorks.createAt'), value : createdAt ? moment(createdAt).format('DD.MM.YYYY') : getText('common.notSpecified')},
            {key : getText('regularWorks.startDate'), value : date ? moment(date).format('DD.MM.YYYY') : getText('common.notSpecified')},
            {key : getText('regularWorks.controlDate'), value : controlDate ? moment(controlDate).format('DD.MM.YYYY') : getText('common.notSpecified')},
        );

        if(equipment?.object) {
            result.push({
                    key : getText('objects.object'),
                    value : <WithModuleDetail
                                element={<span className="with-module-expand">{getObjectName(equipment.object)}</span>}
                                modal={{
                                    id: "regularWorksObject",
                                    component : () => (
                                        <ObjectController id={(equipment.object as Object).id}/>
                                    ),
                                    title: getText('objects.object')
                                }}
                    />
            })
        }
        result.push(
            {key : getText('regularStatuses.regularStatus'), value : regularStatus ? regularStatus.name : getText('common.notSpecified')},
            {key : getText('equipmentWorks.equipmentWork'), value : equipmentWork ? equipmentWork.name : getText('common.notSpecified')},
            {key : getText('equipments.equipment'), value : getEquipmentName(equipment)},
            {key : getText('regularWorks.createdFromWork'), value : workGroup.name},
        )

        return result;
    }

    componentDidUpdate(prevProps){
        if(prevProps.regularWork.id !== this.props.regularWork.id){
            this.getHistory();
        }
    }

    render() {
        const {declineReasons} = this.props.regularWork;
        const {loading} = this.state;
        return (
            <>
                <Spinner loading={loading}/>
                <div className="just-pro__pre-detail-panel">
                    <div className="panel-toolbar regular-work">
                        <RenderIf condition={this.dropdownOptions?.length}>
                            <Dropdown disableChangeActive
                                      onChange={this.selectAction}
                                      label={{
                                          name: "Выберите действие",
                                          icon: ""
                                      }}
                                      list={this.dropdownOptions}
                            />
                        </RenderIf>
                        <Button
                            className="btn-default pull-right"
                            onClick={this.props.getEditMode.bind(this, 'edit')}
                        >
                            <i className="fa fa-edit"/> {getText('common.edit')}
                        </Button>
                    </div>
                </div>

                <InfoBlock>
                    <BlockController
                        items={this.blockItems}
                    />
                </InfoBlock>

                {!!declineReasons?.length && declineReasons?.map((declineReason) => (
                    <div className="bs-callout bdt">
                        <h4>{getText('orders.cancelReason')}</h4>
                        <p>
                            {declineReason?.reason}
                            <br/>
                            {getText('common.fromWho')} <b>{moment(declineReason?.createdAt).format("DD.MM.YYYY HH:mm:ss")}</b>
                            &nbsp;
                            {getText('common.createdBy')}: <b>{getFullName(declineReason?.author?.person)} </b>
                            <br/>
                        </p>
                    </div>
                ))}

                <Tabs tabs={this.tabs} defaultIndex={0} onInit={this.onTabsInit}/>
            </>
        )
    }
}

const mapState = (state) => ({
    settings: state.application.settings,
    rights: state.application.rights
})
const dispatchProps = (dispatch) => ({
    openModal: (props:Modal) => dispatch(openModal(props))
})
export default connect(mapState, dispatchProps)(RegularWorkDetail)