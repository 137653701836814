import React from 'react';
import Comments from "../UI/comments/comments.controller";
import ModuleHistory from "../UI/moduleHistory/history.controller";
import {
    Comment,
    AddFile,
    History,
    addActComment,
    getActComments,
    getActHistory,
    ModulesResponse, ITask, getActTasks
} from "@justpro/terminal";
import checkError from "../../utils/checkError";
import {ApplicationMapState} from "../application/application.controller";
import {connect} from "react-redux";
import Tabs from "../UI/tabs/tabs.controller";
import ActTasks from "./act/act.tasks";
import TaskListItem from "../tasks/task.listItem";


interface Props {
    actId: number
    rights?: Partial<ModulesResponse>
}

interface State {
    selected: number
    comments: Comment[]
    history: History[]
    tasks: ITask[]
}

class ActTabs extends React.Component<Props, State> {

    state: State = {
        comments: [],
        history: [],
        selected: 0,
        tasks: [],
    };

    changeTab: ((index: number) => any) | void = void 0;

    get tabs() {
        const {rights} = this.props;
        const result: any[] = [];

        if (rights && rights['acts.comments'] && rights['acts.comments']['read']) {
            result.push(
                {
                    title: 'UI.tabs.comments',
                    component: () => {
                        return <Comments
                            comments={this.state.comments}
                            addComment={this.addComment}
                            rightsName="acts.comments"
                        />
                    },
                    onSelectTab: this.getComments
                }
            )
        }

        if (rights && rights['acts.history'] && rights['acts.history']['read']) {
            result.push(
                {
                    title: 'UI.tabs.history',
                    component: () => {
                        return <ModuleHistory list={this.state.history}/>
                    },
                    onSelectTab: this.getActHistory
                }
            )
        }

        if (rights?.['acts.tasks']?.read) {
            result.push(
                {
                    title: 'tasks.tasks',
                    component: () => (
                        <>
                            {this.state.tasks && this.state.tasks.map((task) => (
                                <TaskListItem task={task} key={task.id} withModuleDetail/>
                            ))}
                        </>
                    ),
                    onSelectTab: this.getActTasks
                },
            )
        }

        return result;
    }


    getActTasks = async () => {
        try {
            const tasks = await getActTasks(this.props.actId);

            this.setState(() => ({tasks}))

        } catch (e) {
            checkError(e)
        }
    }

    onTabsInit = ({changeTab}: any) => {
        this.changeTab = changeTab;
    };

    addComment = async (message: string, files?: AddFile[]) => {

        const {rights} = this.props;


        if (rights && rights['acts.comments'] && rights['acts.comments']['create']) {
            try {
                const addedComment = await addActComment(this.props.actId, {
                    message,
                    files,
                });

                this.setState((prevState) => ({
                    comments: [...prevState.comments, {
                        ...addedComment,
                        new: false,
                    }]
                }));

            } catch (e) {
                checkError(e)
            }
        }
    };

    getActHistory = async () => {
        const {rights} = this.props;
        if (rights && rights['acts.history'] && rights['acts.history']['read']) {
            try {
                const history = await getActHistory(this.props.actId);

                this.setState({
                    history: history || []
                })
            } catch (e) {
                checkError(e)
            }
        }
    };

    getComments = async () => {
        const {rights} = this.props;
        if (rights && rights['acts.comments'] && rights['acts.comments']['read']) {
            try {
                const actComments = await getActComments(this.props.actId);

                const comments = actComments?.map((actComment) => {
                    return {
                        ...actComment,
                        new: false,
                    }
                });

                this.setState({
                    comments: comments || []
                })

            } catch (e) {
                checkError(e)
            }
        }
    };

    componentDidMount() {
        this.getActHistory();
        this.getActTasks();
        return this.getComments();
    }

    componentDidUpdate(nextProps: Props) {
        if (this.props.actId !== nextProps.actId) {
            this.getActHistory();
            this.getActTasks();
            return this.getComments();
        }
    }

    render() {
        return <Tabs tabs={this.tabs} onInit={this.onTabsInit} defaultIndex={0}/>
    }
}

const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
});


export default connect(mapState)(ActTabs)