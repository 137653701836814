import React from "react";
import {getStatusGroup, StatusGroup, updateStatusGroup, UpdateStatusGroup} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import DetailStatusGroup from './statusGroup.detail'
import RenderIf from "../../../utils/renderIf";

interface Props {
    id : number
    afterUpdate?(item:StatusGroup):void
}

interface State {
    loading : boolean
    statusGroup? : StatusGroup
}

class StatusGroupController extends React.Component<Props, State>{

    state:State = {
        loading : false,
    };


    getStatusGroup = async () => {
        try {
            this.setState(() => ({loading : true}));

            const statusGroup = await getStatusGroup(this.props.id);

            this.setState(() => ({
                loading : false,
                statusGroup,
            }));
        }catch (e) {
            checkError(e)
        }

    };

    updateGroup = async (data:Partial<UpdateStatusGroup>) => {
        const {afterUpdate} = this.props;
        try {
            this.setState(() => ({loading : true}));
            const statusGroup = await updateStatusGroup(this.props.id, data);

            afterUpdate && afterUpdate(statusGroup);

            this.setState(() => ({
                loading : false,
                statusGroup,
            }));


        }catch (e) {
            checkError(e)
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getStatusGroup()
        }
    }

    async componentDidMount() {
        this.getStatusGroup()
    }

    render () {


        return (<>
            <RenderIf condition={this.state.statusGroup !== undefined} >
                <DetailStatusGroup
                    update={this.updateGroup}
                    group={this.state.statusGroup as StatusGroup}
                />
            </RenderIf>
        </>)
    }

}

export default StatusGroupController;