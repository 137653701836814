import React from "react";
import {getPersons, Person, SingleNews, createNews, updateSingleNews} from "@justpro/terminal";
import AsyncSelect from "../UI/select/asyncSelect_v2";
import checkError from "../../utils/checkError";
import {getFullName} from "../../utils/names";
import {Option} from "../UI/select/select.types";
import Tooltip from "../UI/tooltip/tooltip.controller";
import './news.css'
import Button from "../UI/button/button";
import withLabel from "../UI/withLabel/withLabel";
import TextEditorController from "../UI/textEditor/textEditor.controller";
import Input from "../UI/input/text";
import SingleNewsTabs from "./singleNews.tabs";
import RenderIf from "../../utils/renderIf";
import {connect} from "react-redux";
import Spinner from "../UI/spinner/spinner.controller";
import SingleNewsDetail from "./singleNews.detail";
import {updateModal} from "../../store/modal/modal.actions";
import {Modal} from "../../store/modal/modal.types";
import {editModalId} from "./news.controller";

interface Props {
    me?: any
    rights?: any
    settings?: any

    updateModal(id:string, props:Partial<Modal>) : void
    onSave(): any
    hide () : void
    singleNews?: SingleNews
}

interface State {
    canCommented: boolean
    title: string
    content: string
    owner?: Option
    files?: File[]
    loading: boolean
}

class SingleNewsEdit extends React.Component<Props, State> {
    state: State = {
        canCommented: true,
        title: '',
        content: '',
        loading: false
    };

    changeOwner = async (owner: Option) => {
        this.setState(() => ({owner}))
    };

    saveOrCreateNews = async (isDraft: boolean) => {
        try {
            const {singleNews} = this.props;
            if (!!singleNews) {
                await updateSingleNews(singleNews?.id, {
                    title: this.state.title,
                    content: this.state.content,
                    canCommented: this.state.canCommented,
                    ownerId: this.state.owner?.value,
                    isDraft
                });
            } else {
                await createNews({
                    title: this.state.title,
                    content: this.state.content,
                    canCommented: this.state.canCommented,
                    ownerId: this.state.owner?.value,
                    isDraft
                });
            }
            this.props.onSave();
            this.props.hide();
        } catch (e) {
            checkError(e)
        }
    }

    changeAbleToComment = () => {
        this.setState((prevState: State) => ({
            canCommented: !prevState.canCommented
        }));
    };

    getAvailablePersons = async (q: string) => {
        const {settings} = this.props;
        if (!settings) return [];
        try {
            const persons = await getPersons({q, contractorsId: [+settings?.tfmContractorId]});

            return persons?.map(item => ({
                ...item,
                label: getFullName(item),
                value: item.id
            })) || []

        } catch (e) {
            checkError(e);

            return []
        }

    };


    onChangeContent = (content: string) => {
        this.setState({content})
    };


    onChangeTitle = (title: string) => {
        this.setState({title})
    };

    resetState = () => {
        const {singleNews, me, rights} = this.props;
        const canEdit = this.state.owner?.id === me?.person?.id && rights?.news?.edit;
        let title = 'Просмотр новости';
        if (canEdit) {
            title = !!singleNews ? "Редактирование новости" : 'Создание новости';
        }

        this.props.updateModal(editModalId,{
            title : (title)
        })

        if (singleNews) {
            this.setState({
                title: singleNews?.title,
                content: singleNews?.content,
                canCommented: singleNews?.canCommented,
                owner: {
                    label: getFullName(singleNews?.owner),
                    value: singleNews?.owner?.id,
                    ...singleNews?.owner
                }
            });
        } else {
            this.setState({
                canCommented: true,
                title: '',
                content: '',
                owner: {
                    label: getFullName(me?.person),
                    value: me?.person?.id,
                    ...me?.pesron
                }
            })
        }
    };

    componentDidMount() {
        this.resetState();
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.resetState();
        }
    }

    render() {
        const defaultValue = {};
        const {singleNews, me, rights} = this.props;
        const {owner, loading} = this.state;
        const canEdit = owner?.id === me?.person?.id && rights?.news?.edit;
        let title = 'news.viewingNews';
        if (canEdit) {
            title = !!singleNews ? "news.editingNews" : 'news.creatingNews';
        }
        return (
            <>
                <Spinner loading={loading}/>
                <RenderIf condition={canEdit || !singleNews}>
                    <div className="novelty-edit">
                        <div className="novelty-edit__pre-header">
                            <Tooltip title="news.possibleToComment">
                                <input type="checkbox" checked={this.state.canCommented}
                                       onChange={this.changeAbleToComment}/>
                            </Tooltip>
                            <RenderIf condition={!singleNews || singleNews?.isDraft}>
                                <Tooltip title="news.saveNews">
                                    <Button className="btn-success" onClick={this.saveOrCreateNews.bind(this, true)}>
                                        <i className="fa fa-save no-text"/>
                                    </Button>
                                </Tooltip>
                            </RenderIf>
                            <Tooltip title="news.publishNews">
                                <Button className="btn-success" onClick={this.saveOrCreateNews.bind(this, false)}>
                                    <i className="fa fa-paper-plane no-text"/>
                                </Button>
                            </Tooltip>

                        </div>

                        <div className="novelty-edit__owner">
                            <AsyncSelect
                                change={this.changeOwner}
                                loadOptions={this.getAvailablePersons}
                                label={{text: "news.setOwner", position: 'top'}}
                                defaultValue={owner}
                                normalizeParams={{
                                    label: (item: Person) => getFullName(item),
                                    value: "id"
                                }}
                            />
                        </div>

                        <div className="novelty-edit__title">
                            {withLabel(
                                <Input
                                    change={this.onChangeTitle}
                                    value={this.state.title}
                                />
                            )({position: 'top', text: 'news.newsHeader'})}
                        </div>
                        <div className="novelty-edit__content">
                            {withLabel(
                                <>
                                    <div id="news-toolbar"></div>
                                    <TextEditorController
                                        onChange={this.onChangeContent}
                                        html={this.state.content || ''}
                                        toolbar
                                        toolbarId="#news-toolbar"
                                        toolbarSticky={false}
                                        inline={false}
                                        minHeight={300}
                                        placeholder="news.dotNewsContent"
                                        toolbarCustom='link image anchor | fontselect fontsizeselect forecolor backcolor | bold italic | \
                                    alignleft aligncenter alignright | \
                                    table bullist numlist outdent indent | pagebreak emoticons help'
                                        init={{
                                            language: "ru",
                                            toolbar_persist: true,
                                            branding: false,
                                            plugins: [
                                                'advlist autolink lists link image',
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste',
                                                'emoticons', 'anchor', 'pagebreak'
                                            ],
                                            table_resize_bars: true
                                        }}
                                    />
                                </>)({text: "news.newsContent", position: "top"})}
                        </div>
                    </div>
                </RenderIf>
                <RenderIf condition={!canEdit && singleNews}>
                    <SingleNewsDetail singleNews={singleNews}/>
                </RenderIf>
                <RenderIf condition={!!singleNews}>
                    <SingleNewsTabs singleNews={singleNews}/>
                </RenderIf>
            </>
        )
    }

}

const mapState = (state: any) => ({
    me: state.application.me,
    settings: state.application.settings,
    rights: state.application.rights
});

const mapDispatchToProps = (dispatch:Function) => ({
    updateModal : (id:string, props:Modal) => dispatch(updateModal(id, props))
});

export default connect(mapState, mapDispatchToProps)(SingleNewsEdit);