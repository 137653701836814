import React from "react";
import {
    ChecklistWorkorder,
    getWorkorders,
    Order,
    OrdersWorkorder,
    Workorder,
    addWorkorderOrders,
    getWorkorder
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import '../orders.css'
import ItemList from "../../UI/itemsList/itemList.controller";
import WorkOrderListItem from "../../workOrders/workOrder.listItem";
import RenderIf from "../../../utils/renderIf";
import WorkOrderController from "../../workOrders/workOrder.controller";
import {addOrderToWorkorderModalName} from "./orderRead.controller";
import GetText from "../../../localization/getText";

interface Props {
    order: Order
}

interface State {
    loading : boolean
    workOrders? : OrdersWorkorder[] | ChecklistWorkorder[]
    workOrder? : OrdersWorkorder | ChecklistWorkorder
}

class AddOrderToWorkorderModal extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    addOrderToWorkorder = async (workOrder:Workorder) => {
        try {

            this.setState(() => ({loading: true}));

            await addWorkorderOrders(workOrder.id, [this.props.order.id]);
            const workorder = await getWorkorder(workOrder.id);


            this.setState(() => ({
                loading: false,
                workOrder : workorder
            }));

            console.log({workorder})

        }catch (e) {
            this.setState(() => ({loading: false}));
            checkError(e)
        }

    };

    getWorkorders = async () => {
        try {

            const {list} = await getWorkorders({
                objectsId : [this.props.order.object.id],
                closed : "0"
            });

            return list

        }catch (e) {
            checkError(e);
            return [];
        }
    };

    renderItem = (item:OrdersWorkorder | ChecklistWorkorder) => {
        return (
            <WorkOrderListItem
                withInclude={{
                    handler : this.addOrderToWorkorder,
                    title : 'orders.addOrderToWorkOrder'
                }}
                workOrder={item}
                withModuleDetail={true}

            />
        )
    };

    async componentDidMount() {
        this.setState(() => ({loading :true}));
        const list = await this.getWorkorders();
        this.setState(() => ({loading : false, workOrders : list}))
    }

    render() {
        const {workOrder, loading, workOrders} = this.state;

        return (
            <>
                <div className="addOrderToWorkOrder_content">

                    <RenderIf condition={workOrder === undefined && !loading && workOrders && workOrders.length > 0}>
                        <h4>{GetText('orders.workOrdersPossibleList')}: </h4>
                    </RenderIf>

                    <RenderIf condition={workOrder === undefined && !loading && workOrders && workOrders.length === 0}>
                        <h4>{GetText('orders.workOrdersPossibleListIsEmpty')}</h4>
                    </RenderIf>


                    <RenderIf condition={workOrder !== undefined}>
                        <WorkOrderController workOrder={workOrder} />
                    </RenderIf>


                    <RenderIf condition={workOrder === undefined}>
                        <ItemList loading={loading} renderItem={this.renderItem} list={workOrders}/>
                    </RenderIf>


                </div>
            </>
        )
    }


}


export default AddOrderToWorkorderModal