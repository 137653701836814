import * as api from '@justpro/terminal'
import checkError from "../../../../utils/checkError";
import getParents from "../getParents";
import {listTypes} from "../../list.reducer";
import {rolesTypes} from "../../roles.reducer";
import {groupTypes} from "../../groups.reducer";
import {usersTypes} from "../../users.reducer";
import {userTypes} from "../../user.reducer";

export const getRole = (id: number) => async (dispatch:Function) => {
    try {
        const users = await api.getUsers({roleId : id});
        const map = await api.getRoles();
        const {name, children, parentRoles, ...rest} = map[id];

        const [parent, parentList] = getParents(name, map, parentRoles, children, id);

        dispatch({
            type : rolesTypes.GET_ROLE,
            parentList,
            parent,
            name,
            id,
            map,
            ...rest
        });

        dispatch({
            type : usersTypes.CHANGE_USERS_PANELS,
            detail : 'role',
            tree : 'role'
        });

        dispatch({
            type: listTypes.GET_USERS_LIST,
            users
        });

        dispatch({
            type : userTypes.UPDATE_USER,
            id : -1
        });

        dispatch({
            type : groupTypes.UPDATE_GROUP,
            id : -1
        });



    }catch (e) {
        checkError(e)
    }
};

export const getGroup = (id: number) => async (dispatch:Function) => {
    try {
        const users = await api.getUsers({groupId : id});
        const {children, parentGroups, name}  = await api.getGroup(id);
        const map = await api.getGroups();

        const [parent, parentList] = getParents(name, map, parentGroups ,children, id);

        dispatch({
            type : groupTypes.GET_GROUP,
            parent,
            parentList,
            map,
            name,
            children,
            id,
        });

        dispatch({
            type : usersTypes.CHANGE_USERS_PANELS,
            detail : 'group',
            tree : 'group'
        });

        dispatch({
            type : userTypes.UPDATE_USER,
            id : -1,
        });

        dispatch({
            type : listTypes.GET_USERS_LIST,
            users
        });

        dispatch({
            type : rolesTypes.UPDATE_ROLES_MAP,
            id : -1,
        });
    }catch (e) {
        checkError(e)
    }
};
