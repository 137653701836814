import React from 'react';
import {CardProps} from './card.types'
import {CardView,Angle} from './card.view'

const CardComponent = (props:CardProps) => {
    const {
        children,
        isActiveReference,
        listActiveItem,
        color,
        size,
        ...rest
    } = props;

    return (
        <CardView
            className="media"
            isActiveReference={isActiveReference}
            listActiveItem={listActiveItem}
            {...rest}
        >
            {color ? <Angle
                color={color}
                size={size}

            /> : null}
            {children}
        </CardView>
    )

};

export default CardComponent;