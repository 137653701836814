import React from "react";
import {Staff} from "@justpro/terminal";
import StaffDepartment from "./staff.department";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import {Option} from "../../UI/select/select.types";
import {getPersonsList} from "../../../utils/functions";

interface Props {
    id : number // contractorId
    staff : Staff[]
    // shouldUpdate : boolean
    updateTree?(staff?:Staff[]) : void

    onStaffClick?(item:Staff) : void


    defaultOpen? : boolean
}

interface State {
    staff: StaffTreeItem[],
    loading : boolean,
    possibleChiefs : Staff[]
    persons : Option[]
}

export interface StaffMap {
    [id: number] : Staff
}
export interface ChildrenStaffMap {
    [id: number] : StaffTreeItem
}

export interface StaffTreeItem extends Staff, StaffTree{}

export interface StaffTree {
    children? : StaffTreeItem[]
    employees? : StaffTreeItem[],
}

class StaffDetail extends React.Component<Props, State>{

    // undefinedNodes:Staff[] = [];


    state:State = {
        staff : [],
        loading : false,
        possibleChiefs: [],
        persons : []
    };

    extendNode = (node:Staff, root:StaffTreeItem[] ):any[] => {

        return root.map(item => {

            if(item.id === node.chiefId) {
                return {
                    ...item,
                    children : item.children ? [...item.children, node] : [node]
                }
            }

            if(item.children) {
                return {
                    ...item,
                    children : this.extendNode(node, item.children)
                }
            }

            return item
        })
    };

    find = (node:Staff, root:StaffTreeItem[] ):any => {
        return root.find(item => {
            if(item.id === node.chiefId) {
                return true
            }

            if(item.children) {
                return this.find(node, item.children)
            }
        })
    };

    createEmployees = (staff:StaffTreeItem[]):StaffTreeItem[] => {

        return staff.map((item) => {

            if(item.children) {

                let children:StaffTreeItem[] = [];
                let employees:StaffTreeItem[] = [];

                item.children.forEach(child => {
                    if(child.children && child.children.length > 0) {
                        const c = children.slice(0);
                        children = [...c, ...this.createEmployees([child])];
                    }else{
                        employees.push(child)
                    }
                });

                return {
                    ...item,
                    children,
                    employees
                }
            }
            return item
        })
    };


    createStaffTree = (staff:Staff[]):StaffTreeItem[] => {
        //1 сделать map из списка
        const map = staff?.reduce((acc:any, item) => {
            return {
                ...acc,
                [item.id] : {
                    ...item,
                    children : []
                }
            }
        }, {}) || {};
        //создать дочерние элементы 1 уровень .
        staff.forEach(item => {
            if(item.chiefId) {
                map[item.chiefId] = {
                    ...map[item.chiefId],
                    children : [...map[item.chiefId].children, item]
                }
            }else{
                map[item.id] = {
                    ...map[item.id],
                    children : map[item.id]?.children ? map[item.id]?.children : []
                }
            }
        });

        const copy = Object.assign({}, map);

        const recursive = (root:any[]):any => {

            return root.map(child => {
                if(Array.isArray(map[child.id].children) && map[child.id].children.length > 0) {
                    return {
                        ...map[child.id],
                        children : recursive(map[child.id].children)
                    }
                }

                return map[child.id]
            })

        };

        const result = Object.values(copy).map((item:any) => {
            if(item.children) {

                return {
                    ...item,
                    children : recursive(item.children)
                }
            }
            return item
        }).filter(item => item.chiefId === null);


        // console.log({result, staff, map})

        return this.createEmployees(result)

    };

    getStaffStructure = async () => {
        try {
            this.setState(() => ({loading : true}));
            const staff = this.createStaffTree(this.props.staff);

            const persons = await getPersonsList('', [this.props.id]);

            this.setState(() => ({
                staff : staff,
                loading : false,
                persons,
                possibleChiefs : this.props.staff,
            }))

        }catch (e) {
            this.setState(() => ({loading : false}));

            checkError(e)
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(JSON.stringify(this.props.staff) !== JSON.stringify(prevProps.staff)) {
            this.getStaffStructure();
        }
    }

    async componentDidMount() {
        this.getStaffStructure();
    }

    render() {
        const {staff} = this.state;

        return (
            <div className="staff">
                <ul>
                    {staff?.map(item => {

                        return (
                            <li key={item.id}>
                                <StaffDepartment
                                    staff={item}
                                    contractorId={this.props.id}
                                    persons={this.state.persons}
                                    possibleChiefs={this.state.possibleChiefs}
                                    updateTree={this.props.updateTree}
                                    onStaffClick={this.props.onStaffClick}
                                    isOpenNode={this.props.defaultOpen}
                                    // shouldUpdate={this.props.shouldUpdate}
                                />
                            </li>
                        )
                    })}

                </ul>

                <Spinner loading={this.state.loading} />
            </div>
        )
    }
}

export default StaffDetail