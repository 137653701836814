import React from "react";
import {Person, createTask, ITask, ICreateTask, ITaskTag, Tag, getFullName} from "@justpro/terminal"
import checkError from "../../../../utils/checkError";
import {getPersonsList} from "../../../../utils/functions";
import MultiSelect from "../../select/multiSelect";
import Select from "../../select/select";
import {ChangeMultiProps, LoadReturn, Option} from "../../select/select.types";
import {ISelectPerson} from "../../../tasks/task.detail";
import moment, {Moment} from "moment";
import InlineCalendar from "../../calendar/views/inline.controller";
import {connect} from "react-redux";
import {MapState} from "../../../mainNav/mainNav.types";
import TextEditorController from "../../textEditor/textEditor.controller";
import RenderIf from "../../../../utils/renderIf";
import TaskController from "../../../tasks/task.controller";
import SavedTaskTemplates from "../../savedFilter/savedTasks";
import {makeNormalizeParams} from "../../../../utils/makeNormalizeParams";
import {getUniqueList} from "../../../../utils/getUniqueArray";
import './createTask.css'
import {renderToString} from "react-dom/server";
import getText from "../../../../localization/getText";
import {Modal} from "../../../../store/modal/modal.types";
import {openModal, updateModal} from "../../../../store/modal/modal.actions";
import {taskModalId} from "../../../mainNav/mainNav.controller";
import DefaultFooterModal from  '../modal/defaultFooterModal'
import Button from "../../button/button";
import {getTagsList} from "../../../../utils/functions";

interface Props {
    // updateModal(id:string, props:Partial<Modal>): void
    openModal(props:Modal) : void
    hide() : void
    me?: any
    settings?: any

}

interface State {
    tags: any[],
    title: string
    content: string
    dueDate: Moment
    responsibles: ISelectPerson[]
    coworkers: ISelectPerson[]
    watchers: ISelectPerson[]
    task: ITask | null
    persons : Person[]

    // template? : Partial<ICreateTask>
}

interface OldCreateTask extends ICreateTask{
    responsibleId: number
}

class CreateTaskController extends React.Component<Props, State> {
    state:State = {
        tags: [],
        title: "",
        content: "",
        responsibles: [],
        coworkers: [],
        dueDate: moment().add("7", "d"),
        watchers: [],
        task: null,
        persons : []
    }

    async componentDidMount() {
        const persons = await getPersonsList();

        const responsible = persons.find((p) => p.id === this.props.me?.person?.id);
        this.setState({
            responsibles: [{
                ...responsible,
                value: responsible.id
            }],
            persons : persons
        });
    }

    createTask = async () => {
        try {
            const {title, content, coworkers, watchers, responsibles, dueDate, tags} = this.state;
            if (!(title && content && responsibles?.length)) {
                return alert(renderToString(getText('common.fillFields')));
            }

            const task = await createTask({
                title,
                content,
                responsiblesId: responsibles?.map((r) => r.id),
                coworkersId: coworkers?.map((cw) => cw.id),
                watchersId: watchers?.map((w) => w.id),
                tagsId: tags?.map(tag => tag.id),
                dueDate: dueDate.utc(false).format("YYYY-MM-DD")
            });

            this.props.openModal({
                id : "task",
                component : () =>  (
                    <TaskController id={task?.id} />
                ),
                title : getText('tasks.task'),
            });

            this.props.hide();

            // this.setState({
            //     task
            // })
            // this.props.hide();
        } catch (err) {
            checkError(err);
        }
    }

    checkDisabled = () => {
        const {title, content, responsibles} = this.state;
        return !(title && content && responsibles?.length)
    }

    changeTitle = (title: string) => {
        this.setState({
            title
        }, this.checkDisabled)
    }

    changeContent = (content: string) => {
        this.setState({
            content
        }, this.checkDisabled)
    }

    onChangeResponsible = async (responsible: ChangeMultiProps) => {
        if (responsible.type === "select-option") {
            this.setState((prevState) => ({
                responsibles: [...prevState.responsibles, {
                    id: responsible?.value?.value,
                    name: responsible?.value?.label,
                    personId: responsible?.value?.personId,
                }]
            }), this.checkDisabled);
        }
        if (responsible.type === "remove-value") {
            this.setState((prevState) => ({
                responsibles: prevState.responsibles.filter((r) => r.id !== responsible?.value?.value)
            }), this.checkDisabled);
        }
        return false;
    }

    onChangeWatchers = async (watcher: ChangeMultiProps) => {
        if (watcher.type === "select-option") {
            this.setState((prevState) => ({
                watchers: [...prevState.watchers, {
                    id: watcher?.value?.value,
                    name: watcher?.value?.label,
                    personId: watcher?.value?.personId,
                }]
            }));
        }
        if (watcher.type === "remove-value") {
            this.setState((prevState) => ({
                watchers: prevState.watchers.filter((cw) => cw.id !== watcher?.value?.value)
            }));
        }
        return false;
    }

    onChangeCoworkers = async (coworker: ChangeMultiProps) => {
        if (coworker.type === "select-option") {
            this.setState((prevState) => ({
                coworkers: [...prevState.coworkers, {
                    id: coworker?.value?.value,
                    name: coworker?.value?.label,
                    personId: coworker?.value?.personId,
                }]
            }));
        }
        if (coworker.type === "remove-value") {
            this.setState((prevState) => ({
                coworkers: prevState.coworkers.filter((cw) => cw.id !== coworker?.value?.value)
            }));
        }
        return false;
    };

    setTemplate = async (template:Partial<OldCreateTask>) => {

        let responsibles: ISelectPerson[] = [];
        let coworkers:ISelectPerson[]= [];
        let watchers:ISelectPerson[] = [];
        let tags = [];

        const getPersonsListFromIds = (ids:number[]):ISelectPerson[] => {
            return this.state.persons.filter((item) => {
                return ids.includes(item.id)
            }).map(item => {
                return {
                    id : item.id,
                    //@ts-ignore
                    name : item.name,
                    personId : item.id
                }
            })
        };

        if(template.responsibleId) {
            // responsible = await getResponsible(template.responsibleId);
            const responsible = this.state.persons.find((p) => p.id === template.responsibleId) ;
            responsibles.push({
                id: responsible.id,
                name: getFullName(responsible),
                personId : responsible.id
            });
        }

        if(template?.responsiblesId?.length){
            responsibles = getPersonsListFromIds(template.responsiblesId)
        }

        if(template?.coworkersId?.length) {
            coworkers = getPersonsListFromIds(template.coworkersId)
        }

        if(template?.watchersId?.length) {
            watchers = getPersonsListFromIds(template.watchersId)
        }

        if(template?.tagsId?.length){
            const tasksTags = await this.getTasksTags();
            tags = tasksTags.filter((tt) => template?.tagsId.includes(tt.id))
        }

        this.setState(() => ({
            title : template.title ? template.title : '',
            content : template.content ? template.content : '',
            responsibles,
            coworkers,
            watchers,
            tags
        }))
    };

    getCurrentValues = ():Partial<ICreateTask> => {
        const {title, content, coworkers, responsibles, task, watchers, tags} = this.state;

        return {
            title : title ?  title : undefined,
            content : content ? content : undefined,
            responsiblesId : responsibles?.length ? responsibles.map((r) => r.id) : undefined,
            coworkersId : coworkers.map(item => item.id),
            watchersId : watchers.map(item => item.id),
            tagsId : tags.map(item => item.id),
        }

    };

    changeDate = (dueDate: Moment) => {
        console.log(dueDate);
        this.setState({
            dueDate
        })
    };

    getTaskPersons = (): LoadReturn => {
        const {settings} = this.props;
        return new Promise(async resolve => {
            const persons = await getPersonsList('', [+settings.tfmContractorId]);

            const unique = getUniqueList(persons, this.state.coworkers, {wholeId: 'id', existId: 'personId'});

            resolve(unique);
        })
    };

    
    allTags: Tag[] = [];

    getTasksTags = (): LoadReturn => {
        return new Promise((async resolve => {

            if (this.allTags.length === 0) {
                this.allTags = await getTagsList({modules: ['tasks']});
            }

            const unique = getUniqueList(this.allTags, this.state.tags, {wholeId: 'id', existId: 'tagId'});

            resolve(unique)
        }))
    };

    onChangeTaskTags = async (task) => {
        if (task.type === "select-option") {
            this.setState((prevState) => ({
                tags: [...prevState.tags, {
                    id: task?.value?.value,
                    name: task?.value?.label,
                }]
                
            }));
        }
        if (task.type === "remove-value") {
            this.setState((prevState) => ({
                tags: prevState.tags.filter(tag => tag.id !== task?.value?.value)
            }));
        }
        return false;
    }

    render() {
        const {title, content, responsibles, watchers, coworkers, dueDate, task, tags} = this.state;
        //@ts-ignore
        // const taskId: any = task?.id || null;
        return (


            <>
                <div id="toolbar" />
                <div className="create-task_saved-templates">
                    <SavedTaskTemplates setTemplate={this.setTemplate} getCurrentValues={this.getCurrentValues} />
                </div>

                <RenderIf condition={!task}>
                    <div className="task-body row">
                        <TextEditorController
                            onChange={this.changeTitle}
                            html={this.state.title}
                            placeholder="tasks.header"
                            customName="form-control"
                            disableNewLine
                        />
                        <div id="create-task-toolbar"/>
                        <TextEditorController
                            onChange={this.changeContent}
                            html={this.state.content}
                            placeholder="tasks.body"
                            toolbarId="#create-task-toolbar"
                            customName="form-control task-title-input"
                            toolbar
                        />
                    </div>
                    <InlineCalendar date={dueDate} onChange={this.changeDate} label={{text :"common.dueDate"}} timePicker/>
                    <MultiSelect
                        change={this.onChangeResponsible}
                        label="common.responsible"
                        load={this.getTaskPersons}
                        placeholder="UI.select.placeholders.selectResponsibles"
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                        defaultValues={responsibles}
                    />
                    <MultiSelect
                        change={this.onChangeCoworkers}
                        load={this.getTaskPersons}
                        placeholder="UI.select.placeholders.selectCoworkers"
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                        defaultValues={coworkers}
                        label="common.coworkers"
                    />
                    <MultiSelect
                        change={this.onChangeWatchers}
                        load={this.getTaskPersons}
                        placeholder="UI.select.placeholders.selectWatchers"
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                        defaultValues={watchers}
                        label="common.watchers"
                    />
                    <MultiSelect
                        change={this.onChangeTaskTags}
                        load={this.getTasksTags}
                        placeholder="UI.select.placeholders.addTag"
                        accessors={{
                            name: 'label',
                            id: 'value',
                            firstName: "firstName"
                        }}
                        defaultValues={tags}
                        label="tags.tags"
                    />

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success" onClick={this.createTask} disabled={this.checkDisabled()}>{getText('common.create')}</Button>
                    </DefaultFooterModal>
                </RenderIf>
                {/*<RenderIf condition={task}>*/}
                {/*    <TaskController id={taskId} />*/}
                {/*</RenderIf>*/}
            </>
        )
    }
}

const mapStateToProps = (state: MapState) => ({
    me: state.application.me,
    settings: state.application.settings,
});


const mapDispatch = (d:Function) => ({
    updateModal : (id:string, props:Partial<Modal>) => d(updateModal(id, props)),
    openModal : (props:Modal) => d(openModal(props)),
});

export default connect(mapStateToProps, mapDispatch)(CreateTaskController)