import React from "react";
import Input from "../../../UI/input/text";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";
import getText from "../../../../localization/getText";

interface Props {
    hide() : void

    points : {
        latitude : string,
        longitude : string
        radius : string
    }

    save(latitude:string, longitude:string, radius:string) : void
}

interface State {
    latitude : string,
    longitude : string
    radius : string
}

class EditGPSCoordinates extends React.Component<Props, State> {

    state:State = {
        latitude : '',
        longitude : '',
        radius : '',
    };

    onBlurLatitude = (latitude:string) => {
        this.setState(() => ({latitude}))
    };

    onBlurLongitude = (longitude:string) => {
        this.setState(() => ({longitude}))
    };

    onBlurRadius = (radius:string) => {
        this.setState(() => ({radius}))
    };

    isDisabled = () => {
        const {longitude, latitude, radius} = this.state;

        return  latitude.trim() === '' ||
                longitude.trim() === '' ||
                radius.trim() === ''
    };

    save = () => {
        if(!this.isDisabled()) {
            this.props.save(this.state.latitude, this.state.longitude, this.state.radius)
            this.props.hide();
        }
    };

    componentDidMount(): void {
        this.setState(() => ({
            latitude : this.props.points.latitude.toString(),
            longitude : this.props.points.longitude.toString(),
            radius : this.props.points.radius.toString()
        }));

    }

    render() {
        const {latitude, longitude, radius} = this.state;

        return (
            <>
                <Input type="number" label="common.latitude" change={this.onBlurLatitude} startValue={latitude}/>
                <Input type="number" label="common.longitude" change={this.onBlurLongitude} startValue={longitude}/>
                <Input type="number" label="common.radius" change={this.onBlurRadius} startValue={radius}/>

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.save} disabled={this.isDisabled()}>{getText('common.edit')}</Button>
                </DefaultFooterModal>

            </>
        )
    }
}

export default EditGPSCoordinates