import React from 'react';
import {Props} from './tooltip.types'
import './tooltip.css'
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";

export default class Tooltip extends React.Component<Props> {

    render() {
        const {position, title} = this.props;
        const pos = position ? position : 'down';

        const t = renderToString(getText(title))
                    .replace(/&quot;/g, '"' )
                        .replace(/&#x27;/g, '`' );

        return (
            <div className='just-pro_tooltip-wrapper'>
                <div data-tooltip={t} data-tooltip-position={pos}>
                    {this.props.children}
                </div>
                <div className="fake-element">
                    {this.props.children}
                </div>
            </div>
        )
    }
}