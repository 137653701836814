import React from 'react'
import '../history.css'
import getPersonPhoto from '../../../../utils/getPersonPhoto'
import PersonInfo from '../../dialog/personInfo/personInfo.controller'
import moment from 'moment'
// import ReactDiffViewer, { DiffMethod }  from 'react-diff-viewer';
import {
    getEquipmentName,
    getObjectName,
    History,
    UPDATE_ORDER,
    CREATE_SUBORDER,
    CREATE_WAIT_REQUEST,
    CREATE_COMMENT,
    UNMARK_COMMENT,
    UNMARK_COMMENT_ALL,
    ADD_ORDER_FILES,
    DELETE_ORDER_FILES,
    ADD_ORDER_OFFERS,
    DELETE_ORDER_OFFERS,
    ADD_ORDER_PHOTOS,
    DELETE_ORDER_PHOTOS,
    ADD_ORDER_TAG,
    DELETE_ORDER_CC,
    ADD_ORDER_CC,
    DELETE_ORDER_TAG,
    IExecutedTime,
    ADD_ORDER_DECLINE_REASON,
    CREATE_ORDER_TASK,
    ACT_UPDATE,
    ACT_UPDATE_PHASE,
    ACT_ADD_COWORKER,
    ACT_UPDATE_COWORKER,
    ACT_DELETE_COWORKER,
    ACT_ADD_TAG,
    ACT_DELETE_TAG,
    ACT_ADD_SCAN,
    ACT_UPDATE_SCAN,
    ACT_DELETE_SCAN,
    ACT_ADD_COMMENT,
    ACT_DELETE_ORDERS,
    ACT_DELETE_REGULAR_WORKS,
    ACT_ADD_REGULAR_WORK,
    ACT_ADD_ORDER,
    TASK_ADD_TAG,
    TASK_DELETE_TAG,
    TASK_DELETE_WATCHER,
    TASK_DELETE_COWORKER,
    TASK_ADD_FILE,
    TASK_ADD_WATCHER,
    TASK_ADD_COWORKER,
    TASK_ADD_COMMENT,
    TASK_UPDATE,
    TASK_READ,
    TASK_DELETE_FILE,
    TASK_CREATE_PROBLEM,
    TASK_UPDATE_PROBLEM,
    TASK_ADD_ORDERS,
    TASK_REMOVE_ORDER,
    NEWS_ADD_COMMENT,
    NEWS_ADD_FILE,
    NEWS_DELETE_FILE,
    NEWS_UPDATE,
    NEWS_UPDATE_COMMENT,
    REGULAR_WORK_ADD_COMMENT,
    REGULAR_WORK_ADD_PHOTO,
    REMOVE_OTREQUEST_REGULAR_WORK,
    ADD_OTREQUEST_REGULAR_WORK,
    REGULAR_WORK_DECLINE,
    REGULAR_WORK_DELETE_PHOTO,
    REGULAR_WORK_UPDATE,
    KNOWLEDGE_CONTENT_UPDATE,
    ADD_KNOWLEDGE_FILE,
    DELETE_KNOWLEDGE_FILE,
    WORKORDER_UPDATE,
    Person,
    WORKORDER_ADD_ORDER,
    WORKORDER_DELETE_ORDER,
    WORKORDER_ADD_REGULAR_WORK,
    WORKORDER_DELETE_REGULAR_WORK,
    WORKORDER_CLOSE,
    WORKORDER_DELETE_COWORKER,
    WORKORDER_ADD_COWORKER,
    WORKORDER_CANCEL_REGULAR_WORKS,
    ORDER_ADD_TO_ACT,
    ORDER_DELETE_FROM_ACT,
    REGULAR_WORK_DELETE_FROM_ACT,
    REGULAR_WORK_ADD_TO_ACT,
} from '@justpro/terminal'
import {Angle} from '../../itemsList/card/card.view'
import FancyBox from '../../fancybox_new/fancybox.controller'
import File from '../../fileManager/file.preview'
import {getTaskPriority, getTaskState} from '../../../tasks/task.listItem'
import {getFullName} from '../../../../utils/names'
import {toClassName} from '../../../../utils/icons'
import getText from '../../../../localization/getText'
import {renderToString} from 'react-dom/server'
import {connect} from 'react-redux'
import {Modal} from '../../../../store/modal/modal.types'
import {openModal} from '../../../../store/modal/modal.actions'
import RenderIf from '../../../../utils/renderIf'

const getActExecutedTime = (array?: any[]) => {
    return (
        Array.isArray(array) &&
        array.map((item: any) => {
            if (item !== null) {
                const {from, to} = item

                if (from) {
                    const [h, m] = from.split('-')

                    return (
                        <>
                            {getText('UI.history.expendedTime')}{' '}
                            <b>{getText('common.from')}</b> {h}:{m}
                        </>
                    )
                }
                if (to) {
                    const [h, m] = to.split('-')

                    return (
                        <>
                            {getText('UI.history.expendedTime')}{' '}
                            <b>{getText('common.to')}</b> {h}:{m}
                        </>
                    )
                }
            }

            return <p>{getText('common.unchanged')}</p>
        })
    )
}

export const getActScanState = (scanState: 1 | 2 | null) => {
    return scanState === 1
        ? getText('acts.states.scan')
        : scanState === 2
            ? getText('acts.states.archive')
            : getText('acts.states.none')
}

const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss'

function getExecutionTime(items: any | null): JSX.Element {
    return (
        <>
            <div>
                {getText('UI.history.executedTime')} :
                {items && items.length !== 0 ? (
                    <ul>
                        {items.map((item: IExecutedTime, index: number) => {
                            if (item) {
                                return (
                                    <li key={index}>
                                        {item.from
                                            ? `${getText('common.from')} ${
                                                item.from
                                            }`
                                            : ''}{' '}
                                        {item.to
                                            ? `${getText('common.to')} ${
                                                item.to
                                            }`
                                            : ''}
                                    </li>
                                )
                            }
                            return null
                        })}
                    </ul>
                ) : (
                    getText('common.unchanged')
                )}
            </div>
        </>
    )
}

export function getHistoryBody(props: History): JSX.Element | null {
    const {was, become, action} = props

    let Component = (props: any) => (
        <DefaultHeader>{getText('UI.history.undefinedAction')}</DefaultHeader>
    )

    if (HISTORY_MAP.hasOwnProperty(action)) {
        Component = HISTORY_MAP[action]
    }

    return <Component was={was} become={become}/>
}

const DefaultHeader = (props: any) => {
    return <h6>{props.children}</h6>
}

const UndefinedAction = () => {
    return (
        <DefaultHeader>{getText('UI.history.undefinedAction')}</DefaultHeader>
    )
}

const Was = ({children}: any) => {
    return (
        <div className='info-block bdt was'>
            <Angle size={30}/>
            <h5>{getText('UI.history.was')}</h5>
            {children}
        </div>
    )
}

const Become = ({children}: any) => {
    return (
        <div className='info-block bdt become'>
            <Angle size={30}/>
            <h5>{getText('UI.history.become')}</h5>
            {children}
        </div>
    )
}

const Title = ({title}: any) => {
    return (
        <div className='task-history-state'>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<p><b>${renderToString(
                        getText('UI.history.title'),
                    )}:</b> ${title}</p>`,
                }}
            />
        </div>
    )
}

const Content = ({content}: any) => {
    return (
        <div className='task-history-state'>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<p><b>${renderToString(
                        getText('common.contentInfo'),
                    )} : </b> ${content}</p>`,
                }}
            />
        </div>
    )
}

const Draft = ({isDraft}: any) => {
    return (
        <div className='task-history-state'>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<p><b>${renderToString(
                        getText('UI.history.isDraft'),
                    )}:</b> ${
                        isDraft ? getText('common.yes') : getText('common.no')
                    }</p>`,
                }}
            />
        </div>
    )
}

const PossibleToComment = ({canCommented}: any) => {
    return (
        <div className='task-history-state'>
            <p>
                <b>
                    {renderToString(getText('UI.history.possibleToComment'))}:
                </b>{' '}
                {canCommented ? getText('common.yes') : getText('common.no')}
            </p>
        </div>
    )
}

const ProblemReason = ({description}: any) => {
    return (
        <div className='task-history-state'>
            <p>
                <b>${renderToString(getText('UI.history.problemReason'))}:</b> $
                {description}
            </p>
        </div>
    )
}

const ProblemResolve = ({solution}: any) => {
    return (
        <div className='task-history-state'>
            <p>
                <b>${renderToString(getText('UI.history.problemResolve'))}:</b>{' '}
                ${solution}
            </p>
        </div>
    )
}

const Responsible = ({responsible}: any) => {
    return (
        <div>
            {getText('common.responsible')} :{' '}
            <PersonInfo person={responsible}/>
        </div>
    )
}

const Date = ({date}: any) => {
    return (
        <p>
            <br/>
            {getText('common.date')}: <br/>
            <b>{moment(date).format("DD.MM.YYYY")}</b>
        </p>
    )
}

const Number = ({number}: any) => {
    return (
        <p>
            <br/>
            {getText('common.number')}: <br/>
            <b>{number}</b>
        </p>
    )
}

const Highpriced = ({highpriced}: any) => {
    return (
        <div>
            {getText('UI.history.highpriced')} :{' '}
            {highpriced ? getText('common.yes') : getText('common.no')}
        </div>
    )
}

const Checked = ({checked}: any) => {
    return (
        <div>
            {getText('common.checked')} :{' '}
            {checked ? getText('common.yes') : getText('common.no')}
        </div>
    )
}

const Calculating = ({calculating}: any) => {
    return (
        <div>
            {getText('UI.history.calculating')} :{' '}
            {calculating ? getText('common.yes') : getText('common.no')}
        </div>
    )
}

const ActState = ({scanState}: any) => {
    return (
        <div>
            {getText('UI.history.actState')} : {getActScanState(scanState)}
        </div>
    )
}

const ActHandler = ({handler}: any) => {
    return (
        <div>
            {getText('UI.history.handler')} :{' '}
            {handler ? getFullName(handler) : getText('common.undefined')}
        </div>
    )
}

const Phase = ({name}: any) => {
    return (
        <div>
            {getText('UI.history.phase')}: <b>{name}</b>
        </div>
    )
}

const OrderType = ({contractOrdersType}: any) => {
    return (
        <>
            <div>
                {getText('UI.history.orderType')} : {contractOrdersType.name}
            </div>
            <div>
                {getText('orderTypes.alias')}: {contractOrdersType.alias}
            </div>
        </>
    )
}

const DueDateCustomer = ({dueDateCustomer}: any) => {
    return (
        <div>
            {getText('UI.history.dueDateCustomer')} :{' '}
            {dueDateCustomer}
        </div>
    )
}

const ContactInfo = ({contactInfo}: any) => {
    return (
        <div>
            {getText('common.contactInfo')} : {contactInfo}
        </div>
    )
}

const ContentInfo = ({content}: any) => {
    return (
        <div>
            {getText('common.contentInfo')} : {content}
        </div>
    )
}

const AdditionalInfo = ({additionalInfo}: any) => {
    return (
        <div>
            {getText('common.additionalInfo')} : {additionalInfo}
        </div>
    )
}

const Warranty = ({warranty}: any) => {
    return (
        <div>
            {getText('UI.history.warranty')} :{' '}
            {warranty ? getText('common.yes') : getText('common.no')}
        </div>
    )
}

const OrderStatus = ({orderStatus}: any) => {
    return (
        <div>
            {getText('UI.history.orderStatus')} : {orderStatus?.name}
        </div>
    )
}

const OrderEquipment = ({equipmentCategory}: any) => {
    return (
        <div>
            {getText('equipmentCategories.equipmentCategory')} :{' '}
            {equipmentCategory?.name}
        </div>
    )
}

const Priority = ({priority}: any) => {
    return (
        <div>
            {getText('UI.history.priority')} : {getTaskPriority(priority)}
        </div>
    )
}

const TaskState = ({state}: any) => {
    return (
        <div>
            {getText('UI.history.taskState')} :{' '}
            {getTaskState(state?.id, {showTitle: true})}
        </div>
    )
}

const ParentTask = ({number}: any) => {
    return <div>{getText('UI.history.parentTask', {number})}</div>
}

const DueDate = ({dueDate}: any) => {
    return (
        <div>
            {getText('common.dueDate')} {moment(dueDate).format(dateTimeFormat)}
        </div>
    )
}

const WaitRequest = ({reason, createdAt, dueDateCustomer}: any) => {
    const dueDate = moment(dueDateCustomer).format("DD.MM.YYYY HH:mm:ss");
    return (
        <span className='wait_request_order_history'>
			{getText('common.fromWho')}{' '}
            <span className='date'>
				{moment(createdAt).format('DD.MM.YYYY')}
			</span>{' '}
            {getText('UI.history.byReason')} : {reason} {dueDateCustomer ? dueDate : ''}
		</span>
    )
}
const SeeAll = ({isVisible}: any) => {
    return (
        <div>
            {getText('UI.history.seeAll')} :{' '}
            <b>{isVisible ? getText('common.yes') : 'common.no'}</b>
        </div>
    )
}

const TaskRead = () => {
    return <DefaultHeader>{getText('UI.history.taskRead')}</DefaultHeader>
}
const NewsUpdate = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.newsUpdate')}</DefaultHeader>

            <>
                <Was>
                    {was && was?.title && <Title title={was.title}/>}
                    {was && was?.content && <Content content={was.content}/>}
                    {was && typeof was?.isDraft !== 'undefined' && (
                        <Draft isDraft={was.isDraft}/>
                    )}
                    {was && typeof was?.canCommented !== 'undefined' && (
                        <PossibleToComment canCommented={was.canCommented}/>
                    )}
                </Was>
                <Become>
                    {become && become?.title && <Title title={become.title}/>}
                    {become && become?.content && (
                        <Content content={become.content}/>
                    )}
                    {become && typeof become?.isDraft !== 'undefined' && (
                        <Draft isDraft={become.isDraft}/>
                    )}
                    {become && typeof become?.canCommented !== 'undefined' && (
                        <PossibleToComment canCommented={become.canCommented}/>
                    )}
                </Become>
            </>
        </>
    )
}
const TaskUpdateProblem = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.taskUpdateProblem')}
            </DefaultHeader>
            <div className='info-block bdt was'>
                <Was>
                    {was && was?.description && (
                        <ProblemReason description={was.description}/>
                    )}
                    {was && was?.solution && (
                        <ProblemResolve solution={was.solution}/>
                    )}
                </Was>
            </div>
            <div className='info-block bdt become'>
                <Become>
                    {become && become?.description && (
                        <ProblemReason description={become.description}/>
                    )}
                    {become && become?.solution && (
                        <ProblemResolve solution={become.solution}/>
                    )}
                </Become>
            </div>
        </>
    )
}
const TaskCreateProblem = () => {
    return (
        <DefaultHeader>{getText('UI.history.taskCreateProblem')}</DefaultHeader>
    )
}
const TaskUpdate = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.taskUpdate')}</DefaultHeader>

            <Was>
                {was && was.responsible !== undefined && (
                    <Responsible responsible={was.responsible}/>
                )}

                {was && was.title !== undefined && <Title title={was.title}/>}

                {was && was.content !== undefined && (
                    <Content content={was.content}/>
                )}

                {was && was.priority !== undefined && (
                    <Priority priority={was.priority}/>
                )}

                {was && was.state?.id !== undefined && (
                    <TaskState state={was.state}/>
                )}

                {was && was?.parentTask && (
                    <ParentTask number={was?.parentTask.number}/>
                )}

                {was && was?.dueDate && <DueDate dueDate={was?.dueDate}/>}
            </Was>
            <Become>
                {become && become.responsible !== undefined && (
                    <Responsible responsible={become.responsible}/>
                )}

                {become && become.title !== undefined && (
                    <Title title={become.title}/>
                )}

                {become && become.content !== undefined && (
                    <Content content={become.content}/>
                )}

                {become && become.priority !== undefined && (
                    <Priority priority={become.priority}/>
                )}

                {become && become.state?.id !== undefined && (
                    <TaskState state={become.state}/>
                )}

                {become && become?.parentTask && (
                    <ParentTask number={become?.parentTask.number}/>
                )}

                {become && become?.dueDate && (
                    <DueDate dueDate={become?.dueDate}/>
                )}
            </Become>
        </>
    )
}
const DeleteActScan = ({was}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.deleteActScan')}</DefaultHeader>
            {was?.map((scan: any, index: number) => {
                return (
                    <FancyBox
                        file={scan}
                        images={was}
                        imageMode='named'
                        key={index}
                    />
                )
            })}
        </>
    )
}
const ActUpdateScan = ({was, become}: any) => {
    const getProtectedText = (action?: boolean): JSX.Element => {
        return action !== undefined ? (
            action ? (
                getText('UI.history.scanSetProtected')
            ) : (
                getText('UI.history.scanSetProtected')
            )
        ) : (
            <></>
        )
    }

    return (
        <>
            <DefaultHeader>{getText('UI.history.actUpdateScan')}</DefaultHeader>
            <div className='info-block bdt was'>
                <Was>{getProtectedText(was?.protected)}</Was>
            </div>
            <div className='info-block bdt become'>
                <Become>{getProtectedText(become?.protected)}</Become>
            </div>
        </>
    )
}
const ActAddScan = ({become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.actAddScan')}</DefaultHeader>
            {become?.map((scan: any) => {
                return (
                    <FancyBox file={scan} images={become} imageMode='named'/>
                )
            })}
        </>
    )
}
const DeletePerson = ({was}: any, title: string) => {
    return (
        <>
            <DefaultHeader>{getText(title)}</DefaultHeader>
            {was?.map((p: Person) => (
                <p>
                    {getText('UI.history.deleted')} : <PersonInfo person={p}/>
                </p>
            ))}
        </>
    )
}
const DeleteSinglePerson = ({was}: any, title: string) => {
    return (
        <>
            <DefaultHeader>{getText(title)}</DefaultHeader>{' '}
            <p>
                {getText('UI.history.deleted')} : <PersonInfo person={was}/>
            </p>
        </>
    )
}
const DeleteOrders = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.actDeleteOrders')}
            </DefaultHeader>
            <p>
                {getText('UI.history.actDeleteOrders')} :{' '}
                {was?.reduce(
                    (acc: string, item: any) => `№${item.number} `,
                    '',
                )}
            </p>
        </>
    )
}
const DeleteRegularWorks = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.actDeleteRegularWorks')}
            </DefaultHeader>
            <p>
                {getText('UI.history.deletedRegularWorks')} :{' '}
                {was?.reduce(
                    (acc: string, item: any) => `№${item.number} `,
                    '',
                )}
            </p>
        </>
    )
}
const AddOrder = ({become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.addOrder')}</DefaultHeader>
            <p>
                {getText('UI.history.addOrder')}
                &nbsp;
                <b>{become?.number ? `№${become.number}` : ''}</b>
            </p>
        </>
    )
}

const AddRegularWork = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.addRegularWork')}
            </DefaultHeader>
            <p>
                {getText('UI.history.addRegularWork')}
                &nbsp;
                <b>{become?.number ? `№${become.number}` : ''}</b>
            </p>
        </>
    )
}

const ActUpdateCoworker = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.actUpdateCoworkers')}
            </DefaultHeader>
            {getText('UI.history.coworker')}:{' '}
            <PersonInfo person={become?.coworker}/>
            <div className='info-block bdt was'>
                <Was>{getActExecutedTime(was?.executedTime)}</Was>
            </div>
            <div className='info-block bdt become'>
                <Become>{getActExecutedTime(become?.executedTime)}</Become>
            </div>
        </>
    )
}
const ActAddCoworker = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.actAddCoworker')}
            </DefaultHeader>
            {getText('UI.history.added')} : <PersonInfo person={become}/>
        </>
    )
}
const AddCoworkers = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.taskAddCoworkers')}
            </DefaultHeader>
            {become?.map((p: Person) => (
                <p>
                    {getText('UI.history.added')} : <PersonInfo person={p}/>
                </p>
            ))}
        </>
    )
}
const TaskAddWatchers = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.taskAddWatchers')}
            </DefaultHeader>
            {become?.map((p: Person) => (
                <p>
                    {getText('UI.history.added')} : <PersonInfo person={p}/>
                </p>
            ))}
        </>
    )
}
const ActUpdate = ({was, become}: any) => {
    return (
        <>
            <Was>
                {was && was.responsible !== undefined && (
                    <Responsible responsible={was.responsible}/>
                )}

                {was?.date && <Date date={was.date}/>}

                {was?.number && (
                    <Number number={was.number}/>
                )}

                {was && was.highpriced !== undefined && (
                    <Highpriced highpriced={was.highpriced}/>
                )}

                {was && was.checked !== undefined && (
                    <Checked checked={was.checked}/>
                )}

                {was && was.calculating !== undefined && (
                    <Calculating calculating={was.calculating}/>
                )}

                {was && was.scanState !== undefined && (
                    <ActState scanState={was.scanState}/>
                )}

                {was && was.handler !== undefined && (
                    <ActHandler handler={was.handler}/>
                )}
            </Was>
            <Become>
                {become && become.responsible !== undefined && (
                    <Responsible responsible={become.responsible}/>
                )}

                {become && become.date !== undefined && (
                    <Date date={become.date}/>
                )}

                {become && become.number !== undefined && (
                    <Number number={become.number}/>
                )}

                {become && become.highpriced !== undefined && (
                    <Highpriced highpriced={become.highpriced}/>
                )}

                {become && become.checked !== undefined && (
                    <Checked checked={become.checked}/>
                )}

                {become && become.calculating !== undefined && (
                    <Calculating calculating={become.calculating}/>
                )}

                {become && become.scanState !== undefined && (
                    <ActState scanState={become.scanState}/>
                )}

                {become && become.handler !== undefined && (
                    <ActHandler handler={become.handler}/>
                )}
            </Become>
        </>
    )
}
const ActUpdatePhase = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.actUpdatePhase')}
            </DefaultHeader>

            <div className='info-block bdt was'>
                <Was>{was?.name && <Phase name={was.name}/>}</Was>
            </div>
            <div className='info-block bdt become'>
                <Become>{become?.name && <Phase name={become.name}/>}</Become>
            </div>
        </>
    )
}
const UpdateOrder = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.updateOrder')}</DefaultHeader>

            <div className='update_history_order'>
                <Was>
                    {was && was.contractOrdersType && (
                        <OrderType
                            contractOrdersType={was.contractOrdersType}
                        />
                    )}

                    {was && was.dueDateCustomer && (
                        <DueDateCustomer
                            dueDateCustomer={was.dueDateCustomer}
                        />
                    )}

                    {was && was.contactInfo && (
                        <ContactInfo contractInfo={was.contactInfo}/>
                    )}

                    {was && was.content && (
                        <ContentInfo content={was.content}/>
                    )}

                    {was && was.additionalInfo && (
                        <AdditionalInfo additionalInfo={was.additionalInfo}/>
                    )}

                    {was && was.highpriced !== undefined && (
                        <Highpriced highpriced={was.highpriced}/>
                    )}

                    {was && was.warranty !== undefined && (
                        <Warranty warranty={was.warranty}/>
                    )}

                    {was &&
                    was.executionTime !== undefined &&
                    getExecutionTime(was.executionTime)}

                    {was && was.orderStatus !== undefined && (
                        <OrderStatus orderStatus={was.orderStatus}/>
                    )}

                    {was && was.equipmentCategory !== undefined && (
                        <OrderEquipment
                            equipmentCategory={was.equipmentCategory}
                        />
                    )}
                </Was>
                <Become>
                    {become && become.contractOrdersType && (
                        <OrderType
                            contractOrdersType={become.contractOrdersType}
                        />
                    )}

                    {become && become.dueDateCustomer && (
                        <DueDateCustomer
                            dueDateCustomer={become.dueDateCustomer}
                        />
                    )}

                    {become && become.contactInfo && (
                        <ContactInfo contractInfo={become.contactInfo}/>
                    )}

                    {become && become.content && (
                        <ContentInfo content={become.content}/>
                    )}

                    {become && become.additionalInfo && (
                        <AdditionalInfo
                            additionalInfo={become.additionalInfo}
                        />
                    )}

                    {become && become.highpriced !== undefined && (
                        <Highpriced highpriced={become.highpriced}/>
                    )}

                    {become && become.warranty !== undefined && (
                        <Warranty warranty={become.warranty}/>
                    )}

                    {become &&
                    become.executionTime !== undefined &&
                    getExecutionTime(become.executionTime)}

                    {become && become.orderStatus !== undefined && (
                        <OrderStatus orderStatus={become.orderStatus}/>
                    )}

                    {become && become.equipmentCategory !== undefined && (
                        <OrderEquipment
                            equipmentCategory={become.equipmentCategory}
                        />
                    )}
                </Become>
            </div>
        </>
    )
}
const CreateWaitRequest = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.addWaitRequest')}
            </DefaultHeader>
            <WaitRequest reason={become.reason} createdAt={become.createdAt} dueDateCustomer={become.dueDateCustomer}/>
        </>
    )
}
const AddOrderDeclineReason = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.addOrderDeclineReason')}
            </DefaultHeader>
            <WaitRequest reason={become.reason} createdAt={become.createdAt}/>
        </>
    )
}
const NewsUpdateComment = ({become, was}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.changeComment')}</DefaultHeader>
            <div className='info-block bdt was'>
                <Was>
                    {typeof was?.isVisible !== 'undefined' && (
                        <SeeAll isVisible={was.isVisible}/>
                    )}
                </Was>
            </div>
            <div className='info-block bdt become'>
                <Become>
                    {typeof become?.isVisible !== 'undefined' && (
                        <SeeAll isVisible={become.isVisible}/>
                    )}
                </Become>
            </div>
        </>
    )
}
const AddComment = ({become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.addComment')}</DefaultHeader>
            <div
                className='create_history_comment'
                dangerouslySetInnerHTML={{__html: become.message}}
            />
        </>
    )
}
const UnmarkCommentAll = () => {
    return (
        <DefaultHeader>{getText('UI.history.unmarkCommentAll')}</DefaultHeader>
    )
}
const DeleteTag = () => {
    return <DefaultHeader>{getText('UI.history.deleteTag')}</DefaultHeader>
}
const AddTag = () => {
    return <DefaultHeader>{getText('UI.history.addTag')}</DefaultHeader>
}
const AddOrderCC = ({was}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.addOrderCC')}</DefaultHeader>

            {was &&
            Array.isArray(was) &&
            was?.map((item: any) => {
                return (
                    <p key={item.id}>
                        <i className={`${toClassName(item.icon)} fa-fw`}/>
                        {item.name}
                    </p>
                )
            })}
        </>
    )
}
const DeleteOrderCC = ({become}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.deleteOrderCC')}</DefaultHeader>
            {become &&
            Array.isArray(become) &&
            become?.map((item: any) => {
                return (
                    <p key={item.id}>
                        <i className={`${toClassName(item.icon)} fa-fw`}/>
                        {item.name}
                    </p>
                )
            })}
        </>
    )
}
const AddPhoto = ({become}: any) => {
    debugger
    return (
        <>
            <DefaultHeader>{getText('UI.history.addOrderPhoto')}</DefaultHeader>
            {become?.map((file: any, index: number) => {
                return (
                    <div key={index} style={{marginBottom: 5}}>
                        <FancyBox
                            file={file}
                            images={become}
                            imageMode='named'
                        />
                    </div>
                )
            })}
        </>
    )
}
const DeletePhoto = ({was}: any) => {
    return (
        <>
            <DefaultHeader>{getText('UI.history.deletePhoto')}</DefaultHeader>
            {was?.map((file: any, index: number) => {
                return (
                    <div key={index} style={{marginBottom: 5}}>
                        <FancyBox file={file} images={was} imageMode='named'/>
                    </div>
                )
            })}
        </>
    )
}
const UnmarkComment = () => {
    return <DefaultHeader>{getText('UI.history.unmarkComment')}</DefaultHeader>
}
const HistoryFile = ({was, become}: any, title: string) => {
    const files = Array.isArray(was) ? was : Array.isArray(become) ? become : []

    return (
        <>
            <DefaultHeader>{getText(`common.${title}`)}</DefaultHeader>

            {files.map((file: any, index: number) => {
                return (
                    <div key={index}>
                        <File file={file} files={was}/>
                    </div>
                )
            })}
        </>
    )
}
const CreateSuborders = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.createSuborders')}
            </DefaultHeader>

            {become?.map((item: any) => {
                return (
                    <span className='create_history_sub_order' key={item.id}>
						{item.number}
					</span>
                )
            })}
        </>
    )
}

const CreateOrderTask = ({become}: any) => {
    return (
        <DefaultHeader>
            {getText('UI.history.createOrderTask')} №{become.id}
        </DefaultHeader>
    )
}

const TaskAddOrders = ({become}: any) => {
    if (!Array.isArray(become))
        return (
            <DefaultHeader>{getText('UI.history.taskAddOrders')}</DefaultHeader>
        )
    return (
        <DefaultHeader>
            {getText('UI.history.taskAddOrders')}№
            {become.map((o) => o.number).join(', ')}
        </DefaultHeader>
    )
}

const TaskRemoveOrder = ({was}: any) => {
    return (
        <DefaultHeader>
            {getText('UI.history.taskRemoveOrder')} №{was.number}
        </DefaultHeader>
    )
}

const UpdateRegularWork = ({was, become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.updateRegularWork')}
            </DefaultHeader>

            <div className='update_history_order'>
                <Was>
                    {was && was.otRequest && (
                        <div>
                            {getText('orders.numberWO')}: &nbsp;
                            {was.otRequest?.woNumber}
                        </div>
                    )}
                    {was && was.startDate && (
                        <div>
                            {getText('regularWorks.startDate')}: &nbsp;
                            {moment(was.startDate).format('DD.MM.YYYY')}
                        </div>
                    )}
                    {was && was.controlDate && (
                        <div>
                            {getText('regularWorks.controlDate')}: &nbsp;
                            {moment(was.controlDate).format('DD.MM.YYYY')}
                        </div>
                    )}
                    {was && was.equipment?.object && (
                        <div>
                            {getText('objects.object')}: &nbsp;
                            {getObjectName(was.equipment.object)}
                        </div>
                    )}
                    {was && was.regularStatus && (
                        <div>
                            {getText('regularStatuses.regularStatus')}: &nbsp;
                            {was?.regularStatus?.name}
                        </div>
                    )}
                    {was && was.equipmentWork && (
                        <div>
                            {getText('equipmentWorks.equipmentWork')}: &nbsp;
                            {was?.equipmentWork?.name}
                        </div>
                    )}
                    {was && was.equipment && (
                        <div>
                            {getText('equipments.equipment')}: &nbsp;
                            {getEquipmentName(was.equipment)}
                        </div>
                    )}
                </Was>
                <Become>
                    {become && become.otRequest && (
                        <div>
                            {getText('orders.numberWO')}: &nbsp;
                            {was.otRequest?.woNumber}
                        </div>
                    )}
                    {become && become.startDate && (
                        <div>
                            {getText('regularWorks.startDate')}: &nbsp;
                            {moment(become.startDate).format('DD.MM.YYYY')}
                        </div>
                    )}
                    {become && become.controlDate && (
                        <div>
                            {getText('regularWorks.controlDate')}: &nbsp;
                            {moment(become.controlDate).format('DD.MM.YYYY')}
                        </div>
                    )}
                    {become && become.equipment?.object && (
                        <div>
                            {getText('objects.object')}: &nbsp;
                            {getObjectName(become.equipment.object)}
                        </div>
                    )}
                    {become && become.regularStatus && (
                        <div>
                            {getText('regularStatuses.regularStatus')}: &nbsp;
                            {become?.regularStatus?.name}
                        </div>
                    )}
                    {become && become.equipmentWork && (
                        <div>
                            {getText('equipmentWorks.equipmentWork')}: &nbsp;
                            {become?.equipmentWork?.name}
                        </div>
                    )}
                    {become && become.equipment && (
                        <div>
                            {getText('equipments.equipment')}: &nbsp;
                            {getEquipmentName(become.equipment)}
                        </div>
                    )}
                </Become>
            </div>
        </>
    )
}

const RemoveOtRequestRegularWork = ({was, become}: any) => {
    return (
        <div>
            {getText('UI.history.removeOtRequestRegularWork')}: &nbsp;
            {was?.number}
        </div>
    )
}

const AddOtRequestRegularWork = ({was, become}: any) => {
    return (
        <div>
            {getText('UI.history.addOtRequestRegularWork')}: &nbsp;
            {become?.number}
        </div>
    )
}

const KnowledgeContentWrapper = ({was, become}: any) => (
    // <ReactDiffViewer
    //     oldValue={props.was.content}
    //     newValue={props.become.content}
    //     splitView={true}
    //     compareMethod={DiffMethod.WORDS_WITH_SPACE }
    // />
    <div className='knowledge-content--wrapper'>
        <div className='knowledge-content--inner-wrapper'>
            <h5>{getText('UI.history.was')}</h5>
            <div
                className='knowledge-content--wrapper-html'
                dangerouslySetInnerHTML={{__html: was.content}}
            ></div>
        </div>
        <div className='knowledge-content--inner-wrapper'>
            <h5>{getText('UI.history.become')}</h5>
            <div
                className='knowledge-content--wrapper-html'
                dangerouslySetInnerHTML={{__html: become.content}}
            ></div>
        </div>
    </div>
)

const mapDisptachToKnowledgeContentUpdateProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props)),
})
const KnowledgeContentUpdate = connect(
    null,
    mapDisptachToKnowledgeContentUpdateProps,
)(({openModal, was, become, ...props}: any) => {
    const handleTextClick = () => {
        openModal({
            id: 'KNOWLEDGE_CONTENT',
            component: (modalProps) => (
                <KnowledgeContentWrapper
                    {...modalProps}
                    was={was}
                    become={become}
                />
            ),
            title: getText('UI.history.changeKnowledgeContent'),
            size: 'large',
        })
    }
    return (
        <div>
            <RenderIf condition={was?.name && become?.name}>
                <DefaultHeader>
                    {getText('UI.history.knowledgeUpdateName')}
                </DefaultHeader>
                <div className='info-block bdt was'>
                    <Was>
                        {was?.name && (
                            <p>
                                <br/>
                                {was?.name}
                            </p>
                        )}
                    </Was>
                </div>
                <div className='info-block bdt become'>
                    <Become>
                        {become?.name && (
                            <p>
                                <br/>
                                {become?.name}
                            </p>
                        )}
                    </Become>
                </div>
            </RenderIf>
            <RenderIf
                condition={
                    was?.published !== void 0 && become?.published !== void 0
                }
            >
                <DefaultHeader>
                    {getText('UI.history.knowledgeUpdatePublish')}
                </DefaultHeader>
                <div className='info-block bdt was'>
                    <Was>
                        <p>
                            <br/>
                            {was?.published
                                ? getText('common.yes')
                                : getText('common.no')}
                        </p>
                    </Was>
                </div>
                <div className='info-block bdt become'>
                    <Become>
                        <p>
                            <br/>
                            {become?.published
                                ? getText('common.yes')
                                : getText('common.no')}
                        </p>
                    </Become>
                </div>
            </RenderIf>
            <RenderIf condition={was?.content && become?.content}>
                <h6 className='history-underlined' onClick={handleTextClick}>
                    {getText('UI.history.changeKnowledgeContent')}
                </h6>
            </RenderIf>
        </div>
    )
})

const UpdateWorkorder = ({was, become}: any) => {
    return (
        <>
            <Was>
                {was && was.responsible !== undefined && (
                    <Responsible responsible={was.responsible}/>
                )}

                {was?.date && <Date date={was.date}/>}
            </Was>
            <Become>
                {become && become.responsible !== undefined && (
                    <Responsible responsible={become.responsible}/>
                )}

                {become && become.date !== undefined && (
                    <Date date={become.date}/>
                )}
            </Become>
        </>
    )
}

const WorkorderAddOrders = ({become}: any) => {
    if (!Array.isArray(become))
        return (
            <DefaultHeader>{getText('UI.history.addOrder')}</DefaultHeader>
        )
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.addOrder')}
            </DefaultHeader>
            <p>№{become.map((o) => o.number).join(', ')}</p>
        </>
    )
}

const WorkorderDeleteOrders = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.deletedOrders')}
            </DefaultHeader>
            <p>№{was.map((o) => o.number).join(', ')}</p>
        </>
    )
}

const WorkorderAddRegularWorks = ({become}: any) => {
    if (!Array.isArray(become))
        return (
            <DefaultHeader>{getText('UI.history.addRegularWork')}</DefaultHeader>
        )
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.addRegularWork')}
            </DefaultHeader>
            <p>№{become.map((o) => o.number).join(', ')}</p>
        </>
    )
}

const WorkorderDeleteRegularWorks = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.deletedRegularWorks')}
            </DefaultHeader>
            <p>№{was.map((o) => o.number).join(', ')}</p>
        </>
    )
}

const WorkorderCancelRegularWorks = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText('UI.history.cancelRegularWorks')}
            </DefaultHeader>
            <p>№{was.map((o) => o.number).join(', ')}</p>
        </>
    )
}

const WorkorderClose = (history: any) => {
    return (
        <DefaultHeader>
            {getText(`UI.history.workorderClose`)}
        </DefaultHeader>
    )
}

const AddToAct = ({become}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText(`UI.history.addToAct`)}
            </DefaultHeader>
            <p>
                №{become.number}
            </p>
        </>
    )
}

const DeleteFromAct = ({was}: any) => {
    return (
        <>
            <DefaultHeader>
                {getText(`UI.history.deleteFromAct`)}
            </DefaultHeader>
            <p>
                №{was.number}
            </p>
        </>
    )
}

const HISTORY_MAP = {
    [ADD_KNOWLEDGE_FILE]: (props) => HistoryFile(props, 'addFile'),
    [DELETE_KNOWLEDGE_FILE]: (props) => HistoryFile(props, 'deleteFile'),
    [KNOWLEDGE_CONTENT_UPDATE]: KnowledgeContentUpdate,
    [TASK_READ]: TaskRead,
    [NEWS_UPDATE]: NewsUpdate,
    [TASK_UPDATE_PROBLEM]: TaskUpdateProblem,
    [TASK_CREATE_PROBLEM]: TaskCreateProblem,
    [TASK_UPDATE]: TaskUpdate,
    [ACT_DELETE_SCAN]: DeleteActScan,
    [ACT_UPDATE_SCAN]: ActUpdateScan,
    [ACT_ADD_SCAN]: ActAddScan,
    [TASK_DELETE_WATCHER]: (props: any) =>
            DeletePerson(props, 'UI.history.deleteWatcher'),
    [TASK_DELETE_COWORKER]: (props: any) =>
            DeletePerson(props, 'UI.history.deleteCoworker'),
    [ACT_DELETE_COWORKER]: (props: any) =>
            DeleteSinglePerson(props, 'UI.history.deleteCoworker'),
    [ACT_DELETE_REGULAR_WORKS]: DeleteOrders,
    [ACT_ADD_REGULAR_WORK]: AddRegularWork,
    [ACT_DELETE_ORDERS]: DeleteRegularWorks,
    [ACT_ADD_ORDER]: AddOrder,
    [ACT_DELETE_ORDERS]: DeleteRegularWorks,
    [ACT_UPDATE_COWORKER]: ActUpdateCoworker,
    [ACT_ADD_COWORKER]: ActAddCoworker,
    [TASK_ADD_COWORKER]: AddCoworkers,
    [TASK_ADD_WATCHER]: TaskAddWatchers,
    [ACT_UPDATE]: ActUpdate,
    [ACT_UPDATE_PHASE]: ActUpdatePhase,
    [UPDATE_ORDER]: UpdateOrder,
    [REGULAR_WORK_UPDATE]: UpdateRegularWork,
    [CREATE_WAIT_REQUEST]: CreateWaitRequest,
    [ADD_ORDER_DECLINE_REASON]: AddOrderDeclineReason,
    [REGULAR_WORK_DECLINE]: AddOrderDeclineReason,
    [NEWS_UPDATE_COMMENT]: NewsUpdateComment,
    [CREATE_COMMENT]: AddComment,
    [ACT_ADD_COMMENT]: AddComment,
    [TASK_ADD_COMMENT]: AddComment,
    [NEWS_ADD_COMMENT]: AddComment,
    [REGULAR_WORK_ADD_COMMENT]: AddComment,
    [UNMARK_COMMENT_ALL]: UnmarkCommentAll,
    [DELETE_ORDER_TAG]: DeleteTag,
    [ACT_DELETE_TAG]: DeleteTag,
    [TASK_DELETE_TAG]: DeleteTag,
    [ACT_ADD_TAG]: AddTag,
    [ADD_ORDER_TAG]: AddTag,
    [TASK_ADD_TAG]: AddTag,
    [ADD_ORDER_CC]: AddOrderCC,
    [DELETE_ORDER_CC]: DeleteOrderCC,
    [ADD_ORDER_PHOTOS]: AddPhoto,
    [DELETE_ORDER_PHOTOS]: DeletePhoto,
    [REGULAR_WORK_ADD_PHOTO]: AddPhoto,
    [REGULAR_WORK_DELETE_PHOTO]: DeletePhoto,
    [UNMARK_COMMENT]: UnmarkComment,
    [DELETE_ORDER_OFFERS]: (props: any) =>
            HistoryFile(props, 'deleteOrderOffers'),
    [ADD_ORDER_OFFERS]: (props: any) => HistoryFile(props, 'addOrderOffers'),
    [TASK_DELETE_FILE]: (props: any) => HistoryFile(props, 'deleteFile'),
    [DELETE_ORDER_FILES]: (props: any) => HistoryFile(props, 'deleteFile'),
    [NEWS_DELETE_FILE]: (props: any) => HistoryFile(props, 'deleteFile'),
    [TASK_ADD_FILE]: (props: any) => HistoryFile(props, 'addFile'),
    [ADD_ORDER_FILES]: (props: any) => HistoryFile(props, 'addFile'),
    [NEWS_ADD_FILE]: (props: any) => HistoryFile(props, 'addFile'),
    [CREATE_SUBORDER]: CreateSuborders,
    [CREATE_ORDER_TASK]: CreateOrderTask,
    [TASK_ADD_ORDERS]: TaskAddOrders,
    [TASK_REMOVE_ORDER]: TaskRemoveOrder,
    [REMOVE_OTREQUEST_REGULAR_WORK]: RemoveOtRequestRegularWork,
    [ADD_OTREQUEST_REGULAR_WORK]: AddOtRequestRegularWork,
    [WORKORDER_UPDATE]: UpdateWorkorder,
    [WORKORDER_ADD_ORDER]: WorkorderAddOrders,
    [WORKORDER_DELETE_ORDER]: WorkorderDeleteOrders,
    [WORKORDER_ADD_REGULAR_WORK]: WorkorderAddRegularWorks,
    [WORKORDER_DELETE_REGULAR_WORK]: WorkorderDeleteRegularWorks,
    [WORKORDER_CANCEL_REGULAR_WORKS]: WorkorderCancelRegularWorks,
    [WORKORDER_CLOSE]: WorkorderClose,
    [WORKORDER_ADD_COWORKER]: AddCoworkers,
    [WORKORDER_DELETE_COWORKER]: (props: any) =>
            DeletePerson(props, 'UI.history.deleteCoworker'),
    [ORDER_ADD_TO_ACT]: AddToAct,
    [REGULAR_WORK_ADD_TO_ACT]: AddToAct,
    [ORDER_DELETE_FROM_ACT]: DeleteFromAct,
    [REGULAR_WORK_DELETE_FROM_ACT]: DeleteFromAct
}

export default (props: History) => {
    const {createdAt, user} = props

    return (
        <div className='just-pro__order-history'>
            <div className='just-pro__order-history-photo'>
                <img src={getPersonPhoto(user?.avatar)} alt=''/>
            </div>

            <div className='just-pro__order-history-content'>
                <div className='just-pro__order-history-text'>
					<span className='just-pro__order-history-name'>
						<PersonInfo person={user?.person}/>
					</span>
                    <span className='just-pro__order-history-time'>
						{' '}
                        {getText('common.fromWho')}{' '}
                        {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
					</span>
                </div>
                <div className='just-pro__order-history-body'>
                    {getHistoryBody(props)}
                </div>
            </div>
        </div>
    )
}
