import React from 'react';
import {useDropzone} from "react-dropzone";
import Button from "../../button/button";
import {MAX_FILE_SIZE} from "@justpro/terminal";


interface Props {
    setFile(files:File[], rejectedFiles:File[]) :void
    name : string | JSX.Element
    accept? : string[]
    children? : JSX.Element
    maxSize?: number
}

const ButtonDropzone = (props:Props) => {
    const onDrop = (acceptedFiles:File[], rejectedFiles:File[]) => {
        props.setFile(acceptedFiles, rejectedFiles)
    };


    let {getRootProps, getInputProps} = useDropzone({
        accept : props.accept,
        minSize : 0,
        maxSize : props.maxSize || MAX_FILE_SIZE,
        onDrop,
        noKeyboard : true
    });


    return (
        <>
            <span {...getRootProps()}>
                <input {...getInputProps()}/>
                <Button className="btn-primary">{props.name}</Button>
            </span>
        </>

    )
};

export default ButtonDropzone;
