import * as api from '@justpro/terminal'
import {orderActions} from "./orders.reducer";
import {OrdersFilterParams} from "@justpro/terminal/dist/orders";
import checkError from "../../utils/checkError";
import {UpdateOrderList} from './orders.types'
import {OrderListItem} from "@justpro/terminal";

export const getOrders = (data:Partial<OrdersFilterParams>, action:'update' | 'get') => async (dispatch:Function) => {
    try {
        const {list, count, haveNewComments} = await api.getOrders(data);
        dispatch({
            type : orderActions.GET_ORDERS,
            payload : {
                list,
                count,
                haveNewComments,
                action
            },
        })
    }catch (e) {
        checkError(e)
    }
};

export const updateOrderList = (orderId:number, data:UpdateOrderList) => (dispatch:Function) => {
    dispatch({
        type : orderActions.UPDATE_ORDER_LIST,
        payload : {
            orderId,
            data
        }
    })
};

// export const afterCreateOrder = (order:OrderListItem) => (dispatch:Function) => {
//     dispatch({
//         type : orderActions.CREATE_ORDER,
//         payload : {
//             order
//         }
//     })
// };



