import React from 'react';
import {
    createEquipmentType,
    EquipmentType,
    filterParams,
    getEquipmentTypes, ModulesResponse
} from "@justpro/terminal";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {getEquipmentCategoriesList} from "../../../utils/functions";
import BaseReferenceController from "../baseReference/baseReference.controller";
import EquipmentTypeController from "./equipmentType.controller";
import EquipmentTypeCreate from "./equipmentType.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class EquipmentTypesController extends React.Component<Props> {

    getCheckboxesMap = async () => {


        if(this.props.rights?.['references.equipmentCategories']?.read) {
            const equipmentCategories = await getEquipmentCategoriesList();

            return {
                [filterParams.EQUIPMENT_CATEGORIES] : {
                    name : 'equipmentCategories.equipmentCategory',
                    children : equipmentCategories.map( ({id, name}) => ({id, name}) )
                }
            };
        }


        return {}
    };

    renderItem = (item:EquipmentType) => {
        return (
            <>{item.name}</>
        )
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.equipmentTypes'];
        return <BaseReferenceController
            Detail={EquipmentTypeController}
            getList={getEquipmentTypes}
            listItem={{
                render : this.renderItem,
            }}
            referenceName="equipmentTypes.moduleName"
            create={{
                Component : EquipmentTypeCreate,
                title : "equipmentTypes.addItem",
                handler: createEquipmentType
            }}
            filter={{
                getCheckboxesMap : this.getCheckboxesMap,
                filterName : filterNames.EQUIPMENT_TYPES,
            }}
            moduleRights={rights}
        />

    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(EquipmentTypesController)