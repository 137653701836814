import React from 'react'
import './step.css'
import { getObjectName } from '@justpro/terminal'
import moment, { Moment } from 'moment'
import { ChangeMultiProps, Option } from '../../../select/select.types'
import { ChecklistEquipmentCategory, ChecklistObject } from './first'
import Button from '../../../button/button'
import InlineCalendar from '../../../calendar/views/inline.controller'
import Select from '../../../select/select'
import { getPersonsList } from '../../../../../utils/functions'
import MultiSelect from '../../../select/multiSelect'
import {
	Checklist,
	ChecklistEquipmentCategory as CreateChecklistEquipmentCategory,
	createWorkorderChecklist,
	format,
} from '@justpro/terminal'
import checkError from '../../../../../utils/checkError'
import getText from '../../../../../localization/getText'
import { ChecklistObject as ChecklistObjectComponent } from '../checklistObject'

interface Props {
	objects: ChecklistObject[]
	defaultExecutor: Option
	defaultCoWorkers?: Option[]
	defaultDate: Moment
	createWorkOrders(data: Checklist[]): void
	disabled?: boolean
}

interface State {
	objects: ChecklistObject[]
}

interface UpdateEquipmentChecklist {
	date?: Moment
	coWorkers?: Option[]
	executor?: Option
}

class ObjectListItem extends React.Component<Props, State> {
	state: State = {
		objects: [],
	}

	componentDidMount() {
		const { objects, defaultDate, defaultExecutor, defaultCoWorkers } =
			this.props
		const result = objects.map((item) => {
			return {
				...item,
				equipmentCategories: item.equipmentCategories.reduce(
					(acc: ChecklistEquipmentCategory[], eq) => {
						const exists = acc.find(
							(checklistEq) =>
								checklistEq.equipmentCategory.id ===
								eq.equipmentCategory.id,
						)
						if (exists) {
							return acc
						}

						return [
							...acc,
							{
								...eq,
								date: defaultDate,
								executor: defaultExecutor,
								coworkers: defaultCoWorkers,
							},
						]
					},
					[],
				),
			}
		})

		this.setState(() => ({ objects: result }))

		console.log({ result })
	}

	update = (
		objectId: number,
		equipmentId: number,
		data: UpdateEquipmentChecklist,
	) => {
		this.setState((prevState) => {
			const result = prevState.objects.map((checklistItem) => {
				if (checklistItem.object.id === objectId) {
					const equipmentCategories =
						checklistItem.equipmentCategories.map((eq) => {
							if (eq.equipmentCategory.id === equipmentId) {
								return {
									...eq,
									coworkers: data.coWorkers
										? data.coWorkers
										: eq.coworkers,
									executor: data.executor
										? data.executor
										: eq.executor,
									date: data.date ? data.date : eq.date,
								}
							}

							return eq
						})

					return {
						...checklistItem,
						equipmentCategories,
					}
				}

				return checklistItem
			})

			return {
				...prevState,
				objects: result,
			}
		})
	}

	changeCoWorkers = async (
		props: ChangeMultiProps,
		objectId: number,
		equipmentId: number,
	) => {
		const object = this.state.objects.find(
			(item) => item.object.id === objectId,
		)

		if (object) {
			const equipment = object.equipmentCategories.find(
				(eq) => eq.equipmentCategory.id === equipmentId,
			)

			if (equipment) {
				let cw: Option[] = equipment.coworkers
					? equipment.coworkers
					: []

				switch (props.type) {
					case 'select-option':
						cw = [...cw, props.value]
						break
					case 'remove-value':
						cw = cw.filter(
							(item) => item.value !== props.value.value,
						)
						break
				}

				await this.update(objectId, equipmentId, { coWorkers: cw })

				return true
			}
		}

		return false
	}

	removeEquipmentCategory = (objectId: number, equipmentId: number) => {
		const { objects } = this.state

		console.log({ objects, objectId, equipmentId })

		this.setState((prevState) => ({
			objects: prevState.objects
				.map((item) => {
					if (item?.object?.id === objectId) {
						const categories = item.equipmentCategories.filter(
							(eq) => {
								return eq?.equipmentCategory?.id !== equipmentId
							},
						)

						if (categories.length > 0) {
							return {
								...item,
								equipmentCategories: categories,
							}
						} else {
							return undefined
						}
					}

					return item
				})
				.filter((item) => !!item),
		}))
	}

	renderItem = (item: ChecklistObject) => {
		return (
			<ChecklistObjectComponent
				item={item}
				update={this.update.bind(this)}
				changeCoWorkers={this.changeCoWorkers.bind(this)}
				removeEquipmentCategory={this.removeEquipmentCategory.bind(
					this,
				)}
			/>
		)
	}

	createChecklist = async () => {
		console.log({ createdData: this.state.objects })

		const data = this.state.objects.reduce((acc: Checklist[], item) => {
			return [
				...acc,
				{
					objectId: item.object.id,
					equipmentCategories: item.equipmentCategories.reduce(
						(acc: CreateChecklistEquipmentCategory[], eqc) => {
							return [
								...acc,
								{
									coworkersId: eqc.coworkers
										? eqc.coworkers.map(
												({ value }) => value,
										  )
										: [],
									equipmentCategoryId:
										eqc.equipmentCategory.id,
									date: eqc.date
										? eqc.date.format(format.date)
										: 'invalid',
									responsibleId: eqc.executor
										? eqc.executor.value
										: undefined,
								},
							]
						},
						[],
					),
				},
			]
		}, [])

		this.props.createWorkOrders(data)
	}

	render() {
		const { objects } = this.state

		return (
			<>
				<div className='checklistObjects'>
					<div>
						{objects.map((item) => {
							return this.renderItem(item)
						})}
					</div>
					<div className='buttons'>
						<Button
							className='btn-success'
							onClick={this.createChecklist}
							disabled={this.props.disabled}
						>
							{getText('workorders.createChecklist')}
						</Button>
					</div>
				</div>
			</>
		)
	}
}

export default ObjectListItem
