import React, {PureComponent, FC, useEffect, useState} from 'react'
import {Person} from '@justpro/terminal'
import {getFullName, getObjectName} from '../../utils/names'
import getText from "../../localization/getText";
import WithModuleDetail from "../UI/dialog/withModuleDetail/withModuleDetail.controller";
import RenderIf from "../../utils/renderIf";
import Tooltip from "../UI/tooltip/tooltip.controller";
import OrderDetail from "../orders/detail/order.detail";
import {connect} from 'react-redux';
import checkError from '../../utils/checkError';
import {removeWorkorderOrders, getOrder} from "@justpro/terminal";
import {determineArrowDirection} from './calendarUtils';
import moment from "moment";
import {InfoWindow} from 'react-google-maps';
import {Provider} from "react-redux";
import store from "../../store";
import {
    updateTouched,
    setIsWoCreatorOpen,
    setOpenedObject,
    setWoArray,
    pushToWoArray,
    removeFromWoArray
} from '../../store/map/map.actions';
import {IObjectInnerProps} from '../maps/markers/markerInnerWindows/innerWindow.types';

interface PersonCardProps {
    person: Person
    selectedExecutorId: number
    changeSelectedExecutorId: (id: number) => void
}

interface ExpandingWrapperProps {
    children: any
    isOpen: boolean
    isLeft?: boolean
}

export const ArrowButton: FC<any> = ({onClick, isOpen, isLeft = false}) => {
    return (<div className="toggleIsOpenButton">
        <span
            onClick={onClick}
            style={determineArrowDirection(isLeft, isOpen)}
        >
            ➝
        </span>
    </div>)
}

export const ExpandingWrapper: FC<ExpandingWrapperProps> = ({isOpen, children, isLeft = false}) => {

    const [className, setClassName] = useState('expanding_wrapper--left')

    useEffect(() => {
        setClassName(determineClassName())
    }, [isOpen])

    const determineClassName = () => {
        if (!isOpen && isLeft) {
            return `expanding_wrapper--left`
        }
        if (!isOpen && !isLeft) {
            return `expanding_wrapper--right`
        }
        if (isOpen && isLeft) {
            return `expanding_wrapper--left expanding_wrapper__expanded`
        }
        if (isOpen && !isLeft) {
            return `expanding_wrapper--right expanding_wrapper__expanded`
        }
    }

    return (
        <div className={className}>
            {children}
        </div>
    )
}

export const PersonCard: FC<PersonCardProps> = ({person, selectedExecutorId, changeSelectedExecutorId}) => {
    return (
        <div
            key={person.id}
            onClick={() => changeSelectedExecutorId(person.id)}
            className={`calendar_person-card ${person.id === selectedExecutorId ? 'selected' : ''}`}
        >
            <img className='calendar_person-card--img' src={person.avatar}/>
            <p className='calendar_person-card--name'>{getFullName(person)}</p>
        </div>
    )
}

class ObjectInfoWindow extends PureComponent<IObjectInnerProps> {
    state = {
        orders: [],
        openedObject: null
    }

    componentDidMount() {
        const el = document.getElementById('maps')
        el.addEventListener('click', this.closeOnMapClick, true)
        this.setState({
            openedObject: this.props.object,
            orders: this.props.object?.orders?.sort((a, b) => b.hot - a.hot).sort((a, b) => b.expired - a.expired)
        })
    }

    componentWillUnmount() {
        const el = document.getElementById('maps')
        el?.removeEventListener('click', this.closeOnMapClick)
    }

    closeOnMapClick = (e) => {
        if (!e.target.closest('.unclosable')) {
            this.props.discardClicked()
            this.setState({
                openedObject: null
            })
        }
    }

    deleteOrder = async (woId, orderId) => {
        try {
            const deleted = await removeWorkorderOrders(woId, [orderId]);
            console.log(deleted)
            const deletedOrder = await getOrder(orderId)
            const i = this.state.orders?.findIndex(order => order.id == deletedOrder.id)
            if (this.state.orders[i]) {
                this.setState((prevState: any) => ({
                    ...prevState.orders[i] = deletedOrder
                }))
            }
        } catch (e) {
            checkError(e)
        }
    };

    handleWoArray = (order, event) => {
        const {removeFromWoArray, pushToWoArray, woArray} = this.props;
        if (event?.target?.checked) {
            pushToWoArray(order);
        } else {
            removeFromWoArray(woArray.findIndex((el) => el.id == order.id))
        }
    }

    render() {
        const {
            object,
            position,
            discardClicked,
            woArray,
            ordersCanWorkorderStatus
        } = this.props;
        const {orders} = this.state;
        return (
            <InfoWindow
                position={{lat: position?.lat, lng: position?.lng}}
                onCloseClick={discardClicked}
            >
                <div className='unclosable'>
                    <h2>{getObjectName(object)}</h2>
                    {orders?.map((order, i) => {
                        const isExist = Array.isArray(woArray) && woArray.find((o, i) => o.id == order.id)
                        return (
                            <div key={i} className={
                                `object-inner__order-wrapper ${
                                    !order.workorders?.[0]?.id || ordersCanWorkorderStatus === order.orderStatus.id ?
                                        'object-inner__order-wrapper__full' : ''}`
                            }>
                                {ordersCanWorkorderStatus == order.orderStatus.id &&
                                <input
                                    type="checkbox"
                                    checked={!!isExist}
                                    id={order.id.toString()}
                                    onChange={this.handleWoArray.bind(this, order)}
                                />}
                                <WithModuleDetail
                                    element={
                                        <>
                                            <RenderIf condition={order.expired}>
                                                <Tooltip title="UI.itemList.expiredTime">
                                                    <i className="fa fa-fw fa-fire" style={{color: 'red'}}/>
                                                </Tooltip>
                                            </RenderIf>
                                            <RenderIf condition={order.hot}>
                                                <Tooltip title="UI.itemList.expiredTime">
                                                    <i className="fa fa-fw fa-fire" style={{color: 'orange'}}/>
                                                </Tooltip>
                                            </RenderIf>
                                            <span
                                                className="objectInner_label"
                                                style={{
                                                    color: order.contractOrdersType?.color
                                                }}
                                            >
                                                {order?.number}
                                            </span>
                                            <span
                                                className="objectInner_text"
                                            >
                                                {order?.content}
                                            </span>
                                        </>
                                    }
                                    modal={{
                                        id: 'orderListItem',
                                        component: () => (
                                            <OrderDetail id={order.id}/>
                                        ),
                                        title: getText('orders.order'),
                                    }}
                                />
                                <RenderIf
                                    condition={ordersCanWorkorderStatus != order.orderStatus.id && order.workorders?.[0]?.id}>
                                    <>
                                        <Tooltip title="UI.itemList.deleteOrder">
                                            <i onClick={() => this.deleteOrder(order.workorders?.[0]?.id, order.id)}
                                               className="fa fa-times-circle" style={{color: 'red'}}/>
                                        </Tooltip>
                                        <span
                                            className="objectInner_responsible">{order?.workorders?.[0]?.responsible && getFullName(order?.workorders?.[0]?.responsible)}</span>
                                        <span>{order?.workorders?.[0]?.date ? moment(order?.workorders?.[0]?.date).format("DD/MM") : ''}</span>
                                    </>
                                </RenderIf>
                            </div>
                        )
                    })}
                </div>
            </InfoWindow>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        touched: state?.maps?.touched,
        isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
        woArray: state?.maps?.woArray,
        ordersCanWorkorderStatus: state.application.settings.ordersCanWorkorderStatus
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        setTouched: (touched) => {
            dispatch(updateTouched(touched));
        },
        setIsWoCreatorOpen: (isOpen) => {
            dispatch(setIsWoCreatorOpen(isOpen));
        },
        setOpenedObject: (object) => {
            dispatch(setOpenedObject(object));
        },
        setWoArray: (object) => {
            dispatch(setWoArray(object));
        },
        pushToWoArray: (el) => {
            dispatch(pushToWoArray(el))
        },
        removeFromWoArray: (index) => {
            dispatch(removeFromWoArray(index));
        }
    };
};

const ConnectedInfoWindow = connect(mapStateToProps, mapDispatchToProps)(ObjectInfoWindow)
export const ProvideredConnectedInfoWindow = (props) => (
    <Provider store={store}>
        <ConnectedInfoWindow {...props} />
    </Provider>
)
export default ProvideredConnectedInfoWindow
