import {AnyAction} from "redux";
import {initialState} from './application.types'

export const applicationActionTypes = {
    GET_USER_ME : 'GET_AVAILABLE_MODULES',
    GET_NOTIFICATIONS : 'GET_NOTIFICATIONS',
    GET_SETTINGS : 'GET_SETTINGS',
    RESET_USER_RIGHTS : 'RESET_USER_RIGHTS',
    CHANGE_LOCALE : 'CHANGE_LOCALE',
};

export default (state = initialState, action:AnyAction) => {

    const {type, ...rest} = action;
    switch (type) {
        case applicationActionTypes.GET_USER_ME :
            return Object.assign({}, state, {rights: rest.rights, me : rest.me});
        case applicationActionTypes.GET_SETTINGS :
            return Object.assign({}, state, {settings: rest.settings});
        case applicationActionTypes.CHANGE_LOCALE :
            return Object.assign({}, state, {locale: rest.locale});
        case applicationActionTypes.GET_NOTIFICATIONS: {
            return Object.assign({}, state, { comments: rest.comments, notifications: rest.notifications, files: rest.files })
        }
        case applicationActionTypes.RESET_USER_RIGHTS :
            return initialState;
        default :
            return state;
    }
};
