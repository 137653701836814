import React, { ChangeEvent } from 'react'
import {
	Master,
	getMasters,
	getMaster,
} from '@justpro/terminal'
import { connect } from 'react-redux'
import { MapState } from '../../../mainNav/mainNav.types'
import './addMaster.css'
import Spinner from '../../spinner/spinner.controller'
import ItemList from '../../itemsList/itemList.controller'
import RenderIf from '../../../../utils/renderIf'
import getText from '../../../../localization/getText'
import Button from '../../button/button'
import {getFullName} from "../../../../utils/names";

interface Props {
	hide(): void
	addMaster(masterId: number): void
	areaId?: number
	cityId?: number
	equipmentCategoryId?: number
	excludeMastersId?: number[]
	me?: any
	settings?: any
}

interface State {
	isLoading: boolean
	masters: Master[]
	selectedMaster: Master | null,
}

class AddMasterController extends React.Component<Props, State> {
	state: State = {
		isLoading: false,
		masters: [],
		selectedMaster: null,
	}

	async componentDidMount() {
		const { excludeMastersId = [] } = this.props;
		this.setState({
			isLoading: true,
		})
		const masters = await getMasters({ 
			active: ['1'],
			areasId: [this.props.areaId, 0],
			citiesId: [this.props.cityId, 0],
			equipmentCategoriesId: [this.props.equipmentCategoryId, 0],
			limit: 10000,
		})
		this.setState({
			masters: masters.filter((m) => !excludeMastersId.includes(m.id)),
			isLoading: false,
		})
	}

	selectMaster = async (masterId: number) => {
		this.setState({
			isLoading: true,
		})
		const master = await getMaster(masterId);
		this.setState({
			selectedMaster: master,
			isLoading: false,
		})
	}

	addMaster = () => {
		this.props.addMaster(this.state?.selectedMaster?.id);
		this.props.hide();
	}

	renderItem = (master: Master) => {
		return (
			<div
				className={`list-item ${master.id === this.state?.selectedMaster?.id ? 'active' : ''}`}
				onClick={this.selectMaster.bind(this, master.id)}
			>
				{getFullName(master.person)}
				&nbsp;
				<b>({master.openedOrdersCount})</b>
			</div>
		)
	};

	render() {
		const { masters, selectedMaster } = this.state

		return (
			<>
				<div id='toolbar' />
				<Spinner loading={this.state.isLoading} />
				<div className="add-master-wrapper">
					<div className="add-master-panel">
						<ItemList
							loading={this.state.isLoading}
							renderItem={this.renderItem}
							list={masters}
						/>
					</div>
					<div className="add-master-panel master-details">
						<RenderIf condition={selectedMaster}>
							<p>
								<img className='add-master-avatar' src={selectedMaster?.person?.avatar} />
								{selectedMaster && getFullName(selectedMaster?.person)}
							</p>
							<p>
								<b>{getText('specializations.specialization')}: </b>
								{selectedMaster?.specializations?.map?.((s) => s.name)?.join?.(', ')}
							</p>
							<p>
								<b>{getText('masters.note')}: </b>
								{selectedMaster?.description}
							</p>
							<p>
								<b>{getText('persons.email')}: </b>
								{selectedMaster?.person?.emails?.map((m) => m.value)?.join(', ')}
							</p>
							<p>
								<b>{getText('persons.phone')}: </b>
								{selectedMaster?.person?.phones?.map((p) => p.value)?.join(', ')}
							</p>
							<p>
								<b>{getText('persons.messenger')}: </b>
								{selectedMaster?.person?.messengers?.map((m) => m.value)?.join(', ')}
							</p>
							<table className='master-table'>
								<tr>
									<td>
										<b>{getText('masters.inStaff')}: </b>
									</td>
									<td>
										<b>{getText('masters.hasStore')}: </b>
									</td>
								</tr>
								<tr>
									<td className='master-table-cell'>
										{selectedMaster?.inStaff ? getText('common.yes') : getText('common.no')}
									</td>
									<td className='master-table-cell'>
										{selectedMaster?.hasStore ? getText('common.yes') : getText('common.no')}
									</td>
								</tr>
							</table>
							<p>
								<b>{getText('masters.paymentCards')}: </b>
								{selectedMaster?.paymentCards?.map(({ number }) => number)?.join(', ')}
							</p>
							<Button onClick={this.addMaster} 
									className="open-master-popup btn-success">
									{getText('common.add')}
							</Button>
						</RenderIf>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state: MapState) => ({
	me: state.application.me,
	settings: state.application.settings,
})

const mapDispatch = (d: Function) => ({
})

export default connect(
	mapStateToProps,
	mapDispatch,
)(AddMasterController)
