import React, {useState} from 'react';
import {ListItem, ListItemView, SubItemWrapper} from "./moduleListItem.view";
import {Order, OrderListItem as IOrderListItem} from "@justpro/terminal";
import ListIcons, {ListIconProps} from "../icons/icons.controller";
import Input from "../../input/text";
import {Angle} from "../card/card.view";
import WithModuleDetail from "../../dialog/withModuleDetail/withModuleDetail.controller";
import Tooltip from "../../tooltip/tooltip.controller";
import Caret from "../../caret/caret";


// interface Props{
//     // id : number
//     item : any
//     content?: JSX.Element
//
//     //общие
//     withCheckbox? : boolean
//
//     withActive? : {
//         activeItemId? : number
//     }
//
//     withIcons? : {
//         icons : ListIconProps
//     }
//
//     withAngle? : {
//         color : string
//         size? : number
//     }
//
//     withNumber? : {
//         number : string
//         date? : string
//     }
//
//     withDelete? : { // удаление из списка
//         deleteHandler(id:number) : void
//     }
//     // activeOrderId? : number
//
//     clickHandler?(id:number) : void
//
//     withBorderBottom?: boolean
//
//     withIncludes?: {
//         handleInclude(id:number) : void
//     }
//
//     withModuleDetail? : {
//         component : JSX.Element
//         title : string
//     } // передавать jsx
//
//
//     //заявки
//
//     withAct? : {
//         onChangeActNumber(order:IOrderListItem | Order, value:string) : void
//         onChangePartial(order:IOrderListItem | Order, value : boolean) : void
//         checked : boolean
//     }
//
//     suborderHelper? : { //
//         subordersCount : string
//         handler?(order: Order) : void
//         getSuborders(id:number) : void
//         loading : boolean
//         subOrderList : Order[],
//     }
//
//     workOrdersHelper? : {
//         changeHandler(order:IOrderListItem | Order) : void
//         checked : boolean
//         show : boolean
//     }
// }
//
// export const ModuleListItemNext = ({
//     item,
//     withAct,
//     clickHandler,
//     withActive,
//     withAngle,
//     withIcons,
//     withNumber,
//     withDelete,
//     withIncludes,
//     withBorderBottom,
//     workOrdersHelper,
//     withCheckbox,
//     withModuleDetail,
//     suborderHelper,
//     content
//
// }:Props) => {
//
//     const [isOpen, setIsOpen] = useState(false);
//
//
//     const onListItemClick = clickHandler ? clickHandler.bind(null, item.id) : undefined;
//
//     const stopPropagation = (e:React.MouseEvent) => {e.stopPropagation();};
//
//     return (
//         <>
//             <ListItemView
//                 onClick={onListItemClick}
//                 isActive={withActive ? withActive.activeItemId === item.id : undefined}
//             >
//
//                 {withAct !== undefined && (
//                     <div className="act-zone" onClick={stopPropagation}>
//                         <span className="text">Акт №</span>
//                         <Input
//                             change={(value:string) => withAct.onChangeActNumber.bind(null, item, value )}
//                         />
//                         <div className="partial">
//                             <input
//                                 type="checkbox"
//                                 checked={withAct.checked}
//                                 onChange={withAct.onChangePartial.bind(null, item ,!withAct.checked)}
//                                 id={"partial" + item.id}
//                             />
//                             <label
//                                 htmlFor={"partial" + item.id}>част.</label>
//                         </div>
//                     </div>
//                 )}
//
//                 <div className="body">
//                     {withAngle !== undefined && (
//                         <Angle size={withAngle.size} color={withAngle.color}/>
//                     )}
//
//                     {withIcons !== undefined && (
//                         <div className="icon-zone">
//                             <ListIcons
//                                 {...withIcons.icons}
//                             />
//                         </div>
//                     )}
//
//                     <div className="content-text">
//                         {content}
//                     </div>
//                 </div>
//
//
//                 {withNumber !== undefined && (
//                     <div className="number">
//                         <b>{withNumber.number}</b>
//                         {withNumber.date && <b>{withNumber.date}</b>}
//                     </div>
//                 )}
//
//                 {!!(workOrdersHelper) && workOrdersHelper.show  && (
//                     <div className="workOrder-zone">
//                         <input type="checkbox"
//                                onChange={workOrdersHelper.changeHandler.bind(null, item)}
//                                checked={workOrdersHelper.checked}
//                                onClick={stopPropagation}
//                         />
//                     </div>
//                 )}
//
//
//                 <div className="helpers">
//                     {withIncludes !== undefined && (
//                         <div>
//                             <i className="fa fa-arrow-up include" onClick={(e:React.MouseEvent) => {
//                                 stopPropagation(e);
//                                 withIncludes.handleInclude(item.id)}
//                             }/>
//                         </div>
//                     )}
//
//                     {withModuleDetail !== undefined && (
//                         <div>
//                             <WithModuleDetail
//                                 element={
//                                     <i className="fa fa-expand expand" />
//                                 }
//                                 component={withModuleDetail.component}
//                                 modalTitle={withModuleDetail.title}
//                             />
//                         </div>
//                     ) }
//
//                     {withDelete !== undefined && (
//                         <div onClick={(e) => {
//                             stopPropagation(e);
//                             withDelete.deleteHandler(item.id)
//                         }}>
//                             <Tooltip position="left" title="Удалить">
//                                 <i className="fa fa-times" />
//                             </Tooltip>
//                         </div>
//                     ) }
//                 </div>
//
//
//                 {suborderHelper !== undefined && +suborderHelper.subordersCount > 0 && <div>
//                     <div className="suborders-count" onClick={(e:React.MouseEvent) => {
//                         stopPropagation(e);
//                         if(isOpen) {
//                             suborderHelper.getSuborders(item.id)
//                         }
//
//                         setIsOpen(!isOpen);
//
//                     }}>
//                         {suborderHelper.subordersCount} <Caret isOpen={isOpen}/>
//                     </div>
//                 </div>}
//             </ListItemView>
//
//             {/*{suborderHelper && isOpen ? (*/}
//             {/*    <SubItemWrapper>*/}
//             {/*        {suborderHelper.subOrderList && suborderHelper.subOrderList.map(suborder => {*/}
//             {/*            const order = Object.assign({}, suborder, {subordersCount : "0"});*/}
//             {/*            return (<OrderListItem*/}
//             {/*                activeOrderId={activeOrderId}*/}
//             {/*                order={order}*/}
//             {/*                clickHandler={clickHandler}*/}
//             {/*                key={order.number}*/}
//             {/*            />)*/}
//             {/*        })}*/}
//
//             {/*        <Spinner loading={loading} />*/}
//             {/*    </SubItemWrapper>*/}
//             {/*) : null}*/}
//         </>
//     )
// };


const ModuleListItem = ({isActive, isFirst, onClick, children, className, style}:ListItem) => {
    return <ListItemView
        onClick={onClick ? onClick : undefined}
        style={style}
        isActive={isActive}
        isFirst={isFirst}
        className={className ? className : ''}
    >
        {children}
    </ListItemView>
};

export default ModuleListItem