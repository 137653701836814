import React from 'react'
import '../act.css'
import {
	RegularWork,
	removeActsRegularWorks,
	IActRegularWork,
} from '@justpro/terminal'
import RegularWorkListItem from '../../regularWorks/regularWork/regularWork.listItem'
import checkError from '../../../utils/checkError'
import getText from '../../../localization/getText'
import { connect } from 'react-redux'

interface Props {
	regularWorks?: IActRegularWork[]
	actId: number
	rights: any
}

interface State {
	regularWorks: IActRegularWork[]
	activeOrderId: number | null
}

class ActRegularWorks extends React.Component<Props, State> {
	state: State = {
		regularWorks: [],
		activeOrderId: null,
	}

	handleActiveOrderId = (orderId: number | null) => {
		this.setState({
			activeOrderId: orderId,
		})
	}

	renderOrderItem = (item: IActRegularWork) => {
		const { rights } = this.props
		return (
			<RegularWorkListItem
				key={item.number}
				regularWork={{ ...item, id: item.regularWorkId }}
				withModuleDetail={true}
				withDelete={{
					handleDelete: this.deleteActRegularWork,
					title: 'acts.deleteRegularWork',
					condition: rights?.['acts.regularWorks']?.delete,
				}}
				clickHandler={this.handleActiveOrderId}
				activeOrderId={this.state.activeOrderId}
			/>
		)
	}

	resetState = () => {
		const { regularWorks } = this.props

		this.setState(() => ({
			regularWorks: regularWorks ? regularWorks : [],
		}))
	}

	deleteActRegularWork = async (regularWork: RegularWork) => {
		try {
			const deleted = await removeActsRegularWorks(this.props.actId, [
				regularWork.id,
			])
			if (deleted) {
				this.setState((prevState) => {
					return {
						regularWorks: prevState.regularWorks.filter(
							(item) => item.regularWorkId !== regularWork.id,
						),
					}
				})
			}
		} catch (e) {
			checkError(e)
		}
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<{}>,
		snapshot?: any,
	): void {
		if (this.props.actId !== prevProps.actId) {
			this.resetState()
		}
	}

	componentDidMount(): void {
		this.resetState()
	}

	render() {
		const { regularWorks } = this.state

		if (regularWorks.length === 0) {
			return null
		}

		return (
			<div className='just-pro__act-orders'>
				<h4>{getText('acts.actIncludesRegularWorks')} </h4>

				<div className='act-orders'>
					{regularWorks &&
						regularWorks.map((order: any) => {
							return this.renderOrderItem(order)
						})}
				</div>
			</div>
		)
	}
}

const mapState = (state: any) => ({
	rights: state?.application?.rights,
})

export default connect(mapState)(ActRegularWorks)
