import React from 'react';
import * as api from '@justpro/terminal'
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {createSquare, filterParams, getSquares, Square} from "@justpro/terminal";
import SquareController from "./square.controller";
import SquareCreate from "./square.create";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {ModulesResponse} from "@justpro/terminal";
import {connect} from "react-redux";

export type PointMap = {
    [key : number] : {
        id? : number
        longitude : string,
        latitude : string,
        radius : string,
    }
}


interface Props {
    rights?: Partial<ModulesResponse>
}

class SquaresController extends React.Component<Props>{

    renderItem = (item: Square) => <>{item.name}</>;

    getCheckboxesMap = async () => {


        if(this.props.rights?.['references.regions']?.read) {
            const regions = await api.getRegions({q : ''});

            return {
                [filterParams.REGIONS] : {
                    name : 'regions.region',
                    children : regions.map(({id, name}) => ({ id, name }))
                }
            }
        }


        return {};
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.squares'];
        return <BaseReferenceController
                    referenceName="squares.moduleName"
                    Detail={SquareController}
                    create={{
                        Component: SquareCreate,
                        title : "squares.addItem",
                        handler : createSquare
                    }}
                    listItem={{render : this.renderItem}}
                    getList={getSquares}
                    filter={{
                        getCheckboxesMap : this.getCheckboxesMap,
                        filterName : filterNames.SQUARES
                    }}
                    moduleRights={rights}
                />
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})


export default connect(mapState)(SquaresController)

