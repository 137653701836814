import React from 'react';
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import Filter from "../UI/filter/filter.controller";
import {save, getSearchParams, get} from "../../utils/filterCheckboxes";
import {filterNames} from "../UI/checkboxList/checkboxes.const";
import {ActiveCheckboxesList, CheckboxesMap} from "../UI/checkboxList/checkboxList.types";
import CheckboxList from "../UI/checkboxList/checkboxList.contoller";
import * as api from "@justpro/terminal";
import checkError from "../../utils/checkError";
import moment, {Moment} from "moment";
import TopLineCalendar from "../UI/calendar/views/topLine.controller";
import ItemList from "../UI/itemsList/itemList.controller";
import ActListItem from "./act/act.listItem";
import RenderIf from "../../utils/renderIf";
import ActController from "./act/act.controller";
import {
    format,
    getActPhases,
    getActs,
    IAct,
    IActsFilterParams,
    IActTag,
    IPhase, ModulesResponse,
    syncAllActWith1S,
    getExcelActs,
    getExcelActsOrders,
    getExcelActsToFile,
    getExcelActsOrdersFile,
    forceSyncAllReferences1s,
    generateFileName
} from "@justpro/terminal";
import Search from "../UI/search/search.controller";
import {orderByOptions} from "../UI/sortBy/sortBy.controller";
import ListFilterController from "../UI/listFilter/listFilter.controller";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import {getExecutorsList, getPersonsList} from "../../utils/functions";
import HeaderController from "../header/header.controller";
import Tooltip from "../UI/tooltip/tooltip.controller";
import Button from "../UI/button/button";
import {ApplicationMapState} from "../application/application.controller";
import {connect} from "react-redux";
import FileDownload from "js-file-download";
import getText from "../../localization/getText";
import ToplineCalendarWrapper from "../UI/calendar/views/toplineCalendarWrapper";
import {getFilter} from "../../store/filters/filters.actions";
import {PersonsFilterParams} from "@justpro/terminal";
import {FilterName} from "../../store/filters/filters.types";
import "./act.css";
import Spinner from "../UI/spinner/spinner.controller";
import {toast} from "react-toastify";
import {confirm} from "../UI/confirmAction/confirmAction.controller";
import {isFileLink} from "../../utils/isFileLink";


export interface Props {
    getFilter(filterName: FilterName, ...rest): Promise<any[]>

    rights?: Partial<ModulesResponse>
    settings?: any
}

export interface State {
    activeCheckboxes: ActiveCheckboxesList
    checkboxesMap: CheckboxesMap
    from: Moment
    to: Moment
    q: string
    loading: boolean
    syncing: boolean
    list: any[],
    offset: number
    count: string
    sortBy: string,
    orderBy: "ASC" | "DESC" | "asc" | "desc",
    syncBtnDisabled: boolean
    excelLoading: boolean
    excelOrdersLoading: boolean
    phases: IPhase[]
    activePhases: number[]

    activeActId?: number

    backScrollToTop: boolean

}

export interface IUpdateActListItem {
    tags?: IActTag[],
    highpriced?: boolean
    checked?: boolean
    calculating?: boolean

    date?: string
    number?: string

    object?: any

    responsible?: any
}

class ActsController extends React.Component<Props, State> {
    state: State = {
        activeCheckboxes: {},
        checkboxesMap: {},
        from: moment().startOf('month'),
        to: moment().endOf('month'),
        q: '',
        loading: false,
        list: [],
        offset: 100,
        count: '',
        orderBy: orderByOptions[0].value,
        sortBy: 'date',
        phases: [],
        activePhases: [],
        syncBtnDisabled: false,
        backScrollToTop: false,
        excelLoading: false,
        syncing: false,
        excelOrdersLoading: false
    };

    orderByOptions: DropDownItem[] = [
        {
            id: 1,
            value: 'date',
            name: 'UI.dropDown.byDate',
            icon: 'fa fa-calendar'
        },
        {
            id: 2,
            value: 'number',
            name: 'UI.dropDown.byNumber',
            icon: 'fa fa-list-ol'

        },
        {
            id: 3,
            value: 'object',
            name: 'UI.dropDown.byObjectNumber',
            icon: 'fa fa-object-ungroup'

        },
    ];

    getAct = (actId: number) => {
        this.setState(() => ({activeActId: actId}))
    };

    renderItem = (item: IAct) => {
        return <ActListItem
            isActive={this.state.activeActId === item.id}
            clickHandler={this.getAct}
            withAngle={{
                color: item.scanState === 2 ? 'green' : 'default'
            }}
            act={item}
        />
    };

    onChangeSearch = (q: string) => {
        this.setState(() => ({q}))
    };

    onFilterSend = () => {
        this.getActs()
    };

    onFilterChange = (activeCheckboxes: ActiveCheckboxesList) => {
        console.log(activeCheckboxes)
        save(filterNames.ACTS, activeCheckboxes);
        this.setState(() => ({activeCheckboxes}));
    };

    searchSubmit = (q: string) => {
        this.setState(() => ({q}), this.getActs);
    };

    getActFilterParams = (offset?: number) => {

        const f: Partial<IActsFilterParams> = {};


        const {
            ACT_ACTS, // +
            ACT_AT_THE_ESTIMATOR, // +
            ACT_CHECKED,
            ACT_HIGH_PRICED, // +,
            ...rest
        } = getSearchParams(this.state.activeCheckboxes, this.state.q);
        if (ACT_ACTS && Array.isArray(ACT_ACTS) && ACT_ACTS.length > 0) {


            if (ACT_ACTS.includes("inArchive")) {
                f.scanState = "2"
            }

            if (ACT_ACTS.includes("inScan")) {
                f.scanState = "1"
            }

            if (ACT_ACTS.includes("none")) {
                f.scanState = "0";
            }

        }

        if (ACT_AT_THE_ESTIMATOR && Array.isArray(ACT_AT_THE_ESTIMATOR) && ACT_AT_THE_ESTIMATOR.length > 0) {
            if (ACT_AT_THE_ESTIMATOR.length === 2) {
                f.calculating = undefined
            } else {
                if (ACT_AT_THE_ESTIMATOR.includes(1)) {
                    f.calculating = "1";
                }

                if (ACT_AT_THE_ESTIMATOR.includes(2)) {
                    f.calculating = "0"
                }
            }
        }


        if (ACT_HIGH_PRICED && Array.isArray(ACT_HIGH_PRICED) && ACT_HIGH_PRICED.length > 0) {

            if (ACT_HIGH_PRICED.length === 2) {
                f.highpriced = undefined
            } else {
                if (ACT_HIGH_PRICED.includes(1)) {
                    f.highpriced = "0";
                }

                if (ACT_HIGH_PRICED.includes(2)) {
                    f.highpriced = "1"
                }
            }
        }

        if (ACT_CHECKED && Array.isArray(ACT_CHECKED) && ACT_CHECKED.length > 0) {

            if (ACT_CHECKED.length === 2) {
                f.checked = undefined
            } else {
                if (ACT_CHECKED.includes(1)) {
                    f.checked = "0";
                }

                if (ACT_CHECKED.includes(2)) {
                    f.checked = "1"
                }
            }
        }


        const params = {
            // ...filters,
            ...rest,
            ...f,
            offset,
            q: this.state.q,
            dateFrom: this.state.from.format(format.date),
            dateTo: this.state.to.format(format.date),
            sortBy: this.state.sortBy,
            sortOrder: this.state.orderBy,
            phaseId: this.state.activePhases,
            limit: 100,
        }

        return params
    };


    getPhases = async () => {
        const phases = await this.props.getFilter("actPhases");
        this.setState(() => ({
            phases,
        }));
    };

    changePhases = (activePhases: number[]) => {
        this.setState(() => ({activePhases}))
    };


    getActs = async () => {
        const {rights} = this.props;

        if (rights && rights['acts'] && rights['acts']['read']) {
            this.setState(() => ({
                loading: true,
                backScrollToTop: true
            }));
            try {
                const {list, count} = await getActs(this.getActFilterParams());

                this.setState(() => ({
                    list,
                    count,
                    loading: false,
                    backScrollToTop: false,
                    activeActId: list?.[0]?.id
                }));

            } catch (e) {
                this.setState(() => ({loading: false, backScrollToTop: false}));
                checkError(e)
            }
        }
    };

    updateActList = async () => {
        const {count, list} = this.state;

        if (count && list) {
            if (+count >= list.length) {
                try {
                    this.setState(() => ({loading: true}));

                    const filterParams = this.getActFilterParams(this.state.offset);
                    const {list, count} = await getActs(filterParams);


                    this.setState((prevState) => ({
                        list: [...prevState.list, ...list],
                        count: count ? count : prevState.count,
                        offset: prevState.offset + 100,
                        loading: false
                    }))
                } catch (e) {
                    checkError(e);
                    this.setState(() => ({loading: false}))
                }
            }
        }
    };


    updateListIcons = (actId: number, data: IUpdateActListItem) => {
        this.setState((prevState) => ({
            list: prevState.list.map((act) => {
                if (act.id === actId) {

                    console.log({act, data})
                    return {
                        ...act,
                        tags: data.tags ? data.tags : act.tags,
                        calculating: data.calculating !== undefined ? data.calculating : act.calculating,
                        checked: data.checked !== undefined ? data.checked : act.checked,
                        highpriced: data.highpriced !== undefined ? data.highpriced : act.highpriced,
                        number: data.number !== undefined ? data.number : act.number,
                        date: data.date !== undefined ? data.date : act.date,
                        object: data.object !== undefined ? data.object : act.object,
                        responsible: data.responsible !== undefined ? data.responsible : act.responsible
                    }
                }
                return act
            })
        }))
    };

    // onChangeTag = (actId:number, tags:IActTag[]) => {
    //     this.setState((prevState) => ({
    //         list : prevState.list.map((act) => {
    //             if(act.id === actId) {
    //                 return {
    //                     ...act,
    //                     tags
    //                 }
    //             }
    //
    //             return act
    //         })
    //     }))
    // };


    onChangeFrom = (from: Moment) => {
        this.setState(() => ({from}))
    };

    onChangeTo = (to: Moment) => {
        this.setState(() => ({to}))
    };

    changeSortBy = (dropDownItem: DropDownItem) => {
        this.setState(() => ({sortBy: dropDownItem.value}), this.getActs)
    };

    changeOrderBy = (dropDownItem: DropDownItem) => {
        this.setState(() => ({orderBy: dropDownItem.value}), this.getActs)
    };

    toggleDisabled = () => {
        this.setState((prevState) => ({syncBtnDisabled: !prevState.syncBtnDisabled}));
    }

    exportActs = async () => {
        try {
            this.setState({
                excelLoading: true
            });
            const filterParams = this.getActFilterParams();
            await isFileLink(
                () => getExcelActsToFile(filterParams),
                () => getExcelActs(filterParams),
                generateFileName('акты', 'xls')
            );
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                excelLoading: false
            });
        }
    }

    exportActsOrders = async () => {
        try {
            this.setState({
                excelOrdersLoading: true
            });
            const filterParams = this.getActFilterParams();
            await isFileLink(
                () => getExcelActsOrdersFile(filterParams),
                () => getExcelActsOrders(filterParams),
                generateFileName('реестр_закрытых_актов', 'xls')
            );
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                excelOrdersLoading: false
            });
        }
    }

    syncAllActs = async () => {


        // alert(1)
        this.toggleDisabled();

        const filters = this.getActFilterParams();

        try {
            const result = await syncAllActWith1S({
                ...filters,
                limit: 9999
            });

            console.log({result})

        } catch (e) {
            checkError(e)
        }


        this.toggleDisabled();


        // console.log({filters})
    };

    resetState = async () => {
        const {rights} = this.props;
        const {settings} = this.props;

        if (!rights || !settings) return false;

        const highPriced = [
            {
                id: 1,
                name: 'acts.allExceptHighpriced'
            },
            {
                id: 2,
                name: 'acts.onlyHighPriced'
            },
        ];

        const checked = [
            {
                id: 1,
                name: 'acts.allExceptChecked'
            },
            {
                id: 2,
                name: 'acts.onlyChecked'
            },
        ];

        const workordersTypes = [
            {
                id: 'orders',
                name: 'workorders.orders'
            },
            {
                id: 'checklist',
                name: 'workorders.checklist'
            },
            {
                id: 'admin',
                name: 'workorders.admin'
            }
        ];

        const atTheEstimator = [
            {
                id: 1,
                name: 'common.yes'
            },
            {
                id: 2,
                name: 'common.no'
            },
        ];
        const checkboxesMap = {
            [api.filterParams.ACT_ACTS]: {
                children: [
                    {
                        name: 'common.undefined',
                        value: 'none'
                    },
                    {
                        name: 'acts.states.archive',
                        value: 'inArchive'
                    },
                    {
                        name: 'acts.states.scan',
                        value: 'inScan'
                    },
                ],
                name: 'acts.act'
            },
            [api.filterParams.ACT_HIGH_PRICED]: {
                children: highPriced,
                name: 'UI.filters.highpriced'
            },
            [api.filterParams.ACT_CHECKED]: {
                children: checked,
                name: 'UI.filters.checked'
            },
            [api.filterParams.ACT_AT_THE_ESTIMATOR]: {
                children: atTheEstimator,
                name: 'UI.filters.estimator'
            },
        };

        checkboxesMap[api.filterParams.TAGS] = {
            name: 'tags.tags',
            children: await this.props.getFilter('actTags', {modules: ['acts']}),
        };

        checkboxesMap[api.filterParams.CLOSE_TYPES] = {
            name: 'acts.closeTypes',
            children: await this.props.getFilter('closeTypes', {modules: ['acts']}),
        };


        checkboxesMap[api.filterParams.CONTRACTORS] = {
            name: 'contractors.contractor',
            children: await this.props.getFilter("contractors")
        };


        const filtersExecutor: PersonsFilterParams = {
            q: "",
            executorsId: [
                +settings.tfmContractorId,
                +settings.tfmShidContractorId
            ]
        };

        const filterPersons: PersonsFilterParams = {
            q: "",
            contractorsId: [
                +settings.tfmContractorId,
                +settings.tfmShidContractorId
            ]
        };

        checkboxesMap[api.filterParams.RESPONSIBLES] = {
            children: await this.props.getFilter("executors", filtersExecutor),
            name: 'common.responsible'
        }
        checkboxesMap[api.filterParams.COWORKERS] = {
            name: 'UI.filters.executors',
            children: await this.props.getFilter("executors", filtersExecutor),
        };
        checkboxesMap[api.filterParams.HANDLERS] = {
            children: await this.props.getFilter("persons", filterPersons),
            name: 'common.inProcessingBy'
        };

        checkboxesMap[api.filterParams.EXECUTORS] = {
            name: 'Подрядчики',
            children: await this.props.getFilter("contractors", {isExecutors: true}),
        };

        checkboxesMap[api.filterParams.AREAS] = {
            children: await this.props.getFilter('areas'),
            name: 'areas.area'
        };

        const contractConditions = await this.props.getFilter("contractsConditions");

        if (contractConditions?.length) {
            checkboxesMap[api.filterParams.CONTRACT_CONDITION] = {
                children: [{
                    id: -1,
                    name: "acts.withoutSign"
                }, ...contractConditions],
                name: 'contractConditions.contractConditions'
            };
        }

        checkboxesMap[api.filterParams.REGIONS] = {
            children: await this.props.getFilter("regions"),
            name: "regions.region"
        };

        checkboxesMap[api.filterParams.WORK_ORDER_TYPES] = {
            children: workordersTypes as unknown as { id: number, name: string }[],
            name: "workorders.workorderType"
        }

        await this.getPhases();
        await this.getActs();

        this.setState(() => ({
            checkboxesMap,
            activeCheckboxes: get(filterNames.ACTS)
        }))
    };

    async componentDidMount() {
        this.resetState();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.resetState();
        }
    }

    startForceSync1s = async () => {
        try {
            this.setState({
                syncing: true
            });
            const response = await forceSyncAllReferences1s();
            toast.success(getText(`acts.forceSyncStarted`));
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                syncing: false
            })
        }
    }

    render() {
        const {activeCheckboxes} = this.state;

        return (
            <WithPrivateRoute>
                <HeaderController>
                    <Filter
                        activeCheckboxes={activeCheckboxes}
                        send={this.onFilterSend}
                    >
                        <CheckboxList
                            checkboxesMap={this.state.checkboxesMap}
                            activeCheckboxes={activeCheckboxes}
                            send={this.onFilterChange}
                        />
                    </Filter>

                    <ToplineCalendarWrapper>
                        <TopLineCalendar date={this.state.from} onChange={this.onChangeFrom}/>
                        <TopLineCalendar date={this.state.to} onChange={this.onChangeTo}/>
                    </ToplineCalendarWrapper>


                    <Search
                        submit={this.searchSubmit}
                        loadOnEraser={false}
                        eraser={true}
                    />

                    <div className="navbar-form navbar-left buttons">
                        <Tooltip title="common.excelExport">
                            <Button className="btn-default" onClick={this.exportActs}>
                                <RenderIf condition={!this.state.excelLoading}>
                                    <i className="fas fa-file-excel"/>
                                </RenderIf>
                                <RenderIf condition={this.state.excelLoading}>
                                    {getText('common.generating')}
                                </RenderIf>
                            </Button>
                        </Tooltip>
                        <Tooltip title="common.sync1s">
                            <Button
                                className="btn-default"
                                disabled={this.state.syncBtnDisabled}
                                onClick={this.syncAllActs}
                            >
                                <i className="fas fa-file-import"/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="acts.registryClosedActs">
                            <Button className="btn-default" onClick={this.exportActsOrders}>
                                <RenderIf condition={!this.state.excelOrdersLoading}>
                                    <i className="fas fa-list"/>
                                </RenderIf>
                                <RenderIf condition={this.state.excelOrdersLoading}>
                                    {getText('common.generating')}
                                </RenderIf>
                            </Button>
                        </Tooltip>
                    </div>

                </HeaderController>

                <div className="just-pro_module acts">
                    <div className="panel content-panel">

                        <ListFilterController
                            counter={{
                                value: parseInt(this.state.count),
                                vars: {
                                    one: 'acts.vars.one',
                                    two: 'acts.vars.two',
                                    default: 'acts.vars.default',
                                    notFound: 'acts.vars.notFound'
                                }
                            }}
                            sortBy={{
                                onChangeSortBy: this.changeSortBy,
                                onChangeOrderBy: this.changeOrderBy,
                                sortByOptions: this.orderByOptions,
                            }}
                            moduleGroups={{
                                list: this.state.phases,
                                title: 'acts.phases',
                                onChange: this.changePhases,
                                checked: this.state.activePhases,
                            }}
                        />

                        <ItemList
                            loading={this.state.loading}
                            renderItem={this.renderItem}
                            list={this.state.list}
                            updateData={this.updateActList}
                            backScrollToTop={this.state.backScrollToTop}
                        />
                    </div>
                    <div className="panel content-panel">
                        <RenderIf condition={this.state.activeActId !== undefined}>
                            <ActController actId={this.state.activeActId as number} updateList={this.updateListIcons}/>
                        </RenderIf>
                    </div>
                </div>
                <RenderIf condition={this.props?.rights?.['references.syncAll1S']?.edit}>
                    <div className="force-sync-1s" onClick={this.startForceSync1s}>
                        <RenderIf condition={!this.state.syncing}>
                            <i className="fa fa-retweet"></i>
                            &nbsp;
                            {getText(`acts.forceSync1SReferences`)}
                        </RenderIf>
                        <RenderIf condition={this.state.syncing}>
                            <Spinner loading={this.state.syncing} size={2}/>
                        </RenderIf>
                    </div>
                </RenderIf>
            </WithPrivateRoute>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights,
    settings: state.application.settings
})

const mapDispatch = (d: Function) => ({
    getFilter: (filterName, rest) => d(getFilter(filterName, rest))
});

export default connect(mapState, mapDispatch)(ActsController)
