import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import moment, { Moment } from 'moment';
import FileDownload from 'js-file-download';
import {
  Person,
  format,
  getDispetcherActs,
  DispetcherAct,
  ModulesResponse,
  getDispetcherActsExport,
  getWaybillsReport,
  WaybillReport,
  getWaybillsReportExport,
  secondsToTime,
  getDurationByLocations,
  getDurationByAvgSpeed,
} from '@justpro/terminal';
import Table from '../../UI/table/table';
import { ApplicationMapState } from '../../application/application.controller';
import { connect } from 'react-redux';
import HasNoRightsController from '../../UI/hasNoRights/hasNoRights.controller';
import checkError from '../../../utils/checkError';
import Spinner from '../../UI/spinner/spinner.controller';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import RenderIf from '../../../utils/renderIf';
import Button from '../../UI/button/button';
import getText from '../../../localization/getText';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import './waybills.css';

interface Props {
  rights?: Partial<ModulesResponse>;
}

interface State {
  from: Moment;
  to: Moment;
  loading: boolean;
  generatingExcel: boolean;
  list: WaybillReport[];
}

class Waybills extends React.Component<Props, State> {
  state: State = {
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    loading: false,
    generatingExcel: false,
    list: [],
  };

  changeFrom = (from: Moment) => {
    this.setState(() => ({ from }), this.getWaybillsList);
  };

  changeTo = (to: Moment) => {
    this.setState(() => ({ to }), this.getWaybillsList);
  };

  getWaybillsList = async () => {
    try {
      this.setState({
        loading: true,
      });
      const waybillsReport = await getWaybillsReport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });
      const list = waybillsReport.map(listItem => {
        return {...listItem, duration: getDurationByLocations(listItem.locations)}
      })
      this.setState({
        list,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  get columns() {
    return [
      {
        Header: getText('common.executor'),
        accessor: 'personFullName',
        width: 200,
      },
      {
        Header: getText('common.date'),
        accessor: 'date',
      },
      {
        Header: getText('acts.waybillsReport.estimate'),
        accessor: 'distanceCheck',
      },
      {
        Header: getText('acts.waybillsReport.executor'),
        accessor: 'distance',
      },
      {
        Header: getText('acts.waybillsReport.actual'),
        accessor: 'distanceAll',
      },
      {
        Header: getText('acts.waybillsReport.final'),
        accessor: 'distanceTotal',
      },
      {
        Header: getText('acts.waybillsReport.durationRoute'),
        Cell: (props) => <>{secondsToTime(+props.original.routeDuration)}</>
      },
      {
        Header: getText('acts.waybillsReport.durationKm'),
        Cell: (props) => <>{secondsToTime(
            getDurationByAvgSpeed(
                props.original?.distanceTotal ||
                props.original?.distance ||
                props.original?.distanceAll ||
                props.original?.distanceCheck ||
                0
            )
        )}</>
      },
      {
        Header: getText('acts.waybillsReport.durationObjects'),
        Cell: (props) => <>{secondsToTime(props.original.duration)}</>
      },
      {
        Header: getText('acts.waybillsReport.workDay'),
        Cell: (props) => {
          if (props.original?.isWorkingDay) {
            return <>{getText('common.yes')}</>;
          }
          if (!props.original?.isWorkDay) {
            return <>{getText('common.no')}</>;
          }
        },
      },
      {
        Header: getText('regions.region'),
        accessor: 'regions',
      },
    ];
  }

  async componentDidMount() {
    return this.getWaybillsList();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      return this.getWaybillsList();
    }
  }

  generateExcel = async () => {
    try {
      this.setState(() => ({ generatingExcel: true }));
      const blob = await getWaybillsReportExport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });
      FileDownload(
        blob,
        `отчет-о-путевых-листах_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState(() => ({ generatingExcel: false }));
    }
  };

  render() {
    const { rights } = this.props;
    const { loading } = this.state;
    const moduleName = getText('acts.waybills');
    if (rights === undefined) {
      return (
        <WithPrivateRoute>
          <HeaderController>
            <ModuleTextName>{moduleName}</ModuleTextName>
          </HeaderController>
        </WithPrivateRoute>
      );
    }

    if (!rights?.['reports.dispetcherActs']?.read) {
      return (
        <WithPrivateRoute>
          <HeaderController>
            <ModuleTextName>{moduleName}</ModuleTextName>
          </HeaderController>
          <HasNoRightsController />
        </WithPrivateRoute>
      );
    }

    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>{moduleName}</ModuleTextName>

          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.changeFrom}
              timePicker={true}
            />
            <TopLineCalendar
              date={this.state.to}
              onChange={this.changeTo}
              timePicker={true}
            />
          </ToplineCalendarWrapper>

          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="Экспортировать в эксель">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                Генерация...
              </RenderIf>
            </Tooltip>
          </div>
        </HeaderController>
        <Spinner loading={loading} />
        <div className="just-pro_module compareHours">
          <div className="panel content-panel waybills-report_table">
            <Table 
              data={this.state.list} 
              columns={this.columns} 
            />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

const mapState = (state: ApplicationMapState) => ({
  rights: state.application.rights,
});

export default connect(mapState)(Waybills);
