import React from 'react';
import {Messenger, getMessengers, createMessenger, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import MessengerController from "./messenger.controller";
import MessengerCreate from "./messenger.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class MessengersController extends React.Component<Props>{

    renderItem = (item:Messenger) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.messengers'];
        return <BaseReferenceController
            referenceName="messengerTypes.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getMessengers}
            Detail={MessengerController}
            create={{
                Component : MessengerCreate,
                title : 'messengerTypes.addItem',
                handler : createMessenger
            }}
            moduleRights={rights}
        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(MessengersController)