import * as api from '@justpro/terminal';
import checkError from "../../../../utils/checkError";
import {rolesTypes} from "../../roles.reducer";

export interface AddObjectSecurity {
    roleID : number
    osID : number
}


export interface StatusRuleList {
    description : string
    id : number,
    type : api.IStatusRuleType
}

export const actualizeRoleData = async (dispatch: Function, roleId: number) => {
    try {
        const map = await api.getRoles();

        dispatch({
            type: rolesTypes.GET_ROLE,
            map,
            id : roleId,
        })
    } catch (err) {
        checkError(err);
    }
};

export const addObjectSecurityItem = (data:AddObjectSecurity) => async (dispatch:Function) => {
    try {
        const addRoleRightsResult = await api.addRoleRights(data.roleID, data.osID);
        if(!addRoleRightsResult) return;
        return actualizeRoleData(dispatch, data.roleID);
    } catch (err){
        checkError(err);
    }
};

export const changeRoleRight = (data:api.ChangeRoleRights) => async (dispatch:Function) => {
    try {
        const changeRoleRightsResult = await api.changeRoleRights(data);
        if(!changeRoleRightsResult) return;
        return actualizeRoleData(dispatch, data.roleId);
    }catch (e) {
        checkError(e)
    }

};

export const updateRole = (id:number, data:api.ChangeRole) => async (dispatch:Function) => {
    try {
        const {name} = await api.changeRole(id, data);
        const map = await api.getRoles();
        const {parentRoles} = map[id];

        dispatch({
            type : rolesTypes.UPDATE_ROLE,
            name,
            roleParentRoles : parentRoles,
            map
        })
    }catch (e) {
        checkError(e)
    }
};

export const addStatusRule = (roleId: number, status: number, type: api.IStatusRuleType) => async (dispatch:Function) => {
    try {
        const data = await api.addRoleStatusRule(roleId, status, type);
        if (!data) return;
        const map = await api.getRoles();

        dispatch({
            type : rolesTypes.UPDATE_ROLES_MAP,
            map,
        })
    }catch (e) {
        checkError(e)
    }

};

export const removeRoleStatusRule = (roleId:number, statusRuleId:number) => async (dispatch:Function) => {
    try {
        await api.removeRoleStatusRule(roleId, statusRuleId);
        const map = await api.getRoles();

        dispatch ({
            type : rolesTypes.UPDATE_ROLES_MAP,
            map,
        })

    }catch (e) {
        checkError(e)
    }
};