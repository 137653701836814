import React from 'react';
import getText from "../../../../localization/getText";
interface Props {
    change(month:number) : void
    active : number[]
}

const weeks = [
    {
        name : "schedule.weeks.first",
        id : 1
    },
    {
        name : 'schedule.weeks.second',
        id : 2
    },
    {
        name : 'schedule.weeks.third',
        id : 3
    },
    {
        name : 'schedule.weeks.fourth',
        id : 4
    },
    {
        name : 'schedule.weeks.fifth',
        id : 5
    },
];

export default class Weeks extends React.Component<Props> {


    render() {

        return (
            <div>
                <h6>{getText('schedule.weeksHeader')}</h6>
                <div className="weeks">
                    {weeks.map (item => {
                        return (
                            <div
                                key={item.id}
                                onClick={(e:React.MouseEvent) => this.props.change(item.id)}
                                className={this.props.active.includes(item.id) ? 'checked cell' : 'cell' }
                            >
                                {getText(item.name)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}