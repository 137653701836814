import styled from "styled-components";

export const TextSwitcherView = styled.div`
   
  cursor: pointer;
  user-select: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;

   & .title {
    padding: 7px;
    font-weight: bolder;
  } 

   & .text-switcher__value {
    display: none;
    background: #eee;
    padding: 7px;

    &.active {
      display: inline-block;
    }
  }
`;