import React from 'react';
import {createWorkorderByOrders, getPersons, format, getFullName, ModulesResponse, OrderListItem} from "@justpro/terminal";
import {CreateWorkOrder} from "./workOrderModal.view";
import Select from "../../select/select";
import {getPersonsList} from "../../../../utils/functions";
import MultiSelect from "../../select/multiSelect";
import InlineCalendar from "../../calendar/views/inline.controller";
import moment, {Moment} from "moment";
import {ChangeMultiProps, Option} from "../../select/select.types";
import Order from "../../../orders/order/order.controller";
import RenderIf from "../../../../utils/renderIf";
import SecondStep from "./secondStep.controller";
import Spinner from '../../spinner/spinner.controller'
import checkError from "../../../../utils/checkError";
import {OrdersWorkorder, Person} from "@justpro/terminal";
import {ApplicationMapState} from "../../../application/application.controller";
import {connect} from "react-redux";
import HasNoRightsController from "../../hasNoRights/hasNoRights.controller";
import DefaultFooterModal from "../modal/defaultFooterModal";
import Button from "../../button/button";
import getText from "../../../../localization/getText";

// interface Props {
//     orders: OrderListItem[]
//
//
//
// }

// import {hideModal, updateModal} from "../../../../store/modal/modal.actions";
import {setWoArray, setArrangedWoArray, setCurrentExecutor} from "../../../../store/map/map.actions";
import {Modal} from "../../../../store/modal/modal.types";
import {ordersCreateWorkordersId} from "../../../orders/orders.controller";
import {sortTypes} from "../../simpleSorting/sortTypes";
import SortingList from '../../simpleSorting/sortingList'

interface Props {
    orders: OrderListItem[]
    // removeOrderFromList?(id:number): void
    onChangeOrdersList?(orders: OrderListItem[]): void
    onChangeCoworkers?(coworkers: Person[]): void
    onChangeDate?(date: Moment): void
    hide?(): void
    onSave?(): void
    setCurrentExecutor(executor: any): void
    setWoArray(orders): void
    setArrangedWoArray(orders): void
    rights?: Partial<ModulesResponse>
    settings: any
    executors?: any
}

interface State {
    isLoading: boolean
    date: Moment
    responsible?: Option
    coworkers?: Option[]
    workOrders?: OrdersWorkorder[]
    orders?: OrderListItem[]
}

class CreateWorkOrderController extends React.Component<Props, State> {

    state: State = {
        isLoading: false,
        date: moment(),
        orders: []
    };

    save = async () => {
        const {responsible, coworkers, orders} = this.state;

        if (!this.isDisabled()) {
            try {
                this.setState(() => ({
                    isLoading: true
                }));

                const data = {
                    orders: orders.map(({id}) => id),
                    responsibleId: (responsible as Option).value,
                    coworkers: coworkers ? coworkers.map(({value}) => value) : [],
                    date: this.state.date.format(format.date)
                };

                const workOrders = await createWorkorderByOrders(data);

                this.setState(() => ({
                    isLoading: true,
                    workOrders,
                    orders: []
                }));

            } catch (e) {
                checkError(e)
            }
        }
    };
    //
    // hideModal = () => {
    //     const {afterHide} = this.props;
    //
    //     this.props.hide();
    //
    //     afterHide && afterHide(this.state.orders);
    // };

    changeDate = (date: Moment) => {
        this.setState(() => ({date}), () => this.props.onChangeDate?.(this.state.date))
    };

    changeResponsible = (option: Option) => {
        this.setState(() => ({responsible: option}));
        this.props.setCurrentExecutor(option);
        setTimeout(() => {
            this.props.onChangeOrdersList && this.props.onChangeOrdersList(this.state.orders);
        }, 500);
        return Promise.resolve();
    };

    changeBasePoint = (option: Option) => {

        return Promise.resolve();
    };

    changeCoworkers = async (props: ChangeMultiProps) => {

        switch (props.type) {
            case "select-option":
                await this.setState((prevState) => ({
                    coworkers: prevState.coworkers ? [...prevState.coworkers, props.value] : [props.value]
                }), () => this.props.onChangeCoworkers?.(this.state.coworkers as unknown as Person[]));
                return true;
            case "remove-value":
                await this.setState((prevState) => ({
                    coworkers: prevState.coworkers ?
                        prevState.coworkers.filter(item => item.value !== props.value.value)
                        : undefined
                }), () => this.props.onChangeCoworkers?.(this.state.coworkers as unknown as Person[]));
                return true
        }

        return false
    };


    resetState = () => {
        this.setState(() => ({orders: this.props?.orders || []}))
    };

    isDisabled = () => {
        const {responsible, orders} = this.state;
        return !(orders && orders.length > 0 && responsible)
    };

    componentDidMount(): void {
        this.resetState();
    }


    removeOrderFromList = (orderId: number) => {
        this.setState(({orders}) => ({
            orders: orders.filter(o => o.id !== orderId)
        }), () => this.props.onChangeOrdersList(this.state.orders))
    };

    getPersonsList = async () => {
        if (this.props.executors) {
            return Promise.resolve(this.props.executors?.map(exec => {
                return {
                    label: getFullName(exec),
                    value: exec.id,
                    position: {lat: exec.latitude, lng: exec.longitude},
                    basePoints: exec.basePoints
                }
            }))
        }
        const personsList = await getPersons({executorsId: [this.props.settings.tfmContractorId, this.props.settings.tfmShidContractorId]});
        return personsList.map(p => {
            return {
                ...p,
                label : getFullName(p),
                value : p.id
            }
        });
    }

    getPersonsListMulti = async (q) => {
        const personsList = await getPersons({
            q,
            executorsId: [this.props.settings.tfmContractorId, this.props.settings.tfmShidContractorId]
        });
        const persons:Option[] = personsList.map(p => {
            return {
                ...p,
                label : getFullName(p),
                value : p.id
            }
        });
        return persons;
    }

    onOrderDrop = (list: OrderListItem[]) => {
        this.props.setWoArray(list)
        this.props.setArrangedWoArray(list)
        this.props.onChangeOrdersList && this.props.onChangeOrdersList(list);
    };

    renderItem = (order: OrderListItem) => {
        return <Order
            order={order}
            key={order.number}
            withBorderBottom={false}
            withDelete={{
                deleteHandler: this.removeOrderFromList,
                condition: true,
                title: 'orders.deleteOrderFromList'
            }}
        />
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const {onChangeOrdersList} = this.props;
        if (JSON.stringify(this.props.orders) !== JSON.stringify(prevProps.orders)) {
            this.resetState();
        }
        if (JSON.stringify(this.state.orders) !== JSON.stringify(prevProps.orders)) {
            onChangeOrdersList && onChangeOrdersList(this.state.orders || []);
        }
    }

    render() {
        console.log(this.state.responsible)
        const {rights} = this.props;
        const {orders} = this.state;
        const isSetWorkOrders = Array.isArray(this.state.workOrders);
        const canCreate = rights && rights['workorders'] && rights['workorders']['create'];
        return (
            <>
                <RenderIf condition={canCreate && !isSetWorkOrders}>
                    <CreateWorkOrder>
                        <div className="header">
                            <InlineCalendar date={this.state.date} onChange={this.changeDate}
                                            label={{text: "common.date"}}/>
                            <Select
                                change={this.changeResponsible}
                                load={this.getPersonsList}
                                label="common.responsible"
                                placeholder="UI.select.placeholders.chooseResponsible"
                            />
                            <Select
                                change={this.changeBasePoint}
                                load={() => this.state.responsible?.basePoints}
                                label="persons.basePoint"
                                placeholder="UI.select.placeholders.chooseBasePoint"
                                accessors={{
                                    address: 'label',
                                    id: 'value'
                                }}
                            />
                            <MultiSelect
                                change={this.changeCoworkers}
                                load={this.getPersonsListMulti}
                                label="common.coworkers"
                                placeholder="UI.select.placeholders.selectCoworkers"
                                accessors={{
                                    name: 'label',
                                    id: 'value'
                                }}
                            />
                        </div>
                        {orders ? (
                            <div className="workOrder__orders">
                                <SortingList
                                    type={sortTypes.order}
                                    renderItem={this.renderItem}
                                    list={orders}
                                    onItemDrop={this.onOrderDrop}
                                />
                            </div>) : null
                        }


                        <RenderIf condition={this.props.hide}>
                            <DefaultFooterModal>
                                <Button className="btn-danger"
                                        onClick={this.props.hide}>{getText('common.cancel')}</Button>
                                <Button className="btn-success" disabled={this.isDisabled()}
                                        onClick={this.save}>{getText('common.create')}</Button>
                            </DefaultFooterModal>
                        </RenderIf>
                        <Spinner loading={this.state.isLoading}/>
                    </CreateWorkOrder>
                </RenderIf>

                <RenderIf condition={canCreate && isSetWorkOrders}>
                    <SecondStep
                        list={this.state.workOrders as OrdersWorkorder[]}
                    />
                </RenderIf>
                <RenderIf condition={!canCreate}>
                    <HasNoRightsController/>
                </RenderIf>
            </>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights,
    settings: state.application.settings
});
const mapDispatch = (d: Function) => ({
    setCurrentExecutor: (executor) => d(setCurrentExecutor(executor)),
    setWoArray: (orders) => d(setWoArray(orders)),
    setArrangedWoArray: (orders) => d(setArrangedWoArray(orders))
});

export default connect(mapState, mapDispatch)(CreateWorkOrderController)