import React from 'react'
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Button from "../../UI/button/button";
import {OrderType} from "./modals/orderTypes";
import checkError from "../../../utils/checkError";
import * as api from '@justpro/terminal'
import ModalOrdersType from './modals/orderTypes'
import {ContractOrdersType, Rules} from "@justpro/terminal";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {openModal} from "../../../store/modal/modal.actions";
import {connect} from "react-redux";


interface Props {
    contractId : number
    orderTypes? : api.ContractOrdersType[]
    rights?: Rules
    openModal(props:Modal) : void

}

interface State {
    orderTypes? : api.ContractOrdersType[]
}


export const orderTypesModalId = 'orderTypesContractModal';

class ContractOrderTypes extends React.Component<Props, State>{

    state:State = {
    };


    addOrderTypeHandler = () => {
        //  title : `${this.state.id ? 'common.editing' : 'common.adding'} ${renderToString(getText('contracts.typeOfOrderForContract'))}`
        this.props.openModal({
            id : orderTypesModalId,
            component : (props:ModalBodyProps) => (
                <ModalOrdersType
                    {...props}
                    onSave={this.createOrderType}
                />
            ),
            title : getText('contracts.addContractOrderType'),
        })
    };

    editOrderTypeHandler = (orderType:ContractOrdersType) => {

        this.props.openModal({
            id : orderTypesModalId,
            component: (props:ModalBodyProps) => (
                <ModalOrdersType
                    {...props}
                    onSave={this.editOrderType}
                    contractOrdersType={orderType}
                />
            ),
            title : getText('contracts.editContractOrderType'),
        });
    };

    createOrderType = async (orderType:OrderType) => {
        const {alias, becomeHot, deadline, id} = orderType;

        const data:api.CreateContractOrdersType = {
            alias,
            ordersTypeId: id,
            becomeHot,
            deadline
        };

        const orderTypes = await api.addContractOrdersType(this.props.contractId, data);


        this.setState((prevState) => ({
            orderTypes : prevState.orderTypes ?
                [...prevState.orderTypes, ...orderTypes] :
                orderTypes
        }));
    };

    editOrderType = async (orderType:OrderType) => {
        const {alias, becomeHot, deadline, id, rowId } = orderType;

        const data:Partial<ContractOrdersType> = {
            alias,
            ordersTypeId: id,
            becomeHot,
            deadline,
            id : rowId
        };

        try {
            const updated = await api.updateContractOrdersType(this.props.contractId, data);

            this.setState((prevState) => ({orderTypes : prevState.orderTypes?.map(item => {
                if(item.id === rowId) {
                    //todo возвращать еще и имя от сервера
                    return updated
                }

                return item
            })}))
        }catch (e) {
            checkError((e))
        }

    };

    deleteContractOrderType = async (props:any) => {
        const {id} = props.original;

        try {

            const deleted = api.deleteContractOrdersType(this.props.contractId, id);

            if(deleted) {
                this.setState((prevState) => ({
                    orderTypes : prevState.orderTypes?.filter(item => item.id !== id)
                }))
            }

        }catch (e) {
            checkError(e)
        }
    };


    resetState = () => {
        this.setState(() => ({
            orderTypes : this.props.orderTypes
        }))
    };

    componentDidMount(): void {
        this.resetState()
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(this.props.contractId !== prevProps.contractId) {
            this.resetState()
        }
    }



    get columns() {
        const {rights} = this.props;
        const result:any[] = [
            {
                Header : getText('common.name'),
                accessor : 'name'
            },
            {
                Header : getText('common.alias'),
                accessor : 'alias'
            },
            {
                Header : getText('contracts.deadlineInHours'),
                accessor : 'becomeHot'
            },
            {
                Header : getText('contracts.becomeHotInHours'),
                accessor : 'deadline'
            },

        ];

        if(rights && rights.edit) {
            result.push(

                {
                    Header : getText('common.edit'),
                    Cell : (props:any) => <Button className="btn-success" onClick={this.editOrderTypeHandler.bind(this, props.original)}>
                        <i className="fa fa-edit no-text"/>
                    </Button>
                },

                {
                    Header : getText('common.delete'),
                    Cell : (props:any) => <DeleteCell deleteHandler={this.deleteContractOrderType} item={props}/>
                },
            )
        }


        return result

    }

    render () {
        const {rights} = this.props;
        const {orderTypes} = this.state;

        return (
            <>
                {withLabel(
                    <>
                        {orderTypes && orderTypes.length > 0 &&
                        <Table
                            columns={this.columns}
                            data={orderTypes}
                            showPagination={false}
                            minRows={0}
                        />
                        }
                        {rights && rights.edit &&
                            <Button className="btn-success margin-top" onClick={this.addOrderTypeHandler}>{getText('common.add')}</Button>
                        }
                    </>

                )({text : 'orderTypes.orderType'})}
            </>
        )
    }
}

const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props))
});

export default connect(null, mapDispatch)(ContractOrderTypes)