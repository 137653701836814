import React from 'react';
import {getTags, Tag, createTag, ModulesResponse} from "@justpro/terminal";
import {toClassName} from "../../../utils/icons";
import BaseReferenceController from "../baseReference/baseReference.controller";
import TagCreate from "./tag.create";
import TagController from "./tag.controller";
import {Angle} from "../../UI/itemsList/card/card.view";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}


class TagsController extends React.Component<Props>{

    renderItem = (item : Tag) => {
        return (
            <>
                <Angle size={20}/>
                <i className={toClassName(item.icon)} style={{color : item.color}}/> {item.name}
            </>
        )
    };


    render () {

        const rights = this.props.rights && this.props.rights['references.tags'];
        return <BaseReferenceController
            referenceName="tags.moduleName"
            create={{
                title : 'tags.addItem',
                handler : createTag,
                Component : TagCreate
            }}
            Detail={TagController}
            getList={getTags}
            listItem={{render : this.renderItem}}
            moduleRights={rights}
        />
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(TagsController)