import React from "react";
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import './pageNotFound.css'
import getText from "../../localization/getText";

export default class PageNotFound extends React.Component{


    render() {
        return (
            <WithPrivateRoute>
                <div className="top-line" />
                <div className="content">
                    <div className="page-not-found">Добро пожаловать!</div>
                </div>
            </WithPrivateRoute>
        )
    }
}