import React from 'react';
import {Department, editDepartment, getDepartment} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import DepartmentDetail from "./department.detail";
interface Props {
    id: number

    afterUpdate?(item:Department) : void
}

interface State {
    loading : boolean
    department? : Department
}

class DepartmentController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateDepartment = async (data:Partial<Department>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const department = await editDepartment(this.props.id, data);

            afterUpdate && afterUpdate(department);
            this.setState(() => ({loading : false, department}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getDepartment = async () => {
        this.setState(() => ({loading : true}));

        try {
            const department = await getDepartment(this.props.id);


            this.setState(() => ({loading : false, department}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getDepartment();
        }
    }

    async componentDidMount(){
        this.getDepartment();
    }

    render() {
        const {department} = this.state;
        return (
            <>
                <RenderIf condition={department !== undefined}>
                    <DepartmentDetail
                        department={department as Department}
                        update={this.updateDepartment} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default DepartmentController