import React from "react";
import Button from "../button/button";
import {toast} from "react-toastify";
import {confirm} from "../confirmAction/confirmAction.controller";


interface Props {
    deleteHandler(item:any) :void
    item?: any
    id?: number
    message? : string
    disabled? : boolean
}


export default class DeleteCell extends React.Component<Props>{


    delete = async () => {
        const {deleteHandler, message, item, id} = this.props;

        console.log({props: this.props})

        if(!item && !id) {
            toast.error('Ни id, ни элемент не переданы.');
            return false
        }

        const agree = await confirm({question : message || 'Удалить запись ?', confirmText : 'Удалить', declineText : 'Отменить'});

        if(agree) {
            if(id) {
                await deleteHandler(id)
            }else if(item) {
                await deleteHandler(item)
            }
        }
    };

    render() {
        return <Button disabled={this.props.disabled} className="btn-success" onClick={this.delete}> <i className="fa fa-trash no-text"/></Button>
    }
}



