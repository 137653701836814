import React from 'react';
import ReactTable from 'react-table';
import Spinner from '../spinner/spinner.controller'
import './table.css'
import withLabel, {ILabelPosition} from "../withLabel/withLabel";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";


interface Props{
    label?: string,
    labelVars?:any
    labelPosition?: ILabelPosition,
    loading? : boolean
    stickyHeader? : boolean
    wrapperClassName? : string
    [key:string] : any
}

const Table = (props:Props) => {
    const {label, labelPosition, loading, stickyHeader, pageSize = 100, showPagination = false, wrapperClassName, labelVars, ...rest} = props;
    const cls = ['just-pro-table'];
    if(stickyHeader) {
        cls.push('sticky')
    }

    const table = (
        <div className={`just-pro__table-wrapper ${wrapperClassName ? wrapperClassName : ''}`}>
            <ReactTable
                {...rest}
                className={cls.join(' ')}
                NoDataComponent={() => <React.Fragment/> }
                previousText={getText('UI.table.prev')}
                nextText={getText('UI.table.next')}
                loadingText={getText('UI.table.loading')}
                noDataText={getText('UI.table.noData')}
                pageText={getText('UI.table.page')}
                ofText={getText('UI.table.from')}
                rowsText={renderToString(getText('UI.table.rows'))}
                showPageSizeOptions={false}
                showPageJump={false}
                resizable={false}
                showPagination={showPagination}
                pageSize={showPagination ? pageSize : rest.data && rest.data.length}
            />
            {loading ? <Spinner loading={loading} /> : null}

        </div>
    );
    return label ? withLabel(table)({text : label, position : labelPosition, labelVars}) : table
};

export default Table;