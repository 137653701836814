import React from 'react'
import moment, { Moment } from 'moment'
import Picker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import withLabel from '../withLabel/withLabel'
import './timePicker.css'
import RenderIf from '../../../utils/renderIf'

interface Props {
	onChange?(newValue: Moment): void

	onBlur?(newValue: Moment): void

	onClick?(...args: any): any
	className?: string
	value: Moment
	defaultValue?: Moment
	label?: string
	placeholder?: string
	disabled?: boolean
	showClear?: boolean
}

interface State {
	value: string
}

export default class TimePicker extends React.Component<Props, State> {

    state: State = {
        value: ''
    };

    onChangeHandler = (date: Moment) => {

        // console.log({date})

        this.props.onChange(date)
    };

    validate = (): boolean => {
        const {value} = this.state;
        moment(value).isValid();

        const [hours, minutes] = value.split(':');

        return (+hours >= 0 && +hours <= 23) && (+minutes >= 0 && +minutes <= 59) && value.length === 5
    };

    change = () => {

        if (this.validate()) {
            const [hours, minutes] = this.state.value.split(':');

            const result = moment().set({
                h: +hours,
                m: +minutes
            });

            // console.log({result})

            this.props.onChange(result)
        }
    };

    normalizeValue = (value: string): string => {
        const prevValue = this.state.value;
        if (value.length === 2 && prevValue?.length < 3) {
            return value + ":"
        } else if (value.length === 3 || (value?.length === 2 && prevValue?.length === 3)) {
            return value.substring(0, 2)
        }
        return value
    };

    changeInputHandler = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        const v = this.normalizeValue(value);

        const [hours, minutes] = value.split(':');

        this.setState(() => ({value: v}), this.change)

    };

    blurHandler = (e: React.FormEvent<HTMLInputElement>) => {
        if (this.validate()) {
            const [hours, minutes] = this.state.value.split(':');
            const result = moment().set({
                h: +hours,
                m: +minutes
            });
            this.props?.onBlur?.(result);
        }
    }

    focusHandler = () => {
        this.setState(() => ({value: ''}))
    }


    resetState = () => {
        const {value} = this.props;
        this.setState(() => ({
            value: value ? moment(value).format('HH:mm') : moment().startOf('d').format('HH:mm')
        }))
    };

    clear = () => {
        this.setState(() => ({value: moment().startOf('d').format("HH:mm")}))
    };

    componentDidMount(): void {
        this.resetState();
    }

    // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    //     if(!this.props.value.isSame(this.state.value)) {
    //         this.resetState()
    //     }
    // }

    render() {
        const {placeholder, label, defaultValue, disabled} = this.props;
        const {value, showClear = true, className = ''} = this.props;

        // console.log({v : this.state.value, check : +'00'})

        // const jsx = (
        //     <Picker
        //         showSecond={false}
        //         onChange={this.onChangeHandler}
        //         placeholder={placeholder}
        //         defaultValue={defaultValue}
        //         value={value}
        //         clearText="Очистить"
        //     />
        // );

        const jsx = (
            <span className={`rc-time-picker ${className}`}>
                <input
                    disabled={disabled}
                    className={`rc-time-picker-input ${this.validate() ? '' : 'rc-time-picker-panel-input-invalid'}`}
                    type="text"
                    onClick={this.props.onClick}
                    value={this.state.value}
                    onChange={this.changeInputHandler}
                    onFocus={this.focusHandler}
                    onBlur={this.blurHandler}
                />
                <RenderIf condition={showClear}>
                <span className="rc-time-picker-icon"/>
                    <a role="button" className="rc-time-picker-clear" title="Очистить" onClick={this.clear}>
                        <i className="rc-time-picker-clear-icon"/>
                    </a>
                </RenderIf>
            </span>
        );
        return label ? withLabel(jsx)({text: label}) : jsx;
    }
}
