import React from 'react';
import {
    ContractCondition,
    createContractCondition,
    getContractConditions, ModulesResponse
} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import ContractConditionController from "./contractCondition.controller";
import ContractConditionCreate from "./contractCondition.create";
import {toClassName} from "../../../utils/icons";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class ContractConditionsController extends React.Component<Props> {

    renderItem = (item:ContractCondition) => {
        return (
            <> <i className={toClassName(item.icon)}/> {item.name} </>
        )
    };

    render() {

        const {rights} = this.props;

        return (
            <BaseReferenceController
                Detail={ContractConditionController}
                getList={getContractConditions}
                listItem={{
                    render : this.renderItem,
                }}
                referenceName="contractConditions.moduleName"
                create={{
                    Component : ContractConditionCreate,
                    title : "contractConditions.addItem",
                    handler: createContractCondition
                }}
                moduleRights={rights && rights['references.contractConditions']}
            />
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights: state.application.rights
})

export default connect(mapState)(ContractConditionsController)