import React, {useState, useEffect} from 'react';
import Button from '../UI/button/button';
import getText from '../../localization/getText';
import withLabel from '../UI/withLabel/withLabel';
import Select from '../UI/select/select';
import InlineCalendar from '../UI/calendar/views/inline.controller';
import moment, {Moment} from 'moment';
import {
    getPersons,
    getFullName,
    getPersonLocations,
    getObjectName,
} from '@justpro/terminal';
import {Option} from '../UI/select/select.types';
import RenderIf from '../../utils/renderIf';
import Table from '../UI/table/table';
import {
    setBpArray,
    setBpDirections,
    setSelectedPointId,
    toggleBuildMode,
} from '../../store/map/map.actions';
import {connect} from 'react-redux';
import {buildRoutesModeType} from '../../store/map/map.types';
import checkError from '../../utils/checkError';
import Spinner from '../UI/spinner/spinner.controller';

type SelectedModeType = {
    value: buildRoutesModeType;
    label: string;
};

const CheckRoutes = ({
                         executors,
                         buildRoutesMode,
                         setBpDirections,
                         setSelectedPointId,
                         ...props
                     }) => {
    const [travelMode, setTravelMode] = React.useState('DRIVING');
    const [date, setDate] = useState<Moment>(moment());
    const [executorId, setExecutorId] = useState<number>();
    const [objects, setObjects] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkTouched, setCheckTouched] = useState<boolean>(false);

    const handleDate = (date) => {
        const isAfter = date.isAfter(moment(), 'day');
        if (!isAfter) {
            setDate(date);
        }
    };

    const chooseModeOptions = [
        {
            value: 'FACT',
            label: getText('maps.fact'),
        },
        {
            value: 'RESULT',
            label: getText('maps.final'),
        },
    ];
    const defaultModeValue = (value: string) =>
        chooseModeOptions.find((option) => option.value === value);

    const chooseMode = (option: SelectedModeType) => {
        props.toggleBuildMode(option.value);
        return Promise.resolve();
    };

    const getPersonsListMulti = async (q) => {
        const persons: Option[] = executors?.map((p) => {
            return {
                ...p,
                label: getFullName(p),
                value: p.id,
            };
        });
        return persons;
    };
    const chooseExecutor = async (option: Option) => {
        setExecutorId(option.value);
        return Promise.resolve();
    };

    const handleTableClick = (id: number) => {
        setSelectedPointId(id);
    };

    const getTotal = () => {
        const totalDistanceMeters = objects.reduce((total, obj) => {
            return total += obj.distanceValue;
        }, 0);
        return `${(totalDistanceMeters / 1000).toFixed(2)} км`
    }


    const tableColumns = [
        {
            Header: getText('common.name'),
            Footer: getText('common.total'),
            Cell: (props: any) => {
                console.log('props.original', props.original);
                return (
                    <div onClick={() => handleTableClick(props.original.id)}>
                        {`${props.original.isRoute ? 'Маршрут к: ' : ''}`}
                        {props.original.name}
                    </div>
                );
            },
        },
        {
            Header: getText('common.distance'),
            Footer: getTotal(),
            width: 150,
            Cell: (props: any) => (
                <div onClick={() => handleTableClick(props.original.id)}>
                    {(props.original.distanceValue / 1000).toFixed(2)} км
                </div>
            ),
        },
    ];

    const handleRequestLocations = async () => {
        if (executorId && date) {
            try {
                setIsLoading(true);
                await getLocations();
            } catch (e) {
                checkError(e);
            } finally {
                setIsLoading(false);
                setCheckTouched(true);
            }
        }
    };

    const getLocations = async () => {
        try {
            let personLocations = [];
            const result: any = await getPersonLocations(
                executorId,
                date.format('YYYY-MM-DD')
            );
            if (result.length) {
                personLocations = result.reduce((pl, location) => {
                    if (
                        buildRoutesMode === 'RESULT' &&
                        location.checkIn
                    ) {
                        pl.push(location);
                        return pl;
                    }
                    if(buildRoutesMode === 'FACT'){
                        pl.push(location);
                    }
                    return pl;
                }, []);
                props.setBpArray(personLocations);
                await buildRoutes(personLocations);
            } else {
                setObjects([]);
            }
        } catch (e) {
            checkError(e);
        }
    };

    const buildRoutes = async (bpArray) => {
        let waypoints = [];
        let allPoints;
        if (buildRoutesMode === 'FACT') {
            allPoints = bpArray.map((bp) => {
                return {
                    lat: bp.latitude,
                    lng: bp.longitude,
                };
            });
        } else {
            allPoints = bpArray.map((bp) => {
                return {
                    lat: bp.object?.latitude,
                    lng: bp.object?.longitude,
                };
            });
        }
        const {google}: any = window;
        const directionsService = new google.maps.DirectionsService();
        waypoints = allPoints.map((location) => ({
            object: location.object,
            location,
        }));
        const routeParams = {
            travelMode: travelMode,
            origin: waypoints[0]?.location,
            destination: waypoints[waypoints.length - 1]?.location,
            waypoints: waypoints.slice(1, -1).slice(0, 24).map(({location}) => ({
                location,
            })),
        };
        await directionsService.route(routeParams, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                const distances = result.routes[0].legs;
                const objectsWithDistances = bpArray.map((point, index) => {
                    return {
                        id: point.id,
                        isRoute: point.route,
                        name: point?.object ? getObjectName(point.object) : 'Нет объекта',
                        distance: `${index ? distances[index - 1].distance.text : '0 км'}`,
                        distanceValue: index ? distances[index - 1].distance.value : 0
                    };
                });
                setObjects(objectsWithDistances);
                setBpDirections(result);
            } else {
                console.error(`error fetching directions ${result}`);
            }
        });
    };

    return (
        <div>
            <Spinner loading={isLoading}/>
            <Select
                change={chooseMode}
                label="maps.chooseMode"
                defaultOptions={chooseModeOptions}
                defaultValue={defaultModeValue(buildRoutesMode)}
            />
            <Select
                change={chooseExecutor}
                load={getPersonsListMulti}
                label="maps.chooseExecutor"
                placeholder="UI.select.placeholders.chooseResponsible"
            />
            <InlineCalendar
                date={date}
                onChange={handleDate}
                label={{text: 'maps.chooseDate'}}
            />
            <Button
                className="btn-success"
                disabled={false}
                onClick={handleRequestLocations}
            >
                {getText('common.showRoute')}
            </Button>
            <RenderIf condition={checkTouched && !objects.length}>
                <p className="noPointsForDay">На эту дату не было точек</p>
            </RenderIf>
            <RenderIf condition={checkTouched && objects.length}>
                {withLabel(
                    <div className="check-routes_table">
                        <Table data={objects} columns={tableColumns}/>
                    </div>
                )({
                    position: 'top',
                    text: 'maps.route',
                })}
            </RenderIf>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        buildRoutesMode: state?.maps?.buildRoutesMode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBpArray: (array) => {
            dispatch(setBpArray(array));
        },
        setBpDirections: (directions) => {
            dispatch(setBpDirections(directions));
        },
        toggleBuildMode: (array) => {
            dispatch(toggleBuildMode(array));
        },
        setSelectedPointId: (id) => {
            dispatch(setSelectedPointId(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckRoutes);
