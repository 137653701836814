import React from 'react';
import InlineDropzone from "../dropzone/inline/inlineDropzone.controller";
import {AddFile, File as SeverFile} from '@justpro/terminal'
import {toast} from "react-toastify";
import Spinner from '../spinner/spinner.controller'
import './files.css'
import File from "./file.preview";
import FancyBox from "../fancybox_new/fancybox.controller";

interface Props {
    files? : SeverFile[]
    setFiles(files:AddFile[]) : void
    unsetFile(fileId:number) : void
    isLoading : boolean
    // updateList?(id:number, files:SeverFile[]) : void
}


class Files extends React.Component<Props>{

    setFiles = async (acceptedFiles:File[]) => {
        this.setState(() => ({filesLoading : true}));

        const files = acceptedFiles.map(file => ({name : file.name, blob : file}));
        await this.props.setFiles(files);

        this.setState(() => ({filesLoading : false}));
    };

    getPhotoDetail = (e:React.MouseEvent, path:string) => {
        //todo fancybox
        e.stopPropagation();
        console.log({e, path})
    };

    clickHandler = (e:React.MouseEvent, id?:number) => {
        e.stopPropagation();
        this.deleteFile(id);
    };

    deleteFile = async (id?:number) => {
        if(id) {
            this.setState(() => ({filesLoading : true}));

            await this.props.unsetFile(id);

            this.setState(() => ({filesLoading : false}));
        }else{
            toast.error('id is not specified (frontend)')
        }
    };


    render() {
        const {files} = this.props;

        return (
            <>
                <div className="just-pro__files_preview">
                    {files && files.map(file => {
                        return (
                            <div className="just-pro__file-wrapper" key={file.id} >
                                <span className="just-pro__file-close" onClick={(e:React.MouseEvent) => this.clickHandler(e, file.id)}>x</span>
                                <File
                                    file={file}
                                    files={files}
                                    withDelete={{
                                    handler : this.deleteFile.bind(this, file.id)
                                }}/>
                            </div>
                        )
                    })}
                </div>

                <div style={{position: 'relative'}}>
                    <InlineDropzone
                        setFiles={this.setFiles}
                    />

                    <Spinner loading={this.props.isLoading}/>
                </div>

            </>
        )
    }
}


export default Files