import {ModulesResponse, SettingsMap} from "@justpro/terminal";
export type lang = 'ua' | 'ru'

export interface ApplicationReducer {
    rights?: Partial<ModulesResponse>
    settings? : SettingsMap,
    me : any
    locale : string
    comments: boolean
    notifications: boolean
}

export const initialState:ApplicationReducer = {
    settings : undefined,
    me : undefined,
    rights: undefined,
    comments: false,
    notifications: false,
    locale : 'ru'
};

export type AvailableLocales = "ru" | "uk"