import React, { useEffect } from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  DirectionsRenderer,
} from 'react-google-maps';
import CustomMarker from './markers/customMarker';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {
  ProvideredConnectedInfoWindow as ObjectInfoWindow,
  ObjectDisplayName,
} from './markers/markerInnerWindows/objectInner';
import {
  ProvideredConnectedInfoWindow as ExecutorInfoWindow,
  ExecutorDisplayName,
} from './markers/markerInnerWindows/executorInner';
import RenderIf from '../../utils/renderIf';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import EXECUTOR_DEFAULT_MARKER from './icons/executors/EXECUTOR_DEFAULT_MARKER.svg';
import EXECUTOR_WITH_WO_MARKER from './icons/executors/EXECUTOR_WITH_WO_MARKER.svg';
import EXECUTOR_WITHOUT_WO_MARKER from './icons/executors/EXECUTOR_WITHOUT_WO_MARKER.svg';

import OBJECT_DEFAULT_MARKER from './icons/objects/OBJECT_DEFAULT_MARKER.svg';
import OBJECT_EXPIRED_MARKER from './icons/objects/OBJECT_EXPIRED_MARKER.svg';
import OBJECT_WO_MARKER from './icons/objects/OBJECT_WO_MARKER.svg';
import OBJECT_DISABLED_MARKER from './icons/objects/OBJECT_DISABLED_MARKER.svg';
import OBJECT_HOT_MARKER from './icons/objects/OBJECT_HOT_MARKER.svg';

import CURSOR_POINT from './icons/CURSOR_POINT.svg';
import BASE_POINT from './icons/BASE_POINT.svg';
import REGION_POINT from './icons/REGION_POINT.svg';

import {
  setArrangedWoArray,
  setSelectedPointId,
} from '../../store/map/map.actions';
import { URGENT_1S, ACCIDENT_1S } from './const';
import { getObjectName } from '@justpro/terminal';

export const clusterStyles = [
  {
    height: 53,
    url: '//ccplugins.co/markerclusterer/images/m1.png',
    width: 53,
  },
];

const defineObjectCurrentColor = (
  obj,
  { ordersCanWorkorderStatus, orderToExecuteStatus, colorByAccident }
) => {
  if (colorByAccident) {
    switch (true) {
      case obj.orders?.some(
        (order) =>
          order.contractOrdersType?.priority1s?.id1s == ACCIDENT_1S &&
          order.orderStatus.id == ordersCanWorkorderStatus
      ):
        return OBJECT_EXPIRED_MARKER;
      case obj.orders?.some(
        (order) =>
          order.contractOrdersType?.priority1s?.id1s == URGENT_1S &&
          order.orderStatus.id == ordersCanWorkorderStatus
      ):
        return OBJECT_HOT_MARKER;
      case obj.orders?.some(
        (order) => order.orderStatus.id == ordersCanWorkorderStatus
      ):
        return OBJECT_WO_MARKER;
      default:
        return OBJECT_DEFAULT_MARKER;
    }
  } else {
    switch (true) {
      case obj.notInFilter:
        return OBJECT_DISABLED_MARKER;
      case obj.orders?.some(
        (order) => order.orderStatus.id == orderToExecuteStatus
      ):
        return OBJECT_WO_MARKER;
      case obj.orders?.some((order) => order.hot === true):
        return OBJECT_HOT_MARKER;
      case obj.orders?.some((order) => order.expired === true):
        return OBJECT_EXPIRED_MARKER;
      default:
        return OBJECT_DEFAULT_MARKER;
    }
  }
};

const defineExecutorCurrentColor = (exec) => {
  const date = moment().format('YYYY-MM-DD');
  const t = exec.workorders.find((el) => el.date == date);
  if (exec.isWorking && t) return EXECUTOR_WITH_WO_MARKER;
  if (exec.isWorking && !t) return EXECUTOR_WITHOUT_WO_MARKER;
  return EXECUTOR_DEFAULT_MARKER;
};

const GMap: React.FC<any> = ({
  objects,
  disabledObjects,
  directions,
  executors,
  basePoints,
  position,
  showNames,
  showExecutors,
  openedObject,
  orderToExecuteStatus,
  ordersCanWorkorderStatus,
  bpArray,
  buildRoutesMode,
  routeing,
  arrangedWoArray,
  colorByAccident,
  selectedPointId,
  setSelectedPointId,
  ...props
}) => {
  const mapRef = React.useRef(null);
  const [travelMode, setTravelMode] = React.useState('DRIVING');
  const [bpSelectedPartDirections, setBpSelectedPartDirections] =
    React.useState(null);
  const [bpDirections, setBpDirections] = React.useState(null);

  useEffect(() => {
    setSelectedPointId(null);
    setBpSelectedPartDirections(null);
    setBpDirections(props.bpDirections);
  }, [props.bpDirections]);

  const handleSelectedPoint = (id) => {
    if (selectedPointId === id) {
      setSelectedPointId(null);
    }
    if (selectedPointId !== id) {
      setSelectedPointId(id);
    }
  };

  const objectsToRender = React.useMemo(() => {
    if (Array.isArray(objects)) {
      return objects.map((obj, i) => {
        if (!obj.latitude) return;
        const currentImage = defineObjectCurrentColor(obj, {
          colorByAccident,
          orderToExecuteStatus,
          ordersCanWorkorderStatus,
        });
        return (
          <CustomMarker
            key={obj.id * i}
            object={obj}
            position={{
              lat: Number.parseFloat(obj.latitude),
              lng: Number.parseFloat(obj.longitude),
            }}
            clickedComponent={(props) => (
              <ObjectInfoWindow
                {...props}
                object={obj}
                position={{ lat: obj.latitude, lng: obj.longitude }}
              />
            )}
            hoveredComponent={ObjectDisplayName}
            showNames={showNames}
            icon={currentImage}
          />
        );
      });
    }
  }, [objects, showNames, colorByAccident]);

  const executorsToRender = React.useMemo(() => {
    if (Array.isArray(executors) && showExecutors) {
      return executors.map((exec, i) => {
        if (!exec.latitude) return;
        const currentImage = defineExecutorCurrentColor(exec);
        return (
          <CustomMarker
            key={i}
            position={{ lat: +exec.latitude, lng: +exec.longitude }}
            clickedComponent={(props) => (
              <ExecutorInfoWindow
                {...props}
                executor={exec}
                position={{
                  lat: exec.latitude,
                  lng: exec.longitude,
                }}
              />
            )}
            hoveredComponent={ExecutorDisplayName}
            icon={currentImage}
          />
        );
      });
    }
  }, [executors?.length, showExecutors]);

  const basePointsToRender = React.useMemo(() => {
    if (basePoints && Array.isArray(basePoints)) {
      return basePoints.map((bp, i) => {
        console.log('bp', bp);
        return (
          <CustomMarker
            key={i}
            position={{ lat: +bp.latitude, lng: +bp.longitude }}
            icon={bp.region ? BASE_POINT : REGION_POINT}
            hoveredComponent={() => (
              <>
                <div className="object-inner_dash"></div>
                <p className="object-inner_name">
                  {' '}
                  {`${bp.city?.name}, ${bp.address}`}
                </p>
              </>
            )}
          />
        );
      });
    }
  }, [basePoints?.length]);

  const determineBasePoinIcon = (bp) => {
    if (bp.route) {
      return CURSOR_POINT;
    }
    if (bp.personId) {
      return BASE_POINT;
    }
    if (bp.region) {
      return REGION_POINT;
    }
    return OBJECT_WO_MARKER;
  };

  const BpRouteDisplayName = (object) => {
    return (
      <div className="object-inner_caption">
        <>
          <div className="object-inner_dash"></div>
          <p className="object-inner_name">
            Маршрут к: {getObjectName(object)}
          </p>
        </>
      </div>
    );
  };

  const bpRoutePoints = React.useMemo(() => {
    if (bpArray && Array.isArray(bpArray)) {
      return bpArray.map((bp, i) => {
        if (buildRoutesMode === 'FACT') {
          return (
            <CustomMarker
              key={i}
              position={{ lat: +bp.latitude, lng: +bp.longitude }}
              icon={determineBasePoinIcon(bp)}
              onClick={() => handleSelectedPoint(bp.id)}
              object={bp.object}
              hoveredComponent={
                bp.route
                  ? () => BpRouteDisplayName(bp.object)
                  : ObjectDisplayName
              }
            />
          );
        } else {
          return (
            <CustomMarker
              key={i}
              position={{
                lat: +bp.object?.latitude,
                lng: +bp.object?.longitude,
              }}
              icon={determineBasePoinIcon(bp)}
              onClick={() => handleSelectedPoint(bp.id)}
              object={bp.object}
              hoveredComponent={
                bp.route
                  ? () => BpRouteDisplayName(bp.object)
                  : ObjectDisplayName
              }
            />
          );
        }
      });
    }
  }, [bpArray?.length, buildRoutesMode]);

  useEffect(() => {
    buildSelectedPartRoute();
  }, [selectedPointId]);

  const buildSelectedPartRoute = async () => {
    const neededIndex = bpArray.findIndex((bp) => bp.id === selectedPointId);
    if (neededIndex < 1) return;
    const selectedPart = bpArray.slice(neededIndex - 1, neededIndex + 1);
    let waypoints = [];
    let allPoints;
    if (buildRoutesMode === 'FACT') {
      allPoints = selectedPart.map((bp) => {
        return {
          lat: bp.latitude,
          lng: bp.longitude,
        };
      });
    } else {
      allPoints = selectedPart.map((bp) => {
        return {
          lat: bp.object?.latitude,
          lng: bp.object?.longitude,
        };
      });
    }
    const { google }: any = window;
    const directionsService = new google.maps.DirectionsService();
    waypoints = allPoints.map((location) => ({
      object: location.object,
      location,
    }));
    const routeParams = {
      travelMode: travelMode,
      origin: waypoints[0]?.location,
      destination: waypoints[1]?.location,
    };
    directionsService.route(routeParams, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setBpSelectedPartDirections(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    });
  };

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={11}
      center={position || { lat: 50.27, lng: 30.31 }}
      options={{ disableDefaultUI: true, zoomControl: true }}
    >
      <RenderIf condition={props.mode === 'CHECK_ROUTES'}>
        <RenderIf condition={selectedPointId}>
          <DirectionsRenderer
            directions={bpSelectedPartDirections}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeOpacity: 0.7,
                strokeWeight: 10,
                strokeColor: '#4a81ff',
              },
            }}
          />
        </RenderIf>
        <DirectionsRenderer
          directions={bpDirections}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeOpacity: 1,
              strokeWeight: 3,
              strokeColor: '#04c774',
            },
          }}
        />
        {bpRoutePoints}
      </RenderIf>
      <RenderIf condition={props.mode === 'DEFAULT'}>
        {executorsToRender}
        {basePointsToRender}
        <MarkerClusterer styles={clusterStyles} minimumClusterSize={10}>
          {objectsToRender}
        </MarkerClusterer>
        <DirectionsRenderer
          directions={directions}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeOpacity: 0.7,
              strokeWeight: 5,
              strokeColor: '#0777cd',
            },
          }}
        />
      </RenderIf>
    </GoogleMap>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state?.maps?.mode,
    selectedPointId: state?.maps?.selectedPointId,
    buildRoutesMode: state?.maps?.buildRoutesMode,
    bpArray: state?.maps?.bpArray,
    bpDirections: state?.maps?.bpDirections,
    isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
    arrangedWoArray: state?.maps?.arrangedWoArray,
    startPosition: state?.maps?.currentExecutor?.position,
    ordersCanWorkorderStatus:
      state?.application?.settings?.ordersCanWorkorderStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setArrangedWoArray: (object) => {
      dispatch(setArrangedWoArray(object));
    },
    setSelectedPointId: (id) => {
      dispatch(setSelectedPointId(id));
    },
  };
};

const ConnectedMap = connect(mapStateToProps, mapDispatchToProps)(GMap);
const WrappedMap: any = withScriptjs(
  withGoogleMap((props) => <ConnectedMap {...props} />)
);

export default WrappedMap;

// export default React.memo(WrappedMap, (props, nextProps) => props?.objects?.length === nextProps?.objects?.length)
