import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Props, MainNavList, MapState, State } from './mainNav.types'
import {
	getUserMe,
	getSettings,
} from '../../store/application/application.actions'
import { connect } from 'react-redux'
import { ModulesResponse } from '@justpro/terminal'
import CreateChecklist from '../UI/dialog/createChecklist/createChecklist'
import Settings from '../settings/settings.controller'
import { openModal } from '../../store/modal/modal.actions'
import { Modal, ModalBodyProps } from '../../store/modal/modal.types'
import getText from '../../localization/getText'
import CreateOrder from '../UI/dialog/createOrderModal/createOrderModal.controller'
import CreateTaskController from '../UI/dialog/createTask/createTask.controller'
import CreateAdminWorkorder from '../UI/dialog/createAdminWorkorder/createAdminWorkorder.controller'
import './mainNav.css'
import RenderIf from '../../utils/renderIf'
import CreateChecklistObject from '../UI/dialog/createChecklistObject/createChecklistObject'

interface MainNavModals {
	CREATE_ORDER: 'createOrder'
	CREATE_CHECKLIST: 'createChecklist'
	CREATE_CHECKLIST_OBJECT: 'createChecklistObject'
	CREATE_TASK: 'createTask'
	CREATE_ADMIN_WORKORDER: 'createAdminWorkorder'
	SETTINGS: 'settings'
}

export type MainNavKey = MainNavModals[keyof MainNavModals]

type MainNavModalMap = {
	[P in MainNavKey]: Modal
}

export const orderModalId = 'createOrder'
export const taskModalId = 'taskModal'
export const checklistModalId = 'checklistModal'
export const checklistObjectModalId = 'checklistObjectModal'
export const adminWorkorderModalId = 'adminWorkorderModalId'

const MAIN_NAV_MODAL_MAP: MainNavModalMap = {
	createOrder: {
		id: orderModalId,
		title: getText('orders.orderCreating'),
		size: 'large',
		component: (props: ModalBodyProps) => <CreateOrder {...props} />,
	},
	createChecklistObject: {
		id: checklistObjectModalId,
		title: getText('workorders.createChecklistObject'),
		size: 'large',
		component: (props: ModalBodyProps) => (
			<CreateChecklistObject {...props} />
		),
		minHeight: '60vh',
	},
	createChecklist: {
		id: checklistModalId,
		title: getText('workorders.createChecklist'),
		size: 'large',
		component: (props: ModalBodyProps) => <CreateChecklist {...props} />,
		minHeight: '60vh',
	},
	createAdminWorkorder: {
		id: adminWorkorderModalId,
		title: getText('workorders.createAdminWorkorder'),
		size: 'large',
		component: (props: ModalBodyProps) => (
			<CreateAdminWorkorder {...props} />
		),
		minHeight: '75vh',
	},
	createTask: {
		id: taskModalId,
		title: getText('tasks.createTask'),
		size: 'large',
		component: (props: ModalBodyProps) => (
			<CreateTaskController {...props} />
		),
	},
	settings: {
		id: 'settings',
		title: getText('settings.personalSettings'),
		size: 'medium',
		component: (props: ModalBodyProps) => <Settings {...props} />,
	},
}

class MainNav extends Component<Props, State> {
	state: State = {
		scroll: false,
		scrollHelperTop: false,
		scrollHelperBottom: false,
	}

	clickModalHandler = (modal: MainNavKey) => {
		this.props.openModal(MAIN_NAV_MODAL_MAP[modal])
	}

	onMouseOverHandler = (
		e: React.MouseEvent<HTMLLIElement>,
		children?: MainNavList,
		id?: string,
	) => {
		const target = e.currentTarget

		const menu = document.querySelector<HTMLDivElement | undefined>(
			'.main-nav',
		)

		this.setState({
			navChildren: children,
			childrenOffsetTop: 60 + target.offsetTop - (menu?.scrollTop || 0),
			hoveredId: id,
		})
	}

	onMouseLeaveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		this.setState({ navChildren: undefined, hoveredId: undefined })
	}

	getList = (root: MainNavList, mouseOverHandler?: any) => {
		const { rights } = this.props
		if (rights === undefined) {
			return null
		}

		return root.map((item, index) => {
			const bigChildren =
				item.children && item.children && item.children.length > 5
			const isAvailable = item.isAvailable(rights as ModulesResponse)
			const id = item.path + index
			const cls = `main-nav__list-item${
				id === this.state.hoveredId ? ' --hover' : ''
			}`

			return isAvailable ? (
				item.path ? (
					<li
						key={id}
						onMouseEnter={
							mouseOverHandler
								? (e) => mouseOverHandler(e, item.children, id)
								: undefined
						}
						id={id}
						className={cls}
					>
						<NavLink to={item.path}>
							{item.icon ? <i className={item.icon} /> : ''}
							{getText(item?.title || '')}
						</NavLink>
						<RenderIf
							condition={
								item.path === '/news' &&
								this.props?.me?.settings?.haveNews
							}
						>
							<div className='is-new-circle'></div>
						</RenderIf>
						{item.children && (
							<ul
								className={
									bigChildren ? 'big-item scroll-panel' : ''
								}
							>
								{this.getList(item.children)}
							</ul>
						)}
					</li>
				) : item.modal ? (
					<li
						key={index}
						onClick={this.clickModalHandler.bind(this, item.modal)}
					>
						<a>{getText(item.title)}</a>
					</li>
				) : item.handler ? (
					<li key={index} onClick={item.handler}>
						<a>{getText(item.title)}</a>
					</li>
				) : null
			) : null
		})
	}

	renderChildren = () => {
		const { navChildren } = this.state
		if (!navChildren) return null
		return this.getList(navChildren)
	}

	resetSettings = async () => {
		if (this.props.settings === undefined) {
			await this.props.getSettings()
		}
	}

	resetRights = async () => {
		if (this.props.rights === undefined) {
			await this.props.getUserMe()
		}
	}

	scrollHandler = () => {
		const list = document.querySelector<HTMLDivElement | undefined>(
			'.main-nav',
		)

		const scrollExists = !(list?.scrollHeight === list?.offsetHeight)
		const showTopScroll = list?.scrollTop !== 0
		const showBottomScroll = !(
			list?.scrollTop + list?.clientHeight ===
			list?.scrollHeight
		)

		this.setState({
			scroll: scrollExists,
			scrollHelperTop: showTopScroll,
			scrollHelperBottom: showBottomScroll,
		})
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		const { rights, settings } = this.props
		if (JSON.stringify(prevProps.settings) !== JSON.stringify(settings)) {
			this.resetSettings()
		}

		if (JSON.stringify(prevProps.rights) !== JSON.stringify(rights)) {
			this.resetRights()
			this.scrollHandler()
		}
	}

	async componentDidMount() {
		this.resetRights()
		this.resetSettings()
		this.scrollHandler()
	}

	render() {
		const { navChildren, scrollHelperBottom, scrollHelperTop, scroll } =
			this.state
		const bigChildren = navChildren && navChildren.length > 5
		const cls = `${
			bigChildren ? 'big-item scroll-panel' : ''
		} left-menu-children`

		return (
			<div className='left-line' onMouseLeave={this.onMouseLeaveHandler}>
				<a href='#' rel='noopener' className='logo'>
					<img src='/images/logotyp.png' alt='Лого' />
				</a>

				<div className='main-nav' onScroll={this.scrollHandler}>
					{/*<RenderIf condition={scroll && scrollHelperTop}>*/}
					{/*    <div*/}
					{/*        className="main-nav__scroll-helper top"*/}
					{/*    />*/}
					{/*</RenderIf>*/}

					<ul className='left-menu'>
						{this.props.list
							? this.getList(
									this.props.list,
									this.onMouseOverHandler,
							  )
							: null}
					</ul>
					{/*<RenderIf condition={scroll && scrollHelperBottom}>*/}
					{/*    <div className="main-nav__scroll-helper bottom"/>*/}
					{/*</RenderIf>*/}
				</div>

				<ul
					className={cls}
					style={{ top: this.state.childrenOffsetTop }}
				>
					{this.renderChildren()}
				</ul>
			</div>
		)
	}
}

const mapStateToProps = (state: MapState) => ({
	rights: state.application.rights,
	settings: state.application.settings,
	me: state.application.me,
})

const mapDispatchToProps = (dispatch: Function) => ({
	getUserMe: () => dispatch(getUserMe()),
	getSettings: () => dispatch(getSettings()),
	openModal: (props: Modal) => dispatch(openModal(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainNav)
