import React from 'react'

import checkError from '../../../utils/checkError'
import { editPerson, getPerson, Person, UpdatePerson } from '@justpro/terminal'
import Spinner from '../../UI/spinner/spinner.controller'
import RenderIf from '../../../utils/renderIf'
import PersonDetail from './person.detail'

type Props = {
	id: number
	afterUpdate?(person: Person): void
}

type State = {
	loading: boolean
	person?: Person
}

export default class PersonController extends React.Component<Props, State> {
	state: State = {
		loading: false,
	}

	getPerson = async () => {
		try {
			this.setState(() => ({ loading: true }))

			const person = await getPerson(this.props.id)

			if (person) {
				this.setState(() => ({ loading: false, person }))
			}
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	updatePerson = async (data: Partial<UpdatePerson>) => {
		try {
			this.setState(() => ({ loading: true }))

			const person = await editPerson(this.props.id, data)

			if (person) {
				if (
					data.hasOwnProperty('firstName') ||
					data.hasOwnProperty('lastName') ||
					data.hasOwnProperty('patronymic')
				) {
					this.props.afterUpdate && this.props.afterUpdate(person)
				}
			}

			if (person) {
				this.setState(() => ({ loading: false, person }))
			}
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	) {
		if (this.props.id !== prevProps.id) {
			this.getPerson()
		}
	}

	async componentDidMount() {
		this.getPerson()
	}

	render() {
		const { person } = this.state

		return (
			<>
				<RenderIf condition={person !== undefined}>
					<PersonDetail
						person={person as Person}
						update={this.updatePerson}
						getPerson={this.getPerson}
					/>
				</RenderIf>
				<Spinner loading={this.state.loading} />
			</>
		)
	}
}
