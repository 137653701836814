import {AnyAction} from "redux";
import {ModalReducer, initialState, Modal,} from "./modal.types";

export const modalActions = {
    HIDE_MODAL : "HIDE_MODAL",
    OPEN_MODAL : "OPEN_MODAL",
    UPDATE_MODAL : "UPDATE_MODAL",
    HIDE_ALL : "HIDE_ALL",
};

export default (state:ModalReducer = initialState, action:AnyAction):ModalReducer => {
    const {type, ...rest} = action;

    switch (type) {
        case  modalActions.HIDE_MODAL :
            return {
                ...state,
                modals : state.modals?.filter(modal => modal.id !== rest.id)
            };
        case  modalActions.HIDE_ALL :
            return {
                ...state,
                modals : []
            };
        case  modalActions.OPEN_MODAL :
            return {
                ...state,
                modals : [ ...state.modals, rest as Modal]
            };
        case  modalActions.UPDATE_MODAL :
            return {
                ...state,
                modals : state.modals?.map(item => {
                    if(item.id === rest.id) {
                        return {
                            ...item,
                            ...rest,
                        }
                    }
                    return item
                })
            }
    }

    return state
}