import React from 'react';
import {CreateRegularStatus} from "@justpro/terminal";
import IconPicker from '../../UI/iconPiker/iconPicker.controller'
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import {fromClassName, getIconsList} from "../../../utils/icons";
import InputColor from '../../UI/input/color'
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateRegularStatus) : void
}

const icons = getIconsList();

interface State {
    icon? : string
    name?: string
    color? : string

}

export default class RegularStatusCreate extends React.Component<Props, State>{

    state:State = {};

    changeIcon = (iconClassName:string) => {
        const [prefix, iconName] = fromClassName(iconClassName);
        const icon = `${prefix}:${iconName}`;

        this.setState(() => ({icon}))
    };

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };


    changeColor = (color:string) => {
        this.setState(() => ({color}))
    };

    isDisabled = () => {
        const {icon, name, color} = this.state;

        return  icon === undefined ||
                color === undefined ||
                (name === undefined || name.trim() === '')
    };


    createStatus = () => {
        const {icon, name, color} = this.state;
        if(icon && name && color) {
            this.props.create({
                icon,
                name,
                color
            })
        }
    };

    render () {
        return (
            <>
                <h4>{getText('regularStatuses.addingNewHeader')}</h4>

                <Input label="regularStatuses.regularStatus" change={this.changeName}/>

                <IconPicker
                    list={icons}
                    change={this.changeIcon}
                    label="common.icon"
                />

                <InputColor label="common.color" blur={this.changeColor} />

                <Button className="btn-success" onClick={this.createStatus} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}