import React from "react";
import './tabsContent.css'
import TabContentHeader from "./tabContentHeader";
import withLabel from "../../UI/withLabel/withLabel";
import checkError from "../../../utils/checkError";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    me?: any
    onChange(field: string, a: boolean): any
}

interface State {
    taskChangeNotifications: boolean
    taskChangeWatcherNotifications: boolean
    taskChangeInitiatorNotifications: boolean
    taskView: boolean
    ordersComments: boolean

}

class Notifications extends React.Component<Props, State> {

    state: State = {
        taskChangeNotifications: false,
        taskChangeWatcherNotifications: false,
        taskChangeInitiatorNotifications: false,
        taskView: false,
        ordersComments: false
    };

    onChange(field: string, event: any){
        this.props?.onChange && this.props.onChange(field, !!event?.target?.checked);
    };

    render() {
        const {me} = this.props;
        return (
            <div className="settings-tab-content">
                <TabContentHeader title="UI.tabs.notifications"/>

                <div>
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.taskChangeNotifications}
                                       onChange={this.onChange.bind(this, "taskChangeNotifications")}
                                />
                                {getText('settings.acceptNotificationInTasks')} </label>
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.taskChangeWatcherNotifications}
                                       onChange={this.onChange.bind(this, "taskChangeWatcherNotifications")}
                                />
                                {getText('settings.acceptNotificationWatcher')}
                            </label>
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.taskChangeInitiatorNotifications}
                                       onChange={this.onChange.bind(this, "taskChangeInitiatorNotifications")}
                                /> {getText('settings.acceptNotificationInitiator')}

                            </label>
                        </div>
                    )({text: 'settings.journal'})}
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.taskView}
                                       onChange={this.onChange.bind(this, "taskView")}
                                />
                                {getText('settings.tasksView')}
                            </label>
                        </div>
                    )({text: 'settings.historyView'})}
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.ordersComments}
                                       onChange={this.onChange.bind(this, "ordersComments")}
                                />
                                {getText('settings.watchingForNewCommentsInOrders')}
                            </label>
                        </div>
                    )({text: 'UI.tabs.comments'})}
                </div>
            </div>
        )
    }
}

const mapState = (state: any) => ({
    me: state.application.me
})

export default connect(mapState)(Notifications)