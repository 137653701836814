import React from "react";
import {Workorder} from "@justpro/terminal";
import ModuleListItem from "../UI/itemsList/moduleListItem/moduleListItem.controller";
import ListIcons from "../UI/itemsList/icons/icons.controller";
import moment from "moment";
import WithModuleDetail from "../UI/dialog/withModuleDetail/withModuleDetail.controller";
import WorkOrderController from "./workOrder.controller";
import Tooltip from "../UI/tooltip/tooltip.controller";
import {Angle} from "../UI/itemsList/card/card.view";
import getText from "../../localization/getText";

interface Props {
    workOrder : Workorder,

    getListItem?(workOrder:Workorder) : void


    withInclude? : {
        handler(workOrder:Workorder) : void
        title : string
    }

    activeItemId? : number

    withModuleDetail? : boolean
}

export default function WorkOrderListItem(props:Props) {
    const item = props.workOrder;

    const listItemClickHandler = (e:React.MouseEvent, workOrder:Workorder) => {
        props.getListItem && props.getListItem(workOrder)
    };

    const addAction = (e:React.MouseEvent, workOrder:Workorder) => {
        props.withInclude && props.withInclude.handler(workOrder)
    };

    return (
        <ModuleListItem
            isActive={props.activeItemId === item.id}
            onClick={(e) => listItemClickHandler(e, item)}
        >
            <Angle color={item.closed ? "#de1118" : undefined} size={30}/>

            <div className="icon-zone">
                <ListIcons
                    checklist={item.type === 'checklist'}
                    admin={item.type === 'admin'}
                />
            </div>

            <div className="body">

                <div className="responsible-name">
                    {item.responsible.lastName} {item.responsible.firstName}
                </div>
            </div>

            <div className="number">
                <b>{item.number}</b>
                <span>{moment(item.date).format('DD.MM.YYYY')}</span>
            </div>

            <div className="helpers">
                {!!props.withModuleDetail && (
                    <WithModuleDetail
                        element={
                            <>
                                <Tooltip title="common.openDetailInfo" position="left">
                                    <i className="fa fa-expand expand" />
                                </Tooltip>
                            </>}
                        modal={{
                            id : `workorder-${item.id}`,
                            component : () => (
                                <WorkOrderController id={item.id}/>
                            ),
                            title : getText('workorders.workorder'),
                        }}
                    />
                ) }

                {props.withInclude !== undefined && (
                    <>
                        <Tooltip title={props.withInclude.title} position="left" >
                            <i className="fa fa-plus add" onClick={(e) => addAction(e, item)} />
                        </Tooltip>
                    </>
                )}
            </div>

        </ModuleListItem>
    )
}