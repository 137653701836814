import React from 'react';
import {getOrder, editOrder, IUpdateOrder, Order, ModulesResponse, createOrderWaitRequest, ICreateOrderWaitRequest, format} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import OrderReadMode from "./orderRead.controller";
import Spinner from "../../UI/spinner/spinner.controller";
import '../orders.css'
import OrderEditMode from "./orderEdit.controller";
import {UpdateOrderList} from "../../../store/orders/orders.types";
import {updateOrderList} from "../../../store/orders/orders.actions";
import {connect} from "react-redux";
import WaitRequestModal from "./waitRequstModal";
import {ApplicationMapState} from "../../application/application.controller";
import HasNoRightsController from "../../UI/hasNoRights/hasNoRights.controller";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import getText from "../../../localization/getText";
import OrderPreloader from "../order.preloader";
import {taskWaitRequestModalId} from "../../tasks/task.orders";

interface Props {
    id : number
    updateOrderList?(orderId:number, data:UpdateOrderList) : void
    rights? : Partial<ModulesResponse>
    openModal(props:Modal) : void
}

export type OrderDetailMode = "read" | 'edit'

interface State {
    mode : OrderDetailMode,
    loading : boolean,
    order?: Order,
    isOpenWaitRequestModal : boolean
}


class OrderDetail extends React.Component<Props, State>{

    state:State = {
        mode : "read",
        loading : false,
        isOpenWaitRequestModal : false

    };

    getEditMode = () => {
        this.setState(() => ({mode : 'edit'}))
    };

    updateOrder = async (data:Partial<IUpdateOrder>, updateListData:UpdateOrderList) => {
        const {id, updateOrderList} = this.props;
        const {rights} = this.props;
        const canEdit = rights && rights['orders'] && rights['orders']['edit'];

        if(canEdit) {
            if(Object.entries(data).length > 0) {
                this.setState(() => ({loading : true}));

                try {
                    const order = await editOrder(this.props.id, data);

                    this.setState(() => ({
                        order,
                        loading : false,
                        mode: "read"
                    }))

                }catch (e) {
                    this.setState(() => ({loading : false}));
                    checkError(e)
                }
            }

            if(id && Object.entries(updateListData).length > 0 && updateOrderList) {
                updateOrderList(id, updateListData)
            }
        }

    };

    dismissUpdate = () => {
        this.setState(() => ({mode : 'read'}));
    };

    getOrder = async (id? : number) => {
        const {rights} = this.props;
        const canRead = rights && rights['orders'] && rights['orders']['read'];

        if(canRead) {
            this.setState(() => ({loading : true}));
            try {

                const _id = id ? id : this.props.id;

                const order = await getOrder(_id);

                this.setState(() => ({
                    order,
                    loading : false,
                    mode: "read"
                }))

            }catch (e) {
                this.setState(() => ({loading : false}));
                checkError(e)
            }
        }
    };

//todo await this.getOrder();

    openWaitRequestModal = () => {
        this.props.openModal({
            id : taskWaitRequestModalId,
            component : (props:ModalBodyProps) => <WaitRequestModal {...props} createWaitRequest={this.createWaitRequest} />,
            title : getText('orders.waitRequest'),
            afterClose : async () => {
                await this.getOrder();
            },
            minHeight : '40vh',
        });

        this.setState(() => ({isOpenWaitRequestModal : true}))
    };

    componentDidMount() {
        return this.getOrder()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            await this.getOrder()
        }
    }

    createWaitRequest = async (data: ICreateOrderWaitRequest) => {
        try {
            const created = await createOrderWaitRequest(this.props.id, data);

            if (created) {
                this.props.updateOrderList(this.props.id, {waitRequests: [data]})
            }
            this.setState({
                isOpenWaitRequestModal: false
            })
        } catch (e) {
            checkError(e);
        }
    }


    render() {
        const {mode, order, isOpenWaitRequestModal} = this.state;
        const {rights} = this.props;

        const canRead = rights && rights['orders'] && rights['orders']['read'];
        const canEdit = rights && rights['orders'] && rights['orders']['edit'];

        return  (
            <div className='order-detail'>
                {canRead &&
                    <>
                        <RenderIf condition={ order !== undefined && !this.state.loading}>
                            <>
                                <RenderIf condition={mode === 'read'}>
                                    <OrderReadMode
                                        getEditMode={this.getEditMode}
                                        order={(order as Order)}
                                        openWaitRequestModal={this.openWaitRequestModal}
                                        updateOrder={this.getOrder}
                                    />
                                </RenderIf>

                                <RenderIf condition={mode === 'edit' && canEdit}>
                                    <OrderEditMode
                                        updateOrder={this.updateOrder}
                                        dismissUpdateOrder={this.dismissUpdate}
                                        order={(order as Order)}
                                    />
                                </RenderIf>
                            </>
                        </RenderIf>

                        <RenderIf condition={order === undefined || this.state.loading}>
                            <OrderPreloader loading={this.state.loading}/>
                        </RenderIf>

                    </>

                }
                {!canRead && <HasNoRightsController/>}

            </div>
        )
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})


const mapDispatchToProps = (dispatch:Function) => ({
    updateOrderList: (orderId:number, data:UpdateOrderList) => dispatch(updateOrderList(orderId, data)),
    openModal: (props:Modal) => dispatch(openModal(props))
});

export default connect(mapState, mapDispatchToProps)(OrderDetail)