import React from 'react';
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import Button from "../../UI/button/button";
import {Option} from "../../UI/select/select.types";
import {getAreasList} from '../../../utils/functions'
import {CreateCity} from "@justpro/terminal";
import getText from "../../../localization/getText";

type Props = {
    create(data: CreateCity): void
}

type State = {
    name: string
    area?: number
}

class CityCreate extends React.Component<Props, State> {

    state: State = {
        name: '',
    };

    create = async () => {
        if (!this.isDisabled()) {
            this.props.create({
                name : this.state.name,
                areaId : this.state.area as number
            });
        }
    };

    changeName = (val: string) => {
        this.setState(() => ({
            name: val
        }))
    };

    changeArea = (option: Option) => {
        this.setState(() => ({area : option.value}));

        return Promise.resolve();

    };

    isDisabled = () => {
        return this.state.name.trim() === '' || this.state.area === undefined;
    };


    render() {
        return (
            <>
                <h3>{getText('cities.addingNewHeader')}</h3>

                <Input
                    label="cities.city"
                    change={this.changeName}
                    placeholder="cities.city"
                />

                <Select
                    change={this.changeArea}
                    load={getAreasList}
                    label="areas.area"
                    accessors={{
                        id: 'value',
                        name: 'label'
                    }}
                />

                <Button className="btn-success" disabled={this.isDisabled()} onClick={this.create}>{getText('common.add')}</Button>
            </>
        )
    }
}


export default CityCreate