import React from "react";


interface Props {
    iconClassName : string
}

const TreeIcon = (props:Props) => {
    return <i className={`${props.iconClassName} just-pro__tree-icon`} />
};

export default TreeIcon