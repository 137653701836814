import {AnyAction, combineReducers} from "redux";
import groups from './groups.reducer'
import roles from './roles.reducer'
import list from './list.reducer'
import user from "./user.reducer";

export type Detail = 'add' | 'role' | 'group' | 'user' | ''
export type Tree = 'role' | 'group' | ''

export interface UsersReducer {
    detail : Detail
    tree : Tree
}

export const usersTypes = {
    CHANGE_TREE : 'CHANGE_TREE',
    CHANGE_USERS_PANELS : 'CHANGE_USERS_PANELS',
};


const initialState:UsersReducer = {
    detail : '',
    tree : ''
};

interface Action extends AnyAction, UsersReducer {}

const users = (state:UsersReducer = initialState, action:Action) => {
    const {type, ...rest} = action;

    switch (type) {
        case usersTypes.CHANGE_USERS_PANELS :
            return Object.assign({}, state, rest);
        case usersTypes.CHANGE_TREE :
            return Object.assign({}, state, {
                tree : action.tree,
            });
        default :
            return state;
    }
};

export default combineReducers({
    users,
    user,
    groups,
    roles,
    list,
})