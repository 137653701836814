import React from "react";
import {Person} from "@justpro/terminal";
import './tasks.css'
import {getFullName} from "../../utils/names";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";

interface Props {
    initiator : Person
}

export default function HasNoTaskAccess(props:Props) {
    const name = getFullName(props.initiator).trim();

    return (
        <div className="has-no-access">
            {getText('tasks.hasNoAccess')} {name.length > 0 ? `${renderToString(getText('tasks.ifNeedAccess'))} ${name}` : ""}.
        </div>
    )
}


