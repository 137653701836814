import React from 'react';
import {editFuel, Fuel, getFuel} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import FuelDetail from "./fuel.detail";

interface Props {
    id: number
    afterUpdate?(item:Fuel) : void
}

interface State {
    loading : boolean
    fuel? : Fuel
}

class FuelController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateFuel = async (data:Partial<Fuel>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const fuel = await editFuel(this.props.id, data);

            afterUpdate && afterUpdate(fuel);
            this.setState(() => ({loading : false, fuel}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getFuel = async () => {
        this.setState(() => ({loading : true}));

        try {
            const fuel = await getFuel(this.props.id);

            this.setState(() => ({loading : false, fuel}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getFuel();
        }
    }

    async componentDidMount(){
        this.getFuel();
    }

    render() {
        const {fuel} = this.state;
        return (
            <>
                <RenderIf condition={fuel !== undefined}>
                    <FuelDetail
                        fuel={fuel as Fuel}
                        update={this.updateFuel} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default FuelController