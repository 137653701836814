import React, {useEffect, useState} from 'react';
import './personInfo.view.css'
import RenderIf from "../../../../utils/renderIf";
import {Person, PersonPosition} from "@justpro/terminal";
import getPersonPhoto from "../../../../utils/getPersonPhoto";
import getFullName from "../../../../utils/getFullName";
import getText from "../../../../localization/getText";
import {renderToString} from "react-dom/server";

interface Props {
    person: Person | null,
    position?: 'up' | 'down',
    canOpen?: boolean,
    openByHover?: boolean,
    personNameClassName?: string
    customAction?: {
        name: string
        onAction: (...args: any) => any
    }
}

const DELAY_TO_OPEN = 500;

const PersonInfo: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {person, canOpen = true, personNameClassName, openByHover = false, customAction} = props;


    const name = getFullName(person);
    const photo = person ? getPersonPhoto(person.avatar) : '';

    const cls = ['just-pro__person-info'];

    if (props.position) {
        cls.push(props.position)
    } else {
        cls.push('up')
    }

    useEffect(() => {
        if (isOpen) {
            const callback = (e: any) => {
                const closest = e.target?.closest('.info-wrapper');

                if (!closest) {
                    setIsOpen(false)
                }
            };

            document.addEventListener('click', callback);

            return () => {
                document.removeEventListener('click', callback)
            }
        }
    }, [isOpen]);
    let openTimeout: any = 0;
    const openInfo = (event: any) => {
        event?.stopPropagation();
        setIsOpen(true);
    }
    const openInfoDelay = (delay: number) => {
        clearTimeout(openTimeout);
        openTimeout = setTimeout(() => {
            setIsOpen(true);
        }, delay)
    }

    const closeInfoDelay = (delay: number) => {
        clearTimeout(openTimeout);
        openTimeout = setTimeout(() => {
            setIsOpen(false);
        }, delay)
    }

    const clickCustomAction = () => {
        customAction?.onAction();
        setIsOpen(false);
        clearTimeout(openTimeout);
    }

    const onMouseDown = (e: any) => {
        e.preventDefault();
    }

    const personClasses = ["person-name"];
    if (canOpen) {
        personClasses.push("can-open");
    }
    personNameClassName && personClasses.push(personNameClassName);
    const regions = Array.isArray(person.executors) && person.executors.reduce((regions, executor) => {
        return [...regions, ...(executor?.regions || [])]
    }, []);
    return (
        <span className={cls.join(' ')}
              onMouseEnter={openByHover ? openInfoDelay.bind(null, DELAY_TO_OPEN) : () => {
              }}
              onMouseLeave={openByHover ? closeInfoDelay.bind(null, DELAY_TO_OPEN) : () => {
              }}
        >
            <span className={personClasses.join(" ")}
                  onClick={canOpen ? openInfo : () => {
                  }}>
                {name}
                </span>
            <RenderIf condition={isOpen}>
                <div className={'info-wrapper'}>
                    <div className="photo">
                        <img src={photo.toString()} alt={renderToString(getText('common.photo'))}/>
                    </div>
                    <div className="info">
                        <p className="name">{name}</p>
                        {person?.positions?.map((position) => (
                            <>
                                <p className="department">{position?.department?.name}: </p>
                                <p className="position">{position?.position?.name}</p>
                                <RenderIf condition={position?.chief}>
                                    <p className="chief">{getText('UI.personInfo.obeysTo')} {position?.chief && getFullName(position?.chief)}</p>
                                </RenderIf>
                            </>
                        ))}
                        <hr/>
                        {props.person?.phones?.map(phone => {
                            return <p className="contact">{getText('UI.personInfo.phone')}: {phone.value}</p>
                        })}
                        {props.person?.emails?.map(email => {
                            return <p className="contact">{getText('UI.personInfo.email')}: {email.value}</p>
                        })}
                        {props.person?.messengers?.map(messenger => {
                            return <p className="contact">{getText('UI.personInfo.messenger')}: {messenger.value}</p>
                        })}
                        {Array.isArray(regions) && regions.map((region) => {
                            return (
                                <p className="contact">
                                    {getText('UI.personInfo.region')}: {region.name}
                                </p>
                            )
                        })}
                        <RenderIf condition={customAction}>
                         <div className="custom-action" onMouseDown={onMouseDown} onFocus={onMouseDown}
                              onClick={clickCustomAction}>
                             {getText(customAction?.name || '')}
                         </div>
                        </RenderIf>
                    </div>
                </div>
            </RenderIf>

        </span>
    )
};

export default PersonInfo
