import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModulesResponse,
  getGlobalSettings,
  format,
  getFullName,
  getLogs,
  Log,
  LOGS_TYPES,
} from "@justpro/terminal";
import { renderToString } from "react-dom/server";
import getText from "../../../localization/getText";
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import RenderIf from "../../../utils/renderIf";
import HeaderController from "../../header/header.controller";
import Button from "../../UI/button/button";
import TopLineCalendar from "../../UI/calendar/views/topLine.controller";
import ToplineCalendarWrapper from "../../UI/calendar/views/toplineCalendarWrapper";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import Spinner from "../../UI/spinner/spinner.controller";
import Table from "../../UI/table/table";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import moment, { Moment } from "moment";
import { getActScanState } from "../../UI/moduleHistory/templates/historyItem";
import checkError from "../../../utils/checkError";
import Search from "../../UI/search/search.controller";
import CheckboxList from "../../UI/checkboxList/checkboxList.contoller";
import Filter from "../../UI/filter/filter.controller";
import { CheckboxesMap, ActiveCheckboxesList } from "../../UI/checkboxList/checkboxList.types";

import "./logs.css";

interface Props {
  rights?: Partial<ModulesResponse>;
}

interface State {
  loading: boolean;
  logs: Log[];
  from: Moment;
  to: Moment;
  count: number;
  pageIndex: number;
  q: string;
  checkboxesMap: CheckboxesMap
  activeCheckboxes: ActiveCheckboxesList
}

const LIMIT = 50;

class LogsController extends Component<Props, State> {
  columns = [
    {
      Header: getText("logs.action"),
      width: 250,
      Cell: (props) => <div className="logs__cell">{props.original.endpoint}</div>,
    },
    {
      Header: getText("logs.type"),
      width: 175,
      Cell: (props) => <div className="logs__cell">{props.original.type}</div>,
    },
    {
      Header: getText("logs.data"),
      width: 400,
      Cell: (props) => <div className="logs__cell">{props.original.data}</div>,
    },
    {
      Header: getText("logs.error"),
      width: 200,
      Cell: (props) => <div className="logs__cell">{props.original.error}</div>,
    },
    {
      Header: getText("common.time"),
      width: 150,
      Cell: (props) => (
        <div className="logs__cell">
          {moment(props.original.createdAt).format("DD.MM.YYYY HH:mm:ss")}
        </div>
      ),
    },
    {
      Header: getText("common.user"),
      width: 250,
      Cell: (props) => <div className="logs__cell">{props.original.user ? getFullName(props.original.user?.person) : 'Неизв.'}</div>,
    },
    {
      Header: getText("logs.userAgent"),
      width: 250,
      Cell: (props) => (
        <div className="logs__cell">
          {props.original.userAgent}
        </div>
      ),
    },
    {
      Header: getText("logs.duration"),
      width: 100,
      Cell: (props) => <div className="logs__cell">{props.original.duration} мс.</div>,
    },
  ];

  state = {
    loading: false,
    logs: [],
    from: moment().startOf("day"),
    to: moment(),
    count: 0,
    pageIndex: 0,
    q: "",
    checkboxesMap: {
      type: {
        children: Object.keys(LOGS_TYPES).map((type) => ({
          name: type,
          value: type
        })),
        name: 'logs.type'
      },
    },
    activeCheckboxes: {
      type: ['SYNC_ERROR']
    }
  };

  componentDidMount() {
    this.getLogs();
  }

  getLogs = async () => {
    try {
      this.setState({
        loading: true,
      });

      const filters = {
        q: this.state.q,
        dateFrom: this.state.from.format('YYYY-MM-DD HH:mm:ss'),
        dateTo: this.state.to.format('YYYY-MM-DD HH:mm:ss'),
        type: this.state.activeCheckboxes.type as any,
        limit: LIMIT,
        offset: LIMIT * this.state.pageIndex
      };

      const { list, count } = await getLogs(filters);

      this.setState({
        logs: list,
        count,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };
  

  onChangeFrom = (from: Moment) => {
    this.setState(() => ({ from }), this.getLogs);
  };
  onChangeTo = (to: Moment) => {
    this.setState(() => ({ to }), this.getLogs);
  };

  onSubmitSearch = (q: string) => {
    this.setState(
      {
        q,
      },
      this.getLogs
    );
  };

  onCheckboxesChange = (activeCheckboxes: ActiveCheckboxesList) => {
    this.setState({activeCheckboxes})
  };

  onPageChange = (pageIndex: number) => {
    this.setState({
      pageIndex
    }, this.getLogs)
  }

  render() {
    const rights = this.props.rights && this.props.rights["references.tags"];
    return (
      <WithPrivateRoute>
        <HeaderController>
          <Filter send={this.getLogs} activeCheckboxes={this.state.activeCheckboxes}>
            <CheckboxList
              checkboxesMap={this.state.checkboxesMap}
              send={this.onCheckboxesChange}
              activeCheckboxes={this.state.activeCheckboxes}
            />
          </Filter>
          <ModuleTextName>{getText("logs.name")}</ModuleTextName>
          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.onChangeFrom}
              timePicker
            />
            <TopLineCalendar
              date={this.state.to}
              onChange={this.onChangeTo}
              timePicker
            />
          </ToplineCalendarWrapper>
          <Search submit={this.onSubmitSearch} eraser={true} q={this.state.q} />
        </HeaderController>

        <div className="just-pro_module report-wrapper">
          <div className="panel content-panel">
            <div className="actsHistory_table">
              <RenderIf condition={!this.state.loading}>
                <Table
                  columns={this.columns}
                  data={this.state.logs}
                  wrapperClassName="report_flex-table"
                  page={this.state.pageIndex}
                  pages={Math.floor(this.state.count / LIMIT)}
                  onPageChange={this.onPageChange}
                  showPagination={true}
                  pageSize={LIMIT}
                  manual={true}
                ></Table>
              </RenderIf>
            </div>

            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

const mapState = (state: any) => ({
  rights: state.application.rights,
});

export default connect(mapState)(LogsController);
