import React from "react";
import {toast} from "react-toastify";
import moment, {Moment} from "moment";
import {connect} from "react-redux";
import {
    getTaskStates, getTasks, relativityMap, priorityMap, problemMap, StringBoolean,
    unmarkUpdatedTask, readAllTaskComments, ITasksFilterParams
} from "@justpro/terminal";
import {isEqual} from "lodash";

import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import Filter from "../UI/filter/filter.controller";
import CheckboxList from "../UI/checkboxList/checkboxList.contoller";
import TopLineCalendar from "../UI/calendar/views/topLine.controller";
import Search from "../UI/search/search.controller";
import ListFilterController from "../UI/listFilter/listFilter.controller";
import ItemList from "../UI/itemsList/itemList.controller";
import RenderIf from "../../utils/renderIf";
import {ActiveCheckboxesList, CheckboxesMap} from "../UI/checkboxList/checkboxList.types";
import {filterNames} from "../UI/checkboxList/checkboxes.const";
import {filterParams, getSubordinateTasks, IGetTasks, ModulesResponse, SettingsMap, Staff} from "@justpro/terminal";
import * as filter from "../../utils/filterCheckboxes";
import {getPersonsList, getTagsList} from "../../utils/functions";
import TaskListItem from "./task.listItem";
import TaskController, {IUpdateTaskIcons} from "./task.controller";
import Tooltip from "../UI/tooltip/tooltip.controller";
import Button from "../UI/button/button";
import HeaderController from "../header/header.controller";
import checkError from "../../utils/checkError";
import SavedFilter from "../UI/savedFilter/savedFilter.controller";
import {get} from "../../utils/filterCheckboxes";
import {ApplicationMapState} from "../application/application.controller";
import SubOrdinateModal from "./subOrdinate.modal";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";
import {openModal} from "../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";
import ToplineCalendarWrapper from "../UI/calendar/views/toplineCalendarWrapper";
import {FilterName} from "../../store/filters/filters.types";
import {getFilter} from "../../store/filters/filters.actions";
import { getNotificationsNew, getUserMe} from "../../store/application/application.actions";

interface Props {
    getFilter(name:FilterName, rest?:any) : Promise<any[]> | undefined
    getNotificationsNew(): Promise<any>
    rights?: Partial<ModulesResponse>
    settings?: SettingsMap
    me?: any
    openModal(props:Modal) : void
}


interface TaskSavedFilters {
    filter?: ActiveCheckboxesList,
    activeStates? : number[]
}

interface State {
    activeCheckboxes: ActiveCheckboxesList
    checkboxesMap: CheckboxesMap

    from: Moment
    to: Moment

    list: any[]
    id?: number
    q: string,
    offset: number,
    count: string
    states: any[]
    activeStates: number[]
    loading: boolean
    activeTaskId?: number | null
    onlyUpdated: boolean

    needBackScroll : boolean

    // from : Moment
    // to : Moment
    isChosenSubordinate : boolean
    subordinatePersonId? : number
}

interface UpdateTaskIcons {
    readMark : boolean
}

// const activeCheckboxes = filter.get(filterNames.TASKS);

class TasksController extends React.Component<Props, State> {
    state: State = {
        list: [],
        checkboxesMap: {},
        activeCheckboxes : {},
        q: "",
        offset: 0,
        count: "0",
        loading: false,
        states: [],
        activeStates: [0,1],
        activeTaskId: null,
        from: moment().set({
            y : 2017,
            M : 0,
            d : 0
        }),
        to: moment(),
        needBackScroll : false,
        onlyUpdated: false,
        isChosenSubordinate : false,
    };


    getTaskFilterParams = (offset?:number):Partial<ITasksFilterParams> => {
        const {q, activeStates, activeCheckboxes, from, to} = this.state;
        const filters: Partial<ITasksFilterParams> = {
            q,
            stateId: activeStates,
            sortBy: "priority",
            sortOrder: "desc",
            ...activeCheckboxes,
            createdAtFrom: from.format("YYYY-MM-DD"),
            createdAtTo: to.format("YYYY-MM-DD"),
            limit : 100,
            offset
        };

        const onlyUpdatedFilters = {
            updated: ['1'] as StringBoolean[]
        };


        return this.state.onlyUpdated ? onlyUpdatedFilters : filters
    }


    getTasks = async (autoSelectFirst = false) => {
        const {subordinatePersonId} = this.state;
        try {
            const filters = this.getTaskFilterParams();

            this.setState({
                loading: true,
                needBackScroll : true
            });

            let tasks:IGetTasks;

            if(subordinatePersonId) {
                tasks = await getSubordinateTasks(subordinatePersonId, filters)
            } else {
                tasks = await getTasks(filters);
            }
            // if(autoSelectFirst){
            //     this.setState({
            //         activeTaskId: tasks?.list?.[0]?.id,
            //     })
            // }
            this.setState({
                list: tasks.list,
                count: tasks.count,
                offset : 100
            })
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false,
                needBackScroll : false
            });
        }
    };

    updateTasksList = async () => {
        const {count, list, subordinatePersonId} = this.state;
        if(count && list) {
            if(+count > list.length) {
                try {
                    const filters = this.getTaskFilterParams(this.state.offset);

                    this.setState({
                        loading: true,
                    });
                    let tasks:IGetTasks;

                    if(subordinatePersonId) {
                        tasks = await getSubordinateTasks(subordinatePersonId, filters)
                    }else {
                        tasks = await getTasks(filters);
                    }

                    this.setState((prevState) => ({
                        list: [...prevState.list, ...tasks.list],
                        count: tasks.count,
                        offset : prevState.offset + 100
                    }))
                } catch (err) {
                    checkError(err);
                } finally {
                    this.setState({
                        loading: false,
                    });
                }
            }
        }


    };

    selectTask(activeTaskId: number) {
        this.setState({
            activeTaskId
        })
    }

    async onClickIcon(taskId: number, icon: string) {
        if (icon === "newComments") {
            await readAllTaskComments(taskId);
            this.setState((prevState: State) => {
                return {
                    list: prevState.list.map((task) => {
                        if(task.id === taskId){
                            task.newComments = false;
                        }
                        return task;
                    })
                }
            });
        }
        if (icon === "updated") {
            await unmarkUpdatedTask(taskId);
            this.setState((prevState: State) => {
                return {
                    list: prevState.list.map((task) => {
                        if(task.id === taskId){
                            task.updated = false;
                        }
                        return task;
                    })
                }
            });
        }
        if(this.state.onlyUpdated && (icon === "updated" || icon === "newComments")){
            await this.getTasks();
        }
        return this.props.getNotificationsNew();
    }

    renderItem = (task: any) => {
        return <TaskListItem
            task={task}
            withActive={this.state.activeTaskId === task.id}
            onClick={this.selectTask.bind(this, task.id)}
            onClickIcon={this.onClickIcon.bind(this, task.id)}
        />
    };

    getFilterCheckboxesMap = async () => {
        const {rights, settings} = this.props;

        const checkboxesMap = {
            [filterParams.TASKS_RELATIVITY]: {
                children: Object.entries(relativityMap).map(([rel, name]) => ({id: rel, name})),
                name: "UI.filters.relativeToTask"
            },
        };


        const filters = {q : "", executorsId : [+settings?.tfmContractorId, +settings?.tfmShidContractorId]}

        checkboxesMap[filterParams.TASKS_INITIATORS]= {
            children: await this.props.getFilter("persons", filters),
            name: "common.initiator"
        };
        checkboxesMap[filterParams.RESPONSIBLES] = {
            children: await this.props.getFilter("executors", filters),
            name: "UI.filters.responsible"
        };

        checkboxesMap[filterParams.TASKS_PRIORITY] = {
            children: Object.entries(priorityMap).map(([priority, name]) => ({id: priority, name})),
            name: "UI.filters.importance"
        };

        checkboxesMap[filterParams.TASKS_PROBLEM] = {
            children: Object.entries(problemMap).map(([priority, name]) => ({id: priority, name})),
            name: "UI.filters.resolveProblem"
        };

        if(rights?.['references.tags']?.read){
            // const tags = await getTagsList({modules: ['tasks']});
            checkboxesMap[filterParams.TAGS] = {
                children: await this.props.getFilter("taskTags", {modules : ['tasks']}),
                name: 'tags.tag',
            }
        }

        return checkboxesMap
    };

    onFilterChange = (activeCheckboxes: ActiveCheckboxesList) => {
        filter.save(filterNames.TASKS, activeCheckboxes);
        this.setState({
            activeCheckboxes
        })
    };


    // onChangeFrom = (from:Moment) => {
    //     this.setState(() => ({from}))
    // };
    //
    // onChangeTo = (to:Moment) => {
    //     this.setState(() => ({to}))
    // };

    searchSubmit = (q: string) => {
        this.setState({
            q
        }, this.getTasks.bind(this, true));
    };

    onChangeStates = (active: number[]) => {
        this.setState({
            activeStates: active
        }, this.getTasks);
    };

    openStaffModal = () => {

    };

    // closeStaffModal = () => {
    //
    // };


    onSavedFilterChange = (allFilters:TaskSavedFilters) => {

        const {filter,activeStates} = allFilters;


        this.setState((prevState) => ({
            activeCheckboxes : filter ? filter : {},
            activeStates : activeStates ? activeStates : []
        }), this.getTasks);


        console.log({filter})

        // return {
        //     filter
        // }
    };

    updateListIcons = (taskId: number, data: IUpdateTaskIcons) => {
        this.setState((prevState: State) => ({
            list: prevState.list.map((task) => {
                if (task.id === taskId) {
                    return {
                        ...task,
                        tags: data.tags ? data.tags : task.tags
                    }
                }
                return task
            })
        }))
    };

    // updateTasksList = async () => {
    //     const {count, list, activeCheckboxes, activeStates} = this.state;
    //
    //     console.log({count, list})
    //     if (count && list) {
    //         if (+count > list.length) {
    //             this.setState(({offset}: State) => ({
    //                 offset: offset + 100
    //             }), this.getTasks);
    //             return;
    //         }
    //     }
    //     return this.getTasks();
    // };

    getCurrentFilters = ():TaskSavedFilters => {
        return {
            filter : this.state.activeCheckboxes,
            activeStates : this.state.activeStates,

        }
    };

    // async componentDidUpdate (prevProps:Readonly<Props>, prevState:State) {
    //     if(prevState.id !== this.state.id)
    // }

    onChangeFrom = (from: Moment) => {
        this.setState(() => ({from}), this.getTasks.bind(this, true))
    };

    onChangeTo = (to: Moment) => {
        this.setState(() => ({to}), this.getTasks.bind(this, true))
    };

    hideFilter = () => {};

    changeTasksFilter = () => {
        this.setState((prevState) => ({
            onlyUpdated: !prevState.onlyUpdated
        }), this.getTasks.bind(this, true));
        this.hideFilter && this.hideFilter();
    }

    onInitFilter = (methods: any) => {
        this.hideFilter = methods.hide;
    };


    resetState = async () => {
        const {me, settings} = this.props;
        if(!me || !settings) return;
        const checkboxesMap = await this.getFilterCheckboxesMap();
        const states = await this.props.getFilter("tasksStates");
        let activeCheckboxes: any = {
            [filterParams.TASKS_RELATIVITY] : [ "initiator", "responsible"]
        };
        if(me?.settings?.tasksLastFilters){
            activeCheckboxes = get(filterNames.TASKS, {
                [filterParams.TASKS_RELATIVITY] : [ "initiator", "responsible"]
            });
        }
        //@ts-ignore
        this.setState(() => ({
            checkboxesMap,
            states,
            activeCheckboxes,
        }));
        return this.getTasks(true);
    };


    openSubordinateModal = () => {
        this.props.openModal({
            id : 'tasksSubordinates',
            component : (props:ModalBodyProps) => (
                <SubOrdinateModal
                    {...props}
                    onSubOrdinatePick={this.onSubOrdinatePick}
                    defaultOpen={true}
                />
            ),
            title : getText('tasks.subordinateList'),
        });

    };


    onSubOrdinatePick = async (staff:Staff) => {
        const {me} = this.props;
        if(staff.person) {
            this.setState((prevState) => ({
                isChosenSubordinate : !(me?.person?.id === staff?.person?.id),
                subordinatePersonId : staff?.person?.id,
            }), this.getTasks);

        }else{
            toast.warn(renderToString(getText('staff.unlinkToPerson')))
        }
    };

    async componentDidMount() {
        return this.resetState();
    }

    async componentDidUpdate(prevProps:Readonly<Props>) {
        if(!isEqual(prevProps.rights, this.props.rights))  {
            return this.resetState();
        }
    }

    render() {
        const {activeCheckboxes, activeTaskId, onlyUpdated} = this.state;
        const classesFilterButton = ["just-pro__tasks_updated"];
        if (onlyUpdated) {
            classesFilterButton.push("active");
        }
        return (
            <WithPrivateRoute>
                <HeaderController
                    backgroundColor={this.state.isChosenSubordinate ? "#FBE9EB" : undefined}
                    savedFilters={{
                        module:"tasks",
                        setFilters: this.onSavedFilterChange,
                        getCurrentFilters:this.getCurrentFilters
                    }}
                >
                    <Filter
                        activeCheckboxes={activeCheckboxes}
                        send={this.getTasks.bind(this, true)}
                        onInit={this.onInitFilter}
                    >
                        <div className={classesFilterButton.join(" ")}
                             onClick={this.changeTasksFilter}
                        >
                            {getText(onlyUpdated ? 'tasks.showAll' : 'tasks.showUpdatedOnly')}
                        </div>

                        <CheckboxList
                            checkboxesMap={this.state.checkboxesMap}
                            activeCheckboxes={activeCheckboxes}
                            send={this.onFilterChange}
                        />

                    </Filter>

                    <ToplineCalendarWrapper>
                        <TopLineCalendar date={this.state.from} onChange={this.onChangeFrom}/>
                        <TopLineCalendar date={this.state.to} onChange={this.onChangeTo}/>
                    </ToplineCalendarWrapper>


                    <Search
                        submit={this.searchSubmit}
                        loadOnEraser={false}
                        eraser={true}
                    />

                    <div className='navbar-form navbar-left buttons'>
                        <Tooltip position="down" title="tasks.tasksOfEmployees">
                            <Button
                                onClick={this.openSubordinateModal}
                                className="btn-default"
                            >
                                <i className="fa fa-users"/>
                            </Button>
                        </Tooltip>

                        <Tooltip position="down" title="common.excelExport">
                            <Button
                                onClick={this.openStaffModal}
                                className="btn-default"
                            >
                                <i className="fa fa-file-excel"/>
                            </Button>
                        </Tooltip>
                    </div>

                </HeaderController>

                <div className="just-pro_module tasks">
                    <div className="panel content-panel">
                        <ListFilterController
                            counter={{
                                value: parseInt(this.state.count),
                                vars: {
                                    one: 'tasks.vars.one',
                                    two: 'tasks.vars.two',
                                    default: 'tasks.vars.default',
                                    notFound: 'tasks.vars.notFound'
                                }
                            }}
                            // sortBy={{
                            //     onChangeSortBy : this.changeSortBy,
                            //     onChangeOrderBy : this.changeOrderBy,
                            //     sortByOptions : this.orderByOptions,
                            // }}
                            moduleGroups={{
                                list: this.state.states,
                                title: 'tasks.byStage',
                                onChange: this.onChangeStates,
                                checked : this.state.activeStates,
                            }}
                        />

                        <ItemList
                            loading={this.state.loading}
                            renderItem={this.renderItem}
                            list={this.state.list}
                            updateData={this.updateTasksList}
                            backScrollToTop={this.state.needBackScroll}
                        />
                    </div>
                    {/*<RenderIf condition={activeTaskId}>*/}
                        <div className="panel content-panel">
                            <TaskController
                                // updateList={this.updateTasksList}
                                updateAllList={this.getTasks}
                                updateListIcons={this.updateListIcons}
                                id={activeTaskId}
                                subordinatePersonId={this.state.subordinatePersonId}
                                isChosenSubordinate={this.state.isChosenSubordinate}
                            />
                        </div>
                    {/*</RenderIf>*/}
                </div>
            </WithPrivateRoute>
        )
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights,
    settings : state.application.settings,
    me : state.application.me,
});

const mapDispatch = (d:Function) => ({
    openModal: (props:Modal) => d(openModal(props)),
    getFilter : (filter, rest) => d(getFilter(filter, rest)),
    getNotificationsNew: () => d(getNotificationsNew())
});

export default connect(mapState,mapDispatch)(TasksController)
