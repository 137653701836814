import React from 'react';
import {OrdersWorkorder} from "@justpro/terminal";
import './createWorkOrders.css'
import WorkOrderController from "../../../workOrders/workOrder.controller";

interface Props {
    list : OrdersWorkorder[]
}

class SecondStep extends React.Component<Props>{
    render() {
        const {list} = this.props;
        return (
            <div className="createWorkOrderList">
                {list.map(item => {
                    return (
                        <div className="createWorkOrder" key={item.id}>
                            <WorkOrderController workOrder={item} />
                        </div>
                    )
                })}
            </div>
        );
    }
}



export default SecondStep