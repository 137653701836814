import React from 'react';
import {Object, UpdateObject, getObject, editObject, ModulesResponse} from '@justpro/terminal'
import Spinner from "../../../UI/spinner/spinner.controller";
import ObjectDetail from "./object.detail";
import RenderIf from "../../../../utils/renderIf";
import checkError from "../../../../utils/checkError";
import {toast} from "react-toastify";
import {ApplicationMapState} from "../../../application/application.controller";
import {connect} from "react-redux";
import HasNoRightsController from "../../../UI/hasNoRights/hasNoRights.controller";

interface Props {
    id?: number
    afterUpdate?(item:Object) : void

    rights? : Partial<ModulesResponse>
}

interface State {
    loading : boolean
    object?: Object
}

class ObjectController extends React.Component<Props, State> {
    state:State = {
        loading : false,
    };

    getObject = async () => {


        const {id, rights} = this.props;

        const read = rights && rights['references.objects'] && rights['references.objects']['read'];

        if(id && read) {
            this.setState(() => ({loading : true}));
            try {
                const object = await getObject(id);
                // changeDetailPanel && await changeDetailPanel('detail');

                this.setState(() => ({
                    loading : false,
                    object
                }))
            }catch (e) {
                this.setState(() => ({
                    loading : false,
                }));
                checkError(e);
            }
        }
    };

    updateObject = async (data:Partial<UpdateObject>) => {
        const {afterUpdate, id} = this.props;

        this.setState(() => ({loading : true}));

        if(id) {
            try {
                const object = await editObject(id, data);
                if(
                    afterUpdate &&
                    (data.hasOwnProperty('number')  ||
                     data.hasOwnProperty('cityId')  ||
                     data.hasOwnProperty('address') ||
                     data.hasOwnProperty('active')  ||
                     data.hasOwnProperty('filial1sId') )
                ){
                    await afterUpdate(object);
                }

                this.setState(() => ({
                    loading : false,
                    object
                }))

            }catch (e) {
                checkError(e)
            }

        }else {
            this.setState(() => ({
                loading : false,
            }));
            toast.warn('id is not specified (frontend).')
        }
    };

    async componentDidMount() {
        this.getObject();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.id !== this.props.id) {
            this.getObject()
        }
    }

    render () {
        const {object} = this.state;
        const {rights} = this.props;

        const read = rights && rights['references.objects'] && rights['references.objects']['read'];

        return (
            <>
                {read ? (
                    <>
                        <RenderIf condition={object !== undefined }>
                            <ObjectDetail
                                update={this.updateObject}
                                object={(object as Object)}
                            />
                        </RenderIf>

                        <Spinner loading={this.state.loading}/>
                    </>
                ) : (
                    <HasNoRightsController/>
                )}

            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(ObjectController)
