type params = {
    [key:string] : any
}
/*
* accessors вида
* {
*   key : value,
* }
*
*   где key - точное совпадение по имени серверного параметра,
*       value - или нужный результирующий ключ или объект если нужна вложенность
*               в случае если это объект можно передать прееменную _self которая будет ключем объекта
*               иначе берется серверное значение
* */

export const makeNormalizeParams = (server:params, accessors : params) => {
    let result:any = {};
    let serverEntries = Object.entries(server);
    for(let couple of serverEntries) {
        if(accessors[couple[0]]) {
            if(typeof couple[1] === 'object' && !Array.isArray(couple[1]) ){
                let k = accessors[couple[0]]._self ? accessors[couple[0]]._self : couple[0];
                // console.log({couple})
                if(couple[1] !== null) {
                    result[k] = makeNormalizeParams(couple[1], accessors[couple[0]]);
                }else{
                    result[k] = accessors[1]
                }
            }else{
                //todo couple[1] может быть масивом объектов и нужно это учесть
                result[accessors[couple[0]]] = couple[1];
            }
        }else{
            result[couple[0]] = couple[1];
            // console.warn(`Возможно забыли передать ключ ${couple[0]} в объект accessors`)
        }
    }

    return result;
};



