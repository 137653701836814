import React from 'react'
import withLabel from '../../UI/withLabel/withLabel'
import Table from '../../UI/table/table'
import {
	addExecutor,
	ModulesResponse,
	deleteExecutor,
	PersonExecutor as IPersonExecutor,
} from '@justpro/terminal'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import { getUserMe } from '../../../store/application/application.actions'
import Select from '../../UI/select/select'
import { LoadReturn, Option } from '../../UI/select/select.types'
import { getContractorList } from '../../../utils/functions'
import { getUniqueList } from '../../../utils/getUniqueArray'
import checkError from '../../../utils/checkError'
import RegionsTable from './executors/regions.table'
import SquaresTable from './executors/squares.table'
import EquipmentCategoriesTable from './executors/equipmentCategories.table'
import PaymentsTable from './executors/payments.table'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'

interface Props {
	personId: number
	executors?: IPersonExecutor[]
	rights?: Partial<ModulesResponse>
}

interface State {
	executors?: IPersonExecutor[]
}

class PersonExecutor extends React.Component<Props, State> {
	state: State = {}
	getExecutors = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const executors = await getContractorList({ q: '' })
			const unique = getUniqueList(executors, this.props.executors, {
				wholeId: 'id',
				existId: 'executorId',
			})
			resolve(unique)
		})
	}

	addExecutor = async (option: Option) => {
		try {
			const executor = await addExecutor({
				executorId: option.value,
				personId: this.props.personId,
			})

			if (executor) {
				this.setState((prevState) => ({
					executors: prevState.executors
						? [...prevState.executors, executor]
						: [executor],
				}))
			}
		} catch (e) {
			checkError(e)
		}

		return Promise.resolve()
	}

	resetState = () => {
		this.setState(() => ({
			executors: this.props.executors,
		}))
	}

	deleteExecutor = async (id: number) => {
		try {
			const deleted = await deleteExecutor({
				personId: this.props.personId,
				executorId: id,
			})

			if (deleted) {
				this.setState((prevState) => ({
					executors: prevState.executors?.filter((e) => e.id !== id),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	getColumns = () => {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('contractors.contractor'),
				accessor: 'name',
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deleteExecutor}
						id={props.original.id}
					/>
				),
			})
		}
		return result
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (
			this.props.personId !== prevProps.personId ||
			this.props.executors !== prevProps.executors
		) {
			this.resetState()
		}
	}

	render() {
		const { rights } = this.props
		const { executors } = this.state

		const personsRights = rights && rights['references.persons']
		if (personsRights) {
			return withLabel(
				<>
					{executors && (
						<Table
							columns={this.getColumns()}
							SubComponent={(row: any) => {
								const executor = row.original
								return (
									<div style={{ padding: 20 }}>
										<div className='executor-table'>
											<RegionsTable
												regions={executor.regions}
												personId={this.props.personId}
												executorPersonId={executor.id}
											/>
										</div>
										<div className='executor-table'>
											<SquaresTable
												squares={executor.squares}
												personId={this.props.personId}
												executorPersonId={executor.id}
											/>
										</div>
										<div className='executor-table'>
											<EquipmentCategoriesTable
												equipmentCategories={
													executor.equipmentCategories
												}
												personId={this.props.personId}
												executorPersonId={executor.id}
											/>
										</div>
										<div className='executor-table'>
											<PaymentsTable
												payments={executor.payments}
												personId={this.props.personId}
												executorPersonId={executor.id}
											/>
										</div>
									</div>
								)
							}}
							data={executors || []}
						/>
					)}

					{personsRights['edit'] && (
						<Select
							load={this.getExecutors}
							change={this.addExecutor}
							placeholder='contractors.addItem'
							className={
								executors && executors.length > 0
									? 'margin-top'
									: ''
							}
							accessors={{
								id: 'value',
								name: 'label',
							}}
						/>
					)}
				</>,
			)({ text: 'common.executor' })
		}

		return null
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

export default connect(mapStateToProps)(PersonExecutor)
