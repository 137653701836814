import React from "react";
import {getContractList, getRegionsList} from "../../../../../utils/functions";
import Table from "../../../table/table";
import '../createOrderModal.view.css'
import {Contract, Region} from "@justpro/terminal";
import {Contractor} from "@justpro/terminal/dist/contractors/contractors.types";
import getText from "../../../../../localization/getText";

interface State {
    regions : Region[] | null

}

interface Props {
    changeRegion:(region:Region) => void
    contracts : Contract[]
    contractor : Contractor | null
}

export default class RegionModalDetail extends React.Component<Props, State>{

    state:State = {
        regions : [],
    };


    async componentDidMount() {
        const {contractor, contracts} = this.props;

        if (contractor) {
            let hash:number[] = [];

            const regions:Region[]= contracts?.reduce((acc:Region[], contract) => {
                const regionsFromObjects:Region[] = contract.objects && contract.objects.reduce( (result:Region[], object) => {
                    if(object.square) {
                        const region = object.square.region;
                        const includes = hash.includes(region.id);
                        if(!includes) {
                            hash.push(region.id);
                            return [...result, region]
                        }
                    }
                    return result
                }, []);
                if(regionsFromObjects) {
                    return [...acc, ...regionsFromObjects]
                }else{
                    return acc
                }
            }, []);

            this.setState(() => ({regions}))

        }
    }

    choose = (region:Region) => {
        this.props.changeRegion(region)
    };

    render() {

        return(
            <>
                <Table
                    columns={[
                        {
                            Header: getText('regions.region'),
                            accessor: 'name',
                            minWidth: 150,
                            Cell:(props:any) => <span className="just-prop_create-order-list-item" onClick={this.choose.bind(this, props.original)}>{props.original.name}</span>
                        },
                    ]}
                    data={this.state.regions}
                    minRows={0}
                    pagination={false}
                    showPagination={false}

                />
            </>
        )
    }

}