import React from "react";
import Table from "../UI/table/table";
import {
    format,
    ISaveWorkorderOrders,
    ISaveWorkorderRegularWorks,
    Person,
    IExecutedTime, getCloseTypes,
} from "@justpro/terminal";
import moment, {Moment} from "moment";
import InlineCalendar from "../UI/calendar/views/inline.controller";
import Select from "../UI/select/select";
import {Option} from "../UI/select/select.types";
import ExecutedTimeController from "../UI/executedTime/executedTime.controller";
import './workOrders.css'
import checkError from "../../utils/checkError";
import getText from "../../localization/getText";
import {updateModal} from "../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal} from "../../store/modal/modal.types";
import {expiredTimeModalId} from "./workOrder.detail";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";
import Button from "../UI/button/button";
import Spinner from "../UI/spinner/spinner.controller";

interface Props {
    save(data?:SaveWorkorderModal[]) : Promise<any>
    responsible : Person,
    coworkers? : Person[]
    actNumbers : string[]
    hide() : void
}

interface State {
    closeTypes?: ActCloseType[]
    data : SaveWorkorderModal[]
    loading : boolean
}


export interface SaveModal {
    regularWorks? : ISaveWorkorderRegularWorks[],
    orders? : ISaveWorkorderOrders[],
}

interface ActCloseType {
    name : string
    id : number
}

interface People {
    id : number
    firstName : string
    lastName : string


    executedTime : IExecutedTime[]


    patronymic? : string
}

export interface SaveWorkorderModal {
    people : People[],
    date : Moment
    actNumber : string
    closeType? : number
}

class SaveWorkOrderModal extends React.Component<Props, State>{

    state:State = {
        data : [],
        loading : false
    };

    select = (props:any) => {

        return (
            <Select
                change={(option:Option) => this.changeCloseType(option, props.original.actNumber)}
                defaultOptions={this.state.closeTypes}
                placeholder="UI.select.placeholders.actCloseType"
                accessors={{
                    name : "label",
                    id : 'value',
                }}
            />
        )
    };

    onChangeTime = (executionTime:IExecutedTime[], personId:number, actNumber:string) => {
        this.setState((prevState) => ({
            ...prevState,
            data : prevState.data.map(row => {
                if(row.actNumber === actNumber) {
                    return  {
                        ...row,
                        people : row.people.map((person) => {

                            if(person.id === personId) {
                                return {
                                    ...person,
                                    executedTime : executionTime
                                }
                            }
                            return person
                        })
                    }
                }

                return row;
            })
        }))
    };

    getExpiredTimeView = (props:any) => {
        const {people, actNumber} = props.original;

        return people?.map((item:People) => {
            return <div key={item.id + '__' + actNumber} className="work-order__person-time">
                <span className="work-order__person-time__text">{item.lastName} {item.firstName} {item.patronymic}</span>
                <ExecutedTimeController
                    onChange={(values:IExecutedTime[]) => this.onChangeTime(values, item.id, actNumber)}
                    executedTime={item.executedTime}
                />
            </div>
        })
    };

    save = async () => {

        this.setState(() => ({loading : true}))

        await this.props.save(this.state.data);
        this.setState(() => ({loading : false}))

        this.props.hide();
    };

    changeActDate = (date:Moment, props:any) => {

        this.setState((prevState) => ({
            data : prevState.data.map((item) => {
                if(item.actNumber === props.original.actNumber) {
                    return {
                        ...item,
                        date
                    }
                }

                return item
            })
        }))
    };

    changeCloseType = (option:Option, actNumber: string) => {
        this.setState((prevState) => ({
            data : prevState.data.map((item) => {
                if(item.actNumber === actNumber) {
                    return {
                        ...item,
                        closeType : option.value
                    }
                }
                return item
            })
        }));

        return Promise.resolve();
    };


    async componentDidMount() {
        const {responsible, coworkers} = this.props;

        try {
            const closeTypes = await getCloseTypes({q : ''});

            const people:People[] = [{
                firstName : responsible.firstName,
                lastName : responsible.lastName,
                patronymic : responsible.patronymic,
                id : responsible.id,
                executedTime : [{
                    from : moment().startOf('day').format(format.time),
                    to : moment().startOf('day').format(format.time)
                }]

            }];

            if(coworkers) {
                coworkers.forEach(coworker => {
                    if(coworker.id !== responsible.id) {
                        people.push({
                            firstName : coworker.firstName,
                            lastName : coworker.lastName,
                            patronymic : coworker.patronymic,
                            id : coworker.id,
                            executedTime : [{
                                from : moment().startOf('day').format(format.time),
                                to : moment().startOf('day').format(format.time)
                            }]
                        })
                    }
                })
            }

            const data = this.props.actNumbers.map(item => {
                return {
                    actNumber : item,
                    date: moment(),
                    people : people,
                }
            });

            this.setState(() => ({
                data,
                closeTypes,

            }))
        }catch (e) {
            checkError(e)
        }
    }

    render () {

        return (
            <>
                <Table
                    columns={[
                        {
                            Header : getText('acts.actNumber'),
                            accessor : 'actNumber'
                        },
                        {
                            Header : getText('acts.actDate'),
                            Cell : (props:any) => {
                                return <InlineCalendar date={props.original.date} onChange={(date:Moment) => {
                                    this.changeActDate(date, props)
                                }} />
                            }
                        },
                        {
                            Header : getText('workorders.timeExpended'),
                            Cell : (props:any) => this.getExpiredTimeView(props)
                        },
                        {
                            Header: getText('closeTypes.closeType'),
                            Cell: this.select
                        }

                    ]}
                    data={this.state.data}
                />

                <p className="text-danger margin-top">
                    <b>{getText('common.warning')}</b>: {getText('workorders.timeExpendedWarning.button')} <b>"{getText('common.cancel')}"</b> {getText('workorders.timeExpendedWarning.message')} <b>00:00</b> {getText('workorders.timeExpendedWarning.andPress')} <b>"{getText('common.save')}"</b>
                </p>


                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-danger" onClick={this.save}>{getText('common.create')}</Button>
                </DefaultFooterModal>
                <Spinner loading={this.state.loading} />

            </>
        )
    }
}

export default SaveWorkOrderModal;