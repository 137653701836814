import React from 'react'
import Input from '../../../UI/input/text'
import Table from '../../../UI/table/table'
import Button from '../../../UI/button/button'
import DeleteCell from '../../../UI/table/deleteCell'
import Dropdown from '../../../UI/dropdownMenu/dropdown.controller'
import getText from '../../../../localization/getText'
import { PHONE_TYPE, EMAIL_TYPE, MESSENGER_TYPE } from './contactTypes'
import RenderIf from '../../../../utils/renderIf'

interface IProps {
	contacts: any
	types: any
	contact?: string
	addContact?: any
	updateContact?: any
	deleteContact?: any
}

const Contacts: React.FC<IProps> = ({
	contacts,
	types,
	contact,
	addContact,
	updateContact,
	deleteContact,
}) => {
	const findActive = (types, typeToFind) => {
		const result = types.map((type) => {
			if (type.id == typeToFind.id) {
				return { ...type, active: true }
			}
			return type
		})
		return result
	}

	const getHeaderName = (contact) => {
		switch (contact) {
			case PHONE_TYPE:
				return getText('persons.phone')
			case EMAIL_TYPE:
				return getText('persons.email')
			case MESSENGER_TYPE:
				return getText('persons.messenger')
			default:
				return ''
		}
	}

	const getAddName = (contact) => {
		switch (contact) {
			case PHONE_TYPE:
				return getText('persons.addPhone')
			case EMAIL_TYPE:
				return getText('persons.addEmail')
			case MESSENGER_TYPE:
				return getText('persons.addMessenger')
			default:
				return ''
		}
	}

	return (
		<div className=''>
			<RenderIf condition={contacts}>
				<Table
					data={contacts}
					columns={[
						{
							Header: getHeaderName(contact),
							Cell: (props: any) => {
								const typeToFind =
									props.original.phoneType ||
									props.original.emailType ||
									props.original.messengerType
								return (
									<Dropdown
										list={findActive(types, typeToFind)}
										onChange={(newValue) =>
											updateContact(
												contact,
												props.original.id,
												newValue.id,
												props.original.value,
											)
										}
									/>
								)
							},
						},
						{
							Header: getText('persons.contact'),
							Cell: (props: any) => {
								const typeToFind =
									props.original.phoneType ||
									props.original.emailType ||
									props.original.messengerType
								return (
									<Input
										onBlur={(e) =>
											updateContact(
												contact,
												props.original.id,
												findActive(
													types,
													typeToFind,
												).find((type) => type.active)
													.id,
												e.target.value,
											)
										}
										startValue={props.original.value}
									/>
								)
							},
						},
						{
							Header: getText('common.delete'),
							Cell: (props: any) => (
								<Button
									className={'btn-success'}
									onClick={() =>
										deleteContact(
											contact,
											props.original.id,
										)
									}
								>
									<i className='fa fa-trash no-text' />
								</Button>
							),
						},
					]}
				/>
			</RenderIf>
			<Button
				onClick={() => addContact(contact)}
				className={'btn-success margin-top person-contacts_button'}
			>
				{getAddName(contact)}
			</Button>
		</div>
	)
}

export default Contacts
