import React, { useState, useCallback } from 'react';
import withLabel, { LabelProps } from '../withLabel/withLabel';
import getText from '../../../localization/getText';
import { renderToString } from 'react-dom/server';

type TextAreaType = React.FormEvent<HTMLTextAreaElement>;

export interface ITextArea {
  className?: string;
  value?: string;
  label?: LabelProps;
  startValue?: string;
  isDisabledButton?: boolean; // Для блокироваки кнопки если такая есть
  onSend?(): void; // при отправке с другого обработчика , например, кнопка
  change?(value: string, oldValue?: string): void; // при обработке onChange
  blur?(val: string, oldValue?: string): void; // при обработке onBlur

  isDisabled?: boolean;
  placeholder?: string;
  [key: string]: any;
}

const TextArea = (props: ITextArea) => {
  const {
    className,
    onSend,
    startValue,
    label,
    change,
    blur,
    isDisabledButton,
    isDisabled = false,
    placeholder,
    ...rest
  } = props;

  const [value, setValue] = useState<string>('');
  const [oldValue, setOldValue] = useState<string>('');

  let cls = ['form-control', 'just-pro__text-area'];
  if (className) {
    cls.push(className);
  }

  const onChangeHandler = useCallback(
    async (e: TextAreaType) => {
      const targetValue = e.currentTarget.value;

      setOldValue(value);
      setValue(targetValue);

      if (!!change) {
        change(targetValue);
      }
    },
    [value, change]
  );

  const onBlurHandler = useCallback(() => {
    if (!!blur) {
      blur(value, oldValue);
    }
  }, [value, oldValue, blur]);

  const onFocusHandler = (e: TextAreaType) => {
    setOldValue(e.currentTarget.value);
  };

  const onKeyPressHandler = (e: React.KeyboardEvent) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();

      console.log('success...');
      setOldValue(value);

      const newValue = value + '\r\n';

      setValue(newValue);

      if (!!change) {
        change(newValue);
      }

      return void 0;
    }

    if (e.key === 'Enter') {
      e.preventDefault();

      if (!!onSend) {
        if (!!isDisabledButton) {
          return void 0;
        }
        onSend();
      } else {
        console.warn('Функция onSend не указана !');
      }
    }
  };

  const ref = useCallback(() => {
    setValue(startValue ? startValue : '');
  }, [startValue]);

  const id = 'textarea-' + Math.round(Math.random() * 999999);

  const jsx = (
    <form>
      <textarea
        ref={ref}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyPress={onKeyPressHandler}
        onFocus={onFocusHandler}
        className={cls.join(' ')}
        id={id}
        disabled={isDisabled}
        rows={3}
        placeholder={
          placeholder ? renderToString(getText(placeholder)) : undefined
        }
        autoComplete="off"
        {...rest}
      />
    </form>
  );

  return label ? withLabel(jsx)(label) : jsx;
};

export default TextArea;
