import React from "react";
import {
    Contract,
    ContractEquipmentCategory,
    ContractObject,
    getContract,
    createOrder,
    OTRequestFull,
    markProccessed,
    ContractCondition,
    ContractOrdersType,
    declineOrder,
    getPerson,
    getFullName,
    verifyOtRequest,
    getObjectName
} from "@justpro/terminal";
import moment from "moment";
import Select from "../../UI/select/select";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {connect} from 'react-redux'
import {ApplicationReducer} from "../../../store/application/application.types";
import {getUserMe} from "../../../store/application/application.actions";
import checkError from "../../../utils/checkError";
import TextArea from "../../UI/textArea/textArea.controller";
import Button from "../../UI/button/button";
import './otsync.css'
import OtsyncRegularWork from "./otsync.regularWork";
import {getInitiatorsList} from "../../../utils/functions";
import RenderIf from "../../../utils/renderIf";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import DeclineReasonModal from "../../orders/detail/declineReason.modal";
import Spinner from "../../UI/spinner/spinner.controller";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {openModal} from "../../../store/modal/modal.actions";
import OrderDetail from "../../orders/detail/order.detail";
import {confirm} from "../../UI/confirmAction/confirmAction.controller";


interface Props {
    otsync: OTRequestFull

    updateOtsyncList(): void

    clearItem(): void

    getSettings(): void

    settings: any

    openModal(data: Modal): void
}

interface State {
    atbContracts: Contract[]
    availableAtbContracts: Contract[]
    isOpenRefuse: boolean
    isOpenRegularWorks: boolean
    orderType?: Option,
    initiator?: Option,
    contractCondition?: Option
    equipmentCategory?: Option
    object?: Option
    additionalInfo?: string
    contactInfo?: string
    content?: string,
    loading: boolean
}

export const otsyncRegularWorksModalId = 'otsyncRegularWorksModal';

class OtsyncData extends React.Component<Props, State> {

    state: State = {
        atbContracts: [],
        availableAtbContracts: [],
        isOpenRefuse: false,
        isOpenRegularWorks: false,
        loading: false,
    };

    get defaultInitiator() {
        return {
            label: renderToString(getText('users.currentUser')),
            value: null
        };
    }

    get defaultValue() {
        return {
            label: renderToString(getText('common.unselected')),
            value: null
        };
    }

    getAtbContractConditions = (): LoadReturn => {
        return new Promise((resolve) => {
            const {availableAtbContracts} = this.state;

            const res = availableAtbContracts.reduce((acc: ContractCondition[], contract) => {
                if (contract.contractConditions) {
                    const exist = contract.contractConditions.filter(contractContractCondition => {
                        return !acc.find(cc => cc.id === contractContractCondition.id);
                    });
                    return [...acc, ...exist]
                }

                return acc;
            }, []);

            resolve(res)
        })

    };

    getAtbOrderTypes = (): LoadReturn => {
        return new Promise((resolve) => {
            const {availableAtbContracts} = this.state;

            const res = availableAtbContracts.reduce((acc: ContractOrdersType[], contract) => {
                if (contract.ordersTypes) {
                    const exist = contract.ordersTypes.filter(orderType => {
                        return !acc.find(ot => ot.id === orderType.id);
                    });
                    return [...acc, ...exist]
                }

                return acc;
            }, []);

            resolve(res)
        })
    };

    getAtbCategories = (): LoadReturn => {
        return new Promise((resolve) => {
            const {availableAtbContracts} = this.state;

            const res = availableAtbContracts.reduce((acc: ContractEquipmentCategory[], contract) => {
                if (contract.equipmentCategories) {
                    const exist = contract.equipmentCategories.filter(eqc => {
                        return !acc.find(i => i.equipmentCategoryId === eqc.equipmentCategoryId);
                    });
                    return [...acc, ...exist]
                }

                return acc;
            }, []);

            resolve(res)
        })
    };


    getAtbObjects = (): LoadReturn => {
        return new Promise((resolve) => {
            const {availableAtbContracts} = this.state;

            const res = availableAtbContracts.reduce((acc: ContractObject[], contract) => {
                if (contract.objects) {
                    const exist = contract.objects.filter(o => {
                        return !acc.find(i => i.objectId === o.objectId)
                    });
                    return [...acc, ...exist]
                }

                return acc;
            }, []).filter((o) => o.active).map((o) => ({...o, name: getObjectName(o)}));
            resolve(res)
        })

    };

    updateAvailableAtbContracts = () => {
        const {object, equipmentCategory, orderType} = this.state;
        this.setState((prevState: State) => {
            return {
                availableAtbContracts: prevState.atbContracts.filter((contract) => {
                    if (!object?.objectId) return true;
                    return !!contract?.objects?.find((obj) => obj.objectId === object.objectId)
                }).filter((contract) => {
                    if (!equipmentCategory?.equipmentCategoryId) return true;
                    return !!contract?.equipmentCategories?.find((eqc) => {
                        return eqc.equipmentCategoryId === equipmentCategory.equipmentCategoryId
                    })
                }).filter((contract) => {
                    if (!orderType?.value) return true;
                    return !!contract?.ordersTypes?.find((ot) => ot.id === orderType.value)
                })
            }
        })
    }

    changeObjectHandler = (option: Option) => {
        this.setState({
            object: option,
            equipmentCategory: this.defaultValue,
            orderType: this.defaultValue,
            contractCondition: this.defaultValue
        }, this.updateAvailableAtbContracts);
        return Promise.resolve();
    };


    changeCategoryHandler = (option: Option) => {
        this.setState({equipmentCategory: option}, this.updateAvailableAtbContracts);

        return Promise.resolve();
    };

    changeContractConditionHandler = (option: Option) => {
        this.setState({contractCondition: option}, this.updateAvailableAtbContracts);
        return Promise.resolve();

    };

    changeOrderTypeHandler = (option: Option) => {
        this.setState({orderType: option}, this.updateAvailableAtbContracts);
        return Promise.resolve();
    };

    changeInitiatorHandler = (option: Option) => {
        this.setState({
            initiator: option
        });
        return Promise.resolve();
    };

    additionalInfo = (additionalInfo: string) => {
        this.setState(() => ({additionalInfo}))
    };
    changeContactInfo = (contactInfo: string) => {
        this.setState(() => ({contactInfo}))
    };
    changeContent = (content: string) => {
        this.setState(() => ({content}))
    };

    getAtbContracts = async () => {
        const {settings} = this.props;
        try {
            if (this?.props.otsync?.contractId) {
                const contract = await getContract(this?.props.otsync?.contractId as number);
                this.setState(() => ({
                    atbContracts: [contract] || [],
                    availableAtbContracts: [contract] || []
                }))
            }
        } catch (e) {
            checkError(e)
        }
    };


    regularWorkBtnClickHandler = () => {
        this.props.openModal({
            id: otsyncRegularWorksModalId,
            component: (props: ModalBodyProps) => (
                <OtsyncRegularWork
                    {...props}
                    clearItem={this.props.clearItem}
                    equipmentCategoryId={+(this.state.equipmentCategory?.equipmentCategoryId || -1)}
                    objectId={+(this.state.object?.objectId || -1)}
                    otsync={this.props.otsync}
                    updateOtsyncList={this.props.updateOtsyncList}
                />
            ),
            title: getText('regularWorks.regularWork')
            // buttonSave : {
            //     title : getText('common.link'),
            // }
        });

        this.setState({
            isOpenRegularWorks: true
        });
    };

    refuseBtnClickHandler = () => {
        this.props.openModal({
            id: 'ordersDeclineOrder',
            component: (props) => <DeclineReasonModal {...props} save={this.declineOrder}/>,
            title: getText('orders.cancelReason')
        });

    };

    createOrder = async () => {
        const {object, orderType, initiator, atbContracts, equipmentCategory, contactInfo, content, additionalInfo, contractCondition} = this.state;
        const atbContract = atbContracts.find((contract) => {
            return contract?.objects?.some((obj) => +object?.objectId === obj.objectId) &&
                contract?.equipmentCategories?.some((eqc) => +equipmentCategory?.equipmentCategoryId === eqc.equipmentCategoryId)
        });
        if (!atbContract) {
            throw Error(renderToString(getText('errors.cannotFindContractWithThisParams')));
        }
        const created = await createOrder({
            otRequestId: this.props?.otsync?.id,
            dueDateCustomer: this.props?.otsync?.dueDate,
            objectId: object?.objectId,
            contractOrdersTypeId: +orderType?.value,
            equipmentCategoryId: +equipmentCategory?.equipmentCategoryId,
            contactInfo,
            content,
            additionalInfo,
            contractConditionsId: [contractCondition?.value],
            contractId: atbContract?.id,
            initiatorId: +initiator?.value,
            contractorId: atbContract?.contractor.id
        });
        return created;
    }

    afterCreate = (orderId: number) => {
        this.props.clearItem();
        this.props.updateOtsyncList();
        this.props.openModal({
            id: 'orderListItem',
            component: () => <OrderDetail id={orderId}/>,
            title: getText('orders.order'),
        });
        this.setState({
            loading: false,
            contactInfo: '',
            content: '',
            additionalInfo: ''
        })
    }

    createOrderBtnClickHandler = async () => {
        try {
            this.setState({
                loading: true
            })
            const verifyResponse = await verifyOtRequest(this?.props?.otsync?.id);
            let confirmCreate = true;
            if (verifyResponse?.isOrders || verifyResponse?.isRegularWorks) {
                confirmCreate = await confirm({
                    question: getText(`otsync.woExist`, {
                        number: this?.props?.otsync?.number
                    }),
                    confirmText: getText(`otsync.anywayCreate`),
                    declineText: getText(`otsync.cancel`)
                })
            }
            if (confirmCreate) {
                const order = await this.createOrder();
                this.afterCreate(order?.id);
            }
        } catch (err) {
            checkError(err)
        } finally {
            this.setState({
                loading: false
            });
        }
    };
    saveBtnClickHandler = async () => {
        try {
            this.setState({
                loading: true
            });
            await markProccessed(this.props?.otsync?.id);
            this.props.clearItem();
            this.props.updateOtsyncList();
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    declineOrder = async (reason: string) => {
        try {
            this.setState({
                loading: true
            });
            const order = await this.createOrder();
            const decline = await declineOrder(order.id, reason);
            this.afterCreate(order?.id);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }


    get isDisabled() {
        return !(this.state.equipmentCategory?.value &&
            this.state.object?.value &&
            this.state.orderType?.value)
    };

    get isDisabledRegular() {
        return !(this.state.equipmentCategory?.value &&
            this.state.object?.value);
    };

    async componentDidMount() {
        if (this.props.settings === undefined) {
            await  this.props.getSettings();
        }
        await this.getAtbContracts();
        return this.initialParams();
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.otsync.id !== prevProps.otsync.id) {
            await this.getAtbContracts();
            return this.initialParams();
        }
    }

    initialParams = async () => {
        const contactInfoArr = this.props.otsync.description
            .match(/(E-mail|Телефон\sгородской|Телефон\sгородской|Телефон\sмобильный|Телефон\sвнутренний)+:\s.+/gi);
        const endIndex = this.props.otsync.description.indexOf("***");
        const fullContent = this.props.otsync.description.slice(0, endIndex).match(/^[^\*\*]+/gmi)?.join(" ");
        const content = this.props.otsync.title.trim() !== fullContent?.trim() ? (this.props.otsync.title + ' ') + (fullContent || '') : fullContent;
        //@ts-ignore
        const prevObject = this.props?.otsync?.prevObject;
        const objects = await this.getAtbObjects();
        const isObject = objects.find((obj) => obj.id === prevObject);
        // const object = isObject ? {
        //     label: getObjectName(isObject),
        //     value: isObject.objectId
        // } : null;
        const object = null;
        //@ts-ignore
        const prevInitiator = this.props?.otsync?.prevInitiator;
        let initiator = null;
        // if(prevInitiator){
        //     try {
        //         const person = await getPerson(prevInitiator);
        //         if(person){
        //             initiator = {
        //                 value: person.id,
        //                 label: getFullName(person)
        //             }
        //         }
        //     } catch (err){
        //         console.log(err);
        //     }
        // }
        this.setState({
            content: content.trim(),
            contactInfo: contactInfoArr?.join("\n"),
            object: object || this.defaultValue,
            equipmentCategory: this.defaultValue,
            contractCondition: this.defaultValue,
            orderType: this.defaultValue,
            initiator: initiator || this.defaultInitiator,
            availableAtbContracts: this.state.atbContracts || []
        })
    }


    render() {
        const {otsync, settings} = this.props;

        const atbContractorId = settings && settings.atbContractorId && parseInt(settings.atbContractorId);


        if (!atbContractorId) {
            return null
        }
        const isRegular = otsync.description.includes(`СБУ Основания:\r\nТО ->`);
        console.log("IS REGULAR", isRegular);
        return (
            <>
                <Spinner loading={this.state.loading}/>
                <Button className="otsync-reset-button"
                        onClick={this.initialParams}>{getText('otsync.throwOff')}</Button>
                <div className="otsync-table">
                    <div className="otsync-table_headings">
                        <div className="otsync-table_heading" style={{maxWidth: '120px'}}>
                            {getText('UI.table.param')}
                        </div>
                        <div className="otsync-table_heading">
                            {getText('UI.table.valueOmniTracker')}

                        </div>
                        <div className="otsync-table_heading" style={{maxWidth: "300px", minWidth: "300px"}}>
                            {getText('UI.table.valueJustPro')}

                        </div>
                    </div>
                    <div className="otsync-table_row">
                        <div className="otsync-table_cell" style={{maxWidth: '120px'}}>
                            {getText('objects.object')}
                        </div>
                        <div className="otsync-table_cell">
                            {otsync.object}
                        </div>
                        <div className="otsync-table_cell" style={{maxWidth: "300px", minWidth: "300px"}}>
                            <Select
                                change={this.changeObjectHandler}
                                load={this.getAtbObjects}
                                accessors={{
                                    name: 'label',
                                    id: 'value'
                                }}
                                placeholder="UI.select.placeholders.selectAnObject"
                                defaultValue={undefined}
                                value={this.state.object}
                            />
                        </div>
                    </div>
                    <div className="otsync-table_row">
                        <div className="otsync-table_cell" style={{maxWidth: '120px'}}>
                            {getText('otsync.categoryOrCC')}
                        </div>
                        <div className="otsync-table_cell">
                            {otsync.sou}
                        </div>
                        <div className="otsync-table_cell" style={{maxWidth: "300px", minWidth: "300px"}}>
                            <Select
                                change={this.changeCategoryHandler}
                                load={this.getAtbCategories}
                                accessors={{
                                    name: 'label',
                                    id: 'value'
                                }}
                                placeholder="UI.select.placeholders.selectCategory"
                                value={this.state.equipmentCategory}
                            />
                            <Select
                                change={this.changeContractConditionHandler}
                                load={this.getAtbContractConditions}
                                accessors={{
                                    name: 'label',
                                    contractConditionId: 'value'
                                }}
                                className="margin-top"
                                placeholder="UI.select.placeholders.selectContractCondition"
                                value={this.state.contractCondition}
                            />
                        </div>
                    </div>
                    <div className="otsync-table_row">
                        <div className="otsync-table_cell" style={{maxWidth: '120px'}}>
                            {getText('otsync.typeOrPriority')}
                        </div>
                        <div className="otsync-table_cell">
                            {otsync.type}
                        </div>
                        <div className="otsync-table_cell" style={{maxWidth: "300px", minWidth: "300px"}}>
                            <Select
                                change={this.changeOrderTypeHandler}
                                load={this.getAtbOrderTypes}
                                accessors={{
                                    //todo change to alias
                                    name: 'label',
                                    id: 'value'
                                }}
                                placeholder="UI.select.placeholders.selectOrdersType"
                                value={this.state.orderType}
                            />
                        </div>
                    </div>
                    <div className="otsync-table_row">
                        <div className="otsync-table_cell" style={{maxWidth: '120px'}}>
                            {getText('common.initiator')}*
                        </div>
                        <div className="otsync-table_cell">
                            {otsync?.initiator}
                        </div>
                        <div className="otsync-table_cell" style={{maxWidth: "300px", minWidth: "300px"}}>
                            <Select
                                change={this.changeInitiatorHandler}
                                load={getInitiatorsList}
                                accessors={{
                                    name: 'label',
                                    id: 'value'
                                }}
                                placeholder="UI.select.placeholders.selectInitiator"
                                value={this.state.initiator}
                            />
                        </div>
                    </div>
                    <div className="otsync-table_group">
                        <div className="otsync-table_cell otsync-table_cell__no-padding">
                            <div className="otsync-table_row" style={{minHeight: "50px"}}>
                                <div className="otsync-table_cell" style={{maxWidth: "120px"}}>
                                    {getText('otsync.short')}
                                </div>
                                <div
                                    className="otsync-table_cell otsync-table_cell__no-border otsync-table_cell__hide-text">
                                    {otsync.title}
                                </div>
                            </div>
                            <div className="otsync-table_row">
                                <div className="otsync-table_cell" style={{maxWidth: "120px"}}>
                                    {getText('otsync.detail')}
                                </div>
                                <div
                                    className="otsync-table_cell otsync-table_cell__no-border otsync-table_cell__hide-text">
                                    {otsync.description}
                                </div>
                            </div>
                        </div>
                        <div className="otsync-table_cell otsync-table_cell__no-padding"
                             style={{maxWidth: "300px", minWidth: "300px"}}>
                            <div className="otsync-ta-wrappers">

                                <div className="otsync_ta-label">{getText('common.contentInfo')}</div>
                                <TextArea
                                    placeholder="UI.textArea.placeholders.contentAndMaterials"
                                    change={this.changeContent}
                                    value={this.state.content}
                                />
                                <div className="otsync_ta-label">{getText('common.additionalInfo')}</div>
                                <TextArea
                                    placeholder="common.additionalInfo"
                                    change={this.additionalInfo}
                                    value={this.state.additionalInfo}
                                />
                                <div className="otsync_ta-label">{getText('common.contactInfo')}</div>
                                <TextArea
                                    placeholder="UI.textArea.placeholders.contactInfo"
                                    change={this.changeContactInfo}
                                    value={this.state.contactInfo}
                                    rows={6}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="otsync-table_row">
                        <div className="otsync-table_cell" style={{maxWidth: "120px"}}>
                            {getText('otsync.protocol')}
                        </div>
                        <div className="otsync-table_cell otsync-table_cell__hide-text">
                            {otsync.protocol}
                        </div>
                        <div className="otsync-table_cell" style={{maxWidth: "300px", minWidth: "300px"}}>
                            <div className="otsync-ta-wrappers">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="otsync-buttons">
                    <RenderIf condition={isRegular}>
                        <Button className="btn-success"
                                disabled={this.isDisabledRegular}
                                onClick={this.regularWorkBtnClickHandler}>
                            {getText('regularWorks.regularWork')}
                        </Button>
                    </RenderIf>
                    <Button className="btn-danger"
                            disabled={this.isDisabled}
                            onClick={this.refuseBtnClickHandler}>{getText('orders.cancelOrder')}</Button>
                    <RenderIf condition={!(otsync?.orders?.length || otsync?.regularWorks?.length) && !isRegular}>
                        <Button
                            className="btn-success"
                            disabled={this.isDisabled}
                            onClick={this.createOrderBtnClickHandler}
                        >
                            {getText('orders.createOrder')}
                        </Button>
                    </RenderIf>
                    <RenderIf condition={otsync?.orders?.length || otsync?.regularWorks?.length}>
                        <Button className="btn-default" onClick={this.saveBtnClickHandler}>
                            {getText('common.save')}
                        </Button>
                    </RenderIf>
                </div>
            </>
        )
    }
}


interface MapStateToProps {
    application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
    settings: state.application.settings
});

const mapDisptachToProps = (dispatch: Function) => ({
    getSettings: () => dispatch(getUserMe),
    openModal: (data: Modal) => dispatch(openModal(data))
});

export default connect(mapStateToProps, mapDisptachToProps)(OtsyncData)
