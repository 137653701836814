import styled from "styled-components";

interface ScheduleView {
    isDisabled? : boolean
}

export const Schedule = styled.div<ScheduleView>`
    display : grid;
    grid-template-columns : 1fr 1fr;
    grid-template-row : 1fr 1fr;
    grid-gap: 10px;
    
    & .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows : repeat(5, 50px);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    
    
    & .weeks {
        display : grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 10px;
        grid-template-rows: 50px;  

    }
    
    & .weekdays {
        display : grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 50px;
        grid-gap: 10px;

    }
    
    
    & .months {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows : repeat(3, 30px);
        grid-column-gap: 5px;
        grid-row-gap: 5px;

    }
    
    
    & .cell {
        display : flex;
        justify-content: center;
        align-items : center;
        color : ${(props) => props.isDisabled ? '#c5cacb' : 'black'};

        cursor : ${(props) => props.isDisabled ? 'not-allowed' : 'pointer'};
        &.checked {
            border-bottom : 2px solid red;
        }
        
        &:hover {
            background-color : #C5CACB;
        }
    
    }
`;


