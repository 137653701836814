import React from 'react';
import {spinner} from './spinner.types';
import './spinner.css'


/*
* Родительскому элементу где крутиться спинер нужно дать класс  .just-pro-spinner_content
* что бы выровнять спинер по центру блока
* todo сделать недоступным контент позади спинера
* */

const Spinner = ({loading, size, full = false}:spinner) => {

    const cls:string[] = ['fa', 'fa-spinner', 'fa-spin'];

    if (size) {
        cls.push(`fa-${size}x`)
    }else{
        cls.push('fa-3x')
    }

    return loading ? (
        <div className={"just-pro-spinner_wrapper" + (full ? ' just-pro-spinner_full' : '')}>
            <div className="just-pro-spinner">
                <i className={cls.join(' ')} />
            </div>
        </div>
    ) : null
};

export default Spinner