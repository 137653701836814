import React from "react";
import {
    editEquipmentType,
    EquipmentCategory,
    EquipmentType,
    getEquipmentType
} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import EquipmentTypeDetail from "./equipmentType.detail";

interface Props {
    id : number
    afterUpdate?(item:EquipmentType) : void
}

interface State {
    equipmentType? : EquipmentType
    loading : boolean
}

class EquipmentTypeController extends React.Component<Props, State>{

    state:State = {
        loading : false
    };

    getEquipmentType = async () => {
        this.setState(() => ({loading : true}));
        try {
            const equipmentType = await getEquipmentType(this.props.id);

            this.setState(() => ({
                loading : false,
                equipmentType
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };

    updateEquipmentType = async (data:Partial<EquipmentCategory>) => {
        this.setState(() => ({loading : true}));
        try {
            const equipmentType = await editEquipmentType(this.props.id, data);

            this.props.afterUpdate
            && (
                data.hasOwnProperty('name') ||
                data.hasOwnProperty('active')
            )
            && this.props.afterUpdate(equipmentType);

            this.setState(() => ({
                loading : false,
                equipmentType
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };

    async componentDidMount() {
        this.getEquipmentType();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getEquipmentType();
        }
    }

    render() {
        return (
            <>
                <RenderIf condition={this.state.equipmentType !== undefined}>
                    <EquipmentTypeDetail
                        update={this.updateEquipmentType}
                        equipmentType={this.state.equipmentType as EquipmentType}
                    />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }
}

export default EquipmentTypeController