import React from 'react';
import {Country, getCountries, createCountry, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import CountryCreate from "./country.create";
import CountryController from "./country.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";

interface Props {
    rights? : Partial<ModulesResponse>
}

class CountriesController extends React.Component<Props>{

    renderItem = (item:Country) => <>{item.name}</>;

    render() {
        const {rights} = this.props;

        return <BaseReferenceController
            referenceName="countries.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getCountries}
            Detail={CountryController}
            create={{
                Component : CountryCreate,
                title : 'countries.addItem',
                handler : createCountry
            }}
            moduleRights={rights && rights['references.countries']}

        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(CountriesController)