import React from "react";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";
import TabContentHeader from "./tabContentHeader";
import withLabel from "../../UI/withLabel/withLabel";
import Input from "../../UI/input/text";
import PersonAvatar from "../../references/persons/person.avatar";
import PersonPhoto from "../../UI/dropzone/personPhoto/personPhoto.controller";
import getPersonPhoto from "../../../utils/getPersonPhoto";
import Contacts from '../../references/persons/contacts/person.contacts'
import types from '../../references/persons/contacts/typesData.json'
import { addContactData, updateContactData, deleteContactData } from '../../references/persons/contacts/contactHandlers'
import {PHONE_TYPE, EMAIL_TYPE, MESSENGER_TYPE} from '../../references/persons/contacts/contactTypes'
import {addUserPersonPhone, addUserPersonEmail, addUserPersonMessenger, updateUserPersonEmail,
    updateUserPersonPhone, updateUserPersonMessenger, deleteUserPersonEmail, deleteUserPersonMessenger,
    deleteUserPersonPhone} from "@justpro/terminal";
import checkError from "../../../utils/checkError";

interface Props {
    me?: any
    onChange(field?: string, value?: any): any
}

class Profile extends React.Component<Props>{

    onChange(field: string, value: string) {
        this.props.onChange && this.props.onChange(field, value);
    };

    setAvatar = async (file:File) => {
        this.props.onChange && this.props.onChange("avatar", file);
    };

    addContact = async (contactType) => {
        try {
            switch (contactType){
                case PHONE_TYPE: {
                    await addUserPersonPhone({
                        phoneTypeId: 1,
                        value: ' '
                    })
                    break;
                }
                case EMAIL_TYPE: {
                    await addUserPersonEmail({
                        emailTypeId: 1,
                        value: ' '
                    })
                    break;
                }
                case MESSENGER_TYPE: {
                    await addUserPersonMessenger({
                        messengerTypeId: 1,
                        value: ' '
                    })
                    break;
                }
                default: {
                    return;
                }
            }
            this.props.onChange && this.props.onChange();
        } catch(err){
            checkError(err);
        }
    }

    updateContact = async (contactType, contactId, typeId, value) => {
        try {
            switch (contactType){
                case PHONE_TYPE: {
                    await updateUserPersonPhone({
                        personPhoneId: contactId,
                        phoneTypeId: typeId,
                        value
                    })
                    break;
                }
                case EMAIL_TYPE: {
                    await updateUserPersonEmail({
                        personEmailId: contactId,
                        emailTypeId: typeId,
                        value
                    })
                    break;
                }
                case MESSENGER_TYPE: {
                    await updateUserPersonMessenger({
                        personMessengerId: contactId,
                        messengerTypeId: typeId,
                        value
                    })
                    break;
                }
                default: {
                    return;
                }
            }
            this.props.onChange && this.props.onChange();
        } catch(err){
            checkError(err);
        }
    }

    deleteContact = async (contactType, contactId) => {
        try {
            switch (contactType){
                case PHONE_TYPE: {
                    await deleteUserPersonPhone({
                        personPhoneId: contactId,
                    })
                    break;
                }
                case EMAIL_TYPE: {
                    await deleteUserPersonEmail({
                        personEmailId: contactId,
                    })
                    break;
                }
                case MESSENGER_TYPE: {
                    await deleteUserPersonMessenger({
                        personMessengerId: contactId,
                    })
                    break;
                }
                default: {
                    return;
                }
            }
            this.props.onChange && this.props.onChange();
        } catch(err){
            checkError(err);
        }
    }

    render() {
        const {me, onChange} = this.props;
        return (
            <div className="settings-tab-content">
                <TabContentHeader title="UI.tabs.profile"/>
                <div>
                    <div className="profile">
                        <div className="profile__avatar">
                            <PersonPhoto avatar={getPersonPhoto(me?.person?.avatar)}
                                         setAvatar={this.setAvatar}
                            />
                        </div>
                        <div className="profile__fio">
                            <Input label="persons.name"
                                   blur={this.onChange.bind(this, "firstName")}
                                   startValue={me?.person?.firstName}/>
                            <Input label="persons.lastName"
                                   blur={this.onChange.bind(this, "lastName")}
                                   startValue={me?.person?.lastName}
                            />
                            <Input label="persons.patronymic"
                                   blur={this.onChange.bind(this, "patronymic")}
                                   startValue={me?.person?.patronymic}
                            />
                        </div>
                    </div>
                </div>
                <Contacts 
                    contacts={me?.person?.phones} 
                    types={types.phoneTypes}
                    contact={PHONE_TYPE}
                    addContact={this.addContact}
                    updateContact={this.updateContact}
                    deleteContact={this.deleteContact}
                />
                <Contacts 
                    contacts={me?.person?.emails} 
                    types={types.emailTypes} 
                    contact={EMAIL_TYPE}
                    addContact={this.addContact}
                    updateContact={this.updateContact}
                    deleteContact={this.deleteContact}
                />
                <Contacts 
                    contacts={me?.person?.messengers} 
                    types={types.messengerTypes} 
                    contact={MESSENGER_TYPE}
                    addContact={this.addContact}
                    updateContact={this.updateContact}
                    deleteContact={this.deleteContact}
                />
            </div>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    me : state.application.me
})
export default connect(mapState)(Profile)