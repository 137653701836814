import React, { FC, ChangeEvent, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { TextAreaModal } from '../../UI/textArea/textAreaModal';
import Button from '../../UI/button/button';
import Input from '../../UI/input/text';
import { toast } from 'react-toastify';
import checkError from '../../../utils/checkError';
import { hideModal } from '../../../store/modal/modal.actions';
import { renderToString } from 'react-dom/server';
import getText from '../../../localization/getText';
import { sendPushDistanceTimes } from '@justpro/terminal';
import moment from 'moment';
import InlineCalendar from '../../UI/calendar/views/inline.controller';

export const SEND_PUSH_ID = 'SEND_PUSH_ID';

interface IProps {
  selectedForPush: number[];
  hideModal: (id: string) => void;
}

const PushModal: FC<IProps> = ({ selectedForPush, hideModal }) => {
  const [monthYear, setMonthYear] = useState(moment().subtract(1, 'months'));
  const [dueDate, setDueDate] = useState(moment());

  const sendPushMessageHandler = async () => {
    try {
      const success = await sendPushDistanceTimes({
        personsId: selectedForPush,
        monthYear: monthYear.format('MMMM YYYY'),
        dueDate: dueDate.format('DD.MM.YYYY'),
      });
      if (success) {
        toast.info(`Пуш-уведомление отправлено`);
      }
    } catch (error) {
      console.log(error);
      checkError(error);
    } finally {
      hideModal(SEND_PUSH_ID);
    }
  };

  return (
    <>
      <div className="push-modal_wrapper">
        <InlineCalendar date={monthYear}
                        onChange={setMonthYear}
                        mode={'monthSelect'}
                        label={{text: renderToString(getText('UI.modals.placeholders.chooseMonth'))}}
        />
        <InlineCalendar date={dueDate}
                        onChange={setDueDate}
                        label={{text: renderToString(getText('UI.modals.placeholders.chooseDate'))}}
        />
      </div>
      <div className="just-pro__modal-footer">
        <div className="buttons">
          <Button
            className="push-modal_button__margin"
            onClick={sendPushMessageHandler}
          >
            {getText('common.send')}
          </Button>
        </div>
      </div>
    </>
  );
};

const MapDispatch = (dispatch: Function) => ({
  hideModal: (id: string) => dispatch(hideModal(id)),
});

export default connect(null, MapDispatch)(PushModal);
