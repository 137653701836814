import React from 'react';
import {useDropzone} from "react-dropzone";
import './inlineDropzone.css'
import {MAX_FILE_SIZE} from "@justpro/terminal";
import GetText from "../../../../localization/getText";

interface Props {
    setFiles(acceptedFiles:File[], rejectedFiles?:File[]) :void
    types? : string[]
    title? : string
    children? : JSX.Element
    files? : any[]
    maxSize?: number
}

const InlineDropzone = (props:Props) => {
    // const maxSize = 5048576;
    const {types, maxSize} = props;

    const onDrop = (acceptedFiles:File[], rejectedFiles?:File[]) => {
        props.setFiles(acceptedFiles, rejectedFiles);
    };


    const {getRootProps, getInputProps, rejectedFiles} = useDropzone({
        accept : types,
        minSize : 0,
        maxSize : maxSize || MAX_FILE_SIZE,
        onDrop,
        noKeyboard : true
    });

    const isError = rejectedFiles.length > 0;
    let isFileTooLarge, isRejectType;

    if (isError) {
        isRejectType = types && !types.includes(rejectedFiles[0].type);
    }

    const placeholder = props.title !== undefined ?
                            GetText(props.title) :
                            GetText('UI.inlineDropzone.placeholder');

    return (
        <>
            <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <div className="just-pro__dropzone--inline">
                    {props.children && props.children}

                    { (!props.files || props.files.length === 0) && (
                        <span className="title">{placeholder}</span>
                    )}
                </div>
            </div>

            {isError && <div className="just-pro__drop-zone-error">
                {isFileTooLarge ? GetText('UI.inlineDropzone.largeFile', {mb : Math.round(MAX_FILE_SIZE / 1024 / 1024)}) :
                    isRejectType ? GetText('UI.inlineDropzone.type', {files : types?.map(item => '.' + item.split('/')[1]) || '...'}) :
                        GetText('UI.inlineDropzone.unexpectedError')}
            </div>}
        </>

    )
};

export default InlineDropzone;
