// type NotUnique = number | string | boolean

// export function getUniqueArray(array:NotUnique[]) {
//     return array.filter((item, index, currentArray) => {
//         return currentArray.indexOf(item) === index
//     })
// }

export interface Accessors {
    existId : string
    wholeId : string
}

export function getUniqueList<T extends Object>(wholeList:T[], existList?:T[], accessors:Accessors = {existId : 'id', wholeId : 'id'}):T[] {

    if(Array.isArray(existList) && existList.length === 0) {
        return wholeList
    }

    return wholeList.filter((item:T) => {
        if(existList) {
            for(let i = 0; i < existList.length; i++){
                if(item.hasOwnProperty(accessors.wholeId)) {
                    //@ts-ignore
                    if(existList[i][accessors.existId] === item[accessors.wholeId]) {
                        return false
                    }
                }else{
                    console.warn(`Prop ${accessors.wholeId} is missing in an object ${item}`)
                }

            }
        }

        return true
    });
}