import React, {Component} from 'react'
import MainNav from '../mainNav/mainNav.controller'
import './withPrivateRoute.css'
import {ModulesResponse} from '@justpro/terminal'
import {MainNavList} from '../mainNav/mainNav.types'
import {signOut} from '../../routing/utils'
import getText from '../../localization/getText'
import {renderToString} from 'react-dom/server'

const COMPLETE_STATUS = {
    COMPLETED: 'completed',
    SERVER_ERROR: 'server-error',
    CLIENT_ERROR: 'client-error',
    NOT_STARTED: 'not-started',
    CHANGED: 'changed',
}

export const restReferences: MainNavList = [
    {
        path: '/globalSettings',
        title: 'globalSettings.name',
        icon: '',
        isAvailable: (modules: ModulesResponse) => {
            return modules && modules['references.globalSettings'] && modules['references.globalSettings'].read
        },
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/specializations',
        title: 'specializations.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.specializations'] &&
            modules['references.specializations'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/logs',
        title: 'logs.name',
        icon: '',
        isAvailable: (modules: ModulesResponse) => {
            return modules && modules['references.logs'] && modules['references.logs'].read
        },
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/users',
        title: 'users.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules && modules['users'] && modules['users'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/persons',
        title: 'persons.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.persons'] &&
            modules['references.persons'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/masters',
        title: 'masters.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) => modules?.['references.masters']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/email-types',
        title: 'emailTypes.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.emails'] &&
            modules['references.emails'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/messenger-types',
        title: 'messengerTypes.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.messengers'] &&
            modules['references.messengers'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/phones',
        title: 'phones.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.phones'] &&
            modules['references.phones'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/transports',
        title: 'transports.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.transports'] &&
            modules['references.transports'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/brands',
        title: 'brands.moduleName',

        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.brands'] &&
            modules['references.brands'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/contractors',
        title: 'contractors.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.contractors'] &&
            modules['references.contractors'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/objects',
        title: 'objects.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.objects'] &&
            modules['references.objects'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },

    {
        path: '/fuels',
        title: 'fuels.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.fuels'] &&
            modules['references.fuels'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/units',
        title: 'units.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.units'] &&
            modules['references.units'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/ordersTypes',
        title: 'orderTypes.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.ordersTypes'] &&
            modules['references.ordersTypes'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },

    {
        path: '/contractConditions',
        title: 'contractConditions.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.contractConditions'] &&
            modules['references.contractConditions'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/tags',
        title: 'tags.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.tags'] &&
            modules['references.tags'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/waitRequests',
        title: 'waitRequests.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.waitRequests'] &&
            modules['references.waitRequests'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/contracts',
        title: 'contracts.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.contracts'] &&
            modules['references.contracts'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },

    {
        path: '/closeTypes',
        title: 'closeTypes.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.closeTypes'] &&
            modules['references.closeTypes'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
]

export const equipmentReferences = [
    {
        path: '/equipmentCategory',
        title: 'equipmentCategories.moduleName',

        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.equipmentCategories'] &&
            modules['references.equipmentCategories'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/equipmentTypes',
        title: 'equipmentTypes.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.equipmentTypes'] &&
            modules['references.equipmentTypes'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/equipmentWorks',
        title: 'equipmentWorks.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.equipmentWorks'] &&
            modules['references.equipmentWorks'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/workGroups',
        title: 'workGroups.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.workGroups'] &&
            modules['references.workGroups'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/equipments',
        title: 'equipments.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.equipments'] &&
            modules['references.equipments'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
]

export const statusesReferences = [
    {
        path: '/statusGroups',
        title: 'statusGroups.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.statusGroups'] &&
            modules['references.statusGroups'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/orderStatuses',
        title: 'orderStatuses.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.statuses'] &&
            modules['references.statuses'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/regularStatuses',
        title: 'regularStatuses.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.regularStatuses'] &&
            modules['references.regularStatuses'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
]

export const geographyReferences = [
    {
        path: '/areas',
        title: 'areas.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.areas'] &&
            modules['references.areas'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/countries',
        title: 'countries.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.countries'] &&
            modules['references.countries'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/cities',
        title: 'cities.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.cities'] &&
            modules['references.cities'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/regions',
        title: 'regions.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.regions'] &&
            modules['references.regions'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/squares',
        title: 'squares.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.squares'] &&
            modules['references.squares'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
]

export const staffReferences = [
    {
        path: '/departments',
        title: 'departments.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.departments'] &&
            modules['references.departments'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/positions',
        title: 'positions.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.positions'] &&
            modules['references.positions'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
    {
        path: '/staff',
        title: 'staff.moduleName',
        icon: '',
        isAvailable: (modules: ModulesResponse) =>
            modules &&
            modules['references.staff'] &&
            modules['references.staff'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        completeStatus: COMPLETE_STATUS.COMPLETED,
    },
]

export const references = [
    ...restReferences,
    ...statusesReferences,
    ...equipmentReferences,
    ...geographyReferences,
    ...staffReferences,
]

export const reports = [
    {
        path: '/reports/dispatcherWorks',
        title: 'reports.dispatcherWorks.moduleName',
        isAvailable: (modules: ModulesResponse) => true,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/executorReconciliation',
        title: 'reports.executorReconciliation.moduleName',
        isAvailable: (modules: ModulesResponse) => true,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/executorCompareHours',
        title: 'reports.executorCompareHours.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.compareExecutorsHours']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/actCloseTypes',
        title: 'reports.actCloseTypes.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.actsCloseTypes']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/actsTimeReport',
        title: 'Учет времени по актам',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.actsTimeReport']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/orderExecution',
        title: 'reports.orderExecution.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.ordersExecution']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/orderExecutionByRegions',
        title: 'reports.orderExecution.byRegions',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.ordersExecution']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/actsCheckHistory',
        title: 'reports.actsCheckHistory.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['acts.history']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/actsHistory',
        title: 'reports.actsHistory.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['acts.history']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/distanceReport',
        title: 'reports.distanceReport.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['reports.executorDistances']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/expiredOrders',
        title: 'reports.expiredOrders.moduleName',
        isAvailable: (modules: ModulesResponse) =>
            modules?.['orders.history']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/taskReport',
        title: 'reports.taskReport.moduleName',
        isAvailable: (modules: ModulesResponse) => true,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/summaryReport',
        title: 'reports.taskReport.summaryReport',
        isAvailable: (modules: ModulesResponse) => true,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/reports/timeDistanceCheck',
        title: 'reports.timeDistanceCheck.moduleName',
        isAvailable: (rights: ModulesResponse) =>
            rights?.['reports.reviseDistanceTimesStaff']?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
]

const modules: MainNavList = [
    {
        path: '/orders',
        title: 'orders.moduleName',
        icon: 'fas fa-envelope-open-text',
        isAvailable: (modules: ModulesResponse) =>
            modules && modules['orders'] && modules['orders'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        children: [
            {
                // handler() {
                //     //@ts-ignore
                //     this.setState(() => ({isOpenOrderModal : true}))
                // },
                modal: 'createOrder',
                isAvailable: (modules: ModulesResponse) =>
                    modules && modules['orders'] && modules['orders'].create,
                title: 'orders.createOrder',

                // isAvailable: (modules:ModulesResponse) => true,
            },
            {
                path: '/regularWorks',
                title: 'regularWorks.moduleName',

                icon: '',
                isAvailable: (modules: ModulesResponse) =>
                    modules &&
                    modules['regularWorks'] &&
                    modules['regularWorks'].read,
                getPriority: (modules: ModulesResponse) => 9999,
            },
            {
                path: '/ATB-sync',
                title: 'otsync.moduleName',
                icon: '',
                isAvailable: (modules: ModulesResponse) =>
                    modules && modules['otsync'] && modules['otsync'].read,
                getPriority: (modules: ModulesResponse) => 9999,
            },
        ],
    },
    {
        path: '/workOrders',
        title: 'workorders.moduleName',
        icon: 'fas fa-rocket',
        isAvailable: (modules: ModulesResponse) =>
            modules && modules['workorders'] && modules['workorders'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        children: [
            {
                title: 'workorders.createChecklistObject',
                isAvailable: (modules: ModulesResponse) =>
                    modules &&
                    modules['workorders'] &&
                    modules['workorders'].create,
                modal: 'createChecklistObject',
            },
            {
                title: 'workorders.createChecklist',
                isAvailable: (modules: ModulesResponse) =>
                    modules &&
                    modules['workorders'] &&
                    modules['workorders'].create,
                modal: 'createChecklist',
            },
            {
                title: 'workorders.createAdminWorkorder',
                isAvailable: (modules: ModulesResponse) =>
                    modules &&
                    modules['workorders'] &&
                    modules['workorders'].create,
                modal: 'createAdminWorkorder',
            },
            {
                path: 'calendar',
                title: 'calendar.moduleName',
                isAvailable: (modules: ModulesResponse) => true,
            },
        ],
    },
    {
        path: '/tasks',
        title: 'tasks.moduleName',
        icon: 'fas fa-clipboard-list',
        isAvailable: (modules: ModulesResponse) =>
            modules && modules['tasks'] && modules['tasks'].read,
        getPriority: (modules: ModulesResponse) => 9999,
        children: [
            {
                title: 'tasks.createTask',
                isAvailable: (modules: ModulesResponse) =>
                    modules && modules['tasks'] && modules['tasks'].create,
                // handler() {
                //@ts-ignore
                // this.setState(() => ({isOpenCreateTask : true}))
                // },
                modal: 'createTask',
            },
        ],
    },
    {
        path: '/acts',
        title: 'acts.moduleName',
        icon: 'fas fa-file-invoice-dollar',
        isAvailable: (modules: ModulesResponse) => modules?.acts?.read || false,
        getPriority: (modules: ModulesResponse) => 9999,
        // children : []
        children: [
            {
                title: 'acts.actScans',
                path: '/acts/scans',
                isAvailable: (rights: ModulesResponse) =>
                    rights?.scanningActs?.create,
                getPriority: (modules: ModulesResponse) => 9999,
            },
            {
                title: 'acts.packageUpload',
                path: '/acts/package-upload',
                isAvailable: (rights: ModulesResponse) =>
                    rights?.['acts.images']?.create,
                getPriority: (modules: ModulesResponse) => 9999,
            },
            {
                title: 'acts.mileage',
                path: '/acts/mileage',
                isAvailable: (rights: ModulesResponse) =>
                    rights?.['reports.executorDistances']?.create,
                getPriority: (modules: ModulesResponse) => 9999,
            },
            {
                title: 'acts.waybills',
                path: '/acts/waybills',
                isAvailable: (rights: ModulesResponse) =>
                    rights?.['reports.executorDistances']?.create,
                getPriority: (modules: ModulesResponse) => 9999,
            },
        ],
    },
    // {
    //     path : '/test',
    //     title : 'Test',
    //     icon : '',
    //     isAvailable: (modules:ModulesResponse) => true,
    //     getPriority : (modules:ModulesResponse) => 9999,
    // },
    // {
    //     path : '/tasks',
    //     title : 'Задачи',
    //     icon : '',
    //     isAvailable: (modules:ModulesResponse) => true,
    //     getPriority : (modules:ModulesResponse) => 9999,
    // },

    {
        path: '/reports',
        icon: 'fas fa-file-text',
        title: 'reports.moduleName',
        children: reports,
        isAvailable: (rights: ModulesResponse) => rights?.reports?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/references',
        icon: 'fas fa-book',
        title: 'common.references',
        children: references,
        isAvailable: (rights: ModulesResponse) => rights?.references?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/news',
        icon: 'fas fa-newspaper-o',
        title: 'Новости',
        isAvailable: (rights: ModulesResponse) => rights?.news?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/knowledge',
        title: 'База знаний',
        icon: 'fas fa-info-circle',
        isAvailable: (rights: ModulesResponse) => rights?.knowledge?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/maps',
        title: 'Карты',
        icon: 'fas fa-map-marked-alt',
        isAvailable: (rights: ModulesResponse) => rights?.maps?.read,
        getPriority: (modules: ModulesResponse) => 9999,
    },
    {
        path: '/account',
        title: 'account.moduleName',
        icon: 'fas fa-user-circle',
        isAvailable: (modules: ModulesResponse) => true,
        getPriority: (modules: ModulesResponse) => 9999,
        children: [
            {
                title: 'settings.moduleName',
                modal: 'settings',
                icon: '',
                isAvailable: (modules: ModulesResponse) => true,
                getPriority: (modules: ModulesResponse) => 9999,
            },
            {
                // path : '/sign',
                title: 'common.exit',
                handler: signOut,
                icon: '',
                isAvailable: (modules: ModulesResponse) => true,
                getPriority: (modules: ModulesResponse) => 9999,
            },
        ],
    },
]

class WithPrivateRoute extends React.Component<any, any> {
    componentDidUpdate(prevProps: any): void {
        // if(JSON.stringify(prevProps.children) !== JSON.stringify(this.props.children)) {
        this.setDocumentTitle()

        // }
    }

    componentDidMount(): void {
        this.setDocumentTitle()
    }

    getTitle = (root: MainNavList) => {
        const path = window.location.pathname

        return root.reduce((acc: string, item) => {
            if (item.path === path) {
                acc = item?.title || acc
            }

            if (item.children && acc === 'just.pro') {
                item.children.forEach((c) => {
                    if (c.path === path) {
                        acc = c?.title || acc
                    }
                })
            }

            return acc
        }, 'just.pro')
    }

    setDocumentTitle = () => {
        const title = this.getTitle(modules)
        document.title = renderToString(getText(title))
    }

    render() {
        return (
            <div className='just-pro__private-route-wrapper'>
                <MainNav list={modules}/>
                {this.props.children}
                <div className='just-pro__footer'>
                    &copy; {getText('footer.companyName')}, 2009—
                    {new Date().getFullYear()}
                </div>
            </div>
        )
    }
}

export default WithPrivateRoute
