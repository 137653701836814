import React, {useState} from 'react';
import InlineDropzone from "../dropzone/inline/inlineDropzone.controller";
import {File as ServerFile, IScan, PHOTOS_BEFORE} from "@justpro/terminal";
import Spinner from "../spinner/spinner.controller";
import FancyBox from "../fancybox_new/fancybox.controller";
import {toast} from "react-toastify";
import './photos.css'
import GetText from "../../../localization/getText";



interface Props {
    photos : ServerFile[]
    setPhoto(files:File[], rejectedFiles?: File[]) : Promise<void>


    withDelete? : {
        unsetPhoto(id:number) : Promise<void>

    }

    header? : string
    types? : string[]
    maxSize?: number
    hideTitle? : boolean
    showCorrectIcons? : boolean
}

const getPhotoDetail = (e:React.MouseEvent) => {
    e.stopPropagation();
};

const Photos = ({
                types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'],
                setPhoto,
                withDelete,
                photos,
                header,
                hideTitle,
                maxSize,
                showCorrectIcons,
                }:Props) => {
    const [loading, setLoading] = useState(false);


    const deleteHandler = async (e:React.MouseEvent, id?:number) => {
        e.stopPropagation();

        await unset(id)
    };

    const unset = async (id?: number) => {
        if(id) {
            setLoading(true);
            withDelete && await withDelete.unsetPhoto(id);
            setLoading(false)
        }else {
            toast.warn('Id photo не передано')
        }
    };



    // @ts-ignore
    return (
        <div className="just-pro__photos">
            {header ? <h4>{GetText(header)}</h4> : null}
            <InlineDropzone
                setFiles={setPhoto}
                types={types}
                files={hideTitle ? photos : undefined}
                maxSize={maxSize}
            >
                <div className="just-pro__photo_exists">
                    {photos?.map((item) => {
                        return (
                            <div key={item.id} className="just-pro__photo">

                                {withDelete && (
                                    <span className="close" onClick={(e:React.MouseEvent) => deleteHandler(e, item.id)}>x</span>
                                )}

                                <div onClick={getPhotoDetail}>
                                    <FancyBox
                                        file={item}
                                        images={photos}
                                        showCorrectIcons={showCorrectIcons}
                                        withDelete={{
                                            handler : unset
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </InlineDropzone>
            <Spinner loading={loading}/>
        </div>
    )
};

export default Photos