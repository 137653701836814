import React from 'react'
import './error.css'

interface IProps {
	title?: string
	message?: string
	onClose?: () => void
}

export const ErrorComponent = ({ title, message, onClose }: IProps) => (
	<div className='just-pro__error-container'>
		<div className='just-pro__error-logo icon_container'>
			<i className='fa fa-ban' />
		</div>
		<div className='just-pro__error-message'>
			<p className='just-pro__error-message__title'>
				{title ? title : ''}
			</p>
			<p className='just-pro__error-message__message'>
				{message ? message : ''}
			</p>
		</div>
		<div className='just-pro__error-cross icon_container' onClick={onClose}>
			<i className='fa fa-times' />
		</div>
	</div>
)
