import React from "react";
import { isEqual } from "lodash";
import Select from "../UI/select/select";
import InfoBlock from "../UI/infoBlock/infoBlock.controller";
import moment, {Moment} from "moment";
import {ChangeMultiProps, LoadReturn, MultiProps, Option} from "../UI/select/select.types";
import MultiSelect, {PersonLabel} from "../UI/select/multiSelect";
import Tabs from "../UI/tabs/tabs.controller";
import TaskBody from "./task.body";
import {
    ITask,
    ITaskTag,
    addTaskTag,
    deleteTaskTag,
    Tag,
    IUpdateTask,
    ITaskWatcher,
    ITaskCoworker,
    Person,
    addTaskCoworkers,
    deleteTaskCoworkers,
    addTaskWatchers,
    deleteTaskWatchers,
    SettingsMap,
    ModulesResponse,
    getPersons,
    getTaskActs, IAct, deleteTaskAct, getActs, addTaskAct, acceptTaskAdminAct
} from "@justpro/terminal";
import {getFullName} from "../../utils/names";
import {getUniqueList} from "../../utils/getUniqueArray";
import {getTagsList} from "../../utils/functions";
import checkError from "../../utils/checkError";
import TaskController, {IUpdateTaskIcons} from "./task.controller";
import PersonInfo from "../UI/dialog/personInfo/personInfo.controller";
import {MapState} from "../mainNav/mainNav.types";
import {connect} from "react-redux";
import Dropdown from "../UI/dropdownMenu/dropdown.controller";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import TaskListItem, {PRIORITY_MAP, STATES_MAP} from "./task.listItem";
import RenderIf from "../../utils/renderIf";
import InlineCalendar from "../UI/calendar/views/inline.controller";
import Spinner from "../UI/spinner/spinner.controller";
import {createSubtask, OrderListItem, getTaskOrders} from "@justpro/terminal";
import SelectParentTaskController from "./selectParentTask.controller";
import Comments from "../UI/comments/comments.controller";
import Files from "../UI/fileManager/files.controller";
import ModuleHistory from "../UI/moduleHistory/history.controller";
import {
    AddFile, addTaskComment, addTaskFile, Comment, deleteTaskFile, File, getTaskComments, getTaskFiles, getTaskHistory,
    History, TASK_READ, ITaskProblem, deleteMeFromTaskWatchers
} from "@justpro/terminal";
import HasNoRightsController from "../UI/hasNoRights/hasNoRights.controller";
import HasNoTaskAccess from "./hasNoTaskAccess";
import AsyncSelect, {CustomActOption} from "../UI/select/asyncSelect_v2";
import {ActionTypes} from "react-select";
import {makeNormalizeParams} from "../../utils/makeNormalizeParams";
import ActListItem from "../acts/act/act.listItem";
import TaskOrders from "./task.orders";
import Button from "../UI/button/button";
import GetText from "../../localization/getText";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";
import {openModal} from "../../store/modal/modal.actions";
import BlockController from '../UI/infoBlock/block.controller'
import TaskWaitModal from "./taskWaitModal";
import { isEquals } from "immutability-helper";


const fullDateFormat = 'DD.MM.YYYY HH:mm:ss';

interface Props {
    task: ITask
    me: any
    isTaskLoading: boolean
    settings?: SettingsMap
    openModal(props:Modal) : void

    updateAllList?(): any
    getTask?(): any

    updateListIcons?(taskId: number, data: IUpdateTaskIcons): void

    addTaskProblem(data: Partial<ITaskProblem>): any

    updateTask(data: Partial<IUpdateTask>): Promise<void>

    updateTaskProblem(data: Partial<ITaskProblem>): void

    rights?: Partial<ModulesResponse>
    isChosenSubordinate?: boolean
    subordinatePersonId?: number
}

export interface ISelectPerson {
    id: number,
    name: string,
    personId: number
}

interface ExtPerson extends Person {
    personId: number
}

interface State {
    tags: ITaskTag[]
    coworkers: ISelectPerson[]
    watchers: ISelectPerson[],
    loading: boolean
    personsToComment: (ITaskWatcher | ITaskCoworker | ExtPerson)[]
    filesLoading: boolean
    historyLoading: boolean
    comments: Comment[]
    history: History[]
    files: File[],
    showReadTask: boolean
    commentsLoading: boolean,
    acts: IAct[]
    orders: OrderListItem[]
    ordersLoading: boolean
}


class TaskDetail extends React.Component<Props, State> {

    state: State = {
        tags: [],
        watchers: [],
        coworkers: [],
        loading: false,
        personsToComment: [],
        filesLoading: false,
        historyLoading: false,
        comments: [],
        history: [],
        files: [],
        showReadTask: false,

        commentsLoading: false,
        orders: [],
        ordersLoading: false,
        acts: [],
    };

    allTags: Tag[] = [];


    getTasksActs = async () => {

        try {

            const acts = await getTaskActs(this.props.task.id);

            console.log({acts})

            this.setState(() => ({acts}))

        } catch (e) {
            checkError(e)
        }

    };

    addActTask = async (props: Option) => {
        console.log('add', {props})
        try {

            const act = await addTaskAct(this.props.task.id, props.value);

            console.log({act});

            this.setState((prevState) => ({
                acts: prevState.acts ? [...prevState.acts, act] : [act]
            }))


        } catch (e) {
            checkError(e)
        }

    }

    getActs = async (q: string): Promise<Option[]> => {
        try {
            const {list} = await getActs({q});

            return list?.filter(item => {
                return !this.state.acts.find(exist => exist.id === item.id)
            })?.map((item: IAct) => ({
                ...item,
                label: item.number,
                value: item.id
            })) || []

        } catch (e) {
            checkError(e);
            return []
        }
    }

    deleteTaskAct = async (act: IAct) => {
        try {
            const deleted = await deleteTaskAct(this.props.task.id, act.id);

            if (deleted) {
                this.setState((prevState) => ({
                    acts: prevState.acts.filter(a => a.id !== act.id)
                }))
            }

        } catch (e) {
            checkError(e)
        }
    };

    get canEdit(): boolean {
        return (this.props.task.initiator.id === this.props.me.person.id) ||
            (this.props.task.responsible.id === this.props.me.person.id);
    }

    get isInitiator(): boolean {
        return (this.props.task.initiator.id === this.props.me.person.id)
    }

    get tabs() {
        const {rights} = this.props;
        const result: any[] = [{
            title: "UI.tabs.comments",
            component: () => (
                <Comments
                    comments={this.state.comments}
                    addComment={this.addComment}
                    persons={this.state.personsToComment}
                    removePerson={this.removePersonFromComment}
                    rightsName="tasks.comments"
                    loading={this.state.commentsLoading}
                />
            ),
            onSelectTab: this.getComments
        }, {
            title: "UI.tabs.files",
            component: () => (
                <Files
                    files={this.state.files}
                    unsetFile={this.unsetTaskFile}
                    setFiles={this.setFiles}
                    isLoading={this.state.filesLoading}
                />
            ),
            onSelectTab: this.getFiles
        }, {
            title: "UI.tabs.history",
            component: () => {
                const history = !this.state.showReadTask ?
                    this.state.history.filter((h: History) => h.action !== TASK_READ) :
                    this.state.history;
                return (
                    <>
                        <div className="just-pro__task-hide-read-task">
                            <label>
                                <input type="checkbox" checked={this.state.showReadTask}
                                       onChange={this.onChangeShowReadTask}/>
                                <span>{getText('tasks.showViewHistory')}</span>
                            </label>
                        </div>
                        <ModuleHistory list={history}/>
                    </>
                )
            },
            onSelectTab: this.getHistory
        }];

        if (rights?.['tasks.orders']?.read) {
            result.push({
                title: "orders.orders",
                component: () => (
                    <TaskOrders taskId={this.props.task.id}/>
                )
            });
        }

        if (rights?.['acts.tasks']?.read) {
            result.push(
                {
                    title: "acts.acts",
                    component: () => {
                        const {acts} = this.state;

                        return <>

                            {/*{rights?.['acts.tasks']?.create &&*/}
                                <AsyncSelect
                                change={this.addActTask}
                                loadOptions={(q: string) => this.getActs(q)}
                                withoutValue={true}

                                // normalizeParams={{
                                //
                                // }}

                                // accessors={{
                                //     label : 'number',
                                //     value : 'id'
                                // }}
                                CustomOption={CustomActOption}
                                />
                            {/*}*/}


                            {acts.length === 0 ? <div className="task-orders__no-orders">{GetText('UI.tabAct.unlinked')}</div> : acts.map((item: IAct) => {
                                return <ActListItem
                                    act={item}
                                    withDelete={
                                        rights?.['acts.tasks']?.delete ? {
                                            handler: this.deleteTaskAct,
                                            title: "tasks.deleteActFromTask"
                                        } : undefined
                                    }
                                    withModuleDetail={true}
                                    key={item.id}
                                />
                            })}
                        </>;
                    },
                    onSelectTab: this.getTasksActs
                }
            );
        }

        return result;
    }

    get taskOptions(): DropDownItem[] {

        const {settings, task} = this.props;

        const fileOptions: DropDownItem[] = [{
            name: 'UI.dropDown.createSubTask',
            id: 3,
            icon: 'fa fa-level-down-alt',
            handler: this.createSubTask
        }];
        if (this.isInitiator) {
            fileOptions.push({
                name: 'UI.dropDown.specifyParentTask',
                id: 1,
                icon: 'fa fa-tasks',
                handler: this.setParentTask
            });
        }
        if(this.canEdit){
            fileOptions.push({
                name: 'UI.dropDown.waitTask',
                id: 8,
                icon: 'fa fa-clock',
                handler: this.openWaitModal.bind(this, this.props.task.id)
            });
        }
        if(task.hasAdminActs && task.state.id !== +settings.taskArchiveStateId){
            fileOptions.push({
                name: 'UI.dropDown.acceptAdminAct',
                id: 9,
                icon: 'fa fa-user-circle',
                handler: this.acceptTaskAdminAct
            })
        }
        fileOptions.push({
            name: 'UI.dropDown.transformToResolveProblem',
            id: 6,
            icon: 'fa fa-exclamation-circle',
            handler: this.setProblemTask
        });
        return fileOptions;
    };

    get taskPriorityList(): DropDownItem[] {
        const taskPriorityList: DropDownItem[] = [];
        Object.entries(PRIORITY_MAP).map(([priority, {color, name, icon}]) => {
            taskPriorityList.push({
                id: +priority,
                color,
                name,
                icon,
                active: +priority === this.props.task.priority
            })
        });
        return taskPriorityList;
    }

    componentDidMount() {
        return this.updateState(this.props);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            !isEqual(prevProps.task, this.props.task) || 
            !isEqual(prevProps.rights, this.props.rights)
        ) {
            return this.updateState(this.props);
        }
    }

    changeTab: ((index: number) => any) | void = void 0;

    onTabsInit = ({changeTab}: any) => {
        this.changeTab = changeTab;
    }

    updateState = (props: Props) => {
        this.setState({
            tags: props?.task?.tags || [],
            coworkers: props?.task?.coworkers?.map((cw: ITaskCoworker) => ({
                id: cw.id,
                name: getFullName(cw),
                personId: cw.personId,
                ...cw,
                customAction: cw?.personId !== this.props?.me?.person?.id ? {
                    name: 'tasks.includeToComment',
                    onAction: this.addPersonToComment.bind(this, cw)
                } : void 0,
            })) || [],
            watchers: props?.task?.watchers?.map((w: ITaskCoworker) => ({
                ...w,
                id: w.id,
                name: getFullName(w),
                personId: w.personId,
                customAction: w?.personId !== this.props?.me?.person?.id ? {
                    name: 'tasks.includeToComment',
                    onAction: this.addPersonToComment.bind(this, w)
                } : void 0,
            })) || []
        });
        this.changeTab && this.changeTab(0);
    };

    createSubTask = async () => {
        try {
            this.setState({
                loading: true
            });
            const subtask = await createSubtask(this.props.task.id);
            this.props.updateAllList && await this.props.updateAllList();
            this.openSubtaskModal(subtask.id);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    setParentTask = async () => {
        this.props.openModal({
            id : 'parentTask',
            component : (props:ModalBodyProps) => (
                <SelectParentTaskController {...props} selectTask={this.onSelectParentTask}/>
            ),
            title : getText('tasks.chooseParentTask'),
        })
    };

    setProblemTask = async () => {
        try {
            this.setState({
                loading: true
            });
            await this.props.addTaskProblem({description: renderToString(getText("tasks.reasonOfProblem")), solution: renderToString(getText("tasks.acceptedResolve"))});
            this.props.updateAllList && await this.props.updateAllList();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    acceptTaskAdminAct = async () => {
        try {
            this.setState({
                loading: true
            });
            await acceptTaskAdminAct(this.props.task.id);
            await this.props.getTask();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    printTask = () => {

    };

    addPersonToComment(person: ITaskWatcher | ITaskCoworker | ExtPerson) {

        this.setState(({personsToComment}) => {
            console.log({personsToComment, person})

            const find = personsToComment.find(p => p.personId === person.personId);

            return {
                personsToComment: !find ? [...personsToComment, person] : personsToComment
            }
        })
    }

    removePersonFromComment = (person: ITaskWatcher | ITaskCoworker | ExtPerson) => {
        this.setState((prevState) => ({
            personsToComment: prevState.personsToComment.filter((oldPerson) => {
                return oldPerson.personId !== person.personId;
            })
        }))
    }

    onChangeShowReadTask = (e: any) => {
        this.setState({
            showReadTask: e.target.checked
        })
    }

    onChangeTaskOption = (taskOption: DropDownItem) => {
        return taskOption.handler && taskOption.handler();
    };

    onChangeTaskPriority = (priority: DropDownItem) => {
        try {
            this.setState({
                loading: true
            });
            this.props.updateTask({
                priority: priority.id
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    onChangeCoworkers = async (option: Option, type?: ActionTypes) => {
        try {
            switch (type) {
                case "select-option":
                    const added = await addTaskCoworkers(this.props.task.id, {
                        coworkersId: [option.value] as number[]
                    });
                    const newCoworkers = added.map((cw: ITaskCoworker) => ({
                        id: cw.id,
                        name: getFullName(cw),
                        personId: cw.personId,
                        customAction: {
                            name: 'tasks.includeToComment',
                            onAction: this.addPersonToComment.bind(this, cw)
                        },
                        ...cw
                    }));
                    await this.setState((prevState: State) => ({
                        coworkers: [...prevState.coworkers, ...newCoworkers]
                    }));

                    return Promise.resolve(this.state.coworkers.map(item => makeNormalizeParams(item, {
                        name: 'label',
                        id: 'value'
                    })));

                    break;

                case "remove-value":
                    const deleted = await deleteTaskCoworkers(this.props.task.id, [option.value] as number[]);


                    if (deleted) {
                        this.setState((prevState) => ({
                            coworkers: prevState.coworkers.filter(cw => cw.id !== option.value)
                        }));
                    }
                    break;
            }
        } catch (e) {
            checkError(e)
        }
        return false
    };

    onChangeWatchers = async (option: Option, type?: ActionTypes) => {
        try {
            switch (type) {
                case "select-option":
                    const added = await addTaskWatchers(this.props.task.id, {
                        watchersId: [option.value] as number[]
                    });


                    const newWatchers = added.map((w: ITaskWatcher) => ({
                        ...w,
                        id: w.id,
                        name: getFullName(w),
                        personId: w.personId,
                        customAction: {
                            name: 'tasks.includeToComment',
                            onAction: this.addPersonToComment.bind(this, w)
                        },
                    }));

                    await this.setState((prevState: State) => ({
                        watchers: [...prevState.watchers, ...newWatchers]
                    }));

                    // return Promise.resolve(this.state.watchers.map(item => makeNormalizeParams(item, {name : 'label', id : 'value'})));
                    break;

                case "remove-value":
                    let deleted: any = null;

                    if (this.canEdit) {
                        deleted = await deleteTaskWatchers(this.props.task.id, [option.value] as number[]);
                    } else if (option?.personId === this.props?.me?.person?.id) {
                        deleted = await deleteMeFromTaskWatchers(this.props.task.id);
                    }
                    if (deleted) {
                        this.setState((prevState) => ({
                            watchers: prevState.watchers.filter(w => w.id !== option.value)
                        }));
                    }
                    break;
            }
        } catch (e) {
            checkError(e)
        }
        return false
    };

    onChangeTags = async (option: ChangeMultiProps) => {
        const {updateListIcons} = this.props;
        const {value, type} = option;

        try {
            switch (type) {
                case "select-option":
                    const added = await addTaskTag(this.props.task.id, value.value);

                    await this.setState((prevState) => ({
                        tags: [...prevState.tags, added]
                    }));

                    break;

                case "remove-value":
                    const deleted = await deleteTaskTag(this.props.task.id, value.value);

                    if (deleted) {
                        await this.setState((prevState) => ({
                            tags: prevState.tags.filter(tag => tag.id !== value.value)
                        }));
                    }
                    break;
            }
            updateListIcons && updateListIcons(this.props.task.id, {tags: this.state.tags});
        } catch (e) {
            checkError(e)
        }
        return false
    };

    getTasksTags = (): LoadReturn => {
        return new Promise((async resolve => {

            if (this.allTags.length === 0) {
                this.allTags = await getTagsList({modules: ['tasks']});
            }

            const unique = getUniqueList(this.allTags, this.state.tags, {wholeId: 'id', existId: 'tagId'});

            resolve(unique)
        }))

    };

    async pickupTask(field: 'responsibleId' | 'initiatorId') {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateTask({
                [field]: this.props.me?.person?.id
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    onChangeResponsible = async (responsible: Option) => {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateTask({
                responsibleId: responsible.value
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    onSelectParentTask = async (taskId: number) => {
        try {
            this.setState({
                loading: true
            });
            this.props.updateTask({
                parentTaskId: taskId
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    async updateTaskState(stateId: number) {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateTask({
                stateId
            });
            this.props.updateAllList && await this.props.updateAllList();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    getAvailableStates(currentStateId: number) {
        const currentState = STATES_MAP[currentStateId];
        return Object.entries(STATES_MAP).filter(([stateId, {states}]) => {
            if (+stateId === 4 && this.props?.me?.person?.id !== this.props?.task?.initiator?.id) {
                return false;
            }
            return currentState.states.includes(+stateId);
        }).map(([stateId, {color, icon, name}], index) => {
            return (
                <div className="task-status-button"
                     onClick={this.updateTaskState.bind(this, +stateId)}
                     key={index}
                     style={{backgroundColor: color || "#7c7c7c"}}>
                    <RenderIf condition={icon}>
                        <i className={`task-status-icon fa ${icon}`}/>
                    </RenderIf>
                    {getText(name)}
                </div>
            )
        });
    }

    changeDueDate = (dueDate: Moment) => {
        try {
            this.setState({
                loading: true
            });
            this.props.updateTask({
                dueDate: dueDate.format("YYYY-MM-DD HH:mm:ss")
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    getTaskPersons = (q: string = "", exists?: any[]): LoadReturn => {
        const {settings} = this.props;
        return new Promise(async (resolve, reject) => {
            try {
                const persons = await getPersons({q, contractorsId: [+settings?.tfmContractorId]});
                // const persons = await getPersons({q, initiatorsId : [60]});

                const unique = getUniqueList(persons, exists, {wholeId: 'id', existId: 'personId'});

                const result = unique.map(item => {
                    return {
                        ...item,
                        label: getFullName(item),
                        value: item.id
                    }
                });

                resolve(result);
            } catch (e) {
                checkError(e)
                resolve([])
            }

        })
    };


    setFiles = async (files: AddFile[]) => {
        this.setState({
            filesLoading: true
        });
        try {
            const newFiles = await addTaskFile(this.props.task?.id, files);
            if (newFiles) {
                await this.setState((prevState) => ({
                    files: [...prevState.files, ...newFiles]
                }));
            }
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                filesLoading: false
            });
        }
    };

    unsetTaskFile = async (fileId: number) => {
        try {
            this.setState({
                filesLoading: true
            });
            const deleted = await deleteTaskFile(this.props.task?.id, [fileId]);
            if (deleted) {
                await this.setState((prevState) => ({
                    files: prevState.files.filter(item => item.id !== fileId)
                }));
            }
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                filesLoading: false
            });
        }
    };


    getComments = async () => {
        try {

            this.setState(() => ({commentsLoading: true}));

            const comments = await getTaskComments(this.props.task?.id);
            this.setState({
                comments: comments.map((c) => {
                    return {
                        ...c,
                        customAction: c?.author?.person?.id !== this.props.me.person.id ? {
                            name: 'tasks.includeToComment',
                            onAction: this.addPersonToComment.bind(this, {
                                ...c?.author?.person,
                                personId: c?.author?.person?.id
                            })
                        } : null
                    }
                }),
                commentsLoading: false
            })

        } catch (e) {
            checkError(e)
        }
    };

    getHistory = async () => {
        try {
            const history = await getTaskHistory(this.props.task?.id);
            this.setState(() => ({
                history
            }))
        } catch (e) {
            checkError(e)
        }
    };

    getFiles = async () => {
        try {
            const files = await getTaskFiles(this.props.task?.id);
            this.setState(() => ({
                files
            }))
        } catch (e) {
            checkError(e)
        }
    };

    addComment = async (comment: string, files?: AddFile[]) => {

        const {rights} = this.props;

        if (rights && rights['tasks.comments'] && rights['tasks.comments']['create']) {
            try {
                const personsId = this.state.personsToComment.map((p: any) => p.personId);
                const newComment = await addTaskComment(this.props.task?.id, {
                    message: comment,
                    files,
                    personsId
                });
                if (newComment) {
                    this.setState((prevState) => ({
                        comments: [...prevState.comments, newComment],
                        personsToComment: []
                    }))
                }
            } catch (e) {
                checkError(e)
            }
        }
    };

    showRemove = (data: Option) => {
        return data.personId === this.props?.me?.person?.id;
    }

    openSubtaskModal = (taskId: number) => {
        this.props.openModal({
            id : `subTask${taskId}`,
            component : () => (
                <TaskController id={taskId} updateAllList={this.props.updateAllList} />
            ),
            title : getText('tasks.task'),
        })
    };

    openWaitModal = (taskId: number) => {
        this.props.openModal({
            id : `waitTask${taskId}`,
            component : (props) => (
                <TaskWaitModal {...props} taskId={taskId} updateAllList={this.props.updateAllList} />
            ),
            title : getText('tasks.task'),
        })
    };

    get infoBlockItems() {

        const {task} = this.props;
        const result:any[] = [
            {key : getText('tasks.task'), value : `${task.number} ${renderToString(getText('common.fromWho')).toLowerCase()} ${moment(task.createdAt).format(fullDateFormat)}`},
            {key : getText('common.initiator'), value : <PersonInfo position="down"
                                                                    person={task.initiator}
                                                                    openByHover
                                                                    customAction={task.initiator?.id !== this.props?.me?.person?.id ? {
                                                                        name: 'tasks.includeToComment',
                                                                        onAction: this.addPersonToComment.bind(this, {
                                                                            ...task.initiator,
                                                                            personId: task.initiator?.id
                                                                        })
                                                                    } : void 0}/>},
            {key : getText('common.responsible'), value : <PersonInfo position="down"
                                                                      person={task.responsible}
                                                                      openByHover
                                                                      customAction={task.responsible?.id !== this.props?.me?.person?.id ? {
                                                                          name: 'tasks.includeToComment',
                                                                          onAction: this.addPersonToComment.bind(this, {
                                                                              ...task.responsible,
                                                                              personId: task.responsible.id
                                                                          })
                                                                      } : void 0}/>},

        ];


        if(!this.isInitiator) {
            result.push({
                key : getText('common.dueDate'),
                value : moment(task.dueDate).format(fullDateFormat)
            })
        }

        if(task.state.id === 3) {
            result.push({
                key : getText('common.ended'),
            })
        }else{
            result.push({
                key : getText('tasks.stillAtWork')
            })
        }

        if(task.taskWait){
            result.push({
                key: getText("tasks.waitFor"),
                value: moment(task.taskWait?.endTime).format(fullDateFormat)
            })
        }


        return result;

    }



    render() {
        const {task, me, rights, isChosenSubordinate, subordinatePersonId} = this.props;
        const {loading} = this.state;
        const responsible = {
            name: getFullName(task.responsible),
            id: task.responsible.id,
        };
        const taskOptions = this.taskOptions;
        const currentState = STATES_MAP[this.props?.task?.state?.id];
        const hasAccessToTask = isChosenSubordinate || (
            task.responsible.id === me?.person?.id ||
            task.initiator.id === me?.person?.id ||
            !!task?.coworkers?.find(c => c.personId === me?.person?.id) ||
            !!task?.watchers?.find(w => w.personId === me?.person?.id)
        );

        const canRead = rights && rights['tasks'] && rights['tasks'].read;
        return canRead ?
            (<>
                {hasAccessToTask &&
                <>
                    <div className="just-pro__pre-detail-panel">
                        <div className="panel-toolbar">
                            <Dropdown
                                onChange={this.onChangeTaskOption}
                                list={taskOptions}
                                label={{
                                    name: "UI.dropDown.chooseAction",
                                    icon: ""
                                }}
                            />
                            <div/>
                            <Dropdown
                                onChange={this.onChangeTaskPriority}
                                list={this.taskPriorityList}
                                showActiveName={false}
                            />
                            <div className="task-header__buttons">
                                <RenderIf
                                    condition={isChosenSubordinate && subordinatePersonId === task.responsible.id}>
                                    <Button className="task-header__button task-header__button--responsible"
                                            onClick={this.pickupTask.bind(this, "responsibleId")}>
                                        {getText('tasks.pickUpTask')}
                                    </Button>
                                </RenderIf>
                                <RenderIf condition={isChosenSubordinate && subordinatePersonId === task.initiator.id}>
                                    <Button className="task-header__button task-header__button--initiator"
                                            onClick={this.pickupTask.bind(this, "initiatorId")}>
                                        {getText('tasks.beInitiator')}
                                    </Button>
                                </RenderIf>
                            </div>
                        </div>
                    </div>
                    <div className="just-pro__pre-detail-panel">
                        <div className="task-status-panel">
                            <RenderIf condition={this.canEdit}>
                                {this.getAvailableStates(this.props?.task?.state?.id)}
                            </RenderIf>
                            <RenderIf condition={!this.canEdit}>
                                <div className="task-status-button"
                                     style={{backgroundColor: currentState?.color || "#7c7c7c"}}>
                                    <RenderIf condition={currentState?.icon}>
                                        <i className={`task-status-icon fa ${currentState?.icon}`}/>
                                    </RenderIf>
                                    {getText(currentState?.name || '')}
                                </div>
                            </RenderIf>
                        </div>
                    </div>

                    <InfoBlock>
                        <BlockController items={this.infoBlockItems} />
                    </InfoBlock>
                    <div className="container-fluid">
                        <RenderIf condition={this.isInitiator}>
                            <InlineCalendar date={moment(task.dueDate)}
                                            onChange={this.changeDueDate}
                                            label={{text: "common.dueDate"}}
                                            timePicker
                            />
                        </RenderIf>
                        <RenderIf
                            condition={this.canEdit && (this.props?.task?.state?.id === 0 || this.props?.task?.state?.id === 1)}>
                            <div>
                                <AsyncSelect
                                    change={this.onChangeResponsible}
                                    loadOptions={this.getTaskPersons}
                                    values={[responsible]}
                                    normalizeParams={{
                                        label: 'name',
                                        value: 'id'
                                    }}
                                    isDisabled={!this.canEdit}
                                    label={{text: "common.executor"}}
                                    placeholder="UI.select.placeholders.selectExecutor"
                                />
                            </div>
                        </RenderIf>
                        <div>
                            <AsyncSelect
                                change={this.onChangeCoworkers}
                                loadOptions={(q: string) => this.getTaskPersons(q, this.state.coworkers)}
                                placeholder="UI.select.placeholders.selectCoworkers"
                                CustomLabel={PersonLabel}
                                isMulti
                                normalizeParams={{
                                    label: 'name',
                                    value: 'id'
                                }}
                                values={this.state.coworkers}
                                isDisabled={!this.canEdit}
                                label={{text: "common.coworkers"}}
                            />
                        </div>


                        <AsyncSelect
                            change={this.onChangeWatchers}
                            loadOptions={(q: string) => this.getTaskPersons(q, this.state.watchers)}
                            placeholder="UI.select.placeholders.selectWatchers"
                            isDisabled={!this.canEdit}
                            values={this.state.watchers}
                            normalizeParams={{
                                label: 'name',
                                value: 'id'
                            }}
                            showRemove={this.showRemove}
                            CustomLabel={PersonLabel}
                            isMulti
                            label={{text: "common.watchers"}}
                        />

                        <MultiSelect
                            change={this.onChangeTags}
                            load={this.getTasksTags}
                            placeholder="UI.select.placeholders.addTag"
                            accessors={{
                                name: 'label',
                                id: 'value',
                                firstName: "firstName"
                            }}
                            defaultValues={this.state.tags}
                            label="tags.tags"
                        />
                    </div>

                    <TaskBody
                        task={task}
                        updateTaskProblem={this.props.updateTaskProblem}
                        updateTask={this.props.updateTask}
                        readOnly={!this.isInitiator}
                        isTaskLoading={this.props.isTaskLoading}
                        isChosenSubordinate={isChosenSubordinate}
                    />
                    <Tabs tabs={this.tabs} defaultIndex={0} onInit={this.onTabsInit}/>
                    <Spinner loading={loading}/>
                </>
                }

                {!hasAccessToTask && <HasNoTaskAccess initiator={task.initiator}/>}

            </>) : <HasNoRightsController/>

    }
}

const mapStateToProps = (state: MapState) => ({
    me: state.application.me,
    settings: state.application.settings,
    rights: state.application.rights
});


const mapDispatch = (d: Function) => ({
    openModal : (props:Modal) => d(openModal(props))
});

export default connect(mapStateToProps, mapDispatch)(TaskDetail)
