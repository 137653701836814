import React from 'react';
import Schedule from "./schedule/schedule.controller";
import Button from "../../UI/button/button";
import {ISchedule, EquipmentWork, CreateWorkGroup} from "@justpro/terminal";
import Input from "../../UI/input/text";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Select from "../../UI/select/select";
import {getContractList, getEquipmentWorksList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import {toast} from "react-toastify";
import {fromDaysToHours} from "../../../utils/date";
import getText from "../../../localization/getText";
import {openModal} from "../../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {confirm} from "../../UI/confirmAction/confirmAction.controller";

interface Props {
    create(data:CreateWorkGroup) : void
    openModal(props:Modal) :void

}

interface State {
    schedule : ISchedule | null
    defaultOptions : EquipmentWork[]
    name : string
    plan? : string
    equipmentWorks : Option[]
    period? : string
    contractId? : number

    scheduleChanged : boolean
}

class WorkGroupCreate extends React.Component<Props,State>{

    state:State = {
        schedule : {
            days : [new Date().getDay()],
            weeks : [],
            daysOfWeek : [],
            month: [0,1,2,3,4,5,6,7,8,9,10,11]
        },
        scheduleChanged : false,
        name : '',
        equipmentWorks : [],
        defaultOptions: [],
    };

    create = () => {
        const {name, schedule, period, plan, equipmentWorks, contractId} = this.state;
        if (
            name.trim() !== '' &&
            schedule !== null &&
            plan !== undefined &&
            period !== undefined &&
            equipmentWorks.length > 0 &&
            contractId !== undefined
        ) {

            const ew: number[] = equipmentWorks.map((item) => item.value);
            const numericPlan = fromDaysToHours(plan);
            const numericPeriod = fromDaysToHours(period);

            if(numericPlan && numericPeriod) {
                this.props.create({
                    name,
                    schedule,
                    equipmentWorksId: ew,
                    period : numericPeriod,
                    planningTimeFrame: numericPlan,
                    contractId
                });
            }

        }
    };

    createHandler = async () => {
        const {scheduleChanged} = this.state;
        let agreed = true;
        if(!scheduleChanged) {
            agreed = await confirm({
                question : <h5>{getText('workGroups.scheduleDoesNotBeenChangeCreateInAnyway')}</h5>,
                title : getText('common.warning'),
                confirmText : getText('common.create'),
                declineText : getText('workGroups.backToEditing')
            });
        }
        if(agreed) {
            this.create();
        }

    };

    onScheduleChange = (schedule:ISchedule) => {
        this.setState(() => ({
            schedule,
            scheduleChanged : true
        }))
    };

    openModal = () => {

        const {schedule} = this.state;
        this.props.openModal({
            id : 'createWorkGroupSchedule',
            component : (props:ModalBodyProps) =>  (
                <Schedule {...props} onChange={this.onScheduleChange} schedule={schedule ? schedule : undefined} />
            ),
            title : getText('workGroups.scheduleAdding'),
            size : "large",
        })
    };


    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    addEquipmentWork = (option:Option) => {
        this.setState((prevState) => ({
            equipmentWorks : [...prevState.equipmentWorks, option],
            defaultOptions : prevState.defaultOptions.filter(item => item.id !== option.value)
        }));

        return Promise.resolve();
    };

    changePlan = (plan:string) => {
        this.setState(() => ({plan}))
    };

    isDisabled = () => {
        const {name, schedule, plan, period, equipmentWorks, contractId } = this.state;

        return  name.trim() === '' ||
                schedule === null ||
                plan === undefined ||
                period === undefined ||
                equipmentWorks.length === 0 ||
                contractId === undefined
    };

    changePeriod = (period:string) => {
        this.setState(() => ({period}))
    };

    changeContract = (option:Option) => {

        this.setState(() => ({contractId : option.value}));

        return Promise.resolve();
    };

    removeEquipmentWork = (props:any) => {
        const value = props?.original?.value;
        if(value) {
            this.setState((prevState) => ({
                equipmentWorks : prevState.equipmentWorks.filter(i => i.value !== value)
            }))
        }
    };

    async componentDidMount() {
        const defaultOptions = await getEquipmentWorksList();

        this.setState(() => ({defaultOptions}))
    }


    render () {
        const {equipmentWorks, schedule} = this.state;

        return (
            <>
                <h4>{getText('workGroups.addingNewHeader')}</h4>

                <Input label="workGroups.workGroup" change={this.changeName}/>

                <Select
                    change={this.changeContract}
                    load={getContractList}
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    label="contracts.contract"


                />

                <Input label="workGroups.planningTime" change={this.changePlan}/>

                {withLabel(
                    <>
                        <Button className="btn-success" onClick={this.openModal}>
                            {getText(schedule === null ? 'workGroups.createSchedule' : 'workGroups.editSchedule')}
                        </Button>
                    </>
                )({text : 'workGroups.schedule'})}

                {withLabel(
                    <>
                        {equipmentWorks.length > 0 ? (
                            <Table
                                columns={[
                                    {
                                        Header : getText('common.name'),
                                        accessor : 'label'
                                    },
                                    {
                                        Header : getText('common.exclude'),
                                        Cell : (props:any) => (
                                            <Button className="btn-success" onClick={this.removeEquipmentWork.bind(this, props)}>
                                                <i className="fa no-text fa-trash"/>
                                            </Button>)
                                    }
                                ]}
                                data={equipmentWorks}
                                minRows={0}
                                showPagination={false}
                            />
                        ) : null }

                        <Select
                            change={this.addEquipmentWork}
                            withoutValue={true}
                            accessors={{
                                id : "value",
                                name : "label" //
                            }}
                            defaultOptions={this.state.defaultOptions}
                            // load={this.loadEquipmentWorks}
                            placeholder="UI.select.placeholders.selectEquipmentWork"
                            className={equipmentWorks.length > 0 ? 'margin-top' : ''}
                        />

                    </>
                )({text : 'equipmentWorks.equipmentWork'})}

                <Input label="workGroups.period" change={this.changePeriod}/>

                <Button className="btn-success" onClick={this.createHandler} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

const mapDispatch = (d: Function) => ({
    openModal : (props:Modal) => d(openModal(props))
});
export default connect(null, mapDispatch)(WorkGroupCreate)