import React from 'react';
import Input from '../../UI/input/text'
import Button from '../../UI/button/button'
import Select from "../../UI/select/select";
import {Option} from '../../UI/select/select.types'
import {connect} from 'react-redux'
import {Props, State} from './addUser.types'
import {addUser} from '../../../store/users/actions/addUser/addUser.actions'
import {CreateUser} from '@justpro/terminal'
import {getContractorList, getPersonsList} from "../../../utils/functions";
import {UsersMapStateToProps} from "../users.types";
import AsyncSelect from "../../UI/select/asyncSelect";
import {ActionTypes} from "react-select";
import getText from "../../../localization/getText";

class AddUser extends React.Component<Props, State> {

    state:State = {
        username : '',
        password : '',
        personId : -1,
        contractorsId : [],
        personsList : [],
    };

    onChangeUserName = (val:string) => {
        this.setState({
            username : val
        })
    };

    onChangePassword = (val:string) => {
        this.setState({
            password : val
        })
    };

    verify() {
        const {
            username,
            password,
            personId,
            contractorsId
        } = this.state;

        return !username || !password || !(personId > 0) || !(contractorsId.length > 0);
    }

    addUser = async (treeId : number) => {
        const {
            contractorsId,
            personId,
            password,
            username
        } = this.state;

        this.props.addUser( {
                contractorsId,
                personId,
                password,
                username
            },
            treeId,
            this.props.tree
        )
    };

    changePerson = ({value}:Option) => {
        this.setState(() => ({
            personId : value
        }));

        return new Promise(resolve => {
            resolve(console.log('person', value))
        });
    };

    changeContractor = async (option:Option, action:ActionTypes) => {


        await this.setState((prevState) => ({
            contractorsId : action === 'remove-value' ?
                                    prevState.contractorsId.filter(id => id !== option.value) :
                                    [...prevState.contractorsId, option.value]
        }));

        return Promise.resolve(true);
    };

    getContractorsList = async (q:string) => {

        const all = await getContractorList({q});

        return all.map(c => ({
            ...c,
            label : c.name,
            value : c.id
        })).filter(c => !this.state.contractorsId.includes(c.id) )
    };


    render () {
        const {roleId, groupId, rolesMap, groupsMap, tree } = this.props;

        const map = tree === 'role' ? rolesMap : groupsMap;
        const treeId = tree === 'role' ? roleId : groupId;


        const disabled = this.verify();
        return (
            <div>
                <div>
                    <h2>{getText('users.addingNewUserIn')} {map[treeId].name}</h2>

                    <Select
                        label="persons.person"
                        change={this.changePerson}
                        load={getPersonsList}
                        autoComplete={"off"}

                        accessors={{
                            id : 'value',
                            name : 'label'
                        }}

                    />

                    <Input
                        label="users.username"
                        placeholder="users.username"
                        change={this.onChangeUserName}
                        autoComplete={"off"}
                    />

                    <AsyncSelect
                        change={this.changeContractor}
                        loadOptions={this.getContractorsList}
                        label={{text : 'contractors.contractor'}}
                        placeholder="UI.select.placeholders.selectContractors"
                        autoComplete={"off"}
                        isMulti
                    />

                    <Input
                        label="users.password"
                        placeholder="users.password"
                        autoComplete={"off"}
                        type="password"
                        change={this.onChangePassword}
                    />

                    <Button  disabled={disabled} onClick={this.addUser.bind(this, treeId)} className="btn-success" >{getText('common.add')}</Button>
                </div>
            </div>
        )

    }

}



const mapStateToProps = (state:UsersMapStateToProps) => ({
    rolesMap : state.users.roles.map,
    groupsMap : state.users.groups.map,
    roleId : state.users.roles.id,
    groupId : state.users.groups.id,
    tree : state.users.users.tree
});

const mapDispatchToProps = (dispatch:Function) => ({
    addUser : (data:CreateUser, id:number, treePanel:string) => dispatch(addUser(data, id, treePanel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
