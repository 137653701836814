import React, { useEffect, useState } from 'react'
import * as view from './order.view'
import Caret from '../../UI/caret/caret'
import {
	OrderListItem as IOrderListItem,
	getOrderSuborders,
	Order,
	IActOrder,
	SettingsMap,
	ModulesResponse,
} from '@justpro/terminal'
import ListIcons, {
	ListIconProps,
} from '../../UI/itemsList/icons/icons.controller'
import moment from 'moment'
import { Angle } from '../../UI/itemsList/card/card.view'
import Input from '../../UI/input/text'
import Spinner from '../../UI/spinner/spinner.controller'
import ModuleListItem from '../../UI/itemsList/moduleListItem/moduleListItem.controller'
import WithModuleDetail from '../../UI/dialog/withModuleDetail/withModuleDetail.controller'
import OrderDetail from '../detail/order.detail'
import Tooltip from '../../UI/tooltip/tooltip.controller'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import { getSettings } from '../../../store/application/application.actions'
import { getObjectName } from '../../../utils/names'
import RenderIf from '../../../utils/renderIf'
import getText from '../../../localization/getText'

type CheckboxEvent = React.FormEvent<HTMLInputElement>

export type ClientOrder = IActOrder | IOrderListItem | Order

interface Props {
	order: ClientOrder
	withCheckbox?: boolean
	withAct?: {
		onChangeActNumber(order: IOrderListItem | Order, value: string): void
		onChangePartial(order: IOrderListItem | Order, value: boolean): void
	}
	withActNumber?: boolean
	workOrdersHelper?: {
		changeHandler(order: IOrderListItem | Order): void
		checkedAll?: boolean
		list: IOrderListItem[]
	}
	suborderHelper?: {
		subordersCount: string
	}
	withDelete?: {
		deleteHandler(id: number): void
		title: string
		condition: boolean
	}
	activeOrderId?: number

	clickHandler?(id: number): void

	withBorderBottom?: boolean

	withIncludes?: {
		handleInclude(orderId: number): void
		title: string
	}

	withModuleDetail?: boolean

	className?: string

	isActSuborder?: boolean

	orderIdAccessor?: 'id' | 'orderId'
	settings?: SettingsMap
	isFirst?: boolean
	me?: any
	rights?: Partial<ModulesResponse>
}

export const getOrderListIcons = (
	order: IOrderListItem | IActOrder | Order,
): ListIconProps => {
	const result: ListIconProps = {}

	result.hot = !!order.hot
	result.orderStatus = order.orderStatus
	result.expired = !!order.expired
	result.executionTime =
		order.executionTime && order.executionTime.length > 0 ? true : undefined
	result.waitRequests =
		order.waitRequests && order.waitRequests.length > 0 ? true : undefined
	result.warranty = order.warranty
	result.highpriced = order.highpriced

	if (order.hasOwnProperty('filesCount')) {
		//@ts-ignore
		result.filesCount = order.filesCount || '0'
	}
	if (order.hasOwnProperty('offersCount')) {
		//@ts-ignore
		result.offersCount = order?.offersCount || '0'
	}
	if (order.hasOwnProperty('photosCount')) {
		//@ts-ignore
		result.photosCount = order?.photosCount || '0'
	}
	result.tags = order.tags
	result.contractConditions = order.contractConditions
	//@ts-ignore
	result.actNumber = order.actNumber
	result.masters = order.masters

	return result
}
const mapStateToProps = (state: MapState) => ({
	settings: state.application.settings,
	rights: state.application.rights,
	me: state.application.me,
})

const mapDispatchToProps = (dispatch: Function) => ({})
const OrderListItem = ({
	order,
	suborderHelper,
	workOrdersHelper,
	withAct,
	withActNumber,
	withDelete,
	activeOrderId,
	clickHandler,
	withIncludes,
	withModuleDetail,
	className,
	isActSuborder,
	orderIdAccessor = 'id',
	withBorderBottom = true,
	settings,
	isFirst = false,
	me,
	rights,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [subOrders, setSubOrders] = useState<IOrderListItem[]>([])
	const [loading, setLoading] = useState(false)
	//todo
	// const [actValue, setActValue] = useState('');
	const [partial, setPartial] = useState(false)

	const canCreateWorkOrder =
		rights && rights['workorders'] && rights['workorders']['create']

	const checkboxClickHandler = (e: React.MouseEvent) => {
		e.stopPropagation()
	}
	const onChangeIncludeToWorkOrder = () => {
		if (workOrdersHelper) {
			workOrdersHelper.changeHandler(order)
		}
	}

	const toggleOpenSuborders = async (e: React.MouseEvent, id: number) => {
		e.stopPropagation()

		setIsOpen(!isOpen)

		if (!isOpen) {
			setLoading(true)

			const list = await getOrderSuborders(id)

			setSubOrders(list)
			setLoading(false)
		} else {
			setSubOrders([])
		}
	}

	const onInputChange = (val: string) => {
		// setActValue(val);
		withAct && withAct.onChangeActNumber(order, val)
	}

	const onChangePartial = (e: CheckboxEvent) => {
		setPartial(e.currentTarget.checked)
		withAct && withAct.onChangePartial(order, e.currentTarget.checked)
	}

	const actZoneClickHandler = (e: React.MouseEvent) => {
		// setPartial(e.currentTarget.checked)
		e.stopPropagation()
	}

	const includesClickHandler = (e: React.MouseEvent, orderId: number) => {
		e.stopPropagation()

		withIncludes && withIncludes.handleInclude(orderId)
	}

	const onOrderClick = (e: React.MouseEvent) => {
		clickHandler && clickHandler(order.id)
	}

	const onExpandClick = () => {
		clickHandler && clickHandler(order.id)
	}

	const onExpandClose = () => {
		clickHandler && clickHandler(null)
	}

	const icons = getOrderListIcons(order)

	if (icons.actNumber) {
	}

	const mainBodyStyles = (opacity = 0.3) => ({
		background:
			order.contractOrdersType &&
			order.contractOrdersType.color +
				Math.round(opacity * 255).toString(16),
	})

	// useEffect(() => {
	//
	//     if (workOrdersHelper && workOrdersHelper.checkedAll !== undefined) {
	//         setIncludeToWorkOrder(workOrdersHelper.checkedAll)
	//     }
	//
	// }, [JSON.stringify(workOrdersHelper)]);
	console.log(mainBodyStyles())
	return (
		<div
			style={
				me?.settings?.fillOrders
					? mainBodyStyles(me?.settings?.fillOrdersOpacity)
					: {}
			}
		>
			<ModuleListItem
				isFirst={isFirst}
				isActive={
					withBorderBottom ? order.id === activeOrderId : undefined
				}
				onClick={onOrderClick}
				className={className}
			>
				{withAct !== undefined && (
					<div className='act-zone' onClick={actZoneClickHandler}>
						<span className='text'>
							{getText('acts.act')} № {order.act?.number}
						</span>

						{settings &&
							settings?.hasOwnProperty('orderDoneStatusId') &&
							settings?.['orderDoneStatusId'] !==
								order.orderStatus.id.toString() && (
								<>
									<Input change={onInputChange} />
									<div className='partial'>
										<input
											type='checkbox'
											checked={partial}
											onChange={onChangePartial}
											id={'partial' + order.id}
										/>
										<label htmlFor={'partial' + order.id}>
											{getText('orders.partial')}.
										</label>
									</div>
								</>
							)}
					</div>
				)}

				<div className='body'>
					<RenderIf condition={!me?.settings?.fillOrders}>
						<Angle
							size={40}
							color={
								order.contractOrdersType &&
								order.contractOrdersType.color
							}
						/>
					</RenderIf>

					<div className='icon-zone'>
						<ListIcons {...icons} isActSuborder={isActSuborder} />
					</div>

					<div className='content-text'>
						<p className='list-group-item-text'>
							<b>{getObjectName(order.object)}</b>
						</p>
						<p className='list-group-item-text'>{order.content}</p>
						<p className='list-group-item-text'>
							{order.additionalInfo}
						</p>
						<p className='list-group-item-text'>
							{order.contactInfo}
						</p>
						<RenderIf condition={order.otRequest}>
							<p className='list-group-item-text'>
								<b>
									{order?.otRequest?.number}
									{order?.otRequest?.baseDocument
										? ` (${order?.otRequest.baseDocument})`
										: ''}
								</b>
							</p>
						</RenderIf>
					</div>
				</div>

				<div className='number'>
					<RenderIf condition={withActNumber}>
						<b className='text'>
							{getText('acts.act')} №{order.act?.number}
						</b>
					</RenderIf>
					<b>{order.number}</b>
					<b>{moment(order.dateStart).format('DD.MM.YYYY')}</b>
				</div>

				<div className='workOrder-zone'>
					{!!workOrdersHelper &&
					order.canWorkorder &&
					canCreateWorkOrder ? (
						<input
							type='checkbox'
							onChange={onChangeIncludeToWorkOrder}
							checked={
								!!workOrdersHelper.list?.find(
									(i) => i.id === order.id,
								)
							}
							onClick={checkboxClickHandler}
						/>
					) : null}
				</div>

				<div className='helpers'>
					{withIncludes !== undefined && (
						<div>
							<Tooltip position='left' title={withIncludes.title}>
								<i
									className='fa fa-arrow-up include'
									onClick={
										//@ts-ignore
										(e: React.MouseEvent) =>
											includesClickHandler(
												e,
												order[orderIdAccessor],
											)
									}
								/>
							</Tooltip>
						</div>
					)}
					{!!withModuleDetail && (
						<div>
							<WithModuleDetail
								element={
									<>
										<Tooltip
											position='left'
											title='common.openDetailInfo'
										>
											<i
												onClick={onExpandClick}
												className='fa fa-expand expand'
											/>
										</Tooltip>
									</>
								}
								modal={{
									id: 'orderListItem',
									component: () => (
										<OrderDetail
											id={order[orderIdAccessor]}
										/>
									),
									title: getText('orders.order'),
									// afterClose: onExpandClose,
								}}
							/>
						</div>
					)}
					<RenderIf
						condition={
							withDelete !== undefined && withDelete?.condition
						}
					>
						<div
							onClick={
								withDelete?.deleteHandler &&
								withDelete?.deleteHandler?.bind(
									null,
									order[orderIdAccessor],
								)
							}
						>
							<Tooltip position='left' title={withDelete?.title}>
								<i className='fa fa-times' />
							</Tooltip>
						</div>
					</RenderIf>
				</div>

				{order.hasOwnProperty('subordersCount') &&
					suborderHelper &&
					+suborderHelper.subordersCount > 0 && (
						<div>
							<div
								className='suborders-count'
								onClick={(e: React.MouseEvent) =>
									toggleOpenSuborders(e, order.id)
								}
							>
								{
									//@ts-ignore
									order.subordersCount
								}{' '}
								<Caret isOpen={isOpen} />
							</div>
						</div>
					)}
			</ModuleListItem>

			{suborderHelper && +suborderHelper.subordersCount > 0 && isOpen ? (
				<view.SubOrdersWrapper>
					{subOrders &&
						subOrders.map((suborder) => {
							const order = Object.assign({}, suborder, {
								subordersCount: '0',
							})
							return (
								<OrderListItem
									isFirst={isFirst}
									activeOrderId={activeOrderId}
									order={order}
									clickHandler={clickHandler}
									key={order.number}
									settings={settings}
									me={me}
									rights={rights}
									workOrdersHelper={{
										changeHandler: workOrdersHelper
											? workOrdersHelper.changeHandler
											: () => void 0,
										list: workOrdersHelper
											? workOrdersHelper.list
											: [],
									}}
								/>
							)
						})}

					<Spinner loading={loading} />
				</view.SubOrdersWrapper>
			) : null}
		</div>
	)
}

interface MapState {
	application: ApplicationReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListItem)
