import React from "react";
import Input from "../../UI/input/text";
import {ModulesResponse, Transport} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    transport : Transport
    update(data:Partial<Transport>):void

    rights?: Partial<ModulesResponse>
}

class TransportDetail extends React.Component<Props> {

    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.update({name})
        }
    };

    changeActive = (active: boolean) => {
        this.props.update({active});
    };

    render () {
        const {rights, transport} = this.props;
        const {name, active} = transport;
        const disabled = rights && rights['references.transports'] && !rights['references.transports']['edit'];
        return (
            <>
                <h3>{getText('transports.transport')}: {name}</h3>

                <ToggleSwitch send={this.changeActive} label="common.active" defaultValue={active} disabled={disabled}/>

                <Input
                    blur={this.onBlurName}
                    label="transports.transport"
                    startValue={name}
                    disabled={disabled}
                />
            </>
        )
    }

}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(TransportDetail)