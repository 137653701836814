import React from "react";
import TabContentHeader from "./tabContentHeader";
import withLabel from "../../UI/withLabel/withLabel";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import InputSlider from './inputSlider'
import checkError from "../../../utils/checkError";
import { userSettingsChange } from "@justpro/terminal";

interface Props {
    me: any
    onChange?(field: string, value: boolean): any
}

class TabContentOrders extends React.Component<Props>{

    onChange(field: string, event: any){
        this.props.onChange && this.props.onChange(field, event?.target?.checked);
    }

    onChangeSlider(field: string, event: any){
        this.props.onChange && this.props.onChange(field, event);
    }

    render () {
        const {me} = this.props;
        return (
            <div className="settings-tab-content">
                <TabContentHeader title="orders.orders"/>

                <div>
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <input type="checkbox"
                                        id='orderColor_checkbox__filters'
                                        className='orderColor_checkbox'
                                       checked={me?.settings?.ordersLastFilters}
                                       onChange={this.onChange.bind(this, "ordersLastFilters")}/>
                            <label htmlFor='orderColor_checkbox__filters' className='orderColor_label'>
                                {getText('settings.useLastFilters')}
                            </label>
                        </div>
                    )({text : 'settings.filters'})}
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <input type="checkbox"
                                        id='orderColor_checkbox__comments'
                                        className='orderColor_checkbox'
                                       checked={me?.settings?.ordersComments}
                                       onChange={this.onChange.bind(this, "ordersComments")}/>
                            <label htmlFor='orderColor_checkbox__comments' className='orderColor_label'>
                                {getText('settings.watchingForNewCommentsInOrders')}
                            </label>
                        </div>
                    )({text : 'UI.tabs.comments'})}
                    {withLabel(
                        <>
                            <div className="notifications__checkbox-group">
                                <input 
                                    type="checkbox"
                                    checked={me?.settings?.fillOrders}
                                    onChange={(e) => this.onChange('fillOrders', e)}
                                />
                                <label className='orderColor_label'>
                                    {'Полная цветовая заливка заявок'}
                                </label >
                            </div>
                            <div className="notifications__checkbox-group">
                                <input 
                                    type="checkbox"
                                    checked={me?.settings?.fillOrdersExcel}
                                    onChange={(e) => this.onChange('fillOrdersExcel', e)}
                                />
                                <label className='orderColor_label'>
                                    {'Полная цветовая заливка заявок - Excel'}
                                </label>
                            </div>
                            <InputSlider
                                defaultValue={me?.settings?.fillOrdersOpacity}
                                max={1}
                                step={0.05}
                                changeHandler={this.onChangeSlider.bind(this)}
                            />
                        </>
                    )({text : 'Заливка заявок'})}
                </div>
            </div>
        )
    }
}

const mapState = (state: any) => ({
    me: state.application.me
})

export default connect(mapState)(TabContentOrders)