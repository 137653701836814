import React from "react";
import {CreatePhone} from "@justpro/terminal";
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreatePhone) : void
}


interface State {
    name? : string
}

export default class PhoneCreate extends React.Component<Props, State>{

    state:State = {

    };

    onChangeName = (val:string) => {
        this.setState(() => ({name : val}))
    };

    isDisabled = () => !this.state.name || this.state.name.trim() === '';


    createButton = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name as string})
        }
    };

    render() {

        return <>

            <h4>{getText('phones.addingNewHeader')}</h4>

            <Input
                placeholder="phones.phone"
                change={this.onChangeName}
                label="phones.phone"
                startValue=""
            />

            <Button className="btn-success" disabled={this.isDisabled()} onClick={this.createButton}>{getText('common.add')}</Button>

        </>
    }

}