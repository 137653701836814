import React from 'react';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {EquipmentCategory, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    equipmentCategory : EquipmentCategory,
    updateCategory(data:Partial<EquipmentCategory>) : void
    rights?: Partial<ModulesResponse>
}

class EquipmentCategoryDetail extends React.Component<Props>{

    changeName = (name:string, prevValue:string) => {
        if(name.trim() !== '' && name !== prevValue) {
            this.props.updateCategory({name})
        }
    };

    changeDescription = (description:string, prevDescription:string) => {
        if(description.trim() !== '' && description !== prevDescription) {
            this.props.updateCategory({description})
        }
    };

    toggleActive = (active:boolean) => {
        this.props.updateCategory({active})
    };

    render() {
        const {rights, equipmentCategory} = this.props;
        const {active, name, description} = equipmentCategory;
        const disabled = rights && rights['references.equipmentCategories'] && !rights['references.equipmentCategories']['edit'];

        return (
            <>
                <h4>{getText('equipmentCategories.equipmentCategory')}: {name}</h4>
                <ToggleSwitch defaultValue={active} label="common.active" send={this.toggleActive} disabled={disabled} />
                <Input
                    blur={this.changeName}
                    label="equipmentCategories.equipmentCategory"
                    startValue={name}
                    disabled={disabled}
                />
                <Input
                    blur={this.changeDescription}
                    label="common.description"
                    startValue={description}
                    disabled={disabled}
                />
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(EquipmentCategoryDetail)