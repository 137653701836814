import React from 'react';
import {
    filterParams,
    getObjects,
    Object,
    createObject, getCities, getRegions, getContractors, ModulesResponse,
} from "@justpro/terminal";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import BaseReferenceController from "../baseReference/baseReference.controller";
import ObjectController from "./object/object.controller";
import ObjectCreate from "./object/object.create";
import {getObjectName} from "../../../utils/names";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import {CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";
interface Props {
    rights?: Partial<ModulesResponse>
}

class Objects extends React.Component<Props>{

    renderItem = (item:Object) => {
        return (
            <div>
                <p><b>{getObjectName(item)}</b></p>
            </div>
        );
    };

    getCheckboxesMap = async () => {

        const result:CheckboxesMap = {};


        if(this.props.rights?.['references.cities']?.read) {
            const cities = await getCities({q : ''});

            result[filterParams.CITIES] = {
                name : 'cities.city',
                children : cities.filter((c) => c.active).sort((c1, c2) => c1.name.localeCompare(c2.name)),
            }
        }
        if(this.props.rights?.['references.contractors']?.read) {
            const contractors = await getContractors({q : ''});
            result[filterParams.CONTRACTORS] = {
                name : 'contractors.contractor',
                children : contractors.filter((c) => c?.active).sort((c1, c2) => c1.name.localeCompare(c2.name)),
            }
        }
        if(this.props.rights?.['references.regions']?.read) {
            const regions = await getRegions({q : ''});
            result[filterParams.REGIONS] = {
                name : 'regions.region',
                children : regions.sort((r1, r2) => r1.name.localeCompare(r2.name)),
            }
        }


        return result;
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.objects'];
        return <BaseReferenceController
            Detail={ObjectController}
            getList={getObjects}
            listItem={{
                render : this.renderItem,
            }}
            counterTranslates={{
                one: 'objects.vars.one',
                two: 'objects.vars.two',
                default: 'objects.vars.default',
                notFound: 'objects.vars.notFound'
            }}
            referenceName="objects.moduleName"
            create={{
                Component : ObjectCreate,
                title : "objects.addItem",
                handler: createObject
            }}
            filter={{
                getCheckboxesMap : this.getCheckboxesMap,
                filterName : filterNames.CITIES,
            }}
            moduleRights={rights}
        />

    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(Objects)