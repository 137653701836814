import React, {FC, useState} from 'react'
import {getEquipments, getObjectName, getWorkGroup, getWorkGroups, WorkGroup, getEquipmentTypes} from '@justpro/terminal';
import Input from "../../UI/input/text";
import {ObjectCheckbox, ObjectGroup} from '../workGroups/objectSelectModal';
import {useEffect} from 'react';
import Select from "../../UI/select/select";
import RenderIf from '../../../utils/renderIf';
import withLabel from "../../UI/withLabel/withLabel";
import getText from "../../../localization/getText";
import Button from '../../UI/button/button';
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import {ModalBodyProps} from "../../../store/modal/modal.types";

interface MassAddProps extends ModalBodyProps {
    handleSubmit({objectIds, equipmentTypeId, number}): void
}

export const MassAdd: FC<MassAddProps> = ({handleSubmit, hide}) => {

    const [selectedEquipmentId, setSelectedEquipmentId] = useState()
    const [number, setNumber] = useState('')
    const [contractObjects, setContractObjects] = useState([])
    const [sortedRegions, setSortedRegions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const requestEquipmentTypes = async (q: string) => {
        try {
            setIsLoading(true);
            const equipmentTypes = await getEquipmentTypes({ q })
            return equipmentTypes;
        } catch (err) {
            checkError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeEquipment = async (e) => {
        setSelectedEquipmentId(e.value)
        return Promise.resolve();
    }

    const requestWorkGroups = async () => {
        try {
            setIsLoading(true);
            const workGroups = await getWorkGroups({})
            const workGroupsFiltered = workGroups.filter(wg => wg.active)
            return workGroupsFiltered
        } catch (err) {
            checkError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeSelectedWorkGroup = async (e) => {
        try {
            setIsLoading(true);
            const workGroup: WorkGroup = await getWorkGroup(e.value)
            const contractObjects = workGroup?.contract?.objects as ObjectCheckbox[];
            const objectsWithCheck = contractObjects?.length ? contractObjects?.map(co => {
                co.checked = !!workGroup.objects.find(o => co.objectId === o.id)
                return co
            }) : []
            setContractObjects(objectsWithCheck)
            return Promise.resolve()
        } catch (err) {
            checkError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const updateObject = ({objectsId, checked}) => {
        const updated = contractObjects?.map((obj: any) => {
            if (objectsId.includes(obj.objectId)) {
                obj.checked = checked;
            }
            return obj;
        })
        setContractObjects(updated)
    }

    useEffect(() => {
        sortRegions()
    }, [contractObjects])

    const sortRegions = () => {
        const sortedRegionsToSet = contractObjects?.reduce((regionsList, currentObject: any) => {
            const currentRegion = regionsList?.find(region => region.id == currentObject?.square?.region?.id)
            const currentSquare = currentRegion?.squares?.find(square => square.id == currentObject?.square?.id)
            if (!currentRegion) {
                regionsList.push({
                    name: currentObject.square.region.name,
                    id: currentObject.square.region.id,
                    squares: [{
                        name: currentObject.square.name,
                        id: currentObject.square.id,
                        objects: [currentObject]
                    }]
                })
            }
            if (currentRegion && !currentSquare) {
                currentRegion.squares.push({
                    name: currentObject.square.name,
                    id: currentObject.square.id,
                    objects: [currentObject]
                })
            }
            if (currentSquare) {
                currentSquare.objects.push(currentObject)
            }
            return regionsList
        }, []);
        setSortedRegions(sortedRegionsToSet)
    }

    const onSubmitClick = async () => {
        try {
            setIsLoading(true);
            const params = {
                objectIds: contractObjects?.filter(object => object.checked)?.map(object => object.objectId),
                equipmentTypeId: selectedEquipmentId,
                number,
            }
            const response = await handleSubmit(params);
            hide();
        } catch (err) {
            checkError(err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Spinner loading={isLoading}/>
            <div className="workgroup__modal_inner">
                <Select
                    change={onChangeEquipment}
                    label="equipmentTypes.equipmentType"
                    load={requestEquipmentTypes}
                    placeholder={'equipmentTypes.equipmentType'}
                    accessors={{
                        name: "label",
                        id: 'value',
                    }}
                />
                {withLabel(
                    <Input
                        change={setNumber}
                        startValue={number}
                    />
                )({
                    text: "common.number"
                })}
                <Select
                    change={onChangeSelectedWorkGroup}
                    label="workGroups.workGroup"
                    load={requestWorkGroups}
                    placeholder={'workGroups.workGroup'}
                    accessors={{
                        name: "label",
                        id: 'value',
                    }}
                />
                <RenderIf condition={contractObjects.length}>
                    {withLabel(
                        <>
                        </>
                    )({
                        text: "objects.objects"
                    })}
                    {sortedRegions?.map(region => (
                        <ObjectGroup
                            type='regions'
                            element={region}
                            update={updateObject}
                        />
                    ))}
                </RenderIf>
            </div>
            <div className="just-pro__modal-footer">
                <Button onClick={onSubmitClick} className="btn-success">
                    {getText('common.accept')}
                </Button>
            </div>
        </div>
    )
}