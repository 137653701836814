import React, {Component, useState} from 'react';
import CreateWorkOrder from '../UI/dialog/createWorkOrders/createWorkOrder.controller';
import {ordersCreateWorkordersId} from '../orders/orders.controller';
import {connect} from 'react-redux';
import {
    Position,
    DistanceElement,
} from './maps.controller';

import {setWoArray, setArrangedWoArray} from '../../store/map/map.actions';
import moment from 'moment-timezone';
import {
    createWorkorderByOrders,
    format,
    OrderListItem,
    GOOGLE_API_KEY
} from '@justpro/terminal';
import {openModal} from '../../store/modal/modal.actions';
import {Modal} from '../../store/modal/modal.types';
import WorkOrderDetail from '../../components/workOrders/workOrder.detail';
import Table from '../UI/table/table';
import RenderIf from '../../utils/renderIf';
import getText from '../../localization/getText';
import Button from '../UI/button/button';
import Spinner from '../UI/spinner/spinner.controller';
import checkError from '../../utils/checkError';
import {Person} from '../../../../justpro-terminal/src/persons/persons.types';
import {Moment} from 'moment';
import {ModeType} from '../../store/map/map.types';
import CheckRoutes from './checkRoutes';

interface Props {
    isWoCreatorOpen: boolean;
    woArray: OrderListItem[];
    origin: Position | null;
    setWoArray: (...args: any) => any;
    distancesList: DistanceElement[];
    executors: any[];
    buildRoutes: (...args: any) => any;
    currentExecutor: any;
    toggleIsOpen: (...args: any) => any;
    openModal: (...args: any) => any;
    createWorkorder: (...args: any) => any;
    mode: ModeType;
}

interface State {
    optimize: boolean;
    date: Moment;
    coworkers: Person[];
}

class WoCreation extends Component<Props, State> {
    state = {
        optimize: true,
        date: moment(),
        coworkers: [],
    };

    componentDidUpdate(nextProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            this.buildRoutesHandler();
        }
    }

    buildRoutesHandler = () => {
        const {buildRoutes} = this.props;
        const {optimize} = this.state;
        buildRoutes(optimize);
    };

    onChangeOptimize = (e: any) => {
        this.setState({
            optimize: e.target.checked,
        });
        this.buildRoutesHandler();
    };

    updateWoArray = (ordersList) => {
        const {setWoArray} = this.props;
        setWoArray(ordersList);
    };

    changeDate = (date) => {
        this.setState({
            date,
        });
    };

    changeCoworkers = (coworkers) => {
        this.setState({
            coworkers,
        });
    };

    createWorkorder = () => {
        this.props.createWorkorder({
            coworkers: this.state.coworkers.map((cw) => cw.value),
            date: this.state.date.format('YYYY-MM-DD'),
        });
    };

    render() {
        const {
            woArray,
            distancesList,
            executors,
            currentExecutor,
            isWoCreatorOpen,
        } = this.props;
        const {optimize} = this.state;
        return (
            <div
                className={`work-order_creation ${
                    isWoCreatorOpen ? 'work-order_creation__expanded' : ''
                    }`}
            >
                <RenderIf condition={this.props.mode === 'DEFAULT'}>
                    <RenderIf condition={woArray?.length}>
                        <>
                            <CreateWorkOrder
                                orders={woArray}
                                onChangeOrdersList={this.updateWoArray}
                                onChangeDate={this.changeDate}
                                onChangeCoworkers={this.changeCoworkers}
                                executors={executors}
                            />
                            <div className="just-pro__checkbox-children">
                                <input
                                    type="checkbox"
                                    checked={optimize}
                                    onChange={this.onChangeOptimize}
                                />
                                {getText('calendar.optimize')}
                            </div>
                        </>
                        <RenderIf condition={distancesList?.length}>
                            <Table
                                columns={[
                                    {
                                        Header: getText('common.name'),
                                        Cell: (props: any) => {
                                            return <div>{props.original.address}</div>;
                                        },
                                    },
                                    {
                                        Header: getText('common.distance'),
                                        Cell: (props: any) => {
                                            return (
                                                <div>
                                                    {(props.original.distance / 1000).toFixed(2)} км.
                                                </div>
                                            );
                                        },
                                        Footer: () => (
                                            <b className="result justPro">
                                                {(
                                                    distancesList.reduce(
                                                        (total, d) => total + d.distance,
                                                        0
                                                    ) / 1000
                                                ).toFixed(2)}{' '}
                                                км.
                                            </b>
                                        ),
                                    },
                                ]}
                                data={distancesList}
                                showPagination={false}
                                minRows={0}
                            />
                        </RenderIf>
                    </RenderIf>
                    <RenderIf condition={!woArray?.length}>
                        <div className="just-pro__maps__no-orders">
                            Пожалуйста, добавте заявки, чтобы иметь возможность создать наряд.
                        </div>
                    </RenderIf>
                    <Button
                        className="btn-success"
                        disabled={!(currentExecutor && woArray?.length)}
                        onClick={this.createWorkorder}
                    >
                        {getText('common.create')}
                    </Button>
                </RenderIf>
                <RenderIf condition={this.props.mode === 'CHECK_ROUTES'}>
                    <CheckRoutes executors={executors}/>
                </RenderIf>
            </div>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
        woArray: state?.maps?.woArray,
        currentExecutor: state?.maps?.currentExecutor,
        mode: state?.maps?.mode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWoArray: (array) => {
            dispatch(setWoArray(array));
        },
        setArrangedWoArray: (array) => {
            dispatch(setArrangedWoArray(array));
        },
        openModal: (props: Modal) => {
            dispatch(openModal(props));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WoCreation);
