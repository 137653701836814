import React, {Component} from "react";

type Props =  {
    protocol?: string
}

export default class ProtocolController extends Component<Props>{
    render(){
        return (
            <div className="protocol-wrapper">
                {this.props.protocol}
            </div>
        )
    }
}