import React from 'react';
import {createPosition, getPositions, ModulesResponse, Position} from "@justpro/terminal";
import PositionCreate from "./position.create";
import PositionController from "./position.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class Positions extends React.Component<Props>{

    renderItem = (item:Position) => {
        return (
            <>{item.name}</>
        )
    };

    render() {
        const rights = this.props.rights && this.props.rights['references.positions'];
        return <BaseReferenceController
            listItem={{render : this.renderItem}}
            getList={getPositions}
            Detail={PositionController}
            referenceName="positions.moduleName"
            create={{
                handler : createPosition,
                Component : PositionCreate,
                title : 'positions.addItem'
            }}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(Positions)