import React from "react";
import './tasks.css'
import {ITask, IUpdateTask, ITaskProblem} from "@justpro/terminal";
import {connect} from "react-redux";
import { isEqual } from "lodash";
import {MapState} from "../mainNav/mainNav.types";
import RenderIf from "../../utils/renderIf";
import TaskListItem from "./task.listItem";
import TextEditorController from "../UI/textEditor/textEditor.controller";
import WithModuleDetail from "../UI/dialog/withModuleDetail/withModuleDetail.controller";
import TaskController from "./task.controller";
import getText from "../../localization/getText";
import getTextFromHtml from "../../utils/getTextFromHtml";

interface Props {
    task: ITask
    isTaskLoading: boolean
    me?: any
    updateTask: (data: Partial<IUpdateTask>) => any
    updateTaskProblem: (data: Partial<ITaskProblem>) => any
    readOnly?: boolean
    isChosenSubordinate?: boolean
}

interface State {
    title?: string
    content?: string
    problemDescription?: string
    problemSolution?: string
}

const prepareSubtasks = (subtasks: any) => {
    const sts = subtasks.map((subtask: any, index: number) => {
        const parent = subtasks.find((s: any) => s.id === subtask.parentTaskId);
        if (parent?.subtasks && !subtask.include) {
            parent?.subtasks?.push(subtask);
            subtask.include = true;
        } else if (parent && !parent.subtasks && !subtask.include) {
            parent.subtasks = [subtask];
            subtask.include = true;
        }
        return subtask;
    }).filter((st: any) => !st.include);
    return sts
}

class TaskBody extends React.Component<Props, State> {
    state = {
        title: this.props?.task?.title || '',
        content: this.props?.task?.content || '',
        problemDescription: this.props?.task?.problem?.description || '',
        problemSolution: this.props?.task?.problem?.solution || ''
    };

    onChangeTitle = async (title: string) => {
        this.setState({
            title
        })
    };

    onChangeContent = async (content: string) => {
        this.setState({
            content
        });
    };

    onChangeProblemDescription = async (problemDescription: string,) => {
        this.setState({
            problemDescription
        });
    };

    onChangeProblemSolution = async (problemSolution: string,) => {
        this.setState({
            problemSolution
        });
    };

    updateState = (props: Props) => {
        this.setState({
            content: props?.task?.content || '',
            title: props?.task?.title || '',
            problemDescription: props?.task?.problem?.description || '',
            problemSolution: props?.task?.problem?.solution || ''
        });
    };

    onBlurTitle = (preValue: string) => {
        if(this.props.isTaskLoading){
            return false;
        }
        if (this.state.title !== this.props?.task?.title) {
            this.props.updateTask({title: this.state.title});
        }
    };

    onBlurContent = (preValue: string) => {
        if (this.state.content !== this.props?.task?.content) {
            this.props.updateTask({content: this.state.content});
        }
    };

    onBlurProblemDescription = (preValue: string) => {
        if (this.state.problemDescription !== preValue) {
            this.props.updateTaskProblem({description: this.state.problemDescription});
        }
    };

    onBlurProblemSolution = (preValue: string) => {
        if (this.state.problemSolution !== preValue) {
            this.props.updateTaskProblem({solution: this.state.problemSolution});
        }
    };

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps, this.props)) {
            this.updateState(this.props);
        }
    }

    componentDidMount(): void {
        this.updateState(this.props);
    }

    renderSubtask = (task: ITask, deep: number = 0): any => {
        return (
            <>
                <WithModuleDetail
                    element={
                        <TaskListItem task={task} subtask={true} style={{width: `${100 - deep * 5}%`}}/>
                    }
                    modal={{
                        id : `task-subtask-${task.id}`,
                        component : () => (
                            <TaskController id={task?.id} isChosenSubordinate={this.props.isChosenSubordinate}/>
                        ),
                        title : getText('tasks.task'),
                    }}
                />
                {task.subtasks && task.subtasks.map((st) => this.renderSubtask(st, deep + 1))}
            </>
        )
    };

    render() {
        const {readOnly, task} = this.props;
        const {title, content, problemDescription, problemSolution} = this.state;
        const preparedSubtasks = prepareSubtasks(task?.subtasks);
        return (
            <>
                <div className="task-body row">
                    <RenderIf condition={task?.parentTask}>
                        <WithModuleDetail
                            element={
                                <div className="parent-task">
                                    <i className="parent-task-icon fa fa-level-up-alt"/>
                                    &nbsp;
                                    {task?.parentTask?.number}:
                                    &nbsp;
                                    {getTextFromHtml(task?.parentTask?.title)}
                                </div>
                            }
                            modal={{
                                id: `parentTask-${task?.parentTask?.id}`,
                                component : () => (
                                    <TaskController id={task?.parentTask?.id} isChosenSubordinate={this.props.isChosenSubordinate}/>
                                ),
                                title : getText('tasks.task'),
                            }}
                        />
                    </RenderIf>

                    <TextEditorController
                        className="task-body-heading"
                        onChange={this.onChangeTitle}
                        html={title}
                        readOnly={readOnly}
                        onBlur={this.onBlurTitle}
                        placeholder="tasks.header"
                        disableNewLine
                        customName="heading"
                    />
                    <TextEditorController html={content}
                                          onChange={this.onChangeContent}
                                          placeholder="tasks.body"
                                          readOnly={readOnly}
                                          onBlur={this.onBlurContent}
                                          toolbar
                    />
                </div>
                <RenderIf condition={task?.problem}>
                    <div className="task-body row">
                        <div className="task-body-heading">
                            <i className="fa fa-exclamation-circle"/>
                            &nbsp;
                            {getText('tasks.problemResolving')}:
                        </div>
                        <TextEditorController html={problemDescription}
                                              onChange={this.onChangeProblemDescription}
                                              placeholder="tasks.body"
                                              readOnly={readOnly}
                                              onBlur={this.onBlurProblemDescription}
                                              toolbar
                        />
                        <TextEditorController html={problemSolution}
                                              onChange={this.onChangeProblemSolution}
                                              placeholder="tasks.body"
                                              readOnly={readOnly}
                                              onBlur={this.onBlurProblemSolution}
                                              toolbar
                        />
                    </div>
                </RenderIf>
                <RenderIf condition={task?.subtasks?.length}>
                    <div className="task-body row">
                        <div className="task-body-heading">{getText('tasks.subTasks')}:</div>
                        {preparedSubtasks?.map((t: any) => this.renderSubtask(t, 0))}
                    </div>
                </RenderIf>
            </>
        )
    }
}

const mapStateToProps = (state: MapState) => ({
    me: state.application.me
});

export default connect(mapStateToProps, null)(TaskBody)