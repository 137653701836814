import { Specialization } from './../../../justpro-terminal/src/specializations/specializations.types';
import Users from "../components/users/users.controller";
import Test from "../components/test/test.controller";
import {RouteProps} from "react-router";
import Settings from '../components/settings/settings.controller'
import Orders from '../components/orders/orders.controller'

import GlobalSettings from '../components/references/globalSettings/globalSettings.controller';
import Logs from '../components/references/logs/logs.controller';
import Persons from "../components/references/persons/persons.controller";
import MastersController from "../components/references/masters/masters.controller";
import EmailTypes from "../components/references/emailTypes/emailTypes.controller";
import PhonesController from "../components/references/phones/phones.controller";
import Messengers from "../components/references/messengerTypes/messengers.controller";
import TransportsController from "../components/references/transport/transports.controller";
import EquipmentCategory from "../components/references/equipmentCategories/equipmentCategories.controller";
import EquipmentTypes from "../components/references/equipmentTypes/equipmentTypes.controller";
import Cities from "../components/references/cities/cities.controller";
import Areas from "../components/references/areas/areas.controller";
import Regions from "../components/references/regions/regions.controller";
import Brands from "../components/references/brands/brands.controller";
import Countries from "../components/references/countries/countries.controller"
import Squares from "../components/references/squares/squares.controller"
import References from '../components/references/mainReference.controller'
import Contractors from "../components/references/contractors/contractors.controller";
import Objects from '../components/references/objects/objects.controller';
import OrderStatusGroups from "../components/references/statusGroups/statusGroups.controller";
import OrderStatuses from "../components/references/orderStatus/orderStatuses.controller";
import Fuels from "../components/references/fuels/fuels.controller";
import Units from "../components/references/units/units.controller";
import OrdersTypes from "../components/references/ordersTypes/ordersTypes.controller";
import Positions from "../components/references/positions/positions.controller";
import ContractConditions from "../components/references/contractConditions/contractConditions.controller";
import Tags from '../components/references/tags/tags.controller'
import WaitRequestsController from "../components/references/waitRequest/waitRequests.controller";
import Contracts from "../components/references/contracts/contracts.controller";
import Departments from "../components/references/departments/departments.controller";
import EquipmentWorks from "../components/references/equipmentWorks/equipmentWorks.controller";
import Equipments from "../components/references/equipments/equipments.controller";
import RegularStatuses from "../components/references/regularStatuses/regularStatuses.controller";
import RegularWorks from "../components/regularWorks/regularWorks.controller";
import WorkGroups from "../components/references/workGroups/workGroups.controller";
import WorkOrders from "../components/workOrders/workOrders.controller";
import ActsController from "../components/acts/acts.controller";
import CloseTypesController from "../components/references/closeTypes/closeTypes.controller";
import ActsScansController from "../components/acts/scans/actsScans.controller";
import StaffsController from "../components/references/staff/staffs.controller";
import ActsPackageUploadController from "../components/acts/pakageUpload/actsPackageUpload.controller";
import ActsMileage from "../components/acts/mileage/actsMileage.controller";
import Waybills from "../components/acts/waybills/waybills.controller";
import TasksController from "../components/tasks/tasks.controller";
import OtsyncsController from "../components/references/otsync/otsyncs.controller";
import DispatcherWorksController from "../components/reports/dispatcherActs/dispatcherActs.controller";
import ExecutorsReconciliation from "../components/reports/executorsReconciliation/executorsReconciliation";
import ExecutorCompareHours from "../components/reports/executorCompareHours/executorCompareHours";
import NewsController from "../components/news/news.controller";
import ActCloseTypesController from "../components/reports/actsCloseTypes/actCloseTypes.controller";

import OrderExecution from "../components/reports/orderExecution/orderExecution";
import OrderExecutionByRegion from "../components/reports/orderExecution/orderExecutionByRegions";
import ActsCheckHistory from "../components/reports/actsCheckHistory/actsCheckHistory";
import ActsHistory from "../components/reports/actsHistory/actsHistory";
import ExpiredOrders from "../components/reports/expiredOrders/expiredOrders";
import DistanceReport from "../components/reports/distanceReport/distanceReport";
import TaskReport from "../components/reports/taskReport/taskReport";
import SummaryReport from "../components/reports/summaryReport/summaryReport";
import TimeDistanceReport from "../components/reports/timeDistanceReport/timeDistanceReport";
import SpecializationsController from "../components/references/specializations/specializations.controller";



import KnowledgeController from "../components/knowledge/knowledge.controller";
import PageNotFound from "../components/pageNotFound/pageNotFound.controller";
import ActsTimeReportController from "../components/reports/actsTimeReport/actsTimeReport";
import MapsController from "../components/maps/maps.controller";
import Calendar from "../components/calendar/calendar.controller";

export interface routeTypes extends RouteProps{
    title?: string,
    icon?: string,
    subroutes?: routeTypes[]
}

export default [
    {
        path: "/orders",
        exact: true,
        component: Orders
    },
    {
        path: "/users",
        exact: true,
        component: Users
    },
    {
        path: "/references",
        exact: true,
        component: References
    },
    {
        path: "/test",
        exact: true,
        component: Test
    },
    {
        path: "/globalSettings",
        exact: true,
        component: GlobalSettings
    },
    // {
    //     path: "/account",
    //     exact: true,
    //     component: Settings
    // },
    {
        path: "/settings",
        exact: true,
        component: Settings
    },
    {
        path: "/persons",
        exact: true,
        component: Persons
    },
    {
        path: "/masters",
        exact: true,
        component: MastersController
    },
    {
        path: "/email-types",
        exact: true,
        component: EmailTypes
    },
    {
        path: "/messenger-types",
        exact: true,
        component: Messengers
    },
    {
        path: "/transports",
        exact: true,
        component: TransportsController
    },
    {
        path: "/phones",
        exact: true,
        component: PhonesController
    },
    {
        path: "/equipmentCategory",
        exact: true,
        component: EquipmentCategory
    },
    {
        path: "/specializations",
        exact: true,
        component: SpecializationsController,
    },
    {
        path: "/equipmentTypes",
        exact: true,
        component: EquipmentTypes
    },
    {
        path: "/cities",
        exact: true,
        component: Cities
    },
    {
        path: "/areas",
        exact: true,
        component: Areas
    },
    {
        path: "/regions",
        exact: true,
        component: Regions
    },
    {
        path: "/brands",
        exact: true,
        component: Brands
    },
    {
        path: "/countries",
        exact: true,
        component: Countries
    },
    {
        path: "/squares",
        exact: true,
        component: Squares
    },
    {
        path: "/contractors",
        exact: true,
        component: Contractors
    },
    {
        path: "/objects",
        exact: true,
        component: Objects
    },
    {
        path: "/statusGroups",
        exact: true,
        component: OrderStatusGroups
    },
    {
        path: "/orderStatuses",
        exact: true,
        component: OrderStatuses
    },
    {
        path: "/fuels",
        exact: true,
        component: Fuels
    },
    {
        path: "/units",
        exact: true,
        component: Units
    },
    {
        path: "/ordersTypes",
        exact: true,
        component: OrdersTypes
    },
    {
        path: "/positions",
        exact: true,
        component: Positions
    },
    {
        path: "/contractConditions",
        exact: true,
        component: ContractConditions
    },
    {
        path: "/tags",
        exact: true,
        component: Tags
    },
    {
        path: "/waitRequests",
        exact: true,
        component: WaitRequestsController
    },
    {
        path: "/contracts",
        exact: true,
        component: Contracts
    },
    {
        path: "/departments",
        exact: true,
        component: Departments
    },
    {
        path: "/staff",
        exact: true,
        component: StaffsController
    },
    {
        path: "/equipments",
        exact: true,
        component: Equipments,
        params : {
            title : '123'
        }
    },
    {
        path: "/equipmentWorks",
        exact: true,
        component: EquipmentWorks
    },
    {
        path: "/regularStatuses",
        exact: true,
        component: RegularStatuses
    },
    {
        path: "/closeTypes",
        exact: true,
        component: CloseTypesController
    },
    {
        path: "/regularWorks",
        exact: true,
        component: RegularWorks
    },
    {
        path: "/workGroups",
        exact: true,
        component: WorkGroups
    },
    {
        path: "/workOrders",
        exact: true,
        component: WorkOrders
    },
    {
        path: "/acts",
        exact: true,
        component: ActsController
    },
    {
        path: "/acts/scans",
        exact: true,
        component: ActsScansController
    },
    {
        path: "/acts/package-upload",
        exact: true,
        component: ActsPackageUploadController
    },
    {
        path: "/acts/mileage",
        exact: true,
        component: ActsMileage
    },
    {
        path: "/acts/waybills",
        exact: true,
        component: Waybills
    },
    {
        path: "/tasks",
        exact: true,
        component: TasksController
    },
    {
        path: "/news",
        exact: true,
        component: NewsController
    },
    {
        path: "/knowledge",
        exact: false,
        component: KnowledgeController
    },
    {
        path: "/ATB-sync",
        exact: true,
        component: OtsyncsController
    },
    {
        path: "/reports/dispatcherWorks",
        exact: true,
        component: DispatcherWorksController
    },
    {
        path: "/reports/executorReconciliation",
        exact: true,
        component: ExecutorsReconciliation
    },
    {
        path: "/reports/actsTimeReport",
        exact: true,
        component: ActsTimeReportController
    },
    {
        path: "/reports/executorCompareHours",
        exact: true,
        component: ExecutorCompareHours
    },
    {
        path: "/reports/actCloseTypes",
        exact: true,
        component: ActCloseTypesController
    },
    {
        path: "/reports/orderExecution",
        exact: true,
        component: OrderExecution
    },
    {
        path: "/reports/orderExecutionByRegions",
        exact: true,
        component: OrderExecutionByRegion
    },
    {
        path: "/reports/actsCheckHistory",
        exact: true,
        component: ActsCheckHistory
    },
    {
        path: "/reports/actsHistory",
        exact: true,
        component: ActsHistory
    },
    {
        path: "/reports/expiredOrders",
        exact: true,
        component: ExpiredOrders
    },
    {
        path: "/reports/distanceReport",
        exact: true,
        component: DistanceReport
    },
    {
        path: "/reports/taskReport",
        exact: true,
        component: TaskReport
    },
    {
        path: "/reports/summaryReport",
        exact: true,
        component: SummaryReport
    },
    {
        path: "/reports/timeDistanceCheck",
        exact: true,
        component: TimeDistanceReport
    },
    {
        path: "/account",
        exact: true,
        component: PageNotFound
    },
    {
        path: "/maps",
        exact: true,
        component: MapsController,
    },
    {
        path: "/calendar",
        exact: true,
        component: Calendar,
    },
    {
        path: "/logs",
        exact: true,
        component: Logs,
    },
    // {
    //     path: "*",
    //     component: PageNotFound
    // },
];
