import {AnyAction} from "redux";
import {Contractor, OrderStatus, Person, Object, UserSettings} from "@justpro/terminal";


export type UserReducer = {
    id : number,
    username : string
    description? : string
    contractors? : Contractor[]
    person : Person
    objects : Object[]
    settings: UserSettings,
    roles : number[]
    parentRoles? :  number[]
    groups : number[]
    newOrderStatus : OrderStatus | null
}

const initialState:UserReducer = {
    id : -1,
    person : {
        id: -1,
        firstName: '',
        lastName: '',
        patronymic: ''
    },
    objects : [],
    roles : [],
    groups : [],
    settings: {
        basePointId: null,
        taskChangeInitiatorNotifications: false,
        workordersNotifications: false,
        taskView: false,
        taskChangeNotifications: false,
        taskChangeWatcherNotifications: false,
        taskResponsibleNotifications: false,
        tasksLastFilters: false,
        ordersComments: false,
        language: null,
        workordersNotificationsHours: 6,
        ordersLastFilters: false,
        reminderPhotoReport: false
    },
    username : '',
    newOrderStatus : null

};

export const userTypes = {
    CREATE_USER: 'CREATE_USER',
    CHANGE_PERSON : 'CHANGE_PERSON',
    CHANGE_CONTRACTOR : 'CHANGE_CONTRACTOR',
    UPDATE_USER : 'UPDATE_USER',

    GET_USER : 'GET_USER',

    ADD_USER_CONTRACTOR : 'ADD_USER_CONTRACTOR',
    REMOVE_USER_CONTRACTOR : 'REMOVE_USER_CONTRACTOR',
};

export default (state:UserReducer = initialState, action: AnyAction) => {
    const {type, ...rest} = action;

    switch (type) {
        case userTypes.CREATE_USER :
        case userTypes.GET_USER :
        case userTypes.UPDATE_USER :
            return {
                ...state,
                ...rest
            };
        case userTypes.ADD_USER_CONTRACTOR :

            console.log({state})

            return {
                ...state,
                ...rest
            };
        case userTypes.REMOVE_USER_CONTRACTOR :
            console.log({state})
            return {
                ...state,
                ...rest
            };
        default :
            return state;
    }
}