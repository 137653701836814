import React, {useState} from 'react';
import {File as IFile,} from '@justpro/terminal'
import withLabel from "../withLabel/withLabel";
import {Wrapper} from "./equipmentPhotos.view";
import Spinner from '../spinner/spinner.controller'
import InlineDropzone from "../dropzone/inline/inlineDropzone.controller";
import FancyBox from "../fancybox_new/fancybox.controller";
import {imagesOnly} from "../dropzone/acceptedTypes";

interface Props {
    photos : IFile[]
    label? : string
    deletePhoto(id:number) : void
    addPhotos(photos:File[]) : void
    isDisabled? : boolean
}

const EquipmentPhotos = (props:Props) => {
    const {photos, label, deletePhoto} = props;
    const [loading, setLoading] = useState(false);

    const deleteHandler = async (e:React.MouseEvent, id:number) => {
        e.stopPropagation();
        setLoading(true);
        await deletePhoto(id);
        setLoading(false)
    };

    const addPhotoHandler = async (files:File[]) => {
        setLoading(true);
        await props.addPhotos(files);
        setLoading(false)
    };


    const jsx = (
        <>
            <Wrapper>
                {photos.length > 0 && (
                    photos.map((photo, index) => {
                        const {id} = photo;
                        return (
                            <div className="photo" key={index}>
                                {/*<img src={photo.path} alt={photo.originalName}/>*/}
                                <FancyBox
                                    file={photo}
                                    imageWrapperCls={' '}
                                    images={photos}
                                />
                                {id && <span onClick={(e:React.MouseEvent) => deleteHandler(e, id)}>x</span>}
                            </div>

                        )
                    })
                )}

                <Spinner loading={loading}/>
            </Wrapper>

            {props.isDisabled !== undefined && !props.isDisabled &&
                <div className={photos.length === 0 ? '' : 'equipment-dropzone'}>
                    <InlineDropzone setFiles={addPhotoHandler} types={imagesOnly} files={props.photos}/>
                </div>
            }
        </>

    );



    return label ? withLabel(jsx)({text : label}) : jsx;
};

export default EquipmentPhotos;
