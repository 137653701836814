import React from "react";
import {
    AddFile,
    File as ServerFile,
    PHOTOS_AFTER,
    PHOTOS_BEFORE
} from '@justpro/terminal'
import checkError from "../../../utils/checkError";
import Photos from "./photos.controller";
import {ImageType} from "../../orders/orderTabs.controller";

interface GetPhotosResponse {
    before? : ServerFile[]
    after? : ServerFile[]
}

interface updateList {
    photos : ServerFile[]
}

interface Props {
    id : number
    getPhotos(moduleId:number) : Promise<GetPhotosResponse>
    deletePhotos(moduleId:number, fileId:number[]) : Promise<boolean>
    addPhotos(moduleId:number, files:AddFile[], type: string) : Promise<ServerFile[]>

    titleBefore : string
    titleAfter : string
    maxSize?: number
    video?: boolean
    updateList?(id:number, photos:updateList) : void
}

interface State {
    before : ServerFile[],
    after : ServerFile[]
}

class BeforeAfterPhotos extends React.Component<Props, State>{

    state: State = {
        before : [],
        after : []
    };

    setImageBefore = async (acceptedFiles:File[]) => {
        const {updateList} = this.props;
        try {
            const files:AddFile[] = acceptedFiles.map(file => ({name : file.name, blob : file}));

            const photos = await this.props.addPhotos(this.props.id, files, PHOTOS_BEFORE);

            updateList && updateList(this.props.id, {photos : [...this.state.before, ...photos]});

            this.setState((prevState) => ({
                before : prevState.before ? [...prevState.before, ...photos] : photos
            }));
        }catch (e) {
            checkError(e)
        }
    };

    setImageAfter = async (acceptedFiles:File[]) => {
        const {updateList} = this.props;
        try {
            const files:AddFile[] = acceptedFiles.map(file => ({name : file.name, blob : file}));

            const photos = await this.props.addPhotos(this.props.id, files, PHOTOS_AFTER);

            updateList && updateList(this.props.id, {photos : [...this.state.after, ...photos]});

            this.setState((prevState) => ({
                after : prevState.after ? [...prevState.after, ...photos] : photos
            }));
        }catch (e) {
            checkError(e)
        }
    };

    unsetImageAfter = async (id:number) => {
        try {
            await this.props.deletePhotos(this.props.id, [id] );
            await this.onDeletePhoto(id, PHOTOS_AFTER);
        }catch (e) {
            checkError(e)
        }
    };


    unsetImageBefore = async (id:number) => {
        try {
            await this.props.deletePhotos(this.props.id, [id] );
            await this.onDeletePhoto(id, PHOTOS_BEFORE);
        }catch (e) {
            checkError(e)
        }
    };

    onDeletePhoto = async (photoId:number, type: ImageType) => {
        const {updateList} = this.props;
        if(type === 'before') {
            await this.setState((prevState) => ({
                before : prevState.before ? prevState.before.filter(item => item.id !== photoId) : []
            }))
        }else if(type === 'after') {
            await this.setState((prevState) => ({
                after : prevState.after ? prevState.after.filter(item => item.id !== photoId) : []
            }))
        }
        updateList && updateList(this.props.id, {photos : [...this.state.before, ...this.state.after]});
    };

    getPhotos = async () => {
        try {
            const {before, after}  = await this.props.getPhotos(this.props.id);

            this.setState(() => ({
                after : after ? after : [],
                before : before ? before : []
            }))
        }catch (e) {
            checkError(e)
        }
    };

    async componentDidMount() {
        this.getPhotos();
    }

    async componentDidUpdate(prevProps:Readonly<Props>) {
        if(prevProps.id !== this.props.id) {
            this.getPhotos()
        }
    }

    get types(){
        const baseTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'];
        if(this.props.video){
            baseTypes.push('video/mp4');
        }
        return baseTypes;
    }

    render() {
        const {before, after} = this.state;
        const {maxSize} = this.props;
        return (
            <>
                <Photos
                    photos={before}
                    setPhoto={this.setImageBefore}
                    withDelete={{
                        unsetPhoto : this.unsetImageBefore
                    }}
                    types={this.types}
                    maxSize={maxSize}
                    hideTitle={true}
                    showCorrectIcons={true}
                    header={this.props.titleBefore}
                />
                <Photos
                    photos={after}
                    setPhoto={this.setImageAfter}
                    withDelete={{
                        unsetPhoto : this.unsetImageAfter
                    }}
                    types={this.types}
                    maxSize={maxSize}
                    hideTitle={true}
                    showCorrectIcons={true}
                    header={this.props.titleAfter}
                />
            </>
        )
    }
}

export default BeforeAfterPhotos