import React from 'react';
import {Props} from './history.types';
import './history.css'
import History from './templates/historyItem'
import getText from "../../../localization/getText";

export default class ModuleHistory extends React.Component<Props>{

    render () {
        const {list} = this.props;

        return list && list.length > 0 ? list.map(history => {
            return <History {...history} key={history.id}/>
        }) : getText('UI.history.notFound')
    }
}