import React from 'react';
import getText from "../../../../localization/getText";

interface Props {
    change(month:number) : void
    active : number[]
}

const numericDates:number[] = [];

for (let i = 1; i < 32; i++) {
    numericDates.push(i);
}

export default class Days extends React.Component<Props> {

    render() {

        return (
            <div>
                <h6>{getText('schedule.daysHeader')}</h6>
                <div className="days">
                    {numericDates.map (id => {
                        return (
                            <div
                                key={id}
                                onClick={(e:React.MouseEvent) => this.props.change(id)}
                                className={this.props.active.includes(id) ? 'checked cell' : 'cell' }
                            >
                                {id}
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}