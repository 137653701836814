import React from "react";
import RenderIf from "../../../utils/renderIf";
import PositionDetail from "./position.detail";
import {editPosition, getPosition, Position} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";

interface Props {
    id : number,
    afterUpdate?(position:Position) : void
}

interface State {
    position? : Position
    loading : boolean
}

class PositionController extends React.Component<Props>{

    state:State = {
        loading : false
    };


    getPosition = async () => {

        try {
            this.setState(() => ({loading : true}));
            const position = await getPosition(this.props.id);

            this.setState(() => ({loading : false, position}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    updatePosition = async (id:number, data:Partial<Position>) => {
        const {afterUpdate} = this.props;

        try {
            this.setState(() => ({loading : true}));

            const position = await editPosition(id, data);
            afterUpdate && await afterUpdate(position);

            this.setState(() => ({position, loading : false}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getPosition()
        }
    }


    async componentDidMount() {
        this.getPosition()
    }

    render() {
        const {position} = this.state;
        return (
            <>
                <RenderIf condition={position !== undefined}>
                    <PositionDetail position={position as Position} update={this.updatePosition}/>
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }
}

export default PositionController