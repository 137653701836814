import React from 'react';
import { Column } from 'react-table';
import WithPrivateRoute, {
  reports,
} from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import RenderIf from '../../../utils/renderIf';
import Spinner from '../../UI/spinner/spinner.controller';
import {
  format,
  getTasksReport,
  getTasksReportExport,
} from '@justpro/terminal';
import Table from '../../UI/table/table';
import getText from '../../../localization/getText';
import Filter from '../../UI/filter/filter.controller';
import CheckboxList from '../../UI/checkboxList/checkboxList.contoller';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import Button from '../../UI/button/button';
import FileDownload from 'js-file-download';
import checkError from '../../../utils/checkError';
import moment, { Moment } from 'moment';
import { getDepartmentsList, getPersonsList } from '../../../utils/functions';
import { filterNames } from '../../UI/checkboxList/checkboxes.const';
import { connect } from 'react-redux';
import './taskReport.css';
import {
  ActiveCheckboxesList,
  CheckboxesMap,
} from '../../UI/checkboxList/checkboxList.types';

const HTML_REPLACE_REG_EXP = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;

interface IProps {
  settings: any;
}

interface IState {
  loading: boolean;
  data: any;
  period: number;
  checkboxesMap: CheckboxesMap;
  activeCheckboxes: ActiveCheckboxesList;
  generatingExcel: boolean;
}

export class TaskReport extends React.Component<IProps, IState> {
  state: IState = {
    loading: false,
    data: [],
    period: 7,
    checkboxesMap: {},
    activeCheckboxes: {},
    generatingExcel: false,
  };

  generateExcel = async () => {
    try {
      this.setState({
        generatingExcel: true,
      });
      const blob = await getTasksReportExport({
        responsiblesId: this.state.activeCheckboxes?.PERSONS_FILTER as number[],
        departmentsId: this.state.activeCheckboxes?.DEPARTMENTS as number[],
        period: this.state.period,
      });

      FileDownload(
        blob,
        `отчет-по-задачам_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        generatingExcel: false,
      });
    }
  };

  async componentDidMount() {
    try {
      const departmentsId = await getDepartmentsList();
      const responsiblesId = await getPersonsList('', [
        +this.props.settings?.tfmContractorId,
      ]);

      const checkboxesMap = {
        [filterNames.DEPARTMENTS]: {
          name: 'reports.taskReport.departments',
          children: departmentsId,
        },
        [filterNames.PERSONS]: {
          name: 'reports.taskReport.employees',
          children: responsiblesId,
        },
      };

      await this.fetchData();

      this.setState({
        checkboxesMap,
      });
    } catch (err) {
      checkError(err);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchData() {
    try {
      this.setState({
        loading: true,
      });

      const filters = {
        responsiblesId: this.state.activeCheckboxes?.PERSONS_FILTER as number[],
        departmentsId: this.state.activeCheckboxes?.DEPARTMENTS as number[],
        period: this.state.period,
      };
      const data = await getTasksReport(filters);
      this.setState({
        data,
      });
    } catch (err) {
      checkError(err);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  onCheckboxesChange = (activeCheckboxes) => {
    this.setState({
      activeCheckboxes,
    });
  };

  convertHtmlToText = (html: string) => {
    return html.replace(HTML_REPLACE_REG_EXP, '');
  };

  stringReducer = (operatedString, neededLength) => {
    const reducedString = this.convertHtmlToText(operatedString);
    if (reducedString.length > neededLength) {
      return reducedString.substr(0, neededLength) + '...';
    }
    return reducedString;
  };

  get columns() {
    const columns: Column[] = [
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.department')}
          </div>
        ),
        accessor: 'department',
        width: 200,
        Cell: (props) => (
          <div className="taskReport_cell">{props.original.department}</div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.employee')}
          </div>
        ),
        accessor: 'responsible',
        width: 250,
        Cell: (props) => (
          <div className="taskReport_cell">{props.original.responsible}</div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.task')}
          </div>
        ),
        accessor: 'number',
        width: 150,
        Cell: (props) => (
          <div className="taskReport_cell">№{props.original.number}</div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.date')}
          </div>
        ),
        accessor: 'createdAt',
        width: 150,
        Cell: (props) => (
          <div className="taskReport_cell">
            {moment(props.original.createdAt).format(`YYYY-MM-DD`)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.name')}
          </div>
        ),
        accessor: 'title',
        width: 200,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original.content &&
              this.stringReducer(props.original.title, 200)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.content')}
          </div>
        ),
        accessor: 'content',
        width: 300,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original.content &&
              this.stringReducer(props.original.content, 200)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.lastComment')}
          </div>
        ),
        accessor: 'comment',
        width: 200,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original?.comment?.message &&
              this.stringReducer(props.original?.comment?.message, 200)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.lastCommentDate')}
          </div>
        ),
        accessor: 'commentedAt',
        width: 200,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original.comment?.createdAt &&
              moment(props.original.comment?.createdAt).format(`YYYY-MM-DD`)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.lastCommentAuthor')}
          </div>
        ),
        accessor: 'commentAuthor',
        width: 250,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original.comment?.author}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.time')}
          </div>
        ),
        accessor: 'createdAt',
        width: 100,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original.comment?.period}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.periodUpdated')}
          </div>
        ),
        accessor: 'periodUpdated',
        width: 100,
        Cell: (props) => (
          <div className="taskReport_cell">{props.original?.periodUpdated}</div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.lastUpdatedDate')}
          </div>
        ),
        accessor: 'updatedAt',
        width: 200,
        Cell: (props) => (
          <div className="taskReport_cell">
            {props.original?.updatedAt &&
              moment(props.original?.updatedAt).format(`YYYY-MM-DD`)}
          </div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.creator')}
          </div>
        ),
        accessor: 'initiator',
        width: 250,
        Cell: (props) => (
          <div className="taskReport_cell">{props.original.initiator}</div>
        ),
      },
      {
        Header: (
          <div className="taskReport_headerCell">
            {getText('reports.taskReport.condition')}
          </div>
        ),
        accessor: 'taskState',
        width: 150,
        Cell: (props) => (
          <div className="taskReport_cell taskReport_cell__last">
            {props.original.taskState}
          </div>
        ),
      },
    ];
    return columns;
  }

  changePeriod = (e) => {
    const period = e.target.value.replace(/[^0-9]/gi, '');
    this.setState({
      period,
    });
  };

  render() {
    const { loading, checkboxesMap, activeCheckboxes } = this.state;
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>
            {getText('reports.taskReport.moduleName')}
          </ModuleTextName>
          <Filter
            activeCheckboxes={activeCheckboxes}
            send={this.fetchData.bind(this)}
          >
            <CheckboxList
              checkboxesMap={checkboxesMap}
              activeCheckboxes={activeCheckboxes}
              send={this.onCheckboxesChange}
            />
            <div className="just-pro__checkbox-children">
              <div className="taskReport-numberInput_container">
                <b>{getText('reports.taskReport.time')}</b>
                <input
                  className="just-pro-textInput"
                  value={this.state.period}
                  onChange={this.changePeriod}
                />
              </div>
            </div>
          </Filter>
          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="Экспортировать в эксель">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                {getText('common.generating')}
              </RenderIf>
            </Tooltip>
          </div>
        </HeaderController>

        <div className="just-pro_module report-wrapper">
          <div className="panel content-panel">
            <div className="report-table">
              <RenderIf condition={!this.state.loading}>
                <Table
                  sortable={false}
                  columns={this.columns}
                  data={this.state.data}
                  wrapperClassName="report_flex-table"
                ></Table>
              </RenderIf>
            </div>
            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.application.settings,
});

export default connect(mapStateToProps)(TaskReport);
