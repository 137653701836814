import React from 'react';
import { Column } from 'react-table';
import { getStatuses, OrderStatus } from '@justpro/terminal';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import getText from '../../../localization/getText';
import Spinner from '../../UI/spinner/spinner.controller';
import Table from '../../UI/table/table';
import checkError from '../../../utils/checkError';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import moment, { Moment } from 'moment';
import { format, getOrdersExecution, OrdersExecution } from '@justpro/terminal';
import './orderExecution.css';
import RenderIf from '../../../utils/renderIf';
import CheckboxSelectController from '../../UI/checkboxSelect/checkboxSelect.controller';

type orderType = {
  id: number;
  name: string;
  color: string;
  priority: number;
  statuses: Object;
};
type region = {
  id: number;
  name: string;
  orderTypes: orderType[];
};
type normalizedDataElement = {
  contractor: { id: number; name: string; pseudoname: string };
  regions?: region[];
  orderTypes?: orderType[];
};

interface Props {
  byRegions?: boolean;
}
interface State {
  loading: boolean;
  preparedData: normalizedDataElement[];
  from: Moment;
  to: Moment;
}

class OrderExecution extends React.Component<Props, State> {
  state = {
    loading: false,
    preparedData: [],
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
  };
  statuses: OrderStatus[];

  async componentDidMount() {
    try {
      this.setState({
        loading: true,
      });
      this.statuses = await getStatuses();
      await this.getData();
    } catch (err) {
      checkError(err);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  onChangeFrom = (from: Moment) => {
    this.setState(() => ({ from }));
  };

  onChangeTo = (to: Moment) => {
    this.setState(() => ({ to }));
  };

  getData = async () => {
    try {
      this.setState({
        loading: true,
      });

      const filters = {
        createdAtFrom: this.state.from.format(format.date),
        createdAtTo: this.state.to.format(format.date),
      };
      const data = await getOrdersExecution(filters);
      const preparedData = this.props.byRegions
        ? this.dataConverterRegions(data)
        : this.dataConverter(data);
      this.setState({
        preparedData,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  defineContractor = (generalData, currentElement) =>
    generalData.contractor.id === currentElement.contractor.id;
  isType = (normalizedData, currentElement) =>
    normalizedData?.some((savedData) =>
      savedData.orderTypes.some(
        (type) => type.id === currentElement.ordersType.id
      )
    );
  isRegion = (regions, currentElement) =>
    regions?.some((region) => region.id === currentElement.region?.id);
  defineStatuses = (currentElement) =>
    Object.fromEntries(
      currentElement.statuses.map((status) => Object.values(status))
    );
  isStatus = (normalizedData, currentElement) =>
    normalizedData
      ?.find(
        (savedData) => savedData.contractor.id === currentElement.contractor.id
      )
      ?.orderTypes.find((type) => type.id === currentElement.ordersType.id)
      ?.statuses;

  dataConverter = (
    originalData: OrdersExecution[]
  ): normalizedDataElement[] => {
    const normalizedData = [];

    originalData.map((currentElement) => {
      const isContractor = normalizedData.some((savedData) =>
        this.defineContractor(savedData, currentElement)
      );
      const isType = this.isType(normalizedData, currentElement);
      const statuses = this.defineStatuses(currentElement);

      if (isContractor && isType) {
        const currentStatus = this.isStatus(normalizedData, currentElement);
        currentElement.statuses.forEach((status) => {
          if (status && currentStatus) {
            currentStatus[status.id] += status.count;
          }
        });
      }
      if (isContractor && !isType) {
        normalizedData
          .find((savedData) => this.defineContractor(savedData, currentElement))
          .orderTypes.push({ ...currentElement.ordersType, statuses });
      }
      if (!isContractor) {
        normalizedData.push({
          contractor: currentElement.contractor,
          orderTypes: [{ ...currentElement.ordersType, statuses }],
        });
      }
    });

    normalizedData.map((type) => {
      type.orderTypes.push({ id: 0, name: 'Всего' });
    });
    return normalizedData;
  };

  dataConverterRegions = (
    originalData: OrdersExecution[]
  ): normalizedDataElement[] => {
    const normalizedData = [];

    originalData.map((currentElement) => {
      const isContractor = normalizedData.some((savedData) =>
        this.defineContractor(savedData, currentElement)
      );
      const definedContractor = normalizedData.find((savedData) =>
        this.defineContractor(savedData, currentElement)
      );
      const isRegion = this.isRegion(
        definedContractor?.regions,
        currentElement
      );
      const statuses = this.defineStatuses(currentElement);

      if (isContractor && isRegion) {
        definedContractor?.regions
          ?.find((region) => region.id === currentElement.region.id)
          ?.orderTypes?.push({ ...currentElement.ordersType, statuses });
      }
      if (isContractor && !isRegion) {
        definedContractor?.regions?.push({
          ...currentElement.region,
          orderTypes: [{ ...currentElement.ordersType, statuses }],
        });
      }
      if (!isContractor) {
        normalizedData.push({
          contractor: currentElement.contractor,
          regions: [
            {
              ...currentElement.region,
              orderTypes: [{ ...currentElement.ordersType, statuses }],
            },
          ],
        });
      }
    });

    normalizedData.map((type) => {
      type.regions.map((region) => {
        region.orderTypes.push({ id: 0, name: 'Всего' });
      });
    });
    return normalizedData;
  };

  totalCounter = (x: any, y: any): number => +x + +y;

  get columns() {
    const columnByRegions = {
      Header: 'Регионы',
      accessor: 'regions',
      width: 150,
      Cell: (props) => (
        <>
          {props.original?.regions?.map((region) => {
            return (
              <div
                key={region.id}
                className="orderExecution__region"
                style={{ height: region?.orderTypes.length * 30 + 'px' }}
              >
                {region?.name}
              </div>
            );
          })}
        </>
      ),
    };
    const columns: Column[] = [
      {
        Header: 'Контрагент',
        accessor: 'contractor',
        width: 150,
        Cell: (props) => <span>{props.original.contractor.name}</span>,
      },
      {
        Header: 'Тип',
        accessor: 'type',
        width: 200,
        Cell: (props) => (
          <>
            <RenderIf condition={this.props.byRegions}>
              {props.original?.regions?.map((region) => {
                return region.orderTypes?.map((type) => {
                  return (
                    <div
                      key={type.id}
                      className="orderExecution__cell"
                      style={{ backgroundColor: type.color }}
                    >
                      {type.name}
                    </div>
                  );
                });
              })}
            </RenderIf>
            <RenderIf condition={!this.props.byRegions}>
              {props.original?.orderTypes?.map((type) => (
                <div
                  key={type.id}
                  className="orderExecution__cell"
                  style={{ backgroundColor: type.color }}
                >
                  {type.name}
                </div>
              ))}
            </RenderIf>
          </>
        ),
      },
      {
        Header: 'Всего',
        accessor: 'total',
        width: 150,
        Cell: (props) => {
          let total: any = 0;
          return (
            <>
              <RenderIf condition={this.props.byRegions}>
                {props.original.regions?.map((regions) => {
                  let totalByRegions: any = 0;
                  return regions.orderTypes.map((type) => {
                    if (type.statuses) {
                      totalByRegions += Object.values(type.statuses).reduce(
                        this.totalCounter
                      );
                      return (
                        <div
                          key={type.id}
                          className="orderExecution__cell"
                          style={{ backgroundColor: type.color }}
                        >
                          {' '}
                          {Object.values(type.statuses).reduce(
                            this.totalCounter
                          )}{' '}
                        </div>
                      );
                    }
                    return (
                      <div key={type.id} className="orderExecution__cell">
                        {' '}
                        {totalByRegions}{' '}
                      </div>
                    );
                  });
                })}
              </RenderIf>
              <RenderIf condition={!this.props.byRegions}>
                {props.original.orderTypes?.map((type) => {
                  if (type.statuses) {
                    total += Object.values(type.statuses).reduce(
                      this.totalCounter
                    );
                    return (
                      <div
                        key={type.id}
                        className="orderExecution__cell"
                        style={{ backgroundColor: type.color }}
                      >
                        {' '}
                        {Object.values(type.statuses).reduce(
                          this.totalCounter
                        )}{' '}
                      </div>
                    );
                  }
                  return (
                    <div key={type.id} className="orderExecution__cell">
                      {' '}
                      {total}{' '}
                    </div>
                  );
                })}
              </RenderIf>
            </>
          );
        },
      },
    ];
    if (this.props.byRegions) {
      columns.splice(1, 0, columnByRegions);
    }

    this.statuses &&
      this.statuses.map((status) => {
        if (!status.active) return false;
        columns.push({
          Header: status.name,
          width: 150,
          Cell: (props) => (
            <>
              <RenderIf condition={this.props.byRegions}>
                {props.original?.regions?.map((region) => {
                  return region.orderTypes?.map((type) => {
                    if (type.statuses) {
                      return (
                        <div
                          key={type.id}
                          className="orderExecution__cell"
                          style={{ backgroundColor: type.color }}
                        >
                          {type.statuses[status.id]}
                        </div>
                      );
                    }
                    let res = 0;
                    region?.orderTypes?.map((type) => {
                      res += type.statuses?.[status.id] || 0;
                    });
                    return (
                      <div key={type.id} className="orderExecution__cell">
                        {res}
                      </div>
                    );
                  });
                })}
              </RenderIf>
              <RenderIf condition={!this.props.byRegions}>
                {props.original?.orderTypes?.map((type) => {
                  if (type.statuses) {
                    return (
                      <div
                        key={type.id}
                        className="orderExecution__cell"
                        style={{ backgroundColor: type.color }}
                      >
                        {type.statuses[status.id]}
                      </div>
                    );
                  }
                  let res = 0;
                  props.original?.orderTypes.map((type) => {
                    res += type.statuses?.[status.id] || 0;
                  });
                  return (
                    <div key={type.id} className="orderExecution__cell">
                      {res}
                    </div>
                  );
                })}
              </RenderIf>
            </>
          ),
        });
      });
    return columns;
  }

  render() {
    const { preparedData } = this.state;
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>
            {this.props.byRegions
              ? getText('reports.orderExecution.byRegions')
              : getText('reports.orderExecution.moduleName')}
          </ModuleTextName>
          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.onChangeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.onChangeTo} />
          </ToplineCalendarWrapper>
          <button
            onClick={this.getData}
            type="button"
            className="btn just-pro-button btn-success orderExecution_button"
          >
            Применить фильтр
          </button>
        </HeaderController>

        <div className="just-pro_module report-wrapper">
          <div className="panel content-panel">
            <div className="report-table">
              <RenderIf condition={!this.state.loading}>
                <Table
                  columns={this.columns}
                  data={preparedData}
                  wrapperClassName="report_flex-table"
                  getTrProps={() => ({
                    style: {
                      background: 'white',
                      borderTop: '1px solid lightgrey',
                    },
                  })}
                ></Table>
              </RenderIf>
            </div>
            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

export default OrderExecution;
