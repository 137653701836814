import React from "react";
import './uploadProgressBox.css'
import {UploadProcessingMessage} from "./actsPackageUpload.controller";

interface Props {
    messages : UploadProcessingMessage[]
}

const UploadProgressBoxController = (props:Props) => {
    return (
        <div className="uploadProgressBox">
            {props?.messages?.map(item => {

                const cls = ['uploadProcessingBox'];

                cls.push(item.type);

                return (
                    <div className={cls.join(' ')}>
                        {item.message}
                    </div>
                )
            })}
        </div>
    )
};

export default UploadProgressBoxController