import React from 'react';

import {CloseType, createCloseType, getCloseTypes, ModulesResponse} from "@justpro/terminal";
import CloseTypeCreate from "./closeType.add";
import CloseTypeController from './closeType.controller'
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";


interface Props {
    rights?: Partial<ModulesResponse>
}

class CloseTypesController extends React.Component<Props> {

    renderItem = (item:CloseType) => <>{item.name}</>;

    render () {
        const {rights} = this.props;
        return <BaseReferenceController
            Detail={CloseTypeController}
            create={{
                Component : CloseTypeCreate,
                title : 'closeTypes.addItem',
                handler : createCloseType
            }}
            referenceName="closeTypes.moduleName"
            listItem={{
                render : this.renderItem,
            }}
            getList={getCloseTypes}
            moduleRights={rights && rights['references.closeTypes']}
        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(CloseTypesController)
