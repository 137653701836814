import React, {Component} from "react";
import {
    Comment, Person, addTaskComment, addOrderComment,
    getNotifications, getNotificationComments, readAllNotifications,
    readAllNotificationsComments, Notification, NotificationComment,
    FilesNotification, getFilesNotifications, readFilesNotifications
} from "@justpro/terminal";
import moment from "moment";
import "./headerNotifications.css";
import Tabs from "../UI/tabs/tabs.controller";
import getFullName from "../../utils/getFullName";
import {getHistoryBody} from "../UI/moduleHistory/templates/historyItem";
import TaskController from "../tasks/task.controller";
import RenderIf from "../../utils/renderIf";
import File from "../UI/fileManager/file.preview";
import Button from "../UI/button/button";
import AddComment from "./addComment.controller";
import checkError from "../../utils/checkError";
import Spinner from "../UI/spinner/spinner.controller";
import OrderDetail from "../orders/detail/order.detail";
import {openModal} from "../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal} from '../../store/modal/modal.types'
import getText from "../../localization/getText";
import {getUserMe, getNotificationsNew} from "../../store/application/application.actions";
import {NotificationsList} from "./notificationsList.controller";
import {CommentsList} from "./commentsList.controller";
import {FilesList} from "./filesList.controller";
import { downloadFile } from 'fs-browsers';

interface Props {
    openModal(props: Modal): void

    getUserMe?(): any
    getNotificationsNew?(): any
    comments,
    notifications,
    files
}

type HeaderNotificationsState = {
    isOpen: boolean
    notifications: Notification[]
    comments: NotificationComment[]
    files: FilesNotification[]
    loading: boolean
    replyPerson?: Person
    replyTaskId?: number
    replyOrderId?: number
}

class HeaderNotifications extends Component<Props, HeaderNotificationsState> {
    isTabActive: boolean = true;
    lastActiveTime: number = Date.now();

    state = {
        isOpen: false,
        loading: false,
        notifications: [],
        comments: [],
        files: [],
        replyPerson: void 0,
        replyTaskId: void 0,
        replyOrderId: void 0
    };

    autoCheckUpdates?: any;

    async componentDidMount() {
        await this.checkNew();
        this.autoCheckUpdates = setInterval(this.checkNew, 60 * 1000) // 1 min;
    }

    checkNew = async () => {
        try {
            await this.props?.getNotificationsNew?.();
            await this.props?.getUserMe?.();
        } catch (err) {
            // checkError(err);
        }
    };

    componentWillUnmount() {
        clearInterval(this.autoCheckUpdates);
    }

    updateData = async () => {
        try {
            this.setState({
                loading: true
            });
            const notifications = await getNotifications({offset: 0});
            const comments = await getNotificationComments({offset: 0});
            const files = await getFilesNotifications();
            await this.checkNew();
            this.setState({
                notifications,
                comments,
                files
            })
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.isOpen && !prevState.isOpen) {
            return this.updateData();
        }
    }

    get tabs() {
        const {notifications, comments, files} = this.state;
        return [{
            title: (
                <div className="notifications__inner-tab">
                    <RenderIf condition={notifications?.length}>
                        <i className="fa fa-circle notifications__tabs-circle" onClick={this.readAllNotifications}/>
                    </RenderIf>
                    {getText('UI.tabs.notifications')}
                </div>
            ),
            component: () => <NotificationsList notifications={notifications} onClick={this.openTask}/>,
            onSelectTab: () => {
            }
        }, {
            title: (
                <div className="notifications__inner-tab">
                    <RenderIf condition={comments?.length}>
                        <i className="fa fa-circle notifications__tabs-circle"
                           onClick={this.readAllNotificationComments}/>
                    </RenderIf>
                    {getText('UI.tabs.comments')}
                </div>
            ),
            component: () => <CommentsList comments={comments} onClick={this.openDetail} onReply={this.openReply}/>,
            onSelectTab: () => {
            }
        }, {
            title: (
                <div className="notifications__inner-tab">
                    <RenderIf condition={files?.filter((f) => !f.read)?.length}>
                        <i className="fa fa-circle notifications__tabs-circle"
                           onClick={this.readAllFilesNotifications}/>
                    </RenderIf>
                    {getText('UI.tabs.files')}
                </div>
            ),
            component: () => <FilesList files={files} onRead={this.readFilesNotification} onClick={this.downloadFile}/>,
            onSelectTab: () => {
            }
        }]
    }

    openTask = (notification: any) => {

        const taskId = notification?.task?.id || notification?.taskId;
        this.props.openModal({
            id: 'notification_task',
            component: () => (
                <TaskController id={taskId}/>
            ),
            title: `Задача №${taskId}`,
        });
    };

    openDetail = (comment: any) => {

        if (comment.taskId) {
            this.openTask(comment);
        }

        if (comment.orderId) {
            this.props.openModal({
                id: 'notification_order',
                title: getText('orders.order'),
                component: () => (<OrderDetail id={comment.orderId}/>)
            })
        }

    };

    openReply = (comment: any) => {
        this.setState({
            replyPerson: comment?.author?.person,
            replyTaskId: comment?.taskId,
            replyOrderId: comment?.orderId
        })
    };

    toggleNotifications = () => {
        this.setState((prevState: any) => ({
            isOpen: !prevState.isOpen
        }));
    };


    cancelAddComment = () => {
        this.setState({
            replyPerson: void 0,
            replyOrderId: void 0,
            replyTaskId: void 0
        })
    };

    onAddComment = async (message: string, files: any) => {
        try {
            const {replyPerson, replyTaskId, replyOrderId}: any = this.state;
            this.setState({
                loading: true
            });
            if (replyTaskId) {
                await addTaskComment(replyTaskId, {
                    message,
                    files,
                    personsId: replyPerson ? [replyPerson?.id] : []
                })
            }
            if (replyOrderId) {
                await addOrderComment(replyOrderId, {
                    message,
                    files
                });
            }
            this.cancelAddComment();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    readAllNotifications = async (event: any) => {
        event.stopPropagation();
        try {
            this.setState({
                loading: true
            });
            await readAllNotifications(this.state.notifications?.map((notification: Notification) => {
                return notification?.task?.id
            }));
            await this.updateData();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    readAllNotificationComments = async (event: any) => {
        event.stopPropagation();
        try {
            this.setState({
                loading: true
            });
            const commentsId: any = this.state.comments?.map((comment: NotificationComment) => {
                return comment?.orderId && comment?.id;
            }).filter((oid) => oid);
            const tasksId: any = this.state.comments?.map((comment: NotificationComment) => {
                return comment?.taskId;
            }).filter((tid) => tid);
            await readAllNotificationsComments(commentsId, tasksId);
            await this.updateData();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    downloadFile = (file) => {
        downloadFile(file.path, file.name);
    }

    readFilesNotification = async (filesNotification: FilesNotification, event) =>{
        try{
            this.setState({
                loading: true
            });
            await readFilesNotifications([filesNotification.id]);
            await this.updateData();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    readAllFilesNotifications = async (event) =>{
        event.stopPropagation();
        try {
            this.setState({
                loading: true
            });
            await readFilesNotifications(this.state.files?.map((filesNotification: FilesNotification) => {
                return filesNotification.id
            }));
            await this.updateData();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const {isOpen, replyTaskId, replyOrderId, replyPerson, loading, }: any = this.state;
        const {comments, notifications, files} = this.props;
        return (
            <>
                <div className="notifications navbar-form navbar-right">
                    <div onClick={this.toggleNotifications}>
                        <i className="fas fa-bell"/>
                        <RenderIf condition={comments || notifications || files}>
                            <i className="fa fa-circle notifications__new-circle"/>
                        </RenderIf>
                    </div>
                </div>
                <div className={`notifications__wrapper ${isOpen ? 'notifications__wrapper-open' : ''}`}>
                    <Spinner loading={loading}/>
                    <Tabs tabs={this.tabs}/>
                    <RenderIf condition={replyTaskId || replyOrderId}>
                        <div className={`notifications-reply__wrapper`}>
                            <AddComment addComment={this.onAddComment}
                                        replyPerson={replyPerson}
                                        onCancel={this.cancelAddComment}
                            />
                        </div>
                    </RenderIf>
                </div>
            </>
        )
    }
}

const mapState = (state) => ({
    notifications: state.application.notifications,
    comments: state.application.comments,
    files: state.application.files,
})

const mapDispatchToProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props)),
    getUserMe: () => dispatch(getUserMe()),
    getNotificationsNew: () => dispatch(getNotificationsNew())
});

export default connect(mapState, mapDispatchToProps)(HeaderNotifications)
