import styled from "styled-components";

export const CreateWorkOrder = styled.div`
    display : flex;
    flex-direction : column;
    position: relative;
    
    
    
    & .header { 
        
    }

`;
