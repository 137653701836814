import {initialState, RegularWorksReducer} from './regularWorks.types'
import {AnyAction} from "redux";

export const regularWorksActions = {
    GET_REGULAR_WORKS : 'GET_REGULAR_WORKS',
    UPDATE_REGULAR_WORK : 'UPDATE_REGULAR_WORK',
};

export default (state:RegularWorksReducer = initialState, action:AnyAction) => {
    const {type, ...rest} = action;

    switch (type) {
        case regularWorksActions.GET_REGULAR_WORKS :
            return {...state, ...rest}
    }

    return state;
}