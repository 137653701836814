import React from 'react';
import {fromClassName, getIconsList, toClassName} from "../../../utils/icons";
import Input from "../../UI/input/text";
import IconPicker from "../../UI/iconPiker/iconPicker.controller";
import InputColor from "../../UI/input/color";
import {ModulesResponse, RegularStatus} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

const icons = getIconsList();

interface Props {
    regularStatus : RegularStatus
    update(data:Partial<RegularStatus>) : void

    rights?: Partial<ModulesResponse>
}

class RegularStatusDetail extends React.Component<Props>{

    changeIcon = (iconClassName:string) => {
        const [prefix, iconName] = fromClassName(iconClassName);
        const icon = `${prefix}:${iconName}`;

        this.props.update({icon})
    };

    changeName = (name:string) => {
        this.props.update({name})
    };

    changeActive = (active:boolean) => {
        this.props.update({active})
    };

    changeColor = (color:string) => {
        this.props.update({color})
    };


    render () {
        const {regularStatus, rights} = this.props;
        const {icon, color, name, active} = regularStatus;
        const iconCls = toClassName(icon);

        const disabled = rights && rights['references.regularStatuses'] && !rights['references.regularStatuses']['edit'];
        return (
            <>
                <h4>{getText('regularStatuses.regularStatus')}: {name}</h4>

                <ToggleSwitch label="common.active" send={this.changeActive} defaultValue={active} />

                <Input label="regularStatuses.regularStatus" blur={this.changeName} startValue={name}/>

                <IconPicker
                    list={icons}
                    change={this.changeIcon}
                    label="common.icon"
                    defaultValue={iconCls}
                />

                <InputColor label="common.color" blur={this.changeColor} defaultValue={color}/>
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(RegularStatusDetail)