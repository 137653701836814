import React, {Component} from "react";
import Input from "../UI/input/text";
import checkError from "../../utils/checkError";
import {waitTask} from "@justpro/terminal";
import TaskListItem from "./task.listItem";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";
import Button from "../UI/button/button";
import getText from "../../localization/getText";
import moment, {Moment} from "moment";
import InlineCalendar from "../UI/calendar/views/inline.controller";
import Spinner from "../UI/spinner/spinner.controller";
import {ModalBodyProps} from "../../store/modal/modal.types";

interface IProps  extends ModalBodyProps{
    updateAllList(): any
    taskId: number
    hide() : void
}

interface IState {
    endDate: Moment
    loading: boolean
}

export default class TaskWaitModal extends Component<IProps, IState> {
    state = {
        endDate: moment().add(7, "d"),
        loading: false
    }

    changeEndDate = (endDate) => {
        this.setState({
            endDate
        })
    }

    waitTask = async () => {
        try{
            const {endDate} = this.state;
            this.setState({
                loading: true
            });
            await waitTask(this.props.taskId, endDate.format("YYYY-MM-DD HH:mm"));
            await this.props.updateAllList();
            this.props.hide();
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }
    render() {
        const {endDate, loading} = this.state;
        return (
            <div style={{
                minHeight: '350px'
            }}>
                <Spinner loading={loading} />
                <InlineCalendar date={moment(endDate)}
                                onChange={this.changeEndDate}
                                label={{text: "common.endDate"}}
                                timePicker
                />
                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button onClick={this.waitTask}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </div>
        )
    }
}