import React from "react";
import {addContractEqc, deleteContractEqc, ContractEquipmentCategory, EquipmentCategory, Rules} from "@justpro/terminal";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Select from "../../UI/select/select";
import checkError from "../../../utils/checkError";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {getEquipmentCategoriesList} from "../../../utils/functions";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";

interface Props {
    contractId: number
    equipmentCategories? : ContractEquipmentCategory[]
    rights?: Rules

}

interface State {
    equipmentCategories? : EquipmentCategory[]
}

class ContractEquipmentCategories extends React.Component<Props, State>{
    state:State = {};

    deleteEquipmentCategory = async (item:any) => {
        const {id} = item.original;
        const {equipmentCategories} = this.state;

        try{
            if(equipmentCategories && equipmentCategories.length > 1) {
                const deleted = deleteContractEqc(this.props.contractId, [id]);

                if(deleted) {
                    this.setState((prevState) => ({
                        equipmentCategories : prevState.equipmentCategories?.filter(item => item.id !== id)
                    }))
                }

            }else{
                checkError(new Error('errors.cannotDeleteLastCategoryFromContract'))
            }
        }catch (e) {
            checkError(e);
        }
    };


    getUniqueEquipmentCategories = ():LoadReturn => {
        return new Promise( async resolve => {
            const {equipmentCategories: eqC} = this.props;
            const equipmentCategories = await getEquipmentCategoriesList();
            if(eqC?.length) {
                return resolve(equipmentCategories.filter((item) => {
                    return !eqC.find((eqc) => eqc.equipmentCategoryId === item.id);
                }))
            }
            resolve(equipmentCategories);
        })
    };


    addEquipmentCategory = async (option:Option) => {
        try {
            const equipmentCategories = await addContractEqc(this.props.contractId, [option.value]);

            this.setState((prevState) => ({
                equipmentCategories : prevState.equipmentCategories ?
                    [...prevState.equipmentCategories, ...equipmentCategories]
                    : equipmentCategories
            }))

        }catch (e) {
            checkError(e)
        }

        return Promise.resolve();
    };

    resetState = () => {
        this.setState(() => ({
            equipmentCategories : this.props.equipmentCategories
        }))
    };

    componentDidMount(): void {
        this.resetState()
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(this.props.contractId !== prevProps.contractId) {
            this.resetState()
        }
    }


    get columns() {

        const {rights} = this.props;

        const result:any[] = [
            {
                Header : getText('common.name'),
                accessor : 'name'
            }
        ];

        if(rights && rights.edit) {
            result.push({
                Header: getText('common.delete'),
                Cell: (item:any) => <DeleteCell deleteHandler={this.deleteEquipmentCategory} item={item}/>
            })
        }


        return result
    }

    render () {
        const {equipmentCategories} = this.state;
        const {rights} = this.props;

        return (
            <>
                {withLabel(
                    <>
                        {equipmentCategories && equipmentCategories.length > 0 &&
                            <Table
                                columns={this.columns}
                                data={equipmentCategories}
                                showPagination={false}
                                minRows={0}
                            />
                        }
                        {rights && rights.edit &&
                            <Select
                                change={this.addEquipmentCategory}
                                load={this.getUniqueEquipmentCategories}
                                // defaultValue={{}}
                                className="margin-top"
                                placeholder="UI.select.placeholders.selectCategory"
                                withoutValue={true}
                                accessors={{
                                    name : 'label',
                                    id : 'value'
                                }}
                            />
                        }
                    </>
                )({
                    text : 'equipmentCategories.equipmentCategory'
                })}
            </>
        )
    }
}

export default ContractEquipmentCategories;