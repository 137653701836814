import React from 'react';
import './statusFilter.css'
import getText from "../../../localization/getText";
import GetText from "../../../localization/getText";

export interface ListItem {
    name : string
    id : number
    [key: string] : any
}


interface CheckedListItem extends ListItem{
    name : string
    id : number
    checked : boolean

    [key: string] : any
}


export interface ModuleGroupsProps {
    onChange(checked:number[]) :void
    list : any[],
    title : string
    checked : number[]
    defaultIsOpenAll? : boolean
}

interface State {
    list : CheckedListItem[],
    isOpenAll : boolean
    showingList : CheckedListItem[]
}

export default class StatusFilter extends React.Component<ModuleGroupsProps, State> {
    state:State = {
        list : [],
        showingList : [],
        isOpenAll : false,
    };

    onChangeGroup = async (id:number) => {
        const {checked} = this.props;

        const includes = this.props.checked.includes(id);
        let newChecked:number[] = [];

        if(includes) {
            newChecked = checked.filter(i => i !== id);
        }else{
            newChecked = [...checked, id];
        }

        await this.props.onChange(newChecked);

        this.setState((prevState) => ({
            list : prevState.list.map(item => ({
                ...item,
                checked : newChecked.includes(item.id)
            }))
        }))

    };

    resizeBox = () => {
        const {list, showingList, isOpenAll} = this.state;

        if(isOpenAll) {
            this.setState((prevState) => ({
                showingList : prevState.list
            }))
        }else{
            const moduleGroups = document.querySelector('.module-groups')?.clientWidth;
            const nameWidth = document.querySelector('.module-groups__text')?.clientWidth;
            const checkboxesElemes = document.querySelectorAll('.module-groups__label');
            const moduleGroupBtnWidth = document.querySelector('.module-groups__button')?.clientWidth;

            const availableWidth = moduleGroups - ( nameWidth + moduleGroupBtnWidth + 10);

            let copyCount:number = 0;
            let shouldContinue = true; // Нужно что бы цикл перестал добавлять элементы если длина первого найденого превышает порог

            Array.prototype.slice.call(checkboxesElemes).reduce( ((acc, item) => {

                const res = acc - ( item.clientWidth + 5); // margin справа 5px
                if( res > 0 && shouldContinue) {
                    copyCount += 1;
                    return res
                }else {
                    shouldContinue = false
                }

                return acc;
            }), availableWidth);

            this.setState((prevState) => ({
                showingList : prevState.list.slice(0, copyCount)
            }));
        }
    };

    resetState = () => {

        //showing count

        this.setState(() => {
            const list = this.props?.list?.map((item) => ({
                ...item,
                checked : this.props.checked.includes(item.id),
            }));

            return {
                showingList : list,
                list
            }
        }, this.resizeBox)
    };

    onClickMoreBtn = () => {
        this.setState((prevState) => ({
            isOpenAll : !prevState.isOpenAll
        }), this.resizeBox)
    };

    componentDidMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: Readonly<ModuleGroupsProps>, prevState: Readonly<State>, snapshot?: any): void {
        if(JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.resetState();
        }

    }

    render() {
        const {list, isOpenAll, showingList} = this.state;

        const showMoreBtn = ( list?.length > showingList?.length || isOpenAll);

        return <div className="module-groups">
            <span className="module-groups__text">{GetText(this.props.title)}</span>
            <div className="statuses" id="statusesWrapper">
                {showingList?.map(item => {
                    return (
                        <label  key={item.id} className={`${item.checked ? 'checked module-groups__label' : 'module-groups__label'}`}>
                            <input
                                className="module-groups__checkbox"
                                type="checkbox"
                                checked={item.checked}
                                onChange={this.onChangeGroup.bind(this, item.id)}
                            />{item.name}
                        </label>
                    )
                })}

                <div
                    className={ `module-groups__button ${showMoreBtn ? 'module-groups__button--showing' : 'module-groups__button--hidden'}`}
                    onClick={this.onClickMoreBtn}
                >
                    {this.state.isOpenAll ?
                        getText('UI.statusFilters.hide') :
                        <>
                            {getText('UI.statusFilters.more')} {list?.length - showingList?.length}
                        </>
                    }
                </div>
            </div>
        </div>

    }
}