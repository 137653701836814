import React from 'react'
import '../act.css'
import {
	OrderListItem as IOrderListItem,
	removeActsOrders,
	IActOrder,
} from '@justpro/terminal'
import OrderListItem from '../../orders/order/order.controller'
import checkError from '../../../utils/checkError'
import getText from '../../../localization/getText'
import { connect } from 'react-redux'

interface Props {
	orders?: IActOrder[]
	actId: number
	rights: any
}

interface State {
	orders: IActOrder[]
	activeOrderId: number | null
}

class ActOrders extends React.Component<Props, State> {
	state: State = {
		orders: [],
		activeOrderId: null,
	}

	handleActiveOrderId = (orderId: number | null) => {
		this.setState({
			activeOrderId: orderId,
		})
	}

	renderOrderItem = (item: IOrderListItem) => {
		const { rights } = this.props
		return (
			<OrderListItem
				key={item.number}
				order={item}
				withModuleDetail={true}
				withDelete={{
					deleteHandler: this.deleteActOrder,
					title: 'acts.deleteOrderFromAct',
					condition: rights?.['acts.orders']?.delete,
				}}
				orderIdAccessor='orderId'
				clickHandler={this.handleActiveOrderId}
				activeOrderId={this.state.activeOrderId}
			/>
		)
	}

	renderSuborder = (item: IActOrder) => {
		return (
			<OrderListItem
				withActNumber={true}
				key={item.number}
				order={item}
				withModuleDetail={true}
				className='order-suborder'
				isActSuborder={true}
				clickHandler={this.handleActiveOrderId}
				activeOrderId={this.state.activeOrderId}
			/>
		)
	}

	resetState = () => {
		const { orders } = this.props

		this.setState(() => ({
			orders: orders
				? orders.map((item) => {
						return {
							...item,
							photosCount: '0',
							filesCount: '0',
							offersCount: '0',
							subordersCount: '0',
						}
				  })
				: [],
			activeOrderId: null,
		}))
	}

	deleteActOrder = async (id: number) => {
		try {
			const deleted = await removeActsOrders(this.props.actId, [id])

			if (deleted) {
				this.setState((prevState) => ({
					orders: prevState.orders.filter(
						(item) => item.orderId !== id,
					),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<{}>,
		snapshot?: any,
	): void {
		if (this.props.actId !== prevProps.actId) {
			this.resetState()
		}
	}

	componentDidMount(): void {
		this.resetState()
	}

	render() {
		const { orders } = this.state

		if (orders.length === 0) {
			return null
		}

		return (
			<div className='just-pro__act-orders'>
				<h4>{getText('acts.actIncludesOrders')} </h4>

				<div className='act-orders'>
					{orders &&
						orders.map((order: any) => {
							const { suborders } = order

							return (
								<div key={order.id}>
									{this.renderOrderItem(order)}

									{suborders &&
										suborders.length > 0 &&
										suborders.map((suborder: IActOrder) => {
											return this.renderSuborder(suborder)
										})}
								</div>
							)
						})}
				</div>
			</div>
		)
	}
}

const mapState = (state: any) => ({
	rights: state?.application?.rights,
})

export default connect(mapState, null)(ActOrders)
