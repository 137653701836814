import {Workorder, CalendarEvent} from "@justpro/terminal";
import {getSecondsStartTime} from "./calendarUtils";

type DimentionType = {
    id: number
    duration: number
    distance: number
}

type MatrixElementType = {
    id: number
    dimensions: DimentionType[]
}

export interface WorkorderDuration extends Workorder {
    duration: number
    new?: boolean
    calendar: CalendarEvent
}

export type CalendarItemType = {
    title?: string
    key?: string
    new?: boolean
    break?: boolean
    factMode?: boolean
    id?: number
    from: number
    duration: number
    roadDistance?: number
    roadDuration?: number
    workorder?: WorkorderDuration
    distanceMatrixError?: boolean
    updateBreakTime?(value: number): void
    deleteOrder?(woId: number, orderId: number, event: any): void
    updateOrderDuration?(orderId: number, event: any): void
    componentToOpen?: () => any
}

export default class Calendar {

    public basePointId: number
    public minStartTime: number
    public maxDuration: number

    private data: CalendarItemType[] = []
    private matrix: MatrixElementType[] = []

    constructor({basePointId = null, minStartTime = 25200, maxDuration = 13 * 3600}) {
        this.minStartTime = minStartTime;
        this.maxDuration = maxDuration;
        this.basePointId = basePointId
    }

    changeMinStartTime(minStartTime) {
        this.minStartTime = minStartTime;
    }

    clearData() {
        this.data = [];
    }

    getMinStartTime() {
        return this.minStartTime;
    }

    setMinStartTime(minStartTime) {
        this.minStartTime = minStartTime;
        this.recalculate();
    }

    initFromWorkorders(workorders, breakDuration = 30 * 60) {
        const scheduledWorkorders = [];
        const emergencyWorkorders = [];
        const adminWorkorders = [];
        const simpleWorkorders = [];
        workorders.map((w) => {
            if (w.orders?.some(order => order.executionTime)) {
                return scheduledWorkorders.push(w);
            }
            if (w.emergency) {
                return emergencyWorkorders.push(w);
            }
            if (w.type === 'admin') {
                return adminWorkorders.push(w);
            }
            return simpleWorkorders.push(w);
        });

        scheduledWorkorders.map(this.addSchedule, this);
        this.addNearFree(emergencyWorkorders);
        this.addNearFree(adminWorkorders);
        this.addBreak(breakDuration);
        this.addNearFree(simpleWorkorders);
    }

    addEvent(event: CalendarItemType) {
        this.data.push(event);
        this.data.sort((e1, e2) => {
            return e1.from - e2.from;
        });
        this.recalculateRoad();
    }

    setMatrix(matrix, wayPoints) {
        const formatedMatrix = matrix.rows.map((row, id) => ({
            id: wayPoints[id]?.objectId,
            dimensions: row.elements.map((element, innerId) => ({
                id: wayPoints[innerId]?.objectId,
                distance: element?.distance?.value || 0,
                duration: element?.duration?.value || 0,
            }))
        }))
        this.matrix = formatedMatrix
    }

    getMatrix() {
        return this.matrix || []
    }

    getInfoBetween(objectFromId: number, objectToId: number): DimentionType {
        const matrixElement = this.matrix
            .find((element) => element.id === objectFromId)
            ?.dimensions.find((d) => d.id === objectToId);
        return matrixElement || {
            id: 0,
            duration: 0,
            distance: 0
        }
    }

    addSchedule(workorder) {
        const lastObject = this.data?.[this.data?.length - 1]?.workorder?.object.id || this.basePointId;
        const objectInfo = this.getInfoBetween(lastObject, workorder?.object?.id);
        const from = getSecondsStartTime(workorder);

        const isAvailable = this.verifyTime(from - objectInfo?.duration, workorder.duration);
        if (!isAvailable) {
            this.addFree(workorder);
        }

        this.addEvent({
            from,
            duration: workorder.duration,
            roadDuration: objectInfo?.duration,
            roadDistance: objectInfo?.distance,
            workorder
        });
    }

    addFree(workorder) {
        let woToLast = null;
        const lastEvent = this.data?.length && this.data[this.data?.length - 1];
        const bpToWo = this.getInfoBetween(this.basePointId, workorder.object.id);
        if (lastEvent) {
            woToLast = this.getInfoBetween(workorder.object.id, lastEvent.workorder.object.id);
        }
        const isBeforeAvailable = this.verifyTime(
            this.minStartTime + bpToWo.duration,
            workorder.duration + (woToLast?.duration || 0)
        );
        if (isBeforeAvailable) {
            return this.addEvent({
                from: this.minStartTime + bpToWo.duration,
                duration: workorder.duration,
                roadDuration: bpToWo.duration,
                roadDistance: bpToWo.distance,
                workorder
            })
        }

        const availableAfterEvent = this.data.find((event) => {
            const eventToWo = this.getInfoBetween(event.workorder.object.id, workorder.object.id);
            const from = event.from + event.duration + eventToWo.duration;
            return this.verifyTime(from, workorder.duration);
        });
        if (availableAfterEvent) {
            const eventToWo = this.getInfoBetween(availableAfterEvent.workorder.object.id, workorder.object.id);
            const from = availableAfterEvent.from + availableAfterEvent.duration + eventToWo.duration;
            return this.addEvent({
                from,
                duration: workorder.duration,
                roadDuration: eventToWo.duration,
                roadDistance: eventToWo.distance,
                workorder
            })
        }
    }

    addToEnd(workorder) {
        const lastEvent = this.data?.length && this.data[this.data?.length - 1];
        const lastObject = lastEvent?.workorder?.object?.id ||
            this.data[this.data?.length - 2]?.workorder?.object?.id ||
            this.basePointId;
        const eventToWo = this.getInfoBetween(lastObject, workorder.object.id);
        const from = lastEvent.from + lastEvent.duration + eventToWo.duration;
        return this.addEvent({
            from,
            duration: workorder.duration,
            roadDuration: eventToWo.duration,
            roadDistance: eventToWo.distance,
            workorder
        });
    }

    findNearWorkorder(workorders) {
        const objectsId = workorders.map((w) => w.object.id);
        const lastObjectDimensions = this.matrix
            .find((m) => m.id === this.basePointId)
            .dimensions
            .filter((d) => objectsId.includes(d.id));
        let minDimensions = lastObjectDimensions[0];
        lastObjectDimensions.map((d) => {
            if (d.duration < minDimensions.duration) {
                minDimensions = d;
            }
        });
        return workorders.find((w) => w.object.id === minDimensions.id);
    }

    addFreeEvery(workorders) {
        for (let i = -1; i < this.data.length; i++) {
            const prevEvent = this.data[i - 1];
            const event = this.data[i];
            const nextEvent = this.data[i + 1];
            const lastObjectId = event?.workorder?.object?.id || prevEvent?.workorder?.object?.id || this.basePointId;
            workorders.sort((w1, w2) => {
                const lastObjectToW1 = this.getInfoBetween(lastObjectId, w1.object.id);
                const lastObjectToW2 = this.getInfoBetween(lastObjectId, w2.object.id);
                return lastObjectToW1.duration - lastObjectToW2.duration;
            });
            for (let j = 0; j < workorders.length; j++) {
                const currentWorkorder = workorders[j];
                const lastObjectToWorkorder = this.getInfoBetween(lastObjectId, currentWorkorder.object.id);
                const currentWorkorderToNext = nextEvent ?
                    this.getInfoBetween(currentWorkorder.object.id, nextEvent?.workorder?.object.id) :
                    {
                        duration: 0,
                        distance: 0
                    };
                let from = this.minStartTime + lastObjectToWorkorder.duration;
                if (event) {
                    from = event.from + event.duration + lastObjectToWorkorder.duration;
                }
                if (this.verifyTime(from - lastObjectToWorkorder.duration, currentWorkorder.duration + currentWorkorderToNext?.duration)) {
                    this.addEvent({
                        from,
                        duration: currentWorkorder.duration,
                        workorder: currentWorkorder,
                        roadDuration: lastObjectToWorkorder.duration,
                        roadDistance: lastObjectToWorkorder.distance
                    });
                    return j;
                }
            }
        }
        return 0;
    }

    addBreak(duration) {
        let from = 13 * 3600;
        const isAvailable = this.verifyTime(from, duration);

        this.data = this.data.filter(event => event.workorder)

        if (!isAvailable) {
            const lastEvent = this.data[this.data.length - 1];
            from = lastEvent.from + lastEvent.duration;
        }
        this.addEvent({
            from,
            duration,
            break: true
        });
    }

    updateBreak(duration) {
        this.data.forEach((event) => {
            if (event.break) {
                event.duration = duration;
            }
        });
    }

    addNearFree(workorders) {
        if (!workorders?.length) {
            return null;
        }
        let otherWorkorders = [...workorders];
        if (!this.data.length) {
            const nearWorkorder = this.findNearWorkorder(workorders);
            const bpToWo = this.getInfoBetween(this.basePointId, nearWorkorder.object.id);
            this.addEvent({
                from: this.minStartTime + bpToWo.duration,
                duration: nearWorkorder.duration,
                roadDuration: bpToWo.duration,
                roadDistance: bpToWo.distance,
                workorder: nearWorkorder
            });
            otherWorkorders = otherWorkorders.filter((w) => w.id !== nearWorkorder.id);
        }
        while (otherWorkorders?.length) {
            const index = this.addFreeEvery(otherWorkorders);
            otherWorkorders.splice(index, 1);
        }
    }

    getData() {
        return [...this.data] || []
    }

    buildFrom(calendar) {
        this.data = [...calendar];
    }

    recalculateRoad() {
        this.data.forEach((event, index, events) => {
            const lastObjectId = events[index - 1]?.workorder?.object?.id ||
                events[index - 2]?.workorder?.object?.id ||
                this.basePointId;
            if (event?.workorder) {
                const lastToWo = this.getInfoBetween(lastObjectId, event?.workorder?.object?.id);
                event.roadDuration = lastToWo.duration;
                event.roadDistance = lastToWo.distance;
            }
        });
    }

    recalculate() {
        this.data.forEach((event, index, events) => {
            const lastObjectId = events[index - 1]?.workorder?.object?.id ||
                events[index - 2]?.workorder?.object?.id ||
                this.basePointId;
            if (event?.workorder) {
                const lastToWo = this.getInfoBetween(lastObjectId, event?.workorder?.object?.id);
                event.roadDuration = lastToWo.duration;
                event.roadDistance = lastToWo.distance;
            }
            if (index === 0) {
                event.from = this.minStartTime + (event?.roadDuration || 0);
            } else {
                const lastEvent = events[index - 1];
                event.from = lastEvent.from + lastEvent.duration + (event?.roadDuration || 0);
            }
        });
    }

    private verifyTime(from: number, duration: number) {
        return this.data.every((event) => {
            const start = event.from - (event?.roadDuration || 0);
            const end = event.from + event.duration;
            return (from < start && from + duration < start) ||
                (from >= end && from + duration >= end);
        })
    }
}