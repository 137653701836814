import moment from "moment";
const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60

const recursiveFindIndex = ( matrix, lastCorrectIndex, currentItemSorted, scheduledWorkorders, currentIndex = 0 ) => {
    if (!lastCorrectIndex) return 1
    let newIndex = currentIndex;
    let nextIndex = matrix[lastCorrectIndex].findIndex(el => el === currentItemSorted[currentIndex])
    for (let o = 0; o < scheduledWorkorders.length; o++) {
        if (scheduledWorkorders[o].index === nextIndex) {
            newIndex++
            break
        }
    }
    if (newIndex === currentIndex) {
        return nextIndex
    }
    if (currentIndex < currentItemSorted.length - 1) {
        return recursiveFindIndex( matrix, lastCorrectIndex, currentItemSorted, scheduledWorkorders, ++currentIndex );
    } else {
        return matrix[lastCorrectIndex].findIndex(el => el === currentItemSorted[0])
    }
}

export const getSecondsStartTime = (workorder) => {
    const order = workorder.orders?.find((order) => order?.executionTime);
    return getOrderStartTime(order);
}

export const getOrderStartTime = (order) => {
    let hours = 0, minutes = 0;
    if(order?.executionTime?.[0]?.from.includes(":")){
        [hours, minutes] = order?.executionTime?.[0]?.from?.split(":");
    }
    if(order?.executionTime?.[0]?.from.includes("-")){
        [hours, minutes] = order?.executionTime?.[0]?.from?.split("-");
    }
    return +hours * 3600 + +minutes * 60 || 0;
}


export const determineArrowDirection = (isLeft, isOpen) => {
    if (isLeft && isOpen) {
        return {transform: 'rotate(-180deg)'}
    }
    if (isLeft && !isOpen) {
        return {transform: 'rotate(0)'}
    }
    if (!isLeft && isOpen) {
        return {transform: 'rotate(0)'}
    }
    if (!isLeft && !isOpen) {
        return {transform: 'rotate(-180deg)'}
    }
}

export const convertToTime = (n: string) => {
    return n
        .split('-')
        .map(item => item.padStart(2, '0'))
        .join(':');
}

export const findFrom = (act) => {
    const sortedTimes = act.coworkers
        .map(coworker => coworker.executedTime)
        .flat()
        .sort((a, b) => a?.from?.localeCompare(b?.from))
    const time = convertToTime(sortedTimes[0]?.from || '00-00')
    return time
}
export const findTo = (act) => {
    const sortedTimes = act.coworkers
        .map(coworker => coworker.executedTime)
        .flat()
        .sort((a, b) => b?.to?.localeCompare(a?.to))
    const time = convertToTime(sortedTimes[0]?.to || '00-00')
    return time
}

export const getDuratiuon = (act) => {
    const from = findFrom(act).split(':')
    const fromHours = +from[0]
    const fromMinutes = +from[1]

    const to = findTo(act).split(':')
    const toHours = +to[0]
    const toMinutes = +to[1]

    let hoursDiff = toHours - fromHours
    let minutesDiff = toMinutes - fromMinutes

    if (hoursDiff < 0) hoursDiff += 24
    if (minutesDiff < 0) minutesDiff += 60

    return hoursDiff * SECONDS_IN_HOUR + minutesDiff * SECONDS_IN_MINUTE
}

export const calcWorkorderDuration = (orders) => {
    return orders?.reduce?.((duration, o) => o.duration + duration, 0) || 0;
}