import React from "react";
import TextArea from "../../UI/textArea/textArea.controller";
import {declineModalId} from "./orderRead.controller";
import {updateModal} from "../../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import Button from "../../UI/button/button";
import GetText from "../../../localization/getText";
import DefaultFooterModal from '../../UI/dialog/modal/defaultFooterModal'


interface Props extends ModalBodyProps{
    save(reason:string) : Promise<void>
    updateModal(id:string, props:Partial<Modal>) : void
}

interface State {
    reason?: string
}

class DeclineReasonModal extends React.Component<Props, State>{
    state:State = {
        reason: ''
    };

    isDisabled = () => {
        const {reason} = this.state;
        return !reason || reason.trim() === '';
    };

    changeHandler = (reason:string) => {this.setState(() => ({reason}))};

    componentDidMount(): void {
        this.setState(() => ({reason : ''}))
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(JSON.stringify(this.props) !== JSON.stringify(prevProps) ) {
            this.setState(() => ({reason : ''}))
        }
    }

    onSend = () => {
        this.props.save(this.state.reason);
        this.props.hide();
    }

    onCancel = () => {
        this.props.hide();
    }

    render () {

        return <>
            <TextArea
                placeholder="UI.textArea.placeholders.orderCancelReason"
                value={this.state.reason}
                change={this.changeHandler}
                isDisabledButton={this.isDisabled()}
            />
            <DefaultFooterModal>
                <Button className="btn-danger modal-button" onClick={this.onCancel}>
                    {GetText('common.cancel')}
                </Button>
                <Button className="btn-success modal-button" disabled={this.isDisabled()} onClick={this.onSend}>
                    {GetText('common.send')}
                </Button>
            </DefaultFooterModal>

        </>

    }
}


const mapDispatchToProps =  (dispatch:Function) => ({
    updateModal: (id:string, props:Partial<Modal>) => dispatch(updateModal(id, props))
});

export default connect(null, mapDispatchToProps)(DeclineReasonModal)