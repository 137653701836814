import React from "react";
import {
    ITasksPerson,
    ITaskWatcher,
} from "@justpro/terminal";
import WithModuleDetail from "../../UI/dialog/withModuleDetail/withModuleDetail.controller";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import {StaffTreeItem} from "./staff.detail";
import getText from "../../../localization/getText";
import {ModalBodyProps} from "../../../store/modal/modal.types";
import StaffWatcherModal from "./staff.watcherModal";

interface Props {
    contractorId: number
    staff: StaffTreeItem
    updateTree?() : void
}

interface State {
    touched : boolean,
    taskPersons: ITasksPerson[]
}

class StaffEditBtn extends React.Component<Props, State>{

    state:State  = {
        // isOpen : false,
        touched : false,
        taskPersons:  []
    };

    resetState = () => {
        this.setState(() => ({
            taskPersons : this.props.staff?.tasksPersons ? this.props.staff.tasksPersons : []
        }))
    };

    afterChange = (list: ITaskWatcher[]) => this.setState(() => ({taskPersons: list}));

    componentDidMount(): void {
        this.resetState()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(JSON.stringify(prevProps.staff) !== JSON.stringify(this.props.staff)) {
            this.resetState();
        }
    }

    render () {
        const {staff} = this.props;


        return (
            <WithModuleDetail
                element={<Tooltip position="down" title="staff.watchingForTasks"><i className="fa fa-edit" /></Tooltip>}
                modal={{
                    id : 'staff',
                    title : getText('staff.addWatchingForTasks'),
                    minHeight : "60vh",
                    component : (props:ModalBodyProps) => <StaffWatcherModal afterChange={this.afterChange} {...props} list={this.state.taskPersons} {...this.props} />
                }}
            />
        )
    }
}

export default StaffEditBtn