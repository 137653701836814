import React from "react";
import '../act.css'
import {Object as IObject} from '@justpro/terminal'
import Table from "../../UI/table/table";
import RenderIf from "../../../utils/renderIf";
import {getObjectName} from "../../../utils/names";
import getText from "../../../localization/getText";

interface Props {
    actId : number
    object : IObject
}

class ActDistance extends React.Component<Props>{

    render() {
        const {object} = this.props;

        return (
            <RenderIf  condition={object !== undefined}>
                <div className="just-pro__act-distance">
                    <h4>{getText('common.distance')}</h4>

                    {object && <Table
                        data={[object]}
                        columns={[
                            {
                                Header : getText('objects.object'),
                                Cell : (props:any) => getObjectName(props.original)
                            },
                            {
                                Header : getText('common.distance'),
                                Cell : (props:any) => props?.original?.filial1s?.distance
                            },
                        ]}
                    />}
                </div>
            </RenderIf>


        )
    }
}

export default ActDistance