import React from 'react'
import WithPrivateRoute from '../withPrivateRoute/withPrivateRoute.controller'
import HeaderController from '../header/header.controller'
import ModuleTextName from '../UI/moduleTextName/moduleTextName'
import './news.css'
import Tooltip from '../UI/tooltip/tooltip.controller'
import Button from '../UI/button/button'
import {
	getNews,
	getNewsDrafts,
	SingleNews,
	updateSingleNews,
	AccessError,
	readNews,
} from '@justpro/terminal'
import Spinner from '../UI/spinner/spinner.controller'
import checkError from '../../utils/checkError'
import SingleNewsController from './singleNews.controller'
import SingleNewsEdit from './singleNews.edit'
import Tabs from '../UI/tabs/tabs.controller'
import RenderIf from '../../utils/renderIf'
import { connect } from 'react-redux'
import { openModal } from '../../store/modal/modal.actions'
import { Modal, ModalBodyProps } from '../../store/modal/modal.types'
import getText from '../../localization/getText'
import { getUserMe } from '../../store/application/application.actions'

interface NewsContainerProps {
	news: SingleNews[]

	open(singleNews?: SingleNews): any

	changeDraft(singleNews?: SingleNews): any
}

const NewsContainer = ({ news, open, changeDraft }: NewsContainerProps) => (
	<div className='news__container'>
		<div className='news__list'>
			{news?.map((singleNews) => (
				<SingleNewsController
					singleNews={singleNews}
					key={singleNews.id}
					openModal={open}
					changeDraft={changeDraft}
				/>
			))}
		</div>
	</div>
)

interface Props {
	rights?: any
	getUserMe: Function
	openModal(props: Modal): void
}

interface State {
	news: SingleNews[]
	drafts: SingleNews[]
	singleNews?: SingleNews
	loading: boolean
	// isOpenEditModal: boolean
}

export const editModalId = 'editNewsModal'

class NewsController extends React.Component<Props, State> {
	state: State = {
		news: [],
		drafts: [],
		loading: false,
		// isOpenEditModal: false
	}

	get tabs() {
		return [
			{
				title: 'UI.tabs.published',
				component: () => (
					<NewsContainer
						news={this.state.news}
						open={this.openModal}
						changeDraft={this.changeDraft}
					/>
				),
				onSelectTab: () => void 0,
			},
			{
				title: 'UI.tabs.draft',
				component: () => (
					<NewsContainer
						news={this.state.drafts}
						open={this.openModal}
						changeDraft={this.changeDraft}
					/>
				),
				onSelectTab: () => void 0,
			},
		]
	}

	addNovelty = () => {}

	getNews = async () => {
		try {
			this.setState({
				loading: true,
			})
			const news = await getNews({})
			const drafts = await getNewsDrafts({})
			this.setState({
				news,
				drafts,
			})
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	openModal = (singleNews?: SingleNews) => {
		this.props.openModal({
			id: editModalId,
			size: 'large',
			minHeight: '90vh',
			component: (props: ModalBodyProps) => (
				<SingleNewsEdit
					{...props}
					singleNews={singleNews}
					onSave={this.onSave}
				/>
			),
			title: 'Новость',
			afterClose: () => {
				this.setState({ singleNews: void 0 })
			},
		})
	}

	changeDraft = async (singleNews: SingleNews) => {
		try {
			this.setState({
				loading: true,
			})
			await updateSingleNews(singleNews.id, {
				isDraft: !singleNews.isDraft,
			})
			await this.getNews()
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	onSave = async () => {
		try {
			this.setState({
				singleNews: void 0,
				// isOpenEditModal: false
			})
			await this.getNews()
		} catch (err) {
			checkError(err)
		}
	}

	async componentDidMount() {
		try {
			await this.getNews()
			const isRead = await readNews()
			if (isRead) {
				await this.props.getUserMe()
			}
		} catch (err) {
			checkError(err)
		}
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	) {
		try {
			if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
				return this.getNews()
			}
		} catch (err) {
			checkError(err)
		}
	}

	render() {
		const { rights } = this.props
		return (
			<WithPrivateRoute>
				<HeaderController>
					<ModuleTextName>
						{getText('news.moduleName')}
					</ModuleTextName>
					<div className='navbar-form navbar-left buttons'>
						<RenderIf condition={rights?.news?.create}>
							<Tooltip title='news.addNews'>
								<Button
									className='btn-default'
									onClick={this.openModal.bind(
										this,
										undefined,
									)}
								>
									<i className='fas fa-plus' />
								</Button>
							</Tooltip>
						</RenderIf>
					</div>
				</HeaderController>

				<div className='just-pro_module news'>
					<div className='panel content-panel'>
						<Spinner loading={this.state.loading} />
						<Tabs tabs={this.tabs} />
					</div>
				</div>
			</WithPrivateRoute>
		)
	}
}

const mapState = (state: any) => ({
	rights: state.application.rights,
})

const mapDispatchToProps = (dispatch: Function) => ({
	openModal: (props: Modal) => dispatch(openModal(props)),
	getUserMe: () => dispatch(getUserMe()),
})

export default connect(mapState, mapDispatchToProps)(NewsController)
