import React from 'react'
import {
	deletePersonTransport,
	deletePersonBasePoint,
	IPersonTransport,
	PersonBasePoint as IPersonBasePoint,
} from '@justpro/terminal'
import Table from '../../UI/table/table'
import Button from '../../UI/button/button'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import PersonBasePointsModal from './person.basePointsModal'
import withLabel from '../../UI/withLabel/withLabel'
import checkError from '../../../utils/checkError'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types'
import { openModal } from '../../../store/modal/modal.actions'

interface IProps {
	personId: number
	basePoints?: IPersonBasePoint[]
	rights: any
	openModal(data: Modal): void
}
interface IState {
	basePoints: IPersonBasePoint[]
}

export const personsBasePointModalId = 'personsBasePointModalId'

class PersonBasePoint extends React.Component<IProps, IState> {
	state: IState = {
		basePoints: [],
	}

	createPersonBasePoint = () => {
		this.props.openModal({
			id: personsBasePointModalId,
			component: (props: ModalBodyProps) => (
				<PersonBasePointsModal
					{...props}
					personId={this.props.personId}
					updatePersonBasePoint={this.updatePersonBasePoint}
				/>
			),
			title: getText('persons.addBasePoint'),
		})
	}

	editPersonBasePoints = (personBasePoints: IPersonBasePoint) => {
		this.props.openModal({
			id: personsBasePointModalId,
			component: (props: ModalBodyProps) => (
				<PersonBasePointsModal
					{...props}
					personId={this.props.personId}
					basePoint={personBasePoints}
					updatePersonBasePoint={this.updatePersonBasePoint}
				/>
			),
			title: getText('persons.editingTransport'),
		})
	}

	updatePersonBasePoint = (basePoint: any) => {
		console.log('basePoint: ', basePoint)
		this.setState((prevState) => {
			const isBasePoint = prevState.basePoints?.find(
				(bp) => bp.id === basePoint.id,
			)
			if (isBasePoint) {
				return {
					basePoints: prevState.basePoints?.map((t) => {
						if (t.id === basePoint.id) {
							return basePoint
						}
						return t
					}),
				}
			} else {
				return {
					basePoints: [...prevState.basePoints, basePoint],
				}
			}
		})
	}

	deletePersonBasePoint = async (id: number) => {
		try {
			const deleted = await deletePersonBasePoint({
				personBasePointId: id,
				personId: this.props.personId,
			})
			console.log('deleted: ', deleted)
			if (deleted) {
				this.setState((prevState) => ({
					basePoints: prevState.basePoints.filter((p) => p.id !== id),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	resetState = () => {
		this.setState(() => ({
			basePoints: this.props.basePoints ? this.props.basePoints : [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(
		prevProps: Readonly<IProps>,
		prevState: Readonly<IState>,
		snapshot?: any,
	): void {
		if (
			prevProps.personId !== this.props.personId ||
			prevProps.basePoints !== this.props.basePoints
		) {
			this.resetState()
		}
	}

	getColumns = () => {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('objects.address'),
				accessor: 'address',
				Cell: (props: any) => (
					<div>{`${props.original.city.name}, ${props.original.address}`}</div>
				),
			},
		]
		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['edit']
		) {
			result.push({
				Header: getText('common.edit'),
				Cell: (props: any) => (
					<Button
						className='btn-success'
						onClick={this.editPersonBasePoints.bind(
							this,
							props.original,
						)}
					>
						<i className='fa fa-edit no-text' />
					</Button>
				),
			})
		}
		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deletePersonBasePoint}
						id={props.original.id}
					/>
				),
			})
		}
		return result
	}

	render() {
		const { basePoints } = this.state
		const { rights } = this.props
		return withLabel(
			<>
				{basePoints && basePoints.length > 0 && (
					<>
						<Table columns={this.getColumns()} data={basePoints} />
					</>
				)}

				{rights &&
					rights['references.persons'] &&
					rights['references.persons']['create'] && (
						<>
							<Button
								onClick={this.createPersonBasePoint}
								className={
									basePoints.length > 0
										? 'btn-success margin-top'
										: 'btn-success'
								}
							>
								{getText('persons.addBasePoint')}
							</Button>
						</>
					)}
			</>,
		)({ text: 'persons.basePoints' })
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

const mapDispatch = (d: Function) => ({
	openModal: (data: Modal) => d(openModal(data)),
})

export default connect(mapStateToProps, mapDispatch)(PersonBasePoint)
