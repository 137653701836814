import {WorkOrdersReducer, initialState} from "./workOrders.types";
import {AnyAction} from "redux";


export const workOrdersActions = {
    GET_WORK_ORDERS_LIST : 'GET_WORK_ORDERS_LIST',

};

export default (state:WorkOrdersReducer = initialState, action:AnyAction) => {
    const {type, ...rest} = action;

    switch (type) {
        case workOrdersActions.GET_WORK_ORDERS_LIST :
            return {...state, ...rest};
    }
    return state;
}