import React, { FC, useEffect, useState } from 'react'
import { Workorder, getWorkorders } from '@justpro/terminal'
import ItemList from "../UI/itemsList/itemList.controller";
import WorkOrderListItem from "../workOrders/workOrder.listItem";
import checkError from '../../utils/checkError';
import WorkOrderDetail from '../workOrders/workOrder.controller'
import getText from '../../localization/getText';
import { connect } from 'react-redux';
import { Modal } from '../../store/modal/modal.types';
import { openModal } from '../../store/modal/modal.actions';
import RenderIf from '../../utils/renderIf';
import Spinner from '../UI/spinner/spinner.controller';

const WorkordersModal: FC<any> = ({ openModal, executorId, dateTo, dateFrom }) => {
    
    const [isLoading, setIsLoading] = useState(false)
    const [backScrollToTop, setBackScrollToTop] = useState(false)
    const [workordersList, setWorkordersList] = useState([])
    const [workordersCount, setWorkordersCount] = useState(0)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        getList()
    }, [])

    const openPastWorkorders = async (item: Workorder) => {
        openModal({
          id: "work_order_detail",
          component: () => <WorkOrderDetail
                id={item.id}
                updateList={getList}
                updateListItem={updateListItem}
            />,
          title: getText('tasks.task'),
          size: 'large',
        })
    }

    const updateListItem = (workorder: Workorder) => {
        setWorkordersList(workordersList.map((w) => {
            if (w.id === workorder.id) {
                return {
                    ...w,
                    ...workorder
                }
            }
            return w;
        }))
    }

    const renderWorkOrder = (item: Workorder) => {
        return <WorkOrderListItem 
            workOrder={item} 
            getListItem={openPastWorkorders}                  
            activeItemId={0}
        />
    };

    const getList = async () => {
        try {

            setBackScrollToTop(true)
            setIsLoading(true)

            const {list, count} = await getWorkorders({
                responsiblesId: [executorId],
                closed: '0',
                types: ["orders", "admin"],
                dateFrom,
                dateTo
            });

            setWorkordersList(list)
            setWorkordersCount(count)
            setOffset(100)

        } catch (e) {
            checkError(e)
        } finally {
            setBackScrollToTop(false)
            setIsLoading(false)
        }
    };

    const updateList = async () => {
        if (+workordersCount > workordersList?.length) {
            try {
                setIsLoading(true)
                const {list, count} = await getWorkorders({
                    responsiblesId: [executorId],
                    dateFrom,
                    dateTo,
                });
                setWorkordersList([...workordersList, ...list])
                setWorkordersCount(count ? count : workordersCount)
                setOffset(offset + 100)
            } catch (e) {
                checkError(e);
            } finally {
                setIsLoading(false)
            }
        }
    };

    return (
        <>
            <RenderIf condition={isLoading}>
                <Spinner loading={isLoading}/>
            </RenderIf>
            <RenderIf condition={!isLoading}>
                <RenderIf condition={!workordersList.length}>
                    <div className='no-workorders_component'><h2>{getText('calendar.noWorkorders')}</h2></div>
                </RenderIf>
                <RenderIf condition={workordersList.length}>
                    <ItemList
                        list={workordersList}
                        renderItem={renderWorkOrder}
                        loading={isLoading}
                        backScrollToTop={backScrollToTop}
                        updateData={updateList}
                    />
                </RenderIf>
            </RenderIf>
        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (props: Modal) => {
            dispatch(openModal(props));
        },
    };
}
  
export default connect(null, mapDispatchToProps)(WorkordersModal);