import React, {useState, useCallback, useEffect} from 'react';
import withLabel from "../withLabel/withLabel";
import {renderToString} from "react-dom/server";
import GetText from "../../../localization/getText";

type InputType = React.FormEvent<HTMLInputElement>

export interface InputProps  {
    className? : string
    value? : string
    label? : string
    startValue? : string
    isDisabledButton? : boolean // Для блокироваки кнопки если такая есть
    onSend?(val?:string, oldValue?:string) : void // при отправке с другого обработчика , например, кнопка
    change?(value:string, oldValue?:string) : void // при обработке onChange
    blur?(val:string, oldValue?:string) : void  // при обработке onBlur
    disabled? : boolean
    autoComplete? : "off" | "on"
    placeholder? : string
    [key: string]: any

}


const Input = (props: InputProps) => {
    const {
        className,
        onSend,
        startValue,
        label,
        change,
        blur,
        placeholder,
        isDisabledButton,
        autoComplete = "off",
        disabled = false,
        ...rest
    } = props;

    const [value, setValue] = useState<string>( '');
    const [oldValue, setOldValue] = useState<string>('');

    let cls = ['just-pro-textInput'];
    if (className) {
        cls.push(className)
    }

    const onChangeHandler = useCallback(async (e:InputType) => {
        const targetValue = e.currentTarget.value;
        setOldValue(value);

        setValue(() => targetValue);

        if(!!change) {
            change(targetValue, value)
        }

    }, [value, change, oldValue]);

    const onBlurHandler = useCallback( () => {
        if(!!blur) {
            blur(value, oldValue)
        }
    },[value, oldValue, blur]);


    const onFocusHandler = (e: InputType) => {
        const v = e.currentTarget.value;
        setOldValue(() => v);
    };

    const onKeyPressHandler = (e:React.KeyboardEvent) => {

        if(e.key === 'Enter') {
            e.preventDefault();
            if(!!isDisabledButton) {
                return void 0;
            }
            if(!!onSend) {
                onSend(value, oldValue)
            }else{
                console.warn('Function onSend is not specified (fronend)!')
            }

        }
    };

    useEffect(() => {
        setValue(startValue !== undefined ? startValue : '')
    }, [startValue] );

    // const ref = useCallback(() => {
    //     setValue(startValue !== undefined ? startValue : '')
    // }, [startValue]);

    const id = 'input-' + Math.round(Math.random() * 999999);

    const jsx = (
            <input
                // ref={ref}
                value={value}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onKeyPress={onKeyPressHandler}
                onFocus={onFocusHandler}
                className={cls.join(' ')}
                id={id ? id : undefined}
                autoComplete={autoComplete}
                // disabled={true}
                disabled={disabled}
                placeholder={placeholder !== undefined ? renderToString(GetText(placeholder)) : undefined}
                {...rest}
            />
    );

    return label ? withLabel(jsx)({text: label, id}) : jsx
};

export default Input