import getFullName from "../../utils/getFullName";
import moment from "moment";
import Button from "../UI/button/button";
import File from "../UI/fileManager/file.preview";
import React from "react";
import {Comment} from "@justpro/terminal";

export const CommentsList = ({comments, onClick, onReply}: any) => (
    <div>
        {comments && comments?.map((comment: Comment) => (
            <div className="notification-task__wrapper">
                <div className="notification-task__header">
                    <img className="notification-task__avatar"
                         src={comment?.author?.person?.avatar}
                         alt={getFullName(comment?.author?.person)}
                    />
                    <div className="notification-task__user-wrapper">
                        <div className="notification-task__user">{getFullName(comment?.author?.person)}</div>
                        <div className="notification-task__datetime">
                            {moment(comment?.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                        </div>
                    </div>
                    <Button className="notification-task__button" onClick={() => onReply && onReply(comment)}>
                        <i className="fas fa-reply"/>
                        Ответить
                    </Button>
                    <Button className="notification-task__button" onClick={() => onClick && onClick(comment)}>
                        <i className="fas fa-info-circle"/>
                        Подробнее
                    </Button>
                </div>
                <div className="notification-task__content">
                    <div dangerouslySetInnerHTML={{__html: comment.message}}/>
                    <div className="notification-files__wrapper">
                        {comment.files && comment.files.length > 0 && comment.files.map((file, index) => {
                            return (
                                <File file={file} key={index} files={comment.files}/>
                            )
                        })}
                    </div>
                </div>
            </div>
        ))}
    </div>
);
