import React from 'react';
import {editWorkGroup, EditWorkGroup, getWorkGroup, WorkGroup,
    uploadFileWorkGroup, deleteFileWorkGroup, setActiveWorkGroupObjects} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import WorkGroupDetail from "./workGroup.detail";
import Spinner from "../../UI/spinner/spinner.controller";
import RenderIf from "../../../utils/renderIf";

interface Props {
    id: number
    afterUpdate?(item: WorkGroup): void
    updateList?(): void
}

interface State {
    workGroup?: WorkGroup
    loading: boolean
}

class WorkGroupController extends React.Component<Props, State> {
    state: State = {
        loading: false
    };

    getWorkGroup = async () => {
        this.setState(() => ({loading: true}));
        try {
            const workGroup = await getWorkGroup(this.props.id);
            console.log('~ workGroup', workGroup)
            this.setState(() => ({
                loading: false,
                workGroup
            }))

        } catch (e) {
            this.setState(() => ({loading: false}));
            checkError(e)
        }
    };

    update = async (data: Partial<EditWorkGroup>) => {

        try {
            this.setState(() => ({loading: true}));

            const workGroup = await editWorkGroup(this.props.id, data);

            if (workGroup) {
                if (data.hasOwnProperty('name') || data.hasOwnProperty('active')) {
                    this.props.afterUpdate && this.props.afterUpdate(workGroup);
                }
            }

            this.setState(() => ({workGroup, loading: false}))

        } catch (e) {
            this.setState(() => ({loading: false}));
            checkError(e)
        }
    };

    uploadFile = async (file: File) => {
        try {
            if (!file) return;
            this.setState({
                loading: true
            });

            await uploadFileWorkGroup(this.props.id, file, file.name);
            const workGroup = await getWorkGroup(this.props.id);

            this.setState({
                workGroup,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false
            });
            checkError(e)
        }
    };

    deleteAttachment = async () => {
        try {
            this.setState({
                loading: true
            });

            await deleteFileWorkGroup(this.props.id);
            const workGroup = await getWorkGroup(this.props.id);

            this.setState({
                workGroup,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false
            });
            checkError(e)
        }
    };

    async componentDidMount() {
        await this.getWorkGroup();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.id !== prevProps.id) {
            await this.getWorkGroup();
        }
    }

    setActiveWorkGroupObjects = async (objectsId: number[]) => {
        try{
            this.setState({
                loading: true
            })
            const objects = await setActiveWorkGroupObjects(this.state.workGroup.id, objectsId);
            const workGroup = await getWorkGroup(this.props.id);
            this.setState({
                workGroup
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const {workGroup} = this.state;
        return (<>
            <RenderIf condition={workGroup !== undefined && !this.state.loading}>
                <WorkGroupDetail workGroup={workGroup as WorkGroup}
                                 update={this.update}
                                 updateList={this.props.updateList}
                                 uploadFile={this.uploadFile}
                                 deleteAttachment={this.deleteAttachment}
                                 setActiveWorkGroupObjects={this.setActiveWorkGroupObjects}
                />
            </RenderIf>
            <Spinner loading={this.state.loading}/>
        </>)
    }
}

export default WorkGroupController