import React from 'react';
import {Fuel, createFuel, getFuels, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import FuelController from "./fuel.controller";
import FuelCreate from "./fuel.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class FuelsController extends React.Component<Props>{

    renderItem = (item:Fuel) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.fuels'];
        return <BaseReferenceController
            referenceName="fuels.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getFuels}
            Detail={FuelController}
            create={{
                Component : FuelCreate,
                title : 'fuels.addItem',
                handler : createFuel
            }}
            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(FuelsController)