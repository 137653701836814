import React from 'react'
import { RegularWork as IRegularWork } from '@justpro/terminal'
import OrderIcons from '../../UI/itemsList/icons/icons.controller'
import moment from 'moment'
import { Angle } from '../../UI/itemsList/card/card.view'
import ModuleListItem from '../../UI/itemsList/moduleListItem/moduleListItem.controller'
import WithModuleDetail from '../../UI/dialog/withModuleDetail/withModuleDetail.controller'
import RegularWorkController from './regularWork.controller'
import Tooltip from '../../UI/tooltip/tooltip.controller'
import { getObjectName } from '../../../utils/names'
import { getEquipmentName } from '../../../utils/names'
import getText from '../../../localization/getText'
import RenderIf from '../../../utils/renderIf'

interface Props {
	regularWork: IRegularWork
	listActive?: boolean

	clickHandler?(orderId: number): void

	withIncludes?: {
		handleInclude(regularWork: IRegularWork): void
		title: string
	}

	withDelete?: {
		handleDelete(regularWork: IRegularWork): void
		title: string
		condition?: boolean
	}

	withModuleDetail?: boolean
	activeOrderId?: number
}

const RegularWorkListItem = ({
	regularWork,
	clickHandler,
	listActive,
	withIncludes,
	withModuleDetail,
	withDelete,
	activeOrderId,
}: Props) => {
	const onRegularWorkClick = (e: React.MouseEvent) => {
		clickHandler && clickHandler(regularWork.id)
	}

	const onExpandClick = () => {
		clickHandler && clickHandler(regularWork.id)
	}

	const onExpandClose = () => {
		clickHandler && clickHandler(null)
	}

	const includesClickHandler = (
		e: React.MouseEvent,
		regularWork: IRegularWork,
	) => {
		e.stopPropagation()

		withIncludes && withIncludes.handleInclude(regularWork)
	}

	const deleteClickHandler = (
		e: React.MouseEvent,
		regularWork: IRegularWork,
	) => {
		e.stopPropagation()
		withDelete &&
			withDelete?.condition &&
			withDelete.handleDelete(regularWork)
	}

	return (
		<ModuleListItem
			isActive={regularWork.id === activeOrderId}
			onClick={onRegularWorkClick}
		>
			<div className='body'>
				<Angle
					size={40}
					color={
						regularWork.regularStatus.color &&
						regularWork.regularStatus.color
					}
				/>

				<div className='icon-zone'>
					<OrderIcons
						hot={regularWork?.hot}
						expired={regularWork.expired}
						photosCount={regularWork?.photosCount?.toString?.()}
						regularStatus={regularWork.regularStatus}
					/>
				</div>
				<div className='content-text'>
					<p className='list-group-item-text'>
						<b>{getObjectName(regularWork?.equipment?.object)}</b>
					</p>
					<p className='list-group-item-text'>
						Вид работы: {regularWork.equipmentWork.name}
					</p>
					<p className='list-group-item-text'>
						{getText('equipments.equipment')}:{' '}
						{getEquipmentName(regularWork.equipment)}
					</p>
					<p className='list-group-item-text'>
						<b>
							{regularWork?.otRequest?.number}
							{regularWork?.otRequest?.baseDocument
								? `(${regularWork?.otRequest?.baseDocument})`
								: ''}
						</b>
					</p>
				</div>
			</div>

			<div className='helpers'>
				{withIncludes !== undefined && (
					<Tooltip title={withIncludes.title} position='left'>
						<i
							className='fa fa-arrow-up include'
							onClick={(e: React.MouseEvent) =>
								includesClickHandler(e, regularWork)
							}
						/>
					</Tooltip>
				)}

				{!!withModuleDetail && (
					<WithModuleDetail
						element={
							<>
								<Tooltip
									title='common.openDetailInfo'
									position='left'
								>
									<i
										onClick={onExpandClick}
										className='fa fa-expand expand'
									/>
								</Tooltip>
							</>
						}
						modal={{
							id: `regularWork${regularWork.id}`,
							component: () => (
								<RegularWorkController id={regularWork.id} />
							),
							title: getText('regularWorks.regularWork'),
							// afterClose: onExpandClose,
						}}
					/>
				)}
				<RenderIf condition={withDelete && withDelete?.condition}>
					<Tooltip title={withDelete?.title} position='left'>
						<i
							className='fa fa-times remove'
							onClick={(e: React.MouseEvent) =>
								deleteClickHandler(e, regularWork)
							}
						/>
					</Tooltip>
				</RenderIf>
			</div>

			<div className='number'>
				<b>{regularWork.number}</b>
				<b>{moment(regularWork.date).format('DD.MM.YYYY')}</b>
				<b>{moment(regularWork.controlDate).format('DD.MM.YYYY')}</b>
			</div>
		</ModuleListItem>
	)
}

export default RegularWorkListItem
