import React from "react";
import {ModulesResponse, Phone} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    phone : Phone
    update(data:Partial<Phone>) : void
    rights? : Partial<ModulesResponse>
}

class PhoneDetail extends React.Component<Props>{

    blurPhoneName = (val:string, oldVal:string) => {
        if(val.trim() !== '' && val !== oldVal) {
            this.props.update({name : val})
        }
    };


    toggleActivePhone = (active:boolean) => {
        this.props.update({active})
    };

    render() {
        const {phone, rights} = this.props;
        const {active, name} = phone;
        const disabled = rights && rights['references.phones'] && !rights['references.phones']['edit'];
        return <>
            <h4>{getText('phones.phone')}: {name}</h4>

            <ToggleSwitch defaultValue={active} send={this.toggleActivePhone} label="common.active" disabled={disabled}/>

            <Input
                blur={this.blurPhoneName}
                label="phones.phone"
                startValue={name}
                disabled={disabled}
            />

        </>
    }

}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(PhoneDetail)