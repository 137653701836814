import React from 'react';
import Tooltip from "../../tooltip/tooltip.controller";
import RenderIf from "../../../../utils/renderIf";
import {ContractCondition, OrderStatus, RegularStatus, ITaskState, Master, getFullName} from "@justpro/terminal";
import {toClassName} from "../../../../utils/icons";
import './icons.css'
import {PRIORITY_MAP, STATES_MAP} from "../../../tasks/task.listItem";

export interface ListIconProps {
    onClick?: (icon: string) => any
    orderStatus?: OrderStatus
    regularStatus?: RegularStatus
    executionTime?: boolean
    waitRequests?: boolean
    hot?: boolean
    expired?: boolean
    filesCount?: string
    photosCount?: string
    offersCount?: string
    tags?: any[]
    contractConditions?: ContractCondition[]
    masters?: Master[]
    checklist?: boolean
    newComments?: boolean
    updated?: boolean
    actNumber?: string
    highpriced?: boolean
    calculating?: boolean
    checked?: boolean
    actScanCount?: number
    isActSuborder?: boolean
    warranty?: boolean
    priority?: number
    problem?: boolean
    state?: ITaskState
    admin?: boolean
    isRegularWorksThisDay?: boolean
    // isActSuborder?           : boolean
}


const ListIcons = ({
                       expired,
                       hot,
                       filesCount,
                       orderStatus,
                       executionTime,
                       offersCount,
                       photosCount,
                       regularStatus,
                       tags,
                       contractConditions,
                       masters,
                       waitRequests,
                       checklist,
                       highpriced,
                       calculating,
                       checked,
                       actNumber,
                       actScanCount,
                       isActSuborder,
                       warranty,
                       priority,
                       state,
                       newComments,
                       updated,
                       problem,
                       admin,
                       isRegularWorksThisDay,
                       onClick
                   }: ListIconProps) => {

    return (
        <>
            <RenderIf condition={state && state.id !== 0}>
                <Tooltip title={`${state ? state.name : ""}`} position="right">
                    <i className={`fa ${state ? STATES_MAP[state.id]?.icon : ""}`}
                       style={{color: state && state.id && STATES_MAP[state.id]?.color}}
                    />
                </Tooltip>
            </RenderIf>

            <RenderIf condition={priority}>
                <Tooltip title={`${priority ? PRIORITY_MAP[priority].name : ""}`} position="right">
                    <i className={`fa ${priority ? PRIORITY_MAP[priority]?.icon : ""}`}
                       style={{color: priority && PRIORITY_MAP[priority]?.color}}
                    />
                </Tooltip>
            </RenderIf>

            {orderStatus && <>
                <Tooltip title={orderStatus.name} position="right">
                    <i className={[toClassName(orderStatus.icon), 'fa-fw'].join(' ')}/>
                </Tooltip>
            </>}

            {tags && tags.length > 0 ? <>
                {tags.map(tag => {
                    return (
                        <Tooltip title={tag.name} position="right" key={tag.id}>
                            <i className={[toClassName(tag.icon), 'fa-fw'].join(' ')}
                               style={{color: tag.color || '#333'}}/>
                        </Tooltip>
                    )
                })}
            </> : null}

            {contractConditions && contractConditions.length > 0 ? <>
                {contractConditions.map(contractCondition => {
                    return (
                        <Tooltip title={contractCondition.name} position="right" key={contractCondition.id}>
                            <i className={[toClassName(contractCondition?.icon), 'fa-fw'].join(' ')}/>
                        </Tooltip>
                    )
                })}
            </> : null}

            {regularStatus && <>
                <Tooltip title={regularStatus.name} position="right">
                    <i className={[toClassName(regularStatus?.icon), 'fa-fw'].join(' ')}/>
                </Tooltip>
            </>}

            <RenderIf condition={newComments}>
                <Tooltip title="UI.itemList.newComments" position="right">
                    <i className="fa fa-fw fa-comment"
                       style={{color: '#b43734'}}
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           onClick?.("newComments")
                       }}
                    />
                </Tooltip>
            </RenderIf>

            <RenderIf condition={updated}>
                <Tooltip title="UI.itemList.taskUpdated" position="right">
                    <i className="fa fa-fw fa-circle"
                       style={{fontSize: '10px', color: '#294d92'}}
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           onClick?.("updated")
                       }}
                    />
                </Tooltip>
            </RenderIf>

            <RenderIf condition={problem}>
                <Tooltip title="UI.itemList.problem" position="right">
                    <i className="fa fa-fw fa-exclamation-circle" style={{color: 'red'}}/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={expired}>
                <Tooltip title="UI.itemList.expiredTime" position="right">
                    <i className="fa fa-fw fa-fire" style={{color: 'red'}}/>
                </Tooltip>
            </RenderIf>


            <RenderIf condition={hot}>
                <Tooltip title="UI.itemList.hot" position="right">
                    <i className="fa fa-fw fa-fire" style={{color: 'orange'}}/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={filesCount && parseInt(filesCount) > 0}>
                <Tooltip title="UI.itemList.file" position="right">
                    <i className="fa fa-file fa-fw"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={photosCount && parseInt(photosCount) > 0}>
                <Tooltip title="UI.itemList.photo" position="right">
                    <i className="fa fa-picture-o fa-fw"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={executionTime}>
                <Tooltip title="UI.itemList.executionTime" position="right">
                    <i className="fa fa-moon fa-fw"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={waitRequests}>
                <Tooltip title="UI.itemList.waitRequest" position="right">
                    <i className="fa fa-clock-o fa-fw"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={offersCount && parseInt(offersCount) > 0}>
                <Tooltip title="UI.itemList.offers" position="right">
                    <i className="fa fa-usd fa-fw"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={checklist}>
                <Tooltip title="UI.itemList.workordersChecklist" position="right">
                    <i className="fa fa-check-circle"/>
                </Tooltip>
            </RenderIf>
            <RenderIf condition={admin}>
                <Tooltip title="workorders.admin" position="right">
                    <i className="fa fa-location-arrow"/>
                </Tooltip>
            </RenderIf>


            {/*<RenderIf condition={actNumber}>*/}
            {/*    <Tooltip title="Эта заявка имеет логическую связь с этим актом, но сама к нему не пренадлежит" position="right">*/}
            {/*        */}
            {/*    </Tooltip>*/}
            {/*</RenderIf>*/}

            <RenderIf condition={highpriced}>
                <Tooltip title="orders.highPriced" position="right">
                    <i className="fa fa-dollar"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={warranty}>
                <Tooltip title="UI.itemList.guarantee" position="right">
                    <i className="fa fa-thumbs-up"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={calculating}>
                <Tooltip title="common.estimate" position="right">
                    <i className="fa fa-calculator"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={checked}>
                <Tooltip title="common.checked" position="right">
                    <i className="fa fa-check"/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={isRegularWorksThisDay}>
                <Tooltip title="acts.isRegularWorksThisDay" position="right">
                    <i className="fa fa-shuttle-van" style={{ color: '#25cc85'}}/>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={actScanCount !== undefined}>
                <Tooltip title="UI.itemList.uploadPageCount" position="right">
                    <b>{actScanCount}</b>
                </Tooltip>
            </RenderIf>

            <RenderIf condition={isActSuborder !== undefined}>
                <Tooltip title="UI.itemList.logicLinkedToAct"
                         position="right">
                    <span className="icon-act-number">{actNumber ? actNumber : <i className="fa fa-link"/>}</span>
                </Tooltip>
            </RenderIf>
            <RenderIf condition={masters?.length}>
                <Tooltip position='right' title={masters?.map?.((m) => getFullName(m.person, false)).join?.(", ")}>
                    <i className='fa fa-calendar-check' style={{ color: '#294d92'}}></i>
                </Tooltip>
            </RenderIf>
        </>
    )
};

export default ListIcons