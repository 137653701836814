import React from "react";
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import Search from "../../UI/search/search.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import checkError from "../../../utils/checkError";
import {getActs, IAct, updateAct} from "@justpro/terminal";
import ItemList from "../../UI/itemsList/itemList.controller";
import ActListItem from "../act/act.listItem";
import {connect} from "react-redux";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal} from '../../../store/modal/modal.types'
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";


interface Props {
    openModal(props: Modal): void

    settings: any
}

interface State {
    isLoading: boolean,

    scansList: IAct[],
    archiveList: IAct[],

    q: string

    loadResent: boolean
}

class ActsScansController extends React.Component<Props, State> {
    interval: any = 0
    state: State = {
        isLoading: false,
        scansList: [],
        archiveList: [],
        q: '',
        loadResent: false
    };

    searchSubmit = async () => {
        const {q} = this.state;

        if (q.trim() !== "") {

            const inScan = this.state.scansList.find(item => item.number === q);
            if (inScan) {
                this.toArchive(inScan);
                this.setState({q: '', isLoading: false})
                return void 0;
            }

            const inArchive = this.state.archiveList.find(item => item.number === q);

            if (inArchive) {
                this.toScan(inArchive);
                this.setState({q: '', isLoading: false})
                return void 0;
            }


            try {
                this.setState({
                    isLoading: true
                });
                const {scansUploadedPhaseId, processingPhaseId, approvingPhaseId, approvedPhaseId} = this.props.settings;
                const {list} = await getActs({
                    number: q,
                    phaseId: [+scansUploadedPhaseId, +processingPhaseId, +approvingPhaseId, +approvedPhaseId]
                });
                this.setState({
                    isLoading: false
                });

                if (list.length > 1) {
                    this.props.openModal({
                        id: 'doubles',
                        title: getText('acts.foundDoubles'),
                        component: () => (
                            <h6>{getText('acts.resolveDoubles.coupleActs')} {q}.{getText('acts.resolveDoubles.revolveThem')}</h6>
                        )
                    });
                    return void 0;
                }
                if (!list.length) {
                    this.props.openModal({
                        id: 'actNotFound',
                        title: getText('common.notFound'),
                        component: () => (
                            <p>{getText('acts.actWithNumber')}
                                <b>{q}</b>. {renderToString(getText('common.notFound')).toUpperCase()}</p>
                        )
                    });
                } else {
                    list.forEach((act: IAct) => {
                        if (act.scanState === null || act.scanState === 1) {
                            this.toArchive(act)
                        }

                        if (act.scanState === 2) {
                            this.toScan(act)
                        }
                    });
                }
                this.setState({q: ''})
            } catch (e) {
                this.setState(() => ({isLoading: false}));

                checkError(e)
            } finally {
                this.setState(() => ({isLoading: false}));
            }
        }
    };

    getActColor = (act: IAct) => {
        return act.scanState === 1 ? '#de1118' :
            act.scanState === 2 ? 'green' : undefined
    };

    renderArchiveAct = (item: IAct) => {
        return <ActListItem
            act={item}
            withAngle={{
                color: this.getActColor(item)
            }}
            withArrow={{
                icon: 'fa fa-arrow-right',
                handler: this.toScan,
                title: "acts.toScan"
            }}
            actScanCount={item.scans?.length}
            withDelete={{
                handler: this.markAsUndefined.bind(this, item),
                title: 'acts.markAsUndefined'
            }}
            isActive={false}/>
    };

    renderScanAct = (item: IAct) => {
        return <ActListItem
            act={item}
            withAngle={{
                color: this.getActColor(item)
            }}
            withArrow={{
                icon: 'fa fa-arrow-left',
                handler: this.toArchive,
                title: "acts.toArchive"
            }}
            isActive={false}
            actScanCount={item.scans?.length}
            withDelete={{
                handler: this.markAsUndefined.bind(this, item),
                title: 'acts.markAsUndefined'
            }}
        />
    };

    markAsUndefined = async (act: IAct) => {
        try {
            const edit = await updateAct(act.id, {scanState: null});

            if (edit) {
                this.setState((prevState) => ({
                    scansList: prevState.scansList.filter(item => item.id !== act.id),
                    archiveList: prevState.archiveList.filter((item) => item.id !== act.id)
                }))
            }

        } catch (e) {
            checkError(e)
        }
    };


    toArchive = async (act: IAct) => {
        console.log('to archive');
        const updated = await updateAct(act.id, {scanState: 2});

        if (updated) {
            this.setState((prevState) => ({
                archiveList: [updated, ...prevState.archiveList],
                scansList: prevState.scansList.filter(item => item.id !== act.id),
                isLoading: false,

            }))
        }


    };

    toScan = async (act: IAct) => {
        const updated = await updateAct(act.id, {scanState: 1});

        if (updated) {
            this.setState((prevState) => ({
                archiveList: prevState.archiveList.filter(item => item.id !== act.id),
                scansList: [updated, ...prevState.scansList],
                isLoading: false,
            }))
        }
    };

    getScans = async () => {
        const {list} = await getActs({scanState: "1"});

        this.setState(() => ({
            scansList: list
        }))


    };

    async componentDidMount() {

        this.getScans()

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    afterChange = (q) => {
        clearInterval(this.interval);
        this.setState({q}, () => {
            this.interval = setInterval(this.searchSubmit, 1000);
        });
    }


    render() {
        const {isLoading} = this.state;
        return (
            <WithPrivateRoute>
                <div className="top-line">
                    <ModuleTextName>{getText('acts.actScans')}</ModuleTextName>

                    <Search
                        submit={this.searchSubmit}
                        loadOnEraser={false}
                        eraser={true}
                        isDisabledBtn={this.state.isLoading}
                        onChange={this.afterChange}
                        q={this.state.q}
                        // autoFocus={true}
                    />
                </div>

                <div className="just-pro_module">
                    <div className="panel"/>
                    <div className="panel"/>
                    <div className="panel content-panel">
                        <ItemList
                            renderItem={this.renderArchiveAct}
                            list={this.state.archiveList}
                            loading={isLoading}
                        />
                    </div>
                    <div className="panel content-panel">
                        <ItemList
                            loading={isLoading}
                            renderItem={this.renderScanAct}
                            list={this.state.scansList}
                        />
                    </div>
                </div>
            </WithPrivateRoute>)
    }
}

const mapState = (state) => ({
    settings: state?.application?.settings
})

const mapDispatchToProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props))
})

export default connect(mapState, mapDispatchToProps)(ActsScansController)