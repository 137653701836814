import React from 'react'
import styled from "styled-components";

export interface ListItem {
    isActive? : boolean
    isFirst? : boolean
    style?: any
    children : React.ReactNode
    onClick?(e:React.MouseEvent) : void
    className? : string
}

export const ListItemView = styled.div<ListItem>`
    display : flex;
    width: 100%;
    border-bottom : ${({isActive}) => {
        return isActive === undefined ? 'none' : !!isActive ? "2px solid #de1118" : '2px solid #c0c0c0';
    }};
    box-sizing: border-box;
    transition: all 0.3s;
    cursor: pointer;
    padding : 5px 5px;
    position : relative;
    margin-top: ${({ isFirst }) => isFirst ? 20 : 0}px;
    margin-bottom : 2px;
    
    &.test {
        font-size : 0.9em;
        color : gray;
        
        &:hover {
            font-size : 1em;
            color : black;
        }
    }    
    
    &:not(:first-child) {
        padding-top: 10px;
    }
    
    &:hover {
        background-color: rgba(192, 192, 192, .1);
    }
    
    & .act-zone {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        margin-right: 5px;
        
        justify-content : space-between;
        
        
        & .text {
            font-weight : bold;
            padding : 5px 0;
            user-select : text;
        }
        
        & .partial {
            padding : 5px 0;
            position : relative;
            
            & label {
                left: 25px;
                position: absolute;
                top: 13px;
            }
        }
    }
    
    & .body {
        display: flex;
        flex-basis: 90%;
        position : relative;
        max-width : 83%;

    }
    
    & .icon-zone {
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        flex-basis: 40px;
        align-items: center;
        padding-top : 3px;
    }
    
    .content-text {
        width : 100%;
    }
    
    & .number {
        flex-basis : 10%;
        display: flex;
        flex-direction: column;
        margin : 5px 5px 15px 5px;
        min-width: 165px;
        white-space: nowrap;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        background-color: #F2F3FA;
        max-height : 60px;
        align-self: center;
        
    }
    
    & .workOrder-zone {
        display : flex;
        align-items: center;
        min-width : 15px;
    }
    
    & .suborders-count {
        position: absolute;
        bottom: 0;
        right : 5px;
        z-index: 500;
    }
    
    
    & .helpers {
        position: absolute;
        // z-index: 999;
        top : 0;
        right : 0;
        display : flex;
        justify-content : center;
         padding : 5px 0;
        
        & > div {
            padding : 0 5px;
            
        }
    }
    
    // & .include {
    //     position: absolute;
    //     z-index: 999;
    //     right: 7px;
    //     top: 1px;
    //     color : white;
    //     background-color : green;
    //     border-radius : 50%;
    //     padding : 1px 2px;
    // }
    //
    // & .close {
    //     position: absolute;
    //     z-index: 999;
    //     right: 0;
    //     top: -3px;
    //     color : red;
    // }
    //
    // .expand {
    //     position: absolute;
    //     z-index: 999;
    //     right: 31px;
    //     top: 1px;
    //     color : black;
    // }
    //
    // .add {
    //     position: absolute;
    //     z-index: 999;
    //     right: 57px;
    //     top: 1px;
    //     color : black;
    // }
    //
    // .arrow {
    //     position: absolute;
    //     z-index: 999;
    //     right: 22px;
    //     top: -5px;
    //     color: black;
    //     padding: 5px;
    // }
`;

export const SubItemWrapper = styled.div`
    position : relative;
    min-height : 100px;
    margin-left : 3em;
`;