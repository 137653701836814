import {PointMap} from './squares.controller'
import withLabel from "../../UI/withLabel/withLabel";
import React from "react";
import {SquarePoint} from "@justpro/terminal";

export function isValidGPS(map:PointMap) {
    let result:boolean = true;
    const points = Object.entries(map);

    points.forEach(([id, item]) => {
        const {
            radius,
            latitude,
            longitude
        } = item;
        // если предыдущие проверки провалились - не продолжать цикл
        if(!result) {
            return false
        }

        // если хоть одно поле указано долны быть указаны все
        if(radius !== '' || latitude !== '' || longitude !== '') {
            result = !!(
                (radius && radius.trim() !== '') &&
                (latitude && latitude.trim() !== '') &&
                (longitude && longitude.trim() !== '')
            )
        }
    });

    return result
}


export function getPointsJSX(
        map:PointMap,
        changeLatitude:(event: React.FormEvent<HTMLInputElement>, id:number) => void,
        changeLongitude:(event: React.FormEvent<HTMLInputElement>, id:number) => void,
        changeRadius:(event: React.FormEvent<HTMLInputElement>, id:number) => void
    ) {
    const points = Object.entries(map);

    return points.map(([id , item]) => {
        return (
            <div key={id}>
                {withLabel(
                    <input
                        onChange={ (e) =>  changeLatitude(e, +id)}
                        className="just-pro-textInput"
                        type="number"
                        id={id + 'lat'}
                    />
                )({
                    text : 'common.latitude',
                    id : id + 'lat'
                })}

                {withLabel(
                    <input
                        onChange={ (e) => changeLongitude(e, +id) }
                        className="just-pro-textInput"
                        type="number"
                        id={id + 'long'}
                    />
                )({
                    text : 'common.longitude',
                    id : id + 'long'
                })}

                {withLabel(
                    <input
                        onChange={ (e) =>  changeRadius(e, +id) }
                        className="just-pro-textInput"
                        type="number"
                        id={id+ 'radius'}
                    />
                )({
                    text : 'common.radius',
                    id : id+ 'radius'
                })}
                <hr/>
            </div>
        )
    })
}

export function getPointsFromMap(map:PointMap):SquarePoint[] {

    const list = Object.entries(map);

    return list.map(item => {
        const {
            id,
            latitude,
            longitude,
            radius
        } = item[1];

        let result:SquarePoint = {
            latitude : +latitude,
            longitude : +longitude,
            radius : +radius
        };

        if(id) {
            result.id = id
        }

        return result
    })
}