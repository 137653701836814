import {Translation} from "react-i18next";
import React from "react";

export default function GetText(key:string, vars?:any) {
    return (<Translation>
        {(t:any, { i18n }:any) => {
            return <>{t(key, vars)}</>
        }}
    </Translation>)
}
