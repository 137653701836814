import React from 'react'
import styled from "styled-components";


type Props = {
    isOpen?: boolean
    onClick? : Function
}

const Caret = styled.div<Props>`
    transform : ${({isOpen}) => isOpen ? 'rotate(180deg)' : null};
    position : relative;
    display: inline-block;
    padding-left : ${({isOpen}) => isOpen ? '5px' : '0'};
    padding-right : ${({isOpen}) => isOpen ? '0' : '5px'};
    cursor : pointer;
    
    
    &::before {
        content: "\\f107";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        padding: 0;
        color: #000;
        vertical-align: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
    }
`;

// const Caret = ({isOpen}:Props) => {
//
//       return <CaretView isOpen={isOpen}/>
// };

export default Caret;