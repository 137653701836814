import {AnyAction} from "redux";
import {OrderStatus, RolesMap} from "@justpro/terminal";

export interface RolesParent {
    name : string,
    id : number
}

export type RolesReducer = {
    map : RolesMap,
    id : number,
    name : string
    newOrderStatus : OrderStatus | null
    parentList : RolesParent[]
    parent?: RolesParent
}

const initialState:RolesReducer = {
    id : -1,
    map : {},
    newOrderStatus : null,
    name : '',
    parentList : []

};

export const rolesTypes = {
    UPDATE_ROLE: 'UPDATE_ROLE',
    DELETE_ROLE : 'DELETE_ROLE',
    CREATE_ROLE : 'CREATE_ROLE',
    GET_ROLE : 'GET_ROLE',
    UPDATE_ROLES_MAP : 'UPDATE_ROLES_MAP'
};


export default (state:RolesReducer = initialState, action: AnyAction) => {
    const {type, ...rest} = action;

    switch (type) {
        case rolesTypes.UPDATE_ROLE :
        case rolesTypes.DELETE_ROLE :
        case rolesTypes.CREATE_ROLE :
        case rolesTypes.GET_ROLE :
        case rolesTypes.UPDATE_ROLES_MAP :
            return {
                ...state,
                ...rest
            };
        default :
            return state;
    }
}