import React from 'react';
import BeforeAfterPhotos from "../../../UI/photos/beforeAfter.controller";
import {addRegularWorkPhotos, getRegularWorkPhotos, deleteRegularWorkPhotos} from '@justpro/terminal'

interface Props {
    regularWorkId : number
}

class RegularWorksPhotos extends React.Component<Props>{
    render() {

        return (
            <BeforeAfterPhotos
                id={this.props.regularWorkId}
                getPhotos={getRegularWorkPhotos}
                deletePhotos={deleteRegularWorkPhotos}
                addPhotos={addRegularWorkPhotos}
                titleBefore="regularWorks.photosBefore"
                titleAfter="regularWorks.photosAfter"
                maxSize={30 * 1024 * 1024}
                video={true}
            />
        )
    }

}

export default RegularWorksPhotos