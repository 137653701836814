import React from 'react';
import {useDrag } from "react-dnd";
const Drag = (props:any) => {
    const {item} = props;

    const [ {isDragging}, drag] = useDrag({
        item : {
            type : item.dropType,
            id : item.id,
            name : item.name ? item.name : ''
        },
        options : {
            dropEffect : 'copy'
        },
        end : (i, monitor) => item.onDragEnd(i, monitor),
        collect : monitor => ({
            isDragging : monitor.isDragging(),
        })
    });
    return (
        <>
        <span ref={drag} style={{opacity : isDragging ? 0.4 : 1}}>
            {props.children}
        </span>
        </>
    )

};

export default Drag;