import React, {useRef, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import checkError from "../../../utils/checkError";

type Props = {
    onChangeText(value: string, oldValue?:string) : void
    text : string
    editable : boolean
}

export interface EditableResponse {
    editable : boolean
    text? : string
}



const EditableText = ({onChangeText, text}:Props) => {
    const ref = useRef<HTMLSpanElement> (null);
    const [editable, setEditable] = useState(false);
    const [prevValue, setPrevValue] = useState('');

    const doubleClickHandler = () => {
        setEditable(true);
    };

    const onBlurHandler = () => {
        if (ref && ref.current) {
            let newValue = ref.current.innerText.trim();

            if(newValue !== '') {
                onChangeText(newValue, prevValue);
            }else{
                checkError(new Error('errors.valueCannotBeEmpty'));
                ref.current.innerText = prevValue;
            }
            setEditable(false)
        }
    };

    const onFocusHandler = () => {
        if(ref?.current) {
            setPrevValue(ref.current.innerText);
        }
    };

    const onKeyPressHandler = (e:React.KeyboardEvent) => {
        if(e.key === 'Enter') {
            onBlurHandler()
        }
    };


    useEffect(() => {
        if(editable) {
            ref?.current?.focus();
        }
    }, [editable]);

    return (
        <span
            ref={ref}
            dangerouslySetInnerHTML={{__html: text}}
            contentEditable={editable}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            onDoubleClick={doubleClickHandler}
            onKeyPress={onKeyPressHandler}
            className={editable ? 'just-pro-editable-text--edit' : 'just-pro-editable-text'}
        />
    )

};

export default EditableText
