import React from "react";
import Select from "../../UI/select/select";
import {getChiefsList, getDepartmentsList} from "../../../utils/functions";
import {LoadReturn, Option} from "../../UI/select/select.types";
import checkError from "../../../utils/checkError";
import {CreateStaffPosition, getPersons, getPositions} from "@justpro/terminal";
import AsyncSelect from "../../UI/select/asyncSelect_v2";
import {getFullName} from "../../../utils/names";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";

interface Props {
    contractorId: number
    create(data:CreateStaffPosition) : void
    hide() : void
}

interface State {
    departmentId? : number
    positionId? : number
    chiefId? : number
    personId? : number
}

class StaffCreate extends React.Component<Props, State>{

    state:State = {};

    isDisabled = (prevState?:State) => {
        const {departmentId, positionId} = prevState ? prevState : this.state;

        return departmentId === undefined || positionId === undefined
    };

    create = async () => {
        if(!this.isDisabled()) {
            const {departmentId, positionId} = this.state;

            await this.props.create({
                departmentId : departmentId as number,
                positionId : positionId as number,
                chiefId : this.state.chiefId,
                personId : this.state.personId
            });

            this.props.hide();
        }
    };

    getPositions = ():LoadReturn => {
        return new Promise(async (resolve) => {
            try{
                const positions = await getPositions({});

                resolve(positions)
            }catch (e) {
                checkError(e)
            }

        })
    };

    changeDepartment = (option:Option) => {
        this.setState(() => ({departmentId : option.value}));

        return Promise.resolve();
    };
    changePosition = (option:Option) => {
        this.setState(() => ({positionId : option.value}));
        return Promise.resolve();

    };
    changeSupervisor = (option:Option) => {
        this.setState(() => ({chiefId : option.value}));
        return Promise.resolve();
    };


    changePerson = (option:Option) => {
        this.setState(() => ({
            personId : option.value
        }));

        return Promise.resolve();
    };

    getPersonsList = async (q:string) => {
        try {
            const persons = await getPersons({q});

            return persons?.map(item => ({
                ...item,
                label : getFullName(item),
                value : item.id
            })) || []

        }catch (e) {
            checkError(e);
            return []
        }
    };

    render () {
        return (
            <>

                <Select
                    label="departments.department"
                    load={getDepartmentsList}
                    change={this.changeDepartment}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    placeholder="UI.select.placeholders.selectDepartment"
                />

                <Select
                    label="positions.position"
                    load={this.getPositions}
                    change={this.changePosition}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    placeholder="UI.select.placeholders.selectPosition"
                />

                <Select
                    label="staff.manager"
                    load={getChiefsList.bind(this, this.props.contractorId)}
                    change={this.changeSupervisor}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    placeholder="UI.select.placeholders.selectManager"
                />

                <AsyncSelect
                    label={{text : "persons.person"}}
                    loadOptions={this.getPersonsList}
                    change={this.changePerson}
                    placeholder="UI.select.placeholders.selectPerson"
                />

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.create')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}


export default StaffCreate