import React from 'react'
import RenderIf from '../../../../utils/renderIf'
import Button from '../../button/button'
import Spinner from '../../spinner/spinner.controller'
import './checklist.css'
import ChecklistFirstStep, { ChecklistObject } from './steps/first'
import SecondStep from './steps/second'
import moment, { Moment } from 'moment'
import { ChangeMultiProps, Option } from '../../select/select.types'
import {
	Checklist,
	ChecklistWorkorder,
	createWorkorderChecklist,
	ModulesResponse,
} from '@justpro/terminal'
import checkError from '../../../../utils/checkError'
import ThirdStep from './steps/third'
import { connect } from 'react-redux'
import { ApplicationMapState } from '../../../application/application.controller'
import HasNoRightsController from '../../hasNoRights/hasNoRights.controller'
import getText from '../../../../localization/getText'
import { ActionTypes } from 'react-select'

interface Props {
	rights?: Partial<ModulesResponse>
}

type Step = 'first' | 'second' | 'third'

interface State {
	isLoading: boolean
	step: Step

	selectedObjects: ChecklistObject[]
	executor?: Option
	coWorkers: Option[]
	date: Moment

	workOrders: ChecklistWorkorder[]
}

class CreateChecklistModal extends React.Component<Props, State> {
	state: State = {
		isLoading: false,
		step: 'first',
		selectedObjects: [],
		date: moment(),
		coWorkers: [],
		workOrders: [],
	}

	onChangeObject = (checklistItem: ChecklistObject) => {
		this.setState((prevState) => {
			const { selectedObjects } = prevState
			const isSet = selectedObjects.find(
				(item) => item.object.id === checklistItem.object.id,
			)

			if (isSet) {
				return {
					...prevState,
					selectedObjects: selectedObjects.filter(
						(item) => item.object.id !== checklistItem.object.id,
					),
				}
			} else {
				return {
					...prevState,
					selectedObjects: [...selectedObjects, checklistItem],
				}
			}
		})
	}

	getSecondStep = () => {
		this.setState(() => ({ step: 'second' }))
	}

	changeCoExecutors = async (option: Option, action?: ActionTypes) => {
		switch (action) {
			case 'select-option':
				await this.setState((prevState) => ({
					coWorkers: [...prevState.coWorkers, option],
				}))
				return true
			case 'remove-value':
				await this.setState((prevState) => ({
					coWorkers: prevState.coWorkers.filter(
						(item) => item.value !== option.value.value,
					),
				}))

				return true
		}

		return false
	}

	changeExecutor = (option: Option) => {
		this.setState(() => ({ executor: option }))
		return Promise.resolve()
	}

	changeDate = (date: Moment) => {
		this.setState(() => ({ date }))
	}

	isDisabledNextButton = () =>
		this.state.executor === undefined ||
		this.state.selectedObjects.length === 0 ||
		this.state.isLoading

	isDisabled = () =>
		this.state.step === 'first' || this.isDisabledNextButton()

	createWorkOrders = async (data: Checklist[]) => {
		try {
			this.setState({
				isLoading: true,
			})
			const checklistWorkorders = await createWorkorderChecklist({
				checklists: data,
			})

			this.setState(() => ({
				workOrders: checklistWorkorders,
				step: 'third',
			}))
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				isLoading: false,
			})
		}

		console.log({ data })
	}

	render() {
		const { step } = this.state
		const { rights } = this.props

		const canCreate =
			rights && rights['workorders'] && rights['workorders']['create']

		//                isOpen={this.props.isOpen}
		//                 size="large"
		//                 title={step === 'third' ? "Просмотр созданых нарядов" : "Создание чеклиста"}
		//                 hide={this.props.hideModal}
		//                 withScroll={true}
		//                 showButtons={false}

		return (
			<>
				{canCreate && (
					<div className='createChecklist'>
						<div className='createChecklist__content'>
							<RenderIf condition={step === 'first'}>
								<ChecklistFirstStep
									onChangeObject={this.onChangeObject}
									selectedObjects={this.state.selectedObjects}
									changeCoExecutors={this.changeCoExecutors}
									coworkers={this.state.coWorkers}
									changeExecutor={this.changeExecutor}
									changeDate={this.changeDate}
									date={this.state.date}
								/>
							</RenderIf>

							<RenderIf condition={step === 'second'}>
								<>
									<SecondStep
										objects={this.state.selectedObjects}
										defaultExecutor={
											this.state.executor as Option
										}
										defaultDate={this.state.date}
										defaultCoWorkers={this.state.coWorkers}
										createWorkOrders={this.createWorkOrders}
										disabled={this.state.isLoading}
									/>
								</>
							</RenderIf>

							<RenderIf condition={step === 'third'}>
								<ThirdStep workOrders={this.state.workOrders} />
							</RenderIf>
						</div>
						<RenderIf condition={step === 'first'}>
							<div className='createChecklist__buttons'>
								<Button
									className='btn-success'
									onClick={this.getSecondStep}
									disabled={this.isDisabledNextButton()}
								>
									{getText('common.next')}
								</Button>
							</div>
						</RenderIf>

						<Spinner loading={this.state.isLoading} />
					</div>
				)}

				{!canCreate && <HasNoRightsController />}
			</>
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(CreateChecklistModal)
