import React from 'react'
import Caret from "../../../UI/caret/caret";

type countryRest = {
    isOpen : boolean
    checked : number
    name : string
}
type Country = [ string, countryRest ]

const GetGeographic = ({propsElements, onChangeElement, toggleIsOpenElement, getView}) => {
    const countries: Country[] = Object.entries(propsElements);

    return countries.map(([innerId, innerRest]) => {
        const id = +innerId;
        const {isOpen, checked, name} = innerRest;
        let check = false;
        let cls = [];

        if(checked === 2) {
            cls.push('indeterminate')
        }else{
            check = !!checked;
        }

        return (
            <div className="contracts-modal_row" key={'country ' + id}>
                <span className="contracts-modal_button">
                    <input
                        type="checkbox"
                        className={cls.join(' ')}
                        checked={check}
                        onChange={onChangeElement(id, innerRest)}
                    />
                </span>

                {name}
                <span className="contracts-modal_button" onClick={toggleIsOpenElement(id)}> <Caret isOpen={innerRest.isOpen} /> </span>

                {isOpen && getView(id)}
            </div>
        )
    });
};

export default GetGeographic