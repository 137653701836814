import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import TextArea from "../../UI/textArea/textArea.controller";
import {CreateWaitRequest} from "@justpro/terminal";
import getText from "../../../localization/getText";


type Props = {
    create(data:CreateWaitRequest) : void
};
type State = {
    name: string
    message? : string
};

export default class WaitRequestCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
    };


    add = () => {
        const data = {
            ...this.state
        };

        this.props.create(data);
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    onChangeMessage = (message: string) => {
        this.setState(() => ({message}))
    };

    isDisabled = () => this.state.name.trim() === '';

    render () {
        return (
            <>
                <h3>{getText('waitRequests.addingNewHeader')}</h3>

                <Input
                    label="waitRequests.waitRequest"
                    change={this.onChangeName}
                />

                <TextArea
                    label={{text : "common.message" }}
                    change={this.onChangeMessage}
                />

                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }

}