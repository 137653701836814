import React from "react";
import {Angle} from "../itemsList/card/card.view";
import './infoBlock.css'

interface Props {
    children : React.ReactNode
}

const InfoBlock = (props:Props) => {
    return (
        <div className="info-block bdt">
            <Angle size={60}/>
            {props.children}
        </div>
    )
};

export default InfoBlock