import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {CreateFuel} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateFuel) : void
}

interface State {
    name : string
}

class MessengerCreate extends React.Component<Props, State>{

    state:State = {
        name : ''
    };

    onChangeCountryName = (name:string) => {
        this.setState(() => ({name}))
    };

    create = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name})
        }
    };

    isDisabled = () => this.state.name.trim() === '';

    render() {

        return (<>
            <h4>{getText('messengerTypes.addingNewHeader')}</h4>

            <Input
                placeholder="messengerTypes.messengerType"
                change={this.onChangeCountryName}
                label="messengerTypes.messengerType"
                startValue=""
            />

            <Button className="btn-success" disabled={this.isDisabled()} onClick={this.create}>{getText('common.add')}</Button>

        </>)
    }

}


export default MessengerCreate