import { initialState } from "./map.types";
import { AnyAction } from "redux";

export const mapActions = {
    SET_TOUCHED: 'SET_TOUCHED',
    SET_IS_WO_OPEN: 'SET_IS_WO_OPEN',
    SET_OPENED_OBJECT: 'SET_OPENED_OBJECT',
    SET_WO_ARRAY: 'SET_WO_ARRAY',
    SET_BP_ARRAY: 'SET_BP_ARRAY',
    SET_ARRANGED_WO_ARRAY: 'SET_ARRANGED_WO_ARRAY',
    PUSH_TO_WO_ARRAY: 'PUSH_TO_WO_ARRAY',
    REMOVE_FROM_WO_ARRAY: 'REMOVE_FROM_WO_ARRAY',
    SET_CURRENT_EXECUTOR: 'SET_CURRENT_EXECUTOR',
    TOGGLE_MODE: 'TOGGLE_MODE',
    TOGGLE_MODE_BUILD_ROUTES: 'TOGGLE_MODE_BUILD_ROUTES',
    SET_BP_DIRECTIONS: 'SET_BP_DIRECTIONS',
    SET_SELECTED_POINT_ID: 'SET_SELECTED_POINT_ID',
};

export default (state: any = initialState, action: AnyAction) => {
    switch (action.type) {
        case mapActions.SET_TOUCHED:
            return { ...state, touched: action.touched };
        case mapActions.SET_IS_WO_OPEN:
            return { ...state, isWoCreatorOpen: action.isWoCreatorOpen };
        case mapActions.SET_OPENED_OBJECT:
            return { ...state, openedObject: action.object };
        case mapActions.SET_WO_ARRAY:
            return { ...state, woArray: action.array };
        case mapActions.SET_BP_ARRAY:
            return { ...state, bpArray: action.array };
        case mapActions.SET_BP_DIRECTIONS:
            return { ...state, bpDirections: action.directions };
        case mapActions.SET_ARRANGED_WO_ARRAY:
            return { ...state, arrangedWoArray: action.arrangedWoArray };
        case mapActions.PUSH_TO_WO_ARRAY:
            return { ...state, woArray: [...state.woArray, action.el] };
        case mapActions.REMOVE_FROM_WO_ARRAY:
            return { ...state, woArray: state.woArray.filter((_, i) => i != action.index) };
        case mapActions.SET_CURRENT_EXECUTOR:
            return { ...state, currentExecutor: action.executor };
        case mapActions.TOGGLE_MODE:
            return { ...state, mode: action.mode };
        case mapActions.TOGGLE_MODE_BUILD_ROUTES:
            return { ...state, buildRoutesMode: action.mode };
        case mapActions.SET_SELECTED_POINT_ID:
            return { ...state, selectedPointId: action.id };
        default:
            return state;
    }
}