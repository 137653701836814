import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './localization/locales/ru/translation.json'
import uk from './localization/locales/uk/translation.json'

const resources = {
    ru,
    uk
};

i18next
    .use(initReactI18next)
    .init({
        lng: 'uk',
        fallbackLng: 'uk',
        // debug: true,

        interpolation : {
            escapeValue : false,
        },

        resources
    });


export default i18next