import React from 'react';
import {ModulesResponse, getPersons, GlobalSetting, GLOBAL_SETTINGS_TYPES} from '@justpro/terminal';
import {connect} from 'react-redux';
import NumberInput from "../../UI/input/number";
import Input from "../../UI/input/text";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import RenderIf from '../../../utils/renderIf';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import AsyncSelect from "../../UI/select/asyncSelect_v2";
import {LoadReturn} from "../../UI/select/select.types";
import {getUniqueList} from "../../../utils/getUniqueArray";
import {getFullName} from "../../../utils/names";
import checkError from "../../../utils/checkError";

interface Props {
    setting: GlobalSetting
    settings: any

    update(data: string): void

    rights?: Partial<ModulesResponse>
}

class GlobalSettingDetail extends React.Component<Props> {
    onBlurNumber = async (value: number) => {
        const {setting} = this.props;
        if (value.toString() !== setting.value) {
            this.props.update(value.toString());
        }
    };

    onBlurString = async (value: string, oldValue: string) => {
        if (value.trim() !== oldValue && value.trim() !== '') {
            this.props.update(value);
        }
    };

    updateBoolean = async (value) => {
        this.props.update((+value).toString());
    }

    onChangePersonId = async ({value}) => {
        this.props.update(value.toString());
    }

    getPersonsList = (q: string = "", exists?: any[]): LoadReturn => {
        const {settings} = this.props;
        return new Promise(async (resolve, reject) => {
            try {
                const persons = await getPersons({q, contractorsId: [+settings?.tfmContractorId]});
                const unique = getUniqueList(persons, exists, {wholeId: 'id', existId: 'personId'});
                const result = unique.map(item => {
                    return {
                        ...item,
                        label: getFullName(item),
                        value: item.id
                    }
                });
                resolve(result);
            } catch (e) {
                checkError(e)
                resolve([])
            }

        })
    };

    private get preparePersonValue(): any{
        const {setting} = this.props;
        const {value, type} = setting;
        if(type !== GLOBAL_SETTINGS_TYPES.SELECT_PERSON_ID){
            return value;
        }
        const person = JSON.parse(value);
        return {
            id: person.id,
            name: getFullName(person)
        }
    }

    render() {
        const {rights, setting} = this.props;
        const {pseudoname, value, type} = setting;
        const disabled =
            rights && rights['references.globalSettings'] && !rights['references.globalSettings']['edit'];
        return (
            <>
                <h3>
                    {renderToString(getText('globalSettings.settingName'))}: {pseudoname}
                </h3>
                <div className="global-settings__input-wrapper">
                    <RenderIf condition={type === GLOBAL_SETTINGS_TYPES.SELECT_PERSON_ID}>
                        <AsyncSelect
                            change={this.onChangePersonId}
                            loadOptions={this.getPersonsList}
                            values={[this.preparePersonValue]}
                            normalizeParams={{
                                label: 'name',
                                value: 'id'
                            }}
                            disabled={disabled}
                            label={{text: renderToString(getText('globalSettings.value'))}}
                            placeholder="UI.select.placeholders.selectExecutor"
                        />
                    </RenderIf>
                    <RenderIf condition={type === GLOBAL_SETTINGS_TYPES.NUMBER}>
                        <NumberInput
                            blur={this.onBlurNumber}
                            defaultValue={value}
                            label={renderToString(getText('globalSettings.value'))}
                            disabled={disabled}
                        />
                    </RenderIf>
                    <RenderIf condition={type === GLOBAL_SETTINGS_TYPES.STRING}>
                        <Input
                            blur={this.onBlurString}
                            startValue={value}
                            label={renderToString(getText('globalSettings.value'))}
                            disabled={disabled}
                        />
                    </RenderIf>
                </div>
                <RenderIf condition={type === GLOBAL_SETTINGS_TYPES.BOOLEAN}>
                    <ToggleSwitch send={this.updateBoolean}
                                  label={renderToString(getText('globalSettings.value'))}
                                  defaultValue={Boolean(+value)}
                                  disabled={disabled}
                                  position='left'
                    />
                </RenderIf>
            </>
        );
    }
}

const mapState = (state: any) => ({
    rights: state.application.rights,
    settings: state.application.settings
});
export default connect(mapState)(GlobalSettingDetail);
