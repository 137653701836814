import React, {useEffect, useRef, Component} from 'react'
import Caret from "../../UI/caret/caret";
import {getObjectName, Object, ContractObject} from '@justpro/terminal'
import RenderIf from "../../../utils/renderIf";
import getText from "../../../localization/getText";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import {ModalBodyProps} from "../../../store/modal/modal.types";
import "./workGroups.css";
import Input from "../../UI/input/text";

type element = any

interface IObjectGroupProps {
    type: string
    element: element
    update: any
}

interface IObjectGroupState{
    isElementOpen: boolean
    childrenToRender: any[]
    childType: string
}

export class ObjectGroup extends Component<IObjectGroupProps, IObjectGroupState>{
    state = {
        isElementOpen: false,
        childrenToRender: [],
        childType: ''
    }

    componentDidMount(){
        this.defineChildren();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props) !== JSON.stringify(prevProps)){
            this.defineChildren();
        }
    }

    getObjects = (type, e) => {
        let objects = [];
        switch (type) {
            case 'regions':
                objects = e.squares
                ?.map(square => square.objects)?.flat()
                break
            case 'squares':
                objects = e.objects
                break
            case 'object':
                objects = [e];
                break
        }
        return objects;
    }

    setChildType = (childType) => {
        return this.setState({
            childType
        })
    }

    setChildrenToRender = (childrenToRender) => {
        return this.setState({
            childrenToRender
        })
    }

    toggleElementOpen = () => {
        this.setState((prevState) => ({
            isElementOpen: !prevState.isElementOpen
        }))
    }

    defineChildren = () => {
        const {type, element} = this.props;
        switch (type) {
            case 'regions':
                this.setChildType('squares')
                this.setChildrenToRender(element.squares)
                break
            case 'squares':
                this.setChildType('object')
                this.setChildrenToRender(element.objects)
                break
            default:
                return;
        }
    }

    onChangeChecked = (event) => {
        const {type, element, update} = this.props;
        const allObjects = this.getObjects(type, element);
        return update({
            objectsId: allObjects?.map((o) => o.objectId),
            checked: event.target.checked
        });
    }

    render(){
        const {type, element, update} = this.props;
        const {isElementOpen, childrenToRender, childType} = this.state;
        const allObjects = this.getObjects(type, element);
        return (
            <div className="contracts-modal_row" key={'region_' + element.id}>

                <RenderIf condition={type === 'object'}>
                 <span className="contracts-modal_button">
                    <input checked={!!element?.checked}
                           type="checkbox"
                           onChange={this.onChangeChecked}
                    />
                </span>
                    <span>{getObjectName(element)}</span>
                </RenderIf>
                <RenderIf condition={type !== 'object'}>
                    <>
                        <span className="contracts-modal_button">
                        <input checked={allObjects.every((obj) => obj.checked)}
                               type="checkbox"
                               onChange={this.onChangeChecked}
                        />
                    </span>
                        <span className="just-pro__checkbox-list-header">
                        {element.name}
                            &nbsp;
                            <span
                                className="contracts-modal_badge badge">{allObjects?.filter((obj) => obj.checked)?.length}</span>
                        </span>
                        <span className="contracts-modal_button" onClick={this.toggleElementOpen}>
                        <Caret isOpen={isElementOpen}/>
                    </span>
                    </>
                </RenderIf>

                {isElementOpen &&
                childrenToRender?.map(child => {
                    return <ObjectGroup
                        type={childType}
                        element={child}
                        update={update}
                    />
                })
                }
            </div>
        )
    }
}

export interface ObjectCheckbox extends ContractObject {
    checked: boolean
}

interface Props extends ModalBodyProps {
    objects: Object[]
    contractObjects: Object[]
    onAccept: (objectsId: number[]) => any
}

interface State {
    contractObjects: ObjectCheckbox[]
    search: string
}

class ObjectSelectModal extends Component <Props, State> {
    state = {
        contractObjects: this.props.contractObjects as ObjectCheckbox[],
        search: ''
    };

    componentDidUpdate(nextProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            this.setState({
                contractObjects: nextProps.contractObjects
            })
        }
    }

    onAccept = () => {
        this.props.onAccept(
            this.state.contractObjects
                .filter((co) => co.checked)
                .map((co) => co.objectId)
        );
        this.props.hide();
    }

    updateObject = ({objectsId, checked}) => {
        this.setState((prevState) => {
            return {
                contractObjects: prevState.contractObjects?.map((obj: any) => {
                    if (objectsId.includes(obj.objectId)) {
                        obj.checked = checked;
                    }
                    return obj;
                })
            }
        })
    }

    onChangeSearch = (search) => {
        this.setState({
            search
        })
    }

    render() {
        const {hide, contractObjects} = this.props;
        const sortedRegions = contractObjects?.filter((obj) => {
            return getObjectName(obj).toLowerCase().includes(this.state.search.toLowerCase());
        })?.reduce((regionsList, currentObject: any) => {
            const currentRegion = regionsList?.find(region => region.id == currentObject?.square?.region?.id)
            const currentSquare = currentRegion?.squares?.find(square => square.id == currentObject?.square?.id)
            if (!currentRegion) {
                regionsList.push({
                    name: currentObject.square.region.name,
                    id: currentObject.square.region.id,
                    squares: [{
                        name: currentObject.square.name,
                        id: currentObject.square.id,
                        objects: [currentObject]
                    }]
                })
            }
            if (currentRegion && !currentSquare) {
                currentRegion.squares.push({
                    name: currentObject.square.name,
                    id: currentObject.square.id,
                    objects: [currentObject]
                })
            }
            if (currentSquare) {
                currentSquare.objects.push(currentObject)
            }
            return regionsList
        }, []);
        return (
            <div>
                <div className="workgroup__modal_inner">
                    <Input startValue={this.state.search} change={this.onChangeSearch} placeholder={"workGroups.enterSearch"}/>
                    {sortedRegions?.map(region => (
                        <ObjectGroup
                            type='regions'
                            element={region}
                            update={this.updateObject}
                        />
                    ))}
                </div>
                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success workgroup__btn-success"
                            onClick={this.onAccept}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </div>
        );
    }
}

export default ObjectSelectModal
