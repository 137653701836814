import React from 'react';
import {createPerson, getPersons, ModulesResponse, Person} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import PersonController from "./person.controller";
import PersonCreate from "./person.create";
import {getFullName} from "../../../utils/names";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class Persons extends React.Component<Props> {

    renderItem = (item:Person) => {
        return <>{getFullName(item)}</>
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.persons'];
        return <BaseReferenceController
            Detail={PersonController}
            create={{
                handler : createPerson,
                Component : PersonCreate,
                title : 'persons.addItem'
            }}
            referenceName="persons.moduleName"
            getList={getPersons}
            listItem={{
                render: this.renderItem
            }}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})


export default connect(mapState)(Persons)