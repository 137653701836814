import React from 'react'
import Input from '../../UI/input/text'
import {
	UpdatePerson,
	Person,
	ModulesResponse,
	addPerson1sIds,
	deletePerson1sIds,
    Master,
    CreateMaster,
    addMasterArea,
    deleteMasterArea,
    addMasterCity,
    deleteMasterCity,
    addMasterEquipmentCategory,
    deleteMasterEquipmentCategory,
    addMasterSpecialization,
    deleteMasterSpecialization,
    getFullName,
} from '@justpro/terminal'
import '../persons/persons.css'
import { ChangeMultiProps, Option } from '../../UI/select/select.types'
import RenderIf from '../../../utils/renderIf'
import PersonAvatar from '../persons/person.avatar'
import PersonInitiator from '../persons/person.initiator'
import { getAreasList, getCitiesList, getEquipmentCategoriesList, getPersons1SList, getPersonsList, getSpecializationsList, getStores1SList } from '../../../utils/functions'
import PersonExecutor from '../persons/person.executor'
import PersonTransport from '../persons/person.transport'
import PersonBasePoint from '../persons/person.basepoints'
import AsyncSelect from '../../UI/select/asyncSelect'
import MultiSelectAsync from '../../UI/select/asyncSelect_v2'
import MultiSelect from '../../UI/select/multiSelect';
import { ApplicationMapState } from '../../application/application.controller'
import { connect } from 'react-redux'
import getText from '../../../localization/getText'

import Contacts from '../persons/contacts/person.contacts'
import types from '../persons/contacts/typesData.json'
import {
	addContactData,
	updateContactData,
	deleteContactData,
} from '../persons/contacts/contactHandlers'
import { PHONE_TYPE, EMAIL_TYPE, MESSENGER_TYPE } from '../persons/contacts/contactTypes'
import ToggleSwitch from '../../UI/toggleSwitch/toggleSwitch.controller'
import checkError from '../../../utils/checkError'
import TextArea from '../../UI/textArea/textArea.controller'
import withLabel from '../../UI/withLabel/withLabel'
import Select from '../../UI/select/select'
import NumberInput from '../../UI/input/number'
import PaymentCardsTable from './paymentCards.controller'

type Props = {
	master: Master
	updateMaster(data: Partial<CreateMaster>): void
	updatePerson(data: Partial<UpdatePerson>): void
	rights?: Partial<ModulesResponse>
	getMaster?: () => void
}

type State = {
	master?: Master
}

class MasterDetail extends React.Component<Props, State> {
	state: State = {}
	onUpdateWorkDayPush = (workDayPush) => {
		if (workDayPush !== this.props.master?.person.workDayPush) {
			this.props.updatePerson({
				workDayPush,
			})
		}
	}

	onBlurName = (firstName: string, oldValue: string) => {
		if (firstName !== oldValue) {
			this.props.updatePerson({ firstName })
		}
	}

	onBlurLastName = (lastName: string, oldValue: string) => {
		if (lastName !== oldValue) {
			this.props.updatePerson({ lastName })
		}
	}

	onBlurPatronymic = (patronymic: string, oldValue: string) => {
		if (patronymic !== oldValue) {
			this.props.updatePerson({ patronymic })
		}
	}

	onBlurPhone = (phone: string, oldValue: string) => {
		if (phone !== oldValue) {
			//@ts-ignore
			this.props.updatePerson({ phone })
		}
	}

	onBlurEmail = (email: string, oldValue: string) => {
		if (email !== oldValue) {
			//@ts-ignore
			this.props.updatePerson({ email })
		}
	}

	onBlurMessenger = (messenger: string, oldValue: string) => {
		if (messenger !== oldValue) {
			//@ts-ignore
			this.props.updatePerson({ messenger })
		}
	}

	changePersonStore = async (option: Option) => {
		return this.props.updatePerson({ store1sId: option.value })
	}

	componentDidMount() {
		this.setState({ master: this.props.master })
	}
	componentDidUpdate(prevProps) {
		if (prevProps.master !== this.props.master) {
			this.setState({ master: this.props.master })
		}
	}

	changePerson1s = async (option: Option, action: any) => {
		try {
			switch (action) {
				case 'select-option':
					const updatedPerson = await addPerson1sIds(
						this.props.master?.person.id,
						option.value,
					)
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            person: {
                                ...prevState.master.person,
                                person1s: updatedPerson.person1s,
                            },
                        }
					}))
					return
				case 'remove-value':
					const result = await deletePerson1sIds(
						this.props.master?.person.id,
						option.value,
					)
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            person: {
                                ...prevState.master.person,
                                person1s: result.person1s,
                            },
                        }
					}))
					return
				default:
					return
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

    selectPerson = async (option) => {
        if (this.state.master.person.id !== option.value) {
			this.props.updateMaster({ personId: option.value })
		}
    }

    onBlurPriority = (priority: number, oldValue: number) => {
		if (priority !== oldValue) {
			this.props.updateMaster({ priority })
		}
	}

    onBlurDescription = (description: string, oldValue: string) => {
		if (description !== oldValue) {
			this.props.updateMaster({ description })
		}
	}

	changeActive = (active: boolean) => {
		if (active !== this.state.master.active) {
			//@ts-ignore
			this.props.updateMaster({ active })
		}
	}

    changeInStaff = (inStaff: boolean) => {
		if (inStaff !== this.state.master.inStaff) {
			//@ts-ignore
			this.props.updateMaster({ inStaff })
		}
	}

    changeHasStore = (hasStore: boolean) => {
		if (hasStore !== this.state.master.hasStore) {
			this.props.updateMaster({ hasStore })
		}
	}

    changeAreas = async (props: ChangeMultiProps) => {
		try {
			switch (props.type) {
				case 'select-option':
					const areaResponse = await addMasterArea({
						masterId: this.props.master?.id,
						areaId: props.value.value,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            areas: [...prevState.master.areas, areaResponse]
                        }
					}))
					return true
				case 'remove-value':
					await deleteMasterArea({
						masterId: this.props.master?.id,
						areaId: props.value.areaId,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            areas: [...prevState.master.areas.filter(({ areaId }) => areaId !== props.value.areaId)]
                        }
					}))
					return true
				default:
					return false
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

    changeCities = async (props: ChangeMultiProps) => {
		try {
			switch (props.type) {
				case 'select-option':
					const cityResponse = await addMasterCity({
						masterId: this.props.master?.id,
						cityId: props.value.value,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            cities: [...prevState.master.cities, cityResponse]
                        }
					}))
					return true
				case 'remove-value':
					await deleteMasterCity({
						masterId: this.props.master?.id,
						cityId: props.value.cityId,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            cities: [...prevState.master.cities.filter(({ cityId }) => cityId !== props.value.cityId)]
                        }
					}))
					return true
				default:
					return false
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

    changeEquipmentCategories = async (props: ChangeMultiProps) => {
		try {
			switch (props.type) {
				case 'select-option':
					const eqcResponse = await addMasterEquipmentCategory({
						masterId: this.props.master?.id,
						equipmentCategoryId: props.value.value,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            equipmentCategories: [...prevState.master.equipmentCategories, eqcResponse]
                        }
					}))
					return true
				case 'remove-value':
					await deleteMasterEquipmentCategory({
						masterId: this.props.master?.id,
						equipmentCategoryId: props.value.equipmentCategoryId,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            equipmentCategories: [...prevState.master.equipmentCategories.filter(({ equipmentCategoryId }) => equipmentCategoryId !== props.value.equipmentCategoryId)]
                        }
					}))
					return true
				default:
					return false
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

    changeSpecializations = async (props: ChangeMultiProps) => {
		try {
			switch (props.type) {
				case 'select-option':
					const specializationResponse = await addMasterSpecialization({
						masterId: this.props.master?.id,
						specializationId: props.value.value,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            specializations: [...prevState.master.specializations, specializationResponse]
                        }
					}))
					return true
				case 'remove-value':
					await deleteMasterSpecialization({
						masterId: this.props.master?.id,
						specializationId: props.value.specializationId,
                    })
					this.setState((prevState) => ({
                        master: {
                            ...prevState.master,
                            specialization: [...prevState.master.specializations.filter(({ specializationId }) => specializationId !== props.value.specializationId)]
                        }
					}))
					return true
				default:
					return false
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

	render() {
		const { rights } = this.props
		const rule = rights && rights['references.masters']

		const disabled = rule && !rule['edit']

		return (
			<>
				<h3>
					{getText('masters.master')}: {this.state.master?.person?.lastName}{' '}
					{this.state.master?.person?.firstName}{' '}
					{this.state.master?.person?.patronymic
						? this.state.master?.person?.patronymic
						: ''}
				</h3>

                <div className="masters__select-wrapper">
                    <Select
                        change={this.selectPerson}
                        label="persons.person"
                        placeholder="UI.select.placeholders.selectPerson"
                        defaultValue={{ 
                            name: this.state?.master?.person && getFullName(this.state?.master?.person),
                            value: this?.state?.master?.person?.id,
                        }}
                        load={getPersonsList}
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                    />
                </div>
				<ToggleSwitch label="masters.actual"
                    send={this.changeActive}
                    defaultValue={this.state?.master?.active}
                />
                <ToggleSwitch label="masters.inStaff"
                    send={this.changeInStaff}
                    defaultValue={this.state?.master?.inStaff}
                />
                <ToggleSwitch label="masters.hasStore"
                    send={this.changeHasStore}
                    defaultValue={this.state.master?.hasStore}
                />
                <div className="masters__select-wrapper">
                    <NumberInput
                        blur={this.onBlurPriority}
                        label="common.priority"
                        defaultValue={this.state?.master?.priority}
                    />
                </div>
                <div className="masters__select-wrapper">
                    <MultiSelect
                        change={this.changeAreas}
                        placeholder="UI.select.placeholders.selectAreas"
                        load={getAreasList}
                        label="masters.areas"
                        defaultValues={this.state?.master?.areas}
                        accessors={{
                            id: 'value',
                            name: 'label',
                        }}
                    />
                </div>
                <div className="masters__select-wrapper">
                    <MultiSelect
                        change={this.changeCities}
                        placeholder="UI.select.placeholders.selectCities"
                        load={getCitiesList}
                        label="masters.cities"
                        defaultValues={this.state?.master?.cities}
                        accessors={{
                            id: 'value',
                            name: 'label',
                        }}
                    />
                </div>
                <div className="masters__select-wrapper">
                    <MultiSelect
                        change={this.changeEquipmentCategories}
                        placeholder="UI.select.placeholders.selectEquipmentCategories"
                        load={getEquipmentCategoriesList}
                        label="masters.equipmentCategories"
                        defaultValues={this.state?.master?.equipmentCategories}
                        accessors={{
                            id: 'value',
                            name: 'label',
                        }}
                    />
                </div>
                <div className="masters__select-wrapper">
                    <MultiSelect
                        change={this.changeSpecializations}
                        placeholder="UI.select.placeholders.selectSpecializations"
                        load={getSpecializationsList}
                        label="masters.specializations"
                        defaultValues={this.state?.master?.specializations}
                        accessors={{
                            id: 'value',
                            name: 'label',
                        }}
                    />
                </div>
                <div className="masters__select-wrapper">
                    {withLabel(<PaymentCardsTable 
                        getMaster={this.props.getMaster}
                        masterId={this.state?.master?.id} 
                        paymentCards={this.state?.master?.paymentCards} 
                    />)({
                        text: "masters.paymentCards",
                    })}
                </div>
                <div className="masters__select-wrapper">
                    {withLabel(<TextArea
                        placeholder="masters.note"
                        blur={this.onBlurDescription}
                        startValue={this.state.master?.description}
                        rows={6}
                    />)({
                        text: "masters.note",
                    })}
                </div>

                <h3>
					{getText('persons.person')}: {this.state.master?.person?.lastName}{' '}
					{this.state.master?.person?.firstName}{' '}
					{this.state.master?.person?.patronymic
						? this.state.master?.person?.patronymic
						: ''}
				</h3>

				<PersonAvatar
					avatar={this.props?.master?.person?.avatar}
					personId={this.state?.master?.person?.id}
				/>

				<Input
					blur={this.onBlurName}
					label='persons.name'
					startValue={this.state.master?.person?.firstName}
					disabled={disabled}
				/>

				<Input
					blur={this.onBlurLastName}
					label='persons.lastName'
					startValue={this.state.master?.person?.lastName}
					disabled={disabled}
				/>

				<Input
					blur={this.onBlurPatronymic}
					label='persons.patronymic'
					startValue={this.state.master?.person?.patronymic}
					disabled={disabled}
				/>

				<MultiSelectAsync
					key={this.state?.master?.person?.id}
					label={{ text: 'persons.person1s' }}
					placeholder='persons.person1s'
					loadOptions={getPersons1SList}
					normalizeParams={{
						label: 'name',
						value: 'id1s',
					}}
					change={this.changePerson1s}
					values={this.state.master?.person?.person1s}
					isMulti
				/>

				<AsyncSelect
					change={this.changePersonStore}
					loadOptions={getStores1SList}
					label={{
						text: 'persons.storage1s',
					}}
					isDisabled={disabled}
					defaultValue={{
						value: this.state.master?.person?.store1s
							? this.state.master?.person?.store1s
							: {},
						accessors: { name: 'label', id1s: 'value' },
					}}
				/>

				<ToggleSwitch
					send={this.onUpdateWorkDayPush}
					defaultValue={this.state.master?.person?.workDayPush}
					label='persons.workDayPush'
					disabled={disabled}
				/>

				<Contacts
					contacts={this.state.master?.person?.phones}
					types={types.phoneTypes}
					contact={PHONE_TYPE}
					addContact={() =>
						addContactData(
							this.state?.master?.person?.id,
							PHONE_TYPE,
							this.props.getMaster,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state?.master?.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getMaster,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state?.master?.person?.id,
							this.props.getMaster,
							contact,
							contactId,
						)
					}
				/>
				<Contacts
					contacts={this.state.master?.person?.emails}
					types={types.emailTypes}
					contact={EMAIL_TYPE}
					addContact={() =>
						addContactData(
							this.state?.master?.person?.id,
							EMAIL_TYPE,
							this.props.getMaster,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state?.master?.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getMaster,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state?.master?.person?.id,
							this.props.getMaster,
							contact,
							contactId,
						)
					}
				/>
				<Contacts
					contacts={this.state.master?.person?.messengers}
					types={types.messengerTypes}
					contact={MESSENGER_TYPE}
					addContact={() =>
						addContactData(
							this.state?.master?.person?.id,
							MESSENGER_TYPE,
							this.props.getMaster,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state?.master?.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getMaster,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state?.master?.person?.id,
							this.props.getMaster,
							contact,
							contactId,
						)
					}
				/>

				<PersonInitiator
					initiators={this.state.master?.person?.initiators}
					personId={this.state?.master?.person?.id}
				/>
				<PersonExecutor
					personId={this.state?.master?.person?.id}
					executors={this.state.master?.person?.executors}
				/>
				<PersonTransport
					personId={this.state?.master?.person?.id}
					transports={this.state.master?.person?.transports}
				/>
				<PersonBasePoint
					personId={this.state?.master?.person?.id}
					basePoints={this.state.master?.person?.basePoints?.filter(
						(bp) => bp.personId,
					)}
				/>
			</>
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(MasterDetail)