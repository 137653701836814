import React from 'react';
import {Props} from './itemList.types';
import Spinner from '../spinner/spinner.controller'


export default class ItemList extends React.Component<Props>{

    scrollRef = React.createRef<HTMLDivElement>();

    onScrollHandler = async (e:React.UIEvent) => {
        const {updateData, offsetToLoad, loading} = this.props;

        if(updateData) {
            const {scrollHeight, clientHeight, scrollTop} = e.currentTarget;
            const offset = offsetToLoad || 2000;


            const maxHeight = scrollHeight - clientHeight;
            const loadHeight = Math.round( maxHeight - offset );

            if(scrollTop >= loadHeight) {
                if(!loading) {
                    await updateData()
                }
            }
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.backScrollToTop) {
            this.scrollRef.current && this.scrollRef.current.scroll({
                top : 0,
                // behavior: 'smooth'
            });
        }
    }


    render () {
        const {list, loading, renderItem, filterByActive} = this.props;
        return (
            <div
                onScroll={this.onScrollHandler}
                ref={this.scrollRef}
                className="list-panel"
            >
                    { list && list.filter(item => {
                        if(filterByActive){
                            return item.active;
                        }
                        return true;
                    }).map( (listItem:any, index:number) => {
                        return (
                            <div key={`${listItem.id}__${index}`}>
                                {renderItem(listItem, index)}
                            </div>
                        )
                    })}

                <Spinner loading={loading}/>
            </div>
        );



    }
}