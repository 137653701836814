import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import {CreateTransport} from '@justpro/terminal'
import getText from "../../../localization/getText";

type Props = {
    create(data:CreateTransport) : void
};
type State = {
    name : string
};

class UnitCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
    };


    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    create = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name})
        }
    };

    isDisabled = () => this.state.name.trim() === '';

    render () {
        return (
            <>
                <h4>{getText('units.addingNewHeader')}</h4>

                <Input
                    blur={this.onChangeName}
                    label="units.unit"
                />

                <Button className="btn-success" onClick={this.create}>{getText('common.add')}</Button>
            </>
        )
    }

}

export default UnitCreate
