import React from "react";
import withLabel from "../../UI/withLabel/withLabel";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import getPersonPhoto from "../../../utils/getPersonPhoto";
import ButtonDropzone from "../../UI/dropzone/button/buttonDropzone.controller";
import {imagesOnly} from "../../UI/dropzone/acceptedTypes";
import checkError from "../../../utils/checkError";
import {editPerson, Person} from "@justpro/terminal";
import {openModal} from "../../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal} from "../../../store/modal/modal.types";
import getText from "../../../localization/getText";

interface Props {
    personId: number
    openModal(data:Modal) : void
    afterPhotoUpload?(person?:Person) : void
    avatar? : string
}

interface State {
    avatar?: string
}

class PersonAvatar extends React.Component<Props, State>{

    state:State = {
    };

    avatarClickHandler = () => {

        this.props.openModal({
            id : 'personsAvatar',
            component : () => (
                <ButtonDropzone
                    setFile={this.setPersonImage}
                    name="common.photoUpload"
                    accept={imagesOnly}
                />
            ),
            title : getText('common.photoEdit')
        })

    };

    saveAvatar = async (photo:any) => {
        const file = new File([photo], 'avatar');
        this.setPersonImage([file])
    };

    resetState = () => {
        this.setState(() => ({
            avatar : this.props.avatar
        }))
    };


    setPersonImage = async (files:File[]) => {
        try {
            const person = await editPerson(this.props.personId, {avatar : files[0]});

            if(person) {

                this.setState(() => ({
                    avatar : person.avatar
                }));

                this.props.afterPhotoUpload && this.props.afterPhotoUpload(person);
            }

        }catch (e) {
            checkError(e)
        }
    };


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.personId !== this.props.personId) {
            this.resetState();
        }
    }

    componentDidMount(): void {
        this.resetState()
    }

    render () {

        return (
            <>
                {withLabel(
                    <Tooltip position="down" title="person.changeAvatar">
                        <div className="person__avatar" onClick={this.avatarClickHandler}>
                            <img src={getPersonPhoto(this.state.avatar)} alt=""/>
                        </div>
                    </Tooltip>
                )({position: "left", text : "common.photo"})}
            </>
        )
    }
}

const mapDispatch = (d:Function) => ({
    openModal : (data:Modal) => d(openModal(data))
});

export default connect(null, mapDispatch)(PersonAvatar)