import React from "react";
import {connect} from "react-redux";
import {ApplicationMapState} from "../application/application.controller";
import {getStaff, SettingsMap, Staff, Person} from "@justpro/terminal";
import checkError from "../../utils/checkError";
import StaffDetail from "../references/staff/staff.detail";
import RenderIf from "../../utils/renderIf";
import getText from "../../localization/getText";


interface Props {
    onSubOrdinatePick(staff: Staff): void

    hide?(): void

    me?: any
    settings?: SettingsMap

    defaultOpen?: boolean
}

interface State {
    staff: Staff[],
    tasksPersons: Staff[]
}

const DEFAULT_TASKS_PERSONS_DEPARTMENT = {
    id: 0,
    active: true,
    name: getText('staff.watchingForTasks')
}

class SubOrdinateModal extends React.Component<Props, State> {

    state: State = {
        staff: [],
        tasksPersons: []
    };


    resetState = async () => {
        const {me, settings} = this.props;


        if (me && settings) {
            try {

                const staff = await getStaff(+settings?.tfmContractorId, {chiefPersonId: me?.person?.id});
                const chief = staff.filter((s) => s?.person?.id).find((s) => s?.person?.id === me?.person?.id);
                const withRoot = staff?.map(s => {
                    return s?.person?.id === me?.person?.id ? {
                        ...s,
                        chiefId: null
                    } : s
                }) || [];
                if(!chief) return;
                this.setState({
                    staff: withRoot,
                    tasksPersons: [{
                        ...chief,
                        department: DEFAULT_TASKS_PERSONS_DEPARTMENT,
                        chiefId: null
                    }, ...chief?.tasksPersons?.map((tp) => {
                        return {
                            id: tp.id,
                            department: DEFAULT_TASKS_PERSONS_DEPARTMENT,
                            position: tp.positions[0],
                            person: {
                                ...tp,
                                id: tp.personId
                            },
                            chiefId: chief.id
                        }
                    })] as unknown as Staff[]
                });
            } catch (e) {
                checkError(e)
            }
        }
    };

    onSubOrdinatePick = (staff: Staff) => {
        this.props.onSubOrdinatePick(staff);
        this.props.hide && this.props.hide();

    }

    async componentDidMount() {
        this.resetState();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.resetState();
        }
    }

    render() {
        const {me, settings} = this.props;

        if (!settings) {
            return false
        }

        return (
            <>
                <StaffDetail
                    id={+settings?.tfmContractorId}
                    staff={this.state.staff}
                    onStaffClick={this.onSubOrdinatePick}
                    defaultOpen={this.props.defaultOpen}
                />
                <StaffDetail id={+settings?.tfmContractorId}
                             staff={this.state.tasksPersons}
                             onStaffClick={this.onSubOrdinatePick}
                             defaultOpen={this.props.defaultOpen}
                />
            </>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    me: state.application.me,
    settings: state.application.settings,
});

export default connect(mapState)(SubOrdinateModal)