import React from 'react';
import '../checklist.css'
import {ChecklistWorkorder} from "@justpro/terminal";
import WorkOrderController from "../../../../workOrders/workOrder.controller";
import {connect} from "react-redux";
import {Modal} from "../../../../../store/modal/modal.types";
import {updateModal} from "../../../../../store/modal/modal.actions";
import {checklistModalId} from "../../../../mainNav/mainNav.controller";
import getText from "../../../../../localization/getText";


interface Props {
    workOrders : ChecklistWorkorder[]
    updateModal(id:string, data:Partial<Modal>): void

}

class ThirdStep extends React.Component<Props>{

    componentDidMount(): void {
        this.props.updateModal(checklistModalId, {
            title : getText('workorders.viewOfCreatedWorkorders'),
        })
    }

    render () {

        return (<>

            {this.props.workOrders.map((workOrder) => {
                return (
                    <div key={workOrder.id} className="workOrder_wrapper--new">
                        <WorkOrderController workOrder={workOrder} />
                    </div>
                )
            })}


        </>)
    }
}

const mapDispatch = (d:Function) => ({
    updateModal : (id:string, data:Partial<Modal>) => d(updateModal(id, data))
});

export default connect(null, mapDispatch)(ThirdStep)