import React from 'react';
import Input from "../../../UI/input/text";
import Select from "../../../UI/select/select";
import Button from "../../../UI/button/button";
import {getCitiesList, getSquaresList} from '../../../../utils/functions'
import {Option} from "../../../UI/select/select.types";
import {CreateObject} from "@justpro/terminal";
import withLabel from "../../../UI/withLabel/withLabel";
import getText from "../../../../localization/getText";
import {getCitiesByArea} from "../../../../utils/selectGroups";
import AsyncSelect from "../../../UI/select/asyncSelect";

type State = {
    number? :  string
    address : string
    cityId : number
    squareId? : number
    actsEmail? : string
    longitude? : string
    latitude? : string
};

type Props = {
    create(data:CreateObject) : void
}

class ObjectCreate extends React.Component<Props, State> {
    state:State = {
        cityId : -1,
        address : '',
    };

    onChangeNumber = (number:string) => {
        this.setState(() => ({number}))
    };
    onChangeAddress = (address:string) => {
        this.setState(() => ({address}))
    };

    onChangeEmail = (actsEmail:string) => {
        this.setState(() => ({actsEmail}))
    };

    onChangeSquare = (option:Option) => {
        return new Promise(resolve => {
            this.setState(() => ({
                squareId : option.value
            }));
            resolve();
        })
    };

    isDisabled = () => {
        const {squareId, cityId, address, number} = this.state;
        return (
            !squareId ||
            cityId === -1 ||
            (!address || address.trim() === '') ||
            (!number || number.trim() === '')
        );
    };


    onChangeNewObjectCity = (option:Option) => {
        return new Promise(resolve => {
            this.setState(() => ({
                cityId : option.value
            }));
            resolve();
        })
    };

    addObject = async () => {
        if(!this.isDisabled()) {
            const data:CreateObject = {
                cityId: this.state.cityId,
                address: this.state.address,
                number: this.state.number,
                latitude: this.state.latitude ? parseInt(this.state.latitude) : undefined,
                longitude: this.state.longitude ? parseInt(this.state.longitude) : undefined,
                actsEmail: this.state.actsEmail,
                squareId: this.state.squareId
            };

            await this.props.create(data);
            // this.props.changeDetailPanel('detail')
        }
    };

    changeLongitude = (longitude:string) => {
        this.setState(() => ({longitude}))
    };

    changeLatitude = (latitude:string) => {
        this.setState(() => ({latitude}))
    };

    render () {
        return(
            <>
                <h3>{getText('objects.addingNewHeader')}</h3>

                <Input
                    placeholder="common.number"
                    change={this.onChangeNumber}
                    label="common.number"
                    startValue=""
                />

                <Input
                    placeholder="objects.address"
                    change={this.onChangeAddress}
                    label="objects.address"
                    startValue=""
                />

                <AsyncSelect
                    loadOptions={(q:string) => getCitiesByArea({q})}
                    label={{ text : "cities.city" }}
                    change={this.onChangeNewObjectCity}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                />

                <Select
                    load={getSquaresList}
                    change={this.onChangeSquare}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    label="squares.square"
                />

                <Input
                    placeholder="objects.email"
                    change={this.onChangeEmail}
                    label="objects.emailForActs"
                    startValue=""
                />

                {withLabel(
                    <>
                        <Input
                            label="common.latitude"
                            startValue=''
                            change={this.changeLatitude}
                        />
                        <Input
                            label="common.longitude"
                            startValue=''
                            change={this.changeLongitude}
                        />
                    </>
                )({text : 'GPS'})}

                <Button className="btn-success" disabled={this.isDisabled()} onClick={this.addObject}>{getText('common.add')}</Button>
            </>
        )
    }
}


export default ObjectCreate