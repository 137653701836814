import cookie from "js-cookie";
import history from "./history";
import store from "../store";
import {applicationActionTypes} from "../store/application/application";

export const signIn = (path = "/") => {
    cookie.set("token", "true");
    history.push(path);
};

export const signOut = () => {
    cookie.remove("token");
    history.push("/sign");

    store.dispatch({type : applicationActionTypes.RESET_USER_RIGHTS})
};