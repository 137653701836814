import React from "react";
import {City, editCity, getCity} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import CityDetail from "./city.detail";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";

interface Props  {
    id: number
    afterUpdate?(item:City) : void
}
interface State  {
    city? : City
    loading : boolean
}



class CityController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };

    updateCity = async (data:Partial<City>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const city = await editCity(this.props.id, data);

            afterUpdate && afterUpdate(city);

            this.setState(() => ({loading : false, city}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getCity = async () => {
        this.setState(() => ({loading : true}));

        try {
            const city = await getCity(this.props.id);

            this.setState(() => ({loading : false, city}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getCity()
        }
    }

    async componentDidMount() {
        this.getCity();
    }

    render () {
        const {city} = this.state;

        return (
            <>
                <RenderIf condition={city !== undefined}>
                    <CityDetail city={city as City} updateCity={this.updateCity} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default CityController
