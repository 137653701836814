import React from 'react';
import {Contract, editContract, EditContract, getContract} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import ContractDetail from "./contract.detail";


interface Props {
    id : number
    afterUpdate?(contract:Contract) : void
}

interface State {
   loading : boolean
   contract? : Contract
}

class ContractController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateContract = async (data:Partial<EditContract>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const contract = await editContract(this.props.id, data);

            afterUpdate && afterUpdate(contract);

            this.setState(() => ({loading : false, contract}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getContract = async () => {
        this.setState(() => ({loading : true}));

        try {
            const contract = await getContract(this.props.id);

            this.setState(() => ({loading : false, contract}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getContract()
        }
    }

    async componentDidMount() {
        this.getContract();
    }

    render () {
        const {contract} = this.state;

        return (
            <div className="just-pro__scroll_panel">
                <RenderIf condition={contract !== undefined}>
                    <ContractDetail contract={contract as Contract} updateContract={this.updateContract} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </div>
        )
    }
}

export default ContractController
