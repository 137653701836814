import React from 'react'
import Input from '../../UI/input/text'
import {
	UpdatePerson,
	Person,
	ModulesResponse,
	addPerson1sIds,
	deletePerson1sIds,
} from '@justpro/terminal'
import './persons.css'
import { ChangeMultiProps, Option } from '../../UI/select/select.types'
import RenderIf from '../../../utils/renderIf'
import PersonAvatar from './person.avatar'
import PersonInitiator from './person.initiator'
import { getPersons1SList, getStores1SList } from '../../../utils/functions'
import PersonExecutor from './person.executor'
import PersonTransport from './person.transport'
import PersonBasePoint from './person.basepoints'
import AsyncSelect from '../../UI/select/asyncSelect'
import MultiSelect from '../../UI/select/asyncSelect_v2'
import { ApplicationMapState } from '../../application/application.controller'
import { connect } from 'react-redux'
import getText from '../../../localization/getText'

import Contacts from './contacts/person.contacts'
import types from './contacts/typesData.json'
import {
	addContactData,
	updateContactData,
	deleteContactData,
} from './contacts/contactHandlers'
import { PHONE_TYPE, EMAIL_TYPE, MESSENGER_TYPE } from './contacts/contactTypes'
import ToggleSwitch from '../../UI/toggleSwitch/toggleSwitch.controller'
import checkError from '../../../utils/checkError'

type Props = {
	person: Person
	update(data: Partial<UpdatePerson>): void
	rights?: Partial<ModulesResponse>
	getPerson?: () => void
}

type State = {
	person?: Person
}

class PersonDetail extends React.Component<Props, State> {
	state: State = {}
	onUpdateWorkDayPush = (workDayPush) => {
		if (workDayPush !== this.props.person.workDayPush) {
			this.props.update({
				workDayPush,
			})
		}
	}

	onBlurName = (firstName: string, oldValue: string) => {
		if (firstName !== oldValue) {
			this.props.update({ firstName })
		}
	}

	onBlurLastName = (lastName: string, oldValue: string) => {
		if (lastName !== oldValue) {
			this.props.update({ lastName })
		}
	}

	onBlurPatronymic = (patronymic: string, oldValue: string) => {
		if (patronymic !== oldValue) {
			this.props.update({ patronymic })
		}
	}

	onBlurPhone = (phone: string, oldValue: string) => {
		if (phone !== oldValue) {
			//@ts-ignore
			this.props.update({ phone })
		}
	}

	onBlurEmail = (email: string, oldValue: string) => {
		if (email !== oldValue) {
			//@ts-ignore
			this.props.update({ email })
		}
	}

	onBlurMessenger = (messenger: string, oldValue: string) => {
		if (messenger !== oldValue) {
			//@ts-ignore
			this.props.update({ messenger })
		}
	}

	changePersonStore = async (option: Option) => {
		return this.props.update({ store1sId: option.value })
	}

	componentDidMount() {
		this.setState({ person: this.props.person })
	}
	componentDidUpdate(prevProps) {
		if (prevProps.person !== this.props.person) {
			this.setState({ person: this.props.person })
		}
	}

	changePerson1s = async (option: Option, action: any) => {
		try {
			switch (action) {
				case 'select-option':
					const updatedPerson = await addPerson1sIds(
						this.props.person.id,
						option.value,
					)
					this.setState((prevState) => ({
						person: {
							...prevState.person,
							person1s: updatedPerson.person1s,
						},
					}))
					return
				case 'remove-value':
					const result = await deletePerson1sIds(
						this.props.person.id,
						option.value,
					)
					this.setState((prevState) => ({
						person: {
							...prevState.person,
							person1s: result.person1s,
						},
					}))
					return
				default:
					return
			}
		} catch (e) {
			checkError(e)
		}
		return false
	}

	render() {
		const { rights } = this.props
		const rule = rights && rights['references.persons']

		const disabled = rule && !rule['edit']

		return (
			<>
				<h3>
					{getText('persons.person')}: {this.state.person?.lastName}{' '}
					{this.state.person?.firstName}{' '}
					{this.state.person?.patronymic
						? this.state.person?.patronymic
						: ''}
				</h3>

				<PersonAvatar
					avatar={this.props?.person?.avatar}
					personId={this.state.person?.id}
				/>

				<Input
					blur={this.onBlurName}
					label='persons.name'
					startValue={this.state.person?.firstName}
					disabled={disabled}
				/>

				<Input
					blur={this.onBlurLastName}
					label='persons.lastName'
					startValue={this.state.person?.lastName}
					disabled={disabled}
				/>

				<Input
					blur={this.onBlurPatronymic}
					label='persons.patronymic'
					startValue={this.state.person?.patronymic}
					disabled={disabled}
				/>

				<MultiSelect
					key={this.state.person?.id}
					label={{ text: 'persons.person1s' }}
					placeholder='persons.person1s'
					loadOptions={getPersons1SList}
					normalizeParams={{
						label: 'name',
						value: 'id1s',
					}}
					change={this.changePerson1s}
					values={this.state.person?.person1s}
					isMulti
				/>

				<AsyncSelect
					change={this.changePersonStore}
					loadOptions={getStores1SList}
					label={{
						text: 'persons.storage1s',
					}}
					isDisabled={disabled}
					defaultValue={{
						value: this.state.person?.store1s
							? this.state.person?.store1s
							: {},
						accessors: { name: 'label', id1s: 'value' },
					}}
				/>

				<ToggleSwitch
					send={this.onUpdateWorkDayPush}
					defaultValue={this.state.person?.workDayPush}
					label='persons.workDayPush'
					disabled={disabled}
				/>

				<Contacts
					contacts={this.state.person?.phones}
					types={types.phoneTypes}
					contact={PHONE_TYPE}
					addContact={() =>
						addContactData(
							this.state.person?.id,
							PHONE_TYPE,
							this.props.getPerson,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getPerson,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state.person?.id,
							this.props.getPerson,
							contact,
							contactId,
						)
					}
				/>
				<Contacts
					contacts={this.state.person?.emails}
					types={types.emailTypes}
					contact={EMAIL_TYPE}
					addContact={() =>
						addContactData(
							this.state.person?.id,
							EMAIL_TYPE,
							this.props.getPerson,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getPerson,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state.person?.id,
							this.props.getPerson,
							contact,
							contactId,
						)
					}
				/>
				<Contacts
					contacts={this.state.person?.messengers}
					types={types.messengerTypes}
					contact={MESSENGER_TYPE}
					addContact={() =>
						addContactData(
							this.state.person?.id,
							MESSENGER_TYPE,
							this.props.getPerson,
						)
					}
					updateContact={(contact, contactId, typeId, value) =>
						updateContactData({
							personId: this.state.person?.id,
							contact,
							contactId,
							typeId,
							value,
							getPerson: this.props.getPerson,
						})
					}
					deleteContact={(contact, contactId) =>
						deleteContactData(
							this.state.person?.id,
							this.props.getPerson,
							contact,
							contactId,
						)
					}
				/>

				<PersonInitiator
					initiators={this.state.person?.initiators}
					personId={this.state.person?.id}
				/>
				<PersonExecutor
					personId={this.state.person?.id}
					executors={this.state.person?.executors}
				/>
				<PersonTransport
					personId={this.state.person?.id}
					transports={this.state.person?.transports}
				/>
				<PersonBasePoint
					personId={this.state.person?.id}
					basePoints={this.state.person?.basePoints?.filter(
						(bp) => bp.personId,
					)}
				/>
			</>
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(PersonDetail)
