import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {CreatePosition} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreatePosition) : void
}

interface State {
    name : string
}

class PositionCreate extends React.Component<Props, State>{

    state:State = {
        name : ''
    };

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    create = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name})
        }
    };

    isDisabled = () => this.state.name.trim() === '';

    render() {

        return (<>
            <h3>{getText('positions.addingNewHeader')}</h3>

            <Input
                change={this.changeName}
                label="positions.position"
            />

            <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.add')}</Button>

        </>)
    }

}


export default PositionCreate