import React from 'react'
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	DirectionsRenderer,
} from 'react-google-maps'
import { connect } from 'react-redux'
import { ACCIDENT_1S, URGENT_1S } from '../maps/const'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import CustomMarker from '../maps/markers/customMarker'
import { ObjectDisplayName } from '../maps/markers/markerInnerWindows/objectInner'
import { ProvideredConnectedInfoWindow as ObjectInfoWindow } from './components'

import OBJECT_DEFAULT_MARKER from '../maps/icons/objects/OBJECT_DEFAULT_MARKER.svg'
import OBJECT_EXPIRED_MARKER from '../maps/icons/objects/OBJECT_EXPIRED_MARKER.svg'
import OBJECT_WO_MARKER from '../maps/icons/objects/OBJECT_WO_MARKER.svg'
import OBJECT_HOT_MARKER from '../maps/icons/objects/OBJECT_HOT_MARKER.svg'
import OBJECT_DISABLED_MARKER from '../maps/icons/objects/OBJECT_DISABLED_MARKER.svg'
import { clusterStyles } from '../maps/map'
import RenderIf from '../../utils/renderIf'

const GMap: React.FC<any> = ({
	objects,
	disabledObjects,
	directions,
	objectsToRender,
	basePointsToRender,
	executors,
	basePoints,
	position,
	showNames,
	showExecutors,
	openedObject,
	orderToExecuteStatus,
	ordersCanWorkorderStatus,
	bpArray,
	buildRoutesMode,
	routeing,
	arrangedWoArray,
	colorByAccident,
	...props
}) => {
	const mapRef = React.useRef(null)

	const defineObjectCurrentColor = (
		obj,
		{ colorByAccident, ordersCanWorkorderStatus },
	) => {
		if (colorByAccident) {
			switch (true) {
				case obj.orders?.some(
					(order) =>
						order.contractOrdersType?.priority1s?.id1s ==
							ACCIDENT_1S &&
						order.orderStatus.id == ordersCanWorkorderStatus,
				):
					return OBJECT_EXPIRED_MARKER
				case obj.orders?.some(
					(order) =>
						order.contractOrdersType?.priority1s?.id1s ==
							URGENT_1S &&
						order.orderStatus.id == ordersCanWorkorderStatus,
				):
					return OBJECT_HOT_MARKER
				case obj.orders?.some(
					(order) => order.orderStatus.id == ordersCanWorkorderStatus,
				):
					return OBJECT_WO_MARKER
				default:
					return OBJECT_DEFAULT_MARKER
			}
		} else {
			switch (true) {
				case obj.notInFilter:
					return OBJECT_DISABLED_MARKER
				case obj.orders?.some(
					(order) => order.orderStatus.id == orderToExecuteStatus,
				):
					return OBJECT_WO_MARKER
				case obj.orders?.some((order) => order.hot === true):
					return OBJECT_HOT_MARKER
				case obj.orders?.some((order) => order.expired === true):
					return OBJECT_EXPIRED_MARKER
				default:
					return OBJECT_DEFAULT_MARKER
			}
		}
	}

	const markersToRender = React.useMemo(() => {
		if (Array.isArray(objects)) {
			return objects.map((obj, i) => {
				if (!obj.latitude) return
				const currentImage = defineObjectCurrentColor(obj, {
					colorByAccident,
					ordersCanWorkorderStatus,
				})
				return (
					<CustomMarker
						key={obj.id * i}
						object={obj}
						position={{
							lat: Number.parseFloat(obj.latitude),
							lng: Number.parseFloat(obj.longitude),
						}}
						clickedComponent={(props) => (
							<ObjectInfoWindow
								{...props}
								object={obj}
								position={{
									lat: obj.latitude,
									lng: obj.longitude,
								}}
							/>
						)}
						hoveredComponent={ObjectDisplayName}
						showNames={showNames}
						icon={currentImage}
					/>
				)
			})
		}
	}, [objects, showNames, colorByAccident])

	return (
		<GoogleMap
			ref={mapRef}
			defaultZoom={11}
			defaultCenter={position || { lat: 50.27, lng: 30.31 }}
			options={{ disableDefaultUI: true, zoomControl: true }}
		>
			<MarkerClusterer styles={clusterStyles} minimumClusterSize={10}>
				{markersToRender}
			</MarkerClusterer>

			{objectsToRender}
			{basePointsToRender}
			<RenderIf condition={directions}>
				<DirectionsRenderer
					directions={directions}
					options={{
						preserveViewport: true,
						suppressMarkers: true,
						polylineOptions: {
							strokeOpacity: 0.7,
							strokeWeight: 5,
							strokeColor: '#4a81ff',
						},
					}}
				/>
			</RenderIf>
		</GoogleMap>
	)
}

const mapStateToProps = (state) => {
	return {
		ordersCanWorkorderStatus:
			state?.application?.settings?.ordersCanWorkorderStatus,
	}
}

const ConnectedMap = connect(mapStateToProps)(GMap)
const WrappedMap: any = withScriptjs(
	withGoogleMap((props) => <ConnectedMap {...props} />),
)

export default WrappedMap
