import React from "react";
import {
    ModulesResponse,
    OrderListItem,
    OrderStatus,
    getAvailableStatuses
} from "@justpro/terminal";
import Select from "../UI/select/select";
import {Option} from "../UI/select/select.types";
import checkError from "../../utils/checkError";
import RenderIf from "../../utils/renderIf";
import Spinner from "../UI/spinner/spinner.controller";
import getText from "../../localization/getText";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";
import Button from "../UI/button/button";

interface Props {
    changeStatusRequest(...a: any[]): any
    hide() : void
    orders: OrderListItem[],
    rights?: Partial<ModulesResponse>
}

interface ChangeStatusMap{
    availableStatuses: OrderStatus[]
    ordersId: number[]
    orderStatusBefore: OrderStatus
    orderStatusAfterId?: number
}

interface State {
    isLoading: boolean
    changeStatusesMap: ChangeStatusMap[]
}

class ChangeOrdersStatusModal extends React.Component<Props, State> {

    state: State = {
        isLoading: false,
        changeStatusesMap: [],
    };

    updateStatuses = async () => {
        try {
            this.setState({
                isLoading: true
            });
            const changeStatusesMap: ChangeStatusMap[] = [];
           this.props.orders?.map(async (order) => {
                const isExist = changeStatusesMap.find((s) => s.orderStatusBefore.id === order.orderStatus.id);
                if (isExist) {
                    return isExist.ordersId.push(order.id);
                }
                changeStatusesMap.push({
                    orderStatusBefore: order.orderStatus,
                    availableStatuses: [],
                    ordersId: [order.id]
                })
            });
           await Promise.all(changeStatusesMap.map(async (status) => {
               const availableStatuses = await getAvailableStatuses(status.ordersId[0]);
               status.availableStatuses = availableStatuses;
           }));
            this.setState({
                changeStatusesMap
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    changeStatusRequest = () => {
        if(this.isDisabled()) return;
        this.props.changeStatusRequest(this.state.changeStatusesMap.map((status) => {
            return {
                orderStatusBeforeId: status.orderStatusBefore.id,
                orderStatusAfterId: status.orderStatusAfterId
            }
        }));

        this.props.hide();
    }

    changeStatusAfterId = async (status: ChangeStatusMap, option: Option) => {
        this.setState((prevState: State) => ({
            changeStatusesMap: prevState.changeStatusesMap.map((s) => {
                if(s.orderStatusBefore.id === status.orderStatusBefore.id){
                    s.orderStatusAfterId = option.value;
                }
                return s;
            })
        }))
    };

    isDisabled = () => {
        const {changeStatusesMap} = this.state;
        return !changeStatusesMap.filter((s) => s.orderStatusAfterId).length
    }

    componentDidMount() {
        return this.updateStatuses();
    }

    componentDidUpdate(prevProps: Props, prevState:Readonly<State>) {
        if (JSON.stringify(this.props.orders) !== JSON.stringify(prevProps.orders)) {
            return this.updateStatuses();
        }
    }
    render() {
        const {isLoading, changeStatusesMap} = this.state;
        return (
            <>
                <RenderIf condition={isLoading}>
                    <Spinner loading={isLoading}/>
                </RenderIf>
                <h4 className="change-orders-status__title">{getText('tasks.statusTransfer')}</h4>
                <div className="change-orders-status__statuses-wrapper">
                    {changeStatusesMap && changeStatusesMap.map((status) => (
                        <div className="change-orders-status__status">
                            <div className="change-orders-status__status-name">
                                {status?.orderStatusBefore?.name}
                            </div>
                            <Select
                                change={this.changeStatusAfterId.bind(this, status)}
                                label="tasks.availableStatuses"
                                load={async () => status.availableStatuses}
                                placeholder="tasks.chooseNewStatus"
                                defaultValue={undefined}
                                accessors={{
                                    id: 'value',
                                    name: 'label'
                                }}
                            />
                        </div>
                    ))}
                </div>

                <DefaultFooterModal>
                    <Button onClick={this.props.hide} className="btn-danger">{getText('common.cancel')}</Button>
                    <Button className="btn-danger" onClick={this.changeStatusRequest} disabled={this.isDisabled()}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}



export default ChangeOrdersStatusModal;