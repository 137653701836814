import React from 'react';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {Specialization, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    specialization : Specialization,
    update(data:Partial<Specialization>) : void
    rights?: Partial<ModulesResponse>
}

class SpecializationDetail extends React.Component<Props>{

    changeName = (name:string, prevValue:string) => {
        if(name.trim() !== '' && name !== prevValue) {
            this.props.update({name})
        }
    };

    changeDescription = (description:string, prevDescription:string) => {
        if(description.trim() !== '' && description !== prevDescription) {
            this.props.update({description})
        }
    };

    toggleActive = (active:boolean) => {
        this.props.update({active})
    };

    render() {
        const {rights, specialization} = this.props;
        const {active, name, description} = specialization;
        const disabled = rights && rights['references.specializations'] && !rights['references.specializations']['edit'];

        return (
            <>
                <h4>{getText('specializations.specialization')}: {name}</h4>
                <ToggleSwitch defaultValue={active} label="common.active" send={this.toggleActive} disabled={disabled} />
                <Input
                    blur={this.changeName}
                    label="specializations.specialization"
                    startValue={name}
                    disabled={disabled}
                />
                <Input
                    blur={this.changeDescription}
                    label="common.description"
                    startValue={description || ''}
                    disabled={disabled}
                />
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(SpecializationDetail)