import React from 'react'
import {
	getRegularWorks,
	RegularWork,
	bindOTRequestRegularWorks,
	OTRequestFull,
	verifyOtRequest,
} from '@justpro/terminal'
import Spinner from '../../UI/spinner/spinner.controller'
import Tabs, { ITab } from '../../UI/tabs/tabs.controller'
import RenderIf from '../../../utils/renderIf'
import checkError from '../../../utils/checkError'
import { renderToString } from 'react-dom/server'
import getText from '../../../localization/getText'
import DefaultFooterModal from '../../UI/dialog/modal/defaultFooterModal'
import Button from '../../UI/button/button'
import { connect } from 'react-redux'
import { confirm } from '../../UI/confirmAction/confirmAction.controller'
import moment from 'moment'

const MONTH_NAME_NUMBER_MAP: any = {
	'01': renderToString(getText('month.january')),
	'02': renderToString(getText('month.february')),
	'03': renderToString(getText('month.march')),
	'04': renderToString(getText('month.april')),
	'05': renderToString(getText('month.may')),
	'06': renderToString(getText('month.june')),
	'07': renderToString(getText('month.july')),
	'08': renderToString(getText('month.august')),
	'09': renderToString(getText('month.september')),
	'10': renderToString(getText('month.october')),
	'11': renderToString(getText('month.november')),
	'12': renderToString(getText('month.december')),
}

interface IRegularWorksByMonth {
	regularWorks: RegularWork[]
	onChangeActiveValues: (activeValues: number[]) => any
}

type NormalizedRW = {
	equipmentCategory: string
	equipmentTypes?: {
		equipmentType: string
		regularWorks?: RegularWork[]
	}[]
}

const isEqc = (normalized: NormalizedRW[], regularWork: RegularWork) => {
	return normalized.find((n) => {
		return (
			n.equipmentCategory ===
			regularWork.equipment.equipmentType.equipmentCategory.name
		)
	})
}
const isEqt = (eqc: NormalizedRW, regularWork: RegularWork) => {
	return (
		eqc.equipmentTypes &&
		eqc.equipmentTypes.find((n) => {
			return (
				n.equipmentType ===
				`№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`
			)
		})
	)
}

interface RWBMState {
	activeValues: number[]
	expandedEqc: null | string
	expandedEqt: null | string
}

class RegularWorksByMonth extends React.Component<
	IRegularWorksByMonth,
	RWBMState
> {
	state = {
		activeValues: [],
		expandedEqc: null,
		expandedEqt: null,
	}

	get tabs() {
		const tabs: any = []
		const { regularWorks } = this.props
		if (!regularWorks?.length) return null
		regularWorks.forEach((regularWork) => {
			const rwMonth = regularWork.date.slice(5, 7)
			const wordMonth =
				MONTH_NAME_NUMBER_MAP[rwMonth] +
				` ${regularWork.date.slice(0, 4)}`
			if (tabs.find((tab: any) => tab.title?.includes(wordMonth))) {
				return
			}
			tabs.push({
				title: wordMonth,
				component: this.renderRegularWorkStructure.bind(this, rwMonth),
				onSelectTab: this.clearEqcAndEqt,
			})
		})
		return tabs
	}

	checkedCategory(eqc: any) {
		return eqc.equipmentTypes.reduce((checked: boolean, eqt: any) => {
			return checked && this.checkedType(eqt)
		}, true)
	}

	checkedType(eqt: any) {
		return eqt.regularWorks.reduce((checked: boolean, rw: RegularWork) => {
			const rwResult = this.checkedRw(rw.id)
			return checked && rwResult
		}, true)
	}

	checkedRw(rwId: number) {
		return !!this.state.activeValues.find(
			(activeValue) => activeValue === rwId,
		)
	}

	clearEqcAndEqt = () => {
		this.setState({
			expandedEqc: null,
			expandedEqt: null,
		})
	}

	expandCategory(equipmentCategory: string, event: any) {
		if (!event.target.closest('.otsync-category-name')) {
			return false
		}
		this.setState((prevState: any) => ({
			expandedEqc:
				prevState.expandedEqc === equipmentCategory
					? null
					: equipmentCategory,
		}))
	}

	expandType(equipmentType: string, event: any) {
		if (!event.target.closest('.otsync-type-name')) {
			return false
		}
		this.setState((prevState: any) => ({
			expandedEqt:
				prevState.expandedEqt === equipmentType ? null : equipmentType,
		}))
	}

	onChangeActiveValues = () => {
		this.props.onChangeActiveValues &&
			this.props.onChangeActiveValues(this.state.activeValues)
	}

	selectRw = (regularWork: RegularWork) => {
		this.setState((prevState: any) => {
			if (prevState.activeValues.includes(regularWork.id)) {
				return {
					activeValues: prevState.activeValues.filter(
						(rwId: number) => {
							return rwId !== regularWork.id
						},
					),
				}
			}
			return {
				activeValues: [...prevState.activeValues, regularWork.id],
			}
		}, this.onChangeActiveValues)
	}

	getAllRwEqc(eqc: any) {
		return eqc.equipmentTypes.reduce((allEqt: number[], eqt: any) => {
			return [...allEqt, ...this.getAllRwEqt(eqt)]
		}, [])
	}

	getAllRwEqt(eqt: any) {
		return eqt.regularWorks.reduce((allRw: number[], rw: RegularWork) => {
			return [...allRw, rw.id]
		}, [])
	}

	removeAllEqc(activeValues: number[], eqc: any) {
		const eqcRwId = this.getAllRwEqc(eqc)
		return activeValues.filter((activeValue: number) => {
			return !eqcRwId.includes(activeValue)
		})
	}

	removeAllEqt(activeValues: number[], eqt: any) {
		const eqtRwId = this.getAllRwEqt(eqt)
		return activeValues.filter((activeValue: number) => {
			return !eqtRwId.includes(activeValue)
		})
	}

	selectAllEqc = (eqc: any) => {
		const eqcRwId = this.getAllRwEqc(eqc)
		const checkedCategory = this.checkedCategory(eqc)
		if (checkedCategory) {
			this.setState(
				(prevState: any) => ({
					activeValues: this.removeAllEqc(
						prevState.activeValues,
						eqc,
					),
				}),
				this.onChangeActiveValues,
			)
		} else {
			this.setState(
				(prevState) => ({
					activeValues: [
						...new Set([...prevState.activeValues, ...eqcRwId]),
					],
				}),
				this.onChangeActiveValues,
			)
		}
	}

	selectAllEqt = (eqt: any) => {
		const eqtRwId = this.getAllRwEqt(eqt)
		const checkedType = this.checkedType(eqt)
		if (checkedType) {
			this.setState(
				(prevState: any) => ({
					activeValues: this.removeAllEqt(
						prevState.activeValues,
						eqt,
					),
				}),
				this.onChangeActiveValues,
			)
		} else {
			this.setState(
				(prevState) => ({
					activeValues: [
						...new Set([...prevState.activeValues, ...eqtRwId]),
					],
				}),
				this.onChangeActiveValues,
			)
		}
	}

	renderRegularWorkStructure(rwMonth: string) {
		const { activeValues, expandedEqc, expandedEqt } = this.state
		const filterRw = this.props.regularWorks.filter((rw) => {
			return rw.date.slice(5, 7) === rwMonth
		})
		const normalizedRw = this.normalizeRegularWorks(filterRw)
		return normalizedRw.map((eqc: NormalizedRW) => {
			return (
				<div className='otsync-categories-wrapper'>
					<div className='otsync-checkbox otsync-category-checkbox'>
						<input
							type='checkbox'
							checked={this.checkedCategory(eqc)}
							onChange={this.selectAllEqc.bind(this, eqc)}
						/>
						<span
							className='otsync-category-name'
							onClick={this.expandCategory.bind(
								this,
								eqc.equipmentCategory,
							)}
						>
							{eqc.equipmentCategory}
						</span>
						<RenderIf
							condition={
								expandedEqc === eqc.equipmentCategory &&
								eqc.equipmentTypes?.length
							}
						>
							{eqc.equipmentTypes?.map((eqt) => (
								<div className='otsync-checkbox otsync-type-checkbox'>
									<input
										type='checkbox'
										checked={this.checkedType(eqt)}
										onChange={this.selectAllEqt.bind(
											this,
											eqt,
										)}
									/>
									<span
										className='otsync-type-name'
										onClick={this.expandType.bind(
											this,
											eqt.equipmentType,
										)}
									>
										{eqt.equipmentType}
									</span>
									<RenderIf
										condition={
											expandedEqt === eqt.equipmentType &&
											eqt.regularWorks?.length
										}
									>
										{eqt.regularWorks?.map(
											(rw: RegularWork) => (
												<div className='otsync-checkbox otsync-rw-checkbox'>
													<input
														type='checkbox'
														checked={this.checkedRw(
															rw.id,
														)}
														onChange={this.selectRw.bind(
															this,
															rw,
														)}
													/>
													{rw.equipmentWork.name}
													<b className='rw-number'>
														(№{rw?.number},{' '}
														{moment(rw.date).format(
															'DD.MM.YYYY',
														)}
														)
													</b>
												</div>
											),
										)}
									</RenderIf>
								</div>
							))}
						</RenderIf>
					</div>
				</div>
			)
		})
	}

	normalizeRegularWorks(regularWorks: RegularWork[]) {
		return regularWorks.reduce(
			(normalized: NormalizedRW[], regularWork) => {
				const isEqcResult = isEqc(normalized, regularWork)
				const isEqtResult =
					isEqcResult && isEqt(isEqcResult, regularWork)
				if (
					isEqcResult &&
					isEqtResult &&
					isEqtResult.regularWorks?.length
				) {
					isEqtResult.regularWorks.push(regularWork)
				}
				if (
					isEqcResult &&
					isEqtResult &&
					!isEqtResult.regularWorks?.length
				) {
					isEqtResult.regularWorks = [regularWork]
				}
				if (isEqcResult && !isEqtResult) {
					isEqcResult.equipmentTypes = [
						...isEqcResult.equipmentTypes,
						{
							equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
							regularWorks: [regularWork],
						},
					]
				}

				if (!isEqcResult && normalized?.length) {
					normalized.push({
						equipmentCategory:
							regularWork?.equipment?.equipmentType
								?.equipmentCategory?.name,
						equipmentTypes: [
							{
								equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
								regularWorks: [regularWork],
							},
						],
					})
				}
				if (!isEqcResult && !normalized?.length) {
					return [
						{
							equipmentCategory:
								regularWork?.equipment?.equipmentType
									?.equipmentCategory?.name,
							equipmentTypes: [
								{
									equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
									regularWorks: [regularWork],
								},
							],
						},
					]
				}
				return normalized
			},
			[],
		)
	}

	render() {
		return <Tabs tabs={this.tabs || []} />
	}
}

interface Props {
	settings: any
	otsync: OTRequestFull
	equipmentCategoryId: number
	objectId: number
	updateOtsyncList(): void
	clearItem(): void
	hide(): void
}

interface State {
	activeValues: any
	regularWorks: any
	loading: any
}
class OtsyncRegularWork extends React.Component<Props, State> {
	state: State = {
		regularWorks: [],
		activeValues: [],
		loading: false,
	}

	async componentDidMount() {
		try {
			this.setState({
				loading: true,
			})
			const { equipmentCategoryId, objectId } = this.props
			const {
				newRegularStatusId,
				regularWorkToExecute,
				regularWorkDoneStatusId,
			} = this.props.settings
			const regularWorks = await getRegularWorks({
				equipmentCategoriesId: [equipmentCategoryId],
				objectsId: [objectId],
				withoutWo: '1',
				regularStatusesId: [+newRegularStatusId, +regularWorkToExecute],
				limit: 10000,
			})
			this.setState({
				regularWorks: regularWorks.list,
				loading: false,
			})

			// this.props.updateModal(otsyncRegularWorksModalId, {
			//     buttonSave : {
			//         handler : this.save,
			//     }
			// })
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	save = async () => {
		try {
			this.setState({
				loading: true,
			})
			const verifyResponse = await verifyOtRequest(
				this?.props?.otsync?.id,
			)
			let confirmCreate = true
			if (verifyResponse?.isOrders || verifyResponse?.isRegularWorks) {
				confirmCreate = await confirm({
					question: getText(`otsync.woExist`, {
						number: this?.props?.otsync?.number,
					}),
					confirmText: getText(`otsync.anywayCreate`),
					declineText: getText(`otsync.cancel`),
				})
			}
			if (confirmCreate) {
				await bindOTRequestRegularWorks(
					this.props?.otsync?.id,
					this.state.activeValues,
				)
				this.props.updateOtsyncList()
				this.props.clearItem()
				this.props.hide()
			}
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	changeActiveValues = (activeValues: number[]) => {
		this.setState({
			activeValues,
		})
	}

	render() {
		return (
			<>
				<Spinner loading={this.state.loading} />
				<RegularWorksByMonth
					regularWorks={this.state?.regularWorks}
					onChangeActiveValues={this.changeActiveValues}
				/>

				<DefaultFooterModal>
					<Button className='btn-danger' onClick={this.props.hide}>
						{getText('common.cancel')}
					</Button>
					<Button
						disabled={!this.state.activeValues.length}
						className='btn-danger'
						onClick={this.save}
					>
						{getText('common.create')}
					</Button>
				</DefaultFooterModal>
			</>
		)
	}
}
const mapState = (state: any) => ({
	settings: state.application.settings,
})
export default connect(mapState)(OtsyncRegularWork)
