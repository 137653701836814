import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone'
import './personPhoto.css';
import Tooltip from "../../tooltip/tooltip.controller";
import {renderToString} from "react-dom/server";
import getText from "../../../../localization/getText";
import {MAX_FILE_SIZE} from "@justpro/terminal";


interface Props {
    setAvatar(file:File) : void

    avatar?: string
}

const PersonPhoto = (props:Props) => {
    // const maxSize = 5048576;
    const types = ['image/png', 'image/jpeg'];

    const [hover, setHover] = useState(false);
    // const [image, setImage] = useState(props.image);

    const onDrop = (acceptedFiles:File[]) => {
        if(acceptedFiles[0]) {
            props.setAvatar(acceptedFiles[0])

        }
    };


    const {getRootProps, getInputProps, rejectedFiles} = useDropzone({
        accept : ['image/png', 'image/jpeg'],
        minSize : 0,
        maxSize : MAX_FILE_SIZE,
        onDrop,
        noKeyboard : true
    });

    const isError = rejectedFiles.length > 0;
    let isFileTooLarge, isRejectType;

    if (isError) {
        isFileTooLarge = rejectedFiles[0].size > MAX_FILE_SIZE;
        isRejectType = !types.includes(rejectedFiles[0].type);
    }

    return (
        <>
            <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <Tooltip title="UI.photos.placeholder" position="down">
                    <div
                        onMouseOver={setHover.bind(null, true)}
                        onMouseLeave={setHover.bind(null, false)}
                        className="just-pro__drop-zone-person"
                    >
                        {hover ? (<i className='fa fa-upload fa-4x'/>) : null}
                        <img src={props.avatar} alt={renderToString(getText('common.photo'))}/>
                    </div>
                </Tooltip>
            </div>

            {isError && <div className="just-pro__drop-zone-error">
                {isFileTooLarge ?  getText('UI.inlineDropzone.largeFile', {mb : Math.round(MAX_FILE_SIZE / 1024 / 1024)}) :
                    isRejectType ? getText('UI.inlineDropzone.type', {files: types.map(item => '.' + item.split('/')[1])}) :
                        getText('UI.inlineDropzone.unexpectedError')}
            </div>}
        </>

    )
}

export default PersonPhoto