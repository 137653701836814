import React from "react";
import {EquipmentCategory, RegularWork} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import './checklistTree.css'
import Input from "../input/text";
import Tooltip from "../tooltip/tooltip.controller";
import getText from "../../../localization/getText";
import {connect} from 'react-redux'


type NormalizedRW = {
    equipmentCategory: EquipmentCategory,
    equipmentTypes?: {
        equipmentType: string,
        regularWorks?: RegularWork[]
    }[]
}

interface Props {
    regularWorks: RegularWork[]
    isWorkorderClosed?: boolean
    onChangeActiveValues: (equipmentCategoryId: number, activeValues: number[]) => any
    // clearEqcAndEqt?() : void
    expandedEqc?: string | null
    expandedEqt?: string | null

    changeActNumber(etcId: number, value: string): void

    deleteRw(rwId: number[]): void

    removeHandler?(el: any): void

    settings?: any

    requestWorkOrder?(): void
}

interface State {
    activeValues: number[]
    expandedEqc: null | string
    expandedEqt: null | string
    showInput: boolean


    normalized?: NormalizedRW[]
}

const isEqc = (normalized: NormalizedRW[], regularWork: RegularWork) => {
    return normalized.find((n) => {
        return n.equipmentCategory.name === regularWork.equipment.equipmentType.equipmentCategory.name;
    });
};
const isEqt = (eqc: NormalizedRW, regularWork: RegularWork) => {
    return eqc.equipmentTypes && eqc.equipmentTypes.find((n) => {
        return n.equipmentType === `№${regularWork?.equipment?.number} ${regularWork.equipment.equipmentType.name}`;
    });
};

export class ChecklistTreeController extends React.Component<Props, State> {

    state: State = {
        activeValues: [],
        expandedEqc: null,
        expandedEqt: null,
        showInput: false
    };

    checkedCategory(eqc: any) {
        return eqc.equipmentTypes.reduce((checked: boolean, eqt: any) => {
            return checked && this.checkedType(eqt);
        }, true);
    }

    checkedType(eqt: any) {
        return eqt.regularWorks.reduce((checked: boolean, rw: RegularWork) => {
            const rwResult = this.checkedRw(rw);
            return checked && rwResult;
        }, true);
    }

    checkedRw(rw: RegularWork) {
        return !!rw.act || !!this.state.activeValues.find((activeValue) => activeValue === rw.id);
    }

    expandCategory(equipmentCategory: EquipmentCategory, event: any) {
        if (!event.target.closest(".checklist-category-name")) {
            return false;
        }

        this.setState((prevState: any) => ({
            expandedEqc: prevState.expandedEqc === equipmentCategory.name ? null : equipmentCategory.name
        }));
    }


    expandType(equipmentType: string, event: any) {
        if (!event.target.closest(".checklist-type-name")) {
            return false;
        }
        this.setState((prevState: any) => ({
            expandedEqt: prevState.expandedEqt === equipmentType ? null : equipmentType
        }));
    }

    onChangeActiveValues = (categoryId: number) => {
        this.props.onChangeActiveValues && this.props.onChangeActiveValues(categoryId, this.state.activeValues);
    };

    selectRw = (regularWork: RegularWork, categoryId: number) => {

        if (!regularWork.act) {
            this.setState((prevState: any) => {
                if (prevState.activeValues.includes(regularWork.id)) {
                    return {
                        activeValues: prevState.activeValues.filter((rwId: number) => {
                            return rwId !== regularWork.id
                        })
                    }
                }
                return {
                    activeValues: [...prevState.activeValues, regularWork.id]
                }
            }, this.onChangeActiveValues.bind(this, categoryId))
        }

    };

    getAllRwEqc(eqc: any) {
        return eqc.equipmentTypes.reduce((allEqt: number[], eqt: any) => {
            return [...allEqt, ...this.getAllRwEqt(eqt)];
        }, []);
    }

    getAllRwEqt(eqt: any) {
        return eqt.regularWorks.reduce((allRw: number[], rw: RegularWork) => {
            if (!!rw.act) return allRw;
            return [...allRw, rw.id];
        }, []);
    }

    removeAllEqc(activeValues: number[], eqc: any) {
        const eqcRwId = this.getAllRwEqc(eqc);
        return activeValues.filter((activeValue: number) => {
            return !eqcRwId.includes(activeValue);
        })
    }

    removeAllEqt(activeValues: number[], eqt: any) {
        const eqtRwId = this.getAllRwEqt(eqt);
        return activeValues.filter((activeValue: number) => {
            return !eqtRwId.includes(activeValue);
        })
    }

    selectAllEqc = (eqc: any) => {
        const eqcRwId = this.getAllRwEqc(eqc);
        const checkedCategory = this.checkedCategory(eqc);
        if (checkedCategory) {
            this.setState((prevState: any) => ({
                activeValues: this.removeAllEqc(prevState.activeValues, eqc)
            }), this.onChangeActiveValues.bind(this, eqc.id))
        } else {
            this.setState((prevState) => ({
                activeValues: [...new Set([...prevState.activeValues, ...eqcRwId])]
            }), this.onChangeActiveValues.bind(this, eqc.id))
        }
    };

    selectAllEqt = (eqt: any, categoryId: number) => {
        const eqtRwId = this.getAllRwEqt(eqt);
        const checkedType = this.checkedType(eqt);

        if (checkedType) {
            this.setState((prevState: any) => ({
                activeValues: this.removeAllEqt(prevState.activeValues, eqt)
            }), this.onChangeActiveValues.bind(this, categoryId))
        } else {
            this.setState((prevState) => ({
                activeValues: [...new Set([...prevState.activeValues, ...eqtRwId])]
            }), this.onChangeActiveValues.bind(this, categoryId))
        }
    };


    normalizeRegularWorks(regularWorks: RegularWork[]) {
        return regularWorks.reduce((normalized: NormalizedRW[], regularWork) => {
            const isEqcResult = isEqc(normalized, regularWork);
            const isEqtResult = isEqcResult && isEqt(isEqcResult, regularWork);
            if (isEqcResult && isEqtResult && isEqtResult.regularWorks?.length) {
                isEqtResult.regularWorks.push(regularWork);
            }
            if (isEqcResult && isEqtResult && !isEqtResult.regularWorks?.length) {
                isEqtResult.regularWorks = [regularWork];
            }

            if (isEqcResult && !isEqtResult) {

                isEqcResult.equipmentTypes = [...isEqcResult.equipmentTypes, {
                    equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
                    regularWorks: [regularWork]
                }];
            }
            if (!isEqcResult && normalized?.length) {
                normalized.push({
                    equipmentCategory: regularWork?.equipment?.equipmentType?.equipmentCategory,
                    equipmentTypes: [{
                        equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
                        regularWorks: [regularWork]
                    }]
                });
            }
            if (!isEqcResult && !normalized?.length) {
                return [{
                    equipmentCategory: regularWork?.equipment?.equipmentType?.equipmentCategory,
                    equipmentTypes: [{
                        equipmentType: `№${regularWork?.equipment?.number} ${regularWork?.equipment?.equipmentType?.name}`,
                        regularWorks: [regularWork]
                    }]
                }]
            }
            return normalized;
        }, []);

    };

    changeActNumber = (value: string, oldValue: string, eqc: NormalizedRW) => {
        const {changeActNumber} = this.props;


        if (value.length === 1 && oldValue.length === 0) {
            const eqcRwId = this.getAllRwEqc(eqc);

            this.setState((prevState) => ({
                activeValues: [...new Set([...prevState.activeValues, ...eqcRwId])]
            }), this.onChangeActiveValues.bind(this, eqc.equipmentCategory.id))

        } else if (value.length === 0) {
            this.setState((prevState) => ({
                activeValues: this.removeAllEqc(prevState.activeValues, eqc)
            }), this.onChangeActiveValues.bind(this, eqc.equipmentCategory.id))
        }

        changeActNumber && changeActNumber(eqc.equipmentCategory.id, value)

    };

    isAllRwHasAnAct = (categoryId: number): boolean => {

        const categoryEW = this.props.regularWorks.filter(rw => {

            return rw?.equipment?.equipmentType?.equipmentCategory?.id === categoryId
        });


        return categoryEW.every(item => item.act)
    };

    componentDidMount(): void {
        const normalized = this.normalizeRegularWorks(this.props.regularWorks);

        this.setState((prevState) => ({
            normalized,
            expandedEqc: null,
            expandedEqt: null,
        }))
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            const {expandedEqc, expandedEqt} = this.props;

            const normalized = this.normalizeRegularWorks(this.props.regularWorks);
            this.setState((prevState) => ({
                normalized,
                expandedEqc: expandedEqc !== undefined ? expandedEqc : prevState.expandedEqc,
                expandedEqt: expandedEqt !== undefined ? expandedEqt : prevState.expandedEqt,
            }))
        }
    }

    render() {
        const {expandedEqc, expandedEqt} = this.state;
        const {isWorkorderClosed} = this.props;
        return this.state.normalized?.map((eqc: NormalizedRW, eqcIndex) => {
            return (
                <div className="checklist-categories-wrapper" key={eqcIndex}>
                    {!this.isAllRwHasAnAct(eqc.equipmentCategory.id) && !isWorkorderClosed &&
                    <div className="act-zone">
                        <span>{getText('acts.act')} №</span>
                        <Input
                            change={(value: string, prevVal: string) => this.changeActNumber(value, prevVal, eqc)}
                            placeholder="acts.actNumber"
                        />
                    </div>
                    }

                    <div className="checklist-checkbox checklist-category-checkbox">
                        <RenderIf condition={!isWorkorderClosed}>
                            <input
                                type="checkbox"
                                checked={this.checkedCategory(eqc)}
                                disabled={isWorkorderClosed}
                                onChange={this.selectAllEqc.bind(this, eqc)}
                            />
                        </RenderIf>
                        <RenderIf condition={isWorkorderClosed && this.checkedCategory(eqc)}>
                            <i className="fa fa-check checklist-icons"></i>
                        </RenderIf>
                        <RenderIf condition={isWorkorderClosed && !this.checkedCategory(eqc)}>
                            <i className="fa fa-fast-backward checklist-icons"></i>
                        </RenderIf>
                        <span
                            className="checklist-category-name"
                            onClick={this.expandCategory.bind(this, eqc.equipmentCategory)}
                        >
                            {eqc.equipmentCategory.name}
                        </span>
                        <RenderIf condition={
                            !eqc.equipmentTypes?.map(el => el.regularWorks?.map(el => el.regularStatus?.id)).flat().some(el => {
                                return el == this.props.settings?.regularWorkDoneStatusId || el == this.props.settings?.declineRegularStatusId
                            }) && !isWorkorderClosed
                        }>
                            <Tooltip title="Cнять регламентную работу">
                                <i onClick={() => this.props.removeHandler(eqc.equipmentTypes?.map(el => el.regularWorks?.map(el => el.id)).flat())}
                                   className="fa fa-ban wo-checklist_remove-work-button"/>
                            </Tooltip>
                        </RenderIf>

                        <RenderIf condition={expandedEqc === eqc.equipmentCategory.name && eqc.equipmentTypes?.length}>


                            <div className="act-checklist">


                                <div className="checklist-types">
                                    {eqc.equipmentTypes?.map((eqt, eqtIndex) => (
                                        <div className="checklist-checkbox checklist-type-checkbox" key={eqtIndex}>
                                            <RenderIf condition={!isWorkorderClosed}>
                                                <input
                                                    type="checkbox"
                                                    disabled={isWorkorderClosed}
                                                    checked={this.checkedType(eqt)}
                                                    onChange={this.selectAllEqt.bind(this, eqt, eqc.equipmentCategory.id)}
                                                />
                                            </RenderIf>
                                            <RenderIf condition={isWorkorderClosed && this.checkedType(eqt)}>
                                                <i className="fa fa-check checklist-icons"></i>
                                            </RenderIf>
                                            <RenderIf condition={isWorkorderClosed && !this.checkedType(eqt)}>
                                                <i className="fa fa-fast-backward checklist-icons"></i>
                                            </RenderIf>

                                            <span className="checklist-type-name"
                                                  onClick={this.expandType.bind(this, eqt.equipmentType)}>
                                                {eqt.equipmentType}
                                            </span>
                                            <RenderIf condition={
                                                !eqt.regularWorks.some(el => {
                                                    return el.regularStatus?.id == this.props.settings?.regularWorkDoneStatusId || el.regularStatus?.id == this.props.settings?.declineRegularStatusId
                                                }) && !isWorkorderClosed
                                            }>
                                                <Tooltip title="Cнять регламентную работу">
                                                    <i onClick={() => this.props.removeHandler(eqt.regularWorks.map(el => el.id))}
                                                       className="fa fa-ban wo-checklist_remove-work-button"/>
                                                </Tooltip>
                                            </RenderIf>
                                            <RenderIf
                                                condition={expandedEqt === eqt.equipmentType && eqt.regularWorks?.length}>
                                                {eqt.regularWorks?.map((rw: RegularWork, rwIndex) => {

                                                    const name = <>{rw.equipmentWork.name} {<b
                                                        className="rw-number">(№{rw.number})</b>}</>;

                                                    return <div className="checklist-checkbox checklist-rw-checkbox"
                                                                key={rwIndex}>
                                                        <div className="checklist-rw-checkbox__name">
                                                            <RenderIf condition={!isWorkorderClosed}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.checkedRw(rw)}
                                                                    disabled={isWorkorderClosed}
                                                                    onChange={this.selectRw.bind(this, rw, eqc.equipmentCategory.id)}
                                                                />
                                                            </RenderIf>
                                                            <RenderIf condition={isWorkorderClosed && this.checkedRw(rw)}>
                                                                <i className="fa fa-check checklist-icons"></i>
                                                            </RenderIf>
                                                            <RenderIf condition={isWorkorderClosed && !this.checkedRw(rw)}>
                                                                <i className="fa fa-fast-backward checklist-icons"></i>
                                                            </RenderIf>
                                                            {rw.equipmentWork.name}

                                                            {!rw.act && (
                                                                <span
                                                                    className="remove"
                                                                    onClick={this.props.deleteRw.bind(null, [rw.id])}
                                                                >x</span>
                                                            )}
                                                        </div>
                                                        <div className="checklist-rw-checkbox__numbers">
                                                            <p>{getText('regularWorks.regularWorksShort')}:
                                                                № {rw.number}</p>
                                                            {rw.act && <p>{getText('acts.act')}: № {rw.act.number}</p>}
                                                        </div>
                                                        <RenderIf
                                                            condition={!isWorkorderClosed && rw.regularStatus?.id != this.props.settings?.regularWorkDoneStatusId && rw.regularStatus?.id != this.props.settings?.declineRegularStatusId}>
                                                            <Tooltip title={'Cнять регламентную работу'}>
                                                                <i onClick={() => this.props.removeHandler([rw.id])}
                                                                   className="fa fa-ban wo-checklist_remove-work-button"/>
                                                            </Tooltip>
                                                        </RenderIf>


                                                    </div>
                                                })}
                                            </RenderIf>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </RenderIf>
                    </div>
                </div>
            )
        }) || null
    }

}

const mapStateToProps = (state) => ({
    settings: state.application.settings
})

export default connect(mapStateToProps)(ChecklistTreeController)