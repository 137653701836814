import React from 'react';
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {getEquipmentCategoriesList, getEquipmentTypesList, getEquipmentWorksList} from "../../../utils/functions";
import {createWorkGroup, filterParams, getWorkGroups, ModulesResponse, WorkGroup} from "@justpro/terminal";
import {CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";
import WorkGroupCreate from "./workGroup.create";
import WorkGroupController from "./workGroup.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class WorkGroupsController extends React.Component<Props>{

    renderItem = (item:WorkGroup) => <>{item.name}</>;

    getCheckboxesMap = async ():Promise<CheckboxesMap> => {

        const result:CheckboxesMap = {};

        if(this.props.rights?.['references.equipmentWorks']?.read) {
            const equipmentWorks = await getEquipmentWorksList();

            result[filterParams.EQUIPMENT_WORKS] = {
                name : "equipmentWorks.equipmentWork",
                children : equipmentWorks.map(({id, name}) => ({id, name}))
            }
        }

        if(this.props.rights?.['references.equipmentCategories']?.read) {
            const equipmentCategories = await getEquipmentCategoriesList();
            result[filterParams.EQUIPMENT_CATEGORIES] = {
                name : "equipmentCategories.equipmentCategory",
                children : equipmentCategories.map(({id, name}) => ({id, name}))
            }
        }

        if(this.props.rights?.['references.equipmentTypes']?.read) {
            const equipmentTypes = await getEquipmentTypesList();
            result[filterParams.EQUIPMENT_TYPES] = {
                name : "equipmentTypes.equipmentType",
                children : equipmentTypes.map(({id, name}) => ({id, name}))
            }
        }

        return result;
    };

    render () {

        const rights = this.props.rights && this.props.rights['references.workGroups'];

        return <BaseReferenceController
            getList={getWorkGroups}
            Detail={WorkGroupController}
            create={{
                handler : createWorkGroup,
                Component : WorkGroupCreate,
                title : 'workGroups.addItem'
            }}
            referenceName="workGroups.moduleName"
            listItem={{
                render : this.renderItem
            }}
            filter={{
                filterName : filterNames.EQUIPMENT_GROUPS,
                getCheckboxesMap: this.getCheckboxesMap
            }}
            moduleRights={rights}
        />

    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(WorkGroupsController)

