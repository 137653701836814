import React from "react";
import {CloseType, editCloseType, getBrand, getCloseType} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import CloseTypeDetail from "./closeType.detail";

interface Props  {
    id: number
    afterUpdate?(item:CloseType) : void
}
interface State  {
    closeType? : CloseType
    loading : boolean
}



class CloseTypeController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };

    updateCloseType = async (data:Partial<CloseType>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const closeType = await editCloseType(this.props.id, data);

            afterUpdate && afterUpdate(closeType);

            this.setState(() => ({loading : false, closeType}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getCloseType = async () => {
        this.setState(() => ({loading : true}));

        try {
            const closeType = await getCloseType(this.props.id);

            this.setState(() => ({loading : false, closeType}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getCloseType()
        }
    }

    async componentDidMount() {
        this.getCloseType();
    }

    render () {
        const {closeType} = this.state;

        return (
            <>
                <RenderIf condition={closeType !== undefined}>
                    <CloseTypeDetail closeType={closeType as CloseType} updateCloseType={this.updateCloseType} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default CloseTypeController
