import React from 'react';
import moment from "moment";
import {
    User,
    OrdersWorkorder,
    ChecklistWorkorder,
    removeWorkorderCoworkers,
    addWorkorderCoworkers,
    IUpdateWorkorder,
    Person,
    OrderListItem,
    ISaveWorkorder,
    saveWorkorder,
    closeWorkorder,
    IAct,
    verifyActsNumbers,
    ISaveWorkorderOrders,
    format,
    printWorkorder,
    OrderStatus,
    sendWorkorderEmail,
    getFullName,
    getWorkorderHistory,
    Workorder, History, getWorkorderActs
} from "@justpro/terminal";
import Select from "../UI/select/select";
import {LoadReturn, Option} from "../UI/select/select.types";
import {getExecutorsList, getPersonsList} from "../../utils/functions";
import './workOrders.css'
import ChecklistWorkOrder from "./workOrder.checklist";
import WorkOrderAdmin from "./workOrder.admin";
import OrdersWorkOrder from "./workOrder.order";
import RenderIf from "../../utils/renderIf";
import checkError from "../../utils/checkError";
import TextSwitcher from "../UI/toggleSwitch/textSwticher/textSwitcher.controller";
import Button from "../UI/button/button";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import Dropdown from "../UI/dropdownMenu/dropdown.controller";
import InfoBlock from "../UI/infoBlock/infoBlock.controller";
import withLabel from "../UI/withLabel/withLabel";
import Table from "../UI/table/table";
import {getUniqueList} from "../../utils/getUniqueArray";
import SaveWorkOrderModal, {SaveWorkorderModal} from "./saveWorkOrder.modal";
import PersonInfo from "../UI/dialog/personInfo/personInfo.controller";
import {toast} from "react-toastify";
import WorkOrderNewActsModal from "./workOrder.newActsModal";

import {ISaveWorkorderRegularWorks} from "@justpro/terminal";
import {confirm} from "../UI/confirmAction/confirmAction.controller";
import FileDownload from "js-file-download";
import DeleteCell from "../UI/table/deleteCell";

import {UpdateWorkorderItem} from "./workOrder.controller";
import {getObjectName} from "../../utils/names";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";
import SendEmailModal from "./sendEmail.modal";
import Spinner from "../UI/spinner/spinner.controller";
import {openModal, updateModal} from "../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";
import BlockController from '../UI/infoBlock/block.controller'
import InlineCalendar from "../UI/calendar/views/inline.controller";
import Tabs from "../UI/tabs/tabs.controller";
import Comments from "../UI/comments/comments.controller";
import Files from "../UI/fileManager/files.controller";
import ModuleHistory from "../UI/moduleHistory/history.controller";
import TaskOrders from "../tasks/task.orders";
import AsyncSelect, {CustomActOption} from "../UI/select/asyncSelect_v2";
import GetText from "../../localization/getText";
import ActListItem from "../acts/act/act.listItem";

interface Props {
    workOrder: OrdersWorkorder | ChecklistWorkorder,

    updateWorkOrder(data?: Partial<IUpdateWorkorder>): any

    reloadWorkOrder?(workOrderId: number): Promise<any>

    updateItem(data: UpdateWorkorderItem): void

    updateListItem?(workorder: Workorder): any

    openModal(props: Modal): void

    requestWorkOrder?(): void

    toggleLoading?(value?: boolean): void

    rights: any
    settings: any

    // updateOrder(data:UpdateOrderData) : void
}


interface ExistMap {
    [actNumber: string]: boolean
}

export interface OrdersSave {
    [orderId: number]: {
        order: OrderListItem,
        actNumber: string

        partial?: boolean
    }
}

export interface RegularWorkSave {
    [categoryId: number]: {
        actNumber: string
        regularWorkId: number[]
    }
}

interface State {
    saveMode: boolean,
    coworkers: Person[]
    acts: IAct[]
    history: History[]

    regularWorksSave?: RegularWorkSave,
    ordersSave?: OrdersSave,

    loading: boolean

    actRegularWorks?: {
        [key: string]: number[]
    }

    ordersToCreateAct?: OrdersSave
    regularWorksToCreateAct?: ISaveWorkorderRegularWorks[]
    newActNumbers: string[]

}


interface VerifyExistsRegularWorks {
    toCreateAct?: RegularWorkSave
    toExtendsAct?: RegularWorkSave
}

interface VerifyExistsActOrders {
    toCreateAct?: OrdersSave
    toExtendsAct?: OrdersSave
}


export const expiredTimeModalId = 'expiredTimeModal';
export const sendEmailModalId = 'sendEmailModal';

class WorkOrderDetail extends React.Component<Props, State> {
    state: State = {
        saveMode: false,
        coworkers: [],
        loading: false,
        newActNumbers: [],
        acts: [],
        history: []
    };

    static defaultProps = {
        toggleLoading: () => {
        }
    }

    get coworkersColumns() {
        const columns: any = [
            {
                Header: getText('persons.name'),
                accessor: 'firstName'
            },
            {
                Header: getText('persons.lastName'),
                accessor: 'lastName'
            },
            {
                Header: getText('persons.patronymic'),
                accessor: 'patronymic'
            }
        ];
        if (!this.props.workOrder?.closed) {
            columns.push({
                Header: getText('common.delete'),
                Cell: (props: any) => <DeleteCell deleteHandler={this.removeCoworker} item={props}/>
            });
        }
        return columns;
    }

    get tabs() {
        const {rights} = this.props;
        const result: any[] = [];
        if (rights?.['acts']?.read) {
            result.push(
                {
                    title: "acts.acts",
                    component: () => {
                        const {acts} = this.state;
                        return <>
                            {acts.length === 0 ?
                                <div className="task-orders__no-orders">{GetText('UI.tabAct.unlinked')}</div> :
                                acts.map((item: IAct) => {
                                    return <ActListItem
                                        act={item}
                                        withModuleDetail={true}
                                        key={item.id}
                                    />
                                })}
                        </>;
                    },
                    onSelectTab: this.getWorkorderActs
                }
            );
        }
        result.push({
            title: "UI.tabs.history",
            component: () => {
                return (
                    <>
                        <ModuleHistory list={this.state.history}/>
                    </>
                )
            },
            onSelectTab: this.getWorkorderHistory
        });

        return result;
    }

    changeTab: ((index: number) => any) | void = void 0;

    onTabsInit = ({changeTab}: any) => {
        this.changeTab = changeTab;
        this.getWorkorderActs()
    }

    getWorkorderActs = async () => {
        try {
            this.setState({
                loading: true
            })
            const acts = await getWorkorderActs(this.props.workOrder?.id);
            this.setState({
                acts
            })
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    getWorkorderHistory = async () => {
        try {
            this.setState({
                loading: true
            })
            const history = await getWorkorderHistory(this.props.workOrder?.id);
            this.setState({
                history
            })
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    changeResponsible = async (option: Option) => {
        const workorder = await this.props.updateWorkOrder({responsibleId: option.value});
        this.props.updateListItem(workorder);
        return Promise.resolve();
    };

    onChangeSaveMode = (item: any) => {
        this.setState((prevState) => ({saveMode: !prevState.saveMode}))
    };


    getVerifiedOrders = (ordersSave: OrdersSave, existsMap: ExistMap): VerifyExistsActOrders => {
        return Object.values(ordersSave)?.reduce((acc: VerifyExistsActOrders, item) => {
            if (existsMap.hasOwnProperty(item.actNumber) && existsMap[item.actNumber]) {
                //существует
                return {
                    ...acc,
                    toExtendsAct: acc.toExtendsAct ? {
                        ...acc?.toExtendsAct,
                        [item.order.id]: item
                        // item
                    } : {
                        [item.order.id]: item
                    }
                }
            } else {
                // не существует
                return {
                    ...acc,
                    toCreateAct: acc.toCreateAct ? {
                        ...acc?.toCreateAct,
                        [item.order.id]: item
                        // item
                    } : {
                        [item.order.id]: item
                    }
                }
            }
        }, {}) || {}
    };

    getVerifiedRegularWorks = (regularWorksSave: RegularWorkSave, existsMap: ExistMap): VerifyExistsRegularWorks => {
        console.log({regularWorksSave, existsMap});


        return Object.entries(regularWorksSave).reduce((acc: VerifyExistsRegularWorks, [categoryId, item]) => {

            if (existsMap.hasOwnProperty(item.actNumber) && existsMap[item.actNumber]) {
                //существует
                return {
                    ...acc,
                    toExtendsAct: acc.toExtendsAct ? {
                        ...acc.toExtendsAct,
                        [categoryId]: item
                    } : {
                        [categoryId]: item
                    }
                }
            } else {
                //не существует
                return {
                    ...acc,
                    toCreateAct: acc.toCreateAct ? {
                        ...acc.toCreateAct,
                        [categoryId]: item
                    } : {
                        [categoryId]: item
                    }
                }
            }


        }, {});
    };

    saveWorkOrderButtonHandler = async () => {
        const {ordersSave, regularWorksSave} = this.state;

        let uniqueActNumbers: string[] = [];

        // 1. Получить уникальные номера актов
        if (ordersSave !== undefined) {
            const ordersSaveValues = Object.values(ordersSave);

            uniqueActNumbers = ordersSaveValues.reduce((acc: string[], item) => {
                if (!acc.includes(item.actNumber) && item.actNumber?.length) {
                    acc.push(item.actNumber)
                }
                return acc
            }, [])
        }

        if (regularWorksSave !== undefined) {
            const regularWorksSaveValues = Object.values(regularWorksSave);

            uniqueActNumbers = regularWorksSaveValues.reduce((acc: string[], item) => {
                if (!acc.includes(item.actNumber)) {
                    acc.push(item.actNumber)
                }
                return acc
            }, [])
        }

        if (uniqueActNumbers.length === 0) {
            if (!this.state.saveMode) {
                toast.warn(renderToString(getText('workorders.actNumberIsRequired')));
            } else {
                try {
                    this.props.toggleLoading(true);
                    const closed = await closeWorkorder(this.props.workOrder.id);

                    if (closed !== undefined) {
                        this.props.updateWorkOrder({closed: !!closed})
                    }

                } catch (e) {
                    checkError(e)
                } finally {
                    this.props.toggleLoading(false);
                }
            }
        }


        try {
            this.props.toggleLoading(true);
            const existsActs = await verifyActsNumbers(uniqueActNumbers.filter((n) => n?.length));
            const existsActsEntries = Object.entries(existsActs);

            const question = `${renderToString(getText('workorders.actsWithNumbers'))} ${existsActsEntries.reduce((acc: string, [actNumber, isExist]) => {

                if (isExist) return `${acc} ${actNumber}, `;

                return acc
            }, '')} ${renderToString(getText('workorders.alreadyExists'))}`;

            const newActNumbers = existsActsEntries.reduce((acc: string[], [actNumber, isExist]) => {
                if (!isExist) {
                    acc.push(actNumber)
                }

                return acc
            }, []);

            if (ordersSave !== undefined) {
                const {toCreateAct, toExtendsAct} = this.getVerifiedOrders(ordersSave, existsActs);
                const toExtendValues = Object.values(toExtendsAct || {});
                console.log({toCreateAct, toExtendsAct})

                if (toExtendValues.length > 0) {
                    const confirmed = await confirm({
                        question
                    });
                    if (!confirmed) return;
                    if (confirmed) {
                        const result = toExtendValues.map(item => {
                            return {
                                act: {number: item.actNumber},
                                partial: item.partial,
                                id: item.order.id
                            } as ISaveWorkorderOrders
                        });
                        await this.save({orders: result}, 'edit');
                    }
                }

                if (Object.values(toCreateAct || {}).length > 0) {
                    this.openCreateWorkorderModal(newActNumbers);

                    this.setState(() => ({
                        ordersToCreateAct: toCreateAct,
                    }))
                }
            }

            if (regularWorksSave !== undefined) {
                const {toCreateAct, toExtendsAct} = this.getVerifiedRegularWorks(regularWorksSave, existsActs);
                const toExtendValues = Object.values(toExtendsAct || {});
                const toCreateValues = Object.values(toCreateAct || {});


                const getResultRegularWorks = (array: any[]): ISaveWorkorderRegularWorks[] => {
                    return array.reduce((acc, item) => {
                        item.regularWorkId.forEach((regularWorkId: number) => {
                            acc.push(
                                {
                                    id: regularWorkId,
                                    act: {
                                        number: item.actNumber
                                    }
                                }
                            )
                        });
                        return acc;
                    }, []);
                };


                if (toExtendValues.length > 0) {
                    const confirmed = await confirm({
                        question
                    });
                    if (!confirmed) return;
                    if (confirmed) {
                        const result: ISaveWorkorderRegularWorks[] = getResultRegularWorks(toExtendValues);

                        await this.save({regularWorks: result}, 'edit')

                    }
                }

                if (toCreateValues.length > 0) {

                    const result: ISaveWorkorderRegularWorks[] = getResultRegularWorks(toCreateValues);

                    this.setState(() => ({
                        // newActNumbers,
                        regularWorksToCreateAct: result,
                    }));

                    this.openCreateWorkorderModal(newActNumbers);
                }
            }

        } catch (e) {
            checkError(e)
        }
    };


    openCreateWorkorderModal = (actNumbers: string[]) => {
        this.props.openModal({
            id: expiredTimeModalId,
            component: (props: ModalBodyProps) => (
                <SaveWorkOrderModal
                    {...props}
                    save={this.saveWorkOrder}
                    coworkers={this.state.coworkers}
                    actNumbers={actNumbers}
                    responsible={this.props.workOrder.responsible}
                />
            ),
            minHeight: '60vh',
            title: getText('workorders.timeExpended'),
            size: "large",
        });
    }


    save = async (data: ISaveWorkorder, mode: 'create' | 'edit') => {
        try {
            const {workorder, acts, warnings} = await saveWorkorder(this.props.workOrder.id, data, this.state.saveMode);

            await this.props.toggleLoading(true);
            if (workorder) {
                await this.props.updateItem({
                    orders: workorder.orders,
                    regularWorks: workorder.regularWorks
                });
            }


            if (warnings?.length) {
                warnings.map((warning) => {
                    toast.warn(warning);
                })
            }

            if (acts.length > 0) {
                this.props.openModal({
                    id: "viewOfCreatedActs",
                    component: () => (
                        <WorkOrderNewActsModal
                            acts={acts}
                        />
                    ),
                    title: mode === 'edit' ? getText('workorders.viewOfActCreated') : getText('workorders.viewOfActEdited')
                })
            }
        } catch (e) {
            checkError(e)
        } finally {
            await this.props.toggleLoading(false);
        }
    };


    saveWorkOrder = async (data: SaveWorkorderModal[]) => {
        try {
            const {ordersToCreateAct, regularWorksToCreateAct} = this.state;
            await this.props.toggleLoading();
            if (ordersToCreateAct !== undefined) {

                const orderToCreateActValues = Object.values(ordersToCreateAct);

                const result = orderToCreateActValues.reduce((acc: ISaveWorkorderOrders[], item) => {

                    const finded = data.find(i => i.actNumber === item.actNumber);

                    if (finded) {
                        acc.push({
                            id: item.order.id,
                            partial: item.partial,
                            act: {
                                number: finded.actNumber,
                                date: moment(finded.date).format(format.date),
                                closeType: finded.closeType,
                                coworkersTime: finded.people.map(p => {
                                    return {
                                        personId: p.id,
                                        executedTime: p.executedTime
                                    }
                                })
                            }
                        })
                    }

                    return acc
                }, []);

                await this.save({orders: result}, 'create')
            }

            if (regularWorksToCreateAct !== undefined) {
                const toSaveRW = regularWorksToCreateAct.reduce((acc: ISaveWorkorderOrders[], item) => {

                    const finded = data.find(i => i.actNumber === item?.act?.number);

                    if (finded) {
                        acc.push({
                            ...item,
                            act: {
                                number: finded.actNumber,
                                date: moment(finded.date).format(format.date),
                                closeType: finded.closeType,
                                coworkersTime: finded.people.map(p => {
                                    return {
                                        personId: p.id,
                                        executedTime: p.executedTime
                                    }
                                })
                            }
                        })
                    }

                    return acc
                }, []);


                await this.save({regularWorks: toSaveRW}, 'create')

            }
        } catch (e) {
            checkError(e)
        } finally {
            await this.props.toggleLoading(false);
        }
    };

    createFileOptions = (): DropDownItem[] => {
        const fileOptions: DropDownItem[] = [
            {
                name: 'UI.dropDown.chooseAction',
                id: 1,
            }
        ];
        //Разделение на будущее для проверки прав ...
        fileOptions.push({
            name: 'UI.dropDown.pdf',
            id: 2,
            icon: 'fa-floppy-o',
        });
        fileOptions.push({
            name: 'UI.dropDown.sendEmail',
            id: 3,
            icon: 'fa fa-envelope',
            handler: this.openSendEmailModal
        });
        fileOptions.push({
            name: 'UI.dropDown.print',
            id: 4,
            icon: 'fa fa-print',
        });

        return fileOptions;
    };

    changeCoExecutors = async (option: Option) => {
        try {
            const coworkers = await addWorkorderCoworkers(this.props.workOrder.id, [option.value]);

            this.setState((prevState) => ({
                coworkers: [...prevState.coworkers, ...coworkers]
            }))

        } catch (e) {
            checkError(e);
        }

        return false
        // return Promise.resolve();
    };

    getDateFormat = (user: User, date?: string) => {
        const {person} = user;
        return (<>
            <PersonInfo position="down"
                        person={user.person}/> {getText('common.in')} {date ? moment(date).format('DD.MM.YYYY HH:mm') : null}
        </>);
    };

    onChangeFileOptions = async (item: DropDownItem) => {
        try {
            // TODO: Убрать привязку к id, переделать на item.handler
            const {responsible, number, object} = this.props.workOrder;
            if (item?.id === 2) {
                const pdf = await printWorkorder(this.props.workOrder.id);
                FileDownload(pdf,
                    `${responsible.firstName.slice(0, 1)}.${responsible.lastName}-${getObjectName(object)}-${number}.pdf`);
                return
            }
            if (item?.id === 4) {
                const pdf = await printWorkorder(this.props.workOrder.id);
                const url = URL.createObjectURL(pdf);
                window.open(url, '_blank');
                return
            }
            return item?.handler && item?.handler();
        } catch (err) {
            checkError(err);
        }
    };

    onChangePartialOrder = (order: OrderListItem, value: boolean) => {
        this.setState((prevState) => ({
            ...prevState,
            ordersSave: {
                ...prevState.ordersSave,
                [order.id]: {
                    order,
                    partial: value,
                    actNumber: prevState.ordersSave ? prevState.ordersSave[order.id]?.actNumber : undefined,
                }
            }
        }))
    };

    onChangeActNumberOrder = async (order: OrderListItem, value: string) => {

        const {ordersSave} = this.state;


        const updateOrders = () => {
            this.setState((prevState) => ({
                ...prevState,
                ordersSave: {
                    ...prevState.ordersSave,
                    [order.id]: {
                        order: order,
                        partial: prevState.ordersSave ? prevState.ordersSave[order.id]?.partial : undefined,
                        actNumber: value
                    }
                }
            }));
        };


        if (ordersSave !== undefined) {
            if (value.trim() === '') {
                const rest = Object.entries(ordersSave)
                    .filter(([id]) => +id !== order.id);

                const map = Object.fromEntries(rest);

                this.setState(() => ({
                    ordersSave: map
                }))
            } else {
                updateOrders()
            }
        } else {
            updateOrders()
        }

    };

    onChangeRegularWorkCheckbox = (categoryId: number, active: number[]) => {

        this.setState((prevState) => ({
            ...prevState,
            regularWorksSave: {
                ...prevState.regularWorksSave,
                [categoryId]: {
                    // ...prevState.regularWorksSave[categoryId].regularWorkId,
                    regularWorkId: active,
                    actNumber: prevState.regularWorksSave &&
                        prevState.regularWorksSave[categoryId] &&
                        prevState.regularWorksSave[categoryId].actNumber || ''
                }
            }
        }), () => {
            console.log(this.state.regularWorksSave)
        });

    };

    onChangeActNumberRegularWork = async (categoryId: number, value: string) => {

        this.setState((prevState) => ({
            ...prevState,
            regularWorksSave: {
                ...prevState.regularWorksSave,
                [categoryId]: {
                    // ...prevState.regularWorksSave[categoryId].regularWorkId,
                    regularWorkId: (
                        prevState.regularWorksSave &&
                        prevState.regularWorksSave[categoryId] &&
                        prevState.regularWorksSave[categoryId].regularWorkId) || [],
                    actNumber: value
                }
            }
        }));
    };

    removeCoworker = async (props: any) => {
        const id = props.original.id;

        try {
            const removed = await removeWorkorderCoworkers(this.props.workOrder.id, [id]);
            this.setState((prevState) => ({
                coworkers: prevState.coworkers.filter(item => item.id !== id)
            }))
        } catch (e) {
            checkError(e)
        }
    };

    getUniqueCoworkers = async () => {
        const allPersons = await getPersonsList();
        return getUniqueList(allPersons, this.state.coworkers)
    };


    resetState = () => {
        this.setState(() => ({
            coworkers: this.props.workOrder.coworkers || [],
            ordersSave: undefined,
            regularWorksSave: undefined,
            saveMode: false
        }))
    };

    openSendEmailModal = () => {
        this.props.openModal({
            id: sendEmailModalId,
            component: (props: ModalBodyProps) => (
                <SendEmailModal {...props} save={this.sendEmail}/>
            ),
            title: "Отправка печатной формы наряда на email",
            size: "large"
        });
    }

    sendEmail = async ({title, message, email}: any) => {
        try {
            this.setState({
                loading: true,
            });
            await sendWorkorderEmail(this.props.workOrder.id, {
                title,
                message,
                email
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
        return true;
    }

    changeDate = async (date) => {
        const workorder = await this.props.updateWorkOrder({date: date.format("YYYY-MM-DD")});
        this.props?.updateListItem?.(workorder);
    }

    componentDidMount() {
        this.resetState()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(prevProps.workOrder) !== JSON.stringify(this.props.workOrder)) {
            this.resetState();
        }
    }

    get blockItems() {
        const {workOrder} = this.props;

        const result: any[] = [
            {
                key: getText('workorders.workorder'),
                value: workOrder?.number || ''
            },
            {
                key: getText('common.status'),
                value: getText(workOrder.closed ? 'workorders.closed' : 'workorders.open')
            },

        ];

        if (workOrder.date && workOrder.closed) {
            result.push({
                key: getText('common.date'),
                value: moment(workOrder.date).format('DD.MM.YYYY')
            })
        }

        if (workOrder.createdBy) {
            result.push({
                key: getText('common.createdBy'),
                value: this.getDateFormat(workOrder.createdBy, workOrder.createdAt)
            })
        }


        if (workOrder.lastUpdatedBy) {
            result.push({
                key: getText('common.lastUpdate'),
                value: this.getDateFormat(workOrder.lastUpdatedBy, workOrder.updatedAt)
            })
        }

        result.push({
            key: getText('objects.object'),
            value: getObjectName(workOrder?.object)
        });

        return result;
    }

    getExecutorsList = (q: string): LoadReturn => {
        const {settings} = this.props;
        return new Promise(async resolve => {
            const persons = await getExecutorsList(q, [+settings.tfmContractorId]);
            resolve(persons.map((p) => ({
                label: getFullName(p),
                value: p.id,
                ...p
            })));
        })
    };

    render() {
        const {workOrder, rights} = this.props;

        const {date, responsible, createdAt, updatedAt, createdBy, lastUpdatedBy, number, type, closed} = workOrder;
        const {coworkers, loading} = this.state;


        const exec = responsible ? {
            ...responsible,
            name: `${responsible.firstName} ${responsible.lastName} ${responsible.patronymic ? responsible.patronymic : ''}`,
        } : undefined;

        const dropdownOptions = this.createFileOptions();

        const isCoworkers = coworkers && coworkers.length > 0;

        return (

            <>
                <div className="workorders_button-groups"
                     style={{marginBottom: this.props?.workOrder?.closed ? '16px' : '0'}}>
                    <Spinner loading={loading}/>
                    <RenderIf condition={type !== 'admin'}>
                        <Dropdown
                            onChange={this.onChangeFileOptions}
                            list={dropdownOptions}
                            disableChangeActive
                            preventToDefault={dropdownOptions[0]}
                        />
                        <RenderIf condition={!closed}>
                            <div className="save-zone">
                                <TextSwitcher
                                    id={this.props.workOrder.id}
                                    title="workorders.saveRule"
                                    values={[
                                        {
                                            text: 'workorders.justSave'
                                        },
                                        {
                                            text: 'workorders.saveAndClose'
                                        },
                                    ]}
                                    changeHandler={this.onChangeSaveMode}
                                />

                                <div className="button-child">
                                    <Button onClick={this.saveWorkOrderButtonHandler}
                                            className="btn-success">{getText('common.save')}</Button>
                                </div>
                            </div>
                        </RenderIf>
                    </RenderIf>
                </div>

                <InfoBlock>
                    <BlockController
                        items={this.blockItems}
                    />

                    <BlockController>
                        <Select
                            change={this.changeResponsible}
                            label="common.responsible"
                            accessors={{
                                name: 'label',
                                id: 'value'
                            }}
                            load={this.getExecutorsList}
                            defaultValue={exec}
                            labelPosition="top"
                            isDisabled={closed}
                        />
                        <RenderIf condition={!workOrder.closed}>
                            <InlineCalendar date={moment(date)}
                                            onChange={this.changeDate}
                                            isDisabled={!rights?.["workorders.date"]?.edit}
                                            label={{text: "common.date", position: "top"}}/>
                        </RenderIf>
                    </BlockController>

                </InfoBlock>

                {(isCoworkers || !closed) && withLabel(
                    <>
                        <RenderIf condition={isCoworkers}>
                            <Table
                                data={coworkers ? coworkers : []}
                                columns={this.coworkersColumns}
                            />
                        </RenderIf>

                        <RenderIf condition={!closed}>
                            <Select
                                change={this.changeCoExecutors}
                                placeholder="UI.select.placeholders.selectCoworkers"
                                className={isCoworkers ? "margin-top" : ''}
                                labelPosition="top"
                                accessors={{
                                    name: 'label',
                                    id: 'value'
                                }}
                                withoutValue={true}
                                load={this.getUniqueCoworkers}
                            />
                        </RenderIf>

                    </>
                )({position: 'left', text: 'common.coworkers'})
                }

                <RenderIf condition={type === 'admin'}>
                    <WorkOrderAdmin
                        workOrder={workOrder as OrdersWorkorder}
                        orders={(workOrder as OrdersWorkorder).orders}
                    />
                </RenderIf>

                <RenderIf condition={type === 'orders'}>
                    <OrdersWorkOrder
                        workOrder={workOrder as OrdersWorkorder}
                        orders={(workOrder as OrdersWorkorder).orders}
                        onChangeActNumber={this.onChangeActNumberOrder}
                        onChangePartial={this.onChangePartialOrder}
                    />
                </RenderIf>

                {type === 'checklist' ? (
                    <ChecklistWorkOrder
                        workOrder={workOrder as ChecklistWorkorder}
                        regularWorks={(workOrder as ChecklistWorkorder).regularWorks}
                        onChangeActNumber={this.onChangeActNumberRegularWork}
                        onChangeRegularWorkCheckbox={this.onChangeRegularWorkCheckbox}
                        requestWorkOrder={this.props.requestWorkOrder}
                    />
                ) : null}
                <Tabs tabs={this.tabs} defaultIndex={0} onInit={this.onTabsInit}/>
            </>
        )
    }
}

const mapDispatch = (d: Function) => ({
    openModal: (props) => d(openModal(props))
});

const mapState = (state) => ({
    rights: state?.application?.rights,
    settings: state?.application?.settings
})
export default connect(mapState, mapDispatch)(WorkOrderDetail)
