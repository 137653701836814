import React from "react";
import TextArea from "../UI/textArea/textArea.controller";
import Input from "../UI/input/text";
import withLabel from "../UI/withLabel/withLabel";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";
import Button from "../UI/button/button";
import getText from "../../localization/getText";

interface Props {
    hide() : void
    save({}: State): any
}

interface State {
    email: string
    title: string
    message: string
}

class SendEmailModal extends React.Component<Props, State> {
    state: State = {
        email: '',
        title: '',
        message: ''
    };

    isDisabled = (prevState?:State) => {
        const {title, email, message} = prevState ? prevState : this.state;
        return !(title?.length && email?.length && message?.length);
    };

    changeField(field: 'email' | 'title' | 'message', value: string) {
        // @ts-ignore
        this.setState({
            [field]: value
        });
    }

    sendEmail = () => {
        this.props.save(this.state);
    };

    render() {

        return <>
            {withLabel(
                <Input placeholder="Email"
                       value={this.state.email}
                       change={this.changeField.bind(this, "email")}
                />)({
                text: "Email",
                position: "top"
            })}
            {withLabel(
                <Input placeholder="Тема"
                       value={this.state.title}
                       change={this.changeField.bind(this, "title")}
                />)({
                text: "Тема",
                position: "top"
            })}
            {withLabel(
                <TextArea
                    placeholder="Дополнительный комментарий"
                    change={this.changeField.bind(this, "message")}
                />)({
                text: "Комментарий",
                position: "top"
            })}


            <DefaultFooterModal>
                <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                <Button className="btn-success" onClick={this.sendEmail} disabled={this.isDisabled()}>{getText('common.save')}</Button>
            </DefaultFooterModal>

        </>

    }
}

export default SendEmailModal