import React from "react";
import '../act.css'
import Button from "../../UI/button/button";
import {
    Object as IObject,
    ICoworker,
    format,
    addActCoworkers,
    deleteActCoworker,
    IExecutedTime, updateActCoworker, ModulesResponse
} from "@justpro/terminal";
import Table from "../../UI/table/table";
import moment from "moment";
import Select from "../../UI/select/select";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {getPersonsList} from "../../../utils/functions";
import {getUniqueList} from "../../../utils/getUniqueArray";
import checkError from "../../../utils/checkError";
import ExecutedTimeController from "../../UI/executedTime/executedTime.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";


interface Executors extends ICoworker {
    isNew? : boolean
}

interface Props {
    actId : number
    object : IObject

    coworkers?: ICoworker[],

    rights? : Partial<ModulesResponse>
}


interface State {
    executors : Executors[],
    hasNewItem : boolean
}

class ActTimeExpend extends React.Component<Props, State>{

    state:State = {
        executors: [],
        hasNewItem : false
    };

    addExecutor = () => {
        this.setState((prevState) => ({
            executors : [...prevState.executors, {
                isNew : true,
                firstName : '',
                lastName : '',
                executedTime : [
                    {
                        from : moment().startOf('day').format(format.time),
                        to : moment().startOf('day').format(format.time),
                    }
                ],
                coworkerId : -1,
                id : -1,
                personId: -1,
            }],
            hasNewItem : true,
        }))
    };

    deleteExecutor = async (coworkerId:number) => {


        if(coworkerId === -1) {
            this.setState((prevState) => ({
                executors : prevState.executors.filter(item => !item.isNew),
                hasNewItem : false,
            }));
        }else{
            try {
                const deleted = await deleteActCoworker(this.props.actId, coworkerId);

                if(deleted) {
                    this.setState((prevState) => ({
                        executors : prevState.executors.filter(item => item.id !== coworkerId)
                    }));
                }
            }catch (e) {
                checkError(e)
            }
        }
    };

    changeNewExecutor = async (option:Option) => {
        try {

            const coworker = await addActCoworkers(this.props.actId, {
                personId : option.value,
                executedTime : [{
                    from : moment().startOf('day').format(format.time),
                    to : moment().startOf('day').format(format.time),
                }]
            });

            await this.setState((prevState) => ({
                executors : prevState.executors.map(executor => {
                    if(executor.isNew) {
                        return coworker
                    }

                    return executor;
                }),
                hasNewItem : false,
            }));
            console.log(this.state.executors)
        }catch (e) {
            checkError(e)
        }

        return Promise.resolve();
    };

    getUniquePersons = ():LoadReturn => {
        return new Promise(async resolve => {
            const allPersons = await getPersonsList();

            const unique = getUniqueList(allPersons, this.state.executors, {wholeId : 'id', existId : 'personId'});

            resolve(unique);
        })
    };

    changeExecutionTime = async (time:IExecutedTime[], coworkerId:number) => {

        console.log({time});

        try {
            const updated = await updateActCoworker(this.props.actId, coworkerId, {
                executedTime : time
            });
            // console.log({updated})

            this.setState((prevState) => ({
                executors : prevState.executors.map(executor => {
                    if(executor.id === coworkerId) {
                        return updated
                    }

                    return executor
                })
            }));

        }catch (e) {
            checkError(e)
        }

    };

    get columns() {
        const {rights} = this.props;

        const result:any[] = [];




        return result;

    }

    resetState = () => {

        const {coworkers} = this.props;

        this.setState(() => ({
            executors : coworkers ? coworkers : []
        }))
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.props.actId !== prevProps.actId) {
            this.resetState();
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state)
    }

    componentDidMount(): void {
        this.resetState();
    }


    render() {
        const {...rights} = this.props.rights?.["acts.coworkers"]
        const {executors} = this.state;
        const isExecs = executors.length > 0;

        return (
            <div className="just-pro__act-orders">
                <h4>{getText('acts.timeExpended')}</h4>
                    {isExecs &&
                        <Table
                            data={executors}
                            columns={[
                                {
                                    Header: getText('common.executor'),
                                    Cell: (props: any) => {
                                        const {firstName, lastName, patronymic, isNew} = props.original;
                                        if(isNew) {
                                            return <Select
                                                change={this.changeNewExecutor}
                                                placeholder="UI.select.placeholders.chooseAnEmployee"
                                                load={this.getUniquePersons}
                                                accessors={{
                                                    name : 'label',
                                                    id : 'value'
                                                }}
                                            />
                                        }
                                        return `${lastName} ${firstName} ${patronymic}`
                                    }
                                },
                                {
                                    Header: getText('common.time'),
                                    Cell: (props: any) =>(
                                        <ExecutedTimeController
                                            onChange={(time:IExecutedTime[]) => this.changeExecutionTime(time, props.original.id)}
                                            executedTime={props.original.executedTime} 
                                            disabled={!rights.edit}
                                        />
                                    )
                                },
                                {
                                    Header: getText('common.delete'),
                                    Cell: (props: any) => <DeleteCell disabled={!rights.delete} deleteHandler={this.deleteExecutor} id={props.original.id} />
                                },
                            ]}
                        />
                    }

                    <Button
                        className={isExecs ? "btn-success margin-top" : "btn-success"}
                        onClick={this.addExecutor}
                        disabled={!rights.create || this.state.hasNewItem }
                    >{getText('acts.addExecutor')}</Button>


            </div>

        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(ActTimeExpend)