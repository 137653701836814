import React from "react";
import Select from "../../UI/select/select";
import Input from "../../UI/input/text";
import TransportController from "../transport/transport.controller";
import moment, {Moment} from "moment";
import {addPersonTransport, format, IPersonTransport, updatePersonTransport} from "@justpro/terminal";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import {Option} from "../../UI/select/select.types";
import checkError from "../../../utils/checkError";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";

interface Props {
    personId : number
    hide() : void

    transport? : IPersonTransport
    updateTransports(transports:IPersonTransport, id?:number) : void
}

interface State {
    transport?: Partial<IPersonTransport>
}

class PersonTransportModal extends React.Component<Props, State>{

    state:State = {};

    save = async () => {
        const {transport} = this.props;

        if(transport?.id !== undefined) {
            this.editTransport();
        }else{
            this.addTransport()
        }

        this.props.hide();
    };

    addTransport = async () => {
        if(!this.isDisabled())  {
            try {
                const transport = await addPersonTransport({
                    ...this.state.transport as IPersonTransport,
                    personId : this.props.personId,
                });

                this.props.updateTransports(transport);
            }catch (e) {
                checkError(e)
            }
        }
    };

    editTransport = async () => {

        if(!this.isDisabled()) {

            const {transport} = this.state;

            const {active, name, id, ...rest} = transport as IPersonTransport;
            try {
                const trasport = await updatePersonTransport({
                    personId : this.props.personId,
                    personTransportId : id as number,
                    ...rest
                });

                this.props.updateTransports(trasport, id);
            }catch (e) {
                checkError(e)
            }
        }

    };

    onChangeModel = async (option:Option) => {

        this.setState((prevState) => ({
            transport : {
                ...prevState.transport,
                transportId : option.value,
                name  : option.label
            }
        }));


        return Promise.resolve();
    };


    onChangeCarNumber = (carNumber:string) => {
        this.setState((prevState) => ({
            transport : {
                ...prevState.transport,
                carNumber
            }
        }));
    };


    onInputChange = (value:string, accessor: 'diesel' | 'petrol' | 'gas') => {
        if(value.length > 0) {
            const numeric = parseInt(value);

            if(numeric || numeric === 0) {
                this.setState((prevState) => ({
                    transport: {
                        ...prevState.transport,
                        [accessor]: value
                    }
                }));
            }else{
                checkError(new Error('errors.valueMustBeANumber'));
            }
        } else {
            this.setState((prevState) => ({
                transport : {
                    ...prevState.transport,
                    [accessor] : value
                }
            }))
        }
    };

    onChangeGas = (gas:string) => {
        this.onInputChange(gas, "gas")
    };


    onChangeDiesel = (diesel:string) => {
        this.onInputChange(diesel, "diesel")
    };

    onChangePetrol = (petrol:string) => {
        this.onInputChange(petrol, "petrol")
    };

    onChangeFrom = (from:Moment) => {
        this.setState((prevState) => ({
            transport : {
                ...prevState.transport,
                commissioned : from.format(format.date)
            }
        }));
    };

    onChangeTo = (to:Moment) => {
        this.setState((prevState) => ({
            transport : {
                ...prevState.transport,
                decommissioned : to.format(format.date)
            }
        }));
    };

    isDisabled = (prevState?:State) => {
        const {transport} = prevState ? prevState : this.state;

        return  transport === undefined ||
                transport.transportId === undefined ||
                (transport.gas === undefined || transport.gas.toString().trim() === '') ||
                (transport.petrol === undefined || transport.petrol.toString().trim() === '') ||
                (transport.diesel === undefined || transport.diesel.toString().trim() === '') ||
                transport.commissioned === undefined ||
                transport.decommissioned === undefined
    };

    resetState = () => {
        this.setState(() => ({
            transport : this.props.transport ? this.props.transport : {
                commissioned : moment().format(format.date),
                decommissioned :  moment().add(10, 'y').format(format.date),
            }
        }))
    };

    componentDidMount(): void {
        this.resetState();

    }

    render() {

        const {transport} = this.state;
        console.log({transport});

        const activeTransport:any = {};

        if(transport !== undefined) {
            activeTransport.id = transport.transportId;
            activeTransport.name = transport.name
        }

        return (
            <>
                <div className="person-transport">

                    <InlineCalendar
                        date={transport ? moment(transport.commissioned) : moment()}
                        onChange={this.onChangeFrom}
                        label={{text : "persons.dateStart" }}
                    />

                    <InlineCalendar
                        date={transport ? moment(transport.decommissioned) : moment().add(10, 'y')}
                        onChange={this.onChangeTo}
                        label={{text : "persons.dateEnd" }}
                    />
                    <Select
                        change={this.onChangeModel}
                        load={TransportController.getAll}
                        defaultValue={activeTransport}
                        label="persons.transportModel"
                        accessors={{
                            name : 'label',
                            id : 'value'
                        }}
                    />

                    <Input
                        label="persons.number"
                        change={this.onChangeCarNumber}
                        // onSend={this.onChangeNumber}
                        startValue={transport ? transport.carNumber: undefined}
                    />

                    <Input
                        label="persons.petrol"
                        change={this.onChangePetrol}
                        startValue={transport ? transport.petrol?.toString(): undefined}
                    />

                    <Input
                        label="persons.diesel"
                        change={this.onChangeDiesel}
                        startValue={transport ? transport.diesel?.toString(): undefined}
                    />

                    <Input
                        label="persons.gas"
                        change={this.onChangeGas}
                        startValue={transport ? transport.gas?.toString(): undefined}
                    />

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success" onClick={this.save} disabled={this.isDisabled()}>{getText('common.save')}</Button>
                    </DefaultFooterModal>

                </div>

            </>
        )
    }
}

export default PersonTransportModal