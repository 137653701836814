import { mapActions } from './map.reducer'
import { ModeType, buildRoutesModeType } from './map.types';

export const updateTouched = (touched: boolean) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_TOUCHED,
        touched
    })
};

export const setIsWoCreatorOpen = (isWoCreatorOpen: boolean) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_IS_WO_OPEN,
        isWoCreatorOpen
    })
};

export const setOpenedObject = (object: Object | null) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_OPENED_OBJECT,
        object
    })
};

export const setWoArray = (array: Array<boolean>) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_WO_ARRAY,
        array
    })
};

export const setBpArray = (array: Array<boolean>) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_BP_ARRAY,
        array
    })
};

export const setBpDirections = (directions: any) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_BP_DIRECTIONS,
        directions
    })
};

export const setArrangedWoArray = (arrangedWoArray: Array<boolean>) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_ARRANGED_WO_ARRAY,
        arrangedWoArray
    })
};

export const pushToWoArray = (el: any) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.PUSH_TO_WO_ARRAY,
        el
    })
};

export const removeFromWoArray = (index: number) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.REMOVE_FROM_WO_ARRAY,
        index
    })
};

export const setCurrentExecutor = (executor: any) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_CURRENT_EXECUTOR,
        executor
    })
}

export const toggleModeCheckRoutes = (mode: ModeType) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.TOGGLE_MODE,
        mode
    })
}

export const toggleBuildMode = (mode: buildRoutesModeType) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.TOGGLE_MODE_BUILD_ROUTES,
        mode
    })
}

export const setSelectedPointId = (id: number | null) => async (dispatch: Function) => {
    dispatch({
        type: mapActions.SET_SELECTED_POINT_ID,
        id
    })
}
