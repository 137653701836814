import React from 'react'
import { deletePersonTransport, IPersonTransport } from '@justpro/terminal'
import Table from '../../UI/table/table'
import Button from '../../UI/button/button'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import PersonTransportModal from './person.transportModal'
import withLabel from '../../UI/withLabel/withLabel'
import checkError from '../../../utils/checkError'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types'
import { openModal } from '../../../store/modal/modal.actions'
type Props = {
	personId: number
	transports?: IPersonTransport[]
	rights: any
	openModal(data: Modal): void
}

interface State {
	transports: IPersonTransport[]
}

export const personsTransportModalId = 'personsTransportModal'

class PersonTransport extends React.Component<Props, State> {
	state: State = {
		transports: [],
	}

	deletePersonTransport = async (id: number) => {
		try {
			const deleted = await deletePersonTransport({
				transportId: id,
				personId: this.props.personId,
			})
			if (deleted) {
				this.setState((prevState) => ({
					transports: prevState.transports.filter((t) => t.id !== id),
				}))
			}

			// :
		} catch (e) {
			checkError(e)
		}
	}

	editPersonTransport = (personTransport: IPersonTransport) => {
		this.props.openModal({
			id: personsTransportModalId,
			component: (props: ModalBodyProps) => (
				<PersonTransportModal
					{...props}
					personId={this.props.personId}
					transport={personTransport}
					updateTransports={this.updateTransports}
				/>
			),
			title: getText('persons.editingTransport'),
		})
	}

	createPersonTransport = () => {
		this.props.openModal({
			id: personsTransportModalId,
			component: (props: ModalBodyProps) => (
				<PersonTransportModal
					{...props}
					personId={this.props.personId}
					// transport={this.state.transport}
					updateTransports={this.updateTransports}
				/>
			),
			title: getText('persons.addingTransport'),
		})
	}

	getColumns = () => {
		const { rights } = this.props

		const result: any[] = [
			{
				Header: getText('persons.model'),
				accessor: 'name',
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['edit']
		) {
			result.push({
				Header: getText('common.edit'),
				Cell: (props: any) => (
					<Button
						className='btn-success'
						onClick={this.editPersonTransport.bind(
							this,
							props.original,
						)}
					>
						<i className='fa fa-edit no-text' />
					</Button>
				),
			})
		}

		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deletePersonTransport}
						id={props.original.id}
					/>
				),
			})
		}

		return result
	}

	updateTransports = (transport: IPersonTransport, id?: number) => {
		this.setState((prevState) => {
			if (id !== undefined) {
				return {
					transports: prevState.transports?.map((t) => {
						if (t.id === id) {
							return transport
						}
						return t
					}),
				}
			} else {
				return {
					transports: [...prevState.transports, transport],
				}
			}
		})
	}

	resetState = () => {
		this.setState(() => ({
			transports: this.props.transports ? this.props.transports : [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		if (
			prevProps.personId !== this.props.personId ||
			prevProps.transports !== this.props.transports
		) {
			this.resetState()
		}
	}

	render() {
		const { transports } = this.state
		const { rights } = this.props
		return withLabel(
			<>
				{transports && transports.length > 0 && (
					<>
						<Table columns={this.getColumns()} data={transports} />
					</>
				)}

				{rights &&
					rights['references.persons'] &&
					rights['references.persons']['create'] && (
						<>
							<Button
								onClick={this.createPersonTransport}
								className={
									transports.length > 0
										? 'btn-success margin-top'
										: 'btn-success'
								}
							>
								{getText('persons.addTransport')}
							</Button>
						</>
					)}
			</>,
		)({ text: 'persons.transport' })
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

const mapDispatch = (d: Function) => ({
	openModal: (data: Modal) => d(openModal(data)),
})

export default connect(mapStateToProps, mapDispatch)(PersonTransport)
