import {
	editOrder,
	removeWorkorderOrders,
	secondsToTime,
	OrderListItem,
	IActOrder,
	Person,
	PersonBasePoint,
	getWorkorders,
	Workorder,
	createWorkorderByOrders,
	saveCalendar,
	getCalendar,
	timeToSeconds,
	delay,
} from '@justpro/terminal'
import moment, { min, Moment } from 'moment'
import checkError from '../../utils/checkError'
import Calendar, { CalendarItemType, WorkorderDuration } from './calendar'
import getText from '../../localization/getText'
import React, { PureComponent, useState } from 'react'
import RenderIf from '../../utils/renderIf'
import SortingList from '../UI/simpleSorting/sortingList'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getObjectName } from '../../utils/names'
import { removeFromWoArray, setWoArray } from '../../store/map/map.actions'
import Tooltip from '../UI/tooltip/tooltip.controller'
import OrderDetail from '../orders/detail/order.detail'
import WithModuleDetail from '../UI/dialog/withModuleDetail/withModuleDetail.controller'
import Button from '../UI/button/button'
import Spinner from '../UI/spinner/spinner.controller'
import WorkOrderDetail from '../workOrders/workOrder.controller'
import { calcWorkorderDuration, getOrderStartTime } from './calendarUtils'
import TimePicker from '../UI/timePicker/timePiker.controller'
import { confirm } from '../UI/confirmAction/confirmAction.controller'

export const CalendarItem = ({
	id,
	duration,
	from,
	roadDuration,
	roadDistance,
	workorder,
	componentToOpen,
	title,
	factMode,
	distanceMatrixError,
	updateBreakTime = () => {},
	deleteOrder = () => {},
	updateOrderDuration = () => {},
}: CalendarItemType) => {
	const dispatch = useDispatch()
	const woArray = useSelector((state: any) => state?.maps?.woArray)

	const removeNewOrder = (orderId) => {
		const index = woArray.findIndex((order) => order.id === orderId)
		dispatch(removeFromWoArray(index))
	}

	const removeNewWorkorder = () => {
		workorder?.orders?.map?.((order) => {
			removeNewOrder(order.id)
		})
	}

	const onBlurBreak = (value: Moment) => {
		const [hours, minutes] = value.format('HH:mm').split(':')
		const hoursInSeconds = +hours * 60 * 60
		const minutesInSeconds = +minutes * 60
		updateBreakTime(hoursInSeconds + minutesInSeconds)
	}

	if (!workorder) {
		return (
			<div className={'calendar_item-container'}>
				<div className='calendar_workorder-wrapper'>
					<div className='calendar_workorders-body--duration'>
						<p>
							<span>Начало: </span>
							<b>{secondsToTime(from)}</b>
						</p>
						<p>
							<span>Время: </span>
							<b>
								<TimePicker
									className='calendar_break-input'
									value={moment()
										.startOf('day')
										.add(duration || 0, 's')}
									showClear={false}
									onChange={() => {}}
									onBlur={onBlurBreak}
								/>
							</b>
						</p>
						<p>
							<span>Конец: </span>
							<b>{secondsToTime(from + duration)}</b>
						</p>
					</div>
					<div className='calendar_item-wrapper'>
						<div className='calendar_workorder'>
							<span className='calendar_workorder-name'>
								{getText(`calendar.break`)}
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const executionTimeOrder = workorder?.orders?.find(
		(order) => order.executionTime,
	)
	const orderStartTime = getOrderStartTime(executionTimeOrder) || 0
	const notInTime = orderStartTime > 0 && orderStartTime < from && !factMode
	const maxTime = from + duration > 72000 && !factMode

	const classesCalendarWrapper = ['calendar_item-container']

	if (executionTimeOrder && !factMode) {
		classesCalendarWrapper.push('calendar_item-container__execution-time')
	}
	if (!workorder.calendar && !factMode) {
		classesCalendarWrapper.push('calendar_item-container__not-in-calendar')
	}
	if (workorder.new && !factMode) {
		classesCalendarWrapper.push('calendar_item-container__new')
	}
	return (
		<div className={classesCalendarWrapper.join(' ')}>
			<div className='calendar_workorder-wrapper'>
				<div className='calendar_workorders-body--duration'>
					<p>
						<RenderIf condition={distanceMatrixError}>
							<Tooltip
								title='calendar.distanceMatrixError'
								position='right'
							>
								<i
									className='fa fa-exclamation-circle'
									style={{ color: 'red', marginRight: '5px' }}
								/>
							</Tooltip>
						</RenderIf>
						<span>{getText(`calendar.road`)}: </span>
						<b>{secondsToTime(roadDuration || 0)}</b>
					</p>
					<p>
						<span>{getText(`calendar.start`)}: </span>
						<b>{secondsToTime(from)}</b>
					</p>
					<p>
						<RenderIf condition={notInTime}>
							<Tooltip
								title='calendar.notInTime'
								position='right'
							>
								<i
									className='fa fa-exclamation-circle'
									style={{ color: 'red', marginRight: '5px' }}
								/>
							</Tooltip>
						</RenderIf>
						<span>{getText(`calendar.time`)}: </span>
						<b>{secondsToTime(duration || 0)}</b>
					</p>
					<p>
						<RenderIf condition={maxTime}>
							<Tooltip title='calendar.maxTime' position='right'>
								<i
									className='fa fa-exclamation-circle'
									style={{ color: 'red', marginRight: '5px' }}
								/>
							</Tooltip>
						</RenderIf>
						<span>{getText(`calendar.end`)}: </span>
						<b>{secondsToTime(from + duration)}</b>
					</p>
				</div>

				<div className='calendar_item-wrapper'>
					<RenderIf condition={workorder && !workorder.new}>
						<WithModuleDetail
							element={
								<div className='calendar_workorder'>
									<span className='calendar_workorder-name'>
										{getObjectName(workorder.object)}
									</span>
									<span className='calendar_workorder-number'>
										{workorder.number}
									</span>
								</div>
							}
							modal={{
								id: 'calendarModal',
								component: componentToOpen,
								title,
							}}
						/>
					</RenderIf>
					<RenderIf condition={workorder?.new}>
						<div className='calendar_workorder'>
							<span className='calendar_workorder-name'>
								{getObjectName(workorder.object)}
							</span>
							<i
								className='fa fa-close calendar_workorder-delete'
								onClick={removeNewWorkorder}
							/>
						</div>
					</RenderIf>

					<div className='calendar_order-wrapper'>
						{workorder?.orders?.map?.((order) => {
							const actOrder = factMode ? order as unknown as IActOrder : null;
							const orderStartTime = getOrderStartTime(order) || 0
							return (
								<div
									className='calendar-order_container'
									key={actOrder?.orderId || order.id}
								>
									<WithModuleDetail
										element={
											<div className='calendar-order_wrapper'>
												<RenderIf
													condition={
														order.executionTime
													}
												>
													<span className='calendar-execution-time'>
														{secondsToTime(
															orderStartTime,
														)}
													</span>
												</RenderIf>
												<div className='calendar-order_content-wrapper'>
													<RenderIf
														condition={
															order.expired
														}
													>
														<Tooltip title='UI.itemList.expiredTime'>
															<i
																className='fa fa-fw fa-fire'
																style={{
																	color: 'red',
																}}
															/>
														</Tooltip>
													</RenderIf>
													<RenderIf
														condition={order.hot}
													>
														<Tooltip title='UI.itemList.expiredTime'>
															<i
																className='fa fa-fw fa-fire'
																style={{
																	color: 'orange',
																}}
															/>
														</Tooltip>
													</RenderIf>
													<span
														className='objectInner_label'
														style={{
															color: order
																.contractOrdersType
																?.color,
														}}
													>
														{order?.number}
													</span>
													<span className='objectInner_text'>
														{order?.content}
													</span>
													<div className='calendar_order-duration__container'>
														<TimePicker
															disabled={factMode}
															onClick={(e) =>
																e.stopPropagation()
															}
															className='calendar-input-order-duration'
															value={moment()
																.startOf('day')
																.add(
																	order.duration ||
																		0,
																	's',
																)}
															showClear={false}
															onChange={() => {}}
															onBlur={updateOrderDuration.bind(
																void 0,
																actOrder?.orderId || order.id,
																workorder.id,
															)}
														/>
														<Tooltip title='UI.itemList.deleteOrder'>
															<RenderIf
																condition={
																	!workorder?.new &&
																	!factMode
																}
															>
																<i
																	onClick={deleteOrder.bind(
																		void 0,
																		workorder.id,
																		order.id,
																	)}
																	className='fa fa-times-circle'
																	style={{
																		color: 'red',
																	}}
																/>
															</RenderIf>
															<RenderIf
																condition={
																	workorder?.new
																}
															>
																<i
																	onClick={(
																		e,
																	) => {
																		e.stopPropagation()
																		removeNewOrder(
																			actOrder?.orderId || order.id,
																		)
																	}}
																	className='fa fa-times-circle'
																	style={{
																		color: 'red',
																	}}
																/>
															</RenderIf>
														</Tooltip>
													</div>
												</div>
											</div>
										}
										modal={{
											id: 'orderListItem',
											component: () => (
												<OrderDetail id={actOrder?.orderId || order.id} />
											),
											title: getText('orders.order'),
										}}
									/>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<p className='calendar_item-footer'>
				<RenderIf condition={distanceMatrixError}>
					<Tooltip
						title='calendar.distanceMatrixError'
						position='right'
					>
						<i
							className='fa fa-exclamation-circle'
							style={{ color: 'red', marginRight: '5px' }}
						/>
					</Tooltip>
				</RenderIf>
				<span>{getText(`calendar.distance`)}: </span>
				<b>{(roadDistance / 1000).toFixed(2)} км.</b>
			</p>
		</div>
	)
}

interface Props {
	woArray: OrderListItem[]
	selectedExecutor?: Person
	selectedDate: string
	settings: any
	buildRoutes: (objects: Object[]) => void
	setWoArray: (array: any[]) => void
}

interface State {
	workorders: WorkorderDuration[]
	newWorkorders: Partial<WorkorderDuration>[]
	isLoading: boolean
	optimize: boolean
	isCalendarChanged: boolean
	basePoint?: PersonBasePoint
	calendarEvents: CalendarItemType[]
}

const mapState = (state) => ({
	settings: state?.application?.settings,
	woArray: state?.maps?.woArray,
})

const mapDispatch = (dispatch) => ({
	setWoArray: (array) => {
		dispatch(setWoArray(array))
	},
})

export default connect(
	mapState,
	mapDispatch,
)(
	class WorkorderCalendar extends PureComponent<Props, State> {
		calendar = new Calendar({})
		state = {
			isLoading: false,
			optimize: true,
			calendarEvents: [],
			workorders: [],
			newWorkorders: [],
			basePoint: null,
			isCalendarChanged: false,
		}

		componentDidMount() {
			this.updateWorkordersList(this.props.woArray)
			document
				.querySelector('#root')
				.addEventListener('click', this.wouldSave)
		}

		componentWillUnmount() {
			document
				.querySelector('#root')
				.removeEventListener('click', this.wouldSave)
			window.onbeforeunload = null
		}

		async componentDidUpdate(prevProps, prevState) {
			if (
				(this.props?.selectedExecutor?.id &&
					prevProps?.selectedExecutor?.id !==
						this.props?.selectedExecutor?.id) ||
				prevProps?.selectedDate !== this.props?.selectedDate
			) {
				this.props.setWoArray([])
				this.setDefaultBasePoint()
				await this.loadPersonCalendar()
				this.buildCalendar()
			}
			if (
				JSON.stringify(prevProps.woArray) !==
				JSON.stringify(this.props.woArray)
			) {
				this.updateWorkordersList(this.props.woArray)
			}
			const isWorkordersNotEqual =
				JSON.stringify(prevState.workorders) !==
				JSON.stringify(this.state.workorders)
			const isNewWorkordersNotEqual =
				JSON.stringify(prevState.newWorkorders) !==
				JSON.stringify(this.state.newWorkorders)
			if (
				isNewWorkordersNotEqual ||
				(!prevState.optimize && this.state.optimize)
			) {
				this.buildCalendar(
					isWorkordersNotEqual || isNewWorkordersNotEqual,
				)
			}
			const closeAlert = () => getText(`calendar.closeAlert`)
			window.onbeforeunload = this.state.isCalendarChanged
				? closeAlert
				: null
		}

		wouldSave = async (event) => {
			if (
				!event.target.closest('.calendar-map-container_element') &&
				!event.target.closest('.expanding_wrapper--right') &&
				!event.target.closest('.top-line') &&
				!event.target.closest('.toggleIsOpenButton') &&
				this.state.isCalendarChanged &&
				moment(this.props.selectedDate).isSameOrAfter(
					moment().startOf('day'),
				) &&
				!event.realEvent
			) {
				event.stopPropagation()
				event.preventDefault()
				const confirmClose = await confirm({
					question: getText(`calendar.confirmText`),
				})
				if (confirmClose) {
					await this.saveCalendar()
				}
				const newEvent = document.createEvent('MouseEvents')
				newEvent.initEvent('click', true, true)
				//@ts-ignore
				newEvent.realEvent = true
				event.target.dispatchEvent(newEvent)
			}
		}

		async loadWorkorders() {
			const { selectedExecutor, selectedDate } = this.props
			if (!selectedExecutor) {
				return []
			}
			const workorders: { list: any[]; count: number } =
				await getWorkorders({
					orders: '1',
					responsiblesId: [selectedExecutor.id],
					types: ['orders', 'admin'],
					dateFrom: selectedDate,
					dateTo: selectedDate,
				})
			return workorders.list
				.filter((w) => w?.orders?.length)
				.map((wo) => {
					const duration = calcWorkorderDuration(wo?.orders)
					wo?.orders?.forEach((order) => {
						order.executionTime?.sort(
							({ from: from1 }, { from: from2 }) => {
								let from1Seconds = 0,
									from2Seconds = 0
								if (from1.includes('-')) {
									from1Seconds = timeToSeconds(from1, '-')
								}
								if (from1.includes(':')) {
									from1Seconds = timeToSeconds(from1, ':')
								}
								if (from2.includes('-')) {
									from2Seconds = timeToSeconds(from2, '-')
								}
								if (from2.includes(':')) {
									from2Seconds = timeToSeconds(from2, ':')
								}
								return from1Seconds - from2Seconds
							},
						)
					})
					return { ...wo, duration }
				})
		}

		async loadPersonCalendar() {
			try {
				this.setState({
					isLoading: true,
				})
				const { selectedExecutor, selectedDate } = this.props
				if (!selectedExecutor) {
					return
				}
				const calendarEvents = await getCalendar(
					selectedExecutor?.id,
					selectedDate,
				)
				const workorders = await this.loadWorkorders()
				try {
					await this.createMatrix(workorders)
				} catch (err) {
					checkError(err)
				}
				this.setState({
					isCalendarChanged: false,
					workorders,
					newWorkorders: [],
					calendarEvents,
					optimize: !calendarEvents?.length,
				})
			} catch (e) {
				checkError(e)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}

		updateWorkordersList = (woArray) => {
			const { selectedDate } = this.props
			const responsible = this.props.selectedExecutor
			const newWorkorders = []
			woArray?.map?.((order) => {
				const workorder = newWorkorders.find(
					(w) => w.object.id === order.object.id,
				)
				if (workorder) {
					workorder.orders.push(order)
					workorder.duration = calcWorkorderDuration(workorder.orders)
					return
				}
				newWorkorders.push({
					id: order.id,
					closed: false,
					new: true,
					orders: [order],
					duration: order.duration,
					type: 'orders',
					date: selectedDate,
					object: { ...order.object },
					responsible,
				})
			})
			this.setState({
				newWorkorders,
			})
		}

		onEventDrop = (calendar: CalendarItemType[]) => {
			if (
				JSON.stringify(this.calendar) ===
				JSON.stringify(this.state.calendarEvents)
			) {
				return false
			}
			this.calendar?.buildFrom(calendar)
			this.calendar.recalculate()
			const calendarEvents = this.calendar.getData()
			const objects = calendarEvents
				.filter((wo) => wo.workorder)
				.map((wo) => wo.workorder.object)
			this.props.buildRoutes([
				{ ...this.state.basePoint, basePoint: true },
				...objects,
				{
					...this.state.basePoint,
					basePoint: true,
				},
			])
			this.setState({
				calendarEvents,
				optimize: false,
				isCalendarChanged: true,
			})
		}

		addDiffWorkorders = (workorders) => {
			const oldCalendar =
				this.state.calendarEvents || this.calendar.getData()
			this.calendar.clearData()
			const filtredCalendar = oldCalendar.filter((event) => {
				return (
					!event.workorder ||
					!!workorders.find((w) => w.id === event?.workorder?.id)
				)
			})
			this.calendar.buildFrom(filtredCalendar)
			const addedWorkorders = workorders
				.map((workorder) => {
					const isInCalenadar = !!filtredCalendar.find(
						(event) => workorder.id === event?.workorder?.id,
					)
					workorder.duration = calcWorkorderDuration(workorder.orders)
					if (!isInCalenadar && this.state.optimize) {
						this.calendar.addFree(workorder)
						return workorder
					}
					if (!isInCalenadar && !this.state.optimize) {
						this.calendar.addToEnd(workorder)
						return workorder
					}
					return false
				})
				?.filter((w) => w)
			if (addedWorkorders) {
				this.setState({
					isCalendarChanged: true,
				})
			}
		}

		buildCalendar = async (shouldUpdateMatrix = true) => {
			try {
				this.setState({
					isLoading: true,
				})
				const workorders = [
					...this.state.workorders,
					...this.state.newWorkorders,
				]
				if (!workorders?.length) {
					this.props.buildRoutes([])
					this.setState({
						calendarEvents: [],
					})
				}
				if (workorders?.length) {
					const { optimize } = this.state
					if (shouldUpdateMatrix) {
						try {
							await this.createMatrix(workorders)
						} catch (err) {
							checkError(err)
						}
					}
					if (!this.state?.calendarEvents?.length || optimize) {
						const dinner = this.state.calendarEvents?.find(
							(event) => !event.workorder,
						)
						this.calendar.clearData()
						this.setState({
							isCalendarChanged: true,
						})
						await this.calendar.initFromWorkorders(
							workorders,
							dinner?.duration,
						)
					} else {
						await this.addDiffWorkorders(workorders)
					}
					const calendarEvents = this.calendar.getData()
					this.setState({
						calendarEvents,
					})
					const objects = calendarEvents
						.filter((wo) => wo.workorder)
						.map((wo) => wo.workorder.object)
					this.props.buildRoutes([
						{ ...this.state.basePoint, basePoint: true },
						...objects,
						{
							...this.state.basePoint,
							basePoint: true,
						},
					])
				}
			} catch (e) {
				checkError(e)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}
		createMatrix = async (workorders) => {
			await delay(1000)
			return new Promise((resolve, reject) => {
				const { basePoint } = this.state
				const { google }: any = window
				const directionsService =
					new google.maps.DistanceMatrixService()
				const wayPoints = workorders?.slice(0, 8).map((wo) => ({
					lat: wo.object.latitude,
					lng: wo.object.longitude,
					objectId: wo.object.id,
				}))
				if (!wayPoints?.length) {
					return resolve()
				}
				wayPoints.push({
					lat: basePoint?.latitude,
					lng: basePoint?.longitude,
					objectId: basePoint?.id,
				})
				const routeParams = {
					travelMode: 'DRIVING',
					origins: wayPoints,
					destinations: wayPoints,
				}
				directionsService.getDistanceMatrix(
					routeParams,
					(result, status) => {
						if (status !== google.maps.DirectionsStatus.OK) {
							return reject(
								new Error(
									`Ошибка при получении данных матрицы расстояний: ${status}`,
								),
							)
						}
						this.calendar.setMatrix(result, wayPoints)
						resolve()
					},
				)
			})
		}

		setDefaultBasePoint = () => {
			const { settings } = this.props
			const { basePoints } = this.props?.selectedExecutor || {}
			let basePoint
			if (!basePoints) {
				return
			}
			if (settings?.basePointId) {
				basePoint = basePoints?.find(
					(point) => point.objectId === settings.basePointId,
				)
			}
			if (!settings?.basePointId) {
				basePoint = basePoints?.[0]
			}
			this.setState({
				basePoint,
			})
			if (basePoint) {
				this.calendar.basePointId = basePoint.id
			}
		}

		countTotalDistance = () => {
			const { calendarEvents } = this.state
			return calendarEvents.reduce((total, event) => {
				return total + (event.roadDistance || 0)
			}, 0)
		}

		countTotalWorkordersDuration = () => {
			const { calendarEvents } = this.state
			return calendarEvents.reduce((total, event) => {
				return total + (event.duration || 0)
			}, 0)
		}
		countTotalRouteDuration = () => {
			const { calendarEvents } = this.state
			return calendarEvents.reduce((total, event) => {
				return total + (event.roadDuration || 0)
			}, 0)
		}

		updateBreakTime = (value: number) => {
			this.calendar.updateBreak(value)
			this.updateCalendar()
		}

		updateOptimize = (e) => {
			this.setState({
				optimize: e.target.checked,
			})
		}

		updateCalendar = () => {
			const workorders = [
				...this.state.workorders,
				...this.state.newWorkorders,
			]
			const oldCalendar = this.calendar
				.getData()
				.map((event) => {
					const workorder = workorders.find(
						(w) => w.id === event?.workorder?.id,
					)
					if (workorder) {
						event.duration = workorder.duration
					}
					return event
				})
				?.filter((event) => {
					const isWorkorder = workorders.find(
						(w) => w.id === event?.workorder?.id,
					)
					return !event?.workorder || isWorkorder
				})
			if (this.state.optimize) {
				const dinner = oldCalendar.find((e) => !e.workorder)
				this.calendar.clearData()
				this.calendar.initFromWorkorders(workorders, dinner.duration)
			} else {
				this.calendar.buildFrom(oldCalendar)
				this.calendar.recalculate()
			}
			this.setState({
				calendarEvents: this.calendar.getData(),
				isCalendarChanged: true,
			})
		}

		deleteOrder = async (woId, orderId, e) => {
			try {
				this.setState({
					isLoading: true,
				})
				e.stopPropagation()
				const deleted = await removeWorkorderOrders(woId, [orderId])
				if (deleted) {
					this.setState((prevState) => {
						const workorders = prevState.workorders
							.map((workorder) => {
								if (workorder.id === woId) {
									workorder.orders = workorder.orders.filter(
										(o) => o.id !== orderId,
									)
									workorder.duration = calcWorkorderDuration(
										workorder.orders,
									)
								}
								return workorder
							})
							.filter((w) => w?.orders?.length)
						return {
							workorders,
						}
					}, this.updateCalendar)
				}
			} catch (e) {
				checkError(e)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}

		updateOrderDuration = async (
			orderId: number,
			workorderId: number,
			value,
		) => {
			try {
				this.setState({
					isLoading: true,
				})
				const timeValue = value.format('HH:mm')
				const duration = Math.round(timeToSeconds(timeValue, ':'))

				const updatedOrder = await editOrder(orderId, {
					duration,
				})

				if (updatedOrder) {
					this.setState(
						(prevState) => ({
							workorders: prevState.workorders.map(
								(workorder: WorkorderDuration) => {
									if (workorder.id === workorderId) {
										workorder.orders = workorder.orders.map(
											(order) => {
												if (order.id === orderId) {
													order.duration = duration
												}
												return order
											},
										)
										workorder.duration =
											calcWorkorderDuration(
												workorder.orders,
											)
									}
									return workorder
								},
							),
							newWorkorders: prevState.newWorkorders.map(
								(newWorkorder: Partial<WorkorderDuration>) => {
									newWorkorder.orders =
										newWorkorder.orders.map((order) => {
											if (order.id === orderId) {
												order.duration = duration
											}
											return order
										})
									newWorkorder.duration =
										calcWorkorderDuration(
											newWorkorder.orders,
										)
									return newWorkorder
								},
							),
						}),
						this.updateCalendar,
					)
				}
			} catch (e) {
				checkError(e)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}

		changeMinStartTime = (minStartTime) => {
			const seconds = minStartTime.diff(
				moment().startOf('day'),
				'seconds',
			)
			this.calendar.setMinStartTime(seconds)
			this.updateCalendar()
		}

		saveCalendar = async () => {
			try {
				this.setState({
					isLoading: true,
				})
				const { calendarEvents } = this.state
				const newEvents = calendarEvents.filter(
					(event) => event?.workorder?.new,
				)
				if (newEvents) {
					await Promise.all(
						newEvents?.map(async (event) => {
							const realEventIndex = calendarEvents.findIndex(
								(e) => e?.workorder?.id === event.workorder.id,
							)
							const workorder = event.workorder
							const responseWorkorders =
								await createWorkorderByOrders({
									orders: workorder.orders.map(
										(order) => order.id,
									),
									date: workorder.date,
									responsibleId: workorder.responsible.id,
									coworkers: [],
								})
							calendarEvents[realEventIndex].workorder.id =
								responseWorkorders?.[0]?.id
						}),
					)
				}
				const calendarItems = calendarEvents.map((event) => {
					return {
						from: event.from,
						duration: event.duration,
						to: event.to,
						roadDuration: event.roadDuration,
						roadDistance: event.roadDistance,
						workorderId: event?.workorder?.id,
					}
				})
				this.props.setWoArray([])
				const newCalendarEvents = await saveCalendar(
					this.props.selectedExecutor?.id,
					this.props.selectedDate,
					calendarItems,
				)
				const workorders = await this.loadWorkorders()
				this.calendar.buildFrom(newCalendarEvents)
				this.setState({
					calendarEvents: newCalendarEvents,
					workorders,
					isCalendarChanged: false,
				})
			} catch (err) {
				checkError(err)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}

		render() {
			const {
				isLoading,
				optimize,
				calendarEvents,
				basePoint,
				isCalendarChanged,
			} = this.state
			const lastObject =
				calendarEvents[calendarEvents?.length - 1]?.workorder?.object
					?.id ||
				calendarEvents[calendarEvents?.length - 2]?.workorder?.object
					?.id ||
				basePoint?.id
			const lastItemToBp = this.calendar.getInfoBetween(
				lastObject,
				basePoint?.id,
			)
			const minStartTime = moment()
				.startOf('day')
				.add(this.calendar.getMinStartTime(), 'seconds')
			return (
				<div className='calendar_workorders-wrapper'>
					<Spinner loading={isLoading} />
					<div className='calendar_workorders-header'>
						<div className='just-pro__checkbox-children'>
							<input
								type='checkbox'
								checked={optimize}
								onChange={this.updateOptimize}
							/>
							{getText('calendar.optimize')}
						</div>
						<RenderIf
							condition={
								isCalendarChanged &&
								moment(this.props.selectedDate).isSameOrAfter(
									moment().startOf('day'),
								)
							}
						>
							<Button onClick={this.saveCalendar}>
								{getText('common.save')}
							</Button>
						</RenderIf>
					</div>
					<div className='calendar_workorders-body'>
						<RenderIf condition={calendarEvents?.length}>
							<div className='calendar_basepoint'>
								<span className='calendar_basepoint_title'>
									{basePoint?.address}
								</span>
								<div className='calendar_start_time'>
									<span className='calendar_start_time_title'>
										{getText(`calendar.start`)}
									</span>
									<TimePicker
										defaultValue={minStartTime}
										onChange={this.changeMinStartTime}
										value={minStartTime}
									/>
								</div>
							</div>
						</RenderIf>
						<RenderIf condition={calendarEvents?.length}>
							<SortingList
								type={'sortTypes.regularWork'}
								renderItem={(event, index) => (
									<CalendarItem
										{...event}
										updateOrderDuration={
											this.updateOrderDuration
										}
										deleteOrder={this.deleteOrder}
										key={
											event?.workorder?.id ||
											index?.toString()
										}
										updateBreakTime={this.updateBreakTime}
										distanceMatrixError={index > 8}
										componentToOpen={() => (
											<WorkOrderDetail
												id={event?.workorder?.id}
											/>
										)}
									/>
								)}
								noPadding={true}
								list={calendarEvents}
								onItemDrop={this.onEventDrop}
							/>
						</RenderIf>
						<RenderIf condition={!calendarEvents?.length}>
							<div>{getText(`calendar.noWorkorders`)}</div>
						</RenderIf>
						<RenderIf condition={calendarEvents?.length}>
							<div className='calendar_basepoint'>
								<span className='calendar_basepoint_title'>
									{basePoint?.address}
								</span>
								<p className='calendar_item-footer'>
									<span>
										{getText(`calendar.distance`)}:{' '}
									</span>
									<b>
										{(
											(lastItemToBp?.distance || 0) / 1000
										).toFixed(2)}{' '}
										км.
									</b>
								</p>
							</div>
						</RenderIf>
					</div>
					<div className='calendar_workorders-footer'>
						<div>
							<p>
								<span>{getText(`calendar.distance`)}: </span>
								<b>
									{(this.countTotalDistance() / 1000).toFixed(
										2,
									)}{' '}
									км
								</b>
							</p>
							<p>
								<span>
									{getText(`calendar.roadDuration`)}:{' '}
								</span>
								<b>
									{secondsToTime(
										this.countTotalRouteDuration(),
									)}
								</b>
							</p>
							<p>
								<span>
									{getText(`calendar.workDuration`)}:{' '}
								</span>
								<b>
									{secondsToTime(
										this.countTotalWorkordersDuration(),
									)}
								</b>
							</p>
						</div>
					</div>
				</div>
			)
		}
	},
)
