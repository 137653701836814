import React from "react";
import '../createOrderModal.view.css'
import '../createOrderModal.view.css'
import Select from "../../../select/select";
import {getInitiatorsList} from "../../../../../utils/functions";
import {ChangeMultiProps, Option} from "../../../select/select.types";
import withLabel from "../../../withLabel/withLabel";
import TextArea from "../../../textArea/textArea.controller";
import {Contract, IExecutedTime, IOrderContractCondition} from "@justpro/terminal";
import ExecutedTimeController from "../../../executedTime/executedTime.controller";
import MultiSelect from "../../../select/multiSelect";
import DefaultFooterModal from '../../modal/defaultFooterModal'
import Button from "../../../button/button";
import getText from "../../../../../localization/getText";

import moment, {Moment} from "moment";

import TopLineCalendar from "../../../../UI/calendar/views/topLine.controller";
import InlineCalendar from "../../../../UI/calendar/views/inline.controller";
import RenderIf from '../../../../../utils/renderIf'
import GetText from "../../../../../localization/getText";

interface State {
    // initiatorId? : number
    date: Moment
}

interface Props {
    createOrder(): void,
    contractorId: number

    contracts? : Contract[]
    hide() : void
    isDisabled() : boolean

    changeInitiator(option:Option) : Promise<any>
    changeContractCondition(options:ChangeMultiProps) : Promise<boolean>
    changeExecutionTime(executionTime:IExecutedTime[]) : void
    changeExecutionDate(executionDate: any) : void
    changeContent(value:string) : void
    changeContactInfo(value:string) : void
    changeAdditionalInfo(value:string) : void

}

class DetailOrderModal extends React.Component<Props, State>{
    state:State = {
        date: void 0
    };

    initDate() {
        this.setState({
            date: moment()
        })
    }

    setDate(value: Moment) {
        this.setState({
            date: value
        })
        this.props.changeExecutionDate(value.format('YYYY-MM-DD HH:mm:ss'))
    }

    render() {
        return(
            <div className="just-pro-create_order__modal-detail">
                <Select
                    load={getInitiatorsList.bind(this, '', [this.props.contractorId])}
                    change={this.props.changeInitiator}
                    label="common.initiator"
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                />

                <InlineCalendar
                    label={{text :"UI.history.dueDateCustomer"}}
                    date={this.state.date}
                    onChange={this.setDate.bind(this)}
                    timePicker
                />

                <MultiSelect
                    change={this.props.changeContractCondition}
                    defaultOptions={this.props.contracts?.reduce((acc:IOrderContractCondition[], contract) => {

                        contract.contractConditions?.forEach(cc => {
                            if(!acc.find(e => e.contractConditionId === cc.contractConditionId)) {
                                acc.push(cc);
                            }
                        });

                        return acc
                    }, []) || []}
                    label="contractConditions.contractConditions"
                    accessors={{
                        name : 'label',
                        contractConditionId : 'value'
                    }}

                />

                <TextArea
                    label={{ text : "common.contentInfo" }}
                    placeholder="UI.textArea.placeholders.contentAndMaterials"
                    change={this.props.changeContent}
                />

                <TextArea
                    label={{text : "common.contactInfo"}}
                    placeholder="UI.textArea.placeholders.contactInfo"
                    change={this.props.changeContactInfo}
                />


                {withLabel(
                    <ExecutedTimeController
                        onChange={this.props.changeExecutionTime}
                        addFirstButton={{text : "common.add"}}
                    />
                )({position : 'left', text : "orders.executedTime"})}

                <TextArea
                    label={{ text : "common.additionalInfo"}}
                    placeholder="common.additionalInfo"
                    change={this.props.changeAdditionalInfo}
                />

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.props.createOrder} disabled={this.props.isDisabled()}>{getText('common.create')}</Button>
                </DefaultFooterModal>
            </div>
        )
    }

}


export default DetailOrderModal