import React, { FC } from 'react'
import moment, { Moment } from 'moment'
import Select from '../../select/select'
import { getObjectName } from '@justpro/terminal'
import MultiSelect from '../../select/multiSelect'
import { getPersonsList } from '../../../../utils/functions'
import InlineCalendar from '../../calendar/views/inline.controller'
import { ChangeMultiProps, Option } from '../../select/select.types'
import RenderIf from '../../../../utils/renderIf'

type UpdateDataType = {
	executor?: Option
	date?: Moment
}

interface IProps {
	item: any
	update?: (
		objectId: number,
		equipmentCategoryId: number,
		data: UpdateDataType,
	) => void
	changeCoWorkers?: (
		option: ChangeMultiProps,
		objectId: number,
		equipmentCategoryId: number,
	) => Promise<boolean>
	removeEquipmentCategory?: (
		objectId: number,
		equipmentCategoryId: number,
	) => void
}

export const ChecklistObject: FC<IProps> = ({
	item,
	update,
	changeCoWorkers,
	removeEquipmentCategory,
}) => (
	<div className='checklistObject' key={item.object.id}>
		{console.log('item', item)}
		<div className='checklistObject__header'>
			<span className='checklistObject__name'>
				{getObjectName(item.object)}
			</span>
		</div>
		<div className='checklistObject__body'>
			{item.equipmentCategories.map((eq) => {
				const { date, executor, coworkers } = eq

				const cWorkers = coworkers
					? coworkers.map(({ value, label, ...rest }) => {
							return {
								...rest,
								id: value,
								name: label,
							}
					  })
					: []

				return (
					<div
						key={eq.equipmentCategory.id}
						className='equipmentCategory'
					>
						<div className='equipmentCategory__name'>
							{eq.equipmentCategory.name}
						</div>
						<RenderIf condition={changeCoWorkers}>
							<div className='equipmentCategory__executors'>
								<Select
									defaultValue={executor}
									change={(option: Option) => {
										update(
											item.object.id,
											eq.equipmentCategory.id,
											{ executor: option },
										)
										return Promise.resolve()
									}}
									placeholder='common.executor'
									load={getPersonsList}
									accessors={{
										id: 'value',
										name: 'label',
									}}
								/>
								<MultiSelect
									defaultValues={cWorkers}
									change={(option: ChangeMultiProps) => {
										return changeCoWorkers(
											option,
											item.object.id,
											eq.equipmentCategory.id,
										)
									}}
									placeholder='common.coworkers'
									className='margin-top'
									load={getPersonsList}
									accessors={{
										id: 'value',
										name: 'label',
									}}
								/>
							</div>
						</RenderIf>
						<div className='equipmentCategory__date'>
							<InlineCalendar
								date={date ? moment(date) : moment()}
								onChange={(date: Moment) =>
									update(
										item.object.id,
										eq.equipmentCategory.id,
										{ date },
									)
								}
							/>
						</div>

						<RenderIf condition={removeEquipmentCategory}>
							<div
								className='equipmentCategory__remove'
								onClick={() =>
									removeEquipmentCategory(
										item?.object?.id,
										eq?.equipmentCategory?.id,
									)
								}
							>
								<i className='fa fa-times' />
							</div>
						</RenderIf>
					</div>
				)
			})}
		</div>
	</div>
)
