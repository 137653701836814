import React, {ReactPortal} from "react";
import ReactDOM from "react-dom";
import Button from "../button/button";
import getText from "../../../localization/getText";

const root = document.querySelector("#confirm-modal");

interface IConfirm {
    question?: React.ReactNode
    title?: React.ReactNode
    confirmText?: React.ReactNode
    declineText?: React.ReactNode
}

interface IConfirmModal {
    onInit(i: any): any
}

interface IFunc {
    onAccept(i?: any): any
    onDecline(i?: any): any
    onClose(i?: any): any
}

class ConfirmModal extends React.Component<IConfirmModal> {
    state = {
        isOpen: false,
        question: getText("common.confirmQuestionMark"),
        title: getText("common.confirmAnAction"),
        confirmText: getText("common.yes"),
        declineText: getText("common.no")
    }

    componentDidMount = () => {
        this.props.onInit(this);
    }

    hideConfirm() {
        this.setState({
            isOpen: false
        })
    }

    onAccept = () => {}
    onDecline = () => {}
    onClose = () => {}

    showConfirm() {
        this.setState({
            isOpen: true
        })
    }

    updateText(data: IConfirm, func: IFunc){
        this.setState({
            ...data
        });
        this.onAccept = func.onAccept;
        this.onDecline = func.onDecline;
        this.onClose = func.onClose;
    }

    render() {
        const {question, title, declineText, confirmText, isOpen} = this.state;
        if(isOpen) {
            return (
                <div className="just-pro__modal-outer">
                    <div className="just-pro__modal-wrapper confirm-modal">
                        <div className="just-pro__modal-header">
                            <p className="just-pro__modal-title">{title}</p>
                            <button onClick={this.onClose} className="just-pro-button_modal--hide">&times;</button>
                        </div>
                        <div className="just-pro__modal-text">{question}</div>
                        <div className="just-pro__modal-footer">
                            <Button onClick={this.onDecline} className="btn-default">{declineText}</Button>
                            <Button onClick={this.onAccept} className="btn-success">
                                {confirmText}
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }
}

let confirmMethods = {
    showConfirm: () => {
    },
    hideConfirm: () => {
    },
    updateText: (data: IConfirm, func: IFunc) => {

    }
}

ReactDOM.render(<ConfirmModal onInit={(cm) => confirmMethods = cm}/>, root as HTMLElement);

export const confirm = async (data: IConfirm): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const onAccept = () => {
            confirmMethods.hideConfirm();
            resolve(true);
        }
        const onDecline = () => {
            confirmMethods.hideConfirm();
            resolve(false);
        }
        const onClose = () => {
            confirmMethods.hideConfirm();
            resolve(null);
        }
        confirmMethods.updateText(data, {
            onAccept,
            onDecline,
            onClose
        });
        confirmMethods.showConfirm();
    });
}
