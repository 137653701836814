import React, {useEffect, useState} from "react";
import * as vendorContextMenu from 'react-contextmenu'
import './contextMenu.css'
import Input from "../input/text";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";


export interface ContextMenuProps {
    name : string
    keyName? : string
    id: number | null
    key? : number // если id нету у элемента (Какой-то по умолчанию), key для реакта
    disabled? : boolean
    subMenuItems? : ContextMenuProps[]
    handler?(...args:any):void
    props? : {
        [key : string] : any
    }
    withSearch? : boolean // для подменю
}

interface Props {
    id : string // уникальный id
    getItems() : ContextMenuProps[]
    children : any // то, на чем отработает контекстное меню

    disabled? : boolean
}

interface MenuListState {
    value?: string
    submenuItems?: ContextMenuProps[]
}

class MenuItem extends React.Component<ContextMenuProps, MenuListState>{

    // inputRef = React.createRef<HTMLInputElement>();

    state:MenuListState = {};

    onChangeHandler = (val:string) => {
        const {subMenuItems} = this.props;

        if(subMenuItems !== undefined) {
            if(val.trim() === '') {
                this.setState(() => ({submenuItems : this.props.subMenuItems}))

            }else{
                const value = val.toLowerCase();

                const items = subMenuItems.filter(item => {
                    const name = item.name.toLowerCase();

                    return !!name.match(value)
                });

                this.setState(() => ({submenuItems : items}))
            }
        }
    };

    resetState = () => {
        this.setState(() => ({submenuItems : this.props.subMenuItems}))
    };

    componentDidMount() {
        this.resetState();
    }

    render () {
        const {submenuItems} = this.state;
        const {name, disabled} = this.props;

        if(submenuItems !== undefined) {
            return (
                <vendorContextMenu.SubMenu
                    disabled={disabled}
                    title={renderToString(getText(name))}
                    //@ts-ignore
                    preventClose={true}
                >
                    <>
                        {this.props.withSearch &&
                            <Input placeholder="Поиск" change={this.onChangeHandler} tabIndex={1}/>
                        }
                        {submenuItems.length > 0 && (
                            <div className="sub-menu__items">
                                {submenuItems.map((submenuItem) => {
                                    return <MenuItem
                                                {...submenuItem}
                                                name={renderToString(getText(submenuItem.name))}
                                                key={submenuItem.id || submenuItem.key} />
                                })}
                            </div>
                        )}
                    </>
                </vendorContextMenu.SubMenu>
            )
        }else {
            return (
                <vendorContextMenu.MenuItem
                    onClick={this.props.handler?.bind(null, this.props.props)}
                    disabled={disabled}

                >
                    {getText(name)}
                </vendorContextMenu.MenuItem>
            )
        }
    }

}

const ContextMenu = ({getItems, disabled, children, id}:Props) => {
    const DynamicMenu = (props:any) => {

        const [items, setItems] = useState<ContextMenuProps[]>([]);

        const onShowHandler = () => {
            if(getItems && items.length === 0) {
                setItems(getItems())
            }
        };

        return <vendorContextMenu.ContextMenu
            id={id}
            hideOnLeave={false}
            onShow={onShowHandler}
        >
            <>
                {items.map(item => {
                    return <MenuItem {...item} key={item.id || item.key}/>
                })}
            </>
        </vendorContextMenu.ContextMenu>
    };

    const ConnectedMenu = vendorContextMenu.connectMenu(id)(DynamicMenu);

    return (
        <>
            <vendorContextMenu.ContextMenuTrigger
                disable={disabled}
                id={id}
            >
                {children}
            </vendorContextMenu.ContextMenuTrigger>

            <ConnectedMenu/>

        </>
    )
};


export default ContextMenu