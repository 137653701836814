import React from "react";
import {addTasksPerson, deleteTasksPerson, getPersons, ITaskWatcher, Person} from "@justpro/terminal";
import AsyncSelect from "../../UI/select/asyncSelect_v2";
import {getFullName} from "../../../utils/names";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import {getUniqueList} from "../../../utils/getUniqueArray";
import checkError from "../../../utils/checkError";
import {Option} from "../../UI/select/select.types";
import {ActionTypes} from "react-select";
import {StaffTreeItem} from "./staff.detail";
import getText from "../../../localization/getText";


interface Props {
    hide() : void
    list : ITaskWatcher[]
    contractorId: number
    staff: StaffTreeItem
    updateTree?() : void
    afterChange(list:ITaskWatcher[]) : void
}

interface State {
    list : ITaskWatcher[]
    touched : boolean
}

export default class StaffWatcherModal extends React.Component<Props, State>{
    state:State = {
        list : [],
        touched : false
    };

    getTaskPersons = async (q:string) => {
        try {
            const persons = await getPersons({q, contractorsId : [this.props.contractorId]});

            const unique = getUniqueList(persons, this.state.list, {wholeId : 'id', existId : 'personId'});

            const result = unique?.map(item => {
                return {
                    ...item,
                    label : getFullName(item),
                    value : item.id,
                }
            }) ;

            return result || [];

        }catch (e) {
            checkError(e);
            return []
        }
    };

    hide = () => {
        if(this.state.touched) {
            this.props.updateTree && this.props.updateTree();
        }
        this.props.hide();
    };

    onChange = async (option:Option, type:ActionTypes) => {
        const {contractorId, staff} = this.props;
        try {
            switch (type) {
                case "remove-value":
                    const deletedPerson = await deleteTasksPerson(contractorId, staff.id, option.value);

                    if(deletedPerson) {
                        this.setState((prevState) => ({
                            touched : true,
                            list : prevState.list.filter(p => p.id !== option.id)
                        }), this.afterChange);

                        return Promise.resolve(true)
                    }

                    break;
                case "select-option":
                    const addedPerson = await addTasksPerson(contractorId, staff.id, option.value);
                    if(addedPerson) {
                        this.setState((prevState) => ({
                            touched : true,
                            list : [...prevState.list, addedPerson]
                        }), this.afterChange);
                        return Promise.resolve(true)
                    }
                    break;
            }
        }catch (e) {
            checkError(e)
        }

        return Promise.resolve(false);
    };

    afterChange = () => {
        this.props.afterChange(this.state.list);
    };

    componentDidMount(): void {
        this.setState(() => ({list : this.props.list}))
    }

    render () {
        const {staff} = this.props;

        const name = staff.person ? `${staff.person.lastName} ${staff.person.firstName}` : '';

        return (
            <>
                <h4>{staff?.department?.name}. {staff?.position?.name}. {name}</h4>
                <AsyncSelect
                    change={this.onChange}
                    loadOptions={this.getTaskPersons}
                    normalizeParams={{
                        label : (item:ITaskWatcher) => getFullName(item),
                        value : 'id'
                    }}
                    isMulti
                    values={this.state.list}
                />
            </>
        )
    }
}