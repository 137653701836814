export const filterNames = {
    TEST: 'TEST_FILTER',
    CITIES: 'CITIES_FILTER',
    AREAS: 'AREAS_FILTER',
    REGIONS: 'REGIONS_FILTER',
    SQUARES: 'SQUARES_FILTER',
    EQUIPMENT_TYPES: 'EQUIPMENT_TYPES_FILTER',
    ORDER_TYPES: 'ORDER_TYPES_FILTER',
    ORDERS: 'ORDERS_FILTER',
    CONTRACTS: 'CONTRACTS_FILTER',
    CONTRACTORS: 'CONTRACTORS_FILTER',
    OBJECTS: 'OBJECTS_FILTER',
    PERSONS: 'PERSONS_FILTER',
    EQUIPMENT_WORKS: 'EQUIPMENT_WORKS',
    EQUIPMENTS: 'EQUIPMENTS',
    REGULAR_WORKS: 'REGULAR_WORKS',
    EQUIPMENT_GROUPS: 'EQUIPMENT_GROUPS',
    WORK_ORDERS: 'WORK_ORDERS',
    ACTS: 'ACTS_FILTER',
    TASKS: 'TASKS_FILTER',
    ELEMENTS: 'ELEMENTS_FILTER',
    PRIORITIES: 'PRIORITIES_FILTER',
    CONDITIONS: 'CONDITIONS_FILTER',
    URGENCY_ORDERS: 'URGENCY_ORDERS',
    EXECUTOR_WO: 'EXECUTOR_WO',
    EXETUTORS_ACCEPTION: 'EXETUTORS_ACCEPTION',
    DEPARTMENTS: 'DEPARTMENTS',
    BASE_POINTS: 'BASE_POINTS',
    MASTERS: 'MASTERS',
};

export const clientFilterParams = {
};