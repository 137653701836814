import React, { Component, useEffect, useState } from 'react'
import moment from 'moment'
import getPersonPhoto from '../../../utils/getPersonPhoto'
import './comment.view.css'
import PersonInfo from '../dialog/personInfo/personInfo.controller'
import {
	Comment,
	AddFile,
	IActComment,
	ModulesResponse,
} from '@justpro/terminal'
import TextArea from '../textArea/textArea.controller'
import Button from '../button/button'
import ButtonDropzone from '../dropzone/button/buttonDropzone.controller'
import { toast } from 'react-toastify'
import File from '../fileManager/file.preview'
import checkError from '../../../utils/checkError'
import { ITaskWatcher, Person, ITaskCoworker } from '@justpro/terminal'
import { fullName } from '../../tasks/task.listItem'
import RenderIf from '../../../utils/renderIf'
import TextEditorController from '../textEditor/textEditor.controller'
import { ApplicationMapState } from '../../application/application.controller'
import { connect } from 'react-redux'
import Spinner from '../spinner/spinner.controller'
import Tooltip from '../tooltip/tooltip.controller'
import GetText from '../../../localization/getText'
import getText from '../../../localization/getText'
import { renderToString } from 'react-dom/server'
import { MAX_FILE_SIZE } from '@justpro/terminal'

interface CommentProps extends Comment {
	isVisible?: boolean
	readComment?(commentId: number): void
	onToggleVisible?(...a: any[]): void
	customAction?: {
		name: string
		onAction: (...args: any[]) => any
	}
}

export class SingleComment extends React.Component<CommentProps> {
	readComment = (id: number) => {
		const { readComment } = this.props
		try {
			if (readComment && typeof readComment === 'function') {
				readComment(id)
			} else {
				toast.warn('Не передана функция для чтения коментария.')
			}
		} catch (e) {
			checkError(e)
		}
	}

	toggleCommentVisible = () => {
		this.props.onToggleVisible && this.props.onToggleVisible()
	}

	render() {
		const {
			files,
			message,
			author,
			createdAt,
			new: isNew,
			id,
			readComment,
			isVisible,
		} = this.props

		const cls = ['wrapper']

		if (isNew) {
			cls.push('new-comment')
		}

		const photo = getPersonPhoto(author?.person?.avatar)
		return (
			<div className={cls.join(' ')} key={id}>
				<div className='photo'>
					<img src={photo} alt='Фото' />
				</div>

				<div>
					<div className='comment-top'>
						<span className='name'>
							<PersonInfo
								person={author.person}
								customAction={this.props.customAction}
							>
								{author.person.firstName}{' '}
								{author.person.lastName}
							</PersonInfo>
						</span>

						<span className='date'>
							{moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
						</span>
					</div>

					<div
						className='comment'
						dangerouslySetInnerHTML={{ __html: message }}
					/>

					{files &&
						files.length > 0 &&
						files.map((file, index) => {
							return (
								<File
									file={file}
									key={index}
									files={files}
									withDelete={{
										handler: (id: number) =>
											Promise.resolve(void 0),
									}}
								/>
							)
						})}
				</div>

				<div className='comment__icons'>
					{isNew && readComment && (
						<div
							className='just-pro__unread-comment'
							onClick={this.readComment.bind(this, id)}
						>
							<i className='fa fa-comment' />
						</div>
					)}

					<RenderIf
						condition={
							this.props.onToggleVisible && isVisible === true
						}
					>
						<div
							className='just-pro__hide-comment'
							onClick={this.toggleCommentVisible}
						>
							<Tooltip
								title='Ограничить просмотр'
								position='left'
							>
								<i className='fa fa-eye' />
							</Tooltip>
						</div>
					</RenderIf>

					<RenderIf
						condition={
							this.props.onToggleVisible && isVisible === false
						}
					>
						<div
							className='just-pro__hide-comment'
							onClick={this.toggleCommentVisible}
						>
							<Tooltip
								title='Сделать доступным для всех'
								position='left'
							>
								<i className='fa fa-eye-slash' />
							</Tooltip>
						</div>
					</RenderIf>
				</div>
			</div>
		)
	}
}

interface MyFiles {
	name: string
	blob: any
	id: number
	rejected: boolean | string
}

interface ICommentsProps {
	comments: Comment[]
	rightsName: string
	persons?: (ITaskWatcher | ITaskCoworker | Person)[]
	buttonsAlwaysVisible?: boolean

	addComment(comment: string, files?: AddFile[]): void

	removePerson?(person: any): any

	readComment?(commentId: number): void
	toggleVisible?(...a: any[]): void
	maxFileSize?: number
	rights?: Partial<ModulesResponse>
	loading?: boolean

	hideCommentRight?: string // Право отвечающее за скрывание комментария для определенного модуля
}

interface ICommentsState {
	value: string
	files: MyFiles[]
	errors: number[]
	isLoading: boolean
}

class Comments extends Component<ICommentsProps, ICommentsState> {
	state = {
		value: '',
		files: [],
		errors: [],
		isLoading: false,
	}

	componentDidUpdate(prevProps: ICommentsProps) {
		const comments = document.querySelector('.just-pro__comments')
		if (
			this.state.value.trim() !== '' &&
			comments &&
			this.state.value.trim().length === 1
		) {
			const panel = comments.closest('.content-panel')

			console.log({ panel })
			if (panel) {
				const height = panel.scrollHeight
				panel.scrollTo({ top: height, behavior: 'smooth' })
			}
		}
		if (
			JSON.stringify(prevProps.persons) !==
			JSON.stringify(this.props.persons)
		) {
			const prevPersons =
				Array.isArray(prevProps.persons) && prevProps.persons.length > 0
					? prevProps.persons
							?.map((person) => person.firstName)
							.join(', ') + ','
					: ''

			const prevValue = this.state.value.replace(prevPersons, '')

			const newPersons =
				Array.isArray(this.props.persons) &&
				this.props.persons.length > 0
					? this.props.persons
							?.map((person) => person.firstName)
							.join(', ') + ', '
					: ''

			console.log({
				prevValue,
				prevPersons,
				stateVal: this.state.value,
				newPersons,
			})

			this.setState({
				value: newPersons + prevValue,
			})
		}
	}

	addComment = async () => {
		const { rightsName, rights } = this.props

		//@ts-ignore
		if (
			rights &&
			rights.hasOwnProperty(rightsName) &&
			rights[rightsName]['create']
		) {
			try {
				this.setState({
					isLoading: true,
				})
				const resultFiles = this.state.files.map((file: MyFiles) => {
					const { id, ...rest } = file
					return {
						...rest,
					}
				})

				await this.props.addComment(this.state.value, resultFiles)

				this.setState({
					files: [],
					value: '',
				})
			} catch (e) {
				checkError(e)
			} finally {
				this.setState({
					isLoading: false,
				})
			}
		}
	}

	sendHandler = async () => {
		if (this.state.value.trim() !== '') {
			await this.addComment()
		} else {
			toast.warn('Значение не может быть пустым')
		}
	}

	onChangeComment = (value: string) => {
		this.setState({
			value,
		})
	}

	dismissSubmit = () => {
		this.setState({
			value: '',
		})
	}

	dismissFileFromComment = (id: number) => {
		const newFiles = this.state.files.filter(
			(item: MyFiles) => item.id !== id,
		)
		this.setState({
			files: newFiles,
		})
	}

	setFile = (newFiles: File[], rejectedFiles: File[]) => {
		let resultFiles: MyFiles[] = []

		newFiles.forEach((file) => {
			resultFiles.push({
				name: file.name,
				blob: file,
				id: file.lastModified,
				rejected: false,
			})
		})

		// todo переделать errors ....
		const errors: number[] = []
		const maxFileSize = this.props.maxFileSize || MAX_FILE_SIZE
		rejectedFiles.forEach((rejected) => {
			errors.push(rejected.lastModified)

			resultFiles.push({
				rejected: `Файл сликом большой, максимальный размер ${Math.round(
					maxFileSize / 1024 / 1024,
				)} Мб`,
				name: rejected.name,
				blob: rejected,
				id: rejected.lastModified,
			})
		})
		this.setState((prevState) => ({
			files: [...prevState.files, ...resultFiles],
			errors,
		}))
	}

	componentDidMount() {}

	render() {
		const { comments, persons, loading, hideCommentRight } = this.props
		const { value, files, isLoading } = this.state
		const { rights, rightsName, buttonsAlwaysVisible } = this.props

		//@ts-ignore
		const canRead =
			rights &&
			rights.hasOwnProperty(rightsName) &&
			rights[rightsName]['read']
		//@ts-ignore
		const canCreate =
			rights &&
			rights.hasOwnProperty(rightsName) &&
			rights[rightsName]['create']
		return (
			<div className='just-pro__comments'>
				{!loading &&
					canRead &&
					comments.map((item: Comment) => {
						return (
							<SingleComment
								{...item}
								key={item.createdAt}
								readComment={this.props.readComment}
								onToggleVisible={
									this.props.toggleVisible &&
									this.props.toggleVisible.bind(this, item)
								}
							/>
						)
					})}
				{canCreate && (
					<div className='just-pro__add_comment'>
						<TextEditorController
							html={value}
							onChange={this.onChangeComment}
							placeholder={renderToString(
								GetText('UI.comments.placeholder'),
							)}
							customName='form-control just-pro__add_comment'
							customEnterFunc={this.sendHandler}
							toolbar
							minHeight={100}
						/>

						{files.length > 0 && (
							<div className='just-pro__comment-files'>
								{files.map((item: MyFiles) => {
									const { rejected } = item
									let cls = ['just-pro__comment-file']

									if (rejected) {
										cls.push('rejected')
										toast.error(rejected)
									}

									return (
										<span
											className={cls.join(' ')}
											key={item.id}
										>
											{' '}
											{item.name}
											<span
												className='just-pro__comment-file-dismiss'
												onClick={this.dismissFileFromComment.bind(
													null,
													item.id,
												)}
											>
												x
											</span>
										</span>
									)
								})}
							</div>
						)}
						<RenderIf condition={persons?.length}>
							<div className='just-pro__notify'>
								<div className='just-pro__notify-title'>
									Уведомить:
								</div>
								{persons?.map((person, index) => (
									<div
										className='just-pro__notify-person'
										key={index}
									>
										{fullName(person)}
										<span
											className='just-pro__notify-delete'
											onClick={() => {
												this.props.removePerson &&
													this.props.removePerson(
														person,
													)
											}}
										>
											x
										</span>
									</div>
								))}
							</div>
						</RenderIf>

						{(value.trim() !== '' || buttonsAlwaysVisible) && (
							<div className='just-pro__comment-buttons'>
								<Button
									className='btn-success'
									onClick={this.addComment}
									disabled={isLoading}
								>
									{GetText('UI.comments.buttonAdd')}
								</Button>
								<ButtonDropzone
									maxSize={this.props.maxFileSize}
									setFile={this.setFile}
									name={GetText('UI.comments.fileUpload')}
								/>
								<Button
									className='btn-warning'
									onClick={this.dismissSubmit}
									disabled={isLoading}
								>
									{GetText('UI.comments.buttonCancel')}
								</Button>
							</div>
						)}
					</div>
				)}

				<Spinner loading={isLoading} />

				{typeof this.props.loading === 'boolean' && (
					<Spinner loading={this.props.loading} />
				)}
			</div>
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(Comments)
