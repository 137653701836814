import React from "react";
import Tabs from "../UI/tabs/tabs.controller";
import {History, File, AddFile, SingleNews, NewsComment, AddComment, Comment,
    getSingleNewsComments, createSingleNewsComment, createSingleNewsFile,
    getSingleNewsFiles, getSingleNewsHistory, updateSingleNewsComment, deleteSingleNewsFile} from "@justpro/terminal"
import Comments from "../UI/comments/comments.controller";
import ModuleHistory from "../UI/moduleHistory/history.controller";
import Files from "../UI/fileManager/files.controller";
import checkError from "../../utils/checkError";
import {connect} from "react-redux";
import Spinner from "../UI/spinner/spinner.controller";


interface Props {
    rights: any
    singleNews?: SingleNews
}

interface State {
    loading: boolean
    comments: NewsComment[],
    history: History[],
    files: File[],
}


class SingleNewsTabs extends React.Component<Props, State> {

    state: State = {
        loading: false,
        comments: [],
        files: [],
        history: []
    }

    changeTab: ((index: number) => any) | void = void 0;

    get tabs() {
        const {singleNews} = this.props;
        const tabs = [];
        if (this.props.rights?.['news.comments']?.read && singleNews?.canCommented) {
            tabs.push({
                title: 'UI.tabs.comments',
                component: () => {
                    return <Comments
                        comments={this.state.comments}
                        addComment={this.addNewComment}
                        buttonsAlwaysVisible={true}
                        rightsName="news.comments"
                        toggleVisible={this.toggleVisible}
                    />
                },
                onSelectTab: this.getComments
            });
        }
        if (this.props?.rights?.['news.history']?.read) {
            tabs.push({
                title: 'UI.tabs.history',
                component: () => {
                    return <ModuleHistory list={this.state.history}/>
                },
                onSelectTab: this.getHistory
            })
        }
        if (this.props?.rights?.['news.files']?.read) {
            tabs.push({
                title: 'UI.tabs.files',
                component: () => {
                    return <Files files={this.state.files}
                                  setFiles={this.addFiles}
                                  isLoading={this.state.loading}
                                  unsetFile={this.removeFile}
                    />
                },
                onSelectTab: this.getFiles
            })
        }
        return tabs;
    }

    updateData = async () => {
        const {rights, singleNews} = this.props;
            if(rights?.['news.comments']?.read && singleNews?.canCommented){
                await this.getComments();
            } else if(rights?.['news.history']?.read){
                await this.getHistory();
            } else if(rights?.['news.files']?.read){
                await this.getFiles();
            }
            this.changeTab && this.changeTab(0);
    }

    componentDidMount(){
        return this.updateData();
    }

    componentDidUpdate(prevProps: Props){
        if(prevProps?.singleNews?.id !== this.props?.singleNews?.id){
            return this.updateData();
        }
    }

    onInitTabs = ({changeTab}: any) => {
        this.changeTab = changeTab;
    };

    getFiles = async () => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            })
            const files = await getSingleNewsFiles(singleNews?.id);
            this.setState({
                files
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    getComments = async () => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            })
            const comments = await getSingleNewsComments(singleNews.id);
            this.setState({
                comments
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    getHistory = async () => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            })
            const history = await getSingleNewsHistory(singleNews?.id);
            this.setState({
                history
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    addNewComment = async (message: string, files: AddFile[]) => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            });
            await createSingleNewsComment(singleNews.id, {
                message,
                files
            });
            const comments = await getSingleNewsComments(singleNews.id);
            this.setState({
                comments
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    toggleVisible = async (comment: NewsComment) => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            });
            await updateSingleNewsComment(singleNews.id, comment.id, {
                isVisible: !comment?.isVisible
            })
            const comments = await getSingleNewsComments(singleNews.id);
            this.setState({
                comments
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    addFiles = async (newFiles: AddFile[]) => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            });
            await createSingleNewsFile(singleNews.id, newFiles);
            const files = await getSingleNewsFiles(singleNews?.id);
            this.setState({
                files
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    removeFile = async (fileId: number) => {
        try {
            const {rights, singleNews} = this.props;
            if (!singleNews?.id) return;
            this.setState({
                loading: true
            });
            await deleteSingleNewsFile(singleNews.id, fileId);
            const files = await getSingleNewsFiles(singleNews?.id);
            this.setState({
                files
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const {loading} = this.state;
        return (
            <>
                <Spinner loading={loading}/>
                <Tabs tabs={this.tabs} defaultIndex={0} onInit={this.onInitTabs}/>
            </>
        )
    }
}

const mapState = (state: any) => ({
    rights: state.application.rights
})

export default connect(mapState)(SingleNewsTabs);