import React, {useState} from "react";
import Input from "../../UI/input/text";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import checkError from "../../../utils/checkError";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";
import {toast} from "react-toastify";


interface Props {
    createSuborder(count:number | string) : Promise<any>
    hide() : void
}

const CreateSubordersModal = ({createSuborder, hide}:Props) => {
    const [count, setCount] = useState();

    const changeCount = (value) => {
        if(!value || value?.trim() === '' ) return;

        const v = parseInt(value);

        if(!isNaN(v)){
            setCount(value)
        }else{
            toast.warn(getText('errors.valueMustBeANumber'))
        }
    };

    const create = async () => {
        await createSuborder(count);
        hide()
    };

    return (

        <>
            <Input
                change={changeCount}
                label="orders.count"
                placeholder="orders.countOfSuborders"
            />

            <DefaultFooterModal>
                <Button className="btn-danger" onClick={hide}>{getText('common.cancel')}</Button>
                <Button className="btn-success" onClick={create}>{getText('common.send')}</Button>
            </DefaultFooterModal>

        </>
    )
};

export default CreateSubordersModal;