import React from "react";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {OTRequest, getOTRequestsAll, ModulesResponse} from "@justpro/terminal";
import OtsyncController from "./otsync.controller";
import moment from "moment";
import './otsync.css'
import checkError from "../../../utils/checkError";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}


class OtsyncsController extends React.Component<Props> {

    renderItem = (item: OTRequest) => {
        return <div className="otsync__list-item">
            <div className="left-block">
                <div className="object-name">{item.object}</div>
                <div className="description">
                    {item.title}
                </div>
            </div>
            <div className="right-block">
                <div className="number">
                    <b>{item.number}</b>
                    <b>{moment(item.createdAt).utc(false).format('DD.MM.YYYY HH:mm')}</b>
                </div>
            </div>
        </div>
    };

    updateOtsyncList = async () => {
        try {

        } catch (err) {
            checkError(err)
        }
    }

    render() {

        const rights = this.props.rights && this.props.rights['otsync'];
        return <BaseReferenceController
            alwaysActive
            getList={getOTRequestsAll}
            listItem={{
                render: this.renderItem
            }}
            search={false}
            CustomHeader={({updateList}: {updateList(): any}) => (
                <div className="otsync-sync-icon-wrapper" onClick={updateList}>
                    <div className="otsync-sync-icon">
                        <i className="fa fa-sync"/>
                    </div>
                </div>
            )}
            Detail={OtsyncController}
            referenceName="otsync.moduleName"
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(OtsyncsController)