import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import './executorCompareHours.css';
import {
  Contractor,
  getCompareExecutorsHours,
  format,
  CompareExecutorsHours,
  ActWithHours,
  ModulesResponse,
} from '@justpro/terminal';
import checkError from '../../../utils/checkError';
import Table from '../../UI/table/table';
import Spinner from '../../UI/spinner/spinner.controller';
import ExecutorObjectHoursModal from './executorObjectHours.modal';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import moment, { Moment } from 'moment';
import { ApplicationMapState } from '../../application/application.controller';
import { connect } from 'react-redux';
import HasNoRightsController from '../../UI/hasNoRights/hasNoRights.controller';
import getText from '../../../localization/getText';
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types';
import { openModal } from '../../../store/modal/modal.actions';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';

interface ResultContractors {
  justPro: number | null;
  odinS: number | null;
  contractor: string;
}

interface Result extends CompareExecutorsHours {
  rowResultJustPro: number;
  rowResult1S: number;
  contractors: ResultContractors[];
}

interface Props {
  rights?: Partial<ModulesResponse>;
  openModal(props: Modal): void;
}

interface State {
  loading: boolean;

  selectedRow?: number;
  list?: Result[];
  contractors?: Contractor[];
  // executor?: string
  // contractor? :string
  // acts?: ActWithHours[]
  // executors?: Person[]
  from: Moment;
  to: Moment;
}

class ExecutorCompareHours extends React.Component<Props, State> {
  state: State = {
    loading: false,
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
  };

  changeFrom = (from: Moment) => {
    this.setState(() => ({ from }), this.getList);
  };
  changeTo = (to: Moment) => {
    this.setState(() => ({ to }), this.getList);
  };

  floatCalc = (num1: number, num2: number) => +(num1 + num2).toFixed(2);

  getList = async () => {
    const canRead = this.props.rights?.['reports.compareExecutorsHours']?.read;

    if (canRead) {
      try {
        this.setState(() => ({ loading: true }));

        const executorsHours = await getCompareExecutorsHours({
          dateFrom: this.state.from.format(format.date),
          dateTo: this.state.to.format(format.date),
        });

        const allContractors = executorsHours?.reduce((acc: string[], item) => {
          item.acts?.forEach((act) => {
            if (acc.find((c) => c === act.contractor) === undefined) {
              acc.push(act.contractor);
            }
          });
          return acc;
        }, []);

        const calcRow = (
          array: ActWithHours[],
          accessor: 'hoursJustpro' | 'time'
        ): number => {
          return (
            array?.reduce(
              (acc: number, act) => this.floatCalc(acc, +act[accessor] || 0),
              0
            ) || 0
          );
        };

        const getRow = (acts: ActWithHours[]) => {
          const [rowResultJustPro, rowResult1S, contractors] = acts?.reduce(
            (
              acc: [number, number, ResultContractors[]],
              item,
              index,
              selfArray
            ) => {
              const contractors = allContractors?.map((contractor) => {
                const contractorActs = selfArray.filter(
                  (i) => i.contractor === contractor
                );

                if (contractorActs.length > 0) {
                  return {
                    contractor,
                    justPro: calcRow(contractorActs, 'hoursJustpro'),
                    odinS: +(calcRow(contractorActs, 'time') / 3600).toFixed(2),
                  };
                }

                return {
                  contractor,
                  odinS: null,
                  justPro: null,
                };
              });

              return [
                this.floatCalc(acc[0], +item.hoursJustpro || 0),
                this.floatCalc(acc[1], +item.time || 0),
                contractors,
              ];
            },
            [0, 0, []]
          );

          return {
            rowResultJustPro,
            rowResult1S: rowResult1S,
            contractors,
          };
        };

        const result: Result[] = executorsHours?.map((item) => {
          return {
            ...item,
            ...getRow(item.acts),
          };
        });

        this.setState(() => ({ list: result }));
      } catch (e) {
        checkError(e);
      } finally {
        this.setState(() => ({ loading: false }));
      }
    }
  };

  openModal = (executor: string, contractor: string, acts?: ActWithHours[]) => {
    this.props.openModal({
      id: 'executorCompareHoursModal',
      size: 'large',
      component: () => (
        <ExecutorObjectHoursModal
          // executor={this.state.executor}
          contractor={contractor}
          acts={acts}
          updateList={this.getList}
        />
      ),
      title: `${executor} - ${contractor}`,
    });
  };

  get columns() {
    const defaultCells = [
      {
        Header: '№',
        Cell: (props: any) => props.viewIndex + 1,
        width: 20,
      },
      {
        Header: getText('common.executor'),
        accessor: 'personFullName',
        width: 400,
      },
    ];

    const getCell = (
      original: any,
      currentContractor: ResultContractors,
      accessor: 'justPro' | 'odinS'
    ): null | JSX.Element => {
      const contractor = original.contractors?.find(
        (c: ResultContractors) => c.contractor === currentContractor.contractor
      );

      if (!contractor) return null;

      const isEqual = Object.is(contractor.justPro, contractor.odinS);

      return (
        <span
          className={`compareHours__cell-item ${isEqual ? 'equal' : 'unequal'}`}
          onClick={this.openModal.bind(
            this,
            original.personFullName,
            contractor.contractor,
            original.acts
          )}
        >
          {contractor[accessor]}
        </span>
      );
    };

    const calcColumn = (
      contractor: ResultContractors,
      accessor: 'justPro' | 'odinS'
    ) => {
      return this.state.list?.reduce((acc: number, item: Result) => {
        const finded = item.contractors.find(
          (c) => c.contractor === contractor.contractor
        );

        if (accessor === 'odinS' && contractor[accessor] !== null) {
        }

        if (finded && finded[accessor]) {
          //@ts-ignore
          return this.floatCalc(acc, +finded?.[accessor]);
        }

        return acc;
      }, 0);
    };

    const calcResultOfResult = (accessor: 'justPro' | 'odinS') => {
      const allItems = document.querySelectorAll(`.result.${accessor}`);

      return Array.prototype.slice
        .call(allItems)
        ?.reduce((acc: number, item) => {
          //@ts-ignore
          return this.floatCalc(acc, +item?.innerText || 0);
        }, 0);
    };
    const getProps = (bcg: string) => ({
      style: {
        backgroundColor: bcg,
        alignSelf: 'stretch',
      },
    });

    const contractors =
      this.state.list?.[0]?.contractors?.map((contractor) => {
        return {
          Header: contractor.contractor,

          columns: [
            {
              Header: 'JustPro',
              Cell: ({ original }: any) =>
                getCell(original, contractor, 'justPro'),
              Footer: () => (
                <span className="result justPro">
                  {calcColumn(contractor, 'justPro')}
                </span>
              ),

              getProps: getProps.bind(this, '#FDF5F5'),
              width: 75,
            },
            {
              Header: '1C',
              Cell: ({ original }: any) =>
                getCell(original, contractor, 'odinS'),
              Footer: () => (
                <span className="result odinS just-pro--text-overflow">
                  {calcColumn(contractor, 'odinS')}
                </span>
              ),
              getProps: getProps.bind(this, '#FAFDFA'),
              width: 75,
            },
          ],
        };
      }) || [];

    const resultColumn = [
      {
        Header: getText('common.total'),
        columns: [
          {
            Header: 'JustPro',
            Cell: ({ original }: any) => (
              <span className="result justPro">
                {original.rowResultJustPro}
              </span>
            ),
            Footer: calcResultOfResult.bind(this, 'justPro'),
            getProps: getProps.bind(this, '#FDF5F5'),
          },
          //
          {
            Header: '1C',
            Cell: ({ original }: any) => (
              <span className="result odinS">
                {(original.rowResult1S / 3600).toFixed(2)}
              </span>
            ),
            Footer: calcResultOfResult.bind(this, 'odinS'),
            getProps: getProps.bind(this, '#FAFDFA'),
          },
        ],
      },
    ];
    return [...defaultCells, ...contractors, ...resultColumn];
  }

  documentEvent = (e: any) => {
    const target = e?.target?.closest('.executorCompareHours .rt-tr-group');

    if (target) {
      document
        .querySelectorAll('.executorCompareHours .rt-tr-group')
        .forEach((item) => item.removeAttribute('style'));

      target.style.border = '1px solid #de1118';
    }
  };

  addDocumentEvent = () => {
    document.addEventListener('click', this.documentEvent);
  };

  removeDocumentEvent = () => {
    document.removeEventListener('click', this.documentEvent);
  };

  normalizeStickyPosition = () => {
    const parent = document.querySelector('.rt-tr');

    if (parent) {
      const height = parent.clientHeight;
      const subHeader = document.querySelector(
        '.compareHours .ReactTable .rt-thead.-header'
      );

      if (subHeader) {
        //@ts-ignore
        subHeader.style.top = `${height - 5}px`;
      }
    }
  };

  async componentDidMount() {
    this.getList();
    this.addDocumentEvent();
    this.normalizeStickyPosition();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.getList();
    }

    if (JSON.stringify(this.state.list) !== JSON.stringify(prevState.list)) {
      this.normalizeStickyPosition();
    }
  }

  componentWillUnmount(): void {
    this.removeDocumentEvent();
  }

  render() {
    const { list } = this.state;
    const { rights } = this.props;

    const moduleName = getText('reports.executorCompareHours.moduleName');

    if (!rights) {
      return (
        <WithPrivateRoute>
          <HeaderController>
            <ModuleTextName>{moduleName}</ModuleTextName>
          </HeaderController>
        </WithPrivateRoute>
      );
    }

    const canRead = rights?.['reports.compareExecutorsHours']?.read;

    if (!canRead) {
      return (
        <WithPrivateRoute>
          <HeaderController>
            <ModuleTextName>{moduleName}</ModuleTextName>
          </HeaderController>
          <div className="just-pro_module compareHours">
            <div className="panel content-panel">
              <HasNoRightsController />
            </div>
          </div>
        </WithPrivateRoute>
      );
    }

    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>{moduleName}</ModuleTextName>

          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.changeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.changeTo} />
          </ToplineCalendarWrapper>
        </HeaderController>

        <div className="just-pro_module compareHours">
          <div className="panel content-panel">
            {list && (
              <Table
                columns={this.columns}
                data={list}
                sortable={false}
                wrapperClassName="report_flex-table executorCompareHours"
              />
            )}
            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

const mapState = (state: ApplicationMapState) => ({
  rights: state.application.rights,
});

const mapDispatch = (d: Function) => ({
  openModal: (props: Modal) => d(openModal(props)),
});

export default connect(mapState, mapDispatch)(ExecutorCompareHours);
