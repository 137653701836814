import React from "react";
import ActDropzone from "../actDropzone";
import '../act.css'
import {IScan} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    actId : number
    scans? : IScan[]
}

class ActScans extends React.Component<Props>{


    render() {


        return (
            <div className="just-pro__act-scans">
                <h4>{getText('acts.actPages')}</h4>
                <ActDropzone
                    actId={this.props.actId}
                    scans={this.props.scans}
                />
            </div>
        )
    }
}

export default ActScans