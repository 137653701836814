import React from "react";
import '../act.css'
import {Option} from "../../UI/select/select.types";
import {editObject} from "@justpro/terminal";
import {getContractors1SList, getFilials1SList, getStores1SList} from "../../../utils/functions";
import RenderIf from "../../../utils/renderIf";
import {IAct, editContractor, editPerson} from "@justpro/terminal";
import AsyncSelect from "../../UI/select/asyncSelect";
import getText from "../../../localization/getText";

interface Props {
    act: IAct
    getAct?() : Promise<void>
}

interface State {
    contractor1s? : Option
    filial1s? :     Option
    store1s?  :     Option
}

class Act1s extends React.Component<Props, State> {

    state:State = {};

    changeObjectFilial = async (option: Option) => {

        await editObject(this.props.act?.object?.id, {filial1sId: option.value});
        this.props.getAct && await this.props.getAct();
    };

    changeContractor1S = async (option: Option) => {
        await editContractor(this.props.act?.object?.contracts?.[0]?.contractor?.id,
            {
                contractor1sId: option.value
            });
        this.props.getAct && await this.props.getAct();

    };

    changePersonStore1S = async (option: Option) => {
        await  editPerson(this.props.act?.responsible.id,
            {
                store1sId: option.value
            });
        this.props.getAct && await this.props.getAct();
    };



    render() {
        const {act} = this.props;
        const {} = this.state;
        return (
            <>
                <RenderIf condition={!act?.object?.filial1s}>
                    <div className="just_pro__act-1c">
                        <h4>{getText('acts.objectDoesNotLinkingTo1s')}</h4>
                        <AsyncSelect
                            change={this.changeObjectFilial}
                            loadOptions={getFilials1SList}
                            placeholder="acts.linkObjectTo1sFilial"
                            // accessors={{
                            //     name : 'label',
                            //     id1s : 'value'
                            // }}
                        />

                    </div>
                </RenderIf>
                <RenderIf condition={!act?.object?.contracts?.[0]?.contractor?.contractor1s}>
                    <div className="just_pro__act-1c">
                        <h4>{getText('acts.contractorDoesNotLinkingTo1s')}</h4>
                        <AsyncSelect
                            change={this.changeContractor1S}
                            loadOptions={getContractors1SList}
                            // placeholder="Привязать котрагента к 1с контрагенту"
                            // accessors={{
                            //     name : 'label',
                            //     id1s : 'value'
                            // }}
                        />

                    </div>
                </RenderIf>
                <RenderIf condition={!act?.responsible?.store1s}>
                    <div className="just_pro__act-1c">
                        <h4>{getText('acts.executorDoesNotLinkingTo1s')}</h4>
                        <AsyncSelect
                            change={this.changePersonStore1S}
                            loadOptions={getStores1SList}
                            placeholder="acts.linkExecutorTo1sFilial"
                            // accessors={{
                            //     name : 'label',
                            //     id1s : 'value'
                            // }}
                        />

                    </div>
                </RenderIf>
            </>
        )
    }

}

export default Act1s;
