import React from 'react';
import Calendar from "../calendar.controller";
import {GoNow, Table} from "../datepicker.view";
import {StyledCalendar} from "../calendar.view";
import Tooltip from "../../tooltip/tooltip.controller";

export default class DesctopCalendar extends Calendar{

    render () {
        return (
            <div>
                <StyledCalendar>
                    <Table>
                        {this.getHeaderTable()}
                        {this.getBodyTable()}
                    </Table>
                </StyledCalendar>
            </div>
        )
    }
}