import React from 'react';
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getAreasList} from '../../../utils/functions'
import withLabel from "../../UI/withLabel/withLabel";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";

import {EditCity, City, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

type Props = {
    city : City
    updateCity(data:Partial<EditCity>) : void
    rights?: Partial<ModulesResponse>
}

class CityDetail extends React.Component<Props> {

    blurName = async (name: string, oldValue: string ) => {
        if(name !== oldValue) {
            this.props.updateCity({name})
        }
    };

    changeArea = async (option:Option) => {
        await this.props.updateCity({areaId : option.value});

        return Promise.resolve();
    };

    toggleActive = async (active:boolean) => {
        await this.props.updateCity({active})
    };

    render () {
        const {rights, city} = this.props;
        const {area, name, active} = city;

        const disabled = rights && rights['references.cities'] && !rights['references.cities']['edit'];
        return (
            <>
                <h3>{getText('cities.city')} : {name}</h3>

                <ToggleSwitch
                    defaultValue={active}
                    send={this.toggleActive}
                    label="common.active"
                    disabled={disabled}
                />

                <Input
                    label="cities.city"
                    blur={this.blurName}
                    placeholder="cities.city"
                    startValue={name}
                    disabled={disabled}
                />
                <Select
                    change={this.changeArea}
                    load={getAreasList}
                    defaultValue={area ? area : undefined}
                    label="areas.area"
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    isDisabled={disabled}
                />
                {withLabel(
                    <span>{area && area.country.name}</span>
                )({
                    text : 'countries.country'
                })}
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(CityDetail)