import React from "react";
import {connect} from "react-redux";
import {ApplicationMapState} from "../components/application/application.controller";
import i18n from '../i18n'
import getLang from "../utils/getLang";
import checkError from "../utils/checkError";
import {getUserMe} from "../store/application/application.actions";

interface Props {
    getUserMe() : void
    me?: any
}

class Localization extends React.Component<Props>{

    async getLang () {
        try {
            const {me} = this.props;

            if (!me) {
                await getUserMe()
            } else {
                i18n.changeLanguage(getLang(me?.settings?.language?.id))
            }
        } catch (err){
            checkError(err);
        }
    }

    async componentDidMount() {
        this.getLang()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(JSON.stringify(this.props.me) !== JSON.stringify(prevProps.me)) {
            this.getLang()
        }
    }


    render () {
        return this.props.children
    }

}


const mapState = (state:ApplicationMapState) => ({
    me : state.application.me
});

const mapDispatch = (dispatch:Function) => ({
    getUserMe : () => dispatch(getUserMe)
});

export default connect(mapState, mapDispatch)(Localization)