import React, { ChangeEvent } from 'react'
import {
	Person,
	createTask,
	ITask,
	ICreateTask,
	getFullName,
	createAdminWorkorder,
	addPersonBasePoint,
	OrdersWorkorder,
	addBasePoint,
	CreateAdminWorkorder,
	getPerson,
	addRegionBasePoint,
} from '@justpro/terminal'
import checkError from '../../../../utils/checkError'
import { getExecutorsList, getPersonsList } from '../../../../utils/functions'
import Select from '../../select/select'
import { ChangeMultiProps, LoadReturn, Option } from '../../select/select.types'
import moment, { Moment } from 'moment'
import InlineCalendar from '../../calendar/views/inline.controller'
import { connect } from 'react-redux'
import { MapState } from '../../../mainNav/mainNav.types'
import RenderIf from '../../../../utils/renderIf'
import SavedAdminWorkordersTemplates from './savedAdminWorkorders'
import './createAdminWorkorder.css'
import getText from '../../../../localization/getText'
import { Modal } from '../../../../store/modal/modal.types'
import { openModal, updateModal } from '../../../../store/modal/modal.actions'
import DefaultFooterModal from '../modal/defaultFooterModal'
import Button from '../../button/button'
import Spinner from '../../spinner/spinner.controller'
import { CreateWorkOrder } from '../createWorkOrders/workOrderModal.view'
import TextArea from '../../textArea/textArea.controller'
import AsyncSelect from '../../select/asyncSelect_v2'
import WorkOrderController from '../../../workOrders/workOrder.controller'
import { getCitiesByArea } from '../../../../utils/selectGroups'
import Input from '../../input/text'
import { ISelectPerson } from '../../../tasks/task.detail'

interface Props {
	// updateModal(id:string, props:Partial<Modal>): void
	openModal(props: Modal): void

	hide(): void

	me?: any
	settings?: any
}

interface State {
	isLoading: boolean
	date: Moment
	responsible?: Option
	basePoint?: Option
	content: string
	workorder?: OrdersWorkorder
	newBasePoint: boolean
	saveBasePoint: boolean
	regional: boolean
	city?: Option
	address: string
	// template? : Partial<ICreateTask>
}

class CreateAdminWorkorderController extends React.Component<Props, State> {
	state: State = {
		isLoading: false,
		newBasePoint: false,
		saveBasePoint: false,
		regional: false,
		content: '',
		basePoint: void 0,
		responsible: void 0,
		address: '',
		date: moment(),
	}

	async componentDidMount() {
		const persons = await getExecutorsList()

		const responsible = persons.find(
			(p) => p.id === this.props.me?.person?.id,
		)
		this.setState({
			responsible: {
				...responsible,
				label: getFullName(responsible),
				value: responsible.id,
			},
		})
	}

	isDisabled = () => {
		const { content, responsible, basePoint, newBasePoint, address, city } =
			this.state
		return !(
			content?.length &&
			responsible &&
			(basePoint || (newBasePoint && city && address?.length))
		)
	}

	changeContent = (content: string) => {
		this.setState({
			content,
		})
	}

	changeResponsible = (option: Option) => {
		this.setState({
			responsible: option,
		})
		return Promise.resolve()
	}

	changeDate = (date: Moment) => {
		this.setState({
			date,
		})
	}

	changeBasePoint = (basePoint: Option) => {
		this.setState({
			basePoint,
		})
		return Promise.resolve()
	}

	// setTemplate = async (template: Partial<>) => {
	//
	// };

	getExecutorsList = (q: string): LoadReturn => {
		const { settings } = this.props
		return new Promise(async (resolve) => {
			const persons = await getExecutorsList(q, [
				+settings.tfmContractorId,
			])
			resolve(
				persons.map((p) => ({
					label: getFullName(p),
					value: p.id,
					...p,
				})),
			)
		})
	}

	changeNewBasePoint = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			newBasePoint: e.target.checked,
		})
	}

	changeSaveBasePoint = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			saveBasePoint: e.target.checked,
		})
	}

	changeRegional = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			regional: e.target.checked,
		})
	}

	onChangeCity = (city: Option) => {
		this.setState((prevState) => ({
			city,
		}))
		return Promise.resolve()
	}

	onChangeAddress = (address: string) => {
		this.setState({
			address,
		})
	}

	createAdminWorkorder = async () => {
		try {
			const {
				content,
				date,
				basePoint,
				responsible,
				city,
				address,
				newBasePoint,
				saveBasePoint,
				regional,
			} = this.state
			this.setState({
				isLoading: true,
			})
			let basePointId = basePoint?.value
			if (newBasePoint && city && address && !regional) {
				const basePoint = await addBasePoint({
					personId: saveBasePoint ? responsible.value : null,
					cityId: city.value,
					address,
				})
				basePointId = basePoint.id
			}
			if (newBasePoint && saveBasePoint && city && address && regional) {
				const person = await getPerson(responsible.value)
				//@ts-ignore
				const regionId = person?.executors?.[0]?.regions?.[0]?.regionId
				if (!regionId) {
					throw new Error(
						`У пользователя отсутствует исполнитель или регион`,
					)
				}
				const basePoint = await addRegionBasePoint(regionId, {
					cityId: city.value,
					address,
					regionId,
				})
				basePointId = basePoint.objectId
			}
			const workorder = await createAdminWorkorder({
				content,
				date: date.format('YYYY-MM-DD'),
				basePointId,
				responsibleId: responsible.value,
			})
			this.setState({
				workorder,
			})
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				isLoading: false,
			})
		}
	}

	getCurrentValues = (): Partial<CreateAdminWorkorder> => {
		const { content, responsible, basePoint, date } = this.state
		return {
			content,
			date: date.format('YYYY-MM-DD'),
			basePointId: basePoint?.value,
			responsibleId: responsible.value,
		}
	}

	setTemplate = async ({
		basePointId,
		responsibleId,
		content,
		date,
	}: Partial<CreateAdminWorkorder>) => {
		const responsible = responsibleId
			? await getPerson(responsibleId)
			: null
		const basePoint = responsible.basePoints.find((bp) => {
			return bp.objectId === basePointId
		})
		const template: any = {
			content,
			date: moment(date, 'YYYY-MM-DD'),
		}
		if (responsible) {
			template.responsible = {
				...responsible,
				label: getFullName(responsible),
				value: responsible.id,
			}
		}
		if (basePoint) {
			template.basePoint = {
				...basePoint,
				label: basePoint.address,
				value: basePoint.objectId,
			}
		}
		this.setState(template)
	}

	getResponsibleBasePoints = () => {
		return this.state.responsible?.basePoints.map((bp) => ({
			...bp,
			label: `${bp.address}, ${bp.city.name}`,
		}))
	}

	render() {
		const { workorder } = this.state

		return (
			<>
				<div id='toolbar' />
				<div className='create-task_saved-templates'>
					<SavedAdminWorkordersTemplates
						setTemplate={this.setTemplate}
						getCurrentValues={this.getCurrentValues}
					/>
				</div>
				<RenderIf condition={!workorder}>
					<CreateWorkOrder>
						<div className='header'>
							<TextArea
								label={{ text: 'common.target' }}
								startValue={this.state.content}
								placeholder='UI.textArea.placeholders.contentAndMaterials'
								change={this.changeContent}
							/>
							<InlineCalendar
								date={this.state.date}
								onChange={this.changeDate}
								label={{ text: 'common.date' }}
							/>
							<AsyncSelect
								change={this.changeResponsible}
								loadOptions={this.getExecutorsList}
								label={{ text: 'common.responsible' }}
								placeholder='UI.select.placeholders.selectExecutor'
								values={[this.state.responsible]}
							/>
							<Select
								change={this.changeBasePoint}
								load={this.getResponsibleBasePoints}
								label='persons.basePoint'
								defaultValue={this.state.basePoint}
								placeholder='UI.select.placeholders.chooseBasePoint'
								accessors={{
									label: 'label',
									objectId: 'value',
								}}
								isDisabled={this.state.newBasePoint}
							/>
							<div className='just-pro__checkbox-children just-pro__new-base-point'>
								<input
									type='checkbox'
									checked={this.state.newBasePoint}
									onChange={this.changeNewBasePoint}
								/>
								{getText('persons.addBasePoint')}
							</div>
							<RenderIf condition={this.state.newBasePoint}>
								<AsyncSelect
									defaultValue={{
										value: this.state.city,
										accessors: {
											name: 'label',
											id: 'value',
										},
									}}
									loadOptions={(q: string) =>
										getCitiesByArea({ q })
									}
									label={{ text: 'cities.city' }}
									change={this.onChangeCity}
									accessors={{
										name: 'label',
										id: 'value',
									}}
								/>

								<Input
									placeholder='objects.address'
									change={this.onChangeAddress}
									label='objects.address'
									value={this.state.address}
								/>
								<div className='just-pro__checkbox-children just-pro__new-base-point'>
									<input
										type='checkbox'
										checked={this.state.saveBasePoint}
										onChange={this.changeSaveBasePoint}
									/>
									{getText('persons.saveBasePoint')}
								</div>
								<RenderIf condition={this.state.saveBasePoint}>
									<div className='just-pro__checkbox-children just-pro__new-base-point'>
										<input
											type='checkbox'
											checked={this.state.regional}
											onChange={this.changeRegional}
										/>
										{getText(
											'maps.basePoints.regionalPoint',
										)}
									</div>
								</RenderIf>
							</RenderIf>
						</div>
						<RenderIf condition={this.props.hide}>
							<DefaultFooterModal>
								<Button
									className='btn-danger'
									onClick={this.props.hide}
								>
									{getText('common.cancel')}
								</Button>
								<Button
									className='btn-success'
									disabled={this.isDisabled()}
									onClick={this.createAdminWorkorder}
								>
									{getText('common.create')}
								</Button>
							</DefaultFooterModal>
						</RenderIf>
					</CreateWorkOrder>
				</RenderIf>
				<RenderIf condition={workorder}>
					<WorkOrderController workOrder={workorder} />
				</RenderIf>
				<Spinner loading={this.state.isLoading} />
			</>
		)
	}
}

const mapStateToProps = (state: MapState) => ({
	me: state.application.me,
	settings: state.application.settings,
})

const mapDispatch = (d: Function) => ({
	updateModal: (id: string, props: Partial<Modal>) =>
		d(updateModal(id, props)),
	openModal: (props: Modal) => d(openModal(props)),
})

export default connect(
	mapStateToProps,
	mapDispatch,
)(CreateAdminWorkorderController)
