import React from 'react';
import {editMessenger, getMessenger, Messenger} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import MessengerDetail from "./messenger.detail";

interface Props {
    id: number
    afterUpdate?(item:Messenger) : void
}

interface State {
    loading : boolean
    messenger? : Messenger
}

class MessengerController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateMessenger = async (data:Partial<Messenger>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const messenger = await editMessenger(this.props.id, data);

            afterUpdate && afterUpdate(messenger);
            this.setState(() => ({loading : false, messenger}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getMessenger = async () => {
        this.setState(() => ({loading : true}));

        try {
            const messenger = await getMessenger(this.props.id);


            this.setState(() => ({loading : false, messenger}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getMessenger();
        }
    }

    async componentDidMount(){
        this.getMessenger();
    }

    render() {
        const {messenger} = this.state;
        return (
            <>
                <RenderIf condition={messenger !== undefined}>
                    <MessengerDetail
                        messenger={messenger as Messenger}
                        update={this.updateMessenger} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default MessengerController