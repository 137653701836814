import styled from "styled-components";
type WrapperType = {
    active : boolean
    disabled : boolean
}

export const ToggleSwitchView = styled.div<WrapperType>`
    border : 1px solid #ccc;
    height : 27px;
    width : 55px;
    border-radius : 2px;
    box-shadow : 1px 1px 1px rgba(0, 0, 0 ,.1);
    padding : 1px;
    position : relative;
    
    background-color : ${({active, disabled}) => (
                                                active ? 
                                                    disabled ? 
                                                        '#b4e2bc' : '#4CD964' 
                                                : '#F2F3FA'
                        )};
    
    cursor : ${({disabled}) => disabled ? 'not-allowed' : 'pointer'}
    
    .just-pro__toggle {
        height : 100%;
        width : 50%;
        box-shadow : 1px 1px 1px rgba(0, 0, 0 ,.3);
        border-radius: 3px;
        background-color : white;
        position : absolute;
        top : 0;
        
        display : block;
        ${({active}) => active ? 'right' : "left" } : 0;
    }
`;

