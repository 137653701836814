import React from "react";
import TaskDetail from "./task.detail";
import RenderIf from "../../utils/renderIf";
import checkError from "../../utils/checkError";
import {getTask, ITask, ITaskTag, IUpdateTask, ITaskProblem, updateTask, updateTaskProblem, addTaskProblem} from "@justpro/terminal";
import Spinner from "../UI/spinner/spinner.controller";
import TaskPreloader from "./task.preloader";

interface Props {
    id?: number | null
    updateAllList?(): any
    updateListIcons?(taskId: number, data: IUpdateTaskIcons): void
    isChosenSubordinate? : boolean
    subordinatePersonId? : number
}

interface State {
    list: ITask[],
    task?: ITask,
    loading: boolean
}

export interface IUpdateTaskIcons {
    tags?: ITaskTag[],
    problem?: boolean
    priority?: number
    state?: string
}

class TaskController extends React.Component<Props, State> {

    state: State = {
        list: [],
        loading: false
    };


    getTask = async () => {
        try {
            if (!this.props.id) return false;
            this.setState({
                loading: true
            })
            const task = await getTask(this.props.id);
            this.setState({
                task
            });
        } catch (e) {
            checkError(e);
        } finally {
            this.setState({
                loading: false
            })
        }
    };


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.id !== this.props.id) {
            return this.getTask();
        }
    }

    componentDidMount() {
        return this.getTask();
    }

    updateTask = async (data: Partial<IUpdateTask>) => {
        try {
            const task = await updateTask(this.state?.task?.id as number, data);
            this.setState(() => ({
                task
            }));

            this.props.updateAllList && this.props.updateAllList();
        } catch (err) {
            checkError(err);
        }
    };

    updateTaskProblem = async (data: Partial<ITaskProblem>) => {
        try {
            await updateTaskProblem(this.state?.task?.id as number, this.state?.task?.problem?.id as number, data);
            const task = await getTask(this.state?.task?.id as number);
            this.setState({
                task
            });
            this.props.updateAllList && this.props.updateAllList();
        } catch (err) {
            checkError(err);
        }
    };

    addTaskProblem = async (data: Partial<ITaskProblem>) => {
        try {
            await addTaskProblem(this.state?.task?.id as number, data);
            const task = await getTask(this.state?.task?.id as number);
            this.setState({
                task
            });
            this.props.updateAllList && this.props.updateAllList();
        } catch (err) {
            checkError(err);
        }
    };

    render() {
        const {task, loading} = this.state;
        return (
            <>
                <RenderIf condition={task && !loading}>
                    <TaskDetail updateTask={this.updateTask}
                                getTask={this.getTask}
                                // updateList={this.props.updateList}
                                updateTaskProblem={this.updateTaskProblem}
                                addTaskProblem={this.addTaskProblem}
                                updateListIcons={this.props.updateListIcons}
                                updateAllList={this.props.updateAllList}
                                task={task as ITask}
                                isChosenSubordinate={this.props.isChosenSubordinate}
                                subordinatePersonId={this.props.subordinatePersonId}
                                isTaskLoading={this.state.loading}
                    />
                </RenderIf>
                <RenderIf condition={loading}>
                    <TaskPreloader loading={loading}/>
                </RenderIf>
            </>
        )
    }
}

export default TaskController
