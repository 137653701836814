import React from 'react';
import {Region, getRegions, createRegion, ModulesResponse} from "@justpro/terminal";
import RegionCreate from "./region.create";
import RegionController from "./region.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class RegionsController extends React.Component<Props>{


    renderItem = (item:Region) => <>{item.name}</>;

    render () {
        const rights = this.props.rights && this.props.rights['references.regions'];
        return <BaseReferenceController
                    referenceName="regions.moduleName"
                    Detail={RegionController}
                    getList={getRegions}
                    listItem={{render : this.renderItem}}
                    create={{
                        Component : RegionCreate,
                        title : 'regions.addItem',
                        handler : createRegion
                    }}

                    moduleRights={rights}
        />
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(RegionsController)