import React, {useCallback, useState} from "react";
import AsyncS from 'react-select/async'
import {AsyncProps, Option} from "./select.types";
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import './select.css'
import withLabel from "../withLabel/withLabel";
import {toast} from "react-toastify";
import ReactSelect, {components} from "react-select";
import {CustomRemove} from "./multiSelect";
import {renderToString} from "react-dom/server";
import GetText from "../../../localization/getText";
import getText from "../../../localization/getText";


const AsyncSelect = ({
            defaultValue,
            defaultValues,
            change,
            label,
            minLength = 3,
            // accessors,
            placeholder,

            loadOptions,
            withoutValue,
            CustomLabel,
            isMulti = false,
            ...rest
               }:AsyncProps) => {
    // const defaultNoOptions = `Введите ${minLength} символа для поиска...`;
    const defaultNoOptions = GetText("UI.select.minLength", {count : minLength});

    const [value, setValue] = useState<Option | null>( null);
    const [values, setValues] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    // const [options, setOptions] = useState<Option[]>([]);
    const [noOptions, setNoOptions] = useState(defaultNoOptions);


    const onChangeHandler = async (option: any, meta:any) => {
        setLoading(true);
        setDisabled(true);

        if(isMulti) {
            console.log({values})
            const o = meta.action === 'select-option' ? meta.option : meta.removedValue;


            const options = await change(o, meta.action);
            setValues(options ? options : option) // для мульти-селекта
        }else{
            await change(option);
            setValue(option) // для простого селекта

        }

        setDisabled(false);
        setLoading(false);
    };

    const loadOptionsHandler = async (value:string, callback:Function) => {

        if(value.length < minLength) {
            setNoOptions(defaultNoOptions);
            return void 0
        }

        if(value.length >= minLength) {
            const list = await loadOptions([value]);

            if(list.length === 0) {
                setNoOptions(GetText('UI.select.queryNotFount'))
            }

            callback(list);
        }
    };

    const ref = useCallback( () => {
        if(defaultValue) {
            const {value, accessors} = defaultValue;
            let dv:Option | null = null;

            if(value) {
                if(accessors) {
                    dv = makeNormalizeParams(value, accessors)
                }else{
                    if(value.hasOwnProperty('label') && value.hasOwnProperty('value')) {
                        dv = value as Option
                    }else{
                        toast.warn('Ошибка при попытке установить значение по умолчанию.')
                    }
                }
            }

            setValue(dv)


        }

        if(defaultValues) {


            const {values, accessors} = defaultValues;

            if(accessors){
                const result = values?.reduce((acc:Option[], item) => {
                    acc.push(makeNormalizeParams(item, accessors));
                    return acc
                }, []) || [];



                setValues(result)
            }
        }
    }, [JSON.stringify(defaultValue), JSON.stringify(defaultValues)]);

    const cmp = <AsyncS
            ref={ref}
            loadOptions={loadOptionsHandler}
            placeholder={placeholder ? renderToString(getText(placeholder)) : renderToString(GetText("UI.select.defaultChoose"))}
            isDisabled={disabled}
            loadingMessage={() => renderToString(GetText('UI.select.loading'))}
            onChange={onChangeHandler}
            noOptionsMessage={() => renderToString(noOptions)}
            isLoading={loading}
            value={isMulti ? values : withoutValue ? undefined : value}
            classNamePrefix='just-pro-select'

            components={{
                MultiValueLabel: CustomLabel || components.MultiValueLabel,
                MultiValueRemove: CustomRemove || components.MultiValueRemove
            }}
            isMulti={isMulti}
            {...rest}
        />;

    return label ? withLabel(cmp)(label) : cmp
};


export default AsyncSelect;