import React from 'react'
import { Props, State } from './orders.types'
import WithPrivateRoute from '../withPrivateRoute/withPrivateRoute.controller'
import Filter from '../UI/filter/filter.controller'
import moment, { Moment } from 'moment'
import Search from '../UI/search/search.controller'
import './orders.css'
import CheckboxList from '../UI/checkboxList/checkboxList.contoller'
import { ActiveCheckboxesList } from '../UI/checkboxList/checkboxList.types'
import { filterNames } from '../UI/checkboxList/checkboxes.const'
import { getSearchParams, get, save } from '../../utils/filterCheckboxes'
import TopLineCalendar from '../UI/calendar/views/topLine.controller'
import DesctopCalendar from '../UI/calendar/views/desctop.controller'
import { format, OrderListItem } from '@justpro/terminal'
import RenderIf from '../../utils/renderIf'
import { OrdersMapReducer } from './orders.types'
import { connect } from 'react-redux'
import * as actions from '../../store/orders/orders.actions'
import {
	OrdersFilterParams,
	getExcelOrders,
	getExcelOrdersFile,
	ITask,
	getTasks,
	createTaskWithOrders,
	addOrdersToTask,
	getMediaFromOrders,
	generateFileName,
	filterParams,
} from '@justpro/terminal'
import { DropDownItem } from '../UI/dropdownMenu/dropdown.types'
import ItemList from '../UI/itemsList/itemList.controller'
import Order from './order/order.controller'
import Tooltip from '../UI/tooltip/tooltip.controller'
import Button from '../UI/button/button'
import CreateWorkOrder from '../UI/dialog/createWorkOrders/createWorkOrder.controller'
import OrderDetail from './detail/order.detail'
import store from '../../store'
import { orderActions } from '../../store/orders/orders.reducer'
import ListFilterController from '../UI/listFilter/listFilter.controller'
import HeaderController from '../header/header.controller'
import FileDownload from 'js-file-download'
import checkError from '../../utils/checkError'
import Dropdown from '../UI/dropdownMenu/dropdown.controller'
import { confirm } from '../UI/confirmAction/confirmAction.controller'
import TaskController from '../tasks/task.controller'
import { openModal, updateModal } from '../../store/modal/modal.actions'
import { Modal, ModalBodyProps } from '../../store/modal/modal.types'
import getText from '../../localization/getText'
import { renderToString } from 'react-dom/server'
import ToplineCalendarWrapper from '../UI/calendar/views/toplineCalendarWrapper'
import { getFilter } from '../../store/filters/filters.actions'
import { AccessorFrom, AccessorTo } from '../regularWorks/regularWorks.types'
import {isFileLink} from "../../utils/isFileLink";

const searchExtensions = [
	{
		name: 'UI.search.extendedPlaceholder',
		id: 1,
		icon: 'fa fa-expand',
	},
	{
		name: 'UI.search.byNumberPlaceholder',
		id: 2,
		icon: 'fa fa-compress',
	},
]

const sortOptions = [
	{
		name: 'UI.dropDown.byDate',
		id: 1,
		icon: 'fa fa-calendar',
		value: 'createdAt',
	},
	{
		name: 'UI.dropDown.byDateCustomer',
		id: 2,
		icon: 'fa fa-calendar',
		value: 'dueDateCustomer',
	},
	{
		name: 'UI.dropDown.byDateContract',
		id: 3,
		icon: 'fa fa-calendar',
		value: 'dueDate',
	},
	{
		name: 'UI.dropDown.byNumber',
		id: 4,
		icon: 'fa fa-calendar',
		value: 'number',
	},
	{
		name: 'UI.dropDown.byObjectNumber',
		id: 5,
		icon: 'fa fa-calendar',
		value: 'object',
	},
]

const dateDropdownItems = [
	{
		id: 0,
		name: 'UI.dropDown.startDate',
		value: 'dateStart',
	},
	{
		id: 1,
		name: 'UI.dropDown.createdAt',
		value: 'createdAt',
	},
]

interface OrderSavedFilters {
	filters?: ActiveCheckboxesList
	activeStates?: number[]
}

export const ordersCreateWorkordersId = 'ordersCreateWorkorders'

class Orders extends React.Component<Props, State> {
	state: State = {
		from: moment().subtract(10, 'd'),
		to: moment(),
		task: null,
		activeCheckboxes: {},
		checkboxesMap: {},
		number: false,
		q: '',
		// detailMode : '',
		// detailSpinner : false,
		listSpinner: false,
		// groups : [],
		openParentOrders: [],
		sortBy: 'createdAt',
		orderBy: 'desc',
		needBackScroll: false,
		filterByNewComments: false,
		dateType: 'dateStart',
		offset: 100,
		orderGroups: [],

		ordersToWorkOrder: [],
		excelLoading: false,
		mediaLoading: false,
		activeStates: [],
	}

	tasks: ITask[] = []

	filterSubmit = async () => {
		await this.getOrdersList()
	}

	searchSubmit = async (q: string) => {
		await this.setState(() => ({ q }))
		await this.getOrdersList()
	}

	onChangeSearchExtension = (item: DropDownItem) => {
		const { id } = item

		switch (id) {
			case 2:
				this.setState(() => ({ number: true }))
				break
			case 1:
				this.setState(() => ({ number: false }))
				break
		}
	}

	onChangeOrderOption = async (option: DropDownItem) => {
		const { value } = option
		if (value) {
			await this.setState(() => ({ orderBy: value }))
			await this.getOrdersList()
		}
	}

	onChangeSortOption = async (option: DropDownItem) => {
		const { value } = option

		if (value) {
			await this.setState(() => ({ sortBy: value }))
			this.getOrdersList()
		}
	}

	onCheckboxesChange = (activeCheckboxes: ActiveCheckboxesList) => {
		save(filterNames.ORDERS, activeCheckboxes)
		this.setState(() => ({ activeCheckboxes }))
	}

	getOrderFilterParams = (offset?: number): Partial<OrdersFilterParams> => {
		const {
			activeCheckboxes,
			q,
			from,
			to,
			number,
			sortBy,
			orderBy,
			activeStates,
			dateType,
		} = this.state

		const { urgencyOrdersId, ...rest } = getSearchParams(activeCheckboxes)

		let searchParams: Partial<OrdersFilterParams> = {
			...rest,
			sortOrder: orderBy,
			sortBy: sortBy,
			[dateType + 'From']: from.format(format.date),
			[dateType + 'To']: to.format(format.date),
			offset,
		}

		if (Array.isArray(urgencyOrdersId) && urgencyOrdersId.includes(1)) {
			searchParams.other = '1'
		}
		if (Array.isArray(urgencyOrdersId) && urgencyOrdersId.includes(2)) {
			searchParams.hot = '1'
		}
		if (Array.isArray(urgencyOrdersId) && urgencyOrdersId.includes(3)) {
			searchParams.expired = '1'
		}

		if (activeStates.length > 0) {
			searchParams.statusGroupsId = activeStates
		}

		if (q) {
			if (number) {
				// Отправлять только поиск по номеру без учета остальных фильтров
				searchParams = { number: q }
			} else {
				searchParams.q = q
			}
		}

		return searchParams
	}

	getOrdersList = async () => {
		const { rights } = this.props

		const read = rights && rights['orders'] && rights['orders']['read']
		if (read) {
			this.setState(() => ({
				listSpinner: true,
				needBackScroll: true,
			}))

			const searchParams = this.getOrderFilterParams()
			await this.props.getOrdersList(searchParams, 'get')

			this.setState(() => ({
				listSpinner: false,
				needBackScroll: false,
				offset: 100,
			}))
		}
	}

	updateOrdersList = async () => {
		const { list, count } = this.props

		if (count && list) {
			if (+count > list.length) {
				this.setState(() => ({
					listSpinner: true,
				}))

				const searchParams = this.getOrderFilterParams(
					this.state.offset,
				)
				await this.props.getOrdersList(searchParams, 'update')

				this.setState((prevState) => ({
					listSpinner: false,
					offset: prevState.offset + 100,
				}))
			}
		}
	}

	changeDateFrom = (from: Moment) => {
		this.setState(() => ({ from }))
	}

	changeDateTo = (to: Moment) => {
		this.setState(() => ({ to }))
	}

	getOrder = async (id: number) => {
		this.setState(() => ({
			activeOrderId: id,
		}))
	}

	onChangeGroups = (checked: number[]) => {
		this.setState(() => ({ activeStates: checked }))
	}

	addOrderToPossibleWorkList = (order: OrderListItem) => {
		// e.stopPropagation();

		const exists = this.state.ordersToWorkOrder.find(
			(item) => item.id === order.id,
		)

		if (!exists) {
			this.setState((prevState) => ({
				...prevState,
				ordersToWorkOrder: [...prevState.ordersToWorkOrder, order],
			}))
		} else {
			this.setState((prevState) => ({
				...prevState,
				ordersToWorkOrder: prevState.ordersToWorkOrder.filter(
					(item) => item.id !== order.id,
				),
			}))
		}
	}

	removeOrderFromPossibleWorkorder = (id: number) => {
		this.setState(({ ordersToWorkOrder }) => ({
			ordersToWorkOrder: ordersToWorkOrder?.filter((o) => o.id !== id),
		}))
	}

	getOrdersWithMessage = async () => {
		await this.setState((prevState) => ({
			filterByNewComments: !prevState.filterByNewComments,
		}))

		if (this.state.filterByNewComments) {
			await this.setState(() => ({ listSpinner: true }))
			await this.props.getOrdersList({ haveNewComments: '1' }, 'get')
			await this.setState(() => ({ listSpinner: false }))
		} else {
			this.getOrdersList()
		}
	}

	renderItem = (order: OrderListItem, index) => {
		return (
			<Order
				isFirst={index === 0}
				key={order.number}
				order={order}
				activeOrderId={this.state.activeOrderId}
				suborderHelper={{
					subordersCount: order.subordersCount,
				}}
				workOrdersHelper={{
					changeHandler: this.addOrderToPossibleWorkList,
					// checkedAll: this.state.checkedAll,
					list: this.state.ordersToWorkOrder,
				}}
				clickHandler={this.getOrder}
			/>
		)
	}

	openCreateWorkOrderModal = () => {
		this.props.openModal({
			id: ordersCreateWorkordersId,
			title: getText('workorders.creatingWorkorder'),
			component: (props: ModalBodyProps) => (
				<CreateWorkOrder
					{...props}
					orders={this.state.ordersToWorkOrder}
					onChangeOrdersList={this.onChangeOrdersList}
					// afterHide={this.resetOrdersToWorkordersList}
				/>
			),
			afterClose: async () => {
				this.getOrdersList()
			},
			// afterClose : async () => {
			//     this.resetOrdersToWorkordersList([]);
			// }
		})
	}

	onChangeOrdersList = (orders: OrderListItem[]) => {
		this.setState({ ordersToWorkOrder: orders })
	}

	getActiveFilters = (): OrderSavedFilters => {
		return {
			filters: this.state.activeCheckboxes,
			activeStates: this.state.activeStates,
		}
	}

	setFilters = (savedFilters: OrderSavedFilters) => {
		const { filters, activeStates } = savedFilters
		this.setState(
			() => ({
				activeStates: activeStates ? activeStates : [],
				activeCheckboxes: filters ? filters : {},
			}),
			this.getOrdersList,
		)
	}

	exportOrders = async () => {
		try {
			this.setState({
				excelLoading: true,
			})
			const filterParams = this.getOrderFilterParams()
			await isFileLink(
				() => getExcelOrdersFile(filterParams),
				() => getExcelOrders(filterParams),
				generateFileName('заявки', 'xls')
			);
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				excelLoading: false,
			})
		}
	}

	exportMedia = async () => {
		try {
			const isAccept = await confirm({
				question: getText("orders.downloadAllMedia", { count: this.props.count})
			});
			if(!isAccept) {
				return false;
			}
			this.setState({
				mediaLoading: true,
			})
			const filterParams = this.getOrderFilterParams()
			const blob = await getMediaFromOrders(filterParams)
			FileDownload(blob, `Фотографии_из_заявок---${moment().format("YYYY-MM-DD-HH-mm-ss")}.zip`, 'application/zip')
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				mediaLoading: false,
			})
		}
	}

	changeAllCheckboxes = () => {
		const { list } = this.props
		// const checked = e.currentTarget.checked;

		if (list) {
			this.setState((prevState) => ({
				ordersToWorkOrder: !prevState.checkedAll
					? list.filter((o) => o.canWorkorder)
					: [],
				checkedAll: !prevState.checkedAll,
			}))
		}
	}

	componentWillUnmount(): void {
		store.dispatch({
			type: orderActions.GET_ORDERS,
			payload: {
				list: [],
				count: 0,
				haveNewComments: false,
			},
		})
	}

	resetState = async () => {
		const { me, getFilter } = this.props
		const checkboxesMap = {}

		checkboxesMap[filterParams.AREAS] = {
			name: 'areas.area',
			children: await getFilter('areas'),
		}

		checkboxesMap[filterParams.REGIONS] = {
			name: 'regions.region',
			children: await getFilter('regions'),
		}

		checkboxesMap[filterParams.CONTRACTORS] = {
			name: 'contractors.contractor',
			children: await getFilter('contractors'),
		}

		checkboxesMap[filterParams.EQUIPMENT_CATEGORIES] = {
			name: 'equipmentCategories.equipmentCategory',
			children: await getFilter('equipmentCategories'),
		}

		checkboxesMap[filterParams.ORDER_TYPES] = {
			name: 'orderTypes.orderType',
			children: await getFilter('orderTypes'),
		}

		checkboxesMap[filterParams.CONTRACT_CONDITION] = {
			name: 'contractConditions.contractCondition',
			children: await getFilter('contractsConditions'),
		}
		//
		// checkboxesMap[filterParams.CONTRACTS] = {
		//     name : 'contracts.contract',
		//     children : await getFilter("contracts")
		// };
		// checkboxesMap[filterParams.CONTRACT_ORDERS_TYPES] = {
		//     name : 'orderTypes.alias',
		//     children : await getFilter("contractsAlias")
		// };
		checkboxesMap[filterParams.URGENCY_ORDERS] = {
			name: 'UI.filters.urgencyOrders.filter',
			children: await getFilter('urgencyOrders'),
		}

		checkboxesMap[filterParams.MASTERS] = {
			name: 'masters.assignToMasters',
			children: await getFilter('masters'),
		}

		checkboxesMap[filterParams.IS_MASTER] = {
			name: 'masters.assignToMaster',
			children: await getFilter('isMaster'),
		}

		const orderGroups = await getFilter('statusGroups')
		let activeCheckboxes: any = {}
		if (me?.settings?.ordersLastFilters) {
			activeCheckboxes = get(filterNames.ORDERS)
		}
		if (this.props?.rights?.tasks?.read) {
			this.tasks =
				(
					await getTasks({
						withOrders: 1,
						stateId: [0, 1],
					})
				)?.list || []
		}
		this.setState(() => ({
			checkboxesMap,
			orderGroups,
			activeCheckboxes,
		}))

		return this.getOrdersList()
	}

	createTaskWithOrders = async (dropdownItem: DropDownItem) => {
		try {
			const { id } = dropdownItem
			const ordersId = this.props.list?.length
				? this.props.list.map((o) => o.id)
				: []
			const count = this.props.count || 0
			let accepted = true
			if (!ordersId?.length) return false
			if (ordersId.length < count) {
				accepted = await confirm({
					//@ts-ignore
					question: renderToString(
						getText('orders.createTaskWithOrdersQuestionMark', {
							allCount: count,
							addingCount: ordersId?.length,
						}),
					),
				})
			}
			if (!accepted) {
				return false
			}
			let task

			if (id && id > 0) {
				task = await addOrdersToTask(id, ordersId)
			} else {
				task = await createTaskWithOrders(ordersId)
			}

			this.props.openModal({
				id: 'ordersTask',
				component: () => <TaskController id={task?.id} />,
				title: getText('orders.order'),
			})

			this.setState({
				task,
			})
			this.tasks =
				(
					await getTasks({
						withOrders: 1,
						stateId: [0, 1],
					})
				)?.list || []
		} catch (err) {
			checkError(err)
		}
	}

	get tasksDropdown() {
		return [
			{
				id: -1,
				icon: 'fas fa-layer-group',
				active: true,
				hidden: true,
				name: '',
			},
			{
				id: 0,
				icon: 'fas fa-plus-circle',
				name: 'orders.headerCreateTasks',
			},
			...this.tasks?.map((t: ITask) => ({
				id: t.id,
				icon: 'fas fa-list',
				name: t.title,
			})),
		]
	}

	// hideTaskModal = () => {
	//     this.setState({
	//         task: null
	//     });
	// }

	toggleListPanel = (e: React.MouseEvent) => {
		const detail = document.getElementById('ordersDetail')
		detail.classList.toggle('--hidden')

		if (detail.classList.contains('--hidden')) {
			e.currentTarget.classList.remove('expander--right')
			e.currentTarget.classList.add('expander--left', 'indent--right')
		} else {
			e.currentTarget.classList.remove('expander--left')
			e.currentTarget.classList.add('expander--right')
		}
	}

	toggleDetailPanel = (e: React.MouseEvent) => {
		const list = document.getElementById('ordersList')
		list.classList.toggle('--hidden')

		if (list.classList.contains('--hidden')) {
			e.currentTarget.classList.remove('expander--left')
			e.currentTarget.classList.add('expander--right', 'indent--left')
		} else {
			e.currentTarget.classList.remove('expander--right')
			e.currentTarget.classList.add('expander--left')
		}
	}

	async componentDidMount() {
		if (this.props.rights !== undefined) {
			this.resetState()
		}
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	) {
		if (
			JSON.stringify(prevProps.rights) !==
			JSON.stringify(this.props.rights)
		) {
			this.resetState()
		}
		if(prevProps.list?.length !== this.props.list?.length && this.props?.list?.length){
			this.setState({
				activeOrderId: this.props?.list?.[0]?.id
			})
		}
	}

	changeDateAccessor = (dropDownItem: DropDownItem) => {
		const { value } = dropDownItem

		this.setState({
			dateType: value,
		})
	}

	render() {
		const {
			activeCheckboxes,
			checkboxesMap,
			from,
			to,
			filterByNewComments,
			ordersToWorkOrder,
			activeOrderId,
		} = this.state
		const { list, rights, me } = this.props

		const createWorkorders =
			rights && rights['workorders'] && rights['workorders']['create']

		return (
			<WithPrivateRoute>
				<HeaderController
					savedFilters={{
						module: 'orders',
						setFilters: this.setFilters,
						getCurrentFilters: this.getActiveFilters,
					}}
				>
					<Filter
						activeCheckboxes={activeCheckboxes}
						send={this.filterSubmit}
					>
						<CheckboxList
							checkboxesMap={checkboxesMap}
							activeCheckboxes={activeCheckboxes}
							send={this.onCheckboxesChange}
						/>

						<div>
							<DesctopCalendar
								date={from}
								onChange={this.changeDateFrom}
							/>
							<DesctopCalendar
								date={to}
								onChange={this.changeDateTo}
							/>
						</div>
					</Filter>

					<div className='navbar-form navbar-left top-line-drop-down'>
						<Dropdown
							list={dateDropdownItems}
							onChange={this.changeDateAccessor}
						/>
					</div>

					<ToplineCalendarWrapper>
						<TopLineCalendar
							date={from}
							onChange={this.changeDateFrom}
						/>
						<TopLineCalendar
							date={to}
							onChange={this.changeDateTo}
						/>
					</ToplineCalendarWrapper>

					<Search
						submit={this.searchSubmit}
						loadOnEraser={false}
						eraser={true}
						extensions={searchExtensions}
						onChangeExtension={this.onChangeSearchExtension}
					/>

					<div className='justpro__navbar-left'>
						<Tooltip position='down' title='common.excelExport'>
							<RenderIf condition={!this.state.excelLoading}>
								<Button
									onClick={this.exportOrders}
									className='btn-default'
								>
									<i className='fa fa-file-excel' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.excelLoading}>
								{getText('common.generating')}
							</RenderIf>
						</Tooltip>
						<Tooltip position='down' title='common.mediaExport'>
							<RenderIf condition={!this.state.mediaLoading}>
								<Button
									onClick={this.exportMedia}
									className='btn-default'
								>
									<i className='fa fa-file-download' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.mediaLoading}>
								{getText('common.generating')}
							</RenderIf>
						</Tooltip>

						<RenderIf
							condition={
								this.props?.rights?.['tasks.orders']?.create
							}
						>
							<Dropdown
								list={this.tasksDropdown}
								disableChangeActive={true}
								onChange={this.createTaskWithOrders}
							/>
						</RenderIf>

						<RenderIf condition={createWorkorders}>
							<Tooltip
								title='orders.headerCheckbox'
								position='down'
							>
								<i
									className={
										this.state.checkedAll
											? 'fas fa-check-square'
											: 'fas fa-square'
									}
									onClick={this.changeAllCheckboxes}
								/>
							</Tooltip>
						</RenderIf>

						<RenderIf
							condition={
								ordersToWorkOrder &&
								ordersToWorkOrder.length > 0 &&
								createWorkorders
							}
						>
							<Tooltip
								position='down'
								title='orders.headerCreateWorkOrders'
							>
								<Button
									onClick={this.openCreateWorkOrderModal}
									className='btn btn-default'
								>
									<i className='fas fa-plus-square' />
								</Button>
							</Tooltip>
						</RenderIf>
					</div>
				</HeaderController>

				<div className='just-pro_module orders'>
					<div className='panel content-panel' id='ordersList'>
						<div
							className='expander expander--right list'
							onClick={this.toggleListPanel}
						/>
						<ListFilterController
							moduleGroups={{
								title: 'UI.moduleGroups.states',
								onChange: this.onChangeGroups,
								//todo времменый фильтр элементов, по активности
								list: this.state.orderGroups
									.filter((item) => item.active)
									.sort(
										(sg1, sg2) =>
											sg2.priority - sg1.priority,
									),
								checked: this.state.activeStates,
							}}
							sortBy={{
								onChangeOrderBy: this.onChangeOrderOption,
								onChangeSortBy: this.onChangeSortOption,
								sortByOptions: sortOptions,
							}}
							counter={{
								value: this.props.count,
								vars: {
									one: 'orders.vars.one',
									two: 'orders.vars.two',
									notFound: 'orders.vars.notFound',
									default: 'orders.vars.default',
								},
							}}
							helper={{
								icon: filterByNewComments
									? 'fa fa-list'
									: 'fa fa-comment',
								show: this.props.haveNewComments === true,
								handler: this.getOrdersWithMessage,
							}}
						/>

						<ItemList
							list={list}
							loading={this.state.listSpinner}
							renderItem={this.renderItem}
							updateData={this.updateOrdersList}
							backScrollToTop={this.state.needBackScroll}
						/>
					</div>
					<div className='panel content-panel' id={'ordersDetail'}>
						<div
							className='expander expander--left detail'
							onClick={this.toggleDetailPanel}
						/>
						<RenderIf condition={activeOrderId !== undefined}>
							<OrderDetail id={activeOrderId as number} />
						</RenderIf>
					</div>
				</div>
			</WithPrivateRoute>
		)
	}
}

const mapStateToProps = (state: OrdersMapReducer) => {
	const { list, haveNewComments, count } = state.orders

	return {
		list,
		haveNewComments,
		count,
		rights: state.application.rights,
		me: state.application.me,
	}
}

const mapDispatchToProps = (dispatch: Function) => ({
	getOrdersList: (
		searchParams: Partial<OrdersFilterParams>,
		action: 'update' | 'get',
	) => dispatch(actions.getOrders(searchParams, action)),
	openModal: (props: Modal) => dispatch(openModal(props)),
	updateModal: (id: string, props: Modal) => dispatch(updateModal(id, props)),
	getFilter: (filterName, filterParams) =>
		dispatch(getFilter(filterName, filterParams)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
