import React, {useEffect, useState} from "react";
import AsyncS from 'react-select/async'
import {AcceptedOption, Load, Option, OptionGroup} from "./select.types";
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import './select.css'
import withLabel, {LabelProps} from "../withLabel/withLabel";
import ReactSelect, {ActionTypes, components} from "react-select";
import {CustomRemove} from "./multiSelect";
import {getObjectName} from "../../../utils/names";
import {getFullName} from "../../../utils/names";
import moment from "moment";
import GetText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";

type Or<A, B> = A | B


interface AsyncProps {
    change(option: Option, action?: ActionTypes): Promise<any>

    loadOptions(params: any): Promise<Option[] | OptionGroup[]>

    values?: AcceptedOption[] | Partial<AcceptedOption>[]
    normalizeParams?: {
        label: Or<(item: any) => string, string>  // или функция которая приймет item и вернет строку, или ключ у объекта values[i] ..
        value: string  // ключ у объекта values[i] ..
    }

    // accessors?: Load // избавится

    minLength?: number
    label?: LabelProps

    placeholder?: string
    withoutValue?: boolean

    [key: string]: any
}


export const CustomActOption = (props: any) => {
    const {data,} = props;

    console.log({props});


    return <components.Option {...props}>
        <div className="act-task__custom-option just-pro-select">
            <div className="act-task__custom-option__left-side">
                <div className="act-task__custom-option__object">
                    {getObjectName(data.object)}
                </div>
                <div className="act-task__custom-option__responsible">
                    {getFullName(data.responsible)}
                </div>
            </div>
            <div className="act-task__custom-option__right-side">
                <div className="act-task__custom-option__number">
                    {data.number}
                </div>
                <div className="act-task__custom-option__date">
                    {moment(data.createdAt).format('DD.MM.YYYY')}
                </div>
            </div>
        </div>
    </components.Option>

};


const AsyncSelect = ({
                         // defaultValue,
                         // defaultValues = [],
                         change,
                         values,
                         label,
                         minLength = 3,
                         accessors,
                         placeholder = renderToString(GetText("UI.select.defaultChoose")),
                         loadOptions,
                         withoutValue,
                         CustomLabel,
                         CustomOption,
                         normalizeParams,
                         isMulti = false,
                         ...rest
                     }: AsyncProps) => {
    // const defaultNoOptions = `Введите ${minLength} символа для поиска...`;
    const defaultNoOptions = renderToString(GetText("UI.select.minLength", {count: minLength}));
    const [selectValues, setSelectValues] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [noOptions, setNoOptions] = useState(defaultNoOptions);


    const onChangeHandler = async (option: any, meta: any) => {
        setLoading(true);
        setDisabled(true);

        if (isMulti) {
            const o = meta.action === 'select-option' ? meta.option : meta.removedValue;

            await change(o, meta.action);
        } else {
            await change(option);
        }

        setDisabled(false);
        setLoading(false);
    };

    const loadOptionsHandler = async (value: string, callback: Function) => {

        if (value.length < minLength) {
            setNoOptions(defaultNoOptions);
            return void 0
        }

        if (value.length >= minLength) {
            const list = await loadOptions([value]);

            if (list.length === 0) {
                setNoOptions(renderToString(GetText('UI.select.queryNotFount')))
            }

            callback(list);
        }
    };


    useEffect(() => {
        let result: any[] = [];

        console.log({values})

        if (normalizeParams !== undefined) {
            result = values?.map(item => {
                return {
                    ...item,
                    label: typeof normalizeParams.label === "function" ? normalizeParams.label(item) : item[normalizeParams.label],
                    value: item[normalizeParams.value]
                }
            }) || []
        } else {
            result = values ? values : []
        }

        setSelectValues(result)

    }, [JSON.stringify(values)]);

    const cmp = <AsyncS
        loadOptions={loadOptionsHandler}
        placeholder={getText(placeholder)}
        isDisabled={disabled}
        loadingMessage={() => renderToString(GetText('UI.select.loading'))}
        onChange={onChangeHandler}
        noOptionsMessage={() => noOptions}
        isLoading={loading}
        value={isMulti ? selectValues : withoutValue ? undefined : selectValues[0]}
        classNamePrefix='just-pro-select'
        components={{
            MultiValueLabel: CustomLabel || components.MultiValueLabel,
            MultiValueRemove: CustomRemove || components.MultiValueRemove,
            Option: CustomOption || components.Option,

        }}
        isMulti={isMulti}
        {...rest}
    />;

    return label ? withLabel(cmp)(label) : cmp
};


export default AsyncSelect;