import React from 'react'
import { InfoWindow } from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import RenderIf from '../../../utils/renderIf'
import WithModuleDetail from '../../UI/dialog/withModuleDetail/withModuleDetail.controller'

import {
	IClickedInnerProps,
	IHoveredInnerProps,
} from './markerInnerWindows/innerWindow.types'
import { connect } from 'react-redux'
import { setIsWoCreatorOpen } from '../../../store/map/map.actions'

interface ICustomMarkerProps {
	position: { lat: number; lng: number }
	object?: any
	clickedComponent?: (props: IClickedInnerProps) => React.ReactElement
	hoveredComponent?: (props: IHoveredInnerProps) => React.ReactElement
	showNames?: boolean
	setIsWoCreatorOpen: any
	icon?: string
	onClick?: () => void
	zIndex?: number
}

const CustomMarker: React.FC<ICustomMarkerProps> = ({
	position,
	object,
	showNames,
	clickedComponent,
	hoveredComponent,
	onClick,
	setIsWoCreatorOpen,
	icon,
	zIndex = 10000,
}) => {
	const [ClickedComponent, HoveredComponent] = [
		clickedComponent,
		hoveredComponent,
	]
	const { google }: any = window
	const [hovered, setHovered] = React.useState(false)
	const [clicked, setClicked] = React.useState(false)

	const showCaption = () => {
		setHovered(true)
	}
	const openInfo = () => {
		setClicked(true)
		onClick && onClick()
	}
	const closeInfo = () => {
		setClicked(false)
	}
	const hideCaption = () => {
		setHovered(false)
	}

	return (
		<MarkerWithLabel
			icon={icon}
			position={position}
			labelAnchor={new google.maps.Point(0, 0)}
			onMouseOver={showCaption}
			onClick={openInfo}
			onCloseClick={closeInfo}
			zIndex={zIndex}
			onMouseOut={hideCaption}
		>
			<>
				<RenderIf condition={clickedComponent && clicked}>
					<ClickedComponent
						discardClicked={() => {
							setClicked(false)
						}}
					/>
				</RenderIf>
				<RenderIf
					condition={
						(hoveredComponent && !clicked && hovered) || showNames
					}
				>
					<HoveredComponent
						object={object}
						hovered={hovered}
						showNames={showNames}
					/>
				</RenderIf>
			</>
		</MarkerWithLabel>
	)
}

let mapStateToProps = (state) => {
	return {
		isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		setIsWoCreatorOpen: (isOpen) => {
			dispatch(setIsWoCreatorOpen(isOpen))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMarker)
