import React from 'react'
import {
	deletePersonTransport,
	deleteRegionBasePoint,
	IPersonTransport,
	PersonBasePoint as IRegionBasePoint,
} from '@justpro/terminal'
import Table from '../../UI/table/table'
import Button from '../../UI/button/button'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import RegionBasePointsModal from './region.basePointsModal'
import withLabel from '../../UI/withLabel/withLabel'
import checkError from '../../../utils/checkError'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types'
import { openModal } from '../../../store/modal/modal.actions'

interface IProps {
	regionId: number
	basePoints?: IRegionBasePoint[]
	rights: any
	openModal(data: Modal): void
}
interface IState {
	basePoints: IRegionBasePoint[]
}

export const regionsBasePointModalId = 'regionsBasePointModalId'

class RegionBasePoint extends React.Component<IProps, IState> {
	state: IState = {
		basePoints: [],
	}

	createRegionBasePoint = () => {
		this.props.openModal({
			id: regionsBasePointModalId,
			component: (props: ModalBodyProps) => (
				<RegionBasePointsModal
					{...props}
					regionId={this.props.regionId}
					updateRegionBasePoints={this.updateRegionBasePoint}
				/>
			),
			title: getText('persons.addBasePoint'),
		})
	}

	editRegionBasePoints = (regionBasePoints: IRegionBasePoint) => {
		this.props.openModal({
			id: regionsBasePointModalId,
			component: (props: ModalBodyProps) => (
				<RegionBasePointsModal
					{...props}
					regionId={this.props.regionId}
					basePoint={regionBasePoints}
					updateRegionBasePoints={this.updateRegionBasePoint}
				/>
			),
			title: getText('persons.editingTransport'),
		})
	}

	updateRegionBasePoint = (basePoint: any) => {
		console.log('basePoint: ', basePoint)
		this.setState((prevState) => {
			const isBasePoint = prevState.basePoints?.find(
				(bp) => bp.id === basePoint.id,
			)
			if (isBasePoint) {
				return {
					basePoints: prevState.basePoints?.map((t) => {
						if (t.id === basePoint.id) {
							return basePoint
						}
						return t
					}),
				}
			} else {
				return {
					basePoints: [...prevState.basePoints, basePoint],
				}
			}
		})
	}

	deleteRegionBasePoint = async (id: number) => {
		try {
			const deleted = await deleteRegionBasePoint(this.props.regionId, id)
			console.log('deleted: ', deleted)
			if (deleted) {
				this.setState((prevState) => ({
					basePoints: prevState.basePoints.filter((p) => p.id !== id),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	resetState = () => {
		this.setState(() => ({
			basePoints: this.props.basePoints ? this.props.basePoints : [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(
		prevProps: Readonly<IProps>,
		prevState: Readonly<IState>,
		snapshot?: any,
	): void {
		if (prevProps.regionId !== this.props.regionId) {
			this.resetState()
		}
	}

	getColumns = () => {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('objects.address'),
				accessor: 'address',
				Cell: (props: any) => (
					<div>{`${props.original.city.name}, ${props.original.address}`}</div>
				),
			},
		]
		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['edit']
		) {
			result.push({
				Header: getText('common.edit'),
				Cell: (props: any) => (
					<Button
						className='btn-success'
						onClick={this.editRegionBasePoints.bind(
							this,
							props.original,
						)}
					>
						<i className='fa fa-edit no-text' />
					</Button>
				),
			})
		}
		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deleteRegionBasePoint}
						id={props.original.id}
					/>
				),
			})
		}
		return result
	}

	render() {
		const { basePoints } = this.state
		const { rights } = this.props
		return withLabel(
			<>
				{basePoints && basePoints.length > 0 && (
					<>
						<Table columns={this.getColumns()} data={basePoints} />
					</>
				)}

				{rights &&
					rights['references.persons'] &&
					rights['references.regions']['edit'] && (
						<>
							<Button
								onClick={this.createRegionBasePoint}
								className={
									basePoints.length > 0
										? 'btn-success margin-top'
										: 'btn-success'
								}
							>
								{getText('persons.addBasePoint')}
							</Button>
						</>
					)}
			</>,
		)({ text: 'persons.basePoints' })
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

const mapDispatch = (d: Function) => ({
	openModal: (data: Modal) => d(openModal(data)),
})

export default connect(mapStateToProps, mapDispatch)(RegionBasePoint)
