import React from 'react';
import {useDrop} from "react-dnd";
import {dnd} from "./dnd.types";

type Props = {
    children : React.ReactNode
    id : number
}

const UserWithDrop = (props:Props) => {
    const [{isOver, canDrop}, drop] = useDrop({
        accept : [dnd.ROLE, dnd.RIGHTS],
        canDrop : () =>  true,
        drop : () => {
            return {
                id : props.id
            }
        },
        collect : monitor => ({
            isOver : monitor.isOver(),
            canDrop : monitor.canDrop()
        })
    });

    return (
        <div
            ref={drop}
            style={{
                backgroundColor : ( canDrop) ? '#f2f3fa' : '',
                transition : ( isOver) ? 'all 0.3s' : '',
            }}
        >
            {props.children}
        </div>
    )
};

export default UserWithDrop;


