import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {CreateDepartment} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateDepartment) : void
}

interface State {
    name : string
}

class DepartmentCreate extends React.Component<Props, State>{

    state:State = {
        name : ''
    };

    onChangeName = (name:string) => {
        this.setState(() => ({name}))
    };

    addDepartment = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name})
        }
    };

    isDisabled = () => this.state.name.trim() === '';

    render() {

        return (<>
            <h4>{getText('departments.addingNewHeader')}</h4>

            <Input
                placeholder="departments.department"
                change={this.onChangeName}
                label="departments.department"
                startValue=""
            />

            <Button className="btn-success" disabled={this.isDisabled()} onClick={this.addDepartment}>{getText('common.add')}</Button>

        </>)
    }

}


export default DepartmentCreate