import React from "react";
import './modal.css'

interface Props {
    children : React.ReactNode
}

export default class DefaultFooterModal extends React.Component<Props>{


    render () {
        return (
            <div className="just-pro__modal-footer">
                <div className="buttons">
                    {this.props.children}
                </div>
            </div>
        )
    }
}