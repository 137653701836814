import React from 'react';
import Calendar from "../calendar.controller";
import HidePortal from "../../hidePortal/hidePortal.controller";
import {Table, InlineCaret, InlinePicker, GoNow} from "../datepicker.view";
import Caret from '../../caret/caret';
import withLabel from "../../withLabel/withLabel";
import Input from "../../input/text";
import Tooltip from "../../tooltip/tooltip.controller";

export default class InlineCalendar extends Calendar {
    componentDidMount() {
        super.componentDidMount();
        document.body.addEventListener("click", this.hideCalendar);
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.hideCalendar);
    }

    hideCalendar = (e: any) => {
        const input = e.target.closest(".inline-date-input");


        if(!input){
            this.setState({
                isOpen: false
            })
        }
    };

    render() {
        const {label} = this.props;

        const jsx = (
            <div className="form-group inline-date-input">
                <div className="input-group date" >
                    <Input
                        blur={this.onInputBlur}
                        onSend={this.onInputEnter}
                        startValue={this.state.inputValue}
                        className="form-control"
                        style={{ cursor: "pointer" }}
                        disabled={this.props.isDisabled}
                    />
                    <InlineCaret onClick={this.toggle}>
                        <Caret isOpen={this.state.isOpen}/>
                    </InlineCaret>
                </div>

                {this.state.isOpen && (
                    <div style={{position: 'relative'}}>
                        {/*<HidePortal hide={this.handlerHelperHide}/>*/}
                        <InlinePicker className="inline-calendar">
                            <Table>
                                {this.getHeaderTable()}
                                {this.getBodyTable()}

                                <GoNow>
                                    <div className="tooltip-helper-wrapper">
                                        <Tooltip position="down" title="Вернуться к сегодня">
                                            <div className="helper" onClick={this.goToNow}/>
                                        </Tooltip>
                                    </div>
                                </GoNow>
                            </Table>
                        </InlinePicker>

                    </div>
                )}
            </div>
        )

        return label ? withLabel(jsx)(label) : jsx
    }
}