import React, {useEffect, useState} from "react";
import getPersonPhoto from "../../../utils/getPersonPhoto";
import ContextMenu, {ContextMenuProps} from '../../UI/contextMenu/contextMenu'
import {StaffTreeItem} from "./staff.detail";
import {Option} from "../../UI/select/select.types";
import './staff.css'
import {Staff, editStaff, deleteStaff, EditStaff, addTasksPerson} from "@justpro/terminal";
import StaffEditBtn from "./staff.editBtn";
import checkError from "../../../utils/checkError";
import {getUniqueList} from "../../../utils/getUniqueArray";
import {getFullName} from "../../../utils/names";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";



interface Props {
    staff : StaffTreeItem
    isChief : boolean
    contractorId:number

    persons : Option[],
    possibleChiefs : Staff[]
    updateTree?(staff?:Staff[]) : void


    onStaffClick?(staff:Staff) : void
}

interface State {

}


const StaffItem = ({
                       staff,
                       isChief,
                       contractorId,
                       persons,
                       possibleChiefs,
                       updateTree,
                       onStaffClick
}:Props) => {

    const cls =  ['position'];
    if(isChief) {
        cls.push('chief')
    }

    const isPerson =  typeof staff.person === 'object' && staff.person !== null;


    if(!isPerson) {
        cls.push('vacancy')
    }
    const [possiblePersons, setPossiblePersons] = useState<ContextMenuProps[]>([]);
    const [personToWatch, setPersonsToWatch] = useState<ContextMenuProps[]>([]);
    const [chiefs, setChiefs] = useState<ContextMenuProps[]>([]);



    const edit = async (data:Partial<EditStaff>) => {

        try {
            const edited = await editStaff(contractorId, staff.id, data);
            updateTree && updateTree(edited);

        }catch (e) {
            checkError(e)
        }
    };

    const onChangePerson = async (props:any) => {
        await edit({
            personId : props.id
        })
    };

    const changeChief = async (props:any) => {
        await edit({chiefId : props.id});
    };

    const toCutStaff = async (props:any) => {
        try {
            const deleted = await deleteStaff(contractorId, staff.id);

            if(deleted) {
                updateTree && updateTree()
            }

        }catch(e) {
            checkError(e)
        }

    };

    const getContextItems = () => {
        return [{
                name : 'staff.changeChief',
                id : 1,
                subMenuItems : [
                    {
                        id : null,
                        name : 'staff.noChief',
                        key : -1,
                        handler : changeChief,
                        props : {
                            id : null,
                        }
                    }, ...chiefs],
                withSearch : true
            },
            {
                name : 'staff.acceptEmployee',
                id : 2,
                subMenuItems : possiblePersons,
                withSearch : true,
                disabled : isPerson,
            },
            {
                name : 'staff.dismissEmployee',
                id : 3,
                handler: onChangePerson,
                props : {
                    id : null
                },
                disabled : !isPerson,
            },
            {
                name : 'staff.reduceThePosition',
                handler: toCutStaff,
                id : 4,
                disabled : isPerson || ( staff.children && staff.children.length > 0) || ( staff.tasksPersons && staff.tasksPersons.length > 0)
            },
            {
                name : 'staff.watchForTasks',
                id : 5,
                subMenuItems : personToWatch,
                withSearch : true,
            },
        ];
    };

    const addObservation = async (props:any) => {
        try {

            const addedPerson = await addTasksPerson(contractorId, staff.id, props.id);

            if(addedPerson) {
                updateTree && updateTree();
            }else{
                checkError(new Error('errors.cannotAddUserToWatch'));
            }

        }catch (e) {
            checkError(e)
        }
    };

    useEffect(() => {
        const narrowedToOptionArray = ():Option[] => {

            return staff.tasksPersons ? staff.tasksPersons.map(item => {
                return {
                    ...item,
                    label : `${item.lastName} ${item.firstName} ${item.patronymic}`,
                    value : item.id,
                }
            }) : []

        };

        const exist = narrowedToOptionArray();

        const unique = getUniqueList(persons, exist, {wholeId : 'id', existId : "personId"});

        const w = unique.filter(i => i.id !== staff.id).map((item) => {
            return {
                id : item.id,
                name : item.name,
                handler: addObservation,
                props: {
                    id : item.id,
                }
            }
        });

        setPersonsToWatch(w);

    }, [JSON.stringify(staff.tasksPersons)]);

    useEffect(() => {
        const chiefs = possibleChiefs
                        .filter(i => ( i.id !== staff.id && i.id !== staff.chiefId))
                        .map(item => {

                            const personName = getFullName(item.person);

                            return {
                                id : item.id,
                                name : `${item?.department?.name || 'Неизвестно'}: ${item?.position?.name || "Неизвестно"}. ${personName}`,
                                handler : changeChief,
                                props : {
                                    id : item.id
                                }
                            }
                        });

        const p = persons.map(item => {
            return {
                id : item.id,
                name : item.name,
                handler: onChangePerson,
                props: {
                    id : item.id,
                }
            }
        });

        setChiefs(chiefs);
        setPossiblePersons(p);

    }, []);

    return (
        <>
            <div
                className={cls.join(' ')}
                style={{}}
                onClick={onStaffClick ? onStaffClick.bind(null, staff) : undefined}
            >
            <ContextMenu
                id={`staff__${staff.id}`}
                getItems={getContextItems}
            >

                <div className="position__icon-zone">
                    <StaffEditBtn
                        contractorId={contractorId}
                        staff={staff}
                        updateTree={updateTree}
                    />
                </div>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <img src={getPersonPhoto(staff.person?.avatar)} alt={renderToString(getText('common.userPhoto'))}/>
                        </td>
                        <td style={{width : '100%'}}>
                            <div>
                                <b className="staffFullName">{getFullName(staff.person)}</b>
                            </div>
                            <div>
                                <i>{staff?.position?.name}</i>
                            </div>
                            <div>
                                <i>{staff?.department?.name}</i>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ContextMenu>
        </div>
</>)
};

export default StaffItem
