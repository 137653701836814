import React from 'react';
import {createUnit, getUnits, ModulesResponse, Unit} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import UnitCreate from "./unit.create";
import UnitController from "./unit.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}


class UnitsController extends React.Component<Props> {

    renderItem = (item:Unit) => {
        return <>{item.name}</>
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.units'];
        return <BaseReferenceController
            create={{
                Component : UnitCreate,
                handler : createUnit,
                title : 'units.addItem'
            }}
            referenceName="units.moduleName"
            Detail={UnitController}
            getList={getUnits}
            listItem={{render : this.renderItem}}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(UnitsController)