import React from "react";
import Input from "../../UI/input/text";
import {CloseType, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import InputColor from "../../UI/input/color";
import IconPiker from '../../UI/iconPiker/iconPicker.controller'
import { fromClassName, toClassName, getIconsList } from '../../../utils/icons'
import NumberInput from '../../UI/input/number'

interface Props {
    closeType : CloseType
    updateCloseType(data:Partial<CloseType>):void
    rights?: Partial<ModulesResponse>
}

const icons = getIconsList();

class CloseTypeDetail extends React.Component<Props> {
    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.updateCloseType({name})
        }
    };

    onBlurOrder = async (order: number) => {
        await this.props.updateCloseType({order})
    };

    onBlurColor = async (color: string) => {
        await this.props.updateCloseType({color})
    };

    changeIcon = async (iconClassName: string) => {
        const [prefix, icon] = fromClassName(iconClassName);
        await this.props.updateCloseType({icon: `${prefix}:${icon}`})
    };

    changeActive = (active: boolean) => {
        this.props.updateCloseType({active});
    };

    render () {
        const {closeType, rights} = this.props;
        const {name, active, color, icon, order} = closeType;

        const disabled = rights && rights['references.closeTypes'] && !rights['references.closeTypes']['edit'];


        return (
            <>
                <h3>{getText('closeTypes.closeType')}: {name}</h3>

                <ToggleSwitch send={this.changeActive} defaultValue={active} label="common.active" disabled={disabled}/>

                <Input
                    label="closeTypes.closeType"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <NumberInput
                    blur={this.onBlurOrder}
                    label="common.priority"
                    defaultValue={order}
                    disabled={disabled}
                />

                <InputColor
                    label="common.color"
                    blur={this.onBlurColor}
                    defaultValue={color}

                />

                <IconPiker
                    list={icons}
                    label="common.icon"
                    defaultValue={toClassName(icon)}
                    change={this.changeIcon}
                    disabled={disabled}
                />
            </>
        )
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(CloseTypeDetail)