import React from 'react'
import InlineDropzone from '../UI/dropzone/inline/inlineDropzone.controller'
import { imagesOnly } from '../UI/dropzone/acceptedTypes'
import './act.css'
import Tooltip from '../UI/tooltip/tooltip.controller'
import {
	addScan,
	deleteScan,
	IScan,
	updateAct,
	updateScan,
} from '@justpro/terminal'
import checkError from '../../utils/checkError'
import FancyBox from '../UI/fancybox_new/fancybox.controller'
import Spinner from '../UI/spinner/spinner.controller'
import RenderIf from '../../utils/renderIf'
import { renderToString } from 'react-dom/server'
import getText from '../../localization/getText'

interface Props {
	actId: number
	scans?: IScan[]
}

interface State {
	scans: IScan[]
	loading: boolean
}

class ActDropzone extends React.Component<Props, State> {
	state: State = {
		scans: [],
		loading: false,
	}

	setFile = async (acceptFiles: File[]) => {
		try {
			const scans = await addScan(
				this.props.actId,
				acceptFiles.map((file) => {
					console.log('~ file', file)
					return {
						blob: file,
						name: file.name,
					}
				}),
			)

			this.setState((prevState) => ({
				scans: [...prevState.scans, ...scans],
			}))
		} catch (e) {
			checkError(e)
		}
	}

	resetState = () => {
		const { scans } = this.props

		this.setState(() => ({
			scans: scans ? scans : [],
		}))
	}

	toggleProtectedScan = async (scan: IScan) => {
		try {
			if (scan.id) {
				const data = await updateScan(this.props.actId, scan.id, {
					protected: !scan.protected,
				})

				this.setState((prevState) => ({
					scans: prevState.scans.map((scan) => {
						if (scan.id === data.id) {
							return data
						}

						return scan
					}),
				}))

				console.log({
					data,
				})
			}
		} catch (e) {
			checkError(e)
		}
	}

	deleteScan = async (scan: IScan) => {
		try {
			if (scan.id) {
				const deleted = await deleteScan(this.props.actId, [scan.id])

				if (deleted) {
					this.setState((prevState) => ({
						scans: prevState.scans.filter(
							(item) => item.id !== scan.id,
						),
					}))
				}
			}
		} catch (e) {
			checkError(e)
		}
	}

	rotateScan = async (scan: IScan, deg: number) => {
		try {
			if (scan.id) {
				await this.setState(() => ({ loading: true }))

				const data = await updateScan(this.props.actId, scan.id, {
					rotate: deg,
				})

				this.setState((prevState) => ({
					scans: prevState.scans.map((s) => {
						if (s.id === data.id) {
							return data
						}
						return s
					}),
					loading: false,
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<{}>,
		snapshot?: any,
	): void {
		if (prevProps.actId !== this.props.actId) {
			this.resetState()
		}
	}

	render() {
		const { scans } = this.state
		return (
			<>
				<div className='act-pages'>
					<RenderIf condition={!this.state.loading}>
						{scans?.map((scan, index, selfArray) => {
							const p = scan.protected
								? 'fa fa-eye-slash'
								: 'fa fa-check-square'
							const title = scan.protected
								? 'acts.viewOfActLimitedByRights'
								: 'acts.actPageAvailableToAll'

							return (
								<div
									className='act-page'
									key={(scan.path + index).toString()}
								>
									<span className='act-page__buttons'>
										<span
											className='act-page__button'
											onClick={this.toggleProtectedScan.bind(
												this,
												scan,
											)}
										>
											<Tooltip
												position='up'
												title={title}
											>
												<i className={p} />
											</Tooltip>
										</span>
										<span
											className='act-page__button'
											onClick={this.rotateScan.bind(
												this,
												scan,
												90,
											)}
										>
											<Tooltip
												title='acts.rotateLeft'
												position='up'
											>
												<i className='fa fa-repeat reverse' />
											</Tooltip>
										</span>
										<span
											className='act-page__button'
											onClick={this.rotateScan.bind(
												this,
												scan,
												-90,
											)}
										>
											<Tooltip
												title='acts.rotateRight'
												position='up'
											>
												<i className='fa fa-repeat' />
											</Tooltip>
										</span>
										<span
											className='act-page__button'
											onClick={this.deleteScan.bind(
												this,
												scan,
											)}
										>
											<Tooltip
												title='acts.deletePage'
												position='left'
											>
												<i className='fa fa-times' />
											</Tooltip>
										</span>
									</span>

									<FancyBox
										disableCache
										file={scan}
										images={selfArray}
										withDelete={{
											handler: () => undefined,
										}}
									/>
									{/*<img src={scan.path} alt={scan.originalName}/>*/}
								</div>
							)
						})}
					</RenderIf>

					<Spinner loading={this.state.loading} />
				</div>

				<InlineDropzone
					setFiles={this.setFile}
					title={`${renderToString(
						getText('acts.placeholder.dropzone'),
					)} ${scans?.length + 1}${renderToString(
						getText('acts.placeholder.pageCounter'),
					)}`}
					types={imagesOnly}
				/>
			</>
		)
	}
}

export default ActDropzone
