import React from "react";
import Dropdown from "../dropdownMenu/dropdown.controller";
import {DropDownItem} from "../dropdownMenu/dropdown.types";

export const orderByOptions:DropDownItem[] = [
    {
        name : 'UI.dropDown.desc',
        id : 2,
        icon : 'fa fa-sort-desc',
        value : 'desc'
    },
    {
        name : 'UI.dropDown.asc',
        id : 1,
        icon : 'fa fa-sort-asc',
        value: 'asc'
    },
];


export interface SortByProps {
    onChangeOrderBy(item:DropDownItem) : void
    onChangeSortBy(item:DropDownItem) : void

    sortByOptions : DropDownItem[],

}

class SortBy extends React.Component<SortByProps>{


    render (){

        return <>
            <Dropdown
                onChange={this.props.onChangeOrderBy}
                list={orderByOptions}
            />

            <Dropdown
                onChange={this.props.onChangeSortBy}
                list={this.props.sortByOptions}
            />
        </>
    }
}

export default SortBy