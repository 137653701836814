import React from "react";
import '../tree/tree.css'

export interface TreeItem {
    id: number
    name: string
    icon?: string
    children?: TreeItem[]
    isOpen?: boolean

    [field: string]: any

    toggleOpen?(id: number, isOpen: boolean): void
}


interface Props {
    list: TreeItem[]
    customAccessors?: string[]

    renderItem(item: TreeItem): JSX.Element
    getIsOpen?(item: TreeItem): boolean
    defaultOpen?: boolean
    withSort?: boolean
}

interface State {
    list: TreeItem[]
}

interface UpdateData {
    isOpen?: boolean
}

export default class TreeController extends React.Component<Props, State> {

    state: State = {
        list: []
    };

    compareFunction(a: TreeItem, b: TreeItem) {
        return a.name.localeCompare(b.name)
    }

    getList = (root: TreeItem[]): TreeItem[] => {
        const {defaultOpen = false} = this.props;

        return root.map(node => {
            let children: TreeItem[] | undefined;

            if (this.props.withSort) {
                const nodeChildren = node.children ? node.children.slice(0) : undefined;
                children = nodeChildren ? this.getList(nodeChildren.sort(this.compareFunction)) : undefined
            } else {
                children = node.children ? this.getList(node.children) : undefined
            }

            return {
                ...node,
                isOpen: defaultOpen,
                toggleOpen: this.toggleNodeIsOpen.bind(this, node.id, defaultOpen),
                children: children
            }

        })
    };

    updateList = (root: TreeItem[], id: number, updateData: UpdateData): TreeItem[] => {
        const {defaultOpen = false} = this.props;

        return root.map(node => {

            if (id === node.id) {


                return {
                    ...node,
                    toggleOpen: this.toggleNodeIsOpen.bind(this, node.id, !node.isOpen),
                    isOpen: !node.isOpen
                }
            }

            let children: TreeItem[] | undefined;

            if (this.props.withSort) {
                const nodeChildren = node.children ? node.children.slice(0) : undefined;

                children = nodeChildren ? this.updateList(nodeChildren.sort(this.compareFunction), id, updateData) : undefined
            } else {
                children = node.children ? this.updateList(node.children, id, updateData) : undefined
            }

            return {
                ...node,
                children: children,
            }

        })
    };

    printTree = (root: TreeItem[]) => {
        const {customAccessors = ["children"]} = this.props;
        return root.map((item: TreeItem) => {

            return (
                <li
                    className={`tree-item just-pro--text-overflow`}
                    data-tree-id={item.id}
                    key={item.id}
                >
                    {this.props.renderItem(item)}
                    {customAccessors.map((accessor) => {
                        if (item?.[accessor]?.length &&
                            (item.isOpen ||
                                (this.props.getIsOpen && this.props.getIsOpen(item))
                            )
                        ) {
                            return (
                                <ul>
                                    {this.printTree(item[accessor])}
                                </ul>
                            )
                        }
                        return null;
                    })}
                </li>
            )
        })
    };


    toggleNodeIsOpen = (id: number, isOpen: boolean) => {
        // let nowIsOpen:boolean | undefined;

        this.setState((prevState) => {
            const newList = this.updateList(prevState.list, id, {isOpen});
            return {
                list: newList
            }
        });

        // if(nowIsOpen) {
        //     const node = document.querySelector(`.tree-item[data-tree-id=${id}]`);
        //
        //     console.log({node})
        // }
    };

    resetState = () => {
        const list = this.getList(this.props.list);

        this.setState(() => ({
            list,
        }))
    };

    componentDidMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(prevProps.list) !== JSON.stringify(this.props.list)) {
            console.log({prevProps: prevProps.list, _thisList: this.props.list})
            this.resetState();
        }
    }


    render() {
        const {list} = this.state;

        return (
            <div className="tree">
                <ul className="tree-container">
                    {list && list.length > 0 && this.printTree(list)}
                </ul>
            </div>
        )
    }
}