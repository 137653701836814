import React from "react";
import ReactModal from 'react-modal';
import {connect} from "react-redux";
import {ModalReducer, Modal as IModal} from "../../../../store/modal/modal.types";
import Tooltip from "../../tooltip/tooltip.controller";
import {hideAll, hideModal} from "../../../../store/modal/modal.actions";
import './modal.css'
ReactModal.setAppElement('#modal');

export interface SizeMap {
    large : string
    medium : string
    mediumSmall : string
    small : string
}

const SIZES_MAP: SizeMap = {
    small : "30vw",
    mediumSmall : "40vw",
    medium : "60vw",
    large : "90vw",
};

interface Props {
    hideModal(id:string) : void
    hideAll() : void
    modals : IModal[]
}

interface State {
    title : string
}


/*
*  Каждая модалка (component) должна замыкать стейт внутри себя на основании пришедших в нее пропров
*       в данном случае props это как initialState
*  Если компоненту который вызвал модалку нужно изменить стейт по закрытию модального окна
*      это можно сделать передав функцию компоненту и вызвав ее при сохраненении
*  Модальное окно само решает когда ему закрыться - по этому каждое модальное окно получается props hide
*
* */

class Modal extends React.Component<Props, State>{


    hideModal = (id:string, afterClose?:Function) => {
        this.props.hideModal(id);
        afterClose && afterClose();
    };

    hideAll = (afterClose?:Function) => {

        this.props.modals.forEach(item => {
            this.props.hideModal(item.id)
        });

        afterClose && afterClose();
    };

    render() {
        const {modals} = this.props;


        return modals?.map(item => {
            const {
                size = 'medium',
                afterClose,
                id,
                component,
                minHeight,
            } = item;
            const width = size in SIZES_MAP ? SIZES_MAP[size] : size;
            const Component: any = component;

            return (
                <ReactModal
                    isOpen={true}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={this.hideModal.bind(this, id, afterClose)}
                    overlayClassName="just-pro__modal-overlay"
                    className="just-pro__modal-wrapper"
                    style={{ content: {width : width, minHeight: minHeight}}}
                >
                    <div className="just-pro__modal-header">
                        <div className="just-pro__modal-title">
                            {item.title}
                        </div>
                        <div className="hide-wrapper">

                            {modals && modals.length > 1 &&
                                <Tooltip title="common.closeAll">
                                    <button
                                        onClick={this.hideAll.bind(this, afterClose)}
                                        className="just-pro-button_modal--hide">
                                            &times;
                                    </button>
                                </Tooltip>
                            }
                            <Tooltip title="common.close">
                                <button
                                    onClick={this.hideModal.bind(this, id, afterClose)}
                                    className="just-pro-button_modal--hide">
                                        &times;
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="just-pro__modal-content">
                        {component && <Component hide={this.hideModal.bind(this, id, afterClose)}/>}
                    </div>
                </ReactModal>
            )
        })
    }
}


interface MapState {
    modal : ModalReducer
}

const mapState = ({modal}:MapState) => ({
    modals : modal.modals
});

const mapDispatch = (dispatch:Function) => ({
    hideModal : (id:string) => dispatch(hideModal(id)),
    hideAll : () => dispatch(hideAll())
});

export default connect(mapState, mapDispatch)(Modal)