import React from 'react';
import * as api from '@justpro/terminal'
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import CityController from "./city.controller";
import CityCreate from "./city.create";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import {ModulesResponse} from "@justpro/terminal";
import {CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";

interface Props {
    rights?: Partial<ModulesResponse>
}

class CitiesController extends React.Component<Props> {

    renderItem = (item:api.City) => <>{item.name}</>;

    getCheckboxesMap = async () => {
        const result: CheckboxesMap = {};
        const {rights} = this.props;
        if (rights?.['references.areas']?.read) {
            const areas = await api.getAreas({q: ''});
            result[api.filterParams.AREAS] = {
                name: 'areas.area',
                children: areas.map(({name, id}) => ({id, name}))
            }
        }
        if (rights?.['references.countries']?.read) {

            const countries = await api.getCountries({q: ''});

            result[api.filterParams.COUNTRIES] = {
                name: 'countries.country',
                children: countries.map(({name, id}) => ({id, name}))
            }
        }


        return result
    };


    render() {
        const {rights} = this.props;

        return (
            <BaseReferenceController
                Detail={CityController}
                getList={api.getCities}
                listItem={{
                    render : this.renderItem,
                }}
                referenceName="cities.moduleName"
                create={{
                    Component : CityCreate,
                    title : "cities.addItem",
                    handler: api.createCity
                }}
                filter={{
                    getCheckboxesMap : this.getCheckboxesMap,
                    filterName : filterNames.CITIES,
                }}
                moduleRights={rights && rights['references.cities']}
            />
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(CitiesController)