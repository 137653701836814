import React, {Component, PureComponent} from "react";
import {RouteComponentProps} from "react-router";
import checkError from "../../utils/checkError";
import {
    AccessError,
    NotFoundError,
    delay,
    getFile,
    updateFile,
    uploadImage,
    printFile,
    File,
    addFileAttachment,
    getFileAttachments,
    deleteFileAttachments,
    getFileHistory,
    CustomFile,
    History
} from "@justpro/terminal";
import RenderIf from "../../utils/renderIf";
import Spinner from "../UI/spinner/spinner.controller";
import Dropdown from "../UI/dropdownMenu/dropdown.controller";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import TextEditorController from "../UI/textEditor/textEditor.controller";
import Button from "../UI/button/button";
import {confirm} from "../UI/confirmAction/confirmAction.controller";
import moment from "moment";
import FileDownload from "js-file-download";
import Tabs from "../UI/tabs/tabs.controller";
import getText from "../../localization/getText";
import {CustomActOption} from "../UI/select/asyncSelect_v2";
import Files from "../UI/fileManager/files.controller";
import {connect} from "react-redux";
import {
    AddFile, createSingleNewsFile, deleteSingleNewsFile,
    getSingleNewsFiles
} from "../../../../justpro-terminal/dist";
import ModuleHistory from "../UI/moduleHistory/history.controller";

interface Params {
    fileId?: string
}

interface Props extends RouteComponentProps<Params> {
    afterSave?(...a: any[]): any
    rights: any
    me: any
}

interface State {
    file?: CustomFile
    error?: string
    loading: boolean
    isEdit: boolean
    attachments: File[],
    history: History[]
}

const mapState = (state: any) => ({
    rights: state.application.rights,
    me: state.application.me
})

export default connect(mapState)(class FileController extends PureComponent<Props, State> {
    state: State = {
        file: void 0,
        error: '',
        loading: false,
        isEdit: false,
        attachments: [],
        history: []
    }

    get fileOptions(): DropDownItem[] {
        const {file} = this.state;
        const fileOptions: DropDownItem[] = [{
            name: 'Распечатать',
            id: 3,
            icon: 'fa fa-print',
            handler: this.printFile
        }];
        if (file?.userRights?.edit) {
            fileOptions.push({
                name: 'Редактировать',
                id: 3,
                icon: 'fa fa-edit',
                handler: this.changeEditState
            });
        }
        return fileOptions;
    };

    get editorHeight() {
        return window.innerHeight - 175;
    }

    get tabs() {
       const tabs = [];
        if (this.props?.rights?.['knowledge.files']?.read) {
            tabs.push({
                title: 'UI.tabs.files',
                component: () => {
                    return <Files files={this.state.attachments}
                                  setFiles={this.addAttachment}
                                  isLoading={this.state.loading}
                                  unsetFile={this.removeAttachment}
                    />
                },
                onSelectTab: this.getAttachments
            })
        }
        if (this.props?.rights?.['knowledge.history']?.read) {
            tabs.push({
                title: 'UI.tabs.history',
                component: () => {
                    return <ModuleHistory list={this.state.history} />
                },
                onSelectTab: this.getHistory
            })
        }
       return tabs;
    }

    componentDidMount() {
        return this.getFile()
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps?.match?.params?.fileId !== this.props?.match?.params?.fileId) {
            await this.getFile();
            await this.getAttachments();
            await this.getHistory();
        }
    }

    printFile = async () => {
        const {fileId} = this.props?.match?.params;
        if (!fileId) return;
        try {
            this.setState({
                loading: true
            });
            const file = await printFile(+fileId);
            FileDownload(file, `${this.state.file?.name}.pdf`);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    scrollToAnchor = () => {
        const {hash} = window.location;
        const element = hash ? document.querySelector(hash) : null;
        if(hash && element){
            element.scrollIntoView()
        }
    }
    getFile = async () => {
        try {
            const {fileId} = this.props?.match?.params;
            if (!fileId) return;
            this.setState({
                loading: true
            })
            const file = await getFile(+fileId);
            this.setState({
                file
            }, this.scrollToAnchor);
        } catch (err) {
            if (err instanceof AccessError) {
                this.setState({
                    error: "У вас нет доступа к указанному файлу"
                });
            }
            if (err instanceof NotFoundError) {
                this.setState({
                    error: "Не удалось найти такой файл в базе знаний"
                });
            }
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    onChangeFileOption = (option: DropDownItem) => {
        return option.handler && option.handler();
    }

    changeEditState = () => {
        this.setState((prevState: State) => ({
            isEdit: !prevState.isEdit
        }));
    }

    onChangeContent = (content: string) => {
        this.setState((prevState: State) => {
            if (prevState.file) {
                return {
                    file: {
                        ...prevState?.file,
                        content
                    }
                }
            }
            return {};
        })
    }

    onSaveFile = async () => {
        try {
            if (!this.state.file) return;
            const {published, content, id} = this.state.file;
            this.setState({
                loading: true
            });
            if (id) {
                const file = await updateFile(id, {
                    published,
                    content
                });
                this.setState({
                    file
                }, this.props.afterSave);
            }
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false,
                isEdit: false
            });
        }
    }

    onCancelFile = async () => {
        await confirm({
            question: `Это дейтсвтие необратимо, Вы уверенны что хотите продолжить?`
        })
        this.setState({
            isEdit: false
        });
        return this.getFile();
    }

    onChangeDraft = (event: any) => {
        const published = event?.target?.checked;
        this.setState((prevState: State) => {
            if (prevState.file) {
                return {
                    file: {
                        ...prevState?.file,
                        published
                    }
                }
            }
            return {};
        })
    }

    uploadImage = async (cb: any) => {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = async function () {
            //@ts-ignore
            const file: any = this.files[0] as unknown;
            const fileUrl = await uploadImage(file);
            cb(fileUrl, {title: file.name});
        };
        input.click();
    }
    changeTab: ((index: number) => any) | void = void 0;
    onInitTabs = ({changeTab}: any) => {
        this.changeTab = changeTab;
    };

    getHistory = async () => {
        try {
            if(!this.props?.rights?.['knowledge.history']?.read){
                return;
            }
            const {fileId} = this.props?.match?.params;
            if (!fileId) return;
            this.setState({
                loading: true
            })
            const history = await getFileHistory(+fileId);
            this.setState({
                history
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    getAttachments = async () => {
        try {
            if(!this.props?.rights?.['knowledge.files']?.read){
                return;
            }
            const {fileId} = this.props?.match?.params;
            if (!fileId) return;
            this.setState({
                loading: true
            })
            const attachments = await getFileAttachments(+fileId);
            this.setState({
                attachments
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    addAttachment = async (newAttachments: AddFile[]) => {
        try {
            const {fileId} = this.props?.match?.params;
            if (!fileId) return;
            this.setState({
                loading: true
            });
            await addFileAttachment(+fileId, newAttachments);
            const attachments = await getFileAttachments(+fileId);
            this.setState({
                attachments
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    removeAttachment = async (attachmentId: number) => {
        try {
            const {fileId} = this.props?.match?.params;
            if (!fileId) return;
            this.setState({
                loading: true
            });
            await deleteFileAttachments(+fileId, [attachmentId]);
            const attachments = await getFileAttachments(+fileId);
            this.setState({
                attachments
            });
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const {isEdit, error, file, loading} = this.state;
        return (
            <div className="file__wrapper">
                <Spinner loading={loading}/>
                <RenderIf condition={!error?.length}>
                    <RenderIf condition={!isEdit && file}>
                        <div className="file__header">
                            <Dropdown
                                onChange={this.onChangeFileOption}
                                disableChangeActive
                                list={this.fileOptions}
                                label={{
                                    name: "Выберите действие",
                                    icon: ""
                                }}
                            />
                        </div>
                        <div dangerouslySetInnerHTML={{__html: file?.content || ''}}></div>
                    </RenderIf>
                    <RenderIf condition={isEdit}>
                        <div className="file__header file__header--right">
                            <div className="file__checkbox-wrapper">
                                <input type="checkbox"
                                       value="draft"
                                       name="draft"
                                       className="file__checkbox"
                                       checked={file?.published}
                                       onChange={this.onChangeDraft}/>
                                <label htmlFor="draft"
                                       className="file__checkbox-label"
                                >
                                    Опубликован
                                </label>
                            </div>
                            <Button className="file__header-button" onClick={this.onSaveFile}>Сохранить</Button>
                            <Button className="file__header-button" onClick={this.onCancelFile}>Отменить</Button>
                        </div>
                        <div id="news-toolbar"></div>
                        <TextEditorController
                            onChange={this.onChangeContent}
                            html={file?.content || ''}
                            toolbar
                            toolbarId="#news-toolbar"
                            toolbarSticky={false}
                            inline={false}
                            minHeight={this.editorHeight}
                            placeholder=""
                            toolbarCustom='link image anchor | fontselect fontsizeselect forecolor backcolor | bold italic | \
                                    alignleft aligncenter alignright alignjustify | \
                                    table bullist numlist outdent indent | pagebreak emoticons help'
                            init={{
                                language: "ru",
                                toolbar_persist: true,
                                branding: false,
                                file_picker_callback: this.uploadImage,
                                file_picker_types: 'image',
                                pagebreak_separator: '<div style="page-break-after: always;"></div>',
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste',
                                    'emoticons', 'anchor', 'pagebreak'
                                ],
                                table_resize_bars: true
                            }}
                        />
                    </RenderIf>
                </RenderIf>
                <Tabs tabs={this.tabs} defaultIndex={0} onInit={this.onInitTabs} />
                <RenderIf condition={error?.length}>
                    <div className="file_error">{error}</div>
                </RenderIf>
            </div>
        )
    }
})