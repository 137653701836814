import React from "react";
import {ICreateOrderWaitRequest, format, ModulesResponse} from "@justpro/terminal";
import moment, {Moment} from "moment";
import {connect} from "react-redux";
import Select from "../../UI/select/select";
import {getWaitRequestList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import InlineCalendar from '../../UI/calendar/views/inline.controller'
import withLabel from "../../UI/withLabel/withLabel";
import {ApplicationMapState} from "../../application/application.controller";
import HasNoRightsController from "../../UI/hasNoRights/hasNoRights.controller";
import RenderIf from "../../../utils/renderIf";
import {updateModal} from "../../../store/modal/modal.actions";
import {Modal} from "../../../store/modal/modal.types";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import {taskWaitRequestModalId} from "../../tasks/task.orders";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";

interface Props {
    createWaitRequest(a: ICreateOrderWaitRequest): any
    hide() : void
    rights?: Partial<ModulesResponse>

}

interface State {
    date: Moment
    reason: string
}

class WaitRequestModal extends React.Component<Props, State> {

    state: State = {
        date: moment().add(1, 'd'),
        reason: '',
    };

    get waitRequestRights() {
        const {rights} = this.props;
        return rights?.['orders.waitRequest']?.create && rights?.['orders']?.edit;
    }

    isDisabled = () => this.state.reason?.trim() === '';

    createWaitRequest = async () => {
       await this.props.createWaitRequest({
           reason: `${this.state.reason}.${renderToString(getText('orders.askForContinuation'))}`,
           dueDateCustomer: this.state.date.format('YYYY-MM-DD HH:mm:ss')
       });

       this.props.hide();
    };


    changeWaitRequest = (option: Option) => {
        return new Promise(resolve => {
            this.setState(() => ({reason: option.reason}));
            resolve();
        })
    };

    onChangeMessage = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const reason = e.currentTarget.value;

        this.setState(() => ({reason}))
    };

    onChangeDate = (date: Moment) => {
        this.setState(() => ({date}))
    };

    render() {

        return (

            <>
                <RenderIf condition={this.waitRequestRights}>
                    <h2>{getText('orders.setWaitRequestReason')}</h2>

                    <InlineCalendar
                        date={this.state.date}
                        label={{text : "common.date"}}
                        onChange={this.onChangeDate}
                        timePicker
                    />

                    <Select
                        change={this.changeWaitRequest}
                        label="orders.waitRequestTemplate"
                        load={getWaitRequestList}
                        placeholder="UI.select.placeholders.selectWaitRequest"
                        defaultValue={undefined}
                        accessors={{
                            id: 'value',
                            name: 'label',
                            message: 'reason',
                        }}
                    />

                    {withLabel(
                        <textarea
                            onChange={this.onChangeMessage}
                            className="form-control just-pro__text-area"
                            value={this.state.reason}
                        />
                    )({text: "common.reason"})}

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success" disabled={this.isDisabled()} onClick={this.createWaitRequest}>{getText('common.create')}</Button>
                    </DefaultFooterModal>
                </RenderIf>

                <RenderIf condition={!this.waitRequestRights}>
                    <HasNoRightsController/>
                </RenderIf>
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationMapState) => ({
    rights: state.application.rights
});



export default connect(mapStateToProps)(WaitRequestModal)