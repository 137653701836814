import React from 'react';
import Input from "../../UI/input/text";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import TextArea from "../../UI/textArea/textArea.controller";
import {ModulesResponse, WaitRequest} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

type Props = {
    waitRequest : WaitRequest
    update(data:Partial<WaitRequest>) :void
    rights?: Partial<ModulesResponse>
};

class WaitRequestDetail extends React.Component<Props> {
    onBlurName = (name: string, oldValue: string) => {
        if(name !== oldValue) {
            this.props.update({name})
        }
    };
    toggleActive = (active:boolean) => {
        this.props.update({active})
    };

    blurMessage = (message: string, oldValue: string) => {
        if(message !== oldValue) {
            this.props.update({message})
        }
    };

    render () {
        const {rights, waitRequest} = this.props;
        const {message, name, active} = waitRequest;
        const disabled = rights && rights['references.waitRequests'] && !rights['references.waitRequests']['edit'];
        return (
            <>
                <h3>{getText('waitRequests.waitRequest')}: {name}</h3>

                <ToggleSwitch
                    defaultValue={active}
                    send={this.toggleActive}
                    label="common.active"
                    disabled={disabled}
                />

                <Input
                    label="waitRequests.waitRequest"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <TextArea
                    label={{text : "common.message"}}
                    blur={this.blurMessage}
                    startValue={message ? message : ''}
                    isDisabled={disabled}
                />
            </>
        )
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(WaitRequestDetail)