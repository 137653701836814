import React from "react";
import {Brand, editBrand, getBrand} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import BrandDetail from "./brand.detail";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";

interface Props  {
    id: number
    afterUpdate?(item:Brand) : void
}
interface State  {
    brand? : Brand
    loading : boolean
}



class BrandController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };

    updateBrand = async (data:Partial<Brand>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const brand = await editBrand(this.props.id, data);

            afterUpdate && afterUpdate(brand);

            this.setState(() => ({loading : false, brand}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getBrand = async () => {
        this.setState(() => ({loading : true}));

        try {
            const brand = await getBrand(this.props.id);

            this.setState(() => ({loading : false, brand}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getBrand()
        }
    }

    async componentDidMount() {
        this.getBrand();
    }

    render () {
        const {brand} = this.state;

        return (
            <>
                <RenderIf condition={brand !== undefined}>
                    <BrandDetail brand={brand as Brand} updateBrand={this.updateBrand} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default BrandController
