import React from "react";
import {
    ITaskTemplate,
    getTaskTemplates,
    updateTaskTemplate,
    deleteTaskTemplates,
    createTaskTemplate,
    ICreateTask
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import Button from "../button/button";
import Input from "../input/text";
import Select from "../select/select";
import {Option} from "../select/select.types";
import './savedFilters.css'
import Spinner from "../spinner/spinner.controller";
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import {connect} from "react-redux";
import {openModal, updateModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import SavedFilterModal from "./savedFilterModal";
import getText from "../../../localization/getText";

interface Props {
    setTemplate(template:Partial<ICreateTask>) : void,
    getCurrentValues() : Partial<ICreateTask>
    openModal(props:Modal) : void
    updateModal(id:string, props:Partial<Modal>) : void
}


interface State {
    templates : ITaskTemplate[],
    isOpenTemplateNameModal : boolean
    value?: Option
}


class SavedTaskTemplates extends React.Component<Props, State>{

    defaultValue = {
        id : -1,
        name : 'Не выбрано',
        template : {}
    };

    modalId = 'savedTasksFilter'

    state:State = {
        templates : [],
        isOpenTemplateNameModal : false,
    };

    getFilters = async () => {
        //todo вынести позже в редакс и брать оттуда для main-Menu
        try {
            const templates = await getTaskTemplates();

            if(templates) {
                this.setState(() => ({
                    templates : [this.defaultValue, ...templates],
                    value : makeNormalizeParams(this.defaultValue, {name : 'label', id : 'value'})
                }))
            }

        }catch (e) {
            checkError(e)
        }
    };

    onChangeFilter = (option:Option) => {
        this.setState(() => ({value : option}));

        if(option.template) {
            this.props.setTemplate(option.template)
        }

        return Promise.resolve();
    };

    openModal = () => {
        this.props.openModal({
            id : this.modalId,
            component : (props:ModalBodyProps) => <SavedFilterModal {...props} save={this.createTemplate} />,
            title : getText('UI.savedFilters.newFilter'),
        });

        this.setState(() => ({isOpenTemplateNameModal : true}))
    };

    createTemplate = async (name:string) => {

        try {

            const task = this.props.getCurrentValues();


            const newTemplate = await createTaskTemplate(name, task);

            if(newTemplate) {
                this.setState((prevState) => ({
                    templates : [...prevState.templates, newTemplate],
                }))
            }

        }catch (e) {
            checkError(e)
        }

    };

    updateTemplate = async () => {
        const {value} = this.state;
        try {

            if(value && value.value !== -1) {
                const task = this.props.getCurrentValues();
                const updatedTemplate = await updateTaskTemplate(value.value, task);

                if(updatedTemplate) {
                    this.setState((prevState) => ({
                        templates : prevState.templates.map(item => {

                            if(value.value === item.id) {
                                return updatedTemplate
                            }

                            return item
                        }),
                    }))
                }
            }

        }catch (e) {
            checkError(e)
        }
    };

    deleteTemplate = async () => {
        const {value} = this.state;

        if(value && value.value !== -1) {
            try {

                const deleted = await deleteTaskTemplates([value.value]);


                if(deleted) {
                    this.setState((prevState) => ({
                        templates : prevState.templates.filter(item => item.id !== value.value)
                    }))
                }

            }catch (e) {
                checkError(e)
            }
        }
    };

    async componentDidMount(){
        this.getFilters();
    }


    render () {
        const {value} = this.state;
        return (

            <>
                <div className="saved-templates">

                    {value && value.value === -1 &&
                    <Button className="btn-success" onClick={this.openModal}><i className="fa fa-save no-text"/></Button>
                    }

                    {value && value.value > 0 &&
                    <Button className="btn-success" onClick={this.updateTemplate}><i className="fa fa-edit no-text"/></Button>
                    }
                    <Select
                        change={this.onChangeFilter}
                        defaultOptions={this.state.templates}
                        accessors={{
                            name : 'label',
                            id : 'value'
                        }}
                        defaultValue={this.defaultValue}
                    />

                    <Button className="btn-danger" onClick={this.deleteTemplate} disabled={ ( value && value.value === -1) }><i className="fa fa-minus no-text"/></Button>
                </div>

            </>
        )
    }
}


const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props)),
    updateModal : (id:string, props:Partial<Modal>) => d(updateModal(id, props))
});

export default connect(null, mapDispatch)(SavedTaskTemplates)