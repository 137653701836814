import React from "react";
import {Tab, TabList, TabPanel, Tabs as VendorTabs} from "react-tabs";
import './tabs.css'
import GetText from "../../../localization/getText";
import {renderToString} from "react-dom/server";


// TODO: НАДО ПЕРЕНЕСТИ ВСЕ ТАБЫ НА ЭТОТ КОМПОНЕНТ!!!
// ОН УНИВЕРСАЛЬНЫЙ ДЛЯ ЛЮБЫХ ТАБОВ ПОДОЙДЁТ

export interface ITab {
    title: string | React.ReactNode
    component: (tab: ITab) => React.ReactNode
    onSelectTab: (tab?: Tab) => any
}

type Props = {
    tabs: ITab[]
    defaultIndex?: number
    vertical?: boolean
    onInit?: (methods: {
        changeTab: (index: number) => any
    }) => any
}

type State = {
    selected: number
}

class Tabs extends React.Component<Props, State> {
    state: State = {
        selected: this.props.defaultIndex || 0
    };

    onChangeTab = (index: number) => {
        this.setState(() => ({selected: index}), this.afterChangeTab);

        return false
    };

    afterChangeTab = () => {
        const {selected} = this.state;
        return  this.props.tabs[selected]?.onSelectTab && this.props.tabs[selected].onSelectTab();
    };

    componentDidMount(){
        this.props.onInit && this.props.onInit({
            changeTab: this.onChangeTab
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if(this.props.defaultIndex !== prevProps.defaultIndex){
            this.setState({
                selected: this.props.defaultIndex || 0
            })
        }
    }

    render() {
        const {vertical} = this.props;
        return (
            <div className={`tabs ${vertical ? "vertical" : ''}`}>
                <VendorTabs onSelect={this.onChangeTab} selectedIndex={this.state.selected}>

                    <TabList>
                        {this.props.tabs.map((tab, index) => (
                            <Tab key={index}>
                                {typeof tab.title === "string" ?
                                    renderToString(GetText(tab.title as string)) :
                                    tab.title
                                }

                            </Tab>
                        ))}
                    </TabList>

                    {this.props.tabs.map((tab, index) => (
                        <TabPanel key={index}>
                            {tab.component(tab)}
                        </TabPanel>
                    ))}

                </VendorTabs>
            </div>
        )
    }
}

export default Tabs