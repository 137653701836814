import React from 'react'
import {
	addPersonExecutorEquipmentCategory,
	deletePersonExecutorEquipmentCategory,
	ExecutorEquipmentCategory,
} from '@justpro/terminal'
import Table from '../../../UI/table/table'
import { LoadReturn, Option } from '../../../UI/select/select.types'
import Select from '../../../UI/select/select'
import checkError from '../../../../utils/checkError'
import { getEquipmentCategoriesList } from '../../../../utils/functions'
import { getUniqueList } from '../../../../utils/getUniqueArray'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../../store/application/application.types'
import DeleteCell from '../../../UI/table/deleteCell'
import getText from '../../../../localization/getText'

interface Props {
	personId: number
	executorPersonId: number
	rights: any
	equipmentCategories?: ExecutorEquipmentCategory[]
}

interface State {
	equipmentCategories: ExecutorEquipmentCategory[]
}

class EquipmentCategoriesTable extends React.Component<Props, State> {
	state: State = {
		equipmentCategories: [],
	}

	getAvailable = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const equipmentCategories = await getEquipmentCategoriesList()

			const unique = getUniqueList(
				equipmentCategories,
				this.state.equipmentCategories,
				{ wholeId: 'id', existId: 'equipmentCategoryId' },
			)

			resolve(unique)
		})
	}

	addEquipmentCategory = async (option: Option) => {
		try {
			const equipmentCategory = await addPersonExecutorEquipmentCategory({
				equipmentCategoryId: option.value,
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
			})

			if (equipmentCategory) {
				this.setState((prevState) => ({
					equipmentCategories: [
						...prevState.equipmentCategories,
						equipmentCategory,
					],
				}))
			}
		} catch (e) {
			checkError(e)
		}

		return Promise.resolve()
	}

	deleteEquipmentCategory = async (props: any) => {
		try {
			const deleted = await deletePersonExecutorEquipmentCategory({
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
				executorEquipmentCategoryId: props.id,
			})

			if (deleted) {
				this.setState((prevState) => ({
					equipmentCategories: prevState.equipmentCategories?.filter(
						(eqc) => eqc.id !== props.id,
					),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	getColumns = () => {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('equipmentCategories.equipmentCategory'),
				accessor: 'name',
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			!!rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				// Cell : (props:any) => <Button className="btn-success" onClick={this.deleteEquipmentCategory.bind(this, props.original)}><i className="fa fa-trash no-text"/></Button>,
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deleteEquipmentCategory}
						item={props.original}
					/>
				),
			})
		}
		return result
	}

	resetState = () => {
		this.setState(() => ({
			equipmentCategories: this.props.equipmentCategories
				? this.props.equipmentCategories
				: [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.resetState()
		}
	}

	render() {
		const { equipmentCategories } = this.state
		const { rights } = this.props

		return (
			<>
				{equipmentCategories && equipmentCategories.length > 0 && (
					<Table
						columns={this.getColumns()}
						data={equipmentCategories}
					/>
				)}

				{rights &&
					rights['references.persons'] &&
					!!rights['references.persons']['edit'] && (
						<Select
							change={this.addEquipmentCategory}
							load={this.getAvailable}
							placeholder='persons.linkEquipmentCategoryToExecutor'
							withoutValue={true}
							accessors={{
								name: 'label',
								id: 'value',
							}}
							className={
								equipmentCategories &&
								equipmentCategories.length > 0
									? 'margin-top'
									: ''
							}
						/>
					)}
			</>
		)
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

export default connect(mapStateToProps)(EquipmentCategoriesTable)
