import React from "react";
import {
    addWorkorderOrders,
    getOrders,
    IUpdateWorkorderOrderPriority, ModulesResponse,
    OrderListItem, OrdersWorkorder, removeWorkorderOrders,
    updatePriorityWorkorderOrders
} from "@justpro/terminal";
import './workOrders.css'
import SortingList from "../UI/simpleSorting/sortingList";
import {sortTypes} from "../UI/simpleSorting/sortTypes";
import Order from "../orders/order/order.controller";
import checkError from "../../utils/checkError";
import RenderIf from "../../utils/renderIf";
import {toast} from "react-toastify";
import ItemList from "../UI/itemsList/itemList.controller";
import {connect} from "react-redux";
import {OrdersReducer, UpdateOrderList} from "../../store/orders/orders.types";
import {updateOrderList} from "../../store/orders/orders.actions";
import {ApplicationReducer} from "../../store/application/application.types";
import {renderToString} from "react-dom/server";
import getText from "../../localization/getText";

interface Props {
    orders? : OrderListItem[],
    workOrder : OrdersWorkorder
    rights: any
}

class WorkOrderAdmin extends React.Component<Props>{

    renderItem = (order:OrderListItem) => {
        return <Order
                    key={order.number}
                    order={order}
                    withModuleDetail={true}
                />
    };

    render() {
        const {closed} = this.props.workOrder;
        const {rights, orders} = this.props;
        const read = rights && rights['orders'] && rights['orders']['read'];

        return (
            <>
                {read &&
                    <div className="workorder-orders-block">
                        <h4>{orders && orders.length > 0 ? getText('workorders.includedOrders') : getText('workorders.hasNoOrders')}</h4>

                        { orders !== undefined ? (
                            <div className="workOrder__orders">
                                <ItemList
                                    loading={false}
                                    renderItem={this.renderItem}
                                    list={orders}
                                />
                            </div>) : null
                        }
                    </div>
                }
            </>

        );
    }
}


interface MapState {
    application : ApplicationReducer,
    orders : OrdersReducer
}

const mapState = (state:MapState) => ({
    rights : state.application.rights,
    ordersList : state.orders.list
});

const mapDispatchToProps = (dispatch:Function) => ({
    updateOrdersList:(orderId: number, data: UpdateOrderList) => dispatch(updateOrderList(orderId, data))
});
export default connect(mapState, mapDispatchToProps)(WorkOrderAdmin)