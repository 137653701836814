import * as api from '@justpro/terminal'
import checkError from "../../../../utils/checkError";
import {groupTypes} from "../../groups.reducer";

export const removeObject = (groupId:number, objectId:number) => async (dispatch:Function) => {
    try {
        await api.removeGroupObject(groupId, objectId);
        const map = await api.getGroups();

        dispatch({
            type : groupTypes.UPDATE_GROUP_MAP,
            map,
        })
    }catch (e) {
        checkError(e)
    }
};

export const addObject = (groupId:number, objectId:number) => async (dispatch:Function) => {
    try {

        await api.addGroupObject(groupId, objectId);
        const map = await api.getGroups();

        dispatch({
            type : groupTypes.UPDATE_GROUP_MAP,
            map
        })
    }catch (e) {
        checkError(e)
    }
};

export const renameGroup = (id:number, data:api.ChangeGroup) => async (dispatch:Function) => {
    try {
        const {name} = await api.changeGroup(id, data);
        const map = await api.getGroups();

        dispatch({
            type : groupTypes.UPDATE_GROUP,
            name,
            map
        })
    }catch (e) {
        checkError(e)
    }
};

export const changeParentGroup = (id:number, data:api.ChangeGroup) => async (dispatch:Function) => {
    try {
        const {name, children, parentGroups} = await api.changeGroup(id, data);
        const map = await api.getGroups();


        let parent:Partial<api.Object> = {};
        let parentList:Partial<api.Object>[] = [];

        if(parentGroups) {
            parent.name = map[parentGroups[0]].name;
            parent.id = map[parentGroups[0]].id;
        }else{
            parent.id = id;
            parent.name = name
        }

        for (let key in map) {
            if (map.hasOwnProperty(key)) {
                const index = +key;
                if (index === id || (children &&  children.includes(index)) || parent.id === index) {
                    continue;
                }
                parentList.push({
                    address : map[index].name,
                    id : index
                })

            }
        }

        dispatch({
            type : groupTypes.UPDATE_GROUP,
            name,
            parent,
            parentList,
            map,
        })
    }catch (e) {
        checkError(e)
    }

};
