import React, {useEffect, useState} from "react";
import StaffItem from "./staff.item";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import {StaffTreeItem} from "./staff.detail";
import './staff.css'
import {Option} from "../../UI/select/select.types";
import {Staff} from "@justpro/terminal";
//todo Анимация скрытия

interface Props {
    staff : StaffTreeItem
    contractorId: number
    // shouldUpdate : boolean
    isOpenNode? : boolean

    persons: Option[],
    possibleChiefs: Staff[]

    updateTree?(staff?:Staff[]) : void

    onStaffClick?(staff:Staff) : void
}

const StaffDepartment = ({isOpenNode = false, staff, contractorId, persons, possibleChiefs, updateTree, onStaffClick}:Props) => {

    const [isOpen, setIsOpen] = useState(isOpenNode);
    const [isOpenAll, setIsOpenAll] = useState<boolean | undefined>(undefined);

    const toggleOne = () => {
        setIsOpen(!isOpen);
        setIsOpenAll(undefined)
    };

    const toggleAll = () => {
        setIsOpenAll(!isOpen);
        setIsOpen(!isOpen);
    };



    useEffect(() => {
        if (typeof isOpenNode === 'boolean') {
            setIsOpen(isOpenNode);
            setIsOpenAll(isOpenNode);
        }

    }, [isOpenNode]);

    const {children = [], employees = []} = staff;

    return (
        <>
            <span className="staff-block">
                <div className={isOpen ? 'department active' : 'department'}>
                    <div className={isOpen ? "department-header active" : "department-header"}>{staff.department?.name}
                        <span className="pull-right">
                            <Tooltip position="left" title="staff.toggleDepartment">
                                <i
                                    className="fa fa-angle-down min-window-btn"
                                    onClick={toggleOne}
                                />
                            </Tooltip>

                            <Tooltip position="left" title="staff.toggleDepartmentAll">
                                <i
                                    className="fa fa-angle-double-down min-window-btn"
                                    onClick={toggleAll}
                                />
                            </Tooltip>
                        </span>
                    </div>

                    {/*{isOpen &&*/}
                        <div className={isOpen ? 'staff-item--active' : 'staff-item--hidden'}>
                            <StaffItem
                                staff={staff}
                                isChief={children.length > 0 ||  employees.length > 0}
                                contractorId={contractorId}
                                persons={persons}
                                possibleChiefs={possibleChiefs}
                                updateTree={updateTree}
                                onStaffClick={onStaffClick}
                            />

                            {employees.length > 0 &&
                                <ul className="staff podchin">
                                    {employees.map(item => {
                                        return (
                                            <li key={item.id}>
                                                <StaffItem
                                                    staff={item}
                                                    isChief={false}
                                                    contractorId={contractorId}
                                                    persons={persons}
                                                    possibleChiefs={possibleChiefs}
                                                    updateTree={updateTree}
                                                    onStaffClick={onStaffClick}

                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </div>
                    {/*}*/}

                    {children.length > 0 && (
                        <ul className="staff">
                            {children.map(item => {
                                return (
                                    <li key={item.id}>
                                        <span style={{display: 'flex', width:'100%'}}>
                                            <span className="list-right-arrow"/>
                                            <StaffDepartment
                                                staff={item}
                                                isOpenNode={isOpenAll}
                                                contractorId={contractorId}
                                                persons={persons}
                                                possibleChiefs={possibleChiefs}
                                                updateTree={updateTree}
                                                onStaffClick={onStaffClick}
                                                // shouldUpdate={shouldUpdate}
                                            />
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </div>
            </span>
        </>
    )

};

export default StaffDepartment
