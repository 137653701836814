import React, {Component} from 'react';
import {Option} from "../../UI/select/select.types";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Select from "../../UI/select/select";
import {getUniqueEquipmentTypes} from "./utils";
import TextArea from "../../UI/textArea/textArea.controller";
import {
    EditEquipmentWork,
    EquipmentWork,
    eqwAddEquipmentType,
    eqwDeleteEquipmentType, ModulesResponse
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import {EqwEquipmentType} from "@justpro/terminal";
import {getServices1SList} from "../../../utils/functions";
import AsyncSelect from "../../UI/select/asyncSelect";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";


interface Props {
    equipmentWork : EquipmentWork
    update(data:Partial<EditEquipmentWork>) : void
    rights?: Partial<ModulesResponse>
}

interface State {
    equipmentTypes : EqwEquipmentType[]
}

class EquipmentWorkDetail extends Component<Props, State> {

    state:State = {
        equipmentTypes : []
    };

    toggleActive = (active:boolean) => {
        this.props.update({active})
    };

    changeName = (name:string, oldName:string) => {
        if(name !== oldName && name.trim() !== '') {
            this.props.update({name})
        }
    };

    changeAddInfo = (additionalInfo:string, oldValue: string) => {
        if(additionalInfo !== oldValue) {
            this.props.update({additionalInfo})
        }
    };

    changeEquipmentWorkSku = async (option:Option) => {
        return this.props.update({sku1sId : option.value});
    };

    addEquipmentType = async (option:Option) => {
        const result = await eqwAddEquipmentType(this.props.equipmentWork.id, [option.value]);

        if(!result) return false;

        this.setState((prevState) => ({
            equipmentTypes : [...prevState.equipmentTypes, ...result]
        }));

        return Promise.resolve();
    };

    deleteEquipmentType = async (props:any) => {
        const row = props.original;

        try {

            const deleted = await eqwDeleteEquipmentType(this.props.equipmentWork.id, [row.id]);

            if(!deleted) return false;

            this.setState((prevState) => ({
                equipmentTypes : prevState.equipmentTypes.filter((i) => i.id !== row.id)
            }))


        }catch (e) {
            checkError(e)
        }
    };

    resetState = () => {
        this.setState(() => ({
            equipmentTypes : this.props.equipmentWork.equipmentTypes,
        }))
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.equipmentWork.id !== this.props.equipmentWork.id) {
            this.resetState();
        }
    }

    componentDidMount(){

        this.resetState()
    }

    get columns() {
        const {rights} = this.props;

        const result:any[] = [
            {
                Header : 'Наименование',
                accessor : 'name'
            },
            {
                Header : 'Описание',
                accessor : 'description'
            }
        ];
        if(rights && rights['references.equipmentWorks'] && rights['references.equipmentWorks']['edit']) {
            result.push({
                Header : 'Удалить',
                Cell : (props:any) => <DeleteCell deleteHandler={this.deleteEquipmentType} item={props}/>
            })
        }


        return result;
    }

    render() {
        const {rights, equipmentWork} = this.props;
        const {active, name, additionalInfo, sku1s} = equipmentWork;
        const {equipmentTypes} = this.state;
        const disabled = rights && rights['references.equipmentWorks'] && !rights['references.equipmentWorks']['edit'];

        return (
            <>
                <h3>{getText('equipmentWorks.equipmentWork')}: {name}</h3>
                <ToggleSwitch defaultValue={active} send={this.toggleActive} label="common.active" disabled={disabled}/>

                <Input startValue={name} label="equipmentWorks.equipmentWork" blur={this.changeName} disabled={disabled}/>

                {withLabel(
                    <>
                        {equipmentTypes && equipmentTypes.length > 0 &&
                            <Table
                                columns={this.columns}
                                data={equipmentTypes ? equipmentTypes : []}
                                showPagination={false}
                                minRows={0}
                            />
                        }

                        {!disabled &&
                            <Select
                                load={getUniqueEquipmentTypes.bind(this, equipmentTypes, 'equipmentTypeId')}
                                change={this.addEquipmentType}
                                placeholder="UI.select.placeholders.selectEquipmentType"
                                className="margin-top"
                                withoutValue={true}
                                accessors={{
                                    name : 'label',
                                    id : 'value'
                                }}
                            />
                        }
                    </>
                )({text: 'equipmentTypes.equipmentType'})}

                <TextArea isDisabled={disabled} startValue={additionalInfo} blur={this.changeAddInfo} label={{text : "common.additionalInfo"}}/>

                <AsyncSelect
                    loadOptions={getServices1SList}
                    label={{text : "equipmentWorks.odinSTmc" }}
                    change={this.changeEquipmentWorkSku}
                    defaultValue={{
                        accessors : {
                            name : 'label',
                            id1s : 'value'
                        },
                        value : sku1s ? sku1s : {}
                    }}
                    isDisabled={disabled}
                />
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(EquipmentWorkDetail);