import {LoadReturn, Option} from "../components/UI/select/select.types";
import checkError from "./checkError";
import {
    getAreas,
    getContractors,
    getObjects,
    getSecurityObjects,
    getPersons,
    getRole,
    getStatuses,
    get1SContractors,
    get1SFilials,
    get1SPriorities,
    IFilials1sFilters,
    get1SSku,
    get1SPersons,
    Rights,
    getCities,
    CitiesFilterParams,
    StatusRules,
    getEquipmentCategories,
    getBrands,
    ObjectsFilterParams,
    ContractorsFilterParams,
    OrdersTypesFilterParams,
    getOrdersTypes,
    getContractConditions,
    getStatusGroups,
    getDepartments,
    getSquares,
    GetSquareParams,
    AreasFilterParams,
    getWaitRequests,
    ContractsFilterParams,
    getContracts,
    getTags,
    TagsFilterParams,
    EquipmentTypesFilterParams,
    getEquipmentTypes,
    getRegularWorks,
    RegularWorksFilterParams,
    RegularStatusesFilterParams,
    getRegularStatuses,
    EquipmentWorksFilterParams,
    getEquipmentWorks,
    get1SStores,
    EquipmentsFilterParams, getEquipments, getStaff, Staff, getEquipmentName, getSpecializations
} from '@justpro/terminal'
import * as api from '@justpro/terminal';
import {StatusRuleList} from "../store/users/actions/role/role.actions";
import {makeNormalizeParams} from "./makeNormalizeParams";
import {renderToString} from "react-dom/server";
import getText from "../localization/getText";
import {getFullName} from "./names";
import {getUniqueList} from "./getUniqueArray";
import areaDetail from "../components/references/areas/area.detail";

export function getContractorList(filterParams: Partial<ContractorsFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getContractors(filterParams);
            resolve(data?.filter((c) => c?.active).sort((c1, c2) => c1.name.localeCompare(c2.name)));
        } catch (e) {
            checkError(e)
        }
    })
}

export function getContractList(filterParams: Partial<ContractsFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getContracts(filterParams);
            console.log('data: ', data);
            resolve(data);
        } catch (e) {
            checkError(e)
        }
    })
}

export function getEquipmentWorksList(filterParams: Partial<EquipmentWorksFilterParams> = {q: ''}): LoadReturn {

    return new Promise(async resolve => {

        try {
            const equipmentWorks = await getEquipmentWorks(filterParams);
            resolve(equipmentWorks)

        } catch (e) {
            checkError(e)
        }
    })
}

export function getEquipmentList(filterParams: Partial<EquipmentsFilterParams> = {number: ''}): LoadReturn {

    return new Promise(async resolve => {

        try {
            const equipments = await getEquipments(filterParams);
            resolve(equipments.list)

        } catch (e) {
            checkError(e)
        }
    })
}

export function getEquipmentListSelect(filterParams: Partial<EquipmentsFilterParams> = {number: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const equipments = await getEquipments(filterParams);
            resolve(equipments.list.map(eq => ({...eq, name: getEquipmentName(eq)})))
        } catch (e) {
            checkError(e)
        }
    })
}

export function getRegularStatuesList(filterParams: Partial<RegularStatusesFilterParams> = {q: ''}): LoadReturn {

    return new Promise(async resolve => {

        try {
            const regularStatuses = await getRegularStatuses(filterParams);
            resolve(regularStatuses)

        } catch (e) {
            checkError(e)
        }
    })
}

export function getEquipmentTypesList(filterParams: Partial<EquipmentTypesFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getEquipmentTypes(filterParams);
            resolve(data);
        } catch (e) {
            checkError(e)
        }
    })
}

export function getObjectsList(params: Partial<ObjectsFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getObjects(params);
            console.log('data: ', data);
            resolve(data?.list);
        } catch (e) {
            checkError(e)
        }
    })
}

export function getOrdersTypesList(params: Partial<OrdersTypesFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getOrdersTypes(params);
            resolve(data.filter((ot) => ot.active));
        } catch (e) {
            checkError(e)
        }
    })
}

export function getCitiesList(data: Partial<CitiesFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const cities = await getCities(data);
            resolve(cities.filter((c) => c.active).map((c) => ({...c, cityId: c.id})))
        } catch (e) {
            checkError(e)
        }

    })
}

export function getAreasList(filterParams: Partial<AreasFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getAreas(filterParams);
            resolve(data.map((area) => ({...area, areaId: area.id})));
        } catch (e) {
            checkError(e)
        }
    })
}

export function getSquaresList(data: Partial<GetSquareParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const squares = await getSquares(data);
            resolve(squares?.filter((sq) => sq.active));
        } catch (e) {
            checkError(e)
        }
    })
}


export function getEquipmentCategoriesList(q: string = ''): LoadReturn {
    return new Promise(async resolve => {
        try {
            const list = await getEquipmentCategories({
                q
            });
            resolve(list.map((eqc) => ({ ...eqc, equipmentCategoryId: eqc.id })));
            return list.map((eqc) => ({ ...eqc, equipmentCategoryId: eqc.id }));

        } catch (e) {
            checkError(e)
        }
    })
}

export function getSpecializationsList(q: string = ''): LoadReturn {
    return new Promise(async resolve => {
        try {
            const list = await getSpecializations({
                q
            });
            resolve(list.map((s) => ({ ...s, specializationId: s.id })));
            return list.map((s) => ({ ...s, specializationId: s.id }));

        } catch (e) {
            checkError(e)
        }
    })
}


export function getContracts1sList(params: Partial<api.Contracts1sFilterParams> = {q: ''}): Promise<Option[]> {

    return new Promise(async resolve => {
        try {

            const list = await api.getContracts1s(params);
            const options = list.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));
            resolve(options)

        } catch (e) {
            checkError(e)
        }
    })

}

export function getFilials1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const filials = await get1SFilials({q});

            const options = filials.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));

            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getServices1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const sku = await get1SSku({q, service: true});
            const options = sku.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));
            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getPriorities1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const priorities = await get1SPriorities();

            const options = priorities.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));

            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getSku1SList(q?: string): LoadReturn {
    return new Promise(async resolve => {
        try {
            const sku = await get1SSku({q});
            resolve(sku);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getStores1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const stores = await get1SStores({q});

            const options = stores.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));

            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getPersons1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const persons = await get1SPersons({q});

            const options = persons.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}))

            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}

export function getContractors1SList(q?: string): Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const contractors1s = await get1SContractors({q});

            const options = contractors1s.map(item => makeNormalizeParams(item, {name: 'label', id1s: 'value'}));

            resolve(options);
        } catch (e) {
            checkError(e)
        }
    });
}


export function getBrandsList(q?: string): LoadReturn {
    return new Promise(async resolve => {
        try {
            const result = await getBrands({q});
            resolve(result);
        } catch (e) {
            checkError(e)
        }
    })
}

export function getCountries(filterParams: { q: string } = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await api.getCountries(filterParams);

            resolve(data);

        } catch (e) {
            checkError(e);
        }
    })

}


export function getPersonsList(q: string = '', contractorsId?: number[]): LoadReturn {
    return new Promise(async resolve => {

        try {
            const personsList = await getPersons({q, contractorsId});

            const persons = personsList.map(item => {
                return {
                    name: `${item.lastName} ${item.firstName} ${item.patronymic ? item.patronymic : ''}`,
                    id: item.id,
                    ...item
                }
            });

            resolve(persons);
        } catch (e) {
            checkError(e)
        }

    })
}

export function getPersonsAsync(params:api.PersonsFilterParams, existList?:any[]):Promise<Option[]> {
    return new Promise(async resolve => {
        try {
            const persons = await getPersons(params);

            let values:Option[] = persons?.map(p => ({
                ...p,
                label : getFullName(p),
                value : p.id
            })) || [];

            if(existList && existList.length > 0) {
                values = getUniqueList(values, existList, {existId : 'value', wholeId : 'value'})
            }


            resolve(values)
        }catch (e) {
            checkError(e)
        }
    });
}

export async function getExecutorsList(q: string = '', executorsId?: number[]): Promise<any[]> {
    try {
        const personsList = await getPersons({q, executorsId, showBasePoints: '1' });
        const persons = personsList.map(item => {
            return {
                name: `${item.lastName} ${item.firstName} ${item.patronymic ? item.patronymic : ''}`,
                id: item.id,
                ...item
            }
        });
        return persons;
    } catch (e) {
        checkError(e);
        return [];
    }

}

export function getInitiatorsList(q: string, initiatorsId?: number[]): LoadReturn {
    return new Promise(async resolve => {
        try {
            const personsList = await getPersons({q, onlyInitiators: '1', initiatorsId});

            const persons = personsList.map((item: any) => {
                return {
                    name: `${item.lastName} ${item.firstName} ${item.patronymic ? item.patronymic : ''}`,
                    id: item.id,
                    ...item
                }
            });

            resolve(persons);
        } catch (e) {
            checkError(e)
        }

    })
}


export function getRegionsList(): LoadReturn {
    return new Promise(async resolve => {
        try {
            const regionsList = await api.getRegions({q: ''});
            const regions = regionsList.map(({name, id}) => {
                return {
                    name, id
                }
            });
            return resolve(regions);
        } catch (e) {
            checkError(e)
        }

    })
}


export function getStatusesList(): LoadReturn {
    return new Promise(async resolve => {
        try {
            const statuses = await getStatuses();

            resolve(statuses)

        } catch (e) {
            checkError(e)
        }
    })
}


export function getContractConditionsList(q: string = ''): LoadReturn {
    return new Promise(async resolve => {
        try {
            const contractConditions = await getContractConditions({q});

            resolve(contractConditions)

        } catch (e) {
            checkError(e)
        }
    })
}


export function getOrderStatusesList(): LoadReturn {
    return new Promise(async resolve => {
        try {
            const orderStatuses = await getStatuses();

            resolve(orderStatuses)

        } catch (e) {
            checkError(e)
        }
    })
}


export function getOrderStatusesGroups(): LoadReturn {
    return new Promise(async resolve => {
        try {
            const groups = await getStatusGroups();

            resolve(groups)

        } catch (e) {
            checkError(e)
        }
    })
}


export function getDepartmentsList(filterParams = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const departments = await getDepartments(filterParams);

            resolve(departments)

        } catch (e) {
            checkError(e)
        }
    })
}


export function getObjectSecurityList(selfRights?: Rights[]): LoadReturn {
    return new Promise(async resolve => {
        const rights = await getSecurityObjects();

        const filtered = rights.filter(right => {
            let isSet = false;

            if (selfRights) {
                for (let i = 0; i < selfRights.length; i++) {
                    if (selfRights[i].securityObjectId === right.id) {
                        isSet = true;
                        break;
                    }
                }
            }
            return !isSet;
        });


        resolve(filtered)
    });
}

export function getWaitRequestList(filterParams = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getWaitRequests(filterParams);

            resolve(data)
        } catch (e) {
            checkError(e)
        }

    })
}

export function getTagsList(filterParams: Partial<TagsFilterParams> = {q: ''}): LoadReturn {
    return new Promise(async resolve => {
        try {
            const data = await getTags(filterParams);

            resolve(data)

        } catch (e) {
            checkError(e)
        }
    })

}

export function getChiefsList(contractorId: number, existList?: api.Staff[]): LoadReturn {
    return new Promise(async resolve => {


        try {
            const data = await getStaff(contractorId, {});

            const staff = data.map(staffItem => {
                const personName = staffItem.person ?
                    `${staffItem.person.lastName} ${staffItem.person.firstName} ${staffItem.person.patronymic ?
                        staffItem.person.patronymic : ''}`
                    : '';

                return {
                    ...staffItem,
                    name: `${staffItem.department.name}: ${staffItem.position.name}. ${personName}`
                }
            });

            resolve(staff)

        } catch (e) {
            checkError(e);
            resolve([])
        }
    })
}

export function getStatusesRules(statusesRules: StatusRules[] = []): LoadReturn {
    return new Promise(async resolve => {
        try {
            const statuses = await getStatuses();
            let result: StatusRuleList[] = [];

            //todo сделать список возможных переводов статусов
            console.log({
                statuses,
                statusesRules,
                // toMap : Object.fromEntries(statusesRules)
            });
            result = statuses.reduce((result: StatusRuleList[], item) => {
                return [...result,
                    {
                        id: item.id,
                        description: `${renderToString(getText('users.statuses.assignFrom'))} ${item.name}`,
                        type: "assignFrom" as const
                    },
                    {
                        id: item.id,
                        description: `${renderToString(getText('users.statuses.assignTo'))} ${item.name}`,
                        type: "assignTo" as const
                    },
                    {
                        id: item.id,
                        description: `${renderToString(getText('users.statuses.rollbackFrom'))} ${item.name}`,
                        type: "rollbackFrom" as const
                    },
                    {
                        id: item.id,
                        description: `${renderToString(getText('users.statuses.rollbackTo'))} ${item.name}`,
                        type: "rollbackTo" as const
                    }
                ]
            }, [])
                .filter((item: StatusRuleList) => {
                    let isSet = false;

                    statusesRules && statusesRules.forEach(existStatus => {
                        if (item.type === existStatus.type && item.id === existStatus.status.id) {
                            isSet = true;
                        }
                    });

                    return !isSet;
                });

            resolve(result)

        } catch (e) {
            checkError(e)
        }
    })
}
