import React from 'react'
import Button from '../../UI/button/button'
import '../orders.css'
import withLabel from '../../UI/withLabel/withLabel'
import { Angle } from '../../UI/itemsList/card/card.view'
import moment, { Moment } from 'moment'
import {
	IUpdateOrder,
	IExecutionTime,
	format,
	Order,
	ContractOrdersType,
	Object as IObject,
	ModulesResponse,
	OrderStatus,
	getAvailableStatuses,
} from '@justpro/terminal'
import ToggleSwitch from '../../UI/toggleSwitch/toggleSwitch.controller'
import Select from '../../UI/select/select'
import { Option } from '../../UI/select/select.types'
import TextArea from '../../UI/textArea/textArea.controller'
import Calendar from '../../UI/calendar/views/inline.controller'
import Input from '../../UI/input/text'
import { toast } from 'react-toastify'
import { UpdateOrderList } from '../../../store/orders/orders.types'
import ExecutedTimeController from '../../UI/executedTime/executedTime.controller'
import HasNoRightsController from '../../UI/hasNoRights/hasNoRights.controller'
import { ApplicationMapState } from '../../application/application.controller'
import { connect } from 'react-redux'
import AsyncSelect from '../../UI/select/asyncSelect'
import { groupObjectsByRegion } from '../../../utils/selectGroups'
import checkError from '../../../utils/checkError'
import Spinner from '../../UI/spinner/spinner.controller'
import GetText from '../../../localization/getText'
import { renderToString } from 'react-dom/server'
import RenderIf from '../../../utils/renderIf'

interface MyExecutionTime {
	id: number
	from?: Moment
	to?: Moment
}

interface Props {
	order: Order

	updateOrder(
		data: Partial<IUpdateOrder>,
		updateListData: UpdateOrderList,
	): void

	dismissUpdateOrder(): void

	rights?: Partial<ModulesResponse>
}

interface State {
	executionTime: IExecutionTime[]
	isChangeExecutionTime: boolean
	dateCustomer: Moment
	availableStatuses: OrderStatus[] | never[]
	highpriced?: boolean
	categoryId?: number
	object?: IObject
	additionalInfo?: string
	contactInfo?: string
	content?: string
	warranty?: boolean
	orderType?: ContractOrdersType
	dateStart?: string
	loading: boolean
	orderStatus?: Option
	duration?: number

	declineReason?: string
}

class OrderEditMode extends React.Component<Props, State> {
	state: State = {
		dateCustomer: moment(),
		loading: false,
		executionTime: [],
		availableStatuses: [],
		isChangeExecutionTime: false,
	}

	toggleHightpriced = (highpriced: boolean) => {
		this.setState(() => ({ highpriced }))
	}

	changeCategory = (option: Option) => {
		return new Promise((resolve) => {
			this.setState(() => ({ categoryId: option.value }))

			resolve()
		})
	}

	changeObject = (option: Option) => {
		return new Promise((resolve) => {
			const { value, label, ...rest } = option

			//@ts-ignore
			const object: IObject = {
				...rest,
				name: label,
				id: value,
			}

			this.setState(() => ({ object }))

			resolve()
		})
	}

	changeOrdersType = (option: Option) => {
		return new Promise((resolve) => {
			const { label, value, ...rest } = option

			//@ts-ignore
			const orderType: ContractOrdersType = {
				...rest,
				name: label,
				id: value,
			}

			this.setState(() => ({ orderType }))

			resolve()
		})
	}

	contentChange = (content: string) => {
		this.setState(() => ({ content }))
	}

	toggleGuarantee = (warranty: boolean) => {
		this.setState(() => ({ warranty }))
	}

	additionalInfoChange = (additionalInfo: string) => {
		this.setState(() => ({ additionalInfo }))
	}

	contactInfoChange = (contactInfo: string) => {
		this.setState(() => ({ contactInfo }))
	}

	update = () => {
		const {
			dateCustomer,
			content,
			highpriced,
			object,
			warranty,
			contactInfo,
			categoryId,
			additionalInfo,
			executionTime,
			isChangeExecutionTime,
			orderType,
			orderStatus,
			duration,
		} = this.state

		const data: Partial<IUpdateOrder> = {}
		const listUpdateData: UpdateOrderList = {}

		if (
			!moment(this.props.order.dueDateCustomer).isSame(
				dateCustomer.format(format.date),
			)
		) {
			data.dueDateCustomer = dateCustomer.format('YYYY-MM-DD HH:mm:ss')
		}

		if (
			orderStatus !== undefined &&
			orderStatus.value !==
				(this.props.order.orderStatus &&
					this.props.order.orderStatus.id)
		) {
			data.orderStatusId = orderStatus.value
			//@ts-ignore
			listUpdateData.orderStatus = {
				...orderStatus,
				id: orderStatus.value,
				name: orderStatus.label,
			}
		}

		if (
			duration !== undefined &&
			duration * 3600 !== this.props.order.duration
		) {
			data.duration = duration * 3600
		}

		if (content !== undefined && content !== this.props.order.content) {
			data.content = content
			listUpdateData.content = content
		}

		if (
			additionalInfo !== undefined &&
			additionalInfo !== this.props.order.additionalInfo
		) {
			data.additionalInfo = additionalInfo
			listUpdateData.additionalInfo = additionalInfo
		}

		if (
			highpriced !== undefined &&
			highpriced !== this.props.order.highpriced
		) {
			data.highpriced = highpriced
			listUpdateData.highpriced = highpriced
		}

		if (
			object !== undefined &&
			this.props.order.object &&
			object.id !== this.props.order.object.id
		) {
			data.objectId = object.id
			//todo
			listUpdateData.object = object
		}

		if (warranty !== undefined && warranty !== this.props.order.warranty) {
			data.warranty = warranty
			listUpdateData.warranty = warranty
		}

		// не могу сравнить с пропсами
		if (isChangeExecutionTime) {
			data.executionTime = executionTime
			listUpdateData.executionTime = executionTime
		}

		if (
			contactInfo !== undefined &&
			contactInfo !== this.props.order.contactInfo
		) {
			data.contactInfo = contactInfo
			listUpdateData.contactInfo = contactInfo
		}

		if (
			typeof categoryId !== 'undefined' &&
			this.props.order.equipmentCategory &&
			categoryId !== this.props.order.equipmentCategory.id
		) {
			data.equipmentCategoryId = categoryId
		}

		if (
			orderType &&
			this.props.order.contractOrdersType &&
			orderType.id !== this.props.order.contractOrdersType.id
		) {
			data.contractOrdersTypeId = orderType.id
			//todo
			listUpdateData.contractOrdersType = orderType
		}
		this.props.updateOrder(data, listUpdateData);
	}

	onChangeContractTermsEnd = (dateCustomer: Moment) => {
		this.setState(() => ({ dateCustomer: dateCustomer }))
	}

	componentDidMount = async () => {
		try {
			this.setState({
				loading: true,
			})
			const { dueDateCustomer, executionTime, id } = this.props.order
			if (dueDateCustomer) {
				this.setState(() => ({
					dateCustomer: moment(dueDateCustomer),
				}))
			}
			const availableStatuses = await getAvailableStatuses(id)
			this.setState({
				loading: false,
				availableStatuses,
			})
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	onChangeExecutionTime = (time: IExecutionTime[]) => {
		this.setState(() => ({
			isChangeExecutionTime: true,
			executionTime: time,
		}))
	}

	changeOrderStatus = (option: Option) => {
		this.setState(() => ({ orderStatus: option }))

		return Promise.resolve()
	}

	changeDuration = (value: string) => {
		const int = parseInt(value)

		if (isNaN(int)) {
			checkError(new Error('errors.valueMustBeANumber'))
			return void 0
		}

		this.setState(() => ({ duration: int }))
	}

	render() {
		const { order, rights } = this.props
		const {
			number,
			equipmentCategory,
			highpriced,
			warranty,
			object,
			contract,
			createdAt,
			contractOrdersType,
			orderStatus,
			duration,
			content,
			additionalInfo,
			contactInfo,
		} = order
		const { availableStatuses } = this.state
		const canEdit = rights && rights['orders'] && rights['orders']['edit']

		let EC: any = {}

		if (equipmentCategory) {
			EC.name = equipmentCategory.name
			EC.active = equipmentCategory.active
			EC.equipmentCategoryId = equipmentCategory.id
		}

		const { executionTime, loading } = this.state

		return canEdit ? (
			<>
				<Spinner loading={loading} />
				<div className='just-pro__order_edit-top-panel'>
					<Button
						className='btn-warning'
						onClick={this.props.dismissUpdateOrder}
					>
						{GetText('common.cancel')}
					</Button>
					<Button className='btn-success' onClick={this.update}>
						{GetText('common.save')}
					</Button>
				</div>

				<div className='info-block bdt'>
					<div className='col-xs-6 col-sm-6 col-md-6 col-lg-6'>
						<Angle size={30} />
						<p>
							<b>{GetText('orders.number')}: </b>
							{number}
						</p>
						<p>
							<b>{GetText('orders.date')}: </b>
							{createdAt &&
								moment(createdAt).format('DD.MM.YYYY')}
						</p>
						<p>
							<b>{GetText('regions.region')}: </b>
							{object &&
								object.square &&
								object.square.region.name}
						</p>
						<p>
							<b>{GetText('brands.brand')}: </b>
							{contract && contract.contractor.brand.name}
						</p>
						<p>
							<b>{GetText('contractors.contractor')}: </b>
							{contract && contract.contractor.name}
						</p>
					</div>
				</div>

				<ToggleSwitch
					label='orders.highPriced'
					defaultValue={highpriced ? highpriced : false}
					send={this.toggleHightpriced}
				/>

				<ToggleSwitch
					label='orders.guarantee'
					defaultValue={warranty ? warranty : false}
					send={this.toggleGuarantee}
				/>

				<Input
					change={this.changeDuration}
					startValue={
						duration ? (duration / 3600).toString() : undefined
					}
					label='orders.time'
				/>

				<Calendar
					date={this.state.dateCustomer}
					onChange={this.onChangeContractTermsEnd}
                    timePicker
					label={{ text: 'orders.dueDateCustomer' }}
				/>

				<Select
					change={this.changeOrderStatus}
					defaultOptions={availableStatuses ? availableStatuses : []}
					accessors={{
						name: 'label',
						id: 'value',
					}}
					label='orderStatuses.orderStatus'
					defaultValue={orderStatus ? orderStatus : undefined}
				/>

				{withLabel(
					<>
						<ExecutedTimeController
							onChange={this.onChangeExecutionTime}
							executedTime={
								this.props.order.executionTime
									? this.props.order.executionTime
									: []
							}
							addFirstButton={{ text: 'common.add' }}
							canRemoveFirst={true}
						/>
					</>,
				)({ text: 'orders.executedTime' })}

				{/*<AsyncSelect*/}
				{/*change={this.changeObject}*/}
				{/*loadOptions={(q: string) => groupObjectsByRegion({q, contractorsId: [contract.contractor.id]})}*/}
				{/*label={{text: "objects.object"}}*/}
				{/*/>*/}

				<Select
					label='equipmentCategories.equipmentCategory'
					change={this.changeCategory}
					defaultValue={EC ? EC : undefined}
					// load={getEquipmentCategoriesList}
					defaultOptions={
						contract ? contract.equipmentCategories : undefined
					}
					accessors={{
						equipmentCategoryId: 'value',
						name: 'label',
					}}
				/>
				<RenderIf condition={rights?.['orders.orderType']?.edit}>
					<Select
						label='orders.orderType'
						change={this.changeOrdersType}
						isDisabled={!rights?.['orders.orderType']?.edit}
						defaultValue={
							contractOrdersType ? contractOrdersType : []
						}
						defaultOptions={
							contract ? contract.ordersTypes : undefined
						}
						accessors={{
							id: 'value',
							//todo change to alias ...
							name: 'label',
						}}
					/>
				</RenderIf>
				<TextArea
					label={{ text: 'common.contentInfo' }}
					change={this.contentChange}
					startValue={content}
				/>

				{withLabel(
					<>
						<TextArea
							change={this.additionalInfoChange}
							startValue={additionalInfo}
							className='margin-top'
						/>
					</>,
				)({ text: 'common.additionalInfo' })}

				<TextArea
					label={{ text: 'common.contactInfo' }}
					change={this.contactInfoChange}
					startValue={contactInfo}
				/>
			</>
		) : (
			<HasNoRightsController />
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(OrderEditMode)
