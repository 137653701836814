import {getEquipmentTypesList} from "../../../utils/functions";


export const getUniqueEquipmentTypes = async (existList:any[], accessor:string) => {
    const allEqTypes = await getEquipmentTypesList();
    return allEqTypes.filter(item => {
        let passed = true;


        if (Array.isArray(existList)) {
            for(let i = 0; i < existList.length; i++) {
                if(existList[i][accessor] === item.id) {
                    passed = false;
                    break;
                }
            }
        }

        return passed;
    })
};