import React, {Component,} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import "./textEditor.css";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import { classNames } from 'react-select/src/utils';

interface TextEditorProps {
    onBlur?(oldValue?: string): any

    onChange(html?: string): any
    className?: string
    html: string
    placeholder?: string
    toolbarId?: string
    toolbarCustom?: string
    readOnly?: boolean
    toolbarSticky?: boolean
    toolbar?: boolean
    inline?: boolean
    customName?: string
    minHeight?: number
    disableNewLine?: boolean
    enableShiftEnter?: boolean
    customEnterFunc?: (...args: any) => any
    init?: any
}

interface TextEditorState {
    oldValue?: string
}

export default class TextEditorController extends Component<TextEditorProps, TextEditorState> {
    state = {
        oldValue: void 0
    }

    get toolbar() {
        if (!this.props.toolbar) return false;
        if(this.props.toolbarCustom) return this.props.toolbarCustom;
        return 'bold italic | underline strikethrough | forecolor backcolor fontsizeselect';
    }

    get toolbarId() {
        if (this.props.toolbarId) return this.props.toolbarId;
        return "#toolbar";
    }

    onFocus = () => {
        this.setState({
            oldValue: this.props.html
        })
    }

    onBlur = (event: any) => {
        this.props.onBlur && this.props.onBlur(this.state.oldValue);
    }

    onChange = (data: string) => {
        this.props.onChange(data);
    }

    render() {
        const {
            readOnly,
            className,
            placeholder,
            minHeight = 150,
            toolbarSticky = true,
            disableNewLine,
            customName,
            customEnterFunc,
            enableShiftEnter,
            inline = true,
            init = {}
        } = this.props;
        return (
            <div className={className}>
                <Editor onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    inline={inline}
                    tinymceScriptSrc='/tinymce/tinymce.min.js'
                    textareaName={customName}
                    init={{
                        menubar: false,
                        browser_spellcheck: true,
                        toolbar_sticky: toolbarSticky,
                        placeholder : placeholder ? renderToString(getText(placeholder)) : undefined,
                        // force_br_newlines: true,
                        // force_p_newlines: false,
                        // forced_root_block: false,
                        fixed_toolbar_container: this.toolbarId,
                        icons: 'material',
                        min_height: minHeight,
                        toolbar: this.toolbar,
                        setup: function (editor: any) {
                            if (customName) {
                                customName.split(" ").forEach((className) => {
                                    editor?.settings?.target?.classList?.add(className);
                                });
                            }
                            if (!enableShiftEnter) {
                                editor.on('keydown', function (event: any) {
                                    if (event.keyCode == 13 && event.shiftKey) {
                                        console.log(event);
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return false;
                                    }
                                });
                            }
                            if (disableNewLine) {
                                editor.on('keydown', function (event: any) {
                                    if (event.keyCode == 13 && customEnterFunc) {
                                        customEnterFunc();
                                    }
                                    if (event.keyCode == 13) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return false;
                                    }
                                });
                            }
                        },
                        ...init
                    }}
                    value={this.props.html}
                    outputFormat="html"
                    disabled={readOnly}
                    onEditorChange={this.onChange}
                    plugins='code'
                />
            </div>
            
        )
    }

}