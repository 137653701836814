import {modalActions} from "./modal.reducer";
import {Modal} from "./modal.types";


export const hideModal = (id:string) => (dispatch:Function) => {
    dispatch({
        type : modalActions.HIDE_MODAL,
        id,
    })
};

export const openModal = (props?:Modal) => (dispatch:Function) => {
    dispatch({
        type : modalActions.OPEN_MODAL,
        ...props
    })
};

export const updateModal = (id:string, props:Partial<Modal>) => (dispatch:Function) =>  {
    dispatch({
        type : modalActions.UPDATE_MODAL,
        id,
        ...props
    })
};

export const hideAll = () => (dispatch:Function) =>  {
    dispatch({
        type : modalActions.HIDE_ALL
    })
};
