import * as api from '@justpro/terminal'

import { usersTypes } from '../../users.reducer'
import { userTypes } from '../../user.reducer'
import { rolesTypes } from '../../roles.reducer'
import { listTypes } from '../../list.reducer'

import checkError from '../../../../utils/checkError'
import { CreateUser } from '@justpro/terminal'

export const addUser =
	(
		{ password, username, personId, contractorsId }: CreateUser,
		treeId: number,
		treePanel: string,
	) =>
	async (dispatch: Function) => {
		try {
			const addAccessor =
				treePanel === 'role' ? 'parentRole' : 'parentGroup'
			const searchAccessor = treePanel === 'role' ? 'roleId' : 'groupId'

			console.log({
				addAccessor,
				searchAccessor,
				treeId,
			})

			const user = await api.createUser({
				contractorsId,
				personId,
				password,
				username,
				[addAccessor]: treeId,
			})

			const users = await api.getUsers({
				[searchAccessor]: treeId,
			})
			const map = await api.getRoles()

			dispatch({
				type: listTypes.GET_USERS_LIST,
				users,
			})

			dispatch({
				type: rolesTypes.UPDATE_ROLES_MAP,
				map,
			})

			dispatch({
				type: userTypes.GET_USER,
				...user,
			})

			dispatch({
				type: usersTypes.CHANGE_USERS_PANELS,
				detail: 'user',
			})
		} catch (e) {
			checkError(e)
		}
	}
