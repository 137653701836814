import React, {useCallback, useState} from 'react';
import {Props} from './toggleSwitch.types';
// import {Wrapper, Toggler} from './toggleSwitch.view'
import {ToggleSwitchView} from './toggleSwitch.view'
import withLabel from "../withLabel/withLabel";

const ToggleSwitch = ({disabled, label, send, defaultValue, className, position = 'left'}:Props) => {

    const [value, setValue] = useState(false);

    const clickHandler = () => {
        if(!disabled) {
            setValue(!value);
            send(!value)
        }
    };

    const ref = useCallback(() => {
        setValue(defaultValue ? defaultValue : false)
    }, [defaultValue]);


    const jxs = (
        <ToggleSwitchView
            className={className}
            active={value}
            ref={ref}
            disabled={disabled ? disabled : false}
            onClick={clickHandler}
        >
            <div className="just-pro__toggle" />
        </ToggleSwitchView>
    );


    return label ? withLabel(jxs)({text: label, position}) : jxs;

};

export default ToggleSwitch;