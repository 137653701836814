import React, {Component} from "react";
import Input from "../UI/input/text";
import checkError from "../../utils/checkError";
import {getTasks, ITask} from "@justpro/terminal";
import TaskListItem from "./task.listItem";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";
import Button from "../UI/button/button";
import getText from "../../localization/getText";

interface IProps {
    selectTask: (taskId: number) => any
    hide() : void
}

interface IState {
    number: string
    tasks: ITask[]
}

export default class SelectParentTaskController extends Component<IProps, IState> {
    state = {
        number: "",
        tasks: []
    }

    getTasks = async () => {
        try {
            const tasks = await getTasks({
                relativity: ['initiator'],
                number: this.state.number
            });
            this.setState({
                tasks: tasks.list
            })
        } catch (err) {
            checkError(err);
        }
    }

    changeTaskNumber = (number: string) => {
        this.setState({
            number
        }, this.getTasks);
    }

    select = (task:ITask) => {
        this.props.selectTask(task.id);
        this.props.hide();
    }

    render() {
        const {number, tasks} = this.state;
        return (
            <>
                <Input value={number} placeholder="tasks.enterTaskNumber" change={this.changeTaskNumber}/>
                <div className="select-tasks-wrapper">
                    {tasks && tasks.map((task: ITask) => (
                        <TaskListItem task={task} subtask={true} onClick={this.select.bind(this, task)}/>
                    ))}
                </div>

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}