import React from "react";
import {IExecutedTime} from "@justpro/terminal";
import TimePicker from "../timePicker/timePiker.controller";
import moment, {Moment} from "moment";
import './executedTime.css'
import Tooltip from "../tooltip/tooltip.controller";
import Button from "../button/button";
import {toast} from "react-toastify";
import GetText from "../../../localization/getText";

interface Props {
    executedTime?: IExecutedTime[],
    onChange(time:IExecutedTime[]) : void,

    addFirstButton?: {
        text : string
    }
    canRemoveFirst?: boolean
    disabled?: boolean
}

interface State {
    executedTime : ExecutedTime[]
}

interface ExecutedTime {
    from : number[]
    to : number[]
    id : number
}

class ExecutedTimeController extends React.Component<Props, State>{

    state:State = {
        executedTime : [],
    };

    addNewTime = () => {
        this.setState((prevState) => ({
            executedTime : [...prevState.executedTime, {
                from : [0,0],
                to : [0,0],
                id : Math.round(Math.random() * 2e13)
            }]
        }), this.afterChangeTime)
    };

    deleteTime = (id:number) => {
        // if(this.state.executedTime.length === 1) {
        //     toast.error('Нельзя удалить последнюю запись о времени!')
        // }else{
            this.setState((prevState) => ({
                executedTime : prevState.executedTime.filter(item => item.id !== id)
            }), this.afterChangeTime)
        // }
    };


    resetState = () => {
        const {executedTime} = this.props;
        this.setState(() => ({
            executedTime : executedTime ? executedTime.map((et) => {
                return {
                    from : et.from.split('-').map(item => parseInt(item)),
                    to : et.to.split('-').map(item => parseInt(item)),
                    id : Math.round(Math.random() * 2e13)
                }
            }) : [],
        }))
    };

    componentDidMount() {
        this.resetState();
    }


    afterChangeTime = () => {
        const {executedTime} = this.state;

        const result = executedTime.reduce((acc:IExecutedTime[], item) => {
            return [...acc, {
                from : `${item.from[0]}-${item.from[1]}`,
                to : `${item.to[0]}-${item.to[1]}`,
            }];
        }, []);

        this.props.onChange(result);
    };

    changeTime = async (value:Moment, id:number, accessor: 'from' | 'to') => {

        if(value !== null) {
            const minutes = value.get('minute'),
                hours = value.get('hour');

            this.setState((prevState) => ({
                executedTime : prevState.executedTime.map((time) => {
                    if(time.id === id) {
                        return {
                            ...time,
                            [accessor] : [hours, minutes]
                        }
                    }

                    return time
                })
            }), this.afterChangeTime);
        }else{
            this.setState((prevState) => ({
                executedTime : prevState.executedTime.map((time) => {
                    if(time.id === id) {
                        return {
                            ...time,
                            [accessor] : [0, 0]
                        }
                    }

                    return time
                })
            }), this.afterChangeTime);
        }

    };

    render () {
        const {executedTime} = this.state;
        const {canRemoveFirst = false, disabled} = this.props;
        return (
            <>
                {executedTime?.map((time, index) => {
                    return (
                        <div key={time.id} className="executed-time">
                            <span className="executed-time__from">
                                <span className="execution-time__text">{GetText('common.from')}</span> <TimePicker
                                        value={moment().set({
                                            hour : time.from[0],
                                            minute : time.from[1]
                                        })}
                                        onChange={(value:Moment) => this.changeTime(value, time.id, 'from')}
                                        disabled={disabled}
                                    />
                            </span>
                            <span className="executed-time__to">
                                <span className="execution-time__text">{GetText('common.to')}</span> <TimePicker
                                        value={moment().set({
                                            hour : time.to[0],
                                            minute : time.to[1]
                                        })}
                                        onChange={(value:Moment) => this.changeTime(value, time.id, 'to')}
                                        disabled={disabled}
                                    />
                            </span>

                            {canRemoveFirst ? (
                                <div>
                                    <div className="executed-time__button">
                                        <Tooltip position="up" title="UI.executedTime.remove" >
                                            <Button className="btn-success" onClick={this.deleteTime.bind(this, time.id)}><i className="fa fa-trash no-text"/></Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            ) : executedTime.length !== 1 ? (
                                <div>
                                    <div className="executed-time__button">
                                        <Tooltip position="up" title="UI.executedTime.remove" >
                                            <Button className="btn-success" onClick={this.deleteTime.bind(this, time.id)}><i className="fa fa-trash no-text"/></Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            ) : <></>}

                            {executedTime.length === index + 1 &&
                                <div className="executed-time__button">
                                    <Tooltip position="up" title="UI.executedTime.add" >
                                        <Button disabled={disabled} className="btn-success" onClick={this.addNewTime}><i className="fa fa-plus no-text"/></Button>
                                    </Tooltip>
                                </div>
                            }

                        </div>
                    )
                })}

                {executedTime?.length === 0 && this.props.addFirstButton && (
                    <Button className="btn-success" onClick={this.addNewTime}>{GetText(this.props.addFirstButton.text)}</Button>
                )}
            </>
        )
    }
}

export default ExecutedTimeController