import React from "react";
import {editEquipmentCategory, EquipmentCategory, getEquipmentCategory} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import EquipmentCategoryDetail from "./equipmentCategory.detail";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";


interface Props {
    id : number
    afterUpdate?(item:EquipmentCategory) : void

}

interface State {
    equipmentCategory? : EquipmentCategory
    loading : boolean
}

class EquipmentCategoryController extends React.Component<Props, State>{

    state:State = {
        loading : false
    };

    getEquipmentCategory = async () => {
        this.setState(() => ({loading : true}));
        try {
            const equipmentCategory = await getEquipmentCategory(this.props.id);

            this.setState(() => ({
                loading : false,
                equipmentCategory
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };
    updateEquipmentCategory = async (data:Partial<EquipmentCategory>) => {
        this.setState(() => ({loading : true}));
        try {
            const equipmentCategory = await editEquipmentCategory(this.props.id, data);

            this.props.afterUpdate
            && (
                data.hasOwnProperty('name') ||
                data.hasOwnProperty('active')
               )
            && this.props.afterUpdate(equipmentCategory);

            this.setState(() => ({
                loading : false,
                equipmentCategory
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };

    async componentDidMount() {
        this.getEquipmentCategory();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getEquipmentCategory();
        }
    }

    render() {
        return (
            <>
                <RenderIf condition={this.state.equipmentCategory !== undefined}>
                    <EquipmentCategoryDetail
                        updateCategory={this.updateEquipmentCategory}
                        equipmentCategory={this.state.equipmentCategory as EquipmentCategory}
                    />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }
}

export default EquipmentCategoryController