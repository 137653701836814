import React from 'react';
import Input from "../../UI/input/text";
import Table from "../../UI/table/table";
import Button from "../../UI/button/button";
import {
    createSquarePoint,
    deleteSquarePoint,
    EditSquare,
    ModulesResponse,
    Square,
    updateSquarePoint,
} from "@justpro/terminal";
import Select from "../../UI/select/select";
import {getRegionsList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import withLabel from "../../UI/withLabel/withLabel";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {PointMap} from "./squares.controller";
import checkError from "../../../utils/checkError";
import {toast} from "react-toastify";
import DeleteGPSCoordinates from "./modals/delete";
import EditGPSCoordinates from "./modals/edit";
import AddGPSCoordinates from "./modals/add";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";

type Props = {
    openModal(data:Modal) : void

    square : Square
    updateSquare(data?:Partial<EditSquare>) : void
    rights?: Partial<ModulesResponse>
};

type State = {
    points : PointMap
    pointId? : number
};


const initialState:State = {
    points : {
        [Date.now()] : {
            latitude : '',
            radius : '',
            longitude : '',
        }
    },
};


export const squaresGPSModalId = 'squaresGPSModalId';

class SquareDetail extends React.Component<Props, State> {

    state = initialState;

    onBlurName = async (name: string, oldValue: string) => {
        if(name !== oldValue) {
            this.props.updateSquare({name})
        }

    };

    onChangeRegion = async (option:Option) => {
        this.props.updateSquare({regionId : option.value});
        return Promise.resolve();

    };

    createGPSCoordinates = () => {
        this.props.openModal({
            id : squaresGPSModalId,
            component : (props:ModalBodyProps) => (
                <AddGPSCoordinates
                    {...props}
                    save={this.addPoints}
                />
            ),
            title : getText('squares.addNewCoordinates'),
        })
    };
    editGPSCoordinates = (props:any) => {

        const {radius, id, latitude, longitude} = props.original;

        this.setState(() => ({
            pointId : id
        }));

        this.props.openModal({
            id : squaresGPSModalId,
            component : (props:ModalBodyProps) => (
                <EditGPSCoordinates
                    {...props}
                    points={{
                        latitude,
                        longitude,
                        radius,
                    }}
                    save={this.editPoints}
                 />
            ),
            title : getText('squares.editCoordinates'),
        })
    };

    deleteGPSCoordinates = (props:any) => {
        const {radius, id, latitude, longitude} = props.original;

        this.setState(() => ({
            pointId : id
        }));
        this.props.openModal({
            id : squaresGPSModalId,
            component : (props:ModalBodyProps) =>  (
                <DeleteGPSCoordinates
                    {...props}
                    points={{
                        latitude : latitude,
                        longitude : longitude,
                        radius : radius,
                    }}
                    save={this.deletePoints}
                 />
            ),
            title : getText('squares.deleteCoordinates'),

        })
    };

    editPoints = async (latitude:string, longitude: string, radius: string) => {
        try {

            if(this.state.pointId) {

                const newPoint = await updateSquarePoint(this.props.square.id, this.state.pointId, {
                    latitude : +latitude,
                    longitude: +longitude,
                    radius : +radius
                });

                await this.props.updateSquare();
            }else{
                toast.warn('Id is not specified(frontend).')
            }
        }catch (e) {
            checkError(e)
        }
    };

    addPoints = async (latitude:string, longitude: string, radius: string) => {
        try {
            const newPoint = await createSquarePoint(this.props.square.id, {
                latitude : +latitude,
                longitude: +longitude,
                radius : +radius
            });

            await this.props.updateSquare();
        }catch (e) {
            checkError(e)
        }
    };

    deletePoints = async () => {
        try {

            if(this.state.pointId !== undefined) {
                const deleted = await deleteSquarePoint(this.props.square.id, this.state.pointId);

                if(deleted) {
                    await this.props.updateSquare();
                }else{
                    checkError(new Error('errors.cannotDeleteARecord'));
                }

            }else{
                toast.warn('Id is not specified(frontend).')
            }
        }catch (e) {
            checkError(e)
        }
    };

    toggleActive = async (active:boolean) => {
        this.props.updateSquare({active})
    };

    get columns() {
        const {rights} = this.props;
        const r = rights && rights['references.squares'];

        const result:any[] = [
            {
                Header : getText('common.latitude'),
                accessor : 'latitude'
            },
            {
                Header : getText('common.longitude'),
                accessor : 'longitude'
            },
            {
                Header : getText('common.radius'),
                accessor : 'radius',
            },
        ];

        if(r && r['edit']) {
            result.push(
                {
                    Header : getText('common.edit'),
                    Cell  : (props:any) => <Button className="btn-default" onClick={this.editGPSCoordinates.bind(this, props)}><i className="fa fa-edit no-text"/></Button>
                }
            )
        }

        if(r && r['delete']) {
            result.push(
                {
                    Header : getText('common.delete'),
                    Cell  : (props:any) => <Button className="btn-default" onClick={this.deleteGPSCoordinates.bind(this, props)}><i className="fa fa-trash no-text"/></Button>
                }
            )
        }
        return result;
    }

    render () {
        const {rights, square} = this.props;
        const {name, points, active, region} = square;

        const disabled = rights && rights['references.squares'] && !rights['references.squares']['edit'];

        return (
            <>
                <h3>{getText('squares.square')}: {name}</h3>
                <ToggleSwitch
                    defaultValue={active}
                    send={this.toggleActive}
                    label="common.active"
                    disabled={disabled}
                />
                <Input
                    label="squares.square"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <Select
                    change={this.onChangeRegion}
                    load={getRegionsList}
                    defaultValue={region}
                    label="regions.region"
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    isDisabled={disabled}
                />

                {withLabel(
                    <>
                        {points && points.length > 0 && <Table
                            columns={this.columns}
                            data={points}
                            showPagination={false}
                            minRows={0}
                        /> }

                        <Button className={points.length > 0 ? "btn-success margin-top" : "btn-success"} onClick={this.createGPSCoordinates}>{getText('squares.addNewCoordinates')}</Button>

                    </>
                )({
                    text : 'GPS'
                })}
            </>
        )
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});
const mapDispatch = (d:Function) => ({
    openModal : (data:Modal) => d(openModal(data))
});

export default connect(mapState,mapDispatch)(SquareDetail)