import React, {useCallback, useEffect, useState} from 'react';
import {Option, SingleProps} from './select.types'
import ReactSelect from 'react-select'
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import './select.css';
import withLabel from "../withLabel/withLabel";
import {renderToString} from "react-dom/server";
import GetText from "../../../localization/getText";


const Select = ( {
                     defaultOptions,
                     defaultValue,
                     placeholder = renderToString(GetText('UI.select.defaultChoose')),
                     accessors = {value : 'value', label : 'label'},
                     load,
                     id,
                     label,
                     change,
                     className,
                     labelPosition,
                     value:val,
                     withoutValue = false,
                    ...rest
                 }:SingleProps ) => {
    const [options, setOptions] = useState<any[]>( []);
    const [value, setValue] = useState<Option | null>( null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(rest.disabled);
    const cls = ['just-pro-select__container'];

    if(className) {
        cls.push(className);
    }

    const onChangeHandler = async (option: any) => {
        setLoading(true);
        setDisabled(true);
        await change(option);
        setDisabled(false);
        setLoading(false);
        setValue(option)
    };

    const onMenuOpenHandler = async () => {
        if(load) {
            setLoading(true);
            const data = await load();
            setLoading(false);
            const normalize = data?.map(item => makeNormalizeParams(item, accessors));
            setOptions(normalize)
        }

        if(defaultOptions) {
            defaultOptions = defaultOptions?.map( item => makeNormalizeParams(item, accessors) );

            setOptions(defaultOptions)
        }
    };

    const ref = useCallback( () => {
        if(defaultValue) {

            const dv = makeNormalizeParams(defaultValue, accessors);
            if(dv) {
                setValue(dv)
            }
        }
    }, [defaultValue]);

    useEffect(() => {
        if(val !== undefined) {
            setValue(val ? val : null)
        }
    }, [val]);

    const customFilter = (element, input) => {
        if(typeof element.label === 'string') {
            return element.label.toLowerCase().includes(input.toLowerCase());
        }
        return true;
    }

    const cmp = <ReactSelect
        ref={ref}
        filterOption={customFilter}
        onMenuOpen={onMenuOpenHandler}
        onChange={onChangeHandler}
        isLoading={loading}
        value={withoutValue ? null : value}
        inputId={id ? id.toString() : undefined}
        options={options}
        placeholder={renderToString(GetText(placeholder))}
        isDisabled={disabled}
        classNamePrefix='just-pro-select'
        className={cls.join(' ')}
        loadingMessage={() => renderToString(GetText('UI.select.loading'))}
        noOptionsMessage={() => renderToString(GetText('UI.select.noData'))}
        {...rest}

    />;

    return label ? withLabel(cmp)({text: label, id: id, position : labelPosition}) : cmp
};

export default Select