import React from 'react';
import Calendar from '../calendar.controller';
import HidePortal from '../../hidePortal/hidePortal.controller';
import { InlineWrapper } from '../calendar.view';
import { Table, GoNow } from '../datepicker.view';
import Caret from '../../caret/caret';
import Input from '../../input/text';
import Tooltip from '../../tooltip/tooltip.controller';

export default class TopLineCalendar extends Calendar {
  render() {
    return (
      <>
        <div className="form-group">
          <div className="input-group date ">
            <Input
              blur={this.onInputBlur}
              onSend={this.onInputEnter}
              startValue={this.state.inputValue}
              className="form-control"
            />
            <span className="input-group-addon" onClick={this.toggle}>
              <Caret isOpen={this.state.isOpen} />
            </span>
          </div>

          {this.state.isOpen && (
            <>
              <HidePortal hide={this.handlerHelperHide} />
              <InlineWrapper>
                <Table>
                  {this.getHeaderTable()}
                  {this.getBodyTable()}

                  <GoNow>
                    <div className="tooltip-helper-wrapper">
                      <Tooltip position="down" title="Вернуться к сегодня">
                        <div className="helper" onClick={this.goToNow} />
                      </Tooltip>
                    </div>
                  </GoNow>
                </Table>
              </InlineWrapper>
            </>
          )}
        </div>
      </>
    );
  }
}
