import {Equipment, Object, Person, Contractor} from "@justpro/terminal";

export function getFullName(person?:Person, patronymic:boolean = true) {
    return person ? `${person.lastName} ${person.firstName} ${person.patronymic && patronymic ? person.patronymic : ''}`.trim() : ""
}

export function getObjectName(object?: Object) {
    if (object && object?.number !== undefined) {
        if (object?.number?.length > 7) {
            return object.number
        } else {
            const contractorName = object?.contracts?.[0]?.contractor?.pseudoname ||
                object?.contracts?.[0]?.contractor?.name ||
                '';
            return `${contractorName} ${object?.number ? `№${object.number},` : ''} ${object?.city?.name || ''} ${object?.address || ''}`.trim();
        }
    }

    return ''
}

export const getEquipmentName = (equipment: Equipment) => {
    return `${equipment.equipmentType.name} ${equipment.number ? '№' + equipment.number : ''}`.trim();
};

export function getContractorName(contractor?: Contractor) {

    if(contractor) {
        if(contractor.pseudoname) {
            return contractor.pseudoname.trim();
        }else if(contractor.name){
            return contractor.name.trim();
        }
    }

    return 'Не удалось определить контрагента.'
}