import React from 'react'
import OrderExecution from './orderExecution'

const OrderExecutionByRegions = () => {
    return (
        <OrderExecution byRegions={true} />
    )
}

export default OrderExecutionByRegions
