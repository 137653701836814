import React, {Component} from 'react';
import './filter.css';
import Button from '../button/button'
import {Props} from './filter.types'
import HideHelper from '../hidePortal/hidePortal.controller'
import getText from "../../../localization/getText";

export default class Filter extends Component<Props, {isOpen : boolean}> {
    state = {
        isOpen : false
    };

    componentDidMount(){
        this.props.onInit && this.props.onInit({
            hide: this.hide
        })
    }

    toggleFilter = async () => {
        await this.setState((prevState) => ({
            isOpen : !prevState.isOpen
        }));

        if(this.props.sendOnClose && !this.state.isOpen) {
            this.filter()
        }
    };

    filter = () => {
        this.props.send();
        this.hide()
    };

    hide = () => {
        this.setState(() => ({
            isOpen : false
        }))
    };

    render () {
        const {activeCheckboxes} = this.props;
        let hasActive = false;

        const expand = this.state.isOpen ? 'just-pro__expanded_filter--expanded' : 'just-pro__expanded_filter';
        const iconClassName = this.state.isOpen ? 'fa just-pro-filter__icon fa-xs fa-minus' : 'fa just-pro-filter__icon fa-xs fa-plus';
        const btnCls = ['navbar-toggler'];

        if(this.props.filterNotify) {
            btnCls.push('active')
        }


        Object.entries(activeCheckboxes).forEach( ([accessor, item]) => {
            if(!hasActive) {
                if(item.length > 0) {
                    hasActive = true
                }
            }

        });


        if(hasActive) {
            btnCls.push('hasActive')
        }

        return (
            <div className='just-pro-filter navbar-form navbar-left filter'>
                <Button onClick={this.toggleFilter} className={btnCls.join(' ')}>{getText('UI.filters.filter')} <i className={iconClassName}/></Button>

                {this.state.isOpen &&
                    <div className={expand}>
                        <div className="just-pro-filter__button-container--submit">
                            <Button onClick={this.filter} className="btn-success just-pro-filter__button---submit text-right">
                                <i className="fas fa-filter"/> {getText('common.accept')}
                            </Button>
                        </div>

                        <div className="just-pro-filter--wrapper">
                            {this.props.children}
                        </div>

                        <HideHelper hide={this.hide}/>
                    </div>
                }
            </div>
        )
    }
}