import React, {useState} from "react";
import getText from "../../../localization/getText";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import './user.css'

interface Props {
    hide() : void
    password: string
}

export default function PasswordModal ({hide, password}:Props) {
    const [show, setShow] = useState(false);

    console.log({show})

    return (
        <>
            {getText('users.newPassword')} : {show ?
                <span className="user-password--shown">{password}</span> :
                <span className="user-password--hide" onClick={setShow.bind(null, true)}>{getText('users.clickForWatch')}</span>

            }

            <DefaultFooterModal>
                <Button className="btn-success" onClick={hide}>{getText('common.ok')}</Button>
            </DefaultFooterModal>
        </>
    )
}