import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import RenderIf from '../../../utils/renderIf';
import Spinner from '../../UI/spinner/spinner.controller';
import {
    blockAllDistanceTimes,
    blockDistanceTimes,
    format,
    getReviseDistanceTimesReport,
    getReviseDistanceTimesReportExport,
    ReviseDistanceTimes,
    unBlockAllDistanceTimes,
    unBlockDistanceTimes,
} from '@justpro/terminal';
import Table from '../../UI/table/table';
import getText from '../../../localization/getText';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import Button from '../../UI/button/button';
import FileDownload from 'js-file-download';
import checkError from '../../../utils/checkError';
import moment, {Moment} from 'moment';
import './timeDistanceReport.css';
import tableColumns from './tableColumns';
import {connect} from 'react-redux';
import {Modal} from '../../../store/modal/modal.types';
import {openModal} from '../../../store/modal/modal.actions';
import {SEND_PUSH_ID} from './pushModal';
import PushModal from './pushModal';

interface IProps {
    openModal(props: Modal): void;

    rights: any
}

interface IState {
    loading: boolean;
    selectedMonth: Moment;
    data: ReviseDistanceTimes[];
    selectedForPush: number[];
    generatingExcel: boolean;
}

export class TimeDistanceReport extends React.Component<IProps, IState> {
    state: IState = {
        loading: false,
        selectedMonth: moment().subtract(1, 'months'),
        data: [],
        selectedForPush: [],
        generatingExcel: false,
    };

    changeSelectedMonth(month: Moment) {
        this.setState({selectedMonth: month});
    }

    changeSelectedForPush(id: number) {
        const {selectedForPush} = this.state;
        if (!selectedForPush.includes(id)) {
            this.setState((prevState: IState) => ({
                selectedForPush: [...prevState.selectedForPush, id],
            }));
            return;
        }
        if (selectedForPush.includes(id)) {
            this.setState((prevState: IState) => ({
                selectedForPush: prevState.selectedForPush.filter(
                    (selectedId) => selectedId !== id
                ),
            }));
            return;
        }
    }

    changeSelectedAllForPush() {
        const {selectedForPush, data} = this.state;
        if (selectedForPush.length === data.length) {
            this.setState({selectedForPush: []});
            return;
        }
        if (selectedForPush.length < data.length) {
            const idsToSet = data.map((row) => row.id);
            this.setState({selectedForPush: idsToSet});
            return;
        }
    }

    async changeIsLocked(id: number) {
        const {selectedMonth} = this.state
        const selectedItem = this.state.data.find(row => row.id === id)
        console.log('selectedItem', selectedItem)
        let success
        if (selectedItem.blocked) {
            success = await unBlockDistanceTimes({
                id,
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            })
        }
        if (!selectedItem.blocked) {
            success = await blockDistanceTimes({
                id,
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            })
        }
        if (success) {
            this.setState((prevState: IState) => ({
                data: prevState.data.map((row) => {
                    if (row.id === id) {
                        return {...row, blocked: !row.blocked};
                    }
                    return row;
                }),
            }));
        }
    }

    async changeIsLockedAll() {
        const {data, selectedMonth} = this.state;
        if (data.every((row) => row.blocked)) {
            const success = await unBlockAllDistanceTimes({
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            })
            if (success) {
                this.setState((prevState: IState) => ({
                    data: prevState.data.map((row) => {
                        return {...row, blocked: false};
                    }),
                }));
            }
            return;
        }
        if (!data.every((row) => row.blocked)) {
            const success = await blockAllDistanceTimes({
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            })
            if (success) {
                this.setState((prevState: IState) => ({
                    data: prevState.data.map((row) => {
                        return {...row, blocked: true};
                    }),
                }));
            }
            return;
        }
    }

    generateExcel = async () => {
        try {
            const {selectedMonth} = this.state
            this.setState({
                generatingExcel: true,
            });
            const blob = await getReviseDistanceTimesReportExport({
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            });

            FileDownload(
                blob,
                `отчёт-сверка-часов-и-км_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
            );
        } catch (e) {
            checkError(e);
        } finally {
            this.setState({
                generatingExcel: false,
            });
        }
    };

    sendPushMessage = async () => {
        try {
            this.props.openModal({
                id: SEND_PUSH_ID,
                component: () => (
                    <PushModal selectedForPush={this.state.selectedForPush}/>
                ),
                title: getText('UI.modals.sendPushMessage'),
                size: 'mediumSmall',
            });
        } catch (e) {
            checkError(e);
        }
    };

    fetchData = async () => {
        try {
            this.setState({
                loading: true,
            });
            const {selectedMonth} = this.state;
            const data = await getReviseDistanceTimesReport({
                dateFrom: selectedMonth.startOf('month').format('YYYY-MM-DD'),
                dateTo: selectedMonth.endOf('month').format('YYYY-MM-DD'),
            });
            console.log('data', data);
            this.setState({
                data
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    };

    get columns() {
        const columns = tableColumns({
            selectedForPush: this.state.selectedForPush,
            changeSelectedForPush: this.changeSelectedForPush.bind(this),
            changeSelectedAllForPush: this.changeSelectedAllForPush.bind(this),
            data: this.state.data,
            rights: this.props.rights,
            changeIsLocked: this.changeIsLocked.bind(this),
            changeIsLockedAll: this.changeIsLockedAll.bind(this),
        });
        return columns;
    }

    render() {
        const {loading, data, selectedMonth} = this.state;
        return (
            <WithPrivateRoute>
                <HeaderController>
                    <ModuleTextName>
                        {getText('reports.timeDistanceCheck.moduleName')}
                    </ModuleTextName>
                    <ToplineCalendarWrapper>
                        <TopLineCalendar
                            date={selectedMonth}
                            mode={'monthSelect'}
                            onChange={this.changeSelectedMonth.bind(this)}
                        />
                    </ToplineCalendarWrapper>
                    <div className="navbar-form navbar-left buttons">
                        <Tooltip position="down" title="Экспортировать в эксель">
                            <RenderIf condition={!this.state.generatingExcel}>
                                <Button onClick={this.generateExcel} className="btn-default">
                                    <i className="fa fa-file-excel"/>
                                </Button>
                            </RenderIf>
                            <RenderIf condition={this.state.generatingExcel}>
                                {getText('common.generating')}
                            </RenderIf>
                        </Tooltip>
                        <RenderIf
                            condition={this.state.selectedForPush.length && this.props.rights?.["reports.reviseDistanceTimesStaffPush"]?.create}>
                            <Tooltip position="down" title="Отправить пуш-уведомление">
                                <Button onClick={this.sendPushMessage} className="btn-default">
                                    <i className="fa fa-paper-plane"/>
                                </Button>
                            </Tooltip>
                        </RenderIf>
                        <Button onClick={this.fetchData}>Получить отчёт</Button>
                    </div>
                </HeaderController>

                <div className="just-pro_module report-wrapper">
                    <div className="panel content-panel report-table">
                        <RenderIf condition={!loading && data.length}>
                            <Table
                                sortable={false}
                                columns={this.columns}
                                data={data}
                                getTrProps={(_state, {original}) => {
                                    if (original.accepted) {
                                        return {
                                            style: {
                                                backgroundColor: '#69d175',
                                            },
                                        };
                                    }
                                    if (!original.accept && original.tasksNumbers?.length) {
                                        return {
                                            style: {
                                                backgroundColor: '#ffc90e',
                                            },
                                        };
                                    }
                                    if (!original.accepted) {
                                        return {
                                            style: {
                                                backgroundColor: '#fafafa',
                                            },
                                        };
                                    }
                                }}
                            ></Table>
                        </RenderIf>
                    </div>
                    <Spinner loading={loading}/>
                </div>
            </WithPrivateRoute>
        );
    }
}

const mapState = (state) => ({
    rights: state.application.rights
})

const mapDisptachToProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props)),
});

export default connect(mapState, mapDisptachToProps)(TimeDistanceReport);
