import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import getText from '../../../localization/getText';
import {
  format,
  getExecutorDistancesReport,
  getExecutorDistancesExportReport,
  ExecutorDistanceReport,
} from '@justpro/terminal';
import moment, { Moment } from 'moment';
import checkError from '../../../utils/checkError';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import Button from '../../UI/button/button';
import FileDownload from 'js-file-download';

import Spinner from '../../UI/spinner/spinner.controller';
import RenderIf from '../../../utils/renderIf';
import Table from '../../UI/table/table';
import './distanceReport.css';

interface IState {
  loading: boolean;
  generatingExcel: boolean;
  from: Moment;
  to: Moment;
  data: ExecutorDistanceReport[];
}

class DistanceReport extends React.Component<null, IState> {
  state = {
    loading: false,
    generatingExcel: false,
    data: [],
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
  };
  async componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({
        loading: true,
      });

      const filters = {
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      };
      const receivedData = await getExecutorDistancesReport(filters);
      this.setState({
        data: receivedData,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeFrom = (from: Moment) => {
    this.setState(() => ({ from }), this.getData);
  };
  onChangeTo = (to: Moment) => {
    this.setState(() => ({ to }), this.getData);
  };

  generateExcel = async () => {
    try {
      this.setState({
        generatingExcel: true,
      });
      const blob = await getExecutorDistancesExportReport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });

      FileDownload(
        blob,
        `отчет-по-километражу_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        generatingExcel: false,
      });
    }
  };

  columns = [
    {
      Header: getText('common.executor'),
      width: 350,
      Cell: (props) => (
        <div className="actsDistance_cell">{props.original.executor}</div>
      ),
    },
    {
      Header: getText('persons.person1s'),
      width: 350,
      Cell: (props) => (
        <div className="actsDistance_cell">{props.original.person1s}</div>
      ),
    },
    {
      Header: getText('common.date'),
      width: 150,
      Cell: (props) => (
        <div className="actsDistance_cell">{props.original.date}</div>
      ),
    },
    {
      Header: getText('reports.distanceReport.mileage'),
      width: 150,
      Cell: (props) => (
        <div className="actsDistance_cell">{props.original.distance}</div>
      ),
    },
  ];
  render() {
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>
            {getText('reports.distanceReport.moduleName')}
          </ModuleTextName>
          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.onChangeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.onChangeTo} />
          </ToplineCalendarWrapper>
          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="Экспортировать в эксель">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                Генерация...
              </RenderIf>
            </Tooltip>
          </div>
        </HeaderController>

        <div className="just-pro_module report-wrapper">
          <div className="panel content-panel">
            <div className="actsDistance_table">
              <RenderIf condition={!this.state.loading}>
                <Table
                  columns={this.columns}
                  data={this.state.data}
                  wrapperClassName="report_flex-table"
                ></Table>
              </RenderIf>
            </div>

            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

export default DistanceReport;
