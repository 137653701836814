import * as api from '@justpro/terminal'
import checkError from "../../../utils/checkError";
import {usersTypes} from "../users.reducer";
import {rolesTypes} from "../roles.reducer";
import {groupTypes} from "../groups.reducer";
import {listTypes} from "../list.reducer";
import {userTypes} from "../user.reducer";
import {getGroups} from "@justpro/terminal";

export const getTree = () => async (dispatch: Function) => {
    try {
        const rolesMap = await api.getRoles();
        const groupsMap = await getGroups();

        dispatch({
            type: rolesTypes.UPDATE_ROLES_MAP,
            map : rolesMap,
        });

        dispatch({
            type: groupTypes.UPDATE_GROUP_MAP,
            map : groupsMap
        });
    } catch (e) {
        checkError(e)
    }
};

export const getAddUserPanel = () => (dispatch: Function) => {
    dispatch({
        type: usersTypes.CHANGE_USERS_PANELS,
        detail : 'add'
    })
};

// export const getStatusList = () => async (dispatch: Function) => {
//     try {
//         const statusList = await api.getStatuses();
//         dispatch({
//             type: types.GET_STATUS_LIST,
//             statusList
//         })
//     } catch (err) {
//         checkError(err);
//     }
// };

// export interface AddRole {
//     roleID: number
//     userID: number
// }

//
// export interface AddGroup {
//     groupID: number
//     userID: number
// }

export const addUserRole = (userId: number, roleId: number) => async (dispatch: Function) => {
    try {
        const addUserRoleResult =  await api.addUserRole(userId, roleId);
        if(!addUserRoleResult) return;
        // return actualizeUserData(dispatch, userId);
        const {roles} = await api.getUser(userId);

        dispatch({
            type : userTypes.UPDATE_USER,
            roles
        })
    } catch (err) {
        checkError(err);
    }
};

export const addUserGroup = (userId:number, groupId:number) => async (dispatch: Function) => {
    try {
        const addUserGroupResult = await api.addUserGroup(userId, groupId);
        if(!addUserGroupResult) return;
        const {groups} = await api.getUser(userId);

        dispatch({
            type : userTypes.UPDATE_USER,
            groups
        })

        // return actualizeUserData(dispatch, userId);
    } catch (err) {
        checkError(err);
    }
};

//todo лишняя херь
export interface IaddTreeItem {
    treeId: number
    tree: string
    name: string
}

export const createGroup = (id:number, name:string) => async (dispatch:Function) =>  {
    try {
        const {children, parentGroups} = await api.createGroup({parentGroup: id, name});
        const map = await api.getGroups();

        const parent = parentGroups && map[parentGroups[0]];

        dispatch({
            type: groupTypes.CREATE_GROUP,
            parent,
            children,
            name,
            map,
        });

        dispatch({
            type : usersTypes.CHANGE_USERS_PANELS,
            detail : 'group'
        });
    }catch (e) {
        checkError(e)
    }

};

export const createRole = (id:number, name:string) => async (dispatch:Function) => {
    try {
        const {newOrderStatus, rights} = await api.createRole({name, parentRole: id});
        const map = await api.getRoles();

        dispatch({
            type: rolesTypes.CREATE_ROLE,
            name,
            id,
            newOrderStatus,
            rights,
            map
        });

        dispatch({
            type : usersTypes.CHANGE_USERS_PANELS,
            detail : 'role',
        });
    }catch(e) {
        checkError(e)
    }
};

export const searchUsers = (data: api.GetUsers) => async (dispatch: Function) => {
    try {
        const users = await api.getUsers(data);
        dispatch({
            type: listTypes.GET_USERS_LIST,
            users,
        })

    } catch (err) {
        checkError(err);
    }
};

export const deleteRole = (roleId: number) => async (dispatch:Function) => {
    const deleted = await api.deleteRole(roleId);
    if(!deleted) return;

    const rolesMap = await api.getRoles();

    dispatch({
        type : usersTypes.CHANGE_USERS_PANELS,
        detail : '',
        tree : ''
    });

    dispatch({
        type : rolesTypes.DELETE_ROLE,
        map : rolesMap,
        id : -1,
    });


};

export const deleteGroup = (groupId:number) => async (dispatch:Function) => {
    const deleted = await api.deleteGroup(groupId);
    if(!deleted) return;

    const groupsMap = await api.getGroups();

    dispatch({
        type : usersTypes.CHANGE_USERS_PANELS,
        detail : '',
        tree : ''
    });

    dispatch({
        type : groupTypes.DELETE_GROUP,
        map : groupsMap,
        id : -1,
    });
};





