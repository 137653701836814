import React from 'react';
import {createMaster, getMasters, ModulesResponse, Master, filterParams} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import MasterController from "./master.controller";
import MasterCreate from "./master.create";
import {getFullName} from "../../../utils/names";
import {ApplicationMapState} from "../../application/application.controller";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {connect} from "react-redux";
import { CheckboxesMap } from '../../UI/checkboxList/checkboxList.types';
import { getEquipmentCategoriesList, getAreasList } from '../../../utils/functions';

interface Props {
    rights?: Partial<ModulesResponse>
}

class MastersController extends React.Component<Props> {

    getCheckboxesMap = async () => {
        const {rights} = this.props;
        const result:CheckboxesMap = {};

        if(rights?.['references.equipmentCategories']?.read) {
            const equipmentCategories = await getEquipmentCategoriesList();
            result[filterParams.EQUIPMENT_CATEGORIES] = {
                name : 'equipmentCategories.equipmentCategory',
                children : equipmentCategories.map( ({name, id}) => ({name, id}))
            }
        }

        if(rights?.['references.areas']?.read) {
            const areas = await getAreasList();
            result[filterParams.AREAS] = {
                name : 'areas.area',
                children : areas.map( ({name, id}) => ({name, id}))
            }
        }

        result.active = {
            name: 'masters.actual',
            children: [{
                name: 'common.yes',
                id: 1
            }, {
                name: 'common.no',
                id: 0
            }]
        }

        return result;
    };

    renderItem = (item:Master) => {
        return <>{getFullName(item.person)} <b>({item.openedOrdersCount})</b></>
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.masters'];
        return <BaseReferenceController
            Detail={MasterController}
            create={{
                handler : createMaster,
                Component : MasterCreate,
                title : 'masters.addItem'
            }}
            referenceName="masters.moduleName"
            getList={(params) => getMasters({ ...params, limit: 10000})}
            filter={{
                getCheckboxesMap : this.getCheckboxesMap,
                filterName : filterNames.MASTERS
            }}
            listItem={{
                render: this.renderItem
            }}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})


export default connect(mapState)(MastersController)