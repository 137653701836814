import React from 'react';
import getText from "../../../../localization/getText";
interface Props {
    change(month:number) : void
    active : number[]
}

const weekDays = [
    {
        name : "schedule.weekDays.monday",
        id : 1
    },
    {
        name : 'schedule.weekDays.tuesday',
        id : 2
    },
    {
        name : 'schedule.weekDays.wednesday',
        id : 3
    },
    {
        name : 'schedule.weekDays.thursday',
        id : 4
    },
    {
        name : 'schedule.weekDays.friday',
        id : 5
    },
    {
        name : 'schedule.weekDays.saturday',
        id : 6
    },
    {
        name : 'schedule.weekDays.sunday',
        id : 0
    },
];

export default class WeekDays extends React.Component<Props> {


    render() {

        return (
            <div>
                <h6>{getText('schedule.weekDaysHeader')}</h6>
                <div className="weekdays">
                    {weekDays.map (item => {
                        return (
                            <div
                                key={item.id}
                                onClick={(e:React.MouseEvent) => this.props.change(item.id)}
                                className={this.props.active.includes(item.id) ? 'checked cell' : 'cell' }
                            >
                                {getText(item.name)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}