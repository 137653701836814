import {fas} from '@fortawesome/free-solid-svg-icons'

export function fromClassName(iconClassName:string) {
    const [prefix, iconClass] = iconClassName.split(' ');
    const iconName = iconClass.substring(3);

    return [prefix, iconName];
}


export function toClassName(serverIcon?:string | null) {
    if(serverIcon) {
        const [prefix, icon] = serverIcon.split(':');
        return `${prefix} fa-${icon}`;
    }else{
        return '';
    }

}

export function getIconsList() {
    const map = Object.entries(fas);

    return map.reduce( (result:string[], [id, icon]):string[] => {
        const prefix = icon.prefix;
        const iconName = icon.iconName;


        return [...result, `${prefix} fa-${iconName}`]
    }, []);
}