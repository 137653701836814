import React from 'react';
import {Contract, EditContract, format, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import moment, {Moment} from "moment";
import Select from "../../UI/select/select";
import {getContractorList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import ContractContractConditions from "./contract.contractConditions";
import ContractEquipmentCategories from "./contract.equipmentCategories";
import ContractOrderTypes from "./contract.orderTypes";
import ContractHistory from "./contract.history";
import ContractObject from "./contract.object";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import checkError from "../../../utils/checkError";
import getText from "../../../localization/getText";

interface Props {
    contract: Contract
    updateContract(data:Partial<EditContract>) : void
    rights?: Partial<ModulesResponse>
}

class ContractDetail extends React.Component<Props>{

    toggleActiveContract = (active:boolean) => {
        this.props.updateContract({active})
    };

    onBlurName = (name: string, oldValue: string) => {
        if(name !== oldValue) {
            this.props.updateContract({
                name
            })
        }
    };

    changeBeginDate = async (date:Moment) => {
        if(moment(this.props.contract.termsEnd).isAfter(date)) {
            await this.props.updateContract({termsStart : date.format(format.date)});
        }else{
            checkError(new Error('errors.endDateCannotBeLessThenStartDate'));
        }
    };

    changeEndDate = async (date:Moment) => {

        if(moment(this.props.contract.termsStart).isBefore(date)) {
            await this.props.updateContract({termsEnd : date.format(format.date)});
        }else{
            checkError(new Error('errors.endDateCannotBeLessThenStartDate'));
        }
    };

    changeContractor = (option:Option) => {
        this.props.updateContract({contractorId : option.value} );
        return Promise.resolve();
    };

    changeExecutor = (option:Option) => {
        this.props.updateContract({executorId : option.value} );
        return Promise.resolve();
    };


    render () {
        const {contract, rights} = this.props;


        const rule = rights && rights['references.contracts'];
        const disabled = rule && !rule['edit'];

        return (
            <>
                <h3>{getText('contracts.contract')}: {contract.name}</h3>

                <ToggleSwitch
                    send={this.toggleActiveContract}
                    label="common.active"
                    defaultValue={contract.active}
                    disabled={disabled}
                />

                <Input
                    label="contracts.contract"
                    blur={this.onBlurName}
                    startValue={contract.name}
                    disabled={disabled}
                />

                <InlineCalendar
                    date={moment(contract.termsStart)}
                    label={{ text : "contracts.startDate"}}
                    onChange={this.changeBeginDate}
                    isDisabled={disabled}
                />

                <InlineCalendar
                    date={moment(this.props.contract.termsEnd)}
                    label={{text : "contracts.endDate"}}
                    onChange={this.changeEndDate}
                    isDisabled={disabled}
                />

                <Select
                    label="contractors.contractor"
                    load={getContractorList}
                    accessors={{
                        name : 'label',
                        id: 'value'
                    }}
                    change={this.changeContractor}
                    defaultValue={contract.contractor ? contract.contractor : {}}
                    isDisabled={disabled}
                />

                <Select
                    change={this.changeExecutor}
                    load={getContractorList}
                    defaultValue={contract.executor ? contract.executor : {}}
                    label="common.executor"
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    isDisabled={disabled}
                />

                <ContractOrderTypes
                    contractId={contract.id}
                    orderTypes={contract.ordersTypes}
                    rights={rule}
                />

                <ContractEquipmentCategories
                    contractId={contract.id}
                    equipmentCategories={contract.equipmentCategories}
                    rights={rule}

                />

                <ContractContractConditions
                    contractId={contract.id}
                    contractConditions={contract.contractConditions}
                    rights={rule}

                />

                <ContractObject
                    contractId={contract.id}
                    objects={contract.objects}
                    contractorId={contract.contractor.id}
                    rights={rule}

                />

                <ContractHistory
                    suspended={contract.suspended}
                    contractId={contract.id}
                    history={contract.suspensionsHistory}
                    rights={rule}
                />
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(ContractDetail)