import React from 'react';
import Input from "../../UI/input/text";
import {ModulesResponse, Region, UpdateRegion} from "@justpro/terminal";
import Select from "../../UI/select/select";
import {getPersonsList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {getFullName} from "../../../utils/names";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import RegionBasePoints from "./region.basepoints";

type Props = {
    updateRegion(data:Partial<UpdateRegion>):void
    region : Region
    rights?: Partial<ModulesResponse>
};


class RegionDetail extends React.Component<Props> {

    onBlurName = async (name: string, oldValue: string) => {

        if(name !== oldValue) {
            this.props.updateRegion({name});
        }

    };

    changeResponsibleAdmAct = (option:Option) => {
        return new Promise(async resolve => {
            this.props.updateRegion({responsibleAdminActId : option.value});
            resolve()
        })
    };



    changeResponsibleInvoice = (option:Option) => {
        return new Promise(async resolve => {
            this.props.updateRegion({responsibleInvoiceId : option.value});

            resolve()

        })
    };

    changeResponsiblePurchase = (option:Option) => {
        return new Promise(async resolve => {
            this.props.updateRegion({responsiblePurchaseId : option.value});

            resolve()

        })
    };

    toggleActive = async (active:boolean) => {
        this.props.updateRegion({active});
    };

    render () {
        const {rights, region} = this.props;
        const {name, responsibleAdminAct, responsiblePurchase, responsibleInvoice, active} = region;

        console.log({responsibleAdminAct, responsiblePurchase, responsibleInvoice})

        const disabled = rights && rights['references.regions'] && !rights['references.regions']['edit'];
        return (
            <>
                <h3>{getText('regions.region')}: {name}</h3>

                <ToggleSwitch
                    defaultValue={active}
                    send={this.toggleActive}
                    label="common.active"
                    disabled={disabled}
                />
                <Input
                    label="regions.region"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <Select
                    change={this.changeResponsibleAdmAct}
                    load={getPersonsList}
                    defaultValue={responsibleAdminAct ? {
                        name : `${getFullName(responsibleAdminAct)}`,
                        id : responsibleAdminAct.id
                    } : {}}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    label="regions.adminAct"
                    isDisabled={disabled}
                />

                <Select
                    change={this.changeResponsibleInvoice}
                    load={getPersonsList}
                    defaultValue={responsibleInvoice ? {
                        name : ` ${getFullName(responsibleInvoice)}`,
                        id : responsibleInvoice.id
                    } :  {}}
                    accessors={{
                        name : 'label',
                        id : 'value',
                    }}
                    label="regions.materials"
                    isDisabled={disabled}
                />

                <Select
                    change={this.changeResponsiblePurchase}
                    load={getPersonsList}
                    defaultValue={responsiblePurchase ? {
                        name : `${getFullName(responsiblePurchase)}`,
                        id : responsiblePurchase?.id
                     } : {}}
                    accessors={{
                        name : 'label',
                        id : 'value',
                    }}
                    label="regions.purchase"
                    isDisabled={disabled}
                />
                <RegionBasePoints regionId={region.id} basePoints={region.basePoints}/>
            </>
        )
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(RegionDetail)