import React from "react";
import {SizeMap} from "../../components/UI/dialog/modal/modal.controller";

export interface ModalBodyProps {
    hide(...args: any): any
}

export interface Modal {
    id : string
    size? : keyof SizeMap,
    title? : React.ReactNode
    component?(props: ModalBodyProps): React.ReactNode
    minHeight?: string
    afterClose?() : void
}

export interface ModalReducer {
    modals : Modal[]
}


export const initialState:ModalReducer = {
    modals : []
};


