import React, { Component } from 'react';
import './checkboxList.css'
import {ActiveCheckboxesList, ParentMap, Props, State} from './checkboxList.types';
import Caret from '../caret/caret'
import Tooltip from "../tooltip/tooltip.controller";
import {toast} from "react-toastify";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import {toClassName} from "../../../utils/icons";

type InputEvent = React.FormEvent<HTMLInputElement>

export default class CheckboxList extends Component<Props, State> {

    state: State = {
        list: {},
    };

    toggleOpen = (accessor: string) => {

        this.setState((prevState) => ({
            ...prevState,
            list : {
                ...prevState.list,
                [accessor] : {
                    ...prevState.list[accessor],
                    isOpen : !this.state.list[accessor].isOpen
                }
            }
        }))
    };

    changeChild = async (e:InputEvent, accessor: string, changedId: number | string, excludeKey? : string) => {
        const checked = e.currentTarget.checked;
        let activeCheckboxes:ActiveCheckboxesList = {};

        if(checked) {
            activeCheckboxes = this.addOne(accessor, changedId, excludeKey)
        }else{
            activeCheckboxes = this.removeOne(accessor, changedId, excludeKey)
        }

        this.props.send(activeCheckboxes)
    };

    addOne = (accessor: string, item: string | number, excludeKey?: string) => {
        const {activeCheckboxes} = this.props;
        let newActive:ActiveCheckboxesList = {};
        if(excludeKey) {
            if(activeCheckboxes[excludeKey]) {
                newActive[excludeKey] = [...activeCheckboxes[excludeKey], item]
            }else{
                newActive[excludeKey] = [item];
            }
        }else {
            if(activeCheckboxes[accessor]) {
                newActive[accessor] = [...activeCheckboxes[accessor], item]
            }else{
                newActive[accessor] = [item];
            }
        }

        return {
            ...activeCheckboxes,
            ...newActive
        }

    };

    removeOne = (accessor: string, item: string | number, excludeKey?: string) => {
        const {activeCheckboxes} = this.props;

        const result:ActiveCheckboxesList = {
            ...activeCheckboxes,
        };

        if(excludeKey) {
            if(activeCheckboxes && activeCheckboxes[excludeKey]) {
                result[excludeKey] = activeCheckboxes[excludeKey].filter(exist => exist !== item)
            }
        }else{
            if(activeCheckboxes && activeCheckboxes[accessor]) {
                result[accessor] = activeCheckboxes[accessor].filter(exist => exist !== item)
            }
        }

        return result
    };


    addAll = (accessor: string, items: Array<string | number>, excludeKey?: string):ActiveCheckboxesList => {
        const {activeCheckboxes} = this.props;
        let newActive:ActiveCheckboxesList = {};

        if(activeCheckboxes[accessor]) {
            newActive[accessor] = [...activeCheckboxes[accessor], ...items]
        }else{
            newActive[accessor] = items ? items : [];
        }

        if(excludeKey) {
            if(activeCheckboxes[excludeKey]) {
                newActive[excludeKey] = [...activeCheckboxes[excludeKey], ...items]
            }else{
                newActive[excludeKey] = items ? items : [];
            }
        }

        return {
            ...activeCheckboxes,
            ...newActive
        };
    };

    removeAll = (accessor: string, items: Array<string | number>, excludeKey?: string):ActiveCheckboxesList => {
        const {activeCheckboxes} = this.props;

        const result = {
            ...activeCheckboxes,
        };

        if(activeCheckboxes[accessor]) {
            result[accessor] = []
        }

        if(excludeKey && activeCheckboxes[excludeKey]) {
            result[excludeKey] = []
        }
        return result
    };

    onChangeParent = async (e:InputEvent, accessor: string, excludeKey?: string) => {
        const {children} = this.state.list[accessor];
        const checked = e.currentTarget.checked;
        let newActive = {};

        if(checked) {

            console.log({children})
            const childs = children?.map( ({id, value}) => {
                if(id !== undefined) {
                    return id
                }else if(value !== undefined) {
                    return value
                }else{
                    toast('Checkbox must have id or value (frontend)')
                }
            });

            newActive = this.addAll(accessor, (childs as Array<number | string>), excludeKey);
        }else{
            newActive = this.removeAll(accessor, [], excludeKey)
        }

        this.props.send(newActive)
    };

    createList = () => {
        const {checkboxesMap} = this.props;
        let list:ParentMap = {};

        Object.entries(checkboxesMap).forEach( ([accessor, {name, children}]) => {

            list[accessor] = {
                isOpen: false,
                name,
                children,
            };
        });

        this.setState(() => ({
            list
        }))
    };

    isActiveCheckbox = (accessor: string, value:string | number, excludeKey?:string ):boolean => {
        const {activeCheckboxes} = this.props;

        let res:boolean = false;

        if(excludeKey) {
            if(activeCheckboxes && activeCheckboxes[excludeKey] && value !== undefined) {
                res = activeCheckboxes[excludeKey].includes(value)
            }
        }else{
            if(activeCheckboxes && activeCheckboxes[accessor] && value !== undefined) {
                res = activeCheckboxes[accessor].includes(value)
            }
        }

        return res;
    };

    cutText = (value:string) => {
        const longText = value.length > 25;


        const v = value.replace(/&quot;/g, '"' )
                        .replace(/&#x27;/g, '`' );

        return longText ? (
            <Tooltip position="up" title={v}>
                <span className='just-pro__checkbox-list-cut-text'>{ v.slice(0, 22) + '...' }</span>
            </Tooltip>
        ) : (
            <span>{v}</span>
        )
    };

    componentDidMount() {
        this.createList();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (JSON.stringify(prevProps.checkboxesMap) !== JSON.stringify(this.props.checkboxesMap)) {
            this.createList();
        }
    }

    render() {

        const {list} = this.state;
        const map = Object.entries(list);

        if (map.length === 0) {
            return <></>
        }

        return (
            <div className="just-pro-checkbox-list_container">
                {map.map( ([accessor, rest]) => {
                    const {isOpen, name, children} = rest;
                    let activeCount = 0;
                    if(!children) return null;

                    const childs = children?.map( ({id, name, value, excludeItem, color, icon}) => {
                        const _id = id !== undefined ? id :
                                    value ? value : '';

                        const active = this.isActiveCheckbox(accessor, _id);
                        let activeExclude:boolean = false;

                        if(active) {
                            activeCount++
                        }

                        if(excludeItem) {
                            activeExclude = this.isActiveCheckbox(accessor, (excludeItem.id as number), excludeItem.excludeKey)
                        }

                        if(activeExclude) {
                            activeCount++
                        }//

                        return (
                            <div key={accessor + _id}>
                                <div className="just-pro__checkbox-children" >
                                    <input
                                        type="checkbox"
                                        checked={active}
                                        onChange={(e:InputEvent) => this.changeChild(e, accessor, _id)}/>
                                    {icon && <i className={toClassName(icon)} style={{color, marginRight : 5}}/>} {this.cutText(renderToString(getText(name)))}
                                </div>

                                {excludeItem !== undefined && isOpen &&
                                    <div className="just-pro__checkbox-children" key={ 'exclude__' + accessor + excludeItem.id}>
                                        <input
                                            type="checkbox"
                                            checked={activeExclude}
                                            onChange={(e:InputEvent) => this.changeChild(e, accessor, _id, excludeItem.excludeKey)}
                                        />
                                        {icon && <i className={toClassName(icon)} style={{color, marginRight : 5}}/>} {this.cutText(renderToString(getText(excludeItem.name)))}
                                    </div>
                                }
                            </div>
                        );
                    } );

                    let checked:boolean = false;

                    if(children[0]?.excludeItem !== undefined) {
                        checked = children?.length * 2 === activeCount;
                    }else{
                        checked = children?.length === activeCount;
                    }


                    let parentClasses = ['just-pro__checkbox-list-row'];

                    if (checked) {
                        parentClasses.push('open')
                    }
                    return (
                        <span key={accessor}>
                            <div className={parentClasses.join(' ')}>
                                <input type="checkbox"
                                       className="just-pro__checkbox-list-input"
                                       onChange={ (e:InputEvent) => this.onChangeParent(e, accessor, children[0]?.excludeItem?.excludeKey)}
                                       checked={checked}
                                />
                                <div className="just-pro__checkbox-list-row-content" onClick={this.toggleOpen.bind(this, accessor)}>
                                    <span className="just-pro__checkbox-list-header">{getText(name)} <span className="badge">{activeCount > 0 ? activeCount : ''}</span></span>
                                    <Caret isOpen={isOpen}/>
                                </div>

                            </div>

                            {isOpen &&
                                <div className="just-pro__checkbox-list-children">
                                    {childs}
                                </div>
                            }
                        </span>
                    )
                })}
            </div>
        )

    }
}