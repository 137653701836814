import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from 'redux-thunk'
import application from './application/application';
import users from './users/users.reducer'
import orders from './orders/orders.reducer'
import regularWorks from './regularWorks/regularWorks.reducer'
import workOrders from './workOrders/workOrders.reducer'
import modal from './modal/modal.reducer'
import maps from './map/map.reducer'
import filters from './filters/filters.reducer'

const reducers = combineReducers({
    users,
    application,
    orders,
    regularWorks,
    workOrders,
    modal,
    maps,
    filters
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
