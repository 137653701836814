import React from "react";
import ModuleListItem from "../UI/itemsList/moduleListItem/moduleListItem.controller";
import {Angle} from "../UI/itemsList/card/card.view";
import ListIcons, {ListIconProps} from "../UI/itemsList/icons/icons.controller";
import moment from "moment";
import {Person, ITask, ITaskTag} from "@justpro/terminal";
import getTextFromHtml from "../../utils/getTextFromHtml";
import RenderIf from "../../utils/renderIf";
import Tooltip from "../UI/tooltip/tooltip.controller";
import WithModuleDetail from "../UI/dialog/withModuleDetail/withModuleDetail.controller";
import TaskController from "./task.controller";
import getText from "../../localization/getText";

interface ITaskMap{
    [key: number]: any
}
export const STATES_MAP: ITaskMap = {
    0: {
        name: "UI.itemList.state.open",
        icon: "",
        color: "#03A9F4",
        states: [1, 2]
    },
    1: {
        name: "UI.itemList.state.inWork",
        icon: "fa-play",
        color: "#3c763d",
        states: [0, 2, 3]
    },
    2: {
        name: "UI.itemList.state.putOff",
        icon: "fa-pause",
        color: "#c09a51",
        states: [0, 1]
    },
    3: {
        name: "UI.itemList.state.ended",
        icon: "fa-stop",
        color: "#c94c4a",
        states: [1, 4]
    },
    4: {
        name: "UI.itemList.state.archive",
        icon: "fa-archive",
        color: "#777",
        states: [0]
    }
};
interface ITaskMapOptions {
    showTitle?: boolean
}

export const getTaskState = (stateId: number, options?: ITaskMapOptions) => {
    const state = STATES_MAP[stateId];
    return state ? (
        <div className="task-status-button"
             style={{backgroundColor: state?.color || "#7c7c7c"}}>
            <RenderIf condition={state?.icon}>
                <i className={`task-status-icon fa ${state?.icon}`}/>
            </RenderIf>
            {getText(state.name)}
        </div>
    ) : null;
};

export const PRIORITY_MAP: ITaskMap = {
    0: {
        color: "#333",
        name: "UI.itemList.priority.notImportant",
        icon: "fa-flag"
    },
    1: {
        color: "#3c763d",
        name: "UI.itemList.priority.middleImportant",
        icon: "fa-flag"
    },
    2: {
        color: "#f0ad4e",
        name: "UI.itemList.priority.important",
        icon: "fa-flag"
    },
    3: {
        color: "#a94442",
        name: "UI.itemList.priority.veryImportant",
        icon: "fa-flag"
    }
};

export const getTaskPriority = (priorityId: number, options?: ITaskMapOptions) => {
    const priority = PRIORITY_MAP[priorityId];
    return priority ? (
        <div className="task-map-element">
            <i className={`fa ${priority?.icon}`} style={{ color: priority?.color }}/>
            {options?.showTitle ? priority?.name : ''}
        </div>
    ) : null;
};

interface Props {
    task : any
    subtask?: boolean
    onClick?(...args: any[]) : any
    onClickIcon?(icon: string) : any
    style?: any
    withActive?: boolean
    withModuleDetail?: boolean
    withAngle?: {
        color : string
    }
}

interface State {

}

export const fullName = (person: Person) => {
    return [person?.firstName, person?.lastName].filter((n) => n).join(" ");
};

const getTaskIcons = (task: ITask, onClick?: (icon: string) => any) => {
    const icons:Partial<ListIconProps> = {};
    icons.onClick = onClick;
    if(task.tags) {
        icons.tags = [];

        task.tags.forEach((tag: ITaskTag) => {
            const exist = icons.tags?.find(item => tag.id === item.id);

            if(!exist) {
                icons.tags?.push(tag);
            }
        })
    }
    icons.priority = task.priority;
    icons.state = task.state;
    icons.problem = !!task.problem;
    icons.newComments = task.newComments && !task.subtasks;
    icons.updated = task.updated && !task.subtasks;
    return icons;
};

function getEllipsisTextFromHtml(maxLength:number, html?:string):string {
    const val = getTextFromHtml(html);

    return val ? val.length >= maxLength ? val.slice(0, maxLength - 3) + '...' : val : ''

}

const TaskListItem = ({task, onClick, onClickIcon, withActive, withAngle, subtask, withModuleDetail, style}:Props) => {
    const maxLength = 70;

    return (
        <ModuleListItem
            onClick={onClick}
            isActive={withActive}
            className={subtask ? 'subtask' : ''}
            style={style}
        >
            <div className="body">
                {withAngle && <Angle size={40} color={withAngle.color} />}


                <div className="icon-zone">
                    <ListIcons  {...getTaskIcons(task, onClickIcon)}/>
                </div>

                <div className="content-text">
                    <p className="list-group-item-text"><i>{fullName(task.initiator)}</i> – <i>{fullName(task.responsible)}</i></p>
                    <p className="list-group-item-text">{getEllipsisTextFromHtml(maxLength, task.title)}</p>
                    <p className="list-group-item-text">{getEllipsisTextFromHtml(maxLength, task.content)}</p>
                </div>
            </div>


            <div className="helpers">
                {!!withModuleDetail && (
                    <div>
                        <WithModuleDetail
                            element={
                                <>
                                    <Tooltip position="left" title="common.openDetailInfo">
                                        <i className="fa fa-expand expand"/>
                                    </Tooltip>
                                </>
                            }
                            modal={{
                                id : `task-detail-${task?.id}`,
                                title : getText('tasks.task'),
                                component : () => (<TaskController id={task.id}/>)
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="number">
                <b>№{task.number}</b>
                <b>{moment(task.dueDate).format('DD.MM.YYYY')}</b>
                <b>{moment(task.dueDate).format('HH:mm')}</b>
            </div>


        </ModuleListItem>
    )
};

export default TaskListItem;
