import React, {Component} from "react";
import {toast} from "react-toastify";
import {fullName} from "../tasks/task.listItem";
import ButtonDropzone from "../UI/dropzone/button/buttonDropzone.controller";
import Button from "../UI/button/button";
import RenderIf from "../../utils/renderIf";
import TextEditorController from "../UI/textEditor/textEditor.controller";
import {SingleComment} from "../UI/comments/comments.controller";
import {
    AddFile, Comment, ITaskCoworker, ITaskWatcher, ModulesResponse,
    Person
} from "@justpro/terminal";
import File from "../UI/fileManager/file.preview";


interface MyFiles {
    name: string
    blob: any
    id: number
    rejected: boolean | string
}

interface IAddCommentState {
    value: string
    files: MyFiles[]
    errors: number[]
    loading: boolean
}

interface IAddCommentProps {
    addComment(comment: string, files?: AddFile[]): void

    onCancel(): any

    replyPerson?: Person
}

export default class AddComment extends Component<IAddCommentProps, IAddCommentState> {
    state = {
        value: this.props?.replyPerson?.firstName ? this.props?.replyPerson?.firstName + ', ' :  '',
        files: [],
        errors: [],
        loading: false
    }

    sendHandler = async () => {
        if (this.state.value.trim() !== "") {
            await this.addComment();
        } else {
            toast.warn('Значение не может быть пустым')
        }
    };

    onChangeComment = (value: string) => {
        this.setState({
            value
        })
    };

    dismissSubmit = () => {
        this.setState({
            value: '',
            files: []
        });
        this.props?.onCancel && this.props.onCancel();
    };

    dismissFileFromComment = (id: number) => {
        const newFiles = this.state.files.filter((item: MyFiles) => item.id !== id);
        this.setState({
            files: newFiles
        })
    };


    setFile = (newFiles: File[], rejectedFiles: File[]) => {
        let resultFiles: MyFiles[] = [];
        const maxSize = 5048576;

        newFiles.forEach((file) => {
            resultFiles.push({
                name: file.name,
                blob: file,
                id: file.lastModified,
                rejected: false,
            })
        });

        // todo переделать errors ....
        const errors: number[] = [];

        rejectedFiles.forEach(rejected => {
            errors.push(rejected.lastModified);

            resultFiles.push({
                rejected: `Файл сликом большой, максимальный размер ${ Math.round(maxSize / 1024 / 1024) } Мб`,
                name: rejected.name,
                blob: rejected,
                id: rejected.lastModified,
            })
        });
        this.setState((prevState) => ({
            files: [...prevState.files, ...resultFiles],
            errors
        }))
    };

    addComment = async () => {
        const resultFiles = this.state.files.map((file: MyFiles) => {
            const {id, ...rest} = file;
            return {
                ...rest
            }
        });
        await this.props.addComment(this.state.value, resultFiles);
        this.setState({
            files: [],
            value: ''
        })
    };

    render() {
        const {value, files} = this.state;
        return (
            <div className="just-pro__add_comment">
                <div id="toolbar-notifications"></div>
                <TextEditorController
                    html={value}
                    onChange={this.onChangeComment}
                    placeholder="Введите сообщение"
                    customName="form-control just-pro__add_comment"
                    customEnterFunc={this.sendHandler}
                    toolbar
                    toolbarId='#toolbar-notifications'
                    minHeight={100}
                />

                {files.length > 0 &&
                <div className="just-pro__comment-files">
                    {files.map((item: MyFiles) => {
                        const {rejected} = item;
                        let cls = ['just-pro__comment-file'];

                        if (rejected) {
                            cls.push('rejected');
                            toast.error(rejected);

                        }

                        return (
                            <span className={cls.join(' ')} key={item.id}> {item.name}
                                <span
                                    className="just-pro__comment-file-dismiss"
                                    onClick={this.dismissFileFromComment.bind(null, item.id)}
                                >x</span>
                                    </span>
                        )
                    })}
                </div>
                }

                <div className="just-pro__comment-buttons">
                    <Button className="btn-success" onClick={this.addComment}>Добавить коментарий</Button>
                    <ButtonDropzone setFile={this.setFile} name="Загрузить файл"/>
                    <Button className="btn-warning" onClick={this.dismissSubmit}>Отменить</Button>
                </div>
            </div>
        )
    }
}