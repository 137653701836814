import React from 'react'

import checkError from '../../../utils/checkError'
import { editMaster, getMaster, Master, CreateMaster, editPerson, UpdatePerson } from '@justpro/terminal'
import Spinner from '../../UI/spinner/spinner.controller'
import RenderIf from '../../../utils/renderIf'
import MasterDetail from './master.detail'

type Props = {
	id: number
	afterUpdate?(person: Master): void
}

type State = {
	loading: boolean
	master?: Master
}

export default class PersonController extends React.Component<Props, State> {
	state: State = {
		loading: false,
	}

	getMaster = async () => {
		try {
			this.setState(() => ({ loading: true }))

			const master = await getMaster(this.props.id)

			if (master) {
				this.setState(() => ({ loading: false, master }))
			}
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	updateMaster = async (data: Partial<CreateMaster>) => {
		try {
			this.setState(() => ({ loading: true }))
			const master = await editMaster(this.props.id, data)
			if (master) {
				this.setState({ loading: false, master })
			}
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	updatePerson = async (data: Partial<UpdatePerson>) => {
		try {
			this.setState(() => ({ loading: true }))
			const person = await editPerson(this.state.master.person.id, data)
			if (person) {
				await this.getMaster();
			}
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
	) {
		if (this.props.id !== prevProps.id) {
			this.getMaster()
		}
	}

	async componentDidMount() {
		this.getMaster()
	}

	render() {
		const { master } = this.state

		return (
			<>
				<RenderIf condition={master !== undefined}>
					<MasterDetail
						master={master as Master}
						updateMaster={this.updateMaster}
						updatePerson={this.updatePerson}
						getMaster={this.getMaster}
					/>
				</RenderIf>
				<Spinner loading={this.state.loading} />
			</>
		)
	}
}
