import React from 'react';
import {
    createEquipmentWork,
    EquipmentWork,
    getEquipmentWorks, ModulesResponse
} from "@justpro/terminal";
import {getEquipmentTypesList} from "../../../utils/functions";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {filterParams} from "@justpro/terminal";
import EquipmentWorkCreate from "./equipmentWork.create";
import EquipmentWorkController from "./equipmentWork.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class EquipmentWorks extends React.Component<Props>{

    renderItem = (item:EquipmentWork) => <>{item.name}</>;

    getCheckboxesMap = async () => {

        if(this.props.rights?.['references.equipmentTypes']?.read) {
            const equipmentTypes = await getEquipmentTypesList();

           return  {
                [filterParams.EQUIPMENT_TYPES] : {
                    name : 'Тип оборудования',
                    children : equipmentTypes.map( ({id, name}) => ({id, name}))
                }
            };
        }

       return {}
    };


    render() {
        const rights = this.props.rights && this.props.rights['references.equipmentWorks'];
        return <BaseReferenceController

         Detail={EquipmentWorkController}
         listItem={{
             render : this.renderItem
         }}
         getList={getEquipmentWorks}
         referenceName="equipmentWorks.moduleName"
         filter={{
             getCheckboxesMap : this.getCheckboxesMap,
             filterName : filterNames.EQUIPMENT_WORKS
         }}
         create={{
             Component : EquipmentWorkCreate,
             handler : createEquipmentWork,
             title : "equipmentWorks.addItem"
         }}

         moduleRights={rights}
        />

    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(EquipmentWorks)