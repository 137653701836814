import getFullName from "../../utils/getFullName";
import moment from "moment";
import Button from "../UI/button/button";
import File from "../UI/fileManager/file.preview";
import React from "react";
import {FilesNotification} from "@justpro/terminal";
import {getHistoryBody} from "../UI/moduleHistory/templates/historyItem";
import RenderIf from "../../utils/renderIf";
import getText from "../../localization/getText";

export const FilesList = ({files, onClick, onRead}: any) => (
    <div>
        {files && files?.map((filesNotification: FilesNotification) => (
            <div className="notification-task__wrapper">
                <div className="notification-task__header">
                    <div className="notification-task__user-wrapper">
                        <div className="notification-task__datetime notification-file__datetime">
                            {moment(filesNotification?.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                        </div>
                    </div>
                </div>
                <div className="notification-task__content">
                    <RenderIf condition={!filesNotification.read}>
                        <i className="fa fa-circle notifications__tabs-circle"
                           onClick={onRead.bind(null, filesNotification)}/>
                    </RenderIf>
                    <b>{getText(`common.fileIsReady`)}: </b>
                    <div className="notification-task__title notification-file__title">
                        <span onClick={onClick.bind(null, filesNotification)}>
                            {filesNotification.name}
                        </span>
                    </div>
                </div>
            </div>
        ))}
    </div>
);
