import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {CreateEquipmentCategory} from "@justpro/terminal";
import getText from "../../../localization/getText";


interface Props {
    create(data:CreateEquipmentCategory) : void
}


interface State {
    name: string
    description? : string

}

class EquipmentCategoryCreate extends React.Component<Props, State>{

    state:State = {
        name : "",
    };

    isDisabled = () => this.state.name.trim() === '';

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    changeDescription = (description:string) => {
        this.setState(() => ({description}))
    };

    create = () => {
        if(!this.isDisabled()) {
            this.props.create({
                name : this.state.name,
                description: this.state.description
            })
        }
    };

    render() {


        return (
            <>
                <h4>{getText('equipmentCategories.addingNewHeader')}</h4>
                <Input
                    placeholder="equipmentCategories.equipmentCategory"
                    change={this.changeName}
                    label="equipmentCategories.equipmentCategory"
                />
                <Input
                    placeholder="Описание категории"
                    change={this.changeDescription}
                    label="Описание"
                />

                <Button className="btn-success" disabled={this.isDisabled()} onClick={this.create}>{getText('common.add')}</Button>
            </>
        )
    }
}

export default EquipmentCategoryCreate;