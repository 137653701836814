import React from 'react';
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import HeaderController from "../../header/header.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import {getFullName} from "../../../utils/names";
import {
    createExecutorDistance,
    format,
    getExecutorDistances,
    getExecutorDistancesExport,
    getPersons,
    Person,
    SettingsMap,
    ExecutorDistance
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import './report.css'
import './mileage.css'
import getPersonPhoto from "../../../utils/getPersonPhoto";
import ActsMileageMonth, {Data} from "./actsMileage.month";
import moment, {Moment} from "moment";
import {Angle} from "../../UI/itemsList/card/card.view";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import RenderIf from "../../../utils/renderIf";
import Button from "../../UI/button/button";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import {toast} from "react-toastify";
import Spinner from "../../UI/spinner/spinner.controller";
import FileDownload from "js-file-download";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import MileageModal from "./mileage.modal";

interface Props {
    openModal(props:Modal) : void
    settings?: SettingsMap
}

interface MonthMap {
    [key: number]: Data[]
}

interface State {
    list: Person[]
    selected?: Person
    months?: MonthMap
    centralDate: Moment

    excelLoading: boolean

    distances: ExecutorDistance[]
    loading: boolean

}


const createMonth = (date: Moment, distances: ExecutorDistance[]): Data[] => {
    const result: Data[] = [];

    const startDay = date.clone().startOf('month');
    const endDay = date.clone().endOf('month').subtract(1, 'day');

    const start = startDay.clone().subtract(1, 'day');
    let i = 1;

    while (start.isBefore(endDay)) {
        const value = start.add(1, 'day').clone();
        const strDate = value.format(format.date);

        const d = distances.find(d => d.date === strDate);

        result.push({
            rowId: i,
            date: strDate,
            distance: d ? d.distance.toString() : undefined,
            id: d ? d.id : undefined
        });
        i++;
    }

    return result
};

class ActsMileageController extends React.Component<Props, State> {

    state: State = {
        centralDate: moment(),
        list: [],
        excelLoading: false,
        distances: [],
        loading: false
    };


    renderItem = (executor: Person) => {
        return <div key={executor.id}
                    className={`mileage__list-item ${executor.id === this.state?.selected?.id ? 'active' : ''}`}
                    onClick={this.onPickExecutor.bind(this, executor)}>
            <Angle size={30}/>
            <div className="mileage__list-item__photo">
                <img src={getPersonPhoto(undefined)} alt="Фото пользователя"/>
            </div>
            <div className="mileage__list-item__name">{getFullName(executor)}</div>
        </div>
    };


    getExecutorDistances = async (from: Moment, to: Moment, executorId: number): Promise<ExecutorDistance[]> => {
        try {
            return await getExecutorDistances({
                executorsId: [executorId],
                dateFrom: from.format(format.date),
                dateTo: to.format(format.date),
            });
        } catch (e) {
            checkError(e)
        }

        return [];
    };

    onPickExecutor = async (executor: Person) => {
        try {
            this.setState(() => ({loading: true}));

            const dateFrom = this.state.centralDate.clone().subtract(1, 'month').startOf('month');
            const dateTo = this.state.centralDate.clone().add(1, 'month').endOf('month');
            const distances = await this.getExecutorDistances(dateFrom, dateTo, executor.id);
            this.setState(() => ({distances, centralDate: moment()}))

        } catch (e) {
            checkError(e)
        } finally {
            this.setState(() => ({
                loading: false,
                selected: executor
            }), this.createMonths);
        }
    };

    onChangeDistance = (rowId: number, distance: string, date: string, id?: number): Promise<boolean> => {
        console.log({date, distance, rowId});
        return new Promise(async () => {

            const numeric = parseFloat(distance) || null;

            if (this.state.selected) {

                try {
                    const result = await createExecutorDistance({
                        date,
                        distance: numeric as unknown as number,
                        executorId: this.state.selected.id
                    });

                    const m = moment(date).get('month');

                    await this.setState((prevState: any) => {

                        return {
                            months: {
                                ...prevState.months,
                                [m]: prevState.months?.[m]?.map(item => {
                                    if (item.rowId === rowId) {
                                        return {
                                            ...item,
                                            distance: result.distance,
                                        }
                                    }
                                    return item
                                }) || []
                            }
                        }
                    });

                    return true
                } catch (e) {
                    checkError(e);
                }
            } else {
                checkError(new Error('errors.valueMustBeANumber'));
            }
            return false

        });
    };

    goPrev = async () => {
        try {
            if (this.state.selected) {
                const distances = await this.getExecutorDistances(
                    this.state.centralDate.clone().subtract(2, 'month').startOf('month'),
                    this.state.centralDate.clone().subtract(2, 'month').endOf('month'),
                    this.state.selected.id
                );

                this.setState((prevState) => ({
                    centralDate: prevState.centralDate.subtract(1, 'month'),
                    distances: [...prevState.distances, ...distances]
                }), this.createMonths);
            }
        } catch (e) {
            checkError(e)
        }
    };

    goNext = async () => {
        try {
            if (this.state.selected) {
                const distances = await this.getExecutorDistances(
                    this.state.centralDate.clone().add(2, 'month').startOf('month'),
                    this.state.centralDate.clone().add(2, 'month').endOf('month'),
                    this.state.selected.id
                );

                this.setState((prevState) => ({
                    centralDate: prevState.centralDate.add(1, 'month'),
                    distances: [...prevState.distances, ...distances]
                }), this.createMonths);
            }
        } catch (e) {
            checkError(e)
        }
    };


    createMonths = () => {
        const {centralDate} = this.state;

        const now = this.getMonth(centralDate);
        const prev = this.getMonth(centralDate.clone().subtract(1, 'month'));
        const next = this.getMonth(centralDate.clone().add(1, 'month'));

        this.setState((prevState) => ({
            months: {
                ...prevState.months,
                [centralDate.get('month')]: now,
                [this.getValidMonthNumber(centralDate.get('month') - 1)]: prev,
                [this.getValidMonthNumber(centralDate.get('month') + 1)]: next,
            }
        }))

    };


    getMonth = (date: Moment): Data[] => {
        const {distances} = this.state;

        const filtered = distances.filter(i => moment(i.date).isSame(date, 'month'));

        return createMonth(date, filtered);
    };

    getExecutorsList = async () => {

        if (this.props.settings) {
            try {
                if(!(this?.props?.settings?.tfmContractorId && this?.props?.settings?.tfmShidContractorId)){
                    return;
                }
                const persons = await getPersons({
                    executorsId: [+this.props.settings.tfmContractorId, +this.props.settings.tfmShidContractorId],
                });

                this.setState(() => ({
                    list: persons?.sort((p1, p2) => p1?.lastName?.localeCompare(p2?.lastName))
                }))

            } catch (e) {
                checkError(e)
            }
        }
    };

    openExportModal = () => {
        this.props.openModal({
            id : 'actsMileageExport',
            component : (props:ModalBodyProps) => <MileageModal {...props}/>,
            title : getText("acts.periodOfExport"),
            minHeight : "60vh"
        })
    };


    getValidMonthNumber = (value: number) => value > 11 ? 0 : value < 0 ? 11 : value;

    async componentDidMount() {
        this.getExecutorsList();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.getExecutorsList();
        }
    }



    render() {
        const header = this.state.selected ? `${renderToString(getText('acts.mileAgeByExecutor'))}${getFullName(this.state.selected)}` : getText('common.chooseExecutor');

        const prev = this.state?.months?.[this.getValidMonthNumber(this.state.centralDate.get('month') - 1)] || [];
        const now = this.state?.months?.[this.state.centralDate.get('month')] || [];
        const next = this.state?.months?.[this.getValidMonthNumber(this.state.centralDate.get('month') + 1)] || [];

        return (
            <WithPrivateRoute>
                <HeaderController>
                    <ModuleTextName>{header}</ModuleTextName>

                    <div className='navbar-form navbar-left buttons'>

                        <Tooltip position="down" title="common.excelExport">
                            <RenderIf condition={!this.state.excelLoading}>
                                <Button
                                    onClick={this.openExportModal}
                                    className="btn-default"
                                >
                                    <i className="fa fa-file-excel"/>
                                </Button>
                            </RenderIf>
                            <RenderIf condition={this.state.excelLoading}>
                                {getText('common.generating')}
                            </RenderIf>
                        </Tooltip>
                    </div>
                </HeaderController>

                <div className="just-pro_module mileage">
                    <div className="panel"/>
                    <div className="panel"/>
                    <div className="panel content-panel">
                        {this.state.list?.map(this.renderItem)}
                    </div>

                    <div className="panel content-panel">
                        {this.state.selected && !this.state.loading &&
                        <div className="month-columns">
                            <ActsMileageMonth
                                data={prev}
                                onChangeDistance={this.onChangeDistance}
                                arrow={{position : "left", handler : this.goPrev}}
                            />
                            <ActsMileageMonth
                                data={now}
                                onChangeDistance={this.onChangeDistance}
                            />
                            <ActsMileageMonth
                                data={next}
                                onChangeDistance={this.onChangeDistance}
                                arrow={{position : "right", handler : this.goNext}}
                            />
                        </div>
                        }

                        <Spinner loading={this.state.loading}/>
                    </div>
                </div>
            </WithPrivateRoute>
        )
    }
}

const mapState = (state: ApplicationMapState) => ({
    settings: state.application.settings
});

const mapDispatch = (dispatch:Function) => ({
    openModal: (props:Modal) => dispatch(openModal(props))
})

export default connect(mapState,mapDispatch)(ActsMileageController)