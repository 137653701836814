import React from "react";
import TabContentHeader from "./tabContentHeader";
import './tabsContent.css'
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import withLabel from "../../UI/withLabel/withLabel";
import checkError from "../../../utils/checkError";
import {toast} from "react-toastify";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";


interface Props {
    onChange(password: string): any

    onVerify(password: string): Promise<boolean>
}

interface State {
    oldPassword: string
    newPassword: string
    verifyNewPassword: string

    touched: boolean
    verified: boolean
}

class Security extends React.Component<Props, State> {

    state: State = this.defaultState

    get defaultState() {
        return {
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
            touched: false,
            verified: false,
        }
    }

    onChangeHandler = (option: Option) => {
        return new Promise(resolve => {
            resolve();
        })
    };

    onChangeOldPassword = (oldPassword: string) => {
        this.setState(() => ({oldPassword}))
    };

    onChangeNewPassword = (newPassword: string) => {
        this.setState(() => ({newPassword}))
    };

    onChangeVerifyNewPassword = (verifyNewPassword: string) => {
        this.setState(() => ({verifyNewPassword}))
    };

    isDisabledCheckBtn = () => {
        return !(this.state.oldPassword.trim().length > 0);
    };

    isDisabledChangeBtn = () => {
        const {verifyNewPassword, newPassword, verified} = this.state;
        return !((verifyNewPassword.trim() !== '' && newPassword.trim() !== '') && verifyNewPassword === newPassword && verified)
    };


    verifyPassword = async () => {
        try {
            this.setState({
                touched: true,
                verified: await this.props.onVerify(this.state.oldPassword)
            })
        } catch (e) {
            checkError(e)
        }
    }

    changePassword = async () => {
        if(!(this.state.newPassword === this.state.verifyNewPassword)){
            return toast.warn(renderToString(getText('settings.enteredPasswordsIsNotEqual')));
        }
        this.props.onChange(this.state.newPassword);
        this.setState(this.defaultState);
    };

    render() {

        return (
            <div className="settings-tab-content">
                <TabContentHeader title="UI.tabs.security"/>

                <div>

                    {withLabel(
                        <div className="just-pro__row-items">
                            <Input change={this.onChangeOldPassword} placeholder="settings.oldPassword"
                                   value={this.state.oldPassword}/>
                            <Button className="btn-success" onClick={this.verifyPassword}
                                    disabled={this.isDisabledCheckBtn()}>{getText('settings.check')}</Button>
                        </div>
                    )({text: 'settings.oldPassword'})}

                    {this.state.touched &&
                    <span className="security__verify-field">
                            {this.state.verified ?
                                <span className="security__verify-field--success">{getText('settings.enterNewPassword')}</span> :
                                <span className="security__verify-field--error">{getText('settings.passwordIsWrong')}</span>}
                        </span>
                    }

                    <Input
                        disabled={!this.state.verified}
                        change={this.onChangeNewPassword}
                        label="settings.newPassword"
                        placeholder="settings.newPassword"
                        value={this.state.newPassword}
                    />
                    <Input
                        disabled={!this.state.verified}
                        change={this.onChangeVerifyNewPassword}
                        placeholder="settings.verifyNewPassword"
                        label="settings.verifyNewPassword"
                        value={this.state.verifyNewPassword}
                    />
                    <Button className="btn-success" onClick={this.changePassword}
                            disabled={this.isDisabledChangeBtn()}>{getText('common.change')}</Button>

                </div>
            </div>
        )
    }
}

export default Security