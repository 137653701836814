import React from 'react';
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getEquipmentCategoriesList} from '../../../utils/functions'
import {EditEquipmentType, EquipmentType, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

type Props = {
    equipmentType : EquipmentType
    update(data:Partial<EditEquipmentType>) : void
    rights?: Partial<ModulesResponse>
}

class EquipmentTypeDetail extends React.Component<Props> {


    blurName = async (name: string, oldValue: string ) => {
        if(name !== oldValue) {
            this.props.update({name});
        }
    };

    blurDescription = async (description: string, oldValue: string) => {
        if(description !== oldValue) {
            this.props.update({description})
        }
    };

    changeCategory = (option:Option) => {
        this.props.update({equipmentCategoryId : option.value});
        return Promise.resolve();
    };

    toggleActiveEquipmentType = async (active:boolean) => {
        this.props.update({active})
    };

    render () {
        const {rights, equipmentType} = this.props;
        const {name, active, description, equipmentCategory} = equipmentType;
        const disabled = rights && rights['references.equipmentTypes'] && !rights['references.equipmentTypes']['edit'];
        return (
            <>
                <h3>{getText('equipmentTypes.equipmentType')}: {name}</h3>

                <ToggleSwitch send={this.toggleActiveEquipmentType} defaultValue={active} label="common.active" disabled={disabled}/>

                <Input
                    label="equipmentTypes.equipmentType"
                    blur={this.blurName}
                    placeholder="equipmentTypes.equipmentType"
                    startValue={name}
                    disabled={disabled}
                />
                <Select
                    change={this.changeCategory}
                    load={getEquipmentCategoriesList}
                    label="equipmentCategories.equipmentCategory"
                    defaultValue={equipmentCategory}
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    isDisabled={disabled}
                />
                <Input
                    label="common.description"
                    blur={this.blurDescription}
                    placeholder="common.description"
                    startValue={description}
                    disabled={disabled}
                />
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(EquipmentTypeDetail)