import React from 'react';
import ModuleListItem from "../../UI/itemsList/moduleListItem/moduleListItem.controller";
import ListIcons, {ListIconProps} from "../../UI/itemsList/icons/icons.controller";
import {IAct} from "@justpro/terminal";
import moment from "moment";
import {Angle} from "../../UI/itemsList/card/card.view";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import WithModuleDetail from "../../UI/dialog/withModuleDetail/withModuleDetail.controller";
import ActController from "./act.controller";
import {getObjectName} from "../../../utils/names";
import RenderIf from "../../../utils/renderIf";
import getFullName from "../../../utils/getFullName";
import getText from "../../../localization/getText";


interface Props {
    act: IAct

    clickHandler?(actId: number): void

    isActive?: boolean
    withArrow?: {
        icon: string
        handler(act: IAct): void
        title: string
    }
    withAngle?: {
        color?: string
    }
    withDelete?: {
        handler(act: IAct): void
        title: string
    }
    actScanCount?: number
    withModuleDetail?: {}
}

const getActIcons = (act: IAct): ListIconProps => {

    const icons: Partial<ListIconProps> = {
        filesCount: act.scans && act.scans.length > 0 ? '1' : undefined
    };
    icons.contractConditions = act.contractConditions;
    icons.highpriced = act.highpriced;
    icons.calculating = act.calculating;
    icons.checked = act.checked;
    icons.tags = [...act.tags, act.closeType];
    icons.isRegularWorksThisDay = act.isRegularWorksThisDay;
    return icons;
};

const ActListItem = ({isActive, clickHandler, act, withAngle, withArrow, withDelete, actScanCount, withModuleDetail}: Props) => {

    const onActClick = () => {
        clickHandler && clickHandler(act.id)
    };

    return (
        <ModuleListItem
            onClick={onActClick}
            isActive={isActive}
        >
            <div className="body">
                {withAngle && <Angle size={40} color={withAngle.color}/>}


                <div className="icon-zone">
                    <ListIcons
                        {...getActIcons(act)}
                        actScanCount={actScanCount}
                    />
                </div>

                <div className="content-text">
                    <p className="list-group-item-text"><b>{getObjectName(act.object)}</b></p>
                    <p className="list-group-item-text">{getFullName(act?.responsible, false)}</p>
                    <RenderIf condition={act?.handler}>
                        <p className="list-group-item-text">
                            <b>{act?.handler && getFullName(act?.handler, false)} </b>
                        </p>
                    </RenderIf>
                </div>
            </div>


            <div className="helpers">
                {withArrow !== undefined && (
                    <Tooltip title={withArrow.title} position="left">
                        <div className="arrow" onClick={withArrow.handler.bind(null, act)}>
                            <i className={withArrow.icon}/>
                        </div>
                    </Tooltip>

                )}

                {withDelete !== undefined && (
                    <Tooltip title={withDelete.title} position="left">
                        <div onClick={withDelete.handler.bind(null, act)}>
                            <i className="fa fa-times"/>
                        </div>
                    </Tooltip>
                )}

                {!!withModuleDetail && (
                    <div>
                        <WithModuleDetail
                            element={
                                <>
                                    <Tooltip position="left" title="common.openDetailInfo">
                                        <i className="fa fa-expand expand"/>
                                    </Tooltip>
                                </>
                            }
                            modal={{
                                id : `act-${act.id}`,
                                component : () => (
                                    <ActController actId={act.id}/>
                                ),
                                title : getText('acts.act'),
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="number">
                <b>{act.number}</b>
                <b>{moment(act.date).format('DD.MM.YYYY')}</b>
            </div>


        </ModuleListItem>
    )
};

export default ActListItem;