import React from "react";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {createWaitRequest, getWaitRequests, ModulesResponse, WaitRequest} from "@justpro/terminal";
import WaitRequestController from "./waitRequest.controller";
import WaitRequestCreate from "./waitRequest.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";


interface Props {
    rights?: Partial<ModulesResponse>
}

class WaitRequestsController extends React.Component<Props>{


    renderItem = (item:WaitRequest) => <>{item.name}</>;

    render () {
        const rights = this.props.rights && this.props.rights['references.waitRequests'];
        return <BaseReferenceController
            listItem={{render : this.renderItem}}
            Detail={WaitRequestController}
            create={{
                Component : WaitRequestCreate,
                title : 'waitRequests.addItem',
                handler : createWaitRequest
            }}
            referenceName="waitRequests.moduleName"
            getList={getWaitRequests}
            moduleRights={rights}

        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});
export default connect(mapState)(WaitRequestsController)