import React from "react";
import {
    IPhase,
    ACT_IN_PROCESSING,
    ACT_ON_AGREEMENT,
    ACT_AGREED,
    ACT_UPLOADED_PAGES,
    ACT_CREATED, Person, IUpdateAct, SettingsMap,
} from "@justpro/terminal";
import {getPersonsList} from "../../../utils/functions";
import {Option} from "../../UI/select/select.types";
import Select from "../../UI/select/select";
import withLabel from "../../UI/withLabel/withLabel";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import {ApplicationMapState} from "../../application/application.controller";

interface Props {
    actId : number,
    phase : IPhase
    // person? : Person
    handler?: Person
    updateAct(actId:number, data:Partial<IUpdateAct>): void

    settings? : SettingsMap
    disabled?: boolean
}

class ActPhase extends React.Component<Props>{


    changeHandler = async (option:Option) => {


        this.props.updateAct(this.props.actId, {
            handlerId : option.value
        });

        return Promise.resolve();
    };



    render() {
        const {phase, handler, settings} = this.props;


        const phaseAction = phase.id === +settings?.createdPhaseId ? ACT_CREATED :
                            phase.id === +settings?.scansUploadedPhaseId ? ACT_UPLOADED_PAGES :
                            phase.id === +settings?.processingPhaseId ? ACT_IN_PROCESSING :
                            phase.id === +settings?.approvingPhaseId ? ACT_ON_AGREEMENT :
                            phase.id === +settings?.approvedPhaseId ? ACT_AGREED : "";

        const label = phaseAction === ACT_UPLOADED_PAGES ? "acts.toAppoint" :
                      phaseAction === ACT_IN_PROCESSING ? "common.inProcessingBy" : "";

        const _handler = {
            name : `${handler?.lastName} ${handler?.firstName} ${handler?.patronymic}`,
            id : handler?.id
        };

        const DEFAULT_HANDLER = {
            name : getText('common.unselected'),
            id : -1
        };


        return (
            <>
                {phaseAction === ACT_CREATED &&
                    <></>
                }

                { (phaseAction === ACT_UPLOADED_PAGES || phaseAction === ACT_IN_PROCESSING) &&
                    <>
                        <Select
                            disabled={this.props.disabled}
                            change={this.changeHandler}
                            label={label}
                            placeholder="UI.select.placeholders.chooseAnEmployee"
                            defaultValue={handler ? _handler : DEFAULT_HANDLER}
                            load={getPersonsList}
                            accessors={{
                                name : 'label',
                                id : 'value'
                            }}
                        />
                    </>
                }

                { (phaseAction === ACT_ON_AGREEMENT || phaseAction === ACT_AGREED) &&
                    <>
                        {withLabel(
                            <>{handler?.lastName} {handler?.firstName} {handler?.patronymic}</>
                        )({position : 'left', text : 'acts.handled'})}
                    </>
                }

            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    settings : state.application.settings
});

export default connect(mapState)(ActPhase)