import {OrdersReducer, initialState} from "./orders.types";
import {AnyAction} from "redux";
import {OrderListItem} from "@justpro/terminal";

export const orderActions = {
    GET_ORDERS : 'GET_ORDERS',
    UPDATE_ORDER_LIST : 'UPDATE_ORDER_LIST',
    CREATE_ORDER : 'CREATE_ORDER'

};

export default (state:OrdersReducer = initialState, action:AnyAction):OrdersReducer => {
    const {type, ...rest} = action;
    switch (type) {
        case orderActions.UPDATE_ORDER_LIST :
            return {
                ...state,
                list : state.list ? state.list.map(order => {
                    if(order.id === rest.payload.orderId) {
                        const data = rest.payload.data;
                        const result:Partial<OrderListItem> = {};
                        //+
                        if(data.hasOwnProperty('photos')) {
                            result.photosCount = data.photos.length.toString()
                        }
                        //+
                        if(data.hasOwnProperty('files')) {
                            result.filesCount = data.files.length.toString()
                        }
                        //+
                        if(data.hasOwnProperty('offers')) {
                            result.offersCount = data.offers?.length.toString()
                        }
                        //+
                        if(data.hasOwnProperty('subordersCount')) {
                            result.subordersCount = (+order.subordersCount + data.subordersCount).toString()
                        }

                        if(data.hasOwnProperty('highpriced')) {
                            result.highpriced = data.highpriced
                        }
                        //warranty
                        if(data.hasOwnProperty('warranty')) {
                            result.warranty = data.warranty
                        }

                        if(data.hasOwnProperty('object')) {
                            result.object = data.object
                        }

                        if(data.hasOwnProperty('executionTime')) {
                            result.executionTime = data.executionTime
                        }
                        //+
                        if(data.hasOwnProperty('waitRequests')) {
                            result.waitRequests = [...order.waitRequests, ...data.waitRequests]
                        }
                        //+
                        if(data.hasOwnProperty('contractOrdersType')) {
                            result.contractOrdersType = data.contractOrdersType
                        }
                        //+
                        if(data.hasOwnProperty('tags')) {
                            result.tags = data.tags
                        }
                        //+
                        if(data.hasOwnProperty('contractConditions')) {
                            result.contractConditions = data.contractConditions
                        }
                        //+
                        if(data.hasOwnProperty('additionalInfo')) {
                            result.additionalInfo = data.additionalInfo
                        }
                        //+
                        if(data.hasOwnProperty('content')) {
                            result.content = data.content
                        }
                        //+
                        if(data.hasOwnProperty('contactInfo')) {
                            result.contactInfo = data.contactInfo
                        }
                        //+
                        if(data.hasOwnProperty('orderStatus')) {
                            result.orderStatus = data.orderStatus
                        }

                        return {
                            ...order,
                            ...result,
                        }

                    }

                    return order
                }) : undefined
            };
        case orderActions.GET_ORDERS :
            const {payload} = rest;
            let orders = [];
            if (payload.action === 'get') {
                orders = payload.list;
            }else if (payload.action === 'update') {
                orders = [...state.list, ...payload.list];
            }
            return {
                ...state,
                count : +payload.count,
                haveNewComments : payload.haveNewComments,
                list : orders
            };
        case orderActions.CREATE_ORDER :

            console.log({rest})

            return {
                ...state,
                list : state.list ? [rest.payload.order, ...state.list] : [rest.order],
                count : state.count ? state.count + 1 : 1
            };
    }

    return state
}