import React from "react";
import {getPointsJSX} from "../GPS";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";
import getText from "../../../../localization/getText";


interface Props {
    save(latitude:string, longitude:string, radius:string) : void
    hide() : void

}

interface State {
    latitude : string,
    longitude : string
    radius : string
}

class AddGPSCoordinates extends React.Component<Props, State> {

    state:State = {
        latitude : '',
        longitude : '',
        radius : '',

    };

    onChangeLatitude = async (event:React.FormEvent<HTMLInputElement>) => {
        const latitude = event.currentTarget.value;


        await this.setState(() => ({
            latitude
        }));
    };

    onChangeLongitude = async (event:React.FormEvent<HTMLInputElement>) => {
        const longitude = event.currentTarget.value;

        await this.setState(() => ({
            longitude
        }));

    };

    onChangeRadius = async (event:React.FormEvent<HTMLInputElement>) => {
        const radius = event.currentTarget.value;

        await this.setState(() => ({
            radius
        }));

    };

    isDisabled = () => {
        const {longitude, latitude, radius} = this.state;

        return  latitude.trim() === '' ||
                longitude.trim() === '' ||
                radius.trim() === ''
    };

    save = () => {
        if(!this.isDisabled()) {
            this.props.save(this.state.latitude, this.state.longitude, this.state.radius)
            this.props.hide();
        }
    };

    render() {

        return (
            <>
                {getPointsJSX({
                        [-1] : {
                            latitude : '',
                            longitude : '',
                            radius : ''
                        }
                    },
                    this.onChangeLatitude,
                    this.onChangeLongitude,
                    this.onChangeRadius
                )}

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.save} disabled={this.isDisabled()}>{getText('common.create')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}

export default AddGPSCoordinates
