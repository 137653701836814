import React from "react";
import {CreateContractCondition} from "@justpro/terminal";
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {fromClassName, getIconsList} from "../../../utils/icons";
import IconPicker from "../../UI/iconPiker/iconPicker.controller";
import getText from "../../../localization/getText";

const icons = getIconsList();

interface Props {
    create(data:CreateContractCondition) : void
}

interface State {
    name : string
    icon? : string
}

class ContractConditionCreate extends React.Component<Props, State>{
    state:State = {
        name : ''
    };

    onChangeName = (name : string) => {
        this.setState(() => ({name}))
    };

    isDisabled = () => this.state.name.trim() === '';

    create = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name, icon : this.state.icon})
        }
    };

    changeIcon = (icon:string) => {
        const [prefix, name] = fromClassName(icon);
        this.setState(() => ({icon : `${prefix}:${name}`}))
    };

    render() {
        return (
            <>
                <h3>{getText('contractConditions.contractCondition')}</h3>

                <Input
                    label="contractConditions.contractCondition"
                    placeholder="contractConditions.contractCondition"
                    change={this.onChangeName}
                />

                <IconPicker
                    change={this.changeIcon}
                    label="common.icon"
                    list={icons}
                />

                <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

export default ContractConditionCreate