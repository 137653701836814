import React from "react";
import Input from "../UI/input/text";
import {signIn} from '../../routing/utils'
import Button from "../UI/button/button";
import {Props, State} from "./sign.types";
import {getAuthToken} from "@justpro/terminal";
import moment from 'moment'
import 'moment/locale/ru'
import './sign.css';
import Spinner from '../UI/spinner/spinner.controller'
import checkError from "../../utils/checkError";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";

moment.locale('ru');

export default class Sign extends React.Component<Props, State> {
    interval: any = 0;
    state = {
        password: '',
        login: '',
        date: moment(),
        loading : false
    };

    isDisabled = ():boolean => {
        return !(this.state.login.trim().length > 0 && this.state.password.trim().length > 0)
    };

    clockTick = () => {
        this.setState({
            date : moment()
        })
    };

    onChangePassword = (val:string) => {
        this.setState(() => ({
            password : val,
        }))
    };

    onChangeLogin = (val:string) => {
        this.setState(() => ({
            login : val,
        }))
    };

    submit = async () => {
        this.setState({
            loading : true
        });
        // console.log({
        //     login : this.state.loading,
        //     password : this.state.password,
        // })
        try {

            await getAuthToken({
                username: this.state.login,
                password: this.state.password
            });
            signIn();
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading : false
            });
        }
    };

    componentDidMount() {
        this.interval = setInterval(this.clockTick, 1000)
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    render() {
        const {date} = this.state;

        const weekDay = date.format('dddd');
        const upWeekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);

        return (
            <React.Fragment>
                <div className="just-pro__sign">
                    <div className="just-pro__sign_time">
                        <p className='just-pro__sign_clock'>{date.format('HH:mm:ss')}</p>
                        <p className='just-pro__sign_day'>{upWeekDay}</p>
                    </div>
                    <div>

                        <div className="just-pro__sign__logo">
                            <img src="/images/auth_logo.png" alt={renderToString(getText('common.photo'))}/>
                        </div>

                        <div className="just-pro__sign__auth">
                            <p className="just-pro__sign_text">{getText('sign.auth')}</p>
                            <form>
                                <div className="form-group">
                                    <Input
                                        label="sign.login"

                                        className="form-control"
                                        change={this.onChangeLogin}
                                        isDisabledButton={this.isDisabled()}
                                        onSend={this.submit}
                                        // onKeyPress={this.onKeyPress}
                                        placeholder="sign.enterLogin"
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        label="sign.password"
                                        type="password"
                                        className="form-control"
                                        isDisabledButton={this.isDisabled()}
                                        onSend={this.submit}
                                        // onKeyPress={this.onKeyPress}
                                        change={this.onChangePassword}
                                        placeholder="sign.enterPassword"/>
                                </div>
                            </form>
                            <div className="auth-button">
                                <Button
                                    type="submit"
                                    onClick={this.submit}
                                    className="btn-success"
                                    disabled={this.isDisabled()}
                                >
                                    {getText('sign.enter')}
                                </Button>
                            </div>

                        </div>
                    </div>

                    <div className="just-pro__sign__footer">
                        <h4>&copy;{getText('footer.companyName')}, 2009&mdash;{date.format('YYYY')}</h4>
                        <h5>&copy;{getText('footer.serviceDesk')}</h5>
                    </div>

                    <Spinner loading={this.state.loading}/>
                </div>
    </React.Fragment>
        )
    }

}
