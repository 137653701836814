import React, {useCallback, useState} from 'react';
import {Wrapper, IconBox, Icon,TopPanel, Icons} from './colorPicker.view'
import withLabel from "../withLabel/withLabel";
import HideHelper from '../hidePortal/hidePortal.controller'

interface Props {
    list : string[],
    defaultValue? : string
    label? : string
    disabled? : boolean
    change(icon:string) : void
    closeOnSelect?(): void
}

export default (props:Props) => {

    const [value, setValue] = useState('');
    const [showing, setShowing] = useState(50);
    const [isOpen, setIsOpen] = useState(false);
    const {defaultValue, list, label, closeOnSelect = true, change} = props;


    const disabled = props.disabled || false



    const onScrollHandler = (e:React.UIEvent<HTMLDivElement>) => {
        const {scrollHeight, clientHeight, scrollTop} = e.currentTarget;
        const unseenHeight = scrollHeight - clientHeight;

        if(unseenHeight - scrollTop < 50) {
            setShowing(showing + 50);
            if(showing <= list.length) {
                e.currentTarget.scrollTo({top : unseenHeight - 400})

            }

        }
    };

    const changeIcon = (icon:string) => {
        setValue(icon);
        change(icon);
        if(closeOnSelect) {
            setIsOpen(false)
        }

    };

    const ref = useCallback(() => {
        setValue(defaultValue ? defaultValue.toString() : '')
    }, [defaultValue]);


    const clickHandler = () => {
        if(!disabled) {
            setIsOpen((prev) => !prev)
        }
    };


    const getIconsList = () => {
        return list.reduce((result, item, index) => {
            if(index <= showing) {
                return (
                    <>
                        {result}
                        <IconBox
                            onClick={changeIcon.bind(null, item)}
                        > <i className={item}/> </IconBox>
                    </>
                )
            }else{
                return result;
            }
        }, <></>)
    };
    const jsx =(
        <>
            <div onClick={clickHandler}>
                <Icon
                    className={`${value} ${disabled ? 'disabled' : ''}`}
                    ref={ref}
                />
                {value.trim() === '' && (
                    <p>Выбрать иконку</p>
                )}
            </div>

            {isOpen && (
                <Wrapper>
                    <TopPanel>
                        <div className="close" onClick={setIsOpen.bind(null, false)}>x</div>
                    </TopPanel>
                    <Icons onScroll={onScrollHandler}>
                        <>
                            {getIconsList()}
                        </>
                    </Icons>
                    <HideHelper hide={setIsOpen.bind(null, false)}/>
                </Wrapper>
            )}
        </>

    )

    return label ? withLabel(jsx)({text : label}) : jsx
}

