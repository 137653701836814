import * as api from '@justpro/terminal';
import checkError from "../../../../utils/checkError";
import {userTypes} from "../../user.reducer";
import {rolesTypes} from "../../roles.reducer";
import {groupTypes} from "../../groups.reducer";
import {usersTypes} from "../../users.reducer";

export const getUserDetail = (id:number) => async (dispatch: Function) => {
    try {
        const user = await api.getUser(id);
        const groupsMap = await api.getGroups();
        const rolesMap = await api.getRoles();

        dispatch({
            type : rolesTypes.UPDATE_ROLES_MAP,
            map : rolesMap
        });
        dispatch({
            type : groupTypes.UPDATE_GROUP_MAP,
            map : groupsMap
        });

        dispatch({
            type : userTypes.GET_USER,
            ...user,
        });

        dispatch({
            type : usersTypes.CHANGE_USERS_PANELS,
            detail : 'user'
        })
    }catch (e) {
        checkError(e)
    }
};