import React from 'react';
import './createOrderModal.view.css'
import {Props} from './createOrderModal.types'
import ContractorDetail from "./details/contractor.controller";
import RegionModalDetail from "./details/regions.controller";
import ObjectsModalDetail from "./details/objects.controller";
import OrdersTypeModalDetail from "./details/ordersTypes.controller";
import DetailModalOrder from "./details/detail.controller";
import {ChangeMultiProps, LoadReturn, Option} from "../../select/select.types";
import checkError from "../../../../utils/checkError";
import {
    Contractor,
    getObject,
    Region,
    CreateOrder as ICreateOrder,
    Contract, ContractOrdersType, OrdersFilterParams,
    ContractEquipmentCategory, IExecutedTime,
    createOrder, ContractObject, ContractsFilterParams, getUsersContracts
} from "@justpro/terminal";
import EquipmentCategories from "./details/equipmentCategories";
import {getOrders} from "../../../../store/orders/orders.actions";
import {connect} from "react-redux";
import {get} from "../../../../utils/filterCheckboxes";
import {filterNames} from "../../checkboxList/checkboxes.const";
import Spinner from "../../spinner/spinner.controller";
import AsyncSelect from "../../select/asyncSelect";
import {groupContractObjectsByRegion} from "../../../../utils/selectGroups";
import {getObjectName} from "../../../../utils/names";
import getText from "../../../../localization/getText";

type ContractAccessor = 'objects' | 'equipmentCategories' | 'ordersTypes' | 'contractConditions'

interface State {
    step : number
    contractor : Contractor | null
    region : Region | null
    object : ContractObject | null
    contracts : Contract[]
    allContracts : Contract[]
    initiatorId? : number

    contractOrdersType?: ContractOrdersType
    equipmentCategory?: ContractEquipmentCategory
    content?: string,
    additionalInfo?: string,
    contactInfo?: string,
    contractConditionsId?: number[],
    executionTime?: IExecutedTime[],

    dueDateCustomer?: string

    isSentRequest : boolean
    loading : boolean
}

let CONTRACTS_CACHE = [];
class CreateOrder extends React.Component<Props, State>{

    state:State = {
        step : 1,
        contractor : null,
        region : null,
        object : null,
        contracts : [],
        isSentRequest : false,
        loading : false,
        allContracts : []
    };

    isDisabled = (prevState?:State):boolean => {
        const {initiatorId, region, object, equipmentCategory, contractOrdersType, isSentRequest} = prevState ? prevState : this.state ;

        return initiatorId === undefined ||
                region === null ||
                object === null ||
                equipmentCategory === undefined ||
                contractOrdersType === undefined ||
                isSentRequest;
    };

    createOrder = async () => {
        const {
            contractor,
            contractOrdersType,
            region,
            object,
            contracts,
            equipmentCategory,
            contactInfo,
            content,
            additionalInfo,
            contractConditionsId,
            executionTime,
            dueDateCustomer,
            initiatorId,
        } = this.state;


        if(contractor && region && (object && object.objectId) && contractOrdersType && equipmentCategory) {
            const saveData:ICreateOrder = {
                contractorId : contracts[0].contractor.id,
                contractId : contracts[0].id,
                contractOrdersTypeId : contractOrdersType.id, // id связи
                //@ts-ignore
                objectId : object.objectId,
                equipmentCategoryId : equipmentCategory.equipmentCategoryId,
                // initiatorId : 1,
                contactInfo,
                content,
                additionalInfo,
                executionTime,
                dueDateCustomer,
                contractConditionsId,
                initiatorId : initiatorId as number
            };


            try {

                this.setState(() => ({loading : true, isSentRequest : true}));

                const order = await createOrder(saveData);
                this.props.getOrders({
                    ...get(filterNames.ORDERS)
                }, 'get');

                this.setState(() => ({loading : false}));
                this.props.hide();

            }catch (e) {
                checkError(e)
            }

        }
    };

    changeInitiator = (option:Option) => {
        return new Promise(resolve => {
            this.setState(() => ({
                initiatorId : option.value
            }));
            resolve()
        })
    };

    changeContent = async (content:string) => {
        await this.setState(() => ({content}));
    };

    changeAdditionalInfo = (additionalInfo:string) => {
        this.setState(() => ({additionalInfo}));
    };

    changeContactInfo = (contactInfo:string) => {
        this.setState(() => ({contactInfo}));
    };

    onChangeContractCondition = ({value, type}:ChangeMultiProps):Promise<boolean> => {
        switch (type) {
            case "select-option" :
                this.setState(({contractConditionsId}) => ({
                    contractConditionsId : contractConditionsId ? [...contractConditionsId, value.value] : [value.value]
                }));
                break;
            case "remove-value" :
                this.setState(({contractConditionsId}) => ({
                    contractConditionsId : contractConditionsId?.filter(cc => cc !== value.value)
                }));
                break;
        }


        return Promise.resolve(true);
    };

    onChangeExecutionTime = (executionTime:IExecutedTime[]) => {
        this.setState(() => ({executionTime}))
    };
    
    onChangeExecutionDate = (dueDateCustomer: any) => {
        this.setState(() => ({dueDateCustomer}))
    };


    getActiveContracts = async (filters:Partial<ContractsFilterParams> = {q : ''}):Promise<Contract[]> => {
        try {
            const {allContracts} = this.state;
            const {contractor} = this.state;


            const result = allContracts?.reduce((acc:Contract[], item) => {
                if(item.active) {
                    if(contractor ) {
                        if(item.contractor.id === contractor.id) {
                            acc.push(item)
                        }
                    }else{
                        acc.push(item)
                    }
                }
                return acc;
            }, []) || [];

            console.log({result});

            return result
        }catch (e) {
            checkError(e);
            return []
        }

    };

    changeStep = async (step:number) => {
        const {contractor, contracts:stateContracts, region, object, equipmentCategory, contractOrdersType} = this.state;
        let contracts:Contract[] = [];


        const cutOffContract = function (accessor:ContractAccessor, existId:number):Contract[] {
            return stateContracts.reduce((acc:Contract[], contract) => {
                let itemInContract = false;

                if(contract.hasOwnProperty(accessor) && Array.isArray(contract[accessor])) {
                    for (let i = 0; i < contract[accessor].length; i++) {
                        let item = contract[accessor][i];

                        if(existId === item.id) {
                            itemInContract = true;
                            break;
                        }
                    }
                }

                if(itemInContract){
                    return [...acc, contract]
                }else{
                    return acc
                }
            }, [])
        };

        this.setState(() => ({loading : true}));


        if (step === 1) {

            await this.setState(() => ({contractor : null}));


            // contracts = await getContractList();
            contracts = await this.getActiveContracts();
        }

        if (step === 2 && contractor) {
            contracts =  await this.getActiveContracts({contractorsId : [contractor.id]})
        }

        //Отсеить договора не в этом регионе
        if(step === 3 && region) {

            contracts = stateContracts.reduce((acc:Contract[], contract) => {
                let contractInRegion = false;

                if(contract.objects) {
                    for(let i = 0; i < contract.objects.length; i++) {
                        const object = contract.objects[i];

                        if(object.square) {
                            if(object.square.region.id === region.id) {
                                contractInRegion = true;
                                break;
                            }
                        }
                    }
                }

                if(contractInRegion) {
                    return [...acc, contract]
                }else{
                    return acc
                }

            }, [])
        }

        // отсеить договора без этого объекта
        if(step === 4 && object) {
            contracts = cutOffContract('objects', object.id);
        }

        //отсеить договора без этого типа заявки
        if(step === 5 && contractOrdersType) {
            contracts = cutOffContract('ordersTypes', contractOrdersType.id);
        }

        //отсеить договора без этой категории
        if(step === 6 && equipmentCategory) {
            contracts = cutOffContract('equipmentCategories', equipmentCategory.id);
        }

        this.setState((prevState) => ({
            step,
            contractor : step === 1 ? null : prevState.contractor,
            region : step <= 2 ? null : prevState.region,
            object : step <= 3 ? null : prevState.object,
            contractOrdersType : step <= 4 ? undefined : prevState.contractOrdersType,
            equipmentCategory : step <= 5 ? undefined : prevState.equipmentCategory,
            contracts,
            loading : false
        }))
    };

    changeContractor = async (contractor:Contractor) => {
        await this.setState(() => ({contractor}));
        await this.changeStep(2)
    };

    changeRegion = async (region:Region) => {
        await this.setState(() => ({region}));
        await this.changeStep(3)
    };

    changeObject = async (object:ContractObject) => {
        await this.setState(() => ({object}));
        await this.changeStep(4)
    };

    changeOrdersType = async (contractOrdersType:ContractOrdersType) => {
        await this.setState(() => ({contractOrdersType}));
        await this.changeStep(5)
    };

    changeEquipmentCategory = async (equipmentCategory:ContractEquipmentCategory) => {
        await this.setState(() => ({equipmentCategory}));
        await this.changeStep(6)
    };


    changeImmediateObject = (option:Option):LoadReturn => {
        return new Promise(async resolve => {
            try {
                const object = await getObject(option.value);

                if(object.contracts && Array.isArray(object.contracts) && object.contracts[0]) {
                    //@ts-ignore
                    this.setState((prevState) => ({
                        step : 4,
                        object : {
                            ...option,
                            objectId : option.value,
                            name : option.label
                        },
                        region : object.square ? object.square.region : null,
                        contractor : (object.contracts as Contract[])[0].contractor,
                        contracts : object.contracts,
                    }))
                }

            }catch (e) {
                checkError(e)
            }

            resolve();
        })
    };

    getAllContracts = async () => {
        try {
            if(CONTRACTS_CACHE?.length){
                return this.setState({
                    allContracts: CONTRACTS_CACHE,
                    loading : false
                }, this.changeStep.bind(this,1))
            }
            this.setState(() => ({loading : true}));
            const all = await getUsersContracts();
            CONTRACTS_CACHE = all;
            this.setState({
                    allContracts: all,
                    loading: false
            }, this.changeStep.bind(this,1))
        }catch (e) {
            checkError(e)
        }
    };

    async componentDidMount() {
        await this.getAllContracts();
    }

    render() {
        const {step, contractor, contractOrdersType, region, object, equipmentCategory, contracts} = this.state;

        const steps = [
            {
                id : 1,
                name : 'contractors.contractor',
                component : <ContractorDetail changeContractor={this.changeContractor} contracts={contracts}/>,
            },
            {
                id : 2,
                name : 'regions.region',
                component : <RegionModalDetail changeRegion={this.changeRegion} contracts={contracts} contractor={contractor}/>,
            },
            {
                id : 3,
                name : 'objects.object',
                component : <ObjectsModalDetail
                                changeObject={this.changeObject}
                                contracts={contracts}
                                contractorId={contractor?.id}
                                regionId={region?.id}
                            />,
            },
            {
                id : 4,
                name : 'orderTypes.orderType',
                component : <OrdersTypeModalDetail changeOrdersType={this.changeOrdersType} contracts={contracts} />,
            },
            {
                id : 5,
                name : 'equipmentCategories.equipmentCategory',
                component : <EquipmentCategories changeEquipmentCategory={this.changeEquipmentCategory} contracts={contracts}/>,
            },
            {
                id : 6,
                name : 'orders.orderCreating',
                component : <DetailModalOrder
                    createOrder={this.createOrder}
                    contracts={contracts}
                    changeInitiator={this.changeInitiator}
                    changeContractCondition={this.onChangeContractCondition}
                    changeContent={this.changeContent}
                    changeContactInfo={this.changeContactInfo}
                    changeAdditionalInfo={this.changeAdditionalInfo}
                    changeExecutionTime={this.onChangeExecutionTime}
                    changeExecutionDate={this.onChangeExecutionDate}
                    contractorId={(this.state.contractor?.id as number)}
                    hide={this.props.hide}
                    isDisabled={this.isDisabled}
                />,
            },
        ];

        let jsx = <></>;

        return (
            <>
                    <div className="create-order-modal">
                        <div className="just-pro-order_create_steps">

                            {steps.map(({id, name, component}) => {
                                if(id === step) {
                                    jsx = component;
                                }

                                const completed = step > id;
                                const active = step === id;

                                const cls = ['just-pro-order_create_step'];
                                if(completed) {
                                    cls.push('completed', 'canJump')
                                }

                                if(active) {
                                    cls.push('active')
                                }

                                return (
                                    <div className={cls.join(' ') }
                                         key={+id}
                                         onClick={completed ? this.changeStep.bind(this, id) : () => undefined}
                                    >
                                        {getText(name)}
                                    </div>
                                )

                            })}
                        </div>

                        <hr/>
                        <div className="just-pro__create_order_chosen-item">

                            {step <= 3 && (
                                <AsyncSelect
                                    loadOptions={
                                        (q?:string) => groupContractObjectsByRegion(this.state.contracts, {
                                            q: q,
                                            regionsId : region ? [region.id] : undefined,
                                            contractorId : contractor ? contractor.id : undefined,
                                            hasContracts : true
                                        })}
                                    label={{text : "UI.select.labels.object" }}
                                    change={this.changeImmediateObject}
                                />
                            )}

                            {contractor && <p>{getText('contractors.contractor')}: {contractor.name}</p>}
                            {region && <p>{getText('regions.region')}: {region.name}</p>}
                            {object && <p>{getText('objects.object')}: {getObjectName(object)}</p>}
                            {contractOrdersType && (<>
                                <p>{getText('orderTypes.orderType')}: {contractOrdersType.name}</p>
                                <p>{getText('orderTypes.alias')}: {contractOrdersType.alias}</p>
                            </>)}
                            {equipmentCategory && <p>{getText('equipmentCategories.equipmentCategory')} : {equipmentCategory.name}</p>}
                        </div>
                        <hr/>

                        {jsx}

                        <Spinner loading={this.state.loading} />
                    </div>

                    {/*<hr/>*/}
                    {/*<div className="just-pro__create_order_chosen-item">*/}

                    {/*    {step <= 3 && (*/}
                    {/*        <AsyncSelect*/}
                    {/*            loadOptions={*/}
                    {/*                (q?:string) => groupContractObjectsByRegion(this.state.contracts, {*/}
                    {/*                    q: q,*/}
                    {/*                    regionsId : region ? [region.id] : undefined,*/}
                    {/*                    contractorId : contractor ? contractor.id : undefined,*/}
                    {/*                    hasContracts : true*/}
                    {/*                })}*/}
                    {/*            label={{text : "Выбрать объект" }}*/}
                    {/*            change={this.changeImmediateObject}*/}
                    {/*            // placeholder=*/}
                    {/*            // accessors={{*/}
                    {/*            //     name : 'label',*/}
                    {/*            //     objectId : 'value'*/}
                    {/*            // }}*/}
                    {/*        />*/}
                    {/*    )}*/}

                    {/*    {contractor && <p>Контрагент: {contractor.name}</p>}*/}
                    {/*    {region && <p>Регион: {region.name}</p>}*/}
                    {/*    {object && <p>Объект: {getObjectName(object)}</p>}*/}
                    {/*    {contractOrdersType && (<>*/}
                    {/*        <p>Тип заявки: {contractOrdersType.name}</p>*/}
                    {/*        <p>Псевдоним типа заявки: {contractOrdersType.alias}</p>*/}
                    {/*    </>)}*/}
                    {/*    {equipmentCategory && <p>Категория оборудования : {equipmentCategory.name}</p>}*/}
                    {/*</div>*/}
                    {/*<hr/>*/}

                    {/*{jsx}*/}

                    {/*<Spinner loading={this.state.loading} />*/}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch:Function) => ({
    getOrders : (filters:Partial<OrdersFilterParams>, type: "get" | "update") => dispatch(getOrders(filters, type)),
});

export default connect(null, mapDispatchToProps)(CreateOrder)