import styled from "styled-components";
import {bcg} from './datepicker.view'

export const Header = styled.div`
    background-color : ${bcg};
    padding : 9px 0 7px;
    padding : 0 20px;
`;

export const Title = styled.div`
    padding : 9px 0 7px;
    border-top: 1px solid #f5f6fb;
    border-bottom: 1px solid #f5f6fb;
`;

export const StyledCalendar = styled.div`
    display : inline-flex;
    flex-direction : column;
    align-items : center;
`;


export const InlineWrapper = styled.div`
    position : absolute;
    width : 100%;
    display : flex;
    justify-content : space-between;
    top : 44px;
    left : 20px; 
`;