import {OptionGroup} from "../components/UI/select/select.types";
import * as api from '@justpro/terminal';
import {City, CitiesFilterParams} from "@justpro/terminal";
import checkError from "./checkError";
import {makeNormalizeParams} from "./makeNormalizeParams";
import {getObjectName} from "./names";
import {getCitiesList, getSquaresList} from "./functions";

export async function groupEWbyET(q?:string):Promise<OptionGroup[]> {

    try {

        const allEw = await api.getEquipmentWorks({q});

        return allEw.reduce((acc:OptionGroup[], ew ) => {

            ew?.equipmentTypes?.forEach((et:api.EquipmentType) => {
                const find = acc.find(accItem => accItem.label === et.name);

                const option = {
                    ...ew,
                    label: ew.name,
                    value : ew.id
                };

                if(find) {
                    acc = acc.map(item => {
                        if(item.label === et.name) {
                            return {
                                ...item,
                                options : item.options ? [...item.options, option] : [option]
                            }
                        }
                        return item;
                    })
                }else{
                    acc.push({
                        label : et.name,
                        options : [option]
                    })
                }
            });

            return acc;

        }, []) || [];

    }catch (e) {
        checkError(e);
        return []
    }

}


interface ObjectFilterParamsWithContractor extends api.ObjectsFilterParams{
    contractorId : number,
    hasContracts : boolean
}

export type Option = {
    label: string
    value: number
}

export type AreaGrouped = {
    label: string
    value: number
    options: Option[]
}

export const getCitiesByArea = async ({q}: Partial<CitiesFilterParams>):Promise<OptionGroup[]>  => {
    const cities = await getCitiesList({q});
    return cities?.reduce((areas: AreaGrouped[], city) => {
        const isCity = areas.find((a) => a.label === city?.area?.name);
        if(isCity){
            isCity.options.push({
                label: city?.name,
                value: city?.id
            });
        } else {
            areas.push({
                label: city?.area?.name,
                value: city?.area?.id,
                options: [{
                    label: city?.name,
                    value: city?.id
                }]
            });
        }
        return areas;
    }, []);
}

export type RegionGrouped = {
    label: string
    value: number
    options: Option[]
}

export const getSquaresByRegion = async ({q}: Partial<CitiesFilterParams>) => {
    const squares = await getSquaresList({q})
    return squares?.reduce((regions: RegionGrouped[], square) => {
        const currentSquare = regions.find((region) => region.label === square.region.name);

        if (currentSquare) {
            currentSquare.options.push({
                label: square?.name,
                value: square.id,
            })
        } else {
            regions.push({
                label: square?.region?.name,
                value: square?.region?.id,
                options : [{
                    label: square?.name,
                    value: square?.id
                }]
            })
        }
        return regions
    }, [])
}

export async function groupObjectsByRegion(filterParams:Partial<ObjectFilterParamsWithContractor>):Promise<OptionGroup[]> {
    try {
        const objectsData = await api.getObjects(filterParams);
        let objects = objectsData.list;


        if(filterParams.hasContracts) {
            objects = objects.filter(object => ( object?.contracts?.length || 0 ) > 0)
        }

        //todo фильтрация по контрагенту на севрере

        if(filterParams.contractorId) {
            objects = objects.filter(object => {
                if(object.contracts && object.contracts[0]) {
                    return object.contracts[0].contractor?.id === filterParams.contractorId
                }
                return false
            })
        }

        return objects?.reduce((acc:OptionGroup[], object:api.Object) => {

            const finded = acc.find(item => item.label === object?.square?.region.name);
            // console.log({object})
            const name = getObjectName(object);


            const option = makeNormalizeParams({
                ...object,
                name
            }, {name : 'label', id : 'value'})

            if(finded) {
                acc = acc.map(item => {

                    if(item.label === object?.square?.region.name) {
                        return {
                            ...finded,
                            options : finded.options ? [...finded.options, option] : [option]
                        }

                    }

                    return item
                })
            }else{
                acc.push({
                    label : object?.square?.region?.name || '',
                    options : [option]
                })
            }

            return acc
        }, []) || []

    }catch (e) {
        checkError(e);
        return [];
    }

}

export async function groupContractObjectsByRegion(contracts:api.Contract[], filterParams:Partial<ObjectFilterParamsWithContractor>):Promise<OptionGroup[]> {

    const q = filterParams?.q?.[0];
    const regionsId = filterParams?.regionsId?.[0];
    const contractorId = filterParams?.contractorId;

    try {

        const diffContracts = contracts?.reduce((acc:OptionGroup[], contract:api.Contract) => {

            const objects = contract?.objects?.filter((o) => o.active)?.reduce((objectsAcc:OptionGroup[], object:api.Object) => {
                const name = getObjectName(object);

                if(q) {
                    const matched = name.toLowerCase().match(q.toLowerCase());

                    if(!matched) {
                        return objectsAcc
                    }
                }

                if(regionsId) {
                    if(object?.square?.region.id !== regionsId) {
                        return objectsAcc
                    }
                }

                if(contractorId) {
                    if(contract?.contractor?.id !== contractorId) {
                        return objectsAcc
                    }
                }

                const option = makeNormalizeParams({
                    ...object,
                    name
                }, {name : 'label', objectId : 'value'});


                const finded = objectsAcc.find(item => item.label === option?.square?.region.name);



                if(finded) {
                    objectsAcc = [...objectsAcc.map(item => {
                        if(item.label === option?.square?.region?.name) {
                            return {
                                ...finded,
                                options : finded.options ? [...finded.options, option] : [option]
                            }
                        }

                        return item
                    })]
                }else{
                    objectsAcc.push({
                        label : option?.square?.region?.name || '',
                        options : [option],
                        id : contract.id
                    })
                }

                return objectsAcc
            }, []) || [];

            return [...acc, ...objects]
        }, []) || [];

        const result = diffContracts.reduce( (acc:OptionGroup[], item) => {

            const finded = acc.find(i => i.label === item.label);

            if(finded) {
                return [
                    ...acc.filter(item => !(item.label === finded.label && item.id === finded.id)),
                    {
                        ...finded,
                        options : [...finded.options, ...item.options ]
                    }
                ]
            }

            return [...acc, item];
        }, []);

        return [...result.sort((a:any, b:any) => a?.label?.localeCompare(b?.label))];

    }catch (e) {
        checkError(e);
        return [];
    }

}