import React from "react";
import '../act.css'
import {Object as IObject, IVirtualAct} from '@justpro/terminal'
import Table from "../../UI/table/table";
import RenderIf from "../../../utils/renderIf";
import {getObjectName} from "../../../utils/names";
import getText from "../../../localization/getText";

interface Props {
    virtualAct: IVirtualAct
}

class VirtualAct extends React.Component<Props, any> {

    render() {
        const {virtualAct} = this.props;
        if (!virtualAct) return null;
        const {materials} = virtualAct;
        const {services} = virtualAct;
        return (
            <>
                <RenderIf condition={services}>
                    <div className="just-pro__act-services">
                        <h4>{getText('acts.services')}</h4>
                        {<Table
                            data={services || []}
                            columns={[
                                {
                                    Header: getText('acts.code'),
                                    width: 90,
                                    Cell: (props: any) => props.original?.code
                                },
                                {
                                    Header: getText('acts.name'),
                                    Cell: (props: any) => props.original?.name
                                },
                                {
                                  Header: getText("acts.comment"),
                                  accessor: "comment"
                                },
                                {
                                    Header: getText('acts.count'),
                                    width: 120,
                                    Cell: (props: any) => `${props.original?.count} ${props.original?.unit}`
                                }
                            ]}
                        />}
                    </div>
                </RenderIf>
                <RenderIf condition={materials}>
                    <div className="just-pro__act-materials">
                        <h4>{getText('acts.materials')}</h4>
                        {<Table
                            data={materials || []}
                            columns={[
                                {
                                    Header: getText('acts.code'),
                                    width: 120,
                                    Cell: (props: any) => props.original?.code
                                },
                                {
                                    Header: getText('acts.name'),
                                    Cell: (props: any) => props.original?.name
                                },
                                {
                                    Header: getText('acts.count'),
                                    width: 120,
                                    Cell: (props: any) => `${props.original?.count} ${props.original?.unit}`
                                }
                            ]}
                        />}
                    </div>
                </RenderIf>
            </>
        )
    }
}

export default VirtualAct