import React from 'react';
import {
    CreateSuspension,
    createSuspension,
    disableSuspensions, Rules,
    SUSPENSION_CREATE_HISTORY,
    SUSPENSION_DISABLE_HISTORY,
    SuspensionHistory
} from "@justpro/terminal";
import withLabel from "../../UI/withLabel/withLabel";
import getPersonPhoto from "../../../utils/getPersonPhoto";
import moment from "moment";
import PersonInfo from "../../UI/dialog/personInfo/personInfo.controller";
import Button from "../../UI/button/button";
import SuspensionModal from './modals/suspension'
import './contact.css'
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {hideModal, openModal} from "../../../store/modal/modal.actions";
import {connect} from "react-redux";
import checkError from "../../../utils/checkError";

interface Props {
    history? : SuspensionHistory[]
    suspended : boolean
    contractId : number
    rights? : Rules
    openModal(props:Modal) : void
}

interface State {
    suspended? : boolean
    history?: SuspensionHistory[]
}


export const suspendedModalId = 'contractsSuspension'

class ContractHistory extends React.Component<Props, State> {

    state: State = {
    };


    enableContract = async () => {
        try {
            const history = await disableSuspensions(this.props.contractId);

            this.setState((prevState) => ({
                history : prevState.history ? [...prevState.history, history] : [history],
                suspended : !prevState.suspended
            }))
        }catch (e) {
            checkError(e)
        }
    };


    openPauseContractModal = () => {

        this.props.openModal({
            id : suspendedModalId,
            title : getText('contracts.suspensionHeader'),
            component : (props:ModalBodyProps) => (
                <SuspensionModal {...props} onSave={this.createSuspension} />
            ),

        })
    };

    createSuspension = async (data:CreateSuspension) => {
        try {
            const history = await createSuspension(this.props.contractId, data);

            this.setState((prevState) => ({
                history : prevState.history ?
                    [...prevState.history, history]
                    : [history],
                suspended : !prevState.suspended
            }));
        }catch (e) {
            checkError(e)
        }

    };

    setDefaultState = () => {

        this.setState(() => ({
            history : this.props.history,
            suspended: this.props.suspended
        }))
    };

    async componentDidMount() {
        await this.setDefaultState()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.contractId !== prevProps.contractId) {
            await this.setDefaultState();
        }
    }

    render() {
        const {history, suspended} = this.state;
        const {rights} = this.props;


        if(rights && !rights.edit) {
            return <></>
        }

        return (<>
            {withLabel(
                <>
                    {suspended ? (
                        <Button className="btn-success" onClick={this.enableContract}>{getText('contracts.history.enable')}</Button>

                    ) : (
                        <Button className="btn-success" onClick={this.openPauseContractModal}>{getText('contracts.history.pause')}</Button>
                    )}
                </>
            )({text: suspended ? 'contracts.history.enableContract' : 'contracts.history.pauseContract'})}


            {history && history.length > 0 && (
                <>
                    {withLabel(
                        <>
                            {history.map((item) => {
                                let pauseTypes: string = renderToString(getText('contracts.history.contractIsPausedBy'));

                                if (item.suspension) {
                                    const {ordersTypes, contractsConditions, equipmentCategories, objects} = item.suspension;
                                    if (ordersTypes && ordersTypes.length > 0) {
                                        pauseTypes += renderToString(getText('contracts.history.types'));
                                        pauseTypes = ordersTypes.reduce((acc: string, item) => {
                                            return ` ${acc} ${item.name}`
                                        }, pauseTypes)
                                    } else if (contractsConditions && contractsConditions.length > 0) {
                                        pauseTypes += renderToString(getText('contracts.history.contractConditions'));
                                        pauseTypes = contractsConditions.reduce((acc: string, item) => {
                                            return ` ${acc} ${item.name}`
                                        }, pauseTypes)
                                    } else if (equipmentCategories && equipmentCategories.length > 0) {
                                        pauseTypes += renderToString(getText('contracts.history.categories'));

                                        pauseTypes = equipmentCategories.reduce((acc: string, item) => {
                                            return ` ${acc} ${item.name}`
                                        }, pauseTypes)
                                    } else if (objects && objects.length > 0) {
                                        pauseTypes += renderToString(getText('contracts.history.objects'));

                                        pauseTypes = objects.reduce((acc: string, item) => {
                                            return ` ${acc} ${item.name}`
                                        }, pauseTypes)
                                    }
                                }


                                const action = item.action === SUSPENSION_CREATE_HISTORY ? 'contracts.history.isPaused' :
                                    item.action === SUSPENSION_DISABLE_HISTORY ? 'contracts.history.isActive' : 'contracts.history.undefinedOperation';

                                return (
                                    <div className="contract__history-item" key={item.id}>
                                        <div className="history-photo">
                                            <img style={{height: 48, width: 48}}
                                                 src={getPersonPhoto(item.author.person.avatar)}
                                                alt=""/>
                                        </div>
                                        <div className="history-content">
                                            <div>
                                                <span className="status">
                                                     {getText('contracts.contract')} {getText(action)} {getText('common.fromWho')} <span className="history-date"> {moment(item.createdAt).format('DD.MM.YYYY')} </span>
                                                </span>
                                                <span className="history-initiator">
                                                    <PersonInfo person={item.author.person}/>
                                                </span>
                                            </div>
                                            {item.suspension &&
                                                <div className="reason">
                                                    {item.suspension.reason}
                                                    <div className="additional-info">
                                                        <p>{pauseTypes}</p>
                                                        <p>{getText('contracts.history.orderStatusTransferIn')}<b>{item.suspension.status.name}</b></p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )({text: 'contracts.history.historyOfContractPauses'})}
                </>
            )}
        </>);


    }
}
const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props)),
});

export default connect(null, mapDispatch)(ContractHistory)