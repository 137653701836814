export interface FilterNamesMap {
  cities: string;
  masters: string;
  isMaster: string;
  areas: string;
  regions: string;
  contractsConditions: string;
  contracts: string;
  contractsAlias: string;
  orderTypes: string;
  urgencyOrders: string;
  equipmentCategories: string;
  equipmentWorks: string;
  workGroups: string;
  equipmentTypes: string;
  contractors: string;
  statusGroups: string;
  actTags: string;
  taskTags: string;
  persons: string;
  executors: string;
  actPhases: string;
  tasksStates: string;
  regularWorkStates: string;
  closeTypes: string;
  urgencyRegularWorks: string;
}

export type FilterName = keyof FilterNamesMap;

export type dispatchFilterFunction = (
  rightsName: FilterName,
  filters?: any
) => Promise<any[] | undefined>;

export interface FiltersStore {
  filters: {
    [key: string]: any;
  };
}

export const initialState: FiltersStore = {
  filters: {},
};
