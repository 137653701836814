import React from 'react';
import {ModulesResponse, RegularStatus} from "@justpro/terminal";
import { getRegularStatuses, createRegularStatus} from '@justpro/terminal'
import {toClassName} from "../../../utils/icons";
import BaseReferenceController from "../baseReference/baseReference.controller";
import RegularStatusController from "./regularStatus.controller";
import RegularStatusCreate from "./regularStatus.create";
import {Angle} from "../../UI/itemsList/card/card.view";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";


interface Props {
    rights?: Partial<ModulesResponse>
}

class RegularStatusesController extends React.Component<Props>{

    renderItem = (item:RegularStatus) => {
        return (
            <>
                <Angle color={item.color} size={20}/>
                <i className={toClassName(item.icon)}/> {item.name}
            </>
        )
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.regularStatuses'];

        return <BaseReferenceController
            listItem={{render : this.renderItem}}
            getList={getRegularStatuses}
            Detail={RegularStatusController}
            create={{
                title : "regularStatuses.addItem",
                handler : createRegularStatus,
                Component : RegularStatusCreate
            }}
            referenceName="regularStatuses.moduleName"
            moduleRights={rights}
        />

    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(RegularStatusesController)