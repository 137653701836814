import React from 'react';
import {createArea, filterParams, getAreas, getCountries, ModulesResponse, Rules} from "@justpro/terminal";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {Area} from "@justpro/terminal";
import AreaCreate from "./area.create";
import AreaController from "./area.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";

interface Props {
    rights?: Partial<ModulesResponse>
}

class AreasController extends React.Component<Props> {


    renderItem = (item:Area) => <>{item.name}</>;

    getCheckboxesMap = async () => {
        const {rights} = this.props;


        if(rights?.['references.countries']?.read) {
            const countries =  await getCountries({});

            return {
                [filterParams.COUNTRIES] : {
                    name : 'countries.country',
                    children : countries.map(({name,id}) => ({id, name}))
                }
            }
        }

        return {};

    };

    render () {
        const {rights} = this.props;


        return (
            <BaseReferenceController
                referenceName="areas.moduleName"
                getList={getAreas}
                listItem={{
                    render: this.renderItem,
                }}
                filter={{
                    filterName : filterNames.AREAS,
                    getCheckboxesMap : this.getCheckboxesMap,
                }}
                Detail={AreaController}
                create={{
                    Component : AreaCreate,
                    handler: createArea,
                    title : "areas.addItem"
                }}
                moduleRights={rights && rights['references.areas'] ? rights['references.areas'] : undefined}
            />
        )
    }
}


const mapStateToProps = (state:ApplicationMapState) => ({
    rights : state.application.rights
});


export default connect(mapStateToProps)(AreasController)