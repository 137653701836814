import {confirm} from "../components/UI/confirmAction/confirmAction.controller";
import {toast} from "react-toastify";
import FileDownload from "js-file-download";
import moment from "moment";
import getText from "../localization/getText";
import {renderToString} from "react-dom/server";

export const isFileLink = async (funcLink: () => any, funcFile: () => any, fileName: string) => {
    const isLinkFile = await confirm({
        title: getText('common.excelExport'),
        question: getText('common.selectExportType'),
        confirmText: getText('common.makeLink'),
        declineText: getText('common.downloadFile')
    })
    if(isLinkFile){
        await funcLink();
        toast.success(renderToString(getText('common.makeLinkSuccess')));
    } else if (isLinkFile === false) {
        const excel = await funcFile();
        excel && FileDownload(excel, fileName);
    }
}
