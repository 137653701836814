interface IPosition {
    lat: number,
    lng: number,
}

export class GeolocationError extends Error {
};
export default function (): Promise<IPosition | string> {
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                res({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            },
            (err) => {
                rej(new GeolocationError(`Разрешите определение вашего местоположения и перезагрузите страницу`))
            })
    });
}