import React from "react";
import '../act.css'
import Spinner from "../../UI/spinner/spinner.controller";

interface Props {
    loading : boolean
}

const ActPreloader = ({loading}:Props) => {
    return (
        <div className="act-preloader">
            <Spinner loading={loading} />
        </div>
    )
}

export default ActPreloader