import React from "react";
import moment, {Moment} from "moment";
import FileDownload from "js-file-download";
import checkError from "../../../utils/checkError";
import {getExecutorDistancesExport, format} from "@justpro/terminal";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";
import Spinner from "../../UI/spinner/spinner.controller";


interface Props {
    hide() : void
}

interface State {
    from : Moment
    to : Moment
    loading : boolean
}

export default class MileageModal extends React.Component<Props, State>{

    state:State = {
        from : moment().subtract(1, 'month').startOf('month'),
        to : moment().add(1, 'month').endOf('month'),
        loading : false
    };

    changeTo = (to: Moment) => {
        this.setState(() => ({to}))
    };
    changeFrom = (from: Moment) => {
        this.setState(() => ({from}))
    };

    export = async () => {
        this.setState(() => ({loading: true}));

        try {
            const result = await getExecutorDistancesExport({
                dateFrom: this.state.from.format(format.date),
                dateTo: this.state.to.format(format.date)
            });
            FileDownload(result, `километраж_исп_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`);

        } catch (e) {
            checkError(e)
        } finally {
            this.setState(() => ({loading: false}));
            this.props.hide();
        }
    };

    render() {

        return (
            <>
                <div className="mileage-dates-dialog">
                    <InlineCalendar label={{text: 'Дата с:', position: "top"}} date={this.state.from}
                                    onChange={this.changeFrom}/>
                    <InlineCalendar label={{text: 'Дата по:', position: "top"}} date={this.state.to}
                                    onChange={this.changeTo}/>
                </div>

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.export}>{getText('common.excelExport')}</Button>
                </DefaultFooterModal>

                <Spinner loading={this.state.loading} />
            </>

        )
    }
}

