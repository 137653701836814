import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {getCountries} from "../../../utils/functions";
import {CreateArea} from '@justpro/terminal'
import getText from "../../../localization/getText";

type Props = {
    create(data:CreateArea) : void
};
type State = {
    name : string
    country? : number
};

class AreaCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
    };

    create =  () => {
        if(!this.isDisabled()) {
            this.props.create({
                name : this.state.name,
                countryId : this.state.country as number
            })
        }
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    onChangeCountry = (option: Option) => {
        return new Promise(async resolve => {
            await this.setState(() => ({
                country : option.value
            }));
            resolve();
        })
    };

    isDisabled = () => this.state.name.trim() === '' || this.state.country === undefined;

    render () {
        return (
            <>
                <h3>{getText('areas.addingNewHeader')}</h3>

                <Input
                    label="areas.area"
                    placeholder="areas.area"
                    change={this.onChangeName}
                />

                <Select
                    change={this.onChangeCountry}
                    load={getCountries}
                    accessors={{
                        name: 'label',
                        id: 'value'
                    }}
                    label="countries.country"
                />

                <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }

}

export default AreaCreate
