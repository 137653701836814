import React from 'react';
import {createPhone, getPhones, ModulesResponse, Phone} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import PhoneController from "./phone.controller";
import PhoneCreate from "./phone.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class PhonesController extends React.Component<Props> {

    renderItem = (phone:Phone) => {
        return (
            <>{phone.name}</>
        )
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.phones'];
        return <BaseReferenceController
            referenceName="phones.moduleName"
            Detail={PhoneController}
            create={{
                title : 'phones.addItem',
                handler : createPhone,
                Component : PhoneCreate
            }}
            getList={getPhones}
            listItem={{
                render: this.renderItem
            }}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(PhonesController)