import React from "react";
import Select from "../../UI/select/select";
import Input from "../../UI/input/text";
import TransportController from "../transport/transport.controller";
import moment, {Moment} from "moment";
import {
    addPersonTransport,
    addPersonBasePoint,
    updatePersonBasePoint,
    format,
    IPersonTransport,
    PersonBasePoint as IPersonBasePoint
} from "@justpro/terminal";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import {Option} from "../../UI/select/select.types";
import checkError from "../../../utils/checkError";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";
import AsyncSelect from "../../UI/select/asyncSelect";
import {getCitiesByArea} from "../../../utils/selectGroups";
import withLabel from "../../UI/withLabel/withLabel";
import RenderIf from "../../../utils/renderIf";


interface Props {
    personId: number

    hide(): void

    basePoint?: IPersonBasePoint

    updatePersonBasePoint(basePoint: IPersonBasePoint, id?: number): void
}

interface State {
    city: Option
    latitude: string
    longitude: string
    address: string
}

class PersonBasePointsModal extends React.Component<Props, State> {

    state: State = {
        latitude: '',
        longitude: '',
        address: '',
        city: null
    };

    save = async () => {
        try {
            if (this.props.basePoint?.id) {
                await this.editBasePoint();
            } else {
                await this.addBasePoint();
            }
            this.props.hide();
        } catch (err) {
            checkError(err);
        }
    };

    addBasePoint = async () => {
        if (this.isDisabled()) return;
        const basePoints = await addPersonBasePoint({
            personId: this.props.personId,
            cityId: +this.state.city?.value,
            latitude: +this.state.latitude,
            longitude: +this.state.longitude,
            address: this.state.address
        });
        this.props.updatePersonBasePoint(basePoints);
    };

    editBasePoint = async () => {
        if (this.isDisabled()) return;
        const params = {
            personId: this.props.personId,
            cityId: this.state.city?.value,
            personBasePointId: this.props?.basePoint?.id,
            latitude: +this.state.latitude,
            longitude: +this.state.longitude,
            address: this.state.address
        }
        const basePoint = await updatePersonBasePoint(params);
        this.props.updatePersonBasePoint(basePoint);
    };

    onChangeCity = (city: Option) => {
        this.setState((prevState) => ({
            city
        }));
        return Promise.resolve();
    }

    onChangeAddress = (address: string) => {
        this.setState({
            address
        });
    };

    onChangeLatitude = (latitude: string) => {
        this.setState({
            latitude
        });
    }

    onChangeLongitude = (longitude: string) => {
        this.setState({
            longitude
        });
    }

    isDisabled = (prevState?: State) => {
        return !(this.state.address?.length && this.state.city?.value)
    };

    resetState = () => {
        this.setState({
            latitude: (this.props.basePoint?.latitude || '') as string,
            longitude: (this.props.basePoint?.longitude || '') as string,
            address: this.props.basePoint?.address as string || '',
            city: {
                label: this.props?.basePoint?.city?.name,
                value: this.props?.basePoint?.city?.id
            }
        })
    };

    componentDidMount(): void {
        this.resetState();
    }

    render() {
        return (
            <>
                <div className="person-transport">

                    <AsyncSelect
                        defaultValue={{
                            value: this.state.city,
                            accessors: {name: 'label', id: 'value'}
                        }}
                        loadOptions={(q: string) => getCitiesByArea({q})}
                        label={{text: "cities.city"}}
                        change={this.onChangeCity}
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                    />

                    <Input
                        placeholder="objects.address"
                        change={this.onChangeAddress}
                        label="objects.address"
                        value={this.state.address}
                    />

                    <RenderIf condition={this.props.basePoint?.id}>
                        {withLabel(
                            <>
                                <Input
                                    label="common.latitude"
                                    value={this.state.latitude}
                                    change={this.onChangeLatitude}
                                />
                                <Input
                                    label="common.longitude"
                                    value={this.state.longitude}
                                    change={this.onChangeLongitude}
                                />
                            </>
                        )({text: 'GPS'})}
                    </RenderIf>


                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success" onClick={this.save}
                                disabled={this.isDisabled()}>{getText('common.save')}</Button>
                    </DefaultFooterModal>

                </div>

            </>
        )
    }
}

export default PersonBasePointsModal