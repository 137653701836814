import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {getRegionsList} from "../../../utils/functions";
import withLabel from "../../UI/withLabel/withLabel";
import {CreateSquare} from '@justpro/terminal/dist/squares'
import {isValidGPS, getPointsJSX, getPointsFromMap} from "./GPS";
import {PointMap} from './squares.controller'
import getText from "../../../localization/getText";

interface Props {
   create(data:CreateSquare) : void
}

interface State {
    name : string
    region : number
    points : PointMap
}

class SquareCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
        region : -1,
        points : {
            [Date.now()]: {
                longitude: '',
                latitude: '',
                radius: '',
            }
        },
    };


    add = () => {
        if(!this.isDisabled()) {
            const {name, region, points:pointsMap} = this.state;

            const points = getPointsFromMap(pointsMap);

            this.props.create({
                points,
                name,
                regionId : region
            });
        }
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    onChangeLatitude = (event:React.FormEvent<HTMLInputElement>, id:number) => {
        const latitude = event.currentTarget.value;

        this.setState((prevState) => ({
            ...prevState,
            points : {
                ...prevState.points,
                [id] : {
                    ...prevState.points[id],
                    latitude
                }
            }
        }))
    };

    onChangeLongitude = (event:React.FormEvent<HTMLInputElement>, id:number) => {
        const longitude = event.currentTarget.value;

        this.setState((prevState) => ({
            ...prevState,
            points : {
                ...prevState.points,
                [id] : {
                    ...prevState.points[id],
                    longitude
                }
            }
        }))
    };

    onChangeRadius = (event:React.FormEvent<HTMLInputElement>, id:number) => {
        const radius = event.currentTarget.value;

        this.setState((prevState) => ({
            ...prevState,
            points : {
                ...prevState.points,
                [id] : {
                    ...prevState.points[id],
                    radius
                }
            }
        }))
    };

    onChangeRegion = (option:Option) => {

        return new Promise(resolve => {

            this.setState(() => ({
                region : option.value
            }));
            resolve();
        })
    };

    isDisabled = () =>  {
        return  this.state.name.trim() === '' ||
            this.state.region === -1      ||
            !isValidGPS(this.state.points)
    };


    addPoint = () => {
        this.setState((prevState) => ({
            ...prevState,
            points : {
                ...prevState.points,
                [Date.now()] : {
                    longitude : '',
                    latitude : '',
                    radius : '',
                }
            }
        }))
    };


    render () {

        return (
            <>
                <h3>{getText('squares.addingNewHeader')}</h3>

                <Input
                    placeholder="squares.square"
                    change={this.onChangeName}
                    label="squares.square"
                    startValue=""
                />

                <Select
                    change={this.onChangeRegion}
                    load={getRegionsList}
                    label="regions.region"
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                />

                {withLabel(
                    <>
                        {getPointsJSX(  this.state.points,
                                        this.onChangeLatitude,
                                        this.onChangeLongitude,
                                        this.onChangeRadius
                                    )
                        }
                    </>
                )({
                    text : 'GPS'
                })}

                {withLabel(
                    <Button className="btn-success" onClick={this.addPoint}>{getText('common.add')}</Button>
                )({
                    text: 'squares.addNewGPSPoint'
                })}


                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }

}


export default SquareCreate
