import React from "react";
import Button from "../UI/button/button";
import getText from "../../localization/getText";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";

interface Props {
    commentId: number
    hide() : void
    unmark(value: 1 | 2, commentId: number) : void
}

interface State {
    value : 1 | 2
}

export default class UnmarkCommentToAll extends React.Component<Props, State>{
    state:State = {value : 1};

    changeValue = (value : 1 | 2) => {
        this.setState({value})
    };

    unmark = async () => {
        await this.props.unmark(this.state.value, this.props.commentId);
        this.props.hide()
    };

    render() {
        return (
            <div>
                <h2>{getText('orders.selfReadingCommentOrForAll')}</h2>
                <input type="radio" name="unmark" checked={this.state.value === 1} onChange={this.changeValue.bind(this, 1)}/> {getText('orders.selfOnly')}
                <input type="radio" name="unmark" checked={this.state.value === 2} onChange={this.changeValue.bind(this, 2)}/> {getText('orders.forAll')}

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.unmark}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </div>
        )
    }
}