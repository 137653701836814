import React from 'react'
import {
	addWorkorderOrders,
	getOrders,
	IUpdateWorkorderOrderPriority,
	ModulesResponse,
	OrderListItem,
	OrdersWorkorder,
	removeWorkorderOrders,
	updatePriorityWorkorderOrders,
} from '@justpro/terminal'
import './workOrders.css'
import SortingList from '../UI/simpleSorting/sortingList'
import { sortTypes } from '../UI/simpleSorting/sortTypes'
import Order from '../orders/order/order.controller'
import checkError from '../../utils/checkError'
import RenderIf from '../../utils/renderIf'
import { toast } from 'react-toastify'
import ItemList from '../UI/itemsList/itemList.controller'
import { connect } from 'react-redux'
import { OrdersReducer, UpdateOrderList } from '../../store/orders/orders.types'
import { updateOrderList } from '../../store/orders/orders.actions'
import { ApplicationReducer } from '../../store/application/application.types'
import { renderToString } from 'react-dom/server'
import getText from '../../localization/getText'

interface Props {
	orders?: OrderListItem[]
	workOrder: OrdersWorkorder
	onChangeActNumber(order: OrderListItem, value: string): void
	onChangePartial(order: OrderListItem, value: boolean): void
	updateOrdersList(orderId: number, data: UpdateOrderList): void

	rights?: Partial<ModulesResponse>

	ordersList?: OrderListItem[]
}
interface State {
	possibleOrders: OrderListItem[]
	loading: boolean
	orders?: OrderListItem[]

	offset: number
	count: string
}

class OrdersWorkOrder extends React.Component<Props, State> {
	state: State = {
		possibleOrders: [],
		loading: false,
		count: '0',
		offset: 0,
	}

	renderItem = (order: OrderListItem) => {
		const { closed } = this.props.workOrder

		return (
			<Order
				key={order.number}
				order={order}
				withDelete={{
					deleteHandler: this.deleteOrder.bind(this, order),
					title: 'workorders.deleteOrderFromWorkorder',
					condition: !order.act && !closed,
				}}
				withAct={
					!this.props.workOrder.closed
						? {
								onChangePartial: this.props.onChangePartial,
								onChangeActNumber: this.props.onChangeActNumber,
						  }
						: void 0
				}
				withBorderBottom={false}
				withModuleDetail={true}
			/>
		)
	}

	renderPossibleItem = (order: OrderListItem) => {
		return (
			<Order
				key={order.number}
				order={order}
				withIncludes={{
					handleInclude: this.includeOrder.bind(this, order),
					title: 'workorders.addOrderToWorkorder',
				}}
				withBorderBottom={false}
				withModuleDetail={true}
			/>
		)
	}

	onOrderDrop = async (list: OrderListItem[]) => {
		const data: IUpdateWorkorderOrderPriority = {
			orders: list.map((item, index) => {
				return {
					priority: index + 1,
					orderId: item.id,
				}
			}),
		}

		try {
			const response = await updatePriorityWorkorderOrders(
				this.props.workOrder.id,
				data,
			)
		} catch (e) {
			checkError(e)
		}
	}

	deleteOrder = async (order: OrderListItem) => {
		this.setState(() => ({
			loading: true,
		}))

		try {
			const deleted = await removeWorkorderOrders(
				this.props.workOrder.id,
				[order.id],
			)

			if (deleted) {
				const { list } = await this.getOrders(0)
				this.setState((prevState) => ({
					...prevState,
					possibleOrders: list,
					orders: prevState.orders
						? prevState.orders.filter(
								(o) => o.id !== order.id,
						  )
						: [],
					loading: false,
				}))
			} else {
				this.setState(() => ({ loading: false }))
				toast.error(
					`${renderToString(
						getText('workorders.cannotDeleteOrderWithNumber'),
					)} ${order.number}`,
				)
			}
		} catch (e) {
			this.setState(() => ({
				loading: false,
			}))
			checkError(e)
		}
	}

	includeOrder = async (order: OrderListItem) => {
		const { updateOrdersList } = this.props
		this.setState(() => ({
			loading: true,
		}))
		try {
			const addedOrder = await addWorkorderOrders(
				this.props.workOrder.id,
				[order.id],
			)
			this.setState((prevState) => ({
				...prevState,
				orders:
					prevState.orders &&
					!prevState.orders?.find((o) => o.id === order.id)
						? [...prevState.orders, order]
						: [order],
				possibleOrders: prevState.possibleOrders
					? prevState.possibleOrders.filter(
							(item) => item.id !== order.id,
					  )
					: [],
				loading: false,
			}))

			// updateOrderList && updateOrderList(order.id, {orderStatus : addedOrder.});
		} catch (e) {
			this.setState(() => ({
				loading: false,
			}))
			checkError(e)
		}
	}

	getOrders = async (offset: number) => {
		const { rights } = this.props

		const read = rights && rights['orders'] && rights['orders']['read']
		if (read) {
			try {
				return await getOrders({
					canWorkorder: '1',
					objectsId: [this.props.workOrder.object.id],
					offset,
					limit: 100,
				})
			} catch (e) {
				checkError(e)
				return {
					list: [],
					count: '0',
				}
			}
		} else {
			return { count: '0', list: [] }
		}
	}

	updateOrdersList = async () => {
		if (+this.state.count > this.state.possibleOrders.length) {
			const offset = this.state.offset + 100

			this.setState(() => ({
				loading: true,
			}))

			const { list, count } = await this.getOrders(offset)

			this.setState((prevState) => ({
				possibleOrders: [...prevState.possibleOrders, ...list],
				count: count.toString(),
			}))
			return
		}
	}

	async getDefaultState() {
		try {
			this.setState(() => ({
				loading: true,
				orders: [],
				possibleOrders: [],
			}))

			const { list, count } = await this.getOrders(0)

			this.setState(() => ({
				possibleOrders: list,
				orders: this.props.orders,
				offset: 0,
				count: count.toString(),
				loading: false,
			}))
		} catch (e) {
			this.setState(() => ({ loading: false }))
			checkError(e)
		}
	}

	async componentDidMount() {
		this.getDefaultState()
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<{}>,
		snapshot?: any,
	) {
		if (
			JSON.stringify(this.props.workOrder) !==
			JSON.stringify(prevProps.workOrder)
		) {
			this.getDefaultState()
		}
	}

	render() {
		const { possibleOrders, orders } = this.state
		const { closed } = this.props.workOrder

		const { rights } = this.props

		const read = rights && rights['orders'] && rights['orders']['read']

		return (
			<>
				{read && (
					<div className='workorder-orders-block'>
						<h4>
							{orders && orders.length > 0
								? getText('workorders.includedOrders')
								: getText('workorders.hasNoOrders')}
						</h4>

						{orders !== undefined ? (
							<div className='workOrder__orders'>
								<SortingList
									type={sortTypes.order}
									renderItem={this.renderItem}
									list={orders}
									onItemDrop={this.onOrderDrop}
								/>
							</div>
						) : null}

						<RenderIf
							condition={possibleOrders.length > 0 && !closed}
						>
							<div className='possible-orders'>
								<h4>
									{getText('workorders.ordersCanInclude')}:
								</h4>

								<ItemList
									loading={this.state.loading}
									renderItem={this.renderPossibleItem}
									updateData={this.updateOrdersList}
									list={this.state.possibleOrders}
								/>
							</div>
						</RenderIf>
					</div>
				)}
			</>
		)
	}
}

interface MapState {
	application: ApplicationReducer
	orders: OrdersReducer
}

const mapState = (state: MapState) => ({
	rights: state.application.rights,
	ordersList: state.orders.list,
})

const mapDispatchToProps = (dispatch: Function) => ({
	updateOrdersList: (orderId: number, data: UpdateOrderList) =>
		dispatch(updateOrderList(orderId, data)),
})
export default connect(mapState, mapDispatchToProps)(OrdersWorkOrder)
