import React from 'react'
import getText from '../../../localization/getText';
import RenderIf from '../../../utils/renderIf';

export default ({
                    selectedForPush,
                    changeSelectedForPush,
                    changeSelectedAllForPush,
                    data,
                    rights,
                    changeIsLocked,
                    changeIsLockedAll,
                }: any) => {
    const columns = [
        {
            Header: (
                <div>
                    {getText('common.executor')}
                </div>
            ),
            width: 350,
            Cell: (props) => (
                <div>{props.original.personFullName}</div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.hours')}
                </div>
            ),
            width: 50,
            Cell: (props) => (
                <div>{(props.original.time / 3600).toFixed(2)}</div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.hoursManual')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>{(props.original.hoursOder / 3600).toFixed(2)}</div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.hoursAuto')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>{(props.original.hoursSystem / 3600).toFixed(2)}</div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.finalDistance')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.totalDistance}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.estimateDistance')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.distanceCheck}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.factDistance')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.distanceAll}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.confirmed')}
                </div>
            ),
            width: 120,
            Cell: (props) => (
                <div>
                    {props.original.accepted ? 'да' : 'нет'}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.tasks')}
                </div>
            ),
            width: 120,
            Cell: (props) => {
                return props.original.tasksNumbers.map((number) => {
                    return <div>{number}</div>;
                });
            },
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsElements')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.virtualActsCount}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsTPMaster')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.tpMasterActsCount}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsJustPro')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {props.original.justproActsCount}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsTotal')}
                </div>
            ),
            width: 100,
            Cell: (props) => (
                <div>
                    {+props.original.justproActsCount + +props.original.tpMasterActsCount + +props.original.virtualActsCount}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsAdjusted')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.actsHoursEdited}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.datesJustPro')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.datesDistanceJustpro}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.datesTP')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.datesDistanceTpMaster}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.datesTotal')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.totalDatesDistance}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.datesAdjusted')}
                </div>
            ),
            width: 120,
            Cell: (props) => (
                <div>
                    {props.original.totalEditedDatesDistance}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.datesCheckIn')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.datesWithCheckIn}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.actsCheckIn')}
                </div>
            ),
            width: 150,
            Cell: (props) => (
                <div>
                    {props.original.actsWithCheckIn}
                </div>
            ),
        },
        {
            Header: (
                <div>
                    {getText('reports.timeDistanceCheck.workingDays')}
                </div>
            ),
            width: 120,
            Cell: (props) => (
                <div>
                    {props.original.workDaysCount}
                </div>
            ),
        }
    ];
    if(rights?.["reports.reviseDistanceTimesStaffPush"]?.create){
        columns.unshift({
            Header: (
                <input
                    type="checkbox"
                    className="just-pro__checkbox-list-input"
                    onChange={changeSelectedAllForPush}
                    checked={data.length === selectedForPush.length}
                />
            ),
            width: 50,
            Cell: (props) => (
                <input
                    type="checkbox"
                    className="just-pro__checkbox-list-input"
                    onChange={() => changeSelectedForPush(props.original.id)}
                    checked={selectedForPush.includes(props.original.id)}
                />
            ),
        });
    }
    if (rights?.["reports.reviseDistanceTimesStaffBlock"]?.edit) {
        columns.push({
            Header: (
                <div className='time-distance_lock' onClick={changeIsLockedAll}>
                    <RenderIf condition={data.every((row) => row.blocked)}>
                        <i className="fa fa-lock"/>
                    </RenderIf>
                    <RenderIf condition={!data.every((row) => row.blocked)}>
                        <i className="fa fa-lock-open"/>
                    </RenderIf>
                </div>
            ),
            width: 50,
            Cell: (props) => (
                <div className='time-distance_lock' onClick={() => changeIsLocked(props.original.id)}>
                    <RenderIf condition={props.original.blocked}>
                        <i className="fa fa-lock"/>
                    </RenderIf>
                    <RenderIf condition={!props.original.blocked}>
                        <i className="fa fa-lock-open"/>
                    </RenderIf>
                </div>
            ),
        })
    }
    return columns;
}
