import getFullName from "../../utils/getFullName";
import moment from "moment";
import {getHistoryBody} from "../UI/moduleHistory/templates/historyItem";
import React from "react";

export const NotificationsList = ({notifications, onClick}: any) => (
    <div>
        {notifications && notifications?.map((notification: any) => (
            <div className="notification-task__wrapper">
                <div className="notification-task__header" onClick={() => onClick && onClick(notification)}>
                    <img className="notification-task__avatar"
                         src={notification?.user?.person?.avatar}
                         alt={getFullName(notification?.user?.person)}
                    />
                    <div className="notification-task__user-wrapper">
                        <div className="notification-task__user">{getFullName(notification?.user?.person)}</div>
                        <div className="notification-task__datetime">
                            {moment(notification?.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                        </div>
                    </div>
                    <div className="notification-task__number">
                        {notification?.task?.number}
                    </div>
                </div>
                <div className="notification-task__content">
                    <div className="notification-task__title" dangerouslySetInnerHTML={{
                        __html: notification?.task?.title
                    }}/>
                    {notification && getHistoryBody(notification)}
                </div>
            </div>
        ))}
    </div>
);
