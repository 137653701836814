import React from 'react'
import Table from '../../UI/table/table'
import Button from '../../UI/button/button'
import {
	addInitiator,
	Initiator,
	getContractors,
	deleteInitiator,
	ModulesResponse,
} from '@justpro/terminal'
import withLabel from '../../UI/withLabel/withLabel'
import Select from '../../UI/select/select'
import { LoadReturn, Option } from '../../UI/select/select.types'
import checkError from '../../../utils/checkError'
import { getUniqueList } from '../../../utils/getUniqueArray'
import { ApplicationMapState } from '../../application/application.controller'
import { connect } from 'react-redux'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'

interface RowInitiator extends Initiator {
	isNew?: boolean
}

interface Props {
	personId: number
	initiators?: Initiator[]
	rights?: Partial<ModulesResponse>
}

interface State {
	rows: RowInitiator[]
	hasNewItem: boolean
}

class PersonInitiator extends React.Component<Props, State> {
	state: State = {
		rows: [],
		hasNewItem: false,
	}

	deleteInitiator = async (id: number) => {
		if (id === -1) {
			this.setState((prevState) => ({
				rows: prevState.rows.filter((item) => !item.isNew),
				hasNewItem: false,
			}))
		} else {
			try {
				const deleted = await deleteInitiator({
					personId: this.props.personId,
					initiatorId: id,
				})

				if (deleted) {
					this.setState((prevState) => ({
						rows: prevState.rows.filter((item) => item.id !== id),
					}))
				}
			} catch (e) {
				checkError(e)
			}
		}
	}

	addInitiator = async (option: Option) => {
		try {
			//todo возвращаться должен добавленный инициатор, а не частичный person
			const initiator = await addInitiator({
				personId: this.props.personId,
				initiatorId: option.value,
			})

			this.setState((prevState) => ({
				hasNewItem: false,
				rows: [...prevState.rows.filter((i) => !i.isNew), initiator],
			}))
		} catch (e) {
			checkError(e)
		}
	}

	addRow = () => {
		this.setState((prevState) => ({
			rows: [
				...prevState.rows,
				{
					isNew: true,
					initiatorId: -1,
					active: true,
					name: '',
					brand: {
						id: -1,
						name: '',
						active: true,
					},
					id: -1,
					pseudoname: '',
				},
			],
			hasNewItem: true,
		}))
	}

	getContractors = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const contractors = await getContractors({ q: '' })

			//todo изменить на сервере и тут initiatorId на contractorId

			const result = getUniqueList(contractors, this.state.rows, {
				wholeId: 'id',
				existId: 'initiatorId',
			})

			resolve(result)
		})
	}

	resetState = () => {
		this.setState(() => ({
			hasNewItem: false,
			rows: this.props.initiators ? this.props.initiators : [],
		}))
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		if (
			prevProps.personId !== this.props.personId ||
			prevProps.initiators !== this.props.initiators
		) {
			this.resetState()
		}
	}

	componentDidMount(): void {
		this.resetState()
	}

	get columns() {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('contractors.contractor'),
				// accessor : 'name',
				Cell: (props: any) => {
					const { isNew, name } = props.original

					if (isNew) {
						return (
							<Select
								change={this.addInitiator}
								placeholder='UI.select.placeholders.chooseAnEmployee'
								load={this.getContractors}
								accessors={{
									name: 'label',
									id: 'value',
								}}
							/>
						)
					}

					return name
				},
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			rights['references.persons']['edit']
		) {
			result.push({
				Header: getText('common.delete'),
				// Cell : (props:any) => <>
				//     <Button
				//         className="btn-default btn-xs"
				//         onClick={this.deleteInitiator.bind(this, props.original.id)}
				//     >
				//         <i className='fa fa-trash no-text'/>
				//     </Button></>,

				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deleteInitiator}
						id={props.original.id}
					/>
				),
				accessor: 'description',
			})
		}

		return result
	}

	render() {
		const { initiators, rights } = this.props
		const { rows } = this.state

		return (
			<>
				{withLabel(
					<>
						{rows.length > 0 && (
							<Table columns={this.columns} data={rows} />
						)}

						{rights &&
							rights['references.persons'] &&
							rights['references.persons']['edit'] && (
								<Button
									className={`btn-success ${
										rows.length > 0 ? 'margin-top' : ''
									}`}
									onClick={this.addRow}
									disabled={this.state.hasNewItem}
								>
									{getText('persons.addInitiator')}
								</Button>
							)}
					</>,
				)({ text: 'common.initiator', position: 'left' })}
			</>
		)
	}
}

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

export default connect(mapState)(PersonInitiator)
