import React from "react";
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getBrandsList} from "../../../utils/functions";
import Button from "../../UI/button/button";
import {CreateContractor} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateContractor) : void
}

interface State {
    name : string
    pseudoname : string
    brandId? : number
}

class ContractorCreate extends React.Component<Props, State>{
    state:State = {
        name : '',
        pseudoname : '',
    };

    create = () => {
        const {name, pseudoname, brandId} = this.state;

        if(!this.isDisabled()) {
            const data = {
                name,
                pseudoname,
                brandId: brandId as number,
            };

            this.props.create(data);
        }


    };

    onChangeName = (name:string) => {
        this.setState(() => ({name}))
    };

    onChangePseudonym = (pseudoname:string) => {
        this.setState(() => ({pseudoname}))
    };

    onChangeBrandHandler = (option:Option) => {
        return new Promise (resolve => {
            this.setState(() => ({
                brandId : option.value
            }));

            resolve()
        })
    };


    isDisabled = () => {
        return this.state.name.trim() === '' ||
            this.state.brandId === undefined;
    };


    render () {
        return (
            <>
                <h3>{getText('contractors.addingNewHeader')}</h3>

                <Input
                    change={this.onChangeName}
                    label="contractors.contractor"
                    startValue=""
                />
                <Select
                    change={this.onChangeBrandHandler}
                    load={getBrandsList}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    label="brands.brand"
                />
                <Input
                    change={this.onChangePseudonym}
                    label="common.alias"
                    startValue=""
                />

                <Button className="btn-success" disabled={this.isDisabled()} onClick={this.create}>{getText('common.add')}</Button>

            </>
        )
    }
}

export default ContractorCreate