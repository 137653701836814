import * as api from '@justpro/terminal';
import checkError from "../../utils/checkError";
import {renderToString} from "react-dom/server";
import getText from "../../localization/getText";
import {getFullName} from "../../utils/names";

type SortItem = {
    name: string
}

export const sortByName = (i1: SortItem, i2: SortItem) => {
    return i1?.name.localeCompare(i2?.name)
};

const prepareFilters = (values) => values.filter((v) => v.active)
                                         .map(({id, name}) => ({ id, name }))
                                         .sort(sortByName)


export const getCities = async (filterParams:Partial<api.CitiesFilterParams> = {q : ''}) => {
    try {
        const cities = await api.getCities(filterParams);
        return prepareFilters(cities)
    } catch (e) {
        checkError(e);
    }
};

export const getAreas = async (filterParams:Partial<api.AreasFilterParams> = {q : ''}) => {
    try {
        const areas = await api.getAreas(filterParams);
        return prepareFilters(areas)
    } catch (e) {
        checkError(e);
    }
};

export const getRegions = async (filterParams: Partial<api.RegionFilterParams> = {q : ''}) => {
    try {
        const regions = await api.getRegions(filterParams);
        return prepareFilters(regions)
    } catch (e) {
        checkError(e);
    }
};

export const getContractors = async (filterParams: Partial<api.ContractorsFilterParams> = {q : ''}) => {
    try {
        const contractors = await api.getContractors(filterParams);
        return prepareFilters(contractors)
    } catch (e) {
        checkError(e);
    }
};

export const getEquipmentCategories = async ( filterParams: Partial<api.EquipmentCategoriesFilterParams> = {q : ''}) => {
    try {
        const eqc = await api.getEquipmentCategories(filterParams);
        return prepareFilters(eqc)
    } catch (e) {
        checkError(e);
    }
};


export const getEquipmentWorksFilter = async ( filterParams: Partial<api.EquipmentWorksFilterParams> = {q : ''}) => {
    try {
        const eqw = await api.getEquipmentWorks(filterParams);
        return prepareFilters(eqw)
    } catch (e) {
        checkError(e);
    }
};
export const getEquipmentTypesFilter = async ( filterParams: Partial<api.EquipmentTypesFilterParams> = {q : ''}) => {
    try {
        const eqts = await api.getEquipmentTypes(filterParams);
        return prepareFilters(eqts)
    } catch (e) {
        checkError(e);
    }
};

export const getWorkGroupsFilter = async ( filterParams: Partial<api.WorkGroupsFilterParams> = {q : ''}) => {
    try {
        const workGroups = await api.getWorkGroups(filterParams);
        return prepareFilters(workGroups)
    } catch (e) {
        checkError(e);
    }
};



export const getOrderTypes = async (filterParams: Partial<api.OrdersTypesFilterParams> = {q : ''}) => {
    try {
        const orderTypes = await api.getOrdersTypes(filterParams);
        return prepareFilters(orderTypes)
    } catch (e) {
        checkError(e);
    }
};

export const getContractConditions = async (filterParams: Partial<api.ContractConditionsFilterParams> = {q : ''}) => {
    try {
        const cc = await api.getContractConditions(filterParams);
        return prepareFilters(cc)
    } catch (e) {
        checkError(e);
    }
};

export const getContracts = async (filterParams: Partial<api.ContractsFilterParams> = {q : ''}) => {
    try {
        const contracts = await api.getContracts(filterParams);
        return contracts?.filter(c => c.active).sort(sortByName)
    } catch (e) {
        checkError(e);
    }
};

export const getContractsAliases = async (filterParams: Partial<api.ContractsFilterParams> = {q : ''}, contracts?:api.Contract[]) => {
    try {
        let c = [];

        if(contracts) {
            c = contracts
        }else {
            c = await api.getContracts(filterParams);
        }

        const result =  c?.reduce( (acc:any[], contract:api.Contract) => {

            const values = contract?.ordersTypes && contract?.ordersTypes?.reduce((orderTypes:any[], orderType) => {
                let isSet = false;
                for (let i = 0; i < acc.length; i++) {
                    if(acc[i].id === orderType.id) {
                        isSet = true;
                        break;
                    }
                }

                acc.filter(item => item.id != orderType.id);

                if(isSet) {
                    return acc
                }else{
                    return [...orderTypes, {
                        id : orderType.id,
                        name : orderType.alias || orderType.name
                    }]
                }
            }, []) || [];
            if(values) {
                return [...acc, ...values]
            }else{
                return acc
            }
        }, []) || []

        console.log({result})

        return result;


    } catch (e) {
        checkError(e);
    }
};

export const getUrgencyOrders = () => {
    return [
        {
            id : 1,
            name : renderToString((getText('UI.filters.urgencyOrders.all')))
        },
        {
            id : 2,
            name : renderToString((getText('UI.filters.urgencyOrders.hot')))
        },
        {
            id : 3,
            name : renderToString((getText('UI.filters.urgencyOrders.expired')))
        },
    ]
};

export const getIsMaster = () => {
    return [
        {
            id : 1,
            name : renderToString((getText('common.yes')))
        },
        {
            id : 0,
            name : renderToString((getText('common.no')))
        },
    ]
};

export const getMasters = async (filterParams: Partial<api.MastersFilterParams>) => {
    try {
        const persons = await api.getMasters(filterParams);
        return persons?.map(master => ({
            id: master.id,
            name : getFullName(master.person),
        })).sort(sortByName)
    }catch (e) {
        checkError(e)
    }
};

export const getStatusGroups = async () => {
    try {
        const statusGroups = await api.getStatusGroups();
        return statusGroups?.filter(i => i.active)
    } catch (e) {
        checkError(e);
    }
};

export const getTags = async (filterParams: Partial<api.TagsFilterParams> = {q : ''}) => {
    try {
        const tags = await api.getTags(filterParams);
        return tags?.filter(t => t.active)?.map(({id, name, icon, color}) => ({
            id,
            name,
            icon,
            color,
            excludeItem: {
                id,
                icon,
                color,
                name: `${renderToString(getText('common.allExcept'))} ${name}`,
                excludeKey: api.filterParams.EXCLUDE_TAGS
            }
        }))?.sort(sortByName)
    }catch (e) {
        checkError(e)
    }
};


export const getPersons = async (filterParams: Partial<api.PersonsFilterParams>) => {
    try {
        const persons = await api.getPersons(filterParams);
        return persons?.map(person => ({
            ...person,
            name : getFullName(person),
        })).sort(sortByName)
    }catch (e) {
        checkError(e)
    }
};



export const getActPhases = async () => {
    try {
        const phases = await api.getActPhases();
        return phases;
    }catch (e) {
        checkError(e)
    }
};

export const getTasksStates = async () => {
    try {
        const states = await api.getTaskStates();
        return states;
    }catch (e) {
        checkError(e)
    }
};

export const getRegularWorkStatuses = async (filters:api.RegularStatusesFilterParams = {q : ""}) => {
    try {
        const statuses = await api.getRegularStatuses(filters);
        return statuses;
    }catch (e) {
        checkError(e)
    }
};

