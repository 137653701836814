import React from "react";
import {IAct} from "@justpro/terminal";
import ItemList from "../UI/itemsList/itemList.controller";
import ActListItem from "../acts/act/act.listItem";
import './workOrders.css'


interface Props {
    acts : IAct[]
}

class WorkOrderNewActsModal extends React.Component<Props>{

    renderItem = (act:IAct) => {
        return <div className="workorder-list-act">
            <ActListItem
                withAngle={{
                    // color : 'default'
                }}
                withModuleDetail={true}
                act={act}
            />
        </div>
    };

    render() {
        return <ItemList loading={false} renderItem={this.renderItem} list={this.props.acts}/>
    }
}

export default WorkOrderNewActsModal