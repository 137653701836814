import React from "react";
import {getRegion, Region, editRegion, UpdateRegion} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import RegionDetail from "./region.detail";


interface Props {
    id: number
    afterUpdate?(region:Region) : void
}

interface State {
    region? : Region
    loading : boolean
}

class RegionController extends React.Component<Props, State>{
    state:State = {
        loading : false,
    };


    getRegion = async () => {

        try {
            this.setState(() => ({loading : true}));

            const region = await getRegion(this.props.id);

            this.setState(() => ({loading : false, region}))

        }catch (e) {
            checkError(e)
        }
    };

    updateRegion = async (data:Partial<UpdateRegion>) => {

        try {
            this.setState(() => ({loading : true}));

            const region = await editRegion(this.props.id, data);

            this.setState(() => ({
                region : region,
                loading : false
            }));

            this.props.afterUpdate && this.props.afterUpdate(region)
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getRegion()
        }
    }

    async componentDidMount() {
        this.getRegion();
    }

    render () {
        const {region} = this.state;
        return (
            <>
                <RenderIf condition={region !== undefined}>
                    <RegionDetail region={region as Region} updateRegion={this.updateRegion}/>
                </RenderIf>
            </>
        )
    }
}

export default RegionController