import React from 'react';
import Table from "../../UI/table/table";
import moment from "moment";
import getText from "../../../localization/getText";
import checkError from "../../../utils/checkError";
import './mileage.css'


export interface Data {
    date: string
    distance?: string
    rowId: number
    id?: number
}

interface Props {
    data: Data[]

    arrow? : {
        position: 'left' | 'right',
        handler() : void
    }

    // rightArrow? : boolean
    // leftArrow? : boolean

    //date format YYYY-MM-DD
    onChangeDistance(rowId: number, distance: string, date: string, id?: number): Promise<boolean>
}


interface State {
    total: number
}

export type EditTextType = 'create' | 'edit'

class ActsMileageMonth extends React.Component<Props> {


    state: State = {
        total: 0
    };

    get columns() {
        const {arrow, data} = this.props;
        const header = <>
            {arrow &&
                <div onClick={arrow.handler} className={`table-header-arrow ${arrow.position === 'left' ?  "--left" : "--right"}`} />
            }
            {moment(this.props.data?.[0]?.date).format('MMMM YYYY')}
        </>;
        return [
            {
                Header: header,
                // Cell : (props:any) => <span>{header}</span>,
                columns: [
                    {
                        Header: getText('common.date'),
                        Cell: (props: any) => <span>{moment(props.original.date).format('DD.MM.YYYY')}</span>,
                        Footer: (
                            <span className="mileage-footer">
                                {getText('common.days')}: {data?.reduce((acc, item) => {
                                    if(item.distance) {
                                        return acc += 1
                                    }
                                    return acc
                            }, 0)}

                            </span>
                        )
                    },
                    {
                        Header: getText('common.distance'),
                        Cell: (props: any) => {
                            const val = props.original.distance;
                            return (
                                <input className="act-mileage__input" defaultValue={val}
                                       onBlur={(event: any) => {
                                           const text = event.target.value;
                                           if(text && text?.trim() !== '') {
                                               return this.change(props, text)
                                           }else{
                                               checkError(new Error('errors.valueCannotBeEmpty'));
                                               return false
                                           }
                                       }}/>
                            )
                        },
                        Footer: (
                            <span className="mileage-footer">
                                {getText('common.total')} {this.state.total}
                            </span>
                        )
                    },

                ]
            },

        ]
    }

    change = async (props: any, text: string) => {
        const {id, date, rowId} = props.original;
        const result = await this.props.onChangeDistance(rowId, text, date, id);

        if (result) {
            this.recalcTotal();
        }
    };

    recalcTotal = () => {
        const total = this.props.data.reduce((acc: number, item) => {
            return acc += item.distance ? +item.distance : 0
        }, 0);

        this.setState(() => ({total}))
    };


    componentDidMount(): void {
        this.recalcTotal();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.recalcTotal();
        }
    }

    render() {

        return <Table
            columns={this.columns}
            data={this.props.data}
        />
    }
}

export default ActsMileageMonth