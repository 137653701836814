import React from 'react';
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import ModuleTextName from '../UI/moduleTextName/moduleTextName';
import {restReferences, equipmentReferences, statusesReferences, geographyReferences, staffReferences} from '../withPrivateRoute/withPrivateRoute.controller'
import {Reference, RefTitle} from './mainReference.view'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {ApplicationMapState} from "../application/application.controller";
import {MainNavList} from "../mainNav/mainNav.types";
import {ModulesResponse} from "@justpro/terminal";
import GetText from "../../localization/getText";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";


interface Props {
    rights?: Partial<ModulesResponse>
}

class MainReferenceController extends React.Component<Props> {

    renderReference = (list:MainNavList, name:string) => {
        const {rights} = this.props;

        const availableList = list.reduce((acc:MainNavList, item) => {

            if(rights && item.isAvailable && item.isAvailable(rights as ModulesResponse)) {
                acc.push(item)
            }
            return acc
        }, []);

        return availableList.length > 0 ? (
            <>
                <h4>{name}:</h4>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap : 'wrap', justifyContent : 'flex-start'}}>
                    {availableList.map(({icon, title, path, completeStatus, isAvailable}, index) => {
                        return (
                            <Link to={path ? path : ''} key={index}>
                                <Reference className={completeStatus ? completeStatus : ''}>
                                    <RefTitle className="text-center">{GetText(title || '')}</RefTitle>
                                </Reference>
                            </Link>
                        )
                    })}
                </div>
            </>
        ) : null;

    };

    render () {
        return (
            <WithPrivateRoute>

                <div className="top-line">
                    <ModuleTextName>{GetText('common.references')}</ModuleTextName>
                </div>

                <div className="content">
                    <div className="just-pro__scroll_panel">
                        {this.renderReference(equipmentReferences, renderToString(getText('equipments.equipment')))}
                        {this.renderReference(geographyReferences, renderToString(getText('common.geography')))}
                        {this.renderReference(statusesReferences, renderToString(getText('common.statuses')))}
                        {this.renderReference(staffReferences, renderToString(getText('common.staff')))}
                        {this.renderReference(restReferences, renderToString(getText('common.restReferences')))}
                    </div>

                </div>
            </WithPrivateRoute>
        )

    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(MainReferenceController)
