import React from "react";
import Input from "../../UI/input/text";
import {Brand, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    brand : Brand
    updateBrand(data:Partial<Brand>):void
    rights?: Partial<ModulesResponse>
}

class BrandDetail extends React.Component<Props> {
    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.updateBrand({name})
        }
    };

    changeActive = (active: boolean) => {
        this.props.updateBrand({active});
    };

    changePushes = (pushes: boolean) => {
        this.props.updateBrand({pushes});
    };

    changeOrdersPhotoReport = (ordersPhotoReport: boolean) => {
        this.props.updateBrand({ ordersPhotoReport });
    };

    changeRegularWorksPhotoReport = (regularWorksPhotoReport: boolean) => {
        this.props.updateBrand({ regularWorksPhotoReport });
    };

    render () {
        const {brand, rights} = this.props;
        const {name, active, pushes, ordersPhotoReport, regularWorksPhotoReport} = brand;
        const disabled = rights && rights['references.brands'] && !rights['references.brands']['edit'];
        return (
            <>
                <h3>{getText('brands.brand')}: {name}</h3>

                <ToggleSwitch
                    send={this.changeActive}
                    defaultValue={active}
                    label="common.active"
                    disabled={disabled}
                />

                <ToggleSwitch
                    send={this.changePushes}
                    defaultValue={pushes}
                    label="brands.pushes"
                    disabled={disabled}
                />

                <ToggleSwitch
                    send={this.changeOrdersPhotoReport}
                    defaultValue={ordersPhotoReport}
                    label="brands.ordersPhotoReport"
                    disabled={disabled}
                />

                <ToggleSwitch
                    send={this.changeRegularWorksPhotoReport}
                    defaultValue={regularWorksPhotoReport}
                    label="brands.regularWorksPhotoReport"
                    disabled={disabled}
                />

                <Input
                    label="brands.brand"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />
            </>
        )
    }
}

const mapStateToProps = (state:ApplicationMapState) => ({
    rights : state.application.rights,
});

export default connect(mapStateToProps)(BrandDetail)