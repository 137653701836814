import React from 'react';
import Input from "../../UI/input/text";
import Table from "../../UI/table/table";
import Button from "../../UI/button/button";
import Select from "../../UI/select/select";
import IconPicker from "../../UI/iconPiker/iconPicker.controller";

import {LoadReturn, Option} from "../../UI/select/select.types";
import {
    addStatusTransfer,
    getStatuses, ModulesResponse,
    OrderStatus,
    removeStatusTransfer,
    UpdateStatus
} from "@justpro/terminal";
import withLabel from "../../UI/withLabel/withLabel";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {fromClassName, toClassName} from '../../../utils/icons'
import {getUniqueList} from "../../../utils/getUniqueArray";
import checkError from "../../../utils/checkError";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";

type Props = {
    icons : string[]
    orderStatus : OrderStatus
    updateStatus(data:Partial<UpdateStatus>) : void

    rights?: Partial<ModulesResponse>
};

interface State {
    transferStatuses : OrderStatus[],
    transferLoading : boolean
}

class OrderStatusDetail extends React.Component<Props, State> {

    state:State = {
        transferStatuses : [],
        transferLoading : false
    };

    onBlurName = async (name: string, oldValue: string) => {
        if(name !== oldValue) {
            await this.props.updateStatus({name})
        }
    };

    removeTransferStatus = async (props:any) => {
        const {id} = props.original;
        if(id) {
            this.setState(() => ({transferLoading : true}));

            try {
                const removed = removeStatusTransfer(this.props.orderStatus.id, id);

                if(removed) {
                    this.setState((prevState) => ({
                        transferStatuses : prevState.transferStatuses.filter(item => item.id !== id),
                        transferLoading : false
                    }))
                }
            }catch (e) {
                this.setState(() => ({transferLoading : false}));
                checkError(e)
            }
        }
    };

    getTransferStatuses = ():LoadReturn => {
        return new Promise(async resolve => {
            try {
                const wholeStatusesList = await getStatuses();
                const unique = getUniqueList(wholeStatusesList, this.state.transferStatuses);
                const withoutSelf = unique.filter(item => item.id !== this.props.orderStatus.id);

                resolve(withoutSelf)

            }catch (e) {
                checkError(e)
            }
        })
    };


    addTransferStatus = async (option:Option) => {
        this.setState(() => ({transferLoading : true}));
        try {
            const status = await addStatusTransfer(this.props.orderStatus.id, option.value);

            this.setState((prevState) => ({
                transferLoading : false,
                transferStatuses : [...prevState.transferStatuses, status]
            }))

        }catch (e) {
            this.setState(() => ({transferLoading : false}));
            checkError(e)
        }

        return Promise.resolve();
    };

    toggleActiveStatus = (active:boolean) => {
         this.props.updateStatus({active})
    };

    onChangeIcon = (icon:string) => {
        const [prefix, iconName ] = fromClassName(icon);
        this.props.updateStatus({icon : `${prefix}:${iconName}`})

    };

    resetState = () => {
        this.setState(() => ({
            transferStatuses : this.props.orderStatus.transfers ? this.props.orderStatus.transfers : [],
            transferLoading : false
        }))
    };

    componentDidMount(): void {
        this.resetState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.orderStatus.id !== prevProps.orderStatus.id) {
            this.resetState()
        }
    }

    get columns() {
        const {rights} = this.props;
        const result:any[] = [
            {
                Header : getText('common.name'),
                accessor : 'name'
            }
        ];

        if(rights && rights['references.statuses'] && rights['references.statuses']['edit']) {
            result.push(
                {
                    Header : getText('common.delete'),
                    Cell : (props:any) => <DeleteCell deleteHandler={this.removeTransferStatus} item={props} />
                }
            )
        }

        return result;
    }


    render () {
        const {orderStatus, rights} = this.props;
        const {transferStatuses} = this.state;

        const {name, icon, active, group} = orderStatus;
        const className = toClassName(icon);
        const disabled = rights && rights['references.statuses'] && !rights['references.statuses']['edit'];

        return (
            <>
                <h3>{getText('orderStatuses.orderStatus')}: {name}</h3>

                <ToggleSwitch
                    defaultValue={active}
                    send={this.toggleActiveStatus}
                    label="common.active"
                    disabled={disabled}
                />

                <Input
                    label="orderStatuses.orderStatus"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <IconPicker
                    change={this.onChangeIcon}
                    list={this.props.icons}
                    label="common.icon"
                    defaultValue={className}
                    disabled={disabled}
                />

                {withLabel(
                    <>
                        {transferStatuses && transferStatuses.length > 0 &&
                            <Table
                                columns={this.columns}
                                data={transferStatuses}
                                loading={this.state.transferLoading}
                                minRows={0}
                                showPagination={false}
                            />
                        }
                        {!disabled &&
                            <Select
                                change={this.addTransferStatus}
                                load={this.getTransferStatuses}
                                withoutValue={true}
                                className="margin-top"
                                placeholder="UI.select.placeholders.addTransferStatus"
                                accessors={{
                                    name : 'label',
                                    id : 'value'
                                }}
                            />
                        }
                    </>
                )({
                    text : "orderStatuses.transferToStatuses",
                    indent : false
                })}

                {withLabel(
                    <>{group ? group.name : getText('orderStatuses.doNotEnterInAnyGroup')}</>
                )({text : 'Группа'})}


            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(OrderStatusDetail)