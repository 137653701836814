import React,{ Component } from 'react';
import { connect } from 'react-redux';
import BaseReferenceController from './../baseReference/baseReference.controller';
import SettingController from './globalSetting.controller';
import {ModulesResponse, getGlobalSettings} from "@justpro/terminal";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import "./globalSettings.css";

interface Props {
  rights?: Partial<ModulesResponse>;
}

class GlobalSettingsController extends Component<Props> {
  renderItem = (item: any) => <>{item.pseudoname}</>;

  getSettings = async (filterParams: any) => {
    const settings = await getGlobalSettings(filterParams);
    return settings.map((setting) => ({ ...setting, active: true }));
  };

  render() {
    const rights = this.props.rights && this.props.rights['references.tags'];
    return (
      <BaseReferenceController
        referenceName={renderToString(getText('globalSettings.name'))}
        listItem={{
          render: this.renderItem,
        }}
        moduleClass="general-settings__module"
        getList={this.getSettings}
        Detail={SettingController}
        moduleRights={rights}
      />
    );
  }
}

const mapState = (state: any) => ({
  rights: state.application.rights,
});

export default connect(mapState)(GlobalSettingsController);
