import React from "react";
import './hasNoRights.css'

export default function HasNoRightsController() {
    return (
        <div className="has-no-rights">
            У вас нет прав на просмотр этой части программы. Обратитесь к администратору.
        </div>
    )
}

