import {AnyAction} from "redux";
import {User} from "@justpro/terminal";

export interface ListReducer {
    users? : User[],
    // activeUserId?: number
}

const initialState:ListReducer = {};

export const listTypes = {
    GET_USERS_LIST: 'GET_USERS_LIST',
};

export default (state:ListReducer = initialState, action: AnyAction) => {
    const {type, ...rest} = action;

    switch (type) {
        case listTypes.GET_USERS_LIST :
            return Object.assign({}, state, rest);
        default :
            return state;
    }
}