import FancyBox from "../fancybox_new/fancybox.controller";
import React from "react";
import {File as ServerFile} from '@justpro/terminal'
import {download} from "../../../utils/fileDownload";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
// import FileDownload from "js-file-download";

interface FileProps {
    file : ServerFile,
    files? : ServerFile[]

    withDelete? : {
        handler(id:number): any
    }
}

const FilePreview = (props:FileProps) => {

    const saveFile = (file: ServerFile, event: any) => {
        event.preventDefault();

        console.log({file})

        download(file.path, file.originalName || renderToString(getText("common.files")))

        // FileDownload(file, file.originalName as string);
    }

    const getExtension = (file:ServerFile) => {
        const splited = file.originalName ? file.originalName.split('.') : [];
        return splited[splited.length -1];

    }

    const {file} = props;

    const ext = getExtension(file);

    switch (ext) {
        case 'xlsx':
        case 'xls':
            return (
                <div className="just-pro__file">
                    <i className="fas fa-file-excel-o" style={{color : 'green'}}/>
                    <a download={file.originalName} onClick={saveFile.bind(null, file)} target="_blank" href={file.path}>{file.originalName && file.originalName}</a>
                </div>
            );
        case 'pdf' :
            return (
                <div className="just-pro__file">
                    <i className="fas fa-file-pdf-o" style={{color : 'red'}}/>
                    <a download={file.originalName} onClick={saveFile.bind(null, file)} target="_blank" href={file.path}>{file.originalName && file.originalName}</a>
                </div>
            );
        case 'jpg' :
        case 'jpeg' :
        case 'png' :
        case 'svg' :
        case 'webp' :
        case 'gif' :

            return (
                <div className="just-pro__file">
                    <FancyBox withDelete={props.withDelete} file={file} imageMode="named" images={props.files?.filter(file => {

                        const ext = getExtension(file);

                        return  ext === "jpg" ||
                                ext === "jpeg" ||
                                ext === "gif" ||
                                ext === "webp" ||
                                ext === "svg" ||
                                ext ===  "png"
                    })}/>
                </div>
            );

        default :
            return (
                <div className="just-pro__file">
                    <i className="fas fa-question" />
                    <a download={file.originalName} href={file.path} onClick={saveFile.bind(null, file)}>{file.originalName && file.originalName}</a>
                </div>
            );

    }
};

export default FilePreview
