import React, {PureComponent} from 'react';
import {InfoWindow} from 'react-google-maps';
import WithModuleDetail from "../../../UI/dialog/withModuleDetail/withModuleDetail.controller";
import InfoBlock from '../../../UI/infoBlock/infoBlock.controller';
import {IClickedInnerProps, IObjectInnerProps, IHoveredInnerProps} from './innerWindow.types';
import getText from "../../../../localization/getText";
import {connect} from 'react-redux';
import {
    updateTouched,
    setIsWoCreatorOpen,
    setOpenedObject,
    setWoArray,
    pushToWoArray,
    removeFromWoArray
} from '../../../../store/map/map.actions';
import OrderDetail from "../../../orders/detail/order.detail";
import {removeWorkorderOrders, getOrder, getObjectName, getShortObjectName} from "@justpro/terminal";
import {Provider} from "react-redux";
import store from "../../../../store";
import RenderIf from "../../../../utils/renderIf";
import checkError from "../../../../utils/checkError";
import Tooltip from "../../../UI/tooltip/tooltip.controller";
import getFullName from '../../../../utils/getFullName';
import moment from "moment";

class ObjectInfoWindow extends PureComponent<IObjectInnerProps> {
    state = {
        orders: [],
        openedObject: null
    }

    componentDidMount() {
        const el = document.getElementById('maps')
        el.addEventListener('click', this.closeOnMapClick, true)
        this.setState({
            openedObject: this.props.object,
            orders: this.props.object?.orders?.sort((a, b) => b.hot - a.hot).sort((a, b) => b.expired - a.expired)
        })
    }

    componentWillUnmount() {
        const el = document.getElementById('maps')
        el?.removeEventListener('click', this.closeOnMapClick)
    }

    closeOnMapClick = (e) => {
        if (!e.target.closest('.unclosable')) {
            this.props.discardClicked()
            this.setState({
                openedObject: null
            })
        }
    }

    deleteOrder = async (woId, orderId) => {
        try {
            const deleted = await removeWorkorderOrders(woId, [orderId]);
            console.log(deleted)
            const deletedOrder = await getOrder(orderId)
            const i = this.state.orders?.findIndex(order => order.id == deletedOrder.id)
            console.log(i)
            if (this.state.orders[i]) {
                this.setState((prevState: any) => ({
                    ...prevState.orders[i] = deletedOrder
                }))
            }
        } catch (e) {
            checkError(e)
        }
    };

    handleWoArray = (checkboxValue, order, currentEl, object) => {
        const {
            setTouched, touched, woArray, pushToWoArray,
            removeFromWoArray, setIsWoCreatorOpen
        } = this.props;
        if (checkboxValue) {
            pushToWoArray({...order, latitude: object?.latitude, longitude: object?.longitude})
        } else {
            removeFromWoArray(woArray.findIndex((el) => el.number == currentEl?.number))
        }
        if (!touched) {
            setTouched(true)
            setIsWoCreatorOpen(true)
        }
    }

    render() {
        const {
            object,
            position,
            discardClicked,
            woArray,
            ordersCanWorkorderStatus
        } = this.props;
        const {orders} = this.state;
        return (
            <InfoWindow
                position={{lat: position?.lat, lng: position?.lng}}
                onCloseClick={discardClicked}
            >
                <div className='unclosable'>
                    <h2>{getObjectName(object)}</h2>
                    {orders?.map((order, i) => {
                        const currentEl = Array.isArray(woArray) && woArray.find((o, i) => o.number == order.number)
                        return (
                            <div key={i} className={
                                `object-inner__order-wrapper ${
                                    !order.workorders?.[0]?.id || ordersCanWorkorderStatus === order.orderStatus.id ?
                                        'object-inner__order-wrapper__full' : ''}`
                            }>
                                {ordersCanWorkorderStatus == order.orderStatus.id &&
                                <input
                                    type="checkbox"
                                    checked={!!currentEl}
                                    id={order.id.toString()}
                                    onChange={(e) => {
                                        this.handleWoArray(e.target.checked, order, currentEl, object)
                                    }}
                                />}
                                <WithModuleDetail
                                    element={
                                        <>
                                            <RenderIf condition={order.expired}>
                                                <Tooltip title="UI.itemList.expiredTime">
                                                    <i className="fa fa-fw fa-fire" style={{color: 'red'}}/>
                                                </Tooltip>
                                            </RenderIf>
                                            <RenderIf condition={order.hot}>
                                                <Tooltip title="UI.itemList.expiredTime">
                                                    <i className="fa fa-fw fa-fire" style={{color: 'orange'}}/>
                                                </Tooltip>
                                            </RenderIf>
                                            <span
                                                className="objectInner_label"
                                                style={{
                                                    color: order.contractOrdersType?.color
                                                }}
                                            >
                                                {order?.number}
                                            </span>
                                            <span
                                                className="objectInner_text"
                                            >
                                                {order?.content}
                                            </span>
                                        </>
                                    }
                                    modal={{
                                        id: 'orderListItem',
                                        component: () => (
                                            <OrderDetail id={order.id}/>
                                        ),
                                        title: getText('orders.order'),
                                    }}
                                />
                                <RenderIf
                                    condition={ordersCanWorkorderStatus != order.orderStatus.id && order.workorders?.[0]?.id}>
                                    <>
                                        <Tooltip title="UI.itemList.deleteOrder">
                                            <i onClick={() => this.deleteOrder(order.workorders?.[0]?.id, order.id)}
                                               className="fa fa-times-circle" style={{color: 'red'}}/>
                                        </Tooltip>
                                        <span
                                            className="objectInner_responsible">{order?.workorders?.[0]?.responsible && getFullName(order?.workorders?.[0]?.responsible)}</span>
                                        <span>{order?.workorders?.[0]?.date ? moment(order?.workorders?.[0]?.date).format("DD/MM") : ''}</span>
                                    </>
                                </RenderIf>
                            </div>
                        )
                    })}
                </div>
            </InfoWindow>
        )
    }
}


let mapStateToProps = (state) => {
    return {
        touched: state?.maps?.touched,
        isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
        woArray: state?.maps?.woArray,
        ordersCanWorkorderStatus: state.application.settings.ordersCanWorkorderStatus
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        setTouched: (touched) => {
            dispatch(updateTouched(touched));
        },
        setIsWoCreatorOpen: (isOpen) => {
            dispatch(setIsWoCreatorOpen(isOpen));
        },
        setOpenedObject: (object) => {
            dispatch(setOpenedObject(object));
        },
        setWoArray: (object) => {
            dispatch(setWoArray(object));
        },
        pushToWoArray: (el) => {
            dispatch(pushToWoArray(el))
        },
        removeFromWoArray: (index) => {
            dispatch(removeFromWoArray(index));
        }
    };
};

const ConnectedInfoWindow = connect(mapStateToProps, mapDispatchToProps)(ObjectInfoWindow)
export const ProvideredConnectedInfoWindow = (props) => (
    <Provider store={store}>
        <ConnectedInfoWindow {...props} />
    </Provider>
)
export default ProvideredConnectedInfoWindow

export const ObjectDisplayName: React.FC<IHoveredInnerProps> = ({object, hovered, showNames}) => {
    return (
        <div className='object-inner_caption'>
            {hovered && !showNames &&
            <>
                <div className="object-inner_dash"></div>
                <p className="object-inner_name"> {getObjectName(object)}</p>
            </>
            }
            {showNames &&
            <>
                <div className="object-inner_dash"></div>
                <p className="object-inner_name"> {getShortObjectName(object)}</p>
            </>
            }
        </div>
    )
}
interface BasePointDisplayNameProps extends IHoveredInnerProps{
    executor: any
}
export const BasePointDisplayName: React.FC<BasePointDisplayNameProps> = ({executor, hovered}) => {
    return (
        <div className='object-inner_caption'>
            {hovered &&
            <>
                <div className="object-inner_dash"></div>
                <p className="object-inner_name"> {getFullName(executor)}</p>
            </>
            }
        </div>
    )
}
