import React, {Component, useMemo} from "react";
import {
    delay, FILE, FOLDER, ROOT_FOLDER_ID, CustomFile, getFoldersMap, getFilesMap,
    Folder, FoldersMap, CustomFilesMap, DEFAULT_FILENAME, DEFAULT_FOLDER_NAME,
    createNewFile, createNewFolder, updateFolder, updateFile,
    moveFolder, moveFile, deleteFolder, deleteFile, copyFile
} from "@justpro/terminal";
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import ModuleTextName from "../UI/moduleTextName/moduleTextName";
import RenderIf from "../../utils/renderIf";
import Tooltip from "../UI/tooltip/tooltip.controller";
import Button from "../UI/button/button";
import HeaderController from "../header/header.controller";
import {connect} from "react-redux";
import Spinner from "../UI/spinner/spinner.controller";
import {Link, NavLink, Route} from "react-router-dom";
import FileController from "./file.controller";
import Search from "../UI/search/search.controller";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import "./knowledge.css";
import TreeController, {TreeItem} from "../UI/tree_new/tree.controller";
import Caret from "../UI/caret/caret";
import ContextMenu from "../UI/contextMenu/contextMenu";
import ChangeRightsModal from "./rightsModal";
import checkError from "../../utils/checkError";
import SearchFilesController from "./searchFiles.controller";
import {Modal} from "../../store/modal/modal.types";
import {openModal} from "../../store/modal/modal.actions";

const ITEM_NAME_SEPARATOR = '-';

// move to terminal

export interface FrontFolder {
    id: number
    name: string
    type: "folder" | "file"
    children?: FrontFolder[]
    files?: CustomFile[]
    parentFolderId: number
}

interface FrontFile extends CustomFile {
    parentFolderId: number
    type: "folder" | "file"
}

interface Props {
    openModal(props: Modal): void

    rights: any
}

interface State {
    selectedFile: any
    loading: boolean
    mainFolder?: FrontFolder
    openedFolders: number[]
    renamingItemId?: string
    selectedItemName?: string
}

const searchExtensions = [
    {
        name: 'Поиск по содержанию и названию',
        id: 1,
        icon: 'fa fa-expand'
    },
    {
        name: 'Поиск только по названию',
        id: 2,
        icon: 'fa fa-compress'
    },
];

const MAX_CALL_NORMALIZE_FOLDERS = 100;
const normalizeFolders = (folder: Folder | FrontFolder, foldersMap: FoldersMap, filesMap: CustomFilesMap, callCount = 0) => {
    const {children, files, ...otherFolder}: any = folder;
    if (callCount > MAX_CALL_NORMALIZE_FOLDERS) return folder;
    if (children?.length) {
        otherFolder.children = children?.map((childId: number) => {
            const child = foldersMap[childId];
            if (!child) return null;
            child.id = childId;
            const normalized = normalizeFolders(child, foldersMap, filesMap, ++callCount);
            normalized.parentFolderId = otherFolder.id;
            return normalized;
        })?.filter((f: any) => f)?.sort((a: Folder, b: Folder) => {
            return a.name.localeCompare(b.name)
        });
    }
    otherFolder.type = FOLDER;
    otherFolder.isOpen = false;
    otherFolder.files = files && files.map((fileId: number) => {
        if (!filesMap[fileId]) return null;
        return {
            id: fileId,
            type: FILE,
            ...filesMap[fileId],
            parentFolderId: otherFolder.id
        } || null;
    }).filter((f: any) => f).sort((a, b) => {
        return a.name.localeCompare(b.name)
    }) || [];
    return otherFolder;
};

class KnowledgeController extends Component<Props, State> {
    state: State = {
        selectedFile: null,
        loading: false,
        openedFolders: []
    }

    foldersMap: FoldersMap = {};
    customFilesMap: CustomFilesMap = {};

    async componentDidMount() {
        try {
            this.setState({
                loading: true
            });
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    updateAll = async () => {
        this.foldersMap = await getFoldersMap();
        this.customFilesMap = await getFilesMap();
        const mainFolder = normalizeFolders({
            id: ROOT_FOLDER_ID,
            ...this.foldersMap[ROOT_FOLDER_ID]
        }, this.foldersMap, this.customFilesMap);
        this.setState({
            mainFolder
        });
    }

    createNewFile = async (folder: FrontFolder) => {
        try {
            this.setState({
                loading: true
            });
            const newFile = await createNewFile(folder?.id, DEFAULT_FILENAME);
            await this.updateAll();
            this.setState({
                renamingItemId: FILE + ITEM_NAME_SEPARATOR + newFile?.id,
                selectedItemName: newFile?.name
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    createNewFolder = async (folder: FrontFolder) => {
        const DEFAULT_FOLDER_NAME = 'Новая папка';
        try {
            this.setState({
                loading: true
            });
            const newFolder = await createNewFolder(folder?.id, DEFAULT_FOLDER_NAME);
            await this.updateAll();
            this.setState({
                renamingItemId: FOLDER + ITEM_NAME_SEPARATOR + newFolder?.id,
                selectedItemName: newFolder?.name
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    moveFolder = async (folder: FrontFolder, folderId: number) => {
        try {
            this.setState({
                loading: true
            });
            if (!folder?.id || isNaN(+folder?.parentFolderId)) return;
            await moveFolder(folder?.id, folder?.parentFolderId, folderId);
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    moveFile = async (file: FrontFile, folderId: number) => {
        try {
            this.setState({
                loading: true
            });
            if (!file?.id || isNaN(+file?.parentFolderId)) return;
            await moveFile(file?.id, file?.parentFolderId, folderId);
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    copyFile = async (file: CustomFile, folderId: number) => {
        try {
            this.setState({
                loading: true
            });
            if (!file?.id) return;
            await copyFile(file?.id, folderId);
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    togglePublishFile = async (file: CustomFile) => {
        try {
            if (!file?.id) return;
            this.setState({
                loading: true
            });
            await updateFile(file.id, {
                published: !file.published
            });
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    deleteFolder = async (folder: FrontFolder) => {
        try {
            this.setState({
                loading: true
            });
            await deleteFolder(folder?.id);
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }


    deleteFile = async (file: FrontFile) => {
        try {
            this.setState({
                loading: true
            });
            if (!file?.id) return;
            await deleteFile(file?.id, file?.parentFolderId);
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    renameItem = async () => {
        try {
            const [type, id] = this.state.renamingItemId?.split(ITEM_NAME_SEPARATOR);
            const {selectedItemName} = this.state;
            if (!id) return;
            let item = null;
            if (type === FOLDER) {
                item = this.foldersMap[+id];
            }
            if (type === FILE) {
                item = this.customFilesMap[+id];
            }
            const isEqual = (item?.name === selectedItemName);
            this.setState({
                renamingItemId: void 0,
                selectedItemName: void 0
            });
            if (!item || isEqual || !selectedItemName?.length) return;
            this.setState({
                loading: true
            });
            if (type === FOLDER) {
                await updateFolder(+id, {
                    name: selectedItemName
                })
            }
            if (type === FILE) {
                await updateFile(+id, {
                    name: selectedItemName
                })
            }
            ;
            await this.updateAll();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    getContextItems(item: any) {
        const {rights} = this.props;
        const actions = [];
        if (rights?.knowledge?.edit && item?.id !== 0 && item?.userRights?.edit) {
            actions.push({
                name: 'Редактировать права',
                id: 10,
                handler: this.openEditRightsModal.bind(this, item)
            });
        }
        if (rights?.knowledge?.create && item?.userRights?.create && item?.type === FOLDER) {
            actions.push({
                name: 'Создать',
                id: 11,
                subMenuItems: [{
                    name: 'Создать папку',
                    id: 111,
                    handler: this.createNewFolder.bind(this, item)
                }, {
                    name: 'Создать файл',
                    id: 112,
                    handler: this.createNewFile.bind(this, item)
                }]
            });
        }
        if (rights?.knowledge?.edit && item?.id !== 0 && item?.userRights?.edit) {
            actions.push({
                name: 'Переименовать',
                id: 45,
                handler: this.toggleRename.bind(this, item)
            });
        }
        if (item?.type === FOLDER && item?.id !== 0 && rights?.knowledge?.create && item?.userRights?.edit) {
            actions.push({
                name: 'Переместить в...',
                id: 13,
                withSearch: true,
                subMenuItems: Object.entries(this.foldersMap).filter(([folderId, folder]) => {
                    const isActiveFolder = folder.parentFolder || folder.parentFolder === 0 || +folderId === 0;
                    const isThisFolder = item?.parentFolderId === +folderId;
                    const isSelfFolder = item?.id === +folderId;
                    return isActiveFolder && folder?.userRights?.create && !isThisFolder && !isSelfFolder;
                }).map(([folderId, folder]) => ({
                    id: +folderId,
                    name: folder.name,
                    handler: this.moveFolder.bind(this, item, +folderId),
                }))
            });
        }
        if (item?.type === FILE && rights?.knowledge?.create && item?.userRights?.edit) {
            actions.push({
                name: 'Переместить в...',
                id: 13,
                withSearch: true,
                subMenuItems: Object.entries(this.foldersMap).filter(([folderId, folder]) => {
                    const isActiveFolder = folder.parentFolder || folder.parentFolder === 0 || +folderId === 0;
                    const isThisFolder = item?.parentFolderId === +folderId;
                    return isActiveFolder && folder?.userRights?.create && !isThisFolder;
                }).map(([folderId, folder]) => ({
                    id: +folderId,
                    name: folder.name,
                    handler: this.moveFile.bind(this, item, +folderId),
                }))
            });
        }
        if (rights?.knowledge?.create && item?.type === FILE) {
            actions.push({
                name: 'Добавить ссылку в...',
                id: 14,
                withSearch: true,
                subMenuItems: Object.entries(this.foldersMap).filter(([folderId, folder]) => {
                    return folder?.userRights?.create;
                }).map(([folderId, folder]) => ({
                    id: +folderId,
                    name: folder.name,
                    handler: this.copyFile.bind(this, item, +folderId),
                }))
            });
        }
        if (rights?.knowledge?.edit && item?.userRights?.edit && item?.type === FILE) {
            actions.push({
                name: item?.published ? 'Снять с публикации' : 'Опубликовать',
                id: 15,
                handler: this.togglePublishFile.bind(this, item)
            });
        }
        if (item?.type === FOLDER && item?.id !== 0 && rights?.knowledge?.delete && item?.userRights?.delete) {
            actions.push({
                name: 'Удалить',
                id: 16,
                handler: this.deleteFolder.bind(this, item)
            });
        }
        if (item?.type === FILE && rights?.knowledge?.delete && item?.userRights?.delete) {
            actions.push({
                name: 'Удалить',
                id: 17,
                handler: this.deleteFile.bind(this, item)
            });
        }
        return actions;
    };

    openEditRightsModal(item: any) {
        this.props.openModal({
            id: 'knowledge_rights',
            title: `Редактирование прав ${item.type === FOLDER ? "папки" : "файла"}`,
            component: () => (<ChangeRightsModal item={item}/>),
            size: "large"
        })
    }


    toggleOpen(folderId: number, event: any) {
        event.stopPropagation();
        this.setState((prevState: State) => {
            if (prevState.openedFolders.includes(folderId)) {
                return {
                    openedFolders: prevState.openedFolders.filter((fId: number) => folderId !== fId)
                }
            } else {
                return {
                    openedFolders: [folderId, ...prevState.openedFolders]
                }
            }
        })
    }

    getIsOpen = (item: TreeItem) => {
        return this.state.openedFolders.includes(item.id);
    }

    changeItemName = (event: any) => {
        this.setState({
            selectedItemName: event?.target?.value
        })
    }

    toggleRename = (item: FrontFile | FrontFolder) => {
        this.setState({
            renamingItemId: item?.type + ITEM_NAME_SEPARATOR + item?.id,
            selectedItemName: item?.name
        });
    }

    selectAllText = (event: any) => {
        event?.target?.select()
    }

    renderTreeItem = (item: TreeItem) => {
        const {openedFolders, renamingItemId, selectedItemName} = this.state;
        const isOpen = openedFolders.includes(item.id);
        const isRenaming = renamingItemId === (item?.type + ITEM_NAME_SEPARATOR + item?.id);
        return (
            <span
                data-id={item.id}
                key={item.id}
                onDoubleClick={this.toggleOpen.bind(this, item.id)}
                className={"file__tree-item"}
            >
                    <RenderIf condition={item?.type === FOLDER && (item?.children?.length || item?.files?.length)}>
                        <Caret
                            isOpen={isOpen}
                            //@ts-ignore
                            onClick={this.toggleOpen.bind(this, item.id)}
                        />
                    </RenderIf>
                    <i className={`just-pro__tree-icon fa ${item.type === FOLDER ? 'fa-folder' : 'fa-file'}` +
                    (item.type == FILE && !item.published ? ' file--unpublished' : '')}
                    />
                    <RenderIf condition={!isRenaming}>
                        <ContextMenu
                            id={`${item?.type}__${item.id}__${item?.parentFolderId}`}
                            disabled={!(this.getContextItems.bind(this, item)()?.length)}
                            getItems={this.getContextItems.bind(this, item)}
                        >
                            <RenderIf condition={item.type === FOLDER}>
                                    <Link
                                        className={"file__link" + (item.type == FILE && !item.published ?
                                            ' file--unpublished' : '')}
                                        to={"/knowledge"}>
                                        {item.name}
                                        </Link>
                            </RenderIf>
                            <RenderIf condition={item.type === FILE}>
                                    <NavLink
                                        className={"file__link" + (item.type == FILE && !item.published ?
                                            ' file--unpublished' : '')}
                                        to={`/knowledge/${item.id}`}>{item.name}</NavLink>
                            </RenderIf>
                        </ContextMenu>
                    </RenderIf>
                    <RenderIf condition={isRenaming}>
                        <input value={selectedItemName}
                               onChange={this.changeItemName}
                               onBlur={this.renameItem}
                               onFocus={this.selectAllText}
                               className="file__input"
                        />
                    </RenderIf>
            </span>
        )
    }

    getFileComponent = (props: any) => {
        return <FileController {...props} afterSave={this.updateAll}/>
    }

    render() {
        const {loading, mainFolder} = this.state;
        return (
            <WithPrivateRoute>
                <HeaderController>
                    <ModuleTextName>База знаний</ModuleTextName>
                </HeaderController>
                <div className="just-pro_module knowledge">
                    <div className="panel content-panel">
                        <Spinner loading={loading}/>
                        <TreeController customAccessors={["children", "files"]}
                                        list={mainFolder && [mainFolder] || []}
                                        renderItem={this.renderTreeItem}
                                        getIsOpen={this.getIsOpen}
                        />
                    </div>
                    <div className="panel content-panel">
                        <Route path={`/knowledge`} exact component={SearchFilesController}/>
                        <Route path={`/knowledge/:fileId`} exact component={this.getFileComponent}/>
                    </div>
                </div>
            </WithPrivateRoute>
        )
    }
}

const mapState = (state: any) => ({
    rights: state.application.rights
});
const mapDispatchToProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props))
});

export default connect(mapState, mapDispatchToProps)(KnowledgeController);