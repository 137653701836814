import {TokenError, AccessError, NotFoundError, ServerError, ValidationError} from '@justpro/terminal'
import {signOut} from "../routing/utils";
import {toast} from "react-toastify";
import {renderToString} from "react-dom/server";
import getText from "../localization/getText";

type MyError = TokenError | AccessError | NotFoundError | ServerError | ValidationError | Error


const checkError = (e:MyError | Error) => {
    if(e instanceof TokenError) {
        signOut();
        // toast.error(renderToString(getText(e.message)));
        console.error(e);
        return void 0;
    }
    if(e instanceof AccessError) {
        toast.error(renderToString(getText('errors.access')));
        console.error(e);
        return void 0;
    }
    if(e instanceof NotFoundError) {
        //todo notFount page..
        toast.warn(renderToString(getText(e.message)));
        return void 0;
    }
    if(e?.message?.toLowerCase()?.includes("network") && window.innerWidth < 720){
        return void 0;
    }
    toast.error(renderToString(getText(e.message)));
    console.error(e);
};


export default checkError;