import React from 'react';
import {
    OrdersWorkorder,
    ChecklistWorkorder,
    getWorkorder,
    IUpdateWorkorder,
    updateWorkOrder, OrderListItem, RegularWork,
    Workorder
} from "@justpro/terminal";
import Spinner from '../UI/spinner/spinner.controller'
import './workOrders.css'
import WorkOrderDetail from "./workOrder.detail";
import checkError from "../../utils/checkError";
import RenderIf from "../../utils/renderIf";
import WorkorderPreloader from "./workorder.preloader";

interface Props {
    id?: number
    workOrder?: OrdersWorkorder | ChecklistWorkorder

    updateListItem?(workorder: Workorder): any

    updateList?(): void
}

interface State {
    isLoading: boolean
    workOrder?: OrdersWorkorder | ChecklistWorkorder

}


export interface UpdateWorkorderItem {
    orders?: OrderListItem[]
    regularWorks?: RegularWork[]
}

class WorkOrderController extends React.Component<Props, State> {

    state: State = {
        isLoading: false,
    };

    getWorkOrder = async () => {
        const {id, workOrder} = this.props;

        this.setState(() => ({workOrder: undefined}));

        if ((id === undefined && workOrder === undefined)) {
            throw Error('Компоненту нужно передать или id или наряд')
        }

        if (workOrder !== undefined) {
            this.setState(() => ({workOrder}))
        } else {
            await this.requestWorkOrder();
        }
    };

    requestWorkOrder = async () => {
        const {id} = this.props;

        if (id !== undefined) {
            try {
                this.setState({
                    isLoading: true
                });

                const workOrder = await getWorkorder(id);
                this.setState({
                    workOrder
                })
            } catch (e) {
                checkError(e)
            } finally {
                this.setState({
                    isLoading: false
                });
            }
        }
    };

    updateWorkOrder = async (data?: Partial<IUpdateWorkorder>): Promise<Workorder> => {
        try {
            let workOrder = null;
            if (data !== undefined) {
                if (!this.state.workOrder) {
                    return null;
                }

                this.setState({isLoading: true});

                workOrder = await updateWorkOrder(this.state.workOrder.id, data);

                this.setState({workOrder});


                if (
                    (
                        data.hasOwnProperty('responsibleId') ||
                        data.hasOwnProperty('closed')
                    )
                    && typeof this.props.updateList === 'function') {
                    await this.props.updateList()
                }
            } else {
                await this.requestWorkOrder();
            }
            return workOrder;

        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                isLoading: false
            });
        }


    };

    updateItem = (data: UpdateWorkorderItem) => {
        this.setState((prevState) => ({
            workOrder: prevState.workOrder ? {
                ...prevState.workOrder,
                orders: data.orders ? data.orders : prevState.workOrder.orders ? prevState.workOrder.orders : [],
                regularWorks: data.regularWorks ? data.regularWorks : prevState.workOrder?.regularWorks
            } : undefined

        }))


    };

    async componentDidMount() {
        await this.getWorkOrder();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            this.props.id !== prevProps.id ||
            (this.props.workOrder && this.props.workOrder.id) !== (prevProps.workOrder && prevProps.workOrder.id)
        ) {
            await this.getWorkOrder();
        }
    }

    render() {

        return (
            <>
                <RenderIf condition={this.state.workOrder}>
                    <WorkOrderDetail
                        workOrder={this.state.workOrder}
                        updateWorkOrder={this.updateWorkOrder}
                        updateListItem={this.props.updateListItem}
                        reloadWorkOrder={this.getWorkOrder}
                        updateItem={this.updateItem}
                        requestWorkOrder={this.requestWorkOrder}
                    />
                </RenderIf>

                <RenderIf condition={!this.state.workOrder || this.state.isLoading}>
                    <WorkorderPreloader loading={this.state.isLoading}/>
                </RenderIf>
            </>
        )
    }
}


export default WorkOrderController
