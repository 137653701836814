import React from 'react';
import {Brand, getBrands, createBrand, ModulesResponse} from "@justpro/terminal";
import BrandCreate from "./brand.create";
import BrandController from "./brand.controller";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";

interface Props {
    rights?: Partial<ModulesResponse>
}

class Brands extends React.Component<Props>{

    renderItem = (item:Brand) => <>{item.name}</>;

    render() {
        const {rights} = this.props;
        return <BaseReferenceController
            referenceName="brands.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getBrands}
            Detail={BrandController}
            create={{
                Component : BrandCreate,
                title : 'brands.addItem',
                handler : createBrand
            }}
            moduleRights={rights && rights['references.brands']}
        />
    }
}

const mapStateToProps = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapStateToProps)(Brands)