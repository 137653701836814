import React from 'react';
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {
    createEquipment,
    createEquipmentsMass,
    Equipment,
    filterParams, 
    getContractors,
    getEquipmentCategories,
    getEquipments,
    getEquipmentTypes,
    ModulesResponse,
    deleteEquipmentsMass
} from "@justpro/terminal";

import EquipmentController from "./equipment.controller";
import EquipmentCreate from "./equipment.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import {CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";
import { Modal } from '../../../store/modal/modal.types';
import { openModal } from '../../../store/modal/modal.actions';
import getText from "../../../localization/getText";
import { MassAdd } from './massAddModal';

interface Props {
    rights?: Partial<ModulesResponse>
    openModal(props: Modal): void
}

class EquipmentsController extends React.Component<Props>{

    renderItem = (item:Equipment) => <>{item.equipmentType.name} {item.number}</>;

    getCheckboxesMap = async () => {
        const {rights} = this.props;

        const result:CheckboxesMap = {};

        if(rights?.['references.equipmentTypes']?.read) {
            const equipmentTypes = await getEquipmentTypes({q : ''});

            result[filterParams.EQUIPMENT_TYPES] = {
                name : 'equipmentTypes.equipmentType',
                children : equipmentTypes.map( ({id, name}) => ({id, name}))
            }
        }

        if(rights?.['references.equipmentCategories']?.read) {
            const equipmentCategories = await getEquipmentCategories({q : ''});

            result[filterParams.EQUIPMENT_CATEGORIES] = {
                name : 'equipmentCategories.equipmentCategory',
                children : equipmentCategories.map( ({id, name}) => ({id, name}))
            }
        }

        if(rights?.['references.contractors']?.read) {
            const contractors = await getContractors({q : ""});

            result[filterParams.CONTRACTORS] = {
                name : 'contractors.contractor',
                children : contractors.map( ({id, name}) => ({id, name}))
            }
        }

        return result
    };

    async handleMassAdd (params) {
        return createEquipmentsMass(params);
    }

    async handleMassDelete (params) {
        return deleteEquipmentsMass(params)
    }
    
    massAdd() {
        this.props.openModal({
            id: "mass_add",
            component: (props) => <MassAdd handleSubmit={this.handleMassAdd} {...props} />,
            title: getText('equipments.massAdd'),
        });
    }

    massDelete() {
        this.props.openModal({
            id: "mass_delete",
            component: (props) => <MassAdd handleSubmit={this.handleMassDelete} {...props}/>,
            title: getText('equipments.massRemove'),
        });
    }
    
    render() {
        const rights = this.props.rights && this.props.rights['references.equipments'];

        return (
            <BaseReferenceController
                Detail={EquipmentController}
                getList={getEquipments}
                listItem={{
                    render : this.renderItem,
                }}
                counterTranslates={{
                    one: 'equipments.vars.one',
                    two: 'equipments.vars.two',
                    default: 'equipments.vars.default',
                    notFound: 'equipments.vars.notFound'
                }}
                referenceName="equipments.moduleName"
                create={{
                    Component : EquipmentCreate,
                    title : "equipments.addItem",
                    handler: createEquipment
                }}
                customButtons={[
                    {
                        title : "equipments.massAdd",
                        handler: this.massAdd.bind(this),
                        icon: 'fa fa-folder-plus',
                    },
                    {
                        title : "equipments.massRemove",
                        handler: this.massDelete.bind(this),
                        icon: 'fa fa-folder-minus',
                    }
                ]}
                filter={{
                    getCheckboxesMap : this.getCheckboxesMap,
                    filterName : filterNames.EQUIPMENTS,
                }}

                moduleRights={rights}
            />
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

const mapDisptach = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props))
});

export default connect(mapState, mapDisptach)(EquipmentsController)