import React from "react";
import checkError from "../../../utils/checkError";
import {EditSquare, editSquare, getSquare, Square} from "@justpro/terminal";
import SquareDetail from "./square.detail";
import RenderIf from "../../../utils/renderIf";


interface Props {
    id : number
    afterUpdate?(square:Square) : void
}

interface State {
    loading : boolean
    square? :Square
}


class SquareController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    getSquare = async () => {
        try {

            this.setState(() => ({loading : true}));

            const square = await getSquare(this.props.id);


            this.setState(() => ({loading : false, square}));
        }catch (e) {
            checkError(e)
        }
    };

    updateSquare = async (data?:Partial<EditSquare>) => {

        try {

            if(data) {
                this.setState(() => ({loading : false}));

                const square = await editSquare(this.props.id, data);

                this.setState(() => ({loading : false, square}));

                this.props.afterUpdate && this.props.afterUpdate(square);
            }else{
                this.getSquare()
            }
        }catch (e) {
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getSquare();
        }
    }

    async componentDidMount() {
        this.getSquare();
    }

    render() {


        return (
            <RenderIf condition={this.state.square !== undefined}>
                <SquareDetail square={this.state.square as Square} updateSquare={this.updateSquare} />
            </RenderIf>
        )
    }
}

export default SquareController