import React, {Component} from "react";
import RenderIf from "../../utils/renderIf";
import Spinner from "../UI/spinner/spinner.controller";
import checkError from "../../utils/checkError";
import {getRoles, RolesMap, Role, User, delay, getUsers, FOLDER, ROOT_ROLE_ID, FILE, ItemRights,
    getFileRoleRights, getFolderRoleRights, updateFolderRoleRights, updateFileRoleRights} from "@justpro/terminal";
import Caret from "../UI/caret/caret";
import {UserItem} from "../users/usersList/usersList";

type Actions = 'allowedRead' | 'allowedCreate' | 'allowedEdit' | 'allowedDelete' |
    'forbiddenRead' | 'forbiddenCreate' | 'forbiddenEdit' | 'forbiddenDelete';

const MAX_CALL_NORMALIZE_ROLE = 100;
const normalizeRole = (role: Role, rolesMap: RolesMap, itemsRights: any, callCount = 0) => {
    const {children, ...otherRole}: any = role;
    if (callCount > MAX_CALL_NORMALIZE_ROLE) return role;
    if (children?.length) {
        otherRole.children = children?.map((childId: number) => {
            const child = rolesMap[childId];
            child.id = childId;
            return normalizeRole(child, rolesMap, itemsRights, ++callCount);
        })?.sort((a: Role, b: Role) => {
            return a.name.localeCompare(b.name)
        });
    }
    otherRole.isOpen = false;
    otherRole.itemRights = itemsRights.find((item: any) => item.roleId === role.id);
    return otherRole;
}

interface Props {
    item: any
}

interface State {
    loading: boolean
    usersLoading: boolean
    openedRoles: number[]
    mainRole?: Role
    selectedRole?: Role
    users?: User[]
}

export default class ChangeRightsModal extends Component<Props, State> {
    state: State = {
        loading: false,
        usersLoading: false,
        openedRoles: []
    }

    rolesMap: RolesMap = {};
    itemsRights: ItemRights[] = [];

    componentDidMount() {
        return this.updateRolesList();
    }

    componentDidUpdate(prevProps: Props) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            return this.updateRolesList()
        }
    }

    updateRolesList = async () => {
        try {
            this.setState({
                loading: true
            });
            this.rolesMap = await getRoles();
            if(this.props.item?.type === FOLDER) {
                this.itemsRights = await getFolderRoleRights(this.props.item?.id);
            }
            if(this.props.item?.type === FILE) {
                this.itemsRights = await getFileRoleRights(this.props.item?.id);
            }

            const mainRole = normalizeRole({
                ...this.rolesMap[ROOT_ROLE_ID],
                id: ROOT_ROLE_ID
            }, this.rolesMap, this.itemsRights);
            this.setState({
                mainRole
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    toggleOpen(roleId: number, event: any) {
        event.stopPropagation();
        this.setState((prevState: State) => {
            if (prevState.openedRoles.includes(roleId)) {
                return {
                    openedRoles: prevState.openedRoles.filter((rId) => roleId !== rId)
                }
            } else {
                return {
                    openedRoles: [roleId, ...prevState.openedRoles]
                }
            }
        })
    }

    async changeRoleFileRights(roleId: number,
                               action: Actions, event: any) {
        try {
            const {item} = this.props;
            this.setState({
                loading: true
            })
            const value = event?.target?.checked;
            if(item?.type === FOLDER) {
                await updateFolderRoleRights(item?.id, roleId, {
                    [action]: value
                });
            }
            if(item?.type === FILE){
                await updateFileRoleRights(item?.id, roleId, {
                    [action]: value
                });
            }
            await this.updateRolesList();
            await delay(1000);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    async selectRole(role: Role) {
        try {
            if (!role?.id) return;
            this.setState({
                usersLoading: false
            });
            const users = await getUsers({roleId: role.id});
            this.setState({
                users,
                selectedRole: role
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                usersLoading: false
            });
        }
    }

    renderRole = (role: any, level = 1) => {
        const {openedRoles} = this.state;
        const isOpen = openedRoles.includes(role?.id);
        return (
            <>
                <tr className={"rights-modal__row " +
                (role?.id === this.state.selectedRole?.id ? 'rights-modal__row-selected' : '')
                }>
                    <td style={{paddingLeft: `${level * 20}px`}} className="rights-modal__name"
                        onClick={this.selectRole.bind(this, role)}
                        onDoubleClick={this.toggleOpen.bind(this, role?.id)}>
                        <RenderIf condition={role.children?.length}>
                            <Caret
                                isOpen={isOpen}
                                //@ts-ignore
                                onClick={this.toggleOpen.bind(this, role?.id)}
                            />
                        </RenderIf>
                        {role.name}
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.allowedRead}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'allowedRead')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.allowedCreate}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'allowedCreate')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.allowedEdit}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'allowedEdit')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.allowedDelete}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'allowedDelete')}
                        />
                    </td>

                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.forbiddenRead}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'forbiddenRead')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.forbiddenCreate}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'forbiddenCreate')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.forbiddenEdit}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'forbiddenEdit')}
                        />
                    </td>
                    <td className="rights-modal__checkbox-wrapper">
                        <input type="checkbox"
                               checked={!!role?.itemRights?.forbiddenDelete}
                               onChange={this.changeRoleFileRights.bind(this, role?.id, 'forbiddenDelete')}
                        />
                    </td>
                </tr>
                <RenderIf condition={role?.children?.length && isOpen}>
                    {role?.children?.length && role?.children?.map((role: any) => this.renderRole(role, level + 1))}
                </RenderIf>
            </>
        )
    }


    //todo btn отмены Готово

    render() {
        const {item} = this.props;
        const {loading, users, usersLoading} = this.state;
        return (
            <>
                <div className="rights-modal__wrapper">
                    <Spinner loading={loading}/>
                    <div className="rights-modal__table-wrapper">
                        <table className="rights-modal__table">
                            <tr className="rights-modal__row">
                                <th className="rights-modal__heading rights-modal__name">Группа прав</th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-eye grid__column_success"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-plus grid__column_success"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-pencil-alt grid__column_success"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-trash grid__column_success"></i>
                                </th>

                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-eye grid__column_deny"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-plus grid__column_deny"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-pencil-alt grid__column_deny"></i>
                                </th>
                                <th className="rights-modal__heading rights-modal__checkbox-wrapper">
                                    <i className="fa fa-trash grid__column_deny"></i>
                                </th>
                            </tr>
                            {this.state.mainRole && this.renderRole(this.state.mainRole)}
                        </table>
                    </div>
                    <div className="rights-modal__users-list">
                        {users?.length && users.map((user) => (
                            <UserItem user={user}/>
                        ))}
                        <Spinner loading={usersLoading}/>
                    </div>
                </div>
            </>
        )
    }
}