import {RightsObjectsList} from "../components/users/group/group.types";
import {GroupsMap} from '@justpro/terminal'

export const getChildrenObjects = (children:number[], groupsMap:GroupsMap):RightsObjectsList[] => {
    let result:RightsObjectsList[] = [];

    children && children.forEach(childGroupId => {
        let {children, name, objects} = groupsMap[childGroupId];

        objects && objects.forEach(object => {
            result.push({
                name,
                id : object.id,
                groupId: childGroupId,
                description : object.name || '',
                edit : false
            })
        });

        if(children && children.length) {
            let childs = getChildrenObjects(children , groupsMap);
            result.push(...childs)
        }

    });

    return result;
};