import React, {Component} from "react";
import {delay, CustomFile, getFilesSearch} from "@justpro/terminal";
import Spinner from "../UI/spinner/spinner.controller";
import Button from "../UI/button/button";
import checkError from "../../utils/checkError";
import "./searchFiles.css";
import {Link} from "react-router-dom";

interface Props {

}

interface State {
    searchValue: string
    loading: boolean
    results: CustomFile[]
}

const HTML_REPLACE_REG_EXP = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;
const convertHtmlToText = (html: string) => {
    return html.replace(HTML_REPLACE_REG_EXP, '');
}

export default class SearchFilesController extends Component<Props, State>{
    state: State = {
        searchValue: '',
        loading: false,
        results: []
    }

    onEnterPress = (event: any) => {
        if(event.charCode === 13){
            return this.searchFiles();
        }
    }

    changeSearchValue = (event: any) => {
        this.setState({
            searchValue: event?.target?.value
        });
    }

    searchFiles = async () => {
        try {
            const {searchValue} = this.state;
            if (searchValue?.length < 3) return false;
            this.setState({
                loading: true
            });
            const results = await getFilesSearch(searchValue);
            this.setState({
                results
            })
        } catch(err){
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    render(){
        const {searchValue, loading, results} = this.state;
        return (
            <div className="search-files__wrapper">
                <Spinner loading={loading} />
                <div className="search-files__search">
                    <input className="search-files__input"
                           type="search"
                           placeholder="Введите 3 символа..."
                           value={searchValue}
                           onChange={this.changeSearchValue}
                           onKeyPress={this.onEnterPress}
                    />
                    <Button className="search-files__button" onClick={this.searchFiles}>Поиск</Button>
                </div>
                <div className="search-files__results">
                    {results.map((file) => {
                        const clearContent = convertHtmlToText(file?.content || '');
                        return (
                            <Link className="search-files__file-wrapper" key={file?.id} to={`/knowledge/${file.id}`}>
                                <i className="search-files__icon fa fa-file" />
                                <div className="search-files__file-info">
                                    <div className="search-files__file-name">
                                        {file?.name}
                                    </div>
                                    <div className="search-files__file-content">
                                        {clearContent.slice(0, 150) + (clearContent?.length > 150 ? "..." : "")}
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }
}