import React from 'react';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {Messenger, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    messenger : Messenger
    update(data:Partial<Messenger>) : void
    rights?: Partial<ModulesResponse>
}

class MessengerDetail extends React.Component<Props>{

    toggleActive = (active:boolean) => {
        this.props.update({active})
    };

    changeName = (name:string, oldName :string) => {
        if(name !== oldName) {
            this.props.update({name})
        }
    };

    render() {
        const {rights, messenger} = this.props;
        const {active, name} = messenger;
        const disabled = rights && rights['references.messengers'] && !rights['references.messengers']['edit'];
        return (<>

            <h4>{getText('messengerTypes.messengerType')}: {name}</h4>

            <ToggleSwitch
                send={this.toggleActive}
                defaultValue={active}
                label="common.active"
                disabled={disabled}
            />
            <Input
                blur={this.changeName}
                label="messengerTypes.messengerType"
                startValue={name}
                disabled={disabled}
            />

        </>)
    }

}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(MessengerDetail)