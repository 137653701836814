import React from "react";
import {CreateEquipmentWork} from "@justpro/terminal";
import Button from "../../UI/button/button";
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import TextArea from "../../UI/textArea/textArea.controller";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import RenderIf from "../../../utils/renderIf";
import {getUniqueEquipmentTypes} from "./utils";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateEquipmentWork) : void
}


interface State {
    name : string
    equipmentTypes : Option[]
    additionalInfo : string,
}

export default class EquipmentWorkCreate extends React.Component<Props, State>{

    state:State = {
        name : '',
        additionalInfo : '',
        equipmentTypes : [],
    };

    add = () => {
        const {name, additionalInfo, equipmentTypes} = this.state;

        if(!this.isDisabled()) {
            this.props.create({
                additionalInfo,
                name,
                equipmentTypesId : equipmentTypes.map(item => item.value)
            })
        }
    };

    change = (option:Option) => {
        this.setState((prevState) => ({equipmentTypes : [...prevState.equipmentTypes, option]}));
        return Promise.resolve();
    };

    changeAddInfo = (additionalInfo:string) => {
        this.setState(() => ({additionalInfo}))
    };

    isDisabled = () => {
        const {name, equipmentTypes} = this.state;
        return name.trim() === '' || equipmentTypes.length === 0
    };

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    removeEquipmentType = (props:any) => {
        const eq = props.original;
        this.setState((prevState) => ({equipmentTypes : prevState.equipmentTypes.filter(item => item.value !== eq.value)}))

    };

    render() {
        const {equipmentTypes} = this.state;

        return (
            <>
                <h3>{getText('equipmentWorks.addingNewHeader')}</h3>
                <Input change={this.changeName} label="equipmentWorks.equipmentWork" />

                {withLabel(
                    <>
                        <RenderIf condition={equipmentTypes.length > 0}>
                            <Table
                                columns={[
                                    {
                                        Header : getText('common.name'),
                                        accessor : 'label'
                                    },
                                    {
                                        Header: getText('common.delete'),
                                        Cell : (props:any) => <Button className="btn-success" onClick={this.removeEquipmentType.bind(this, props)}><i className="fa fa-trash no-text" /></Button>
                                    }
                                ]}
                                data={equipmentTypes}
                                minRows={0}
                                showPagination={false}
                            />
                        </RenderIf>
                        <Select
                            load={getUniqueEquipmentTypes.bind(this, this.state.equipmentTypes, 'value')}
                            accessors={{
                                name : 'label',
                                id : 'value'
                            }}
                            defaultValue={undefined}
                            placeholder="UI.select.placeholders.selectEquipmentType"
                            change={this.change}
                            className="margin-top"
                            withoutValue={true}
                        />
                    </>
                )({text : 'equipmentTypes.equipmentType'})}

                <TextArea change={this.changeAddInfo} label={{text : "common.additionalInfo"}}/>

                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}