import styled from "styled-components";

export const Wrapper = styled.div`
    width : 100%;
    display : grid;
    grid-template-columns : repeat(3, 1fr);
    grid-gap : 20px;
    position : relative;
    
    & .photo {
        position : relative;
    }
    
    & img {
        height : 100px;
        width : 100%;
        object-fit : cover;
    }
    
    & span {
       position : absolute;
       right : 0;
       top : 0;
       color : red;
       font-weight : bold;
       font-size : 1.3em;
       padding : 5px;
       display : flex;
       align-items : center; 
       cursor : pointer;
    }
`;