import React, { useEffect, useState } from 'react'
import { ActionTypes } from 'react-select'
import moment, { Moment } from 'moment'
import { connect } from 'react-redux'

import {
	format,
	getPersons,
	getRegularWorks,
	Object as IObject,
	createWorkorderChecklist,
	getShortObjectName,
	getObjectName,
} from '@justpro/terminal'
import Select from '../../select/select'
import { Option } from '../../select/select.types'
import checkError from '../../../../utils/checkError'
import { getFullName } from '../../../../utils/names'
import getText from '../../../../localization/getText'
import InlineCalendar from '../../calendar/views/inline.controller'
import { Modal, ModalBodyProps } from '../../../../store/modal/modal.types'
import { ErrorComponent } from '../../errorComponent/errorComponent'
import { ChecklistEquipmentCategory } from '../createChecklist/steps/first'
import { openModal } from '../../../../store/modal/modal.actions'
import Spinner from '../../spinner/spinner.controller'
import AsyncSelect from '../../select/asyncSelect_v2'
import RenderIf from '../../../../utils/renderIf'
import Button from '../../button/button'
import {
	getContractorList,
	getObjectsList,
	getRegionsList,
} from '../../../../utils/functions'
import WorkOrderDetail from '../../../workOrders/workOrder.detail'
import DefaultFooterModal from '../modal/defaultFooterModal'
import { ChecklistObject } from '../createChecklist/checklistObject'

type ChecklistType = {
	object: Option
	equipmentCategories: any[]
}

interface IProps {
	settings: any
	openModal(props: Modal): void
}

const CreateChecklistObject = (props: IProps) => {
	// state //

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [date, setDate] = useState<Moment>(moment())
	const [executor, setExecutor] = useState<Option>()
	const [coWorkers, setCoWorkers] = useState<Option[]>([])
	const [contractor, setContractor] = useState<Option>()
	const [region, setRegion] = useState<Option>()
	const [selectedObject, setSelectedObject] = useState<Option>()
	const [showError, setShowError] = useState<boolean>(false)
	const [checklist, setChecklist] = useState<ChecklistType>()

	// state handlers //
	const changeDate = (date: Moment) => {
		setDate(date)
	}
	const changeExecutor = (option: Option) => {
		setExecutor(option)
		return Promise.resolve()
	}
	const changeCoExecutors = async (option: Option, action?: ActionTypes) => {
		switch (action) {
			case 'select-option':
				setCoWorkers((prevCoWorkers) => [...prevCoWorkers, option])
				return true
			case 'remove-value':
				setCoWorkers((prevCoWorkers) =>
					prevCoWorkers.filter((item) => item.value !== option.value),
				)
				return true
		}
		return false
	}
	const changeContractor = async (option: Option) => {
		setContractor(option)
	}
	const changeRegion = async (option: Option) => {
		setRegion(option)
	}
	const changeSelectedObject = async (option: Option) => {
		setSelectedObject(option)
	}
	const closeErrorMessage = () => {
		setShowError(false)
	}

	// load options //
	const getPersonsList = async (q: string) => {
		const personsList = await getPersons({
			q,
			executorsId: [
				props.settings.tfmContractorId,
				props.settings.tfmShidContractorId,
			],
		})
		const persons: Option[] = personsList.map((p) => {
			return {
				...p,
				label: getFullName(p),
				value: p.id,
			}
		})
		return persons
	}

	const loadObjects = async (q = '') => {
		try {
			setIsLoading(true)
			const objects = await getObjectsList({
				q: q[0],
				regionsId: region?.value ? [region?.value] : null,
				contractorsId: contractor?.value ? [contractor?.value] : null,
			})
			return objects.map((object) => ({
				...object,
				label: getObjectName(object),
			}))
		} catch (e) {
			checkError(e)
		} finally {
			setIsLoading(false)
		}
	}

	const loadRegularWorks = async () => {
		try {
			setIsLoading(true)
			setShowError(false)
			const { list } = await getRegularWorks({
				limit: 100000,
				canWorkorder: '1',
				dateTo: date.format('YYYY-MM-DD'),
				controlDateFrom: date.format('YYYY-MM-DD'),
				objectsId: [selectedObject.id],
			})
			if (!list.length) {
				setShowError(true)
			}
			// const checklist = {
			// 	object: selectedObject,
			// 	equipmentCategories: [],
			// }
			if (list.length) {
				const equipmentCategoriesToSet = []
				while (list?.length) {
					const regularWork = list.pop()
					const equipmentCategory = {
						responsibleId: executor.id,
						equipmentCategory:
							regularWork.equipment?.equipmentType
								?.equipmentCategory,
						equipmentCategoryId:
							regularWork.equipment?.equipmentType
								?.equipmentCategory?.id,
						date: date.format(format.date),
						coworkersId: coWorkers.map(
							(coworker) => coworker.value,
						),
					}
					equipmentCategoriesToSet.push(equipmentCategory)
				}
				const checklistToSet = {
					object: selectedObject,
					equipmentCategories: equipmentCategoriesToSet.reduce(
						(acc: ChecklistEquipmentCategory[], eq) => {
							const exists = acc.find(
								(checklistEq) =>
									checklistEq.equipmentCategory.id ===
									eq.equipmentCategory.id,
							)
							if (exists) {
								return acc
							}

							return [...acc, eq]
						},
						[],
					),
				}
				setChecklist(checklistToSet)
			}
		} catch (e) {
			checkError(e)
		} finally {
			setIsLoading(false)
		}
	}

	const removeEquipmentCategory = (_objectId, deletedEquipmentCategoryId) => {
		setChecklist((prevChecklist) => ({
			...prevChecklist,
			equipmentCategories: prevChecklist.equipmentCategories.filter(
				(eq) => eq.equipmentCategoryId !== deletedEquipmentCategoryId,
			),
		}))
	}

	useEffect(() => {
		if (selectedObject) {
			loadRegularWorks()
		}
	}, [selectedObject, date])

	const submitWorkorderChecklist = async () => {
		if (checklist) {
			const workorder = await createWorkorderChecklist({
				checklists: [{ ...checklist, objectId: checklist.object.id }],
			})
			openWorkoRderModal(workorder)
		}
	}

	const openWorkoRderModal = (workOrder) => {
		props.openModal({
			id: 'workorderModal',
			component: () => (
				<WorkOrderDetail
					workOrder={workOrder[0]}
					updateWorkOrder={() => {}}
					updateItem={() => {}}
				/>
			),
			size: 'large',
			title: getText('workorders.workorder'),
		})
	}

	return (
		<div>
			<Spinner loading={isLoading} />

			<InlineCalendar
				date={date}
				onChange={changeDate}
				label={{ text: 'common.date' }}
			/>
			<AsyncSelect
				change={changeExecutor}
				loadOptions={getPersonsList}
				label={{ text: 'common.executor' }}
				placeholder='UI.select.placeholders.selectExecutor'
			/>
			<AsyncSelect
				change={changeCoExecutors}
				loadOptions={getPersonsList}
				values={coWorkers}
				isMulti
				label={{ text: 'common.coworkers' }}
				placeholder='UI.select.placeholders.selectCoworkers'
			/>
			<Select
				change={changeContractor}
				load={getContractorList}
				label='contractors.contractor'
				accessors={{ name: 'label', id: 'value' }}
				isDisabled={!date}
			/>
			<Select
				change={changeRegion}
				load={getRegionsList}
				label='regions.region'
				accessors={{ name: 'label', id: 'value' }}
				isDisabled={!date}
			/>
			<AsyncSelect
				cacheOptions
				defaultOptions={true}
				loadOptions={loadObjects}
				change={changeSelectedObject}
				label={{ text: 'objects.object' }}
			/>
			<RenderIf condition={checklist}>
				<div className='checklistObjects'>
					<ChecklistObject
						item={checklist}
						removeEquipmentCategory={removeEquipmentCategory}
					/>
				</div>
			</RenderIf>
			<RenderIf condition={showError}>
				<ErrorComponent
					title='Нельзя создать наряд'
					message='На этот объект нельзя создать наряд, т.к нет регламентных работ по этому объекту'
					onClose={closeErrorMessage}
				/>
			</RenderIf>

			<DefaultFooterModal>
				<Button
					className='btn-success'
					onClick={submitWorkorderChecklist}
					disabled={isLoading || !checklist}
				>
					{getText('common.create')}
				</Button>
			</DefaultFooterModal>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		settings: state.application.settings,
	}
}

const mapDispatchToProps = (dispatch: Function) => ({
	openModal: (props: Modal) => dispatch(openModal(props)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateChecklistObject)
