import {ActiveCheckboxesList} from "../components/UI/checkboxList/checkboxList.types";

interface SearchParams {
    [key : string] : Array<number | string> | string | undefined;
}

export function save(filterName: string, active: ActiveCheckboxesList):void {
    if(Object.keys(active).length > 0) {
        localStorage.setItem(filterName, JSON.stringify(active));
    }else {
        localStorage.removeItem(filterName);
    }
}

export function get(filterName:string, defaultValues:ActiveCheckboxesList = {}):ActiveCheckboxesList | {} {
    let result = defaultValues;
    const filter = localStorage.getItem(filterName);

    if(filter) {
        result = JSON.parse(filter);
    }
    return result
}

export function getSearchParams(activeCheckboxes:ActiveCheckboxesList, q?: string):SearchParams{
    let result:SearchParams = {};

    if(q || q === '') {
        result.q = q;
    }

    let entries =  Object.entries(activeCheckboxes);

    entries && entries.length && entries.forEach( ([accessor, active]) => {
        result[accessor] = active;
    });

    return result;
}
