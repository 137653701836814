import React from 'react'
import Slider from 'react-input-slider';

interface IProps {
    changeHandler?: (field: string, value: any) => void
    disabled? : boolean
    min? : number
    max : number
    step : number
    defaultValue : number
}

class InputSlider extends React.Component<IProps> {

    state= {
        x: this.props.defaultValue
    }

    mouseUpTracker = () => {
        window.addEventListener('mouseup', this.submitHandler)
    }

    submitHandler = async () => {
        this.props.changeHandler('fillOrdersOpacity', (this.state.x))
        window.removeEventListener('mouseup', this.submitHandler)
    }

    render() {
        
        return (
            <div onMouseDownCapture={this.mouseUpTracker}>
                <Slider 
                    onChange={(data) => this.setState({
                        x: data.x
                    })}
                    disabled={this.props.disabled}
                    xmin={this.props.min}
                    xmax={this.props.max} 
                    xstep={this.props.step}
                    x={this.state.x}
                    styles={{
                        track: {
                            width: '100%',
                            height: '10px',
                            borderRadius: '10px',
                            marginTop: '10px'
                        },
                        active: {
                            backgroundColor: '#de1118'
                        },
                    }}
                />
            </div>
        )
    }
}

export default InputSlider
