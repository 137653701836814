import React from "react";
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getCountries} from "../../../utils/functions";
import {Area, EditArea, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";
import getText from "../../../localization/getText";

interface Props {
    area : Area
    rights?: Partial<ModulesResponse>
    updateArea(data:Partial<EditArea>):void
}

class AreaDetail extends React.Component<Props> {
    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.updateArea({name})
        }
    };

    changeCountry = (option:Option) => {
        this.props.updateArea({countryId : option.value});
        return Promise.resolve();
    };

    changeActiveArea = (active: boolean) => {
        this.props.updateArea({active});
    };

    render () {
        const {area, rights} = this.props;
        const {active, name, country} = area;

        const edit = rights && rights['references.areas'] && !rights['references.areas']['edit'];

        return (
            <>
                <h3>{getText('areas.area')}: {name}</h3>

                <ToggleSwitch
                    send={this.changeActiveArea}
                    defaultValue={active}
                    label="common.active"
                    disabled={edit}
                />

                <Input
                    label="areas.area"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={edit}
                />

                <Select
                    change={this.changeCountry}
                    label="countries.country"
                    defaultValue={country}
                    isDisabled={edit}
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    load={getCountries}
                />
            </>
        )
    }

}

const mapStateToProps = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapStateToProps)(AreaDetail)