import React from "react";
import Table from "../../../table/table";
import '../createOrderModal.view.css'
import {Contract, ContractOrdersType} from "@justpro/terminal";
import getText from "../../../../../localization/getText";

interface Props {
    changeOrdersType:(ordersType:ContractOrdersType) => void
    contracts?: Contract[],
}

export default class ObjectsModalDetail extends React.Component<Props>{

    choose = (ordersType:ContractOrdersType) => {
        this.props.changeOrdersType(ordersType)
    };

    getData = () => {

        const {contracts} = this.props;

        return contracts ? contracts.reduce((result:ContractOrdersType[], contract) => {


            if(contract.ordersTypes) {
                return [...result, ...contract.ordersTypes.filter(i => i.active)]
            }else{
                return result
            }
        }, []) : []
    };

    render() {

        const data = this.getData();
        return(
            <>
                <Table
                    columns={[
                        {
                            Header: getText('orderTypes.orderType'),
                            minWidth: 150,
                            Cell:(props:any) => {

                                const name = props?.original?.alias ? props.original.alias : props?.original?.name;


                                return (
                                    <span className="just-prop_create-order-list-item" onClick={this.choose.bind(this, props.original)}>
                                        {name}
                                    </span>
                                )
                            }
                        },
                    ]}
                    data={this.getData()}
                    minRows={0}
                    pagination={false}
                    showPagination={false}

                />
            </>
        )
    }

}