import React from 'react';
import {toast} from "react-toastify";

export default class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error:any) {
        console.log({error});
        return { hasError: true };
    }

    componentDidCatch(error:any, errorInfo:any) {
        console.error({error, errorInfo});

        toast.error('Произошла ошибка. Обратитесь к администратору');

    }

    render() {
        return this.props.children;
    }
}
