import React from 'react';
import Input from "../../UI/input/text";
import {EditOrdersType, ModulesResponse, OrdersType} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import InputColor from '../../UI/input/color'
import NumberInput from '../../UI/input/number'
import {getPriorities1SList, getServices1SList} from "../../../utils/functions";
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

type Props = {
    orderType : OrdersType
    update(data:Partial<EditOrdersType>) : void,
    rights?: Partial<ModulesResponse>
};

class OrderTypeDetail extends React.Component<Props> {
    onBlurName = (name: string, oldValue: string) => {
        if(name !== oldValue) {
            this.props.update({name});
        }
    };

    changeActive = async (active:boolean) => {
        await this.props.update({active});
    };

    onBlurPriority = async (priority:number, oldValue:number) => {
        if(priority !== oldValue) {
            await this.props.update({
                priority
            });
        }
    };

    blurColor = async (color:string) => {
        await this.props.update({color});
    };

    changePriority1s = async (option:Option) => {
        return this.props.update({priority1sId : option.value});
    };

    render () {
        const {rights, orderType} = this.props;
        const {name, active, color, priority, priority1s} = orderType;
        const disabled = rights && rights['references.ordersTypes'] && !rights['references.ordersTypes']['edit'];
        return (
            <>

                <h3>{getText('orderTypes.orderType')}: {name}</h3>

                <ToggleSwitch send={this.changeActive} defaultValue={active} label="common.active" disabled={disabled}/>

                <Input
                    label="orderTypes.orderType"
                    placeholder="Введите название типа заявки"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <InputColor label="common.color" defaultValue={color} blur={this.blurColor} disabled={disabled}/>

                <NumberInput
                    blur={this.onBlurPriority}
                    label="common.priority"
                    defaultValue={priority}
                    disabled={disabled}
                />
                <Select
                    load={getPriorities1SList}
                    label="common.priority1s"
                    change={this.changePriority1s}
                    value={priority1s && { label: priority1s?.name, value: priority1s?.id1s } as unknown as Option}
                    accessors={{
                        name : 'label',
                        id1s : 'value'
                    }}
                    isDisabled={disabled}
                />
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(OrderTypeDetail)