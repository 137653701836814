import React from "react";
import {Area, editArea, EditArea, getArea} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import AreaDetail from "./area.detail";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";

interface Props  {
    id: number
    afterUpdate?(item:Area) : void
}
interface State  {
    area? : Area
    loading : boolean
}

class AreaController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };

    updateArea = async (data:Partial<EditArea>) => {
        this.setState(() => ({loading : true}));

        try {
            const {afterUpdate} = this.props;
            const area = await editArea(this.props.id, data);

            afterUpdate
            && (
                data.hasOwnProperty('name') ||
                data.hasOwnProperty('active')
            )
            && afterUpdate(area);


            this.setState(() => ({loading : false, area}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getArea = async () => {
        this.setState(() => ({loading : true}));

        try {
            const area = await getArea(this.props.id);

            this.setState(() => ({loading : false, area}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getArea()
        }
    }

    async componentDidMount() {
        this.getArea();
    }

    render () {
        const {area} = this.state;

        return (
            <>
               <RenderIf condition={area !== undefined}>
                   <AreaDetail area={area as Area} updateArea={this.updateArea} />
               </RenderIf>


                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default AreaController
