import styled from "styled-components";

export const Reference = styled.div`
    
    min-width : 200px;
    border : 1px solid black;
    padding : 20px;
    margin : 10px;
    display : flex;
    justify-content : center;
    align-content : center;
    
    &:hover {
        background-color : rgb(226, 227, 235);
    }
    
    &.completed {
        border-bottom : 5px solid green;
    }
    
    &.changed {
        border-bottom : 5px solid blue;
    }
    
    &.not-started {
        border-bottom : 5px solid red;
    }
    
    &.server-error {
        border-bottom : 5px solid yellow;
    }
    
    &.client-error {
        border-bottom : 5px solid orange;
    }
    
    

`;

export const RefTitle = styled.span`
    font-weight : 600;
    line-height : 70px;

`;