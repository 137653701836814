import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import IconPicker from '../../UI/iconPiker/iconPicker.controller'
import withLabel from "../../UI/withLabel/withLabel";
import {CreateStatus, getStatusGroups, StatusGroup} from "@justpro/terminal";
import {fromClassName, getIconsList} from "../../../utils/icons";
import getText from "../../../localization/getText";

const icons = getIconsList();

type Props = {
    create(data:CreateStatus):void
};

type State = {
    name : string
    icon : string

    groups? : StatusGroup[]
    groupId? : number
};

class OrderStatusCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
        icon : '',
    };


    create = () => {
        const {name, icon, groupId} = this.state;
        let data:CreateStatus = {
            name,
            icon
        };

        if(groupId !== undefined) {
            data.groupId = groupId;
        }

        this.props.create(data)
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    isDisabled = () => this.state.name.trim() === '';

    onChangeGroup = (groupId: number) => {
        this.setState(() => ({groupId}))
    };

    getGroupsList = () => {
        const {groups} = this.state;

        return (
            <>
                {groups?.map(item => {
                    return (
                        <div key={item.name + item.id}>
                            {withLabel(
                                <input type="radio" name="group" id={item.name + item.id} onChange={this.onChangeGroup.bind(this, item.id)}/>
                            )({
                                text: item.name,
                                id : item.name + item.id,
                            })}
                        </div>
                    )
                })}

                {withLabel(
                    <input type="radio" name="group" onChange={this.onChangeGroup.bind(this, -1)} id="invalid"/>
                )({
                    text :'orderStatuses.doNotDefineGroup',
                    id : 'invalid'
                })}
            </>
        )
    };

    onChangeIcon = (icon:string) => {
        const [prefix, iconName] = fromClassName(icon);
        this.setState(() => ({icon : `${prefix}:${iconName}`}))
    };

    async componentDidMount() {
        const groups = await getStatusGroups();
        this.setState(() => ({groups : groups.filter(item => item.id !== 0)}))
    }

    render () {
        return (
            <>
                <h3>{getText("orderStatuses.addingNewHeader")}</h3>

                <Input
                    label="orderStatuses.orderStatus"
                    placeholder="orderStatuses.orderStatus"
                    change={this.onChangeName}
                />

                <IconPicker
                    change={this.onChangeIcon}
                    list={icons}
                    label="common.icon"
                />

                {withLabel(
                    <>
                        {this.getGroupsList()}
                    </>
                )({
                    text : 'orderStatuses.defineStatusGroup',
                    indent : false,
                })}

                <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }

}

export default OrderStatusCreate