import React from "react";
import {editPhone, getPhone, Phone} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import PhoneDetail from "./phone.detail";
import Spinner from "../../UI/spinner/spinner.controller";


interface Props {
    id : number
    afterUpdate?(phone:Phone) : void
}

interface State {
    loading : boolean

    phone? : Phone
}

class PhoneController extends React.Component<Props, State>{

    state:State = {
        loading : false
    };

    getPhone = async () => {

          try {
              this.setState(() => ({
                  loading : true
              }));

              const phone = await getPhone(this.props.id);
              this.setState(() => ({
                  loading : false,
                  phone
              }));

          }  catch (e) {
              this.setState(() => ({
                  loading : false
              }));

              checkError(e)
          }
    };

    update = async (data:Partial<Phone>) => {

        try {
            this.setState(() => ({
                loading : true
            }));

            const phone = await editPhone(this.props.id, data);

            this.props.afterUpdate && this.props.afterUpdate(phone);

            this.setState(() => ({
                loading : false,
                phone
            }));


        }catch (e) {
            this.setState(() => ({
                loading : false
            }));

            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.id !== this.props.id) {
            this.getPhone();
        }
    }

    async componentDidMount() {
        this.getPhone();
    }

    render () {


        return (
            <>
                <RenderIf condition={this.state.phone !== undefined}>
                    <PhoneDetail phone={this.state.phone as Phone} update={this.update} />
                </RenderIf>

                <Spinner loading={this.state.loading} />

            </>
        )
    }
}

export default PhoneController

