import React from "react";
import moment from "moment";
import Spinner from "../UI/spinner/spinner.controller";
import {SingleNews} from "@justpro/terminal";
import RenderIf from "../../utils/renderIf";
import PersonInfo from "../UI/dialog/personInfo/personInfo.controller";
import {connect} from "react-redux";
import getText from "../../localization/getText";

interface Props {
    singleNews: SingleNews
    showComments?: boolean
    me?: any
    rights?: any
    openModal(singleNews?: SingleNews): void
    changeDraft(singleNews?: SingleNews): void
}

interface State {
    loading: boolean
    // singleNews?: SingleNews
    published: boolean
}

class SingleNewsController extends React.Component<Props, State> {
    state: State = {
        loading: false,
        published: false,
    };

    render() {
        const {singleNews, showComments = true, me, rights} = this.props;

        if (!singleNews) return null;
        const canEdit = rights?.news?.edit;
        return (
            <div className="novelty">
                {/*<Angle size={90}/>*/}

                {/*<div className="singleNews__angle" />*/}
                <div className="novelty__wrapper">
                    <div className="novelty__body novelty-margin-left">
                        <h2 className="novelty__title" onClick={this.props.openModal.bind(this, singleNews)}>
                            <span>{singleNews.title}</span></h2>
                        <div className="novelty__content"
                             dangerouslySetInnerHTML={{__html: singleNews.content}}/>
                    </div>
                    <div className="novelty__info-block">
                        <div className="novelty__owner-block">
                            <div className="novelty__owner">
                                <PersonInfo canOpen={false} person={singleNews.owner}/>
                            </div>
                            <div className="novelty__date">
                                {moment(singleNews.createdAt).format('DD.MM.YYYY HH:mm')}
                            </div>
                            <div className="novelty__buttons">
                            <span className="novelty__editable-text"
                                  onClick={this.props.changeDraft.bind(this, singleNews)}>
                                <RenderIf condition={canEdit && singleNews.isDraft}>
                                    <i className="fa fa-eye"></i>
                                    {getText('news.publish')}
                                </RenderIf>
                                <RenderIf condition={canEdit && !singleNews.isDraft}>
                                       <i className="fa fa-eye-slash"></i>
                                    {getText('news.unpublish')}
                                </RenderIf>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {singleNews.commentsCount && (
                    <div className="novelty__comments novelty-margin-left"
                         onClick={this.props.openModal.bind(this, singleNews)}>
                        <i className="fa fa-comment"/>{getText('UI.tabs.comments')} ({singleNews.commentsCount})
                    </div>
                )}

                <Spinner loading={this.state.loading}/>
            </div>
        )
    }
}

const mapState = (state: any) => ({
    me: state.application.me,
    rights: state.application.rights
});

export default connect(mapState)(SingleNewsController)