import React from 'react';
import {AddFile, CreateEquipment} from "@justpro/terminal";
import {Option} from "../../UI/select/select.types";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getEquipmentTypesList, getObjectsList} from "../../../utils/functions";
import Button from "../../UI/button/button";
import {File as IFile} from '@justpro/terminal'
import EquipmentPhotos from "../../UI/equipmentPhotos/equipmentPhotos.controller";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateEquipment) : void
}

interface MFile extends IFile{
    file : File
}

interface State {
    number : string
    equipmentTypeId : number | null
    objectId : number | null
    photos : MFile[]
}

class EquipmentCreate extends React.Component<Props, State> {

    state:State = {
        number : '',
        equipmentTypeId : null,
        objectId : null,
        photos : []
    };

    add = () => {
        const {number, equipmentTypeId, objectId} = this.state;

        if(number.trim() !== '' && equipmentTypeId !== null && objectId !== null) {


            const photos:AddFile[] = this.state.photos.map(p => {
                const file = p.file;

                return {
                    name : file.name,
                    blob : file
                }
            });


            this.props.create({
                objectId,
                number,
                equipmentTypeId,
                photos
            })
        }
    };

    changeObject = (option:Option) => {
        this.setState(() => ({objectId : option.value}));
        return Promise.resolve();
    };

    changeEquipmentType = (option:Option) => {
        this.setState(() => ({equipmentTypeId : option.value}));
        return Promise.resolve();
    };

    changeNumber = (number:string) => {
        this.setState(() => ({number}));
    };

    isDisabled = () => {
        const {number, equipmentTypeId, objectId} = this.state;
        return number.trim() === '' || equipmentTypeId === null || objectId === null
    };

    deletePhoto = (id: number) => {
        this.setState((prevState) => ({
            photos : prevState.photos.filter(p => p.id !== id)
        }))
    };

    addPhotos = (files:File[]) => {
        this.setState((prevState) => ({
            photos : [
                ...prevState.photos,
                ...files.map((file, index) => ({
                    path: URL.createObjectURL(file),
                    id: index + 1,
                    createdAt: '',
                    file
                }))
            ]
        }))

    };


    render() {

        const {photos} = this.state;

        return (
            <>
                <h4>{getText('equipments.equipment')}</h4>

                <Input change={this.changeNumber} label="common.number" />

                <Select
                    load={getEquipmentTypesList}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    defaultValue={undefined}
                    placeholder="UI.select.placeholders.selectEquipmentType"
                    label="equipmentTypes.equipmentType"
                    change={this.changeEquipmentType}
                />

                <AsyncSelect
                    change={this.changeObject}

                    loadOptions={(q:string) => groupObjectsByRegion({q})}
                    label={{ text : "objects.object" }}
                />

                <EquipmentPhotos photos={photos} label="common.photo" deletePhoto={this.deletePhoto} addPhotos={this.addPhotos}/>


                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

export default EquipmentCreate