import React from "react";
import {Redirect, Route} from "react-router";
import cookies from "js-cookie";

import {routeTypes} from "./routes.const";
import PageNotFound from "../components/pageNotFound/pageNotFound.controller";

export default (props: routeTypes) => {
    const isLogged = cookies.get("token");
    return isLogged ? (
        <>
            <Route {...props} />
        </>
    ) : <Redirect to="/sign"/>
}

