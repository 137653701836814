import React from 'react';
import {OrdersType, getOrdersTypes, createOrdersType, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import OrderTypeController from "./orderType.controller";
import OrderTypeCreate from "./orderType.create";
import {Angle} from "../../UI/itemsList/card/card.view";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class OrdersTypesController extends React.Component<Props>{

    renderItem = (item:OrdersType) => (
        <>
            <Angle size={30} color={item.color} />
            {item.name}
        </>
    );

    render() {

        const rights = this.props.rights && this.props.rights['references.ordersTypes'];
        return <BaseReferenceController
            referenceName="orderTypes.moduleName"
            listItem={{
                render : this.renderItem,
            }}
            getList={getOrdersTypes}
            Detail={OrderTypeController}
            create={{
                Component : OrderTypeCreate,
                title : 'orderTypes.addItem',
                handler : createOrdersType
            }}

            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(OrdersTypesController)