import React from 'react';
import StatusFilter, {ModuleGroupsProps} from "../statusFilters/statusFilter";
import normalizeModuleCount, {Vars} from "../../../utils/normalizeModuleCount";
import './listFilter.css'
import SortBy, {SortByProps} from "../sortBy/sortBy.controller";


interface Counter {
    vars : Vars
    value? : number
}

interface Helper {
    icon : string
    handler(): void
    show: boolean
}

// interface ModuleGroups {
//     onChange(items:number[]) : void
//     list : any[]
//     text : string
//     active : number[]
// }

interface Props {
    counter?: Counter
    sortBy? : SortByProps
    moduleGroups? : ModuleGroupsProps
    helper? : Helper
}

class ListFilterController extends React.Component<Props>{

    render () {
        const {counter, helper, sortBy, moduleGroups} = this.props;

        return (
            <>
                <div className="just-pro__pre-detail-panel">

                    {moduleGroups && <StatusFilter
                                       {...moduleGroups}
                                    />
                    }   

                    <div className="orderBy-filter">

                        {sortBy &&
                            <SortBy {...(sortBy as SortByProps)} />
                        }

                        {counter &&
                            <div className="just-pro__orders_count">
                                {normalizeModuleCount((counter as Counter).vars, (counter as Counter).value)}
                            </div>
                        }

                        {helper && helper.show &&
                            <div className="just-pro__orders_message" onClick={(helper as Helper).handler}>
                                <i className={(helper as Helper).icon}/>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default ListFilterController