import React from 'react';
import Button from "../../UI/button/button";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import {getRegularWork, RegularWork, updateRegularWork, UpdateRegularWork} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RegularWorkDetail from "./regularWork.detail";
import EditRegularWork from './regularWork.edit'
import '../regularWorks.css';

export type PanelMode = 'edit' | 'read'

interface Props {
    id: number

    getRegularWorks?(): void
    updateListItem?(regularWork: RegularWork): void
}

interface State {
    mode: PanelMode,
    loading: boolean
    regularWork?: RegularWork
}

class RegularWorkController extends React.Component<Props, State> {

    state: State = {
        mode: 'read',
        loading: false
    };

    changeMode = (mode: PanelMode) => {
        this.setState(() => ({mode}))
    };

    update = async (data: Partial<UpdateRegularWork>) => {
        this.setState(() => ({loading: true}));

        if (Object.values(data).length > 0) {
            try {
                const regularWork = await updateRegularWork(this.props.id, data);
                this.setState(() => ({
                    regularWork,
                    loading: false,
                    mode: "read"
                }));
                this.props.updateListItem?.(regularWork);
            } catch (e) {
                checkError(e)
            }
        }


        this.setState(() => ({
            loading: false,
            mode: 'read'
        }))
    };


    getRegularWork = async () => {

        this.setState(() => ({loading: true}));
        try {
            const regularWork = await getRegularWork(this.props.id);

            this.setState(() => ({
                loading: false,
                regularWork,
                mode: 'read'
            }));
            this.props.updateListItem?.(regularWork);

        } catch (e) {
            this.setState(() => ({loading: false}));

            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.id !== prevProps.id) {
            this.getRegularWork();
        }
    }

    async componentDidMount() {
        this.getRegularWork()
    }

    render() {

        const {regularWork, mode} = this.state;

        return (
            <div className="regularWork__detail">
                <RenderIf condition={regularWork}>
                    <>
                        <RenderIf condition={this.state.mode === 'read'}>
                            <RegularWorkDetail
                                regularWork={regularWork as RegularWork}
                                getEditMode={this.changeMode}
                                getRegularWork={this.getRegularWork}
                            />
                        </RenderIf>

                        <RenderIf condition={this.state.mode === 'edit'}>
                            <EditRegularWork
                                regularWork={regularWork as RegularWork}
                                getRegularWorks={this.props.getRegularWorks}
                                updateRegularWork={this.update}
                            />
                        </RenderIf>
                    </>
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </div>

        )
    }
}

export default RegularWorkController