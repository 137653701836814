import React from 'react'
import { Props, State, FancyBoxImage } from './fancybox.types'
import './fancybox.css'
import Modal from 'react-modal'
import RenderIf from '../../../utils/renderIf'

const getIconColor = (isCorrect: boolean | null) => {
	if(isCorrect === null) {
		return `#999`
	}
	if(isCorrect) {
		return `#0f0`
	}
	if(!isCorrect) {
		return `#ff7700`
	}
}

const getIconName = (isCorrect: boolean | null) => {
	if(isCorrect === null) {
		return `fa-minus-circle`
	}
	if(isCorrect) {
		return `fa-check-circle`
	}
	if(!isCorrect) {
		return `fa-times-circle`
	}
}

export default class FancyBox extends React.Component<Props, State> {
	state: State = {
		isOpen: false,
		images: [],
	}

	hide = () => {
		this.setState(() => ({ isOpen: false }))
	}

	clickHandler = () => {
		this.setState(() => ({ isOpen: true }))
	}

	getDefaultState = () => {
		const { images } = this.props

		this.setState(() => ({
			images: images
				? images.map((item, index) => ({
						...item,
						index: index,
				  }))
				: [],
			selected: images
				? {
						...this.props.file,
						index: images.findIndex(
							(item) => item.id === this.props.file.id,
						),
				  }
				: undefined,
		}))
	}

	go = (action: 'prev' | 'next') => {
		const { images, selected } = this.state

		let nextImage: FancyBoxImage | undefined = undefined

		switch (action) {
			case 'next':
				nextImage = images[(selected as FancyBoxImage).index + 1]
				break
			case 'prev':
				nextImage = images[(selected as FancyBoxImage).index - 1]
				break
		}

		if (nextImage) {
			this.setSelected(nextImage)
		}
	}

	setSelected = (selected: FancyBoxImage) => {
		this.setState(() => ({ selected }))
	}

	deleteImage = (e: React.MouseEvent) => {
		console.log('deleted')
	}

	componentDidMount() {
		this.getDefaultState()
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		const { images } = this.props

		if (
			(!prevState.isOpen && this.state.isOpen) ||
			(prevProps.images && prevProps.images.length) !==
				(images && images.length)
		) {
			this.getDefaultState()
		}
	}

	render() {
		const {
			imageWrapperCls,
			imageMode: mode = 'image',
			file,
			withDelete,
			showCorrectIcons,
		} = this.props
		const { selected, images } = this.state
		const canGoNext = selected && selected.index + 1 !== images.length

		// let mode = 'image';

		const cls: string[] = ['fancybox-image']

		if (imageWrapperCls) {
			cls.push(imageWrapperCls)
		} else {
			cls.push('default')
		}

		// if(imageMode) {
		//     mode = imageMode
		// }

		return (
			<>
				<RenderIf condition={mode === 'image'}>
					<div className={cls.join(' ')} onClick={this.clickHandler}>
						<img
							src={
								(file?.thumbnail || file.path) +
								`?a=${Date.now()}`
							}
							alt=''
						/>
						<RenderIf condition={file.thumbnail}>
							<div className='fancybox-image__play'>
								<i className='fa fa-xs fa-play' />
							</div>
						</RenderIf>
					</div>
					<RenderIf condition={showCorrectIcons}>
						<div className='fancybox-image__correct'>
								<div className='fancybox-image__correct-distance'>
									<i className='fa fa-location-arrow'></i>:&nbsp;
									<i className={`fa ${getIconName(file?.isDistanceCorrect)}`} style={{ 
										color: getIconColor(file?.isDistanceCorrect)
									}}></i>
								</div>
								<div className='fancybox-image__correct-time'>
									<i className='fa fa-clock'></i>:&nbsp;
									<i className={`fa ${getIconName(file?.isDateTimeCorrect)}`} style={{ 
										color: getIconColor(file?.isDateTimeCorrect)
									}}></i>
								</div>
						</div>
					</RenderIf>
				</RenderIf>

				<RenderIf condition={mode === 'named'}>
					<>
						<img
							src={file?.thumbnail || file.path}
							style={{ height: 16, width: 16, marginRight: 5 }}
							alt=''
						/>
						<span
							className='image-name'
							onClick={this.clickHandler}
						>
							{file.originalName && file.originalName}
						</span>
					</>
				</RenderIf>

				<Modal
					isOpen={this.state.isOpen}
					onRequestClose={this.hide}
					shouldCloseOnOverlayClick={true}
					overlayClassName='fancyBox-overlay'
					className='fancyBox'
				>
					<div className='fancyBox__wrapper'>
						<div className='fancyBox__selected open'>
							<div
								className='fancyBox__arrow'
								onClick={this.go.bind(this, 'prev')}
							>
								{selected && selected.index > 0 && (
									<i className='fa fa-arrow-left' />
								)}
							</div>

							<div className='fancyBox__content'>
								<div className='fancyBox__current-image'>
									<RenderIf
										condition={
											!this.state?.selected?.thumbnail
										}
									>
										<img
											onClick={
												canGoNext
													? this.go.bind(this, 'next')
													: this.hide
											}
											src={
												this.state.selected?.path ? this.state.selected?.path  +
                                                    `?a=${Date.now()}` : ''
											}
											alt=''
										/>
									</RenderIf>
									<RenderIf
										condition={
											this.state?.selected?.thumbnail
										}
									>
										<video
											className='fancyBox__video'
											src={this.state?.selected?.path}
											autoPlay
											muted
											controls
										/>
									</RenderIf>
								</div>
							</div>

							<div
								className='fancyBox__arrow'
								onClick={this.go.bind(this, 'next')}
							>
								{canGoNext && (
									<i className='fa fa-arrow-right' />
								)}
							</div>
							<div className='count'>
								{selected ? selected.index + 1 : ''}/
								{images ? images.length : ''}
							</div>
							{/*<div className="remove" onClick={withDelete.handler.bind(this, selected?.id)}>*/}

							{withDelete && (
								<div className='remove' onClick={this.hide}>
									{/*{selected ? selected.index + 1 : ''}*/}
									<i className='fa fa-2x fa-times' />
									{/*{images ? images.length : ''}*/}
								</div>
							)}
						</div>
					</div>
				</Modal>
			</>
		)
	}
}
