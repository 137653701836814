import React from "react";
import './checkboxSelect.css'
import Caret from "../caret/caret";
import RenderIf from "../../../utils/renderIf";
import Input from "../input/text";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";


interface List {
    id : number
    name : string
    [key : string] : any
}

interface Props {
    getList(params?:any) : Promise<List[]>
    onChange(items:any[]) : void
    selected : any[]

    placeholderParams? : {
        maxItemLength: number
        multiplyPlaceholder : string
    }

    placeholder?: string
    pickAllNodes? : string //
    label? : string
}

interface State {
    isOpen: boolean
    q : string
    list? : List[]
    filteredList? : List[]
}

export default class CheckboxSelectController extends React.Component<Props, State>{
    state:State = {
        isOpen : false,
        q : '',
    };


    getListWithDefaultValue = (list?:List[]):List[] | undefined => {
        const {pickAllNodes} = this.props;

        return pickAllNodes ? [
            {
                id : -1,
                name : pickAllNodes
            },
            ...list
        ] : list
    };

    getList = async () => {
        const list = await this.props.getList();

        this.setState(() => ({
            filteredList : this.getListWithDefaultValue(list),
            list
        }))
    };

    toggleIsOpen = async () => {
        if(this.state.list === undefined) {
            await this.getList()
        }

        await this.setState((prevState) => ({isOpen : !prevState.isOpen}), this.onChangeSearch.bind(this, ''));
    };


    onChangeSearch = (q:string) => {
        this.setState((prevState) => ({
            q,
            filteredList: this.getListWithDefaultValue(prevState.list?.filter(item => item.name.toLowerCase().match(q.toLowerCase())))
        }))
    };

    clearSearchInput = () => {
        this.onChangeSearch('');
    };


    onChangeItemHandler = async (item:any) => {
        const {selected} = this.props;
        if(item?.id === -1) {
            if(selected.length > 0) {
                this.props.onChange([])
            }else{
                this.props.onChange(this.state.list || [])
            }
        }else {
            if(selected.find((i: any) => i.id === item.id)) {
                this.props.onChange(selected.filter(i => i.id !== item.id))
            }else{
                this.props.onChange([...selected, item])
            }
        }
    };

    containerHandler = (e:React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    documentHandler = (e:any) => {
        const target = e?.target?.closest('.checkbox-select');

        if(!target) {
            this.setState(() => ({isOpen : false, q : ''}))
        }
    };

    setDocumentHandler = () => {
        document.body.addEventListener('click', this.documentHandler)
    };

    unsetDocumentHandler = () => {
        document.body.removeEventListener('click', this.documentHandler);
    };

    getPlaceholder = () => {
        const {selected} = this.props;
        const {list} = this.state;
        const {placeholder = renderToString(getText('common.unselected')), placeholderParams} = this.props;

        if(placeholderParams && selected.length > 0) {
            const {maxItemLength, multiplyPlaceholder} = placeholderParams;

            if(selected.length > maxItemLength) {
                return `${renderToString(getText('UI.checkboxSelect.chosen'))} ${selected.length} ${renderToString(getText(multiplyPlaceholder))}`
            }else{
                return `${renderToString(getText('UI.checkboxSelect.chosen2'))} ${selected?.map((r: any) => r.name).join(", ")}`
            }
        }

        if(selected.length === list?.length) {
            return this.state.filteredList?.find(item => item.id === -1)?.name || renderToString(getText('common.selectedAll'))
        }


        return placeholder
    };


    componentDidMount(): void {
        this.setDocumentHandler();
    }

    componentWillUnmount(): void {
        this.unsetDocumentHandler();
    }

    render () {
        const {selected} = this.props;
        const {isOpen, filteredList, list} = this.state;

        return (
            <div className="navbar-form navbar-left checkbox-select">
                <div
                    className={`checkbox-select__select ${selected.length > 0 ? 'checkbox-select__select--selected' : ''}`}
                    onClick={this.toggleIsOpen}
                >
                    {this.getPlaceholder()} <Caret isOpen={isOpen} />

                    <RenderIf condition={isOpen}>
                        <div className="checkbox-select__container" onClick={this.containerHandler}>

                            <div className="checkbox-select__search">
                                <div className="checkbox-select__icon">
                                    <i className="fa fa-search" />
                                </div>

                                <div className="checkbox-select__search-input">
                                    <Input value={this.state.q} change={this.onChangeSearch} placeholder="common.searching"/>
                                </div>

                                <div className="checkbox-select__icon" onClick={this.clearSearchInput}>
                                    <i className="fas fa-eraser" />
                                </div>
                            </div>

                            <div className="checkbox-select__item-list">
                                {filteredList?.map(item => {
                                    const id = `item_${item.id}`;
                                    return <div className="checkbox-select__item" key={item.id}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-select__checkbox"
                                            checked={
                                                item.id === -1 ? selected.length === list?.length :
                                                selected.find((i) => i.id === item.id)
                                            }
                                            id={id}
                                            onChange={this.onChangeItemHandler.bind(this, item)}/>
                                            <label
                                                htmlFor={id}
                                                className={`checkbox-select__label ${item.id === -1 ? 'checkbox-select__label-all' : ''}`}
                                            >
                                                {getText(item.name)}
                                            </label>
                                    </div>
                                })}
                            </div>
                        </div>
                    </RenderIf>

                </div>
            </div>
        )
    }
}