import React from 'react';
import {createDepartment, Department, getDepartments, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import DepartmentController from "./department.controller";
import DepartmentCreate from "./department.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}


class DepartmentsController extends React.Component<Props>{

    renderItem = (item:Department) => <>{item.name}</>;

    render() {
        const rights = this.props.rights && this.props.rights['references.departments'];

        return <BaseReferenceController
            referenceName="departments.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getDepartments}
            Detail={DepartmentController}
            create={{
                Component : DepartmentCreate,
                title : 'departments.addItem',
                handler : createDepartment
            }}
            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(DepartmentsController)