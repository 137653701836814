import styled from "styled-components";

const maxWidth = '500px';


export const Wrapper = styled.div`
    position : relative;
    z-index : 1200;
`;

export const Icons = styled.div`
    max-width : ${maxWidth};
    height : 300px;
    overflow-y : scroll;
    display : flex;
    flex-direction : row;
    flex-wrap: wrap;
    justify-content: center;
    
    box-shadow: 0 0 2px 1px #ccc;
    border-radius : 5px;
    

    // border : 1px solid black;
`;

export const IconBox = styled.div`
    height : 50px;
    width : 50px;
    display : flex;
    justify-content: center;
    align-items: center;
    border : 1px solid green;
    margin : 5px;
    cursor : pointer;
    
    &:hover {
        background-color : #cccccc42;
        
    }
`;

export const Icon = styled.i`
    font-size : 30px;
    cursor: pointer;
    
    &.disabled {
        cursor : not-allowed;
    }
`;

export const TopPanel = styled.i`
    min-height: 30px;
    max-width : ${maxWidth}
    display: flex;
    flex-direction : row;
    align-items: center;
    justify-content: flex-end;
    border-radius : 5px;
    
    
    & div.close {
        height: 20px;
        width : 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        font-size : 16px;
        font-weight: normal;
        
    }
`;
