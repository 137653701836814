export default function (avatar?:Blob | string) {
    if (avatar) {
        if(avatar.toString().startsWith('http')) {
            return avatar.toString()
        }else{
            return 'http://localhost:9002' + avatar.toString()
        }
    }else{
        return  '/images/avatar_demo.png'
    }
}