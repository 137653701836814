import React from "react";
import {OTRequestFull, getOTRequest} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import OtsyncDetail from "./otsync.detail";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";


interface Props {
    id: number
    afterUpdate?(item: OTRequestFull): void
    updateList(): any
    clearItem(): any
}

interface State {
    loading: boolean
    otsync?: OTRequestFull,
}

export default class OtsyncController extends React.Component<Props, State>{

    state: State = {
        loading: false
    };

    getOtsync = async () => {
        try {
            this.setState({
                loading: true
            });
            const otsync = await getOTRequest(this.props.id);
            this.setState({
                otsync
            })
        } catch (e) {
            checkError(e);
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.id !== this.props.id) {
            return this.getOtsync()
        }
    }

    async componentDidMount() {
        return this.getOtsync()
    }

    render() {
        return (
            <>
                <RenderIf condition={this.state.otsync}>
                    <OtsyncDetail updateOtsyncList={this.props.updateList} clearItem={this.props.clearItem} otsync={this.state.otsync as OTRequestFull}/>
                    <Spinner loading={this.state.loading}/>
                </RenderIf>
            </>
        )
    }
}