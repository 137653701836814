import React from 'react';
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import {editOrdersType, getOrdersType, OrdersType, EditOrdersType} from "@justpro/terminal";
import OrderTypeDetail from "./orderType.detail";

interface Props {
    id: number
    afterUpdate?(item:OrdersType) : void
}

interface State {
    loading : boolean
    orderType? : OrdersType
}

class OrderTypeController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    update = async (data:Partial<EditOrdersType>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const ordersType = await editOrdersType(this.props.id, data);

            afterUpdate && afterUpdate(ordersType);

            this.setState(() => ({loading : false, ordersType}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getOrdersType = async () => {
        this.setState(() => ({loading : true}));

        try {
            const orderType = await getOrdersType(this.props.id);

            console.log({orderType})

            this.setState(() => ({loading : false, orderType}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getOrdersType();
        }
    }

    async componentDidMount(){
        this.getOrdersType();
    }

    render() {
        const {orderType} = this.state;
        return (
            <>
                <RenderIf condition={orderType !== undefined}>
                    <OrderTypeDetail
                        orderType={orderType as OrdersType}
                        update={this.update} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default OrderTypeController