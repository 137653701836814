import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import getText from '../../../localization/getText';
import Spinner from '../../UI/spinner/spinner.controller';
import RenderIf from '../../../utils/renderIf';
import Table from '../../UI/table/table';
import {
  getExpiredOrdersReport,
  getExpiredOrdersReportExport,
  format,
  IExpiredOrder,
  Region,
  Contractor,
} from '@justpro/terminal';
import './expiredOrders.css';
import moment, { Moment } from 'moment';
import checkError from '../../../utils/checkError';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import Button from '../../UI/button/button';
import FileDownload from 'js-file-download';

interface IState {
  loading: boolean;
  data: IExpiredOrder[];
  headerData: Contractor[];
  from: Moment;
  to: Moment;
  generatingExcel: boolean;
}

class ExpiredOrders extends React.Component<null, IState> {
  state = {
    loading: false,
    data: [],
    headerData: [],
    from: moment().subtract(1, 'year'),
    to: moment(),
    generatingExcel: false,
  };

  onChangeFrom = (from: Moment) => {
    this.setState(() => ({ from }), this.getData);
  };

  onChangeTo = (to: Moment) => {
    this.setState(() => ({ to }), this.getData);
  };

  generateExcel = async () => {
    try {
      this.setState({
        generatingExcel: true,
      });
      const blob = await getExpiredOrdersReportExport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });

      FileDownload(
        blob,
        `отчет-о-просроченных-заявках_${moment().format(
          `YYYY-MM-DD_HH-mm-ss`
        )}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        generatingExcel: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    try {
      this.setState({
        loading: true,
      });

      const filters = {
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      };
      const requestData = await getExpiredOrdersReport(filters);
      const [normalizedData, headerData] = this.normalizeData(requestData);

      await this.setState({
        headerData,
        data: normalizedData,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  normalizeData(data: IExpiredOrder[]): [IExpiredOrder[], Contractor[]] {
    const normalizedData: IExpiredOrder[] = data.reduce((result, current) => {
      const currentRegion = result?.find(
        (currentReg) => currentReg.region.id === current.region.id
      );
      const currentContractor = currentRegion?.contractors?.find(
        (contractor) => contractor.id === current.contractor.id
      );

      if (!currentRegion) {
        result.push({
          region: current.region,
          contractors: [
            {
              ...current.contractor,
              priorities: [
                {
                  simple: current.simple,
                  cp: current.cp,
                  id: current.priority1s.id1s,
                  name: current.priority1s.name,
                },
              ],
            },
          ],
        });
      }
      if (currentRegion && !currentContractor) {
        currentRegion.contractors.push({
          ...current.contractor,
          priorities: [
            {
              simple: current.simple,
              cp: current.cp,
              id: current.priority1s.id1s,
              name: current.priority1s.name,
            },
          ],
        });
      }
      if (currentRegion && currentContractor) {
        currentContractor.priorities.push({
          simple: current.simple,
          cp: current.cp,
          id: current.priority1s.id1s,
          name: current.priority1s.name,
        });
      }
      return result;
    }, []);

    const headerData: Contractor[] = data.reduce((result, current) => {
      const currentContractor = result?.find(
        (currentCont) => currentCont.id === current.contractor.id
      );
      const currentPriority = currentContractor?.priorities?.find(
        (priority) => priority.id === current.priority1s.id1s
      );
      if (currentContractor && !currentPriority) {
        currentContractor.priorities.push({
          id: current.priority1s.id1s,
          name: current.priority1s.name,
        });
      }
      if (!currentContractor) {
        result.push({
          id: current.contractor.id,
          name: current.contractor.name,
          priorities: [
            {
              id: current.priority1s.id1s,
              name: current.priority1s.name,
            },
          ],
        });
      }
      return result;
    }, []);
    return [normalizedData, headerData];
  }

  get columns() {
    const contractors = (data) => {
      return data.map((contractor) => {
        return {
          Header: contractor?.name,
          columns: contractor?.priorities?.map((currentPriority) => ({
            Header: (
              <>
                <div className="expiredOrders_priority-header">
                  {currentPriority?.priorityId || currentPriority?.name}
                </div>
                <div className="expiredOrders_priority-container">
                  <div className="expiredOrders_priority">
                    {getText('reports.expiredOrders.simple')}
                  </div>
                  <div
                    className="expiredOrders_priority expiredOrders_KP-span"
                    style={{ marginLeft: '10px' }}
                  >
                    {getText('reports.expiredOrders.kp')}
                  </div>
                </div>
              </>
            ),
            width: 200,
            Cell: ({ original }: any) => {
              const currentContractorData = original.contractors
                ?.find((c) => c.name === contractor.name)
                ?.priorities?.find((p) => p.id === currentPriority.id);
              return (
                <div className="expiredOrders_priority-container">
                  <div className="expiredOrders_priority">
                    {currentContractorData?.simple}
                  </div>
                  <div
                    className="expiredOrders_priority expiredOrders_KP-span"
                    style={{ marginLeft: '10px' }}
                  >
                    {currentContractorData?.cp}
                  </div>
                </div>
              );
            },
          })),
        };
      });
    };

    return [
      {
        Header: getText('regions.regions'),
        width: 200,
        Cell: (props) => (
          <div className="actsCheckHistory_cell">
            {props.original.region.name}
          </div>
        ),
      },
      ...contractors(this.state.headerData),
    ];
  }

  render() {
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>
            {getText('reports.expiredOrders.moduleName')}
          </ModuleTextName>
          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.onChangeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.onChangeTo} />
          </ToplineCalendarWrapper>
          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="Экспортировать в эксель">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                Генерация...
              </RenderIf>
            </Tooltip>
          </div>
        </HeaderController>
        <div className="just-pro_module report-wrapper">
          <div className="panel content-panel">
            <div className="expiredOrders_table">
              <RenderIf condition={!this.state.loading}>
                <Table
                  columns={this.columns}
                  data={this.state.data}
                  wrapperClassName="report_flex-table"
                />
              </RenderIf>
            </div>
            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

export default ExpiredOrders;
