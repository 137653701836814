import React from 'react';
import ReactDom from 'react-dom';
import './hidePortal.css'

type Props = {
    hide():void
}


/*
*      Предмет отрисовки должен иметь z-index больше 1000;
* */

export default function (props:Props) {
    return ReactDom.createPortal(
        <>
            <div className="just-pro__with-hide-portal" onClick={props.hide} />
        </>,
        document.getElementById('root-hide-helper')!
    )
}