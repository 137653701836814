import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import {CreateRegion} from '@justpro/terminal/dist/regions'
import Select from "../../UI/select/select";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {getPersonsList} from "../../../utils/functions";
import getText from "../../../localization/getText";

type Props = {
    create(data: CreateRegion):void
};


type State = {
    name : string
    personsList : LoadReturn[]

    responsibleAdminActId? : number
    responsibleInvoiceId? : number
    responsiblePurchaseId? : number

};

class RegionCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
        personsList : []
    };
    add = async () => {
        if(!this.isDisabled()) {
            const {
                name,
                responsibleAdminActId,
                responsibleInvoiceId,
                responsiblePurchaseId
            } = this.state;


            this.props.create({
                name,
                responsibleAdminActId : responsibleAdminActId as number,
                responsibleInvoiceId : responsibleInvoiceId as number,
                responsiblePurchaseId : responsiblePurchaseId as number
            })
        }
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    isDisabled = () => {
        const {name, responsibleAdminActId, responsibleInvoiceId, responsiblePurchaseId} = this.state;

        return  name.trim() === '' ||
                responsibleAdminActId === undefined ||
                responsibleInvoiceId === undefined ||
                responsiblePurchaseId === undefined
    };

    changeResponsibleAdmAct = (option:Option) => {

        return new Promise (resolve => {

            this.setState(() => ({
                responsibleAdminActId : option.value
            }));

            resolve();
        })
    };

    changeResponsibleInvoice = (option:Option) => {

        return new Promise (resolve => {

            this.setState(() => ({
                responsibleInvoiceId : option.value
            }));

            resolve();
        })
    };

    changeResponsiblePurchase = (option:Option) => {

        return new Promise (resolve => {
            this.setState(() => ({
                responsiblePurchaseId : option.value
            }));

            resolve();
        })
    };



    async componentDidMount() {
        const personsList = await getPersonsList();

        this.setState(() => ({
            personsList
        }))

    }


    render () {

        return (
            <>
                <h3>{getText('regions.addingNewHeader')}</h3>

                <Input
                    label="regions.region"
                    placeholder="regions.region"
                    change={this.onChangeName}
                />

                <Select
                    change={this.changeResponsibleAdmAct}
                    defaultOptions={this.state.personsList}
                    accessors={{
                        name : 'label',
                        id : 'value',
                    }}
                    label="regions.adminAct"
                />

                <Select
                    change={this.changeResponsibleInvoice}
                    defaultOptions={this.state.personsList}
                    accessors={{
                        name : 'label',
                        id : 'value',
                    }}
                    label="regions.materials"
                />

                <Select
                    change={this.changeResponsiblePurchase}
                    defaultOptions={this.state.personsList}
                    accessors={{
                        name : 'label',
                        id : 'value',
                    }}
                    label="regions.purchase"
                />

                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }

}

export default RegionCreate