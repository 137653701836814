import React from "react";
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import Search from "../../UI/search/search.controller";
import Button from "../../UI/button/button";
import ItemList from "../../UI/itemsList/itemList.controller";
import RenderIf from "../../../utils/renderIf";
import {Detail} from "../references.types";
import checkError from "../../../utils/checkError";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import {ActiveCheckboxesList, CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";
import CheckboxList from "../../UI/checkboxList/checkboxList.contoller";
import Filter from "../../UI/filter/filter.controller";
import {save, get, getSearchParams} from "../../../utils/filterCheckboxes";
import './baseReference.css'
import HeaderController from "../../header/header.controller";
import {Rules} from "@justpro/terminal";
import HasNoRightsController from "../../UI/hasNoRights/hasNoRights.controller";
import getText from "../../../localization/getText";
import ListFilterController from "../../UI/listFilter/listFilter.controller";

interface Props {
    referenceName: string
    moduleClass?: string
    getList(filterParams: any): Promise<any>

    search?: boolean
    listItem: {
        render(item: any): JSX.Element,
        // color? : string
        // size? : number
    }

    Detail: any
    create?: {
        Component: any
        handler(data: any): Promise<any>
        title: string
    }
    customButtons?: {title: string, handler(data: any): void, icon: string}[]

    filter?: {
        filterName: string,
        getCheckboxesMap(): Promise<CheckboxesMap>

    }
    alwaysActive?: boolean
    CustomHeader?: any
    disableAutoSort?: boolean
    moduleRights?: Rules
    counterTranslates?: {
        one: string
        two: string
        default: string
        notFound: string
    }
}

interface State {
    detail: Detail,
    list: any[],
    offset: number,
    q: string,
    needBackScroll: boolean
    loading: boolean
    count: string

    checkboxesMap: CheckboxesMap
    activeCheckboxes: ActiveCheckboxesList

    activeItemId?: number,

}

/*
*        Все справочники дожны иметь контроллер который бы загружал информацию о себе
*        Какждый из этих контроллеров должен принимать afterUpdate пропс (Не обязательным параметром) для обновления списка
*        Например если наименование справочника обновилось - то что бы в списке оно тоже отобразилось
*        Если передается создание справочника - то тот компонент должен как пропс принимать функцию create
* */

class BaseReferenceController extends React.Component<Props, State> {

    state: State = {
        detail: "",
        list: [],
        offset: 100,
        q: "",
        needBackScroll: false,
        loading: false,
        count: "0",
        checkboxesMap: {},
        activeCheckboxes: {}
    };

    isFilters = false;


    searchSubmit = (q: string) => {
        this.setState(() => ({q}), this.getList)
    };

    filterSubmit = async () => {
        this.getList();
    };

    changeDetail = (detail: Detail) => {
        this.setState(() => ({detail}))
    };

    getList = async () => {
        try {
            this.setState(() => ({
                loading: true,
                needBackScroll: true
            }));

            const filterParams = getSearchParams(this.state.activeCheckboxes, this.state.q);
            const data = await this.props.getList(filterParams);
            let list = [];
            if (data?.list && data?.count) {
                this.setState({
                    count: data?.count
                })
                list = data?.list;
            } else {
                list = data;
            }
            let sortedList = list.slice(0);
            if (!this.props.disableAutoSort) {
                sortedList = list.slice(0).sort((a: any) => a.active ? -1 : 1);
            }
            this.setState(() => ({
                loading: false,
                needBackScroll: false,
                list: sortedList
            }))

        } catch (e) {
            checkError(e)
        }
    };

    nextPage = async () => {
        const {list, count} = this.state;
        if (+count > list.length) {
            this.setState(() => ({
                loading: true
            }));

            const filterParams = getSearchParams(this.state.activeCheckboxes, this.state.q);

            let data = await this.props.getList({
                ...filterParams,
                offset: this.state.offset
            });
            let list = [];
            if (data?.list && data?.count) {
                list = data?.list;
                this.setState({
                    count: data?.count
                })
            } else {
                list = data.slice();
            }

            this.setState((prevState) => ({
                loading: false,
                offset: prevState.offset + 100,
                list: [...prevState.list, ...list],
            }))
        }
    };

    updateList = async () => {

        this.setState(() => ({
            loading: true
        }));

        const filterParams = getSearchParams(this.state.activeCheckboxes, this.state.q);

        const list = await this.props.getList({
            ...filterParams,
            offset: this.state.offset
        });

        this.setState({
            loading: false,
            list
        })
    };

    clearActive = () => {
        this.setState({
            activeItemId: void 0
        })
    }

    afterEditItem = (item: any) => {

        this.setState((prevState) => ({
            list: prevState.list.map(i => {
                if (item.id === i.id) {
                    return item
                }

                return i
            })
        }))
    };

    onCheckboxesChange = (activeCheckboxes: ActiveCheckboxesList) => {
        const {filter} = this.props;

        if (filter) {


            save(filter.filterName, activeCheckboxes);
            this.setState(() => ({activeCheckboxes}))
        }

    };

    createItem = async (data: any) => {
        const {create} = this.props;

        if (create) {
            try {
                const item = await create.handler(data);

                this.setState((prevState) => ({
                    detail: "detail",
                    list: [item, ...prevState.list],
                    activeItemId: item.id,
                }))

            } catch (e) {
                checkError(e)
            }
        }

    };

    getReference = (activeItemId: number) => {
        this.setState(() => ({
            activeItemId,
            detail: "detail"

        }))
    };

    getFilter = async () => {
        const {filter} = this.props;
        if (filter) {
            try {
                const checkboxesMap = await filter.getCheckboxesMap();

                this.isFilters = Object.keys(checkboxesMap).length > 0;

                this.setState(() => ({
                    checkboxesMap,
                    activeCheckboxes: get(filter.filterName)
                }))
            } catch (e) {
                checkError(e)
            }
        }
    };


    renderItem = (item: any) => {
        const {listItem, alwaysActive} = this.props;
        return (
            <div
                className={`list-item ${item.id === this.state.activeItemId ? 'active' : ''} ${item.active || alwaysActive ? "" : "inactive"}`}
                onClick={this.getReference.bind(this, item.id)}
            >
                {listItem.render(item)}
            </div>
        )
    };


    resetState = () => {
        if (this.props.moduleRights && this.props.moduleRights.read) {

            this.getList();
            this.getFilter()
        }
    };

    async componentDidMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            JSON.stringify(prevProps.moduleRights) !== JSON.stringify(this.props.moduleRights) ||
            JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)
        ) {
            this.resetState()
        }
    }

    render() {
        const {create, customButtons, Detail, CustomHeader, search = true, moduleRights, moduleClass} = this.props;
        const {activeItemId, activeCheckboxes} = this.state;


        const createComponent = create ? <create.Component create={this.createItem}/> : <></>;

        if (!moduleRights) {
            return <WithPrivateRoute/>
        }


        return (
            <>
                {moduleRights && moduleRights.read ? (
                    <WithPrivateRoute>

                        <HeaderController>
                            <ModuleTextName>{getText(this.props.referenceName)}</ModuleTextName>
                            <RenderIf condition={CustomHeader}>
                                <CustomHeader updateList={this.updateList}/>
                            </RenderIf>
                            {this.props.filter && this.isFilters && (
                                <Filter
                                    send={this.filterSubmit}
                                    activeCheckboxes={activeCheckboxes}
                                >
                                    <CheckboxList
                                        checkboxesMap={this.state.checkboxesMap}
                                        send={this.onCheckboxesChange}
                                        activeCheckboxes={activeCheckboxes}
                                    />
                                </Filter>
                            )}

                            <RenderIf condition={search}>
                                <Search
                                    submit={this.searchSubmit}
                                    eraser={true}
                                />
                            </RenderIf>

                            {create && moduleRights && moduleRights['create'] && (

                                <div className='navbar-form navbar-left buttons'>

                                    <Tooltip position="down" title={create.title}>
                                        <Button
                                            onClick={this.changeDetail.bind(this, 'add')}
                                            className="btn-default"
                                        >
                                            <i className="fa fa-plus"/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                            {customButtons && (
                                <div className='navbar-form navbar-left buttons'>
                                    {
                                        customButtons.map(customButton => (
                                            <Tooltip position="down" title={customButton.title}>
                                                <Button
                                                    onClick={customButton.handler}
                                                    className="btn-default"
                                                >
                                                    <i className={customButton.icon}/>
                                                </Button>
                                            </Tooltip>
                                        ))
                                    }
                                </div>
                            )}
                        </HeaderController>

                        <div className={["just-pro_module", moduleClass]?.filter((c) => c).join(" ")}>
                            <div className="panel"/>
                            <div className="panel"/>
                            <div className="panel content-panel">
                                {this.props.counterTranslates && <ListFilterController
                                    counter={{
                                        value: parseInt(this.state.count),
                                        vars: this.props.counterTranslates
                                    }}
                                />}
                                <ItemList
                                    loading={this.state.loading}
                                    renderItem={this.renderItem}
                                    list={this.state.list}
                                    updateData={this.nextPage}
                                    backScrollToTop={this.state.needBackScroll}
                                />
                            </div>
                            <div className="panel content-panel">

                                <RenderIf condition={this.state.detail === 'detail' && activeItemId !== undefined}>
                                    <Detail
                                        id={activeItemId}
                                        afterUpdate={this.afterEditItem}
                                        updateList={this.updateList}
                                        clearItem={this.clearActive}
                                    />
                                </RenderIf>

                                <RenderIf condition={this.state.detail === 'add' && create !== undefined}>
                                    {createComponent}
                                </RenderIf>
                            </div>
                        </div>
                    </WithPrivateRoute>

                ) : (
                    <WithPrivateRoute>
                        <HeaderController> </HeaderController>
                        <HasNoRightsController/>

                    </WithPrivateRoute>
                )}
            </>
        )
    }
}

export default BaseReferenceController
