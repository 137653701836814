import {renderToString} from "react-dom/server";
import React from "react";
import getText from "../localization/getText";

export interface Vars {
    notFound : string,
    one : string,
    two : string,
    default : string
}

function normalizeModuleCount(vars:Vars, val?:number):JSX.Element {
    let  name;

    if(!val || +val === 0) {
        return <>{getText(vars.notFound)} {renderToString(getText('UI.counter.notFound'))}</>
    }

    const count = val % 100;

    if (count>=11 && count<=19) {
        return  <>{getText('UI.counter.found')} {val} {getText(vars.default)}</>
    } else {
        let i = count % 10;
        switch (i) {
            case 1: name = <>{(getText('UI.counter.found'))} {val} {getText(vars.one)}</>;
                break;
            case 2:
            case 3:
            case 4: name =  <>{getText('UI.counter.found')} {val} {getText(vars.two)}</>;
                break;
            default: name =  <>{getText('UI.counter.found')} {val} {getText(vars.default)}</>
        }
    }
    return name;
}

export default normalizeModuleCount

