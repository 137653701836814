import React from 'react'
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller'
import HeaderController from '../../header/header.controller'
import ModuleTextName from '../../UI/moduleTextName/moduleTextName'
import './executorReconciliation.css'
import TopLineCalendar from '../../UI/calendar/views/topLine.controller'
import moment, { Moment } from 'moment'
import CheckboxSelectController from '../../UI/checkboxSelect/checkboxSelect.controller'
import checkError from '../../../utils/checkError'
import {
	format,
	getRegions,
	getReviseExecutors,
	getReviseExecutorsExport,
	ActRevise,
	ReviseExecutor,
	getReviseExecutorExcel,
} from '@justpro/terminal'
import Spinner from '../../UI/spinner/spinner.controller'
import { Angle } from '../../UI/itemsList/card/card.view'
import getPersonPhoto from '../../../utils/getPersonPhoto'
import Table from '../../UI/table/table'
import RenderIf from '../../../utils/renderIf'
import Tooltip from '../../UI/tooltip/tooltip.controller'
import Button from '../../UI/button/button'
import FileDownload from 'js-file-download'
import getText from '../../../localization/getText'
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper'

type DataType = {
	date: string
	// TODO: ditermine type
	acts: any[]
	//
	distance: number
}

interface Props {}

interface State {
	from: Moment
	to: Moment
	q: string
	selectedRegions: number[]
	list: ReviseExecutor[]
	detailLoading: boolean
	listLoading: boolean
	generatingArchive: boolean
	generatingExcel: boolean
	data: DataType[]
	selectedItem?: ReviseExecutor
}

class ExecutorsReconciliation extends React.Component<Props, State> {
	state: State = {
		from: moment().subtract(1, 'month').startOf('month'),
		to: moment().subtract(1, 'month').endOf('month'),
		q: '',
		selectedRegions: [],
		list: [],
		detailLoading: false,
		listLoading: false,
		generatingArchive: false,
		generatingExcel: false,
		data: [],
	}

	renderItem = (item: any) => {
		return (
			<div
				key={item.id}
				className={`mileage__list-item ${
					item.personFullName ===
					this.state.selectedItem?.personFullName
						? 'active'
						: ''
				}`}
				onClick={this.getDetail.bind(this, item)}
			>
				<Angle size={30} />
				<div className='mileage__list-item__photo'>
					<img
						src={getPersonPhoto(undefined)}
						alt='Фото пользователя'
					/>
				</div>
				<div className='mileage__list-item__name'>
					{item?.personFullName}
				</div>
			</div>
		)
	}

	getList = async () => {
		try {
			if (!this.state.selectedRegions?.length) {
				this.setState({
					list: [],
					selectedItem: null,
				})
				return
			}
			this.setState({
				listLoading: true,
			})

			const filters = {
				regionsId: this.state.selectedRegions.map((r: any) => r.id),
				dateFrom: this.state.from.format(format.date),
				dateTo: this.state.to.format(format.date),
			}

			const executors = await getReviseExecutors(filters)

			this.setState({
				list: executors,
			})
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				listLoading: false,
			})
		}
	}
	getDetail = async (item: any) => {
		try {
			this.setState({
				selectedItem: item,
			})
			const dates = []
			const start = this.state.from.clone()
			const end = this.state.to.clone()
			while (end.isAfter(start)) {
				const date = start.clone()
				const dateStr = date.format('DD.MM.YYYY')
				dates.push({
					date: dateStr,
					acts: item.acts.filter((act: ActRevise) => {
						return moment(act.date).format('DD.MM.YYYY') === dateStr
					}),
					distance:
						item.dates.find((d: any) => {
							return (
								moment(d.date).format('DD.MM.YYYY') === dateStr
							)
						})?.distance || 0,
				})
				start.add(1, 'days')
			}
			this.setState({
				data: dates,
			})
		} catch (e) {
			checkError(e)
		} finally {
			this.setState(() => ({ detailLoading: false }))
		}
	}

	onChangeFrom = (from: Moment) => {
		this.setState(() => ({ from }), this.getList)
	}

	onChangeTo = (to: Moment) => {
		this.setState(() => ({ to: to.endOf('day') }), this.getList)
	}

	onChangeRegion = (regions: any[]) => {
		this.setState(
			() => ({
				selectedRegions: regions,
			}),
			this.getList,
		)
	}

	getRegionsList = async () => {
		try {
			return await getRegions({ q: this.state.q })
		} catch (e) {
			checkError(e)
			return []
		}
	}

	getPersonsList = () => {}

	calcResult = (
		accessor:
			| 'time'
			| 'hoursOder'
			| 'hoursSystem'
			| 'distance'
			| 'checkHours'
			| 'initialHours',
	): number => {
		return (
			this.state.data?.reduce((acc: number, item) => {
				item?.acts?.forEach((act: any) => {
					acc += act?.[accessor] || 0
				})
				return acc
			}, 0) || 0
		)
	}

	isEqualInitialCheck(act) {
		return act?.checkHours === 0 || act?.initialHours === act?.checkHours
	}

	get columns() {
		return [
			{
				Header: getText('common.date'),
				Cell: (props: any) => <span>{props.original.date}</span>,
				Footer: <span>{getText('common.total')}</span>,
			},
			{
				Header: getText('reports.executorReconciliation.actTime'),
				columns: [
					{
						Header: getText('acts.actNumber'),
						Cell: (props: any) =>
							props?.original?.acts?.map((item: any) => {
								return <p>{item.number}</p>
							}),
					},
					{
						Header: getText('common.hours'),
						Cell: (props: any) =>
							props?.original?.acts?.map((item: any) => {
								return (
									<p>
										{((item?.time || 0) / 3600).toFixed(2)}
									</p>
								)
							}),
						Footer: (this.calcResult('time') / 3600).toFixed(2),
					},
					{
						Header: getText(
							'reports.executorReconciliation.handleCutOff',
						),
						Cell: (props: any) =>
							props?.original?.acts?.map((item: any) => {
								return (
									<p>
										{(
											(item?.hoursOder || 0) / 3600
										).toFixed(2)}
									</p>
								)
							}),
						Footer: (this.calcResult('hoursOder') / 3600).toFixed(
							2,
						),
					},
					{
						Header: getText(
							'reports.executorReconciliation.autoCutOff',
						),
						Cell: (props: any) =>
							props?.original?.acts?.map((item: any) => {
								return (
									<p>
										{(
											(item?.hoursSystem || 0) / 3600
										).toFixed(2)}
									</p>
								)
							}),
						Footer: (this.calcResult('hoursSystem') / 3600).toFixed(
							2,
						),
					},
					{
						Header: getText(
							'reports.executorReconciliation.totalHours',
						),
						Cell: (props: any) =>
							props?.original?.acts?.map((item: any) => {
								return (
									<p>
										{(
											((item?.time || 0) -
												(item?.hoursOder || 0) -
												(item?.hoursSystem || 0)) /
											3600
										).toFixed(2)}
									</p>
								)
							}),
						Footer: (
							(this.calcResult('time') -
								this.calcResult('hoursOder') -
								this.calcResult('hoursSystem')) /
							3600
						).toFixed(2),
					},
				],
			},
			{
				Header: getText('common.km'),
				accessor: 'distance',
				Footer: (
					<span>
						{this.state.data?.reduce((distance, date) => {
							return (distance += date?.distance || 0)
						}, 0)}
					</span>
				),
			},
			{
				Header: getText(
					'reports.executorReconciliation.checkInCheckOutHours',
				),
				Cell: (props: any) =>
					props?.original?.acts?.map((act: any) => {
						return (
							<p
								className={
									this.isEqualInitialCheck(act)
										? ''
										: 'warning-text'
								}
							>
								{((act?.checkHours || 0) / 3600).toFixed(2)}
							</p>
						)
					}),
				Footer: (this.calcResult('checkHours') / 3600).toFixed(2),
			},
			{
				Header: getText('reports.executorReconciliation.executorHours'),
				Cell: (props: any) =>
					props?.original?.acts?.map((act: any) => {
						return (
							<p
								className={
									this.isEqualInitialCheck(act)
										? ''
										: 'warning-text'
								}
							>
								{((act?.initialHours || 0) / 3600).toFixed(2)}
							</p>
						)
					}),
				Footer: (this.calcResult('initialHours') / 3600).toFixed(2),
			},
			{
				Header: getText('reports.executorReconciliation.correction'),
				Cell: (props: any) =>
					props?.original?.acts?.map((act: any) => {
						return (
							<p
								className={
									this.isEqualInitialCheck(act)
										? ''
										: 'warning-text'
								}
							>
								{this.isEqualInitialCheck(act)
									? getText('common.no')
									: getText('common.yes')}
							</p>
						)
					}),
			},
		]
	}

	getZipArchive = async () => {
		try {
			if (!this.state.selectedRegions?.length) return
			this.setState({
				generatingArchive: true,
			})
			const dateFrom = this.state.from.format(format.date)
			const dateTo = this.state.to.format(format.date)
			const regionsName = this.state.selectedRegions
				.map((r: any) => r.name)
				.join('_')
			const blob = await getReviseExecutorsExport({
				regionsId: this.state.selectedRegions.map((r: any) => r.id),
				dateFrom,
				dateTo,
			})
			FileDownload(
				blob,
				`${regionsName}__${dateFrom}---${dateTo}.zip`,
				'application/zip',
			)
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				generatingArchive: false,
			})
		}
	}

	getExcelExecutor = async () => {
		try {
			if (!this.state.selectedRegions?.length || !this.state.selectedItem)
				return
			this.setState({
				generatingExcel: true,
			})
			const dateFrom = this.state.from.format(format.date)
			const dateTo = this.state.to.format(format.date)
			const blob = await getReviseExecutorExcel(
				this.state.selectedItem?.personId,
				{
					regionsId: this.state.selectedRegions.map((r: any) => r.id),
					dateFrom,
					dateTo,
				},
			)
			FileDownload(
				blob,
				`${this.state.selectedItem?.personFullName}__${dateFrom}---${dateTo}.xls`,
			)
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				generatingExcel: false,
			})
		}
	}

	render() {
		return (
			<WithPrivateRoute>
				<HeaderController>
					<ModuleTextName>
						{getText('reports.executorReconciliation.moduleName')}
					</ModuleTextName>

					<ToplineCalendarWrapper>
						<TopLineCalendar
							date={this.state.from}
							onChange={this.onChangeFrom}
						/>
						<TopLineCalendar
							date={this.state.to}
							onChange={this.onChangeTo}
						/>
					</ToplineCalendarWrapper>

					<CheckboxSelectController
						onChange={this.onChangeRegion}
						getList={this.getRegionsList}
						selected={this.state.selectedRegions}
						pickAllNodes='reports.executorReconciliation.allRegions'
						placeholderParams={{
							maxItemLength: 2,
							multiplyPlaceholder: 'regions.regions',
						}}
					/>

					<div className='navbar-form navbar-left buttons'>
						<Tooltip
							position='down'
							title='reports.executorReconciliation.archiveToSend'
						>
							<RenderIf
								condition={
									!this.state.generatingArchive &&
									this.state.selectedRegions?.length
								}
							>
								<Button
									onClick={this.getZipArchive}
									className='btn-default'
								>
									<i className='fa fa-archive' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.generatingArchive}>
								{getText('common.generating')}
							</RenderIf>
						</Tooltip>
						<Tooltip
							position='down'
							title='reports.executorReconciliation.excel'
						>
							<RenderIf
								condition={
									!this.state.generatingExcel &&
									this.state.selectedRegions?.length &&
									this.state.selectedItem
								}
							>
								<Button
									onClick={this.getExcelExecutor}
									className='btn-default'
								>
									<i className='fa fa-file-excel' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.generatingExcel}>
								{getText('common.generating')}
							</RenderIf>
						</Tooltip>
					</div>
				</HeaderController>

				<div className='just-pro_module executorReconciliation'>
					<div className='panel' />
					<div className='panel' />
					<div className='panel content-panel'>
						{this.state.list?.map(this.renderItem)}
						<Spinner loading={this.state.listLoading} />
					</div>

					<div className='panel content-panel'>
						<RenderIf
							condition={
								this.state.selectedItem &&
								!this.state.detailLoading
							}
						>
							<Table
								wrapperClassName={'report_flex-table'}
								columns={this.columns}
								data={this.state.data}
							/>
						</RenderIf>

						<Spinner loading={this.state.detailLoading} />
					</div>
				</div>
			</WithPrivateRoute>
		)
	}
}

export default ExecutorsReconciliation
