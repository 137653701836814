import React from 'react';
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import Button from "../../UI/button/button";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import Card from '../../UI/itemsList/card/card.controller'
import Search from "../../UI/search/search.controller";
import RenderIf from "../../../utils/renderIf";
import Spinner from '../../UI/spinner/spinner.controller'
import DetailWaitRequest from './waitRequest.detail'
import checkError from "../../../utils/checkError";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import {editWaitRequest, getWaitRequest, WaitRequest} from "@justpro/terminal";
import WaitRequestDetail from "./waitRequest.detail";

interface Props {
    id: number
    afterUpdate?(item:WaitRequest) : void
}

interface State {
    loading : boolean
    waitRequest? : WaitRequest
}


export default class WaitRequestController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };

    get = async () => {
        try {
            this.setState(() => ({loading : true}));

            const waitRequest = await getWaitRequest(this.props.id);

            this.setState(() => ({
                loading : false,
                waitRequest
            }))
        }catch (e) {
            checkError(e);
            this.setState(() => ({loading : false}));
        }
    };


    update = async (data:Partial<WaitRequest>) => {

        try {
            this.setState(() => ({loading : true}));

            const waitRequest = await editWaitRequest(this.props.id, data);

            if(waitRequest) {
                this.setState(() => ({
                    loading : false,
                    waitRequest
                }));

                if(data.hasOwnProperty('active') || data.hasOwnProperty('name') ) {
                    this.props.afterUpdate && this.props.afterUpdate(waitRequest)
                }
            }


        }catch (e) {
            checkError(e);
            this.setState(() => ({loading : false}));
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(prevProps.id !== this.props.id) {
            this.get();
        }
    }

    async componentDidMount() {
        this.get();
    }


    render () {
        const {waitRequest} = this.state;

        return (
            <>
                <RenderIf condition={waitRequest !== undefined}>
                    <WaitRequestDetail waitRequest={waitRequest as WaitRequest} update={this.update} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )

    }

}