import * as api from '@justpro/terminal';
import checkError from "../../../../utils/checkError";
import {userTypes} from "../../user.reducer";
import {rolesTypes} from "../../roles.reducer";
import {groupTypes} from "../../groups.reducer";
import {listTypes} from "../../list.reducer";
import {GetUsers, UserSettings} from "@justpro/terminal";

export const removeUserRole = (userId:number, roleId:number) => async (dispatch: Function) => {
    try {
        const removeRoleResult = await api.removeUserRole(userId, roleId);
        if (!removeRoleResult) return;
        const {roles} = await api.getUser(userId);
        const map = await api.getRoles();

        dispatch({
            type : rolesTypes.UPDATE_ROLES_MAP,
            map
        });

        dispatch({
            type : userTypes.UPDATE_USER,
            // objects,
            roles,
            // groups
        })

    } catch (err) {
        checkError(err);
    }
};

export const updateUserSettings = (userId: number, data: Partial<UserSettings>) => async (dispatch: Function) => {
    try {
        const settings = await api.updateUserSettings(userId, data);
        const user = await api.getUser(userId);
        dispatch({
            type : userTypes.GET_USER,
            ...user
        });
    } catch (err) {
        checkError(err);
    }
}

export const getUser = (userId: number) => async (dispatch: Function) => {
    try {
        const user = await api.getUser(userId);
        dispatch({
            type : userTypes.GET_USER,
            ...user
        });
    } catch (err) {
        checkError(err);
    }
}

export const removeUserGroup = (userId:number, groupId:number) => async (dispatch: Function) => {
    try {
        const removeGroupResult = await api.removeUserGroup(userId, groupId);
        if (!removeGroupResult) return;
        const {groups} = await api.getUser(userId);
        const map = await api.getGroups();

        dispatch({
            type : groupTypes.UPDATE_GROUP_MAP,
            map
        });

        dispatch({
            type : userTypes.UPDATE_USER,
            // roles,
            groups,
            // objects
        })
    } catch (err) {
        checkError(err);
    }
};

export const removeUserObject = (userId:number, objectId:number) => async (dispatch:Function) => {
    let remove = await api.removeUserObject(userId, objectId);
    if(!remove) return;
    const {objects} = await api.getUser(userId);


    dispatch ({
        type : userTypes.UPDATE_USER,
        objects
    })
};

export const addUserObject = (userId:number, objectId: number) => async (dispatch: Function) => {
    try {
        const addObjectResult = await api.addUserObject(userId, objectId);
        if (!addObjectResult) return;
        const {objects} = await api.getUser(userId);

        dispatch({
            type : userTypes.UPDATE_USER,
            objects
        })

        // return await actualizeUserData(dispatch, userId);
    } catch (err) {
        checkError(err);
    }
};

export const updateUser = (userID: number, data: api.UpdateUser, searchData?:GetUsers) => async (dispatch: Function) => {
    try {
        const user = await api.updateUser(userID, data);
        if(searchData) {

            getUsers(searchData);
            // const users = await api.getUsers(searchData);
            // dispatch({
            //     type : listTypes.GET_USERS_LIST,
            //     users
            // });
        }

        dispatch({
            type: userTypes.UPDATE_USER,
            ...user
        });
    } catch (err) {
        checkError(err);
    }
};

export const getUsers = (filters:GetUsers) => async (dispatch:Function) => {
    try {
        const users = await api.getUsers(filters);

        dispatch({
            type : listTypes.GET_USERS_LIST,
            users
        });

    }catch (e) {
        checkError(e)
    }
};

export const addUserContractor = (userId:number, contractorId:number) => async (dispatch:Function) => {
    try {
        const added = await api.addUserContractor(userId, contractorId);

        dispatch({
            type: userTypes.ADD_USER_CONTRACTOR,
            contractor : added
        });
    }catch (e) {
        checkError(e)
    }
};

export const removeUserContractor = (userId:number, contractorId:number) => async (dispatch:Function) => {
    try {
        const removed = await api.removeUserContractor(userId, contractorId);

        dispatch({
            type: userTypes.REMOVE_USER_CONTRACTOR,
        });
    }catch (e) {
        checkError(e)
    }
};