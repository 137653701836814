import { toast } from 'react-toastify'
import number from '../components/UI/input/number'
import checkError from './checkError'

export function toDayHoursFormat(hours: number): string {
	const d = Math.floor(hours / 24)
	const h = hours % 24

	return `${d > 0 ? `${d}дн.` : ''} ${h > 0 ? `${h}ч.` : ''}`
}

export function secondsToDayHoursFormat(seconds: number): string {
	const d = Math.floor(seconds / 86400)
	const h = Math.floor((seconds % 86400) / 3600)
	const m = (seconds % 3600) / 60

	return `${d > 0 ? `${d}дн.` : ''} ${h > 0 ? `${h}ч.` : ''} ${
		m > 0 ? `${m}м.` : ''
	}`
}

function narrowToNumeric(val: string | number): number | undefined {
	const numeric = typeof val === 'string' ? parseInt(val) : val

	if (numeric) {
		return numeric
	} else {
		checkError(new Error('errors.valueMustBeANumber'))
		// toast.warn(`Значение ${fieldName ? fieldName : ''} должно быть числом.`)
	}
}
export function fromDaysToHours(value: string | number) {
	const val = narrowToNumeric(value)

	if (val) {
		return val * 24
	}
}

export function fromHoursToDays(value: string | number) {
	const val = narrowToNumeric(value)

	if (val) {
		return Math.floor(val / 24)
	}
}

export function fromHoursToSeconds(value: string | number) {
	const val = narrowToNumeric(value)

	if (val) {
		return val * 3600
	}
}
