import React, {useEffect, useState} from "react";
import {Props, SwitcherValue} from "./textSwitcher.types";
import {TextSwitcherView} from "./textSwitcher.view";
import getText from "../../../../localization/getText";



const TextSwitcher = (props:Props) => {
    const [values, setValues] = useState<SwitcherValue[]>([]);
    const [active, setActive] = useState(0);



    const getNextValue = async () => {
        const nexValue = values[active + 1];

        if(nexValue) {
            await setActive(active + 1)
        }else{
            await setActive(0)
        }

        props.changeHandler && props.changeHandler(values[active]);
    };

    useEffect(() => {
        setValues(props.values.map((item, index) => ({
            ...item,
            id : index
        })));
        setActive(0)
    }, [props.id]);

    return (
        <TextSwitcherView onClick={getNextValue}>
            <span className="title">{getText(props.title)}</span>
            <span className="values" >
          {values.map((value, index) => {
              const cls = ['text-switcher__value'];
              if(index === active) {
                  cls.push('active')
              }
              return (
                  <span
                      className={cls.join(' ')}
                      key={index}
                  >
                  {getText(value.text)}
              </span>)
          })}
        </span>
        </TextSwitcherView>
    )
};


export default TextSwitcher