import store from "../index";
import {filterActions} from "./filters.reducer";
import {
    getCities,
    getAreas,
    getStatusGroups,
    getRegions,
    getTags,
    getContractConditions,
    getEquipmentCategories,
    getContracts,
    getContractors,
    getContractsAliases,
    getOrderTypes,
    getUrgencyOrders, getPersons, getActPhases, getTasksStates,
    getEquipmentWorksFilter, getEquipmentTypesFilter, getWorkGroupsFilter, getRegularWorkStatuses, getIsMaster,
    getMasters,
} from './functions'
import {FilterName} from './filters.types'
import { getCloseTypes } from "@justpro/terminal";

// const map:FilterNamesMap = {
//     cities : getCities,
//     areas : getAreas,
//     regions : getRegions,
//     contracts : getContracts,
//     contractsAlias : getContractsAliases,
//     orderTypes : getOrderTypes,
//     urgencyOrders : getUrgencyOrders,
//     equipmentCategories : getEquipmentCategories,
//     contractsConditions : getContractConditions,
//     contractors : getContractors,
//     statusGroups : getStatusGroups
// };

export const getFilter = (filterName:FilterName, ...rest) => async (dispatch) => {
    const {filters, application} = store.getState();
    const {filters:existFilters} = filters;
    const {rights} = application;
    let values = [];

    if(!existFilters[filterName]) {

        switch (filterName) {
            case "cities" :
                if(rights?.['references.cities']?.read) {
                    values = await getCities();
                }
                break;
            case "areas" :
                if(rights?.['references.areas']?.read) {
                    values = await getAreas();
                }
                break;
            case "masters" :
                if(rights?.['references.masters']?.read) {
                    values = await getMasters({
                        limit: 10000,
                    });
                }
                break;
            case "isMaster" :
                values = getIsMaster();
                break;
            case "regions" :
                if(rights?.['references.regions']?.read) {
                    values = await getRegions();
                }
                break;
            case "contractsConditions" :
                if(rights?.['references.contractConditions']?.read) {
                    values = await getContractConditions();
                }
                break;
            case "contracts" :
                if(rights?.['references.contracts']?.read) {
                    values = await getContracts();
                }
                break;
            case "contractsAlias" :
                if(rights?.['references.contracts']?.read) {
                    values = await getContractsAliases({q : ''}, existFilters.contracts);
                }
                break;
            case "orderTypes" :
                if(rights?.['references.ordersTypes']?.read) {
                    values = await getOrderTypes();
                }
                break;
            case "urgencyOrders" :
                    values = getUrgencyOrders();
                break;
            case "urgencyRegularWorks" :
                values = getUrgencyOrders();
                break;
            case "equipmentCategories" :
                if(rights?.['references.equipmentCategories']?.read) {
                    values = await getEquipmentCategories();
                }
                break;
            case "contractors" :
                if(rights?.['references.contractors']?.read) {
                    values = await getContractors(...rest);
                }
                break;
            case "statusGroups" :
                // if(rights?.['references.cities']?.read) {
                    values = await getStatusGroups();
                // }
                break;
            case "actTags":
            case "taskTags":
                if(rights?.['references.tags']?.read) {
                    values = await getTags(...rest);
                }
                break;
            case "executors":
            case "persons":
                if(rights?.['references.persons']?.read) {
                    values = await getPersons({ q: '', ...rest[0]});
                }
                break;
            case "actPhases":
                if(rights?.['references.actsPhases']?.read) {
                    values = await getActPhases();
                }
                break;

            case "tasksStates" :
                 values = await getTasksStates();
                break;
            case "regularWorkStates" :
                values = await getRegularWorkStatuses();
                break;
            case "equipmentWorks" :
                if(rights?.['references.equipmentWorks']?.read) {
                    values = await getEquipmentWorksFilter();
                }
                break;
            case "equipmentTypes" :
                if(rights?.['references.equipmentTypes']?.read) {
                    values = await getEquipmentTypesFilter();
                }
                break;
            case "closeTypes" :
                    values = await getCloseTypes({});
                break;
            case "workGroups":
                if(rights?.['references.workGroups']?.read) {
                    values = await getWorkGroupsFilter();
                }
                break;

        }

        dispatch({
            type : filterActions.SET_FILTER,
            values: values,
            filterName,
        })
    }else{
        values = existFilters[filterName]
    }

    return values
};
