import React, {Component} from "react";
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import Detail from './detail/detail'
import {dnd} from './usersWithDnd/dnd.types'
import UsersList from '../../components/users/usersList/usersList'
import ModuleTextName from '../../components/UI/moduleTextName/moduleTextName'
import Search from '../UI/search/search.controller'
import Notify from "../UI/notify/notify.controller";
import {
    getAddUserPanel,
    getTree,
    deleteGroup,
    deleteRole,
    searchUsers, addUserGroup, addUserRole,
    createRole, createGroup
} from "../../store/users/actions/users.actions";
import {getUserDetail} from '../../store/users/actions/usersList/usersList.actions';
import { getGroup, getRole} from '../../store/users/actions/tree/tree.actions'
import {connect} from "react-redux";
import {UsersProps,UsersMapStateToProps, State, DropType} from './users.types'
import Button from '../UI/button/button'
import './users.css'
import Spinner from '../UI/spinner/spinner.controller'
import {GroupsMap, GetUsers, RolesMap} from '@justpro/terminal'
import Input from "../UI/input/text";
import WithUsersDrag from './usersWithDnd/usersWithDrag'
import TreeIcon from "../UI/tree/tree.icon";
import TreeController, {TreeItem} from "../UI/tree_new/tree.controller";
import Caret from "../UI/caret/caret";
import HeaderController from "../header/header.controller";
import HasNoRightsController from "../UI/hasNoRights/hasNoRights.controller";
import {renderToString} from "react-dom/server";
import getText from "../../localization/getText";
import {openModal} from "../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";
import {toast} from "react-toastify";
import DefaultFooterModal from "../UI/dialog/modal/defaultFooterModal";

class Users extends Component<UsersProps, State> {

    state:State = {
        q : '',
        usersListSpinner : false,
        panelSpinner : false,
        treeSpinner : false,
        newName : '',
    };
    hash:number[] = [];

    combineTreeProps = (map:RolesMap | GroupsMap, children?:number[]):TreeItem[] => {

        if (children) {
            return children.reduce( (result:TreeItem[], childId) => {
                if(!this.hash.includes(childId)) {
                    const {name, children} = map[childId];
                    this.hash.push (childId);
                    return [...result, {
                        // ...additionalProps,
                        // isOpen : true,
                        name,
                        id : childId,
                        children : children ? this.combineTreeProps(map, children) : undefined
                    }]
                }

                return [...result]
            }, [])
        }


        return Object.entries(map).reduce( (result:TreeItem[], [id, treeItem]) => {
            const {name, children:TreeItemChildren} = treeItem;

            if(!this.hash.includes(+id)) {
                const item = {
                    // ...additionalProps,
                    name : name,
                    // isOpen : true,
                    id : +id,
                    children : TreeItemChildren ? this.combineTreeProps(map, TreeItemChildren) : undefined
                };
                this.hash.push (+id);
                return [...result, item]

            }
            return [...result]


        }, []);
    };


    isDisabled = ():boolean => {
        const {groupId, roleId} = this.props;

        return groupId === -1 && roleId === -1;
    };


    getDeleteModal = () => {
        const {tree, rolesMap, groupsMap, groupId, roleId} = this.props;

        const item = tree === 'role' ? 'users.role' : 'users.group';
        const name = tree === 'role' ? rolesMap[roleId].name : groupsMap[groupId].name;


        this.props.openModal({
            id : 'deleteRoleOrGroup',
            component : (props:ModalBodyProps) => (
                <>
                    {renderToString(getText('common.delete'))} {renderToString(getText(item))} {name} ?

                    <DefaultFooterModal>
                        <Button className="btn-success" onClick={this.deleteTreeItem.bind(this, props.hide)}>{getText('common.delete')}</Button>
                    </DefaultFooterModal>
                </>
            ),
            title : `${renderToString(getText('users.areYouSureToDelete'))} ${renderToString(getText(item))} ?`,
            size : "small"
        })
    };

    onChangeAddNewNode = (val:string) => {
        this.setState({
            newName : val,
        });
    };


    getAddModal = () => {
        const {tree, groupId, roleId, rolesMap, groupsMap} = this.props;
        const item = tree === 'role' ? 'users.role' : 'users.group';
        const id = tree === 'role' ? roleId : groupId;
        const panel = tree === 'role' ? rolesMap : groupsMap;


        const name = panel[id].name;

        const {detail} = this.props;

        this.props.openModal({
            id : 'usersRemoveTreeItem',
            title : `${renderToString(getText('users.addingNew'))} ${renderToString(getText(item))} ${renderToString(getText('common.in')).toLowerCase()} ${name}`,
            // buttonSave : {
            //     title : getText("common.add"),
            //     handler : this.addTreeItem.bind(this, id)
            // },
            component : (props:ModalBodyProps) => (
                <>
                    <div>
                        <Input label={renderToString(getText('users.nameOfNew')) + renderToString(getText(item))} change={this.onChangeAddNewNode}/>

                        <DefaultFooterModal>
                            <Button className="btn-success" onClick={this.addTreeItem.bind(this, id, props.hide)}>{getText('common.add')}</Button>
                        </DefaultFooterModal>
                    </div>
                </>
            )
        });
    };

    addTreeItem = async (id: number, hide: () => void) => {
        const {tree, createRole, createGroup} = this.props;
        const {newName} = this.state;

        if(newName?.trim() !== '') {
            tree === "role" ? createRole(id, newName) : createGroup(id, newName);
            hide();
        }else{
            toast.warn(renderToString(getText(tree === "role" ? "users.errorRole" : "users.errorGroup")))
        }
    };

   deleteTreeItem = async (hide : () => void) => {
       const {roleId, groupId, tree, deleteRole, deleteGroup} = this.props;
       await tree === "role" ? deleteRole(roleId) : deleteGroup(groupId);
       hide();
   };

    getRightsDetail = async (id:number) => {

        this.setState({
            panelSpinner : true,
            usersListSpinner : true
        });

        await this.props.getGroup(id);

        this.setState({
            panelSpinner : false,
            usersListSpinner : false
        });
    };

    onRolesDragEnd = ( role:DropType, monitor:any) => {
        const dropResult = monitor.getDropResult();

        if(dropResult) {
            const {id} = dropResult;
            this.props.addUserRole(id, role.id)
        }
    };

    onGroupsDragEnd = ( group:DropType, monitor:any) => {
        const dropResult = monitor.getDropResult();

        if(dropResult) {
            const {id} = dropResult;
            this.props.addUserGroup(id, group.id)
        }

    };

    getRolesDetail = async (id:number)  => {
        this.setState({
            panelSpinner : true,
            usersListSpinner : true
        });

        await this.props.getRole(id);

        this.setState({
            panelSpinner : false,
            usersListSpinner : false
        });
    };

    onSearch = async (q:string) => {
        const {groupId, roleId} = this.props;

        let searchData:GetUsers = {
            q
        };

        if(groupId !== -1) {
            searchData.groupId = groupId
        }

        if(roleId !== -1 && roleId !== 1) {
            searchData.roleId = roleId
        }

        await this.setState(() => ({
            q
        }));

        await this.props.search(searchData);
    };


    getId = (e:React.MouseEvent):number => {
        let id = e.currentTarget.getAttribute('data-id');

        if( typeof id !== "object") {
            return +id
        }else{
            return -1;
        }
    };

    getUserDetail = async (e:React.MouseEvent) => {
        this.setState({
            panelSpinner : true
        });

        await this.props.getUser(this.getId(e));

        this.setState({
            panelSpinner : false
        });
    };

    resetState = async () => {

        const {rights} = this.props;

        if(rights && rights['users'] && rights['users'].read) {
            this.setState({
                treeSpinner : true
            });
            await this.props.getTree();

            this.setState({
                treeSpinner : false
            });
        }
    };

     async componentDidMount() {
         this.resetState();
     }


     async componentDidUpdate(prevProps: Readonly<UsersProps>) {
         if(JSON.stringify(this.props.rights) !== JSON.stringify(prevProps.rights)) {
             this.resetState();
         }
     }

    renderRolesItem = (item:TreeItem) => {

        return (<>
            <WithUsersDrag item={{
                dropType : dnd.ROLE,
                onDragEnd : this.onRolesDragEnd,
                id: item.id
            }}>
                <span
                    data-id={item.id}
                >
                    {item.children && item.children.length > 0 &&
                        <Caret
                            isOpen={item.isOpen}
                            //@ts-ignore
                            onClick={item.toggleOpen ? item.toggleOpen : undefined}
                        />
                    }

                     <i className="just-pro__tree-icon fa fa-users"/>
                    <span
                        onClick={this.getRolesDetail.bind(this, item.id)}
                        className={this.props.roleId === item.id ? 'just-pro__users_tree--active' : ''}
                    >
                        {item.name}
                    </span>
                </span>
            </WithUsersDrag>
        </>)
    };

    renderGroupsItem = (item:TreeItem) => {

        return (<>
            <WithUsersDrag item={{
                dropType : dnd.RIGHTS,
                onDragEnd : this.onGroupsDragEnd,
                id: item.id
            }}>
                <span
                    data-id={item.id}
                    // onClick={this.getRightsDetail}
                >
                    {item.children && item.children.length > 0 &&
                        <Caret
                            isOpen={item.isOpen}
                            //@ts-ignore
                            onClick={item.toggleOpen ? item.toggleOpen : undefined}
                        />
                    }

                    <TreeIcon iconClassName="fa fa-building"/>

                    <span
                        className={this.props.groupId === item.id ? 'just-pro__users_tree--active' : ''}
                        onClick={this.getRightsDetail.bind(this, item.id)}
                    >
                        {item.name}
                    </span>
                </span>
            </WithUsersDrag>
        </>)
    };

    changePanelLoading = (loading) => {
        this.setState({
            panelSpinner: loading
        })
    }

    render () {
        const {rolesMap, groupsMap, detail, rights} = this.props;
        const canRead = rights && rights['users'] && rights['users'].read;
        const canEdit = rights && rights['users'] && rights['users'].edit;
        const canDelete = rights && rights['users'] && rights['users'].delete;
        const canCreate = rights && rights['users'] && rights['users'].create;

        this.hash = [];
        const roles = this.combineTreeProps(rolesMap);
        this.hash = [];
        const groups = this.combineTreeProps(groupsMap);

        return rights ? canRead ? (<WithPrivateRoute>
                <HeaderController>
                    <ModuleTextName>{getText('users.moduleName')}</ModuleTextName>
                    <Search
                        submit={this.onSearch}
                    />

                    {(canDelete || canEdit || canCreate) &&
                        <div className='navbar-form navbar-left buttons'>

                            {canCreate &&
                                <Button
                                    onClick={this.props.getAddPanel}
                                    title='users.addNewUser'
                                    className='btn-default'
                                    disabled={this.isDisabled()}
                                >
                                    +
                                    <i className='fas fa-user' />
                                </Button>
                            }

                            {canEdit &&
                                <Button
                                    onClick={this.getAddModal}
                                    title='users.addNewRoleOrGroup'
                                    className='btn-default'
                                    disabled={this.isDisabled()}
                                >
                                    + <i className='fas fa-folder' />
                                </Button>
                            }
                            {canDelete &&
                                <Button
                                    onClick={this.getDeleteModal}
                                    title='users.deleteNewRoleOrGroup'
                                    className='btn-default'
                                    disabled={this.isDisabled()}
                                >
                                    - <i className='fas fa-folder' />
                                </Button>
                            }
                        </div>
                    }

                    <Notify />
                </HeaderController>

                <div className="just-pro_module users">
                    <div className="panel content-panel">
                        {/*<Tree list={roles} renderItem={this.renderRolesItem}/>*/}
                        <TreeController list={roles} renderItem={this.renderRolesItem} withSort={true} />

                        <hr />
                        <TreeController list={groups} renderItem={this.renderGroupsItem} withSort={true}/>
                        <Spinner loading={this.state.treeSpinner}/>
                    </div>
                    <div className="panel content-panel">
                        <UsersList onClick={this.getUserDetail}/>
                        <Spinner loading={this.state.usersListSpinner}/>
                    </div>
                    <div className="panel content-panel">
                        <Spinner loading={this.state.panelSpinner}/>
                        <Detail panel={detail} q={this.state.q}/>
                    </div>
                </div>
            </WithPrivateRoute>
        ) : (
            <WithPrivateRoute>
                <>
                    <HeaderController> </HeaderController>

                    <HasNoRightsController/>
                </>
            </WithPrivateRoute>
        ) : <WithPrivateRoute />
    }
}

const mapStateToProps = (state:UsersMapStateToProps):object => {
    return {
        rolesMap: state.users.roles.map,
        roleId : state.users.roles.id,
        groupsMap: state.users.groups.map,
        groupId : state.users.groups.id,
        detail: state.users.users.detail,
        tree : state.users.users.tree,

        userId: state.users.user.id,

        rights : state.application.rights
    }
};


const mapDispatchToProps = (dispatch:Function):object => ({
    getRole  : (id:number) => dispatch(getRole(id)),
    getGroup  : (id:number) => dispatch(getGroup(id)),
    getAddPanel : () => dispatch(getAddUserPanel()),
    getTree : () => dispatch(getTree()),
    getUser : (id:number) => dispatch(getUserDetail(id)),
    addUserGroup : (userId: number, groupId:number) => dispatch(addUserGroup(userId, groupId)),
    addUserRole : (userId:number, roleId:number) => dispatch(addUserRole(userId, roleId)),
    deleteGroup : (groupId:number) => dispatch(deleteGroup(groupId)),
    deleteRole: (roleId:number) => dispatch(deleteRole(roleId)),
    search : (data:GetUsers) => dispatch(searchUsers(data)),
    createGroup : (id: number, name:string) => dispatch(createGroup(id, name)),
    createRole : (id: number, name:string) => dispatch(createRole(id, name)),
    openModal : (props:Modal) => dispatch(openModal(props))
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);

