import React, {Component} from "react";
import {DropDownItem, Props, State} from './dropdown.types';
import './dropdown.css'
import Caret from "../caret/caret";
import RenderIf from "../../../utils/renderIf";
import getText from "../../../localization/getText";

class Dropdown extends Component<Props, State> {
    state = {
        isOpen: false,
        active: this.props.list.find((i) => i.active) || this.props.list[0]
    };

    componentDidUpdate(prevProps: any){
        document.body.addEventListener("click", this.hideList)
        if(JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)){
            this.setState({
                active: this.props.list.find((i) => i.active) || this.props.list[0]
            })
        }
    }

    hideList = (e: any) => {
        if(!e.target?.closest(".just-pro_dropdown-menu")){
            this.setState({
                isOpen: false
            })
        }
    }

    setIsOpen = (isOpen: boolean, event?: any) => {
        event && event.stopPropagation();
        this.setState({
            isOpen
        })
    };

    setActive = (active: DropDownItem) => {
        if(this.props.disableChangeActive) return;
        this.setState({
            active
        })
    };

    onChangeHandler = (item: DropDownItem) => {
        this.setActive(item);
        this.props.onChange(item);
    };

    handleDropdownClick = () => {
        this.setState((prevState: State) => ({
            isOpen: !prevState.isOpen
        }))
    };



    render() {
        const {preventToDefault, label, showActiveName = true} = this.props;
        const {active, isOpen} = this.state;
        let iconCls = ['just-pro__dropdown-icon fa'];

        if (!label && active.icon) {
            iconCls.push(active.icon);
        }

        if (label?.icon) {
            iconCls.push(label.icon);
        }

        return (
            <>
                <div className="input-group-btn just-pro-dropdown" onClick={this.handleDropdownClick}>
                    <RenderIf condition={!label}>
                        <div>
                            {active.icon && (
                                <i className={iconCls.join(' ')} style={active.color ? {color: active.color} : {}}/>
                            )}

                            {active.name && showActiveName && (
                                <span className="just-pro__dropdown-name">{getText(active.name)}</span>
                            )}
                            <Caret isOpen={isOpen}/>

                        </div>
                    </RenderIf>
                    <RenderIf condition={label}>
                        <div>
                            <i className={iconCls.join(' ')}/>
                            <span className="just-pro__dropdown-name">{getText(label?.name || '')}</span>
                            <Caret isOpen={isOpen}/>
                        </div>
                    </RenderIf>

                    <RenderIf condition={isOpen}>
                        <>
                            <ul className="just-pro_dropdown-menu">
                                {
                                    this.props.list.map(item => {
                                        if(item.hidden) return null;
                                        return (
                                            <li
                                                className={active && !label && active.id === item.id ? 'active' : ''}
                                                key={item.id}
                                                onClick={this.onChangeHandler.bind(null, item)}
                                            >
                                                <i className={'fa ' + item.icon}
                                                   style={item.color ? {color: item.color} : {}}/>
                                                {getText(item.name || '')}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </>
                    </RenderIf>
                </div>
            </>
        )
    }
}

export default Dropdown;