import React from 'react';
import { ActWithHours, ModulesResponse } from '@justpro/terminal';
import Spinner from '../../UI/spinner/spinner.controller';
import Table from '../../UI/table/table';
import moment from 'moment';
import './executorCompareHours.css';
import ActController from '../../acts/act/act.controller';
import { ApplicationMapState } from '../../application/application.controller';
import { connect } from 'react-redux';
import getText from '../../../localization/getText';
import {
  hideModal,
  openModal,
  updateModal,
} from '../../../store/modal/modal.actions';
import { Modal } from '../../../store/modal/modal.types';

interface Props {
  updateList(): Promise<void>;
  contractor?: string;
  acts?: ActWithHours[];
  rights?: Partial<ModulesResponse>;
  hideModal(id: string): void;
  openModal(props: Modal): void;
}

interface State {
  loading: boolean;
  acts: ActWithHours[];
  actId?: number;
}

const ATTENTION_COLOR = '#FDF5F5';

class ExecutorObjectHoursModal extends React.Component<Props, State> {
  state: State = {
    loading: false,
    acts: [],
  };

  modalId = 'reportActsCompareHours';

  resetState = async () => {
    this.setState(() => ({
      acts:
        this.props.acts?.filter(
          (act) => act.contractor === this.props.contractor
        ) || [],
    }));
  };

  get columns() {
    const { acts } = this.props;

    const getProps = (bcg: string = '#fdeaee') => ({
      style: {
        background: bcg,
        alignSelf: 'stretch',
      },
    });

    return [
      {
        Header: getText('objects.object'),
        accessor: 'object',
        width: 350,
        // Cell:({original}:any) => getObjectName(original),
      },
      {
        Header: getText('common.justpro'),
        columns: [
          {
            Header: getText('common.number'),
            accessor: 'number',
            getProps,
          },
          {
            Header: getText('common.date'),
            Cell: ({ original }: any) =>
              moment(original?.date).format('DD.MM.YYYY'),
            getProps,
          },
          {
            Header: getText('common.hours'),
            accessor: 'hoursJustpro',
            getProps,
          },
        ],
      },
      {
        Header: getText('common.odinS'),
        columns: [
          {
            Header: getText('common.number'),
            accessor: 'number1s',
            getProps,
          },
          {
            Header: getText('common.date'),
            Cell: ({ original }: any) =>
              moment(original?.date).format('DD.MM.YYYY'),
            getProps,
          },
          {
            Header: getText('common.hours'),
            accessor: 'hoursSystem',
            Cell: ({ original }: any) => (
              <span>{(original?.time / 3600).toFixed(2)}</span>
            ),
            getProps,
          },
          {
            Header: getText('reports.executorCompareHours.autoCutOff'),
            Cell: ({ original }: any) => (
              <span>{(original?.hoursSystem / 60).toFixed(2)}</span>
            ),
            getProps,
          },
          {
            Header: getText('reports.executorCompareHours.handleCutOff'),
            accessor: 'odinS.handleCutDown',
            Cell: ({ original }: any) => (
              <span>{(original?.hoursOder / 60).toFixed(2)}</span>
            ),
            getProps,
          },
        ],
      },
    ];
  }

  setAct = (actId: number) => {
    this.setState(() => ({ actId }));
  };

  async componentDidMount() {
    this.resetState();
    //
    //     this.props.updateModal(this.modalId, {
    //         title : this.title
    //     })
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.resetState();
    }
    if (
      this.state.actId &&
      this.state.actId !== prevState.actId &&
      this.props.rights?.['acts']?.read
    ) {
      this.props.openModal({
        id: this.modalId,
        afterClose: async () => {
          await this.props.updateList();
          this.setState(() => ({ actId: undefined }));
        },
        title: getText('reports.executorCompareHours.actEditing'),
        component: () => <ActController actId={this.state.actId} />,
      });
    } else {
      this.props.hideModal(this.modalId);
    }
  }

  render() {
    const { loading } = this.state;
    const { acts, rights } = this.props;

    return (
      <>
        <div className="compareHours__objects">
          {!loading && (
            <Table
              columns={this.columns}
              data={this.state.acts || []}
              getTrProps={(
                state: any,
                { original }: any,
                column: any,
                instance: any
              ) => {
                return {
                  onClick: () => {
                    this.setAct(original.actId);
                  },
                  style: {
                    backgroundColor:
                      +original.time / 3600 !== original.hoursJustpro
                        ? ATTENTION_COLOR
                        : 'transparent',
                  },
                };
              }}
            />
          )}
        </div>

        <Spinner loading={loading} />
      </>
    );
  }
}

const mapState = (state: ApplicationMapState) => ({
  rights: state.application.rights,
});

const mapDispatch = (d: Function) => ({
  openModal: (props: Modal) => d(openModal(props)),
  hideModal: (id: string) => d(hideModal(id)),
  updateModal: (id: string, props: Modal) => d(updateModal(id, props)),
});

export default connect(mapState, mapDispatch)(ExecutorObjectHoursModal);
