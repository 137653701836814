import React from "react";
import TabContentHeader from "./tabContentHeader";
import withLabel from "../../UI/withLabel/withLabel";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    me?: any
    onChange(field: string, value: boolean): any
}

class TabContentTasks extends React.Component<Props>{
    onChange(field: string, event: any){
        this.props.onChange && this.props.onChange(field, event?.target?.checked);
    }

    render () {
        const {me} = this.props;
        return (
            <div className="settings-tab-content">
                <TabContentHeader title="tasks.tasks"/>
                <div>
                    {withLabel(
                        <div className="notifications__checkbox-group">
                            <label>
                                <input type="checkbox"
                                       checked={me?.settings?.tasksLastFilters}
                                       onChange={this.onChange.bind(this, "tasksLastFilters")}/>
                                {getText('settings.useLastFilters')}
                            </label>
                        </div>
                    )({text : 'settings.filters'})}
                </div>
            </div>
        )
    }
}

const mapState = (state: any) => ({
    me: state.application.me
})
export default connect(mapState)(TabContentTasks)