import {RightsRow} from "../components/users/role/roles.types";
import {Rights} from "@justpro/terminal";

const ACTIONS = ["create", "read", "edit", "delete"];
export default function (right: any, name:string, edit:boolean):RightsRow {
    const allowed: any = {}, forbidden: any = {};
    ACTIONS.map((action: string) => {
        if(right.allowed[action]){
            allowed[action] = {
                isSet : right.allowed[action].value !== null,
                edit : edit,
                checked : !!right.allowed[action].value,
                name : `allowed.${action}`,
                description : right.allowed[action].label
            }
        } else {
            allowed[action] = {};
        }
    })
    ACTIONS.map((action: string) => {
        if(right.forbidden[action]){
            forbidden[action] = {
                isSet : right.forbidden[action].value !== null,
                edit : edit,
                checked : !!right.forbidden[action].value,
                name : `forbidden.${action}`,
                description : right.forbidden[action].label
            }
        } else {
            forbidden[action] = {};
        }
    });
    return {
        name : name,
        rightId : right.securityObjectId,
        description : right.description,
        allowed,
        forbidden
    }
};