import React from 'react';
import {Equipment, EquipmentWork, format, RegularStatus, RegularWork, UpdateRegularWork, getEquipmentName, getObjectName} from "@justpro/terminal";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import moment, {Moment} from "moment";
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {getEquipmentWorksList, getEquipmentList, getEquipmentListSelect, getRegularStatuesList} from "../../../utils/functions";
import Button from "../../UI/button/button";
import RegularWorkDetail from "./regularWork.detail";
import getText from "../../../localization/getText";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";

interface Props {
    regularWork : RegularWork

    updateRegularWork(data:Partial<UpdateRegularWork>) : void
    getRegularWorks?() : void
}

interface State {
    number : number | null
    controlDate : string | null,
    regularStatus : RegularStatus | null,
    equipmentWork : EquipmentWork | null,
    equipment : Equipment | null,
    date : string | null
    updateData : Partial<UpdateRegularWork>
    selectedObjectId : number
}

class RegularWorkEdit extends React.Component<Props, State> {

    state:State = {
        number : null,
        controlDate : null,
        regularStatus : null,
        equipmentWork : null,
        equipment : null,
        date : null,
        updateData : {},
        selectedObjectId : this.props.regularWork?.equipment?.object.id,
    };


    update = () => {
        this.props.updateRegularWork(this.state.updateData);
    };

    changeDate = (date:Moment) => {
        this.setState((prevState) => ({
            ...prevState,
            updateData : {
                ...prevState.updateData,
                date : date.format(format.date)
            }
        }))
    };

    changeControlDate = (date:Moment) => {
        this.setState((prevState) => ({
            ...prevState,
            updateData : {
                ...prevState.updateData,
                controlDate : date.format(format.date)
            }
        }))
    };

    changeEquipmentWork = (option:Option) => {
        return new Promise(async resolve => {

            this.setState((prevState) => ({
                ...prevState,
                updateData : {
                    ...prevState.updateData,
                    equipmentWorkId : option.value
                }
            }));



            resolve()
        })
    };

    changeEquipment = (option:Option) => {
        return new Promise(async resolve => {

            this.setState((prevState) => ({
                ...prevState,
                updateData : {
                    ...prevState.updateData,
                    equipmentId : option.value
                }
            }));


            resolve()
        })
    };

    changeRegularStatus = (option:Option) => {
        return new Promise(async resolve => {

            this.setState((prevState) => ({
                ...prevState,
                updateData : {
                    ...prevState.updateData,
                    regularStatusId : option.value
                }
            }));

            resolve()
        })
    };

    changeSelectedObject = (newNumber) => {
        console.log('newNumber: ', newNumber);

        return new Promise(async resolve => {

            this.setState({
                selectedObjectId: newNumber.value
            });

            resolve()
        })
    }

    componentDidMount(): void {
        const {
            number,
            controlDate,
            regularStatus,
            equipmentWork,
            equipment,
            date
        } = this.props.regularWork;

        //Значения по умолчанию.
        this.setState(() => ({
            number,
            controlDate,
            regularStatus,
            equipmentWork,
            equipment,
            date
        }))
    }

    render(){
        const {number, controlDate, date, equipment, equipmentWork, regularStatus} = this.props.regularWork;

        return (
            <>

                <div className="just-pro__pre-detail-panel">
                    <div className="panel-toolbar regular-work">
                        <Button
                            className="btn-default pull-right"
                            onClick={this.update}
                        >
                            <i className="fa fa-check"/> {getText('common.save')}
                        </Button>
                    </div>
                </div>

                <h4>{getText('regularWorks.regularWork')} : №{number ? number : getText('common.notSpecified')}</h4>

                {date ? (
                    <InlineCalendar date={moment(date)} onChange={this.changeDate} label={{text : "regularWorks.startDate"}} />
                ) : ''}

                {controlDate ? (
                    <InlineCalendar date={moment(controlDate)} onChange={this.changeControlDate} label={{text : "regularWorks.controlDate"}}/>
                ) : ''}

                {equipmentWork ? (
                    <Select
                        change={this.changeEquipmentWork}
                        label="regularWorks.regularWork"
                        accessors={{
                            id : 'value',
                            name : 'label'
                        }}
                        defaultValue={{
                            id : equipmentWork.id,
                            name : equipmentWork.name
                        }}
                        load={getEquipmentWorksList}
                    />
                ) : ""}

                {regularStatus ? (
                    <Select
                        change={this.changeRegularStatus}
                        label="regularStatuses.regularStatus"
                        accessors={{
                            id : 'value',
                            name : 'label'
                        }}
                        defaultValue={{
                            id : regularStatus.id,
                            name : regularStatus.name
                        }}
                        load={getRegularStatuesList}
                    />
                ) : ""}

                {equipment?.object ? (
                    <AsyncSelect
                        defaultValue={{
                            value : {
                                label : getObjectName(this.props.regularWork?.equipment?.object), 
                                value : this.state.selectedObjectId
                            }
                        }}
                        change={this.changeSelectedObject}
                        loadOptions={(q: string) => groupObjectsByRegion({q})}
                        label={{text: "objects.object"}}
                    />
                ) : ""}

                {equipment?.equipmentType ? (
                    <Select
                        change={this.changeEquipment}
                        label="equipments.equipment"
                        accessors={{
                            id : 'value',
                            name : 'label'
                        }}
                        defaultValue={{
                            id : equipment.equipmentType.id,
                            name : getEquipmentName(equipment)
                        }}
                        load={() => getEquipmentListSelect({objectsId: [this.state.selectedObjectId]})}
                    />
                ) : ""}
            </>
        )
    }
}


export default RegularWorkEdit