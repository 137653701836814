import React from "react";
import Table from "../../../table/table";
import '../createOrderModal.view.css'
import {Contract, ContractEquipmentCategory} from "@justpro/terminal";
import getText from "../../../../../localization/getText";


interface Props {
    changeEquipmentCategory:(equipmentCategory:ContractEquipmentCategory) => void
    contracts?: Contract[]
}

export default class EquipmentCategoriesModal extends React.Component<Props>{

    choose = (equipmentCategory:ContractEquipmentCategory) => {
        this.props.changeEquipmentCategory(equipmentCategory)
    };

    render() {

        return(
            <>
                <Table
                    columns={[
                        {
                            Header: getText('equipmentCategories.equipmentCategory'),
                            accessor: 'name',
                            minWidth: 150,
                            Cell:(props:any) => <span className="just-prop_create-order-list-item" onClick={this.choose.bind(this, props.original)}>{props.original.name}</span>
                        },
                    ]}
                    data={this.props.contracts?.reduce((result:ContractEquipmentCategory[], contract) => {
                        if(contract.equipmentCategories) {
                            return [...result, ...contract.equipmentCategories]
                        }
                        return result
                    }, [])}
                    minRows={0}
                    pagination={false}
                    showPagination={false}

                />
            </>
        )
    }

}