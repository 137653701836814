import React, {useCallback, useState} from 'react';
import withLabel from "../withLabel/withLabel";
import checkError from "../../../utils/checkError";


interface Props {
    label? : string
    defaultValue? : string | number
    className? : string
    change?(value:number, oldValue?:number):void
    blur(value:number, oldValue?:number):void
    [key:string] : any
}

export default (props:Props) => {
    const {defaultValue, blur, className, label, change, ...rest} = props;
    const id = 'input-' + Math.round(Math.random() * 999999);
    const cls = ['just-pro-textInput'];

    const [value, setValue] = useState('');
    const [oldValue, setOldValue] = useState('');

    if(className) {
        cls.push(className)
    }

    const onChangeHandler = (e:React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        if(val || val === '') {
            setValue(val);
        }

        if(change) {
            onBlurHandler()
        }

    };

    const onFocusHandler = () => {
        setOldValue(value);
    };


    const onBlurHandler = () => {
        try {
            const toNumericValue = parseInt(value);
            const toNumericOldValue = parseInt(defaultValue as string);
            if(!isNaN(toNumericValue) || value === '') {
                blur(toNumericValue, toNumericOldValue)
            }else{
                checkError(new Error('errors.valueMustBeANumber'))
            }



        }catch (e) {
            checkError(e)
        }

    };



    const onSubmit = (e:React.FormEvent) => {
        e.preventDefault();
    };

    const ref = useCallback(() => {
        setValue(defaultValue ? defaultValue.toString() : '')
    }, [defaultValue]);

    const jsx = <form onSubmit={onSubmit}>
            <input
                ref={ref}
                value={value}
                onChange={onChangeHandler}
                className={cls.join(' ')}
                id={id}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
                autoComplete="off"
                type="text"
                {...rest}
            />
        </form>;


    return label ? withLabel(jsx)({text : label, id : id}) : jsx

}
