import React from "react";
import withLabel from "../../../UI/withLabel/withLabel";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";
import getText from "../../../../localization/getText";

interface Props {
    save () : Promise<void>
    hide () : void
    points : {
        latitude : string,
        longitude : string
        radius : string
    }
}


class DeleteGPSCoordinates extends React.Component<Props> {

    save = async () => {
        await this.props.save();
        this.props.hide()
    }

    render() {
        const {latitude, longitude, radius} = this.props.points;

        return (
            <>
                {withLabel(
                    <span>{latitude}</span>
                )({
                    text : "common.latitude"
                })}
                {withLabel(
                    <span>{longitude}</span>
                )({
                    text : "common.longitude"
                })}
                {withLabel(
                    <span>{radius}</span>
                )({
                    text : "common.radius"
                })}

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.save}>{getText('common.delete')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}

export default DeleteGPSCoordinates