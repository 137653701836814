import React from 'react';
import {
    copyEquipmentFromObject,
    createEquipment,
    editEquipment,
    Equipment, ModulesResponse,
    Object,
    UpdateObject
} from '@justpro/terminal'
import {Option} from "../../../UI/select/select.types";
import ToggleSwitch from "../../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../../UI/input/text";
import Select from "../../../UI/select/select";
import {getCountries, getAreasList, getCitiesList, getEquipmentTypesList, getObjectsList, getSquaresList, getFilials1SList} from "../../../../utils/functions";
import withLabel from "../../../UI/withLabel/withLabel";
import RenderIf from "../../../../utils/renderIf";
import Table from "../../../UI/table/table";
import Button from "../../../UI/button/button";
import checkError from "../../../../utils/checkError";
import {getObjectName} from "../../../../utils/names";
import {ApplicationMapState} from "../../../application/application.controller";
import {connect} from "react-redux";
import AsyncSelect from "../../../UI/select/asyncSelect";
import {getCitiesByArea, getSquaresByRegion, groupObjectsByRegion} from "../../../../utils/selectGroups";
import DeleteCell from "../../../UI/table/deleteCell";
import getText from "../../../../localization/getText";
import {Modal, ModalBodyProps} from "../../../../store/modal/modal.types";
import {openModal} from "../../../../store/modal/modal.actions";

interface Props {
    object : Object
    update(data:Partial<UpdateObject>) : void
    rights?: Partial<ModulesResponse>
    openModal(props:Modal) : void
}

interface State {
    equipmentNumber : string
    equipmentTypeId? : number,
    equipments?: Equipment[]
    // copiedObject? : number,
    copiedOption: Option | null
    countries?: any
}

class ObjectDetail extends React.Component<Props, State>{

    state:State = {
        equipmentNumber : '',
        copiedOption : null
    };


    changeActiveObject = (active:boolean) => {
        this.props.update({active})
    };


    onBlurNumber = (number: string, oldValue: string) => {
        if(number !== oldValue) {
            this.props.update({number})
        }
    };

    onBlurAddress = (address: string, oldValue: string) => {
        if(address !== oldValue) {
            this.props.update({address})
        }
    };

    changeObjectCity = (option:Option) => {
        return new Promise(resolve => {
            this.props.update({cityId : option.value});
            resolve()
        })
    };

    changeObjectFilial = async (option:Option) => {
        return this.props.update({filial1sId : option.value});
    };

    changeObjectSquare = (option:Option) => {
        return new Promise(resolve => {
            this.props.update({squareId : option.value});
            resolve()
        })
    };

    blurGPSLatitude = (latitude:string, oldValue: string) => {
        if(latitude !== oldValue){
            const parsed = parseFloat(latitude);
            if(!isNaN(parsed)) {
                this.props.update({latitude : parsed})
            }else{
                checkError(new Error('errors.valueMustBeANumber'));
            }
        }
    };

    blurGPSLongitude = (longitude:string, oldValue: string) => {
        if(longitude !== oldValue){
            const parsed = parseFloat(longitude);
            if(!isNaN(parsed)) {
                this.props.update({longitude : parsed})
            }else{
                checkError(new Error('errors.valueMustBeANumber'));
            }
        }
    };

    blurEmailForActs = (actsEmail:string, oldValue: string) => {
        if(actsEmail !== oldValue){
            this.props.update({actsEmail})
        }
    };

    addObjectEquipment = async () => {
        if(!this.isDisabledAddEquipment()) {
            try {
                const newEquipment = await createEquipment({
                    objectId : this.props.object.id,
                    equipmentTypeId : this.state.equipmentTypeId as number,
                    number : this.state.equipmentNumber
                });

                this.setState((prevState) => ({
                    equipments : prevState.equipments ? [...prevState.equipments, newEquipment] : [newEquipment],
                    equipmentNumber : '',
                }))

            }catch (e) {
                checkError(e)
            }

        }

        return Promise.resolve();
    };

    removeObjectEquipment = async (props:any) => {
        const equipment = props.original;
        console.log({equipment});

        try {
            const editedEquipment = await editEquipment(equipment.id, {active : false});

            this.setState((prevState) => ({
                equipments : prevState.equipments ?
                    prevState.equipments.filter(item => {
                        if(item.id !== editedEquipment.id) {
                            return true
                        }
                    })
                    : []
            }))

        }catch (e) {
            checkError(e);
        }
    };

    isDisabledAddEquipment = () => {
        return this.state.equipmentTypeId === undefined || this.state.equipmentNumber.trim() === ''
    };

    changeEquipmentNumber = (val:string) => {
        this.setState(() => ({equipmentNumber : val}))
    };

    onChangeEquipmentType = (option:Option) => {
        this.setState(() => ({equipmentTypeId : option.value}));

        return Promise.resolve();
    };

    resetState = async() => {
        const {equipments} = this.props.object;
        const countries = await getCountries()
        this.setState(() => ({
            equipments : equipments ? equipments.filter(item => item.active) : undefined,
            equipmentNumber : '',
            copiedOption : null,
            countries
        }))
    };

    onChangeCopyEquipment = (option:Option) => {

        this.setState(() => ({
            // copiedObject : option.value,
            copiedOption : option
        }));

        return Promise.resolve();
    };


    copy = async () => {

        if( (this.state?.copiedOption?.value) !== undefined) {
            try {

                const copied = await copyEquipmentFromObject(this.props.object.id, this.state.copiedOption.value);


                this.setState((prevState) => ({
                    equipments : [...prevState.equipments, ...copied],
                    copiedOption : null,

                }))

            }catch (e) {
                checkError(e)
            }
        }

    };

    componentDidMount(){
        this.resetState();
    
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.object.id !== this.props.object.id) {
            this.resetState();
        }
    }

    get equipmentColumns() {

        const {rights} = this.props;

        const result:any[] = [
            {
                Header : getText('equipmentCategories.equipmentCategory'),
                accessor : 'equipmentType.equipmentCategory.name',
            },
            {
                Header : getText('equipments.equipmentNumber'),
                accessor : 'number',
                Cell : (props:any) => {
                    const {equipmentType, number} = props.original;
                    return `${equipmentType.name} №${number}`
                }
            }
        ];

        if(rights && rights['references.objects'] && rights['references.objects']['edit'] ) {
            result.push(
                {
                    Header : getText('common.delete'),
                    Cell : (props:any) => <DeleteCell deleteHandler={this.removeObjectEquipment} item={props}/>
                }
            )
        }


        return result;
    }

    get contractColumns() {
        const result:any[] = [
            {
                Header : getText('common.name'),
                accessor : 'name'
            },
            {

                Header : getText('contractors.contractor'),
                accessor : 'contractor.name'
            },
            {
                Header : getText('common.executor'),
                accessor : 'executor.name'
            },
            {
                Header : getText('orderTypes.alias'),
                Cell : (props:any) => {
                    const {ordersTypes} = props.original;

                    return ordersTypes && ordersTypes.length > 0 ? (
                        <ul className="object-orders-types">
                            {ordersTypes.map((orderType:any) => {
                                return (
                                    <li key={orderType.id}>{orderType.alias}</li>
                                )
                            })}
                        </ul>
                    ) : getText('common.notSpecified')
                }
            },
        ];

        return result;
    }

    render () {
        const {object, rights} = this.props;
        const {equipments} = this.state;
        const {filial1s} = object;
        const disabled = rights && rights['references.objects'] && !rights['references.objects']['edit'];

        return(
            <>
                <h3>Объект: {getObjectName(object)}</h3>

                <ToggleSwitch
                    send={this.changeActiveObject}
                    defaultValue={object?.active}
                    label="common.active"
                    disabled={disabled}
                />

                <Input
                    blur={this.onBlurNumber}
                    label="common.number"
                    startValue={object?.number}
                    disabled={disabled}
                />

                <AsyncSelect
                    loadOptions={(q:string) => getCitiesByArea({q})}
                    label={{ text : "cities.city" }}
                    change={this.changeObjectCity}
                    defaultValue={{
                        value : object.city,
                        accessors : {name : 'label', id : 'value'}
                    }}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                />

                <AsyncSelect
                    loadOptions={(q:string) => getSquaresByRegion({q})}
                    label={{ text : "squares.square" }}
                    change={this.changeObjectSquare}
                    defaultValue={{
                        value : object.square,
                        accessors : {name : 'label', id : 'value'}
                    }}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                />

                <Input
                    blur={this.onBlurAddress}
                    label="objects.address"
                    startValue={object?.address}
                    disabled={disabled}
                />

                {withLabel(
                    <>
                        <Input
                            label="common.latitude"
                            startValue={object?.latitude ? object.latitude.toString() : ''}
                            disabled={disabled}
                            blur={this.blurGPSLatitude}

                        />
                        <Input
                            label="common.longitude"
                            startValue={object?.longitude ? object.longitude.toString() : ''}
                            blur={this.blurGPSLongitude}

                            disabled={disabled}
                        />
                    </>
                )({text : 'GPS'})}

                <Input
                    label="objects.emailForActs"
                    startValue={object?.actsEmail ? object?.actsEmail.toString() : ''}
                    blur={this.blurEmailForActs}
                    disabled={disabled}
                />

                <AsyncSelect
                    change={this.changeObjectFilial}
                    loadOptions={getFilials1SList}
                    label={{text : "objects.filial1S"}}
                    isDisabled={disabled}
                    defaultValue={{
                        value : filial1s,
                        accessors : {
                            name : 'label',
                            id1s : 'value'
                        }
                    }}

                />


                {withLabel(
                    <>
                        <RenderIf condition={equipments && equipments.length > 0}>
                            <Table
                                columns={this.equipmentColumns}
                                data={equipments}
                            />
                        </RenderIf>


                        {!disabled &&
                            <div className="just-pro__row-items margin-top">
                                <Select
                                    change={this.onChangeEquipmentType}
                                    load={getEquipmentTypesList}
                                    placeholder="UI.select.placeholders.selectEquipmentType"
                                    // withoutValue={true}
                                    defaultValue={undefined}
                                    accessors={{
                                        id : 'value',
                                        name : 'label'

                                    }}
                                />

                                <Input
                                    change={this.changeEquipmentNumber}
                                    placeholder="common.number"
                                    value={this.state.equipmentNumber}
                                />

                                <Button
                                    className="btn-success"
                                    onClick={this.addObjectEquipment}
                                    disabled={this.isDisabledAddEquipment()}
                                >{getText('common.add')}</Button>
                            </div>
                        }
                        {!disabled &&
                            <div className="just-pro__row-items margin-top">
                                <AsyncSelect
                                    change={this.onChangeCopyEquipment}
                                    loadOptions={(q:string) => groupObjectsByRegion({q})}
                                    defaultValue={{
                                        value : this.state.copiedOption ? this.state.copiedOption : undefined,
                                    }}
                                    placeholder="objects.copyFromExists"
                                />

                                <Button
                                    className="btn-success"
                                    onClick={this.copy}
                                    disabled={this.state.copiedOption === null}
                                >{getText('objects.copy')}</Button>
                            </div>
                        }

                    </>
                )({position : "left", text : "equipments.equipment"})}


                <RenderIf condition={object?.contracts && object?.contracts.length > 0}>
                    <Table
                        columns={this.contractColumns}
                        data={object?.contracts}
                        minRows={0}
                        label="contracts.contract"
                    />
                </RenderIf>
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props))
 });

export default connect(mapState, mapDispatch)(ObjectDetail)