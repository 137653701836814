import * as api from "@justpro/terminal";


export default (
        name : string,
        map: api.RolesMap | api.GroupsMap,
        parentsList?: number[],
        children?: number[],
        id?: number
) => {
    let parent:Partial<{name : string, id: number}> = {};

    if(parentsList && parentsList.length) {
        parent.name = map[parentsList[0]].name;
        parent.id = parentsList[0];
    }else{
        parent.id = id;
        parent.name = name;
    }

    const getAllChildren = (nodeChildren?:number[]):number[] => {
        if(nodeChildren) {
            return nodeChildren.reduce((list:number[], index:number ) => {
                if(map[index].children) {
                    return [...new Set ( [...list, index, ...getAllChildren(map[index].children) ])]
                }
                return [...new Set ([ ...list, index]) ]
            }, [])
        }


        return [];
    };

    const childrenList = getAllChildren(children);

    const parentList = Object.entries(map).reduce((result:Partial<api.Object>[], mapItem) => {
        const nodeId = +mapItem[0];
        const node = mapItem[1];

        if(nodeId === id || childrenList.includes(nodeId)) {
            return [...result]
        }

        return [...result, {
            name : node.name,
            id : nodeId
        }]
    }, []);

    return [parent, parentList]
};