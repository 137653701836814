import React from 'react';
import {
    Contractor,
    createContractor,
    filterParams,
    getBrands,
    getContractors, ModulesResponse,
} from "@justpro/terminal";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import BaseReferenceController from "../baseReference/baseReference.controller";
import ContractorController from "./contractor.controller";
import ContractorCreate from "./contractor.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class ContractorsController extends React.Component<Props>{

    renderItem = (item:Contractor) => <>{item.name}</>;

    getCheckboxesMap = async () => {

        const {rights} = this.props;

        if(rights?.['references.brands']?.read) {
            const brands =  await getBrands({});

            return {
                [filterParams.BRANDS] : {
                    name : 'brands.brand',
                    children : brands.map(({name,id}) => ({id, name}))
                }
            }
        }


        return {}
    };

    render () {
        const {rights} = this.props;

        return (
            <BaseReferenceController
                referenceName="contractors.moduleName"
                getList={getContractors}
                listItem={{
                    render: this.renderItem,
                }}
                filter={{
                    filterName : filterNames.CONTRACTORS,
                    getCheckboxesMap : this.getCheckboxesMap,
                }}
                Detail={ContractorController}
                create={{
                    Component : ContractorCreate,
                    handler: createContractor,
                    title : "contractors.addItem"
                }}
                moduleRights={rights && rights['references.contractors']}
            />
        )
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights: state.application.rights
})

export default connect(mapState)(ContractorsController)