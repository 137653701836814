import React from 'react';
import {Props, State} from './workOrders.types'
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import './workOrders.css'
import Filter from "../UI/filter/filter.controller";
import CheckboxList from "../UI/checkboxList/checkboxList.contoller";
import DesctopCalendar from "../UI/calendar/views/desctop.controller";
import TopLineCalendar from "../UI/calendar/views/topLine.controller";
import moment, {Moment} from "moment";
import {orderByOptions} from "../UI/sortBy/sortBy.controller";
import Search from "../UI/search/search.controller";
import RenderIf from "../../utils/renderIf";
import WorkOrderDetail from "./workOrder.controller";
import {
    filterParams,
    format,
    getWorkorders,
    IWorkordersSortBy,
    Workorder,
    WorkordersFilterParams,
} from "@justpro/terminal";
import {ActiveCheckboxesList} from "../UI/checkboxList/checkboxList.types";
import ItemList from "../UI/itemsList/itemList.controller";
import checkError from "../../utils/checkError";
import {get, save} from "../../utils/filterCheckboxes";
import {filterNames} from "../UI/checkboxList/checkboxes.const";
import {getExecutorsList, getPersonsList} from "../../utils/functions";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import {IWorkordersTypes} from "@justpro/terminal";
import WorkOrderListItem from "./workOrder.listItem";
import HeaderController from "../header/header.controller";
import {ApplicationMapState} from "../application/application.controller";
import {connect} from "react-redux";
import ListFilterController from "../UI/listFilter/listFilter.controller";
import getText from "../../localization/getText";
import {renderToString} from "react-dom/server";
import ToplineCalendarWrapper from "../UI/calendar/views/toplineCalendarWrapper";
import {getFilter} from "../../store/filters/filters.actions";


const sortOptions = [
    {
        name: 'UI.dropDown.byDate',
        id: 1,
        icon: 'fa fa-calendar',
        value: 'date'
    },
    {
        name: 'UI.dropDown.byNumber',
        id: 2,
        icon: 'fa fa-list-ol',
        value: 'number'
    },
    {
        name: 'UI.dropDown.byExecutor',
        id: 3,
        icon: 'fa fa-user',
        value: 'responsible'
    },
];

const workOrderStages = [
    {
        name: 'workorders.open',
        id: 1,
    },
    {
        name: 'workorders.closed',
        id: 2,
    },
];


interface WorkorderSavedFilters {
    filters?: ActiveCheckboxesList
    activeStages?: number[]
}

class WorkOrders extends React.Component<Props, State> {

    state: State = {
        list: [],
        activeStages: [],
        from: moment().subtract(3, 'month').startOf('month'),
        to: moment().endOf('month'),
        q: '',
        checkboxesMap: {},
        activeCheckboxes: get(filterNames.WORK_ORDERS),

        offset: 100,
        count: 0,
        backScrollToTop: false,
        listLoading: false,
        sortBy: sortOptions[0].value as IWorkordersSortBy,
        orderBy: orderByOptions[0].value,

    };

    changeActiveStage = (activeStages: number[]) => {
        this.setState(() => ({activeStages}))
    };

    changeDateFrom = (from: Moment) => {
        this.setState(() => ({from}))
    };

    changeDateTo = (to: Moment) => {
        this.setState(() => ({to}))
    };

    changeWorkOrderSortBy = (item: DropDownItem) => {
        this.setState(() => ({sortBy: item.value}), this.getList)
    };

    changeWorkOrderOrderBy = (item: DropDownItem) => {
        this.setState(() => ({orderBy: item.value}), this.getList)
    };

    searchSubmit = (q: string) => {
        this.setState(() => ({q}), this.getList)
    };

    getWorkOrder = (workOrder: Workorder) => {
        this.setState(() => ({workOrderId: workOrder.id}))
    };

    onCheckboxesChange = (activeCheckboxes: ActiveCheckboxesList) => {
        save(filterNames.WORK_ORDERS, activeCheckboxes);
        this.setState(() => ({activeCheckboxes}));
    };

    filterSubmit = async () => {
        this.getList();
    };

    getFilterParams = (offset?: number): Partial<WorkordersFilterParams> => {
        const {
            q,
            activeCheckboxes,
            from,
            to,
            sortBy,
            orderBy,
            activeStages
        } = this.state;


        let closed: "1" | "0" | undefined = undefined;

        const {workordersTypes, ...restActiveCheckboxes} = activeCheckboxes;
        if (activeStages && activeStages.length > 0) {
            //если это так то искать все
            if (activeStages.length !== 2) {
                if (activeStages.includes(1)) {
                    closed = "0"
                }

                if (activeStages.includes(2)) {
                    closed = '1'
                }
            }
        }

        const filterParams: Partial<WorkordersFilterParams> = {
            q,
            ...restActiveCheckboxes,
            dateFrom: from.format(format.date),
            dateTo: to.format(format.date),
            offset,
            sortBy,
            closed,
            sortOrder: orderBy,
            limit: 100,
        }
        if(workordersTypes?.length){
            filterParams.types = workordersTypes as IWorkordersTypes[];
        }

        return filterParams;
    };

    getList = async () => {
        try {
            this.setState(() => ({
                listLoading: true,
                backScrollToTop: true,
            }));

            const filterParams = this.getFilterParams();

            const {list, count} = await getWorkorders(filterParams);

            this.setState(() => ({
                listLoading: false,
                list,
                workOrderId: list?.[0]?.id,
                backScrollToTop: false,
                count,
                offset: 100,
            }))
        } catch (e) {
            this.setState(() => ({
                listLoading: false,
                backScrollToTop: false,
            }));
            checkError(e)
        }
    };

    updateList = async () => {
        const {list, count} = this.state;

        if (+count > list?.length) {
            try {
                this.setState(() => ({
                    listLoading: true
                }));
                const filterParams = this.getFilterParams(this.state.offset);
                const {list, count} = await getWorkorders(filterParams);

                this.setState((prevState) => ({
                    listLoading: false,
                    list: [...prevState.list, ...list],
                    count: count ? count : prevState.count,
                    offset: prevState.offset + 100
                }))
            } catch (e) {
                checkError(e);
            }

        }
    };

    renderWorkOrder = (item: Workorder) => {
        return <WorkOrderListItem workOrder={item} getListItem={this.getWorkOrder}
                                  activeItemId={this.state.workOrderId}/>
    };

    resetState = async () => {
        const {rights, settings} = this.props;

        if (!rights || !settings) return false;

        if (rights?.['workorders']?.read) {
            await this.getList();
        }

        const workordersTypes = [{
            name: 'workorders.workorder',
            id: 'orders',
        }, {
            name: 'workorders.checklist',
            id: 'checklist',
        }, {
            name: 'workorders.admin',
            id: 'admin'
        }];

        const checkboxesMap = {
            [filterParams.WORK_ORDER_TYPES]: {
                children: workordersTypes as unknown as {id: number, name: string }[],
                name: "workorders.workorderType"
            }
        };

        checkboxesMap[filterParams.RESPONSIBLES] = {
            name: 'common.executor',
            children: await this.props.getFilter("executors", {
                q: "",
                executorsId: [+settings?.tfmContractorId, +settings?.tfmShidContractorId]
            })
        }

        this.setState(() => ({
            checkboxesMap,
            activeCheckboxes: get(filterNames.WORK_ORDERS)
        }))
    };

    async componentDidMount() {
        this.resetState()
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (JSON.stringify(prevProps.rights) !== JSON.stringify(this.props.rights)) {
            this.resetState();
        }
    }

    setFilters = (savedFilters: WorkorderSavedFilters) => {
        const {filters, activeStages} = savedFilters;

        this.setState(() => ({
            activeCheckboxes: filters ? filters : {},
            activeStages: activeStages ? activeStages : []
        }), this.getList)
    };

    getCurrentFilters = () => {
        return {
            filters: this.state.activeCheckboxes,
            activeStages: this.state.activeStages
        }
    };

    updateListItem = (workorder: Workorder) => {
        this.setState((prevState: State) => {
            return {
                list: prevState.list.map((w) => {
                    if (w.id === workorder.id) {
                        return {
                            ...w,
                            ...workorder
                        }
                    }
                    return w;
                })
            }
        })
    }

    render() {

        const {from, to, workOrderId, activeCheckboxes, checkboxesMap} = this.state;

        return (
            <WithPrivateRoute>
                <HeaderController
                    savedFilters={{
                        module: "workorders",
                        setFilters: this.setFilters,
                        getCurrentFilters: this.getCurrentFilters
                    }}
                >

                    <Filter
                        activeCheckboxes={activeCheckboxes}
                        send={this.filterSubmit}
                    >
                        <CheckboxList
                            checkboxesMap={checkboxesMap}
                            activeCheckboxes={activeCheckboxes}
                            send={this.onCheckboxesChange}
                        />

                        <div>
                            <DesctopCalendar date={from} onChange={this.changeDateFrom}/>
                            <DesctopCalendar date={to} onChange={this.changeDateTo}/>
                        </div>

                    </Filter>

                    <ToplineCalendarWrapper>
                        <TopLineCalendar date={from} onChange={this.changeDateFrom}/>
                        <TopLineCalendar date={to} onChange={this.changeDateTo}/>
                    </ToplineCalendarWrapper>

                    <Search
                        submit={this.searchSubmit}
                        loadOnEraser={false}
                        eraser={true}
                    />
                </HeaderController>
                <div className="just-pro_module workOrders">
                    <div className="panel content-panel">

                        <ListFilterController
                            moduleGroups={{
                                onChange: this.changeActiveStage,
                                list: workOrderStages.map(item => {
                                    return {
                                        ...item,
                                        name: renderToString(getText(item.name))
                                    }
                                }),
                                checked: this.state.activeStages,
                                title: "workorders.stage",
                            }}
                            counter={{
                                vars: {
                                    one: 'workorders.vars.one',
                                    two: 'workorders.vars.two',
                                    notFound: 'workorders.vars.notFound',
                                    default: 'workorders.vars.default'
                                },
                                value: this.state.count
                            }}
                            sortBy={{
                                sortByOptions: sortOptions,
                                onChangeOrderBy: this.changeWorkOrderOrderBy,
                                onChangeSortBy: this.changeWorkOrderSortBy
                            }}
                        />

                        <ItemList
                            list={this.state.list}
                            renderItem={this.renderWorkOrder}
                            loading={this.state.listLoading}
                            backScrollToTop={this.state.backScrollToTop}
                            updateData={this.updateList}
                        />
                    </div>
                    <div className="panel content-panel">
                        <RenderIf condition={workOrderId !== undefined}>
                            <WorkOrderDetail
                                id={(workOrderId as number)}
                                updateList={this.getList}
                                updateListItem={this.updateListItem}
                            />
                        </RenderIf>
                    </div>
                </div>
            </WithPrivateRoute>
        )
    }
}

const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights,
    settings: state.application.settings,
    me: state.application.me
});

const mapDispatch = (d: Function) => ({
    getFilter: (filterName, rest) => d(getFilter(filterName, rest))
});


export default connect(mapState, mapDispatch)(WorkOrders);
