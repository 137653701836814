import React, {useState, useCallback, useEffect} from 'react';
import update from 'immutability-helper'
import SimpleSort from "./simpleSorting";

interface Props {
    type : string
    renderItem(item:any, index?: number) : JSX.Element
    list : any[],
    onItemDrop(list:any[]) : void
    noPadding?: boolean
}

const SortingList:React.FC<Props> = (props:Props) => {

    const [list, setList] = useState<any>([]);

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const dragCard = list[dragIndex];

        const updated = update(list, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });

        setList(updated)
    };

    const dropHandler = useCallback((item:any) => {
        props.onItemDrop(list)
    }, [list]);



    // useEffect(() => {
    //     props.onSortEnds(list)
    // }, [list]);
    //
    useEffect(() => {
        setList(props.list)
    }, [props.list]);
    return (<div>
        {list && list.map((item:any, index:number) => {
            return <SimpleSort
                key={item.id || index}
                index={index}
                id={item.id}
                accept={props.type}
                move={moveItem}
                onDrop={dropHandler}
                noPadding={props.noPadding}
                {...item}
            >
                {props.renderItem(item, index)}
            </SimpleSort>
        })}
    </div>)
};

export default SortingList