import React, {useState} from "react";
import './withModuleDetail.css'
import {connect} from "react-redux";
import {openModal} from "../../../../store/modal/modal.actions";
import {Modal} from "../../../../store/modal/modal.types";

interface Props {
    element : React.ReactNode // элемент на который повесится событие
    modal : Modal
    openModal(props:Modal) : void
}


const WithModuleDetail = (props:Props) => {

    const clickHandler = (e:React.MouseEvent) => {
        e.stopPropagation();
        props.openModal(props.modal);
    };

    return (
        <span onClick={clickHandler} className="withModuleDetail_element">
            {props.element}
        </span>
    )

};

const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props))
});

export default connect(null, mapDispatch)(WithModuleDetail)