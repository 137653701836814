import React from 'react'
import { ContractObject, Object as IObject } from '@justpro/terminal'
import { getObjectsList } from '../../../../utils/functions'
import Caret from '../../../UI/caret/caret'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Spinner from '../../../UI/spinner/spinner.controller'
import '../contact.css'
import { getObjectName } from '../../../../utils/names'
import getText from '../../../../localization/getText'
import { Modal, ModalBodyProps } from '../../../../store/modal/modal.types'
import { openModal, updateModal } from '../../../../store/modal/modal.actions'
import { connect } from 'react-redux'
import DefaultFooterModal from '../../../UI/dialog/modal/defaultFooterModal'
import Button from '../../../UI/button/button'
import GetGeographic from '../../objects/object/objectDetailGeographic'

type checked = 0 | 1 | 2

interface MyObject {
	id: number
	countryId: number
	areaId: number
	cityId: number
	name?: string
	checked: boolean
	regionId?: number
	squareId?: number
}

interface City {
	isOpen: boolean
	name: string
	checked: checked
	countryId: number
	areaId: number
	objects: MyObject[]
}
interface Area {
	isOpen: boolean
	name: string
	countryId: number
	checked: checked
}

interface Country {
	isOpen: boolean
	name: string
	checked: checked
}

interface Region {
	isOpen: boolean
	name: string
	checked: checked
}

interface Square {
	isOpen: boolean
	name: string
	checked: checked
	regionId: number
	objects: MyObject[]
}

interface Props {
	onSave(objects: number[]): void
	hide(): void
	existsObjects?: ContractObject[]
	contractorId: number
	openModal(props: Modal): void
}

interface State {
	selected: number[]
	// tree:Tree | null
	countries: {
		[key: number]: Country
	}
	areas: {
		[key: number]: Area
	}
	cities: {
		[key: number]: City
	}
	regions: {
		[key: number]: Region
	}
	squares: {
		[key: number]: Square
	}
	isOpenQuestion: boolean
	tabIndex: number

	isLoading: boolean
}

class AddContractObjectsModal extends React.Component<Props, State> {
	state: State = {
		selected: [],
		// tree : null
		areas: {},
		countries: {},
		cities: {},
		regions: {},
		squares: {},
		isOpenQuestion: false,
		tabIndex: 0,

		isLoading: false,
	}

	saveObjects = async () => {
		const { selected } = this.state

		this.setState(() => ({ isLoading: true }))

		await this.props.onSave(selected)

		this.props.hide()

		this.setState(() => ({ isLoading: false }))
	}

	onlyUnique = (value: any, index: number, self: any[]) => {
		return self.indexOf(value) === index
	}

	getCityObjects = (cityId: number, action: checked) => {
		const { cities } = this.state
		const objects = cities[cityId].objects

		let selected: number[] = []

		const map = objects.map((object) => {
			selected.push(object.id)
			return {
				...object,
				checked: !!action,
			}
		})

		return { map, selected }
	}

	getCitiesMap = (
		parent: {
			areaId?: number
			countryId?: number
		},
		checked: checked,
	) => {
		const { cities } = this.state

		let selectedObjects: number[] = []

		const newCitiesMap = Object.entries(cities).map(
			([cityId, cityRest]) => {
				const id = +cityId
				const { objects, countryId, areaId } = cityRest
				const action: checked = checked === 1 ? 0 : 1

				if (parent.countryId) {
					if (parent.countryId === countryId) {
						const { map: newObjects, selected } =
							this.getCityObjects(id, action)

						selectedObjects = [...selectedObjects, ...selected]

						let data = {
							...cityRest,
							// objects : newObjects,
							objects: newObjects,
							checked: action,
						}
						return [id, data]
					}
				}

				if (parent.areaId) {
					if (parent.areaId === areaId) {
						const { map: newObjects, selected } =
							this.getCityObjects(id, action)

						selectedObjects = [...selectedObjects, ...selected]

						let data = {
							...cityRest,
							// objects : newObjects,
							objects: newObjects,
							checked: action,
						}
						return [id, data]
					}
				}

				return [id, cityRest]
			},
		)
		const citiesFromEntries = Object.fromEntries(newCitiesMap)

		return [citiesFromEntries, selectedObjects]
	}

	onChangeCountry = (countryId: number, rest: any) => {
		// для области поставить значение чекбокса в 0 или 1  +
		// для для всех городов этой области поставить значение в 0 1 +
		// для всех объектов этой области поставить значнеие 0 1 +
		this.setState((prevState) => {
			const { checked } = prevState.countries[countryId]
			const { areas } = prevState
			const inversion: checked = checked === 1 ? 0 : 1

			const [citiesFromEntries, selectedObjects] = this.getCitiesMap(
				{ countryId },
				checked,
			)

			const newAreaMap = Object.entries(areas).map(
				([areaId, areaRest]) => {
					const id = +areaId

					if (countryId === areaRest.countryId) {
						let data = {
							...areaRest,
							checked: inversion,
						}
						return [id, data]
					}

					return [id, areaRest]
				},
			)
			const areasFromEntries = Object.fromEntries(newAreaMap)

			return {
				cities: citiesFromEntries,
				areas: areasFromEntries,
				selected: this.getSelected(!!inversion, selectedObjects),
				countries: {
					...prevState.countries,
					[countryId]: {
						...prevState.countries[countryId],
						checked: inversion,
					},
				},
			}
		})
	}

	onChangeArea = (areaId: number, rest: any) => {
		// для области поставить значение чекбокса в 0 или 1 (2 считается как 0) +
		// для для всех городов этой области поставить значение в 0 1 +
		// для всех объектов этой области поставить значнеие 0 1 +
		this.setState((prevState) => {
			const { checked } = prevState.areas[areaId]
			const { selected, countries, areas } = prevState
			const action = checked === 1 ? 0 : 1

			let countryChecked: checked = action
			let match = false

			const [citiesFromEntries, selectedObjects] = this.getCitiesMap(
				{ areaId },
				checked,
			)

			Object.entries(areas).forEach(([aId, areaRest]) => {
				const id = +aId

				if (areaRest.countryId === rest.countryId && id !== areaId) {
					// посмотреть остальные области
					if (!match) {
						if (areaRest.checked !== action) {
							match = true
							countryChecked = 2
						}
					}
				}
			})

			return {
				cities: citiesFromEntries,
				selected: this.getSelected(!!action, selectedObjects),
				areas: {
					...areas,
					[areaId]: {
						...areas[areaId],
						checked: action,
					},
				},
				countries: {
					...countries,
					[rest.countryId]: {
						...countries[rest.countryId],
						checked: countryChecked,
					},
				},
			}
		})
	}

	onChangeCity = (cityId: number, cityRest: any) => {
		this.setState((prevState) => {
			const { checked, areaId, countryId } = prevState.cities[cityId]
			const { areas, cities, countries } = prevState

			const inversion = checked === 1 ? 0 : 1

			let areaChecked: checked = inversion
			let matchCity = false

			// для области
			Object.entries(cities).forEach(([cId, restCity]) => {
				const id = +cId

				if (restCity.areaId === areaId && id !== cityId) {
					// посмотреть остальные города
					if (!matchCity) {
						if (restCity.checked !== inversion) {
							matchCity = true
							areaChecked = 2
						}
					}
				}
			})

			let countryChecked: checked = inversion
			let matchArea = false
			// const [citiesFromEntries, selectedObjects] = this.getCitiesMap({areaId}, checked);

			// для страны
			Object.entries(areas).forEach(([aId, areaRest]) => {
				const id = +aId

				if (areaRest.countryId === countryId && id !== areaId) {
					// посмотреть области
					if (!matchArea) {
						if (areaRest.checked !== inversion) {
							matchArea = true
							countryChecked = 2
						}
					}
				}
			})

			const { map: objects, selected } = this.getCityObjects(
				cityId,
				inversion,
			)

			return {
				areas: {
					...areas,
					[areaId]: {
						...areas[areaId],
						checked: areaChecked,
					},
				},
				countries: {
					...countries,
					[countryId]: {
						...countries[countryId],
						checked: countryChecked,
					},
				},
				cities: {
					...cities,
					[cityId]: {
						...cities[cityId],
						checked: inversion,
						objects,
					},
				},
				selected: this.getSelected(!!inversion, selected),
			}
		})
	}

	onChangeRegion = (regionId: number, regionRest: any) => {
		this.setState((prevState) => {
			const { checked } = prevState.regions[regionId]
			const { squares } = prevState
			const inversion: checked = checked === 1 ? 0 : 1
			const selected: number[] = []

			const squareEntries = Object.entries(squares).map(
				([squareId, squareRest]) => {
					const id = +squareId

					if (regionId === squareRest.regionId) {
						const { objects } = squareRest

						const newObjects = objects.map((object) => {
							selected.push(object.id)

							return {
								...object,
								checked: !!inversion,
							}
						})

						let data = {
							...squareRest,
							checked: inversion,
							objects: newObjects,
						}
						return [id, data]
					}

					return [id, squareRest]
				},
			)

			const squaresMap = Object.fromEntries(squareEntries)

			return {
				squares: squaresMap,
				regions: {
					...prevState.regions,
					[regionId]: {
						...prevState.regions[regionId],
						checked: inversion,
					},
				},
				selected: this.getSelected(!!inversion, selected),
			}
		})
	}

	toggleIsOpenRegion = (id: number) => {
		this.setState((prevState) => ({
			...prevState,
			regions: {
				...prevState.regions,
				[id]: {
					...prevState.regions[id],
					isOpen: !prevState.regions[id].isOpen,
				},
			},
		}))
	}

	toggleIsOpenCountry = (id: number) => {
		this.setState((prevState) => ({
			...prevState,
			countries: {
				...prevState.countries,
				[id]: {
					...prevState.countries[id],
					isOpen: !prevState.countries[id].isOpen,
				},
			},
		}))
	}

	toggleIsOpenArea = (id: number) => {
		this.setState((prevState) => ({
			...prevState,
			areas: {
				...prevState.areas,
				[id]: {
					...prevState.areas[id],
					isOpen: !prevState.areas[id].isOpen,
				},
			},
		}))
	}

	getSelected = (checked: boolean, objectsId: number[]): number[] => {
		const { selected } = this.state
		if (checked) {
			return [...selected, ...objectsId].filter(this.onlyUnique)
		} else {
			return selected.filter((item) => !objectsId.includes(item))
		}
	}

	onChangeObject = (object: MyObject) => {
		this.setState((prevState) => {
			const {
				checked,
				name,
				areaId,
				cityId,
				countryId,
				id,
				regionId,
				squareId,
			} = object
			const { cities, areas, countries, squares, regions } = prevState

			const inversion = checked ? 0 : 1
			const newSelected = this.getSelected(!checked, [id])

			let cityChecked: checked = inversion
			let matchObject = false

			//для города
			cities[cityId].objects.forEach((object) => {
				if (object.id !== id) {
					if (!matchObject) {
						if (object.checked !== !!inversion) {
							matchObject = true
							cityChecked = 2
						}
					}
				}
			})

			let areaChecked: checked = cityChecked
			let matchCity = false
			// для области
			Object.entries(cities).forEach(([cId, restCity]) => {
				const id = +cId

				if (restCity.areaId === areaId && id !== cityId) {
					// посмотреть остальные города
					if (!matchCity) {
						if (restCity.checked !== inversion) {
							matchCity = true
							areaChecked = 2
						}
					}
				}
			})

			let countryChecked: checked = areaChecked
			let matchArea = false

			// для страны
			Object.entries(areas).forEach(([aId, areaRest]) => {
				const id = +aId

				if (areaRest.countryId === countryId && id !== areaId) {
					// посмотреть области
					if (!matchArea) {
						if (areaRest.checked !== inversion) {
							matchArea = true
							countryChecked = 2
						}
					}
				}
			})

			let squareChecked: checked = inversion
			let matchO = false

			if (squareId) {
				//для квадрата
				squares[squareId].objects.forEach((object) => {
					if (object.id !== id) {
						if (!matchO) {
							if (object.checked !== !!inversion) {
								matchO = true
								squareChecked = 2
							}
						}
					}
				})
			}
			let regionChecked: checked = squareChecked
			let matchSquare = false

			// для региона
			Object.entries(squares).forEach(([sId, restSquare]) => {
				const id = +sId

				// if(regionId) {
				if (restSquare.regionId === regionId && id !== squareId) {
					if (!matchSquare) {
						if (restSquare.checked !== inversion) {
							matchSquare = true
							regionChecked = 2
						}
					}
				}
				// }
			})

			if (squareId && regionId) {
				return {
					...prevState,
					cities: {
						...cities,
						[cityId]: {
							...cities[cityId],
							checked: cityChecked,
							objects: prevState.cities[cityId].objects.map(
								(item) => {
									if (item.id === id) {
										return {
											areaId,
											cityId,
											name,
											countryId,
											id,
											checked: !checked,
										}
									}

									return item
								},
							),
						},
					},
					areas: {
						...areas,
						[areaId]: {
							...areas[areaId],
							checked: areaChecked,
						},
					},
					countries: {
						...countries,
						[countryId]: {
							...countries[countryId],
							checked: countryChecked,
						},
					},
					selected: newSelected,
					squares: {
						...squares,
						[squareId]: {
							...squares[squareId],
							checked: squareChecked,
							objects: squares[squareId].objects.map((object) => {
								if (object.id === id) {
									return {
										...object,
										checked: !checked,
									}
								}

								return object
							}),
						},
					},
					regions: {
						...regions,
						[regionId]: {
							...regions[regionId],
							checked: regionChecked,
						},
					},
				}
			}

			return {
				...prevState,
				cities: {
					...cities,
					[cityId]: {
						...cities[cityId],
						checked: cityChecked,
						objects: prevState.cities[cityId].objects.map(
							(item) => {
								if (item.id === id) {
									return {
										areaId,
										cityId,
										name,
										countryId,
										id,
										checked: !checked,
									}
								}

								return item
							},
						),
					},
				},
				areas: {
					...areas,
					[areaId]: {
						...areas[areaId],
						checked: areaChecked,
					},
				},
				countries: {
					...countries,
					[countryId]: {
						...countries[countryId],
						checked: countryChecked,
					},
				},
				selected: newSelected,
			}
		})
	}

	onChangeSquare = (squareId: number, squareRest: any) => {
		this.setState((prevState) => {
			const { checked, regionId, objects } = prevState.squares[squareId]
			const { squares, regions } = prevState

			const inversion = checked === 1 ? 0 : 1

			let regionChecked: checked = inversion
			let matchRegion = false

			// для региона посмотреть его квадраты
			Object.entries(squares).forEach(([sId, restSquare]) => {
				const id = +sId

				if (restSquare.regionId === regionId && id !== squareId) {
					if (!matchRegion) {
						if (restSquare.checked !== inversion) {
							matchRegion = true
							regionChecked = 2
						}
					}
				}
			})

			const selected = objects.map((item) => {
				return item.id
			})

			return {
				// selected : this.getSelected(!!inversion, selected),
				regions: {
					...regions,
					[regionId]: {
						...regions[regionId],
						checked: regionChecked,
					},
				},
				squares: {
					...squares,
					[squareId]: {
						...squares[squareId],
						checked: inversion,
						objects: squares[squareId].objects.map((object) => {
							if (object.squareId === squareId) {
								return {
									...object,
									checked: !checked,
								}
							}
							return object
						}),
					},
				},
				selected: this.getSelected(!!inversion, selected),
			}
		})
	}

	onChangeTab = (index: number, lastIndex: number) => {
		if (index !== lastIndex) {
			if (this.state.selected.length > 0) {
				this.props.openModal({
					id: 'confirmAnAction',
					component: (props: ModalBodyProps) => (
						<>
							<p>
								{getText('contracts.chosenObjectsIsNotBeSaved')}
							</p>
							<DefaultFooterModal>
								<Button
									onClick={props.hide}
									className='btn-danger'
								>
									{getText('common.cancel')}
								</Button>
								<Button
									onClick={this.changeTab.bind(
										this,
										props.hide,
									)}
									className='btn-success'
								>
									{getText('common.ok')}
								</Button>
							</DefaultFooterModal>
						</>
					),
				})
			} else {
				this.setState(() => ({ tabIndex: index }))
			}
		}
	}

	changeTab = async (hide: () => void) => {
		await this.resetState()

		this.setState((prevState) => ({
			tabIndex: prevState.tabIndex === 1 ? 0 : 1,
			isOpenQuestion: false,
		}))

		hide()
	}

	toggleIsOpenCity = (id: number) => {
		this.setState((prevState) => ({
			...prevState,
			cities: {
				...prevState.cities,
				[id]: {
					...prevState.cities[id],
					isOpen: !prevState.cities[id].isOpen,
				},
			},
		}))
	}

	toggleSquareIsOpen = (id: number) => {
		this.setState((prevState) => ({
			...prevState,
			squares: {
				...prevState.squares,
				[id]: {
					...prevState.squares[id],
					isOpen: !prevState.squares[id].isOpen,
				},
			},
		}))
	}

	getAreasView = (cId: number) => {
		const areas = Object.entries(this.state.areas)

		return areas.map(([areaId, areaRest]) => {
			const id = +areaId
			const { countryId, checked, name, isOpen } = areaRest
			let check = false
			let cls = []

			if (checked === 2) {
				cls.push('indeterminate')
			} else {
				check = !!checked
			}

			if (cId === countryId) {
				return (
					<div className='contracts-modal_row' key={'area ' + id}>
						<span className='contracts-modal_button'>
							<input
								type='checkbox'
								className={cls.join(' ')}
								checked={check}
								onChange={this.onChangeArea.bind(
									this,
									id,
									areaRest,
								)}
							/>
						</span>

						{name}
						<span
							className='contracts-modal_button'
							onClick={this.toggleIsOpenArea.bind(this, id)}
						>
							{' '}
							<Caret isOpen={areaRest.isOpen} />{' '}
						</span>

						{isOpen && this.getCityView(id)}
					</div>
				)
			}
		})
	}

	getCityView = (id: number) => {
		const cities = Object.entries(this.state.cities)

		return cities.map(([cityId, cityRest]) => {
			const cId = +cityId
			const { areaId, isOpen, name, checked } = cityRest

			let check = false
			let cls = []

			if (checked === 2) {
				cls.push('indeterminate')
			} else {
				check = !!checked
			}

			if (areaId === id) {
				return (
					<div className='contracts-modal_row' key={'city ' + cId}>
						<span className='contracts-modal_button'>
							<input
								type='checkbox'
								className={cls.join(' ')}
								checked={check}
								onChange={this.onChangeCity.bind(
									this,
									cId,
									cityRest,
								)}
							/>
						</span>

						{name}
						<span
							className='contracts-modal_button'
							onClick={this.toggleIsOpenCity.bind(this, cId)}
						>
							{' '}
							<Caret isOpen={isOpen} />{' '}
						</span>

						{isOpen && this.getObjectsView(cityRest.objects)}
					</div>
				)
			}
		})
	}

	getObjectsView = (objects: MyObject[]) => {
		return objects.map((object: MyObject) => {
			const { id, checked, name } = object

			return (
				<div className='contracts-modal_row' key={'object ' + id}>
					<span className='contracts-modal_button'>
						<input
							type='checkbox'
							checked={checked}
							onChange={this.onChangeObject.bind(this, object)}
						/>
					</span>
					{name}
				</div>
			)
		})
	}

	getSquareView = (regionId: number) => {
		const squares = Object.entries(this.state.squares)

		return squares.map(([squareId, squareRest]) => {
			const sId = +squareId
			const { isOpen, name, checked } = squareRest

			let check = false
			let cls = []

			if (checked === 2) {
				cls.push('indeterminate')
			} else {
				check = !!checked
			}

			if (squareRest.regionId === regionId) {
				return (
					<div className='contracts-modal_row' key={'square ' + sId}>
						<span className='contracts-modal_button'>
							<input
								type='checkbox'
								className={cls.join(' ')}
								checked={check}
								onChange={this.onChangeSquare.bind(
									this,
									sId,
									squareRest,
								)}
							/>
						</span>
						{name}
						<span
							className='contracts-modal_button'
							onClick={this.toggleSquareIsOpen.bind(this, sId)}
						>
							{' '}
							<Caret isOpen={isOpen} />{' '}
						</span>

						{isOpen && this.getObjectsView(squareRest.objects)}
					</div>
				)
			}
		})
	}

	resetState = async () => {
		const { existsObjects, contractorId } = this.props

		this.setState(() => ({ isLoading: true }))

		const allObjects = await getObjectsList({
			contractorsId: [contractorId, 0],
			limit: 10000,
		})

		const objects = allObjects
			.filter((object) => !object.basePoint)
			.reduce((acc: IObject[], object) => {
				//Не включать объекты которые уже выбраны для договора
				if (object.active) {
					const exist = existsObjects
						? existsObjects.find((o) => o.objectId === object.id)
						: undefined
					if (!exist) {
						acc.push(object)
						// return acc
					}
				}
				return acc
			}, [])

		let cities: {
			[key: number]: City
		} = {}

		let areas: {
			[key: number]: Area
		} = {}

		let countries: {
			[key: number]: Country
		} = {}

		let regions: {
			[key: number]: Region
		} = {}

		let squares: {
			[key: number]: Square
		} = {}

		objects.forEach((object: IObject) => {
			const { city, square } = object
			const { area } = city
			const { country } = area

			let obj: MyObject = {
				checked: false,
				name: getObjectName(object),
				id: object.id,
				countryId: country.id,
				areaId: area.id,
				cityId: city.id,
			}

			if (square) {
				const { region } = square
				obj.squareId = square.id
				obj.regionId = region.id
				squares = {
					...squares,
					[square.id]: {
						isOpen: false,
						checked: 0,
						regionId: region.id,
						name: square.name,
						objects: (function () {
							if (
								squares[square.id] &&
								squares[square.id].objects &&
								squares[square.id].objects.length > 0
							) {
								return [...squares[square.id].objects, obj]
							} else {
								return [obj]
							}
						})(),
					},
				}

				regions = {
					...regions,
					[region.id]: {
						isOpen: false,
						checked: 0,
						name: region.name,
					},
				}
			}

			cities = {
				...cities,
				[city.id]: {
					name: city.name,
					areaId: area.id,
					countryId: country.id,
					isOpen: false,
					checked: 0,
					objects: (function () {
						if (
							cities[city.id] &&
							cities[city.id].objects &&
							cities[city.id].objects.length > 0
						) {
							return [...cities[city.id].objects, obj]
						} else {
							return [obj]
						}
					})(),
				},
			}

			areas = {
				...areas,
				[area.id]: {
					name: area.name,
					isOpen: false,
					countryId: country.id,
					checked: 0,
					// cities: cities
				},
			}

			countries = {
				...countries,
				[country.id]: {
					isOpen: false,
					name: country.name,
					checked: 0,
					// areas : areas
				},
			}
		})

		this.setState(() => ({
			countries,
			areas,
			cities,
			regions,
			squares,
			selected: [],
			isLoading: false,
		}))
	}

	async componentDidMount() {
		await this.resetState()
	}

	render() {
		const isEmptyCountries =
			Object.entries(this.state.countries).length === 0
		return (
			<>
				<div className='add-contract-objects-wrapper'>
					<Tabs
						onSelect={this.onChangeTab}
						selectedIndex={this.state.tabIndex}
					>
						<TabList>
							<Tab>{getText('contracts.byCountries')}</Tab>
							<Tab>{getText('contracts.byRegions')}</Tab>
						</TabList>

						<h3>{getText('contracts.chooseFromList')}</h3>

						{isEmptyCountries && !this.state.isLoading ? (
							<p>
								{getText(
									'contracts.listAvailableObjectsIsEmpty',
								)}
							</p>
						) : null}

						<TabPanel>
							{GetGeographic({
								propsElements: this.state.countries,
								onChangeElement: (id, countryRest) =>
									this.onChangeCountry.bind(
										this,
										id,
										countryRest,
									),
								toggleIsOpenElement: (id) =>
									this.toggleIsOpenCountry.bind(this, id),
								getView: this.getAreasView,
							})}
						</TabPanel>
						<TabPanel>
							{GetGeographic({
								propsElements: this.state.regions,
								onChangeElement: (id, regionRest) =>
									this.onChangeRegion.bind(
										this,
										id,
										regionRest,
									),
								toggleIsOpenElement: (id) =>
									this.toggleIsOpenRegion.bind(this, id),
								getView: this.getSquareView,
							})}
						</TabPanel>

						<DefaultFooterModal>
							<Button
								className='btn-danger'
								onClick={this.props.hide}
							>
								{getText('common.cancel')}
							</Button>
							<Button
								className='btn-danger'
								onClick={this.saveObjects}
							>
								{getText('common.add')}
							</Button>
						</DefaultFooterModal>

						<Spinner loading={this.state.isLoading} />
					</Tabs>
				</div>
			</>
		)
	}
}

const mapDispatch = (d: Function) => ({
	openModal: (props: Modal) => d(openModal(props)),
})

export default connect(null, mapDispatch)(AddContractObjectsModal)
