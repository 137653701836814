import React from "react";
import {Language} from "@justpro/terminal";
import TabContentHeader from "./tabContentHeader";
import './tabsContent.css'
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";
import {AvailableLocales} from "../../../store/application/application.types";
import {changeLocale} from "../../../store/application/application.actions";
import i18n from '../../../i18n'
import getLang from "../../../utils/getLang";


interface Props {
    languages: Language[]
    me: any
    onChange?(languageId: number): any
}

interface State {
    selected?: Option
}

class General extends React.Component<Props, State>{

    onChangeHandler = async (option:Option) => {
        this.props.onChange && await this.props.onChange(option.value);
        i18n.changeLanguage(getLang(option.value));
    };

    render () {

        return (
            <div className="settings-tab-content">
                <TabContentHeader title="settings.general"/>
                <div>
                    <Select
                        defaultOptions={this.props.languages}
                        defaultValue={this.props?.me?.settings?.language}
                        change={this.onChangeHandler}
                        label="settings.interfaceLanguage"
                        placeholder="settings.interfaceLanguage"
                        accessors={{
                            name : "label",
                            id : 'value',
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapState = (state: ApplicationMapState) => ({
    me: state.application.me,
})

export default connect(mapState)(General)