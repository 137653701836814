import React from "react";
import {
    OrderListItem,
    ModulesResponse,
    getOrders,
    addOrdersToTask,
    getTaskOrders,
    deleteOrderFromTask,
    ICreateOrderWaitRequest,
    ITaskOrdersStatuses,
    declineTaskOrders,
    createWaitRequestTaskOrders,
    changeStatusTaskOrders
} from "@justpro/terminal";
import RenderIf from "../../utils/renderIf";
import Spinner from "../UI/spinner/spinner.controller";
import Dropdown from "../UI/dropdownMenu/dropdown.controller";
import {DropDownItem} from "../UI/dropdownMenu/dropdown.types";
import {connect} from "react-redux";
import {default as AsyncSelect, CustomActOption} from "../UI/select/asyncSelect_v2";
import {MapState} from "../mainNav/mainNav.types";
import checkError from "../../utils/checkError";
import OrderItem from '../orders/order/order.controller';
import {Option} from "react-select/src/filters";
import WaitRequestModal from "../orders/detail/waitRequstModal";
import DeclineReasonModal from "../orders/detail/declineReason.modal";
import ChangeStatusModal from "./changeOrdersStatusModal";
import {components} from "react-select";
import getText from "../../localization/getText";
import {openModal} from "../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";


interface Props {
    taskId: number
    rights?: Partial<ModulesResponse>

    openModal(props: Modal): void
}

interface State {
    orders: OrderListItem[]
    isLoading: boolean
}

const CustomOrderOption = (props: any) => {
    return (
        <components.Option {...props}>
            <OrderItem key={props.data.number}
                       order={props.data}
                       withBorderBottom={false}
                       orderIdAccessor="id"/>
        </components.Option>
    )
}

export const taskWaitRequestModalId = 'taskWaitRequestModal'
export const taskChangeOrderStatusModalId = 'taskChangeOrderStatusModal'

class TaskOrders extends React.Component<Props, State> {
    state: State = {
        orders: [],
        isLoading: false,
    };

    openWaitRequestModal = () => {

        this.props.openModal({
            id: taskWaitRequestModalId,
            component: (props: ModalBodyProps) => (<WaitRequestModal
                {...props}
                createWaitRequest={this.createOrdersWaitRequests}
            />),
            minHeight: '60vh',
            title: getText('waitRequests.waitRequest'),
        })
    }

    declineOrderOpenModal = () => {
        this.props.openModal({
            id: 'ordersDeclineOrder',
            component: (props) => <DeclineReasonModal {...props} save={this.declineTaskOrders}/>,
            minHeight: '60vh',
            title: getText('orders.cancelReason'),
        });
    };

    openChangeStatusModal = () => {

        this.props.openModal({
            id: taskChangeOrderStatusModalId,
            title: getText('tasks.changeOrderStatus'),
            minHeight: '60vh',
            component: (props: ModalBodyProps) => (
                <ChangeStatusModal
                    {...props}
                    changeStatusRequest={this.changeOrdersStatusRequest}
                    orders={this.state.orders}
                />
            )
        });
    }

    ordersActions = [{
        id: 0,
        name: 'UI.dropDown.chooseAction',
        hidden: true,
        active: true
    }, {
        id: 1,
        icon: 'fas fa-clock',
        name: "UI.dropDown.sendWaitRequest",
        handler: this.openWaitRequestModal
    }, {
        id: 3,
        name: 'UI.dropDown.declineOrder',
        handler: this.declineOrderOpenModal,
        icon: 'fa fa-ban'
    }, {
        id: 2,
        icon: 'fas fa-exchange-alt',
        name: "UI.dropDown.changeStatus",
        handler: this.openChangeStatusModal
    }];

    componentDidMount() {
        return this.getTaskOrders();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props?.taskId !== prevProps?.taskId) {
            return this.getTaskOrders();
        }
    }

    getOrders = async (q: string) => {
        try {
            const {orders} = this.state;
            const {list} = await getOrders({q});
            return list?.filter((item: any) => {
                return !orders.find(exist => exist.id === item.id)
            })?.map((item: OrderListItem) => ({
                ...item,
                label: item.number,
                value: item.id
            })) || []

        } catch (e) {
            checkError(e);
            return []
        }
    }

    selectAction = (item: DropDownItem) => {
        return item?.handler && item?.handler();
    }

    addTaskOrder = async (item: Option) => {
        try {
            const {taskId} = this.props;
            this.setState({
                isLoading: true
            })
            await addOrdersToTask(taskId, [+item.value]);
            await this.getTaskOrders();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    getTaskOrders = async () => {
        try {
            this.setState({
                isLoading: true
            })
            const orders = await getTaskOrders(this.props.taskId);
            this.setState({
                orders
            })
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    async deleteTaskOrder(orderId: number) {
        try {
            const {taskId} = this.props;
            this.setState({
                isLoading: true
            })
            await deleteOrderFromTask(taskId, orderId);
            await this.getTaskOrders();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    declineTaskOrders = async (reason: string) => {
        try {
            this.setState({
                isLoading: true
            });
            await declineTaskOrders(this.props.taskId, reason);
            await this.getTaskOrders();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }


    createOrdersWaitRequests = async (data: ICreateOrderWaitRequest) => {
        try {
            this.setState({
                isLoading: true
            });
            await createWaitRequestTaskOrders(this.props.taskId, data);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }


    changeOrdersStatusRequest = async (data: ITaskOrdersStatuses[]) => {
        try {
            this.setState({
                isLoading: true
            });
            await changeStatusTaskOrders(this.props.taskId, data);
            await this.getTaskOrders();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        const {rights} = this.props;
        const {isLoading, orders} = this.state;
        return (
            <div className="task-orders__wrapper">
                <RenderIf condition={isLoading}>
                    <Spinner loading={isLoading}/>
                </RenderIf>
                <RenderIf condition={!orders?.length}>
                    <div className="task-orders__no-orders">
                        {getText('tasks.noLinkedOrdersWithTask')}
                    </div>
                </RenderIf>
                <RenderIf condition={orders?.length && rights?.["tasks.orders.actions"]?.create}>
                    <div className="task-orders__actions-wrapper">
                        <Dropdown list={this.ordersActions} onChange={this.selectAction} disableChangeActive={true}/>
                    </div>
                </RenderIf>
                <RenderIf condition={rights?.["tasks.orders"]?.create}>
                    <div className="task-orders__select-by-number">
                        <AsyncSelect
                            change={this.addTaskOrder}
                            loadOptions={(q: string) => this.getOrders(q)}
                            withoutValue={true}
                            placeholder="tasks.putOrdersByNumber"
                            CustomOption={CustomOrderOption}
                        />
                    </div>
                </RenderIf>
                <RenderIf condition={orders?.length}>
                    {orders && orders.map((order) => (
                        <OrderItem
                            key={order.number}
                            order={order}
                            withModuleDetail={true}
                            withBorderBottom={false}
                            withDelete={{
                                deleteHandler: this.deleteTaskOrder.bind(this, order.id),
                                title: 'tasks.deleteOrderFromTask',
                                condition: true
                            }}
                            orderIdAccessor="id"
                        />
                    ))}
                </RenderIf>
            </div>
        )
    }
}

const mapState = (state: MapState) => {
    return {
        rights: state?.application?.rights
    }
}

const mapDispatch = (d: Function) => ({
    openModal: (props: Modal) => d(openModal(props))
});

export default connect(mapState, mapDispatch)(TaskOrders)