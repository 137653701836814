import React from 'react';
import './orders.css'
import {connect} from "react-redux";
import {
    addOrderPhotos,
    deleteOrderPhotos,
    getOrderPhotos
} from '@justpro/terminal'
import {updateOrderList} from '../../store/orders/orders.actions'
import {UpdateOrderList} from "../../store/orders/orders.types";
import BeforeAfterPhotos from "../UI/photos/beforeAfter.controller";

interface Props {
    orderId : number
    updateOrderList(orderId:number, data:UpdateOrderList): void
}

class OrderPhoto extends React.Component<Props>{

    render() {

        return (
            <>
                <BeforeAfterPhotos
                    id={this.props.orderId}
                    getPhotos={getOrderPhotos}
                    updateList={this.props.updateOrderList}
                    addPhotos={addOrderPhotos}
                    deletePhotos={deleteOrderPhotos}
                    titleBefore="UI.beforeAfterPhotos.before"
                    titleAfter="UI.beforeAfterPhotos.after"
                />
            </>
        )
    }
}

const mapDispatchToProps = (dispatch:Function) => ({
    updateOrderList : (orderId:number, data:UpdateOrderList) => dispatch(updateOrderList(orderId, data))
});

export default connect(null, mapDispatchToProps)(OrderPhoto)