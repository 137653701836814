import React from 'react'
import OrderTabs from '../orderTabs.controller'
import PersonInfo from '../../UI/dialog/personInfo/personInfo.controller'
import * as api from '@justpro/terminal'
import Button from '../../UI/button/button'
import Dropdown from '../../UI/dropdownMenu/dropdown.controller'
import { DropDownItem } from '../../UI/dropdownMenu/dropdown.types'
import { connect } from 'react-redux'
import moment from 'moment'
import Input from '../../UI/input/text'
import { ChangeMultiProps, LoadReturn, Option } from '../../UI/select/select.types'
import Select from '../../UI/select/select'
import { getTagsList } from '../../../utils/functions'
import '../orders.css'
import { secondsToDayHoursFormat, toDayHoursFormat } from '../../../utils/date'
import { getUniqueList } from '../../../utils/getUniqueArray'
import checkError from '../../../utils/checkError'
import { updateOrderList } from '../../../store/orders/orders.actions'
import { UpdateOrderList } from '../../../store/orders/orders.types'
import InfoBlock from '../../UI/infoBlock/infoBlock.controller'
import AddOrderToWorkorderModal from './addOrderToWorkorder.modal'
import { confirm } from '../../UI/confirmAction/confirmAction.controller'
import {
	getStatus,
	ModulesResponse,
	SettingsMap,
	createTaskFromOrder,
	declineOrder,
	getFullName,
} from '@justpro/terminal'
import { getObjectName } from '../../../utils/names'
import { ApplicationMapState } from '../../application/application.controller'
import HasNoRightsController from '../../UI/hasNoRights/hasNoRights.controller'
import RenderIf from '../../../utils/renderIf'
import DeclineReasonModal from './declineReason.modal'
import TaskController from '../../tasks/task.controller'
import Spinner from '../../UI/spinner/spinner.controller'
import { openModal } from '../../../store/modal/modal.actions'
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types'
import getText from '../../../localization/getText'
import GetText from '../../../localization/getText'
import CreateSubordersModal from './createSuborders.modal'
import BlockController from '../../UI/infoBlock/block.controller'
import Table from '../../UI/table/table'
import MultiSelect from '../../UI/select/multiSelect'
import AddMasterPopup from '../../UI/dialog/addMaster/addMaster.controller';

type Props = {
	order: api.Order
	getEditMode(): void
	updateOrderList(orderId: number, data: UpdateOrderList): void
	openWaitRequestModal(): void
	updateOrder(id: number): void
	openModal(props: Modal): void
	settings?: SettingsMap
	rights?: Partial<ModulesResponse>
}

interface State {
	loading: boolean
	count: string
	task?: api.ITask
	contractConditions: api.IOrderContractCondition[]
	tags: api.OrdersTag[]
	waitRequests: api.IOrderWaitRequest[],
	masters: api.Master[],
}

export const addOrderToWorkorderModalName = 'addOrderToWorkorder'
export const declineModalId = 'declineModal'

class OrderReadMode extends React.Component<Props, State> {
	state: State = {
		task: void 0,
		loading: false,
		count: '',
		contractConditions: [],
		tags: [],
		waitRequests: [],
		masters: [],
	}

	onChangeOrderOption = (item: DropDownItem) => {
		if (item.handler) {
			item.handler()
		}
	}

	createSubOrder = async (count: number | string, warranty?: boolean) => {
		try {
			const suborders = await api.createSuborder(
				this.props.order.id,
				+count,
				warranty ? warranty : undefined,
			)
			if (suborders) {
				this.props.updateOrderList(this.props.order.id, {
					subordersCount: suborders.length,
				})
			}
		} catch (e) {
			checkError(e)
		}
	}

	openSubOrderModal = () => {
		this.props.openModal({
			id: 'createSuborders',
			title: getText('orders.countOfSuborders'),
			component: (props: ModalBodyProps) => (
				<CreateSubordersModal
					{...props}
					createSuborder={this.createSubOrder}
				/>
			),
		})
	}

	deleteOrderContractCondition = async (contractConditionId: number) => {
		try {
			const deleted = await api.deleteOrderContractCondition(
				this.props.order.id,
				[contractConditionId],
			)

			if (deleted) {
				await this.setState((prevState) => ({
					contractConditions: prevState.contractConditions.filter(
						(item) =>
							item.contractConditionId !== contractConditionId,
					),
				}))

				this.props.updateOrderList(this.props.order.id, {
					contractConditions: this.state.contractConditions,
				})
			}
		} catch (e) {
			checkError(e)
		}
	}

	deleteOrderTag = async (tagId: number) => {
		try {
			const deleted = await api.deleteOrderTag(this.props.order.id, tagId)

			const tags = this.state.tags.filter((item) => item.id !== tagId)

			await this.setState(() => ({ tags }))
			this.props.updateOrderList(this.props.order.id, { tags })
		} catch (e) {
			checkError(e)
		}
	}

	getContractConditionView = () => {
		const { contractConditions } = this.state
		if (!contractConditions || contractConditions.length === 0) return null

		return (
			<div className='order-contract-conditions'>
				<h6>{getText('contractConditions.contractCondition')}</h6>
				<ul>
					{contractConditions.map((contractCondition) => {
						return (
							<li key={contractCondition.id}>
								<span className='contract-condition'>
									{contractCondition.name}
									<span
										className='order-delete-info-item'
										onClick={this.deleteOrderContractCondition.bind(
											this,
											contractCondition.contractConditionId,
										)}
									>
										x
									</span>
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	getTagsView = () => {
		const { tags } = this.state

		if (!tags || tags.length === 0) return null

		return (
			<div className='order-tags'>
				<h6>{getText('orders.tags')}</h6>
				<ul>
					{tags.map((tag) => {
						return (
							<li key={tag.id}>
								<span className='order-tag'>
									{tag.name}
									<span
										className='order-delete-info-item'
										onClick={this.deleteOrderTag.bind(
											this,
											tag.id,
										)}
									>
										x
									</span>
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	getWaitRequestsView = () => {
		const { waitRequests } = this.state

		if (!waitRequests || waitRequests.length === 0) return null

		return (
			<div className='order-wait-request'>
				<h6>{getText('waitRequests.waitRequests')}</h6>
				<ul>
					{waitRequests.map((waitRequest) => {
						return (
							<li key={waitRequest.id}>
								<PersonInfo
									person={waitRequest.author.person}
								/>{' '}
								{GetText('common.fromWho')}{' '}
								{moment(waitRequest.createdAt).format(
									'DD.MM.YYYY HH:mm:ss',
								)}
								<span className='reason'>
									{waitRequest.reason}{' '}
									{moment(waitRequest.dueDateCustomer).format(
										'DD.MM.YYYY',
									)}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	onChangeContractCondition = (option: Option) => {
		return new Promise(async (resolve) => {
			try {
				const contractConditions = await api.addOrderContractCondition(
					this.props.order.id,
					[option.value],
				)
				await this.setState((prevState) => ({
					contractConditions: contractConditions,
				}))

				this.props.updateOrderList(this.props.order.id, {
					contractConditions: this.state.contractConditions,
				})
			} catch (e) {
				checkError(e)
			}
			resolve()
		})
	}

	createOrderTask = async () => {
		try {
			this.setState({
				loading: true,
			})
			const task = await createTaskFromOrder(this.props.order?.id)

			if (task) {
				this.props.openModal({
					id: 'orderTask',
					component: () => <TaskController id={task?.id} />,
					title: getText('tasks.task'),
				})
			}
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	getContractConditionOptions = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const { contract } = this.props.order
			const { contractConditions } = this.state

			const availableCC = getUniqueList(
				contract.contractConditions,
				contractConditions,
				{
					existId: 'contractConditionId',
					wholeId: 'contractConditionId',
				},
			)

			resolve(availableCC)
		})
	}

	getOrdersTags = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const { tags } = this.state
			const allTags = await getTagsList({ modules: ['orders'] })
			const availableTags = getUniqueList(allTags, tags, {
				existId: 'tagId',
				wholeId: 'id',
			})

			resolve(availableTags)
		})
	}

	onChangeOrderTag = (option: Option) => {
		return new Promise(async (resolve) => {
			try {
				const tag = await api.addOrderTag(
					this.props.order.id,
					option.value,
				)
				if (tag) {
					const tags = [...this.state.tags, tag]
					this.setState(() => ({ tags }))

					this.props.updateOrderList(this.props.order.id, { tags })
				}
			} catch (e) {
				checkError(e)
			}

			resolve()
		})
	}

	openAddOrderToWorkorderModal = () => {
		this.props.openModal({
			id: addOrderToWorkorderModalName,
			component: () => (
				<AddOrderToWorkorderModal order={this.props.order} />
			),
			title: getText('orders.addingOrderToWorkorder'),
		})
	}

	updateDefaultState() {
		const { tags, contractConditions, waitRequests, masters } = this.props.order

		this.setState(() => ({
			contractConditions: contractConditions ? contractConditions : [],
			tags: tags ? tags : [],
			waitRequests: waitRequests ? waitRequests : [],
			masters: masters ? masters : [],
		}))
	}

	createFileOptions = (): DropDownItem[] => {
		const { settings, rights, order } = this.props

		const fileOptions: DropDownItem[] = []
		//Разделение на будущее для проверки прав ...
		fileOptions.push(
			{
				name: 'UI.dropDown.createSuborder',
				id: 2,
				icon: 'fa fa-link',
				handler: this.createSubOrder.bind(this, '1'),
			},
			{
				name: 'UI.dropDown.createSuborders',
				id: 3,
				icon: 'fa fa-link',
				handler: this.openSubOrderModal,
			},
		)

		if (rights && rights?.['tasks.orders']?.create) {
			fileOptions.push({
				name: 'UI.dropDown.createTask',
				id: 6,
				icon: 'fa fa-tasks',
				handler: this.createOrderTask,
			})
		}

		if (
			settings &&
			settings.hasOwnProperty('orderDoneStatusId') &&
			this.props.order.orderStatus.id.toString() !==
			settings.orderDoneStatusId
		) {
			fileOptions.push({
				name: 'UI.dropDown.createWaitRequest',
				handler: this.props.openWaitRequestModal,
				id: 7,
				icon: 'fa fa-clock-o',
			})
		}

		if (this.props.order.canWorkorder) {
			fileOptions.push({
				name: 'UI.dropDown.addToWorkorder',
				handler: this.openAddOrderToWorkorderModal,
				id: 8,
				icon: 'fa fa-plus',
			})
		}

		if (
			settings &&
			settings.hasOwnProperty('orderDoneStatusId') &&
			this.props.order.orderStatus.id.toString() !==
			settings.orderDoneStatusId
		) {
			fileOptions.push({
				name: 'UI.dropDown.declineOrder',
				handler: this.declineOrderOpenModal,
				id: 9,
				icon: 'fa fa-ban',
			})
		}

		if (order.otRequest) {
			fileOptions.push({
				id: 10,
				icon: 'fa fa-window-close',
				name: 'Отвязать WO',
				handler: this.removeOtRequest,
			})
		}
		return fileOptions
	}

	declineOrderOpenModal = () => {
		this.props.openModal({
			id: 'ordersDeclineOrder',
			component: (props) => (
				<DeclineReasonModal {...props} save={this.saveReason} />
			),
			title: getText('orders.cancelReason'),
		})
	}

	saveReason = async (reason) => {
		const { settings } = this.props
		try {
			this.setState({
				loading: true,
			})
			const declineOrderStatusId = settings && settings['declineOrderId']
			const declined = await declineOrder(this.props.order.id, reason)
			if (declined) {
				const declineStatus = await getStatus(declineOrderStatusId)
				await this.props.updateOrderList(this.props.order.id, {
					orderStatus: declineStatus,
				})
				await this.props.updateOrder(this.props.order.id)
			}
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	removeOtRequest = async () => {
		try {
			const accept = await confirm({
				question: getText('woDeclineMessage'),
			})
			if (!accept) return
			this.setState({
				loading: true,
			})
			await api.orderRemoveOtRequest(this.props.order.id)
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	narrowExecutionTimeToHumanReadable = (value: string) => {
		return value
			.split('-')
			.map((item) => item.padStart(2, '0'))
			.join(':')
	}

	getOrderContractConditions = (): LoadReturn => {
		return new Promise((resolve) => {
			const cc = this.props.order?.contract?.contractConditions || []
			const result = getUniqueList(cc, this.state.contractConditions, {
				wholeId: 'contractConditionId',
				existId: 'contractConditionId',
			})
			resolve(result)
		})
	}

	onChangeMasters = async (option: ChangeMultiProps) => {
		try {
			const { value, type } = option;
			if (type === "remove-value") {
				const deleted = await api.deleteOrderMaster(this.props.order.id, +value.value);
				if (deleted) {
					await this.setState((prevState) => ({
						masters: prevState.masters.filter(master => master.id !== value.value)
					}));
				}
			}
			await this.props.updateOrder(this.props.order.id);
		} catch (e) {
			checkError(e)
		}
		return false
	}

	addMaster = async (masterId: number) => {
		try {
			this.setState({
				loading: true,
			})
			await api.addOrderMaster(this.props.order.id, masterId);
			await this.props.updateOrder(this.props.order.id);
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	openAddMasterPopup = () => {
		this.props.openModal({
			id: 'addMaster',
			title: getText('masters.addMaster'),
			component: (props: ModalBodyProps) => (
				<AddMasterPopup
					{...props}
					areaId={this.props?.order?.object?.city?.area?.id}
					cityId={this.props?.order?.object?.city?.id}
					equipmentCategoryId={this.props?.order?.equipmentCategory?.id}
					excludeMastersId={this.state.masters.map((m) => m.id)}
					addMaster={this.addMaster}
				/>
			),
		})
	}

	componentDidMount() {
		this.updateDefaultState()
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		const { order } = this.props

		if (
			order.id !== prevProps.order.id ||
			this.state.waitRequests.length !==
			(order.waitRequests && order.waitRequests.length)
		) {
			this.updateDefaultState()
		}
	}

	get firstBlockItems() {
		const { order } = this.props
		const { otRequest, contractOrdersType, number } = order

		const result: any[] = [{ key: getText('orders.number'), value: number }]

		if (order.otRequest) {
			result.push(
				{
					key: getText('orders.numberWO'),
					value: `${otRequest?.number} ${otRequest?.baseDocument ? otRequest.baseDocument : ''
						}`,
				},
				{
					key: getText('orders.initiatorATB'),
					value: otRequest?.initiator,
				},
			)
		}
		result.push({
			key: getText('orders.orderType'),
			value: contractOrdersType && contractOrdersType.name,
		})
		return result
	}

	render() {
		const { rights } = this.props
		const {
			createdBy,
			number,
			initiator,
			highpriced,
			contractOrdersType,
			orderStatus,
			equipmentCategory,
			createdAt,
			dateStart,
			dateEnd,
			dueDate,
			additionalInfo,
			contactInfo,
			content,
			dueDateCustomer,
			object,
			contract,
			warranty,
			executionTime,
			waitRequests,
			otRequest,
			declineReasons,
			duration,
			works,
			materials,
		} = this.props.order

		const { contractConditions, tags, masters } = this.state

		const fileOptions = this.createFileOptions()

		const canRead = rights && rights['orders'] && rights['orders']['read']
		const canEdit = rights && rights['orders'] && rights['orders']['edit']

		const preparedMasters = masters.map((m) => ({
			name: getFullName(m.person),
			id: m.id,
		}))

		return canRead ? (
			<>
				<Spinner loading={this.state.loading} />
				<div className='just-pro__pre-detail-panel'>
					<div className='panel-toolbar'>
						<Dropdown
							onChange={this.onChangeOrderOption}
							list={fileOptions}
							label={{
								name: 'UI.dropDown.chooseAction',
								icon: '',
							}}
						/>
						<div />

						{canEdit && (
							<Button
								className='btn-default pull-right'
								onClick={this.props.getEditMode}
							>
								<i className='fa fa-edit' />
								{GetText('common.edit')}
							</Button>
						)}
					</div>
				</div>

				<InfoBlock>
					<BlockController items={this.firstBlockItems} />

					<BlockController
						items={[
							{
								key: getText('orders.highPriced'),
								value: highpriced
									? getText('common.yes')
									: getText('common.no'),
							},
							{
								key: getText('orders.guarantee'),
								value: warranty
									? getText('common.yes')
									: getText('common.no'),
							},
							{
								key: getText('orderTypes.alias'),
								value: contractOrdersType?.alias,
							},
							{
								key: getText('orderStatuses.orderStatus'),
								value: orderStatus && orderStatus.name,
							},
						]}
					/>
				</InfoBlock>

				<InfoBlock>
					<BlockController
						items={[
							{
								key: getText('regions.region'),
								value:
									object &&
									object.square &&
									object.square.region.name,
							},
							{
								key: getText('objects.object'),
								value: getObjectName(object),
							},
							{
								key: getText('common.initiator'),
								value: getFullName(initiator),
							},
							{
								key: getText('brands.brand'),
								value:
									contract && contract.contractor.brand.name,
							},
							{
								key: getText('contractors.contractor'),
								value: contract && contract.contractor.name,
							},
							{
								key: getText('common.executor'),
								value: contract && contract.executor.name,
							},
							{
								key: getText(
									'equipmentCategories.equipmentCategory',
								),
								value:
									equipmentCategory && equipmentCategory.name,
							},
						]}
					/>
					<BlockController
						items={[
							{
								key: getText('orders.date'),
								value: moment(createdAt).format(
									'DD.MM.YYYY HH:mm:ss',
								),
							},
							{
								key: getText('orders.time'),
								value: secondsToDayHoursFormat(duration),
							},
							{
								key: getText('orders.startDate'),
								value:
									dateStart &&
									moment(dateStart).format('DD.MM.YYYY HH:mm:ss'),
							},
							{
								key: getText('orders.dueDateCustomer'),
								value:
									dueDateCustomer &&
									moment(dueDateCustomer).format(
										'DD.MM.YYYY HH:mm:ss',
									),
							},
							{
								key: getText('orders.dueDateContract'),
								value:
									dueDate &&
									moment(dueDate).format('DD.MM.YYYY HH:mm:ss'),
							},
							{
								key: getText('orders.dateEnd'),
								value:
									dateEnd &&
									moment(dateEnd).format(
										'DD.MM.YYYY HH:mm:ss',
									),
							},
						]}
					/>
				</InfoBlock>

				{content && (
					<div className='bs-callout bdt'>
						<h4>{getText('common.contentInfo')}</h4>
						<p
							className='just_pro__auto-pre'
							dangerouslySetInnerHTML={{ __html: content }}
						/>
					</div>
				)}
				<RenderIf condition={works?.length}>
					<h4>{getText('common.works')}</h4>
					<Table
						wrapperClassName={'orders__table'}
						columns={[
							{
								Header: getText('common.name'),
								accessor: 'name',
							},
							{
								Header: getText('common.count'),
								accessor: 'count',
								width: 100,
								Cell: (props) =>
									`${props.original.count} ${props.original.unit || 'шт.'
									}`,
							},
						]}
						data={works}
					/>
				</RenderIf>

				<RenderIf condition={materials?.length}>
					<h4>{getText('common.materials')}</h4>
					<Table
						wrapperClassName={'orders__table'}
						columns={[
							{
								Header: getText('common.name'),
								accessor: 'name',
							},
							{
								Header: getText('common.count'),
								accessor: 'count',
								width: 100,
								Cell: (props) =>
									`${props.original.count} ${props.original.unit || 'шт.'
									}`,
							},
						]}
						data={materials}
					/>
				</RenderIf>

				{(additionalInfo ||
					(executionTime && executionTime.length > 0) ||
					(tags && tags.length > 0) ||
					(contractConditions && contractConditions.length > 0) ||
					(waitRequests && waitRequests.length > 0)) && (
						<>
							<div className='bs-callout bdt'>
								<h4>{getText('common.additionalInfo')}</h4>
								<p className='just_pro__auto-pre'>
									{additionalInfo}
								</p>
								{executionTime && executionTime.length > 0 && (
									<div className='order-execution-time'>
										<h6>{getText('orders.executedTime')} :</h6>
										<ul>
											{executionTime.map((time, index) => {
												return (
													<li key={index}>
														{getText('common.from')}{' '}
														{this.narrowExecutionTimeToHumanReadable(
															time.from,
														)}
														&nbsp;
														{getText('common.to')}{' '}
														{this.narrowExecutionTimeToHumanReadable(
															time.to,
														)}
													</li>
												)
											})}
										</ul>
									</div>
								)}
								{this.getContractConditionView()}
								{this.getTagsView()}
								{this.getWaitRequestsView()}
							</div>
						</>
					)}

				{declineReasons?.length > 0 &&
					declineReasons?.map((declineReason, index) => (
						<div key={index}>
							<br />
							<div className='bs-callout bdt'>
								<h4>{getText('orders.cancelReason')}</h4>
								<p>
									{declineReason?.reason}
									<br />
									{getText('common.fromWho')}{' '}
									<b>
										{moment(
											declineReason?.createdAt,
										).format('DD.MM.YYYY HH:mm:ss')}
									</b>
									{getText('common.createdBy')}:{' '}
									<b>
										{getFullName(
											declineReason?.author?.person,
										)}{' '}
									</b>
									<br />
								</p>
							</div>
						</div>
					))}

				{canEdit && (
					<>
						<div className='row bdt'>
							<div className='col-xs-6 col-sm-6 col-md-6 col-lg-6'>
								<Select
									change={this.onChangeContractCondition}
									load={this.getOrderContractConditions}
									withoutValue={true}
									placeholder='UI.select.placeholders.selectContractCondition'
									accessors={{
										name: 'label',
										contractConditionId: 'value',
									}}
								/>
							</div>
							<div className='col-xs-6 col-sm-6 col-md-6 col-lg-6'>
								<Select
									change={this.onChangeOrderTag}
									load={this.getOrdersTags}
									withoutValue={true}
									placeholder='UI.select.placeholders.addTag'
									accessors={{
										name: 'label',
										id: 'value',
									}}
								/>
							</div>
						</div>
					</>
				)}

				<RenderIf condition={rights?.['orders.masters']?.read}>
					<div className='row bdt'>
						<div className='col-xs-9 col-sm-9 col-md-9 col-lg-9'>
							<MultiSelect
								isSearchable={false}
								isLoading={false}
								menuIsOpen={false}
								isDisabled={!rights?.['orders.masters']?.edit}
								change={this.onChangeMasters}
								placeholder='UI.select.placeholders.selectMaster'
								accessors={{
									name: 'label',
									id: 'value',
								}}
								defaultValues={preparedMasters}
							/>
						</div>
						<div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
							<Button onClick={this.openAddMasterPopup}
								className="open-master-popup btn-success">
								{getText('common.add')}
							</Button>
						</div>
					</div>
				</RenderIf>

				{contactInfo && (
					<div className='bs-callout bdt'>
						<h4>{getText('common.contactInfo')}</h4>
						<p className='just_pro__auto-pre'>{contactInfo}</p>
					</div>
				)}

				<div className='bdt'>
					<b>{getText('common.createdBy')}: </b>
					<PersonInfo person={createdBy && createdBy.person} />
				</div>

				<OrderTabs
					id={this.props.order.id}
					act={this.props.order.act}
					workorders={this.props.order.workorders}
					otRequest={this.props.order.otRequest}
				/>
			</>
		) : (
			<HasNoRightsController />
		)
	}
}

const mapStateToProps = (state: ApplicationMapState) => ({
	settings: state.application.settings,
	rights: state.application.rights,
})

const mapDispatchToProps = (dispatch: Function) => ({
	updateOrderList: (orderId: number, data: UpdateOrderList) =>
		dispatch(updateOrderList(orderId, data)),
	openModal: (props: Modal) => dispatch(openModal(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderReadMode)
