import React from 'react';
import {createSpecialization, Specialization, getSpecializations, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import SpecializationController from "./specialization.controller";
import SpecializationCreate from "./specialization.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class SpecializationsController extends React.Component<Props>{

    renderItem = (item:Specialization) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.specializations'];
        return <BaseReferenceController
            referenceName="specializations.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getSpecializations}
            Detail={SpecializationController}
            create={{
                Component : SpecializationCreate,
                title : 'specializations.addItem',
                handler : createSpecialization
            }}
            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(SpecializationsController)