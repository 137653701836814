import React from "react";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {ModulesResponse, Position} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";


interface Props {
    position : Position

    update(id:number, data:Partial<Position>) : void
    rights?: Partial<ModulesResponse>
}

class PositionDetail extends React.Component<Props>{

    updateName = (name:string, prevVal:string) => {
        if(name !== prevVal && name.trim() !== '') {
            this.props.update(this.props.position.id, {name})
        }
    };

    updateActive = (active:boolean) => {
        this.props.update(this.props.position.id,{active})
    };

    render () {
        const {rights, position} = this.props;
        const {active, name} = position;
        const disabled = rights && rights['references.positions'] && !rights['references.positions']['edit'];
        return (
            <>
                <h3>{getText('positions.position')}: {name} </h3>

                <ToggleSwitch
                    defaultValue={active}
                    send={this.updateActive}
                    label="common.active"
                    disabled={disabled}
                />

                <Input
                    blur={this.updateName}
                    label="positions.position"
                    startValue={name}
                    disabled={disabled}
                />
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(PositionDetail)