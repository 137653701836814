import React from 'react'
import {
	addPersonExecutorPayments,
	deletePersonExecutorPayment,
	ExecutorPayment,
} from '@justpro/terminal'
import Table from '../../../UI/table/table'
import checkError from '../../../../utils/checkError'
import Button from '../../../UI/button/button'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../../store/application/application.types'
import TextArea from '../../../UI/textArea/textArea.controller'
import DeleteCell from '../../../UI/table/deleteCell'
import getText from '../../../../localization/getText'

interface Props {
	personId: number
	executorPersonId: number
	rights: any
	payments?: ExecutorPayment[]
}

interface State {
	payments: ExecutorPayment[]
	payment: string
}

class PaymentsTable extends React.Component<Props, State> {
	state: State = {
		payments: [],
		payment: '',
	}

	addPayment = async () => {
		try {
			const payment = await addPersonExecutorPayments({
				payInformation: this.state.payment,
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
			})

			if (payment) {
				this.setState((prevState) => ({
					payments: [...prevState.payments, payment],
				}))
			}

			this.setState(() => ({
				payment: '',
			}))
		} catch (e) {
			checkError(e)
		}

		return Promise.resolve()
	}

	deletePayment = async (props: any) => {
		try {
			const deleted = await deletePersonExecutorPayment({
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
				paymentId: props.id,
			})

			if (deleted) {
				this.setState((prevState) => ({
					payments: prevState.payments?.filter(
						(p) => p.id !== props.id,
					),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	getColumns = () => {
		const { rights } = this.props

		const result: any[] = [
			{
				Header: getText('persons.stageProperty'),
				accessor: 'payInformation',
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			!!rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				// Cell : (props:any) => <Button className="btn-success" onClick={this.deletePayment.bind(this, props.original)}><i className="fa fa-trash no-text"/></Button>
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deletePayment}
						item={props.original}
					/>
				),
			})
		}
		return result
	}

	changePaymentText = (payment: string) => {
		this.setState(() => ({
			payment,
		}))
	}

	resetState = () => {
		this.setState(() => ({
			payments: this.props.payments ? this.props.payments : [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.resetState()
		}
	}

	render() {
		const { payments } = this.state
		const { rights } = this.props

		return (
			<>
				{payments && payments.length > 0 && (
					<Table columns={this.getColumns()} data={payments} />
				)}

				{rights &&
					rights['references.persons'] &&
					!!rights['references.persons']['edit'] && (
						<div
							className={payments.length > 0 ? 'margin-top' : ''}
						>
							<TextArea
								change={this.changePaymentText}
								placeholder='persons.newStageProperty'
								value={this.state.payment}
							/>

							<Button
								className='btn-success'
								onClick={this.addPayment}
								disabled={this.state.payment.length === 0}
							>
								{getText('common.add')}
							</Button>
						</div>
					)}
			</>
		)
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

export default connect(mapStateToProps)(PaymentsTable)
