import React from 'react';
import {editEmail, Email, getEmail} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import EmailTypeDetail from "./emailType.detail";

interface Props {
    id: number
    afterUpdate?(item:Email) : void
}

interface State {
    loading : boolean
    emailType? : Email
}

class EmailTypeController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateEmailType = async (data:Partial<Email>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const emailType = await editEmail(this.props.id, data);

            afterUpdate && afterUpdate(emailType);
            this.setState(() => ({loading : false, emailType}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getEmailType = async () => {
        this.setState(() => ({loading : true}));

        try {
            const emailType = await getEmail(this.props.id);


            this.setState(() => ({loading : false, emailType}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getEmailType();
        }
    }

    async componentDidMount(){
        this.getEmailType();
    }

    render() {
        const {emailType} = this.state;
        return (
            <>
                <RenderIf condition={emailType !== undefined}>
                    <EmailTypeDetail
                        emailType={emailType as Email}
                        update={this.updateEmailType} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default EmailTypeController