import React from 'react';
import BaseReferenceController from "../baseReference/baseReference.controller";
import {createStatusGroup, getStatusGroups, ModulesResponse, StatusGroup} from "@justpro/terminal";
import StatusGroupController from "./statusGroup.controller";
import StatusGroupCreate from "./statusGroup.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class OrderStatusGroups extends React.Component<Props> {

    renderItem = (item:StatusGroup) => {
        return <>
            {item.name}
        </>
    };

    render () {

        const rights = this.props.rights && this.props.rights['references.statusGroups'];
        return (
            <BaseReferenceController
                referenceName="statusGroups.moduleName"
                getList={getStatusGroups}
                listItem={{
                    render : this.renderItem,
                }}
                Detail={StatusGroupController}
                create={{
                    Component : StatusGroupCreate,
                    title : 'statusGroups.addItem',
                    handler: createStatusGroup
                }}
                moduleRights={rights}
            />
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});
export default connect(mapState)(OrderStatusGroups)