import React from 'react';
import Select from "../../../UI/select/select";
import {getOrdersTypesList} from "../../../../utils/functions";
import Input from "../../../UI/input/text";
import withLabel from "../../../UI/withLabel/withLabel";
import {AcceptedOption, Option} from "../../../UI/select/select.types";
import {ContractOrdersType} from "@justpro/terminal";
import {renderToString} from "react-dom/server";
import getText from "../../../../localization/getText";
import {hideModal, updateModal} from "../../../../store/modal/modal.actions";
import {connect} from "react-redux";
import {Modal} from "../../../../store/modal/modal.types";
import {orderTypesModalId} from "../contract.orderTypes";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";

interface Unit  {
    label : string
    value : number
}

interface UnitMap {
    [key : number] : {
        calc : (val:string) => number
    }
}

export interface OrderType {
    becomeHot : number,
    deadline : number,
    id : number
    name : string
    alias : string
    temp_id : number
    rowId? : number
}

interface Props {
    onSave(data:OrderType, mode : 'edit' | 'create') : void
    hide() : void

    contractOrdersType? : ContractOrdersType
}

interface State {
    orderType : Unit | null
    deadline : string
    becomeHot : string
    deadLineUnit : Unit
    becomeHotUnit : Unit
    alias : string
    id?: number
}

const units:Unit[] = [
    {
        label : renderToString(getText('common.inHours')),
        value : 1,
    },
    {
        label : renderToString(getText('common.inDays')),
        value : 2,
    },
];

const unitsMap:UnitMap = {
    1 : {
        calc : (val:string) => parseInt(val)
    },
    2 : {
        calc : (val:string) => parseInt(val) * 24
    }
};


const defaultBecomeHotUnit = units[0];
const defaultDeadlineUnit = units[0];

class OrderTypesModal extends React.Component<Props, State> {
    state:State = {
        orderType : null,
        deadLineUnit : defaultDeadlineUnit,
        becomeHotUnit : defaultBecomeHotUnit,
        alias : '',
        deadline : '',
        becomeHot : '',
    };

    addContractOrderType = () => {
        const {deadline, alias, becomeHot, becomeHotUnit, deadLineUnit, orderType, id} = this.state;

        if(orderType) {
            const data:OrderType = {
                deadline : unitsMap[deadLineUnit.value].calc(deadline),
                becomeHot : unitsMap[becomeHotUnit.value].calc(becomeHot),
                id : orderType.value,
                name : orderType.label,
                alias : alias,
                temp_id : Math.round(Math.random() * 2e9),
                rowId : id

            };
            const mode = id ? 'edit' : 'create';

            this.props.onSave(data, mode);
            this.props.hide();
        }

    };

    changeOrdersType = (orderType:Option) => {
        return new Promise (async resolve => {
            this.setState(() => ({
                orderType,
            }),this.isDisabledModalSaveButton);

            resolve()

        })
    };

    changeOrderTypePseudonym = async (alias:string) => {
        this.setState(() => ({
            alias,
        }), this.isDisabledModalSaveButton);
    };


    changeOrderTypeDeadline = async (deadline:string) => {
        this.setState(() => ({
            deadline,
        }),this.isDisabledModalSaveButton);

    };

    changeOrderTypeBecomeHot = async (becomeHot:string) => {
        await this.setState(() => ({
            becomeHot,
        }), this.isDisabledModalSaveButton);
    };

    changeDeadlineUnit = (deadLineUnit:Option) => {
        return new Promise(resolve => {
            this.setState(() => ({deadLineUnit}), this.isDisabledModalSaveButton);
            resolve()
        });
    };

    changeBecomeHotUnit = (becomeHotUnit:Option) => {
        return new Promise(resolve => {
            this.setState(() => ({becomeHotUnit}), this.isDisabledModalSaveButton);
            resolve()
        });
    };

    isDisabledModalSaveButton = () => {
        const {contractOrdersType} = this.props;

        if(contractOrdersType && contractOrdersType.id) {
            return false
        }

        return this.state.alias.trim() === '' ||
            this.state.deadline.trim() === '' ||
            this.state.becomeHot.trim() === '' ||
            this.state.orderType === null;
    };


    resetDefaultState = () => {
        const {contractOrdersType} = this.props;
        if(contractOrdersType !== undefined) {
            this.setState(() => ({
                becomeHot : contractOrdersType.becomeHot?.toString(),
                alias :  contractOrdersType.alias || '',
                deadline : contractOrdersType.deadline?.toString(),
                orderType : {
                    label : contractOrdersType.name,
                    value : contractOrdersType.ordersTypeId
                },
                id : contractOrdersType.id
            }))
        }
    };


    componentDidMount(): void {
        this.resetDefaultState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(JSON.stringify(prevProps.contractOrdersType) !== JSON.stringify(this.props.contractOrdersType)) {
            this.resetDefaultState()
        }
    }

    render() {

        const {orderType} = this.state;

        const defaultValue:AcceptedOption | undefined = orderType ? {
            name : orderType.label,
            id : orderType.value
        } : undefined;

        return (
            <>
                <Select
                    change={this.changeOrdersType}
                    load={getOrdersTypesList}
                    defaultValue={defaultValue}
                    // isDisabled={!!this.state.id}
                    label="orderTypes.orderType"
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                />

                <Input
                    label="common.alias"
                    change={this.changeOrderTypePseudonym}
                    startValue={this.state.alias}
                    placeholder="contracts.nameForContractor"
                />

                {withLabel(
                    <div className="just-pro__row-items">
                        <Input
                            // label=" "
                            change={this.changeOrderTypeDeadline}
                            startValue={this.state.deadline}
                        />

                        <Select
                            change={this.changeDeadlineUnit}
                            defaultOptions={units}
                            defaultValue={this.state.deadLineUnit}
                            // label=" "
                        />
                    </div>
                )({
                    text: 'contracts.deadline'
                })}

                {withLabel(
                    <div className="just-pro__row-items">
                        <Input
                            change={this.changeOrderTypeBecomeHot}
                            startValue={this.state.becomeHot}
                        />

                        <Select
                            change={this.changeBecomeHotUnit}
                            defaultOptions={units}
                            defaultValue={this.state.becomeHotUnit}
                        />
                    </div>
                )({
                    text: 'contracts.becomeHot'
                })}

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.addContractOrderType}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </>
        );
    }
}

export default OrderTypesModal