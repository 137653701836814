import React from 'react'
import {InfoWindow} from 'react-google-maps'
import {IClickedInnerProps, IHoveredInnerProps} from './innerWindow.types'

import WithModuleDetail from "../../../UI/dialog/withModuleDetail/withModuleDetail.controller";
// import WorkOrderDetail from "../../../workOrders/workOrder.detail";


// import WorkOrderDetail from '../../../workOrders/workOrder.detail'
import WorkOrderController from '../../../workOrders/workOrder.controller'
import RenderIf from '../../../../utils/renderIf'

import {Provider} from "react-redux";
import store from "../../../../store";

export const ExecutorInfoWindow: React.FC<IClickedInnerProps> = ({executor, position, discardClicked}) => {

    const closeOnMapClick = (e) => {
        if (!e.target.closest('.unclosable')) {
            discardClicked()
        }
    }
    React.useEffect(() => {
        const el = document.getElementById('maps')
        el.addEventListener('click', closeOnMapClick, true)
        return () => {
            el.removeEventListener('click', closeOnMapClick)
        }
    }, [])

    return (
        <InfoWindow
            position={position}
            onCloseClick={discardClicked}
        >
            <div className='unclosable'>
                <h2>{executor?.firstName} {executor?.lastName}</h2>
                <div className="just-pro__maps__workorders">
                    {executor?.workorders?.map((wo, i) => {
                        return (
                            <div key={i} className="just-pro__maps__workorder-wrapper">
                                <WithModuleDetail
                                    element={
                                        <div key={i}>
                                            Наряд <b>№{wo.number}</b>
                                            <i className="fa fa-expand expand"/>
                                        </div>
                                    }
                                    modal={{
                                        id: 'workOrderListItem',
                                        component: () => (
                                            <WorkOrderController
                                                id={wo.id}
                                            />
                                        ),
                                        title: 'Наряд',
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </InfoWindow>
    )
}

export const ProvideredConnectedInfoWindow = (props) => (
    <Provider store={store}>
        <ExecutorInfoWindow {...props} />
    </Provider>
)
// export default ProvideredConnectedInfoWindow

export const ExecutorDisplayName: React.FC<IHoveredInnerProps> = ({object, hovered, showNames}) => {
    return (<></>)
}
