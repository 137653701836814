import React from 'react';
import {CreateContract} from "@justpro/terminal";
import {Option} from "../../UI/select/select.types";
import Select from "../../UI/select/select";
import {getContracts1sList} from "../../../utils/functions";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";


interface Props {
    create(data:CreateContract) : void
}

interface State {
    contract1sId? : number
}

class ContractCreate extends React.Component<Props, State>{

    state:State = {};

    create = () => {

        if(!this.isDisabled()) {

            const createData:CreateContract = {
                contract1sId : this.state.contract1sId as number
            };

            this.props.create(createData)
        }
    };


    isDisabled = () => {
        return this.state.contract1sId === undefined

    };

    change1cContract = (option:Option) => {

        this.setState(() => ({
            contract1sId : option.value
        }));

        return Promise.resolve();

    };

    render() {

        return <>
            <h3>{getText('contracts.addingNewHeader')}</h3>

            <Select
                change={this.change1cContract}
                load={getContracts1sList}
                label="contracts.contract1s"
                accessors={{
                    name : 'label',
                    id : 'value',
                }}
            />

            <Button className="btn-success" onClick={this.create} disabled={this.isDisabled()}>{getText('common.add')}</Button>

        </>
    }
}

export default ContractCreate