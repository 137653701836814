import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";
import {CreateOrdersType} from "@justpro/terminal";
import InputColor from "../../UI/input/color";
import {toast} from "react-toastify";
import getText from "../../../localization/getText";
import checkError from "../../../utils/checkError";


type Props = {
    create(data:CreateOrdersType):void
};

type State = {
    name : string,
    deadline : number,
    brandId : number
    priority : string,
    color : string
};

export default class OrderTypeCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
        deadline : -1,
        brandId : -1,
        priority : '',
        color : '#ffffff'
    };

    add = () => {
        if(!this.isDisabled()) {
            const {
                name,
                priority,
                color
            } = this.state;

            const parsed = parseInt(priority, 10);

            if(isNaN(parsed)) {
                checkError(new Error('errors.valueMustBeANumber'));
            }else{
                const data = {
                    name,
                    priority : parsed,
                    color
                };

                this.props.create(data)
            }
        }
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    isDisabled = ():boolean => {
        return this.state.name.trim() === '' ||
                this.state.priority === ''

    };

    blurColor = (color:string) => {
        this.setState(() => ({
            color
        }));
    };

    onChangePriority = (priority:string) => {
        this.setState(() => ({
            priority
        }));
    };

    render () {


        return (
            <>
                <h3>{getText('orderTypes.addingNewHeader')}</h3>

                <Input
                    label="orderTypes.orderType"
                    placeholder="orderTypes.orderType"
                    change={this.onChangeName}
                />

                <InputColor label="common.color" blur={this.blurColor} defaultValue={this.state.color}/>

                <Input
                    change={this.onChangePriority}
                    label="common.priority"
                />

                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

