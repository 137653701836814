import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import makeValidUserRightsStructure from "../../../utils/makeValidUserRightsStructure";
import {CreatePerson} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface State {
    firstName : string
    lastName : string
    patronymic? :string
}

interface Props {
    create(data:CreatePerson) : void
}


export default class PersonCreate extends React.Component<Props, State>{

    state:State = {
        firstName : '',
        lastName : '',
        // patronymic? : ''
    };


    changeFirstName = (firstName:string) => {
        this.setState( () => ({
            firstName
        }))
    };

    changeLastName = (lastName:string) => {
        this.setState( () => ({
            lastName
        }))
    };

    changePatronymic = (value:string) => {
        this.setState( () => ({
            patronymic : value
        }))
    };

    //todo вынести в utils
    isDisabled = () => {
        return this.state.lastName.trim() === '' || this.state.firstName.trim() === '';
    };

    addPerson = () => {
        const {firstName, lastName, patronymic} = this.state;


        this.props.create({
            firstName,
            lastName,
            patronymic : patronymic ? patronymic : ''
        })

    };

    render () {

        return (
            <div>
                <h3>{getText('persons.addingNewHeader')}</h3>
                <Input
                    label="persons.name"
                    change={this.changeFirstName}
                    placeholder="persons.name"
                />
                <Input
                    label="persons.lastName"
                    change={this.changeLastName}
                    placeholder="persons.lastName"

                />
                <Input
                    label="persons.patronymic"
                    change={this.changePatronymic}
                    placeholder="persons.patronymic"
                />
                <Button className="btn-success" onClick={this.addPerson} disabled={this.isDisabled()}>{getText('common.add')}</Button>

            </div>
        )
    }
}