import React from 'react';
import Table from '../../UI/table/table';
import Select from '../../UI/select/select'
import {Option} from '../../UI/select/select.types'
import {Props, State, RightsObjectsList} from './group.types'
import {connect} from "react-redux";
import RenderIf from "../../../utils/renderIf";
import EditableText from "../../UI/editableText/editableText";
import {
    removeObject,
    renameGroup,
    changeParentGroup,
    addObject
} from '../../../store/users/actions/group/group.actions'
import {getChildrenObjects} from '../../../utils/getChildrenObjects'
import {ChangeGroup} from "@justpro/terminal";
import {UsersMapStateToProps} from "../users.types";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";


class Group extends React.Component<Props, State> {
    state: State = {
        editable: false,
        removedId: -1
    };

    hasObjects = false;

    onExcludeObject = async (props: any) => {
        await this.props.removeObject(this.props.id, props.original.id);
        // update objects list
        this.setState({
            removedId: props.original.id,
        })
    };


    getObjectsGridParams = () => {
        const objectsGridColumns = [
            {
                Header: getText('users.extendsFrom'),
                accessor: 'name',
            },
            {
                Header: getText('users.availableObjects'),
                accessor: 'description',
            },
            {
                Header: getText('common.exclude'),
                accessor: 'edit',
                Cell : (item:any) => {
                    if(item.original.groupId === this.props.id){
                        return <DeleteCell deleteHandler={this.onExcludeObject} item={item}/>
                    }
                    return <div></div>;
                }
            },
        ];
        const resultObjectList: Partial<RightsObjectsList>[] = [];
        const {
            groupsMap,
            name,
            id,
        } = this.props;

        const {children} = groupsMap[id];

        if (children && children.length) {
            let childsObjects = getChildrenObjects(children, groupsMap);
            resultObjectList.push(...childsObjects)
        }

        groupsMap[id] && groupsMap[id].objects.forEach(object => {
            resultObjectList.push({
                id: object.id,
                description: object.name,
                name,
                groupId: id,
                edit: true
            })
        });

        this.hasObjects = resultObjectList.length > 0;

        return {
            columns: objectsGridColumns,
            data: resultObjectList,
            showPagination: true,
            minRows: 0,
        }
    };


    onChangeGroupName = (value:string) => {
        if (value) {
            this.props.renameGroup(this.props.id, {name: value})
        }
    };

    changeParentGroup = (option: Option) => {
        return new Promise(async (resolve) => {
            await this.props.changeParentGroup(this.props.id, {
                parentGroup: option.value
            });
            resolve();
        })
    };

    changeObject = (option: Option) => {
        return new Promise(async resolve => {
            await this.props.addObject(this.props.id, option.value);
            resolve()
        })
    };

    render() {
        const objectsGridParams = this.getObjectsGridParams();
        const isRootGroup = this.props.id === 1;

        const {rights} = this.props;

        const canEdit = rights && rights['users'] && rights['users']['edit'];

        return (
            <React.Fragment>
                <h2>{getText('users.groupUpperCase')}: {canEdit ? (
                    <EditableText
                        text={this.props.name}
                        onChangeText={this.onChangeGroupName}
                        editable={this.state.editable}
                    />
                ) : this.props.name}
                </h2>

                {!isRootGroup &&
                    <Select
                        change={this.changeParentGroup}
                        defaultOptions={this.props.parentList}
                        defaultValue={this.props.parent}
                        accessors={{id: 'value', name: 'label'}}
                        label='users.parentGroup'
                        isDisabled={!canEdit}
                    />
                }
                <h2>{getText(this.hasObjects ? 'users.groupObjects' : 'users.addAnObject')}</h2>

                <RenderIf condition={this.hasObjects}>
                    <Table {...objectsGridParams}
                           sorting={false}
                           showPaginationBottom
                    />
                </RenderIf>


                <AsyncSelect
                    label={{text : 'users.addAnObject'}}
                    loadOptions={(q:string) => groupObjectsByRegion({q})}
                    change={this.changeObject}
                    isDisabled={!canEdit}
                />

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state: UsersMapStateToProps) => ({
    id: state.users.groups.id,
    name: state.users.groups.name,
    groupsMap: state.users.groups.map,
    parent: state.users.groups.parent,
    parentList: state.users.groups.parentList,
    rights : state.application.rights

});

const mapDispatchToProps = (dispatch: Function) => ({
    removeObject: (groupId: number, objectId: number) => dispatch(removeObject(groupId, objectId)),
    addObject: (groupId: number, objectId: number) => dispatch(addObject(groupId, objectId)),
    renameGroup: (id: number, data: ChangeGroup) => dispatch(renameGroup(id, data)),
    changeParentGroup: (id: number, data: ChangeGroup) => dispatch(changeParentGroup(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group)