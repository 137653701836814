import React from "react";
import {ContractCondition, deleteContractCC, addContractCC, Rules} from '@justpro/terminal'
import {Option} from "../../UI/select/select.types";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Button from "../../UI/button/button";
import Select from "../../UI/select/select";
import {getContractConditionsList} from "../../../utils/functions";
import checkError from "../../../utils/checkError";
import DeleteCell from "../../UI/table/deleteCell";
import getText from "../../../localization/getText";

interface Props {
    contractId : number
    contractConditions? : ContractCondition[]
    rights?: Rules

}

interface State {
    contractConditions? : ContractCondition[]
}

class ContractContractConditions extends React.Component<Props, State>{

    state:State = {};

    deleteContractCondition = async (item:any) => {
        const {id} = item.original;

        try {
            const deleted = await deleteContractCC(this.props.contractId, [id]);

            if(deleted) {
                this.setState((prevState) => ({
                    contractConditions : prevState.contractConditions?.filter(item => item.id !== id)
                }))
            }

        }catch (e) {
            checkError(e)
        }

    };

    addContractCondition = async (option:Option) => {
        try {
            const cc = await addContractCC(this.props.contractId, [option.value]);

            this.setState((prevState) => ({
                contractConditions : prevState.contractConditions ? [...prevState.contractConditions, ...cc] : cc
            }));

        }catch (e) {
            checkError(e)
        }
        return Promise.resolve();

    };

    resetState = () => {
        this.setState(() => ({
            contractConditions : this.props.contractConditions
        }))
    };

    componentDidMount(): void {
        this.resetState()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.contractId !== prevProps.contractId) {
            this.resetState()
        }
    }

    get columns() {
        const {rights} = this.props;

        const result:any[] = [
            {
                Header: getText('common.name'),
                accessor : 'name'
            }
        ];

        if(rights && rights.edit) {
            result.push(
                {
                    Header: getText('common.delete'),
                    Cell: (item:any) => <DeleteCell deleteHandler={this.deleteContractCondition} item={item}/>
                }
            )
        }


        return result
    }

    render () {
        const {rights} = this.props;
        const {contractConditions} = this.state;

        return (
            <>
                {withLabel(
                    <>
                        {contractConditions && contractConditions.length > 0 && <Table
                            columns={this.columns}
                            data={contractConditions}
                            showPagination={false}
                            minRows={0}
                        /> }

                        {rights && rights.edit &&
                            <Select
                                change={this.addContractCondition}
                                className="margin-top"
                                placeholder="UI.select.placeholders.selectContractCondition"
                                load={getContractConditionsList}
                                defaultValue={undefined}
                                accessors={{
                                    name : 'label',
                                    id : 'value'
                                }}
                            />
                        }
                    </>
                )({text : 'contractConditions.contractCondition'})}
            </>
        )
    }
}

export default ContractContractConditions