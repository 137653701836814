import React from 'react'
import withLabel from '../../UI/withLabel/withLabel'
import Table from '../../UI/table/table'
import Button from '../../UI/button/button'
import {
	addContractObjects,
	deleteContractObjects,
	Object,
	Rules,
	ContractObject as IContractObject,
} from '@justpro/terminal'
import ContactObjectsModal from './modals/objects'
import checkError from '../../../utils/checkError'
import { getObjectName } from '../../../utils/names'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'
import { renderToString } from 'react-dom/server'
import { connect } from 'react-redux'
import { Modal, ModalBodyProps } from '../../../store/modal/modal.types'
import { openModal } from '../../../store/modal/modal.actions'

interface Props {
	objects?: IContractObject[]
	contractId: number
	rights?: Rules
	openModal(props: Modal): void

	contractorId: number
}

interface State {
	objects?: IContractObject[]
}

export const contractObjectModalId = 'contractObjects'

class ContractObject extends React.Component<Props, State> {
	state: State = {}

	deleteContractObject = async (item: any) => {
		const { objectId } = item.original

		if (objectId) {
			const deleted = deleteContractObjects(this.props.contractId, [
				objectId,
			])

			if (deleted) {
				this.setState((prevState) => ({
					objects: prevState.objects?.filter(
						(item) => item.objectId !== objectId,
					),
				}))
			}
		}
	}

	openObjectsModal = () => {
		this.props.openModal({
			id: 'contractObjects',
			component: (props: ModalBodyProps) => (
				<ContactObjectsModal
					{...props}
					onSave={this.saveObjects}
					existsObjects={this.state.objects}
					contractorId={this.props.contractorId}
				/>
			),
			title: getText('contracts.addObjectsToContract'),
		})
	}

	resetState = () => {
		this.setState(() => ({
			objects: this.props.objects,
		}))
	}

	saveObjects = async (objects: number[]) => {
		try {
			const o = await addContractObjects(this.props.contractId, objects)

			this.setState((prevState) => ({
				objects: (prevState.objects
					? [...prevState.objects, ...o]
					: o) as any[],
			}))
		} catch (e) {
			checkError(e)
		}
	}

	async componentDidMount() {
		await this.resetState()
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	) {
		if (this.props.contractId !== prevProps.contractId) {
			await this.resetState()
		}
	}

	get columns() {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('common.name'),
				Cell: ({ original: object }: any) => getObjectName(object),
			},
		]

		if (rights && rights.edit) {
			result.push({
				Header: getText('common.delete'),
				Cell: (item: any) => (
					<DeleteCell
						deleteHandler={this.deleteContractObject}
						item={item}
					/>
				),
			})
		}

		return result
	}

	render() {
		const { rights } = this.props
		const { objects } = this.state

		return (
			<>
				{withLabel(
					<>
						{objects && objects.length > 0 && (
							<Table
								columns={this.columns}
								data={objects}
								getTrProps={(state, row, instance) => {
									if (!row?.original?.active) {
										return {
											style: {
												background: 'rgb(228 218 218)',
											},
										}
									}
									return {}
								}}
								// label="Объекты"
								// showPagination={false}
								minRows={0}
							/>
						)}
						{rights && rights.edit && (
							<Button
								onClick={this.openObjectsModal}
								className='btn-success margin-top'
							>
								{getText('contracts.addObjects')}
							</Button>
						)}
					</>,
				)({
					text: `${renderToString(getText('objects.objects'))} (${
						objects?.length
					})`,
				})}
			</>
		)
	}
}

const mapDispatch = (d: Function) => ({
	openModal: (props: Modal) => d(openModal(props)),
})

export default connect(null, mapDispatch)(ContractObject)
