import React from 'react';
import Button from "../../UI/button/button";
import Select from '../../UI/select/select'
import {LoadReturn, Option} from '../../UI/select/select.types'
import Input from "../../UI/input/text";
import Table from '../../UI/table/table'
import {State, Props, UserResultStatusTransfer, ResultObjectList} from './user.types'
import {RightsRow, checkbox} from '../role/roles.types'
import Tabs from "../../UI/tabs/tabs.controller";
import {
    removeUserRole,
    removeUserGroup,
    removeUserObject,
    addUserObject,
    updateUser, addUserContractor, removeUserContractor, getUsers, updateUserSettings, getUser,
} from '../../../store/users/actions/user/user.actions'
import './user.css'
import "react-tabs/style/react-tabs.css";
import {connect} from "react-redux";
import RenderIf from "../../../utils/renderIf";
import UserWithDrop from "../usersWithDnd/usersWithDrop";
import makeValidUserRightsStructure from "../../../utils/makeValidUserRightsStructure";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import {getChildrenObjects} from "../../../utils/getChildrenObjects";
import {getContractorList, getStatusesList} from "../../../utils/functions";
import {UsersMapStateToProps} from "../users.types";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";
import {ActionTypes} from "react-select";
import * as api from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import {toast} from "react-toastify";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import {hideModal, openModal, updateModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import PasswordModal from "./passwordModal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Spinner from "../../UI/spinner/spinner.controller";

const TableCell = {
    WIDTH: 15
};


const defaultPassword = "Password";

class User extends React.PureComponent <Props, State> {

    state: State = {
        addObjectList: [],
        loading: false,
        password: defaultPassword
    };
    statusRules: boolean = false;
    usersChangedPasswordModalId = 'usersChangedPasswordModal';
    person: api.Person;

    get tabs() {
        const {rights} = this.props;
        const canEdit = rights && rights['users'] && rights['users']['edit'];
        return [{
            title: 'UI.tabs.rights',
            component: () => (
                <Table {...this.resultsRightsGridOptions}
                       sorting={false}
                       showPaginationBottom
                />
            ),
            onSelectTab: () => {
            }
        }, {
            title: 'objects.objects',
            component: () => (
                <>
                    <Table {...this.objectsGridOptions}
                           sorting={false}
                           showPaginationBottom
                    />
                    <AsyncSelect
                        label={{text: 'users.addObject'}}
                        loadOptions={(q: string) => groupObjectsByRegion({q})}
                        change={this.changeObject}
                        isDisabled={!canEdit}
                    />
                </>
            ),
            onSelectTab: () => {
            }
        }]
    }

    async componentDidMount() {
        try {
            this.setState({
                loading: true
            });
            await this.props.getUser(this.props.id);
            this.person = await api.getPerson(this.props.person.id);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id && this.props.id !== -1) {
            try {
                this.setState({
                    loading: true
                });
                await this.props.getUser(this.props.id);
                this.person = await api.getPerson(this.props.person.id);
            } catch (err) {
                checkError(err);
            } finally {
                this.setState({
                    loading: false
                })
            }
        }
    }

    changePassword = async (val: string) => {
        try {
            const changed = await api.updateUser(this.props.id, {
                password: val
            });

            if (!changed) {
                checkError(new Error('users.passwordChangeError'))
            } else {
                this.setState(() => ({
                    password: defaultPassword
                }));
                toast.success(renderToString(getText('users.passwordChangeSuccess')))
            }
        } catch (e) {
            checkError(e)
        }
    };

    blurPasswordHandler = async (val: string, oldValue: string) => {
        if (val.trim() !== '' && val !== oldValue && val !== defaultPassword) {
            await this.changePassword(val);

            this.props.openModal({
                id: this.usersChangedPasswordModalId,
                title: getText('users.yourPasswordChangedSuccess'),
                component: (props: ModalBodyProps) => <PasswordModal {...props} password={val}/>,
            });
        }
    };

    changeUserDescription = async (val: string, oldValue: string) => {
        if (val.trim() !== '' && val !== oldValue) {
            const {q, groupId, roleId} = this.props;

            const searchData: api.GetUsers = {
                q
            };

            if (groupId !== -1) {
                searchData.groupId = groupId
            }

            if (roleId !== -1) {
                searchData.roleId = roleId
            }


            await this.props.updateUser(this.props.id, {
                description: val
            }, searchData);
        }
    };

    onRemoveUserRole = async (id: number, hide: () => void) => {
        await this.props.removeUserRole(this.props.id, id);
        hide();
    };

    onRolesModalOpen = (props: any) => {
        this.props.openModal({
            id: "usersExcludeRole",
            component: (modalProps: ModalBodyProps) => (

                <>
                    <p>{getText('users.roleUpperCase')} : {props.original.name}</p>

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={modalProps.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success"
                                onClick={this.onRemoveUserRole.bind(this, props.original.id, modalProps.hide)}>{getText('common.exclude')}</Button>
                    </DefaultFooterModal>
                </>
            ),
            title: getText('users.areYouSureToExcludeUserFromRole'),
            size: 'small'
        })
    };

    getRolesGridDate = () => {
        const {
            roles,
            rolesMap,
            rights
        } = this.props;

        const isEmptyMap = Object.keys(rolesMap).length === 0;
        const canEdit = rights && rights['users'] && rights['users']['edit'];


        const rolesGridOptions: any[] = [
            {
                Header: getText('users.roleUpperCase'),
                accessor: 'name',
            }
        ];

        if (canEdit) {
            rolesGridOptions.push(
                {
                    Header: getText('common.exclude'),
                    accessor: 'exclude',
                    Cell: (props: any) => {
                        return (
                            <Button className="btn-warning"
                                    onClick={this.onRolesModalOpen.bind(this, props)}>{getText('common.exclude')}</Button>
                        )
                    },
                }
            )
        }

        let gridRows: { name: string, id: number }[] = [];

        !isEmptyMap && roles.forEach(roleId => {
            gridRows.push({
                name: this.props.rolesMap[roleId].name,
                id: roleId,
            })
        });

        return {
            data: gridRows,
            columns: rolesGridOptions,
            minRows: 0,
            showPageJump: false,
            showPagination: false,
            className: 'active_roles_list',
            resizable: false,
            label: 'users.includesToRoles',
        };

    };

    onRemoveUserGroup = async (id: number, hide: () => void) => {
        await this.props.removeUserGroup(this.props.id, id);
        hide();
    };

    onGroupsModalOpen = (props: any) => {
        this.props.openModal({
            id: 'usersExcludeGroup',
            component: (modalProps: ModalBodyProps) => (

                <>
                    <p>{getText('users.groupUpperCase')} : {props.original.name}</p>

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={modalProps.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success"
                                onClick={this.onRemoveUserGroup.bind(this, props.original.id, modalProps.hide)}>{getText('common.exclude')}</Button>
                    </DefaultFooterModal>
                </>
            ),
            title: getText('users.areYouSureToExcludeUserFromGroup'),
            size: 'small'

        });
    };


    removeObject = async (objectId: number, hide: () => void) => {
        await this.props.removeUserObject(this.props.id, objectId);
        hide();
    };


    onObjectsModalSave = (props: any) => {
        this.props.openModal({
            id: "excludeUsersObject",
            component: (modalProps: ModalBodyProps) => (
                <>
                    <p>{getText('users.objectName')} : {props.original.description}</p>
                    <p>{getText('users.extendedFrom')} : {props.original.name}</p>

                    <DefaultFooterModal>
                        <Button className="btn-danger" onClick={modalProps.hide}>{getText('common.cancel')}</Button>
                        <Button className="btn-success"
                                onClick={this.removeObject.bind(this, props.original.id, modalProps.hide)}>{getText('common.exclude')}</Button>
                    </DefaultFooterModal>
                </>
            ),
            title: getText("users.areYouSureToExcludeUserObject"),
            size: 'small'
        });

    };

    getRightsGridData = () => {

        const {rights} = this.props;
        const canEdit = rights && rights['users'] && rights['users']['edit'];

        const columns: any[] = [
            {
                Header: getText('users.groupUpperCase'),
                accessor: 'name',
            }
        ];


        if (canEdit) {
            columns.push(
                {
                    Header: getText('common.exclude'),
                    accessor: 'exclude',
                    Cell: (props: any) => {
                        return (
                            <Button className="btn-warning"
                                    onClick={this.onGroupsModalOpen.bind(this, props)}>{getText('common.exclude')}</Button>
                        )
                    },
                }
            )
        }


        let options: { name: string, id: number }[] = [];

        this.props.groups.forEach(groupId => {
            options.push({
                name: this.props.groupsMap[groupId].name,
                id: groupId
            })
        });

        return {
            data: options,
            columns: columns,
            // minRows: 0,
            // showPageJump: false,
            // showPagination: false,
            className: 'active_roles_list',
            label: 'users.includesToGroups',
            resizable: false,
        };
    };

    getContractorList = (q?: string): LoadReturn => {
        return new Promise(async resolve => {
            try {
                const list = await getContractorList({q});

                const result = list?.map(item => {
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                });

                resolve(result)

            } catch (e) {
            }
        });
    };

    changeContractor = async (option: Option, action: ActionTypes) => {

        switch (action) {
            case "select-option":
                await this.props.addUserContractor(this.props.id, option.value);
                break;

            case "remove-value":
                await this.props.removeUserContractor(this.props.id, option.value);
                break;
        }

        const {q, groupId, roleId} = this.props;
        const searchData: api.GetUsers = {
            q
        };
        if (groupId !== -1) {
            searchData.groupId = groupId
        }
        if (roleId !== -1) {
            searchData.roleId = roleId
        }


        await this.props.getUsersList(searchData);


        // return new Promise(async resolve => {
        //     const {q, groupId, roleId, contractor} = this.props;
        //
        //     const searchData: api.GetUsers = {
        //         q
        //     };
        //
        //     if (groupId !== -1) {
        //         searchData.groupId = groupId
        //     }
        //
        //     if (roleId !== -1) {
        //         searchData.roleId = roleId
        //     }
        //
        //     await this.props.updateUser(this.props.id, {
        //         contractorId: option.value
        //     }, searchData);
        //
        //     resolve();
        // })
    };

    changeOrderState = (option: Option) => {
        return new Promise(async resolve => {
            await this.props.updateUser(this.props.id, {
                newOrderStatus: option.value
            });
            resolve()
        });
    };

    changeBasePoint = async (option: Option) => {
        try {
            this.setState({ loading: true })
            await api.updateUserSettings(this.props.id, {basePointId: option.objectId})
        } catch(err) {
            console.log(err);
        } finally {
            this.setState({ loading: false })
        }
    }


    getUniqueRoles = (existsRoles: number[]) => {
        const {rolesMap} = this.props;

        const parents = existsRoles.reduceRight((roles: number[], roleId: number) => {
            return [...roles, ...rolesMap[roleId].parentRoles, roleId]
        }, []);

        return [...new Set(parents)];

    };


    getStatusTransferList = () => {
        const {
            rolesMap,
            roles
        } = this.props;

        this.statusRules = false;


        const transerStatusColumns = [
            {
                Header: getText('users.extendedFrom'),
                accessor: 'name',
            },
            {
                Header: getText('users.allowed'),
                accessor: 'description',
            }
        ];


        const isEmptyMap = Object.keys(rolesMap).length === 0;
        if (!isEmptyMap) {
            let resultData: UserResultStatusTransfer[] = [];
            const uniqueRoles = this.getUniqueRoles(roles);

            uniqueRoles.forEach(roleId => {
                const role = this.props.rolesMap[roleId];
                const name = role.name;
                role.statusesRules && role.statusesRules.forEach(status => {
                    let text = status.type === api.ASSIGN_FROM ? renderToString(getText('users.statuses.assignFrom')) :
                        status.type === api.ASSIGN_TO ? renderToString(getText('users.statuses.assignTo')) :
                            status.type === api.ROLLBACK_TO ? renderToString(getText('users.statuses.rollbackTo')) :
                                status.type === api.ROLLBACK_FROM ? renderToString(getText('users.statuses.rollbackFrom')) : '';

                    resultData.push({
                        name: name,
                        description: text + `"${status.status.name}"`
                    })

                })
            });

            if (resultData.length > 0) {
                this.statusRules = true
            }

            return {
                data: resultData,
                columns: transerStatusColumns,
                showPageJump: false,
                showPagination: false,
                minRows: 0,
                resizable: false,
            };
        }
    };

    get resultsRightsGridOptions() {
        const {
            rolesMap,
            roles
        } = this.props;

        const cell = (props: { value: checkbox }) => {
            const Input = () => <input type='checkbox' disabled checked={props.value.checked}/>;
            if (props.value.isSet) {
                if (props.value.description) {
                    return (
                        <Tooltip title={props.value.description} position={'left'}>
                            <Input/>
                        </Tooltip>
                    )
                } else {
                    return <Input/>
                }
            } else {
                return <></>;
            }
        };

        const resultsRightsGridColumns = [
            {
                Header: getText('users.extendedFrom'),
                accessor: 'name',
            },
            {
                Header: getText('users.objectSecurity'),
                accessor: 'description',
            },
            {
                Header: getText('users.allowed'),
                accessor: 'allowed',
                columns: [
                    {
                        Header: <i className='fa fa-eye grid__column_success'/>,
                        accessor: 'allowed.read',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-plus grid__column_success'/>,
                        accessor: 'allowed.create',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-pencil-alt grid__column_success'/>,
                        accessor: 'allowed.edit',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-trash grid__column_success'/>,
                        accessor: 'allowed.delete',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                ]
            },
            {
                Header: getText('users.forbidden'),
                accessor: 'forbidden',
                columns: [
                    {
                        Header: <i className='fa fa-eye grid__column_deny'/>,
                        accessor: 'forbidden.read',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-plus grid__column_deny'/>,
                        accessor: 'forbidden.create',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-pencil-alt grid__column_deny'/>,
                        accessor: 'forbidden.edit',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                    {
                        Header: <i className='fa fa-trash grid__column_deny'/>,
                        accessor: 'forbidden.delete',
                        Cell: cell,
                        minWidth: TableCell.WIDTH,
                    },
                ]
            },
        ];

        let resultRights: RightsRow[] = [];
        const isEmptyMap = Object.keys(rolesMap).length === 0;

        if (!isEmptyMap) {
            const uniqueRoles = this.getUniqueRoles(roles);

            uniqueRoles.forEach(roleId => {
                const {rights, name} = rolesMap[roleId];
                rights && rights.forEach(right => {
                    let row = makeValidUserRightsStructure(right, name, false);
                    resultRights.push(row);
                });

            })
        }


        return {
            columns: resultsRightsGridColumns,
            data: resultRights,
            stickyHeader: true,
        }
    };

    get objectsGridOptions() {

        const objectsGridColumns = [
            {
                Header: getText('users.extendedFrom'),
                accessor: 'name',
                minWidth: 150
            },
            {
                Header: getText('users.availableObjects'),
                accessor: 'description',
                minWidth: 300
            },
            {
                Header: getText('common.exclude'),
                accessor: 'edit',
                Cell: (props: any) => {
                    return props.original.edit ? <Button className="btn-warning"
                                                         onClick={this.onObjectsModalSave.bind(this, props)}>{getText('common.exclude')}</Button> : null
                },
                minWidth: 100
            }
        ];
        const {groups, objects, groupsMap} = this.props;

        let resultObjectList: ResultObjectList[] = [];

        groups.map(groupId => {
            let group = groupsMap[groupId];

            group.objects.forEach(object => {
                resultObjectList.push({
                    description: object.name ? object.name : this.props.username,
                    id: object.id,
                    name: group.name,
                    edit: false
                })
            });

            if (group.children && group.children.length) {
                const childrenObjects = getChildrenObjects(group.children, groupsMap);
                resultObjectList.push(...childrenObjects);
            }
        });

        objects && objects.map(item => {

            const {person} = this.props;
            const name = `${person.lastName} ${person.firstName} ${person.patronymic}`;

            resultObjectList.push({
                edit: true,
                description: item.address || '',
                name,
                id: item.id
            })
        });

        return {
            columns: objectsGridColumns,
            data: resultObjectList,
            minRows: 0,
            showPagination: true,
        }
    };

    changeObject = (option: Option) => {
        return new Promise(async resolve => {
            await this.props.addUserObject(this.props.id, option.value);
            resolve()
        })
    };

    updateWorkordersNotifications = async (workordersNotifications) => {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateUserSettings(this.props.id, {
                workordersNotifications
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    updateWorkordersHoursNotifications = async (workordersNotificationsHours) => {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateUserSettings(this.props.id, {
                workordersNotificationsHours
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    updateTaskResponsibleNotifications = async (taskResponsibleNotifications) => {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateUserSettings(this.props.id, {
                taskResponsibleNotifications
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    updateReminderPhotoReport = async (reminderPhotoReport) => {
        try {
            this.setState({
                loading: true
            });
            await this.props.updateUserSettings(this.props.id, {
                reminderPhotoReport
            });
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    get canEditSettings(){
        return this.props.rights?.["users"]?.edit &&
            (this.props.rights?.["users.all.settings"]?.edit ||
                (this.props.rights?.["users.subordinates.settings"]?.edit && this.props?.user?.isSubordinate));
    }


    render() {
        const { contractors, newOrderStatus, rights, settings} = this.props;
        console.log('REMINDER: ' + settings?.reminderPhotoReport)

        const rolesGridData = this.getRolesGridDate();
        const rightsGridData = this.getRightsGridData();
        const statusTransferList = this.getStatusTransferList();

        const canEdit = rights && rights['users'] && rights['users']['edit'];


        return (
            <UserWithDrop id={this.props.id}>
                <h2>{this.props.username}</h2>
                <Input
                    label="users.password"
                    blur={this.blurPasswordHandler}
                    // change={this.changePasswordHandler}
                    startValue=""
                    // value={this.state.password}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    disabled={!canEdit}
                />

                <Input
                    label="common.description"
                    blur={this.changeUserDescription}
                    startValue={this.props.description}
                    // trigger="blur"
                    className="form-control"
                    disabled={!canEdit}
                />
                <RenderIf condition={this.props.roles.length > 0}>
                    <Table {...rolesGridData} />
                </RenderIf>

                <RenderIf condition={this.props.groups.length > 0}>
                    <Table {...rightsGridData} />
                </RenderIf>

                <AsyncSelect
                    loadOptions={this.getContractorList}
                    label={{text: 'contractors.contractor'}}
                    defaultValues={{
                        values: contractors,
                        accessors: {
                            name: 'label',
                            id: 'value'
                        }
                    }}
                    isMulti
                    change={this.changeContractor}
                    isDisabled={!canEdit}
                />

                <p className="just-pro-panel_header">{getText('users.operationWithOrders')}</p>

                <Select
                    change={this.changeOrderState}
                    load={getStatusesList}
                    defaultValue={newOrderStatus ? newOrderStatus : {}}
                    accessors={{
                        id: 'value',
                        name: 'label'
                    }}
                    label='users.newOrderStatus'
                    isDisabled={!canEdit}

                />

                <RenderIf condition={this.statusRules}>
                    <Table {...statusTransferList} />
                </RenderIf>

                <RenderIf condition={this.canEditSettings}>
                    <p className="just-pro-panel_header">{getText('users.settings')}</p>
                    <div className="user-settings-wrapper">
                        <RenderIf condition={this.state.loading}>
                            <Spinner loading={this.state.loading}/>
                        </RenderIf>
                        <ToggleSwitch defaultValue={settings?.workordersNotifications}
                                      send={this.updateWorkordersNotifications}
                                      label="users.workordersNotifications"
                        />
                        <Input
                            label="users.workordersNotificationsHours"
                            blur={this.updateWorkordersHoursNotifications}
                            startValue={settings?.workordersNotificationsHours as unknown as string}
                            type="number"
                            disabled={!settings?.workordersNotifications}
                            autoComplete="off"
                            className="form-control"
                        />
                        <ToggleSwitch defaultValue={settings?.taskResponsibleNotifications}
                                      send={this.updateTaskResponsibleNotifications}
                                      label="users.taskResponsibleNotifications"
                        />
                        <ToggleSwitch defaultValue={settings?.reminderPhotoReport}
                                      send={this.updateReminderPhotoReport}
                                      label="users.reminderPhotoReport"
                        />
                        <Select
                            change={this.changeBasePoint}
                            defaultValue={this.person?.basePoints?.find?.(point => point.objectId === settings?.basePointId)}
                            defaultOptions={this.person?.basePoints?.filter?.(point => point.personId)}
                            accessors={{
                                id: 'value',
                                address: 'label'
                            }}
                            label='users.basePoint'
                        />
                    </div>
                </RenderIf>

                <p className="just-pro-panel_header">{getText('users.resultRights')}</p>
                <Tabs tabs={this.tabs}/>
            </UserWithDrop>
        )
    }
}

const mapStateToProps = (state: UsersMapStateToProps) => ({
    rolesMap: state.users.roles.map,
    groupsMap: state.users.groups.map,
    id: state.users.user.id,
    objects: state.users.user.objects,
    groups: state.users.user.groups,
    roles: state.users.user.roles,
    newOrderStatus: state.users.user.newOrderStatus,
    username: state.users.user.username,
    contractors: state.users.user.contractors,
    description: state.users.user.description,
    person: state.users.user.person,
    roleId: state.users.roles.id,
    groupId: state.users.groups.id,
    user: state.users?.user,
    settings: state?.users?.user?.settings,
    rights: state.application.rights
});

const mapDispatchToProps = (dispatch: Function) => ({
    removeUserRole: (userId: number, roleId: number) => dispatch(removeUserRole(userId, roleId)),
    removeUserGroup: (userId: number, groupId: number) => dispatch(removeUserGroup(userId, groupId)),
    removeUserObject: (userId: number, objectId: number) => dispatch(removeUserObject(userId, objectId)),
    addUserObject: (userId: number, objectId: number) => dispatch(addUserObject(userId, objectId)),
    updateUser: (userID: number, data: api.UpdateUser, searchData?: api.GetUsers) => dispatch(updateUser(userID, data, searchData)),
    updateUserSettings: (userId: number, data: api.UserSettings) => dispatch(updateUserSettings(userId, data)),
    addUserContractor: (userId: number, contractorId: number) => dispatch(addUserContractor(userId, contractorId)),
    removeUserContractor: (userId: number, contractorId: number) => dispatch(removeUserContractor(userId, contractorId)),
    getUsersList: (filters: api.GetUsers) => dispatch(getUsers(filters)),
    getUser: (userId: number) => dispatch(getUser(userId)),
    openModal: (props: Modal) => dispatch(openModal(props)),
    updateModal: (id: string, props: Partial<Modal>) => dispatch(updateModal(id, props)),
    hideModal: (id: string) => dispatch(hideModal(id)),

});


export default connect(mapStateToProps, mapDispatchToProps)(User);