import React from 'react';
import { Props, State, body, Dates } from './calendar.types';
import moment, { DurationInputArg2, min, Moment } from 'moment';
import {
  Caret,
  CaretContent,
  Day,
  Header,
  Month,
  MonthContainer,
  Th,
  TimeInput,
  TimeOuter,
  TimeTitle,
  TimeWrapper,
  Title,
  Tr,
} from './datepicker.view';
import './calendar.css';
import { format } from '@justpro/terminal';
import RenderIf from '../../../utils/renderIf';
import Button from '../button/button';

export default class Calendar extends React.Component<Props, State> {
  get format() {
    if (this.props.mode === 'monthSelect') return 'MMM YYYY';
    if (this.props.timePicker) return 'DD.MM.YYYY HH:mm';
    return 'DD.MM.YYYY';
  }

  state: State = {
    isOpen: false,
    inputValue: moment().format(this.format),
    body: 'month',
    hours: '00',
    minutes: '00',
    calendarDate: moment(),
  };

  protected map: body[] = ['month', 'year', 'decade'];

  goToNow = () => {
    this.props.onChange(moment());
  };

  protected go = (amount: number, unit: DurationInputArg2) => {
    this.setState((prevState) => ({
      calendarDate: prevState.calendarDate.add(amount, unit),
    }));
  };

  protected setDate = (e: React.MouseEvent) => {
    const value = e.currentTarget.getAttribute('data-date');

    if (value) {
      this.setState(() => ({
        inputValue: moment(value).format(this.format),
      }));

      // this.set(value)
    }
  };

  protected setCalendarDate = (e: React.MouseEvent) => {
    const value = e.currentTarget.getAttribute('data-date') as string;
    let index = this.map.indexOf(this.state.body);
    let panel = this.map[index - 1];
    const calendarDate = moment(value);
    const determineBody = () => {
      if (this.state.body === 'month') {
        return 'month';
      }
      if (this.props.mode === 'monthSelect') {
        return 'year';
      }
      return panel;
    };
    const isOpen = () => {
      if (this.state.body === 'month' || this.props.mode === 'monthSelect') {
        return false;
      }
      return this.state.isOpen;
    };
    if (value) {
      this.setState({
        calendarDate,
        hours: calendarDate.format('HH'),
        minutes: calendarDate.format('mm'),
        body: determineBody(),
        isOpen: isOpen(),
      });
    }
    if (this.props.mode === 'monthSelect') {
      this.dateClickHandler(calendarDate.format(format.date));
    }
  };

  protected setBody = () => {
    let index = this.map.indexOf(this.state.body);
    if (this.map[index + 1]) {
      this.setState({
        body: this.map[index + 1],
      });
    }
  };

  protected toggle = () => {
    if (!this.props.isDisabled) {
      this.setState((state) => ({
        isOpen: !state.isOpen,
      }));
    }
  };

  protected handlerHelperHide = () => {
    this.setState({
      isOpen: false,
    });
  };

  protected validateInputDate = (val: string) => {
    const isValid = (
      arr: string[],
      dateIndex: number,
      monthIndex: number,
      yearIndex: number = 2
    ) => {
      return arr
        .map((date: string, index) => {
          let res = parseInt(date);
          if (index === dateIndex && !isNaN(res)) {
            // date
            if (res <= 31 && res > 0) {
              return res;
            }
          }

          if (index === monthIndex && !isNaN(res)) {
            // month

            if (res <= 12 && res > 0) {
              return res - 1;
            }
          }

          if (index === yearIndex && !isNaN(res)) {
            // year
            if (res >= 1900 && res <= 2200) {
              return res;
            }
          }
        })
        .filter((x): x is number => x !== undefined);
    };

    const space = val.split(' '),
      spaceValues = isValid(space, 0, 1);
    const point = val.split('.'),
      pointValues = isValid(point, 0, 1);
    const slash = val.split('/'),
      slashValues = isValid(slash, 1, 0);

    // const date = new Date();
    const date = moment();
    let validDate: Moment;
    let month: number, day: number, year: number;

    if (val.length === 8) {
      day = parseInt(val.slice(0, 2));
      month = parseInt(val.slice(2, 4)) - 1;
      year = parseInt(val.slice(4, 8));
    } else if (spaceValues.length === 3) {
      month = spaceValues[1];
      day = spaceValues[0];
      year = spaceValues[2];
    } else if (slashValues.length === 3) {
      month = slashValues[0];
      day = slashValues[1];
      year = slashValues[2];
    } else {
      //point
      month = pointValues[1];
      day = pointValues[0];
      year = pointValues[2];
    }

    date.set({
      year,
      month,
      date: day,
    });

    validDate = moment(date);

    this.props.onChange(validDate);

    this.setState(() => ({
      inputValue: validDate.format(this.format),
    }));
  };

  protected onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState(() => ({
      inputValue: value,
    }));
  };

  protected onInputEnter = (val: string) => {
    if (val) {
      this.validateInputDate(val);
    }
  };

  protected onInputBlur = (val: string, oldValue: string) => {
    if (val.trim() !== '') {
      this.validateInputDate(val);
    }
  };

  protected getYearsArray(): Dates[] {
    let result: Dates[] = [];
    // const {date} = this.props;
    const { calendarDate } = this.state;

    const startDecade = calendarDate.clone().subtract(5, 'year');
    const endDecade = calendarDate.clone().subtract(-6, 'year');

    const start = startDecade.clone().subtract(1, 'year');

    while (start.isBefore(endDecade, 'year')) {
      result.push(
        Array(3)
          .fill(0)
          .map(() => {
            const value = start.add(1, 'year').clone();
            const selected = value.isSame(this.props.date, 'year');

            return {
              value,
              selected,
            };
          })
      );
    }
    return result;
  }

  protected getMonthArray(): Dates[] {
    let result: Dates[] = [];
    const { calendarDate } = this.state;
    const { date } = this.props;

    const startMonth = calendarDate.clone().startOf('year').startOf('month');
    const endMonth = calendarDate.clone().endOf('year').endOf('month');

    const start = startMonth.clone().subtract(1, 'month');

    while (start.isBefore(endMonth, 'month')) {
      result.push(
        Array(3)
          .fill(0)
          .map(() => {
            const value = start.add(1, 'month').clone();
            const selected = value.isSame(date, 'month');
            return {
              value,
              selected,
            };
          })
      );
    }
    return result;
  }

  protected getWeekArray(): Dates[] {
    let result: Dates[] = [];
    const { calendarDate } = this.state;
    const today = moment();

    const startDay = calendarDate.clone().startOf('month').startOf('week');
    const endDay = calendarDate.clone().endOf('month').endOf('week');

    const start = startDay.clone().subtract(1, 'day');

    while (start.isBefore(endDay)) {
      result.push(
        Array(7)
          .fill(0)
          .map(() => {
            const value = start.add(1, 'day').clone();
            // active - сегодня
            const active = value.isSame(today.format(format.date));
            let selected = value.isSame(this.props?.date?.format(format?.date));
            let disabled: boolean = false;

            if (this.props.disabledTo) {
              disabled = this.props.disabledTo.isAfter(value, 'day');
            }

            if (this.props.disabledFrom) {
              disabled = this.props.disabledFrom.isBefore(value, 'day');
            }

            if (this.props.disabled) {
              disabled = this.props.disabled.includes(
                start.format('DD MM YYYY')
              );
            }

            const inactive = !start.isSame(calendarDate, 'month');

            return {
              value,
              selected,
              inactive,
              disabled,
              active,
            };
          })
      );
    }

    return result;
  }

  dateClickHandler = (value: string) => {
    if (!this.props.timePicker) {
      this.setState(() => ({
        isOpen: false,
      }));
    }
    const calendarDate = moment(value);
    if (this.props.timePicker) {
      calendarDate.set('hours', +this.state.hours);
      calendarDate.set('minutes', +this.state.minutes);
    }
    this.setState(
      {
        calendarDate,
      },
      () => {
        this.props.onChange(this.state.calendarDate);
      }
    );
  };

  acceptButtonHandler = () => {
    this.setState(() => ({
      isOpen: false,
    }));
    this.props.onChange(this.state.calendarDate);
  };

  setCalendarHours = (e: any) => {
    const hours = this.state.hours;
    this.setState((prevState: State) => ({
      calendarDate: prevState.calendarDate.set('hours', +hours),
    }));
  };

  get hours() {
    return this.state.calendarDate
      ? this.state.calendarDate.format('HH')
      : '00';
  }

  setCalendarMinutes = () => {
    const minutes = this.state.minutes;
    this.setState((prevState: State) => ({
      calendarDate: prevState.calendarDate.set('minutes', +minutes),
    }));
  };

  setMinutes = (e: any) => {
    let minutes = e.target.value.replace(/\D/g, '');
    if (minutes.length > 2) return false;
    if (+minutes > 59) {
      minutes = '00';
    }
    if (+minutes < 0) {
      minutes = '59';
    }
    this.setState({
      minutes,
    });
  };

  setHours = (e: any) => {
    let hours = e.target.value.replace(/\D/g, '');
    if (hours.length > 2) return false;
    if (+hours > 23) {
      hours = '00';
    }
    if (+hours < 0) {
      hours = '23';
    }
    this.setState({
      hours,
    });
  };

  get minutes() {
    return this.state.calendarDate
      ? this.state.calendarDate.format('mm')
      : '00';
  }

  getMonth = () => {
    const week = this.getWeekArray();
    return (
      <>
        <RenderIf condition={this.props.timePicker}>
          <TimeOuter>
            <TimeTitle>Выбрать время</TimeTitle>
            <TimeWrapper>
              <TimeInput
                value={this.state.hours}
                type="number"
                onChange={this.setHours}
                onBlur={this.setCalendarHours}
              />
              :
              <TimeInput
                value={this.state.minutes}
                type="number"
                onChange={this.setMinutes}
                onBlur={this.setCalendarMinutes}
              />
            </TimeWrapper>
          </TimeOuter>
        </RenderIf>
        <Tr>
          <Th>Пн</Th>
          <Th>Вт</Th>
          <Th>Ср</Th>
          <Th>Чт</Th>
          <Th>Пт</Th>
          <Th>Сб</Th>
          <Th>Вс</Th>
        </Tr>
        {week.map((week, index) => {
          return (
            <Tr key={'week-' + index}>
              {week.map((day, index) => {
                const { active, selected, inactive, value, disabled } = day;

                return (
                  <Day
                    key={'day-' + index}
                    // data-date={value.clone().locale('en').format(format.date)}
                    active={active}
                    selected={selected}
                    inactive={inactive}
                    disabled={disabled}
                    onClick={
                      disabled
                        ? () => null
                        : this.dateClickHandler.bind(
                            this,
                            value.format(format.date)
                          )
                    }
                  >
                    {day.value.format('D')}
                  </Day>
                );
              })}
            </Tr>
          );
        })}
        <RenderIf
          condition={this.props.timePicker && !this.props.hideApplyButton}
        >
          <Button onClick={this.acceptButtonHandler}>Применить</Button>
        </RenderIf>
      </>
    );
  };

  getYear = () => {
    const month = this.getMonthArray();
    return (
      <MonthContainer>
        {month.map((square, index) => {
          return (
            <Tr key={'square-' + index}>
              {square.map((month, index) => {
                return (
                  <Month
                    selected={month.selected}
                    data-date={month.value
                      .clone()
                      .locale('en')
                      .format(format.date)}
                    onClick={this.setCalendarDate}
                    key={'month-' + index}
                  >
                    {month.value.format('MMM')}
                  </Month>
                );
              })}
            </Tr>
          );
        })}
      </MonthContainer>
    );
  };
  getDecade = () => {
    const years = this.getYearsArray();

    return (
      <MonthContainer>
        {years.map((years, index) => {
          return (
            <Tr key={'years-' + index}>
              {years.map((year, index) => {
                return (
                  <Month
                    selected={year.selected}
                    data-date={year.value
                      .clone()
                      .locale('en')
                      .format(format.date)}
                    onClick={this.setCalendarDate}
                    key={'year-' + index}
                  >
                    {year.value.format('YYYY')}
                  </Month>
                );
              })}
            </Tr>
          );
        })}
      </MonthContainer>
    );
  };

  private setDatesToDefault = () => {
    const now = moment();
    this.setState({
      body: this.props.mode === 'monthSelect' ? 'year' : 'month',
      inputValue:
        this?.props?.date instanceof moment
          ? this.props.date.format(this.format)
          : '',
      calendarDate:
        this?.props?.date instanceof moment ? this?.props?.date?.clone() : now,
      hours:
        this?.props?.date instanceof moment
          ? this.props.date.format('HH')
          : now.format('HH'),
      minutes:
        this?.props?.date instanceof moment
          ? this.props.date.format('mm')
          : now.format('mm'),
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      (prevProps.date instanceof moment &&
        prevProps.date.isSame(this.props.date)) ||
      prevProps.date === this.props.date
    ) {
      return;
    }
    return this.setDatesToDefault();
  }

  componentDidMount(): void {
    this.setDatesToDefault();
  }

  getBodyTable = () => {
    switch (this.state.body) {
      case 'decade':
        return this.getDecade();
      case 'year':
        return this.getYear();
      default:
        return this.getMonth();
    }
  };

  getHeaderTable = () => {
    let format, unit: DurationInputArg2, prevAmount: number, nextAmount: number;
    const { calendarDate } = this.state;

    switch (this.state.body) {
      case 'year':
        format = calendarDate.format('YYYY');
        unit = 'year';
        prevAmount = 1;
        nextAmount = -1;
        break;
      case 'decade':
        format =
          calendarDate.clone().subtract(5, 'y').format('YYYY') +
          '-' +
          calendarDate.clone().subtract(-6, 'y').format('YYYY');
        prevAmount = 12;
        nextAmount = -12;
        unit = 'year';
        break;
      default:
        //month
        unit = 'month';
        prevAmount = 1;
        nextAmount = -1;
        format = calendarDate.format('MMMM YYYY');
    }

    return (
      <Header>
        <Caret onClick={this.go.bind(this, nextAmount, unit)}>
          <CaretContent>&#60;</CaretContent>
        </Caret>
        <Title onClick={this.setBody}>{format}</Title>
        <Caret onClick={this.go.bind(this, prevAmount, unit)}>
          <CaretContent>&#62;</CaretContent>
        </Caret>
      </Header>
    );
  };
}
