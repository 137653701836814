import React from "react";
import './header.css';
import HeaderNotifications from "./headerNotifications.controller";
import PersonName from "./personName/personName";
import SavedFilter, {SavedFiltersProps} from "../UI/savedFilter/savedFilter.controller";


interface Props {
    children : React.ReactNode
    backgroundColor?: string
    savedFilters? : SavedFiltersProps
}

class HeaderController extends React.Component<Props>{

    render () {

        const {backgroundColor, savedFilters} = this.props;

        return (
            <div className="top-line" style={{backgroundColor : backgroundColor ? backgroundColor : undefined}}>
                {this.props.children}


                <PersonName />
                <HeaderNotifications />

                {savedFilters &&
                    <div className="navbar-form navbar-right">
                        <SavedFilter {...savedFilters}/>
                    </div>
                }
                <div id="toolbar"/>
            </div>
        )
    }
}

export default HeaderController