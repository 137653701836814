import * as api from '@justpro/terminal';
import {applicationActionTypes} from './application'
import checkError from "../../utils/checkError";
import {AvailableLocales} from "./application.types";

export const getUserMe = () => async (dispatch:Function) => {
    try {
        const {rights, ...me} = await api.getUserMe();
        dispatch({
            type: applicationActionTypes.GET_USER_ME,
            rights,
            me
        })
    } catch(err){
        checkError(err);
    }
};

export const getNotificationsNew = () => async (dispatch:Function) => {
    try {
        const {comments, notifications, files} = await api.getNotificationsNew();
        dispatch({
            type: applicationActionTypes.GET_NOTIFICATIONS,
            comments,
            notifications,
            files
        })
    } catch(err){
        checkError(err);
    }
};

export const getSettings = () => async (dispatch:Function) => {
    try {
        const settings = await api.getSettings();

        dispatch({
            type: applicationActionTypes.GET_SETTINGS,
            settings
        })
    } catch(err){
        checkError(err);
    }
};

export const resetUserRights = () => (dispatch:Function) => {
    dispatch({
        type : applicationActionTypes.RESET_USER_RIGHTS
    })
};


export const changeLocale = (locale:AvailableLocales) => (dispatch:Function) => {
    dispatch({
        type : applicationActionTypes.CHANGE_LOCALE,
        locale,
    })
}
