import React from 'react';
import Input from "../../UI/input/text";
import Table from "../../UI/table/table";
import {Column} from 'react-table'
import Button from "../../UI/button/button";
import Select from "../../UI/select/select";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {
    getStatusGroups,
    ModulesResponse,
    OrderStatus,
    StatusGroup,
    updateStatus,
    UpdateStatusGroup
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import withLabel from "../../UI/withLabel/withLabel";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

type Props = {
    group : StatusGroup,
    update(item:Partial<UpdateStatusGroup>) : void

    rights?: Partial<ModulesResponse>
};

interface State {
    statusTableSpinner : boolean
    group?: StatusGroup
    statuses : OrderStatus[],
}

class DetailStatusGroup extends React.Component<Props, State> {

    state:State = {
        statusTableSpinner: false,
        statuses : [],
    };


    onBlurName = (name: string, oldValue: string) => {
        if(name !== oldValue) {
            //todo переиновать эту группу
            this.props.update({name})
        }
    };

    removeStatus = async (props:any) => {
        const status = props.original;
        await this.changeStatusGroup(status.id, 0);
    };


    changeGroup = (option:Option, props:any) => {
        return new Promise(async resolve => {
            const status  = props.original;
            await this.changeStatusGroup(status.id, option.value);
            resolve();
        })
    };

    getGroups = ():LoadReturn => {
        return new Promise(async resolve => {
            try {
                const groups = await getStatusGroups();
                const data = groups.filter(item => item.id != this.state.group?.id && item.id !== 0);
                resolve(data)
            }catch (e) {
                checkError(e);
                resolve()
            }

        })
    };

    getStatusesColumns = ():Column[] => {
        const {rights} = this.props;
        const canRemove = rights && rights['references.statusGroups'] && rights['references.statusGroups']['edit']

        const data = [
            {
                Header : getText('common.name'),
                accessor : 'name'
            },
            {
                Header : getText('statusGroups.transfer'),
                Cell : (props:any) => {
                    return (
                        <>
                            <Select
                                change={ (option) => this.changeGroup(option, props)}
                                load={this.getGroups}
                                accessors={{
                                    id : 'value',
                                    name : 'label'
                                }}
                                defaultValue={undefined}
                                placeholder="statusGroups.transferStatus"
                            />
                        </>)
                }
            },
        ];

        if(this.state.group?.id !== 0 && canRemove) {
            data.push({
                Header : getText('statusGroups.removeStatusFromGroup'),
                Cell : (props:any) => <Button className="btn-success" onClick={this.removeStatus.bind(this, props)}><i className="fa fa-minus no-text" /></Button>
            })
        }

        return data
    };

    toggleGroupActive = (active:boolean) => {
        this.props.update({active});
    };

    changeStatusGroup = async (statusId:number, groupId:number) => {
        this.setState(() => ({statusTableSpinner : true}));

        try {
            const status = await updateStatus(statusId, {groupId});

            if(status) {
                this.setState((prevState) => ({
                    statuses : prevState.statuses.filter(i => i.id !== statusId)
                }))
            }
        }catch (e) {
            checkError(e);
            this.setState(() => ({statusTableSpinner : false}));
        }

        this.setState(() => ({statusTableSpinner : false}));
    };


    resetState = () => {
        this.setState({
            group : this.props.group,
            statuses: this.props.group.statuses
        })
    };

    componentDidMount(): void {
        this.resetState();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.group !== this.props.group) {
            this.resetState();
        }
    }

    render () {
        const {rights} = this.props;
        const {group, statuses} = this.state;
        const disabled = rights && rights['references.statusGroups'] && !rights['references.statusGroups']['edit'];
        return (
            <>
                <h3>{getText('statusGroups.statusGroup')}: {group && group.name}</h3>

                <ToggleSwitch
                    defaultValue={group?.active}
                    label='common.active'
                    send={this.toggleGroupActive}
                    disabled={disabled}
                />

                <Input
                    label="statusGroups.statusGroup"
                    blur={this.onBlurName}
                    startValue={group?.name}
                    disabled={disabled}
                />

                {group && group?.statuses?.length > 0 ? <Table
                    label="statusGroups.statuses"
                    columns={this.getStatusesColumns()}
                    data={statuses}
                    showPagination={false}
                    minRows={0}
                    loading={this.state.statusTableSpinner}
                /> : withLabel(<>{getText('statusGroups.groupHasNoStatuses')}</>)({text : 'statusGroups.statusGroups'})}
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(DetailStatusGroup)