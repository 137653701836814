import React from 'react';
import {Equipment, EquipmentCategory, getPersons, getRegularWorks, Object as IObject} from "@justpro/terminal";
import {
    getContractorList,
    getObjectsList,
    getPersonsAsync,
    getPersonsList,
    getRegionsList
} from "../../../../../utils/functions";
import Table from "../../../table/table";
import InlineCalendar from "../../../calendar/views/inline.controller";
import Select from "../../../select/select";
import MultiSelect from "../../../select/multiSelect";
import {AcceptedOption, ChangeMultiProps, Option} from "../../../select/select.types";
import {Moment} from "moment";
import checkError from "../../../../../utils/checkError";
import {getFullName, getObjectName} from "../../../../../utils/names";
import getText from "../../../../../localization/getText";
import AsyncSelect from "../../../select/asyncSelect_v2";
import {ActionTypes} from "react-select";
import {connect} from "react-redux";


export interface ChecklistEquipmentCategory {
    equipmentCategory: EquipmentCategory,
    date?: Moment
    executor?: Option
    coworkers?: Option[]
}

export interface ChecklistObject {
    object: IObject
    checked: boolean,
    equipmentCategories: ChecklistEquipmentCategory[]
}

interface Props {
    onChangeObject(object: ChecklistObject): void

    selectedObjects: ChecklistObject[],

    changeCoExecutors(option:Option, action?:ActionTypes) : Promise<any>

    changeExecutor(option: Option): Promise<any>

    changeDate(date: Moment): void

    coworkers : AcceptedOption[]

    date: Moment
    settings: any
}

interface State {
    objects: ChecklistObject[],
    objectsLoading: boolean,
    contractor?: Option
    region?: Option
}

const mapState = (state) => {
    return {
        settings: state.application.settings
    }
}

export default connect(mapState)(class ChecklistFirstStep extends React.Component<Props, State> {

    state: State = {
        objects: [],
        objectsLoading: false
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const {date} = this.props;
        const {contractor, region} = this.state;

        if(!(region && contractor)) return;

        if (JSON.stringify(date) === JSON.stringify(prevProps.date) &&
            JSON.stringify(contractor) === JSON.stringify(prevState.contractor) &&
            JSON.stringify(region) === JSON.stringify(prevState.region)) {
            return;
        }
        return this.createChecklist();
    }

    createChecklist = async () => {
        const {date} = this.props;
        const {contractor, region} = this.state;
        if (!contractor?.value || !date) {
            return false;
        }
        this.setState({
            objectsLoading: true
        });
        try {
            const {list} = await getRegularWorks({
                limit: 100000,
                canWorkorder: "1",
                contractorsId: [contractor?.value],
                dateTo: date.format("YYYY-MM-DD"),
                controlDateFrom: date.format("YYYY-MM-DD"),
                regionsId : [region?.value],
            });
            const filteredObjects = list.reduce((acc: ChecklistObject[], item) => {
                const categories = item.equipmentWork.equipmentTypes.reduce((acc: ChecklistEquipmentCategory[], eqt) => {
                    const exists = acc.find(equipmentCategory => equipmentCategory.equipmentCategory.id === eqt.equipmentCategory.id);

                    if (!exists) {
                        return [...acc, {
                            equipmentCategory: eqt.equipmentCategory,
                        }]
                    }

                    return acc;
                }, []);

                const exists = acc.find(checklistObject => checklistObject.object.id === item.equipment.object?.id);

                if (exists) {
                    const filtered = acc.filter(item => item.object.id !== exists.object.id);

                    return [...filtered, {
                        ...exists,
                        equipmentCategories: [...exists.equipmentCategories, ...categories]
                    }]
                }

                return [...acc, {
                    object: item.equipment.object as IObject,
                    checked: false,
                    equipmentCategories: categories,
                }]
            }, []);
            this.setState(() => ({
                objects: filteredObjects,
                objectsLoading: false
            }));

        } catch (e) {
            checkError(e)
        }
    };


    getPersonsList = async (q:string) => {
        const personsList = await getPersons({
            q,
            executorsId: [this.props.settings.tfmContractorId, this.props.settings.tfmShidContractorId]
        });
        const persons:Option[] = personsList.map(p => {
            return {
                ...p,
                label : getFullName(p),
                value : p.id
            }
        });
        return persons;
    };

    changeContractor = async (option: Option) => {
        this.setState({
            contractor: option
        });
    };

    changeRegion = async (option: Option) => {
        this.setState({
            region: option
        });
    };

    render() {

        const {contractor, region} = this.state;


        console.log({c : this.props.coworkers})

        return (
            <>
                <InlineCalendar date={this.props.date} onChange={this.props.changeDate} label={{text : "common.date"}}/>

                {/*<Select*/}
                {/*    change={this.props.changeExecutor}*/}
                {/*    load={getPersonsList}*/}
                {/*    label="common.executor"*/}
                {/*    placeholder="UI.select.placeholders.selectExecutor"*/}
                {/*    accessors={{*/}
                {/*        name: 'label',*/}
                {/*        id: 'value'*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<MultiSelect*/}
                {/*    change={this.props.changeCoExecutors}*/}
                {/*    load={getPersonsList}*/}
                {/*    label="common.coworkers"*/}
                {/*    placeholder="UI.select.placeholders.selectCoworkers"*/}
                {/*    accessors={{*/}
                {/*        name: 'label',*/}
                {/*        id: 'value'*/}
                {/*    }}*/}
                {/*/>*/}

                <AsyncSelect
                    change={this.props.changeExecutor}
                    loadOptions={this.getPersonsList}
                    label={{text : "common.executor"}}
                    placeholder="UI.select.placeholders.selectExecutor"
                />

                <AsyncSelect
                    change={this.props.changeCoExecutors}
                    loadOptions={this.getPersonsList}
                    values={this.props.coworkers}
                    isMulti
                    label={{text : "common.coworkers"}}
                    placeholder="UI.select.placeholders.selectCoworkers"
                />

                <Select
                    load={getContractorList}
                    change={this.changeContractor}
                    label="contractors.contractor"
                    accessors={{name: 'label', id: 'value'}}
                    isDisabled={!this.props.date}
                />

                <Select
                    load={getRegionsList}
                    change={this.changeRegion}
                    label="regions.region"
                    accessors={{name: 'label', id: 'value'}}
                    isDisabled={!this.props.date}
                />

                {contractor && region &&
                <Table
                    columns={[
                        {
                            Cell: (props: any) => {
                                const checked = this.props.selectedObjects.find((item) => item.object.id === props.original.object.id);

                                return <input
                                    type="checkbox"
                                    onChange={this.props.onChangeObject.bind(this, props.original)}
                                    checked={!!checked}
                                    // style={{
                                    //     background : checked ? '#B8B8B8' : undefined
                                    // }}
                                />
                            },
                            getProps : (props:any, {original}:any ) => {
                                const checked = this.props.selectedObjects.find((item) => item.object.id === original.object.id);
                                console.log({checked})

                                return {
                                    style : {
                                        backgroundColor : checked ? '#EBEBEB' : undefined,
                                    }
                                }
                            },
                            width: 30
                        },
                        {
                            Header: getText('objects.object'),
                            Cell: (props: any) => {
                                const {object} = props.original;
                                return getObjectName(object);
                            },
                            getProps : (props:any, {original}:any ) => {
                                const checked = this.props.selectedObjects.find((item) => item.object.id === original.object.id);
                                console.log({checked})

                                return {
                                    style : {
                                        backgroundColor : checked ? '#EBEBEB' : undefined,
                                        color : checked ? 'rgb(150,150,150)' : undefined
                                    }
                                }
                            }
                        }
                    ]}
                    data={this.state.objects}
                    minRows={this.state.objects.length}
                    showPagination={false}
                    loading={this.state.objectsLoading}

                />
                }
            </>

        )
    }
})