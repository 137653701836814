import React from 'react';
import {Country, editCountry, getCountry} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import CountryDetail from "./country.detail";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";

interface Props {
    id: number
    afterUpdate?(item:Country) : void
}

interface State {
    loading : boolean
    country? : Country
}

class CountryController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateCountry = async (data:Partial<Country>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const country = await editCountry(this.props.id, data);

            afterUpdate && afterUpdate(country);
            this.setState(() => ({loading : false, country}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getCountry = async () => {
        this.setState(() => ({loading : true}));

        try {
            const country = await getCountry(this.props.id);


            this.setState(() => ({loading : false, country}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getCountry();
        }
    }

    async componentDidMount(){
        this.getCountry();
    }

    render() {
        const {country} = this.state;
        return (
            <div className="just-pro__scroll_panel">
                <RenderIf condition={country !== undefined}>
                    <CountryDetail
                        country={country as Country}
                        update={this.updateCountry} />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </div>
        )
    }

}


export default CountryController