import React from "react";
import Table from "../../../table/table";
import '../createOrderModal.view.css'
import {Contract, ContractObject} from '@justpro/terminal'
import {getObjectName} from "../../../../../utils/names";
import getText from "../../../../../localization/getText";

interface Props {
    changeObject:(object:ContractObject) => void
    contracts : Contract[]
    contractorId?:number
    regionId?:number
}

export default class ObjectsModalDetail extends React.Component<Props>{

    choose = (object:ContractObject) => {
        this.props.changeObject(object)
    };

    render() {
        const {contractorId, regionId} = this.props;
        return(
            <>
                <Table
                    columns={[
                        {
                            Header: getText('objects.object'),
                            // accessor: 'name',
                            minWidth: 150,
                            Cell:(props:any) => (
                                <span className="just-prop_create-order-list-item" onClick={this.choose.bind(this, props.original)}>
                                    {getObjectName(props.original)}
                                </span>
                            )
                        },
                    ]}
                    data={this.props.contracts?.reduce((acc:ContractObject[], item:Contract) => {

                        if(contractorId && regionId) {
                            if(item.contractor.id === contractorId && item.objects) {
                                item.objects.forEach(o => {

                                    if(o?.square?.region?.id === regionId && o.active) {
                                        acc.push(o)
                                    }
                                });
                            }
                        }


                        return acc;

                    } , [])}
                    minRows={0}
                    pagination={false}
                    showPagination={false}

                />
            </>
        )
    }

}