import React from 'react';
import {connect} from "react-redux";
import Card from "../../UI/itemsList/card/card.controller";
import {UsersMapStateToProps} from '../users.types'
import getPersonPhoto from "../../../utils/getPersonPhoto";
import {User} from "@justpro/terminal";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";

type Props = {
    onClick : React.MouseEvent

    users? : User[],
    active?: number,
    [key:string] : any
}

type UserItemProps = {
    user: User
    index?: number
    active?: number
    [field: string]: any
}
export const UserItem = (props: UserItemProps) => {
    const {active, index, onClick = () => {}, ...rest} = props;
    const {
        id,
        contractors,
        description,
        person,
        username,
    } = props.user;
    const fullName = `${person.firstName} ${person.lastName} ${person.patronymic ? person.patronymic : ''}`;

    return (
        <Card
            key={index}
            onClick={onClick}
            listActiveItem={active}
            isActiveReference={true}
            data-id={id}
            {...rest}
        >
                            <span className="pull-left card__image">
                                <img className="media-object " src={getPersonPhoto(person.avatar)} alt={renderToString(getText('common.photo'))} />
                            </span>
            <div className="media-body">
                <h4 className="media-heading">{username}</h4>
                <p className="user-card__name">{fullName}</p>
                {description ? <p className="user-card__description">{description}</p> : null}
                {contractors?.map(contractor => {
                    return <p className="user-card__contragent">{contractor.name}</p>
                })}
            </div>
        </Card>
    )
}

class UsersList extends React.Component<Props> {

    render () {
        const {users} = this.props;

        return (
            <React.Fragment>
                { users?.map( ( user, index:number ) => <UserItem user={user} {...this.props} />)}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state:UsersMapStateToProps) => ({
    active : state.users.user.id,
    users : state.users.list.users,
});

export default connect(mapStateToProps)(UsersList)