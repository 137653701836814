import React from "react";
import Input from "../../UI/input/text";
import {ContractCondition, ModulesResponse} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import IconPicker from "../../UI/iconPiker/iconPicker.controller";
import {fromClassName, toClassName} from "../../../utils/icons";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    contractCondition : ContractCondition
    updateContractCondition(data:Partial<ContractCondition>):void
    icons: string[]

    rights?: Partial<ModulesResponse>
}

class ContractConditionDetail extends React.Component<Props> {

    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.updateContractCondition({name})
        }
    };

    onChangeActive = (active: boolean) => {
        this.props.updateContractCondition({active});
    };

    onChangeItem = async (icon:string) => {
        const [prefix, name] = fromClassName(icon);

        this.props.updateContractCondition({icon: `${prefix}:${name}`})
    };

    render () {
        const {rights, contractCondition} = this.props
        const {name, active, icon} = contractCondition;

        const disabled = rights && rights['references.contractConditions'] && !rights['references.contractConditions']['edit'];

        return (
            <>
                <h3>{getText('contractConditions.contractCondition')}: {name}</h3>

                <ToggleSwitch send={this.onChangeActive} defaultValue={active} label="common.active" disabled={disabled}/>

                <Input
                    label="contractConditions.contractCondition"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <IconPicker
                    change={this.onChangeItem}
                    label="common.icon"
                    defaultValue={icon ? toClassName(icon) : ''}
                    list={this.props.icons}
                    disabled={disabled}

                />
            </>
        )
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(ContractConditionDetail)