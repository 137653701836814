import React from 'react'
import Button from '../../UI/button/button'
import Table from '../../UI/table/table'
import Select from '../../UI/select/select'
import { LoadReturn, Option } from '../../UI/select/select.types'
import { connect } from 'react-redux'
import { Props, State, RightsRow, TransferOrders } from './roles.types'
import RenderIf from '../../../utils/renderIf'
import {
	ChangeRoleRights,
	ChangeRole,
	IStatusRuleType,
	ASSIGN_TO,
	ASSIGN_FROM,
	ROLLBACK_TO,
	ROLLBACK_FROM,
	changeRoleRights,
	deleteRoleRight,
	getObjects,
	getGroups,
	addRoleObject,
	deleteRoleObject,
} from '@justpro/terminal'
import {
	AddObjectSecurity,
	addObjectSecurityItem,
	changeRoleRight,
	updateRole,
	addStatusRule,
	removeRoleStatusRule,
} from '../../../store/users/actions/role/role.actions'
import getRightsRow from '../../../utils/makeValidUserRightsStructure'
import EditableText, {
	EditableResponse,
} from '../../UI/editableText/editableText'
import Tooltip from '../../UI/tooltip/tooltip.controller'
import checkError from '../../../utils/checkError'
import { UsersMapStateToProps } from '../users.types'
import {
	// getObjectsList,
	getStatusesList,
	getStatusesRules,
	getObjectSecurityList,
} from '../../../utils/functions'
import { toast } from 'react-toastify'
import moment from 'moment'
import getText from '../../../localization/getText'
import { renderToString } from 'react-dom/server'
import { openModal } from '../../../store/modal/modal.actions'
import { Modal } from '../../../store/modal/modal.types'
import DeleteCell from '../../UI/table/deleteCell'
import AsyncSelect from '../../UI/select/asyncSelect_v2'
import { groupObjectsByRegion } from '../../../utils/selectGroups'
import withLabel from '../../UI/withLabel/withLabel'

const TableCell = {
	WIDTH: 20,
}

class Roles extends React.Component<Props, State> {
	state: State = {
		statusRuleType: '',
		extendsFrom: '',
		transferDescription: '',
		objectSecurityList: [],
		editable: false,

		rights: [],

		isLoadingRights: false,
	}

	get allStatusesRules(){
		let inheritStatusesRules: TransferOrders[] = []
		const { rolesMap, id, name } = this.props

		const { parentRoles, statusesRules } = rolesMap[id]
		parentRoles &&
		parentRoles.forEach((parentRole) => {
			const parent = rolesMap[parentRole]
			parent &&
			parent.statusesRules &&
			parent.statusesRules.forEach((item) => {
				inheritStatusesRules.push({
					edit: false,
					name: parent.name,
					...item,
				})
			})
		})

		statusesRules &&
		statusesRules.forEach((status) => {
			inheritStatusesRules.push({
				edit: true,
				name,
				...status,
			})
		})
		return inheritStatusesRules;
	}

	getOperationsListOptions() {
		const orderColumns = [
			{
				Header: getText('users.receivedFrom'),
				accessor: 'name', // String-based value accessors!
			},
			{
				Header: getText('users.allowed'),
				accessor: 'status.name',
				Cell: (props: any) => {
					const key =
						props.original.type === ASSIGN_FROM
							? 'users.statuses.assignFrom'
							: props.original.type === ASSIGN_TO
							? 'users.statuses.assignTo'
							: props.original.type === ROLLBACK_FROM
							? 'users.statuses.rollbackFrom'
							: props.original.type === ROLLBACK_TO
							? 'users.statuses.rollbackTo'
							: ''

					const message = renderToString(getText(key))

					let text = `${message} "${props.value}"`

					return <span className='number'>{text}</span>
				},
			},
			{
				Header: getText('common.exclude'),
				accessor: 'edit',
				Cell: (props: any) => {
					const { edit, id } = props.original
					console.log({ id })
					return edit ? (
						<DeleteCell deleteHandler={this.excludeRole} id={id} />
					) : (
						<></>
					)
				},
			},
		]

		return {
			columns: orderColumns,
			data: this.allStatusesRules,
			showPagination: false,
			minRows: 0,
			resizable: false,
		}
	}

	deleteRow = async (osId: number, name: string) => {
		const { rights } = this.state

		const row = rights.find((r) => r.name === name && r.rightId === osId)

		if (row) {
			const { allowed, forbidden } = row

			const isAllowedHasChecked = Object.values(allowed).some(
				(item) => item.checked,
			)

			if (isAllowedHasChecked) {
				return false
			}

			const isForbiddenHasChecked = Object.values(forbidden).some(
				(item) => item.checked,
			)

			if (isForbiddenHasChecked) {
				return false
			}

			const deleted = await deleteRoleRight({
				roleId: this.props.id,
				rightId: osId,
			})

			if (deleted) {
				this.setState((prevState) => ({
					rights: prevState.rights.filter(
						(r) => !(r.rightId === osId && r.name === name),
					),
				}))
			}
		}
	}

	onChangeCell = async (
		name: string,
		status: boolean,
		osId: number,
		osName: string,
	) => {
		const [accessibility, right] = name.split('.')

		const newValue = !status

		let result = {
			roleId: this.props.id,
			rightId: osId,
			[accessibility]: {
				[right]: newValue,
			},
		}

		try {
			const changed = await changeRoleRights(result)

			if (changed) {
				await this.setState(
					(prevState) => ({
						rights: prevState.rights.map((item) => {
							if (item.rightId === osId) {
								return {
									...item,
									[accessibility]: {
										//@ts-ignore
										...item[accessibility],
										[right]: {
											//@ts-ignore
											...item[accessibility][right],
											checked: newValue,
										},
									},
								}
							}
							return item
						}),
					}),
					this.deleteRow.bind(this, osId, osName),
				)
			}
		} catch (e) {
			checkError(e)
		}
	}

	excludeRole = async (statusRuleId: number) => {
		console.log({ statusRuleId })
		const { removeRoleStatusRule, id } = this.props
		await removeRoleStatusRule(id, statusRuleId)
	}

	onChangeRolesName = async (value: string) => {
		const id = this.props.id
		try {
			await this.props.updateRole(id, {
				name: value,
			})
		} catch (e) {
			checkError(e)
		}
	}

	changeParentRole = (option: Option) => {
		return new Promise(async (resolve) => {
			await this.props.updateRole(this.props.id, {
				parentRole: option.value,
			})
			resolve()
		})
	}

	changeStatus = (option: Option) => {
		return new Promise(async (resolve) => {
			// try {
			await this.props.updateRole(this.props.id, {
				newOrderStatus: option.value,
			})
			resolve()
		})
	}

	changeStatusRule = (option: Option) => {
		return new Promise(async (resolve) => {
			await this.props.addStatusRule(
				this.props.id,
				option.value,
				option.type,
			)
			resolve()
		})
	}

	changeObjectSecurity = (option: Option) => {
		return new Promise(async (resolve) => {
			this.props.addObjectSecurity({
				osID: option.value,
				roleID: this.props.id,
			})

			resolve()
		})
	}

	resetRights = () => {
		const { rolesMap, id } = this.props

		const { rights, parentRoles } = rolesMap[id]
		let resultRights: RightsRow[] = []

		parentRoles &&
			parentRoles
				.sort((a, b) => {
					//@ts-ignore
					return moment(a.createdAt) > moment(b.createdAt) ? 1 : 0
				})
				.map((parentId) => {
					const { rights, name } = rolesMap[parentId]

					rights &&
						rights.forEach((right) => {
							resultRights.push(getRightsRow(right, name, false))
						})
				})

		rights
			.sort((a, b) => {
				//@ts-ignore
				return moment(a.createdAt) > moment(b.createdAt) ? 1 : 0
			})
			.forEach((right) => {
				resultRights.push(getRightsRow(right, this.props.name, true))
			})

		this.setState(() => ({ rights: resultRights }))
	}

	componentDidMount() {
		this.resetRights()
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	): void {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.resetRights()
		}
	}

	setSelectedGroupId = (group) => {
		console.log('~ group', group)
		this.setState({
			selectedGroupId: group.value,
		})
		return Promise.resolve()
	}

	async addRoleGroup() {
		const result = await addRoleObject(
			this.props.id,
			this.state.selectedGroupId,
		)
		if (result) {
			this.setState({
				selectedGroupId: null,
			})
		}
	}

	async deleteRoleGroup() {
		const result = await deleteRoleObject(
			this.props.id,
			this.state.selectedGroupId,
		)
		if (result) {
			this.setState({
				selectedGroupId: null,
			})
		}
	}

	render() {
		const statusesRulesGrid = this.getOperationsListOptions()
		const {
			parentList,
			parent,
			newOrderStatus,
			id,
			name,
			rolesMap,
			rights,
		} = this.props

		const canEdit = rights && rights['users'] && rights['users']['edit']

		const isRootRole = id === 1

		const isStatusesRules = this.allStatusesRules.length > 0

		const cell = (cellProp: any) => {
			const { rightId, name } = cellProp.original

			if (cellProp.value.isSet) {
				const Input = () => (
					<input
						type='checkbox'
						key={cellProp.value.name + cellProp.original.osID}
						onChange={this.onChangeCell.bind(
							this,
							cellProp.value.name,
							!!cellProp.value.checked,
							rightId,
							name,
						)}
						disabled={canEdit ? !cellProp.value.edit : true}
						checked={!!cellProp.value.checked}
					/>
				)
				if (cellProp.value.description) {
					return (
						<Tooltip
							title={cellProp.value.description}
							position='left'
						>
							<Input />
						</Tooltip>
					)
				} else {
					return <Input />
				}
			} else {
				return <></>
			}
		}

		const getObjectsList = async () => {
			const groups = await getGroups()
			const groupsWithIds = Object.entries(groups)?.map((group) => ({
				...group[1],
				id: group[0],
			}))
			return Promise.resolve(groupsWithIds)
		}

		return (
			<React.Fragment>
				<h2>
					{getText('users.roleUpperCase')}:{' '}
					{canEdit ? (
						<EditableText
							text={name}
							onChangeText={this.onChangeRolesName}
							editable={this.state.editable}
						/>
					) : (
						this.props.name
					)}
				</h2>

				{!isRootRole && (
					<Select
						change={this.changeParentRole}
						defaultOptions={parentList}
						defaultValue={parent}
						accessors={{
							id: 'value',
							name: 'label',
						}}
						isDisabled={!canEdit}
						label='users.parentRole'
					/>
				)}

				<Select
					accessors={{
						id: 'value',
						name: 'label',
					}}
					label='users.newOrderStatus'
					load={getStatusesList}
					defaultValue={newOrderStatus ? newOrderStatus : {}}
					change={this.changeStatus}
					isDisabled={!canEdit}
				/>

				<RenderIf condition={isStatusesRules}>
					<Table {...statusesRulesGrid} />
				</RenderIf>

				{withLabel(
					<Select
						change={this.setSelectedGroupId}
						load={getObjectsList}
						placeholder='users.groupObjects'
						accessors={{
							id: 'value',
							name: 'label',
						}}
					/>,
				)({
					position: 'left',
					text: `${renderToString(
						getText('users.addOrDeleteGroup'),
					)} "${this.props.name}"`,
				})}

				<RenderIf condition={this.state.selectedGroupId}>
					<Button
						className='btn-warning'
						onClick={this.deleteRoleGroup.bind(this)}
					>
						{getText('common.delete')}
					</Button>
					<Button
						className='btn-success'
						onClick={this.addRoleGroup.bind(this)}
					>
						{getText('common.add')}
					</Button>
				</RenderIf>

				<Select
					isMulti={false}
					accessors={{
						id: 'value',
						description: 'label',
						type: 'type',
					}}
					load={getStatusesRules.bind(
						this,
						rolesMap[id].statusesRules,
					)}
					change={this.changeStatusRule}
					label='users.addOperation'
					withoutValue={true}
					isDisabled={!canEdit}
				/>

				<h2>{getText('users.permissions')}</h2>
				<Table
					sorting={false}
					showPaginationBottom
					data={this.state.rights}
					loading={this.state.isLoadingRights}
					columns={[
						{
							Header: getText('users.extendsFrom'),
							accessor: 'name',
						},
						{
							Header: getText('users.objectSecurity'),
							accessor: 'description',
						},
						{
							Header: getText('users.allowed'),
							accessor: 'allowed',
							columns: [
								{
									Header: (
										<i className='fa fa-eye grid__column_success' />
									),
									accessor: 'allowed.read',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-plus grid__column_success' />
									),
									accessor: 'allowed.create',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-pencil-alt grid__column_success' />
									),
									accessor: 'allowed.edit',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-trash grid__column_success' />
									),
									accessor: 'allowed.delete',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
							],
						},
						{
							Header: getText('users.forbidden'),
							accessor: 'forbidden',
							columns: [
								{
									Header: (
										<i className='fa fa-eye grid__column_deny' />
									),
									accessor: 'forbidden.read',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-plus grid__column_deny' />
									),
									accessor: 'forbidden.create',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-pencil-alt grid__column_deny' />
									),
									accessor: 'forbidden.edit',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
								{
									Header: (
										<i className='fa fa-trash grid__column_deny' />
									),
									accessor: 'forbidden.delete',
									Cell: cell,
									minWidth: TableCell.WIDTH,
								},
							],
						},
					]}
				/>
				<Select
					load={getObjectSecurityList.bind(this, rolesMap[id].rights)}
					isDisabled={canEdit ? this.state.isLoadingRights : true}
					change={this.changeObjectSecurity}
					accessors={{
						id: 'value',
						description: 'label',
					}}
					label='users.addObjectSecurity'
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state: UsersMapStateToProps) => ({
	name: state.users.roles.name,
	id: state.users.roles.id,
	rolesMap: state.users.roles.map,
	parentList: state.users.roles.parentList,
	parent: state.users.roles.parent,
	newOrderStatus: state.users.roles.newOrderStatus,

	rights: state.application.rights,
})

const mapDispatchToProps = (dispatch: Function) => ({
	addObjectSecurity: (data: AddObjectSecurity) =>
		dispatch(addObjectSecurityItem(data)),
	changeRoleRight: (data: ChangeRoleRights) =>
		dispatch(changeRoleRight(data)),
	updateRole: (id: number, data: ChangeRole) =>
		dispatch(updateRole(id, data)),
	addStatusRule: (roleId: number, status: number, type: IStatusRuleType) =>
		dispatch(addStatusRule(roleId, status, type)),
	removeRoleStatusRule: (roleId: number, statusRuleId: number) =>
		dispatch(removeRoleStatusRule(roleId, statusRuleId)),
	openModal: (props: Modal) => dispatch(openModal(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Roles)
