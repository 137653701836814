import styled from "styled-components";
import {CardViewProps} from "./card.types";


export const CardView = styled.div<CardViewProps>`

    border-bottom: ${(props) => {
        return props['data-id'] === props.listActiveItem ? '2px solid #de1118' : '2px solid #c0c0c0'
    }}
    box-sizing: border-box;
    margin-top: 0;
    transition: all 0.3s;
    cursor: pointer;
    padding : 10px 0;
    position : relative;
    margin-bottom : 2px;    
    
    background-color : ${({isActiveReference}) => !isActiveReference && '#a9a9a952'}
    &:not(:first-child) {
        padding-top: 10px;
    }
    &:hover {
        background-color: rgba(192, 192, 192, .1);
    }
`;

interface AngleProps {
    color? : string
    size? : number
}

function getBorderLeft({color, size}:AngleProps):string {
    let result:string[] = [];
    if(color && color !== 'default') {
        result.push(color)
    }else{
        result.push('#f2f3fA')
    }

    if(size) {
        result.push(size + 'px')
    }else{
        result.push('90px')
    }

    result.push('solid');

    return result.join(' ');

}

export const Angle = styled.div<{color?: string, size?: number}>`
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    height: 0;
    
    border-bottom:${({size}) => size ? `${size}px` : '90px'} solid transparent;
    border-right: ${({size}) => size ? `${size}px` : '90px'} solid transparent;
    border-left: ${getBorderLeft};
    top:0;
    left : 0;
`;
