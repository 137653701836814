import {
    Object,
    OrderListItem,
    File,
    IExecutionTime,
    Tag,
    ContractCondition,
    ContractOrdersType,
    ICreateOrderWaitRequest, OrdersTag, OrderStatus,
} from '@justpro/terminal'


export interface OrdersReducer{
    list? : OrderListItem[],
    count? : number
    haveNewComments? : boolean
}

export const initialState:OrdersReducer = {};

export interface UpdateOrderList {
    tags? : OrdersTag[]
    contractConditions? : ContractCondition[]
    additionalInfo? : string
    content? : string
    contactInfo? : string
    object? : Object
    contractOrdersType? : ContractOrdersType,
    executionTime?: IExecutionTime[]
    highpriced? : boolean
    warranty? : boolean
    waitRequests? : ICreateOrderWaitRequest[]
    orderStatus? : OrderStatus

    photos? : File[]
    files? : File[]
    offers? : File[]
    subordersCount? : number
}
