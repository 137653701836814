import React from "react";
import Table from "../../../table/table";
import '../createOrderModal.view.css'
import {Contract, Contractor} from "@justpro/terminal";
import getText from "../../../../../localization/getText";


interface Props {
    changeContractor:(contractor:Contractor) => void
    contracts : Contract[]
}

interface State {
    contractors : Contractor[],
    loading : boolean

}

export default class ContractorDetail extends React.Component<Props, State>{

    state:State = {
        contractors : [],
        loading : false
    };

    choose = (contractor:Contractor) => {
        this.props.changeContractor(contractor)
    };

    createList = () => {
        const {contracts} = this.props;

        this.setState(() => ({loading : true}));

        const contractors = contracts?.reduce((acc:Contractor[], contract) => {
            let passed = true;

            if(!contract.objects) {
                return acc
            }

            for (let i = 0; i < acc.length; i++) {
                if(acc[i].id === contract?.contractor.id) {
                    passed = false;
                    break;
                }
            }

            if (passed) {
                return [...acc, contract.contractor]
            }
            return acc;
        }, []);

        this.setState(() => ({
            loading : false,
            contractors : contractors || [],

        }));

    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(JSON.stringify(prevProps.contracts) !== JSON.stringify(this.props.contracts)) {
            this.createList();
        }
    }

    componentDidMount(): void {
        this.createList();
    }

    render() {

        const {contracts} = this.props;


        return contracts && contracts.length > 0 &&
            <Table
                columns={[
                    {
                        Header: getText('contractors.contractor'),
                        accessor: 'name',
                        minWidth: 150,
                        Cell:(props:any) => <span className="just-prop_create-order-list-item" onClick={this.choose.bind(this, props.original)}>{props.original.name}</span>
                    },
                ]}
                data={this.state.contractors}
                minRows={0}
                pagination={false}
                showPagination={false}
                loading={this.state.loading}
            />
    }

}