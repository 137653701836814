import React, {useEffect, useRef, Component} from 'react'
import getText from "../../../localization/getText";
import DefaultFooterModal from "../../UI/dialog/modal/defaultFooterModal";
import Button from "../../UI/button/button";
import {ModalBodyProps} from "../../../store/modal/modal.types";
import Input from "../../UI/input/text";

interface Props extends ModalBodyProps {
    defaultName: string
    onAccept: (newName: string) => any
}

interface State {
   newName: string
}

class CopyWorkGroupModal extends Component <Props, State> {
    state = {
        newName: this.props.defaultName
    };

    componentDidUpdate(nextProps) {
        if (this.props.defaultName !== nextProps.defaultName) {
            this.setState({
                newName: nextProps.defaultName
            })
        }
    }

    onAccept = () => {
        this.props.onAccept(
            this.state.newName
        );
        this.props.hide();
    }

    changeNewName = (newName) => {
        this.setState({
            newName
        })
    }

    render() {
        const {newName} = this.state;
        const {hide} = this.props;
        return (
            <div>
                <div>
                    <Input
                        label="common.name"
                        blur={this.changeNewName}
                        startValue={newName}
                    />
                </div>
                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success workgroup__btn-success"
                            onClick={this.onAccept}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </div>
        );
    }
}

export default CopyWorkGroupModal;
