import React from "react";
interface Props {
    children : any
}

const ModuleTextName = (props:Props) => {
    return (
        <div className='navbar-form navbar-left filter'>
            <span className='name-spravochnik'>{props.children}</span>
        </div>
    )
}


export default ModuleTextName