import React from 'react';
import {Props, State} from './schedule.types'
import {Schedule} from "./schedule.view";
import Month from './month.controller'
import Days from './days.controller'
import Weeks from './weeks.cotnroller'
import WeekDays from './weekDays.controller'
import {toast} from "react-toastify";
import {ISchedule} from "@justpro/terminal";
import {renderToString} from "react-dom/server";
import getText from "../../../../localization/getText";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";

export default class ScheduleController extends React.Component<Props, State>{
    state:State = {
        days : [new Date().getDate()],
        daysOfWeek : [],
        month : [0,1,2,3,4,5,6,7,8,9,10,11],
        weeks : [],
    };


    getData = ():ISchedule => {
        const {days, daysOfWeek, weeks, month} = this.state;

        return {days, daysOfWeek, weeks, month}
    };

    changeMonth = async (id:number) => {
        const {isDisabled = false} = this.props;

        if(!isDisabled) {
            await this.setState(prevState => {
                const includes = prevState.month.includes(id);

                if (prevState.month.length === 1 && includes) {
                    toast.warn(renderToString(getText('schedule.atListOneMonthMustBeSelected')));
                    return prevState
                }

                return {
                    ...prevState,
                    month: includes ? prevState.month.filter(item => item !== id) : [...prevState.month, id],
                }
            })

            this.props.onChange(this.getData())
        }
    };

    changeDay = async (day:number) => {
        const {isDisabled = false} = this.props;

        if(!isDisabled) {
            await this.setState(prevState => {
                const includes = prevState.days.includes(day);

                if(prevState.days.length === 1 && includes) {
                    if (prevState.daysOfWeek.length === 0 || prevState.weeks.length === 0) {
                        toast.warn(renderToString(getText('schedule.weeksIsRequired')));
                        return prevState
                    }
                }

                return {
                    ...prevState,
                    days : includes ? prevState.days.filter(item => item !== day) : [...prevState.days, day]
                }
            });

            this.props.onChange(this.getData())
        }


    };

    changeWeekDay = async (weekDay:number) => {
        const {isDisabled = false} = this.props;

        if(!isDisabled) {
            await this.setState(prevState => {
                const includes = prevState.daysOfWeek.includes(weekDay);

                if (prevState.daysOfWeek.length === 1 && includes) {
                    if (prevState.days.length === 0) {
                        toast.warn(renderToString(getText('schedule.daysIsRequired')))
                        return prevState
                    }
                }

                return {
                    ...prevState,
                    daysOfWeek: includes ? prevState.daysOfWeek.filter(item => item !== weekDay) : [...prevState.daysOfWeek, weekDay]
                }
            });


            this.props.onChange(this.getData())
        }

    };

    changeWeek = async (week:number) => {
        const {isDisabled = false} = this.props;

        if(!isDisabled) {
            await this.setState(prevState => {
                const includes = prevState.weeks.includes(week);

                if (prevState.weeks.length === 1 && includes) {
                    if (prevState.days.length === 0) {
                        toast.warn(renderToString(getText('schedule.daysIsRequired')))
                        return prevState
                    }
                }


                return {
                    ...prevState,
                    weeks: includes ? prevState.weeks.filter(item => item !== week) : [...prevState.weeks, week]
                }
            });

            this.props.onChange(this.getData())
        }
    };

    save = () => {
        this.props.saveSchedule && this.props.saveSchedule();
        this.props.hide();
    }

    componentDidMount(): void {
        const {schedule} = this.props;

        if(schedule) {
            this.setState(() => ({
                ...schedule
            }))
        }
    }

    render() {

        return (
            <>
                <Schedule isDisabled={this.props.isDisabled}>
                    <Days active={this.state.days} change={this.changeDay}/>
                    <WeekDays active={this.state.daysOfWeek} change={this.changeWeekDay}/>
                    <Weeks active={this.state.weeks} change={this.changeWeek} />
                    <Month change={this.changeMonth} active={this.state.month}/>
                </Schedule>
                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button onClick={this.save} className="btn-success" disabled={this.props.isDisabled}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}