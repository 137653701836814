import React from 'react';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {Department, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    department : Department
    update(data:Partial<Department>) : void
    rights? : Partial<ModulesResponse>
}

class DepartmentDetail extends React.Component<Props>{

    toggleActive = (active:boolean) => {
        this.props.update({active})
    };

    changeName = (name:string, oldName :string) => {
        if(name !== oldName) {
            this.props.update({name})
        }
    };

    render() {
        const {department, rights} = this.props;
        const {active, name} = department;
        const disabled = rights && rights['references.departments'] && !rights['references.departments']['edit'];

        return (<>

            <h4>{getText('departments.department')}: {name}</h4>

            <ToggleSwitch
                send={this.toggleActive}
                defaultValue={active}
                label="common.active"
                disabled={disabled}
            />
            <Input
                blur={this.changeName}
                label="departments.department"
                startValue={name}
                disabled={disabled}
            />

        </>)
    }

}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(DepartmentDetail)