import React from 'react'
import {
    MastersPaymentCard,
    addPaymentCard,
    deletePaymentCard,
    editPaymentCard,
} from '@justpro/terminal'
import Table from '../../UI/table/table'
import checkError from '../../../utils/checkError'
import Button from '../../UI/button/button'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../store/application/application.types'
import DeleteCell from '../../UI/table/deleteCell'
import getText from '../../../localization/getText'
import Input from '../../UI/input/text'
import ToggleSwitch from '../../UI/toggleSwitch/toggleSwitch.controller'

interface Props {
	masterId: number
	rights: any
	paymentCards?: MastersPaymentCard[]
    getMaster: () => void
};

interface State {
	number: string
}

class PaymentCardsTable extends React.Component<Props, State> {
	state: State = {
		number: '',
	}

	addPayment = async () => {
		try {
			await addPaymentCard({
				number: this.state.number,
				masterId: this.props.masterId,
                isMain: false,
			})
            await this.props.getMaster();
			this.setState({
				number: '',
			})
		} catch (e) {
			checkError(e)
		}

		return Promise.resolve()
	}

	deletePayment = async (props: any) => {
		try {
			const deleted = await deletePaymentCard({
				masterId: this.props.masterId,
				id: props.id,
			})
            await this.props.getMaster();
		} catch (e) {
			checkError(e)
		}
	}

    async changeIsMainCard(paymentCardId, isMain) {
        try {
			const updateResponse = await editPaymentCard({
				masterId: this.props.masterId,
				id: paymentCardId,
                isMain
			})
            await this.props.getMaster();
		} catch (e) {
			checkError(e)
		}
    }

	getColumns = () => {
		const { rights } = this.props

		const result: any[] = [
			{
				Header: getText('masters.isMainCard'),
				accessor: 'isMain',
                Cell: (props: any) => (
                    <ToggleSwitch
                        send={(isMain) => this.changeIsMainCard(props.original.id, isMain)}
                        defaultValue={props.original.isMain}
                    />
                )
			},
            {
				Header: getText('masters.cardNumber'),
				accessor: 'number',
			},
		]

		if (rights?.['references.masters']?.delete) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deletePayment}
						item={props.original}
					/>
				),
			})
		}
		return result
	}

	changePaymentText = (number: string) => {
        const preparedNumber = number.replace(/\D/gi, '').slice(0, 16)
        const spacedNumber = preparedNumber.split("").reduce((spacedNumber, digit, index) => {
            const shouldPlaceSpace = [3, 7, 11];
            return spacedNumber + digit + ((shouldPlaceSpace.includes(index)) ? ' ' : '');
        }, '');
		this.setState({
			number: spacedNumber,
		})
	}

	render() {
		const { rights, paymentCards } = this.props
		return (
			<>
				{paymentCards?.length > 0 && (
					<Table columns={this.getColumns()} data={paymentCards} />
				)}

				{rights['references.masters']?.edit && (
						<div
							className={paymentCards?.length > 0 ? 'margin-top' : ''}
						>
							<Input
								change={this.changePaymentText}
								placeholder='masters.cardNumberPlaceholder'
								value={this.state.number}
                                maxLength={19}
							/>

							<Button
								className='btn-success'
								onClick={this.addPayment}
								disabled={this.state.number.length === 0}
							>
								{getText('common.add')}
							</Button>
						</div>
					)}
			</>
		)
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

export default connect(mapStateToProps)(PaymentCardsTable)
