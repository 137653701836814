import React from "react";
import {
    ModulesResponse,
    OTRequestFull
} from "@justpro/terminal";
import './otsync.css'
import OtsyncData from "./otsync.data";
import Tabs, {ITab} from "../../UI/tabs/tabs.controller";
import OrderListItem from "../../orders/order/order.controller";
import moment from "moment";
import InfoBlock from "../../UI/infoBlock/infoBlock.controller";
import RegularWorkListItem from "../../regularWorks/regularWork/regularWork.listItem";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import BlockController from "../../UI/infoBlock/block.controller";

const dateTimeFormat = 'DD.MM.YYYY HH:mm';

interface Props {
    otsync: OTRequestFull

    updateOtsyncList(): any

    clearItem(): any
    rights?: Partial<ModulesResponse>
}

class OtsyncDetail extends React.Component<Props> {

    changeTab: ((index: number) => any) | undefined;

    onInitTabs = ({changeTab}: any) => {
        this.changeTab = changeTab
    };

    get tabs(): ITab[] {
        const tabs = [
            {
                title: 'UI.tabs.workorderData',
                component: () => <OtsyncData clearItem={this.props.clearItem}
                                             updateOtsyncList={this.props.updateOtsyncList}
                                             otsync={this.props.otsync}/>,
                onSelectTab: () => Promise.resolve()
            }
        ];
        if (this.props.otsync.orders?.length) {
            tabs.push({
                title: 'UI.tabs.linkedOrders',
                component: () => {
                    const {orders} = this.props.otsync;

                    return (
                        <>
                            {orders && orders.map(item => {
                                return <OrderListItem withModuleDetail={true} order={item} key={item.id}/>
                            })}
                        </>
                    )
                },
                onSelectTab: () => Promise.resolve()
            });
        }
        if (this.props.otsync?.regularWorks?.length) {
            tabs.push({
                title: 'regularWorks.regularWorks',
                component: () => {
                    const {regularWorks} = this.props.otsync;

                    return (
                        <>
                            {regularWorks && regularWorks.map(regularWork => {
                                return <RegularWorkListItem withModuleDetail={true}
                                                            regularWork={regularWork}
                                                            key={regularWork.id}
                                />
                            })}
                        </>
                    )
                },
                onSelectTab: () => Promise.resolve()
            })
        }

        return tabs;
    }

    render() {
        const {otsync, rights} = this.props;
        const header = (
            <InfoBlock>
                <BlockController items={[
                    {
                        key : getText('orders.order'),
                        value : otsync.number
                    },
                    {
                        key : getText('otsync.createdAt'),
                        value : moment(otsync.createdAt).utc(false).format(dateTimeFormat)
                    },
                ]} />
                <BlockController items={[
                    {
                        key : getText('otsync.baseNumber'),
                        value : otsync.baseDocument
                    },
                    {
                        key : getText('otsync.dueDate'),
                        value : moment(otsync?.dueDate).format("DD.MM.YYYY HH:mm:ss")
                    },
                ]} />
            </InfoBlock>
        );
        if (otsync.orders?.length || otsync.regularWorks?.length) {
            return (
                <>
                    {header}
                    <Tabs defaultIndex={1} tabs={this.tabs} onInit={this.onInitTabs}/>
                </>
            )
        }

        return (
            <>
                {header}
                <OtsyncData clearItem={this.props.clearItem}
                            updateOtsyncList={this.props.updateOtsyncList}
                            otsync={this.props.otsync}
                />
            </>
        )
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(OtsyncDetail)
