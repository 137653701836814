import React, {Component} from "react";
import {Router, Route, Switch, Redirect} from "react-router";
import {connect, Provider} from "react-redux";
import routes from "./routing/routes.const";
import Sign from "./components/sign/sign.controller";
import store from "./store";
import PrivateRoute from "./routing/private";
import customHistory from "./routing/history";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import moment from "moment-timezone"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import PageNotFound from "./components/pageNotFound/pageNotFound.controller";
// import Localization from "./components/localization/localization";
import {I18nextProvider} from 'react-i18next'
import "./i18n";
import Localization from "./localization/localization";
import Modal from "./components/UI/dialog/modal/modal.controller";

moment.tz.setDefault('+00:00');

const mapStateToProps = (state: any) => ({
    rights: state?.application?.rights
})
const DefaultModule = connect(mapStateToProps, null)((props: any) => {
        if(props.rights?.news?.read){
            return <Redirect to={"/news"}/>
        }
        return <PageNotFound />;
})

export default class App extends Component {
    render() {
        return (
            <ErrorBoundary>
                <Provider store={store}>
                    <Localization>
                        <Router history={customHistory}>
                            <DndProvider backend={HTML5Backend}>
                                <Switch>
                                    {routes.map((route) => (
                                        <PrivateRoute {...route} key={route.path}/>
                                    ))}
                                    <Route path="/sign" component={Sign} />
                                    <Route path="/" component={DefaultModule}/>
                                    <Route path="*">
                                        <PageNotFound />
                                    </Route>
                                </Switch>
                                <Modal />
                                <ToastContainer autoClose={3000} position="bottom-right"/>
                            </DndProvider>
                        </Router>
                    </Localization>
                </Provider>
            </ErrorBoundary>

        )
    }
}