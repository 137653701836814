import React, { useRef } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'

export interface ItemProps {
    id: any
    index: number
    move: (dragIndex: number, hoverIndex: number) => void
    accept : string
    children : JSX.Element
    onDrop(item:any) : void
    noPadding?: boolean
    [key: string] : any
}

interface DragItem {
    index: number
    id: string
    type: string
}
const SimpleSort: React.FC<ItemProps> = ({ id, index, accept, move, onDrop, noPadding,...rest }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: accept,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current!.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            move(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        drop(item) {
            console.log({drop : item})
            onDrop(item);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: accept, id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    const style = {
        border: '1px dashed gray',
        marginBottom: '.5rem',
        padding: noPadding ? '0px' : '0.5rem 1rem',
        cursor: 'move',
    };
    return (
        <div ref={ref} style={{ ...style, opacity }}>
            {rest.children}
        </div>
    )
};

export default SimpleSort
