import React from 'react';
import {
    getStatus,
    OrderStatus,
    UpdateStatus,
    updateStatus
} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import OrderStatusDetail from "./orderStatus.detail";
import {getIconsList} from "../../../utils/icons";

const icons = getIconsList();

interface Props {
    id: number
    afterUpdate?(status:OrderStatus) : void
}

interface State {
    loading : boolean
    orderStatus? : OrderStatus
}

class OrderStatusController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateOrderStatus = async (data:Partial<UpdateStatus>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const orderStatus = await updateStatus(this.props.id, data);

            afterUpdate && afterUpdate(orderStatus);
            this.setState(() => ({loading : false, orderStatus}));
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };

    getOrderStatus = async () => {
        this.setState(() => ({loading : true}));

        try {
            const orderStatus = await getStatus(this.props.id);


            this.setState(() => ({loading : false, orderStatus}));

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(this.props.id !== prevProps.id) {
            this.getOrderStatus();
        }
    }

    async componentDidMount(){
        this.getOrderStatus();
    }

    render() {
        const {orderStatus} = this.state;
        return (
            <>
                <RenderIf condition={orderStatus !== undefined}>
                    <OrderStatusDetail
                        orderStatus={orderStatus as OrderStatus}
                        updateStatus={this.updateOrderStatus}
                        icons={icons}
                    />
                </RenderIf>

                <Spinner loading={this.state.loading}/>
            </>
        )
    }

}


export default OrderStatusController