import React from "react";
import './infoBlock.css';

interface Values {
    key : React.ReactNode
    value?: React.ReactNode
}

interface Props {
    items? : Values[]
}

export default class BlockController extends React.Component<Props>{

    render() {
        const {items, children} = this.props;
        return (
            <div className="info-block__block">
                {items ? items.map((item, index) => {
                    return (
                        <p key={index}>
                            <b>{item.key}</b> {item.value}
                        </p>
                    )
                }) : children}
            </div>
        )
    }
}