import React from "react";
import {Props, State} from "./search.types";
import Tooltip from "../tooltip/tooltip.controller";
import Button from "../button/button";
import RenderIf from "../../../utils/renderIf";
import Dropdown from "../dropdownMenu/dropdown.controller";
import {DropDownItem} from "../dropdownMenu/dropdown.types";
import {toast} from "react-toastify";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";


export default class Search extends React.Component<Props, State> {
    state = {
        q : '',
        extension : this.props.extensions ?  this.props.extensions[0] : null,
        isOpenDropdown : false,
        clear : false,
    };

    inputRef = React.createRef<HTMLInputElement>();

    changeInputHandler = (e:React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if(this.props.onChange){
           return this.props.onChange(value);
        }
        this.setState( {
            q : value,
        });
    };

    onSubmit = () => {
        const {extension} = this.state;
        const {extensions, isDisabledBtn} = this.props;
        if(!isDisabledBtn) {
            if(extensions && extension) {
                this.props.submit(this.state.q, extension.id)
            }else{
                this.props.submit(this.state.q)
            }
        }
    };

    onEraser = async () => {
        await this.setState(() => ({
            q : '',
        }));

        if (this.props.loadOnEraser) {
            this.onSubmit();
        }

    };

    changeExtension = async (extension:DropDownItem) => {
        const {onChangeExtension} = this.props;
        await this.setState({
            extension
        });

        if(onChangeExtension) {
            onChangeExtension(extension)
        }else{
            toast.warn('missing function onChange (frontend)')
        }
    };

    toggleIsOpen = () => {
        this.setState(prevState => ({
            isOpenDropdown : !prevState.isOpenDropdown
        }));
    };

    onKeyPressHandler = (e:React.KeyboardEvent) => {
        if(e.key === 'Enter') {
            this.onSubmit()
        }
    };


    componentDidMount() {
        if(this.props.autoFocus) {
            this?.inputRef?.current?.focus();
        }
    }


    render() {
        const {placeholder, extensions} = this.props;
        const {extension} = this.state;


        const p = extension ? getText(extension.name || '') :
                    placeholder ? getText(placeholder) :
                        getText('UI.search.defaultPlaceholder');


        return (
            <div className="navbar-form navbar-left search">
                <div className="input-group find-group">
                    {
                    extensions ? (
                        <Dropdown
                            list={extensions}
                            // active={this.state.extension}
                            onChange={this.changeExtension}
                            showActiveName={false}
                        />
                    ) : null
                    }

                    <input
                        className="find-text-input"
                        placeholder={renderToString(p)}
                        type="text"
                        value={this.props.q || this.state.q}
                        onKeyPress={this.onKeyPressHandler}
                        onChange={this.changeInputHandler}
                        autoComplete="off"
                        ref={this.inputRef}
                    />

                    <span className="input-group-btn">
                        <RenderIf condition={this.props.eraser}>
                              <Tooltip title={renderToString(getText('UI.search.btnClear'))} position="down">
                                <Button
                                    id="clearFilter"
                                    className="btn-default"
                                    data-toop="tooltip"
                                    onClick={this.onEraser}
                                >
                                    <i className="fas fa-eraser"/>
                                </Button>
                            </Tooltip>
                        </RenderIf>

                        <Tooltip title={renderToString(getText('UI.search.btnSearch'))} position="down">
                            <Button
                                id="setFilter"
                                className="btn-default red"
                                onClick={this.onSubmit}
                                disabled={this.props.isDisabledBtn || false}

                            >
                                <i className="fas fa-filter"/>
                            </Button>
                        </Tooltip>

                  </span>
                </div>
            </div>
        )
    }
}