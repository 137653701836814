import React from 'react';
import {ModulesResponse, Tag, addTagModule, deleteTagModule} from "@justpro/terminal";
import Input from "../../UI/input/text";
import InputColor from "../../UI/input/color";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import IconPiker from '../../UI/iconPiker/iconPicker.controller'
import {fromClassName, toClassName, getIconsList} from '../../../utils/icons'
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import MultiSelect from "../../UI/select/multiSelect";
import {ChangeMultiProps} from "../../UI/select/select.types";
import getModules from "./getModules";
import checkError from "../../../utils/checkError";
import getText from "../../../localization/getText";

type Props = {
    tag: Tag
    update(data: Partial<Tag>): void

    rights?: Partial<ModulesResponse>
};

const icons = getIconsList();

class TagDetail extends React.Component<Props> {
    onBlurName = async (name: string, oldValue: string) => {
        if (name !== oldValue) {
            await this.props.update({name})
        }
    };

    toggleActive = async (active: boolean) => {
        await this.props.update({active})
    };

    onBlurColor = async (color: string) => {
        await this.props.update({color})
    };

    changeIcon = async (iconClassName: string) => {
        const [prefix, icon] = fromClassName(iconClassName);

        await this.props.update({icon: `${prefix}:${icon}`})
    };

    onChangeModule = async (option: ChangeMultiProps) => {
        try {
            const {tag} = this.props;

            let modules: string[] = [];

            switch (option.type) {
                case "select-option":
                    await addTagModule(tag.id, option.value.value);
                    modules = [...tag?.modules, option.value.value];
                    break;
                case "remove-value":
                    await deleteTagModule(tag.id, option.value.value)
                    modules = tag?.modules?.filter(m => m !== option.value.value) || [];
                    break;
            }

            await this.props.update({modules: modules});
        } catch (err) {
            checkError(err);
        }
        return Promise.resolve(true)
    };


    render() {
        const {rights, tag} = this.props;
        const {name, active, color, icon} = tag;
        const disabled = rights && rights['references.tags'] && !rights['references.tags']['edit'];

        const existsModules = tag.modules?.map(item => {
            const name = getModules().find(m => m.id === item);

            return {
                name: name?.name,
                id: item
            }
        });


        //todo color rights ...
        return (
            <>
                <h3>{getText('tags.tag')}: {name}</h3>

                <ToggleSwitch
                    label="common.active"
                    send={this.toggleActive}
                    defaultValue={active}
                    disabled={disabled}
                />

                <Input
                    label="tags.tag"
                    blur={this.onBlurName}
                    startValue={name}
                    disabled={disabled}
                />

                <MultiSelect
                    change={this.onChangeModule}
                    defaultOptions={getModules()}
                    defaultValues={existsModules}
                    label="tags.modules"
                    accessors={{name: 'label', id: 'value'}}
                    placeholder="tags.defineWhatModulesWillBeUseThisTag"
                />

                <InputColor
                    label="common.color"
                    blur={this.onBlurColor}
                    defaultValue={color}

                />

                <IconPiker
                    list={icons}
                    label="common.icon"
                    defaultValue={toClassName(icon)}
                    change={this.changeIcon}
                    disabled={disabled}
                />


            </>
        )
    }
}

const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
})

export default connect(mapState)(TagDetail)