import React from "react";
import {IScan} from "@justpro/terminal";
import './uploadedScan.css'


interface Props {
    scans : IScan[]
}

const UploadedScan = (props:Props) => {
    const {scans} = props;
    // const [loading, setLoading] = useState(false);

    return (
        <>
            <div className="uploadedScan">
                {scans?.map((scan, index) => {
                    return (
                        <div className="scan" key={index}>
                            <img src={scan.path} alt={scan.originalName}/>

                            <div className="info-block">
                                <span className="name">{scan.originalName}</span>
                            </div>

                        </div>

                    )
                })}

            </div>
        </>
    );
};

export default UploadedScan