import React from "react";
import './tabsContent.css'
import getText from "../../../localization/getText";

interface Props {
    title : string
}

const TabContentHeader = ({title}:Props) => {

    return (
        <div className="tab-content-header">
            {getText(title)}
        </div>
    )
};

export default TabContentHeader