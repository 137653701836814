import React from "react";
import Input from "../input/text";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import Spinner from "../spinner/spinner.controller";
import DefaultFooterModal from "../dialog/modal/defaultFooterModal";
import Button from "../button/button";

interface Props {
    hide() : void
    save(name:string) : Promise<void>
}
interface State {
    loading : boolean
    value : string
}

class SavedFilterModal extends React.Component<Props, State>{
    state:State = {
        loading : false,
        value : '',
    };

    changeFilterName = (value:string) => {
        this.setState(() => ({value}))
    };

    isDisabled = () => this.state.value.trim() === '' || this.state.loading;

    save = async () => {
        if(!this.isDisabled()) {
            this.setState({loading : true});
            await this.props.save(this.state.value);
            this.setState({loading : false});
            this.props.hide();
        }
    };

    render () {
        return (
            <>

                <Input
                    change={this.changeFilterName}
                    label="Название"
                    placeholder={renderToString(getText('UI.savedFilters.newFilterPlaceholder'))}
                />
                <Spinner loading={this.state.loading} />

                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.save}>{getText('common.save')}</Button>
                </DefaultFooterModal>
            </>
        )
    }

}


export default SavedFilterModal