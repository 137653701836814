import React from 'react';
import {
    Contract,
    createContract,
    filterParams,
    getContracts, ModulesResponse
} from "@justpro/terminal";
import {filterNames} from "../../UI/checkboxList/checkboxes.const";
import {
    getContractConditionsList,
    getContractorList,
    getEquipmentCategoriesList,
    getOrdersTypesList
} from "../../../utils/functions";
import ContractController from "./contract.controller";
import ContractCreate from "./contract.create";
import BaseReferenceController from "../baseReference/baseReference.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import {CheckboxesMap} from "../../UI/checkboxList/checkboxList.types";

interface Props {
    rights?: Partial<ModulesResponse>
}

class ContractsController extends React.Component<Props>{
    renderItem = (item:Contract) => {
        return (
            <>{item.name}</>
        )
    };

    getCheckboxesMap = async () => {
        const {rights} = this.props;
        const result:CheckboxesMap = {};


        if(rights?.['references.contractConditions']?.read) {
            const contractConditions = await getContractConditionsList();


            result[filterParams.CONTRACT_CONDITION] = {
                name : 'contractConditions.contractCondition',
                children : contractConditions.map( ({name, id}) => ({name, id}))
            }
        }

        if(rights?.['references.contractors']?.read) {
            const contractors = await getContractorList();

            result[filterParams.CONTRACTORS] = {
                name : 'contractors.contractor',
                children : contractors.map( ({name, id}) => ({name, id}))
            };

            result[filterParams.EXECUTORS] = {
                name : 'common.executor',
                children : contractors.map( ({name, id}) => ({name, id}))
            }
        }


        if(rights?.['references.equipmentCategories']?.read) {
            const equipmentTypes = await getEquipmentCategoriesList();
            result[filterParams.EQUIPMENT_CATEGORIES] = {
                name : 'equipmentCategories.equipmentCategory',
                children : equipmentTypes.map( ({name, id}) => ({name, id}))
            }
        }


        if(rights?.['references.ordersTypes']?.read) {
            const ordersTypes = await getOrdersTypesList();

            result[filterParams.ORDER_TYPES] = {
                name : 'equipmentTypes.equipmentType',
                children : ordersTypes.map( ({name, id}) => ({name, id}))
            }
        }

        return result;
    };

    render () {
        const {rights} = this.props;

        return (
            <BaseReferenceController
                referenceName="contracts.moduleName"
                getList={getContracts}
                listItem={{
                    render : this.renderItem
                }}
                Detail={ContractController}
                create={{
                    Component : ContractCreate,
                    title : 'contracts.addItem',
                    handler : createContract
                }}
                filter={{
                    getCheckboxesMap : this.getCheckboxesMap,
                    filterName : filterNames.CONTRACTS
                }}
                moduleRights={rights && rights['references.contracts']}
            />
        )
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(ContractsController)