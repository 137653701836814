import React from 'react';
import Comments from "../UI/comments/comments.controller";
import OrderPhoto from "./orderPhoto";
import Files from "../UI/fileManager/files.controller";
import {connect} from "react-redux";
import ModuleHistory from "../UI/moduleHistory/history.controller";
import * as api from '@justpro/terminal'
import checkError from "../../utils/checkError";
import {updateOrderList} from "../../store/orders/orders.actions";
import {UpdateOrderList} from "../../store/orders/orders.types";
import Tabs from "../UI/tabs/tabs.controller";
import {IAct, Workorder, OTRequestFull, ModulesResponse, ITask} from "@justpro/terminal";
import ActListItem from "../acts/act/act.listItem";
import WorkOrderListItem from "../workOrders/workOrder.listItem";
import ProtocolController from "../UI/protocol/protocol.controller";
import {ApplicationMapState} from "../application/application.controller";
import TaskListItem from "../tasks/task.listItem";
import Spinner from "../UI/spinner/spinner.controller";
import {openModal} from "../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../store/modal/modal.types";
import UnmarkCommentToAll from "./unmarkCommentToAll";
import getText from "../../localization/getText";

export type ImageType = 'before' | 'after'

interface Props {
    id: number
    updateOrderList(orderId: number, data: UpdateOrderList): void
    openModal(props:Modal): void

    act?: IAct
    workorders?: Workorder[]
    otRequest?: OTRequestFull
    rights?: Partial<ModulesResponse>
}

interface State {
    selected: number
    isLoading: boolean
    filesLoading: boolean
    offersLoading: boolean
    comments: api.Comment[]
    tasks?: ITask[],
    history: api.History[]
    offers: api.File[]
    files: api.File[]
}

class OrderTabs extends React.Component<Props, State> {

    state: State = {
        filesLoading: false,
        isLoading: false,
        offersLoading: false,
        selected: 0,
        comments: [],
        tasks: [],
        history: [],
        offers: [],
        files: [],
    };

    changeTab: ((index: number) => any) | void = void 0;

    addComment = async (comment: string, files?: api.AddFile[]) => {
        const {rights} = this.props;
        if (rights && rights['orders.comments'] && rights['orders.comments']['create']) {
            try {
                const newComment = await api.addOrderComment(this.props.id, {
                    message: comment,
                    files
                });

                if (newComment) {
                    this.setState((prevState) => ({
                        comments: [...prevState.comments, newComment]
                    }))
                }

            } catch (e) {
                checkError(e)
            }
        }
    };

    readOrderComment = async (commentId: number) => {
        const {rights} = this.props;

        const canUnmarkAll = rights && rights['orders.comments.unmark'] && rights['orders.comments.unmark']['edit'];

        try {

            if (canUnmarkAll) {

                this.props.openModal({
                    id : 'unmarkToAll',
                    component: (props:ModalBodyProps) => <UnmarkCommentToAll {...props} commentId={commentId} unmark={this.unmarkToAll}/>,
                    title : getText('orders.unmarkComment'),
                })

            } else {
                this.unmarkToAll(1, commentId);
            }
        } catch (e) {
            checkError(e)
        }
    };

    unmarkToAll = async (value: 1 | 2, commentId:number) => {
        const {id} = this.props;

        try {
            let unmarked = false;

            if (value === 2) {
                unmarked = await api.unmarkOrderCommentToAll(id, commentId);
            } else if (value === 1) {
                unmarked = await api.unmarkOrderComment(id, commentId);
            }

            if (unmarked) {
                this.setState(({comments}) => ({
                    comments : comments.map(item => {
                        if(item.id === commentId){
                            return {
                                ...item,
                                new : false
                            }
                        }
                        return item
                    })
                }))
            }
        } catch (e) {
            checkError(e)
        }
    };

    get tabs() {
        const {act, workorders, otRequest, rights} = this.props;
        const {tasks} = this.state;
        const result: any[] = [];
        const readComments = rights && rights['orders.comments'] && rights['orders.comments']['read'];
        const readPhotos = rights && rights['orders.photos'] && rights['orders.photos']['read'];
        const readFiles = rights && rights['orders.files'] && rights['orders.files']['read'];
        const readOffers = rights && rights['orders.offers'] && rights['orders.offers']['read'];
        const readHistory = rights && rights['orders.history'] && rights['orders.history']['read'];
        const readWorkorders = rights && rights['workorders'] && rights['workorders']['read'];
        const readActs = rights && rights['acts'] && rights['acts']['read'];
        const readTasks = rights && rights['tasks.orders'] && rights['tasks.orders']['read'];
        
        if (readPhotos) {
            result.push(
                {
                    title: 'UI.tabs.photo',
                    component: () => <OrderPhoto orderId={this.props.id}/>,
                    onSelectTab: () => Promise.resolve(),
                }
            )
        }

        if (readComments) {
            result.push(
                {
                    title: 'UI.tabs.comments',
                    component: () => {
                        return <Comments
                            comments={this.state.comments}
                            addComment={this.addComment}
                            readComment={this.readOrderComment}
                            rightsName="orders.comments"
                        />
                    },
                    onSelectTab: this.getComments
                }
            )
        }

        if (readFiles) {
            result.push(
                {
                    title: 'UI.tabs.files',
                    component: () => <Files
                        files={this.state.files}
                        setFiles={this.setOrderFiles}
                        unsetFile={this.unsetOrderFile}
                        isLoading={this.state.filesLoading}
                    />,
                    onSelectTab: this.getFiles,
                }
            )
        }

        if (readOffers) {
            result.push(
                {
                    title: 'UI.tabs.offers',
                    component: () => <Files
                        files={this.state.offers}
                        setFiles={this.setOrderOffers}
                        unsetFile={this.unsetOrderOffers}
                        isLoading={this.state.offersLoading}
                    />,
                    onSelectTab: this.getOffers,
                }
            )
        }

        if (readHistory) {
            result.push(
                {
                    title: 'UI.tabs.history',
                    component: () => <ModuleHistory list={this.state.history}/>,
                    onSelectTab: this.getHistory,
                }
            )
        }

        if (workorders && workorders.length > 0 && readWorkorders) {
            result.push(
                {
                    title: 'workorders.workorders',
                    component: () => {
                        return workorders.map(workorder => {
                            return <WorkOrderListItem workOrder={workorder} withModuleDetail={true} key={workorder.id}/>
                        })
                    },
                    onSelectTab: () => Promise.resolve(),
                },
            )
        }

        if (tasks && readTasks) {
            result.push(
                {
                    title: 'tasks.tasks',
                    component: () => (
                        <>
                            {tasks && tasks.map((task) => (
                                <TaskListItem task={task} key={task.id} withModuleDetail/>
                            ))}
                        </>
                    ),
                    onSelectTab: this.getTasks
                },
            )
        }

        if (act && readActs) {
            result.push(
                {
                    title: getText('acts.act'),
                    component: () => <ActListItem act={act} withModuleDetail={true} key={act.id}/>,
                    onSelectTab: () => Promise.resolve(),
                },
            )
        }

        if (otRequest?.protocol) {
            result.push({
                title: 'UI.tabs.protocol',
                component: () => {
                    return <ProtocolController protocol={otRequest?.protocol}/>
                }
            })
        }

        return result;
    }

    onTabsInit = ({changeTab}: any) => {
        this.changeTab = changeTab;
    };

    setOrderFiles = async (files: api.AddFile[]) => {
        this.setState(() => ({filesLoading: true}));

        try {
            const newFiles = await api.addOrderFiles(this.props.id, files);

            if (newFiles) {
                await this.setState((prevState) => ({
                    files: [...prevState.files, ...newFiles],
                    filesLoading: false
                }));

                this.updateFilesInList()
            } else {
                this.setState(() => ({filesLoading: false}));
            }
        } catch (e) {
            this.setState(() => ({filesLoading: false}));
            checkError(e)
        }


    };

    unsetOrderFile = async (fileId: number) => {
        this.setState(() => ({filesLoading: true}));

        const deleted = await api.deleteOrderFiles(this.props.id, [fileId]);

        if (deleted) {
            await this.setState((prevState) => ({
                filesLoading: false,
                files: prevState.files.filter(item => item.id !== fileId)
            }));

            this.updateFilesInList()
        } else {
            this.setState(() => ({filesLoading: false}));
        }
    };

    setOrderOffers = async (files: api.AddFile[]) => {

        this.setState(() => ({offersLoading: true}));

        try {
            const newOffers = await api.addOrderOffers(this.props.id, files);

            await this.setState((prevState) => ({
                offers: [...prevState.offers, ...newOffers],
                offersLoading: false
            }));
            this.updateOffersInList();
        } catch (e) {
            this.setState(() => ({offersLoading: false}));
            checkError(e)
        }


    };

    unsetOrderOffers = async (offerId: number) => {
        this.setState(() => ({offersLoading: true}));

        try {
            const deleted = await api.deleteOrderOffers(this.props.id, [offerId]);

            if (deleted) {
                await this.setState((prevState) => ({
                    offersLoading: false,
                    offers: prevState.offers.filter(item => item.id !== offerId)
                }));

                this.updateOffersInList();
            } else {
                this.setState(() => ({
                    offersLoading: false,
                }));
            }
        } catch (e) {
            this.setState(() => ({offersLoading: false}));
            checkError(e)
        }
    };

    getComments = async () => {
        try {
            const comments = await api.getOrderComments(this.props.id);

            this.setState(() => ({
                comments: comments ? comments : []
            }))

        } catch (e) {
            checkError(e)
        }
    };

    getOffers = async () => {
        try {
            const offers = await api.getOrderOffers(this.props.id);

            this.setState(() => ({
                offers: offers ? offers : []
            }))
        } catch (e) {
            checkError(e)
        }
    };

    getHistory = async () => {
        try {
            const history = await api.getOrderHistory(this.props.id);

            this.setState(() => ({
                history: history ? history : []
            }))
        } catch (e) {
            checkError(e)
        }
    };

    getTasks = async () => {
        try {
            this.setState({
                isLoading: true
            });
            const tasks = await api.getOrderTasks(this.props.id);
            this.setState({
                tasks
            });
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                isLoading: false
            })
        }
    };

    getFiles = async () => {
        try {
            const files = await api.getOrderFiles(this.props.id);

            this.setState(() => ({
                files: files ? files : []
            }))
        } catch (e) {
            checkError(e)
        }
    };

    updateOffersInList = () => {
        this.props.updateOrderList(this.props.id, {offers: this.state.offers})
    };

    updateFilesInList = () => {
        this.props.updateOrderList(this.props.id, {files: this.state.files})
    };

    updateState = () => {
        this.changeTab && this.changeTab(0);
    };


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.updateState()
        }
    }

    componentDidMount() {
        this.updateState();
    }

    render() {
        const {isLoading} = this.state;
        const {rights} = this.props;

        if (rights === undefined || this.tabs.length === 0) {
            return null
        }


        return (
            <>
                <Spinner loading={isLoading} />
                <Tabs tabs={this.tabs} onInit={this.onTabsInit} defaultIndex={0}/>
            </>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
});

const mapDispatchToProps = (dispatch: Function) => ({
    updateOrderList: (orderId: number, data: UpdateOrderList) => dispatch(updateOrderList(orderId, data)),
    openModal : (props:Modal) => dispatch(openModal(props))
});

export default connect(mapState, mapDispatchToProps)(OrderTabs)
