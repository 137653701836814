import React from 'react';
import TextArea from "../../../UI/textArea/textArea.controller";
import Select from "../../../UI/select/select";
import {
    getContractConditionsList,
    getEquipmentCategoriesList,
    getObjectsList,
    getOrderStatusesList,
    getOrdersTypesList
} from "../../../../utils/functions";
import MultiSelect from "../../../UI/select/multiSelect";
import {CreateSuspension} from "@justpro/terminal";
import {ChangeMultiProps, Option} from "../../../UI/select/select.types";
import getText from "../../../../localization/getText";
import DefaultFooterModal from "../../../UI/dialog/modal/defaultFooterModal";
import Button from "../../../UI/button/button";

interface Props {
    onSave(data:CreateSuspension) : void
    hide() : void
}

interface State {
    reason : string
    equipmentCategories : number[]
    objects : number[]
    ordersTypes : number[]
    contractConditions: number[]
    orderStatus? : number
}

class SuspensionModal extends React.Component<Props, State>{

    state:State = {
        reason : '',
        equipmentCategories : [],
        objects : [],
        ordersTypes : [],
        contractConditions : [],
    };

    changePauseContract = async () => {
        const {
            objects,
            equipmentCategories,
            ordersTypes,
            orderStatus,
            contractConditions,
            reason
        } = this.state;

        if(orderStatus && reason.trim() !== '') {
            let data:CreateSuspension = {
                statusId : orderStatus,
                reason
            };

            if(objects.length > 0) {
                data.objectsId = objects
            }else if(equipmentCategories.length > 0) {
                data.equipmentCategoriesId = equipmentCategories
            }else if(contractConditions.length > 0) {
                data.contractsConditionsId = contractConditions
            }else if(ordersTypes.length > 0) {
                data.ordersTypesId = ordersTypes
            }

            this.props.onSave(data);
            this.props.hide();
        }
    };



    onChangeReason = (reason:string) => {
        this.setState(() => ({reason}))
    };

    onChangeOrderStatus = (option:Option) => {
        return new Promise (resolve => {
            this.setState(() => ({
                orderStatus : option.value
            }));
            resolve()
        })
    };

    changeOptions = async (props:ChangeMultiProps, accessor: "equipmentCategories" | "objects" | "ordersTypes" | "contractConditions"):Promise<boolean> => {
        switch (props.type) {
            case "remove-value":

                // @ts-ignore
                await this.setState((prevState) => ({
                    [accessor] : prevState[accessor].filter(item => item !== props.value.value)
                }));
                return true;
            case "select-option":

                // @ts-ignore
                await this.setState((prevState) => ({
                    [accessor] : [...prevState[accessor], props.value.value]
                }));

                return true;
        }

        return false

    };

    isDisabled = () => {
        return this.state.reason.trim() === '' || this.state.orderStatus === null || (
            this.state.objects.length === 0 &&
            this.state.ordersTypes.length === 0 &&
            this.state.contractConditions.length === 0 &&
            this.state.equipmentCategories.length === 0
        )
    };

    onChangeObjects = async (props:ChangeMultiProps) => {
        return this.changeOptions(props, 'objects')
    };

    onChangeOrderType = async (props:ChangeMultiProps) => {
        return this.changeOptions(props, 'ordersTypes');
    };

    onChangeEquipmentCategories = async (props:ChangeMultiProps) => {
        return this.changeOptions(props, 'equipmentCategories')
    };

    onChangeContractConditions = async (props:ChangeMultiProps) => {
        return this.changeOptions(props, 'contractConditions')
    };

    render () {

        return (
            <>
                <TextArea
                    label={{text : "contracts.reason"}}
                    change={this.onChangeReason}
                    placeholder="UI.textArea.placeholders.pauseReason"
                />

                <Select
                    change={this.onChangeOrderStatus}
                    placeholder="UI.select.placeholders.selectStatusToTransferSubmission"
                    load={getOrderStatusesList}
                    label="orderStatuses.orderStatus"
                    accessors={{
                        id : 'value',
                        name : 'label',
                    }}
                />

                <MultiSelect
                    change={this.onChangeOrderType}
                    placeholder="UI.select.placeholders.selectOrdersType"
                    load={getOrdersTypesList}
                    label="orderTypes.orderType"
                    isDisabled={
                        this.state.contractConditions.length > 0 ||
                        this.state.equipmentCategories.length > 0 ||
                        this.state.objects.length > 0
                    }
                    accessors={{
                        id : 'value',
                        name : 'label',
                    }}
                />

                <MultiSelect
                    change={this.onChangeObjects}
                    placeholder="UI.select.placeholders.selectAnObject"
                    load={getObjectsList}
                    isDisabled={
                        this.state.contractConditions.length > 0 ||
                        this.state.equipmentCategories.length > 0 ||
                        this.state.ordersTypes.length > 0
                    }
                    label="objects.object"
                    accessors={{
                        id : 'value',
                        name : 'label',
                    }}
                />

                <MultiSelect
                    change={this.onChangeEquipmentCategories}
                    placeholder="UI.select.placeholders.selectCategory"
                    load={getEquipmentCategoriesList}
                    label="equipmentCategories.equipmentCategory"
                    isDisabled={
                        this.state.contractConditions.length > 0 ||
                        this.state.objects.length > 0 ||
                        this.state.ordersTypes.length > 0
                    }
                    accessors={{
                        id : 'value',
                        name : 'label',
                    }}
                />

                <MultiSelect
                    change={this.onChangeContractConditions}
                    placeholder="UI.select.placeholders.selectContractCondition"
                    load={getContractConditionsList}
                    label="contractConditions.contractCondition"
                    isDisabled={
                        this.state.equipmentCategories.length > 0 ||
                        this.state.objects.length > 0 ||
                        this.state.ordersTypes.length > 0
                    }
                    accessors={{
                        id : 'value',
                        name : 'label',
                    }}
                />


                <DefaultFooterModal>
                    <Button className="btn-danger" onClick={this.props.hide}>{getText('common.cancel')}</Button>
                    <Button className="btn-success" onClick={this.changePauseContract} disabled={this.isDisabled()}>{getText('contracts.history.pause')}</Button>
                </DefaultFooterModal>
            </>
        )
    }
}



export default SuspensionModal