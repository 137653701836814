import React from 'react'
import {
	generateRegularWorks,
	getRegularWorks,
	getWorkGroups,
	getExcelRegularWorks,
	getExcelRegularWorksFile,
	RegularWork as IRegularWork,
	getMediaFromRegularWorks,
	generateFileName
} from '@justpro/terminal'
import * as api from '@justpro/terminal'
import WithPrivateRoute from '../withPrivateRoute/withPrivateRoute.controller'
import Filter from '../UI/filter/filter.controller'
import CheckboxList from '../UI/checkboxList/checkboxList.contoller'
import TopLineCalendar from '../UI/calendar/views/topLine.controller'
import Search from '../UI/search/search.controller'
import { Props, State, AccessorFrom, AccessorTo } from './regularWorks.types'
import moment, { Moment } from 'moment'
import { getSearchParams, get, save } from '../../utils/filterCheckboxes'
import { filterNames } from '../UI/checkboxList/checkboxes.const'
import {
	ActiveCheckboxesList,
	CheckboxesMap,
} from '../UI/checkboxList/checkboxList.types'
import checkError from '../../utils/checkError'
import { DropDownItem } from '../UI/dropdownMenu/dropdown.types'
import ItemList from '../UI/itemsList/itemList.controller'
import { connect } from 'react-redux'
import RenderIf from '../../utils/renderIf'
import DetailRegularWork from './regularWork/regularWork.controller'
import Dropdown from '../UI/dropdownMenu/dropdown.controller'
import {
	getContractorList,
	getEquipmentCategoriesList,
	getEquipmentTypesList,
	getEquipmentWorksList,
	getRegularStatuesList,
} from '../../utils/functions'
import Tooltip from '../UI/tooltip/tooltip.controller'
import Button from '../UI/button/button'
import RegularWork from './regularWork/regularWork.listItem'
import ListFilterController from '../UI/listFilter/listFilter.controller'
import HeaderController from '../header/header.controller'
import FileDownload from 'js-file-download'
import './regularWorks.css'
import { ApplicationMapState } from '../application/application.controller'
import { confirm } from '../UI/confirmAction/confirmAction.controller'
import getText from '../../localization/getText'
import ToplineCalendarWrapper from '../UI/calendar/views/toplineCalendarWrapper'
import { getFilter } from '../../store/filters/filters.actions'
import { getRegions } from '../../store/filters/functions'
import {isFileLink} from "../../utils/isFileLink";

const searchExtensions = [
	{
		id: 0,
		icon: 'fa fa-bullseye',
		name: 'UI.dropDown.byNumber',
		value: 'number',
	},
	{
		id: 1,
		icon: 'fa fa-wrench',
		name: 'UI.dropDown.equipmentNumber',
		value: 'equipmentNumber',
	},
	{
		id: 2,
		icon: 'fa fa-expand',
		name: 'UI.search.extendedPlaceholder',
		value: 'q',
	},
]

const dateDropdownItems = [
	{
		id: 0,
		name: 'UI.dropDown.startDate',
		value: 'date',
	},
	{
		id: 1,
		name: 'UI.dropDown.endDate',
		value: 'control',
	},
	{
		id: 2,
		name: 'UI.dropDown.createdAt',
		value: 'create',
	},
]

class RegularWorks extends React.Component<Props, State> {
	state: State = {
		from: moment().startOf('month'),
		to: moment().endOf('month'),
		fromAccessor: 'dateFrom',
		toAccessor: 'dateTo',
		activeCheckboxes: {},
		checkboxesMap: {},
		excelLoading: false,
		mediaLoading: false,
		q: '',
		numberAccessor: 'number',
		// list : [],
		listSpinner: false,
		// detailSpinner : false,
		offset: 100,
		needToBackScroll: false,
		// mode : 'read',
		// count : 0,
		regularStatuses: [],
		activeStatuses: [],
		sortBy: 'desc',
		list: [],
		count: '0',
		haveNewComments: false,
	}

	changeDateFrom = (from: Moment) => {
		this.setState(() => ({ from }))
	}

	changeDateTo = (to: Moment) => {
		this.setState(() => ({ to }))
	}

	onCheckboxesChange = (activeCheckboxes: ActiveCheckboxesList) => {
		save(filterNames.REGULAR_WORKS, activeCheckboxes)
		this.setState(() => ({ activeCheckboxes }))
	}

	filterSubmit = async () => {
		this.getRegularWorksList()
	}

	getFilterParams = (offset?: number) => {
		const {
			toAccessor,
			fromAccessor,
			from,
			to,
			q,
			numberAccessor,
			activeCheckboxes,
		} = this.state

		const filters = getSearchParams(activeCheckboxes)
		if (
			Array.isArray(filters.urgencyRegularWorksId) &&
			filters.urgencyRegularWorksId.includes(1)
		) {
			filters.other = '1'
		}
		if (
			Array.isArray(filters.urgencyRegularWorksId) &&
			filters.urgencyRegularWorksId.includes(2)
		) {
			filters.hot = '1'
		}
		if (
			Array.isArray(filters.urgencyRegularWorksId) &&
			filters.urgencyRegularWorksId.includes(3)
		) {
			filters.expired = '1'
		}
		return {
			...filters,
			[fromAccessor]: from.format(api.format.date),
			[toAccessor]: to.format(api.format.date),
			[numberAccessor]: q,
			offset,
			limit: 100,
			[api.filterParams.REGULAR_STATUSES]: this.state.activeStatuses,
		}
	}

	getList = async () => {
		try {
			const filterParams = this.getFilterParams()

			this.setState(() => ({
				listSpinner: true,
				needToBackScroll: true,
			}))

			const { list, count, haveNewComments } = await getRegularWorks(
				filterParams,
			)

			this.setState(() => ({
				listSpinner: false,
				needToBackScroll: false,
				list: list && Array.isArray(list) ? list : [],
				count: count.toString(),
				haveNewComments,
			}))
		} catch (e) {
			checkError(e)
			return { list: [], count: 0 }
		}
	}

	getRegularWorksList = async () => {
		await this.setState(() => ({
			listSpinner: true,
			needToBackScroll: true,
		}))

		await this.getList()

		this.setState(() => ({
			listSpinner: false,
			needToBackScroll: false,
			offset: 100,
		}))
	}

	updateList = async () => {
		const { list, count } = this.state

		if (count !== undefined && list !== undefined) {
			if (+count > list.length) {
				await this.setState(() => ({
					listSpinner: true,
					needToBackScroll: false,
				}))

				const filterParams = this.getFilterParams(this.state.offset)

				const { list } = await getRegularWorks(filterParams)

				this.setState((prevState) => ({
					listSpinner: false,
					offset: prevState.offset + 100,
					list:
						list && Array.isArray(list)
							? [...prevState.list, ...list]
							: prevState.list,
				}))
			}
		}
	}

	get = async (id: number) => {
		this.setState(() => ({ id }))
	}

	searchSubmit = (q: string) => {
		this.setState(() => ({ q }), this.getRegularWorksList)
	}

	onChangeSearchExtension = (extension: DropDownItem) => {
		const accessor = extension.value

		this.setState(() => ({ numberAccessor: accessor }))
	}

	renderItem = (item: api.RegularWork) => {
		return (
			<RegularWork
				regularWork={item}
				listActive={this.state.id === item.id}
				clickHandler={this.get}
			/>
		)
	}

	changeDateAccessor = (dropDownItem: DropDownItem) => {
		const { value } = dropDownItem
		let fromAccessor: AccessorFrom =
			value === 'control'
				? 'controlDateFrom'
				: value === 'create'
				? 'createdAtFrom'
				: 'dateFrom'

		let toAccessor: AccessorTo =
			value === 'control'
				? 'controlDateTo'
				: value === 'create'
				? 'createdAtTo'
				: 'dateTo'

		this.setState(() => ({
			fromAccessor,
			toAccessor,
		}))
	}

	resetFilters = async () => {
		const { rights } = this.props
		const result: CheckboxesMap = {}

		const equipmentCategories = await getEquipmentCategoriesList()

		result[api.filterParams.EQUIPMENT_CATEGORIES] = {
			name: 'equipmentCategories.equipmentCategory',
			children: equipmentCategories,
		}

		const equipmentWorks = await getEquipmentWorksList({})

		result[api.filterParams.EQUIPMENT_WORKS] = {
			name: 'equipmentWorks.equipmentWork',
			children: equipmentWorks,
		}

		const equipmentTypes = await getEquipmentTypesList({})

		result[api.filterParams.EQUIPMENT_TYPES] = {
			name: 'equipmentTypes.equipmentType',
			children: equipmentTypes,
		}

		const contractors = await getContractorList({})

		result[api.filterParams.CONTRACTORS] = {
			name: 'contractors.contractor',
			children: contractors,
		}

		const workGroups = await getWorkGroups({})

		result[api.filterParams.WORK_GROUPS] = {
			name: 'workGroups.workGroup',
			children: workGroups,
		}

		const regions = await getRegions()

		result[api.filterParams.REGIONS] = {
			name: 'regions.regions',
			children: regions,
		}

		result[api.filterParams.URGENCY_REGULAR_WORKS] = {
			name: 'UI.filters.urgencyRegularWorks',
			children: await this.props.getFilter('urgencyRegularWorks'),
		}

		const regularStatuses = rights?.['references.regularStatuses']?.read
			? await getRegularStatuesList()
			: []
		const activeCheckboxes = get(filterNames.REGULAR_WORKS)
		this.setState(() => ({
			checkboxesMap: result,
			regularStatuses,
			activeCheckboxes,
		}))
	}

	async componentDidMount() {
		await this.resetFilters()
		this.getRegularWorksList()
	}

	async componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<State>,
		snapshot?: any,
	) {
		if (
			JSON.stringify(this.props.rights) !==
			JSON.stringify(prevProps.rights)
		) {
			this.resetFilters()
		}
	}

	updateRegularWork = (regularWork: IRegularWork) => {
		this.setState((prevState: State) => {
			return {
				list: prevState.list.map((rw) => {
					if (rw.id === regularWork.id) {
						return {
							...rw,
							...regularWork,
						}
					}
					return rw
				}),
			}
		})
	}

	onChangeRegularStatus = (activeStatuses: number[]) => {
		this.setState(() => ({
			activeStatuses,
		}))
	}

	openInfoModal = async () => {
		const agree = await confirm({
			title: getText('regularWorks.generateRegularWorks'),
			question: (
				<h5>
					Генерация регулярных работ займет некоторое время. В среднем
					это 10-15 минут
				</h5>
			),
			declineText: getText('common.cancel'),
			confirmText: getText('common.generate'),
		})

		if (agree) {
			try {
				await generateRegularWorks()
			} catch (e) {
				checkError(e)
			}
		}
	}

	exportRegularWorks = async () => {
		try {
			this.setState({
				excelLoading: true,
			})
			const filterParams = this.getFilterParams()
			await isFileLink(
				() => getExcelRegularWorksFile(filterParams),
				() => getExcelRegularWorks(filterParams),
				generateFileName('регламентные_работы', 'xls')
			);
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				excelLoading: false,
			})
		}
	}

	exportMedia = async () => {
		try {
            const isAccept = await confirm({
                question: getText("regularWorks.downloadAllMedia", { count: this.state.count})
            });
            if(!isAccept) {
                return false;
            }
			this.setState({
				mediaLoading: true,
			})
			const filterParams = this.getFilterParams()
			const blob = await getMediaFromRegularWorks(filterParams)
			FileDownload(blob, `Регламентные_фото---${moment().format("YYYY-MM-DD-HH-mm-ss")}.zip`, 'application/zip')
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				mediaLoading: false,
			})
		}
	}

	render() {
		const { from, to, checkboxesMap, activeCheckboxes } = this.state

		const isFilters = Object.keys(checkboxesMap).length > 0

		return (
			<WithPrivateRoute>
				<HeaderController>
					{isFilters && (
						<Filter
							activeCheckboxes={activeCheckboxes}
							send={this.filterSubmit}
						>
							<CheckboxList
								checkboxesMap={checkboxesMap}
								activeCheckboxes={activeCheckboxes}
								send={this.onCheckboxesChange}
							/>
						</Filter>
					)}
					<div className='navbar-form navbar-left top-line-drop-down'>
						<Dropdown
							list={dateDropdownItems}
							onChange={this.changeDateAccessor}
						/>
					</div>

					<ToplineCalendarWrapper>
						<TopLineCalendar
							date={from}
							onChange={this.changeDateFrom}
						/>
						<TopLineCalendar
							date={to}
							onChange={this.changeDateTo}
						/>
					</ToplineCalendarWrapper>

					<Search
						submit={this.searchSubmit}
						loadOnEraser={false}
						eraser={true}
						placeholder='equipments.equipmentNumber'
						extensions={searchExtensions}
						onChangeExtension={this.onChangeSearchExtension}
					/>

					<div className='navbar-form navbar-left buttons'>
						<Tooltip position='down' title='common.excelExport'>
							<RenderIf condition={!this.state.excelLoading}>
								<Button
									onClick={this.exportRegularWorks}
									className='btn-default'
								>
									<i className='fa fa-file-excel' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.excelLoading}>
								Генерация...
							</RenderIf>
						</Tooltip>
						<Tooltip position='down' title='common.mediaExport'>
							<RenderIf condition={!this.state.mediaLoading}>
								<Button
									onClick={this.exportMedia}
									className='btn-default'
								>
									<i className='fa fa-file-download' />
								</Button>
							</RenderIf>
							<RenderIf condition={this.state.mediaLoading}>
								Генерация...
							</RenderIf>
						</Tooltip>
						<Tooltip
							position='down'
							title='regularWorks.generateRegularWorks'
						>
							<Button
								onClick={this.openInfoModal}
								className='btn-default'
							>
								<i className='fa fa-history' />
							</Button>
						</Tooltip>
					</div>
				</HeaderController>

				<div className='just-pro_module regularWorks'>
					<div className='panel content-panel'>
						<ListFilterController
							moduleGroups={{
								title: 'regularWorks.state',
								onChange: this.onChangeRegularStatus,
								list: this.state.regularStatuses,
								checked: this.state.activeStatuses,
							}}
							// sortBy={}

							counter={{
								value: parseInt(this.state.count),
								vars: {
									one: 'regularWorks.vars.one',
									two: 'regularWorks.vars.two',
									notFound: 'regularWorks.vars.notFound',
									default: 'regularWorks.vars.default',
								},
							}}
						/>

						<ItemList
							list={this.state.list}
							updateData={this.updateList}
							loading={this.state.listSpinner}
							renderItem={this.renderItem}
							backScrollToTop={this.state.needToBackScroll}
						/>
					</div>

					<div className='panel content-panel'>
						<RenderIf condition={this.state.id !== undefined}>
							<DetailRegularWork
								id={this.state.id as number}
								getRegularWorks={this.getList}
								updateListItem={this.updateRegularWork}
							/>
						</RenderIf>
						{/*<Spinner loading={this.state.detailSpinner}/>*/}
					</div>
				</div>
			</WithPrivateRoute>
		)
	}
}

// const mapStateToProps = (state:MapState) => ({
//     list : state.regularWorks.list,
//     count : state.regularWorks.count,
//     haveNewComments : state.regularWorks.haveNewComments
// });
//
// const mapDispatchToProps = (dispatch:Function) => ({
//     getRegularList : (filterParams:Partial<RegularWorksFilterParams>) => dispatch(getRegularWorks(filterParams))
// });

const mapState = (state: ApplicationMapState) => ({
	rights: state.application.rights,
})

const mapDispatch = (d: Function) => ({
	getFilter: (filterName, params) => d(getFilter(filterName, params)),
})

export default connect(mapState, mapDispatch)(RegularWorks)
