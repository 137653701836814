import React, {Component, useState} from 'react';
import WithPrivateRoute from "../withPrivateRoute/withPrivateRoute.controller";
import './test.css';
import {connect} from "react-redux";
import {getFilter} from "../../store/filters/filters.actions";

interface Props {
    getFilter: (filterName:string) => any
    rights:  any
}

const mapState = () => {

}

class Test extends Component <Props, any> {
    state = {
        html: '<p>Html from state</p>',
        counter: 0
    };


    async reset() {
        const cities = await this.props.getFilter('cities');
        const areas = await this.props.getFilter('areas');
    }

    componentDidMount(): void {
        this.reset();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
        if(JSON.stringify(prevProps.rights) !== JSON.stringify(this.props.rights)) {
            this.reset()
        }
    }



    render() {

        return (
            <WithPrivateRoute>

            </WithPrivateRoute>

        );
    }
}

const mapStateToProps = (state:any) => ({
    rights : state.application.rights
})

const mapDispatch = (dispatch: Function) => ({
    // hideModal : () => dispatch(hideModal()),
    getFilter: (filterName) => dispatch(getFilter(filterName))
});

export default connect(mapStateToProps, mapDispatch)(Test)