import React from 'react';

const Notify = () => {
    return (
        <div className="notice">
            <span id="notyfierShow" className="notyfierShowCircle">
                <i className="fas fa-bell" />
            </span>
        </div>
    )

};

export default Notify;