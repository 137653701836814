import React from 'react';
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import HeaderController from "../../header/header.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import TopLineCalendar from "../../UI/calendar/views/topLine.controller";
import moment, {Moment} from "moment";
import FileDownload from "js-file-download";
import {
    Person,
    format,
    getDispetcherActs,
    DispetcherAct,
    ModulesResponse,
    getDispetcherActsExport
} from "@justpro/terminal";
import Table from "../../UI/table/table";
import {getFullName} from "../../../utils/names";
import './dispatcherActs.css'
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import HasNoRightsController from "../../UI/hasNoRights/hasNoRights.controller";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import RenderIf from "../../../utils/renderIf";
import Button from "../../UI/button/button";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import ToplineCalendarWrapper from "../../UI/calendar/views/toplineCalendarWrapper";

interface Props {
    rights?: Partial<ModulesResponse>
}

interface State {
    from: Moment
    to: Moment

    loading: boolean
    generatingExcel: boolean
    list: DispetcherAct[]
}

class DispatcherActsController extends React.Component<Props, State> {


    state: State = {
        from: moment().startOf('month').set('hours', 0),
        to: moment().endOf('month').set('hours', 23).set('minutes', 59),
        loading: false,
        generatingExcel: false,
        list: []
    };

    changeFrom = (from: Moment) => {
        this.setState(() => ({from}), this.getDispatcherList)
    };

    changeTo = (to: Moment) => {
        this.setState(() => ({to}), this.getDispatcherList)
    };


    getDispatcherList = async () => {
        try {
            this.setState({
                loading: true
            });
            const list = await getDispetcherActs({
                dateFrom: this.state.from.format(format.date),
                dateTo: this.state.to.format(format.date),
                timeFrom: this.state.from.format("HH:mm"),
                timeTo: this.state.to.format("HH:mm"),
            });
            this.setState({
                list
            })
        } catch (e) {
            checkError(e)
        } finally {
            this.setState({
                loading: false
            })
        }
    };

    get columns() {
        return [
            {
                Header: getText('reports.dispatcherWorks.dispatcher'),
                accessor: 'personFullName',
            },
            {
                Header: getText('reports.dispatcherWorks.created'),
                accessor: 'createdOrdersCount'
            },
            {
                Header: getText('reports.dispatcherWorks.closedOrders'),
                accessor: 'closedOrdersCount'
            },
            {
                Header: getText('reports.dispatcherWorks.closedRegulars'),
                accessor: 'closedRegularWorksCount'
            },
            {
                Header: getText('reports.dispatcherWorks.bindedRegularWorks'),
                accessor: 'bindedRegularWorksCount'
            }
        ]
    }

    async componentDidMount() {
        return this.getDispatcherList();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            return this.getDispatcherList();
        }
    }

    generateExcel = async () => {
        try {
            this.setState(() => ({generatingExcel: true}));
            const blob = await getDispetcherActsExport({
                dateFrom: this.state.from.format(format.date),
                dateTo: this.state.to.format(format.date)
            });

            FileDownload(blob, `статистика-работы-диспетчеров_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`)
        } catch (e) {
            checkError(e)
        } finally {
            this.setState(() => ({generatingExcel: false}))
        }
    };

    render() {
        const {rights} = this.props;
        const {loading} = this.state;
        const reportName = getText('reports.dispatcherWorks.moduleName');
        if (rights === undefined) {
            return <WithPrivateRoute><HeaderController><ModuleTextName>{reportName}</ModuleTextName></HeaderController></WithPrivateRoute>;
        }

        if (!rights?.['reports.dispetcherActs']?.read) {
            return <WithPrivateRoute>
                <HeaderController><ModuleTextName>{reportName}</ModuleTextName></HeaderController>
                <HasNoRightsController/>
            </WithPrivateRoute>;
        }

        return (<WithPrivateRoute>
                <HeaderController>
                    <ModuleTextName>{reportName}</ModuleTextName>

                    <ToplineCalendarWrapper>
                        <TopLineCalendar date={this.state.from} onChange={this.changeFrom} timePicker={true}/>
                        <TopLineCalendar date={this.state.to} onChange={this.changeTo} timePicker={true}/>
                    </ToplineCalendarWrapper>

                    <div className='navbar-form navbar-left buttons'>
                        <Tooltip position="down" title="Экспортировать в эксель">
                            <RenderIf condition={!this.state.generatingExcel}>
                                <Button
                                    onClick={this.generateExcel}
                                    className="btn-default"
                                >
                                    <i className="fa fa-file-excel"/>
                                </Button>
                            </RenderIf>
                            <RenderIf condition={this.state.generatingExcel}>
                                Генерация...
                            </RenderIf>
                        </Tooltip>
                    </div>
                </HeaderController>
                <Spinner loading={loading}/>
                <div className="dispatcherActs">
                    <Table
                        data={this.state.list}
                        columns={this.columns}
                        label={'reports.dispatcherWorks.tableHeader'}
                        labelVars={{
                            from: this.state.from.format('HH:mm'),
                            to: this.state.to.format('HH:mm'),
                        }}
                        labelPosition={'top'}
                    />
                </div>
            </WithPrivateRoute>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
});

export default connect(mapState)(DispatcherActsController)