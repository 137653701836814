import React from 'react';
import Button from "../../UI/button/button";
import Input from "../../UI/input/text";

import withLabel from "../../UI/withLabel/withLabel";
import {CreateStatusGroup, OrderStatus} from "@justpro/terminal";
import {getOrderStatusesList} from "../../../utils/functions";
import getText from "../../../localization/getText";


type Props = {
    create(data:CreateStatusGroup) : void
};

type State = {
    name : string,
    statuses : OrderStatus[],
    selected : number[]
};

class StatusGroupCreate extends React.Component<Props, State> {

    state:State = {
        name : '',
        statuses : [],
        selected : [],
    };

    add = () => {
        // добавить новый элемент справочника
        const {name, selected:statusesId} = this.state;

        if(!this.isDisabled()) {
            this.props.create({
                name,
                statusesId
            });
        }
    };

    onChangeName = (name: string) => {
        this.setState(() => ({name}))
    };

    onChangeStatuses = (id : number) => {
        const {selected} = this.state;
        const include = selected.includes(id);
        let result:number[] = [];

        if(include) {
            result = selected.filter(x => x !== id);
        }else{
            result = [...selected, id];
        }

        this.setState((prevState) => ({
            ...prevState,
            selected : result
        }));
    };

    getStatusesList = () => {

        const {statuses} = this.state;

        return statuses.map(status => {
            return <div key={status.id}>
                <label><input type="checkbox" onChange={this.onChangeStatuses.bind(this, status.id)}/> {status.name}</label>
            </div>
        })
    };

    isDisabled = () => this.state.name.trim() === '';


    async componentDidMount() {
        const statuses = await getOrderStatusesList();

        this.setState(() => ({statuses}));
    }

    render () {

        return (
            <>
                <h3>{getText('statusGroups.statusGroup')}</h3>

                <Input
                    label="statusGroups.statusGroup"
                    placeholder="statusGroups.statusGroup"
                    change={this.onChangeName}
                />

                {withLabel(
                    <>
                        {this.getStatusesList()}
                    </>
                )({
                    text : 'statusGroups.defineOrRedefineStatus'
                })}

                <Button className="btn-success" onClick={this.add} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

export default StatusGroupCreate