import styled from "styled-components";
import {Moment} from "moment";

export type td = {
    selected? : boolean
    disabled? : boolean
    active? : boolean
    inactive? : boolean
    value? : Moment
}

const hover = 'rgb(247, 247, 247)';
export const bcg = 'rgb(226, 227, 235)'

export const Header = styled.div`
    display : flex;
    border-bottom : 1px solid #fff;
`;

export const Caret = styled.div`
    flex-basis : 14%;
    height : 30px;
    cursor : pointer;
    padding : 7px 3px 6px;
    text-align: center;
    
    &:hover {
     background-color : ${hover}
    }
`;

export const CaretContent = styled.span`
    font-weight : bold;
    line-height : 15px;
    text-align : center;
`;

export const Title = styled.div`
    padding : 7px 3px 6px;
    height : 30px;
    flex-basis : 72%;
    user-select : none;
    cursor : pointer;
    text-align : center;
    color : #000;
    font-weight : bold;
    &:hover {
     background-color : ${hover}
    }
`;



export const Table = styled.div`
    display : inline-flex;
    flex-direction : column;
    background-color : ${bcg};
    padding : 0 10px 20px 10px;
    margin : 0 auto;
    border-bottom : 1px solid #fff;
    border-top : 1px solid #fff;
    position : relative;
    user-select: none;
`;

const goToNowSize = 8;


export const GoNow = styled.span`
    position : absolute;
    bottom : 0;
    right : 0;
    cursor : pointer;
    height : 0;
    width : 0;
    
    
    border-top : ${goToNowSize}px solid transparent; 
    border-left : ${goToNowSize}px solid transparent; 
    border-bottom : ${goToNowSize}px solid black;
    
    & .tooltip-helper-wrapper {
        position : relative;
        left : -${goToNowSize}px;
        
        & .helper {
            height : 12px;
            width :  12px;
            
        }
    }
    
`;


export const Th = styled.div`
    font-weight : bold;
    padding : 6px;
    width : 100%;
    text-align : center;
`;

export const Tr = styled.div`
    display : flex;
`;

export const TimeInput = styled.input`
  width: 60px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  -webkit-appearance: none;
  border: 1px solid #333;
  &:first-child{
    margin-right: 7px;
  }
  &:last-child{
  margin-left: 7px;
  }
  &:focus{
  outline: none;
  }
`;

export const TimeTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 7px;
  text-align: center;
`;
export const TimeWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const TimeOuter = styled.div`
  position:absolute;
  background-color: #e2e3eb;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  left: 100%;
`;

export const Day = styled.div<td>`
    height: 32px;
    line-height: 18px;
    font-size: 14px;
    vertical-align: middle;
    padding: 8px 7px 7px;
    width : 32px;
    margin: 0 1px;
    
    text-align : center;
    cursor : pointer;
    
    color : ${ (props) => 
                    (props.active && props.disabled ) ? 
                        '#e47a7a' : (props.inactive || props.disabled ) ? 
                        '#b1b1b1' : props.active ? '#de1118' : 'black'
            }
    
    font-weight : ${ (props) => props.active || props.selected ? '700' : '200'}
    background-color : ${ (props) => props.selected ? 'white' : ''};
    border-bottom : ${ (props) => props.selected ? '2px solid red' : 'none'};
    &:hover {
        background-color : ${(props) => !props.disabled ? hover : ''};
    }
`;
export const Month = styled.div<td>`
    width:       52px;
    height:      52px;
    line-height: 52px;
    margin: 2px 1.5px;
    cursor: pointer;
    text-align : center;
    color : ${ (props) => props.selected ? 'black' : props.active ? '#de1118' :  props.inactive ? '#b1b1b1' : 'black'}
    font-weight : ${ (props) => props.active || props.selected ? '700' : '200'}
    background-color : ${ (props) => props.selected ? 'white' : ''}
    border-bottom : ${ (props) => props.selected ? '2px solid red' : 'none'}    
    
    &:hover {
        background-color : ${hover};
    }
`;

export const MonthContainer = styled.div`
    padding : 7px 10px;  
`;


export const InlineCaret = styled.span`
    padding : 6px 12px;
    position : absolute;
    cursor : pointer;
`;


export const InlinePicker = styled.div`
    position : absolute;
    z-index : 2000;
    top : 10px;
`;
