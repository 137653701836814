import React from "react";
import {editTag, getTag, Tag} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import TagDetail from "./tag.detail";

interface Props {
    id : number
    afterUpdate?(item:Tag):void
}

interface State {
    loading : boolean
    tag? : Tag
}

class TagController extends React.Component<Props, State>{

    state:State = {
        loading : false,
    };


    getTag = async () => {
        try {
            this.setState(() => ({loading : true}));

            const tag = await getTag(this.props.id);

            this.setState(() => ({
                loading : false,
                tag,
            }));
        }catch (e) {
            checkError(e)
        }

    };

    updateTag = async (data:Partial<Tag>) => {
        const {afterUpdate} = this.props;
        try {
            this.setState(() => ({loading : true}));
            const tag = await editTag(this.props.id, data);

            afterUpdate && afterUpdate(tag);

            this.setState(() => ({
                loading : false,
                tag,
            }));


        }catch (e) {
            checkError(e)
        }
    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getTag()
        }
    }

    async componentDidMount() {
        this.getTag()
    }

    render () {


        return (<>
            <RenderIf condition={this.state.tag !== undefined} >
                <TagDetail
                    update={this.updateTag}
                    tag={this.state.tag as Tag}
                />
            </RenderIf>
        </>)
    }

}

export default TagController;