import React from 'react';
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import {
    EditWorkGroup,
    ISchedule,
    ModulesResponse,
    WorkGroup,
    workGroupAddEqw,
    workGroupDeleteEqw,
    WorkGroupEqw,
    EquipmentType,
    copyWorkGroup,
    Object
} from "@justpro/terminal";
import withLabel from "../../UI/withLabel/withLabel";
import Table from "../../UI/table/table";
import Select from "../../UI/select/select";
import {LoadReturn, Option} from "../../UI/select/select.types";
import {getContractList, getEquipmentWorksList, getObjectsList, getCountries} from "../../../utils/functions";
import ScheduleController from "./schedule/schedule.controller";
import Button from "../../UI/button/button";
import checkError from "../../../utils/checkError";
import {getUniqueList} from "../../../utils/getUniqueArray";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupEWbyET} from "../../../utils/selectGroups";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import "./workGroups.css";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import {fromHoursToDays, fromDaysToHours} from "../../../utils/date";
import DeleteCell from "../../UI/table/deleteCell";
import {getContractorName} from "../../../utils/names";
import getText from "../../../localization/getText";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal} from "../../../store/modal/modal.types";
import ObjectSelectModal, {ObjectCheckbox} from './objectSelectModal'
import CopyWorkGroupModal from "./copyWorkGroupModal";

interface Props {
    workGroup: WorkGroup

    update(data: Partial<EditWorkGroup>): void
    updateList(): void

    uploadFile(file: File): void
    deleteAttachment(): void

    openModal(props: Modal): void

    setActiveWorkGroupObjects(objectsId: number[]): any

    rights?: Partial<ModulesResponse>
}

interface State {
    equipmentWorks: WorkGroupEqw[]
    schedule: ISchedule | null
    files: File[]
    loading: boolean
    objects?: Object[]
    period: string,
    planningTimeFrame: string
    // loading : boolean

}

class WorkGroupDetail extends React.Component<Props, State> {

    hiddenFileInput: any = null;

    state: State = {
        loading: false,
        schedule: null,
        // loading : false,
        equipmentWorks: [],
        files: [],
        period: '',
        objects: [],
        planningTimeFrame: '',
    };

    changeIncludeInPdf = (includeInPdf: boolean) => {
        this.props.update({ includeInPdf })
    }

    changeActive = (active: boolean) => {
        this.props.update({active})
    };

    changeName = (name: string, oldVal: string) => {
        if (name !== oldVal) {
            this.props.update({name})
        }
    };

    changePlan = (plan: string, oldValue: string) => {

        const hours = fromDaysToHours(plan);

        if (hours && plan !== oldValue) {
            this.props.update({planningTimeFrame: hours})
        }

    };

    addEquipmentWork = (option: Option) => {
        return new Promise(async resolve => {

            try {
                const response = await workGroupAddEqw(this.props.workGroup.id, [option.value]);

                if (response && Array.isArray(response)) {
                    this.setState((prevState) => ({
                        equipmentWorks: [...prevState.equipmentWorks, ...response]
                    }))
                }


            } catch (e) {
                checkError(e)
            }

            resolve();
        })
    };

    changePeriod = (val: string, oldValue: string) => {
        const hours = fromDaysToHours(val);

        if (hours && val !== oldValue) {
            this.props.update({period: hours})
        }
    };

    openModal = () => {
        const {rights, openModal, workGroup} = this.props;
        const disabled = rights && rights['references.workGroups'] && !rights['references.workGroups']['edit'];

        openModal({
            id: 'workGroupsSchedule',
            size: "large",
            component: (props) => (
                <ScheduleController {...props} isDisabled={disabled} onChange={this.changeSchedule}
                                    schedule={workGroup?.schedule} saveSchedule={this.saveSchedule}/>
            ),
            title: getText('workGroups.scheduleEditing'),
            // buttonSave : {
            //     handler : this.saveSchedule,
            //     title : getText('common.save'),
            //     disabled: disabled
            // },
        })

    };

    changeSchedule = (schedule: ISchedule) => {
        this.setState(() => ({schedule}))
    };

    saveSchedule = async () => {
        const {schedule} = this.state;
        if (schedule !== null) {
            await this.props.update({schedule})
        }
    };

    removeEquipmentType = async (props: any) => {
        const qw = props.original;

        try {
            const deleted = workGroupDeleteEqw(this.props.workGroup.id, [qw.id]);

            if (deleted) {
                this.setState((prevState) => ({
                    equipmentWorks: prevState.equipmentWorks.filter(item => item.id !== qw.id)
                }))
            }
        } catch (e) {
            checkError(e)
        }

    };

    getEquipmentOptions = (): LoadReturn => {
        return new Promise(async resolve => {
            const {equipmentWorks} = this.props.workGroup;
            const allEquipmentsWorks = await getEquipmentWorksList();

            const data = getUniqueList(allEquipmentsWorks, equipmentWorks, {wholeId: 'id', existId: 'equipmentWorkId'});
            resolve(data)
        })
    };

    changeContract = (option: Option) => {
        this.props.update({contractId: option.value});
        return Promise.resolve();
    };


    resetState = async () => {
        this.setState({
            equipmentWorks: this.props.workGroup.equipmentWorks,
            schedule: null,
            period: fromHoursToDays(this.props.workGroup.period)?.toString() || '',
            planningTimeFrame: fromHoursToDays(this.props.workGroup.planningTimeFrame)?.toString() || '',
            objects: this.props.workGroup.objects
        })
    };


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.resetState();
        }

    }

    componentDidMount(): void {
        this.resetState()
    }

    get columns() {
        const {rights} = this.props;

        const result: any[] = [
            {
                Header: getText('common.name'),
                accessor: 'name'
            },
            {
                Header: getText('equipmentTypes.equipmentType'),
                Cell: ({original}: any) => {
                    return (
                        <div>
                            {original?.equipmentTypes?.map((et: EquipmentType, index: number) => {
                                return <p key={index}>{et.name}</p>
                            })}
                        </div>
                    )

                }
            },
            {
                Header: getText('common.description'),
                accessor: 'additionalInfo'
            },
        ];

        if (rights && rights['references.workGroups'] && rights['references.workGroups']['edit']) {
            result.push({
                Header: getText('common.delete'),
                Cell: (props: any) => <DeleteCell deleteHandler={this.removeEquipmentType} item={props}/>
            })
        }

        return result;
    }

    openUploadFile = () => {
        this?.hiddenFileInput?.click();
    }

    uploadFile = async (event: any) => {
        const file = event.target.files[0];
        this.props.uploadFile(file);
    }

    openObjectsModal = async () => {
        const contractObjects = this?.props?.workGroup?.contract?.objects as ObjectCheckbox[];
        this.props.openModal({
            id: 'contractObjects',
            component: (props) => (
                <ObjectSelectModal {...props}
                                   onAccept={this.props.setActiveWorkGroupObjects}
                                   contractObjects={contractObjects?.length ? contractObjects?.map((co) => {
                                       co.checked = !!this.props.workGroup.objects.find((o) => co.objectId === o.id);
                                       return co;
                                   }) : []}
                                   objects={this.props.workGroup.objects}
                />
            ),
            title: getText('users.addObject'),
        })
    };

    createWorkGroupCopy = async (newName) => {
        try{
            this.setState({
                loading: true
            });
            const workGroup = await copyWorkGroup(this.props.workGroup.id, newName);
            this.props.updateList();
            this.setState({
                loading: false
            });
        } catch (err){
            checkError(err);
        }
    }

    openCopyModal = async () => {
        this.props.openModal({
            id: 'copyWorkGroup',
            component: (props) => (
                <CopyWorkGroupModal {...props}
                                    defaultName={this.props.workGroup.name + " (копия)"}
                                    onAccept={this.createWorkGroupCopy}
                />
            ),
            size: "medium",
            title: getText('objects.copy'),
        })
    }

    render() {
        const {workGroup, rights} = this.props;
        const {active, name, schedule, contract, attachment, includeInPdf} = workGroup;
        const {equipmentWorks, period, planningTimeFrame, objects} = this.state;
        const disabled = rights && rights['references.workGroups'] && !rights['references.workGroups']['edit'];
        const contractObjects = (this?.props?.workGroup?.contract?.objects || []) as ObjectCheckbox[];

        return (
            <>
                <Spinner loading={this.state.loading}/>
                <h4>{getText('workGroups.workGroup')}: {name} </h4>
                <Button onClick={this.openCopyModal}
                        className='btn-success'>{getText('objects.copy')}</Button>
                <ToggleSwitch
                    send={this.changeActive}
                    defaultValue={active}
                    label="common.active"
                    disabled={disabled}
                />

                <ToggleSwitch
                    send={this.changeIncludeInPdf}
                    defaultValue={includeInPdf}
                    label="workGroups.includeInPdf"
                    disabled={disabled}
                />

                <Input
                    label="workGroups.workGroup"
                    blur={this.changeName}
                    startValue={name}
                    disabled={disabled}

                />

                <Select
                    label="contracts.contract"
                    load={getContractList}
                    accessors={{
                        id: 'value',
                        name: 'label'
                    }}
                    change={this.changeContract}
                    defaultValue={contract ? contract : undefined}
                    isDisabled={disabled}

                />

                {withLabel(<>{getContractorName(contract.contractor)}</>)({text: 'contractors.contractor'})}

                <Input
                    label="workGroups.planningTime"
                    blur={this.changePlan}
                    startValue={planningTimeFrame}
                    disabled={disabled}

                />

                {withLabel(
                    <>
                        <RenderIf condition={attachment}>
                            <div className="download-wrapper">
                                <a className="download-file-link"
                                   download
                                   href={attachment}
                                   target="_blank">
                                    <i className="fa fa-download" style={{color: "#de1118"}}/>
                                    <span>{getText('common.download')}</span>
                                </a>
                            </div>
                            <Button onClick={this.props.deleteAttachment} className="btn-success">
                                {getText(`common.delete`)}
                            </Button>
                        </RenderIf>

                        {!disabled &&
                        <Button onClick={this.openUploadFile} className="btn-success">
                            {getText(attachment && attachment.length > 0 ? 'common.edit' : 'common.add')} {getText('common.attachment')}
                        </Button>
                        }

                        <input
                            type="file"
                            ref={(ref) => this.hiddenFileInput = ref}
                            onChange={this.uploadFile}
                            style={{display: 'none'}}
                        />
                    </>
                )({text: 'common.attachmentUpperCase'})}

                {withLabel(<Button className="btn-success"
                                   onClick={this.openModal}>{getText('workGroups.changeSchedule')}</Button>)({text: 'workGroups.schedule'})}

                {withLabel(
                    <>
                        <Table
                            columns={this.columns}
                            data={equipmentWorks}
                            minRows={0}
                            showPagination={false}

                        />

                        {!disabled &&
                        <AsyncSelect
                            change={this.addEquipmentWork}
                            withoutValue={true}
                            loadOptions={groupEWbyET}
                            className="margin-top"
                        />
                        }

                    </>
                )({text: 'equipmentWorks.equipmentWork'})}

                {withLabel(
                    <Button onClick={this.openObjectsModal}
                            className='btn-success margin-left'>{getText('common.change')}</Button>
                )({
                    text: 'objects.enabledObjects', labelVars: {
                        count: objects?.length,
                        allCount: contractObjects?.length
                    }
                })}

                <Input label="workGroups.period" blur={this.changePeriod} startValue={period}
                       disabled={disabled}/>
            </>
        )
    }
}

const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
});

const mapDispatch = (d: Function) => ({
    openModal: (props: Modal) => d(openModal(props))
});

export default connect(mapState, mapDispatch)(WorkGroupDetail)
