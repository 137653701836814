import React from 'react';
import getText from "../../../../localization/getText";
interface Props {
    change(month:number) : void
    active : number[]
}

interface IMonth {
    name : string
    id : number
}

export const months:IMonth[] = [
    {
        id : 0,
        name : 'schedule.month.january',
    },
    {
        id : 1,
        name : 'schedule.month.february',
    },
    {
        id : 2,
        name : 'schedule.month.march',
    },
    {
        id : 3,
        name : 'schedule.month.april',
    },
    {
        id : 4,
        name : 'schedule.month.may',
    },
    {
        id : 5,
        name : 'schedule.month.june',
    },
    {
        id : 6,
        name : 'schedule.month.july',
    },
    {
        id : 7,
        name : 'schedule.month.august',
    },
    {
        id : 8,
        name : 'schedule.month.september',
    },
    {
        id : 9,
        name : 'schedule.month.october',
    },
    {
        id : 10,
        name : 'schedule.month.november',
    },
    {
        id : 11,
        name : 'schedule.month.december',
    },
];


export default class Month extends React.Component<Props> {


    render() {

        return (
            <div>
                <h6>{getText('schedule.monthHeader')}</h6>
                <div className="months">
                    {months.map (item => {
                        return (
                            <div
                                key={item.id}
                                onClick={(e:React.MouseEvent) => this.props.change(item.id)}
                                className={this.props.active.includes(item.id) ? 'checked cell' : 'cell' }
                            >
                                {getText(item.name)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}