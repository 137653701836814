import React from 'react';
import {OrderStatus, createStatus, getStatuses, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import OrderStatusController from "./orderStatus.controller";
import OrderStatusCreate from "./orderStatus.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}

class OrderStatusesController extends React.Component<Props>{

    renderItem = (item:OrderStatus) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.statuses'];

        return <BaseReferenceController
            referenceName="orderStatuses.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getStatuses}
            Detail={OrderStatusController}
            create={{
                Component : OrderStatusCreate,
                title : 'orderStatuses.addItem',
                handler : createStatus
            }}
            moduleRights={rights}
        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(OrderStatusesController)