import React from 'react';
import {getTransports, createTransport, Transport, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import TransportCreate from "./transport.create";
import TransportController from "./transport.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";

interface Props {
    rights?: Partial<ModulesResponse>
}


class TransportsController extends React.Component<Props>{

    renderItem = (item:Transport) => {
        return <>{item.name}</>
    };

    render () {
        const rights = this.props.rights && this.props.rights['references.transports'];
        return <BaseReferenceController
            create={{
                Component : TransportCreate,
                handler : createTransport,
                title : 'transports.addItem'
            }}
            referenceName="transports.moduleName"
            Detail={TransportController}
            getList={getTransports}
            listItem={{render : this.renderItem}}
            moduleRights={rights}
        />
    }
}

const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(TransportsController)