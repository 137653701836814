import React from "react";
import {addWorkorderRegularWorks, ChecklistWorkorder, getRegularWorks, RegularWork, removeWorkorderRegularWorks, IAct} from "@justpro/terminal";
import './workOrders.css'
import RenderIf from "../../utils/renderIf";
import RegularWorkListItem from "../regularWorks/regularWork/regularWork.listItem";
import checkError from "../../utils/checkError";
import ItemList from "../UI/itemsList/itemList.controller";
import ChecklistTreeController from "../UI/checklistTree/checklistTree.controller";
import getText from "../../localization/getText";
import {declineRegularWorks, delay} from "@justpro/terminal";
import {connect} from 'react-redux'

import {openModal} from "../../store/modal/modal.actions";
import {Modal} from "../../store/modal/modal.types";
import DeclineRegularWoBody from './declineRegularWoBody'
import DeclineReasonModal from '../orders/detail/declineReason.modal'
import Spinner from "../UI/spinner/spinner.controller";



interface Props {
    regularWorks? : RegularWork[]
    workOrder : ChecklistWorkorder
    onChangeActNumber(categoryId:number, value:string) : void
    onChangeRegularWorkCheckbox(categoryId:number, active:number[]) : void
    openModal? (e: Modal) : void
    requestWorkOrder?() : void
}

interface State {
    // map  : ChecklistEquipmentCategory[],
    possibleRegularWorks : RegularWork[]
    regularWorks : RegularWork[]
    possibleLoading : boolean
    offset : number
    count : number
    isLoading : boolean
}

export interface ChecklistEquipmentWork {
    name : string,
    number: number,
    id : number
    act?: IAct
}

export interface ChecklistEquipmentType {
    id : number
    name : string
    equipmentWorks? : ChecklistEquipmentWork[]
}


export interface ChecklistEquipmentCategory{
    id : number
    name : string,
    equipmentTypes : ChecklistEquipmentType[]
}

export class ChecklistWorkOrder extends React.Component<Props, State>{

    state:State = {
        // map : [],
        possibleRegularWorks : [],
        possibleLoading : false,
        offset: 0,
        count : 0,
        regularWorks : [],
        isLoading : false
    };

    renderPossibleItem = (regularWork:RegularWork) => {
        return <RegularWorkListItem
                    regularWork={regularWork}
                    withIncludes={ {
                        handleInclude : this.includeRegularWork.bind(this, regularWork),
                        title : 'workorders.addRegularWorkToOrder'
                    }}
                    withModuleDetail={true}
        />

    };

    deleteChecklistItems = async (items:number[]) => {
        try {
            const removed = await removeWorkorderRegularWorks(this.props.workOrder.id, items);
            if(removed) {
                this.setState((prevState) => ({
                    regularWorks : prevState.regularWorks.filter(item => {
                        return !items.includes(item.id)
                    })
                }))
            }
            this.setState({
                possibleLoading: true
            });
            const {list, count} = await this.getRegularWorks(0);
            this.setState(() => ({
                possibleRegularWorks : list,
                possibleLoading : false,
                offset : 0,
                count
            }));
        }catch (e) {
            checkError(e)
        }
    };


    includeRegularWork = async (regularWork: RegularWork) => {
        try {

            this.setState(() => ({possibleLoading : true}));

            const added = await addWorkorderRegularWorks(this.props.workOrder.id, [regularWork.id]);

            await this.setState((prevState) => ({
                ...prevState,
                regularWorks : prevState.regularWorks && !prevState.regularWorks?.find((rw) => rw.id === regularWork.id)
                    ? [...prevState.regularWorks, regularWork] : [regularWork],
                possibleRegularWorks : prevState.possibleRegularWorks.filter(item => item.id !== regularWork.id),
                possibleLoading : false,
            }));

            // this.createChecklist();
        }catch (e) {
            this.setState(() => ({possibleLoading : false}));
            checkError(e);
        }

    };

    // createChecklist = async () => {
    //     const {regularWorks} = this.state;
    //
    //     const map = regularWorks?.reduce((acc:ChecklistEquipmentCategory[], regularWork) => {
    //         const {equipment} = regularWork;
    //         const {equipmentWork} = regularWork;
    //         const {equipmentType} = equipment;
    //         const {equipmentCategory} = equipmentType;
    //
    //         const existCategory = acc.find(item => item.id === equipmentCategory.id);
    //
    //         const rw:ChecklistEquipmentWork = {
    //             name : equipmentWork.name,
    //             id : regularWork.id,
    //             number : regularWork.number,
    //             act : regularWork.act
    //         };
    //         const et = {
    //             id : equipmentType.id,
    //             name : `${equipmentType.name} ${equipment.number}`,
    //             equipmentWorks : [rw]
    //         };
    //
    //
    //         if(!existCategory) {
    //             acc.push({
    //                 id : equipmentCategory.id,
    //                 name : equipmentCategory.name,
    //                 equipmentTypes : [et]
    //             })
    //         }else{
    //             const existType = existCategory.equipmentTypes.find(item => item.id === equipmentType.id);
    //
    //             if(!existType) {
    //                 existCategory.equipmentTypes.push(et)
    //             }else{
    //                 existType.equipmentWorks?.push(rw)
    //             }
    //         }
    //
    //         return acc
    //     } , []);
    //
    //     this.setState(() => ({
    //         map : map ? map : [],
    //     }))
    // };


    getRegularWorks = async (offset:number) => {
        try {
            return await getRegularWorks({
                canWorkorder : "1",
                objectsId : [this.props.workOrder.object.id],
                offset : offset,
                limit : 100,
                controlDateFrom : this.props.workOrder.date,
                dateTo : this.props.workOrder.date,

            });
        }catch (e) {
            checkError(e);
            return {
                list : [],
                count : 0
            };
        }
    };

    updateRegularWorks = async () => {
        if(+this.state.count > this.state.possibleRegularWorks.length) {
            const offset = this.state.offset + 100;

            this.setState(() => ({possibleLoading : true}));
            const {list, count} = await this.getRegularWorks(offset);

            this.setState((prevState) => ({
                offset,
                possibleRegularWorks : [...prevState.possibleRegularWorks, ...list],
                possibleLoading : false,
                count
            }))
        }
    };
    //
    // onChangeRegularWork = (categoryId: number, active:number[]) => {
    //     console.log({active, categoryId})
    //
    // };

    resetState = async () => {
        this.setState(() => ({
            possibleLoading : true,
            possibleRegularWorks : [],
            regularWorks : [],
        }));

        const {list, count} = await this.getRegularWorks(0);

        this.setState(() => ({
            regularWorks : this.props.regularWorks ?  this.props.regularWorks : [],
            possibleRegularWorks : list,
            possibleLoading : false,
            offset : 0,
            count
        }));

    };

    async componentDidMount(){
        this.resetState()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any){
        if(JSON.stringify(this.props.regularWorks) !== JSON.stringify(prevProps.regularWorks)) {
            this.resetState()
        }
    }

    declineRegularWorks = async (rwId, reason) => {
        try {
            this.setState({isLoading : true})
            await declineRegularWorks(rwId, reason)
        } catch(err){
            checkError(err);
        } finally {
            this.setState({isLoading : false})
            this.props.requestWorkOrder()
        }
    }

    removeHandler = async (rwId) => {
        await this.props.openModal({
            id : 'ordersDeclineRegularWo',
            title : 'Снятие регламентной работы',
            component : (props) => < DeclineReasonModal save={reason => this.declineRegularWorks(rwId, reason)} {...props} />
        });
    }

    render() {
        const {possibleRegularWorks, regularWorks, isLoading} = this.state;
        const {workOrder} = this.props;

        return (
            <div style={{width : '100%', display : 'flex', flexDirection : 'column'}}>
                <Spinner loading={isLoading}/>
                <h4>{regularWorks && regularWorks.length > 0 ? getText('workorders.checklistHas') : getText('workorders.checklistHasNo')}</h4>
                <div className="workOrder__regularWorks">
                    <RenderIf condition={regularWorks && Array.isArray(regularWorks)}>
                        <>
                            <ChecklistTreeController
                                regularWorks={(regularWorks as RegularWork[])}
                                onChangeActiveValues={this.props.onChangeRegularWorkCheckbox}
                                deleteRw={this.deleteChecklistItems}
                                isWorkorderClosed={workOrder?.closed}
                                changeActNumber={this.props.onChangeActNumber}
                                removeHandler={this.removeHandler}
                            />
                        </>

                    </RenderIf>
                </div>
                <RenderIf condition={!this.props.workOrder.closed && possibleRegularWorks && possibleRegularWorks.length > 0}>
                    <div className="possibleRegularWorks">
                        <h4>{getText('workorders.possibleRegularWorksToWorkorder')}: </h4>

                        <ItemList
                            loading={this.state.possibleLoading}
                            renderItem={this.renderPossibleItem}
                            list={possibleRegularWorks}
                            updateData={this.updateRegularWorks}
                        />
                    </div>
                </RenderIf>

            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (props:Modal) => {
            dispatch(openModal(props))
        },
    };
};

export default connect(null, mapDispatchToProps)(ChecklistWorkOrder)
