import React from 'react'
import {
	addPersonExecutorRegion,
	deletePersonExecutorRegion,
	ExecutorRegion,
} from '@justpro/terminal'
import Table from '../../../UI/table/table'
import { LoadReturn, Option } from '../../../UI/select/select.types'
import Select from '../../../UI/select/select'
import checkError from '../../../../utils/checkError'
import { getRegionsList } from '../../../../utils/functions'
import { getUniqueList } from '../../../../utils/getUniqueArray'
import { connect } from 'react-redux'
import { ApplicationReducer } from '../../../../store/application/application.types'
import DeleteCell from '../../../UI/table/deleteCell'
import getText from '../../../../localization/getText'

interface Props {
	personId: number
	executorPersonId: number
	rights: any
	regions?: ExecutorRegion[]
}

interface State {
	regions: ExecutorRegion[]
}

class RegionsTable extends React.Component<Props, State> {
	state: State = {
		regions: [],
	}

	getAvailableRegions = (): LoadReturn => {
		return new Promise(async (resolve) => {
			const regions = await getRegionsList()

			const unique = getUniqueList(regions, this.state.regions, {
				wholeId: 'id',
				existId: 'regionId',
			})

			resolve(unique)
		})
	}

	addRegion = async (option: Option) => {
		try {
			const region = await addPersonExecutorRegion({
				regionId: option.value,
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
			})

			if (region) {
				this.setState((prevState) => ({
					regions: [...prevState.regions, region],
				}))
			}
		} catch (e) {
			checkError(e)
		}

		return Promise.resolve()
	}

	deleteRegion = async (props: any) => {
		try {
			const deleted = await deletePersonExecutorRegion({
				personId: this.props.personId,
				executorPersonId: this.props.executorPersonId,
				executorRegionId: props.id,
			})

			if (deleted) {
				this.setState((prevState) => ({
					regions: prevState.regions?.filter(
						(r) => r.id !== props.id,
					),
				}))
			}
		} catch (e) {
			checkError(e)
		}
	}

	getColumns = () => {
		const { rights } = this.props
		const result: any[] = [
			{
				Header: getText('regions.region'),
				accessor: 'name',
			},
		]

		if (
			rights &&
			rights['references.persons'] &&
			!!rights['references.persons']['delete']
		) {
			result.push({
				Header: getText('common.delete'),
				Cell: (props: any) => (
					<DeleteCell
						deleteHandler={this.deleteRegion.bind(
							this,
							props.original,
						)}
						item={props.original}
					/>
				),
			})
		}
		return result
	}

	resetState = () => {
		this.setState(() => ({
			regions: this.props.regions ? this.props.regions : [],
		}))
	}

	componentDidMount(): void {
		this.resetState()
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.resetState()
		}
	}

	render() {
		const { regions } = this.state
		const { rights } = this.props

		return (
			<>
				{regions && regions.length > 0 && (
					<Table columns={this.getColumns()} data={regions} />
				)}

				{rights &&
					rights['references.persons'] &&
					!!rights['references.persons']['edit'] && (
						<Select
							change={this.addRegion}
							load={this.getAvailableRegions}
							placeholder='persons.linkRegionToExecutor'
							withoutValue={true}
							accessors={{
								name: 'label',
								id: 'value',
							}}
							className={
								regions && regions.length > 0
									? 'margin-top'
									: ''
							}
						/>
					)}
			</>
		)
	}
}

interface MapStateToProps {
	application: ApplicationReducer
}

const mapStateToProps = (state: MapStateToProps) => ({
	rights: state.application.rights,
})

export default connect(mapStateToProps)(RegionsTable)
