import React from 'react';
import AddUserDetail from "../addUser/addUser";
import RolesDetail from "../role/roles";
import RightsDetail from "../group/group";
import UserDetail from "../user/user";
import {PanelsProps} from './detail.types';

const Detail = (props:PanelsProps) => {
    const {panel, q} = props;

    return (
        <>
            {
                panel === 'add' ? <AddUserDetail /> :
                panel === 'role'   ? <RolesDetail /> :
                panel === 'group'  ? <RightsDetail /> :
                panel === 'user'    ? <UserDetail q={q} {...props}/> : <></>
            }
        </>
    )
};

export default Detail;