import React, {Component} from 'react';
import {editEquipmentWork, EditEquipmentWork, EquipmentWork, getEquipmentWork} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import EquipmentWorkDetail from "./equipmentWork.detail";

interface Props {
    id : number
    afterUpdate?(item:EquipmentWork) : void
}

interface State {
    equipmentWork?: EquipmentWork
    loading : boolean
}



class EquipmentWorkController extends Component<Props, State> {

    state:State = {
        loading : false
    };

    update = async (data:Partial<EditEquipmentWork>) => {
        const {afterUpdate} = this.props;

        try {
            this.setState(() => ({loading : true}));
            const equipmentWork = await editEquipmentWork(this.props.id, data);

            if(data.hasOwnProperty('active') || data.hasOwnProperty('name')) {
                afterUpdate && afterUpdate(equipmentWork);
            }

            this.setState(() => ({loading : false, equipmentWork}));

        }catch (e) {
            this.setState(() => ({loading : false}));

            checkError(e)
        }
    };

    getEquipmentWork = async () => {
        try {
            this.setState(() => ({loading : true}));
            const equipmentWork = await getEquipmentWork(this.props.id);
            this.setState(() => ({loading : false, equipmentWork}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getEquipmentWork();
        }
    }

    async componentDidMount() {
        this.getEquipmentWork();
    }

    render() {
        const {equipmentWork} = this.state;
        return (
            <>
                <RenderIf condition={equipmentWork !== undefined}>
                    <EquipmentWorkDetail update={this.update} equipmentWork={equipmentWork as EquipmentWork} />
                </RenderIf>
            </>
        );
    }
}

export default EquipmentWorkController;