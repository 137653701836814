import React from "react";
import {Contractor, editContractor, EditContractor, getContractor} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import RenderIf from "../../../utils/renderIf";
import ContractorDetail from "./contractor.detail";

interface Props {
    id : number
    afterUpdate?(item:Contractor) : void
}
interface State {
    loading : boolean
    contractor?: Contractor
}

class ContractorController extends React.Component<Props, State>{

    state:State = {
        loading : false
    };

    updateContractor = async (data:Partial<EditContractor>) => {
        this.setState(() => ({loading : true}));

        try {
            const contractor = await editContractor(this.props.id, data);

            this.props.afterUpdate
            && (
               data.hasOwnProperty('active') ||
               data.hasOwnProperty('name')
            )
            && this.props.afterUpdate(contractor);

            this.setState(() => ({loading : false, contractor}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getContractor = async () => {
        this.setState(() => ({loading : true}));
        try {
            const contractor = await getContractor(this.props.id);

            this.setState(() => ({loading : false, contractor}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getContractor()
        }
    }

    async componentDidMount() {
        this.getContractor();
    }

    render() {

        const {contractor} = this.state;
        return (
            <>

                <RenderIf condition={contractor !== undefined}>
                    <ContractorDetail
                        contractor={contractor as Contractor}
                        updateContractor={this.updateContractor} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }
}

export default ContractorController