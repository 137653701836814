import React, {useCallback, useState} from 'react';
import withLabel from "../withLabel/withLabel";
import {Color, ColorResult, ChromePicker} from 'react-color';


interface Props {
    label? : string
    defaultValue? : string
    className? : string

    blur(value:string, oldValue?:string):void

    [key:string] : any

}

export default (props:Props) => {
    const {defaultValue, blur, className, label,  ...rest} = props;
    const cls = ['just-pro-colorInput']; //todo styles

    const [color, setColor] = useState('#fff');

    if(className) {
        cls.push(className)
    }


    const onSubmit = (e:React.FormEvent) => {
        e.preventDefault();
    };

    const ref = useCallback(() => {
        setColor(defaultValue ? defaultValue : '#fff')
    }, [defaultValue]);

    const onColorComplete = (color:ColorResult) => {
        setColor(color.hex);
        blur(color.hex)
    };

    const jsx = <form onSubmit={onSubmit}>
        <ChromePicker
            color={color}
            onChangeComplete={onColorComplete}
            ref={ref}
            {...rest}
        />
    </form>;


    return label ? withLabel(jsx)({text : label}) : jsx

}