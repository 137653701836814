import React from 'react';
import {
    ContractCondition,
    editContractCondition,
    getContractCondition
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import RenderIf from "../../../utils/renderIf";
import Spinner from "../../UI/spinner/spinner.controller";
import {getIconsList} from "../../../utils/icons";
import ContractConditionDetail from "./contractCondition.detail";

const icons = getIconsList();

interface Props {
    id: number
    afterUpdate?(item:ContractCondition) : void
}

interface State {
    loading : boolean
    contractCondition?: ContractCondition
}

class ContractConditionController extends React.Component<Props, State>{
    state:State = {
        loading : false
    };

    updateContractCondition = async (data:Partial<ContractCondition>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {

            const contractCondition = await editContractCondition(this.props.id, data);

            afterUpdate && afterUpdate(contractCondition);

            this.setState(() => ({loading : false, contractCondition}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getContractCondition = async () => {
        this.setState(() => ({loading : true}));

        try {
            const contractCondition = await getContractCondition(this.props.id);

            this.setState(() => ({loading : false, contractCondition}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getContractCondition()
        }
    }

    async componentDidMount() {
        this.getContractCondition();
    }

    render () {
        const {contractCondition} = this.state;

        return (
            <>
                <RenderIf condition={contractCondition !== undefined}>
                    <ContractConditionDetail
                        contractCondition={contractCondition as ContractCondition}
                        updateContractCondition={this.updateContractCondition}
                        icons={icons}
                    />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default ContractConditionController