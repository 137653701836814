import React from "react";
import {ApplicationReducer} from "../../../store/application/application.types";
import {connect} from "react-redux";
import '../header.css'
import {getFullName} from "../../../utils/names";

interface Props {
    me?: any
}

interface State {
    tooltip : boolean
}

class PersonName extends React.Component<Props, State>{

    state:State = {
        tooltip : false
    };

    onMouseEnterHandler = (e:React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            tooltip : true
        })
    };

    onMouseLeaveHandler = (e:React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            tooltip : false
        })
    };

    render() {
        const fullName = getFullName(this.props?.me?.person, false);
        const person = this.props?.me?.person;
        if(!person || !fullName) return null;
        const firstLetters = person?.firstName?.[0] + (person?.lastName?.[0] || '');
        return <div className="header-person"
                    onMouseEnter={this.onMouseEnterHandler}
                    onMouseLeave={this.onMouseLeaveHandler}
        >
            <div className="header-person__name">
                {firstLetters}
            </div>

            <div
                style={{left : `-${fullName.length * 7.5}px`, display : this.state.tooltip ? 'block' : 'none' }}
                className={`header-person__tooltip ${this.state.tooltip ? 'header-person__tooltip--open' : ''}`}
            >
                {fullName}
            </div>
        </div>
    }
}

interface MapState {
    application : ApplicationReducer
}

const mapStateToProps = (state:MapState) => ({
    me : state.application.me
});

export default connect(mapStateToProps)(PersonName)