import React,{ Component } from 'react';
import checkError from '../../../utils/checkError';
import Spinner from '../../UI/spinner/spinner.controller';
import SettingDetail from './globalSetting.detail';
import RenderIf from '../../../utils/renderIf';
import { editGlobalSetting, getGlobalSetting, GlobalSetting } from '@justpro/terminal';

interface Props {
  id: number;
  afterUpdate?(item: GlobalSetting): void;
}
interface State {
  setting?: GlobalSetting;
  loading: boolean;
}

class SettingController extends Component<Props, State> {
  state: State = {
    loading: false,
  };

  updateSetting = async (value: string) => {
    const { afterUpdate } = this.props;
    this.setState({ loading: true });
    try {
      const globalSetting = await editGlobalSetting(this.props.id, value);
      const setting = { ...globalSetting, active: true};
      this.setState({
        setting
      } as any, () => {
        afterUpdate && afterUpdate(setting);
      })
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  getSetting = async () => {
    this.setState({ loading: true });
    try {
      const setting = await getGlobalSetting(this.props.id);
      this.setState(() => ({
        setting: { ...setting, active: true },
      }));
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.id !== prevProps.id) {
      this.getSetting();
    }
  }

  async componentDidMount() {
    this.getSetting();
  }

  render() {
    const { setting } = this.state;

    return (
      <>
        <RenderIf condition={setting !== undefined}>
          <SettingDetail
            setting={setting as GlobalSetting}
            update={this.updateSetting}
          />
        </RenderIf>

        <Spinner loading={this.state.loading} />
      </>
    );
  }
}

export default SettingController;
