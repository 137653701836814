import {AnyAction} from "redux";
import {GroupsMap} from "@justpro/terminal";

export type ParentGroup = {
    name : string,
    id : number
}

export type GroupsReducer = {
    map : GroupsMap,
    id : number,
    name : string,
    parent?: ParentGroup,
    parentList : ParentGroup[]
}

const initialState:GroupsReducer = {
    id : -1,
    map : {},
    name : '',
    parentList : [],
};

export const groupTypes = {
    UPDATE_GROUP: 'UPDATE_GROUP',
    DELETE_GROUP : 'DELETE_GROUP',
    CREATE_GROUP : 'CREATE_GROUP',
    UPDATE_GROUP_MAP : 'UPDATE_GROUP_MAP',
    GET_GROUP : 'GET_GROUP'
};


export default (state:GroupsReducer = initialState, action: AnyAction) => {
    const {type, ...rest} = action;

    switch(type) {
        case groupTypes.UPDATE_GROUP_MAP :
        case groupTypes.UPDATE_GROUP :
        case groupTypes.DELETE_GROUP :
        case groupTypes.CREATE_GROUP :
        case groupTypes.GET_GROUP :
            return {
                ...state,
                ...rest
            };
        default :
            return state;

    }
}