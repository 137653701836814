import React from 'react';
import {
    AddFile,
    EditEquipment, Equipment,
    File as ServerFile, addEquipmentPhotos, deleteEquipmentPhoto, ModulesResponse,
} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import withLabel from "../../UI/withLabel/withLabel";
import moment from "moment";
import Select from "../../UI/select/select";
import {Option} from "../../UI/select/select.types";
import {getEquipmentTypesList, getObjectsList} from "../../../utils/functions";
import EquipmentPhotos from "../../UI/equipmentPhotos/equipmentPhotos.controller";
import checkError from "../../../utils/checkError";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";
import {getObjectName} from "../../../utils/names";
import getText from "../../../localization/getText";

interface Props {
    equipment : Equipment
    update(data:Partial<EditEquipment>) : void
    rights?: Partial<ModulesResponse>
}


interface State {
    photos? : ServerFile[],
    defaultObject? : any
}


class EquipmentDetail extends React.Component<Props, State>{

    state:State = {};

    toggleActive = (active:boolean) => {
        this.props.update({active});
    };

    changeNumber = (number:string, oldVal:string) => {
        if(number !== oldVal && number.trim() !== '') {
            this.props.update({number})
        }
    };

    changeObject = (option:Option) => {
        this.props.update({objectId : option.value});
        return Promise.resolve();
    };

    changeEquipmentType = (option:Option) => {
        this.props.update({equipmentTypeId : option.value});
        return Promise.resolve();
    };

    deletePhoto = async (photoId:number) => {
        try {
            const deleted = await deleteEquipmentPhoto(this.props.equipment.id, photoId);

            if(deleted) {
                this.setState((prevState) => ({
                    photos: prevState.photos?.filter(item => item.id !== photoId)
                }))
            }


        }catch (e) {
            checkError(e)
        }
    };

    addPhotos = async (photos:File[]) => {
        const addFiles:AddFile[] = photos.map((item:File) => {
            return {
                name : item.name,
                blob : item
            }
        });

        try {
            const photos = await addEquipmentPhotos(this.props.equipment.id, addFiles);

            this.setState((prevState) => ({
                photos : prevState.photos ? [...prevState.photos, ...photos] : photos
            }))

        }catch (e) {
            checkError(e)
        }

    };

    resetState = () => {
        this.setState(() => ({
            photos : this.props.equipment.photos,
            defaultObject : {
                ...this.props?.equipment?.object,
                name : getObjectName(this.props?.equipment?.object),

            }
        }))
    };


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.equipment.id !== this.props.equipment.id) {
            this.resetState();
        }
    }

    componentDidMount(): void {
        this.resetState()
    }

    render() {
        const {equipment, rights} = this.props;
        const {number, active, createdAt, object, equipmentType} = equipment;
        const {photos} = this.state;
        const disabled = rights && rights['references.equipments'] && !rights['references.equipments']['edit'];



        return (
            <>
                <h4>{getText('equipments.equipment')}: {equipmentType?.name} {number}</h4>
                {withLabel(<>{moment(createdAt).format('DD.MM.YYYY')}</>)({text : 'common.created'})}
                <ToggleSwitch label="common.active" defaultValue={active} send={this.toggleActive} disabled={disabled}/>
                <Input blur={this.changeNumber} label="common.number" startValue={number} disabled={disabled}/>

                <AsyncSelect
                    change={this.changeObject}
                    loadOptions={(q:string) => groupObjectsByRegion({q})}
                    defaultValue={{
                        value : this.state.defaultObject,
                        accessors : {name : 'label', id : 'value'}
                    }}
                    isDisabled={disabled}
                    label={{ text : "objects.object" }}
                />

                <Select
                    change={this.changeEquipmentType}
                    load={getEquipmentTypesList}
                    defaultValue={equipmentType ? equipmentType : {}}
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                    isDisabled={disabled}
                    label="equipmentTypes.equipmentType"
                    placeholder="UI.select.placeholders.selectEquipmentType"
                />

                <EquipmentPhotos photos={photos ? photos : []} label="common.photo" deletePhoto={this.deletePhoto} addPhotos={this.addPhotos} isDisabled={disabled}/>
            </>
        )
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(EquipmentDetail)