import React from 'react';
import {createEquipmentCategory, EquipmentCategory, getEquipmentCategories, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import EquipmentCategoryController from "./equipmentCategory.controller";
import EquipmentCategoryCreate from "./equipmentCategory.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class EquipmentCategoriesController extends React.Component<Props>{

    renderItem = (item:EquipmentCategory) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.equipmentCategories'];
        return <BaseReferenceController
            referenceName="equipmentCategories.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getEquipmentCategories}
            Detail={EquipmentCategoryController}
            create={{
                Component : EquipmentCategoryCreate,
                title : 'equipmentCategories.addItem',
                handler : createEquipmentCategory
            }}
            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})

export default connect(mapState)(EquipmentCategoriesController)