import React from 'react';
import {
    addRegularWorkComment,
    unmarkRegularWorkComment,
    getRegularWorkComments,
    Comment,
    AddFile, ModulesResponse
} from '@justpro/terminal'
import Comments from "../../../UI/comments/comments.controller";
import checkError from "../../../../utils/checkError";
import {ApplicationMapState} from "../../../application/application.controller";
import {connect} from "react-redux";
import {toast} from "react-toastify";

interface Props {
    regularWorkId: number

    rights?: Partial<ModulesResponse>
}

interface State {
    comments: Comment[],
    loading: boolean
}

const MAX_FILE_SIZE = 1024 * 1024 * 30;
class RegularWorksComments extends React.Component<Props, State> {

    state: State = {
        comments: [],
        loading: false
    };

    getComments = async () => {
        const {rights} = this.props;
        const canRead = rights && rights['regularWorks.comments'] && rights['regularWorks.comments']['read'];

        if (canRead) {
            this.setState(() => ({loading: false}));

            try {
                const comments = await getRegularWorkComments(this.props.regularWorkId);

                this.setState(() => ({
                    loading: false,
                    comments
                }))
            } catch (e) {
                checkError(e)
            }
            this.setState(() => ({loading: false}))
        }

    };

    addComment = async (comment: string, files?: AddFile[]) => {
        const {rights} = this.props;
        if(!comment?.trim?.()?.length){
            return toast.warn(`Нельзя отправить пустой комментарий`);
        }
        const canCreate = rights && rights['regularWorks.comments'] && rights['regularWorks.comments']['create'];

        if (canCreate) {
            this.setState(() => ({loading: true}));

            try {

                const newComment = await addRegularWorkComment(this.props.regularWorkId, {
                    message: comment,
                    files
                });

                this.setState((prevState) => ({
                    comments: [...prevState.comments, newComment],
                    loading: false
                }))

            } catch (e) {
                this.setState(() => ({loading: false}));

                checkError(e)
            }
        }

    };

    unmarkComment = async (commentId: number) => {
        const {rights} = this.props;

        const canUnmark = rights?.['regularWorks.comments']?.read;
        if (canUnmark) {
            this.setState({
                loading: true
            });
            try {
                await unmarkRegularWorkComment(this.props.regularWorkId, commentId);
                await this.getComments();
            } catch (e) {
                checkError(e)
            } finally {
                this.setState(
                    {loading: false
                    });
            }
        }
    };


    async componentDidMount() {
        return this.getComments()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.regularWorkId !== this.props.regularWorkId) {
            await this.getComments()
        }
    }


    render() {

        return (
            <div>
                <Comments
                    comments={this.state.comments}
                    addComment={this.addComment}
                    readComment={this.unmarkComment}
                    buttonsAlwaysVisible
                    maxFileSize={MAX_FILE_SIZE}
                    rightsName="regularWorks.comments"
                />
            </div>
        )
    }
}


const mapState = (state: ApplicationMapState) => ({
    rights: state.application.rights
});
export default connect(mapState)(RegularWorksComments)