import React from "react";
import {
    RegularStatus,
    getRegularStatus,
    updateRegularStatus
} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import RegularStatusDetail from "./regularStatus.detail";

interface Props {
    id: number
    afterUpdate?(region:RegularStatus) : void
}

interface State {
    regularStatus? : RegularStatus
    loading : boolean
}

class RegularStatusController extends React.Component<Props, State>{
    state:State = {
        loading : false,
    };


    getRegularStatus = async () => {

        try {
            this.setState(() => ({loading : true}));

            const regularStatus = await getRegularStatus(this.props.id);

            this.setState(() => ({loading : false, regularStatus}))

        }catch (e) {
            checkError(e)
        }
    };

    updateRegularStatus = async (data:Partial<RegularStatus>) => {

        try {
            this.setState(() => ({loading : true}));

            const regularStatus = await updateRegularStatus(this.props.id, data);

            this.setState(() => ({
                regularStatus : regularStatus,
                loading : false
            }));

            this.props.afterUpdate && this.props.afterUpdate(regularStatus)
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

    };


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getRegularStatus()
        }
    }

    async componentDidMount() {
        this.getRegularStatus();
    }

    render () {
        const {regularStatus} = this.state;
        return (
            <>
                <RenderIf condition={regularStatus !== undefined}>
                    <RegularStatusDetail regularStatus={regularStatus as RegularStatus} update={this.updateRegularStatus}/>
                </RenderIf>
            </>
        )
    }
}

export default RegularStatusController