import React from 'react';
import {IButton} from './interfaces'

import './button.css'
import Tooltip from "../tooltip/tooltip.controller";


const Button = (props:IButton) => {
    let {
        className,
        text,
        icon,
        title,
        titlePosition,
        ...rest
    } = props;
    let cls = ['btn just-pro-button'];

    if(className) {
        cls.push(className);
    }
    const position = titlePosition ? titlePosition : 'down';
    return title ? (
            <Tooltip title={title} position={position}>
                <button
                    {...rest}
                    type="button"
                    className={cls.join(' ')}
                >
                    {props.children}
                </button>
            </Tooltip>
        ) : (
            <button
            {...rest}
                type="button"
                className={cls.join(' ')}
            >
                {props.children}
            </button>
        )
};

export default Button;

