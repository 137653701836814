import {FiltersStore, initialState} from "./filters.types";
import {AnyAction} from "redux";

export const filterActions = {
    SET_FILTER : 'SET_FILTER',
};

export default (state:FiltersStore = initialState, {type, ...rest}:AnyAction) => {

    switch (type) {
        case filterActions.SET_FILTER:
            const {values, filterName} = rest;
            return {
                ...state,
                filters : {
                    ...state.filters,
                    [filterName] : values
                }
            }

    }

    return state
}