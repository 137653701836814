import React from "react";
import {AvailableModules, createFilter, deleteFilters, editFilter, getFilters, IFilter} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import {Option} from "../select/select.types";
import './savedFilters.css'
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import {toast} from "react-toastify";
import Tooltip from "../tooltip/tooltip.controller";
import {renderToString} from "react-dom/server";
import getText from "../../../localization/getText";
import {connect} from "react-redux";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {confirm} from "../confirmAction/confirmAction.controller";
import SavedFilterModal from "./savedFilterModal";

export interface SavedFiltersProps {
    module : AvailableModules,
    setFilters(filters:any) : void
    getCurrentFilters() : {[key:string] : any}
    openModal?(modal:Modal) : void
}


interface State {
    filters : IFilter[]
    value?: Option

    isOpen : boolean
}




class SavedFilter extends React.Component<SavedFiltersProps, State>{

    defaultValue = {
        id : -1,
        name : renderToString(getText('UI.savedFilters.placeholder')),
        filters : {},
        modelName : '',
        isOpen : false
    };

    state:State = {
        filters : [],
        isOpen : false
    };
    getFilters = async () => {

        try {
            const moduleFilters = await getFilters(this.props.module);

            if(moduleFilters) {
                this.setState(() => ({
                    filters : [this.defaultValue, ...moduleFilters],
                    value : {
                        ...makeNormalizeParams(this.defaultValue, {name : 'label', id : 'value'})
                    }
                }))
            }

        }catch (e) {
            checkError(e)
        }
    };

    onChangeFilter = (option:Option) => {
        this.setState(() => ({value : option}));

        if(option.filters) {
            this.props.setFilters(option.filters)
        }

        return Promise.resolve();
    };


    openModal = () => {

        this.props?.openModal({
            id : 'createNewFilter',
            title : getText('UI.savedFilters.newFilter'),
            size : "small",
            component: (props:ModalBodyProps) =>  <SavedFilterModal {...props} save={this.saveFilter}/>
        });

    };


    saveFilter = async (name:string) => {

        try {
            const filters = this.props.getCurrentFilters();


            const newFilter = await createFilter(this.props.module, {
                filters,
                name,
            });

            if(newFilter) {
                this.setState((prevState) => ({
                    filters : [...prevState.filters, newFilter],
                }))
            }


        }catch (e) {
            checkError(e)
        }

    };

    openQuestionMark = async () => {

        const agreed = await confirm({
            title : 'Отредактировать фильтр',
            question : (
                <>
                    <p>Изменить текущий набор фильтров на выбраный в данный момент?</p>
                </>
            ),
            confirmText : getText('common.edit'),
        });

        if(agreed) {
            this.editFilter();
        }

    };


    editFilter = async () => {
        const currentFilters = this.props.getCurrentFilters();
        const {value} = this.state;
        try {
            if(value && value.value) {
                const newFilters = await editFilter(value.value, currentFilters);

                this.setState((prevState) => ({
                    filters : prevState.filters.map((item) => {

                        if(value.value === item.id) {
                            return newFilters
                        }
                        return item
                    }),
                }))
            }else{
                toast.warn('Id элемента не выбрано.')
            }
        }catch (e) {
            checkError(e)
        }
    };

    deleteFilter = async () => {
        const {value} = this.state;
        if(value && value.value !== -1) {
            try {

                const deleted = await deleteFilters([value.value]);

                if(deleted) {
                    const defaultOption = {
                        ...this.defaultValue,
                        label : this.defaultValue.name,
                        value : this.defaultValue.id
                    };

                    this.setState((prevState) => ({
                        filters : prevState.filters.filter(item => item.id !== value.value),
                        value : defaultOption
                    }), this.onChangeFilter.bind(this, defaultOption))
                }

            }catch (e) {
                checkError(e)
            }
        }
    };

    toggleIsOpen = () => {
        this.setState((prevState) => ({
            isOpen : !prevState.isOpen
        }))
    };

    documentEvent = (e:MouseEvent) => {
        const {isOpen} = this.state;

        if(isOpen) {
            //@ts-ignore
            const closest = e?.target?.closest('.save-filter');

            if(!closest) {
                this.setState({isOpen: false})
            }
        }
    };


    async componentDidMount(){
        this.getFilters();
        document.addEventListener('click', this.documentEvent)
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.documentEvent)
    }

    render () {
        const {value} = this.state;

        return <div className="saved">

            {value && value.value === -1 &&
                <Tooltip title={renderToString(getText('UI.savedFilters.save'))}>
                    <label
                        className="fas fa-save save-filters-button"
                        onClick={this.openModal}
                    />
                </Tooltip>

            }

            {value && value.value > 0 &&
                <Tooltip title={renderToString(getText('UI.savedFilters.edit'))}>
                    <label
                        className="fas fa-edit save-filters-button"
                        onClick={this.openQuestionMark}
                    />
                </Tooltip>
            }
                    <div className="save-filter">

                        <div
                            className={`cs-select ${this.state.isOpen ? 'cs-active' : ''}`}
                            onClick={this.toggleIsOpen}
                        >
                            <span className="cs-placeholder">{value && value.label}</span>
                            <div className="cs-options">
                                <ul>
                                    {this.state.filters?.map(item => {
                                        return <li key={item.id} onClick={this.onChangeFilter.bind(this, {
                                            ...item,
                                            value : item.id,
                                            label : item.name
                                        })}><span>{item.name}</span></li>
                                    })}
                                </ul>
                            </div>

                        </div>
                    </div>

                    <Tooltip title={renderToString(getText('UI.savedFilters.clear'))}>
                        <label className="fas fa-trash del-filter-button" onClick={this.deleteFilter} />
                    </Tooltip>

                </div>;

    }
}

const mapDispatch = (d:Function) => ({
    openModal: (props:Modal) => d(openModal(props))
});

export default connect(null, mapDispatch)(SavedFilter)