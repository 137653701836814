import React, {useEffect, useState} from 'react'
import {
    secondsToTime,
    IActsFilterParams,
    getActs,
    format,
    getWorkorders,
    WorkordersFilterParams,
    timeToSeconds
} from '@justpro/terminal'
import getText from "../../localization/getText";
import RenderIf from "../../utils/renderIf";
import {connect} from 'react-redux';
import checkError from '../../utils/checkError';
import Spinner from '../UI/spinner/spinner.controller';
import Dropdown from '../UI/dropdownMenu/dropdown.controller';
import {calcWorkorderDuration, getDuratiuon, getSecondsStartTime} from './calendarUtils';
import {renderToString} from 'react-dom/server';
import {CalendarItem} from './workordersCalendar.controller'
import WorkOrderDetail from '../workOrders/workOrder.controller';
import ActController from '../acts/act/act.controller';
import {MAX_WAYPOINTS} from "./calendar.controller";

const availableMods = [
    {
        name: renderToString(getText('acts.act')),
        id: 1
    },
    {
        name: renderToString(getText('workorders.workorder')),
        id: 2
    }
]

const mapState = (state) => ({settings: state?.users?.user?.settings});
export const FactData = connect(mapState)(({selectedExecutor, selectedDate, settings, updateDirections}: any) => {

    const [isLoading, setIsLoading] = useState(false)
    const [acts, setActs] = useState([])
    const [workorders, setWorkorders] = useState([])
    const [distanceTimes, setDistanceTimes] = useState([])

    const [mode, setMode] = useState(1)
    const handleSelectedMode = (mode: any) => {
        setMode(mode.id)
    }

    const getDefaultBasePoint = () => {
        const {basePoints} = selectedExecutor || {};
        let basePoint;
        if (!basePoints) {
            return;
        }
        if (settings?.basePointId) {
            basePoint = basePoints?.find(point => point.objectId === settings.basePointId)
        }
        if (!settings?.basePointId) {
            basePoint = basePoints?.[0]
        }
        return basePoint;
    }

    useEffect(() => {
        if (mode === 1 && selectedExecutor) {
            requestActs();
        }
        if (mode === 2 && selectedExecutor) {
            requestWorkorders()
        }
    }, [mode, selectedExecutor, selectedDate]);


    const buildRoutes = async (objects) => {
        return new Promise((resolve, reject) => {
            objects = objects.filter(object => object?.latitude)
            const {google}: any = window;
            const travelMode = 'DRIVING';
            const allPoints = objects.map((object) => {
                return {
                    lat: object.latitude,
                    lng: object.longitude
                };
            }).slice(0, MAX_WAYPOINTS);
            const directionsService = new google.maps.DirectionsService();

            const routeParams = {
                travelMode: travelMode,
                origin: allPoints[0],
                destination: allPoints[allPoints.length - 1],
                waypoints: allPoints.slice(0, -1).map(location => ({
                    location,
                })),
            };

            directionsService.route(routeParams, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    updateDirections(result);
                    const distanceTimes = result?.routes?.[0]?.legs?.map(({distance, duration}) => ({
                        distance: distance?.value,
                        duration: duration?.value,
                    }));
                    setDistanceTimes(distanceTimes);
                    resolve(distanceTimes);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        });

    };

    const requestActs = async () => {
        try {
            setIsLoading(true);
            const params: Partial<IActsFilterParams> = {
                orders: '1',
                coworkersId: [selectedExecutor?.id],
                dateFrom: selectedDate,
                dateTo: selectedDate,
                sortBy: "createdAt",
                sortOrder: "ASC"
            }
            const acts = await getActs(params)
            const objects = acts?.list.map(act => act.object)
            const basePoint = getDefaultBasePoint();
            if(!basePoint){
                throw new Error(`Отсутствует базовая точка выезда`)
            }
            await buildRoutes([basePoint, ...objects, basePoint]);
            setActs(acts?.list?.map((act: any) => {
                act.from = timeToSeconds(act.coworkers.find((cw) => cw.personId === selectedExecutor.id)?.executedTime?.[0]?.from);
                act.duration = timeToSeconds(act.coworkers.find((cw) => cw.personId === selectedExecutor.id)?.executedTime?.[0]?.to) - act.from;
                return act;
            }));
        } catch (e) {
            checkError(e)
        } finally {
            setIsLoading(false)
        }
    }

    const requestWorkorders = async () => {
        try {
            setIsLoading(true);
            const params: Partial<WorkordersFilterParams> = {
                closed: '1',
                orders: '1',
                responsiblesId: [selectedExecutor?.id],
                dateFrom: selectedDate,
                dateTo: selectedDate,
                sortBy: "createdAt",
                sortOrder: "ASC"
            }
            const workorders: any = await getWorkorders(params);
            const objects = workorders?.list?.map((w) => w.object);
            const basePoint = getDefaultBasePoint();
            const distanceTimes = await buildRoutes([basePoint, ...objects, basePoint]);
            const preparedWorkorders = [];
            workorders?.list?.forEach((workorder, index, list) => {
                const roadDuration = distanceTimes?.[index + 1]?.duration || 0;
                const prevWorkorder = preparedWorkorders[preparedWorkorders?.length - 1] || null;
                workorder.from = index === 0 ? 25200 + roadDuration : prevWorkorder?.from + prevWorkorder?.duration + roadDuration;
                workorder.duration = calcWorkorderDuration(workorder?.orders);
                preparedWorkorders.push(workorder);
            })
            setWorkorders(preparedWorkorders);
        } catch (e) {
            checkError(e)
        } finally {
            setIsLoading(false)
        }
    }

    const getTotalDistance = () => {
        return (distanceTimes.reduce(
            (total, {distance}) => distance + total,
            0
        ) / 1000).toFixed(2)
    }
    const getTotalDuration = () => {
        return distanceTimes.reduce(
            (total, {duration}) => duration + total,
            0
        );
    }
    const getTotalWorkDuration = () => {
        if (mode === 2) {
            return workorders?.reduce((total, workorder) => {
                return total + workorder.duration;
            }, 0);
        }
        if (mode === 1) {
            return acts?.reduce((total, act) => {
                return total + act.duration;
            }, 0);
        }
    }
    const basePoint = getDefaultBasePoint();
    return (
        <div className='calendar_workorders-wrapper'>
            <Spinner loading={isLoading}/>
            <div className='calendar_workorders-header'>
                <Dropdown
                    onChange={handleSelectedMode}
                    list={availableMods}
                />
            </div>
            <div className='calendar_workorders-body'>
                <RenderIf condition={acts?.length || workorders?.length}>
                    <div className="calendar_basepoint">
                            <span className="calendar_basepoint_title">
                                {basePoint?.address}
                            </span>
                    </div>
                </RenderIf>
                <RenderIf condition={mode === 1}>
                    {acts?.map((act, index) => {
                        const lastAct = acts[index - 1];
                        return (
                            <div className='item_basepoint'>
                                <CalendarItem
                                    id={act.id}
                                    key={act.id}
                                    factMode
                                    from={act?.from}
                                    duration={act?.duration}
                                    roadDuration={act.from - (lastAct?.from || 0) - (lastAct?.duration || 0)}
                                    roadDistance={distanceTimes?.[index + 1]?.distance || 0}
                                    workorder={act}
                                    title={renderToString(getText('acts.act'))}
                                    componentToOpen={() => (
                                        <ActController
                                            actId={act.id as number}
                                            updateList={requestActs}
                                        />
                                    )}

                                />
                            </div>
                        )
                    })}
                    <RenderIf condition={!acts.length}>
                        <div>{getText('calendar.noActs')}</div>
                    </RenderIf>
                </RenderIf>
                <RenderIf condition={mode === 2}>
                    {workorders?.map?.((wo, index) => {
                        const lastWo = workorders[index - 1];
                        return (
                            <div className='item_basepoint'>
                                <CalendarItem
                                    id={wo.id}
                                    key={wo.id}
                                    factMode
                                    from={wo?.from}
                                    duration={wo?.duration}
                                    roadDuration={lastWo ? wo.from - (lastWo?.from || 0) - (lastWo?.duration || 0) : 0}
                                    roadDistance={distanceTimes?.[index + 1]?.distance || 0}
                                    workorder={wo}
                                    title={renderToString(getText('workorders.workorder'))}
                                    componentToOpen={() => (
                                        <WorkOrderDetail
                                            id={wo.id}
                                            updateList={requestWorkorders}
                                        />
                                    )}
                                />
                            </div>
                        )
                    })}
                    <RenderIf condition={!workorders.length}>
                        <div>{getText('calendar.noWorkorders')}</div>
                    </RenderIf>
                </RenderIf>
                <RenderIf condition={acts?.length || workorders?.length}>
                    <div className="calendar_basepoint">
                            <span className="calendar_basepoint_title">
                                {basePoint?.address}
                            </span>
                        <p className="calendar_item-footer">
                            <span>{getText(`calendar.road`)}: </span>
                            <b>{secondsToTime(distanceTimes?.[distanceTimes?.length - 1]?.duration || 0)} ч.</b>
                        </p>
                        <p className="calendar_item-footer">
                            <span>{getText(`calendar.distance`)}: </span>
                            <b>{((distanceTimes?.[distanceTimes?.length - 1]?.distance || 0) / 1000).toFixed(2)} км.</b>
                        </p>
                    </div>
                </RenderIf>
            </div>
            <div className='calendar_workorders-footer'>
                <div>
                    <p><span>{getText('calendar.distance')}: </span><b>{getTotalDistance()} км</b></p>
                    <p>
                        <span>{getText('calendar.roadDuration')}: </span><b>{secondsToTime(getTotalDuration())} ч.</b>
                    </p>
                    <p>
                        <span>{getText('calendar.workDuration')}: </span><b>{secondsToTime(getTotalWorkDuration())} ч.</b>
                    </p>
                </div>
            </div>
        </div>
    )
})
