import React from "react";
import './settings.css'
import Tabs, {ITab} from "../UI/tabs/tabs.controller";
import General from "./tabsContent/general";
import Notifications from "./tabsContent/notifications";
import Security from "./tabsContent/security";
import TabContentOrders from "./tabsContent/tabContentOrders";
import TabContentTasks from "./tabsContent/tabContentTasks";
import Profile from "./tabsContent/profile";
import {getUserMe} from "../../store/application/application.actions";
import checkError from "../../utils/checkError";
import {connect} from "react-redux";
import {
    getLanguages, userSettingsChange, Language, userPersonChange,
    userPasswordChange, userPasswordVerify, UserPersonUpdate
} from "@justpro/terminal";
import Spinner from "../UI/spinner/spinner.controller";
import {toast} from "react-toastify";
import {signOut} from "../../routing/utils";
import {renderToString} from "react-dom/server";
import getText from "../../localization/getText";

interface Props {
    getUserMe?(): any
}

interface State {
    loading: boolean
}

class Settings extends React.Component<Props, State> {
    state = {
        loading: false
    }
    languages: Language[] = [];

    changeTab: ((index: number) => any) | void = void 0;

    get tabs(): ITab[] {
        return [
            {
                title: "settings.general",
                component: () => {
                    return <General languages={this.languages}
                                    onChange={this.onChangeSettings.bind(this, "languageId")}
                    />
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>

            },
            {
                title: "UI.tabs.notifications",
                component: () => {
                    return <Notifications onChange={this.onChangeSettings}/>
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>
            },
            {
                title: "UI.tabs.profile",
                component: () => {
                    return <Profile onChange={this.onChangeProfile}/>
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>
            },
            {
                title: "UI.tabs.security",
                component: () => {
                    return <Security onVerify={this.onVerifyPassword} onChange={this.onChangePassword}/>
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>
            },
            {
                title: "orders.orders",
                component: () => {
                    return <TabContentOrders onChange={this.onChangeSettings}/>
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>
            },
            {
                title: "tasks.tasks",
                component: () => {
                    return <TabContentTasks onChange={this.onChangeSettings}/>
                },
                onSelectTab: () => undefined
                // onSelectTab(tab:any) =>
            },
        ]
    }

    onInitTabs = (changeTab: any) => {
        this.changeTab = changeTab;
    };

    async componentDidMount() {
        try {
            this.setState({
                loading: true
            });
            this.props.getUserMe && await this.props.getUserMe();
            this.languages = await getLanguages();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    onChangeSettings = async (field: string, value: any) => {
        console.log('field' , field)
        console.log('value: ', value);
        try {
            this.setState({
                loading: true
            });
            await userSettingsChange({
                [field]: value
            });
            this.props.getUserMe && await this.props.getUserMe();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    onChangeProfile = async (field?: string, value?: any) => {
        try {
            this.setState({
                loading: true
            });
            if(field && value) {
                await userPersonChange({
                    [field]: value
                });
            }
            this.props.getUserMe && await this.props.getUserMe();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    onVerifyPassword = async (password: string) => {
        let isValid = false;
        try {
            this.setState({
                loading: true
            });
            isValid = await userPasswordVerify(password);
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
            return isValid;
        }
    }

    onChangePassword = async (password: string) => {
        try {
            this.setState({
                loading: true
            });
            await userPasswordChange(password);
            toast.success(renderToString(getText('settings.passwordWasSuccessChanged')));
            await signOut();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const {loading} = this.state;
        return (
            <>
                <Spinner loading={loading}/>
                <div className="user-settings">
                    <div className="user-settings__vertical-tabs">
                        <Tabs tabs={this.tabs} onInit={this.onInitTabs} vertical/>
                    </div>
                </div>
            </>
        )
    }
}

const mapState = () => ({});
const mapDispatch = (dispatch: Function) => ({
    getUserMe: () => dispatch(getUserMe())
});

export default connect(mapState, mapDispatch)(Settings);