import React from 'react';
import {createEmail, Email, getEmails, ModulesResponse} from "@justpro/terminal";
import BaseReferenceController from "../baseReference/baseReference.controller";
import EmailTypeController from "./emailType.controller";
import EmailTypeCreate from "./emailType.create";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
interface Props {
    rights?: Partial<ModulesResponse>
}

class EmailTypesController extends React.Component<Props>{

    renderItem = (item:Email) => <>{item.name}</>;

    render() {

        const rights = this.props.rights && this.props.rights['references.emails'];

        return <BaseReferenceController
            referenceName="emailTypes.moduleName"
            listItem={{
                render : this.renderItem
            }}
            getList={getEmails}
            Detail={EmailTypeController}
            create={{
                Component : EmailTypeCreate,
                title : 'emailTypes.addItem',
                handler : createEmail
            }}
            moduleRights={rights}

        />
    }
}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})


export default connect(mapState)(EmailTypesController)