import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import {CreateFuel} from "@justpro/terminal";
import getText from "../../../localization/getText";

interface Props {
    create(data:CreateFuel) : void
}

interface State {
    name : string
}

class FuelCreate extends React.Component<Props, State>{

    state:State = {
        name : ''
    };

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    addFuel = () => {
        if(!this.isDisabled()) {
            this.props.create({name : this.state.name})
        }
    };

    isDisabled = () => this.state.name.trim() === '';

    render() {

        return (<>
            <h4>{getText('fuels.addingNewHeader')}</h4>

            <Input
                placeholder="fuels.fuel"
                change={this.changeName}
                label="fuels.fuel"
                startValue=""
            />

            <Button className="btn-success" disabled={this.isDisabled()} onClick={this.addFuel}>{getText('common.add')}</Button>

            </>)
    }

}


export default FuelCreate