import React from 'react'
import WithPrivateRoute from '../withPrivateRoute/withPrivateRoute.controller'
import HeaderController from '../header/header.controller'
import {
	OrderListItem,
	Person,
	Object,
	get1SPriorities,
	getSettings,
	getWorkorders,
	getMapObjects,
	getMapExecutors,
	BasePointType,
	WorkordersStates,
	StringBoolean,
	getPerson,
	Workorder,
	GOOGLE_API_KEY,
} from '@justpro/terminal'
import Map from './map'
import Spinner from '../UI/spinner/spinner.controller'
import RenderIf from '../../utils/renderIf'
import './calendar.css'
import { connect } from 'react-redux'
import {
	setCurrentExecutor,
	toggleModeCheckRoutes,
} from '../../store/map/map.actions'
import CustomMarker from '../maps/markers/customMarker'
import OBJECT_DEFAULT_MARKER from '../maps/icons/objects/OBJECT_DEFAULT_MARKER.svg'
import REGION_POINT from '../maps/icons/REGION_POINT.svg'
import BASE_POINT from '../maps/icons/BASE_POINT.svg'
import { openModal } from '../../store/modal/modal.actions'
import { Modal } from '../../store/modal/modal.types'
import getPosition from '../../utils/getPosition'
import {
	getContractorList,
	getOrderStatusesList,
	getOrdersTypesList,
	getRegionsList,
} from '../../utils/functions'
import { ArrowButton, ExpandingWrapper, PersonCard } from './components'
import WorkorderCalendar from './workordersCalendar.controller'
import WorkordersModal from './workorders.modal'
import ModuleTextName from '../UI/moduleTextName/moduleTextName'
import Filter from '../UI/filter/filter.controller'
import TopLineCalendar from '../UI/calendar/views/topLine.controller'
import moment, { Moment } from 'moment'
import ToplineCalendarWrapper from '../UI/calendar/views/toplineCalendarWrapper'
import Dropdown from '../UI/dropdownMenu/dropdown.controller'
import Button from '../UI/button/button'
import getText from '../../localization/getText'
import CheckboxList from '../UI/checkboxList/checkboxList.contoller'
import {
	ActiveCheckboxesList,
	CheckboxesMap,
} from '../UI/checkboxList/checkboxList.types'
import { filterNames } from '../UI/checkboxList/checkboxes.const'
import { renderToString } from 'react-dom/server'
import checkError from '../../utils/checkError'
import ToggleSwitch from '../UI/toggleSwitch/toggleSwitch.controller'
import { FactData } from './factMode'
import { ObjectDisplayName } from '../maps/markers/markerInnerWindows/objectInner'

// 1. Добавить галочку "Оптимизировать маршрут", после любой перестановки – галочка снимается и маршрут строится по порядку
// 2. Доработать перестановки в календаре
// 3. Подтягивать реальный календарь и сохранять его
// 4. Запрещать сохранять календарь на прошедшие даты

export const MAX_WAYPOINTS = 26

export type ModeType = 1 | 2

export type Position =
	| {
			lat: number
			lng: number
	  }
	| string

export type DistanceElement = {
	address: string
	distance: number
}

interface Props {
	touched: boolean
	isWoCreatorOpen: boolean
	openedObject: Object
	currentExecutor: any
	woArray: OrderListItem[]
	rights: any
	settings: any
	mode: ModeType
	setIsWoCreatorOpen: (isWoCreatorOpen: boolean) => void
	setWoArray: (array: Array<boolean>) => void
	openModal: (props: Modal) => void
	setCurrentExecutor: (executor: any) => void
	toggleMode: (mode: ModeType) => void
}

interface State {
	objects: any
	basePoints: any
	showAllObjects: boolean
	position: any
	loading: boolean
	showNames: boolean
	colorByAccident: boolean
	isExecutorsListOpen: boolean
	isWayPointsListOpen: boolean
	executors: Person[]
	selectedExecutorId: number
	selectedDate: Moment
	mode: ModeType
	checkboxesMap: CheckboxesMap
	activeCheckboxes: ActiveCheckboxesList
	directions: any
	objectsToRender: any
	basePointsToRender: any
}

class CalendarController extends React.Component<Props, State> {
	state: State = {
		objects: null,
		basePoints: null,
		showAllObjects: false,
		loading: false,
		position: null,
		showNames: false,
		colorByAccident: true,
		isExecutorsListOpen: true,
		isWayPointsListOpen: false,
		executors: [],
		selectedExecutorId: 0,
		selectedDate: moment(),
		mode: 1,
		checkboxesMap: {},
		activeCheckboxes: {},
		directions: null,
		objectsToRender: null,
		basePointsToRender: null,
	}

	async componentDidMount() {
		this.optionalSetPosition()
		this.getExecutors()
		this.initFilters()
	}

	async getExecutors() {
		try {
			this.setState({ loading: true })
			const executors = await getMapExecutors({})
			this.setState({ executors })
		} catch (e) {
			checkError(e)
		} finally {
			this.setState({ loading: false })
		}
	}

	async openPastWorkorders() {
		this.props.openModal({
			id: 'act_task',
			component: () => (
				<WorkordersModal
					executorId={this.state.selectedExecutorId}
					dateTo={moment().add(1, 'd').format('YYYY-MM-DD')}
				/>
			),
			title: getText('workorders.workorders'),
			size: 'large',
			minHeight: '80vh',
		})
	}

	async openFutureWorkorders() {
		this.props.openModal({
			id: 'act_task',
			component: () => (
				<WorkordersModal
					executorId={this.state.selectedExecutorId}
					dateFrom={moment().add(1, 'd').format('YYYY-MM-DD')}
				/>
			),
			title: getText('workorders.workorders'),
			size: 'large',
			minHeight: '80vh',
		})
	}

	changeSelectedMode(mode) {
		this.setState({
			mode: mode.id,
			selectedDate:
				mode.id === 1
					? moment().add(1, 'days')
					: moment().subtract(1, 'days'),
			directions: null,
			objectsToRender: null,
		})
	}

	handleSelectedDate(date: Moment) {
		this.setState({ selectedDate: date })
	}

	async changeSelectedExecutorId(newId) {
		const person = await getPerson(newId)
		const basePointsToRender = this.getBasePointsToRender(person.basePoints)
		this.setState({
			selectedExecutorId: newId,
			isWayPointsListOpen: true,
			basePoints: person.basePoints,
			basePointsToRender,
		})
	}

	getBasePointsToRender = (basePoints) => {
		if (Array.isArray(basePoints)) {
			return basePoints.map((bp) => {
				if (!bp.latitude) return
				return (
					<CustomMarker
						object={bp}
						key={bp.id}
						icon={BASE_POINT}
						hoveredComponent={ObjectDisplayName}
						position={{
							lat: +bp.latitude,
							lng: +bp.longitude,
						}}
					/>
				)
			})
		}
	}

	toggleShowNames = () => {
		this.setState((prevState) => ({
			showNames: !prevState.showNames,
		}))
	}

	toggleColorizeBy = (value: boolean) => {
		this.setState(() => ({
			colorByAccident: value,
		}))
	}

	toggleIsExecutorsListOpen = () => {
		this.setState((prevState: State) => ({
			isExecutorsListOpen: !prevState.isExecutorsListOpen,
		}))
	}

	toggleIsWayPointsListOpen = () => {
		this.setState((prevState: State) => ({
			isWayPointsListOpen: !prevState.isWayPointsListOpen,
		}))
	}

	optionalSetPosition = async () => {
		try {
			this.setState({ loading: true })
			const position = await getPosition()
			if (position) {
				this.setState({ position })
			}
		} catch (err) {
			console.log(err)
		} finally {
			this.setState({ loading: false })
		}
	}

	onCheckboxesChange = (activeCheckboxes) => {
		this.setState({
			activeCheckboxes,
		})
	}

	getObjectsParams = () => {
		const ccFilter = this?.state?.activeCheckboxes?.CONDITIONS_FILTER
		let contractConditions = void 0
		if (ccFilter?.length === 1) {
			contractConditions = ccFilter[0]
		}
		return {
			regionsId: this?.state?.activeCheckboxes
				?.REGIONS_FILTER as number[],
			contractorsId: this?.state?.activeCheckboxes
				?.CONTRACTORS_FILTER as number[],
			priorities1sId: this?.state?.activeCheckboxes
				?.PRIORITIES_FILTER as number[],
			contractConditions,
			other: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes('1')
				? '1'
				: '0') as StringBoolean,
			hot: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes('2')
				? '1'
				: '0') as StringBoolean,
			expired: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes(
				'3',
			)
				? '1'
				: '0') as StringBoolean,
			ordersTypesId: this?.state?.activeCheckboxes
				?.ORDER_TYPES_FILTER as number[],
			ordersStatusesId: this?.state?.activeCheckboxes
				?.ORDERS_FILTER as number[],
		}
	}

	getExecutorsParams = () => {
		const executorsAcception =
			this?.state?.activeCheckboxes?.[filterNames.EXETUTORS_ACCEPTION]
		const isWorking =
			executorsAcception?.length === 1 ? executorsAcception?.[0] : void 0
		return {
			workordersStates: this?.state?.activeCheckboxes?.[
				filterNames.EXECUTOR_WO
			] as WorkordersStates[],
			isWorking: isWorking as StringBoolean,
		}
	}

	getBasePointsParams = () => {
		const type = this?.state?.activeCheckboxes?.[filterNames.BASE_POINTS]
		return { type: type as BasePointType[] }
	}

	filterSubmit = async () => {
		try {
			this.setState({
				loading: true,
			})
			const filterObjects = await getMapObjects(this.getObjectsParams())
			if (this.state.showAllObjects) {
				const allObjects = await getMapObjects({})
				this.setState({
					objects: allObjects.map((obj) => {
						const object = filterObjects.find(
							(o) => obj.id === o.id,
						)
						if (object) return object
						return {
							...obj,
							notInFilter: true,
						}
					}),
				})
			} else {
				this.setState({
					objects: filterObjects,
				})
			}
		} catch (err) {
			checkError(err)
		} finally {
			this.setState({
				loading: false,
			})
		}
	}

	async initFilters() {
		this.setState({ loading: true })

		const regions = await getRegionsList()
		const contractors = await getContractorList()
		const priorities = await get1SPriorities()
		const orderTypes = await getOrdersTypesList()

		const {
			ordersCanWorkorderStatus,
			orderDoneStatusId,
			orderToExecuteStatus,
			declineOrderId,
		} = await getSettings()

		let statuses = await getOrderStatusesList()
		statuses = statuses.filter((status) => {
			return (
				status.id != orderDoneStatusId &&
				status.id != declineOrderId &&
				status.active
			)
		})

		const urgency = {
			name: 'UI.filters.urgencyOrders.filter',
			children: [
				{
					id: '1',
					name: renderToString(
						getText('UI.filters.urgencyOrders.all'),
					),
				},
				{
					id: '2',
					name: renderToString(
						getText('UI.filters.urgencyOrders.hot'),
					),
				},
				{
					id: '3',
					name: renderToString(
						getText('UI.filters.urgencyOrders.expired'),
					),
				},
			],
		}

		const checkboxesMap = {
			[filterNames.ELEMENTS]: {
				name: 'common.elements',
				children: [
					{ name: 'objects.objects', id: 'objects' },
					{ name: 'common.executors', id: 'executors' },
					{ name: 'maps.basePoints.name', id: 'adminPoints' },
					{ name: 'maps.departureBasePoints.name', id: 'basePoints' },
				],
			},
			[filterNames.REGIONS]: {
				name: 'regions.regions',
				children: regions,
			},
			[filterNames.CONTRACTORS]: {
				name: 'contractors.contractors',
				children: contractors.filter((c) => c.active),
			},
			[filterNames.PRIORITIES]: {
				name: 'common.priority',
				children: priorities.map((p) => ({
					name: p.name,
					id: p.id1s,
				})),
			},
			[filterNames.CONDITIONS]: {
				name: 'contractConditions.contractCondition',
				children: [
					{
						name: 'contractConditions.withConditions',
						id: '1',
					},
					{ name: 'contractConditions.withOutConditions', id: '0' },
				],
			},
			[filterNames.URGENCY_ORDERS]: urgency,
			[filterNames.ORDER_TYPES]: {
				name: 'orderTypes.moduleName',
				children: orderTypes,
			},
			[filterNames.ORDERS]: {
				name: 'orderStatuses.moduleName',
				children: statuses.sort((a, b) => a.name.localeCompare(b.name)),
			},
			[filterNames.EXECUTOR_WO]: {
				name: 'executorsWO.moduleName',
				children: [
					{ name: 'executorsWO.withOrder', id: '1' },
					{ name: 'executorsWO.orderForLater', id: '2' },
					{ name: 'executorsWO.withOutOrder', id: '0' },
				],
			},
			[filterNames.EXETUTORS_ACCEPTION]: {
				name: 'executorsAcception.moduleName',
				children: [
					{ name: 'executorsAcception.accepted', id: '1' },
					{ name: 'executorsAcception.notAccepted', id: '0' },
				],
			},
		}

		this.setState({
			loading: false,
			checkboxesMap,
			activeCheckboxes: {
				[filterNames.ELEMENTS]: [
					'objects',
					'adminPoints',
					'basePoints',
				],
				[filterNames.ORDERS]: [
					+ordersCanWorkorderStatus,
					+orderToExecuteStatus,
				],
			},
		})
	}

	buildRoutes = async (objects) => {
		objects = objects.filter((object) => object?.latitude)
		const { google }: any = window
		const travelMode = 'DRIVING'
		const allPoints = objects
			.map((object) => {
				return {
					lat: object.latitude,
					lng: object.longitude,
				}
			})
			.slice(0, MAX_WAYPOINTS)
		const directionsService = new google.maps.DirectionsService()

		if (!allPoints.length) {
			this.setState({
				directions: null,
				objectsToRender: null,
			})
		}

		const routeParams = {
			travelMode: travelMode,
			origin: allPoints[0],
			destination: allPoints[allPoints.length - 1],
			waypoints: allPoints.slice(0, -1).map((location) => ({
				location,
			})),
		}

		const objectsToRender = this.getObjectsToRender(objects)

		directionsService.route(routeParams, (result, status) => {
			if (status === google.maps.DirectionsStatus.OK) {
				this.setState({
					directions: result,
					objectsToRender,
				})
			} else {
				console.error(`error fetching directions ${result}`)
			}
		})
	}

	updateDirections = (directions, objects) => {
		const objectsToRender = this.getObjectsToRender(objects)
		this.setState({
			directions: directions,
			objectsToRender,
		})
	}

	getObjectsToRender = (objects) => {
		if (Array.isArray(objects)) {
			return objects.map((obj, i) => {
				if (!obj.latitude) return
				const currentImage = this.defineObjectCurrentColor(obj)
				return (
					<CustomMarker
						zIndex={9999}
						object={obj}
						key={obj.id * i}
						icon={currentImage}
						hoveredComponent={ObjectDisplayName}
						position={{
							lat: +obj.latitude,
							lng: +obj.longitude,
						}}
					/>
				)
			})
		}
	}

	defineObjectCurrentColor = (obj) => {
		if (!obj.basePoint) {
			return OBJECT_DEFAULT_MARKER
		}
		if (obj.basePoint && obj.personId) {
			return BASE_POINT
		}
		if (obj.basePoint && !obj.personId) {
			return REGION_POINT
		}
	}

	render() {
		const {
			loading,
			executors,
			selectedExecutorId,
			selectedDate,
			checkboxesMap,
			activeCheckboxes,
			mode,
			objects,
			objectsToRender,
			basePointsToRender,
		} = this.state

		const selectedExecutor = executors.find(
			(ex) => ex.id === selectedExecutorId,
		)
		return (
			<WithPrivateRoute>
				<div className='map-main__container'>
					<HeaderController>
						<ModuleTextName>Календарь сотрудника</ModuleTextName>
						<ToplineCalendarWrapper>
							На дату
							<TopLineCalendar
								date={selectedDate}
								disabledFrom={mode === 2 ? moment() : null}
								onChange={this.handleSelectedDate.bind(this)}
							/>
						</ToplineCalendarWrapper>
						<div className='calendar-header_wrapper'>
							<div>Режим</div>
							<Dropdown
								onChange={this.changeSelectedMode.bind(this)}
								list={[
									{
										name: 'Планирование',
										id: 1,
									},
									{
										name: 'Факт',
										id: 2,
									},
								]}
							/>
						</div>
						<Filter
							activeCheckboxes={activeCheckboxes}
							send={this.filterSubmit}
						>
							<div className='just-pro__checkbox-children'>
								<input
									className='maps_filterInput'
									type='checkbox'
									checked={this.state.showNames}
									onChange={this.toggleShowNames}
								/>
								<b>
									{renderToString(
										getText('common.showNames'),
									)}
								</b>
							</div>
							<CheckboxList
								checkboxesMap={checkboxesMap}
								activeCheckboxes={activeCheckboxes}
								send={this.onCheckboxesChange}
							/>
							<div className='just-pro__urgent-color-mode'>
								<ToggleSwitch
									send={this.toggleColorizeBy}
									label='По аварийности'
									position='top'
									className='just-pro__toggle_container'
									defaultValue={this.state.colorByAccident}
								/>
							</div>
						</Filter>
						<RenderIf condition={selectedExecutorId}>
							<div className='calendar-header_wrapper'>
								<Button
									onClick={this.openPastWorkorders.bind(this)}
								>
									Прошлые наряды
								</Button>
								<Button
									onClick={this.openFutureWorkorders.bind(
										this,
									)}
								>
									Будущие наряды
								</Button>
							</div>
						</RenderIf>
					</HeaderController>
					<div id='maps' className='just-pro_module calendar'>
						<ArrowButton
							onClick={this.toggleIsExecutorsListOpen.bind(this)}
							isOpen={this.state.isExecutorsListOpen}
							isLeft
						/>
						<ExpandingWrapper
							isOpen={this.state.isExecutorsListOpen}
							isLeft
						>
							{executors.map((executor) => (
								<PersonCard
									key={executor.id}
									person={executor}
									selectedExecutorId={selectedExecutorId}
									changeSelectedExecutorId={this.changeSelectedExecutorId.bind(
										this,
									)}
								/>
							))}
						</ExpandingWrapper>
						<Spinner loading={loading} />
						<Map
							directions={this.state.directions}
							objectsToRender={
								activeCheckboxes?.ELEMENTS_FILTER?.includes(
									'adminPoints',
								) && objectsToRender
							}
							basePointsToRender={
								activeCheckboxes?.ELEMENTS_FILTER?.includes(
									'basePoints',
								) && basePointsToRender
							}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${GOOGLE_API_KEY}`}
							loadingElement={
								<div className='calendar-map-container_element'></div>
							}
							containerElement={
								<div className='calendar-map-container_element' />
							}
							mapElement={
								<div className='calendar-map-container_element__inner' />
							}
							position={this.state.position}
							openedObject={this.props?.openedObject}
							colorByAccident={this.state.colorByAccident}
							orderToExecuteStatus={
								this.props?.settings?.orderToExecuteStatus
							}
							objects={
								activeCheckboxes?.ELEMENTS_FILTER?.includes(
									'objects',
								) && objects
							}
						/>
						<ArrowButton
							onClick={this.toggleIsWayPointsListOpen}
							isOpen={this.state.isWayPointsListOpen}
						/>
						<ExpandingWrapper
							isOpen={this.state.isWayPointsListOpen}
						>
							<RenderIf condition={mode === 1}>
								<WorkorderCalendar
									buildRoutes={this.buildRoutes}
									selectedExecutor={selectedExecutor}
									selectedDate={selectedDate.format(
										'YYYY-MM-DD',
									)}
								/>
							</RenderIf>
							<RenderIf condition={mode === 2}>
								<FactData
									updateDirections={this.updateDirections}
									selectedExecutor={selectedExecutor}
									selectedDate={selectedDate.format(
										'YYYY-MM-DD',
									)}
									buildRoutes={this.buildRoutes}
								/>
							</RenderIf>
						</ExpandingWrapper>
					</div>
				</div>
			</WithPrivateRoute>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		touched: state?.maps?.touched,
		isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
		openedObject: state?.maps?.openedObject,
		currentExecutor: state?.maps?.currentExecutor,
		woArray: state?.maps?.woArray,
		rights: state.application.rights,
		settings: state.application.settings,
		mode: state?.maps?.mode,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: (props: Modal) => {
			dispatch(openModal(props))
		},
		setCurrentExecutor: (executor) =>
			dispatch(setCurrentExecutor(executor)),
		toggleMode: (mode) => dispatch(toggleModeCheckRoutes(mode)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarController)
