import React from "react";
import {Workorder} from "@justpro/terminal";
import WorkOrderListItem from "../../../workOrders/workOrder.listItem";
import RenderIf from "../../../../utils/renderIf";
interface Props {
    workorders? : Workorder[]
}

interface State {

}

export default class RegularWorkWorkorders extends React.Component<Props, State>{

    state:State = {

    };

    renderItem = (workOrder:Workorder) => {
        return <WorkOrderListItem
                workOrder={workOrder}
                withModuleDetail={true}
                key={workOrder.id}
        />
    };

    render () {
        const {workorders} = this.props;
        return (
            <div className="regularWorks__workorders">
                <RenderIf condition={workorders && workorders.length > 0}>
                    <>
                        {(workorders as Workorder[]).map(w => this.renderItem(w))}
                    </>
                </RenderIf>
            </div>
        )
    }
}