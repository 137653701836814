import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import RenderIf from '../../../utils/renderIf';
import Button from '../../UI/button/button';
import checkError from '../../../utils/checkError';
import FileDownload from 'js-file-download';
import moment, { Moment } from 'moment';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import './actCloseTypes.css';
import {
  CloseType,
  format,
  getActsCloseTypesReport,
  getActsCloseTypesReportExport,
  getCloseTypes,
  ActsCloseTypeReport,
  filterParams,
  getPersons,
} from '@justpro/terminal';
import Table from '../../UI/table/table';
import { getFullName } from '../../../utils/names';
import Spinner from '../../UI/spinner/spinner.controller';
import getText from '../../../localization/getText';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';
import Filter from '../../UI/filter/filter.controller';
import CheckboxList from '../../UI/checkboxList/checkboxList.contoller';
import { save } from '../../../utils/filterCheckboxes';
import { filterNames } from '../../UI/checkboxList/checkboxes.const';
import {
  ActiveCheckboxesList,
  CheckboxesMap,
} from '../../UI/checkboxList/checkboxList.types';
import { FilterName } from '../../../store/filters/filters.types';
import { getFilter } from '../../../store/filters/filters.actions';
import { connect } from 'react-redux';

interface Props {
  getFilter(filterName: FilterName, ...rest): Promise<any[]>;
}

interface State {
  generatingExcel: boolean;
  activeCheckboxes: ActiveCheckboxesList;
  checkboxesMap: CheckboxesMap;
  from: Moment;
  to: Moment;
  closeTypes: CloseType[];
  data?: ActsCloseTypeReport[];
  loading: boolean;
}

class ActCloseTypesController extends React.Component<Props, State> {
  state: State = {
    generatingExcel: false,
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
    closeTypes: [],
    activeCheckboxes: {},
    checkboxesMap: {},
    loading: false,
  };

  changeFrom = async (from: Moment) => {
    this.setState(() => ({ from }), this.getList);
  };
  changeTo = async (to: Moment) => {
    this.setState(() => ({ to }), this.getList);
  };

  generateExcel = async () => {
    try {
      this.setState(() => ({ generatingExcel: true }));

      const blob = await getActsCloseTypesReportExport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });

      FileDownload(
        blob,
        `actCloseTypes_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState(() => ({ generatingExcel: false }));
    }
  };

  get columns() {
    return [
      {
        Header: getText('reports.actCloseTypes.executorNSP'),
        columns: [
          {
            Header: getText('common.justpro'),
            accessor: 'personFullName',
            // Cell : ({original}:any) => getFullName(original.personJustPro),
          },
          {
            Header: getText('common.odinS'),
            accessor: 'person1sName',
            // Cell : ({original}:any) => getFullName(original.personOdinS),
          },
        ],
      },
      {
        Header: getText('reports.actCloseTypes.byClosedUser'),
        columns: this.state.closeTypes?.map((item) => {
          return {
            minWidth: 80,
            Header: item.name,
            Cell: ({ original }: any) => {
              const accessor = `id${item.id}`;

              if (original?.hasOwnProperty(accessor)) {
                return original[accessor];
              }

              return null;
            },
          };
        }),
      },
      {
        Header: getText('reports.actCloseTypes.byResponsible'),
        columns: this.state.closeTypes?.map((item) => {
          return {
            minWidth: 80,
            Header: item.name,
            Cell: ({ original }: any) => {
              const accessor = `alt${item.id}`;

              if (original?.hasOwnProperty(accessor)) {
                return original[accessor];
              }

              return null;
            },
          };
        }),
      },
    ];
  }

  getList = async () => {
    try {
      this.setState({
        loading: true,
      });
      const data = await getActsCloseTypesReport({
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
        regionsId: this.state?.activeCheckboxes?.regionsId as number[],
      });
      this.setState({ data });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState(() => ({ loading: false }));
    }
  };

  resetState = async () => {
    await this.getList();
    const closeTypes = await getCloseTypes({ q: '' });
    const checkboxesMap = {};
    checkboxesMap[filterParams.REGIONS] = {
      children: await this.props.getFilter('regions'),
      name: 'regions.region',
    };

    this.setState(() => ({ closeTypes, checkboxesMap }));
  };

  onFilterChange = (activeCheckboxes: ActiveCheckboxesList) => {
    this.setState({
      activeCheckboxes,
    });
  };

  async componentDidMount() {
    this.resetState();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.resetState();
    }
  }

  render() {
    const { activeCheckboxes, checkboxesMap } = this.state;
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>
            {getText('reports.actCloseTypes.moduleName')}
          </ModuleTextName>

          <Filter activeCheckboxes={activeCheckboxes} send={this.getList}>
            <CheckboxList
              checkboxesMap={checkboxesMap}
              activeCheckboxes={activeCheckboxes}
              send={this.onFilterChange}
            />
          </Filter>

          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.changeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.changeTo} />
          </ToplineCalendarWrapper>

          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="common.excelExport">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                {getText('common.generating')}
              </RenderIf>
            </Tooltip>
          </div>
        </HeaderController>

        <div className="just-pro_module report-acts-close-types">
          <div className="panel content-panel">
            {this.state.data && (
              <Table columns={this.columns} data={this.state.data}></Table>
            )}

            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}
const mapState = () => {
  return {};
};
const mapDispatch = (d: Function) => ({
  getFilter: (filterName, rest) => d(getFilter(filterName, rest)),
});

export default connect(mapState, mapDispatch)(ActCloseTypesController);
