import React from 'react';
import checkError from "../../../utils/checkError";
import InfoBlock from "../../UI/infoBlock/infoBlock.controller";
import {ChangeMultiProps, LoadReturn, Option} from "../../UI/select/select.types";
import ActTabs from "../act.tabs";
import {
    addActTag,
    deleteActTag,
    forceSyncActWith1S,
    format,
    updateAct,
    IAct,
    IActTag,
    IUpdateAct,
    Object,
    Tag, createActTask
} from '@justpro/terminal'
import PersonInfo from "../../UI/dialog/personInfo/personInfo.controller";
import ActDistance from "./act.distance";
import ActScans from "./act.scans";
import ActOrders from "./act.orders";
import moment, {Moment} from "moment";
import ActRegularWorks from "./act.regularWorks";
import ActTimeExpend from "./act.timeExpend";
import MultiSelect from "../../UI/select/multiSelect";
import {getExecutorsList, getPersonsList, getTagsList} from "../../../utils/functions";
import {getUniqueList} from "../../../utils/getUniqueArray";
import ActPhase from "./act.phase";
import Act1s from "./act.1s";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Select from "../../UI/select/select";
import {IUpdateActListItem} from "../acts.controller";
import RenderIf from "../../../utils/renderIf";
import Input from "../../UI/input/text";
import InlineCalendar from "../../UI/calendar/views/inline.controller";
import {toast} from "react-toastify";
import Dropdown from "../../UI/dropdownMenu/dropdown.controller";
import {DropDownItem} from "../../UI/dropdownMenu/dropdown.types";
import AsyncSelect from "../../UI/select/asyncSelect";
import {groupObjectsByRegion} from "../../../utils/selectGroups";
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import {getObjectName} from "../../../utils/names";
import {connect} from "react-redux";
import {ApplicationReducer} from "../../../store/application/application.types";
import TaskController from "../../tasks/task.controller";
import {openModal} from "../../../store/modal/modal.actions";
import {Modal} from "../../../store/modal/modal.types";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";
import VirtualAct from "./virtualAct.controller";
import BlockController from "../../UI/infoBlock/block.controller";
import {getCloseTypes, ModulesResponse, getFullName} from '@justpro/terminal'

interface ActCloseType {
    name: string
    id: number
}

interface Props {
    act: IAct

    updateAct(actId: number, data: Partial<IUpdateAct>): void

    openModal(props: Modal): void

    settings: any
    rights: Partial<ModulesResponse>

    updateActListItem?(actId: number, data: IUpdateActListItem): void

    getAct?(): Promise<void>
}

interface State {
    objects: Object[]
    tags: IActTag[]
    date?: Moment
    actNumber: string
    closeTypes?: ActCloseType[]
}


class ActDetail extends React.Component<Props, State> {

    state: State = {
        objects: [],
        tags: [],
        actNumber: '',
    };

    dropdownItems: DropDownItem[] = [
        {
            name: "UI.dropDown.chooseAction",
            id: 1,
        },
    ];

    allTags: Tag[] = [];

    changeTags = async (option: ChangeMultiProps) => {
        const {updateActListItem} = this.props;
        const {value, type} = option;

        try {
            switch (type) {
                case "select-option":
                    const added = await addActTag(this.props.act.id, value.value);

                    await this.setState((prevState) => ({
                        tags: [...prevState.tags, added]
                    }));

                    break;

                case "remove-value":
                    const deleted = await deleteActTag(this.props.act.id, value.value);

                    if (deleted) {
                        await this.setState((prevState) => ({
                            tags: prevState.tags.filter(tag => tag.id !== value.value)
                        }));
                    }
                    break;
            }
            updateActListItem && updateActListItem(this.props.act.id, {tags: this.state.tags});
        } catch (e) {
            checkError(e)
        }
        return false
    };

    getActTags = (): LoadReturn => {
        return new Promise((async resolve => {

            if (this.allTags.length === 0) {
                this.allTags = await getTagsList({modules: ['acts']});
            }

            const unique = getUniqueList(this.allTags, this.state.tags, {wholeId: 'id', existId: 'tagId'});

            resolve(unique)
        }))

    };

    changeActAppoint = (option: Option) => {
        return Promise.resolve();
    };


    resetState = () => {
        const {act} = this.props;
        const objects: Object[] = [];
        const tags = act.tags;

        if (act.regularWorks) {
            act.regularWorks.forEach((regularWork) => {
                if (regularWork.equipment.object) {
                    objects.push(regularWork.equipment.object)
                }
            })
        }

        if (act.orders) {
            act.orders.forEach(order => {
                objects.push(order.object)
            })
        }
        this.setState({
            objects,
            tags: tags ? tags : [],
            date: moment(act.date),
            actNumber: act.number
        });
    };

    changeHighpriced = async (value: boolean) => {
        const {updateActListItem} = this.props;
        await this.props.updateAct(this.props.act.id, {
            highpriced: value
        });
        updateActListItem && updateActListItem(this.props.act.id, {highpriced: value});
    };

    changeCalculating = async (value: boolean) => {
        const {updateActListItem} = this.props;
        this.props.updateAct(this.props.act.id, {
            calculating: value
        });
        updateActListItem && updateActListItem(this.props.act.id, {calculating: value});
    };

    changeChecked = async (value: boolean) => {
        const {updateActListItem} = this.props;

        await this.props.updateAct(this.props.act.id, {
            checked: value
        });
        updateActListItem && updateActListItem(this.props.act.id, {checked: value});
    };

    onChangeDate = async (date: Moment) => {

        await this.props.updateAct(this.props.act.id, {
            date: date.format(format.date)
        });
        this.props.updateActListItem && this.props.updateActListItem(this.props.act.id, {date: date.format(format.date)})
        this.setState(() => ({date}))
    };

    changeActNumber = (actNumber: string) => {
        this.setState({
            actNumber
        })
    }

    blurActNumber = async (actNumber: string, prevValue: string) => {
        try {
            if (prevValue !== actNumber && actNumber.trim() !== '') {
                const act = await updateAct(this.props.act.id, {
                    number: actNumber
                });
                this.props.updateActListItem && this.props.updateActListItem(this.props.act.id, {number: actNumber})
                this.setState({actNumber})
                toast.success(renderToString(getText('acts.actNumberSuccessChanged')))
            }
        } catch (err) {
            checkError(err);
            this.setState({
                actNumber: this.props.act.number
            })
        }
    };


    changeResponsible = async (option: Option) => {

        this.props.updateAct(this.props.act.id, {
            responsibleId: option.value
        });
        return Promise.resolve();
    };


    relinkActWith1c = async () => {
        try {
            const relinked = await forceSyncActWith1S(this.props.act.id);

            if (relinked) {
                toast.success(`${renderToString(getText('acts.act'))} №${this.props.act.number} ${renderToString(getText('acts.successRelinkedTo1c'))}`);
            } else {
                toast.warn(`${renderToString(getText('acts.smthWrongWithRelink'))} №${this.props.act.number}`);
            }

        } catch (e) {
            checkError(e)
        }
    };

    createActTask = async () => {

        try {
            const task = await createActTask(this.props.act.id);
            this.props.openModal({
                id: "act_task",
                component: () => (<TaskController id={task.id}/>),
                title: getText('tasks.task'),
            });

        } catch (e) {
            checkError(e)
        }
    };

    createDropdownItems = () => {

        this.dropdownItems.push(
            {
                name: "UI.dropDown.print",
                id: 2,
                icon: 'fa fa-print'
            },
            {
                name: "UI.dropDown.createTask",
                id: 3,
                icon: 'fa fa-list',
                handler: this.createActTask
            },
            {
                name: "UI.dropDown.relinkTo1s",
                id: 4,
                icon: 'fa fa-refresh',
                handler: this.relinkActWith1c
            },
        )


        // return result;
    }

    onChangeActDropdownItem = (item: DropDownItem) => {
        item.handler && item.handler()
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (JSON.stringify(prevProps.act) !== JSON.stringify(this.props.act)) {
            this.resetState();
        }
    }

    async componentDidMount() {
        this.resetState();

        const closeTypes = await getCloseTypes({q: ''});
        this.setState({closeTypes})

        this.createDropdownItems();

    }

    changeActObject = async (option: Option) => {
        await this.props.updateAct(this.props.act.id, {objectId: option.value})
    };

    changeCloseTypeHandler = async (newType: Option) => {
        //@ts-ignore
        await this.props.updateAct(this.props.act.id, {closeTypeId: newType.value})
    }

    getExecutorsList = (q: string): LoadReturn => {
        const {settings} = this.props;
        return new Promise(async resolve => {
            const persons = await getExecutorsList(q, [+settings.tfmContractorId]);
            resolve(persons.map((p) => ({
                label: getFullName(p),
                value: p.id,
                ...p
            })));
        })
    };

    render() {
        // const {orders, objects, executors} = this.state;
        const {act, settings, rights} = this.props;

        const responsible = {
            name: `${act.responsible.lastName} ${act.responsible.firstName} ${act.responsible.patronymic}`,
            id: act.responsible.id,
        };

        const name = getObjectName(act.object)

        const defaultObject = makeNormalizeParams({
            ...act.object,
            name
        }, {name: 'label', id: 'value'});
        return (
            <>
                <Dropdown
                    onChange={this.onChangeActDropdownItem}
                    list={this.dropdownItems}
                />

                <InfoBlock>
                    <BlockController items={[
                        {
                            key: getText('common.executor'),
                            value: <PersonInfo position="down" person={act.responsible}/>
                        },
                        {key: getText('acts.phase'), value: act?.phase?.name},
                        {key: getText('closeTypes.closeType'), value: act?.closeType?.name},
                        {key: getText('acts.actNumber'), value: act?.number},
                        {key: getText('acts.isRegularWorksThisDay'), value: act.isRegularWorksThisDay ? getText("common.yes") :  getText("common.no") }
                    ]}/>
                    <BlockController items={[
                        {
                            key: getText('acts.actCreatedBy'),
                            value: <PersonInfo position="down" person={act.createdBy.person}/>
                        },
                        {key: getText('acts.timeOfCreated'), value: moment(act?.createdAt).format('DD.MM.YYYY HH:mm')},
                        {key: getText('acts.orderIn1S'), value: act?.number1s || getText('acts.stillNot')},
                        {key: getText('common.date'), value: moment(act?.date).format('DD.MM.YYYY')},
                    ]}/>
                </InfoBlock>


                <RenderIf condition={(rights?.acts?.edit)}>
                    <Input label="acts.actNumber" value={this.state.actNumber} change={this.changeActNumber}
                           blur={this.blurActNumber}/>
                </RenderIf>

                <RenderIf condition={
                    (rights?.acts?.edit &&
                        rights?.["acts.object"]?.edit)
                }>
                    <AsyncSelect
                        change={this.changeActObject}
                        loadOptions={(q: string) => groupObjectsByRegion({q})}
                        label={{text: "objects.object"}}
                        defaultValue={{
                            value: defaultObject,
                            accessors: {
                                name: 'label',
                                id: 'value'
                            }
                        }}
                    />
                </RenderIf>

                <RenderIf condition={(rights?.acts?.edit && rights?.["acts.date"]?.edit) && this.state.date !== undefined}>
                    <InlineCalendar isDisabled={!(rights?.acts?.edit && rights?.["acts.date"]?.edit)}
                                    date={(this.state.date as Moment)} onChange={this.onChangeDate}
                                    label={{text: "common.date"}}/>
                </RenderIf>

                <RenderIf condition={(rights?.acts?.edit)}>
                    <Select
                        change={this.changeCloseTypeHandler}
                        label="closeTypes.closeType"
                        defaultOptions={this.state.closeTypes}
                        placeholder={act?.closeType?.name}
                        accessors={{
                            name: "label",
                            id: 'value',
                        }}
                    />
                </RenderIf>

                <Act1s
                    act={act}
                    getAct={this.props.getAct}
                />
                <RenderIf condition={(
                    act?.object?.filial1s &&
                    act?.object?.contracts?.[0]?.contractor?.contractor1s &&
                    act?.responsible?.store1s
                )}>
                    <ActPhase
                        actId={act.id}
                        phase={act.phase}
                        handler={act.handler}
                        updateAct={this.props.updateAct}
                        disabled={!rights?.["acts.handler"]?.edit}
                    />
                </RenderIf>

                <Select
                    disabled={!(rights?.acts?.edit && rights?.["acts.responsible"]?.edit)}
                    change={this.changeResponsible}
                    label="common.executor"
                    load={this.getExecutorsList}
                    accessors={{
                        name: 'label',
                        id: 'value'
                    }}
                    defaultValue={responsible}
                />
                <ToggleSwitch disabled={!(rights?.acts?.edit && rights?.["acts.highpriced"]?.edit)}
                              label="acts.highpriced" send={this.changeHighpriced} defaultValue={act.highpriced}/>
                <ToggleSwitch label="common.estimate" send={this.changeCalculating} defaultValue={act.calculating}/>
                <ToggleSwitch label="common.checked" send={this.changeChecked} defaultValue={act.checked}/>

                <MultiSelect
                    change={this.changeTags}
                    load={this.getActTags}
                    placeholder="UI.select.placeholders.addTag"
                    accessors={{
                        name: 'label',
                        id: 'value'
                    }}
                    defaultValues={this.state.tags}
                    label="tags.tag"
                />

                <RenderIf condition={rights?.["acts.coworkers"]?.read}>
                    <ActTimeExpend
                        actId={act.id}
                        // responsible={act.responsible}
                        object={act.object}
                        coworkers={act.coworkers}
                    />
                </RenderIf>
                {/* <ActTimeExpend
                    actId={act.id}
                    // responsible={act.responsible}
                    object={act.object}
                    coworkers={act.coworkers}
                /> */}

                <ActDistance
                    actId={act.id}
                    object={act.object}
                />

                <VirtualAct virtualAct={act?.virtualAct}/>

                <ActScans
                    actId={act.id}
                    scans={act.scans}
                />

                <ActOrders
                    orders={act.orders}
                    actId={act.id}
                />
                <ActRegularWorks
                    actId={act.id}
                    regularWorks={act.regularWorks}
                />

                <ActTabs
                    actId={act.id}
                />
            </>
        )
    }
}

interface MapStateToProps {
    application: ApplicationReducer
}


const mapStateToProps = (state: MapStateToProps) => ({
    settings: state.application.settings,
    rights: state.application.rights
});

const mapDisptachToProps = (dispatch: Function) => ({
    openModal: (props: Modal) => dispatch(openModal(props))
});

export default connect(mapStateToProps, mapDisptachToProps)(ActDetail)