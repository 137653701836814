import React, {Component, useCallback, useEffect, useState} from 'react';
import {MultiProps, Option} from './select.types'
import ReactSelect, {ActionMeta, components} from 'react-select'
import {makeNormalizeParams} from "../../../utils/makeNormalizeParams";
import './select.css';
import withLabel from "../withLabel/withLabel";
import PersonInfo from "../dialog/personInfo/personInfo.controller";
import {renderToString} from "react-dom/server";
import GetText from "../../../localization/getText";
import getText from "../../../localization/getText";

export class PersonLabel extends Component<any, any> {
    componentDidMount() {
    }

    render() {
        const {children, data} = this.props;
        return (
            <div>
                <PersonInfo personNameClassName="person-name-simple"
                            position="up" person={data}
                            openByHover
                            canOpen={false}
                            customAction={data.customAction} />
            </div>
        );
    }
}

export class CustomRemove extends Component<any, any> {
    componentDidMount() {
    }

    render() {
        const {children, data, selectProps} = this.props;
        const isSelf = selectProps.showRemove ? selectProps.showRemove(data) : false;
        if(selectProps.isDisabled && !isSelf) return null;
        return (
            <components.MultiValueRemove {...this.props} />
        );
    }
}

interface Meta extends ActionMeta {
    removedValue?: Option
    option?: Option
}

const MultiSelect = ({
                         defaultOptions,
                         defaultValues,
                         placeholder = renderToString(GetText('UI.select.defaultChoose')),
                         accessors = {value: 'value', label: 'label'},
                         load,
                         id,
                         label,
                         isDisabled,
                         labelPosition,
                         change,
                         CustomLabel,
                         ...rest
                     }: MultiProps) => {

    const [options, setOptions] = useState<any[]>([]);
    const [values, setValues] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(isDisabled ? isDisabled : false);

    const onChangeHandler = async (options: any, meta: Meta) => {

        setLoading(true);
        setDisabled(true);
        let passed: boolean = false;

        switch (meta.action) {
            case "remove-value":
                passed = await change({value: (meta.removedValue as Option), type: meta.action});
                break;
            case "select-option":
                passed = await change({value: (meta.option as Option), type: meta.action});
                break;
        }
        if (passed) {
            setValues(options);
        }
        setLoading(false);
        setDisabled(false);
    };

    const onMenuOpenHandler = async () => {
        if (load) {
            setLoading(true);
            const data = await load();
            setLoading(false);
            const normalize = data.map(item => makeNormalizeParams(item, accessors));

            setOptions(normalize)
        }

        if (defaultOptions) {
            defaultOptions = defaultOptions.map(item => makeNormalizeParams(item, accessors));
            setOptions(defaultOptions)
        }
    };
    const ref = useCallback(() => {
        if (defaultValues) {
            const dv = defaultValues.map((item: any) => makeNormalizeParams(item, accessors));
            if (dv) {
                setValues(dv)
            }
        }
    }, [defaultValues]);

    useEffect(() => {
        if (isDisabled !== undefined) {
            setDisabled(isDisabled)
        }
    }, [isDisabled]);

    const cmp = <ReactSelect
        ref={ref}
        onMenuOpen={onMenuOpenHandler}
        onChange={onChangeHandler}
        isLoading={loading}
        value={values}
        inputId={id ? id.toString() : undefined}
        options={options}
        placeholder={placeholder ? getText(placeholder) : undefined}
        isDisabled={disabled}
        components={{
            MultiValueLabel: CustomLabel || components.MultiValueLabel,
            MultiValueRemove: CustomRemove || components.MultiValueRemove
        }}
        classNamePrefix='just-pro-select'
        className='just-pro-select__container'
        loadingMessage={() => renderToString(GetText('UI.select.loading'))}
        noOptionsMessage={() => renderToString(GetText('UI.select.noData'))}
        isMulti
        {...rest}

    />;

    return label ? withLabel(cmp)({text: label, id: id, position: labelPosition}) : cmp

};

export default MultiSelect