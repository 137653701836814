import React from 'react';
import WithPrivateRoute from '../withPrivateRoute/withPrivateRoute.controller';
import getPosition, {GeolocationError} from '../../utils/getPosition';
import HeaderController from '../header/header.controller';
import moment from 'moment-timezone';
import WorkOrderDetail from '../../components/workOrders/workOrder.detail';
import {
    delay,
    getMapObjects,
    getMapExecutors,
    get1SPriorities,
    StringBoolean,
    getSettings,
    getObjectName,
    WorkordersStates,
    createWorkorderByOrders,
    Executor,
    OrderListItem,
    getMapBasePoints,
    BasePointType,
    GOOGLE_API_KEY
} from '@justpro/terminal';
import Filter from '../UI/filter/filter.controller';
import checkError from '../../utils/checkError';
import {filterNames} from '../UI/checkboxList/checkboxes.const';
import CheckboxList from '../UI/checkboxList/checkboxList.contoller';
import {
    ActiveCheckboxesList,
    CheckboxesMap,
} from '../UI/checkboxList/checkboxList.types';

import {
    getRegionsList,
    getContractorList,
    getObjectsList,
    getOrdersTypesList,
    getPriorities1SList,
    getExecutorsList,
    getOrderStatusesList,
    getContractConditionsList,
    getEquipmentCategoriesList,
} from '../../utils/functions';
import Map from './map';
import Spinner from '../UI/spinner/spinner.controller';
import RenderIf from '../../utils/renderIf';
import './maps.css';
import WoCreation from './woCreation';
import {connect} from 'react-redux';
import {
    updateTouched,
    setIsWoCreatorOpen,
    setWoArray,
    setCurrentExecutor,
    toggleModeCheckRoutes,
} from '../../store/map/map.actions';
import {renderToString} from 'react-dom/server';
import getText from '../../localization/getText';
import {openModal} from '../../store/modal/modal.actions';

import ToggleSwitch from '../UI/toggleSwitch/toggleSwitch.controller';
import {ordersCreateWorkordersId} from '../orders/orders.controller';
import {Modal} from '../../store/modal/modal.types';
import {ModeType} from '../../store/map/map.types';
import {Object} from '@justpro/terminal';

export type Position =
    | {
    lat: number;
    lng: number;
}
    | string;

export type DistanceElement = {
    address: string;
    distance: number;
};

interface Props {
    touched: boolean;
    isWoCreatorOpen: boolean;
    openedObject: Object;
    currentExecutor: any;
    woArray: OrderListItem[];
    rights: any;
    settings: any;
    mode: ModeType;

    setIsWoCreatorOpen: (isWoCreatorOpen: boolean) => void;
    setWoArray: (array: Array<boolean>) => void;
    openModal: (props: Modal) => void;
    setCurrentExecutor: (executor: any) => void;
    toggleMode: (mode: ModeType) => void;
}

interface State {
    objects: any;
    executors: any;
    basePoints: any;
    errorMessage?: string;
    loading: boolean;
    position: Position | null;
    activeCheckboxes: ActiveCheckboxesList;
    checkboxesMap: CheckboxesMap;
    showNames: boolean;
    showAllObjects: boolean;
    activeStates: | {
        REGIONS_FILTER: number[];
    }
        | any;
    colorByAccident: boolean;
    directions: any;
    distancesList: DistanceElement[];
}

interface OrderSavedFilters {
    filters?: ActiveCheckboxesList;
    activeStates?: number[];
}

const DEFAULT_POSITION = {
    lat: 50.492647,
    lng: 30.444773,
};

type Coords = {
    lng: number;
    lat: number;
};
const R = 6371.071;
const RADIAN = Math.PI / 180;
const distanceByCoords = (loc1: Coords, loc2: Coords) => {
    const rLat1 = loc1?.lat * RADIAN;
    const rLat2 = loc2?.lat * RADIAN;
    const diffLat = rLat1 - rLat2;
    const diffLng = (loc1?.lng - loc2?.lng) * RADIAN;
    return (
        2 *
        R *
        Math.asin(
            Math.sqrt(
                Math.sin(diffLat / 2) ** 2 +
                Math.cos(rLat1) * Math.cos(rLat2) * Math.sin(diffLng / 2) ** 2
            )
        )
    );
    return Math.sqrt((loc1.lat - loc2.lat) ** 2 + (loc1.lng - loc2.lng) ** 2);
};

class Maps extends React.Component<Props, State> {
    state: State = {
        objects: null,
        activeCheckboxes: {},
        checkboxesMap: {},
        executors: null,
        basePoints: null,
        position: DEFAULT_POSITION,
        loading: false,
        errorMessage: void 0,
        showNames: false,
        showAllObjects: false,
        activeStates: {
            REGIONS_FILTER: [0],
        },
        directions: void 0,
        colorByAccident: false,
        distancesList: [],
    };

    getObjectsParams = () => {
        const ccFilter = this?.state?.activeCheckboxes?.CONDITIONS_FILTER;
        let contractConditions = void 0;
        if (ccFilter?.length === 1) {
            contractConditions = ccFilter[0];
        }
        return {
            regionsId: this?.state?.activeCheckboxes?.REGIONS_FILTER as number[],
            contractorsId: this?.state?.activeCheckboxes
                ?.CONTRACTORS_FILTER as number[],
            priorities1sId: this?.state?.activeCheckboxes
                ?.PRIORITIES_FILTER as number[],
            contractConditions,
            other: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes('1')
                ? '1'
                : '0') as StringBoolean,
            hot: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes('2')
                ? '1'
                : '0') as StringBoolean,
            expired: (this?.state?.activeCheckboxes?.URGENCY_ORDERS?.includes('3')
                ? '1'
                : '0') as StringBoolean,
            ordersTypesId: this?.state?.activeCheckboxes
                ?.ORDER_TYPES_FILTER as number[],
            ordersStatusesId: this?.state?.activeCheckboxes
                ?.ORDERS_FILTER as number[],
        };
    };

    getExecutorsParams = () => {
        const executorsAcception =
            this?.state?.activeCheckboxes?.[filterNames.EXETUTORS_ACCEPTION];
        const isWorking =
            executorsAcception?.length === 1 ? executorsAcception?.[0] : void 0;
        return {
            workordersStates: this?.state?.activeCheckboxes?.[
                filterNames.EXECUTOR_WO
                ] as WorkordersStates[],
            isWorking: isWorking as StringBoolean,
        };
    };


    getBasePointsParams = () => {
        const type = this?.state?.activeCheckboxes?.[filterNames.BASE_POINTS];
        return {type: type as BasePointType[]}
    };

    async componentDidUpdate(prevProps: Props) {
        if (this.props.mode !== prevProps.mode) {
            try {
                this.setState({
                    loading: true,
                    objects: [],
                });
                const obj =
                    this.props.mode === 'DEFAULT'
                        ? await getMapObjects(this.getObjectsParams())
                        : [];
                this.setState({
                    objects: obj,
                });
            } catch (err) {
                checkError(err);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    async componentDidMount() {
        try {
            const {
                ordersCanWorkorderStatus,
                orderDoneStatusId,
                orderToExecuteStatus,
                declineOrderId,
            } = await getSettings();
            this.setState({
                loading: true,
            });
            const regions = await getRegionsList();
            const contractors = await getContractorList();
            const priorities = await get1SPriorities();
            const orderTypes = await getOrdersTypesList();
            let statuses = await getOrderStatusesList();
            statuses = statuses.filter((status) => {
                return (
                    status.id != orderDoneStatusId &&
                    status.id != declineOrderId &&
                    status.active
                );
            });
            const urgency = {
                name: 'UI.filters.urgencyOrders.filter',
                children: [
                    {
                        id: '1',
                        name: renderToString(getText('UI.filters.urgencyOrders.all')),
                    },
                    {
                        id: '2',
                        name: renderToString(getText('UI.filters.urgencyOrders.hot')),
                    },
                    {
                        id: '3',
                        name: renderToString(getText('UI.filters.urgencyOrders.expired')),
                    },
                ],
            };

            const checkboxesMap = {
                [filterNames.ELEMENTS]: {
                    name: 'common.elements',
                    children: [
                        {name: 'objects.objects', id: 'objects'},
                        {name: 'common.executors', id: 'executors'},
                    ],
                },
                [filterNames.BASE_POINTS]: {
                    name: 'maps.basePoints.name',
                    children: [
                        {
                            name: 'maps.basePoints.regional',
                            id: '1',
                        },
                        {name: 'maps.basePoints.personal', id: '2'},
                    ],
                },
                [filterNames.REGIONS]: {
                    name: 'regions.regions',
                    children: regions,
                },
                [filterNames.CONTRACTORS]: {
                    name: 'contractors.contractors',
                    children: contractors.filter((c) => c.active),
                },
                [filterNames.PRIORITIES]: {
                    name: 'common.priority',
                    children: priorities.map((p) => ({
                        name: p.name,
                        id: p.id1s,
                    })),
                },
                [filterNames.CONDITIONS]: {
                    name: 'contractConditions.contractCondition',
                    children: [
                        {
                            name: 'contractConditions.withConditions',
                            id: '1',
                        },
                        {name: 'contractConditions.withOutConditions', id: '0'},
                    ],
                },
                [filterNames.URGENCY_ORDERS]: urgency,
                [filterNames.ORDER_TYPES]: {
                    name: 'orderTypes.moduleName',
                    children: orderTypes,
                },
                [filterNames.ORDERS]: {
                    name: 'orderStatuses.moduleName',
                    children: statuses.sort((a, b) => a.name.localeCompare(b.name)),
                },
                [filterNames.EXECUTOR_WO]: {
                    name: 'executorsWO.moduleName',
                    children: [
                        {name: 'executorsWO.withOrder', id: '1'},
                        {name: 'executorsWO.orderForLater', id: '2'},
                        {name: 'executorsWO.withOutOrder', id: '0'},
                    ],
                },
                [filterNames.EXETUTORS_ACCEPTION]: {
                    name: 'executorsAcception.moduleName',
                    children: [
                        {name: 'executorsAcception.accepted', id: '1'},
                        {name: 'executorsAcception.notAccepted', id: '0'},
                    ],
                },
            };
            this.setState({
                checkboxesMap,
                activeCheckboxes: {
                    [filterNames.ELEMENTS]: ['objects', 'executors'],
                    [filterNames.ORDERS]: [
                        +ordersCanWorkorderStatus,
                        +orderToExecuteStatus,
                    ],
                },
            });
            // const obj =
            //   this.props.mode === 'DEFAULT'
            //     ? await getMapObjects(this.getObjectsParams())
            //     : [];
            const obj = await getMapObjects(this.getObjectsParams());
            const executors = await getMapExecutors(this.getExecutorsParams());
            const basePoints = await getMapBasePoints(this.getBasePointsParams());
            this.setState({
                objects: obj,
                executors,
                basePoints,
            });
            this.optionalSetPosition();
        } catch (err) {
            if (err instanceof GeolocationError) {
                this.setState({
                    errorMessage: err.toString(),
                });
            }
            return;
            checkError(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    optionalSetPosition = async () => {
        try {
            const position = await getPosition();
            if (position) {
                this.setState({
                    position,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    onCheckboxesChange = (activeCheckboxes) => {
        this.setState({
            activeCheckboxes,
        });
    };

    getActiveFilters = (): OrderSavedFilters => {
        return {
            filters: this?.state?.activeCheckboxes,
            activeStates: this?.state?.activeStates,
        };
    };

    setFilters = (savedFilters: OrderSavedFilters) => {
        const {filters, activeStates} = savedFilters;
        this.setState(
            () => ({
                activeStates: activeStates ? activeStates : [],
                activeCheckboxes: filters ? filters : {},
            }),
            this.filterSubmit
        );
    };

    filterSubmit = async () => {
        try {
            this.setState({
                loading: true,
            });
            const filterObjects = await getMapObjects(this.getObjectsParams());
            const executors = await getMapExecutors(this.getExecutorsParams());
            const basePoints = await getMapBasePoints(this.getBasePointsParams());
            //
            if (this.state.showAllObjects) {
                const allObjects = await getMapObjects({});
                this.setState({
                    objects: allObjects.map((obj) => {
                        const object = filterObjects.find((o) => obj.id === o.id);
                        if (object) return object;
                        return {
                            ...obj,
                            notInFilter: true,
                        };
                    }),
                    executors,
                    basePoints,
                });
            } else {
                this.setState({
                    objects: filterObjects,
                    executors,
                    basePoints,
                });
            }
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    };

    toggleShowNames = () => {
        this.setState((prevState) => ({
            showNames: !prevState.showNames,
        }));
    };
    toggleShowObjects = () => {
        this.setState((prevState) => ({
            showAllObjects: !prevState.showAllObjects,
        }));
    };
    toggleShowWoCreator = () => {
        this.props.setIsWoCreatorOpen(!this.props.isWoCreatorOpen);
    };

    toggleColorizeBy = (value: boolean) => {
        this.setState(() => ({
            colorByAccident: value,
        }));
    };

    buildRoutes = async (optimize) => {
        let waypoints = [];
        const {google}: any = window;
        const {woArray, currentExecutor} = this.props;
        const travelMode = 'DRIVING';
        const allPoints = woArray.map((order) => {
            return {
                lat: order.object.latitude,
                lng: order.object.longitude,
                object: order.object,
            };
        });
        const directionsService = new google.maps.DirectionsService();
        if (
            !(
                currentExecutor?.position?.lat &&
                currentExecutor?.position?.lng &&
                woArray?.length
            )
        ) {
            this.setState({
                distancesList: [],
                directions: void 0,
            });
            //   return;
        }
        if (optimize) {
            const copyAllPoints = [...allPoints];
            const lastPosition = waypoints?.length
                ? waypoints[waypoints.length - 1].location
                : currentExecutor?.position;
            while (copyAllPoints?.length) {
                let minDistance = Math.min();
                let closestIndex: number = 0;
                copyAllPoints.forEach((location, index) => {
                    const distance = distanceByCoords(location, lastPosition);
                    if (minDistance > distance) {
                        closestIndex = index;
                        minDistance = distance;
                    }
                });
                const closestWaypoint: any = copyAllPoints[closestIndex];
                copyAllPoints.splice(closestIndex, 1);
                waypoints.push({
                    object: closestWaypoint.object,
                    location: closestWaypoint,
                });
            }
        } else {
            waypoints = allPoints.map((location) => ({
                object: location.object,
                location,
            }));
        }
        const routeParams = {
            travelMode: travelMode,
            origin: currentExecutor?.position,
            destination: waypoints[waypoints.length - 1]?.location,
            waypoints: waypoints.slice(0, -1).map(({location}) => ({
                location,
            })),
        };
        directionsService.route(routeParams, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                this.setState({
                    directions: result,
                    distancesList: result?.routes?.[0]?.legs
                        .map((leg, index) => {
                            return {
                                address: getObjectName(waypoints[index].object),
                                distance: leg?.distance?.value || 0,
                            };
                        })
                        .filter((d) => d.distance),
                });
            } else {
                console.error(`error fetching directions ${result}`);
            }
        });
    };

    createWorkorder = async ({date, coworkers}) => {
        try {
            const {
                woArray,
                currentExecutor,
                setWoArray,
                setCurrentExecutor,
                openModal,
            } = this.props;
            const params = {
                orders: woArray.length
                    ? woArray.map((el) => el.id)
                    : woArray.map((el) => el.id),
                responsibleId: currentExecutor?.value,
                coworkers,
                date,
            };
            this.setState({
                loading: true,
            });
            this.toggleShowWoCreator();
            const returnedWO = await createWorkorderByOrders(params);
            setWoArray([]);
            openModal({
                id: ordersCreateWorkordersId,
                title: 'Наряд',
                component: (props) => {
                    return (
                        <>
                            {returnedWO.map((el) => (
                                <div className="createdWorkOrderMaps">
                                    <WorkOrderDetail
                                        workOrder={el}
                                        updateWorkOrder={() => {
                                        }}
                                        updateItem={() => {
                                        }}
                                    />
                                </div>
                            ))}
                        </>
                    );
                },
            });
            this.setState({
                distancesList: [],
                directions: void 0,
            });
            setCurrentExecutor(void 0);
            await this.filterSubmit();
        } catch (err) {
            checkError(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    };

    toggleMode = () => {
        if (this.props.mode === 'DEFAULT') {
            this.props.toggleMode('CHECK_ROUTES');
            this.props.setIsWoCreatorOpen(true);
        } else {
            this.props.toggleMode('DEFAULT');
        }
    };

    render() {
        const {loading, activeCheckboxes, checkboxesMap} = this.state;
        console.log('this.state.basePoints', this.state.basePoints)
        return (
            <WithPrivateRoute>
                <div className="map-main__container">
                    <HeaderController
                        savedFilters={{
                            module: 'map',
                            setFilters: this.setFilters,
                            getCurrentFilters: this.getActiveFilters,
                        }}
                    >
                        <Filter
                            activeCheckboxes={activeCheckboxes}
                            send={this.filterSubmit}
                        >
                            <div className="just-pro__checkbox-children">
                                <input
                                    className="maps_filterInput"
                                    type="checkbox"
                                    checked={this.state.showNames}
                                    onChange={this.toggleShowNames}
                                />
                                <b>{renderToString(getText('common.showNames'))}</b>
                            </div>
                            <div className="just-pro__checkbox-children">
                                <input
                                    className="maps_filterInput"
                                    type="checkbox"
                                    checked={this.state.showAllObjects}
                                    onChange={this.toggleShowObjects}
                                />
                                <b>{renderToString(getText('common.allObjects'))}</b>
                            </div>

                            <CheckboxList
                                checkboxesMap={checkboxesMap}
                                activeCheckboxes={activeCheckboxes}
                                send={this.onCheckboxesChange}
                            />
                            <div className="just-pro__urgent-color-mode">
                                <ToggleSwitch
                                    send={this.toggleColorizeBy}
                                    label="По аварийности"
                                    position="top"
                                    className="just-pro__toggle_container"
                                    defaultValue={this.state.colorByAccident}
                                />
                            </div>
                        </Filter>
                        <div className="navbar-form navbar-left buttons navbar-toggle">
                            <ToggleSwitch
                                send={this.toggleMode}
                                defaultValue={this.props.mode === 'CHECK_ROUTES'}
                            />
                            <div className="toggle-label">
                                {renderToString(getText('maps.routesMode'))}
                            </div>
                        </div>
                    </HeaderController>

                    <div className="woHandleButton">
            <span
                onClick={this.toggleShowWoCreator}
                style={
                    this.props.isWoCreatorOpen
                        ? {transform: 'rotate(0)'}
                        : {transform: 'rotate(-180deg)'}
                }
            >
              {' '}
                ➝{' '}
            </span>
                    </div>
                    <div className="woHandleButton_container"/>

                    <WoCreation
                        origin={this.state.position}
                        executors={this.state.executors}
                        toggleIsOpen={this.toggleShowWoCreator}
                        buildRoutes={this.buildRoutes}
                        distancesList={this.state.distancesList}
                        createWorkorder={this.createWorkorder}
                    />

                    <div id="maps" className="just-pro_module maps">
                        <Spinner loading={loading}/>
                        <RenderIf condition={!this.state.loading}>
                            <Map
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${GOOGLE_API_KEY}`}
                                loadingElement={<div className="map-container_element"></div>}
                                containerElement={<div className="map-container_element"/>}
                                mapElement={<div className="map-container_element__inner"/>}
                                objects={
                                    activeCheckboxes?.ELEMENTS_FILTER?.includes('objects') &&
                                    this?.state?.objects
                                }
                                executors={this?.state?.executors}
                                basePoints={this?.state?.basePoints}
                                position={this.state?.position}
                                showNames={this.state?.showNames}
                                showExecutors={activeCheckboxes?.ELEMENTS_FILTER?.includes(
                                    'executors'
                                )}
                                openedObject={this.props?.openedObject}
                                orderToExecuteStatus={
                                    this.props?.settings?.orderToExecuteStatus
                                }
                                // woArray={this.props?.woArray}
                                colorByAccident={this.state.colorByAccident}
                                directions={this.state.directions}
                            />
                        </RenderIf>
                    </div>
                </div>
            </WithPrivateRoute>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        touched: state?.maps?.touched,
        isWoCreatorOpen: state?.maps?.isWoCreatorOpen,
        openedObject: state?.maps?.openedObject,
        currentExecutor: state?.maps?.currentExecutor,
        woArray: state?.maps?.woArray,
        rights: state.application.rights,
        settings: state.application.settings,
        mode: state?.maps?.mode,
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        setIsWoCreatorOpen: (isOpen) => {
            dispatch(setIsWoCreatorOpen(isOpen));
        },
        setWoArray: (array) => {
            dispatch(setWoArray(array));
        },
        openModal: (props: Modal) => {
            dispatch(openModal(props));
        },
        setCurrentExecutor: (executor) => dispatch(setCurrentExecutor(executor)),
        toggleMode: (mode) => dispatch(toggleModeCheckRoutes(mode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
