import React from "react";
import styled from "styled-components";
import GetText from "../../../localization/getText";


export type ILabelPosition = 'top' | 'left'

export interface LabelProps {
    text : string
    labelVars? : any
    id? : number | string
    position? : ILabelPosition
    indent? : boolean
    className?: string
}

interface WrapperProps {
    hasId : boolean
    position : ILabelPosition
    indent: boolean

}

const Wrapper = styled.div<WrapperProps>`
    display : flex;
    padding : 5px 0;
    width : 100%;
    flex-direction : ${({position}) => position === 'left' ? 'row' : position === 'top' ? 'column' : ''}
    
    @media screen and (max-width: 1255px) {
        flex-direction: column;
    }
    
    
    & .text-content {
        font-size : 16px;
        flex-basis : 30%;
        @media screen and (max-width: 1255px) {
            align-self : flex-start;
            padding-bottom : 20px;
        }
    }
    
    & .text {
        border-bottom : ${(props) =>  props.hasId ? '1px dashed black' : 'none'};
        cursor : ${(props) =>  props.hasId ? 'pointer' : 'default'};
    }
    
    & .component {
        flex-basis : 70%;
        
        & >:first-child {
            ${({indent}) => !indent ? 'margin-top : -20px;' : ''}
        }
        
    }
`;


export const withLabel = (Component:JSX.Element ) => {

    return ({text, id, position = 'left', labelVars, indent = true, className}:LabelProps) => {

        return (
            <Wrapper position={position} hasId={!!id} indent={indent}>
                <label className="text-content" htmlFor={id ? id.toString() : undefined}>
                    <span className="text">
                        {GetText(text, labelVars)}
                    </span>
                </label>

                <div className={"component " + className}  >
                    {Component}
                </div>
            </Wrapper>
        )

    }
};

export default withLabel;