import React from "react";
import {editTransport, getTransport, getTransports, Transport, TransportsFilterParams} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import TransportDetail from "./transport.detail";
import {LoadReturn} from "../../UI/select/select.types";

interface Props  {
    id: number
    afterUpdate?(item:Transport) : void
}
interface State  {
    transport? : Transport
    loading : boolean
}

class TransportController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };


    static getAll(filters:TransportsFilterParams = {q : ""}):LoadReturn {

        return new Promise (async resolve => {
            try {

                const all = await getTransports(filters);

                if(all && Array.isArray(all)) {
                    resolve(all);
                }

            }catch (e) {
                checkError(e)
            }

            resolve([]);

        })

    }

    updateTransport = async (data:Partial<Transport>) => {
        const {afterUpdate} = this.props;
        this.setState(() => ({loading : true}));

        try {
            const transport = await editTransport(this.props.id, data);

            afterUpdate && afterUpdate(transport);

            this.setState(() => ({loading : false, transport}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    getTransport = async () => {
        this.setState(() => ({loading : true}));

        try {
            const transport = await getTransport(this.props.id);

            this.setState(() => ({loading : false, transport}))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getTransport()
        }
    }

    async componentDidMount() {
        this.getTransport();
    }

    render () {
        const {transport} = this.state;

        return (
            <>
                <RenderIf condition={transport !== undefined}>
                    <TransportDetail transport={transport as Transport} update={this.updateTransport} />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }

}

export default TransportController
