export type ModeType = 'DEFAULT' | 'CHECK_ROUTES'
export type buildRoutesModeType = 'FACT' | 'RESULT'

export const initialState = {
    touched: false,
    isWoCreatorOpen: false,
    openedObject: null,
    woArray: [],
    bpArray: [],
    bpDirections: null,
    arrangedWoArray: [],
    mode: 'DEFAULT',
    buildRoutesMode: 'FACT',
    selectedPointId: null,
}