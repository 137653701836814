import React from 'react';
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import Button from "../../UI/button/button";
import {Option} from "../../UI/select/select.types";
import {getEquipmentCategoriesList} from '../../../utils/functions'
import {CreateEquipmentType} from "@justpro/terminal";
import getText from "../../../localization/getText";

type Props = {
    create(data:CreateEquipmentType):void
}
type State = {
    description : string,
    name : string
    categoryId? : number
}

class EquipmentTypeCreate extends React.Component<Props, State> {

    state:State = {
        description : '',
        name : '',
    };

    create = async () => {
        if(!this.isDisabled()) {
            const {description, categoryId, name} = this.state;
            const data:CreateEquipmentType = {
                name,
                equipmentCategoryId : categoryId as number,
            };

            if(description !== '') {
                data.description = description
            }

            await this.props.create(data);
        }
    };

    changeName = (name:string) => {
        this.setState(() => ({name}))
    };

    changeDescription = (description:string) => {
        this.setState(() => ({description}))
    };

    changeCategory = (option:Option) => {
        return new Promise (resolve => {
            console.log({
                option
            });

            this.setState(() => ({
                categoryId : option.value
            }));
            resolve()
        })
    };

    isDisabled = () => {
        const {categoryId, name} = this.state;
        return name.trim() === '' || categoryId === undefined;
    };



    render () {
        return (
            <>
                <h3>{getText('equipmentTypes.addingNewHeader')}</h3>

                <Input
                    label="equipmentTypes.equipmentType"
                    change={this.changeName}
                    placeholder="equipmentTypes.equipmentType"
                />

                <Select
                    change={this.changeCategory}
                    load={getEquipmentCategoriesList}
                    label="equipmentCategories.equipmentCategory"
                    accessors={{
                        id : 'value',
                        name : 'label'
                    }}
                />
                <Input
                    label="common.description"
                    change={this.changeDescription}
                    placeholder="common.description"
                />

                <Button className="btn-success" disabled={this.isDisabled()} onClick={this.create}>{getText('common.add')}</Button>
            </>
        )
    }
}



export default EquipmentTypeCreate