import React from "react";
import {
    EditEquipment,
    editEquipment,
    Equipment,
    getEquipment,
} from "@justpro/terminal";
import RenderIf from "../../../utils/renderIf";
import checkError from "../../../utils/checkError";
import Spinner from "../../UI/spinner/spinner.controller";
import EquipmentDetail from "./equipment.detail";


interface Props {
    id : number
    afterUpdate?(item:Equipment) : void
}

interface State {
    equipment? : Equipment
    loading : boolean
}

class EquipmentController extends React.Component<Props, State>{

    state:State = {
        loading : false
    };

    getEquipment = async () => {
        this.setState(() => ({loading : true}));
        try {
            const equipment = await getEquipment(this.props.id);

            this.setState(() => ({
                loading : false,
                equipment
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };

    updateEquipment = async (data:Partial<EditEquipment>) => {
        this.setState(() => ({loading : true}));
        try {
            const equipment = await editEquipment(this.props.id, data);

            this.props.afterUpdate
            && (
                data.hasOwnProperty('number') ||
                data.hasOwnProperty('active')
            )
            && this.props.afterUpdate(equipment);

            this.setState(() => ({
                loading : false,
                equipment
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }

        this.setState(() => ({loading : false}))
    };

    async componentDidMount() {
        this.getEquipment();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.getEquipment();
        }
    }

    render() {
        return (
            <>
                <RenderIf condition={this.state.equipment !== undefined}>
                    <EquipmentDetail
                        update={this.updateEquipment}
                        equipment={this.state.equipment as Equipment}
                    />
                </RenderIf>

                <Spinner loading={this.state.loading} />
            </>
        )
    }
}

export default EquipmentController