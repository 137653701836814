import { addPersonPhone,
    updatePersonPhone,
    deletePersonPhone,

    addPersonEmail, 
    updatePersonEmail,
    deletePersonEmail,

    addPersonMessenger,
    updatePersonMessenger,
    deletePersonMessenger, 
} from '@justpro/terminal'
import {PHONE_TYPE, EMAIL_TYPE, MESSENGER_TYPE} from './contactTypes'

export const addContactData = async (personId, contact, getPerson) => {
    switch (contact) {
        case PHONE_TYPE:
            await addPersonPhone({
                personId,
                phoneTypeId: 1,
                value: ' '
            })
            break;
        case EMAIL_TYPE:
            await addPersonEmail({
                personId,
                emailTypeId: 1,
                value: ' '
            })
            break;
        case MESSENGER_TYPE:
            await addPersonMessenger({
                personId,
                messengerTypeId: 1,
                value: ' '
            })
            break;
        default:
            break;
    }
    getPerson();
}

export const updateContactData = async ({ personId, contact, contactId, typeId, value, getPerson}) => {
    switch (contact) {
        case PHONE_TYPE:
            await updatePersonPhone({
                personId,
                personPhoneId: contactId,
                phoneTypeId: typeId,
                value
            })
            break;
        case EMAIL_TYPE:
            await updatePersonEmail({
                personId,
                personEmailId: contactId,
                emailTypeId: typeId,
                value
            })
            break;
        case MESSENGER_TYPE:
            await updatePersonMessenger({
                personId,
                personMessengerId: contactId,
                messengerTypeId: typeId,
                value
            })
            break;
        default:
            return;
    }
    getPerson();
}

export const deleteContactData = async (personId, getPerson, contact, contactId) => {
    switch (contact) {
        case PHONE_TYPE:
            await deletePersonPhone({
                personId, 
                personPhoneId: contactId,
            })
            break;
        case EMAIL_TYPE:
            await deletePersonEmail({
                personId, 
                personEmailId: contactId,
            })
            break;
        case MESSENGER_TYPE:
            await deletePersonMessenger({
                personId, 
                personMessengerId: contactId,
            })
            break;
        default:
            return;
    }
    getPerson();
}