import React from 'react';
import {CreateTag} from "@justpro/terminal";
import Input from "../../UI/input/text";
import InputColor from "../../UI/input/color";
import Button from "../../UI/button/button";
import IconPiker from "../../UI/iconPiker/iconPicker.controller";
import {fromClassName, getIconsList} from "../../../utils/icons";
import {ChangeMultiProps} from "../../UI/select/select.types";
import MultiSelect from "../../UI/select/multiSelect";
import getModules from "./getModules";
import getText from "../../../localization/getText";

const icons = getIconsList();

type Props = {
    create(data:CreateTag) : void
};

type State = {
    color : string
    name : string
    icon : string

    modules : string[]
};

export default class TagCreate extends React.Component<Props, State> {
    state:State = {
        color: '#000000',
        name: '',
        icon : '',
        modules : []
    };

    onChangeName = (name: string) => {

        this.setState(() => ({name}))
    };
    onChangeColor = (color: string) => {
        this.setState(() => ({color}))

    };
    onChangeIcon = (icon: string) => {
        this.setState(() => ({icon}))
    };

    save = () => {
        if(!this.isDisabled()) {
            const [prefix, iconName] = fromClassName(this.state.icon);

            const data = {
                color : this.state.color,
                name : this.state.name,
                icon : `${prefix}:${iconName}`,
                modules : this.state.modules
            };

            this.props.create(data);

        }
    };

    isDisabled = () => {
        const {color, name, icon} = this.state;

        return  name.trim() === '' ||
                icon.trim() === ''
    };


    onChangeModule = async (option:ChangeMultiProps) => {

        switch (option.type) {
            case "select-option":
                this.setState(({modules}) => ({
                    modules : [...modules, option.value.value]
                }));
                break;
            case "remove-value":

                this.setState(({modules}) => ({
                    modules : modules.filter(i => i !== option.value.value)
                }));
                break;
        }


        return Promise.resolve(true)

    };

    render () {

        return (
            <>
                <h3>{getText('tags.addingNewHeader')}</h3>

                <Input
                    label="tags.tag"
                    change={this.onChangeName}
                />

                <MultiSelect
                    change={this.onChangeModule}
                    defaultOptions={getModules()}
                    label="tags.modules"
                    accessors={{name : 'label', id : 'value'}}
                    placeholder="tags.defineWhatModulesWillBeUseThisTag"
                />

                <InputColor
                    label="common.color"
                    blur={this.onChangeColor}
                    defaultValue={this.state.color}
                />

                <IconPiker
                    list={icons}
                    label="common.icon"
                    change={this.onChangeIcon}
                />
                <Button className="btn-success" onClick={this.save} disabled={this.isDisabled()}>{getText('common.add')}</Button>
            </>
        )
    }
}

