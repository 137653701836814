import React from "react";
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import InlineDropzone from "../../UI/dropzone/inline/inlineDropzone.controller";
import {toast} from "react-toastify";
import {AddFile, IScan, packageUpload, SCAN_NAME_REGEXP} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import UploadedScan from "./packageUpload.uploadedScan";
import UploadProgressBoxController from "./uploadProgressBox.controller";
import getText from "../../../localization/getText";
import {renderToString} from "react-dom/server";

export interface UploadProcessingMessage {
    message : string
    type : 'error' | 'info' | 'success'
}

interface State {
    isLoading : boolean,
    uploads : IScan[]
    rejectedFiles : AddFile[]
    verified : AddFile[]
    uploadProcessing : UploadProcessingMessage[]
}

interface IVerifyScanRespons {
    verified : AddFile[]
    rejected : AddFile[]
}

class ActsPackageUploadController extends React.Component<null, State>{

    state:State = {
        isLoading : false,
        uploads : [],
        rejectedFiles : [],
        uploadProcessing : [],
        verified : [],

    };


    upload = async () => {
        try {
            this.setState((prevState) => ({
                isLoading : true,
                uploadProcessing : [
                    ...prevState.uploadProcessing,
                    ...prevState.verified.map(item => ({message : `${item.name} ${renderToString(getText('common.uploading'))}`, type : 'info' as const}))
                ]
            }));
            const {data, warnings} = await packageUpload(this.state.verified);


            let uploadProcessing:UploadProcessingMessage[] = this.state.uploadProcessing;

            if(warnings?.length > 0){
                uploadProcessing = [
                    ...this.state.uploadProcessing,
                    ...warnings.map(item => ({message : item, type : 'error' as const}))
                ]
            }else{
                if(data) {
                    uploadProcessing = [
                        ...this.state.uploadProcessing,
                        ...data.map(item => ({message : `${item.originalName} ${renderToString(getText('acts.successUploadFile'))}`, type : 'success' as const}))
                    ]
                }
            }

            if(data && Array.isArray(data)) {
                this.setState((prevState) => ({
                    uploads : [...prevState.uploads, ...data],
                    isLoading : false,
                    uploadProcessing,
                }))
            }else{
                this.setState(() => ({isLoading : false}));
                toast.warn(renderToString(getText('acts.failedUploadFile')))
            }


        }catch (e) {
            checkError(e)
        }
    };


    addScan = async (files:File[]) => {
        const warning = renderToString(getText('acts.warningTemplate'));
        const result = this.verifyScan(files);
        if(result) {
            await this.setState(() => ({
                rejectedFiles : result.rejected,
                verified : result.verified
            }));

            const {rejectedFiles, verified} = this.state;
            const verifiedEntries = Object.entries(verified);

            if(verifiedEntries.length > 0) {

                if(rejectedFiles.length > 0) {

                    const agree = await ({
                        title : 'common.agreeAction',
                        children : (
                            <>
                                <h4>{getText('acts.someFileDoesNotEqualTemplate')}</h4>

                                <ul>
                                    {result.rejected.map((file, index) => {
                                        return <li key={index}>
                                            {file.name}
                                        </li>
                                    })}
                                </ul>


                                <p>{getText('acts.uploadRestFiles')}</p>
                            </>
                        )
                    });

                    if(agree) {
                        this.upload();
                    }
                }else{
                    this.upload()
                }
            }else if (rejectedFiles.length > 0) {
                toast.warn(warning)
            }
        }else{
            toast.warn(warning)
        }
    };

    verifyScan = (files:File[]):IVerifyScanRespons | undefined => {

        return files?.reduce((acc:IVerifyScanRespons, scan) => {

            const addFile:AddFile = {
                blob: scan,
                name : scan.name.replace("(", '').replace(")", '')
            };
            const regexpTest = SCAN_NAME_REGEXP.test(addFile.name);


            if(regexpTest) {
                return {
                    ...acc,
                    verified : [...acc.verified, addFile]
                }
            }

            return {
                ...acc,
                rejected : acc.rejected ? [...acc.rejected, addFile] : [addFile]
            }

        }, {rejected : [], verified : []})
    };


    render () {

        return (
            <WithPrivateRoute>
                <div className="top-line">
                    <ModuleTextName>{getText('acts.packageUpload')}</ModuleTextName>
                </div>

                <div className="just-pro_module">
                    <div className="panel"/>
                    <div className="panel"/>
                    <div className="panel content-panel">
                    <InlineDropzone
                        setFiles={this.addScan}
                        types={['image/jpeg', 'image/jpg']}
                        title=""
                    >
                        <div style={{padding : 20}}>
                            <h6>{getText('acts.uploadTemplate.header')}</h6>
                            <p>{getText('acts.uploadTemplate.coupleFilesAndTemplate')}</p>
                            <ul>
                                <li>{getText('acts.uploadTemplate.number')}</li>
                                <li>{getText('acts.uploadTemplate.symbol')}</li>
                                <li>{getText('acts.uploadTemplate.page')}</li>
                            </ul>

                            <p>
                                {getText('acts.uploadTemplate.example')}<br/>
                                {getText('acts.uploadTemplate.existAct')}<br/>
                                {getText('acts.uploadTemplate.whileLoading')}<br/>
                                12345-3.jpg,<br/>
                                12345-5.jpg,<br/>
                                12345-10.jpg<br/>
                                {getText('acts.uploadTemplate.willAppear')}<br/>
                                2 (12345-3.jpg),<br/>
                                3 (12345-5.jpg),<br/>
                                4 (12345-10.jpg).<br/>
                            </p>
                        </div>
                    </InlineDropzone>

                    <UploadedScan scans={this.state.uploads} />

                    </div>
                    <div className="panel content-panel">
                        <h4>{getText('acts.progress')}</h4>

                        <UploadProgressBoxController messages={this.state.uploadProcessing}/>
                    </div>
                </div>

            </WithPrivateRoute>)
    }
}

export default ActsPackageUploadController