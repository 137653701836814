import React from "react";
import {Detail} from "../references.types";
import {
    Staff,
    Contractor,
    CreateStaffPosition, createStaff, getStaff, SettingsMap
} from "@justpro/terminal";
import checkError from "../../../utils/checkError";
import Card from "../../UI/itemsList/card/card.controller";
import WithPrivateRoute from "../../withPrivateRoute/withPrivateRoute.controller";
import ModuleTextName from "../../UI/moduleTextName/moduleTextName";
import Search from "../../UI/search/search.controller";
import Tooltip from "../../UI/tooltip/tooltip.controller";
import Button from "../../UI/button/button";
import ItemList from "../../UI/itemsList/itemList.controller";
import RenderIf from "../../../utils/renderIf";
import {getContractorList} from "../../../utils/functions";
import StaffDetail from "./staff.detail";
import StaffCreate from "./staff.create";
import './staff.css'
import {toast} from "react-toastify";
import HeaderController from "../../header/header.controller";
import {connect} from "react-redux";
import {ApplicationMapState} from "../../application/application.controller";
import getText from "../../../localization/getText";
import {Modal, ModalBodyProps} from "../../../store/modal/modal.types";
import {openModal} from "../../../store/modal/modal.actions";

interface Props {
    settings?: Partial<SettingsMap>
    openModal(props:Modal) : void
}

interface State {
    detail : Detail
    q : string
    loading : boolean
    list : Staff[]
    staff : Staff[]
    // shouldUpdateTree : boolean
    id? : number
}
export const createStaffModalId = 'createStaffModal';

class StaffsController extends React.Component<Props, State>{
    state:State = {
        loading : false,
        q : '',
        detail: "",
        list :  [],
        staff: [],
        // shouldUpdateTree : false,
    };

    searchSubmit = async (q:string) => {
        await this.setState(() => ({q}));
        await this.getStaffStructures();
    };

    openCreateModal = () => {
        this.props.openModal({
            id : createStaffModalId,
            title : getText('staff.addingNewHeader'),
            component : (props:ModalBodyProps) => (
                <StaffCreate
                    {...props}
                    contractorId={this.state.id as number}
                    create={this.createStaff}
                />
            )
        })

    };

    getStaffStructures = async () => {
        const {settings} = this.props;

        if(settings) {
            this.setState(() => ({loading : true}));
            try {
                const list = await getContractorList({q : this.state.q});

                const sorted = list.slice(0).sort((a:Contractor, b:Contractor) => {
                    return a.id === +settings?.tfmContractorId ? b.id === +settings?.tfmContractorId ? 1 : -1 : 0;
                    // return a.id === 60 ? b.id === 60 ? 1 : -1 : 0;
                });

                this.setState(() => ({list : sorted, loading : false}))

            }catch (e) {
                this.setState(() => ({loading : false}));
                checkError(e)
            }
        }

    };

    updateTree = async (staff?:Staff[]) => {

        if(staff) {
            this.setState((prevState) => ({
                staff,
                // shouldUpdateTree : !prevState.shouldUpdateTree
            }))
        }else {
            const {id} = this.state;

            try {
                if(id) {
                    const staff = await getStaff(id, {});

                    this.setState((prevState) => ({
                        staff,
                        // shouldUpdateTree : !prevState.shouldUpdateTree
                    }))
                }else{
                    toast.warn('contractor id is not specified (frontend)')
                }
            }catch (e) {
                checkError(e)
            }
        }

    };

    createStaff = async (data:CreateStaffPosition) => {
        const {id} = this.state;

        if(id) {
            try {
                const createdStaff = await createStaff(id, data);

                this.setState((prevState) => ({
                    loading : false,
                    staff : prevState.staff ? [...prevState.staff, createdStaff] : [createdStaff]
                }))

            }catch (e) {
                checkError(e)
            }
        }
    };

    renderItem = (item:Contractor) => {
        return (<Card
            id={item.id}
            key={item.id}
            onClick={this.changeListItem.bind(this, item.id)}
            listActiveItem={this.state.id}
            isActiveReference={item.active}
            data-id={item.id}
            className="staff-structure__list-item"
            size={50}
            color="default"
        >
            <p className="text-overflow header">{item.name}</p>
            <p className="text-overflow">{item.pseudoname}</p>

        </Card>)
    };

    changeListItem = async (id:number) => {
        try {
            const staff = await getStaff(id, {});

            this.setState(() => ({
                id,
                detail : "detail",
                staff
            }))
        }catch (e) {
            checkError(e);
        }
    };


    async componentDidMount() {
        await this.getStaffStructures();
    }

    async componentDidUpdate(prevProps:Readonly<Props>) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) ) {
            await this.getStaffStructures();
        }
    }

    render () {
        return (
            <WithPrivateRoute>

                <HeaderController >
                    <ModuleTextName>{getText('staff.moduleName')}</ModuleTextName>

                    <Search
                        submit={this.searchSubmit}
                        eraser={true}
                    />

                    <div className='navbar-form navbar-left buttons'>
                        <RenderIf condition={this.state.id !== undefined}>
                            <Tooltip position="down" title="staff.addItem">
                                <Button
                                    onClick={this.openCreateModal}
                                    className="btn-default"
                                >
                                    <i className="fa fa-plus"/>
                                </Button>
                            </Tooltip>
                        </RenderIf>
                    </div>

                </HeaderController>


                <div className="just-pro_module">
                    <div className="panel"/>
                    <div className="panel"/>
                    <div className="panel content-panel">
                        <ItemList
                            loading={this.state.loading}
                            renderItem={this.renderItem}
                            list={this.state.list}
                        />
                    </div>
                    <div className="panel content-panel">
                        <RenderIf condition={this.state.id !== undefined}>
                            <StaffDetail
                                id={this.state.id as number}
                                staff={this.state.staff}
                                updateTree={this.updateTree}
                                // shouldUpdate={this.state.shouldUpdateTree}
                            />
                        </RenderIf>
                    </div>
                </div>
            </WithPrivateRoute>
        )

    }
}

const mapState = (state:ApplicationMapState) => ({
    settings : state.application.settings
});

const mapDispatch = (d:Function) => ({
    openModal : (props:Modal) => d(openModal(props))
});

export default connect(mapState,mapDispatch)(StaffsController)