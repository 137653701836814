import React from "react";
import Input from "../../UI/input/text";
import {ModulesResponse, Unit} from "@justpro/terminal";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";

interface Props {
    unit : Unit
    update(data:Partial<Unit>):void

    rights?: Partial<ModulesResponse>
}

class UnitDetail extends React.Component<Props> {

    onBlurName = async (name: string, oldValue: string) => {
        if(name.trim() !== '' && name !== oldValue) {
            this.props.update({name})
        }
    };

    changeActive = (active: boolean) => {
        this.props.update({active});
    };

    render () {
        const {rights, unit} = this.props;
        const {name, active} = this.props.unit;
        const disabled = rights && rights['references.units'] && !rights['references.units']['edit'];
        return (
            <>
                <h3>{getText('units.unit')}: {name}</h3>

                <ToggleSwitch send={this.changeActive} defaultValue={active} label="common.active" disabled={disabled}/>

                <Input
                    blur={this.onBlurName}
                    startValue={name}
                    label="units.unit"
                    disabled={disabled}
                />
            </>
        )
    }

}
const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
})
export default connect(mapState)(UnitDetail)