import React from "react";
import {Option} from "../../UI/select/select.types";
import ToggleSwitch from "../../UI/toggleSwitch/toggleSwitch.controller";
import Input from "../../UI/input/text";
import Select from "../../UI/select/select";
import {getBrandsList, getContractors1SList, getFilials1SList} from "../../../utils/functions";
import {Contractor, EditContractor, ModulesResponse} from "@justpro/terminal";
import {ApplicationMapState} from "../../application/application.controller";
import {connect} from "react-redux";
import getText from "../../../localization/getText";
import AsyncSelect from "../../UI/select/asyncSelect_v2";

interface Props {
    contractor : Contractor
    updateContractor(data:Partial<EditContractor>) : void
    rights?: Partial<ModulesResponse>
}

class ContractorDetail extends React.Component<Props>{

    changeName = (name: string, oldValue: string ) => {
        if(name !== oldValue) {
            this.props.updateContractor({name})
        }
    };

    changePseudonym = (pseudoname: string, oldValue: string ) => {
        if(pseudoname !== oldValue) {
            this.props.updateContractor({pseudoname})
        }
    };

    onChangeBrand = (option:Option) => {
        return new Promise(resolve => {
            this.props.updateContractor({
                brandId : option.value
            });
            resolve()
        })
    };

    changeActive = (active:boolean) => {
        this.props.updateContractor({active})
    };

    changeContractor1S = async (option: Option) => {
        return this.props.updateContractor({
            contractor1sId: option.value
        });
    };


    render () {
        const {rights, contractor} = this.props;
        const {name, brand, active, pseudoname, contractor1s} = contractor;

        const disabled = rights && rights['references.contractors'] && !rights['references.contractors']['edit'];

        return (
            <>
                <h3>{getText('contractors.contractor')}: {name}</h3>

                <ToggleSwitch defaultValue={active} send={this.changeActive} label="common.active" disabled={disabled}/>

                <Input
                    blur={this.changeName}
                    label="contractors.contractor"
                    startValue={name}
                    disabled={disabled}
                />

                <Select
                    change={this.onChangeBrand}
                    load={getBrandsList}
                    defaultValue={brand ? brand : {}}
                    accessors={{
                        name : 'label',
                        id : 'value'
                    }}
                    isDisabled={disabled}
                    label="brands.brand"
                />

                <AsyncSelect
                    loadOptions={getContractors1SList}
                    label={{text :"contractors.contractor1s"}}
                    change={this.changeContractor1S}
                    value={contractor1s && { label: contractor1s?.name, value: contractor1s?.id1s } as unknown as Option}
                    accessors={{
                        name : 'label',
                        id1s : 'value'
                    }}
                    isDisabled={disabled}
                />

                <Input
                    blur={this.changePseudonym}
                    startValue={pseudoname}
                    label="common.alias"
                    disabled={disabled}
                />
            </>
        )
    }
}


const mapState = (state:ApplicationMapState) => ({
    rights : state.application.rights
});

export default connect(mapState)(ContractorDetail)