import React from 'react';
import WithPrivateRoute from '../../withPrivateRoute/withPrivateRoute.controller';
import HeaderController from '../../header/header.controller';
import ModuleTextName from '../../UI/moduleTextName/moduleTextName';
import Tooltip from '../../UI/tooltip/tooltip.controller';
import RenderIf from '../../../utils/renderIf';
import Button from '../../UI/button/button';
import checkError from '../../../utils/checkError';
import FileDownload from 'js-file-download';
import moment, { Moment } from 'moment';
import TopLineCalendar from '../../UI/calendar/views/topLine.controller';
import './actsTimeReport.css';
import {
  CloseType,
  format,
  getActsCloseTypesReport,
  getActsCloseTypesReportExport,
  getCloseTypes,
  ActsCloseTypeReport,
  getPersons,
  getRegions,
  getActsTimeReport,
  IActsTimeReport,
  getActsTimeReportExport,
  secondsToTime,
} from '@justpro/terminal';
import Table from '../../UI/table/table';
import { getFullName } from '../../../utils/names';
import Spinner from '../../UI/spinner/spinner.controller';
import CheckboxSelectController from '../../UI/checkboxSelect/checkboxSelect.controller';
import { classNames } from 'react-select/src/utils';
import ToplineCalendarWrapper from '../../UI/calendar/views/toplineCalendarWrapper';

interface Props {}

interface State {
  generatingExcel: boolean;
  from: Moment;
  to: Moment;
  data?: IActsTimeReport[];
  selectedRegions: number[];
  loading: boolean;
  dates: string[];
}

console.log(secondsToTime(1550));

class ActsTimeReportController extends React.Component<Props, State> {
  state: State = {
    generatingExcel: false,
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment().subtract(1, 'month').endOf('month'),
    selectedRegions: [],
    dates: [],
    data: [],
    loading: false,
  };

  changeFrom = async (from: Moment) => {
    this.setState({
      from,
    });
  };
  changeTo = async (to: Moment) => {
    this.setState({
      to,
    });
  };

  generateExcel = async () => {
    try {
      this.setState({
        generatingExcel: true,
      });

      const blob = await getActsTimeReportExport({
        regionsId: this.state.selectedRegions.map((r: any) => r.id),
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });

      FileDownload(
        blob,
        `Учет_Времени_По_Актам_${moment().format(`YYYY-MM-DD_HH-mm-ss`)}.xls`
      );
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        generatingExcel: false,
      });
    }
  };

  get columns() {
    return [
      {
        Header: 'Объект',
        accessor: 'object',
        width: 400,
      },
      {
        Header: 'Город',
        accessor: 'city',
        width: 170,
      },
      {
        Header: 'Регион',
        accessor: 'region',
        width: 170,
      },
      {
        Header: 'Сервис',
        width: 70,
        Cell: ({ original }: any) => {
          return (
            <span className="act-service">
              {secondsToTime(original.totalService)}
            </span>
          );
        },
      },
      {
        Header: 'СМР',
        width: 70,
        Cell: ({ original }: any) => {
          return (
            <span className="act-smr">{secondsToTime(original.totalSmr)}</span>
          );
        },
      },
      {
        Header: 'Гибрид',
        width: 70,
        Cell: ({ original }: any) => {
          return (
            <span className="act-hybrid">
              {secondsToTime(original.totalHybrid)}
            </span>
          );
        },
      },
      {
        Header: 'Всего',
        width: 70,
        Cell: ({ original }: any) => {
          return secondsToTime(
            original.totalService + original.totalSmr + original.totalHybrid
          );
        },
      },
      ...this.state.dates.map((date) => ({
        Header: moment(date).format('DD.MM.YYYY'),
        columnProps: {
          date,
        },
        width: 160,
        columns: [
          {
            Header: 'Часов',
            width: 70,
            Cell: ({ original, column }: any) => {
              const acts = original.acts.filter(
                (a) => a.date === column?.parentColumn?.columnProps?.date
              );
              return acts.map((act) => {
                const classNames = [];
                if (act.isService) classNames.push('act-service');
                if (act.isSmr) classNames.push('act-smr');
                if (act.isHybrid) classNames.push('act-hybrid');
                return (
                  <div className={classNames.join(' ')}>
                    {secondsToTime(act.time)}
                  </div>
                );
              });
            },
          },
          {
            Header: '№ Акта',
            width: 90,
            Cell: ({ original, column }: any) => {
              const acts = original.acts.filter(
                (a) => a.date === column?.parentColumn?.columnProps?.date
              );
              return acts.map((act) => {
                const classNames = [];
                if (act.isService) classNames.push('act-service');
                if (act.isSmr) classNames.push('act-smr');
                if (act.isHybrid) classNames.push('act-hybrid');
                return <div className={classNames.join(' ')}>{act.number}</div>;
              });
            },
          },
        ],
      })),
    ];
  }

  getActsTimeReport = async () => {
    try {
      this.setState({
        loading: true,
      });
      const data = await getActsTimeReport({
        regionsId: this.state.selectedRegions.map((r: any) => r.id),
        dateFrom: this.state.from.format(format.date),
        dateTo: this.state.to.format(format.date),
      });
      const optimizedData = data.map((object: any) => {
        const { totalService, totalSmr, totalHybrid } = object.acts.reduce(
          (sums, act) => {
            act.isSmr =
              act.smrOrdersCount === act.allOrdersCount &&
              act.smrOrdersCount > 0 &&
              act.allRegularWorksCount === 0;
            act.isService = act.smrOrdersCount === 0;
            act.isHybrid = !act.isService && !act.isSmr;
            sums.totalService += act.isService ? act.time : 0;
            sums.totalSmr += act.isSmr ? act.time : 0;
            sums.totalHybrid += act.isHybrid ? act.time : 0;
            return sums;
          },
          {
            totalService: 0,
            totalSmr: 0,
            totalHybrid: 0,
          }
        );
        object.totalService = totalService;
        object.totalSmr = totalSmr;
        object.totalHybrid = totalHybrid;
        return object;
      });
      const dates = data.reduce((dates, object) => {
        object.acts.forEach((act) => {
          if (!dates.includes(act.date)) {
            dates.push(act.date);
          }
        });
        return dates;
      }, []);
      dates.sort((d1, d2) => {
        return moment(d1).diff(moment(d2));
      });
      this.setState({
        data: optimizedData,
        dates,
      });
    } catch (e) {
      checkError(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeRegion = (regions: number[]) => {
    this.setState(() => ({
      selectedRegions: regions,
    }));
  };

  getRegionsList = async () => {
    try {
      return await getRegions({});
    } catch (e) {
      checkError(e);
      return [];
    }
  };

  render() {
    return (
      <WithPrivateRoute>
        <HeaderController>
          <ModuleTextName>Учёт времени по актам</ModuleTextName>

          <ToplineCalendarWrapper>
            <TopLineCalendar
              date={this.state.from}
              onChange={this.changeFrom}
            />
            <TopLineCalendar date={this.state.to} onChange={this.changeTo} />
          </ToplineCalendarWrapper>

          <CheckboxSelectController
            onChange={this.onChangeRegion}
            getList={this.getRegionsList}
            selected={this.state.selectedRegions}
            pickAllNodes="Все регионы"
            placeholderParams={{
              maxItemLength: 2,
              multiplyPlaceholder: 'Регионов',
            }}
          />

          <div className="navbar-form navbar-left buttons">
            <Tooltip position="down" title="Экспортировать в эксель">
              <RenderIf condition={!this.state.generatingExcel}>
                <Button onClick={this.generateExcel} className="btn-default">
                  <i className="fa fa-file-excel" />
                </Button>
              </RenderIf>
              <RenderIf condition={this.state.generatingExcel}>
                Генерация...
              </RenderIf>
            </Tooltip>
          </div>

          <div className="navbar-form navbar-left buttons">
            <Button onClick={this.getActsTimeReport}>Фильтр</Button>
          </div>
        </HeaderController>

        <div className="just-pro_module acts-time-report">
          <div className="panel content-panel">
            {this.state.data && (
              <Table columns={this.columns} data={this.state.data}></Table>
            )}

            <Spinner loading={this.state.loading} />
          </div>
        </div>
      </WithPrivateRoute>
    );
  }
}

export default ActsTimeReportController;
