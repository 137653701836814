import React from 'react';
import Input from "../../UI/input/text";
import Button from "../../UI/button/button";
import { CreateMaster, createPerson } from "@justpro/terminal";
import getText from "../../../localization/getText";
import Select from '../../UI/select/select';
import { getPersonsList } from '../../../utils/functions';
import withLabel from '../../UI/withLabel/withLabel';
import RenderIf from '../../../utils/renderIf';
import { Option } from '../../UI/select/select.types';
import "./master.css";
import TextArea from '../../UI/textArea/textArea.controller';
import ToggleSwitch from '../../UI/toggleSwitch/toggleSwitch.controller';
import NumberInput from '../../UI/input/number'

interface State {
    firstName: string
    lastName: string
    patronymic: string
    description: string
    createNewPerson: boolean
    inStaff: boolean
    hasStore: boolean
    person: Option | null
    priority: number
}

interface Props {
    create(data: CreateMaster): void
}


export default class MasterCreate extends React.Component<Props, State>{

    state: State = {
        firstName: '',
        lastName: '',
        patronymic: '',
        description: '',
        createNewPerson: false,
        inStaff: false,
        hasStore: false,
        person: null,
        priority: 1,
    };


    changeFirstName = (firstName: string) => {
        this.setState(() => ({
            firstName
        }))
    };

    changeLastName = (lastName: string) => {
        this.setState(() => ({
            lastName
        }))
    };

    changePatronymic = (value: string) => {
        this.setState(() => ({
            patronymic: value
        }))
    };

    isDisabled = () => {
        return !this.state.person && (
            this.state.lastName.trim() === '' ||
            this.state.firstName.trim() === ''
        );
    };

    addMaster = async () => {
        const { firstName, lastName, patronymic } = this.state;
        let personId = this.state?.person?.value;
        if (this.state.createNewPerson) {
            const person = await createPerson({
                firstName,
                lastName,
                patronymic: patronymic ? patronymic : ''
            })
            personId = person.id;
        }
        this.props.create({
            personId,
            description: this.state.description,
            inStaff: this.state.inStaff,
            hasStore: this.state.hasStore,
            priority: this.state.priority,
        })
    };

    selectPerson = (option: Option) => {
        this.setState({
            person: option
        })
        return Promise.resolve();
    }

    changeCreateNewPerson = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            createNewPerson: event.target.checked,
        })
    }

    changeDescription = (description: string) => this.setState({ description })
    changePriority = (priority: number) => this.setState({ priority })

    changeToggle(fieldName: string, value: boolean) {
        //@ts-ignore
        this.setState({
            [fieldName]: value,
        })
    }

    render() {

        return (
            <div>
                <h3>{getText('masters.addingNewHeader')}</h3>
                <div className="masters__select-wrapper">
                    <Select
                        change={this.selectPerson}
                        isDisabled={this.state.createNewPerson}
                        label="persons.person"
                        placeholder="UI.select.placeholders.selectPerson"
                        defaultValue={this.state.person}
                        load={getPersonsList}
                        accessors={{
                            name: 'label',
                            id: 'value'
                        }}
                    />
                </div>
                <div className="masters__checkbox-wrapper">
                    <label>
                        <input type="checkbox"
                            className="masters__checkbox"
                            checked={this.state.createNewPerson}
                            onChange={this.changeCreateNewPerson}
                        />
                        {getText('masters.createNewPerson')}
                    </label>
                </div>
                <RenderIf condition={this.state.createNewPerson}>
                    <Input
                        label="persons.name"
                        change={this.changeFirstName}
                        placeholder="persons.name"
                    />
                    <Input
                        label="persons.lastName"
                        change={this.changeLastName}
                        placeholder="persons.lastName"

                    />
                    <Input
                        label="persons.patronymic"
                        change={this.changePatronymic}
                        placeholder="persons.patronymic"
                    />
                </RenderIf>
                <div className="masters__select-wrapper">
                    <NumberInput
                        blur={() => {}}
                        change={this.changePriority}
                        label="common.priority"
                        defaultValue={this.state.priority}
                    />
                </div>
                <ToggleSwitch label="masters.inStaff"
                    send={this.changeToggle.bind(this, "inStaff")}
                    defaultValue={this.state.inStaff}
                />
                <ToggleSwitch label="masters.hasStore"
                    send={this.changeToggle.bind(this, "hasStore")}
                    defaultValue={this.state.hasStore}
                />
                <div className="masters__select-wrapper">
                    {withLabel(<TextArea
                        placeholder="masters.note"
                        change={this.changeDescription}
                        value={this.state.description}
                        rows={6}
                    />)({
                        text: "masters.note",
                    })}
                </div>
                <Button className="btn-success"
                    onClick={this.addMaster}
                    disabled={this.isDisabled()}>
                    {getText('common.add')}
                </Button>
            </div>
        )
    }
}