import React, {Component} from "react";
import {SingleNews} from "@justpro/terminal";

interface SingleNewsDetailProps {
    singleNews?: SingleNews
}

export default class SingleNewsDetail extends Component<SingleNewsDetailProps> {
    render() {
        const {singleNews} = this.props;
        return (
            <div className="novelty__detail">
                <h2 className="novelty__title">
                    <span>{singleNews?.title}</span></h2>
                <div className="novelty__content"
                     dangerouslySetInnerHTML={{__html: singleNews?.content || ''}}/>
            </div>
        )
    }
}