import React from 'react';
import Spinner from "../../UI/spinner/spinner.controller";
import checkError from "../../../utils/checkError";
import ActDetail from "./act.detail";
import RenderIf from "../../../utils/renderIf";
import {getAct, getUser, IAct, IUpdateAct, updateAct} from "@justpro/terminal";
import {IUpdateActListItem} from "../acts.controller";
import ActPreloader from "./act.preloader";

interface Props {
    actId : number
    updateList?(actId:number, data:IUpdateActListItem) : void
}

interface State {
    act? : IAct
    loading : boolean
}

class ActController extends React.Component<Props, State> {

    state:State = {
        loading : false
    };


    getAct =  async () => {
        try {
            this.setState(() => ({loading : true}));
            const act = await getAct(this.props.actId);
            this.setState(() => ({
                act: act || void 0,
                loading : false,
            }))
        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };

    updateAct = async (actId:number, data:Partial<IUpdateAct>) => {
        try {
            this.setState(() => ({loading : true}));
            const act = await updateAct(actId, data);
            this.setState(() => ({
                act: act || void 0,
                loading : false,
            }));

            if(data.hasOwnProperty('date') || data.hasOwnProperty('number') || data.hasOwnProperty('objectId') || data.hasOwnProperty('responsibleId') ) {
                this.props.updateList && act && this.props.updateList(actId, act)
            }

        }catch (e) {
            this.setState(() => ({loading : false}));
            checkError(e)
        }
    };


    async componentDidMount(){
        this.getAct();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.actId !== this.props.actId) {
            this.getAct();
        }
    }


    render() {
        const {act, loading} = this.state;
        return(
            <>
                <RenderIf condition={act !== undefined}>
                    <ActDetail
                        act={act as IAct}
                        updateActListItem={this.props.updateList}
                        updateAct={this.updateAct}
                        getAct={this.getAct}
                    />
                </RenderIf>
                <RenderIf condition={loading}>
                    <ActPreloader loading={loading} />
                </RenderIf>

            </>
        )
    }
}

export default ActController